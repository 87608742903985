// @flow

import * as React from 'react';

import { Image } from '@realadvisor/image';
import { useResizeRect } from '@realadvisor/observe';
import { graphql, useFragment } from 'react-relay';

import { useTheme } from '../hooks/theme';

import type { previewImage_image$key } from './__generated__/previewImage_image.graphql';
import type {
  PropertyImageBackground,
  PropertyImageCrop,
  PropertyImageSize,
} from './__generated__/propertyImageForm_image.graphql';

type Props = {|
  image: previewImage_image$key,
  crop?: PropertyImageCrop,
  backgroundColor?: PropertyImageBackground,
  size?: PropertyImageSize,
  brochure: 'portrait' | 'landscape' | 'old',
|};

const aspectRatio = props => ({
  position: 'relative',
  '&:before': {
    display: 'block',
    width: '100%',
    paddingTop: `${100 * (props.ratio || 1)}%`,
    content: "''",
  },
});

const BROCHURE_RATIO = 1.4;
const IMG_NORMAL_RATIO = 0.44;
const ROTATE_DEG = -90;
const PADDING_TOP_BOTTOM = 20;

export const PreviewImage = (props: Props): React.Node => {
  const image = useFragment(
    graphql`
      fragment previewImage_image on Image {
        url
      }
    `,
    props.image,
  );
  const { size, crop, backgroundColor = 'white', brochure } = props;

  const ref = React.useRef(null);
  const rect = useResizeRect(ref);
  const { colors } = useTheme();
  let rotateVal,
    width,
    height = 0;

  let sizeImgOption = null;
  if (rect != null) {
    sizeImgOption = { w: rect.width, h: rect.height };
    if (size === 'full_page') {
      if (brochure !== 'landscape') {
        rotateVal = brochure === 'portrait' ? 0 : ROTATE_DEG;
      }
      sizeImgOption = {
        w: Math.floor(rect.height) - PADDING_TOP_BOTTOM,
        h: Math.floor(rect.width),
      };
      width = sizeImgOption.h;
      height = sizeImgOption.w;
    }
    if (size === 'half_page') {
      sizeImgOption = {
        w:
          brochure === 'landscape'
            ? Math.floor(rect.width * IMG_NORMAL_RATIO)
            : Math.floor(rect.width),
        h:
          brochure === 'landscape'
            ? Math.floor(rect.height) - PADDING_TOP_BOTTOM
            : Math.floor(rect.height * IMG_NORMAL_RATIO),
      };
      width = sizeImgOption.w;
      height = sizeImgOption.h;
    }
    if (size === 'quarter_page') {
      sizeImgOption = {
        w: Math.floor(rect.width / 2),
        h: Math.floor(rect.height * IMG_NORMAL_RATIO),
      };
      width = sizeImgOption.w;
      height = sizeImgOption.h;
    }
  }

  return (
    <div
      ref={ref}
      css={[
        {
          border: `1px solid ${colors.grey400}`,
          position: 'relative',
          width: brochure === 'landscape' ? 198 : 142,
          height: brochure === 'landscape' ? 142 : 'auto',
        },
        aspectRatio({ ratio: BROCHURE_RATIO }),
      ]}
    >
      <div
        css={[
          {
            position: 'absolute',
            left: 0,
            top: Math.floor(PADDING_TOP_BOTTOM / 2),
            bottom: Math.floor(PADDING_TOP_BOTTOM / 2),
            backgroundColor,
            width,
            height,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        ]}
      >
        <Image
          src={image.url ?? ''}
          options={{ ...sizeImgOption, c: crop, f: 'jpg' }}
          css={[
            rotateVal != null &&
              rotateVal !== 0 && [
                {
                  transform: `rotate(${rotateVal}deg)`,
                  transformOrigin: 'center center',
                },
              ],
            brochure != null &&
              brochure !== 'old' && [
                {
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  width: '100%',
                  height: '100%',
                  objectFit: crop === 'fit' ? 'contain' : 'cover',
                  objectPosition: 'center',
                },
              ],
          ]}
        />
      </div>
    </div>
  );
};
