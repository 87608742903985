/**
 * @generated SignedSource<<d5fb13b98013b44b0835409cfb12c81c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ActivityForm_enquiry$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ActivityEventForm_enquiry" | "ActivityNoteForm_enquiry">;
  readonly " $fragmentType": "ActivityForm_enquiry";
};
export type ActivityForm_enquiry$key = {
  readonly " $data"?: ActivityForm_enquiry$data;
  readonly " $fragmentSpreads": FragmentRefs<"ActivityForm_enquiry">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActivityForm_enquiry",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ActivityNoteForm_enquiry"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ActivityEventForm_enquiry"
    }
  ],
  "type": "Enquiry",
  "abstractKey": null
};

(node as any).hash = "b6eb1e451a44147eeaab746b171135f5";

export default node;
