import { Clear } from '@mui/icons-material';
import { Autocomplete, IconButton, TextField, Tooltip } from '@mui/material';

import { addWhereClause, deleteWhereClause } from '../Filters';
import { useSchema } from '../useSchema';

import { Node } from './Node';

interface InputObjectProps {
  name: string;
  node: any;
  path: string[];
  where: any;
  setWhere: (where: any) => void;
}

export const InputObject = ({
  name,
  node,
  path,
  where,
  setWhere,
}: InputObjectProps) => {
  const { getTypeFromPath } = useSchema();
  const type = getTypeFromPath(path);

  if (type?.kind !== 'INPUT_OBJECT') {
    throw new Error('Type is not an input object');
  }

  const options = type?.inputFields ?? [];

  return (
    <div>
      <div
        style={{
          display: 'flex',
          gap: 5,
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        <Tooltip
          title={
            <>
              {type?.name}
              <br />
              {type?.kind}
            </>
          }
        >
          <strong>{name}</strong>
        </Tooltip>
        {name !== 'where' && (
          <IconButton
            sx={{
              padding: '3px',
            }}
            size="small"
            color="error"
            onClick={() => {
              const newWhere = deleteWhereClause(where, path);
              setWhere(newWhere);
            }}
          >
            <Clear sx={{ fontSize: 20 }} />
          </IconButton>
        )}
      </div>

      <div
        style={{
          paddingLeft: 10,
          borderLeft: '1px solid #ccc',
          display: 'flex',
          flexDirection: 'column',
          gap: 5,
        }}
      >
        {Object.keys(node ?? {})
          .toSorted((a, b) => a.localeCompare(b))
          .map(key => (
            <Node
              key={[...path, key].join('.')}
              name={key}
              node={node[key]}
              path={[...path, key]}
              where={where}
              setWhere={setWhere}
            />
          ))}
        <Autocomplete
          value={null as any}
          options={options}
          disableClearable
          clearOnBlur
          blurOnSelect
          sx={{ width: 350 }}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              placeholder={`+ Add filter (${name})`}
              sx={{
                background: 'white',
                width: 350,
                '& .MuiInputBase-root': {
                  padding: '0 !important',
                },
              }}
            />
          )}
          size="small"
          getOptionLabel={option => option.name}
          onChange={(e, field) => {
            const fieldName = field?.name;
            const typeKind = field?.type?.kind;
            const typeName = field?.type?.name;

            if (!fieldName || !typeKind) {
              throw new Error('Invalid field');
            }

            let filterValue: any;
            if (['INPUT_OBJECT', 'NON_NULL'].includes(typeKind)) {
              filterValue = {};
            } else if (typeKind === 'LIST') {
              filterValue = [];
            } else if (typeKind === 'ENUM') {
              filterValue = undefined;
            } else if (typeKind === 'SCALAR') {
              switch (typeName) {
                case 'String':
                  filterValue = '';
                  break;
                case 'Int':
                case 'Float':
                case 'float8':
                  filterValue = 0;
                  break;
                case 'Boolean':
                  filterValue = true;
                  break;
                case 'date':
                case 'timestamptz':
                  filterValue = new Date();
                  break;
                case 'geometry':
                  filterValue = { type: 'Point', coordinates: [0, 0] };
                  break;
                default:
                  filterValue = undefined;
                  break;
              }
            } else {
              throw new Error('Unknown typeKind: ' + typeKind);
            }
            const newWhere = addWhereClause(
              where,
              [...path, fieldName],
              filterValue,
            );
            setWhere(newWhere);
          }}
        />
      </div>
    </div>
  );
};
