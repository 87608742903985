import React, { useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';
import { FileDownload } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';
import { download, generateCsv, mkConfig } from 'export-to-csv';

import { useLocale } from '../../../src/hooks/locale';
import type {
  ExportUsersQuery,
  Users_Bool_Exp,
} from '../../__generated__/graphql';
import { flatten } from '../../utils/flatten';

import { EXPORT_USERS } from './userQueries';

type UsersExportProps = {
  where: Users_Bool_Exp;
  open: boolean;
  onExporterClose: () => void;
};

const csvConfig = mkConfig({
  useKeysAsHeaders: true,
  filename: 'users_export.csv',
});

export const UsersExport = (props: UsersExportProps) => {
  const [getUsers, { loading, data }] =
    useLazyQuery<ExportUsersQuery>(EXPORT_USERS);
  const { t } = useLocale();

  const { where, open, onExporterClose } = props;

  useEffect(() => {
    if (open) {
      getUsers({ variables: { where } });
    }
  }, [open, getUsers, where]);

  const handleClose = () => {
    onExporterClose(); // Notify parent component
  };

  // Flatten the data for CSV export
  const users = data?.users.map((user: any) => flatten(user));

  // remove all key which include __typename
  if (users) {
    users.forEach(user => {
      Object.keys(user).forEach(key => {
        if (key.includes('__typename')) {
          delete user[key];
        }
      });
    });
  }

  // Generate and download CSV file
  const downloadCSV = () => {
    if (users) {
      const csv = generateCsv(csvConfig)(users);
      download(csvConfig)(csv);
    }
    handleClose(); // Close the dialog after downloading
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="loading-dialog-title"
      maxWidth="sm"
    >
      <DialogTitle id="loading-dialog-title">
        {loading ? t('Preparing Export Data') : t('Export Ready')}
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Alert
            severity="info"
            icon={<CircularProgress size={16} disableShrink />}
          >
            <AlertTitle>{t('Please wait')}</AlertTitle>
            {t('We are preparing your data for download')}
          </Alert>
        ) : (
          <Stack gap={2}>
            <Alert severity="success">
              <AlertTitle>{t('Data is ready')}</AlertTitle>
              {t('Your data is ready for download')}
            </Alert>
            <Button
              onClick={downloadCSV}
              color="primary"
              variant="contained"
              startIcon={<FileDownload />}
            >
              {t('Download CSV')}
            </Button>
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
