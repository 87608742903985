/**
 * @generated SignedSource<<230183adebf82b04d4bf01afa03f321b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type leadBrokerCard_root$data = {
  readonly me: {
    readonly isAdmin: boolean | null;
  } | null;
  readonly " $fragmentType": "leadBrokerCard_root";
};
export type leadBrokerCard_root$key = {
  readonly " $data"?: leadBrokerCard_root$data;
  readonly " $fragmentSpreads": FragmentRefs<"leadBrokerCard_root">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "leadBrokerCard_root",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "me",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isAdmin",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "63f09aba0a1250dbda6e7c635cf3fac3";

export default node;
