export const toNoCase = (string: string, separator: string): string => {
  return (
    string
      .replace(/([a-z])([A-Z])/g, `$1${separator}$2`)
      .replace(/([A-Z]+)([A-Z][a-z]+)/g, `$1${separator}$2`)
      .replace(/(\d+)([A-Z]+)/g, `$1${separator}$2`)
      .replace(/^\W+/, '')
      .replace(/\W+$/, '')
      .replace(/\W+/g, separator)
      // underscore is not caught by \W in 1_2_3
      .replace(/_+/g, separator)
      .toLowerCase()
  );
};

export const toSnakeCase = (string: string) => toNoCase(string, '_');

// if the filename is too long, it becomes:
// VERY_long_file_name_blbalbklkbklbksome_ending.pdf => VERY_long_fil…ending.pdf
export const shortenFilename = (str: string) => {
  const NAME_LIMIT = 40;
  const APPENDIX = 6;
  if (str.length < NAME_LIMIT) {
    return str;
  }

  const [extension, ...parts] = str.split('.').reverse();
  let name = parts.reverse().join('.');
  name = `${name.slice(0, NAME_LIMIT - APPENDIX * 2)}…${name.slice(-APPENDIX)}`;
  return `${name}.${extension}`;
};
