import { gql } from '@apollo/client';
import { Email, Person, Phone } from '@mui/icons-material';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import type { ContactCardUserFragmentFragment } from '../../__generated__/graphql';

export const USER_PHONE_NUMBERS_FRAGMENT = gql`
  fragment UserPhoneNumbers on users {
    phone_numbers(order_by: [{ primary: desc }, { verified: desc }]) {
      id
      number
    }
  }
`;

export const USER_EMAILS_FRAGMENT = gql`
  fragment UserEmails on users {
    emails(
      order_by: [{ primary: desc }, { verified: desc }, { created_at: desc }]
    ) {
      id
      email
    }
  }
`;

export const CONTACT_CARD_USER_FRAGMENT = gql`
  ${USER_PHONE_NUMBERS_FRAGMENT}
  ${USER_EMAILS_FRAGMENT}
  fragment ContactCardUserFragment on users {
    id
    first_name
    last_name
    ...UserPhoneNumbers
    ...UserEmails
  }
`;

export const ContactCard = ({
  user,
}: {
  user?: ContactCardUserFragmentFragment | null;
}) => {
  return (
    <>
      <TableContainer component={Paper} elevation={0} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>Contact</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                sx={{ pr: 0, width: '20px' }}
              >
                <Person />
              </TableCell>
              <TableCell>
                {user?.first_name} {user?.last_name}
              </TableCell>
            </TableRow>
            {user?.phone_numbers.map(phone => (
              <TableRow
                key={phone.number}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row" sx={{ pr: 0 }}>
                  <Phone />
                </TableCell>
                <TableCell>{phone.number}</TableCell>
              </TableRow>
            ))}
            {user?.emails.map(({ email }) => (
              <TableRow
                key={email}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row" sx={{ pr: 0 }}>
                  <Email />
                </TableCell>
                <TableCell>{email}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
