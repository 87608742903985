/**
 * @generated SignedSource<<b6961932e184f2f6b0bd1450dc9254e9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PipelineLeadType = "buyer" | "financing" | "sales";
export type TenantEmailTemplate = "cardis" | "default" | "realadvisor";
export type generalSettingsQuery$variables = {};
export type generalSettingsQuery$data = {
  readonly pipelines: ReadonlyArray<{
    readonly id: string;
    readonly label: string | null;
    readonly leadType: PipelineLeadType;
    readonly name: string | null;
    readonly stages: ReadonlyArray<{
      readonly __typename: "LeadStage";
    }>;
  }>;
  readonly tenantSettings: {
    readonly availableEmailTemplates: ReadonlyArray<TenantEmailTemplate | null>;
    readonly countryCode: string;
    readonly debugEmailMode: boolean;
    readonly defaultPipeline: {
      readonly id: string;
    } | null;
    readonly emailSenderEmail: string;
    readonly emailSenderName: string;
    readonly emailTemplate: TenantEmailTemplate | null;
    readonly googleTagManagerId: string;
    readonly helpLinkDe: string | null;
    readonly helpLinkEn: string | null;
    readonly helpLinkEs: string | null;
    readonly helpLinkFr: string | null;
    readonly helpLinkIt: string | null;
    readonly locality: string;
    readonly logoImage: {
      readonly url: string;
    } | null;
    readonly mainColor: string;
    readonly name: string;
    readonly phoneMain: string;
    readonly postcode: string;
    readonly route: string;
    readonly sendInBlueApiKey: string | null;
    readonly state: string;
    readonly streetNumber: string;
    readonly stripeSecretKey: string | null;
    readonly stripeSecretKeyTest: string | null;
    readonly stripeWebhookSecret: string | null;
    readonly stripeWebhookSecretTest: string | null;
  } | null;
};
export type generalSettingsQuery = {
  response: generalSettingsQuery$data;
  variables: generalSettingsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emailSenderName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emailSenderEmail",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Pipeline",
  "kind": "LinkedField",
  "name": "defaultPipeline",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emailTemplate",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "availableEmailTemplates",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "route",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "streetNumber",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postcode",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locality",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "countryCode",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mainColor",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phoneMain",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "googleTagManagerId",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sendInBlueApiKey",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stripeSecretKey",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stripeWebhookSecret",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stripeSecretKeyTest",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stripeWebhookSecretTest",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "helpLinkFr",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "helpLinkEs",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "helpLinkDe",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "helpLinkEn",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "helpLinkIt",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "debugEmailMode",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "leadType",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "generalSettingsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantSettings",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "logoImage",
            "plural": false,
            "selections": [
              (v21/*: any*/)
            ],
            "storageKey": null
          },
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Pipeline",
        "kind": "LinkedField",
        "name": "pipelines",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          (v0/*: any*/),
          (v28/*: any*/),
          (v29/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "LeadStage",
            "kind": "LinkedField",
            "name": "stages",
            "plural": true,
            "selections": [
              (v30/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "generalSettingsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantSettings",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "logoImage",
            "plural": false,
            "selections": [
              (v21/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Pipeline",
        "kind": "LinkedField",
        "name": "pipelines",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          (v0/*: any*/),
          (v28/*: any*/),
          (v29/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "LeadStage",
            "kind": "LinkedField",
            "name": "stages",
            "plural": true,
            "selections": [
              (v30/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b266d2a4ff1c59a2c0936bab3b99ed9e",
    "id": null,
    "metadata": {},
    "name": "generalSettingsQuery",
    "operationKind": "query",
    "text": "query generalSettingsQuery {\n  tenantSettings {\n    name\n    emailSenderName\n    emailSenderEmail\n    defaultPipeline {\n      id\n    }\n    emailTemplate\n    availableEmailTemplates\n    route\n    streetNumber\n    postcode\n    state\n    locality\n    countryCode\n    mainColor\n    phoneMain\n    googleTagManagerId\n    sendInBlueApiKey\n    stripeSecretKey\n    stripeWebhookSecret\n    stripeSecretKeyTest\n    stripeWebhookSecretTest\n    logoImage {\n      url\n      id\n    }\n    helpLinkFr\n    helpLinkEs\n    helpLinkDe\n    helpLinkEn\n    helpLinkIt\n    debugEmailMode\n    id\n  }\n  pipelines {\n    id\n    name\n    label\n    leadType\n    stages {\n      __typename\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d7b5fb0df0fc4637a74809fc59227734";

export default node;
