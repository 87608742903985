// Generated from src/svgs/tag-outline.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const TagOutline = ({ size, fill, className }: Props) => {
  return (
    <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><path style={{"fill":"none"}} d="M0 0h24v24H0z" /><path d="M14 22a.997.997 0 0 1-.707-.293l-11-11A.996.996 0 0 1 2 10V3a1 1 0 0 1 1-1h7c.266 0 .52.105.707.293l11 11a.999.999 0 0 1 0 1.414l-7 7A.997.997 0 0 1 14 22zM4 9.586l10 10L19.586 14l-10-10H4v5.586z" /><circle cx="7" cy="7" r="2" /></svg>
  );
}
