import * as React from 'react';

import { Box, IconButton, Stack } from '@mui/material';
import scrollbarSize from 'dom-helpers/scrollbarSize';
import { useSystem } from 'react-system';

import { Navigation } from '../components/Navigation';
import { useTheme } from '../hooks/theme';
import { useWindowInnerSize } from '../hooks/window-size';
import { Menu } from '../icons/menu';
import { Sidebar } from '../shared/sidebar';
import { TopbarProvider } from '../shared/topbar';

type Props = {
  children: React.ReactNode;
};

// generate by material-ui
const layoutTransition = 'transform 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms';

const BaseLayout = ({
  expanded,
  children,
}: {
  expanded: boolean;
  children: React.ReactNode;
}) => {
  const { media } = useSystem();

  React.useEffect(() => {
    if (document.body) {
      /* hide overflowed layout when sidebar is expanded */
      document.body.style.overflowX = expanded ? 'hidden' : 'visible';
    }
  }, [expanded]);

  return (
    <div css={{ width: '100%' }}>
      <div
        css={media({
          display: 'grid',
          width: [
            '100%',
            expanded ? 'calc(100% - 238px + 58px)' : '100%',
            expanded ? 'calc(100% - 238px + 58px)' : '100%',
          ],
          position: 'relative',
          left: [-238, -238, -238 + 58],
          gridTemplateColumns: [
            '238px 100%',
            '238px 100%',
            '238px calc(100% - 58px)',
          ],
          transform: [
            `translateX(${expanded ? 238 : 0}px)`,
            `translateX(${expanded ? 238 : 0}px)`,
            `translateX(${expanded ? 238 - 58 : 0}px)`,
          ],
          overflow: [expanded ? 'hidden' : 'visible', 'visible', 'visible'],
          transition: layoutTransition,
          height: ['100vh', 'unset'],
        })}
      >
        {children}
      </div>
    </div>
  );
};

const SidebarContainer = ({
  expanded,
  onChange,
  children,
}: {
  expanded: boolean;
  onChange: (value: boolean) => void;
  children: React.ReactNode;
}) => {
  const { colors, depth } = useTheme();
  const [hidden, setHidden] = React.useState(expanded === false);
  const [, height] = useWindowInnerSize();
  // To hide even Mac or Mobile scrollbar if scrollbarSize() is 0
  const MAX_SCROLL_BAR_SIZE = 0;
  return (
    <div
      css={{
        boxSizing: 'unset',
        position: 'sticky',
        top: 0,
        overflow: 'hidden',
        height,
        backgroundColor: colors.sidebar,
        color: colors.white,
        borderRight: `1px solid ${colors.divider}`,
        zIndex: depth.sideBar,
      }}
    >
      <Box
        aria-hidden={hidden}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          transform: `translateX(${expanded ? 0 : 238 - 58}px)`,
          transition: layoutTransition,
          overflowX: 'hidden',
          overflowY: 'scroll',
          // enable momentum scrolling for menu
          WebkitOverflowScrolling: 'touch',
          width: [
            `calc(100%)`,
            `calc(100% + ${scrollbarSize() || MAX_SCROLL_BAR_SIZE}px)`,
          ],
          // hide scrollbar
          height: 'inherit',
          paddingRight: scrollbarSize() === 0 ? MAX_SCROLL_BAR_SIZE : 0,
        }}
        onTransitionEnd={() => setHidden(expanded === false)}
      >
        {children}
      </Box>
      <div
        css={{
          opacity: expanded ? 0 : 1,
          pointerEvents: expanded ? 'none' : 'auto',
          transition: `opacity 0.5s`,
          position: 'absolute',
          top: 0,
          right: 0,
          width: 58,
          height: 64,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: colors.sidebar,
        }}
      >
        <IconButton onClick={() => onChange(!expanded)}>
          <Menu fill={colors.white} />
        </IconButton>
      </div>
    </div>
  );
};

export const Layout = (props: Props) => {
  const [expanded, setExpanded] = React.useState(false);
  return (
    <TopbarProvider>
      <BaseLayout expanded={expanded}>
        <SidebarContainer expanded={expanded} onChange={setExpanded}>
          <Sidebar expanded={expanded} onChange={setExpanded} />
        </SidebarContainer>
        <Stack flexDirection="column" css={{ position: 'relative' }}>
          <Navigation open={expanded} onOpenMenu={() => setExpanded(true)} />
          <Stack
            flexShrink={0}
            flexGrow={1}
            flexDirection="column"
            css={{ position: 'relative' }}
          >
            {props.children}
          </Stack>
        </Stack>
      </BaseLayout>
    </TopbarProvider>
  );
};
