import * as React from 'react';

import { CircularProgress } from '@material-ui/core';
import { graphql, useLazyLoadQuery } from 'react-relay';
import {
  Route,
  Routes,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { useLocale } from '../hooks/locale';
import { LeadDetails, useLeadTitle } from '../routes/LeadDetails/lead-details';
import { Leads } from '../routes/Leads/Leads';
import { TopbarTitle } from '../shared/topbar';

import type { LeadsRoutesDetailsQuery } from './__generated__/LeadsRoutesDetailsQuery.graphql';

interface LeadsWithDataProps {
  setTitle?: (title: string) => void;
}

export const LeadWithData = ({ setTitle }: LeadsWithDataProps) => {
  const { leadId } = useParams() as { leadId: string };
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const data = useLazyLoadQuery<LeadsRoutesDetailsQuery>(
    graphql`
      query LeadsRoutesDetailsQuery($leadId: ID!) {
        ...leadDetails_root
        leadById(id: $leadId) {
          ...leadDetails_lead
          ...leadDetails_leadClaimData
        }
      }
    `,
    {
      leadId,
    },
  );

  const title = useLeadTitle(data.leadById);
  setTitle?.(title);

  return (
    <>
      {setTitle != null ? (
        <TopbarTitle titleOverride={title}>
          {/* So that we don't display title in the app bar */}
          {null}
        </TopbarTitle>
      ) : (
        <TopbarTitle>{title}</TopbarTitle>
      )}
      {data.leadById != null && (
        <React.Suspense fallback={null}>
          <LeadDetails
            root={data}
            lead={data.leadById}
            closeLead={() =>
              navigate({
                pathname: '../',
                search: searchParams.toString(),
              })
            }
          />
        </React.Suspense>
      )}
    </>
  );
};

export const LeadsRoutes = () => {
  const { t } = useLocale();
  return (
    <Routes>
      <Route
        index
        element={
          <React.Suspense
            fallback={
              <>
                <TopbarTitle>{t('Leads')}</TopbarTitle>
                <CircularProgress css={{ margin: 'auto' }} disableShrink />
              </>
            }
          >
            <Leads />
          </React.Suspense>
        }
      />
      <Route
        path="add/*"
        element={
          <React.Suspense
            fallback={
              <>
                <TopbarTitle>{t('Leads')}</TopbarTitle>
                <CircularProgress css={{ margin: 'auto' }} disableShrink />
              </>
            }
          >
            <Leads />
          </React.Suspense>
        }
      />
      <Route path=":leadId" element={<LeadWithData />} />
    </Routes>
  );
};

export default LeadsRoutes;
