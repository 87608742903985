/**
 * @generated SignedSource<<03538cf852ebae3bd30c7a46c6ef655d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NewMatch_activity$data = {
  readonly createdAt: string;
  readonly lot: {
    readonly id: string;
  } | null;
  readonly targetUsers: ReadonlyArray<{
    readonly id: string;
  } | null> | null;
  readonly " $fragmentType": "NewMatch_activity";
};
export type NewMatch_activity$key = {
  readonly " $data"?: NewMatch_activity$data;
  readonly " $fragmentSpreads": FragmentRefs<"NewMatch_activity">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NewMatch_activity",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "targetUsers",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Lot",
      "kind": "LinkedField",
      "name": "lot",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Activity",
  "abstractKey": "__isActivity"
};
})();

(node as any).hash = "eecedb059e54fadc4e0bb5e99fc3b23d";

export default node;
