import { forwardRef, useState } from 'react';

import {
  type InternalRefetchQueriesInclude,
  useMutation,
} from '@apollo/client';
import {
  Alert,
  AlertTitle,
  Dialog,
  DialogTitle,
  Slide,
  Snackbar,
} from '@mui/material';
import type { TransitionProps } from '@mui/material/transitions';
import parsePhoneNumberFromString from 'libphonenumber-js';

import { useLocale } from '../../src/hooks/locale';
import { gql } from '../__generated__';

import { type FormDefinitionType, RaForm } from './form/RaForm';

type AddUserModalProps = {
  user?: {
    firstName?: string;
    lastName?: string;
    email?: string;
  };
  opened: boolean;
  onClose: (newId?: string) => void;
  refetchQueries?: InternalRefetchQueriesInclude;
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const INSERT_USER_QUERY = gql(`
  mutation InsertUser($user: users_insert_input!) {
    insert_users_one(object: $user) {
      id
    }
  }
`);

const formDefinition: FormDefinitionType<{
  firstName: string;
  lastName: string;
  email: string | null;
  phone: string | null;
}> = ({ t }) => [
  {
    type: 'text',
    required: true,
    name: 'firstName',
    label: t('First name'),
  },
  {
    type: 'text',
    required: true,
    name: 'lastName',
    label: t('Last name'),
  },
  {
    type: 'text',
    name: 'email',
    label: t('Email'),
  },
  {
    type: 'phone_number',
    name: 'phone',
    label: t('Phone'),
  },
];

export const AddUserModal: React.FC<AddUserModalProps> = ({
  user,
  opened,
  onClose,
  refetchQueries,
}) => {
  const { t } = useLocale();
  const [insertUser] = useMutation(INSERT_USER_QUERY, {
    onCompleted: data => {
      if (data.insert_users_one != null) {
        setDisplaySuccessMessage(true);
        onClose(data.insert_users_one.id);
      }
    },
    onError: error => {
      setErrorMessage(error.message);
    },
    refetchQueries,
  });
  const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  return (
    <>
      <Dialog
        open={opened}
        fullWidth
        maxWidth="sm"
        onClose={() => onClose()}
        TransitionComponent={Transition}
        sx={{
          '& .MuiBackdrop-root': { backgroundColor: 'rgba(0, 0, 0, 0.3)' },
        }}
      >
        <DialogTitle>{t('New user')}</DialogTitle>
        <RaForm
          formDefinition={formDefinition}
          onCancel={() => onClose()}
          defaultValues={user ?? {}}
          allowSubmitNotDirty
          onSubmit={data =>
            insertUser({
              variables: {
                user: {
                  first_name: data.firstName,
                  last_name: data.lastName,
                  emails:
                    data.email != null
                      ? { data: [{ email: data.email }] }
                      : undefined,
                  phone_numbers:
                    data.phone != null
                      ? {
                          data: [
                            {
                              number:
                                parsePhoneNumberFromString(data.phone)?.format(
                                  'E.164',
                                ) ?? '',
                            },
                          ],
                        }
                      : undefined,
                },
              },
            })
          }
        >
          {errorMessage != null && (
            <Alert severity="error" sx={{ m: 2 }}>
              <AlertTitle>
                {t('An error occured while creating the user.')}
              </AlertTitle>
              {errorMessage}
            </Alert>
          )}
        </RaForm>
      </Dialog>
      <Snackbar
        open={displaySuccessMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={5000}
        onClose={() => setDisplaySuccessMessage(false)}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: '100%', alignItems: 'center' }}
        >
          {t('User created successfully!')}
        </Alert>
      </Snackbar>
    </>
  );
};
