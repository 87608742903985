import { useCallback } from 'react';

import { useScopedSearchParams } from '../../utils/navigation';

export const useFiltersSearchParams = (
  queryParamsScope?: string,
): [URLSearchParams, (where: any) => void] => {
  const [searchParams, setSearchParams] =
    useScopedSearchParams(queryParamsScope);

  const setSearchParamsWithWhere = useCallback(
    (where: any) => {
      searchParams.set('where', JSON.stringify(where));
      searchParams.delete('limit');
      searchParams.delete('offset');
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  return [searchParams, setSearchParamsWithWhere];
};
