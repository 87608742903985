/**
 * @generated SignedSource<<cf7d0b13e72ca5df22f1f87c345efb82>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
type userEditableCard_user$fragmentType = any;
export type CompositePropertyType = "APPT" | "BUILDING" | "COMMERCIAL" | "GASTRO" | "HOUSE" | "HOUSE_APPT" | "OTHER" | "PARK" | "PROP" | "ROOM";
import type { FragmentType } from "relay-runtime";
declare export opaque type propertyCard_property$fragmentType: FragmentType;
export type propertyCard_property$data = {|
  +builtSurface: ?number,
  +compositePropertyType: CompositePropertyType,
  +formattedAddress: ?string,
  +id: string,
  +isRental: ?boolean,
  +latestAppraisal: ?{|
    +realadvisor: ?{|
      +max: ?number,
      +min: ?number,
    |},
  |},
  +livingSurface: ?number,
  +municipalityObject: ?{|
    +slug: string,
  |},
  +numberOfBedrooms: ?number,
  +numberOfRooms: ?number,
  +owners: ?$ReadOnlyArray<{|
    +id: string,
    +$fragmentSpreads: userEditableCard_user$fragmentType,
  |}>,
  +propertyImages: ?$ReadOnlyArray<{|
    +image: {|
      +url: string,
    |},
    +title: ?string,
  |}>,
  +propertyType: ?{|
    +label: ?string,
  |},
  +$fragmentType: propertyCard_property$fragmentType,
|};
export type propertyCard_property$key = {
  +$data?: propertyCard_property$data,
  +$fragmentSpreads: propertyCard_property$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "propertyCard_property",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "formattedAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isRental",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "livingSurface",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "builtSurface",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numberOfRooms",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numberOfBedrooms",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "compositePropertyType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PropertyType",
      "kind": "LinkedField",
      "name": "propertyType",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PropertyImage",
      "kind": "LinkedField",
      "name": "propertyImages",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Image",
          "kind": "LinkedField",
          "name": "image",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Appraisal",
      "kind": "LinkedField",
      "name": "latestAppraisal",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RealadvisorAppraisal",
          "kind": "LinkedField",
          "name": "realadvisor",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "min",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "max",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Place",
      "kind": "LinkedField",
      "name": "municipalityObject",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "owners",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "userEditableCard_user"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Property",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "618ca3c14da9ae7e8d3c4edede7df6a9";

export default node;
