import * as React from 'react';

export const ScrollToError = ({
  errors,
  callOnInitial = false,
}: {
  errors: unknown;
  callOnInitial?: boolean;
}) => {
  const first = React.useRef(!callOnInitial);
  const serialized = JSON.stringify(errors);

  React.useEffect(() => {
    if (first.current === false) {
      const target = document.querySelector('.Mui-error');
      if (target) {
        target.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
    first.current = false;
  }, [serialized]);

  return null;
};
