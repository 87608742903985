// Generated from src/svgs/nav-performance-active.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const NavPerformanceActive = ({ size, fill, className }: Props) => {
  return (
    <svg viewBox="0 0 25 25" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><path d="M25 23.5a.5.5 0 0 1-.5.5h-22a.5.5 0 0 1-.5-.5v-22a.5.5 0 0 1 1 0V23h2v-4.5A1.5 1.5 0 0 1 6.5 17h1A1.5 1.5 0 0 1 9 18.5V23h3v-9.5a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5V23h3V7.5A1.5 1.5 0 0 1 20.5 6h1A1.5 1.5 0 0 1 23 7.5V23h1.5a.5.5 0 0 1 .5.5z" /></svg>
  );
}
