/**
 * @generated SignedSource<<f8d40c4cb7836a3868e9fa1c864bb6b3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeedNewForm_enquiry$data = {
  readonly __typename: "Enquiry";
  readonly user: {
    readonly phoneNumbers: ReadonlyArray<{
      readonly createdAt: string;
      readonly number: string;
      readonly primary: boolean | null;
    }>;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"ActivityForm_enquiry">;
  readonly " $fragmentType": "FeedNewForm_enquiry";
};
export type FeedNewForm_enquiry$key = {
  readonly " $data"?: FeedNewForm_enquiry$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedNewForm_enquiry">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedNewForm_enquiry",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ActivityForm_enquiry"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PhoneNumber",
          "kind": "LinkedField",
          "name": "phoneNumbers",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "number",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "primary",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "createdAt",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    }
  ],
  "type": "Enquiry",
  "abstractKey": null
};

(node as any).hash = "565b235f5d7b6fa9e51c5879fdeb9946";

export default node;
