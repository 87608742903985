/**
 * @generated SignedSource<<a89a572915f81736512d05be8922504f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PipelineLeadType = "buyer" | "financing" | "sales";
import { FragmentRefs } from "relay-runtime";
export type FeedVisit_activity$data = {
  readonly buyerLead?: {
    readonly __typename: "BuyerLead";
    readonly id: string;
  } | null;
  readonly canEdit?: boolean;
  readonly done?: boolean;
  readonly dueAt?: string | null;
  readonly enquiry?: {
    readonly __typename: "Enquiry";
    readonly id: string;
  } | null;
  readonly id: string;
  readonly lead?: {
    readonly __typename: "Lead";
    readonly id: string;
    readonly stage: {
      readonly pipeline: {
        readonly leadType: PipelineLeadType;
      } | null;
    } | null;
  } | null;
  readonly location?: string | null;
  readonly lot?: {
    readonly __typename: "Lot";
    readonly id: string;
  } | null;
  readonly note?: string | null;
  readonly startDate?: string | null;
  readonly user?: {
    readonly __typename: "User";
    readonly id: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"activityWorkflowDialog_activity">;
  readonly " $fragmentType": "FeedVisit_activity";
};
export type FeedVisit_activity$key = {
  readonly " $data"?: FeedVisit_activity$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedVisit_activity">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedVisit_activity",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "activityWorkflowDialog_activity"
    },
    (v0/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canEdit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "done",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dueAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "note",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "location",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Enquiry",
          "kind": "LinkedField",
          "name": "enquiry",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Lot",
          "kind": "LinkedField",
          "name": "lot",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Lead",
          "kind": "LinkedField",
          "name": "lead",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "LeadStage",
              "kind": "LinkedField",
              "name": "stage",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Pipeline",
                  "kind": "LinkedField",
                  "name": "pipeline",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "leadType",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BuyerLead",
          "kind": "LinkedField",
          "name": "buyerLead",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "type": "ActivityVisit",
      "abstractKey": null
    }
  ],
  "type": "Activity",
  "abstractKey": "__isActivity"
};
})();

(node as any).hash = "9b6f441bbe652506e14c065a75f36df5";

export default node;
