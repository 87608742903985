// This component allows to build a Hasura GraphQL query
// by clicking on a few buttons
// It reads the schema from the Hasura API and builds
// the interface based on that
// Language: typescript
import { type ReactElement, forwardRef, useEffect, useState } from 'react';

import { Delete, Layers, LayersClear } from '@mui/icons-material';
import {
  Alert,
  Badge,
  Box,
  Chip,
  type ChipProps,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { type IntrospectionField, type IntrospectionQuery } from 'graphql';

import { useLocale } from '../../../src/hooks/locale';
import { useAppData } from '../../providers/AppDataProvider';
import { useScopedSearchParams } from '../../utils/navigation';
import {
  type ConditionClause,
  type LogicalOperator,
  type WhereClause,
  isConditionClause,
  isWhereClause,
  parseWhereClause,
  revertWhereClause,
} from '../../utils/parseWhereClause';
import { DICTIONARIES } from '../DictionaryFilterForm';
import { FilterChip, type TableType } from '../FilterChip';
import { SelectChip } from '../SelectChip';

const compatibleTypes = [
  'String',
  'citext',
  'Boolean',
  'Float',
  'Int',
  'timestamptz',
  'date',
  'float8',
];

const getCompatibleField = (
  f: IntrospectionField,
  schema: IntrospectionQuery,
) => {
  let typeName = '';
  if ('name' in f.type) {
    typeName = f.type.name;
  }

  let ofType: any;
  if ('ofType' in f.type) {
    ofType = f.type.ofType;
  }
  if (compatibleTypes.includes(typeName)) {
    return { name: f.name, type: typeName };
  } else if (compatibleTypes.includes(ofType?.name)) {
    return { name: f.name, type: ofType?.name };
  } else if (ofType?.kind === 'ENUM') {
    const type = schema.__schema.types.find(t => t.name === ofType?.name);
    let enumValues;
    if (type && 'enumValues' in type) {
      enumValues = type.enumValues?.map(e => e.name);
    }
    return { name: f.name, type: 'ENUM', enumValues };
  }
  return null;
};

const getSpecialField = (f: IntrospectionField) => {
  const typeName =
    'name' in f.type
      ? f.type.name
      : 'ofType' in f.type
      ? f.type.ofType.name
      : '';

  const ofTypeOfTypeName =
    ('ofType' in f.type && f.type.ofType?.ofType?.name) ?? '';

  if (typeName === 'users') {
    return { name: f.name + '.id', type: typeName };
  } else if (ofTypeOfTypeName === 'places') {
    return { name: f.name + '.id', type: ofTypeOfTypeName };
  } else if (DICTIONARIES.includes(typeName)) {
    return { name: f.name + '.id', type: typeName };
  } else if (typeName === 'teams') {
    return { name: f.name + '.id', type: typeName };
  }

  return null;
};

// This should be removed once all enums are migrated
const getNotMigratedEnums = (f: IntrospectionField) => {
  if ('name' in f.type) {
    const typeName = f.type.name ?? '';

    if (typeName === 'activity_type') {
      return { name: f.name, type: 'String' };
    }
  }
  return null;
};

// Helper function to validate columns against a list (whitelist or blacklist)
const validateColumns = (
  fields: any,
  columns: string[],
  table: string,
  type: string,
) => {
  const tableColumns = fields.map((f: any) => f.name);
  columns.forEach((c: string) => {
    if (!tableColumns.includes(c)) {
      throw new Error(
        `Column ${c} does not exist in table ${table} or is not ${type}`,
      );
    }
  });
};

type GetFieldsProps = {
  schema?: IntrospectionQuery | null;
  table: string;
  whitelistedColumns?: string[];
  blacklistedColumn?: string[];
};

// Main function
export const getFields = ({
  schema,
  table,
  whitelistedColumns,
  blacklistedColumn,
}: GetFieldsProps) => {
  if (!schema) {
    return [];
  }

  const type = schema.__schema.types.find(t => t.name === table);
  if (type == null || !('fields' in type)) {
    return [];
  }
  let fields = type.fields
    .map(
      f =>
        getCompatibleField(f, schema) ??
        getSpecialField(f) ??
        getNotMigratedEnums(f) ??
        null,
    )
    .filter(f => f) as Array<{
    name: string;
    type: string;
    enumValues?: string[];
  }>;

  if (whitelistedColumns) {
    validateColumns(fields, whitelistedColumns, table, 'whitelisted');
  }

  if (blacklistedColumn) {
    validateColumns(fields, blacklistedColumn, table, 'blacklisted');
  }

  if (whitelistedColumns) {
    fields = fields.filter(f => whitelistedColumns.includes(f.name));
    fields.sort((a, b) => {
      const aIndex = whitelistedColumns.indexOf(a.name);
      const bIndex = whitelistedColumns.indexOf(b.name);
      return aIndex - bIndex;
    });
  }

  if (blacklistedColumn) {
    fields = fields.filter(f => !blacklistedColumn.includes(f.name));
  }

  return fields;
};

const ActionChip = forwardRef<any, ChipProps>((props, ref) => {
  return (
    <Chip
      ref={ref}
      {...props}
      size="small"
      sx={!props.label ? { '& .MuiChip-label': { paddingRight: 0 } } : {}}
    />
  );
});

const ObjectOperatorChip = ({
  initialSelected,
  onChange,
}: {
  initialSelected: '_not' | '';
  onChange: (options: { label: string; value: string }) => void;
}) => {
  const { t } = useLocale();

  return (
    <>
      {t('there')}
      <SelectChip
        options={[
          { label: t('is not'), value: '_not' },
          { label: t('is'), value: '' },
        ]}
        initialSelected={initialSelected ?? ''}
        onChange={onChange}
      />
    </>
  );
};

const GroupOperatorChip = ({
  initialSelected,
  onChange,
  showLongOptions,
}: {
  initialSelected: LogicalOperator;
  onChange: (options: { label: string; value: string }) => void;
  showLongOptions?: boolean;
}) => {
  const { t } = useLocale();

  const longOptions = [
    { label: t('all of'), value: '_and' },
    { label: t('any of'), value: '_or' },
    { label: t('not all of'), value: '_not._and' },
    { label: t('none of'), value: '_not._or' },
  ];

  const shortOptions = [
    { label: t('all of short'), value: '_and' },
    { label: t('any of short'), value: '_or' },
    { label: t('not all of short'), value: '_not._and' },
    { label: t('none of short'), value: '_not._or' },
  ];

  return (
    <SelectChip
      options={showLongOptions ? longOptions : shortOptions}
      initialSelected={initialSelected ?? '_and'}
      onChange={onChange}
    />
  );
};

const GroupeBadge = ({
  parentOperator,
  path,
}: {
  parentOperator: LogicalOperator;
  path: (number | string)[];
}) => {
  const { t } = useLocale();

  return (
    <Stack width="50px" flexDirection="column">
      <Stack pt="2px">
        {path.length > 2 && <Divider />}
        <Badge
          badgeContent={parentOperator.includes('_and') ? t('AND') : t('OR')}
          color="info"
          sx={{
            width: '100%',
            '& .MuiBadge-badge': {
              width: '100%',
              position: 'relative',
              transform: 'unset',
              alignSelf: 'center',
            },
          }}
        />
      </Stack>
      <Divider
        orientation="vertical"
        sx={{ mx: 'auto', height: 'calc(100% - 30px)' }}
      />
    </Stack>
  );
};

export type FiltersTableDef = {
  name: string;
  label: string;
  relationshipPath: string | null;
  fields: GetFieldsProps;
};

type TableFiltersProps = {
  tables: FiltersTableDef[];
  defaultOpenFilters?: boolean;
  actions?: ReactElement;
  queryParamsScope?: string;
};

export const TableFilters = (props: TableFiltersProps) => {
  const { tables: tablesDef, defaultOpenFilters } = props;
  const [tables, setTables] = useState<TableType[]>([]);
  const { schema } = useAppData();

  useEffect(() => {
    const tables: TableType[] = tablesDef.map(tableDef => ({
      ...tableDef,
      fields: getFields({ ...tableDef.fields, schema }),
    }));

    setTables(tables);
  }, [schema, tablesDef]);

  const { t } = useLocale();
  const [searchParams, setSearchParams] = useScopedSearchParams(
    props.queryParamsScope,
  );

  let filters: WhereClause;
  try {
    filters = parseWhereClause(
      JSON.parse(searchParams.get('where') ?? '{ "_and": [] }'),
    ) as WhereClause;
  } catch (e) {
    return (
      <Alert severity="error" sx={{ m: 2, mt: 1 }}>
        {t('Where clause not supported by advanced filters v1')}
      </Alert>
    );
  }

  const handleGroupOperatorChange = (
    path: (number | string)[],
    operator?: LogicalOperator,
    object_operator?: '_not' | null,
  ) => {
    const newConditions = { ...filters };
    let current: any = newConditions;

    // Traverse the path to get to the desired node
    for (const segment of path) {
      current = current[segment];
    }

    // Update the operator if provided
    if (operator !== undefined) {
      current.operator = operator;
    }

    // Update the object_operator if provided
    if (object_operator !== undefined) {
      current.object_operator = object_operator;
    }

    // If the new conditions meet criteria, set the search params
    if (isWhereClause(newConditions as WhereClause)) {
      searchParams.set(
        'where',
        JSON.stringify(revertWhereClause(newConditions)),
      );
      setSearchParams(searchParams);
    }
  };

  const handleGroupClause = (
    path: (number | string)[],
    object?: string | null,
  ) => {
    const newConditions = { ...filters };
    let current: any = newConditions;

    const lastKey = path[path.length - 1];
    const leadingPath = path.slice(0, path.length - 1);

    // Traverse to the desired position in the conditions tree
    for (const segment of leadingPath) {
      current = (current as any)[segment];
    }

    // wrap into a group
    const newGroup = {
      operator: '_and',
      conditions: [current[lastKey]],
      object_operator: null,
      object: object ?? null,
    };
    current[lastKey] = newGroup;

    searchParams.set('where', JSON.stringify(revertWhereClause(newConditions)));
    setSearchParams(searchParams);
  };

  const handleInsertGroup = (
    path: (number | string)[],
    conditions?: [ConditionClause] | null,
    object?: string | null,
  ) => {
    const newConditions = { ...filters };
    // insert group at path
    let current: any = newConditions;
    for (let i = 0; i < path.length; i++) {
      if (i === path.length - 1) {
        const newGroup = {
          operator: '_and',
          conditions: conditions ?? [],
          object_operator: null,
          object: object ?? null,
        };

        current.push(newGroup);
      } else {
        current = current[path[i]];
      }
    }

    searchParams.set('where', JSON.stringify(revertWhereClause(newConditions)));
    setSearchParams(searchParams);
  };

  const buildCondition = (
    objectPath: string | null,
    condition: ConditionClause | WhereClause,
    currentObject?: string | null,
  ): WhereClause | ConditionClause => {
    // Prepare conditions for insertion
    let newCondition: WhereClause | ConditionClause = condition;
    // Build condition groups from objectPath
    // remove all elements before {{currentObject}}.
    const objectPaths = objectPath ? objectPath.split('.') : [];
    if (currentObject) {
      const index = objectPaths.indexOf(currentObject);
      objectPaths.splice(0, index + 1);
    }

    // otherwise build nested condition
    // Loop through objectPath in reverse
    for (const objectPath of objectPaths.reverse()) {
      newCondition = {
        operator: '_and',
        conditions: [newCondition],
        object_operator: null,
        object: objectPath,
      };
    }
    return newCondition;
  };

  const getObjectAtPath = (path: (number | string)[]) => {
    // get nested object at path
    let current: any = filters;
    for (const pathSegment of path) {
      current = current[pathSegment];
    }
    return current;
  };

  const handleConditionChange = (
    path: (number | string)[],
    condition: ConditionClause,
    object: string | null,
  ) => {
    let newConditions = { ...filters };
    let current: any = newConditions;

    // This a complexe function we need to do the following:
    // 1. If condition exists at path replace it
    // 2. If condition does not exist at path insert it

    // Let's build de condition
    const parentObject = getParentWhereClause(path);
    const newCondition = buildCondition(
      object,
      condition,
      parentObject?.object,
    );
    const objectAtPath = getObjectAtPath(path);

    // If last item of path is a number it's condition, replace the condition at that index
    if (objectAtPath && isConditionClause(objectAtPath)) {
      // Traverse to the array of conditions
      for (let i = 0; i < path.length - 1; i++) {
        current = current[path[i]];
      }
      current[path[path.length - 1] as number] = newCondition;
    } else {
      // Traverse to the where clause
      for (let i = 0; i < path.length - 2; i++) {
        current = current[path[i]];
      }

      // If the clause conditions if empty remplace it with the new condition
      if (current.conditions.length === 0) {
        // if its the first condition and obect is null add a a group
        if (
          isWhereClause(newCondition) &&
          current.object == null &&
          newCondition.object != null
        ) {
          current.conditions = newCondition?.conditions;
          current.object = newCondition?.object;
        } else {
          current.conditions = [newCondition];
        }
        // Edge for first condition. wrap in group if object is not null
        if (
          newConditions.object != null ||
          !isWhereClause(newConditions?.conditions[0])
        ) {
          newConditions = {
            operator: newConditions.operator,
            conditions: [newConditions],
            object_operator: null,
          } as WhereClause;
        }
      } else {
        // If the clause conditions is not empty, insert the condition at the end
        current.conditions.push(newCondition);
      }
    }

    searchParams.set('where', JSON.stringify(revertWhereClause(newConditions)));
    setSearchParams(searchParams);
  };

  const handleDelete = (path: (number | string)[]) => {
    const newConditions = { ...filters };
    // delete the condition or group at the path in newConditions
    let current: any = newConditions;
    for (let i = 0; i < path.length; i++) {
      if (i === path.length - 1) {
        // if group delete the group
        if (isWhereClause(current)) {
          current.conditions.splice(path[i] as number, 1);
        } else {
          // if condition delete the condition
          current.splice(path[i], 1);
        }
      } else {
        current = current[path[i]];
      }
    }

    searchParams.set('where', JSON.stringify(revertWhereClause(newConditions)));
    setSearchParams(searchParams);
  };

  const handleUngroup = (path: (number | string)[]) => {
    const newConditions = { ...filters };
    // ungroup the conditions at the path
    // move the conditions up one level
    let current: any = newConditions;
    for (let i = 0; i < path.length; i++) {
      if (i === path.length - 1) {
        // delete object at path
        const conditions = current[path[i]].conditions;
        current.splice(path[i], 1, ...conditions);
      } else {
        current = current[path[i]];
      }
    }

    searchParams.set('where', JSON.stringify(revertWhereClause(newConditions)));
    setSearchParams(searchParams);
  };

  const getParentWhereClause = (
    path: (number | string)[] = [],
  ): WhereClause => {
    let parentWhereClause;
    let current: any = filters;
    for (let i = 0; i < path.length - 1; i++) {
      if (i === path.length - 2) {
        parentWhereClause = current;
      } else {
        current = current[path[i]];
      }
    }
    return parentWhereClause;
  };

  // Generates group badges
  const generateGroupBadges = (path: (string | number)[]) => {
    const parentOperator = getParentWhereClause(path)?.operator ?? '_and';

    // if its the first where clause, add a label otherwise add a group operator
    if (path.length === 2 && path[1] === 0) {
      return (
        <Stack
          key={'where-badge-' + path.join('-')}
          height="24px"
          alignSelf="center"
        >
          <Typography variant="body2">{t('Where:')}</Typography>
          <Divider
            orientation="vertical"
            sx={{ mx: 'auto', height: 'calc(100% - 30px)' }}
          />
        </Stack>
      );
    }
    return (
      <GroupeBadge
        key={'group-badge-' + path.join('-')}
        parentOperator={parentOperator}
        path={path}
      />
    );
  };

  // Generates chips for the generateGroupOperators type.
  const generateGroupOperators = (
    clause: WhereClause,
    path: (number | string)[] = [],
  ): ReactElement[] => {
    const groupOperatorChips: ReactElement[] = [];

    groupOperatorChips.push(
      <Stack
        direction="row"
        alignItems="center"
        gap={0.5}
        key={'group-' + path.join('-')}
      >
        {clause.object && (
          <ObjectOperatorChip
            initialSelected={clause.object_operator ?? ''}
            onChange={({ value }) => {
              handleGroupOperatorChange(
                path,
                undefined,
                value === '_not' ? '_not' : null,
              );
            }}
          />
        )}
        {
          tables.find(
            table =>
              (table.relationshipPath ?? '').split('.').slice(-1)[0] ===
                clause.object && table.label != null,
          )?.label
        }{' '}
        {clause.object && t('matching')}
        <GroupOperatorChip
          onChange={({ value }) => {
            handleGroupOperatorChange(path, value as LogicalOperator);
          }}
          initialSelected={clause.operator}
        />
      </Stack>,
    );
    if (
      (clause.conditions[0] as ConditionClause)?.column == null &&
      clause.conditions.length !== 0
    ) {
      // Ungroup or delete only possible if there are no columns
      if (clause.object == null) {
        groupOperatorChips.push(
          generateActionButtons(path, clause, false, {
            ungroup: true,
            delete: true,
          }),
        );
      }
    }
    return groupOperatorChips;
  };

  const generateConditionChip = (
    path: (number | string)[],
    condition: ConditionClause,
    clause: WhereClause,
    tables: any,
  ): ReactElement => (
    <FilterChip
      key={path.join('-')}
      onChange={(condition, object) =>
        handleConditionChange(path, condition, object)
      }
      onDelete={() => handleDelete(path)}
      tables={tables}
      tableName={clause.object}
      initialCondition={condition}
      defaultOpen={defaultOpenFilters}
    />
  );

  const generateActionButtons = (
    path: (number | string)[],
    clause: WhereClause,
    displayLabel?: boolean,
    buttons?: {
      add?: boolean;
      delete?: boolean;
      group?: boolean;
      ungroup?: boolean;
      addgroup?: boolean;
    },
  ): ReactElement => (
    <Stack
      direction="row"
      key={
        'add-condition-group-' +
        [...path].join('-') +
        [buttons?.add, buttons?.delete, buttons?.group].join('-')
      }
      gap={0.5}
    >
      {buttons?.add && (
        <Tooltip placement="top" arrow title={t('Add filter')}>
          <div>
            <FilterChip
              tables={tables}
              tableName={clause.object}
              label={displayLabel ? t('Add filter') : undefined}
              onChange={(condition, object) => {
                handleConditionChange(
                  [...path, 'conditions', clause.conditions.length],
                  condition,
                  object,
                );
              }}
              defaultOpen={defaultOpenFilters}
            />
          </div>
        </Tooltip>
      )}
      {buttons?.group && (
        <Tooltip placement="top" arrow title={t('Wrap in group')}>
          <ActionChip
            icon={<Layers />}
            label={displayLabel ? t('Wrap in group') : undefined}
            onClick={() => handleGroupClause(path)}
          />
        </Tooltip>
      )}
      {buttons?.ungroup && (
        <Tooltip placement="top" arrow title={t('Unwrap group')}>
          <ActionChip
            icon={<LayersClear />}
            label={displayLabel ? t('Unwrap group') : undefined}
            onClick={() => handleUngroup(path)}
          />
        </Tooltip>
      )}
      {buttons?.addgroup && (
        <Tooltip placement="top" arrow title={t('Add group')}>
          <ActionChip
            icon={<Layers />}
            label={displayLabel ? t('Add group') : undefined}
            onClick={() =>
              // add group at the end of the conditions
              handleInsertGroup([
                ...path,
                'conditions',
                clause.conditions.length,
              ])
            }
          />
        </Tooltip>
      )}
      {buttons?.delete && (
        <Tooltip placement="top" arrow title={t('Delete')}>
          <ActionChip
            icon={<Delete />}
            label={displayLabel ? t('Delete') : undefined}
            onClick={() => handleDelete(path)}
          />
        </Tooltip>
      )}
    </Stack>
  );

  const generateChips = (
    clause: WhereClause | ConditionClause,
    path: (number | string)[] = [],
  ): any => {
    const leftItems: ReactElement[] = [];
    const chips: ReactElement[] = [];

    if (isWhereClause(clause) && path.length > 0) {
      leftItems.push(generateGroupBadges(path));
      chips.push(...generateGroupOperators(clause, path));
    }

    if (isWhereClause(clause)) {
      clause.conditions.forEach((condition, i) => {
        const conditionPath = [...path, 'conditions', i];
        if (isWhereClause(condition)) {
          chips.push(generateChips(condition, conditionPath));
        } else if (isConditionClause(condition)) {
          chips.push(
            <Stack
              direction="row"
              gap={0.5}
              key={'condition-' + conditionPath.join('-')}
            >
              {generateConditionChip(conditionPath, condition, clause, tables)}
            </Stack>,
          );
        }
      });

      // If there are no conditions that is a where clause, add a group button
      if (
        filters.conditions.length !== 0 &&
        clause.conditions.every(c => !isWhereClause(c))
      ) {
        chips.push(
          generateActionButtons(path, clause, false, {
            // only allow group from root
            group: getParentWhereClause(path)?.object == null,
            delete: true,
            add: true,
          }),
        );
      }
      // If group is of a nested object, add a group button and a delete button
      if (
        filters.conditions.length !== 0 &&
        !clause.conditions.every(c => !isWhereClause(c)) &&
        clause.object != null
      ) {
        // only allow group from root
        chips.push(
          generateActionButtons(path, clause, false, {
            group: getParentWhereClause(path)?.object == null,
            delete: true,
          }),
        );
      }
      // If any of the conditions is a where clause, add a group button
      // If the parent is nested don't add a group button
      if (
        filters.conditions.length === 0 ||
        (clause.conditions.some(c => isWhereClause(c)) && clause.object == null)
      ) {
        chips.push(
          generateActionButtons(path, clause, true, {
            add: true,
            addgroup: true,
          }),
        );
      }
    }

    if (leftItems.length === 0) {
      return chips;
    }

    return (
      <Stack
        direction="row"
        spacing={1}
        width="100%"
        key={'chips-container-' + path.join('-')}
      >
        <Stack minWidth="50px" alignItems="center">
          {leftItems}
        </Stack>
        <Stack
          direction="row"
          flexWrap="wrap"
          maxWidth="calc(100% - 50px)"
          width="100%"
          gap={0.5}
        >
          {chips.map(chip =>
            // If chip doesn't have a key or key includes 'container', return as is.
            typeof chip?.key === 'string' && chip.key.includes('container') ? (
              chip
            ) : (
              <Box key={chip.key} maxWidth="100%" alignSelf="center">
                {chip}
              </Box>
            ),
          )}
        </Stack>
      </Stack>
    );
  };

  return (
    <>
      <Divider sx={{ opacity: 0.5 }} />
      <Stack>
        <Stack direction="row" px={2} py={1} alignItems="center" gap={0.5}>
          <Typography variant="body2">
            {t('Find {{table}} matching', {
              table: tables[0]?.label,
            })}
          </Typography>
          <GroupOperatorChip
            initialSelected={filters.operator ?? '_and'}
            onChange={({ value }) => {
              handleGroupOperatorChange([], value as LogicalOperator);
            }}
            showLongOptions
          />
          <Typography variant="body2">{t('the following filters:')}</Typography>
        </Stack>
        <Divider sx={{ opacity: 0.5 }} />
        <Stack direction="column" px={2} py={1} gap={0.5}>
          {generateChips(filters)}
        </Stack>
      </Stack>
    </>
  );
};
