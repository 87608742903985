import type { Currency, IntlLocale } from '../locale';

export const formatPrice = (
  value: number | null | undefined,
  locale: IntlLocale,
  currency: Currency,
  maximumFractionDigits = 0,
  emptyPlaceholder = '',
) => {
  const localeToUse = currency === 'CHF' ? 'de-CH' : locale;

  return value != null
    ? value.toLocaleString(localeToUse, {
        maximumFractionDigits,
        minimumFractionDigits: 0,
        currency,
        style: 'currency',
      })
    : emptyPlaceholder;
};
