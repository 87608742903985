// @flow

import * as React from 'react';

import { Button, CircularProgress, Paper, Tab, Tabs } from '@material-ui/core';
import { GoogleMarker, useGoogleApi } from '@realadvisor/google-maps';
import { graphql, useFragment, useMutation } from 'react-relay';
import { Box, Flex } from 'react-system';
import { Map, useMap } from 'rgm';

import { GooglePoiMap } from '../../controls/LocationMap';
import { LocationMarker } from '../../controls/mapbox';
import { useLocale } from '../../hooks/locale';
import { useTheme } from '../../hooks/theme';
import { GlobeOutline } from '../../icons/globe-outline';
import { LocationPinOutline } from '../../icons/location-pin-outline';
import { MapOutline } from '../../icons/map-outline';
import { PoiMapbox } from '../../shared/poi-mapbox';

import type { LeadDetailsMap_property$key } from './__generated__/LeadDetailsMap_property.graphql';
import type { LeadDetailsMapUpsertPropertyMutation } from './__generated__/LeadDetailsMapUpsertPropertyMutation.graphql';

type Props = {|
  property: LeadDetailsMap_property$key,
  tab: 'map' | 'satellite' | 'mapbox',
  previewMode: boolean,
|};

const SaveButton = ({ children, loading, ...props }) => {
  return (
    <Button {...props} variant="contained" css={{ position: 'relative' }}>
      {loading && (
        <CircularProgress
          disableShrink={true}
          size={24}
          css={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            marginLeft: -12,
            marginTop: -12,
          }}
        />
      )}
      {children}
    </Button>
  );
};

const LocationEditor = ({ marker }) => {
  const { t } = useLocale();
  const [savedMarker, setSavedMarker] = React.useState(null);
  const [updateProperty, updating] =
    useMutation<LeadDetailsMapUpsertPropertyMutation>(
      graphql`
        mutation LeadDetailsMapUpsertPropertyMutation(
          $input: UpsertPropertyInput!
        ) {
          upsertProperty(input: $input) {
            property {
              ...LeadDetailsMap_property
              # update registry owners
              location {
                plot {
                  owners {
                    names
                  }
                }
              }
            }
          }
        }
      `,
    );

  const value = savedMarker ? savedMarker : marker;

  const cancel = () => {
    setSavedMarker(null);
  };

  const mapRef = React.useRef(null);

  return (
    <Flex style={{ height: '100%', position: 'relative' }}>
      <PoiMapbox
        mapRef={mapRef}
        lat={marker.lat}
        lng={marker.lng}
        zoom={16}
        withSearch={false}
      />
      <LocationMarker
        mapRef={mapRef}
        lat={value.lat}
        lng={value.lng}
        onDragend={({ latLng }) => setSavedMarker(latLng)}
      />

      <Flex css={{ position: 'absolute', bottom: 8, right: 40 }}>
        <Box p={2}>
          <Button
            variant="contained"
            disabled={savedMarker == null || updating}
            onClick={cancel}
          >
            {t('cancel')}
          </Button>
        </Box>

        <Box p={2}>
          <SaveButton
            loading={updating}
            disabled={savedMarker == null || updating}
            onClick={() => {
              if (savedMarker) {
                const { lat, lng } = savedMarker;

                updateProperty({
                  variables: {
                    input: {
                      property: { id: marker.id, lat, lng },
                    },
                  },
                  onCompleted: cancel,
                });
              }
            }}
          >
            {t('save')}
          </SaveButton>
        </Box>
      </Flex>
    </Flex>
  );
};

const GOOD_ZOOM_FOR_TILT = 19;

const waitForVisibility = (element, callback) => {
  let animationFrameId = null;
  const run = () => {
    if (element.offsetParent == null) {
      animationFrameId = requestAnimationFrame(run);
    } else {
      callback();
    }
  };
  animationFrameId = requestAnimationFrame(run);
  return () => {
    if (animationFrameId != null) {
      cancelAnimationFrame(animationFrameId);
    }
  };
};

type MarkerProps = {|
  lat: number,
  lng: number,
|};

const GMarker = ({ lat, lng }: MarkerProps) => {
  const { api, map } = useMap();

  React.useEffect(() => {
    if (api) {
      const marker = new api.Marker({
        map,
        position: {
          lat,
          lng,
        },
      });

      return () => {
        marker.setMap(null);
      };
    }
  }, [api, map, lat, lng]);

  return null;
};

export const LeadDetailsMap = (props: Props): React.Node => {
  const { colors, depth, borderRadius, boxShadow } = useTheme();
  const property = useFragment(
    graphql`
      fragment LeadDetailsMap_property on Property {
        id
        lat
        lng
        googleAddress {
          lat
          lng
        }
      }
    `,
    props.property,
  );

  const [tab, setTab] = React.useState(props.tab);

  const id = property.id;

  const lat = property.lat ?? property.googleAddress?.lat;
  const lng = property.lng ?? property.googleAddress?.lng;

  const marker =
    lat != null && lng != null ? { id, lat, lng } : { id, lat: 0, lng: 0 };

  // TODO: remove when we are on React 18+
  // The problem is that effects run before loading completed and display none is removed
  // It's known React bug https://github.com/facebook/react/issues/14536
  const renderCheckRef = React.useRef<null | HTMLElement>(null);
  const [shouldRender, setShouldRender] = React.useState(false);
  React.useEffect(() => {
    const element = renderCheckRef.current;
    if (element != null) {
      return waitForVisibility(element, () => {
        setShouldRender(true);
      });
    }
  }, []);

  const mapApi = useGoogleApi(true);

  const MAP_OPTIONS = {
    zoom: 10,
    center: {
      lat: marker.lat,
      lng: marker.lng,
    },
    gestureHandling: 'greedy',
    clickableIcons: false,
  };

  return (
    <Paper
      css={{ height: '25vh', minHeight: 300, position: 'relative' }}
      ref={renderCheckRef}
    >
      {props.previewMode ? (
        <Flex height="100%" css={{ position: 'relative' }}>
          <React.Suspense fallback={null}>
            <GooglePoiMap
              mapType="satellite"
              latitude={marker.lat}
              longitude={marker.lng}
              previewMode={true}
            >
              {null}
            </GooglePoiMap>
          </React.Suspense>
        </Flex>
      ) : (
        <>
          <Tabs
            orientation="vertical"
            css={{
              color: colors.black,
              background: colors.white,
              boxShadow: boxShadow.elevation1,
              borderRadius: borderRadius.small,
              position: 'absolute',
              bottom: 40,
              left: 10,
              minHeight: 40,
              zIndex: depth.base,
              '.MuiTab-root': {
                height: 40,
                minHeight: 40,
                minWidth: 22,
                width: 40,
                maxWidth: 40,
              },
              '.MuiTab-root:not(:last-child)': {
                borderBottom: `1px solid ${colors.borderMain}`,
              },
              '.MuiTabs-indicator': { backgroundColor: 'transparent' },
              '.Mui-selected': { color: colors.primaryMain },
            }}
            value={tab}
            onChange={(event, value) => setTab(value)}
          >
            <Tab value="map" icon={<MapOutline />} aria-label="Map" />
            <Tab
              value="satellite"
              icon={<GlobeOutline />}
              aria-label="Satellite"
            />
            <Tab
              value="mapbox"
              icon={<LocationPinOutline />}
              aria-label="Mapbox"
            />
          </Tabs>
          {tab === 'map' && mapApi && (
            <Box height="100%">
              <Map api={mapApi} options={MAP_OPTIONS}>
                <GMarker
                  lat={MAP_OPTIONS.center.lat}
                  lng={MAP_OPTIONS.center.lng}
                />
              </Map>
            </Box>
          )}
          {/* check initial tab */}
          {tab === 'satellite' && shouldRender && (
            <Flex height="100%" css={{ position: 'relative' }}>
              <React.Suspense fallback={null}>
                <GooglePoiMap
                  mapType="satellite"
                  latitude={marker.lat}
                  longitude={marker.lng}
                  zoom={GOOD_ZOOM_FOR_TILT}
                >
                  <GoogleMarker lat={marker.lat} lng={marker.lng} />
                </GooglePoiMap>
              </React.Suspense>
            </Flex>
          )}
          {tab === 'mapbox' && <LocationEditor marker={marker} />}
        </>
      )}
    </Paper>
  );
};
