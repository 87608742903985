/**
 * @generated SignedSource<<4f6f4739a9b7f71903927b69914aef59>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type appraisalGraph_appraisal$fragmentType: FragmentType;
export type appraisalGraph_appraisal$data = {|
  +iazi: ?{|
    +max: ?number,
    +min: ?number,
    +value: ?number,
  |},
  +iazi_cv: ?{|
    +max: ?number,
    +min: ?number,
    +value: ?number,
  |},
  +pricehubble: ?{|
    +max: ?number,
    +min: ?number,
    +value: ?number,
  |},
  +propertydata: ?{|
    +max: ?number,
    +min: ?number,
    +value: ?number,
  |},
  +realadvisor: ?{|
    +max: ?number,
    +min: ?number,
    +value: ?number,
  |},
  +realadvisor_listing: ?{|
    +max: ?number,
    +min: ?number,
    +value: ?number,
  |},
  +realadvisor_naive_listings: ?{|
    +max: ?number,
    +min: ?number,
    +value: ?number,
  |},
  +realadvisor_omni_meta: ?{|
    +max: ?number,
    +min: ?number,
    +value: ?number,
  |},
  +realadvisor_open_data_transaction: ?{|
    +max: ?number,
    +min: ?number,
    +value: ?number,
  |},
  +realadvisor_perceived: ?{|
    +max: ?number,
    +min: ?number,
    +value: ?number,
  |},
  +realadvisor_transaction: ?{|
    +max: ?number,
    +min: ?number,
    +value: ?number,
  |},
  +wup: ?{|
    +max: ?number,
    +min: ?number,
    +value: ?number,
  |},
  +$fragmentType: appraisalGraph_appraisal$fragmentType,
|};
export type appraisalGraph_appraisal$key = {
  +$data?: appraisalGraph_appraisal$data,
  +$fragmentSpreads: appraisalGraph_appraisal$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "min",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "max",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "appraisalGraph_appraisal",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "IaziAppraisal",
      "kind": "LinkedField",
      "name": "iazi",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "IaziAppraisal",
      "kind": "LinkedField",
      "name": "iazi_cv",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Pricehubble",
      "kind": "LinkedField",
      "name": "pricehubble",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WupAppraisal",
      "kind": "LinkedField",
      "name": "wup",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PropertydataAppraisal",
      "kind": "LinkedField",
      "name": "propertydata",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RealadvisorListingAppraisal",
      "kind": "LinkedField",
      "name": "realadvisor_listing",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RealadvisorNaiveListingsAppraisal",
      "kind": "LinkedField",
      "name": "realadvisor_naive_listings",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RealadvisorOmniMetaAppraisal",
      "kind": "LinkedField",
      "name": "realadvisor_omni_meta",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RealadvisorTransactionAppraisal",
      "kind": "LinkedField",
      "name": "realadvisor_transaction",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RealadvisorOpenDataTransactionAppraisal",
      "kind": "LinkedField",
      "name": "realadvisor_open_data_transaction",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RealadvisorPerceivedAppraisal",
      "kind": "LinkedField",
      "name": "realadvisor_perceived",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RealadvisorAppraisal",
      "kind": "LinkedField",
      "name": "realadvisor",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Appraisal",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "88f7aa13ac510ac89bbcef92b979f00c";

export default node;
