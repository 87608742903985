import { type PropsWithChildren, useState } from 'react';

import { type ApolloError } from '@apollo/client';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PhoneEnabledOutlinedIcon from '@mui/icons-material/PhoneEnabledOutlined';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import copy from 'clipboard-copy';
import { Link } from 'react-router-dom';

import { toGlobalId } from '../../shared/global-id';
import { useLocale } from '../../src/hooks/locale';
import type {
  UserCard_UserFragment,
  Users_Bool_Exp,
  Users_Order_By,
} from '../__generated__/graphql';
import { formatPhone } from '../utils/formatting';

import { MutationErrorModal } from './MutationErrorModal';
import { UserSelect } from './user-select/UserSelect';
import { UserAvatar } from './UserAvatar';
import { UserCard, UserCardSkeleton } from './UserCard';

type TEditUserInputProps = {
  orderResultsBy?: Users_Order_By[];
  filters?: Users_Bool_Exp;
};

interface UserActionCardProps {
  user?: UserCard_UserFragment | null;
  canDelete?: boolean;
  useLegacyLinks?: boolean;
  editUserInputProps?: TEditUserInputProps;
  secondaryText?: React.ReactNode;
  isLoading?: boolean;
  onUserChanged: (
    userId: string | null,
    onError: (err: ApolloError) => void,
  ) => Promise<any>;
}

export const EditableUserCard: React.FC<
  {
    selectedUserId?: string;
    isEditing: boolean;
    onChange: (userId: string) => Promise<any>;
    onCancel: () => void;
  } & TEditUserInputProps &
    PropsWithChildren
> = ({
  selectedUserId,
  children,
  isEditing,
  onChange,
  onCancel,
  orderResultsBy,
  filters,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useLocale();

  return isEditing ? (
    <Stack sx={{ position: 'relative' }} direction="row" spacing={1}>
      <UserSelect
        sx={{ flexGrow: 1 }}
        disabled={isLoading}
        userId={selectedUserId}
        orderResultsBy={orderResultsBy}
        filters={filters}
        autoFocus={true}
        onChange={async userId => {
          if (userId == null) {
            return;
          }

          setIsLoading(true);
          await onChange(userId);
          setIsLoading(false);
        }}
      />
      <Button
        variant="outlined"
        onClick={onCancel}
        disabled={isLoading}
        size="small"
      >
        {t('Cancel')}
      </Button>

      {isLoading && (
        <CircularProgress
          size={30}
          sx={{
            zIndex: 1,
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-15px !important',
            marginLeft: '-15px !important',
          }}
          disableShrink
        />
      )}
    </Stack>
  ) : (
    <>{children}</>
  );
};

export const UserActionCard: React.FC<UserActionCardProps> = ({
  user,
  onUserChanged,
  canDelete = false,
  useLegacyLinks = true,
  editUserInputProps = {},
  secondaryText,
  isLoading = false,
}) => {
  const { t } = useLocale();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [toggleError, setToggleError] = useState<null | ApolloError>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [displayTooltip, setDisplayTooltip] = useState(false);

  if (isLoading) {
    return <UserCardSkeleton condensed hasSecondaryText />;
  }

  const open = Boolean(anchorEl);
  const primaryPhone =
    user?.phone_numbers.find(p => p.primary) ?? user?.phone_numbers[0];
  const primaryEmail = user?.emails.find(p => p.primary) ?? user?.emails[0];
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const updateUser = async (userId: string | null) => {
    await onUserChanged(userId, setToggleError);
    setIsEditing(false);
  };

  if (user == null) {
    return (
      <EditableUserCard
        isEditing={isEditing}
        onChange={updateUser}
        onCancel={() => setIsEditing(false)}
        {...editUserInputProps}
      >
        <List dense={true} sx={{ p: 0 }}>
          <ListItem sx={{ py: 0 }} disableGutters>
            <ListItemButton sx={{ p: 0 }} onClick={() => setIsEditing(true)}>
              <ListItemAvatar>
                <UserAvatar user={null} size={40} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography variant="body1">{t('Assign an user')}</Typography>
                }
              />
            </ListItemButton>
          </ListItem>
        </List>
      </EditableUserCard>
    );
  }

  const userLink = useLegacyLinks
    ? `/v1/users/${toGlobalId('User', user.id)}`
    : `/users/${user.id}`;

  return (
    <>
      <EditableUserCard
        selectedUserId={user.id}
        isEditing={isEditing}
        onChange={updateUser}
        onCancel={() => setIsEditing(false)}
        {...editUserInputProps}
      >
        <Box sx={{ position: 'relative' }}>
          <UserCard
            user={user}
            condensed
            secondaryText={secondaryText}
            userActions={
              <>
                {primaryPhone != null && (
                  <IconButton
                    href={`tel:${primaryPhone.number}`}
                    title={formatPhone(primaryPhone.number) ?? ''}
                  >
                    <PhoneEnabledOutlinedIcon />
                  </IconButton>
                )}
                {primaryEmail != null && (
                  <IconButton
                    href={`mailto:${primaryEmail.email}`}
                    title={primaryEmail.email}
                  >
                    <EmailOutlinedIcon />
                  </IconButton>
                )}
                <IconButton edge="end" onClick={handleOpenMenu}>
                  <MoreVertIcon />
                </IconButton>
              </>
            }
          />
          {isDeleting && (
            <Backdrop open={true} sx={{ zIndex: 1, position: 'absolute' }}>
              <CircularProgress disableShrink />
            </Backdrop>
          )}
        </Box>
      </EditableUserCard>
      <Menu
        open={open}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        anchorEl={anchorEl}
      >
        {user.phone_numbers.length > 0 && (
          <>
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={() => setDisplayTooltip(false)}
              open={displayTooltip}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={t('Copied to clipboard')}
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -50],
                      },
                    },
                  ],
                },
              }}
            >
              <ListSubheader>{t('Phone Numbers')}</ListSubheader>
            </Tooltip>
            {user.phone_numbers.map(phone => (
              <MenuItem
                key={phone.number}
                onClick={() => {
                  copy(phone.number);
                  setDisplayTooltip(true);
                  setTimeout(() => setDisplayTooltip(false), 1000);
                }}
              >
                <ListItemIcon>
                  <ContentCopyIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>{formatPhone(phone.number)}</ListItemText>
              </MenuItem>
            ))}
            <Divider />
          </>
        )}
        <MenuItem component={Link} to={userLink}>
          {t('See profile')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setIsEditing(true);
            handleCloseMenu();
          }}
        >
          {t('edit')}
        </MenuItem>
        {canDelete && (
          <MenuItem
            onClick={async () => {
              setIsDeleting(true);
              handleCloseMenu();
              await updateUser(null);
              setIsDeleting(false);
            }}
          >
            {t('remove')}
          </MenuItem>
        )}
      </Menu>
      <MutationErrorModal
        error={toggleError}
        onClose={() => setToggleError(null)}
      />
    </>
  );
};
