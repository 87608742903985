import { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import { useWatchMultiple } from '../../utils/reactHookForm';

import type { PropertyFormContext } from './forms-definitions/propertyDetailsFormDefinition';
import type { PropertyFormData } from './propertyFormDefinition';

export const PropertyTypeProvider: React.FC<{
  propertyTypes: PropertyFormContext['propertyTypes'];
}> = ({ propertyTypes }) => {
  const { __main_type, property_type_id } = useWatchMultiple<
    PropertyFormData,
    '__main_type' | 'property_type_id'
  >({ name: ['__main_type', 'property_type_id'] });

  const { setValue } = useFormContext<PropertyFormData>();

  useEffect(() => {
    if (__main_type == null || property_type_id == null) {
      setValue('__property_type', null);
    } else {
      const propertyTypeName = propertyTypes.find(
        pt => pt.id === property_type_id && pt.main_type === __main_type,
      )?.name;

      setValue(
        '__property_type',
        propertyTypeName == null
          ? null
          : {
              main_type: __main_type,
              name: propertyTypeName,
            },
      );
    }
  }, [__main_type, property_type_id, propertyTypes, setValue]);

  return null;
};
