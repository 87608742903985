import * as React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
} from '@material-ui/core';
import { Form, type FormikErrors, useFormik } from '@realadvisor/form';
import {
  fetchQuery,
  graphql,
  useMutation,
  useRelayEnvironment,
} from 'react-relay';
import { Box, useResponsive } from 'react-system';

import { RelayApiWrapper } from '../networking';
import { isProduction } from '../src/config';
import { ProgressButton } from '../src/controls/progress-button';
import { useLocale } from '../src/hooks/locale';
import { type User, UserInput } from '../src/shared/user-input';
import { nylasErrorSyncStates } from '../src/utils/constants';

import type { emailSequenceDialogAddRecipientsMutation } from './__generated__/emailSequenceDialogAddRecipientsMutation.graphql';
import type { emailSequenceDialogUserLoginsQuery } from './__generated__/emailSequenceDialogUserLoginsQuery.graphql';
import { isEmailValid } from './email';
import {
  type EmailSequenceData,
  EmailSequenceSelect,
} from './email-sequence-select';
import { fromGlobalId, fromHasuraGlobalId } from './global-id';

export type Recipient = {
  userId: string;
  email?: string | null;
  buyerLeadId?: string;
  lotId?: string;
  leadId?: string;
  propertyId?: string;
};

export type ExistingRecipient = Omit<Recipient, 'email'>;

type Props = Readonly<{
  recipients: Recipient[] | null;
  me?: User | null;
  open: boolean;
  onClose: () => void;
  onSuccess?: (count: number) => void;
  onAlreadyEnrolledAlert?: () => void;
}>;

type SendSequenceFormState = {
  sequence: EmailSequenceData | null;
  existingRecipients: ExistingRecipient[];
  sendFrom: User | null;
};

const userLoginsQuery = graphql`
  query emailSequenceDialogUserLoginsQuery($id: uuid) {
    users_connection(where: { id: { _eq: $id } }) {
      edges {
        node {
          logins(where: { provider: { _eq: "nylas" } }) {
            __typename
          }
          nylas_account_config {
            sync_state
          }
        }
      }
    }
  }
`;

export const EmailSequenceDialog = ({
  recipients,
  open,
  me,
  onClose,
  onSuccess,
  onAlreadyEnrolledAlert,
}: Props) => {
  const responsive = useResponsive();
  const { t } = useLocale();
  const environment = useRelayEnvironment();
  const [fromUserError, setFromUserError] = React.useState(false);

  const [addRecipients, adding] =
    useMutation<emailSequenceDialogAddRecipientsMutation>(
      graphql`
        mutation emailSequenceDialogAddRecipientsMutation(
          $objects: [email_sequences_recipients_insert_input!]!
        ) {
          insert_email_sequences_recipients(objects: $objects) {
            returning {
              __typename
            }
          }
        }
      `,
    );

  const formik = useFormik<SendSequenceFormState>({
    initialValues: {
      sequence: null,
      existingRecipients: [],
      sendFrom: me ?? null,
    },
    validate: values => {
      const errors: FormikErrors<SendSequenceFormState> = {};
      if (!values.sequence) {
        errors.sequence = t('Field is required');
      }
      if (!values.sendFrom) {
        errors.sendFrom = t('Field is required');
      }
      return errors;
    },
    onSubmit: values => {
      const fromUserId = fromGlobalId(values.sendFrom?.id ?? '');
      fetchQuery<emailSequenceDialogUserLoginsQuery>(
        environment,
        userLoginsQuery,
        { id: fromUserId },
      ).subscribe({
        next: ({ users_connection }) => {
          const isNylasAccMissing = !users_connection.edges[0]?.node.logins[0];
          const syncState =
            users_connection.edges[0]?.node.nylas_account_config[0]
              ?.sync_state ?? 'stopped';
          if (
            isNylasAccMissing ||
            (isProduction && nylasErrorSyncStates.includes(syncState))
          ) {
            setFromUserError(true);
            return;
          }
          // if userIds are equal, then we check if all of lead_id, lot_id, buyer_lead_id are null
          // (as user_id,null,null,null can exists once)
          // or we check if any of lead_id, lot_id, buyer_lead_id are equal
          const newRecipients = recipients?.filter(recipient => {
            const { userId, email, ...rest } = recipient;
            if (email == null || !isEmailValid(email)) {
              return false;
            }

            const isRestNull = Object.values(rest).every(
              (v: string | null) => v == null,
            );

            return (
              values.existingRecipients.some(existingRecipient => {
                const { userId: existingUserId, ...existingRest } =
                  existingRecipient;
                return (
                  fromGlobalId(userId) === existingUserId &&
                  ((isRestNull &&
                    Object.values(existingRest).every(
                      (v: string | null) => v == null,
                    )) ||
                    (rest.leadId != null &&
                      fromGlobalId(rest.leadId) === existingRest.leadId) ||
                    (rest.lotId != null &&
                      fromGlobalId(rest.lotId) === existingRest.lotId) ||
                    (rest.buyerLeadId != null &&
                      fromGlobalId(rest.buyerLeadId) ===
                        existingRest.buyerLeadId))
                );
              }) === false
            );
          });

          const objects = newRecipients?.map(item => ({
            user_id: fromGlobalId(item.userId),
            ...(values.sequence && {
              email_sequence_id: fromHasuraGlobalId(values.sequence.id),
            }),
            ...(item.lotId && { lot_id: fromGlobalId(item.lotId) }),
            ...(item.leadId && { lead_id: fromGlobalId(item.leadId) }),
            ...(values.sendFrom && {
              from_user_id: fromUserId,
            }),
          }));

          if (objects?.length) {
            addRecipients({
              variables: { objects },
              onCompleted() {
                setFromUserError(false);
                resetForm();
                onClose();
                onSuccess?.(objects.length);
              },
              onError: () => {
                formik.setResponseErrors({
                  existingRecipients: t('contactsAreAlreadyActiveInSequence'),
                });
              },
            });
          } else {
            onAlreadyEnrolledAlert?.();
            onClose();
          }
        },
      });
    },
  });

  const { values, setValues, submitForm, resetForm, errors, valid } = formik;

  return (
    <Dialog
      open={open}
      onClose={() => {
        setFromUserError(false);
        resetForm();
        onClose();
      }}
      fullScreen={responsive([true, false])}
    >
      <DialogTitle>{t('sendEmailSequence')}</DialogTitle>
      <Form onSubmit={submitForm}>
        <DialogContent>
          <React.Suspense fallback={null}>
            <Box pv={1}>
              {t('enrollContactsToSequence', {
                count: recipients?.length ?? 0,
              })}
            </Box>
            <Box pb={3}>{t('itWillOnlyBeSentToTheContactsFirstEmail')}</Box>
            <EmailSequenceSelect
              value={values.sequence}
              onChange={(sequence, existingRecipients) => {
                setValues({ sequence, existingRecipients });
              }}
              errorText={errors.sequence || errors.existingRecipients}
            />
            <Box pt={3}>
              <FormControl error={!!errors.sendFrom || fromUserError}>
                <RelayApiWrapper>
                  <InputLabel>{t('sendFrom')}</InputLabel>
                  <UserInput
                    creatable={false}
                    value={values.sendFrom}
                    onChange={sendFrom => {
                      setValues({ sendFrom });
                      setFromUserError(false);
                    }}
                  />
                </RelayApiWrapper>
                {(!!errors.sendFrom || fromUserError) && (
                  <FormHelperText>
                    {errors.sendFrom || t('noNylasAccountError')}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
          </React.Suspense>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setFromUserError(false);
              resetForm();
              onClose();
            }}
            disabled={adding}
          >
            {t('cancel')}
          </Button>
          <ProgressButton
            disabled={!valid}
            loading={adding}
            onClick={submitForm}
          >
            {t('send')}
          </ProgressButton>
        </DialogActions>
      </Form>
    </Dialog>
  );
};
