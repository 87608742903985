// @flow

import { convertFromHTML, convertToHTML } from 'draft-convert';
import {
  AtomicBlockUtils,
  ContentState,
  EditorState,
  convertFromRaw,
  convertToRaw,
} from 'draft-js';
import exporter from 'draft-js-ast-exporter';
import importer from 'draft-js-ast-importer';

import { applySuggestions } from '../../controls/editor-suggestions.jsx';

import { renderPropertySnippet } from './renderPropertySnippet.jsx';

export type InputFormat = 'html' | 'raw';
export type OutputFormat = 'html' | 'raw' | 'plaintext';

export class RichEditorValue {
  _editorState: EditorState;

  constructor(editorState: EditorState) {
    this._editorState = editorState;
  }

  getEditorState(): EditorState {
    return this._editorState;
  }

  getCurrentContent(): any {
    return this.getEditorState().getCurrentContent();
  }

  setEditorState(editorState: EditorState): RichEditorValue {
    return this._editorState === editorState
      ? this
      : new RichEditorValue(editorState);
  }

  toString(format: OutputFormat, t?: any, language?: string): string {
    return toString(this.getEditorState(), format, t, language);
  }

  toAST(): any {
    return exporter(this.getEditorState());
  }

  static createEmpty(): RichEditorValue {
    const editorState = EditorState.createEmpty();
    return new RichEditorValue(editorState);
  }

  static createFromState(editorState: EditorState): RichEditorValue {
    return new RichEditorValue(editorState);
  }

  static createFromString(
    markup: string,
    format: InputFormat,
  ): RichEditorValue {
    const contentState = fromString(markup, format);

    const editorState = EditorState.createWithContent(contentState);
    return new RichEditorValue(editorState);
  }

  static appendCustomHTML(
    prevEditorValue: RichEditorValue,
    html: string,
  ): RichEditorValue {
    const prevState = prevEditorValue.getEditorState();
    const contentState = prevState.getCurrentContent();

    const contentStateWithEntity = contentState.createEntity(
      'CUSTOM_HTML',
      'IMMUTABLE',
      {
        type: 'html',
        html,
      },
    );

    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const newEditorState = EditorState.set(prevState, {
      currentContent: contentStateWithEntity,
    });

    const newState = AtomicBlockUtils.insertAtomicBlock(
      newEditorState,
      entityKey,
      ' ',
    );

    return new RichEditorValue(newState);
  }

  static updateSuggestions(
    prevEditorValue: RichEditorValue,
    suggestions: { [string]: string, ... },
  ): RichEditorValue {
    return new RichEditorValue(
      applySuggestions(prevEditorValue.getEditorState(), suggestions),
    );
  }

  static pushRawString(
    prevEditorValue: RichEditorValue,
    markup: string,
    options?: { suggestions?: { [string]: string, ... }, ... },
  ): RichEditorValue {
    let newState = EditorState.push(
      prevEditorValue.getEditorState(),
      fromString(markup, 'raw'),
      'insert-fragment',
    );
    // Set suggestions if provided
    if (options != null && options.suggestions != null) {
      newState = applySuggestions(newState, options.suggestions);
    }
    return new RichEditorValue(newState);
  }

  static pushAST(
    prevEditorValue: RichEditorValue,
    ast: Array<any>,
    options?: { suggestions?: { [string]: string, ... }, ... },
  ): RichEditorValue {
    let newState = EditorState.push(
      prevEditorValue.getEditorState(),
      importer(ast),
      'insert-fragment',
    );
    // Set suggestions if provided
    if (options != null && options.suggestions != null) {
      newState = applySuggestions(newState, options.suggestions);
    }
    return new RichEditorValue(newState);
  }

  static pushEmptyValue(prevEditorValue: RichEditorValue): RichEditorValue {
    const contentState = ContentState.createFromText('');

    const prevState = prevEditorValue.getEditorState();
    const newState = EditorState.push(
      prevState,
      contentState,
      'insert-fragment',
    );

    return new RichEditorValue(newState);
  }
}

function toString(
  editorState: EditorState,
  format: OutputFormat,
  t?: any,
  language?: string,
): string {
  const contentState = editorState.getCurrentContent();
  switch (format) {
    case 'raw': {
      return JSON.stringify(convertToRaw(contentState));
    }
    case 'html': {
      // This is config needed for HTML to visually match editor
      return convertToHTML({
        blockToHTML: block => {
          switch (block.type) {
            case 'unstyled':
              if (block.text === ' ' || block.text === '') {
                return <br />;
              }
              return <div />;
            case 'paragraph':
              return <p />;
            // case 'header-one':
            //   return <h1/>;
            case 'atomic':
              if (block.text.trim() !== '') {
                return (
                  <img
                    src={block.text}
                    style={{
                      maxWidth: 256,
                      maxHeight: 128,
                      objectFit: 'contain',
                    }}
                  />
                );
              } else {
                return {
                  start: '<div>',
                  end: '</div>',
                };
              }
          }
        },
        entityToHTML: (entity, originalText) => {
          if (entity.type === 'LINK') {
            return <a href={entity.data.url}>{originalText}</a>;
          }

          if (entity.type === 'PROPERTY_SNIPPET') {
            return renderPropertySnippet({
              ...entity.data,
              t,
              language: language != null ? language : 'en',
              emailMode: true,
            });
          }

          if (entity.type === 'CUSTOM_HTML') {
            return entity.data.html;
          }

          return originalText;
        },
      })(contentState);
    }
    case 'plaintext': {
      return contentState.getPlainText();
    }
    default: {
      throw new Error('Format not supported: ' + format);
    }
  }
}

function fromString(markup: string, format: InputFormat): ContentState {
  switch (format) {
    case 'html': {
      return convertFromHTML(markup);
    }
    case 'raw': {
      return convertFromRaw(JSON.parse(markup));
    }
    default: {
      throw new Error('Format not supported: ' + format);
    }
  }
}
