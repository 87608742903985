type Marker = {
  lat: number;
  lng: number;
  color?: string;
};
type StaticMapLinkOptions = {
  lat?: number;
  lng?: number;
  width: number;
  height: number;
  style?: 'satellite' | 'roadmap';
  zoom?: number;
  markers?: Marker[];
  scale?: number;
};

export const getStaticMapLink = ({
  lat,
  lng,
  width,
  height,
  style = 'roadmap',
  zoom,
  markers = [],
  scale = 2,
}: StaticMapLinkOptions) => {
  const baseURL = new URL(
    `https://tiles.stadiamaps.com/static/${
      style === 'roadmap' ? 'osm_bright' : 'alidade_satellite'
    }.png`,
  );

  const params = new URLSearchParams({ size: `${width}x${height}@${scale}x` });
  if (lat && lng) {
    params.set('center', `${lat},${lng}`);
  }

  if (zoom) {
    params.set('zoom', zoom.toString());
  }

  if (markers.length > 0) {
    markers.forEach(marker => {
      params.append(
        `markers`,
        `${marker.lat},${marker.lng},,${marker.color ?? 'red'}`,
      );
    });
  }

  baseURL.search = params.toString();
  return baseURL.toString();
};
