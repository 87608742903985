// Based on https://docs.imgproxy.net/#/generating_the_url

export type WatermarkOptions = {
  opacity: number;
  position:
    | 'ce'
    | 'no'
    | 'so'
    | 'ea'
    | 'we'
    | 'noea'
    | 'nowe'
    | 'soea'
    | 'sowe'
    | 're';
  xOffset: number;
  yOffset: number;
  scale: number;
  base64Url: string;
};

export type ImgProxyOptions = {
  width: number | null;
  height: number | null;
  format: 'webp' | 'jpg' | 'png' | string;
  resizingType: 'fill' | 'fit';
  expand: 1 | 0;
  bg: string | null;
  // https://docs.imgproxy.net/#/watermark
  watermark?: WatermarkOptions;
  trim: number | null;
  blur: number | null;
  quality: number | null;
};

// btoa operates latin1 character set which is used instead of default utf-8 in node version
const toBase64: (string: string) => string =
  typeof window !== 'undefined'
    ? str => btoa(unescape(encodeURIComponent(str)))
    : str =>
        Buffer.from(unescape(encodeURIComponent(str)), 'latin1').toString(
          'base64',
        );

export const urlSafeBase64 = (string: string): string => {
  return toBase64(string)
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_');
};

// Inverse of toBase64
// atob operates latin1 character set which is used instead of default utf-8 in node version
const fromBase64: (string: string) => string =
  typeof window !== 'undefined'
    ? str => decodeURIComponent(escape(atob(str)))
    : str =>
        decodeURIComponent(
          escape(Buffer.from(str, 'base64').toString('latin1')),
        );

// safeBase64ToUrl even not used now, can be usefull in the future to create composable trasformation urls
// also good for testing
export const safeBase64ToUrl = (string: string): string => {
  return fromBase64(string.replace(/-/g, '+').replace(/_/g, '/'));
};

// Needed to break internal cloudflare cache
// See https://docs.imgproxy.net/#/generating_the_url
export const toImgproxyPath = (
  url: string,
  options: ImgProxyOptions,
): string => {
  let pathTransformations = `rs:${options.resizingType}:${options.width ?? 0}:${
    options.height ?? 0
  }:${options.expand}:0`;

  const { watermark, bg, trim, blur, quality } = options;

  if (watermark != null) {
    // pathTransformations += `/wmu:${watermark.base64Url}`; // Only PRO version
    pathTransformations += `/wm:${watermark.opacity}:${watermark.position}:${watermark.xOffset}:${watermark.yOffset}:${watermark.scale}`;
  }

  if (bg != null) {
    pathTransformations += `/bg:${bg}`;
  }

  if (trim != null) {
    pathTransformations += `/t:${trim}`;
  }

  if (blur != null) {
    pathTransformations += `/bl:${blur}`;
  }

  if (quality != null) {
    pathTransformations += `/q:${quality}`;
  }

  const safeUrl = urlSafeBase64(url);
  // we use empty signature because image sources can be only ours
  const signature = '_';

  const path = `${signature}/${pathTransformations}/${safeUrl}.${options.format}`;

  return path;
};
