import { format, formatISO } from 'date-fns';

// consider passed local date as UTC without timezone
export const toISODateString = (date: null | Date): null | string => {
  if (date == null) {
    return null;
  }

  return formatISO(date, { representation: 'date' });
};

export const getDateString = (date: null | Date | string): null | string => {
  if (date == null) {
    return null;
  }

  return format(new Date(date), 'yyyy-MM-dd');
};
