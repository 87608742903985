import React from 'react';

import { Box, FormControl, FormControlLabel, Radio } from '@mui/material';

import { Filter } from '../../../../../src/controls/Filters/Filter';
import { useLocale } from '../../../../../src/hooks/locale';
import type { Merged_Listings_Compare_Args } from '../../../../__generated__/graphql';

type OfflineValue = boolean | null;

interface OnlineFilterProps {
  filters: Partial<Merged_Listings_Compare_Args>;
  setFilters: (filters: Partial<Merged_Listings_Compare_Args>) => void;
}

const OnlineFilter: React.FC<OnlineFilterProps> = ({ filters, setFilters }) => {
  const { t } = useLocale();

  const { offline } = filters;

  const options: Array<{ value: OfflineValue; label: string }> = [
    { value: null, label: t('onlineAndOffline') },
    { value: true, label: t('offline') },
    { value: false, label: t('online') },
  ];

  const getLabel = (value: OfflineValue) => {
    const option = options.find(opt => opt.value === value);
    return option ? option.label : t('onlineAndOffline');
  };

  const handleChange = (value: OfflineValue) => {
    setFilters({ offline: value });
  };

  return (
    <Filter
      empty={offline == null}
      onReset={() => handleChange(null)}
      label={getLabel(offline as OfflineValue)}
      dialogTitle={t('onlineAndOffline')}
    >
      <Box width="280px" p={2}>
        <FormControl>
          {options.map(({ value, label }) => (
            <FormControlLabel
              key={label}
              label={label}
              control={
                <Radio
                  color="primary"
                  checked={offline === value}
                  onChange={() => handleChange(value)}
                />
              }
            />
          ))}
        </FormControl>
      </Box>
    </Filter>
  );
};

export default OnlineFilter;
