/**
 * @generated SignedSource<<bb475be2ee6ab1a3cf0cf35fa9dec63e>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type leadSnippetQuery$variables = {||};
export type leadSnippetQuery$data = {|
  +me: ?{|
    +isAdmin: ?boolean,
  |},
|};
export type leadSnippetQuery = {|
  response: leadSnippetQuery$data,
  variables: leadSnippetQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isAdmin",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "leadSnippetQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "leadSnippetQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "718d8081378cdee9654675808737c3a6",
    "id": null,
    "metadata": {},
    "name": "leadSnippetQuery",
    "operationKind": "query",
    "text": "query leadSnippetQuery {\n  me {\n    isAdmin\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "91a20dc9a99a7aa2713fb439cf772095";

export default node;
