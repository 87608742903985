import React, { type MutableRefObject, useMemo } from 'react';

import { LinearProgress } from '@mui/material';

import { type PageId } from '../shared';

import type { CMAReportType } from './CMAReportEditorDrawer';
import useCMATemplateRenderer from './useCMATemplateRenderer';
import useMarkupRenderer from './useMarkupRenderer';

type MobilePreviewProps = {
  cmaReport: CMAReportType;
  iFrameRef: MutableRefObject<HTMLIFrameElement>;
  viewerPageId: PageId;
};

const MobilePreview: React.FC<MobilePreviewProps> = ({
  cmaReport,
  iFrameRef,
  viewerPageId,
}) => {
  const { markup, dataReady, loading } = useCMATemplateRenderer(cmaReport);
  useMarkupRenderer(iFrameRef, markup, viewerPageId);

  const iframeStyles = useMemo(
    () => ({
      position: 'absolute' as const,
      height: '100%',
      width: '100%',
      zIndex: 0,
      overflow: 'hidden',
      border: 0,
      filter: dataReady ? 'none' : 'brightness(0.8) grayscale(1)',
      transition: 'filter 1s ease',
      pointerEvents: dataReady ? ('initial' as const) : ('none' as const),
    }),
    [dataReady],
  );

  return (
    <>
      {(!dataReady || loading) && (
        <LinearProgress
          variant="indeterminate"
          color="primary"
          sx={{
            zIndex: 2,
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            opacity: dataReady ? 0.4 : 1,
            height: 6,
          }}
        />
      )}
      <iframe
        ref={iFrameRef}
        title="CMA Report Mobile Preview"
        sandbox="allow-same-origin allow-scripts"
        style={iframeStyles}
      />
    </>
  );
};

export default React.memo(MobilePreview);
