import { graphql, useFragment } from 'react-relay';

import type { ActivityForm_activity$key } from './__generated__/ActivityForm_activity.graphql';
import type { ActivityForm_buyerLead$key } from './__generated__/ActivityForm_buyerLead.graphql';
import type { ActivityForm_enquiry$key } from './__generated__/ActivityForm_enquiry.graphql';
import type { ActivityForm_lead$key } from './__generated__/ActivityForm_lead.graphql';
import type { ActivityForm_lot$key } from './__generated__/ActivityForm_lot.graphql';
import type { ActivityForm_root$key } from './__generated__/ActivityForm_root.graphql';
import type { ActivityForm_user$key } from './__generated__/ActivityForm_user.graphql';
import { ActivityEventForm } from './ActivityEventForm';
import { ActivityNoteForm } from './ActivityNoteForm';

type Props = {
  enquiry: null | ActivityForm_enquiry$key;
  lot: null | ActivityForm_lot$key;
  lead: null | ActivityForm_lead$key;
  user: null | ActivityForm_user$key;
  buyerLead: null | ActivityForm_buyerLead$key;
  activity: null | ActivityForm_activity$key;
  root: null | ActivityForm_root$key;
  type?: 'note' | 'task' | 'call' | 'visit' | 'email' | 'workflow' | null;
  onSubmit: () => void;
  onCancel: () => void;
  onCallScheduled?: () => void;
  openDialog: boolean;
  setOpenDialog: (value: boolean) => void;
  showUnfinishedWarning: boolean;
  disableDialogCollapse?: boolean;
};

export const ActivityForm = (props: Props) => {
  const enquiry = useFragment(
    graphql`
      fragment ActivityForm_enquiry on Enquiry {
        ...ActivityNoteForm_enquiry
        ...ActivityEventForm_enquiry
      }
    `,
    props.enquiry,
  );
  const lot = useFragment(
    graphql`
      fragment ActivityForm_lot on Lot {
        ...ActivityNoteForm_lot
        ...ActivityEventForm_lot
      }
    `,
    props.lot,
  );
  const lead = useFragment(
    graphql`
      fragment ActivityForm_lead on Lead {
        ...ActivityNoteForm_lead
        ...ActivityEventForm_lead
      }
    `,
    props.lead,
  );
  const user = useFragment(
    graphql`
      fragment ActivityForm_user on User {
        ...ActivityNoteForm_user
        ...ActivityEventForm_user
      }
    `,
    props.user,
  );
  const buyerLead = useFragment(
    graphql`
      fragment ActivityForm_buyerLead on BuyerLead {
        ...ActivityNoteForm_buyerLead
        ...ActivityEventForm_buyerLead
      }
    `,
    props.buyerLead,
  );

  const root = useFragment(
    graphql`
      fragment ActivityForm_root on Query {
        ...ActivityEventForm_root
        ...ActivityNoteForm_root
      }
    `,
    props.root,
  );
  const activity = useFragment(
    graphql`
      fragment ActivityForm_activity on Activity {
        activityType
        ...ActivityNoteForm_activity
        ...ActivityEventForm_activity
      }
    `,
    props.activity,
  );

  const activityType = activity?.activityType || props.type;

  switch (activityType) {
    case 'note':
      return (
        <ActivityNoteForm
          enquiry={enquiry}
          lot={lot}
          lead={lead}
          user={user}
          buyerLead={buyerLead}
          activity={activity}
          onSubmit={props.onSubmit}
          onCancel={props.onCancel}
          root={root}
          openDialog={props.openDialog}
          setOpenDialog={props.setOpenDialog}
          showUnfinishedWarning={props.showUnfinishedWarning}
        />
      );
    case 'task':
    case 'visit':
    case 'call':
      return (
        <ActivityEventForm
          key={activityType}
          activityType={activityType}
          enquiry={enquiry}
          lot={lot}
          lead={lead}
          user={user}
          buyerLead={buyerLead}
          root={root}
          activity={activity}
          onSubmit={() => {
            props.onSubmit();
            if (activityType === 'call' && props.onCallScheduled) {
              props.onCallScheduled();
            }
          }}
          onCancel={props.onCancel}
          openDialog={props.openDialog}
          setOpenDialog={props.setOpenDialog}
          disableDialogCollapse={props.disableDialogCollapse}
          showUnfinishedWarning={props.showUnfinishedWarning}
        />
      );
    default:
      return null;
  }
};
