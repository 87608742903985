import { useCallback } from 'react';

import { useLocale } from '../../src/hooks/locale';
import { useAppData } from '../providers/AppDataProvider';

export type ApolloTemplate = {
  id: string;
  name: string;
  language: string;
  active: boolean;
  type?: string;
  doc_templates_tenants: Array<{
    tenant_id: string;
    is_default: boolean;
  } | null> | null;
};

type Type =
  | 'appraisal_report'
  | 'lot_brochure'
  | 'cma_report'
  | 'financing_passport';

export const useGetDefaultApolloTemplate = () => {
  const { me } = useAppData();
  const { language } = useLocale();
  const tenantId = me?.tenant_id;

  const getDefaultTemplate = useCallback(
    (templates: ApolloTemplate[] | null | undefined, type?: Type) => {
      const templatesList = templates ?? [];

      if (!templatesList?.length) {
        return null;
      }

      const isDefaultTemplate = (template: ApolloTemplate) => {
        return template.doc_templates_tenants?.some(
          tenant => tenant?.tenant_id === tenantId && tenant?.is_default,
        );
      };

      const matchesType = (template: ApolloTemplate) => {
        if (!type) {
          return true;
        }
        return template?.type === type;
      };

      const isActive = (template: ApolloTemplate) => template?.active === true;

      // Find template matching all criteria
      const defaultTemplate = templatesList.find(
        template =>
          template &&
          isDefaultTemplate(template) &&
          template?.language === language &&
          matchesType(template) &&
          isActive(template),
      );

      // Fallback to English if no template in specified language
      const defaultEnglishTemplate = templatesList.find(
        template =>
          template &&
          isDefaultTemplate(template) &&
          template?.language === 'en' &&
          matchesType(template) &&
          isActive(template),
      );

      return defaultTemplate ?? defaultEnglishTemplate ?? null;
    },
    [language, tenantId],
  );

  return { getDefaultTemplate };
};
