/**
 * @generated SignedSource<<a17536492e8196e89b3f9e22a4654fed>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
type appraisalDrawer_root$fragmentType = any;
import type { FragmentType } from "relay-runtime";
declare export opaque type AppraisalListItem_root$fragmentType: FragmentType;
export type AppraisalListItem_root$data = {|
  +$fragmentSpreads: appraisalDrawer_root$fragmentType,
  +$fragmentType: AppraisalListItem_root$fragmentType,
|};
export type AppraisalListItem_root$key = {
  +$data?: AppraisalListItem_root$data,
  +$fragmentSpreads: AppraisalListItem_root$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppraisalListItem_root",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "appraisalDrawer_root"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node/*: any*/).hash = "88985e3bcb75bd2479cf1e5f2bf0723f";

export default node;
