import { useState } from 'react';

import {
  type ApolloError,
  type InternalRefetchQueriesInclude,
  useMutation,
} from '@apollo/client';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { useLocale } from '../../../src/hooks/locale';
import { gql } from '../../__generated__';
import {
  type GetListingQuickActionsDataQuery,
  type PortalListingButtonFragment,
  type TogglePortalListingMutation,
} from '../../__generated__/graphql';
import { TOGGLE_PORTAL_LISTING } from '../../pages/listings/lotsQueries';
import { LoadingCheckBox } from '../LoadingCheckbox';
import { MutationErrorModal } from '../MutationErrorModal';

interface ListingPortalsButtonProps {
  listing?: PortalListingButtonFragment | null;
  onPortalListingChange?: (portalId: string, live: boolean) => void;
  refetchQueries?: InternalRefetchQueriesInclude;
  allPortals?: GetListingQuickActionsDataQuery['portals'];
  isLoading?: boolean;
  btnSize?: 'small' | 'medium' | 'large';
}

export const PORTAL_LISTINGS_BTN_FRAGMENT = gql(`
  fragment PortalListingButton on lots {
    id
    portal_listings {
      id
      live
      portal_id
      portal {
        id
        name
        active
      }
    }
    enquiries {
      id
      created_at
      portal_id
    }
  }
`);

export const ListingPortalsList: React.FC<
  Omit<ListingPortalsButtonProps, 'isLoading'> & { fullWidth?: boolean }
> = ({
  listing,
  onPortalListingChange,
  allPortals,
  refetchQueries,
  fullWidth = false,
}) => {
  const { t } = useLocale();
  const { breakpoints } = useTheme();
  const [toggleError, setToggleError] = useState<null | ApolloError>(null);
  const notMobile = useMediaQuery(breakpoints.up('sm'));

  const [togglePortalListing] = useMutation<TogglePortalListingMutation>(
    TOGGLE_PORTAL_LISTING,
    {
      onError: e => setToggleError(e),
      refetchQueries,
      onCompleted: onCompletedData => {
        if (onCompletedData.toggle_portal_listing) {
          onPortalListingChange?.(
            onCompletedData.toggle_portal_listing.portal_id,
            onCompletedData.toggle_portal_listing.live,
          );
        }
      },
    },
  );

  if (listing == null) {
    return null;
  }

  const portals = allPortals ?? [];

  return (
    <>
      {portals.length === 0 ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '70px',
            width: fullWidth ? '100%' : '270px',
          }}
        >
          <Typography>{t('No portals available')}</Typography>
        </Box>
      ) : (
        <List
          sx={{
            display: 'grid',
            gridTemplateColumns: { xs: 'repeat(2, 1fr)', sm: 'repeat(3, 1fr)' },
            columnGap: { xs: 2, sm: 5 },
            width: fullWidth ? '100%' : 'auto',
            maxWidth: 800,
          }}
          dense={!notMobile}
        >
          {portals.map(portal => {
            const portalListing = listing.portal_listings.find(
              pl => pl.portal_id === portal.id,
            );

            const totalEnquiries = listing.enquiries.filter(
              enq => enq.portal_id === portal.id,
            ).length;

            const onClick = async () =>
              togglePortalListing({
                variables: {
                  portal_id: portal.id,
                  lot_id: listing.id,
                },
              }).then(response => {
                // return checkbox state
                return (
                  response.data?.toggle_portal_listing?.live ??
                  portalListing?.live ??
                  false
                );
              });

            return (
              <ListItem
                key={portal.id}
                dense={true}
                disableGutters={true}
                sx={{
                  breakInside: 'avoid',
                }}
              >
                <ListItemAvatar>
                  <Avatar
                    sx={{ backgroundColor: 'grey.200' }}
                    title={portal.name ?? ''}
                  >
                    <LoadingCheckBox
                      checked={portalListing?.live ?? false}
                      onClick={onClick}
                    />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={portal.name}
                  secondary={t('{{count}} enquiries', {
                    count: totalEnquiries ?? 0,
                  })}
                />
              </ListItem>
            );
          })}
        </List>
      )}
      <MutationErrorModal
        error={toggleError}
        onClose={() => setToggleError(null)}
      />
    </>
  );
};

export const ListingPortalsButton: React.FC<
  ListingPortalsButtonProps
> = props => {
  const { listing, btnSize, isLoading = false, ...listProps } = props;
  const { t } = useLocale();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [toggleError, setToggleError] = useState<null | ApolloError>(null);

  const livePortals = listing?.portal_listings.filter(pl => pl.live) ?? [];
  const nbLivePortals = livePortals.length ?? 0;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'portals-popover' : undefined;

  return (
    <>
      {isLoading ? (
        <Skeleton variant="rounded" width={100}>
          <Button size={btnSize} endIcon={<ArrowDropDownIcon />}>
            placeholder
          </Button>
        </Skeleton>
      ) : (
        <Button
          size={btnSize}
          variant="contained"
          color={nbLivePortals > 0 ? 'success' : 'neutral'}
          disableElevation
          onClick={handleClick}
          aria-describedby={id}
          endIcon={<ArrowDropDownIcon />}
        >
          {nbLivePortals > 0
            ? t('Online {{count}}', { count: nbLivePortals })
            : t('Offline')}
        </Button>
      )}
      {listing != null && !isLoading && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          sx={{ '& .MuiPopover-paper': { p: 2, mt: 1 } }}
        >
          <ListingPortalsList listing={listing} {...listProps} />
        </Popover>
      )}
      <MutationErrorModal
        error={toggleError}
        onClose={() => setToggleError(null)}
      />
    </>
  );
};
