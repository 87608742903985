/**
 * @generated SignedSource<<23150eba2a96e4dc612ea9605d70d91c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EmailVisibilityTypeEnum = "organisation" | "private" | "shared";
export type SubscriptionStatus = "active" | "canceled" | "incomplete" | "incomplete_expired" | "past_due" | "trialing" | "unpaid";
import { FragmentRefs } from "relay-runtime";
export type ActivityNoteForm_activity$data = {
  readonly createdBy?: {
    readonly firstName: string | null;
    readonly id: string;
    readonly lastName: string | null;
    readonly organisation: {
      readonly formattedAddress: string | null;
      readonly name: string | null;
    } | null;
    readonly primaryEmail: {
      readonly email: string;
    } | null;
    readonly primaryImage: {
      readonly url: string;
    } | null;
    readonly subscription: {
      readonly status: SubscriptionStatus;
    } | null;
  } | null;
  readonly id: string;
  readonly note?: string | null;
  readonly participants?: ReadonlyArray<{
    readonly email: string | null;
    readonly name: string | null;
    readonly user: {
      readonly firstName: string | null;
      readonly id: string;
      readonly lastName: string | null;
      readonly organisation: {
        readonly formattedAddress: string | null;
        readonly name: string | null;
      } | null;
      readonly primaryEmail: {
        readonly email: string;
      } | null;
      readonly primaryImage: {
        readonly url: string;
      } | null;
      readonly subscription: {
        readonly status: SubscriptionStatus;
      } | null;
    } | null;
  }> | null;
  readonly visibility?: EmailVisibilityTypeEnum | null;
  readonly " $fragmentType": "ActivityNoteForm_activity";
};
export type ActivityNoteForm_activity$key = {
  readonly " $data"?: ActivityNoteForm_activity$data;
  readonly " $fragmentSpreads": FragmentRefs<"ActivityNoteForm_activity">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "firstName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lastName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Email",
    "kind": "LinkedField",
    "name": "primaryEmail",
    "plural": false,
    "selections": [
      (v1/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Image",
    "kind": "LinkedField",
    "name": "primaryImage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Organisation",
    "kind": "LinkedField",
    "name": "organisation",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "formattedAddress",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Subscription",
    "kind": "LinkedField",
    "name": "subscription",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActivityNoteForm_activity",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "createdBy",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "visibility",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "note",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ActivityParticipant",
          "kind": "LinkedField",
          "name": "participants",
          "plural": true,
          "selections": [
            (v2/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": (v3/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "ActivityNote",
      "abstractKey": null
    }
  ],
  "type": "Activity",
  "abstractKey": "__isActivity"
};
})();

(node as any).hash = "e93ae15d461e05596b6f05393a90417a";

export default node;
