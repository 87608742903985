/**
 * @generated SignedSource<<f123877574323f3edeb881f477953424>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ActivityForm_lead$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ActivityEventForm_lead" | "ActivityNoteForm_lead">;
  readonly " $fragmentType": "ActivityForm_lead";
};
export type ActivityForm_lead$key = {
  readonly " $data"?: ActivityForm_lead$data;
  readonly " $fragmentSpreads": FragmentRefs<"ActivityForm_lead">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActivityForm_lead",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ActivityNoteForm_lead"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ActivityEventForm_lead"
    }
  ],
  "type": "Lead",
  "abstractKey": null
};

(node as any).hash = "0c65c0d7d40ddd4c11f031a30422da1e";

export default node;
