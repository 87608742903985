// Generated from src/svgs/space-rocket.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const SpaceRocket = ({ size, fill, className }: Props) => {
  return (
    <svg viewBox="0 0 20 20" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><g clipPath="url(#space-rocket_svg__a)" fill="#000"><path d="M18.333 1.667c-.27-.27-.679-.395-1.287-.395-1.218 0-3.608.575-5.194 2.162L8.56 6.725H3.013a.838.838 0 0 0-.59.244l-.589.59a.835.835 0 0 0 0 1.18l9.428 9.427a.831.831 0 0 0 1.179 0l.59-.59a.832.832 0 0 0 .243-.59V11.44l3.292-3.291C17.764 6.95 18.293 5.403 18.5 4.59c.168-.66.49-2.267-.167-2.923ZM6.894 8.392 5.37 9.916 3.845 8.392h3.05Zm-.346 2.703 5.893-5.892 2.356 2.355-5.891 5.894-2.358-2.357Zm5.06 5.06-1.524-1.524 1.524-1.525v3.048Zm4.31-9.833-2.24-2.24c1.166-.82 2.61-1.144 3.368-1.144h.023c.01.672-.302 2.174-1.151 3.384ZM6.547 15.81c-.375.373-1.805.698-3.211.853.159-1.41.489-2.846.855-3.211.215-.216.477-.353.752-.426l-1.258-1.258c-.238.14-.468.3-.673.505-1.144 1.145-1.384 4.581-1.42 5.259l-.05.926.927-.05c.678-.036 4.114-.278 5.258-1.421a3.27 3.27 0 0 0 .505-.674l-1.259-1.259a1.64 1.64 0 0 1-.426.755Z" /></g><defs><clipPath id="space-rocket_svg__a"><path fill="#fff" d="M0 0h20v20H0z" /></clipPath></defs></svg>
  );
}
