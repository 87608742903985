import * as React from 'react';

import type { Handle } from '../utils/animation-timeout';
import {
  cancelAnimationTimeout,
  requestAnimationTimeout,
} from '../utils/animation-timeout';

export const useDebouncedHandler = <T extends (...args: any[]) => any>(
  time: number,
  handler: T,
): T => {
  const timeoutHandle = React.useRef<Handle | null>(null);
  const handlerRef = React.useRef(handler);

  React.useLayoutEffect(() => {
    handlerRef.current = handler;
  });

  React.useEffect(() => {
    return () => {
      cancelAnimationTimeout(timeoutHandle.current);
    };
  }, []);

  const [runDebouncedHandler] = React.useState(
    () =>
      (...args: Parameters<T>) => {
        cancelAnimationTimeout(timeoutHandle.current);
        timeoutHandle.current = requestAnimationTimeout(time, () => {
          handlerRef.current(...args);
        });
      },
  );

  return runDebouncedHandler as T;
};
