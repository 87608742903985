/**
 * @generated SignedSource<<e5c67cb774d5bdee6c003ecb552055b6>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
type userSummary_user$fragmentType = any;
export type SubscriptionStatus = "active" | "canceled" | "incomplete" | "incomplete_expired" | "past_due" | "trialing" | "unpaid";
import type { FragmentType } from "relay-runtime";
declare export opaque type userEditableCard_user$fragmentType: FragmentType;
export type userEditableCard_user$data = {|
  +firstName: ?string,
  +id: string,
  +lastName: ?string,
  +organisation: ?{|
    +formattedAddress: ?string,
    +name: ?string,
  |},
  +primaryEmail: ?{|
    +email: string,
  |},
  +primaryImage: ?{|
    +url: string,
  |},
  +subscription: ?{|
    +status: SubscriptionStatus,
  |},
  +$fragmentSpreads: userSummary_user$fragmentType,
  +$fragmentType: userEditableCard_user$fragmentType,
|};
export type userEditableCard_user$key = {
  +$data?: userEditableCard_user$data,
  +$fragmentSpreads: userEditableCard_user$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "userEditableCard_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "userSummary_user"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Email",
      "kind": "LinkedField",
      "name": "primaryEmail",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "primaryImage",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organisation",
      "kind": "LinkedField",
      "name": "organisation",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "formattedAddress",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Subscription",
      "kind": "LinkedField",
      "name": "subscription",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node/*: any*/).hash = "4d400cf97ab19426b03423329d793f94";

export default node;
