// @flow

// hey! if you're making changes to this file, please make sure to check if you
// need to update this in packages/template-renderer/renderer.js also! thanks!

import * as React from 'react';

import { useResizeRect } from '@realadvisor/observe';
import { scaleLinear, scalePoint } from 'd3-scale';
import { graphql, useFragment } from 'react-relay';

import { useLocale } from '../hooks/locale';
import { useTheme } from '../hooks/theme';

import type { appraisalGraph_appraisal$key } from './__generated__/appraisalGraph_appraisal.graphql';
import type { appraisalGraph_tenantSettings$key } from './__generated__/appraisalGraph_tenantSettings.graphql';

type Props = {|
  tenantSettings: appraisalGraph_tenantSettings$key,
  appraisal: appraisalGraph_appraisal$key,
|};

type AppraisalValueType = {|
  min: ?number,
  value: ?number,
  max: ?number,
|};

type DataItem = {|
  name: string,
  min: ?number,
  value: ?number,
  max: ?number,
  model: string,
|};

// also see realadvisor/packages/template-renderer/renderer.js
const formatValue = (value, locale) => {
  if (value < 1_000) {
    return value.toLocaleString(locale, {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    });
  }
  if (value < 1_000_000) {
    return (
      (value / 1_000).toLocaleString(locale, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }) + 'K'
    );
  }
  return (
    (value / 1_000_000).toLocaleString(locale, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }) + 'M'
  );
  // TODO: use code below when target Safari version become 14.1+
  // const formatValue = (value, locale) => {
  //   return new Intl.NumberFormat(locale, { notation: 'compact' }).format(value);
  // };
};

const AppraisalMax = ({ x, y, item }) => {
  const { colors, text } = useTheme();
  const { locale } = useLocale();

  if (item.max != null) {
    const name = item.name;
    const value = item.max;
    return (
      <>
        <circle
          cx={x(name)}
          cy={y(value)}
          r={3}
          fill="#fff"
          stroke={colors.blue400}
          strokeWidth="2"
        />
        <text
          x={x(name)}
          dx={20}
          y={y(value)}
          style={{ fontSize: 10, fontWeight: text.font.regular }}
          textAnchor="middle"
        >
          {formatValue(value, locale)}
        </text>
      </>
    );
  }

  return null;
};

const AppraisalValue = ({ x, y, item }) => {
  const { colors, text } = useTheme();
  const { locale } = useLocale();

  if (item.value != null) {
    const name = item.name;
    const value = item.value;
    return (
      <>
        <circle cx={x(name)} cy={y(value)} r={5} fill={colors.blue400} />
        <text
          x={x(name)}
          dx={20}
          y={y(value)}
          style={{ fontSize: 12, fontWeight: text.font.medium }}
          textAnchor="middle"
        >
          {formatValue(value, locale)}
        </text>
      </>
    );
  }

  return null;
};

const AppraisalMin = ({ x, y, item }) => {
  const { colors, text } = useTheme();
  const { locale } = useLocale();

  if (item.min != null) {
    const name = item.name;
    const value = item.min;
    return (
      <>
        <circle
          cx={x(name)}
          cy={y(value)}
          r={3}
          fill="#fff"
          stroke={colors.blue400}
          strokeWidth="2"
        />
        <text
          x={x(name)}
          dx={20}
          y={y(value)}
          style={{ fontSize: 10, fontWeight: text.font.regular }}
          textAnchor="middle"
        >
          {formatValue(value, locale)}
        </text>
      </>
    );
  }

  return null;
};

const getAppraisalDomain = appraisal => {
  const getTuple = item => [item?.min, item?.value, item?.max];

  const values = [
    ...getTuple(appraisal.iazi),
    ...getTuple(appraisal.iazi_cv),
    ...getTuple(appraisal.wup),
    ...getTuple(appraisal.propertydata),
    ...getTuple(appraisal.pricehubble),
    ...getTuple(appraisal.realadvisor_listing),
    ...getTuple(appraisal.realadvisor_naive_listings),
    ...getTuple(appraisal.realadvisor_omni_meta),
    ...getTuple(appraisal.realadvisor_transaction),
    ...getTuple(appraisal.realadvisor_open_data_transaction),
    ...getTuple(appraisal.realadvisor_perceived),
    ...getTuple(appraisal.realadvisor),
  ]
    .filter(d => d != null)
    // Hack to fix flow
    .map(d => (d != null ? d : 0));

  return [Math.min(...values), Math.max(...values)];
};

const getData = (tenantSettings, appraisals): Array<DataItem> => {
  const data: Array<DataItem> = [];
  const modelMappings = {
    iazi: 'IAZI',
    iazi_cv: 'IAZI CV',
    pricehubble: 'PH',
    wup: 'Wuest',
    propertydata: 'PD',
    realadvisor_listing: 'RAL',
    realadvisor_naive_listings: 'RAN',
    realadvisor_omni_meta: 'RAX',
    realadvisor_transaction: 'RAT',
    realadvisor_open_data_transaction: 'RAO',
    realadvisor_perceived: 'RAP',
  };

  (Object.entries(appraisals): Array<[string, mixed]>).forEach(
    ([key, value]) => {
      if (value != null && typeof value === 'object') {
        const appraisalValue: AppraisalValueType = (value: any);
        const modelName = modelMappings[key] || key.toUpperCase();
        const modelActivationMap = {
          iazi: tenantSettings.activateModelIazi,
          iazi_cv: tenantSettings.activateModelIazi,
          wup: tenantSettings.activateModelWup,
          propertydata: tenantSettings.activateModelPropertydata,
          pricehubble: tenantSettings.activateModelPricehubble,
          realadvisor_listing: tenantSettings.activateModelRealadvisorListings,
          realadvisor_naive_listings:
            tenantSettings.activateModelRealadvisorListings,
          realadvisor_omni_meta:
            tenantSettings.activateModelRealadvisorListings,
          realadvisor_transaction:
            tenantSettings.activateModelRealadvisorTransactions,
          realadvisor_open_data_transaction:
            tenantSettings.activateModelRealadvisorTransactions,
          realadvisor_perceived:
            tenantSettings.activateModelRealadvisorPerceived,
        };

        const shouldInclude = !!modelActivationMap[key];

        if (shouldInclude) {
          data.push({
            name: modelName,
            min: appraisalValue.min,
            value: appraisalValue.value,
            max: appraisalValue.max,
            model: key,
          });
        }
      }
    },
  );

  data.sort((a, b) => {
    const aIsRealadvisor = a.model.toLowerCase().startsWith('realadvisor');
    const bIsRealadvisor = b.model.toLowerCase().startsWith('realadvisor');

    if (aIsRealadvisor && !bIsRealadvisor) {
      return -1;
    }
    return a.model.localeCompare(b.model);
  });

  return data;
};

const Chart = ({ width, height, tenantSettings, appraisal }) => {
  const { colors, text } = useTheme();
  const { locale } = useLocale();
  // collect points data and remove items without values
  const data = getData(tenantSettings, appraisal).filter(
    item => item.min != null || item.value != null || item.max != null,
  );
  const x = scalePoint()
    .domain(data.map(item => item.name))
    .range([0, width])
    .padding(1);
  const y = scaleLinear()
    .domain(getAppraisalDomain(appraisal))
    .range([height - 30, 20])
    .nice(5);
  const ticks = y.ticks(5);
  // cast array items to nullable similar to proposal
  const item = <T>(array: $ReadOnlyArray<T>, index: number): T | null =>
    array[index] ?? null;
  const xStart = x(item(data, 0)?.name);
  const xEnd = x(item(data, data.length - 1)?.name);

  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <g transform={`translate(0, ${height - 30})`}>
        {data.map(item => {
          const pos = x(item.name);
          return (
            <React.Fragment key={item.name}>
              <line x1={pos} x2={pos} y1={0} y2={6} stroke="#666" />
              <text
                x={pos}
                y="16"
                dy="0.71em"
                textAnchor="middle"
                style={{ fontSize: 12, fontWeight: text.font.medium }}
              >
                {item.name}
              </text>
            </React.Fragment>
          );
        })}
      </g>

      <g transform={`translate(34, 0)`}>
        {ticks.map(tick => (
          <React.Fragment key={tick}>
            <line
              x1={0}
              x2={width}
              y1={y(tick)}
              y2={y(tick)}
              stroke="rgba(0, 0, 0, .12)"
              strokeWidth={2}
              strokeDasharray="3 6"
            />
            <text
              x={0}
              y={y(tick)}
              textAnchor="end"
              style={{ fontSize: 10, fontWeight: text.font.medium }}
            >
              {formatValue(tick, locale)}
            </text>
          </React.Fragment>
        ))}
      </g>

      <line
        x1={xStart}
        x2={xEnd}
        y1={y(appraisal.realadvisor?.max)}
        y2={y(appraisal.realadvisor?.max)}
        stroke={colors.green600}
        strokeWidth="1"
        strokeDasharray="2 2"
      />

      <line
        x1={xStart}
        x2={xEnd}
        y1={y(appraisal.realadvisor?.value)}
        y2={y(appraisal.realadvisor?.value)}
        stroke={colors.green600}
        strokeWidth="2"
        strokeDasharray="2 2"
      />

      <line
        x1={xStart}
        x2={xEnd}
        y1={y(appraisal.realadvisor?.min)}
        y2={y(appraisal.realadvisor?.min)}
        stroke={colors.green500}
        strokeWidth="1"
        strokeDasharray="2 2"
      />

      {data.map(item => (
        <AppraisalMax key={item.name} x={x} y={y} item={item} />
      ))}

      {data.map(item => (
        <AppraisalValue key={item.name} x={x} y={y} item={item} />
      ))}

      {data.map(item => (
        <AppraisalMin key={item.name} x={x} y={y} item={item} />
      ))}
    </svg>
  );
};

export const AppraisalGraph = (props: Props): React.Node => {
  const targetRef = React.useRef(null);
  const targetRect = useResizeRect(targetRef);
  const tenantSettings = useFragment(
    graphql`
      fragment appraisalGraph_tenantSettings on Tenant {
        activateModelIazi
        activateModelWup
        activateModelPropertydata
        activateModelPricehubble
        activateModelRealadvisorListings
        activateModelRealadvisorTransactions
        activateModelRealadvisorPerceived
      }
    `,
    props.tenantSettings,
  );
  const appraisal = useFragment(
    graphql`
      fragment appraisalGraph_appraisal on Appraisal {
        iazi {
          min
          max
          value
        }
        iazi_cv {
          min
          max
          value
        }
        pricehubble {
          min
          max
          value
        }
        wup {
          min
          max
          value
        }
        propertydata {
          min
          max
          value
        }
        realadvisor_listing {
          min
          max
          value
        }
        realadvisor_naive_listings {
          min
          max
          value
        }
        realadvisor_omni_meta {
          min
          max
          value
        }
        realadvisor_transaction {
          min
          max
          value
        }
        realadvisor_open_data_transaction {
          min
          max
          value
        }
        realadvisor_perceived {
          min
          max
          value
        }
        realadvisor {
          min
          max
          value
        }
      }
    `,
    props.appraisal,
  );

  return (
    <div css={{ height: 280, overflow: 'hidden' }} ref={targetRef}>
      {targetRect && (
        <Chart
          width={targetRect.width}
          height={targetRect.height}
          tenantSettings={tenantSettings}
          appraisal={appraisal}
        />
      )}
    </div>
  );
};
