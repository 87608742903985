import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  type AccordionProps,
  AccordionSummary,
  Divider,
  Typography,
} from '@mui/material';

export const ListingAccordion: React.FC<AccordionProps> = ({
  children,
  title,
  ...props
}) => (
  <Accordion
    variant="outlined"
    elevation={0}
    sx={theme => ({
      '&.MuiAccordion-root::before': {
        display: 'none',
      },
      '&.MuiAccordion-root:not(.Mui-expanded)+.MuiAccordion-root:not(.Mui-expanded)':
        {
          borderTopWidth: 0,
        },
      '&.MuiAccordion-root:has(+.MuiAccordion-root.Mui-expanded)': {
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
      },
      '&.MuiAccordion-root.Mui-expanded+.MuiAccordion-root': {
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
      },
      [theme.breakpoints.down('sm')]: {
        borderRadius: '0 !important',
        borderLeftWidth: '0 !important',
        borderRightWidth: '0 !important',
        '&.Mui-expanded': {
          my: 1,
        },
      },
    })}
    {...props}
  >
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      sx={{
        '&.MuiAccordionSummary-root.Mui-expanded': {
          minHeight: 0,
        },
        '.MuiAccordionSummary-content.Mui-expanded': {
          mt: 1.5,
          mb: 1,
        },
      }}
    >
      <Typography variant="body1" sx={{ fontWeight: 500 }}>
        {title}
      </Typography>
    </AccordionSummary>
    <AccordionDetails sx={{ display: 'block' }}>
      <Divider sx={{ marginBottom: 2, marginTop: -1 }} />
      {children}
    </AccordionDetails>
  </Accordion>
);
