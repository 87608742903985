/**
 * @generated SignedSource<<2d78cbb57c8a6a1a6445423e3244f3c5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type financingWonDialog_lead$data = {
  readonly id: string;
  readonly mortgageInsuranceProvider: {
    readonly id: string;
    readonly name: string | null;
    readonly primaryImage: {
      readonly url: string;
    } | null;
  } | null;
  readonly mortgageInsuranceRevenue: number | null;
  readonly mortgageLender: {
    readonly id: string;
    readonly name: string | null;
    readonly primaryImage: {
      readonly url: string;
    } | null;
  } | null;
  readonly mortgagePropertyValue: number | null;
  readonly mortgagePurchaseDate: string | null;
  readonly mortgageRevenue: number | null;
  readonly mortgageStartDate: string | null;
  readonly " $fragmentType": "financingWonDialog_lead";
};
export type financingWonDialog_lead$key = {
  readonly " $data"?: financingWonDialog_lead$data;
  readonly " $fragmentSpreads": FragmentRefs<"financingWonDialog_lead">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Image",
    "kind": "LinkedField",
    "name": "primaryImage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "financingWonDialog_lead",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mortgageRevenue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mortgagePropertyValue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mortgageInsuranceRevenue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mortgagePurchaseDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mortgageStartDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organisation",
      "kind": "LinkedField",
      "name": "mortgageLender",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organisation",
      "kind": "LinkedField",
      "name": "mortgageInsuranceProvider",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Lead",
  "abstractKey": null
};
})();

(node as any).hash = "2165c21f23131bebfdd70356a3eed0c7";

export default node;
