/**
 * @generated SignedSource<<2c486f90d4db180bc8b35b1656bd0d8c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type StartEmailCampaignInput = {
  clientMutationId?: string | null;
  email?: BatchEmailInput | null;
};
export type BatchEmailInput = {
  bodyTemplate?: string | null;
  files?: ReadonlyArray<EmailTemplateFilesInput | null> | null;
  subjectTemplate?: string | null;
  to: ReadonlyArray<BatchSendEmailRecipient | null>;
};
export type BatchSendEmailRecipient = {
  buyerLeadId?: string | null;
  email: string;
  name?: string | null;
};
export type EmailTemplateFilesInput = {
  id?: string | null;
  mimetype?: string | null;
  name?: string | null;
  size?: number | null;
  url?: string | null;
};
export type EmailFormStartEmailCampaignMutation$variables = {
  input: StartEmailCampaignInput;
};
export type EmailFormStartEmailCampaignMutation$data = {
  readonly startEmailCampaign: {
    readonly campaignId: string | null;
    readonly error: number | null;
  } | null;
};
export type EmailFormStartEmailCampaignMutation = {
  response: EmailFormStartEmailCampaignMutation$data;
  variables: EmailFormStartEmailCampaignMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "StartEmailCampaignPayload",
    "kind": "LinkedField",
    "name": "startEmailCampaign",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "campaignId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmailFormStartEmailCampaignMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmailFormStartEmailCampaignMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f1a2eae5983b91be16dc3a1a7feceef7",
    "id": null,
    "metadata": {},
    "name": "EmailFormStartEmailCampaignMutation",
    "operationKind": "mutation",
    "text": "mutation EmailFormStartEmailCampaignMutation(\n  $input: StartEmailCampaignInput!\n) {\n  startEmailCampaign(input: $input) {\n    campaignId\n    error\n  }\n}\n"
  }
};
})();

(node as any).hash = "7d08657fae946df3acf4b7a8beb5276c";

export default node;
