import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  LinearProgress,
  Skeleton,
  Typography,
} from '@mui/material';
import { Image } from '@realadvisor/image';

interface ImageCardProps {
  id: string; // The unique identifier for the image
  size?: number; // The image size (if available)
  signedUrl?: string; // The signed URL (for new uploads)
  isLoading?: boolean; // Flag to indicate if the image has been uploaded
  imageUrl?: string; // The actual image URL (for existing DB images)
  error?: Error;
  children?: React.ReactNode; // Custom card actions
}

export const ImageCard = ({
  id,
  size,
  signedUrl,
  isLoading = false,
  imageUrl,
  error,
  children,
}: ImageCardProps) => (
  <Card variant="outlined" elevation={0} sx={{ position: 'relative' }}>
    {error && (
      <Alert
        severity="error"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          padding: 8,
          zIndex: 1,
          background: 'rgba(255, 255, 255, 0.6)',
          backdropFilter: 'blur(10px)',
          borderRadius: 0,
        }}
      >
        <AlertTitle>{error.message}</AlertTitle>
        <pre style={{ fontSize: '0.9em' }}>
          {JSON.stringify(error, null, 2)}
        </pre>
      </Alert>
    )}
    <CardMedia sx={{ height: 200 }}>
      {imageUrl && (
        <Image
          src={imageUrl}
          options={{ h: 200, f: 'jpg' }}
          objectFit="cover"
        />
      )}
    </CardMedia>
    <CardContent>
      <Typography variant="body2" color="text.secondary" noWrap>
        {id}
      </Typography>
      {size && (
        <Typography variant="body2" color="text.secondary" noWrap>
          {size}
        </Typography>
      )}
      {signedUrl && (
        <Typography variant="body2" color="text.secondary" noWrap>
          {signedUrl}
        </Typography>
      )}
    </CardContent>
    <CardActions>{children}</CardActions>
    {isLoading && <LinearProgress />}
  </Card>
);

export const ImageCardSkeleton = () => (
  <Card variant="outlined">
    <CardMedia sx={{ height: 200 }}>
      <Skeleton variant="rectangular" height={200} animation="wave" />
    </CardMedia>
    <CardContent>
      <Skeleton variant="text" animation="wave" />
    </CardContent>
    <CardActions>
      <Button size="small" disabled>
        <Skeleton variant="text" width={100} animation="wave" />
      </Button>
    </CardActions>
  </Card>
);
