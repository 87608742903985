/**
 * @generated SignedSource<<3b050cfa5938999cfa38def5717678a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LeadDetailsLatestAppraisalCard_lead$data = {
  readonly id: string;
  readonly property: {
    readonly appraisable: boolean;
    readonly countryCode: string | null;
    readonly id: string;
    readonly latestAppraisal: {
      readonly realadvisor: {
        readonly max: number | null;
        readonly min: number | null;
        readonly pricePerSquareMeterBuiltSurface: number | null;
        readonly pricePerSquareMeterLivingSurface: number | null;
        readonly rentalValue: number | null;
        readonly value: number | null;
      } | null;
      readonly updatedAt: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"appraisalGraph_appraisal">;
    } | null;
  } | null;
  readonly userCanViewLeadDetails: boolean;
  readonly " $fragmentType": "LeadDetailsLatestAppraisalCard_lead";
};
export type LeadDetailsLatestAppraisalCard_lead$key = {
  readonly " $data"?: LeadDetailsLatestAppraisalCard_lead$data;
  readonly " $fragmentSpreads": FragmentRefs<"LeadDetailsLatestAppraisalCard_lead">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LeadDetailsLatestAppraisalCard_lead",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userCanViewLeadDetails",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Property",
      "kind": "LinkedField",
      "name": "property",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "appraisable",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Appraisal",
          "kind": "LinkedField",
          "name": "latestAppraisal",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "appraisalGraph_appraisal"
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "RealadvisorAppraisal",
              "kind": "LinkedField",
              "name": "realadvisor",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "min",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "max",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "value",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "pricePerSquareMeterBuiltSurface",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "pricePerSquareMeterLivingSurface",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "rentalValue",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "updatedAt",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "countryCode",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Lead",
  "abstractKey": null
};
})();

(node as any).hash = "ff5e3378cb62bd879efbd4f7347d025a";

export default node;
