import type { RecordProxy } from 'relay-runtime';

import { decode, encode } from '../utils/base64';

export function updateEndCursor(
  connection: null | RecordProxy,
  operation: 'increase' | 'decrease',
) {
  if (!connection) {
    return;
  }

  // relay reads `pageInfo` `endCursor` to pass as $after connection variable,
  // we change `endCursor` which is internally (in our codebase)
  // just base64 encoded word and position like `arrayconnection:$OFFSET`
  // having that we just deleted the element at some interval [0,$OFFSET]
  // we need to change `endCursor` with $delta offset
  // otherwise we will not read one element
  const pageInfoProxy: any = connection.getLinkedRecord('pageInfo');
  const endCursor = decode(pageInfoProxy.getValue('endCursor'));
  const [word, offsetStr] = endCursor.split(':');
  const offset = Number.parseFloat(offsetStr);

  const delta = operation === 'increase' ? 1 : -1;

  if (offset > 0) {
    pageInfoProxy.setValue(encode(`${word}:${offset + delta}`), 'endCursor');
  }

  connection.setValue(
    (connection?.getValue('totalCount') as number) + delta,
    'totalCount',
  );
}
