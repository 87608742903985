/**
 * @generated SignedSource<<b9cb30ebb9cdbbc3ab479ed63e192782>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DocTemplateLanguage = "de" | "en" | "es" | "fr" | "it";
import { FragmentRefs } from "relay-runtime";
export type useGetDefaultRelayTemplate$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly active: boolean;
      readonly docTemplatesTenants: ReadonlyArray<{
        readonly isDefault: boolean | null;
        readonly tenantId: string | null;
      } | null> | null;
      readonly id: string;
      readonly language: DocTemplateLanguage;
      readonly type: string;
    };
  } | null> | null;
  readonly " $fragmentType": "useGetDefaultRelayTemplate";
};
export type useGetDefaultRelayTemplate$key = {
  readonly " $data"?: useGetDefaultRelayTemplate$data;
  readonly " $fragmentSpreads": FragmentRefs<"useGetDefaultRelayTemplate">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useGetDefaultRelayTemplate",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DocTemplateEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DocTemplate",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "language",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "active",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "DocTemplatesTenants",
              "kind": "LinkedField",
              "name": "docTemplatesTenants",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "tenantId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isDefault",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DocTemplateConnection",
  "abstractKey": null
};

(node as any).hash = "de154d03388c60e5103dbd3d940dbc44";

export default node;
