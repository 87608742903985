/**
 * @generated SignedSource<<a1f786dcd9deb7d4d7a2c8a5aad4da62>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TenantEmailTemplate = "cardis" | "default" | "realadvisor";
export type UpdateTenantSettingsInput = {
  clientMutationId?: string | null;
  countryCode?: string | null;
  debugEmailMode?: boolean | null;
  defaultPipelineId?: string | null;
  emailSenderEmail?: string | null;
  emailSenderName?: string | null;
  emailTemplate?: TenantEmailTemplate | null;
  googleTagManagerId?: string | null;
  helpLinkDe?: string | null;
  helpLinkEn?: string | null;
  helpLinkEs?: string | null;
  helpLinkFr?: string | null;
  helpLinkIt?: string | null;
  locality?: string | null;
  logoImageUrl?: string | null;
  mainColor?: string | null;
  mortgageRates?: any | null;
  name?: string | null;
  phoneMain?: string | null;
  postcode?: string | null;
  resourcesDe?: string | null;
  resourcesEn?: string | null;
  resourcesEs?: string | null;
  resourcesFr?: string | null;
  resourcesIt?: string | null;
  route?: string | null;
  sendInBlueApiKey?: string | null;
  state?: string | null;
  streetNumber?: string | null;
  stripeSecretKey?: string | null;
  stripeSecretKeyTest?: string | null;
  stripeWebhookSecret?: string | null;
  stripeWebhookSecretTest?: string | null;
};
export type generalSettingsUpdateTenantSettingsMutation$variables = {
  input: UpdateTenantSettingsInput;
};
export type generalSettingsUpdateTenantSettingsMutation$data = {
  readonly updateTenantSettings: {
    readonly tenantSettings: {
      readonly availableEmailTemplates: ReadonlyArray<TenantEmailTemplate | null>;
      readonly countryCode: string;
      readonly debugEmailMode: boolean;
      readonly defaultPipeline: {
        readonly id: string;
      } | null;
      readonly emailSenderEmail: string;
      readonly emailSenderName: string;
      readonly emailTemplate: TenantEmailTemplate | null;
      readonly googleTagManagerId: string;
      readonly helpLinkDe: string | null;
      readonly helpLinkEn: string | null;
      readonly helpLinkEs: string | null;
      readonly helpLinkFr: string | null;
      readonly helpLinkIt: string | null;
      readonly locality: string;
      readonly logoImage: {
        readonly url: string;
      } | null;
      readonly mainColor: string;
      readonly name: string;
      readonly phoneMain: string;
      readonly postcode: string;
      readonly route: string;
      readonly sendInBlueApiKey: string | null;
      readonly state: string;
      readonly streetNumber: string;
      readonly stripeSecretKey: string | null;
      readonly stripeSecretKeyTest: string | null;
      readonly stripeWebhookSecret: string | null;
      readonly stripeWebhookSecretTest: string | null;
    } | null;
  } | null;
};
export type generalSettingsUpdateTenantSettingsMutation = {
  response: generalSettingsUpdateTenantSettingsMutation$data;
  variables: generalSettingsUpdateTenantSettingsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emailSenderName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emailSenderEmail",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Pipeline",
  "kind": "LinkedField",
  "name": "defaultPipeline",
  "plural": false,
  "selections": [
    (v5/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emailTemplate",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "availableEmailTemplates",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "route",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "streetNumber",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postcode",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locality",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "countryCode",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mainColor",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phoneMain",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "googleTagManagerId",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sendInBlueApiKey",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stripeSecretKey",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stripeWebhookSecret",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stripeSecretKeyTest",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stripeWebhookSecretTest",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "helpLinkFr",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "helpLinkEs",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "helpLinkDe",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "helpLinkEn",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "helpLinkIt",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "debugEmailMode",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "generalSettingsUpdateTenantSettingsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateTenantSettingsPayload",
        "kind": "LinkedField",
        "name": "updateTenantSettings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Tenant",
            "kind": "LinkedField",
            "name": "tenantSettings",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Image",
                "kind": "LinkedField",
                "name": "logoImage",
                "plural": false,
                "selections": [
                  (v23/*: any*/)
                ],
                "storageKey": null
              },
              (v24/*: any*/),
              (v25/*: any*/),
              (v26/*: any*/),
              (v27/*: any*/),
              (v28/*: any*/),
              (v29/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "generalSettingsUpdateTenantSettingsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateTenantSettingsPayload",
        "kind": "LinkedField",
        "name": "updateTenantSettings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Tenant",
            "kind": "LinkedField",
            "name": "tenantSettings",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Image",
                "kind": "LinkedField",
                "name": "logoImage",
                "plural": false,
                "selections": [
                  (v23/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v24/*: any*/),
              (v25/*: any*/),
              (v26/*: any*/),
              (v27/*: any*/),
              (v28/*: any*/),
              (v29/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "86358c45ad05a00ac86f087fcd420efc",
    "id": null,
    "metadata": {},
    "name": "generalSettingsUpdateTenantSettingsMutation",
    "operationKind": "mutation",
    "text": "mutation generalSettingsUpdateTenantSettingsMutation(\n  $input: UpdateTenantSettingsInput!\n) {\n  updateTenantSettings(input: $input) {\n    tenantSettings {\n      name\n      emailSenderName\n      emailSenderEmail\n      defaultPipeline {\n        id\n      }\n      emailTemplate\n      availableEmailTemplates\n      route\n      streetNumber\n      postcode\n      state\n      locality\n      countryCode\n      mainColor\n      phoneMain\n      googleTagManagerId\n      sendInBlueApiKey\n      stripeSecretKey\n      stripeWebhookSecret\n      stripeSecretKeyTest\n      stripeWebhookSecretTest\n      logoImage {\n        url\n        id\n      }\n      helpLinkFr\n      helpLinkEs\n      helpLinkDe\n      helpLinkEn\n      helpLinkIt\n      debugEmailMode\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "14a7240dc57a9076a0191185c0fb21d7";

export default node;
