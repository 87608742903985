import React from 'react';

import type { SetHook } from '../../../../src/hooks/set';
import type {
  Merged_Listings_Compare_Args,
  StepListingsCompareFragment,
} from '../../../__generated__/graphql';

import ListingAutomatic from './ListingAutomatic';
import ListingManual from './ListingManual';

interface ListingWrapperProps {
  mode: 'automatic' | 'manual';
  selection: SetHook<number>;
  onSelect: (id: number) => void;
  data?: StepListingsCompareFragment;
  filters?: Merged_Listings_Compare_Args;
}

const ListingWrapper: React.FC<ListingWrapperProps> = ({
  mode,
  selection,
  onSelect,
  data,
  filters,
}) => {
  if (mode === 'automatic' && data) {
    return (
      <ListingAutomatic selection={selection} onSelect={onSelect} data={data} />
    );
  } else if (mode === 'manual' && filters) {
    return (
      <ListingManual
        selection={selection}
        onSelect={onSelect}
        filters={filters}
      />
    );
  }
  return null;
};

export default ListingWrapper;
