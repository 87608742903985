/**
 * @generated SignedSource<<ac70b9fda1f9200836e639ad3b620107>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SubscriptionStatus = "active" | "canceled" | "incomplete" | "incomplete_expired" | "past_due" | "trialing" | "unpaid";
export type UserAssetsThirdPillarAOrganisationType = "bank" | "insurance_company";
export type UserAssetsThirdPillarBOrganisationType = "bank" | "insurance_company";
export type UserGender = "female" | "male";
export type UserLanguage = "de" | "en" | "es" | "fr" | "it";
export type UserMaritalStatus = "cohabitation" | "divorced" | "married" | "separated" | "single" | "widowed";
export type UserMatrimonialRegime = "contribution_to_jointly_acquired_property" | "joint_estates" | "separate_estates";
export type UserProfessionalSituation = "business_owner" | "employed" | "retired" | "self_employed" | "unemployed";
export type UserTitle = "doctor" | "madam" | "professor" | "sir";
export type CreateUserInput = {
  accountManagerId?: string | null;
  annualCostsChildSupport?: number | null;
  annualCostsLeasing?: number | null;
  annualCostsOtherCredits?: number | null;
  annualCredits?: ReadonlyArray<UserAnnualCreditInput | null> | null;
  annualCreditsExist?: boolean | null;
  annualIncomeAdditionalExist?: boolean | null;
  annualIncomeBonus?: number | null;
  annualIncomeChildSupport?: number | null;
  annualIncomeFamilyAllowances?: number | null;
  annualIncomeOther?: number | null;
  annualIncomeRent?: number | null;
  annualIncomeSalary?: number | null;
  annualIncomeSalary13?: boolean | null;
  annualIncomeSalary14?: boolean | null;
  annualLeasings?: ReadonlyArray<UserAnnualLeasingInput | null> | null;
  annualLeasingsExist?: boolean | null;
  annualOtherExist?: boolean | null;
  annualOtherPayments?: ReadonlyArray<number | null> | null;
  assetsSavings?: number | null;
  assetsSavingsDonation?: number | null;
  assetsSecondPillar?: number | null;
  assetsSecondPillarExist?: boolean | null;
  assetsSecondPillarOrganisation?: string | null;
  assetsSecurities?: number | null;
  assetsThirdPillarA?: number | null;
  assetsThirdPillarAAnnualPremium?: number | null;
  assetsThirdPillarAAnnualPremiumOrganization?: string | null;
  assetsThirdPillarACash?: number | null;
  assetsThirdPillarAExist?: boolean | null;
  assetsThirdPillarALifeInsurance?: boolean | null;
  assetsThirdPillarAOrganisation?: string | null;
  assetsThirdPillarAOrganisationType?: UserAssetsThirdPillarAOrganisationType | null;
  assetsThirdPillarB?: number | null;
  assetsThirdPillarBAnnualPremium?: number | null;
  assetsThirdPillarBExist?: boolean | null;
  assetsThirdPillarBLifeInsurance?: boolean | null;
  assetsThirdPillarBOrganisation?: string | null;
  assetsThirdPillarBOrganisationType?: UserAssetsThirdPillarBOrganisationType | null;
  assetsThirdPillarExist?: boolean | null;
  autoAssignLeads?: boolean | null;
  brandingColor?: string | null;
  brandingLogo?: string | null;
  brandingName?: string | null;
  brandingWebsite?: string | null;
  brokerCatchmentAreas?: ReadonlyArray<string> | null;
  brokerId?: string | null;
  canEditSettings?: boolean | null;
  canUserLoginAs?: boolean | null;
  clientMutationId?: string | null;
  dateOfBirth?: string | null;
  debtLeasingBalance?: number | null;
  debtMortgageBalance?: number | null;
  debtOtherCreditsBalance?: number | null;
  divorceDate?: string | null;
  donation?: number | null;
  emailSignature?: string | null;
  emailSignatureHTML?: string | null;
  employer?: string | null;
  employerId?: string | null;
  employmentCommencementDate?: string | null;
  employmentRate?: number | null;
  firstName?: string | null;
  gender?: UserGender | null;
  hasAcceptedTerms?: boolean | null;
  hasChildren?: boolean | null;
  hasFreeCrmAccess?: boolean | null;
  hasUsedTrialButton?: boolean | null;
  homeAddress?: AddressInput | null;
  identificationDocumentExpiryDate?: string | null;
  identificationDocumentIssueDate?: string | null;
  identificationDocumentNumber?: string | null;
  identificationDocumentTypeId?: string | null;
  isAdmin?: boolean | null;
  isBroker?: boolean | null;
  jobTitle?: string | null;
  language?: UserLanguage | null;
  lastBonus?: number | null;
  lastName?: string | null;
  maritalPartnerId?: string | null;
  maritalStatus?: UserMaritalStatus | null;
  matrimonialRegime?: UserMatrimonialRegime | null;
  membershipType?: string | null;
  modules?: ReadonlyArray<string> | null;
  mortgageBrokerId?: string | null;
  nationalityId?: string | null;
  numberOfBuyers?: number | null;
  numberOfDependantChildren?: number | null;
  occupationId?: string | null;
  occupationText?: string | null;
  placeOfOrigin?: string | null;
  primaryEmail?: string | null;
  primaryImageId?: string | null;
  primaryPhoneNumber?: string | null;
  professionalAddress?: AddressInput | null;
  professionalSituation?: UserProfessionalSituation | null;
  propertiesExist?: boolean | null;
  referrer?: string | null;
  residenceCountryCode?: string | null;
  residenceStateId?: string | null;
  secondPillarOrganisationId?: string | null;
  thirdPillarAAnnualPremiumOrganizationId?: string | null;
  thirdPillarAOrganisationId?: string | null;
  thirdPillarBOrganisationId?: string | null;
  title?: UserTitle | null;
  updatesDailyTasks?: boolean | null;
  updatesIntroductionToLeadContacts?: boolean | null;
  updatesNewLeadAssignedSms?: boolean | null;
  updatesNewsletter?: boolean | null;
  updatesPropertyRevaluation?: boolean | null;
  weddingDate?: string | null;
  workPermitId?: string | null;
  yearlyRevenuesAtRetirement?: number | null;
};
export type AddressInput = {
  countryCode?: string | null;
  lat?: number | null;
  lng?: number | null;
  locality?: string | null;
  postcode?: string | null;
  route?: string | null;
  state?: string | null;
  streetNumber?: string | null;
};
export type UserAnnualLeasingInput = {
  annualPayment?: number | null;
  endDate?: string | null;
  remainingBalance?: number | null;
};
export type UserAnnualCreditInput = {
  annualPayment?: number | null;
  endDate?: string | null;
  purpose?: string | null;
  remainingBalance?: number | null;
};
export type userCreateDialogMutation$variables = {
  input: CreateUserInput;
};
export type userCreateDialogMutation$data = {
  readonly createUser: {
    readonly errors: {
      readonly primaryEmail: string | null;
      readonly primaryPhoneNumber: string | null;
    } | null;
    readonly user: {
      readonly firstName: string | null;
      readonly id: string;
      readonly lastName: string | null;
      readonly organisation: {
        readonly formattedAddress: string | null;
        readonly name: string | null;
      } | null;
      readonly primaryEmail: {
        readonly email: string;
      } | null;
      readonly primaryImage: {
        readonly url: string;
      } | null;
      readonly subscription: {
        readonly status: SubscriptionStatus;
      } | null;
    } | null;
  } | null;
};
export type userCreateDialogMutation = {
  response: userCreateDialogMutation$data;
  variables: userCreateDialogMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "UserErrors",
  "kind": "LinkedField",
  "name": "errors",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "primaryEmail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "primaryPhoneNumber",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "formattedAddress",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "userCreateDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateUserPayload",
        "kind": "LinkedField",
        "name": "createUser",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Email",
                "kind": "LinkedField",
                "name": "primaryEmail",
                "plural": false,
                "selections": [
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Image",
                "kind": "LinkedField",
                "name": "primaryImage",
                "plural": false,
                "selections": [
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Organisation",
                "kind": "LinkedField",
                "name": "organisation",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Subscription",
                "kind": "LinkedField",
                "name": "subscription",
                "plural": false,
                "selections": [
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "userCreateDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateUserPayload",
        "kind": "LinkedField",
        "name": "createUser",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Email",
                "kind": "LinkedField",
                "name": "primaryEmail",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Image",
                "kind": "LinkedField",
                "name": "primaryImage",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Organisation",
                "kind": "LinkedField",
                "name": "organisation",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Subscription",
                "kind": "LinkedField",
                "name": "subscription",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "04ca3ae51d56a21220f6c32ca62c9433",
    "id": null,
    "metadata": {},
    "name": "userCreateDialogMutation",
    "operationKind": "mutation",
    "text": "mutation userCreateDialogMutation(\n  $input: CreateUserInput!\n) {\n  createUser(input: $input) {\n    errors {\n      primaryEmail\n      primaryPhoneNumber\n    }\n    user {\n      id\n      firstName\n      lastName\n      primaryEmail {\n        email\n        id\n      }\n      primaryImage {\n        url\n        id\n      }\n      organisation {\n        name\n        formattedAddress\n        id\n      }\n      subscription {\n        status\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "40c09dee9f08a1361f0d90445f1898db";

export default node;
