// Generated from src/svgs/nav-performance.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const NavPerformance = ({ size, fill, className }: Props) => {
  return (
    <svg viewBox="0 0 25 25" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><path d="M23.5 24H22V8.5A1.5 1.5 0 0 0 20.5 7h-1A1.5 1.5 0 0 0 18 8.5V24h-3v-9.5a1.5 1.5 0 0 0-1.5-1.5h-1a1.5 1.5 0 0 0-1.5 1.5V24H8v-4.5A1.5 1.5 0 0 0 6.5 18h-1A1.5 1.5 0 0 0 4 19.5V24H2V2.5a.5.5 0 0 0-1 0v22a.5.5 0 0 0 .5.5h22a.5.5 0 0 0 0-1zM7 24H5v-4.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5zm7 0h-2v-9.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5zm7 0h-2V8.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z" /></svg>
  );
}
