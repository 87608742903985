/**
 * @generated SignedSource<<e74865a4b272179bca71db55290d165c>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
type LeadDetailsMap_property$fragmentType = any;
export type MortgageTrancheType = "fixed" | "variable";
export type PropertyCooling = "air_conditioning" | "ceiling_fans" | "none";
export type PropertyHeatingDistribution = "floor" | "forced_air" | "other" | "radiators" | "stove";
export type PropertyOrientation = "E" | "N" | "NE" | "NW" | "S" | "SE" | "SW" | "W";
export type UpsertPropertyInput = {|
  clientMutationId?: ?string,
  property: PropertyInput,
|};
export type PropertyInput = {|
  balconySurface?: ?number,
  basementSurface?: ?number,
  borrowerLeadId?: ?string,
  buildingCondition?: ?number,
  buildingStandard?: ?number,
  buildingVolume?: ?number,
  buildingVolumeNorm?: ?string,
  builtSurface?: ?number,
  ceilingHeight?: ?number,
  charges?: ?number,
  commercialPotentialRentalIncome?: ?number,
  commercialSurface?: ?number,
  commercialYearlyRentalIncome?: ?number,
  constructionYear?: ?number,
  consumption?: ?number,
  cooling?: ?PropertyCooling,
  country?: ?string,
  countryCode?: ?string,
  createdBy?: ?string,
  ctn?: ?string,
  deletedByUser?: ?boolean,
  distanceToAirport?: ?number,
  distanceToCityCenter?: ?number,
  distanceToGroceryShop?: ?number,
  distanceToHighSchool?: ?number,
  distanceToHospital?: ?number,
  distanceToKindergarten?: ?number,
  distanceToMotorway?: ?number,
  distanceToPrimarySchool?: ?number,
  distanceToPublicTransport?: ?number,
  distanceToSportsCenter?: ?number,
  distanceToTrainStation?: ?number,
  emissions?: ?number,
  energyCertificateVersion?: ?string,
  energyConsumptionClass?: ?string,
  energyInspectionDate?: ?string,
  energyReferenceYear?: ?number,
  estimatedHigherEnergyCost?: ?number,
  estimatedLowerEnergyCost?: ?number,
  financingInstitutionId?: ?string,
  financingLeadId?: ?string,
  flatCondition?: ?number,
  floorOfFlat?: ?number,
  gardenSurface?: ?number,
  gasEmissionsClass?: ?string,
  googleAddressId?: ?string,
  grossFloorSurface?: ?number,
  hasAttic?: ?boolean,
  hasCellar?: ?boolean,
  hasElevator?: ?boolean,
  hasFireplace?: ?boolean,
  hasGym?: ?boolean,
  hasPhotovoltaicPanels?: ?boolean,
  hasSauna?: ?boolean,
  hasStorageRoom?: ?boolean,
  hasSwimmingPool?: ?boolean,
  hasView?: ?boolean,
  hasWellnessArea?: ?boolean,
  heating?: ?string,
  heatingDistribution?: ?PropertyHeatingDistribution,
  id?: ?string,
  incomeIncludesParking?: ?boolean,
  isChildFriendly?: ?boolean,
  isCommercialFullyRented?: ?boolean,
  isLuxury?: ?boolean,
  isMaisonette?: ?boolean,
  isParkingFullyRented?: ?boolean,
  isRental?: ?boolean,
  isResidentialFullyRented?: ?boolean,
  isSecondaryResidence?: ?boolean,
  isWheelchairAccessible?: ?boolean,
  landSurface?: ?number,
  lat?: ?number,
  livingSurface?: ?number,
  lng?: ?number,
  locality?: ?string,
  localityId?: ?string,
  macroLocation?: ?number,
  microLocation?: ?number,
  minergieCode?: ?number,
  monthlyGrossRent?: ?number,
  mortgage?: ?MortgageInput,
  numberOfBathrooms?: ?number,
  numberOfBedrooms?: ?number,
  numberOfCommercialUnits?: ?number,
  numberOfFlats?: ?number,
  numberOfFloors?: ?number,
  numberOfIndoorParkings?: ?number,
  numberOfLifts?: ?number,
  numberOfOutdoorParkings?: ?number,
  numberOfResidentialUnits?: ?number,
  numberOfRooms?: ?number,
  numberOfToilets?: ?number,
  orientation?: ?PropertyOrientation,
  ownersIds?: ?$ReadOnlyArray<?string>,
  parkingPotentialRentalIncome?: ?number,
  parkingYearlyRentalIncome?: ?number,
  perceivedValue?: ?number,
  plotHasConstructibleReserve?: ?boolean,
  plotIsConstructible?: ?boolean,
  plotNumber?: ?string,
  postcode?: ?string,
  propertyTypeId?: ?string,
  propertyTypeName?: ?string,
  relevantServitudes?: ?boolean,
  renovationFund?: ?number,
  renovationYear?: ?number,
  residentialPotentialRentalIncome?: ?number,
  residentialSurface?: ?number,
  residentialYearlyRentalIncome?: ?number,
  route?: ?string,
  shareOfOwnership?: ?number,
  side?: ?string,
  state?: ?string,
  streetNumber?: ?string,
  subLocality?: ?string,
  terraceSurface?: ?number,
  usableSurface?: ?number,
  weightedFloorSurface?: ?number,
|};
export type MortgageInput = {|
  amortisation?: ?number,
  annualRentalIncome?: ?number,
  bankerId?: ?string,
  borrowerPropertyLocation?: ?string,
  expiryDate?: ?string,
  id?: ?string,
  interestRate?: ?number,
  lender?: ?string,
  lenderId?: ?string,
  loanAmount?: ?number,
  mortgageBrokerId?: ?string,
  property?: ?PropertyInput,
  propertyId?: ?string,
  signedAt?: ?string,
  startDate?: ?string,
  tranches?: ?$ReadOnlyArray<?MortgageTrancheInput>,
  tranchesExist?: ?boolean,
  type?: ?MortgageTrancheType,
  value?: ?number,
|};
export type MortgageTrancheInput = {|
  amortisation?: ?number,
  expiryDate?: ?string,
  fixedFor?: ?number,
  interestRate?: ?number,
  loanAmount?: ?number,
  startDate?: ?string,
  type?: ?MortgageTrancheType,
|};
export type LeadDetailsMapUpsertPropertyMutation$variables = {|
  input: UpsertPropertyInput,
|};
export type LeadDetailsMapUpsertPropertyMutation$data = {|
  +upsertProperty: ?{|
    +property: ?{|
      +location: ?{|
        +plot: ?{|
          +owners: ?{|
            +names: ?$ReadOnlyArray<string>,
          |},
        |},
      |},
      +$fragmentSpreads: LeadDetailsMap_property$fragmentType,
    |},
  |},
|};
export type LeadDetailsMapUpsertPropertyMutation = {|
  response: LeadDetailsMapUpsertPropertyMutation$data,
  variables: LeadDetailsMapUpsertPropertyMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Owners",
  "kind": "LinkedField",
  "name": "owners",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "names",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lat",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lng",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LeadDetailsMapUpsertPropertyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertPropertyPayload",
        "kind": "LinkedField",
        "name": "upsertProperty",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Property",
            "kind": "LinkedField",
            "name": "property",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "LeadDetailsMap_property"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Location",
                "kind": "LinkedField",
                "name": "location",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Plot",
                    "kind": "LinkedField",
                    "name": "plot",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LeadDetailsMapUpsertPropertyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertPropertyPayload",
        "kind": "LinkedField",
        "name": "upsertProperty",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Property",
            "kind": "LinkedField",
            "name": "property",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "GoogleAddress",
                "kind": "LinkedField",
                "name": "googleAddress",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Location",
                "kind": "LinkedField",
                "name": "location",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Plot",
                    "kind": "LinkedField",
                    "name": "plot",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "00c2f4050ccb823e2d21140288a6edce",
    "id": null,
    "metadata": {},
    "name": "LeadDetailsMapUpsertPropertyMutation",
    "operationKind": "mutation",
    "text": "mutation LeadDetailsMapUpsertPropertyMutation(\n  $input: UpsertPropertyInput!\n) {\n  upsertProperty(input: $input) {\n    property {\n      ...LeadDetailsMap_property\n      location {\n        plot {\n          owners {\n            names\n          }\n          id\n        }\n      }\n      id\n    }\n  }\n}\n\nfragment LeadDetailsMap_property on Property {\n  id\n  lat\n  lng\n  googleAddress {\n    lat\n    lng\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "80e3714b2b85ef3bd2e62655674a0c9b";

export default node;
