import { useState } from 'react';

import { useQuery } from '@apollo/client';
import { Box, CircularProgress, useTheme } from '@mui/material';

import { useLocale } from '../../../../src/hooks/locale';
import type { SetHook } from '../../../../src/hooks/set';
import type {
  AggregatesListingsListQuery,
  Merged_Listings_Compare_Args,
} from '../../../__generated__/graphql';
import { AGGREGATES_LISTINGS_LIST_QUERY } from '../cmaReportsQueries';

import ListingGrid from './ListingGrid';

export type ListingManualType = Omit<
  NonNullable<
    | AggregatesListingsListQuery['ch_merged_listings_compare']
    | AggregatesListingsListQuery['fr_merged_listings_compare']
    | AggregatesListingsListQuery['es_merged_listings_compare']
    | AggregatesListingsListQuery['it_merged_listings_compare']
  >[number],
  'property_type'
> & {
  property_type: null | string;
};

type ListingManualProps = {
  filters: Merged_Listings_Compare_Args;
  onSelect: (id: number) => void;
  selection: SetHook<number>;
};

const ListingManual = ({
  selection,
  onSelect,
  filters,
}: ListingManualProps) => {
  const { text } = useTheme();
  const { countryCode, t } = useLocale();
  const PAGE_SIZE = 60;
  const [pageCount] = useState(1);

  const { data, loading } = useQuery(AGGREGATES_LISTINGS_LIST_QUERY, {
    variables: {
      pageSize: PAGE_SIZE,
      page: (pageCount - 1) * PAGE_SIZE,
      filters,
      ch: countryCode === 'CH',
      fr: countryCode === 'FR',
      es: countryCode === 'ES',
      it: countryCode === 'IT',
    },
  });

  const listings: ListingManualType[] =
    data?.ch_merged_listings_compare ??
    data?.fr_merged_listings_compare ??
    data?.es_merged_listings_compare ??
    data?.it_merged_listings_compare ??
    [];

  if (loading) {
    return (
      <Box m="auto">
        <CircularProgress />
      </Box>
    );
  }

  if (listings.length === 0) {
    return (
      <div
        css={[
          text.caption,
          {
            height: 300,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        ]}
      >
        {t('noListingsFound')}
      </div>
    );
  }

  return (
    <ListingGrid
      listings={listings}
      selection={selection}
      onSelect={onSelect}
    />
  );
};

export default ListingManual;
