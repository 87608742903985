// Generated from src/svgs/minimize-outline.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const MinimizeOutline = ({ size, fill, className }: Props) => {
  return (
    <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><path style={{"fill":"none"}} d="M0 0h24v24H0z" /><path d="M5 15h2.586l-5.293 5.293 1.414 1.414L9 16.414V19h2v-6H5zM20.293 2.293 15 7.586V5h-2v6h6V9h-2.586l5.293-5.293z" /></svg>
  );
}
