import { useState } from 'react';

import { Alert, AlertTitle, Collapse } from '@mui/material';

interface RaPreProps {
  title?: string;
  json: object;
}

export const RaPre = ({ title, json }: RaPreProps) => {
  const [open, setOpen] = useState(false);

  return (
    <Alert severity="info" onClick={() => setOpen(!open)}>
      <AlertTitle>{title}</AlertTitle>
      <Collapse in={open}>
        <pre>{JSON.stringify(json, null, 2)}</pre>
      </Collapse>
    </Alert>
  );
};
