/**
 * @generated SignedSource<<758df0fa5dba225f23b910dffff2d357>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type leadSourceFilterQuery$variables = {};
export type leadSourceFilterQuery$data = {
  readonly leadSourceTypes: ReadonlyArray<{
    readonly id: string;
    readonly label: string;
    readonly name: string;
  }>;
};
export type leadSourceFilterQuery = {
  response: leadSourceFilterQuery$data;
  variables: leadSourceFilterQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": "leadSourceTypes",
    "args": [
      {
        "kind": "Literal",
        "name": "type",
        "value": "lead_source_types"
      }
    ],
    "concreteType": "Dictionary",
    "kind": "LinkedField",
    "name": "dictionaries",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "label",
        "storageKey": null
      }
    ],
    "storageKey": "dictionaries(type:\"lead_source_types\")"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "leadSourceFilterQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "leadSourceFilterQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "141b875dfc565f1ed28d6ddc35ce8b0f",
    "id": null,
    "metadata": {},
    "name": "leadSourceFilterQuery",
    "operationKind": "query",
    "text": "query leadSourceFilterQuery {\n  leadSourceTypes: dictionaries(type: \"lead_source_types\") {\n    id\n    name\n    label\n  }\n}\n"
  }
};
})();

(node as any).hash = "767ad4595903534753fd02d8228a30d5";

export default node;
