import * as React from 'react';

import { Avatar, Checkbox } from '@material-ui/core';
import { useConstant } from '@realadvisor/hooks';
import { Image } from '@realadvisor/image';
import { useResizeRect } from '@realadvisor/observe';
import { graphql, useLazyLoadQuery, usePaginationFragment } from 'react-relay';
import { Box, Flex } from 'react-system';
import { FixedSizeList, type ListChildComponentProps } from 'react-window';

import { SearchField } from '../controls/Filters';
import { useLocale } from '../hooks/locale';
import { useWindowPagination } from '../hooks/relay';
import { useTheme } from '../hooks/theme';

import type {
  baseUserFilter_root$data,
  baseUserFilter_root$key,
} from './__generated__/baseUserFilter_root.graphql';
import type { baseUserFilterPaginationQuery } from './__generated__/baseUserFilterPaginationQuery.graphql';
import baseUserFilterPaginationQueryNode from './__generated__/baseUserFilterPaginationQuery.graphql';
import type {
  UserSearchFilters,
  baseUserFilterQuery,
} from './__generated__/baseUserFilterQuery.graphql';

type UserNode = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<baseUserFilter_root$data['usersSearch']>['edges']
    >[number]
  >['node']
>;

type WindowNode = {
  selectedIds: Array<null | string>;
  nodes: UserNode[];
  onRowClick: (node: UserNode) => void;
};

const getFirstChar = (str: null | string) =>
  str != null && str !== '' ? str[0] : '';
const getAbbr = (firstName: null | string, lastName: null | string) =>
  (getFirstChar(firstName) + getFirstChar(lastName)).toUpperCase();

const UserItem = ({
  style,
  index,
  data,
}: ListChildComponentProps<WindowNode>) => {
  const { text, textColor } = useTheme();
  const node = data.nodes[index];
  const imageUrl = node?.primaryImage?.url;

  return (
    <Flex
      style={style}
      alignItems="center"
      px={1}
      css={{ cursor: 'pointer' }}
      onClick={() => data.onRowClick(node)}
    >
      <Checkbox checked={data.selectedIds.includes(node.id)} color="primary" />
      <Flex mr={3} flexShrink={0} width={'40px'} height={'40px'}>
        {imageUrl == null ? (
          <Avatar>{getAbbr(node.firstName, node.lastName)}</Avatar>
        ) : (
          <Image
            // invalidate element to fix previous image with another user name
            key={node.id}
            src={imageUrl}
            options={{ w: 256, f: 'jpg' }}
            css={{ borderRadius: '50%' }}
          />
        )}
      </Flex>
      <Box flexGrow={1}>
        <Box css={[text.body2, text.truncate(1)]}>
          {[node.firstName, ' ', node.lastName]}
        </Box>
        {node.primaryEmail?.email != null && (
          <Box css={[text.body2, text.truncate(1), textColor('mediumText')]}>
            {node.primaryEmail?.email ?? null}
          </Box>
        )}
      </Box>
    </Flex>
  );
};

const UserList = ({
  nodes,
  loadUntil,
  selectedIds,
  onChange,
}: {
  nodes: UserNode[];
  loadUntil: (size: number) => void;
  selectedIds: Array<string | null>;
  onChange: (node: UserNode) => void;
}) => {
  const containerRef = React.useRef(null);
  const containerRect = useResizeRect(containerRef);
  return (
    <Box flexGrow={1} ref={containerRef}>
      {containerRect && (
        <FixedSizeList<WindowNode>
          width={containerRect.width}
          height={containerRect.height}
          itemSize={64}
          itemCount={nodes.length}
          itemData={{
            selectedIds,
            nodes,
            onRowClick: onChange,
          }}
          overscanCount={20}
          onItemsRendered={({ overscanStopIndex }) =>
            loadUntil(overscanStopIndex)
          }
        >
          {UserItem}
        </FixedSizeList>
      )}
    </Box>
  );
};

const BaseUserFilterContent = (props: Props) => {
  const { t } = useLocale();
  const {
    filters,
    selectedIds,
    onChange,
    showNoAssignedOption = false,
    noItemLabel = t('noAgentAssigned'),
  } = props;
  const { text } = useTheme();
  const FETCH_COUNT = 20;
  const id_sort_first_in = selectedIds.map(item => {
    if (item === 'null') {
      return null;
    }
    return item;
  });

  const initialVariables = useConstant(() => ({
    count: FETCH_COUNT,
    filters: {
      ...filters,
      id_sort_first_in,
    },
  }));
  const queryData = useLazyLoadQuery<baseUserFilterQuery>(
    graphql`
      query baseUserFilterQuery($count: Int!, $filters: UserSearchFilters) {
        ...baseUserFilter_root @arguments(count: $count, filters: $filters)
      }
    `,
    initialVariables,
  );
  const { data, refetch, loadUntil } = useWindowPagination<
    baseUserFilterPaginationQuery,
    baseUserFilter_root$key
  >(
    usePaginationFragment<
      baseUserFilterPaginationQuery,
      baseUserFilter_root$key
    >(
      graphql`
        fragment baseUserFilter_root on Query
        @refetchable(queryName: "baseUserFilterPaginationQuery")
        @argumentDefinitions(
          count: { type: "Int!" }
          cursor: { type: "String", defaultValue: null }
          search: { type: "String", defaultValue: null }
          filters: { type: "UserSearchFilters", defaultValue: null }
        ) {
          usersSearch(
            first: $count
            after: $cursor
            search: $search
            filters: $filters
          ) @connection(key: "baseUserFilterConnection_usersSearch") {
            edges {
              node {
                id
                firstName
                lastName
                primaryEmail {
                  email
                }
                primaryImage {
                  url
                }
              }
            }
          }
        }
      `,
      queryData,
    ),
    data => data.usersSearch?.edges?.length ?? 0,
    baseUserFilterPaginationQueryNode,
  );
  const nodes = (data.usersSearch?.edges ?? []).flatMap(edge => {
    return edge?.node ? [edge.node] : [];
  });
  const [search, setSearch] = React.useState('');

  return (
    <>
      <SearchField
        value={search}
        onChange={newSearch => {
          setSearch(newSearch);
          refetch({
            count: FETCH_COUNT,
            search: newSearch,
            filters: { ...filters, id_sort_first_in },
          });
        }}
      />
      {showNoAssignedOption && (
        <Flex
          alignItems="center"
          px={1}
          css={{
            cursor: 'pointer',
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          }}
          onClick={() =>
            onChange({
              id: null,
              name: noItemLabel,
            })
          }
        >
          <Checkbox checked={selectedIds.includes(null)} color="primary" />
          <Box flexGrow={1} css={[text.body2, text.truncate(1)]}>
            {noItemLabel}
          </Box>
        </Flex>
      )}
      <UserList
        nodes={nodes}
        loadUntil={loadUntil}
        selectedIds={selectedIds}
        onChange={node => {
          const name =
            node.firstName != null && node.lastName != null
              ? `${node.firstName} ${node.lastName}`
              : null;
          onChange({ id: node.id, name });
        }}
      />
    </>
  );
};

type Props = {
  filters?: UserSearchFilters;
  selectedIds: Array<string | null>;
  onChange: (value: { id: null | string; name: string | null }) => void;
  showNoAssignedOption?: boolean;
  noItemLabel?: string;
};

export const BaseUserFilter = ({
  filters,
  selectedIds,
  onChange,
  showNoAssignedOption,
  noItemLabel,
}: Props) => {
  return (
    <Flex
      flexDirection="column"
      width="300px"
      height="calc(100vh - 300px)"
      css={{ minHeight: 300 }}
    >
      <React.Suspense fallback={<SearchField value="" onChange={() => {}} />}>
        <BaseUserFilterContent
          filters={filters}
          selectedIds={selectedIds}
          onChange={onChange}
          showNoAssignedOption={showNoAssignedOption}
          noItemLabel={noItemLabel}
        />
      </React.Suspense>
    </Flex>
  );
};
