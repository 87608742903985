import { useState } from 'react';

import { type MutationTuple } from '@apollo/client';
import { Alert, Snackbar } from '@mui/material';

import { useLocale } from '../../src/hooks/locale';
import { type Property_Files_Insert_Input } from '../__generated__/graphql';
import { useFileUpload } from '../utils/files/file-upload';

export const usePropertyFileUpload = <
  TData extends any,
  TVariables extends {
    object: Property_Files_Insert_Input;
  },
>({
  propertyId,
  createFile,
}: {
  propertyId: string;
  createFile: MutationTuple<TData, TVariables>[0];
}) => {
  const [pendingFiles, setPendingFiles] = useState<File[]>([]);
  const addPendingFile = (file: File) =>
    setPendingFiles(prev => [...prev, file]);
  const removePendingFile = (file: File) =>
    setPendingFiles(prev => prev.filter(item => item !== file));
  const [errorFiles, setErrorFiles] = useState<
    { fileName: string; key: number }[]
  >([]);

  const addErrorFile = (fileName: string) =>
    setErrorFiles(prev => [...prev, { fileName, key: new Date().getTime() }]);

  const removeErrorFile = (key: number) =>
    setErrorFiles(prev => prev.filter(item => item.key !== key));

  const [uploadFile] = useFileUpload('file');

  const upload = (file: File) => {
    addPendingFile(file);
    uploadFile(file, ({ data, error }) => {
      if (error != null || data?.url == null) {
        addErrorFile(file.name);
        removePendingFile(file);
        return;
      }
      createFile({
        variables: {
          object: {
            file: {
              data: {
                name: file.name,
                url: data.url,
                size: file.size,
                mimetype: file.type,
              },
            },
            property_id: propertyId,
          },
        } as TVariables,
        onCompleted: () => {
          removePendingFile(file);
        },
        onError: () => {
          addErrorFile(file.name);
          removePendingFile(file);
        },
      });
    });
  };
  const FileErrors = () => {
    const { t } = useLocale();
    return (
      <>
        {errorFiles.map(({ fileName, key }) => (
          <Snackbar
            key={key}
            open={true}
            autoHideDuration={3000}
            onClose={() => removeErrorFile(key)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <Alert
              onClose={() => removeErrorFile(key)}
              severity="error"
              sx={{ width: '100%' }}
            >
              {t('An error occured while uploading {{file}}', {
                file: fileName,
              })}
            </Alert>
          </Snackbar>
        ))}
      </>
    );
  };
  return {
    pendingFiles,
    errorFiles,
    upload,
    removeErrorFile,
    FileErrors,
  };
};
