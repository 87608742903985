/**
 * @generated SignedSource<<fe47daaad9d0d38ecff311decaa075dc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Feed_buyerLead$data = {
  readonly __typename: "BuyerLead";
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"FeedEmailForm_buyerLead" | "FeedNewForm_buyerLead">;
  readonly " $fragmentType": "Feed_buyerLead";
};
export type Feed_buyerLead$key = {
  readonly " $data"?: Feed_buyerLead$data;
  readonly " $fragmentSpreads": FragmentRefs<"Feed_buyerLead">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Feed_buyerLead",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeedNewForm_buyerLead"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeedEmailForm_buyerLead"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "BuyerLead",
  "abstractKey": null
};

(node as any).hash = "161d022d6b7da4ea2703c5690322411e";

export default node;
