import { FilledInput, InputAdornment } from '@material-ui/core';
import { useRifm } from 'rifm';

import { useLocale } from '../hooks/locale';
import { makeNumberFormat } from '../utils/number-format';

type Props = {
  decimalScale?: number;
  value: string;
  disabled?: boolean;
  onChange: (value: string) => void;
  onBlur?: () => void;
};

const MeterInputBase = (props: Props, unit: string) => {
  const { locale } = useLocale();
  const { accept, format, delocalizeNumber, localizeNumber } = makeNumberFormat(
    {
      scale: props.decimalScale,
      locale,
    },
  );

  const rifm = useRifm({
    accept,
    format,
    value: localizeNumber(props.value),
    onChange: formatted => props.onChange(delocalizeNumber(formatted)),
  });

  return (
    <FilledInput
      endAdornment={<InputAdornment position="end">{unit}</InputAdornment>}
      value={rifm.value}
      disabled={props.disabled}
      onChange={rifm.onChange}
      onBlur={props.onBlur}
    />
  );
};

export const SquareInput = (props: Props) => {
  // m^2
  return MeterInputBase(props, ' m\u00B2');
};

export const CubeInput = (props: Props) => {
  // m^3
  return MeterInputBase(props, ' m\u00B3');
};

export const MeterInput = (props: Props) => {
  return MeterInputBase(props, ' m');
};

export const EnergyConsumptionInput = (props: Props) => {
  return MeterInputBase(props, ' kWh/m²/y');
};

export const EmissionsInput = (props: Props) => {
  return MeterInputBase(props, ' kg CO2/m²/y');
};
