import { Box } from '@mui/material';

import type { SetHook } from '../../../../src/hooks/set';

import type { ListingAutomaticType } from './ListingAutomatic';
import ListingCardInfo from './ListingCardInfo';
import type { ListingManualType } from './ListingManual';
import { SelectableCard } from './SelectableCard';

type ListingGridProps = {
  listings: ListingAutomaticType[] | ListingManualType[];
  onSelect: (id: number) => void;
  selection: SetHook<number>;
};

const ListingGrid = ({ listings, selection, onSelect }: ListingGridProps) => (
  <Box
    sx={{
      padding: { xs: 2, md: 3 },
      display: 'grid',
      gap: { xs: 2, sm: 3 },
      justifyContent: 'center',
      gridTemplateColumns: {
        xs: 'repeat(1, minmax(0, 400px))',
        sm: 'repeat(auto-fill, minmax(250px, 1fr))',
        md: 'repeat(auto-fill, minmax(280px, 1fr))',
        xl: 'repeat(auto-fill, minmax(300px, 1fr))',
      },
    }}
  >
    {listings.map(listing => (
      <SelectableCard
        key={listing.id}
        selected={selection.has(listing.id)}
        onChange={() => onSelect(listing.id)}
      >
        <ListingCardInfo listing={listing} variant="vertical" />
      </SelectableCard>
    ))}
  </Box>
);

export default ListingGrid;
