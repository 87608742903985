import { getNames, registerLocale } from 'i18n-iso-countries';
import countriesEnLocale from 'i18n-iso-countries/langs/en.json';

registerLocale(countriesEnLocale);

export const getCountryName = (code: string | null): string => {
  const countries = getNames('en');
  if (code != null && countries?.[code] != null) {
    return countries[code];
  }
  return '';
};
