import BusinessRounded from '@mui/icons-material/BusinessRounded';
import { grey } from '@mui/material/colors';
import { Image } from '@realadvisor/image';

export const TeamAvatar: React.FC<{
  logoUrl?: string | null;
  size?: number;
}> = ({ logoUrl, size = 50 }) => {
  return (
    <div style={{ height: size, width: size, textAlign: 'center' }}>
      {logoUrl ? (
        <Image
          src={logoUrl}
          options={{ w: 256, f: 'jpg' }}
          objectFit="contain"
        />
      ) : (
        <BusinessRounded
          style={{ fontSize: size - 16, color: grey[400], margin: 8 }}
        />
      )}
    </div>
  );
};
