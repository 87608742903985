// @flow

import * as React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { Box, useResponsive } from 'react-system';

import { useLocale } from '../hooks/locale';

import type { leadLostDialogQuery } from './__generated__/leadLostDialogQuery.graphql';

type Props = {|
  title: string,
  open: boolean,
  onClose: () => void,
  onConfirm: (id: string, note: string) => void,
|};

const LeadLostDialogContent = ({ onClose, onConfirm }) => {
  const [index, setIndex] = React.useState(0);
  const { t } = useLocale();
  const data = useLazyLoadQuery<leadLostDialogQuery>(
    graphql`
      query leadLostDialogQuery {
        leadLostTypes: dictionaries(type: "lead_lost_types") {
          id
          label
        }
      }
    `,
    {},
  );

  const preset = (data.leadLostTypes ?? [])
    .filter(Boolean)
    .map(item => ({ id: item.id, label: item.label }));

  return (
    <>
      <DialogContent>
        {preset.map((item, i) => (
          <Box key={i}>
            <FormControlLabel
              label={item.label}
              control={
                <Radio checked={i === index} onChange={() => setIndex(i)} />
              }
            />
          </Box>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('back')}</Button>
        <Button
          color="primary"
          onClick={() => onConfirm(preset[index].id, preset[index].label)}
        >
          {t('ok')}
        </Button>
      </DialogActions>
    </>
  );
};

export const LeadLostDialog = (props: Props): React.Node => {
  const responsive = useResponsive();

  return (
    <Dialog
      maxWidth="xs"
      open={props.open}
      onClose={props.onClose}
      fullScreen={responsive([true, false])}
    >
      <DialogTitle>{props.title}</DialogTitle>
      <React.Suspense fallback={null}>
        <LeadLostDialogContent
          onClose={props.onClose}
          onConfirm={props.onConfirm}
        />
      </React.Suspense>
    </Dialog>
  );
};
