import { useCallback, useMemo } from 'react';

import {
  Box,
  FormControl,
  FormLabel,
  Paper,
  Stack,
  useMediaQuery,
} from '@mui/material';
import {
  type DateRange,
  DateRangePicker,
  LocalizationProvider,
  type PickersShortcutsItem,
} from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import {
  format,
  isValid,
  parse,
  startOfMonth,
  startOfYear,
  subDays,
} from 'date-fns';
import { useSearchParams } from 'react-router-dom';

import { useLocale } from '../../../src/hooks/locale';
import { TopbarTitle } from '../../../src/shared/topbar';
import { getTenantHostname } from '../../../src/utils/tenant';
import { TeamsSelect } from '../../components/teams-select/TeamsSelect';
import { useAppData } from '../../providers/AppDataProvider';

import { VisibilityGraph } from './VisibilityGraph';
import { VisibilityList } from './VisibilityList';

const yesterday = subDays(new Date(), 1);

const shortcutsItems: PickersShortcutsItem<DateRange<Date>>[] = [
  {
    label: 'Last 7 Days',
    getValue: () => {
      return [subDays(yesterday, 7), yesterday];
    },
  },
  {
    label: 'Last 28 Days',
    getValue: () => {
      return [subDays(yesterday, 28), yesterday];
    },
  },
  {
    label: 'Last Month',
    getValue: () => {
      const endOfPreviousMonth = subDays(startOfMonth(yesterday), 1);
      return [startOfMonth(endOfPreviousMonth), endOfPreviousMonth];
    },
  },
  {
    label: 'This Month',
    getValue: () => {
      return [startOfMonth(yesterday), yesterday];
    },
  },
  {
    label: 'This Year',
    getValue: () => {
      return [startOfYear(yesterday), yesterday];
    },
  },
  { label: 'All time', getValue: () => [null, null] },
];

type Props = {
  initialTeamId?: string;
  showTeamSelect?: boolean;
};

const dateFormat = 'dd-MM-yyyy';

const parseDateOrDefault = (dateString: string, defaultDate: Date) => {
  const parsedDate = parse(dateString, dateFormat, new Date());
  return isValid(parsedDate) ? parsedDate : defaultDate;
};

export const VisibilityStats = ({
  initialTeamId,
  showTeamSelect = true,
}: Props) => {
  const { t, dateLocale } = useLocale();
  const { me } = useAppData();
  const [searchParams, setSearchParams] = useSearchParams();
  const teams = me?.teams_users.map(tu => tu.team) ?? [];
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const yesterday = useMemo(() => subDays(new Date(), 1), []);
  const aMonthAgo = useMemo(() => subDays(yesterday, 28), [yesterday]);

  const getParsedDates = useCallback(() => {
    const startDateParam = searchParams.get('startDate') ?? '';
    const endDateParam = searchParams.get('endDate') ?? '';

    return {
      startDate: parseDateOrDefault(startDateParam, aMonthAgo),
      endDate: parseDateOrDefault(endDateParam, yesterday),
    };
  }, [searchParams, aMonthAgo, yesterday]);

  const { startDate, endDate, teamId, isAdmin, hostname } = useMemo(() => {
    const { startDate, endDate } = getParsedDates();
    const teamIdParam =
      searchParams.get('teamId') ??
      initialTeamId ??
      (me?.is_admin ? null : me?.default_team?.id);
    const hostname = me == null ? null : getTenantHostname(me.tenant_id);

    return {
      startDate,
      endDate,
      teamId: teamIdParam,
      isAdmin: me?.is_admin,
      hostname,
    };
  }, [getParsedDates, searchParams, initialTeamId, me]);

  const handleDateChange = (newValue: DateRange<Date>) => {
    const [newStartDate, newEndDate] = newValue;
    const { startDate: currentStartDate, endDate: currentEndDate } =
      getParsedDates();

    let startDate = newStartDate ?? currentStartDate;
    let endDate = newEndDate ?? currentEndDate;

    if (startDate && endDate && startDate.getTime() > endDate.getTime()) {
      // Swap the dates if startDate is greater than endDate
      [startDate, endDate] = [endDate, startDate];
    }

    if (startDate) {
      searchParams.set('startDate', format(startDate, dateFormat));
    }
    if (endDate) {
      searchParams.set('endDate', format(endDate, dateFormat));
    }

    setSearchParams(searchParams);
  };

  return (
    <Stack width="100%" alignItems="center">
      <TopbarTitle>{t('Visibility Statistics')}</TopbarTitle>
      <Stack width="100%" maxWidth={780} gap={2} p={2} zIndex={1}>
        <Stack component={Paper} p={2} gap={2}>
          {showTeamSelect &&
            ((teams?.length ?? 0) > 1 || me?.is_admin === true) && (
              <FormControl fullWidth>
                <FormLabel style={{ marginBottom: '0.5rem', fontWeight: 500 }}>
                  {t('Select Agency')}
                </FormLabel>
                <TeamsSelect
                  size="small"
                  onChange={value => {
                    searchParams.set('teamId', value ?? '');
                    setSearchParams(searchParams);
                  }}
                  teamId={teamId ?? undefined}
                  openOnFocus={true}
                />
              </FormControl>
            )}
          <Stack direction="row" spacing={2} useFlexGap flexWrap="wrap">
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={dateLocale}
            >
              <FormControl
                sx={{
                  flex: 1,
                }}
              >
                <FormLabel style={{ marginBottom: '0.5rem', fontWeight: 500 }}>
                  {t('Date Range')}
                </FormLabel>
                <DateRangePicker
                  slotProps={{
                    textField: {
                      size: 'small',
                      variant: 'outlined',
                      sx: { background: 'white', minWidth: '120px' },
                      hiddenLabel: true,
                    },
                    shortcuts: {
                      items: isMobile ? undefined : shortcutsItems,
                    },
                    actionBar: { actions: [] },
                  }}
                  maxDate={yesterday}
                  value={[startDate, endDate]}
                  localeText={{
                    start: '',
                    end: '',
                  }}
                  onChange={handleDateChange}
                />
              </FormControl>
            </LocalizationProvider>
          </Stack>
        </Stack>

        <Box component={Paper} bgcolor="#ffffff" overflow={'hidden'}>
          <VisibilityGraph
            teamId={teamId ?? undefined}
            isAdmin={isAdmin}
            startDate={startDate}
            endDate={endDate}
            hostname={hostname}
          />
        </Box>
        <Box bgcolor="#ffffff" overflow={'hidden'} component={Paper}>
          <VisibilityList
            teamId={teamId ?? undefined}
            isAdmin={isAdmin}
            availableTabs={isAdmin ? undefined : ['page_type', 'page_location']}
            startDate={startDate}
            endDate={endDate}
            hostname={hostname}
          />
        </Box>
      </Stack>
    </Stack>
  );
};

export default VisibilityStats;
