import type { IntlLocale } from '../../../src/locale';
import type {
  GetPropertyQuery,
  PropertyAddressFragment,
  PropertyFeaturesFragment,
  PropertyFormPricingFragment,
  PropertyPoiFragment,
} from '../../__generated__/graphql';
import type {
  FormDefinitionType,
  FormFieldDefinitionType,
} from '../form/RaForm';

import {
  type PropertyFormContext,
  type PropertyFormDetailsData,
  getPropertyDetailsFormDefinition,
} from './forms-definitions/propertyDetailsFormDefinition';
import {
  getEquipmentFormDefinition,
  getFeaturesFormDefinition,
} from './forms-definitions/propertyFeaturesDefinition';
import { poiFormDefinition } from './forms-definitions/propertyPoiDefinition';
import { pricingFormDefinition } from './forms-definitions/propertyPricingDefinition';

type Property = NonNullable<GetPropertyQuery['properties_by_pk']>;

export type PropertyFormData = PropertyFormDetailsData &
  Pick<Property, 'created_at' | 'created_by'> &
  PropertyAddressFragment &
  PropertyFeaturesFragment &
  PropertyPoiFragment &
  PropertyFormPricingFragment;

export const getPropertyFormDefinition =
  (
    useMinergieCode: boolean,
    currency: string | null | undefined,
    countryCode: string,
    locale: IntlLocale,
  ): FormDefinitionType<PropertyFormData, PropertyFormContext> =>
  ({ t, context }) =>
    [
      {
        name: 'address-title',
        label: t('Address'),
        type: 'category-title',
      },
      {
        label: t('Address'),
        type: 'address',
        required: false,
        gridProps: { xs: 12 },
      },
      ...(getPropertyDetailsFormDefinition(
        useMinergieCode,
        currency,
        countryCode,
        locale,
      )({ t, context }) as FormFieldDefinitionType<PropertyFormData>[]),

      // *******************
      // COSTS
      // *******************
      ...pricingFormDefinition({ t, context: undefined }),

      // *******************
      // DISTANCES
      // *******************
      {
        type: 'category-title',
        name: 'distances',
        label: t('Distances'),
      },
      ...(poiFormDefinition({
        t,
        context: undefined,
      }) as FormFieldDefinitionType<PropertyFormData>[]),

      // *******************
      // OTHER
      // *******************
      {
        type: 'category-title',
        name: 'other',
        label: t('Other'),
      },
      ...getEquipmentFormDefinition()({ t, context: undefined }),
      ...getFeaturesFormDefinition()({ t, context: undefined }),
      {
        name: 'meta',
        label: t('Metadata'),
        type: 'category-title',
      },
      {
        name: 'created_at',
        label: t('Created at'),
        type: 'date',
        disabled: () => true,
      },
      {
        name: 'created_by',
        label: t('Created by'),
        type: 'user',
        disabled: () => true,
      },
    ];
