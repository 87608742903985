import { useEffect, useMemo, useState } from 'react';

import { useLazyQuery, useQuery } from '@apollo/client';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import { Autocomplete, CircularProgress, debounce } from '@mui/material';

import { gql } from '../../../__generated__';
import type {
  LotSelectFragment,
  SearchLotsQuery,
} from '../../../__generated__/graphql';
import { formatAddress } from '../../../utils/formatting';
import { LotSelectItem, SEARCH_LOTS } from '../../LotsSelect';
import { useFiltersSearchParams } from '../useFiltersSearchParams';
import { useSchema } from '../useSchema';

import { FilterChip } from './FilterChip';
import type { QuickFilterProps } from './QuickFilters';
import { AutocompletePaper, AutocompleteTextField } from './QuickFilters';

const GET_DEFAULT_LISTINGS = gql(`
  query GetListingsByIds($ids: [uuid!]!) {
    lots(where: { id: { _in: $ids } }) {
      id
      ...LotSelect
    }
  }
`);

interface ListingMultiSelectProps {
  onClose?: () => void;
  onChange: (value: string[]) => void;
  defaultValue: string[];
}

export const ListingMultiSelect = ({
  onChange,
  onClose,
  defaultValue,
}: ListingMultiSelectProps) => {
  const [options, setOptions] = useState<LotSelectFragment[]>([]);
  const [searchListings, { loading }] =
    useLazyQuery<SearchLotsQuery>(SEARCH_LOTS);
  const [value, setValue] = useState<LotSelectFragment[]>([]);

  const { data: defaultListings } = useQuery(GET_DEFAULT_LISTINGS, {
    skip: !defaultValue,
    variables: { ids: defaultValue },
  });

  useEffect(() => {
    if (defaultListings?.lots) {
      setValue(defaultListings.lots);
    }
  }, [defaultListings]);

  const debouncedSearch = useMemo(
    () =>
      debounce(value => {
        searchListings({
          variables: {
            q: value,
          },
        }).then(response => {
          const newOptions = (response.data?.search_lots_tsv ?? []).filter(
            lot => !defaultValue.includes(lot.id),
          );
          setOptions(newOptions);
        });
      }, 275),
    [searchListings, defaultValue],
  );

  const handleSearch = (value: string) => {
    if (value) {
      debouncedSearch(value);
    } else {
      setOptions([]);
    }
  };

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      openOnFocus
      open
      disablePortal
      size="small"
      clearOnBlur={false}
      value={value}
      loading={loading}
      onClose={onClose}
      sx={{ width: 350 }}
      options={[...value, ...options]}
      getOptionLabel={option => formatAddress(option.property, ', ')}
      filterOptions={x => x}
      PaperComponent={AutocompletePaper}
      onInputChange={(_, value) => handleSearch(value)}
      onChange={(_, values) => {
        onChange(values.map(val => val.id));
      }}
      renderInput={params => (
        <AutocompleteTextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <LotSelectItem lot={option} props={props} selected={selected} />
      )}
    />
  );
};

export const ListingFilter = ({
  label,
  path,
  where,
  addWhereClause,
  deleteWhereClause,
  queryParamsScope,
  disabled,
}: QuickFilterProps) => {
  const [, setFiltersParams] = useFiltersSearchParams(queryParamsScope);
  const { getValueFromPath } = useSchema();
  const defaultValue = getValueFromPath(path, where)?.id?._in ?? [];

  const handleChange = (value: string[]) => {
    let newWhere = deleteWhereClause(where, path, true);
    if (value.length > 0) {
      newWhere = addWhereClause(newWhere, [...path, 'id', '_in'], value);
    }
    setFiltersParams(newWhere);
  };
  const handleDelete =
    defaultValue.length > 0 ? () => handleChange([]) : undefined;

  return (
    <FilterChip
      label={label}
      icon={<HomeWorkOutlinedIcon />}
      onDelete={handleDelete}
      disabled={disabled?.(where) ?? false}
      renderFilter={({ handleClose }) => (
        <ListingMultiSelect
          onClose={handleClose}
          onChange={handleChange}
          defaultValue={defaultValue}
        />
      )}
    />
  );
};
