/**
 * @generated SignedSource<<66d4249e96502ecdfdbb5b81d0d49466>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type LotMandateType = "commission_based" | "fixed_fee";
export type OfferStatus = "accepted" | "pending" | "refused";
export type SubscriptionStatus = "active" | "canceled" | "incomplete" | "incomplete_expired" | "past_due" | "trialing" | "unpaid";
export type LotSearchFilters = {|
  developmentId_eq?: ?string,
  id_nin?: ?$ReadOnlyArray<?string>,
  id_sort_first_in?: ?$ReadOnlyArray<?string>,
  search?: ?string,
|};
export type lotInputQuery$variables = {|
  filters?: ?LotSearchFilters,
|};
export type lotInputQuery$data = {|
  +lotsSearch: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +broker: ?{|
          +firstName: ?string,
          +id: string,
          +lastName: ?string,
          +organisation: ?{|
            +formattedAddress: ?string,
            +name: ?string,
          |},
          +primaryEmail: ?{|
            +email: string,
          |},
          +primaryImage: ?{|
            +url: string,
          |},
          +subscription: ?{|
            +status: SubscriptionStatus,
          |},
        |},
        +commissionRate: ?number,
        +fixedFee: ?number,
        +id: string,
        +lotReference: ?string,
        +mandateType: LotMandateType,
        +offers: ?$ReadOnlyArray<{|
          +amount: number,
          +createdAt: string,
          +status: OfferStatus,
        |}>,
        +primaryPropertyImage: ?{|
          +image: {|
            +url: string,
          |},
        |},
        +property: {|
          +formattedAddress: ?string,
          +id: string,
        |},
        +salePrice: ?number,
        +seller: ?{|
          +firstName: ?string,
          +id: string,
          +lastName: ?string,
          +organisation: ?{|
            +formattedAddress: ?string,
            +name: ?string,
          |},
          +primaryEmail: ?{|
            +email: string,
          |},
          +primaryImage: ?{|
            +url: string,
          |},
          +subscription: ?{|
            +status: SubscriptionStatus,
          |},
        |},
        +title: ?string,
      |},
    |}>,
  |},
|};
export type lotInputQuery = {|
  response: lotInputQuery$data,
  variables: lotInputQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lotReference",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "salePrice",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mandateType",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fixedFee",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "commissionRate",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "formattedAddress",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Property",
  "kind": "LinkedField",
  "name": "property",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v9/*: any*/)
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v12 = [
  (v11/*: any*/)
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v18 = [
  (v2/*: any*/),
  (v13/*: any*/),
  (v14/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Email",
    "kind": "LinkedField",
    "name": "primaryEmail",
    "plural": false,
    "selections": [
      (v15/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Image",
    "kind": "LinkedField",
    "name": "primaryImage",
    "plural": false,
    "selections": (v12/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Organisation",
    "kind": "LinkedField",
    "name": "organisation",
    "plural": false,
    "selections": [
      (v16/*: any*/),
      (v9/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Subscription",
    "kind": "LinkedField",
    "name": "subscription",
    "plural": false,
    "selections": [
      (v17/*: any*/)
    ],
    "storageKey": null
  }
],
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v21 = [
  (v11/*: any*/),
  (v2/*: any*/)
],
v22 = [
  (v2/*: any*/),
  (v13/*: any*/),
  (v14/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Email",
    "kind": "LinkedField",
    "name": "primaryEmail",
    "plural": false,
    "selections": [
      (v15/*: any*/),
      (v2/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Image",
    "kind": "LinkedField",
    "name": "primaryImage",
    "plural": false,
    "selections": (v21/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Organisation",
    "kind": "LinkedField",
    "name": "organisation",
    "plural": false,
    "selections": [
      (v16/*: any*/),
      (v9/*: any*/),
      (v2/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Subscription",
    "kind": "LinkedField",
    "name": "subscription",
    "plural": false,
    "selections": [
      (v17/*: any*/),
      (v2/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "lotInputQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LotConnectionConnection",
        "kind": "LinkedField",
        "name": "lotsSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LotConnectionEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Lot",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PropertyImage",
                    "kind": "LinkedField",
                    "name": "primaryPropertyImage",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Image",
                        "kind": "LinkedField",
                        "name": "image",
                        "plural": false,
                        "selections": (v12/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "broker",
                    "plural": false,
                    "selections": (v18/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "seller",
                    "plural": false,
                    "selections": (v18/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Offer",
                    "kind": "LinkedField",
                    "name": "offers",
                    "plural": true,
                    "selections": [
                      (v17/*: any*/),
                      (v19/*: any*/),
                      (v20/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "lotInputQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LotConnectionConnection",
        "kind": "LinkedField",
        "name": "lotsSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LotConnectionEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Lot",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PropertyImage",
                    "kind": "LinkedField",
                    "name": "primaryPropertyImage",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Image",
                        "kind": "LinkedField",
                        "name": "image",
                        "plural": false,
                        "selections": (v21/*: any*/),
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "broker",
                    "plural": false,
                    "selections": (v22/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "seller",
                    "plural": false,
                    "selections": (v22/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Offer",
                    "kind": "LinkedField",
                    "name": "offers",
                    "plural": true,
                    "selections": [
                      (v17/*: any*/),
                      (v19/*: any*/),
                      (v20/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f10c6aaabe9b4ae7ed1054bb5ba9a295",
    "id": null,
    "metadata": {},
    "name": "lotInputQuery",
    "operationKind": "query",
    "text": "query lotInputQuery(\n  $filters: LotSearchFilters\n) {\n  lotsSearch(first: 20, filters: $filters) {\n    edges {\n      node {\n        id\n        title\n        lotReference\n        salePrice\n        mandateType\n        fixedFee\n        commissionRate\n        property {\n          id\n          formattedAddress\n        }\n        primaryPropertyImage {\n          image {\n            url\n            id\n          }\n          id\n        }\n        broker {\n          id\n          firstName\n          lastName\n          primaryEmail {\n            email\n            id\n          }\n          primaryImage {\n            url\n            id\n          }\n          organisation {\n            name\n            formattedAddress\n            id\n          }\n          subscription {\n            status\n            id\n          }\n        }\n        seller {\n          id\n          firstName\n          lastName\n          primaryEmail {\n            email\n            id\n          }\n          primaryImage {\n            url\n            id\n          }\n          organisation {\n            name\n            formattedAddress\n            id\n          }\n          subscription {\n            status\n            id\n          }\n        }\n        offers {\n          status\n          amount\n          createdAt\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "e8a300a83e9b676797fc3b2bc7cb55cc";

export default node;
