// @flow

import * as React from 'react';

import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { Form, useFormik } from '@realadvisor/form';
import { graphql, useLazyLoadQuery, useMutation } from 'react-relay';
import { Box, Flex } from 'react-system';

import { OrganisationLegacyInput } from '../../shared/organisation-input';
import { ConfirmDialog } from '../controls/ConfirmDialog';
import { DateInput, formatDate, parseDate } from '../controls/date-input';
import { DrawerBottomToolbar } from '../controls/drawer';
import { PercentInput } from '../controls/percent-input';
import { PriceInput } from '../controls/price-input';
import { ProgressButton } from '../controls/progress-button';
import { SelectInput } from '../controls/select-input';
import { useLocale } from '../hooks/locale';
import { useTheme } from '../hooks/theme';
import { AddCircleOutline } from '../icons/add-circle-outline';
import { MinusCircleOutline } from '../icons/minus-circle-outline';
import { PlusCircleOutline } from '../icons/plus-circle-outline';
import { type User, UserInput } from '../shared/user-input';
import { UserMultiInput, getMultiUserShrink } from '../shared/user-input';
import { toISODateString } from '../utils/date-utils';
import { number_of_string, string_of_number } from '../utils/number-format';

import type {
  MortgageTrancheType,
  PropertyMortgageFormDataQuery,
} from './__generated__/PropertyMortgageFormDataQuery.graphql';
import type { PropertyMortgageFormDeleteMortgageMutation } from './__generated__/PropertyMortgageFormDeleteMortgageMutation.graphql';
import type { PropertyMortgageFormFinancingInstitutionTypesQuery } from './__generated__/PropertyMortgageFormFinancingInstitutionTypesQuery.graphql';
import type { PropertyMortgageFormUpsertMortgageMutation } from './__generated__/PropertyMortgageFormUpsertMortgageMutation.graphql';

graphql`
  fragment PropertyMortgageForm_mortgage on Mortgage {
    id
    lender {
      ...organisationInput_organisation @relay(mask: false)
    }
    mortgageBroker {
      ...userInput_user @relay(mask: false)
    }
    borrowerPropertyLocation
    loanAmount
    value
    signedAt
    tranches {
      interestRate
      loanAmount
      type
      startDate
      expiryDate
    }
    property {
      id
      owners {
        ...userInput_user @relay(mask: false)
      }
      lead {
        mortgageRevenue
        mortgageInsuranceRevenue
        mortgageInsuranceProvider {
          ...organisationInput_organisation @relay(mask: false)
        }
        contact {
          ...userInput_user @relay(mask: false)
          id
        }
        secondContact {
          ...userInput_user @relay(mask: false)
          id
        }
        broker {
          ...userInput_user @relay(mask: false)
          id
        }
        mortgageLender {
          ...organisationInput_organisation @relay(mask: false)
        }
      }
      financingInstitution {
        id
      }
    }
    totalDebt
  }
`;

type Tranche = {|
  interestRate: string,
  loanAmount: string,
  type: ?MortgageTrancheType,
  startDate: string,
  expiryDate: string,
|};

const useUpsertMortgage = () => {
  return useMutation<PropertyMortgageFormUpsertMortgageMutation>(
    graphql`
      mutation PropertyMortgageFormUpsertMortgageMutation(
        $input: UpsertMortgageInput!
      ) {
        upsertMortgage(input: $input) {
          node {
            ...PropertyMortgageForm_mortgage
            property {
              ...UserFinancingPropertyCard_property
            }
          }
        }
      }
    `,
  );
};

const MAX_AMOUNT_VALUE = 1_000_000_000;

const validate = ({ values, t }) => {
  const errors = {};
  if ((number_of_string(values.value) ?? 0) > MAX_AMOUNT_VALUE) {
    errors.value = t('maxAmountValue', {
      value: MAX_AMOUNT_VALUE,
    });
  }
  return errors;
};

const MortgageForm = ({ mortgage, retry }) => {
  const { t } = useLocale();
  const { text } = useTheme();
  const [upsertMortgage, upserting] = useUpsertMortgage();

  const [deleteMortgage, deleting] =
    useMutation<PropertyMortgageFormDeleteMortgageMutation>(
      graphql`
        mutation PropertyMortgageFormDeleteMortgageMutation(
          $input: DeleteMortgageInput!
        ) {
          deleteMortgage(input: $input) {
            deletedNodeId
          }
        }
      `,
    );

  const response =
    useLazyLoadQuery<PropertyMortgageFormFinancingInstitutionTypesQuery>(
      graphql`
        query PropertyMortgageFormFinancingInstitutionTypesQuery {
          financingInstitutionTypes {
            id
            name
          }
        }
      `,
      {},
      { fetchPolicy: 'store-and-network' },
    );

  const financingInstitutionTypes = (response.financingInstitutionTypes ?? [])
    .filter(Boolean)
    .map(x => ({
      id: x.id,
      label: x.name,
    }));

  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const financingLead = mortgage.property.lead ?? {};
  const borrowersAsArray: Array<User> = [];
  if (financingLead.contact != null) {
    borrowersAsArray.push(financingLead.contact);
  }
  if (financingLead.secondContact != null) {
    borrowersAsArray.push(financingLead.secondContact);
  }

  const formik = useFormik({
    initialValues: {
      owners:
        mortgage.property.owners && mortgage.property.owners?.length > 0
          ? (mortgage.property.owners: $ReadOnlyArray<User>)
          : borrowersAsArray,
      lender: mortgage.lender ?? financingLead.mortgageLender,
      mortgageInsuranceProvider: financingLead.mortgageInsuranceProvider,
      broker: mortgage.mortgageBroker ?? financingLead.broker,
      value: string_of_number(mortgage.value),
      mortgageRevenue: string_of_number(financingLead.mortgageRevenue),
      mortgageInsuranceRevenue: string_of_number(
        financingLead.mortgageInsuranceRevenue,
      ),
      financingInstitutionId: mortgage.property.financingInstitution?.id,
      signedAt:
        mortgage.signedAt != null
          ? formatDate(new Date(mortgage.signedAt))
          : '',
      tranches: (mortgage.tranches
        .filter(Boolean)
        .map(({ loanAmount, interestRate, type, startDate, expiryDate }) => ({
          loanAmount: string_of_number(loanAmount),
          interestRate: string_of_number(interestRate),
          type,
          startDate: startDate != null ? formatDate(new Date(startDate)) : '',
          expiryDate:
            expiryDate != null ? formatDate(new Date(expiryDate)) : '',
        })): $ReadOnlyArray<Tranche>),
      borrowerPropertyLocation: mortgage.borrowerPropertyLocation,
    },
    validate: values => validate({ values, t }),
    onSubmit: ({
      owners,
      lender,
      broker,
      value,
      signedAt,
      tranches,
      financingInstitutionId,
      borrowerPropertyLocation,
    }) => {
      upsertMortgage({
        variables: {
          input: {
            node: {
              id: mortgage.id,
              property: {
                financingInstitutionId,
                ownersIds: owners.map(owner => owner.id),
              },
              lenderId: lender?.id,
              mortgageBrokerId: broker?.id,
              value: number_of_string(value),
              borrowerPropertyLocation,
              tranches: tranches.map(
                ({
                  loanAmount,
                  interestRate,
                  type,
                  startDate,
                  expiryDate,
                }) => ({
                  loanAmount: number_of_string(loanAmount),
                  interestRate: number_of_string(interestRate),
                  type,
                  startDate: toISODateString(parseDate(startDate)),
                  expiryDate: toISODateString(parseDate(expiryDate)),
                }),
              ),
              signedAt: toISODateString(parseDate(signedAt)),
            },
          },
        },
        onCompleted: () => {
          retry();
          formik.resetForm;
        },
      });
    },
  });

  const {
    changed,
    resetForm,
    submitForm,
    values,
    setValues,
    // setTouched,
  } = formik;

  const deleteTranche = index => {
    setValues({
      tranches: values.tranches.filter((d, i) => i !== index),
    });
  };

  const addTranche = () =>
    setValues({
      tranches: [
        ...values.tranches,
        {
          interestRate: '',
          loanAmount: '',
          type: 'fixed',
          startDate: '',
          expiryDate: '',
        },
      ],
    });

  const setTrancheValues = (index, trancheValues: $Shape<Tranche>) => {
    setValues({
      tranches: values.tranches.map((d, i) => {
        if (i === index) {
          return { ...d, ...trancheValues };
        }
        return d;
      }),
    });
  };

  const propertyLocationItems = [
    {
      label: t('switzerland'),
      id: 'switzerland',
    },
    {
      label: t('outsideSwitzerland'),
      id: 'outside_switzerland',
    },
  ];

  return (
    <>
      <ConfirmDialog
        isSubmitting={deleting}
        open={deleteDialogOpen}
        title={t('areYouSureToDeleteMortgage')}
        okText={t('delete')}
        onClose={() => setDeleteDialogOpen(false)}
        onSubmit={() => {
          deleteMortgage({
            variables: {
              input: {
                id: mortgage.id,
              },
            },
            onCompleted: retry,
          });
        }}
      />
      <Form onSubmit={submitForm}>
        <Flex flexWrap="wrap" p={3}>
          {mortgage.property.financingLead != null && (
            <>
              <Box width={1} p={2}>
                <Box css={text.body1}>{t('lifeInsurance')}</Box>
              </Box>
              <Box width={[1, 1, 1 / 2]} p={2}>
                <FormControl>
                  <InputLabel>{t('insuranceProvider')}</InputLabel>
                  <OrganisationLegacyInput
                    value={values.mortgageInsuranceProvider}
                    onChange={value =>
                      setValues({ mortgageInsuranceProvider: value })
                    }
                  />
                </FormControl>
              </Box>
              <Box width={[1, 1, 1 / 2]} p={2}>
                <FormControl>
                  <InputLabel shrink={true}>{t('insuranceRevenue')}</InputLabel>
                  <PriceInput
                    value={values.mortgageInsuranceRevenue}
                    onChange={value =>
                      setValues({ mortgageInsuranceRevenue: value })
                    }
                  />
                </FormControl>
              </Box>
            </>
          )}
          <Box width={1} p={2}>
            <Box css={text.body1}>{t('mortgage')}</Box>
          </Box>
          <Box width={1} p={2}>
            <FormControl>
              <InputLabel shrink={getMultiUserShrink(values.owners)}>
                {t('owners')}
              </InputLabel>
              <UserMultiInput
                value={values.owners}
                onChange={value => setValues({ owners: value })}
              />
            </FormControl>
          </Box>
          <Box width={[1, 1, 1 / 2]} p={2}>
            <FormControl>
              <InputLabel>{t('broker')}</InputLabel>
              <UserInput
                creatable={false}
                filters={{ isBroker: true }}
                value={values.broker}
                onChange={value => setValues({ broker: value })}
              />
            </FormControl>
          </Box>
          <Box width={[1, 1, 1 / 2]} p={2}>
            <FormControl>
              <InputLabel shrink={true}>{t('mortgageRevenue')}</InputLabel>
              <PriceInput
                value={values.mortgageRevenue}
                onChange={value => setValues({ mortgageRevenue: value })}
              />
            </FormControl>
          </Box>
          <Box width={[1, 1, 1 / 2]} p={2}>
            <FormControl>
              <InputLabel>{t('lender')}</InputLabel>
              <OrganisationLegacyInput
                value={values.lender}
                onChange={value => setValues({ lender: value })}
              />
            </FormControl>
          </Box>
          <Box width={[1, 1, 1 / 2]} p={2}>
            <FormControl error={formik.errors.value != null}>
              <InputLabel shrink={true}>{t('value')}</InputLabel>
              <PriceInput
                value={values.value}
                onChange={value => setValues({ value })}
              />
              {formik.errors.value != null && (
                <FormHelperText>{formik.errors.value}</FormHelperText>
              )}
            </FormControl>
          </Box>
          <Box width={[1, 1, 1 / 2]} p={2}>
            <FormControl>
              <InputLabel>{t('signedAt')}</InputLabel>
              <DateInput
                value={values.signedAt}
                onChange={signedAt => setValues({ signedAt })}
              />
            </FormControl>
          </Box>
          <Box width={[1, 1, 1 / 2]} p={2}>
            <FormControl disabled={true}>
              <InputLabel shrink={true}>{t('totalDebt')}</InputLabel>
              <PriceInput
                value={string_of_number(mortgage.totalDebt)}
                onChange={() => {}}
              />
            </FormControl>
          </Box>
          <Box width={[1, 1, 1 / 2]} p={2}>
            <FormControl>
              <InputLabel shrink={true}>
                {t('mortgagePropertyLocation')}
              </InputLabel>
              <SelectInput
                items={propertyLocationItems}
                value={values.borrowerPropertyLocation}
                onChange={value =>
                  setValues({ borrowerPropertyLocation: value })
                }
              />
            </FormControl>
          </Box>

          <Box width={1} p={2} mt={2}>
            <Box css={text.body1}>
              {t('tranchesSummary', { count: values.tranches.length })}
            </Box>
          </Box>

          <Box width={[1, 1, 1 / 2]} p={2}>
            <FormControl>
              <InputLabel shrink={true}>{t('financialInstitution')}</InputLabel>
              <SelectInput
                items={financingInstitutionTypes}
                value={values.financingInstitutionId}
                onChange={financingInstitutionId =>
                  setValues({ financingInstitutionId })
                }
              />
            </FormControl>
          </Box>

          {values.tranches.map((tranche, index) => (
            <React.Fragment key={index}>
              <Flex flexDirection="column">
                <Flex>
                  <Box css={text.buttonText} p={2}>
                    {t('trancheNumber', {
                      number: index + 1,
                    })}
                  </Box>
                </Flex>
                <Flex>
                  <Flex flexGrow={1} flexWrap="wrap" px={2}>
                    <Box width={[1, 1, 1 / 2]} p={2}>
                      <FormControl>
                        <InputLabel shrink={true}>{t('loanAmount')}</InputLabel>
                        <PriceInput
                          value={tranche.loanAmount}
                          onChange={value =>
                            setTrancheValues(index, { loanAmount: value })
                          }
                        />
                      </FormControl>
                    </Box>
                    <Box width={[1, 1, 1 / 2]} p={2}>
                      <FormControl>
                        <InputLabel>{t('startDate')}</InputLabel>
                        <DateInput
                          value={tranche.startDate}
                          onChange={startDate =>
                            setTrancheValues(index, { startDate })
                          }
                        />
                      </FormControl>
                    </Box>
                    <Flex width={[1, 1, 1 / 2]}>
                      <Box flexGrow={1} p={2}>
                        <TextField
                          variant="filled"
                          select={true}
                          fullWidth={true}
                          label={t('Type')}
                          value={tranche.type}
                          onChange={evt =>
                            setTrancheValues(index, {
                              type: (evt.target.value: any),
                            })
                          }
                        >
                          <MenuItem value="fixed">{t('fixed')}</MenuItem>
                          <MenuItem value="variable">{t('variable')}</MenuItem>
                        </TextField>
                      </Box>
                      <Box width="100px" p={2}>
                        <FormControl>
                          <InputLabel shrink={true}>{t('rate')}</InputLabel>
                          <PercentInput
                            value={tranche.interestRate}
                            onChange={value =>
                              setTrancheValues(index, { interestRate: value })
                            }
                          />
                        </FormControl>
                      </Box>
                    </Flex>
                    <Box width={[1, 1, 1 / 2]} p={2}>
                      <FormControl>
                        <InputLabel>{t('expiryDate')}</InputLabel>
                        <DateInput
                          value={tranche.expiryDate}
                          onChange={expiryDate =>
                            setTrancheValues(index, { expiryDate })
                          }
                        />
                      </FormControl>
                    </Box>
                  </Flex>
                  <Flex alignItems="center" justifyContent="center" p={2}>
                    <IconButton onClick={() => deleteTranche(index)}>
                      <MinusCircleOutline />
                    </IconButton>
                  </Flex>
                </Flex>
              </Flex>
              <Divider css={{ width: '100%' }} />
            </React.Fragment>
          ))}
        </Flex>
        <Flex alignItems="center" justifyContent="center" p={3} mb={3}>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<PlusCircleOutline />}
            onClick={() => addTranche()}
          >
            {t('addTranche')}
          </Button>
        </Flex>
        <DrawerBottomToolbar>
          <Box mr="auto">
            <Button
              color="secondary"
              component="button"
              onClick={() => setDeleteDialogOpen(true)}
            >
              {t('delete')}
            </Button>
          </Box>
          <Button disabled={!changed} onClick={resetForm}>
            {t('cancel')}
          </Button>
          <ProgressButton
            variant="text"
            disabled={!changed}
            loading={upserting}
            onClick={submitForm}
          >
            {t('save')}
          </ProgressButton>
        </DrawerBottomToolbar>
      </Form>
    </>
  );
};

type Props = {|
  propertyId: string,
  retry: () => void,
|};

export const PropertyMortgageForm = (props: Props): React.Node => {
  const { t } = useLocale();
  const { text, textColor } = useTheme();
  const [fetchKey, retry] = React.useReducer(d => d + 1, 0);
  const [upsertMortgage, upserting] = useUpsertMortgage();

  const { property } = useLazyLoadQuery<PropertyMortgageFormDataQuery>(
    graphql`
      query PropertyMortgageFormDataQuery($propertyId: ID!) {
        property: node(id: $propertyId) {
          ... on Property {
            id
            mortgage {
              ...PropertyMortgageForm_mortgage @relay(mask: false)
            }
          }
        }
      }
    `,
    { propertyId: props.propertyId },
    { fetchKey, fetchPolicy: 'store-and-network' },
  );

  const retryParent = () => {
    props.retry();
    retry();
  };

  return !property?.mortgage ? (
    <Flex
      flexGrow={1}
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Box css={[text.body1, textColor('disabledText')]} mb={3}>
        {t('thisPropertyHasNoMortgage')}
      </Box>
      <ProgressButton
        variant="outlined"
        color="primary"
        loading={upserting}
        startIcon={<AddCircleOutline />}
        onClick={() => {
          upsertMortgage({
            variables: {
              input: {
                node: { propertyId: property?.id },
              },
            },
            onCompleted: retry,
          });
        }}
      >
        {t('createMortgage')}
      </ProgressButton>
    </Flex>
  ) : (
    <MortgageForm mortgage={property.mortgage} retry={retryParent} />
  );
};
