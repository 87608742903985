/**
 * @generated SignedSource<<4c2dd304e410ef35f2fc1753b2d919b3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LeadAppraisalReason = "already_for_sale" | "buy_soon" | "buy_this" | "inheritance" | "move_rental" | "not_selling" | "not_set" | "other" | "other_not_owner" | "other_owner" | "other_renting" | "refinance" | "sell" | "separation";
export type LeadBuyHorizon = "not_set" | "over_12_months" | "under_12_months" | "under_3_months";
export type LeadRelationship = "agent" | "buyer" | "heir" | "not_set" | "other" | "owner" | "tenant";
export type LeadSaleHorizon = "already_for_sale" | "from_1_to_2_years" | "from_2_years" | "from_6_to_12_months" | "not_selling" | "not_set" | "under_6_months";
export type SortDirection = "asc" | "desc";
export type LeadsFilters = {
  appraisalNextStep_in?: ReadonlyArray<string | null> | null;
  appraisalOpenToOffer_in?: ReadonlyArray<string | null> | null;
  appraisalPerception_in?: ReadonlyArray<string | null> | null;
  appraisalPropertyOccupied_in?: ReadonlyArray<string | null> | null;
  appraisalPropertyUsage_in?: ReadonlyArray<string | null> | null;
  appraisalReason_in?: ReadonlyArray<LeadAppraisalReason> | null;
  appraisalValue_gte?: number | null;
  appraisalValue_lte?: number | null;
  brokerId_in?: ReadonlyArray<string | null> | null;
  buyHorizon_in?: ReadonlyArray<LeadBuyHorizon> | null;
  claimedBy_eq?: string | null;
  completed_in?: ReadonlyArray<boolean> | null;
  contactId_in?: ReadonlyArray<string | null> | null;
  contacts_assets_gte?: number | null;
  contacts_assets_lte?: number | null;
  contacts_income_gte?: number | null;
  contacts_income_lte?: number | null;
  createdAt_inRange?: DateRange | null;
  createdBy_in?: ReadonlyArray<string | null> | null;
  id_in?: ReadonlyArray<string | null> | null;
  leadLostId_in?: ReadonlyArray<string | null> | null;
  mandateProbability_in?: ReadonlyArray<number | null> | null;
  mortgageBuyingStatus_in?: ReadonlyArray<string | null> | null;
  mortgagePropertyValue_gte?: number | null;
  mortgagePropertyValue_lte?: number | null;
  mortgageStatus_in?: ReadonlyArray<string | null> | null;
  mortgage_type_in?: ReadonlyArray<string | null> | null;
  nextActivity_exists?: boolean | null;
  nextActivity_overdue?: boolean | null;
  nextActivity_type_in?: ReadonlyArray<string> | null;
  organisationId_in?: ReadonlyArray<string | null> | null;
  pipelineId_eq?: string | null;
  potentialDevelopment_eq?: boolean | null;
  predictedListingDate_inRange?: DateRange | null;
  property_commercialSurface_gte?: number | null;
  property_commercialSurface_lte?: number | null;
  property_googleAddress_lat_gte?: number | null;
  property_googleAddress_lat_lte?: number | null;
  property_googleAddress_lng_gte?: number | null;
  property_googleAddress_lng_lte?: number | null;
  property_landSurface_gte?: number | null;
  property_landSurface_lte?: number | null;
  property_livingSurface_gte?: number | null;
  property_livingSurface_lte?: number | null;
  property_placeId_in?: ReadonlyArray<string> | null;
  property_propertyType_name_in?: ReadonlyArray<string | null> | null;
  property_residentialSurface_gte?: number | null;
  property_residentialSurface_lte?: number | null;
  receivedMortgageOffer_in?: ReadonlyArray<boolean | null> | null;
  relationship_in?: ReadonlyArray<LeadRelationship> | null;
  saleHorizon_in?: ReadonlyArray<LeadSaleHorizon> | null;
  sourceId_in?: ReadonlyArray<string | null> | null;
  stageId_in?: ReadonlyArray<string | null> | null;
  stageName_in?: ReadonlyArray<string | null> | null;
  status_in?: ReadonlyArray<string | null> | null;
  tags_in?: ReadonlyArray<string | null> | null;
};
export type DateRange = {
  endDate?: string | null;
  mostRecent?: boolean | null;
  startDate?: string | null;
  useRequalifiedIfAvailable?: boolean | null;
};
export type leadsMapPaginationQuery$variables = {
  count: number;
  cursor?: string | null;
  filters?: LeadsFilters | null;
  sortBy?: string | null;
  sortDirection?: SortDirection | null;
};
export type leadsMapPaginationQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"leadsMap_root">;
};
export type leadsMapPaginationQuery = {
  response: leadsMapPaginationQuery$data;
  variables: leadsMapPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortBy"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortDirection"
  }
],
v1 = {
  "kind": "Variable",
  "name": "filters",
  "variableName": "filters"
},
v2 = {
  "kind": "Variable",
  "name": "sortBy",
  "variableName": "sortBy"
},
v3 = {
  "kind": "Variable",
  "name": "sortDirection",
  "variableName": "sortDirection"
},
v4 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v1/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lat",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lng",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "leadsMapPaginationQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          {
            "kind": "Variable",
            "name": "cursor",
            "variableName": "cursor"
          },
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "leadsMap_root"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "leadsMapPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "LeadConnectionConnection",
        "kind": "LinkedField",
        "name": "leads",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LeadConnectionEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Lead",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "requalifiedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "appraisalPerception",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "appraisalPerceivedValue",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "userCanViewLeadDetails",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "completed",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Dictionary",
                    "kind": "LinkedField",
                    "name": "source",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "contact",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "firstName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Email",
                        "kind": "LinkedField",
                        "name": "primaryEmail",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PhoneNumber",
                        "kind": "LinkedField",
                        "name": "primaryPhoneNumber",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "number",
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Image",
                        "kind": "LinkedField",
                        "name": "primaryImage",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Property",
                    "kind": "LinkedField",
                    "name": "property",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "route",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "streetNumber",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "postcode",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "locality",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "state",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PropertyType",
                        "kind": "LinkedField",
                        "name": "propertyType",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "mainType",
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Appraisal",
                        "kind": "LinkedField",
                        "name": "latestAppraisal",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "RealadvisorAppraisal",
                            "kind": "LinkedField",
                            "name": "realadvisor",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "max",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "min",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "filters": [],
        "handle": "connection",
        "key": "Connection_leads",
        "kind": "LinkedHandle",
        "name": "leads"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantSettings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MapPositionWithZoom",
            "kind": "LinkedField",
            "name": "defaultMapCoordinates",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "z",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isAdmin",
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8c75d71fd90cfe2ed0cac09b0b4935d2",
    "id": null,
    "metadata": {},
    "name": "leadsMapPaginationQuery",
    "operationKind": "query",
    "text": "query leadsMapPaginationQuery(\n  $count: Int!\n  $cursor: String = null\n  $filters: LeadsFilters\n  $sortBy: String\n  $sortDirection: SortDirection\n) {\n  ...leadsMap_root_28BXUr\n}\n\nfragment leadUpdatedTimeAgo_lead on Lead {\n  createdAt\n  requalifiedAt\n}\n\nfragment leadsMap_root_28BXUr on Query {\n  leads(first: $count, after: $cursor, sortBy: $sortBy, sortDirection: $sortDirection, filters: $filters) {\n    edges {\n      node {\n        id\n        ...leadUpdatedTimeAgo_lead\n        appraisalPerception\n        appraisalPerceivedValue\n        userCanViewLeadDetails\n        completed\n        source {\n          name\n          id\n        }\n        contact {\n          ...userInfo_user\n          firstName\n          lastName\n          primaryEmail {\n            email\n            id\n          }\n          id\n        }\n        property {\n          lat\n          lng\n          route\n          streetNumber\n          postcode\n          locality\n          state\n          propertyType {\n            mainType\n            id\n          }\n          latestAppraisal {\n            realadvisor {\n              max\n              min\n            }\n            id\n          }\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  tenantSettings {\n    defaultMapCoordinates {\n      lng\n      lat\n      z\n    }\n    id\n  }\n  me {\n    isAdmin\n    id\n  }\n}\n\nfragment userInfo_user on User {\n  id\n  firstName\n  lastName\n  primaryEmail {\n    email\n    id\n  }\n  primaryPhoneNumber {\n    number\n    id\n  }\n  primaryImage {\n    url\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "74c3d4c023a6e402aefc8470967257dc";

export default node;
