/**
 * @generated SignedSource<<b1f6130383cf0e1565a8ab4775d18b52>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type organisationFilter_root$data = {
  readonly organisations_search_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string | null;
        readonly organisation_images: ReadonlyArray<{
          readonly image: {
            readonly url: string | null;
          };
        }>;
      };
    }>;
  };
  readonly " $fragmentType": "organisationFilter_root";
};
export type organisationFilter_root$key = {
  readonly " $data"?: organisationFilter_root$data;
  readonly " $fragmentSpreads": FragmentRefs<"organisationFilter_root">;
};

import organisationFilterPaginationQuery_graphql from './organisationFilterPaginationQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  "organisations_search_connection"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "is_lender"
    },
    {
      "defaultValue": "{}",
      "kind": "LocalArgument",
      "name": "order_by_first_ids"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "search"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": organisationFilterPaginationQuery_graphql
    }
  },
  "name": "organisationFilter_root",
  "selections": [
    {
      "alias": "organisations_search_connection",
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "order_by_first_ids",
              "variableName": "order_by_first_ids"
            },
            {
              "kind": "Variable",
              "name": "search",
              "variableName": "search"
            }
          ],
          "kind": "ObjectValue",
          "name": "args"
        },
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "is_lender",
              "variableName": "is_lender"
            }
          ],
          "kind": "ObjectValue",
          "name": "where"
        }
      ],
      "concreteType": "organisationsConnection",
      "kind": "LinkedField",
      "name": "__organisationFilter__organisations_search_connection_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "organisationsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "organisations",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "order_by",
                      "value": {
                        "is_primary": "desc"
                      }
                    }
                  ],
                  "concreteType": "organisation_images",
                  "kind": "LinkedField",
                  "name": "organisation_images",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "images",
                      "kind": "LinkedField",
                      "name": "image",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "url",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "organisation_images(order_by:{\"is_primary\":\"desc\"})"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "query_root",
  "abstractKey": null
};
})();

(node as any).hash = "5ddd50f0af82812be77966f8bc8d3c54";

export default node;
