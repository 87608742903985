/**
 * @generated SignedSource<<66e2d6ff077973b102aa824be3ccb09e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Feed_root$data = {
  readonly " $fragmentSpreads": FragmentRefs<"FeedEmailForm_root" | "FeedItem_root" | "FeedNewForm_root">;
  readonly " $fragmentType": "Feed_root";
};
export type Feed_root$key = {
  readonly " $data"?: Feed_root$data;
  readonly " $fragmentSpreads": FragmentRefs<"Feed_root">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Feed_root",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeedItem_root"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeedNewForm_root"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeedEmailForm_root"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "3519249562f0502e3bcfdd4b1d1aa431";

export default node;
