/**
 * @generated SignedSource<<9c75b514d01e486e5dd7e5ba7735385b>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type PoisFilters = {|
  bbox?: ?$ReadOnlyArray<?number>,
  completed_in?: ?$ReadOnlyArray<?boolean>,
  endDate?: ?string,
  id_eq?: ?string,
  mostRecent?: ?boolean,
  nextActivity?: ?$ReadOnlyArray<?string>,
  placeId?: ?string,
  saleHorizon_in?: ?$ReadOnlyArray<?string>,
  startDate?: ?string,
  status_in?: ?$ReadOnlyArray<?string>,
  useRequalifiedIfAvailable?: ?boolean,
|};
export type PoiMapWithPropsQuery$variables = {|
  count: number,
  filters?: ?PoisFilters,
|};
export type PoiMapWithPropsQuery$data = {|
  +pois: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +properties: $ReadOnlyArray<{|
          +route: ?string,
          +streetNumber: ?string,
          +users: ?$ReadOnlyArray<{|
            +firstName: ?string,
            +lastName: ?string,
          |}>,
        |}>,
      |},
    |}>,
  |},
|};
export type PoiMapWithPropsQuery = {|
  response: PoiMapWithPropsQuery$data,
  variables: PoiMapWithPropsQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "route",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "streetNumber",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PoiMapWithPropsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PoiConnection",
        "kind": "LinkedField",
        "name": "pois",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PoiEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Poi",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Property",
                    "kind": "LinkedField",
                    "name": "properties",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "users",
                        "plural": true,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PoiMapWithPropsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PoiConnection",
        "kind": "LinkedField",
        "name": "pois",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PoiEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Poi",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Property",
                    "kind": "LinkedField",
                    "name": "properties",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "users",
                        "plural": true,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9d24ee40067a2e70eb9b6f75cf8776fc",
    "id": null,
    "metadata": {},
    "name": "PoiMapWithPropsQuery",
    "operationKind": "query",
    "text": "query PoiMapWithPropsQuery(\n  $count: Int!\n  $filters: PoisFilters\n) {\n  pois(first: $count, filters: $filters) {\n    edges {\n      node {\n        properties {\n          route\n          streetNumber\n          users {\n            firstName\n            lastName\n            id\n          }\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "464d4f066cab09cf2b2ee64fa5526a6a";

export default node;
