import { useCallback } from 'react';

import {
  type NavigateOptions,
  type SetURLSearchParams,
  type URLSearchParamsInit,
  createSearchParams,
  useSearchParams,
} from 'react-router-dom';

const extractScopedSearchParams = (
  searchParams: URLSearchParams,
  scope: string,
): URLSearchParams =>
  new URLSearchParams(
    Array.from(searchParams.entries()).reduce((acc, [key, value]) => {
      if (!key.startsWith(`${scope}_`)) {
        return acc;
      }

      acc.push([key.replace(new RegExp(`^${scope}_`), ''), value]);

      return acc;
    }, [] as [string, string][]),
  );

const mergeSearchParamsWithScope = (
  searchParams: URLSearchParams,
  extractedSearchParams: URLSearchParams,
  scope: string,
): URLSearchParams => {
  const cleanedSearchParams = Array.from(searchParams.entries()).filter(
    ([key]) => !key.startsWith(`${scope}_`),
  );

  return new URLSearchParams(
    Array.from(extractedSearchParams.entries()).reduce(
      (acc, [key, value]) => [...acc, [`${scope}_${key}`, value]],
      cleanedSearchParams,
    ),
  );
};

export const useScopedSearchParams = (
  scope?: string,
): [URLSearchParams, SetURLSearchParams] => {
  const [searchParams, setSearchParams] = useSearchParams();

  const scopedSearchParams = extractScopedSearchParams(
    searchParams,
    scope ?? '',
  );
  const scopedSetSearchParams = useCallback(
    (
      nextInit?:
        | URLSearchParamsInit
        | ((prev: URLSearchParams) => URLSearchParamsInit),
      navigateOpts?: NavigateOptions,
    ) => {
      if (scope == null) {
        setSearchParams(nextInit, navigateOpts);
      } else {
        const nextInitUrlSearchParams = createSearchParams(
          typeof nextInit === 'function'
            ? nextInit(scopedSearchParams)
            : nextInit,
        );
        const next = mergeSearchParamsWithScope(
          searchParams,
          nextInitUrlSearchParams,
          scope,
        );

        setSearchParams(next, navigateOpts);
      }
    },
    [setSearchParams, scope, searchParams, scopedSearchParams],
  );

  return [
    scope != null ? scopedSearchParams : searchParams,
    scope != null ? scopedSetSearchParams : setSearchParams,
  ];
};
