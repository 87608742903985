/**
 * @generated SignedSource<<52bb4508dda2a6a350d1d5861bb99f36>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
export type SubscriptionStatus = "active" | "canceled" | "incomplete" | "incomplete_expired" | "past_due" | "trialing" | "unpaid";
import type { FragmentType } from "relay-runtime";
declare export opaque type lotMandateCreateDialog_lead$fragmentType: FragmentType;
export type lotMandateCreateDialog_lead$data = {|
  +broker: ?{|
    +firstName: ?string,
    +id: string,
    +lastName: ?string,
    +organisation: ?{|
      +formattedAddress: ?string,
      +name: ?string,
    |},
    +primaryEmail: ?{|
      +email: string,
    |},
    +primaryImage: ?{|
      +url: string,
    |},
    +subscription: ?{|
      +status: SubscriptionStatus,
    |},
  |},
  +contact: ?{|
    +firstName: ?string,
    +id: string,
    +lastName: ?string,
    +organisation: ?{|
      +formattedAddress: ?string,
      +name: ?string,
    |},
    +primaryEmail: ?{|
      +email: string,
    |},
    +primaryImage: ?{|
      +url: string,
    |},
    +subscription: ?{|
      +status: SubscriptionStatus,
    |},
  |},
  +id: string,
  +property: ?{|
    +id: string,
  |},
  +$fragmentType: lotMandateCreateDialog_lead$fragmentType,
|};
export type lotMandateCreateDialog_lead$key = {
  +$data?: lotMandateCreateDialog_lead$data,
  +$fragmentSpreads: lotMandateCreateDialog_lead$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "firstName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lastName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Email",
    "kind": "LinkedField",
    "name": "primaryEmail",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Image",
    "kind": "LinkedField",
    "name": "primaryImage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Organisation",
    "kind": "LinkedField",
    "name": "organisation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "formattedAddress",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Subscription",
    "kind": "LinkedField",
    "name": "subscription",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "lotMandateCreateDialog_lead",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "contact",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "broker",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Property",
      "kind": "LinkedField",
      "name": "property",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Lead",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "1063c8ca396abe920d1c0be2914a9386";

export default node;
