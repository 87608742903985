import { gql } from '../../__generated__';

export const GET_OFFERS_QUERY = gql(`
  query GetOffers(
    $where: offers_bool_exp!
    $order_by: [offers_order_by!]!
    $limit: Int!
    $offset: Int!
  ) {
    offers(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {
      id
      amount
      valid_until
      lot {
        id
        currency
        broker {
          ...UserCard_user
        }
        ...ListingCell_listing
        ...ListingPriceCell
      }
      status
      offer_refused_type {
        id
        label
      }
      made_on
      comments
      buyer {
        ...UserCard_user
      }
      created_by
      ...ChangeOfferStatusModal
    }
  }
`);

export const GET_OFFERS_COUNT_QUERY = gql(`
  query GetOffersCount(
    $where: offers_bool_exp!
  ) {
    offers_count: offers_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`);

export const GET_OFFER_DRAWER_QUERY = gql(`
  query GetOfferDrawer($id: uuid!) {
    offers_by_pk(id: $id) {
      id
      ...OfferEditForm
      created_by
    }
  }
`);

export const DELETE_OFFER_MUTATION = gql(`
  mutation DeleteOffer($id: uuid!) {
    delete_offers(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`);

export const UPDATE_OFFER_MUTATION = gql(`
  mutation UpdateOffer($id: uuid!, $updates: offers_set_input!) {
    update_offers_by_pk(pk_columns: { id: $id }, _set: $updates) {
      id
      ...OfferEditForm
    }
  }
`);

export const GET_OFFER_REFUSED_TYPES_QUERY = gql(`
  query GetOfferRefusedTypes {
    dictionaries(where: { type: { _eq: offer_refused_types } }) {
        id
        name
        label
      }
  }
`);

export const INSERT_OFFER_MUTATION = gql(`
  mutation InsertOffer($offer: offers_insert_input!) {
    insert_offers_one(object: $offer) {
      id
      ...OfferEditForm
    }
  }
`);
