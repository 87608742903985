import type { ErrorOption, FieldPath } from 'react-hook-form';

import { type Translate } from '../../../../src/hooks/locale';
import { gql } from '../../../__generated__';
import type {
  Properties_Set_Input,
  PropertyFormPricingFragment,
} from '../../../__generated__/graphql';
import { getKeys } from '../../../utils/objects';
import {
  isCommercialBuilding,
  isMixedUseBuilding,
  isResidentialBuilding,
} from '../../../utils/propertyDataChecks';
import { isLandTypeProperty } from '../../../utils/propertyTypes';
import {
  type AddressFieldDefinition,
  type FormDefinitionType,
  type FormFieldDefinitionType,
  createCheckboxElement,
  createNumberFieldElement,
} from '../../form/RaForm';
import type { PropertyFormData } from '../propertyFormDefinition';
import type { PropertyTypeLight as PropertyType } from '../propertyFormQueries';

export const PROPERTY_FORM_PRICING_FRAGMENT = gql(`
  fragment PropertyFormPricing on properties {
    is_residential_fully_rented
    residential_yearly_rental_income
    residential_potential_rental_income
    is_commercial_fully_rented
    commercial_yearly_rental_income
    commercial_potential_rental_income
    income_includes_parking
    is_parking_fully_rented
    parking_yearly_rental_income
    parking_potential_rental_income
    charges
    renovation_fund
  }
`);

type ComposedType<
  Path extends string | undefined,
  Type extends Record<string, any>,
> = Path extends string
  ? {
      [K in Path]: Type;
    }
  : Type;

function getPricingFields(
  t: Translate,
): FormFieldDefinitionType<PropertyFormPricingFragment>[];
function getPricingFields<Path extends string>(
  t: Translate,
  parentPath: Path,
): FormFieldDefinitionType<ComposedType<Path, PropertyFormPricingFragment>>[];
function getPricingFields<Path extends string | undefined>(
  t: Translate,
  parentPath?: Path,
):
  | FormFieldDefinitionType<ComposedType<Path, PropertyFormPricingFragment>>[]
  | FormFieldDefinitionType<PropertyFormPricingFragment>[] {
  const fieldDefinitions: Exclude<
    FormFieldDefinitionType<PropertyFormPricingFragment>,
    AddressFieldDefinition
  >[] = [
    {
      name: 'property_income',
      type: 'category-title',
      label: t('Income'),
    },
    createCheckboxElement(
      'is_residential_fully_rented',
      t('fullyRentedResidential'),
      { gridProps: { md: 12 } },
    ),
    createNumberFieldElement(
      'residential_yearly_rental_income',
      t('actualRentalIncomeResidential'),
      { gridProps: { sm: 6 } },
    ),
    createNumberFieldElement(
      'residential_potential_rental_income',
      t('potentialRentalIncomeResidential'),
      {
        gridProps: { sm: 6 },
        render: ({ is_residential_fully_rented }) =>
          is_residential_fully_rented !== true,
      },
    ),
    createCheckboxElement(
      'is_commercial_fully_rented',
      t('fullyRentedCommercial'),
      { gridProps: { md: 12 } },
    ),
    createNumberFieldElement(
      'commercial_yearly_rental_income',
      t('actualRentalIncomeCommercial'),
      { gridProps: { sm: 6 } },
    ),
    createNumberFieldElement(
      'commercial_potential_rental_income',
      t('potentialRentalIncomeCommercial'),
      {
        render: ({ is_commercial_fully_rented }) =>
          is_commercial_fully_rented !== true,
        gridProps: { sm: 6 },
      },
    ),
    // Used to prevent fields from parking to go on the same line as commercial when is_commercial_fully_rented = true
    {
      name: 'separator',
      type: 'custom',
      element: <div></div>,
      gridProps: { md: 12 },
    },
    createCheckboxElement(
      'income_includes_parking',
      t('incomeIncludesParking'),
      { gridProps: { sm: 6 } },
    ),
    createCheckboxElement('is_parking_fully_rented', t('fullyRentedParking'), {
      render: ({ income_includes_parking }) => income_includes_parking !== true,
      gridProps: { sm: 6 },
    }),
    createNumberFieldElement(
      'parking_yearly_rental_income',
      t('actualRentalIncomeParking'),
      {
        render: ({ income_includes_parking }) =>
          income_includes_parking !== true,
        gridProps: { sm: 6 },
      },
    ),
    createNumberFieldElement(
      'parking_potential_rental_income',
      t('potentialRentalIncomeParking'),
      {
        render: ({ income_includes_parking, is_parking_fully_rented }) =>
          income_includes_parking !== true && is_parking_fully_rented !== true,
        gridProps: { sm: 6 },
      },
    ),
    {
      name: 'property_costs',
      type: 'category-title',
      label: t('costs'),
    },
    createNumberFieldElement('charges', t('charges'), { gridProps: { sm: 6 } }),
    createNumberFieldElement('renovation_fund', t('renovationFund'), {
      gridProps: { sm: 6 },
    }),
  ];

  if (parentPath == null) {
    return fieldDefinitions;
  }

  return fieldDefinitions.map(
    field =>
      ('name' in field
        ? {
            ...field,
            name: `${parentPath}.${field.name}`,
          }
        : field) as FormFieldDefinitionType<
        ComposedType<Path, PropertyFormPricingFragment>
      >,
  );
}

const isBuilding = (propertyType: PropertyType) =>
  isResidentialBuilding(propertyType) ||
  isCommercialBuilding(propertyType) ||
  isMixedUseBuilding(propertyType);

const isCommercial = (propertyType: PropertyType) =>
  isCommercialBuilding(propertyType) || isMixedUseBuilding(propertyType);

const isResidential = (propertyType: PropertyType) =>
  isResidentialBuilding(propertyType) || isMixedUseBuilding(propertyType);

export const pricingFormConditionalFields = new Map<
  FieldPath<PropertyFormPricingFragment> | 'property_income',
  (data: { __property_type?: PropertyType }) => boolean
>([
  // To conditionnally display category title
  ['property_income', ({ __property_type }) => isBuilding(__property_type)],
  [
    'is_residential_fully_rented',
    ({ __property_type }) => isResidential(__property_type),
  ],
  [
    'residential_yearly_rental_income',
    ({ __property_type }) => isResidential(__property_type),
  ],
  [
    'residential_potential_rental_income',
    ({ __property_type }) => isResidential(__property_type),
  ],
  [
    'is_commercial_fully_rented',
    ({ __property_type }) => isCommercial(__property_type),
  ],
  [
    'commercial_yearly_rental_income',
    ({ __property_type }) => isCommercial(__property_type),
  ],
  [
    'commercial_potential_rental_income',
    ({ __property_type }) => isCommercial(__property_type),
  ],
  [
    'income_includes_parking',
    ({ __property_type }) => isBuilding(__property_type),
  ],
  [
    'is_parking_fully_rented',
    ({ __property_type }) => isBuilding(__property_type),
  ],
  [
    'parking_yearly_rental_income',
    ({ __property_type }) => isBuilding(__property_type),
  ],
  [
    'parking_potential_rental_income',
    ({ __property_type }) => isBuilding(__property_type),
  ],
]);

export const getStandalonePricingFormDefinition =
  <Path extends string>(
    path?: Path,
  ): FormDefinitionType<
    ComposedType<Path, PropertyFormPricingFragment>,
    { propertyType: PropertyType }
  > =>
  ({ t, context }) => {
    const fields =
      path == null ? getPricingFields(t) : getPricingFields(t, path);

    if (isLandTypeProperty(context.propertyType)) {
      return [];
    }

    const displayedFields = fields.reduce((acc, field) => {
      if ('name' in field) {
        const fieldName = path == null ? field.name : field.name.split('.')[1];
        const conditionCb = pricingFormConditionalFields.get(fieldName as any);

        if (
          conditionCb != null &&
          conditionCb({ __property_type: context.propertyType }) === false
        ) {
          return acc;
        }
      }

      if (path != null && field.render != null) {
        const oldRender = field.render;
        field.render = (
          data: ComposedType<Path, PropertyFormPricingFragment>,
        ) =>
          (oldRender as (data: PropertyFormPricingFragment) => boolean)(
            data[path],
          );
      }

      return [
        ...acc,
        field as FormFieldDefinitionType<
          ComposedType<Path, PropertyFormPricingFragment>
        >,
      ];
    }, [] as FormFieldDefinitionType<ComposedType<Path, PropertyFormPricingFragment>>[]);

    return displayedFields;
  };

export const pricingFormDefinition: FormDefinitionType<PropertyFormData> = ({
  t,
}) => {
  const fields = getPricingFields(
    t,
  ) as FormFieldDefinitionType<PropertyFormData>[];

  fields.forEach(field => {
    const renderFns = [
      (data: PropertyFormData) => !isLandTypeProperty(data.__property_type),
    ];

    if ('name' in field) {
      const conditionCb = pricingFormConditionalFields.get(field.name as any);

      if (conditionCb != null) {
        renderFns.push(conditionCb);
      }
    }

    field.render = renderFns.reduce(
      (acc, cur) => data => acc(data) && cur(data),
      field.render ?? (() => true),
    );
  });

  return fields;
};

export const preparePricingFormData = (
  data: PropertyFormPricingFragment,
  propertyType: PropertyType,
): Properties_Set_Input => {
  // Reset fields.
  const propertyPayload: Properties_Set_Input = {
    charges: null,
    renovation_fund: null,
    is_residential_fully_rented: null,
    residential_yearly_rental_income: null,
    residential_potential_rental_income: null,
    is_commercial_fully_rented: null,
    commercial_yearly_rental_income: null,
    commercial_potential_rental_income: null,
    income_includes_parking: null,
    is_parking_fully_rented: null,
    parking_yearly_rental_income: null,
    parking_potential_rental_income: null,
  };

  if (isLandTypeProperty(propertyType)) {
    return propertyPayload;
  }

  propertyPayload.charges = data.charges;
  propertyPayload.renovation_fund = data.renovation_fund;

  if (!isBuilding) {
    return propertyPayload;
  }

  if (isResidential(propertyType)) {
    propertyPayload.is_residential_fully_rented =
      data.is_residential_fully_rented;
    propertyPayload.residential_yearly_rental_income =
      data.residential_yearly_rental_income;

    if (data.is_residential_fully_rented !== true) {
      propertyPayload.residential_potential_rental_income =
        data.residential_potential_rental_income;
    }
  }

  if (isCommercial(propertyType)) {
    propertyPayload.is_commercial_fully_rented =
      data.is_commercial_fully_rented;
    propertyPayload.commercial_yearly_rental_income =
      data.commercial_yearly_rental_income;

    if (data.is_commercial_fully_rented !== true) {
      propertyPayload.commercial_potential_rental_income =
        data.commercial_potential_rental_income;
    }
  }

  propertyPayload.income_includes_parking = data.income_includes_parking;

  if (data.income_includes_parking !== true) {
    propertyPayload.is_parking_fully_rented = data.is_parking_fully_rented;
    propertyPayload.parking_yearly_rental_income =
      data.parking_yearly_rental_income;

    if (data.is_parking_fully_rented === true) {
      propertyPayload.parking_potential_rental_income =
        data.parking_potential_rental_income;
    }
  }

  return propertyPayload;
};

export const getDefaultPropertyPricingFormValues = (
  data: PropertyFormPricingFragment,
): PropertyFormPricingFragment => ({
  is_residential_fully_rented: data.is_residential_fully_rented === true,
  residential_yearly_rental_income: data.residential_yearly_rental_income,
  residential_potential_rental_income: data.residential_potential_rental_income,
  is_commercial_fully_rented: data.is_commercial_fully_rented === true,
  commercial_yearly_rental_income: data.commercial_yearly_rental_income,
  commercial_potential_rental_income: data.commercial_potential_rental_income,
  income_includes_parking: data.income_includes_parking === true,
  is_parking_fully_rented: data.is_parking_fully_rented === true,
  parking_yearly_rental_income: data.parking_yearly_rental_income,
  parking_potential_rental_income: data.parking_potential_rental_income,
  charges: data.charges,
  renovation_fund: data.renovation_fund,
});

type ValidateCallback<Path extends string | undefined = undefined> =
  Path extends string
    ? (
        data: ComposedType<Path, PropertyFormPricingFragment>,
      ) => [
        FieldPath<ComposedType<Path, PropertyFormPricingFragment>>,
        ErrorOption,
      ][]
    : (
        data: PropertyFormPricingFragment,
      ) => [FieldPath<PropertyFormPricingFragment>, ErrorOption][];

function getValidatePricingFormDataFn(
  t: Translate,
  propertyType: PropertyType,
): ValidateCallback;
function getValidatePricingFormDataFn<Path extends string>(
  t: Translate,
  propertyType: PropertyType,
  path: Path,
): ValidateCallback<Path>;
function getValidatePricingFormDataFn<Path extends string>(
  t: Translate,
  propertyType: PropertyType,
  path?: Path,
) {
  return (
    data:
      | ComposedType<Path, PropertyFormPricingFragment>
      | PropertyFormPricingFragment,
  ) => {
    const isMultiFamilyProperty = isResidential(propertyType);
    const isCommercialProperty = isCommercial(propertyType);
    const errors: [FieldPath<PropertyFormPricingFragment>, ErrorOption][] = [];

    const pricingData =
      path == null
        ? (data as PropertyFormPricingFragment)
        : (data as ComposedType<Path, PropertyFormPricingFragment>)[path];
    const values = getKeys(pricingData).reduce(
      (acc: PropertyFormPricingFragment, key): PropertyFormPricingFragment => {
        const value = pricingData[key] as any;
        acc[key] = value;

        const renderFn = pricingFormConditionalFields.get(key);
        if (renderFn && !renderFn({ __property_type: propertyType })) {
          // We unset the value if the field is not rendered.
          acc[key] = null;
        }

        return acc;
      },
      {},
    );

    if (
      isMultiFamilyProperty &&
      values.is_residential_fully_rented !== true &&
      values.residential_potential_rental_income != null &&
      values.residential_yearly_rental_income != null &&
      values.residential_potential_rental_income <=
        values.residential_yearly_rental_income
    ) {
      errors.push([
        'residential_potential_rental_income',
        { type: 'min', message: t('potentialIncomeTooLow') },
      ]);
    }

    if (
      isCommercialProperty &&
      values.is_commercial_fully_rented !== true &&
      values.commercial_potential_rental_income != null &&
      values.commercial_yearly_rental_income != null &&
      values.commercial_potential_rental_income <=
        values.commercial_yearly_rental_income
    ) {
      errors.push([
        'commercial_potential_rental_income',
        { type: 'min', message: t('potentialIncomeTooLow') },
      ]);
    }

    if (
      (isMultiFamilyProperty || isCommercialProperty) &&
      values.is_parking_fully_rented !== true &&
      values.parking_potential_rental_income != null &&
      values.parking_yearly_rental_income != null &&
      values.parking_potential_rental_income <=
        values.parking_yearly_rental_income
    ) {
      errors.push([
        'parking_potential_rental_income',
        { type: 'min', message: t('potentialIncomeTooLow') },
      ]);
    }

    return path == null
      ? errors
      : errors.map(([field, error]) => [`${path}.${field}`, error]);
  };
}

export const getValidatePricingFormData = getValidatePricingFormDataFn;
