export const CoverPhotoPlaceholder = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 1472 300"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid slice"
  >
    <g clipPath="url(#clip0_2840_4094)">
      <path d="M0 0H1472V300H0V0Z" fill="#F3F6FF" />
      <circle cx="174.5" cy="80.5" r="324.5" fill="#E3EAFF" />
      <circle cx="1383.5" cy="301.5" r="324.5" fill="#E4E9F7" />
    </g>
    <defs>
      <clipPath id="clip0_2840_4094">
        <rect width="1472" height="300" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
