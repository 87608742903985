/**
 * @generated SignedSource<<945b1578e3a2e8f326a47764f58f03e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type leadLotCard_root$data = {
  readonly " $fragmentSpreads": FragmentRefs<"lotMandateCreateDialog_root">;
  readonly " $fragmentType": "leadLotCard_root";
};
export type leadLotCard_root$key = {
  readonly " $data"?: leadLotCard_root$data;
  readonly " $fragmentSpreads": FragmentRefs<"leadLotCard_root">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "leadLotCard_root",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "lotMandateCreateDialog_root"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "a6f27ecb3cdc88277db63206df8e0ee6";

export default node;
