/**
 * @generated SignedSource<<f93d251941237c981a58163c1fe334da>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeedAssignmentClaimLead$data = {
  readonly assignedTo: {
    readonly id: string;
  } | null;
  readonly id: string;
  readonly lead?: {
    readonly hasOpenActivityForMe: boolean;
    readonly id: string;
  } | null;
  readonly " $fragmentType": "FeedAssignmentClaimLead";
};
export type FeedAssignmentClaimLead$key = {
  readonly " $data"?: FeedAssignmentClaimLead$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedAssignmentClaimLead">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedAssignmentClaimLead",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "assignedTo",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Lead",
          "kind": "LinkedField",
          "name": "lead",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasOpenActivityForMe",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "ActivityAssignment",
      "abstractKey": null
    }
  ],
  "type": "Activity",
  "abstractKey": "__isActivity"
};
})();

(node as any).hash = "9bbdde2b092de355184d64854e3a558f";

export default node;
