/**
 * @generated SignedSource<<0d237e32bc440be8e011e525c900096c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeedEmail_activity$data = {
  readonly emailMessage: {
    readonly bcc: ReadonlyArray<{
      readonly name: string | null;
      readonly user: {
        readonly id: string;
      } | null;
    } | null>;
    readonly cc: ReadonlyArray<{
      readonly name: string | null;
      readonly user: {
        readonly id: string;
      } | null;
    } | null>;
    readonly emailMessageFiles: ReadonlyArray<{
      readonly name: string | null;
      readonly nylasFileId: string | null;
      readonly size: number | null;
    } | null>;
    readonly from: ReadonlyArray<{
      readonly name: string | null;
      readonly user: {
        readonly id: string;
      } | null;
    } | null>;
    readonly id: string;
    readonly snippet: string | null;
    readonly status: string | null;
    readonly subject: string | null;
    readonly to: ReadonlyArray<{
      readonly name: string | null;
      readonly user: {
        readonly id: string;
      } | null;
    } | null>;
    readonly " $fragmentSpreads": FragmentRefs<"ConnectEmailForm_forwardMessage" | "ConnectEmailForm_replyToMessage">;
  } | null;
  readonly id: string;
  readonly " $fragmentType": "FeedEmail_activity";
};
export type FeedEmail_activity$key = {
  readonly " $data"?: FeedEmail_activity$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedEmail_activity">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": [
      (v0/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedEmail_activity",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "EmailMessage",
      "kind": "LinkedField",
      "name": "emailMessage",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ConnectEmailForm_replyToMessage"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ConnectEmailForm_forwardMessage"
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "subject",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "snippet",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EmailRecipient",
          "kind": "LinkedField",
          "name": "to",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EmailRecipient",
          "kind": "LinkedField",
          "name": "from",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EmailRecipient",
          "kind": "LinkedField",
          "name": "cc",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EmailRecipient",
          "kind": "LinkedField",
          "name": "bcc",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EmailMessageFile",
          "kind": "LinkedField",
          "name": "emailMessageFiles",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "nylasFileId",
              "storageKey": null
            },
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "size",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ActivityEmail",
  "abstractKey": null
};
})();

(node as any).hash = "9b819de2d92ce7ece56d19641c230446";

export default node;
