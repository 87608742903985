import { Suspense } from 'react';

import {
  loginByEmail,
  loginByFacebook,
  loginByGoogle,
  parseJwt,
  switchTenant,
} from '@realadvisor/auth-client';
import { init } from '@sentry/react';
import { createRoot } from 'react-dom/client';

import { ApolloHasuraWrapper, RelayApiWrapper } from './networking';
import { App } from './src/app';
import { SENTRY_DSN, isProduction } from './src/config';
import { nylasLoginReturnRoute } from './src/shared/email-client-connect';

const getRedirectBackUri = () => {
  const { searchParams, hostname } = new URL(location.href);
  const redirect_uri = searchParams.get('redirect_uri');
  if (redirect_uri == null) {
    throw Error('Invalid redirect uri');
  }

  //check that redirect URI is on the same tenant than current url otherwise modify it
  const redirectURL = new URL(redirect_uri);
  if (hostname !== redirectURL.hostname) {
    redirectURL.hostname = hostname;
  }

  if (redirectURL.pathname.includes('/noaccess')) {
    redirectURL.pathname = '/';
  }

  const access_token = localStorage.getItem('access_token');
  if (access_token != null) {
    const { user } = parseJwt(access_token);
    if (user != null) {
      return redirectURL.href;
    }
  }

  const select_tenant_url = new URL(`${location.origin}/en/select-tenant`);
  select_tenant_url.searchParams.set('return', redirect_uri);
  return select_tenant_url.toString();
};

const getErrorRedirectUri = (error: string) => {
  const { searchParams } = new URL(location.href);
  const redirect_uri = searchParams.get('redirect_uri');
  if (redirect_uri == null) {
    throw Error('Invalid redirect uri');
  }
  const login_url = new URL(`${location.origin}/login`);
  // prevent showing error when user is logged out
  if (error === 'LOGIN_NOT_FOUND') {
    // and redirect back after login
    login_url.searchParams.set('return', location.href);
  } else {
    login_url.searchParams.set('error', error);
    login_url.searchParams.set('return', redirect_uri);
  }
  return login_url.toString();
};

// Enabled only in production.
import.meta.env.NODE_ENV === 'production' &&
  isProduction &&
  init({
    dsn: SENTRY_DSN,
    sampleRate: 1.0, //  Capture 100% of the errors we can tweak this later
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions we can tweak this later
    environment: import.meta.env.NODE_ENV,
    release: import.meta.env.SENTRY_RELEASE,
  });

const url = new URL(location.href);
const authCode = url.searchParams.get('auth_code');
const tenantId = url.searchParams.get('tenant_id');
const refreshToken = localStorage.getItem('refresh_token');

if (location.pathname === '/login/google') {
  (async () => {
    const response = await loginByGoogle();
    if (response.status >= 400) {
      const data = await response.json();
      location.replace(getErrorRedirectUri(data.error));
    } else {
      location.replace(getRedirectBackUri());
    }
  })();
} else if (location.pathname === '/login/facebook') {
  (async () => {
    const response = await loginByFacebook();
    if (response.status >= 400) {
      const data = await response.json();
      location.replace(getErrorRedirectUri(data.error));
    } else {
      location.replace(getRedirectBackUri());
    }
  })();
} else if (location.pathname === '/login/email') {
  (async () => {
    const response = await loginByEmail();
    if (response.status >= 400) {
      const data = await response.json();
      location.replace(getErrorRedirectUri(data.error));
    } else {
      location.replace(getRedirectBackUri());
    }
  })();
} else {
  (async () => {
    if (authCode != null && tenantId != null && refreshToken == null) {
      localStorage.setItem('refresh_token', authCode);
      await switchTenant();
    }
    const app = (
      <ApolloHasuraWrapper>
        <RelayApiWrapper>
          <Suspense fallback={null}>
            <App />
          </Suspense>
        </RelayApiWrapper>
      </ApolloHasuraWrapper>
    );

    const mountNode = document.getElementById('root');

    if (mountNode != null) {
      const routes = [nylasLoginReturnRoute({ mountNode })];
      if (routes.includes(true) === false) {
        const root = createRoot(mountNode);
        // nothing matches
        root.render(app);
      }
    }
  })();
}
