/**
 * @generated SignedSource<<70ac08fe3d909a99b58cde447251302a>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type PropertyMortgageFormFinancingInstitutionTypesQuery$variables = {||};
export type PropertyMortgageFormFinancingInstitutionTypesQuery$data = {|
  +financingInstitutionTypes: ?$ReadOnlyArray<?{|
    +id: string,
    +name: string,
  |}>,
|};
export type PropertyMortgageFormFinancingInstitutionTypesQuery = {|
  response: PropertyMortgageFormFinancingInstitutionTypesQuery$data,
  variables: PropertyMortgageFormFinancingInstitutionTypesQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "FinancingInstitution",
    "kind": "LinkedField",
    "name": "financingInstitutionTypes",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PropertyMortgageFormFinancingInstitutionTypesQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PropertyMortgageFormFinancingInstitutionTypesQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "9c9585ce428040bd2576e7bfc5d0cb08",
    "id": null,
    "metadata": {},
    "name": "PropertyMortgageFormFinancingInstitutionTypesQuery",
    "operationKind": "query",
    "text": "query PropertyMortgageFormFinancingInstitutionTypesQuery {\n  financingInstitutionTypes {\n    id\n    name\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "5d68a81b5d14e2dd07df8dcb5fcf264f";

export default node;
