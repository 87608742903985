// @flow

import * as React from 'react';

import {
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import copy from 'clipboard-copy';
import { graphql, useFragment } from 'react-relay';
// $FlowFixMe[untyped-import]
import { Link } from 'react-router-dom';

import { Menu } from '../controls/popup';
import { useLocale } from '../hooks/locale';
import { Email } from '../icons/email';
import { MoreVert } from '../icons/more-vert';
import { OutlineFileCopy } from '../icons/outline-file-copy';
import { Phone } from '../icons/phone';
import { Sms } from '../icons/sms';
import { Whatsapp } from '../icons/whatsapp';

import type { userSummary_user$key } from './__generated__/userSummary_user.graphql';
import { UserAvatar } from './user-avatar';

type Props = $ReadOnly<{|
  user: userSummary_user$key | null,
  showBroker?: boolean,
  showTitle?: boolean,
  secondaryAction?: React.Node,
  children?: React.Node,
|}>;

const UserItem = ({ user, secondaryAction }) => {
  return (
    // $FlowFixMe[prop-missing] to is not defined in ListItem
    <ListItem
      button={true}
      ContainerProps={{ style: { zIndex: 2, marginBottom: 4 } }}
      component={Link}
      to={`/v1/users/${user.id}`}
      dense={true}
    >
      <ListItemAvatar>
        <UserAvatar user={user} disableLink={true} />
      </ListItemAvatar>
      <ListItemText
        css={{ overflowWrap: 'break-word' }}
        primary={user.displayName}
        secondaryTypographyProps={{ noWrap: true }}
        secondary={user.organisation?.name}
      />
      {secondaryAction != null && (
        <ListItemSecondaryAction>{secondaryAction}</ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

const PhoneItem = ({ phone, first }) => {
  const { t } = useLocale();
  const menuRef = React.useRef(null);
  const [menu, setMenu] = React.useState(false);

  return (
    <ListItem
      button={true}
      component="a"
      href={`tel:${phone.number}`}
      dense={true}
    >
      {first && (
        <ListItemIcon>
          <Phone />
        </ListItemIcon>
      )}
      <ListItemText inset={!first} primary={phone.formattedNumber} />
      <ListItemSecondaryAction>
        <Tooltip title={t('phoneNumberOptions')} placement="top">
          <IconButton ref={menuRef} edge="end" onClick={() => setMenu(v => !v)}>
            <MoreVert />
          </IconButton>
        </Tooltip>

        <Menu referenceRef={menuRef} open={menu} onClose={() => setMenu(false)}>
          <MenuItem
            title={t('sendWhatsappMessage')}
            component="a"
            href={
              // Link created according https://faq.whatsapp.com/iphone/how-to-link-to-whatsapp-from-a-different-app/?lang=en
              'https://wa.me/' + phone.number.replace(/[^0-9]/g, '')
              // v2 we should have whatsapp templates'&text=Hi+my+name+is+Jonas'
            }
            target="_blank"
          >
            <ListItemIcon>
              <Whatsapp />
            </ListItemIcon>
            {t('sendWhatsappMessage')}
          </MenuItem>
          <MenuItem
            title={t('sendSMS')}
            component="a"
            href={
              'sms:' + phone.number
              // v2 we should have whatsapp templates'&body=Hi+my+name+is+Jonas'
            }
            target="_blank"
          >
            <ListItemIcon>
              <Sms />
            </ListItemIcon>
            {t('sendSMS')}
          </MenuItem>
          <MenuItem
            title={t('copyToPhoneNumber')}
            onClick={() => {
              copy(phone.formattedNumber);
            }}
          >
            <ListItemIcon>
              <OutlineFileCopy />
            </ListItemIcon>
            {t('copyToPhoneNumber')}
          </MenuItem>
        </Menu>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const EmailItem = ({ email, first }) => {
  const { t } = useLocale();

  return (
    <ListItem
      button={true}
      component="a"
      href={`mailto:${email.email}`}
      target="_blank"
      dense={true}
    >
      {first && (
        <ListItemIcon>
          <Email />
        </ListItemIcon>
      )}
      <ListItemText
        inset={!first}
        primary={email.email}
        primaryTypographyProps={{ noWrap: true }}
      />
      <ListItemSecondaryAction>
        <Tooltip title={t('copyToClipboard')} placement="top">
          <IconButton
            edge="end"
            onClick={() => {
              copy(email.email);
            }}
          >
            <OutlineFileCopy />
          </IconButton>
        </Tooltip>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const BrokerItem = ({ broker }) => {
  return (
    // $FlowFixMe[prop-missing]
    <ListItem button={true} component={Link} to={`/v1/users/${broker.id}`}>
      <ListItemAvatar>
        <UserAvatar user={broker} disableLink={true} />
      </ListItemAvatar>
      <ListItemText primary={broker.displayName} />
    </ListItem>
  );
};

export const UserSummary = (props: Props): React.Node => {
  const user = useFragment(
    graphql`
      fragment userSummary_user on User {
        ...userAvatar_user
        id
        displayName
        organisation {
          id
          name
        }
        phoneNumbers {
          formattedNumber
          number
          primary
        }
        emails {
          email
          primary
        }
        broker {
          ...userAvatar_user
          id
          displayName
        }
      }
    `,
    props.user,
  );

  const { showBroker, showTitle, secondaryAction, children } = props;
  const { t } = useLocale();

  if (user == null) {
    return (
      <List css={{ width: '100%' }}>
        <ListItem
          dense={true}
          button={true}
          ContainerProps={{ style: { zIndex: 2 } }}
        >
          <ListItemAvatar>
            <UserAvatar user={user} disableLink={true} />
          </ListItemAvatar>
          <ListItemText primary={t('notAssigned')} />
        </ListItem>
      </List>
    );
  }

  return (
    <>
      <List>
        {showTitle === true && <ListSubheader>{t('contact')}</ListSubheader>}
        <UserItem user={user} secondaryAction={secondaryAction} />
        {children}
        {Array.from(user.phoneNumbers ?? [])
          .sort(data => (data.primary === true ? -1 : 1))
          .map((data, index, arr) => (
            <PhoneItem
              key={data.number}
              first={index === 0}
              last={index === arr.length - 1}
              phone={data}
            />
          ))}
        {Array.from(user.emails ?? [])
          .sort(data => (data.primary === true ? -1 : 1))
          .map((data, index, arr) => (
            <EmailItem
              key={data.email}
              email={data}
              first={index === 0}
              last={index === arr.length - 1}
            />
          ))}
        {showBroker === true && user.broker && (
          <>
            <ListSubheader>{t('owner')}</ListSubheader>
            {user.broker && <BrokerItem broker={user.broker} />}
          </>
        )}
      </List>
    </>
  );
};
