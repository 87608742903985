/**
 * @generated SignedSource<<09cd87a11600778b0803804a20d12b69>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateLeadStageInput = {
  clientMutationId?: string | null;
  id: string;
  putAfter?: string | null;
  stageId: string;
};
export type leadsKanbanUpdateStageMutation$variables = {
  input: UpdateLeadStageInput;
};
export type leadsKanbanUpdateStageMutation$data = {
  readonly updateLeadStage: {
    readonly clientMutationId: string | null;
  } | null;
};
export type leadsKanbanUpdateStageMutation = {
  response: leadsKanbanUpdateStageMutation$data;
  variables: leadsKanbanUpdateStageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateLeadStagePayload",
    "kind": "LinkedField",
    "name": "updateLeadStage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "leadsKanbanUpdateStageMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "leadsKanbanUpdateStageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8e7da382ceae1a7965506bd8c195e981",
    "id": null,
    "metadata": {},
    "name": "leadsKanbanUpdateStageMutation",
    "operationKind": "mutation",
    "text": "mutation leadsKanbanUpdateStageMutation(\n  $input: UpdateLeadStageInput!\n) {\n  updateLeadStage(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();

(node as any).hash = "827d6e6a200a8457f1a48ca466e22a5f";

export default node;
