import {
  type ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { type Interpolation, type Theme } from '@emotion/react';
import { $generateNodesFromDOM } from '@lexical/html';
import { $isLinkNode, LinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListItemNode,
  ListNode,
  REMOVE_LIST_COMMAND,
} from '@lexical/list';
import {
  $convertFromMarkdownString,
  $convertToMarkdownString,
} from '@lexical/markdown';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { HeadingNode } from '@lexical/rich-text';
import { $isAtNodeEnd } from '@lexical/selection';
import { $findMatchingParent, $getNearestNodeOfType } from '@lexical/utils';
// use mui 4 for dialogs to prevent focus fight inside mui 4 modals
import { Dialog, Popover } from '@material-ui/core';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  TextField,
  ToggleButton,
  useTheme,
} from '@mui/material';
import { Form } from '@realadvisor/form';
import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  $getSelection,
  $insertNodes,
  $isParagraphNode,
  $isRangeSelection,
  $isRootOrShadowRoot,
  COMMAND_PRIORITY_CRITICAL,
  type EditorState,
  type EditorThemeClasses,
  FORMAT_TEXT_COMMAND,
  type RangeSelection,
  SELECTION_CHANGE_COMMAND,
  createEditor,
} from 'lexical';
import normalizeUrl from 'normalize-url';
import { useResponsive } from 'react-system';

import { FormatBold } from '../icons/format-bold';
import { FormatItalic } from '../icons/format-italic';
import { FormatListBulleted } from '../icons/format-list-bulleted';
import { FormatListNumbered } from '../icons/format-list-numbered';
import { Link } from '../icons/link';
import { LinkOff } from '../icons/link-off';
import { useLocale } from '../src/hooks/locale';
import { customPalette } from '../src/styles';

export const convertHtmlToMarkdown = (html: string) => {
  const parser = new DOMParser();
  const normalizedHtml = html.replace(/\n\s*/g, '\n');
  const dom = parser.parseFromString(normalizedHtml, 'text/html');
  // wrap all text and line break nodes with paragraph
  let p: null | Element = null;
  for (const node of Array.from(dom.body.childNodes)) {
    if (
      node.nodeType === Node.TEXT_NODE ||
      (node.nodeType === Node.ELEMENT_NODE && node.nodeName === 'BR')
    ) {
      if (p == null) {
        p = document.createElement('p');
        node.replaceWith(p);
      }
      p.appendChild(node);
    } else {
      p = null;
    }
  }

  const editor = createEditor({
    nodes: [ListNode, ListItemNode, LinkNode],
  });
  let markdown = '';
  editor.update(() => {
    const nodes = $generateNodesFromDOM(editor, dom);
    // need to have at least one paragraph before inserting html
    $getRoot().append($createParagraphNode());
    $insertNodes(nodes);
    markdown = $convertToMarkdownString();
  });
  return markdown;
};

const ToolbarDialog = ({
  anchorEl,
  open,
  onClose,
  children,
}: {
  anchorEl: Element | null;
  open: boolean;
  onClose: () => void;
  children: ReactNode;
}) => {
  const responsive = useResponsive();
  return responsive([
    <Dialog
      key="mobile"
      css={{
        '.MuiDialog-paper': {
          marginLeft: '18px !important',
          marginRight: '18px !important',
        },
      }}
      // focus restore should use internal draft method to preserve cursor position
      disableRestoreFocus={true}
      open={open}
      onClose={onClose}
    >
      {children}
    </Dialog>,
    <Popover
      key="desktop"
      disableRestoreFocus={true}
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
    >
      <Paper style={{ overflow: 'auto', minWidth: 300 }}>{children}</Paper>
    </Popover>,
  ]);
};

const normalizeUrlOrSpecialLink = (url: string) => {
  if (url.startsWith('mailto:') || url.startsWith('tel:')) {
    return url.trim();
  } else {
    try {
      return normalizeUrl(url);
    } catch {
      return url.trim();
    }
  }
};

const LinkDialogContent = ({
  link,
  onSave,
  onClose,
}: {
  link: null | Link;
  onSave: (link: Link) => void;
  onClose: () => void;
}) => {
  const { t } = useLocale();
  const [url, setUrl] = useState(link?.url ?? 'https://');
  const [title, setTitle] = useState(link?.title ?? '');
  return (
    <Form
      onSubmit={() => {
        const normalizedUrl = normalizeUrlOrSpecialLink(url);
        onSave({
          url: normalizedUrl,
          title: title.length === 0 ? normalizedUrl : title,
        });
      }}
    >
      <DialogTitle>{t('insertLink')}</DialogTitle>
      <DialogContent>
        <div css={{ display: 'grid', gap: 8 }}>
          <TextField
            variant="filled"
            label="Title"
            value={title}
            onChange={event => setTitle(event.target.value)}
          />
          <TextField
            variant="filled"
            label="URL"
            autoFocus={true}
            value={url}
            onChange={event => setUrl(event.target.value)}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('cancel')}</Button>
        <Button type="submit">{t('save')}</Button>
      </DialogActions>
    </Form>
  );
};

type Link = {
  url: string;
  title: string;
};

const getSelectedNode = (selection: RangeSelection) => {
  const anchor = selection.anchor;
  const focus = selection.focus;
  const anchorNode = anchor.getNode();
  const focusNode = focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  } else {
    return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
  }
};

const Toolbar = ({
  readOnly,
  actions,
}: {
  readOnly: boolean;
  actions?: React.ReactNode;
}) => {
  const { palette } = useTheme();
  const [editor] = useLexicalComposerContext();
  const [blockType, setBlockType] = useState<string>('paragraph');
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [linkAnchor, setLinkAnchor] = useState<null | HTMLElement>(null);
  const [link, setLink] = useState<null | Link>(null);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      // update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));

      // update link
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent)) {
        setLink({ url: parent.getURL(), title: parent.getTextContent() });
      } else if ($isLinkNode(node)) {
        setLink({ url: node.getURL(), title: node.getTextContent() });
      } else {
        setLink(null);
      }

      // update block type
      const anchorNode = selection.anchor.getNode();
      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, e => {
              const parent = e.getParent();
              return parent != null && $isRootOrShadowRoot(parent);
            });
      if (element == null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }

      if ($isListNode(element)) {
        const parentList = $getNearestNodeOfType(anchorNode, ListNode);
        const type = parentList
          ? parentList.getListType()
          : element.getListType();
        setBlockType(type);
      } else {
        const type = element.getType();
        setBlockType(type);
      }
    }
  }, []);

  useEffect(() => {
    if (readOnly) {
      return;
    }
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      () => {
        updateToolbar();
        return false;
      },
      COMMAND_PRIORITY_CRITICAL,
    );
  }, [editor, updateToolbar, readOnly]);

  useEffect(() => {
    if (readOnly) {
      return;
    }
    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        updateToolbar();
      });
    });
  }, [editor, updateToolbar, readOnly]);

  return (
    <div
      css={{
        overflowX: 'auto',
        borderBottom: `1px solid ${palette.divider}`,
        '.MuiToggleButton-root': {
          border: '0 !important',
        },
      }}
    >
      <div css={{ display: 'flex' }}>
        <ToggleButton
          tabIndex={-1}
          disabled={readOnly}
          value="bold"
          selected={isBold}
          onChange={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
          }}
        >
          <FormatBold />
        </ToggleButton>

        <ToggleButton
          tabIndex={-1}
          disabled={readOnly}
          value="italic"
          selected={isItalic}
          onChange={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
          }}
        >
          <FormatItalic />
        </ToggleButton>

        <ToggleButton
          tabIndex={-1}
          disabled={readOnly}
          value="number"
          selected={blockType === 'number'}
          onChange={() => {
            if (blockType === 'number') {
              editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
            } else {
              editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
            }
          }}
        >
          <FormatListNumbered />
        </ToggleButton>

        <ToggleButton
          tabIndex={-1}
          disabled={readOnly}
          value="bullet"
          selected={blockType === 'bullet'}
          onChange={() => {
            if (blockType === 'bullet') {
              editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
            } else {
              editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
            }
          }}
        >
          <FormatListBulleted />
        </ToggleButton>

        <ToggleButton
          tabIndex={-1}
          disabled={readOnly}
          value="link"
          selected={link != null}
          onChange={event => {
            if (link == null) {
              editor.dispatchCommand(TOGGLE_LINK_COMMAND, 'https://');
            }
            setLinkAnchor(event.currentTarget);
          }}
        >
          <Link />
        </ToggleButton>

        <ToggleButton
          tabIndex={-1}
          disabled={readOnly}
          value="unlink"
          selected={false}
          onChange={() => {
            if (link != null) {
              editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
            }
          }}
        >
          <LinkOff />
        </ToggleButton>
        <div css={{ flexGrow: 1 }} />
        {actions != null && actions}

        <ToolbarDialog
          anchorEl={linkAnchor}
          open={linkAnchor != null}
          onClose={() => setLinkAnchor(null)}
        >
          <LinkDialogContent
            link={link}
            onSave={({ url, title }) => {
              setLinkAnchor(null);
              editor.update(() => {
                const selection = $getSelection();
                if ($isRangeSelection(selection) && title.length !== 0) {
                  const node = getSelectedNode(selection);
                  // add title text before creating link
                  if (
                    $isParagraphNode(node) &&
                    node.getChildrenSize() === 0 &&
                    selection.isCollapsed()
                  ) {
                    selection.insertText(title);
                  }
                  // preserve formatting if title is not changed
                  else if (link != null && link.title !== title) {
                    const parent = node.getParent();
                    // replace link text with title
                    const textNode = $createTextNode(title);
                    if ($isLinkNode(parent)) {
                      parent.splice(0, parent.getChildrenSize(), [textNode]);
                    } else if ($isLinkNode(node)) {
                      node.splice(0, node.getChildrenSize(), [textNode]);
                    }
                  }
                }
              });
              editor.dispatchCommand(TOGGLE_LINK_COMMAND, url);
            }}
            onClose={() => setLinkAnchor(null)}
          />
        </ToolbarDialog>
      </div>
    </div>
  );
};

const onError = (error: Error) => {
  console.error(error);
};

const theme: EditorThemeClasses = {
  paragraph: 'editor-paragraph',
  text: {
    bold: 'editor-bold',
    italic: 'editor-italic',
  },
};

const UpdatePlugin = ({ initialValue }: { initialValue: string }) => {
  const [editor] = useLexicalComposerContext();
  const initialValueRef = useRef(initialValue);

  useEffect(() => {
    editor.update(() => {
      const currentMarkdown = $convertToMarkdownString();
      if (currentMarkdown !== initialValue) {
        const root = $getRoot();
        root.clear();
        $convertFromMarkdownString(initialValue);
        // Set selection to the start of the editor
        const firstChild = root.getFirstChild();
        if (firstChild) {
          const selection = $getSelection();
          if ($isRangeSelection(selection)) {
            selection.anchor.set(firstChild.getKey(), 0, 'element');
            selection.focus.set(firstChild.getKey(), 0, 'element');
          }
        }
      }
    });
    initialValueRef.current = initialValue;
  }, [editor, initialValue]);

  return null;
};

export const MarkdownComposer = ({
  readOnly = false,
  placeholder,
  maxHeight,
  initialValue,
  onChange,
  actions,
  variant = 'filled',
}: {
  readOnly?: boolean;
  placeholder?: string;
  maxHeight?: string;
  initialValue: string;
  actions?: React.ReactNode;
  onChange: (markdown: string) => void;
  variant?: 'filled' | 'outlined';
}) => {
  const { palette } = useTheme();

  const initialConfig = {
    namespace: 'Composer',
    editable: readOnly === false,
    nodes: [HeadingNode, ListNode, ListItemNode, LinkNode],
    theme,
    onError,
    editorState: () => {
      $convertFromMarkdownString(initialValue);
    },
  };

  const hoverStyle: Interpolation<Theme> =
    readOnly === true
      ? {}
      : variant === 'outlined'
      ? {
          '&:focus-within': {
            '.editor-editable': {
              outlineColor: palette.primary.main,
              outlineWidth: '2px',
            },
          },
        }
      : {
          '&:hover, :focus-within': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
          },
        };

  const onChangeHandler = useCallback(
    (editorState: EditorState) => {
      editorState.read(() => {
        const markdown = $convertToMarkdownString();
        onChange(markdown);
      });
    },
    [onChange],
  );

  return (
    <div
      css={{
        borderRadius: 4,
        backgroundColor:
          variant === 'outlined' ? 'white' : 'rgba(0, 0, 0, 0.04)',
        border:
          variant === 'outlined' ? `1px solid ${customPalette.gray400}` : '0',
        ...hoverStyle,

        // editor styles
        '.editor-editable': {
          minHeight: 80,
          padding: '8px 16px',
          outline:
            variant === 'outlined'
              ? `1px solid ${customPalette.gray400}`
              : 'unset',
          opacity: readOnly ? 0.5 : 1,
          maxHeight,
          overflow: 'auto',
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4,

          a: {
            color: customPalette.blue500,
          },
          // Prevent zooming on iPhone
          fontSize: '16px',
          touchAction: 'manipulation',
        },

        '.editor-placeholder': {
          color: palette.grey[500],
          overflow: 'hidden',
          position: 'absolute',
          top: 0,
          left: 0,
          padding: '8px 16px',
          userSelect: 'none',
          pointerEvents: 'none',
        },

        '.editor-paragraph': {
          margin: '0 0 15px 0',
        },

        '.editor-bold': {
          fontWeight: 'bold',
        },

        '.editor-italic': {
          fontStyle: 'italic',
        },
      }}
    >
      <LexicalComposer initialConfig={initialConfig}>
        <Toolbar readOnly={readOnly} actions={actions} />
        <div css={{ position: 'relative' }}>
          <RichTextPlugin
            ErrorBoundary={LexicalErrorBoundary}
            contentEditable={<ContentEditable className="editor-editable" />}
            placeholder={
              <div className="editor-placeholder">{placeholder}</div>
            }
          />
        </div>
        <ListPlugin />
        <LinkPlugin />
        <HistoryPlugin />
        <OnChangePlugin
          onChange={onChangeHandler}
          ignoreSelectionChange
          ignoreHistoryMergeTagChange
        />
        <UpdatePlugin initialValue={initialValue} />
      </LexicalComposer>
    </div>
  );
};
