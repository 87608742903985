import type { ReactNode } from 'react';

import { Chip } from '@material-ui/core';

import { useTheme } from '../../hooks/theme';

import { AnimChipIcons } from './AnimChipIcons';

type Props = {
  label: ReactNode;
  empty: boolean;
  active: boolean;
  onClick: () => void;
  onDelete: () => void;
};

export const AnimChip = ({
  label,
  empty,
  active,
  onClick,
  onDelete,
}: Props) => {
  const { colors, text } = useTheme();

  return (
    <Chip
      label={label}
      onClick={onClick}
      onDelete={onDelete}
      deleteIcon={<AnimChipIcons empty={empty} />}
      css={[
        {
          color: colors.mediumText,
          background: colors.white,
          border: `1px solid ${colors.borderDark}`,
          fontWeight: text.font.medium,
          cursor: 'pointer',
          '&:hover': {
            background: colors.grey100,
          },
          ':focus': {
            background: colors.grey200,
          },
        },
        active && {
          backgroundColor: colors.blue50,
          borderColor: colors.primaryMain,
          color: colors.primaryDark,
        },
      ]}
    />
  );
};
