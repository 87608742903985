import { Chip } from '@material-ui/core';
import Autocomplete, {
  type AutocompleteRenderInputParams,
} from '@material-ui/lab/Autocomplete';

type Options = { title: string; value: string }[];
type Props = {
  options: Options;
  renderInput: (props: AutocompleteRenderInputParams) => JSX.Element;
  value?: Options;
  onChange: (event: React.ChangeEvent<{}>, value: Options) => void;
};

export const AutocompleteMultiple = ({
  options,
  renderInput,
  value,
  onChange,
}: Props) => {
  return (
    <Autocomplete
      filterSelectedOptions={true}
      multiple={true}
      options={options}
      getOptionLabel={option => option.title}
      value={value}
      renderInput={renderInput}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            key={index}
            variant="outlined"
            label={option.title}
            {...getTagProps({ index })}
          />
        ))
      }
      onChange={onChange}
    />
  );
};
