import * as React from 'react';

import { CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import copy from 'clipboard-copy';
import { Title } from 'react-head';
import { graphql, useLazyLoadQuery } from 'react-relay';

import { DrawerContent, DrawerHeader, DrawerLayer } from '../controls/drawer';
import { useLocale } from '../hooks/locale';
import { useTheme } from '../hooks/theme';
import { Share } from '../icons/share';
import { LeadDetails, useLeadTitle } from '../routes/LeadDetails/lead-details';

import type { leadDrawerQuery } from './__generated__/leadDrawerQuery.graphql';

type Props = {
  leadId: null | string;
  onClose: (refetchLeads?: boolean) => void;
  onDelete: () => void;
};

const LeadDrawerHeader = ({
  leadId,
  leadRef,
  onClose,
}: {
  leadId: string;
  leadRef: leadDrawerQuery['response']['leadById'];
  onClose: () => void;
}) => {
  const { t, language } = useLocale();
  const [shareTitle, setShareTitle] = React.useState(
    t('leadUrlCopyToClipboard'),
  );
  const title = useLeadTitle(leadRef);
  return (
    <>
      <Title>{title}</Title>
      <DrawerHeader
        onClose={onClose}
        actions={
          <Tooltip title={shareTitle} placement="top">
            <IconButton
              color="inherit"
              onClick={() => {
                const leadLink = `${window.location.origin}/${language}/leads/${leadId}`;
                copy(leadLink).then(() => {
                  setShareTitle(t('leadUrlCopiedToClipboard'));
                  setTimeout(() => {
                    setShareTitle(t('leadUrlCopyToClipboard'));
                  }, 3000);
                });
              }}
            >
              <Share />
            </IconButton>
          </Tooltip>
        }
      >
        {title}
      </DrawerHeader>
    </>
  );
};

const LeadDrawerContent = ({
  leadId,
  onClose,
  onDelete,
}: {
  leadId: string;
  onClose: (refetchLeads?: boolean) => void;
  onDelete: () => void;
}) => {
  const data = useLazyLoadQuery<leadDrawerQuery>(
    graphql`
      query leadDrawerQuery($leadId: ID!) {
        ...leadDetails_root
        leadById(id: $leadId) {
          ...leadDetails_lead
          ...leadDetails_leadClaimData
        }
      }
    `,
    { leadId },
  );
  return (
    <>
      <LeadDrawerHeader
        leadId={leadId}
        leadRef={data.leadById}
        onClose={onClose}
      />
      {data.leadById != null && (
        <React.Suspense fallback={null}>
          <LeadDetails
            root={data}
            lead={data.leadById}
            onDelete={onDelete}
            closeLead={() => onClose(true)}
          />
        </React.Suspense>
      )}
    </>
  );
};

export const LeadDrawer = (props: Props) => {
  const { colors, sideBarWidth } = useTheme();
  return (
    <DrawerLayer
      open={props.leadId != null}
      onClose={props.onClose}
      width={['100vw', '100vw', `calc(100vw - ${sideBarWidth}px)`, '80vw']}
      paperBg={colors.superLightBlue}
    >
      <React.Suspense
        fallback={
          <>
            {props.leadId != null && (
              <LeadDrawerHeader
                leadId={props.leadId}
                leadRef={null}
                onClose={props.onClose}
              />
            )}
            <CircularProgress css={{ margin: 'auto' }} disableShrink />
          </>
        }
      >
        <DrawerContent>
          {props.leadId != null && (
            <LeadDrawerContent
              leadId={props.leadId}
              onClose={props.onClose}
              onDelete={props.onDelete}
            />
          )}
        </DrawerContent>
      </React.Suspense>
    </DrawerLayer>
  );
};
