// @flow

import * as React from 'react';

import { arrayMove } from '@dnd-kit/sortable';
import { orderBy } from '@realadvisor/array';
import { graphql, useFragment, useMutation } from 'react-relay';
import { Box } from 'react-system';

import { ImageManager } from '../../shared/image-manager/image-manager';
import { useTheme } from '../hooks/theme';

import type { propertyPhotos_property$key } from './__generated__/propertyPhotos_property.graphql';
import type { propertyPhotosCreateMutation } from './__generated__/propertyPhotosCreateMutation.graphql';
import type { propertyPhotosDeleteMutation } from './__generated__/propertyPhotosDeleteMutation.graphql';
import type { propertyPhotosOrderMutation } from './__generated__/propertyPhotosOrderMutation.graphql';
import { useFileUpload } from './file-upload';
import { PropertyImageForm } from './property-image-form';
import { PropertyImageQuickEdit } from './property-image-quick-edit';

type Props = {|
  property: propertyPhotos_property$key,
|};

export const PropertyPhotos = (props: Props): React.Node => {
  const { colors } = useTheme();
  const property = useFragment(
    graphql`
      fragment propertyPhotos_property on Property {
        id
        propertyImages {
          ...propertyImageQuickEdit_image
          ...propertyImageForm_image
          id
          orderNr
          title
          description
          image {
            id
            url
          }
        }
      }
    `,
    props.property,
  );

  const [uploadFile] = useFileUpload();
  const [createImage] = useMutation<propertyPhotosCreateMutation>(
    graphql`
      mutation propertyPhotosCreateMutation($input: CreatePropertyImageInput!) {
        createPropertyImage(input: $input) {
          property {
            ...propertyPhotos_property
            propertyImages {
              image {
                url
              }
            }
          }
        }
      }
    `,
  );
  const [deleteImage] = useMutation<propertyPhotosDeleteMutation>(
    graphql`
      mutation propertyPhotosDeleteMutation($input: DeletePropertyImageInput!) {
        deletePropertyImage(input: $input) {
          property {
            ...propertyPhotos_property
          }
        }
      }
    `,
  );
  const [orderImage] = useMutation<propertyPhotosOrderMutation>(
    graphql`
      mutation propertyPhotosOrderMutation($input: UpdatePropertyImageInput!) {
        updatePropertyImage(input: $input) {
          property {
            id
            propertyImages {
              id
              orderNr
            }
          }
        }
      }
    `,
  );

  const images = orderBy(property.propertyImages || [], v => v.orderNr);
  const propertyId = property.id;

  return (
    <Box
      width={1}
      css={{ minHeight: '100%', backgroundColor: colors.superLightBlue }}
    >
      <ImageManager
        // mapping is necessary to solve unused-fields eslint rule
        // TODO move fragment to image manager
        // or abstract data properly
        images={images.map(item => ({
          id: item.id,
          title: item.title,
          description: item.description,
          image: {
            id: item.image.id,
            url: item.image.url,
          },
        }))}
        onImageUpload={(image, done) => {
          uploadFile(image.file, 'images', url => {
            if (url == null) {
              done();
            } else {
              createImage({
                variables: {
                  input: {
                    url,
                    propertyId,
                  },
                },
                onCompleted: () => {
                  done();
                },
              });
            }
          });
        }}
        onImageDelete={(img, done) => {
          deleteImage({
            variables: {
              input: { id: img.id.toString() },
            },
            onCompleted: () => {
              done();
            },
          });
        }}
        onImageReorder={(from, to) => {
          const optimistic = arrayMove(images, from, to).map(
            (item, orderNr) => ({ id: item.id, orderNr }),
          );
          orderImage({
            variables: {
              input: {
                propertyId,
                id: images[from].id,
                destinationOrderNr: to,
              },
            },
            optimisticResponse: {
              updatePropertyImage: {
                property: {
                  id: propertyId,
                  propertyImages: optimistic,
                },
              },
            },
          });
        }}
        editContent={(image, showGrid: any) => (
          <PropertyImageForm
            key={image.id}
            // passed image does not have fragment refs
            // $FlowFixMe[incompatible-type]
            image={images.find(item => item.id === image.id)}
            onClose={showGrid}
          />
        )}
        quickEditContent={image => (
          <PropertyImageQuickEdit
            key={image.id}
            // passed image does not have fragment refs
            // $FlowFixMe[incompatible-type]
            image={images.find(item => item.id === image.id)}
          />
        )}
      />
    </Box>
  );
};
