/**
 * @generated SignedSource<<3bd17af5b1dfe3f1de721c48f8dd98bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type leadRefusedReasonsQuery$variables = {};
export type leadRefusedReasonsQuery$data = {
  readonly dictionaries_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly label: string | null;
      };
    }>;
  };
};
export type leadRefusedReasonsQuery = {
  response: leadRefusedReasonsQuery$data;
  variables: leadRefusedReasonsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "where",
        "value": {
          "type": {
            "_eq": "lead_refused_types"
          }
        }
      }
    ],
    "concreteType": "dictionariesConnection",
    "kind": "LinkedField",
    "name": "dictionaries_connection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "dictionariesEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "dictionaries",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "label",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "dictionaries_connection(where:{\"type\":{\"_eq\":\"lead_refused_types\"}})"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "leadRefusedReasonsQuery",
    "selections": (v0/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "leadRefusedReasonsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "8eeffc34ec1e1862e5ef4be323239201",
    "id": null,
    "metadata": {},
    "name": "leadRefusedReasonsQuery",
    "operationKind": "query",
    "text": "query leadRefusedReasonsQuery {\n  dictionaries_connection(where: {type: {_eq: lead_refused_types}}) {\n    edges {\n      node {\n        id\n        label\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "db0ac8e93d2c7a7cfd7f5027067f0e06";

export default node;
