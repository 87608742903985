// Generated from src/svgs/reply.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const Reply = ({ size, fill, className }: Props) => {
  return (
    <svg viewBox="0 0 16 14" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><path clipRule="evenodd" d="M6.222 3.556V0L0 6.222l6.222 6.222V8.8c4.445 0 7.556 1.422 9.778 4.533-.889-4.444-3.556-8.889-9.778-9.777Z" /></svg>
  );
}
