// Generated from src/svgs/archery.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const Archery = ({ size, fill, className }: Props) => {
  return (
    <svg viewBox="0 0 25 25" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><path d="M20.19 20.586A10.495 10.495 0 0 0 7.746 3.722a.5.5 0 0 0-.229.448c.01.18 1.04 1.148 1.2 1.228.161.08.527.1.677 0C12.808 3.197 17.169 3.5 20.19 7.343c2.31 2.939 2.611 7.442-.8 11.093-3.018 3.233-8.035 3.639-11.474.407-3.224-3.242-3.816-7.827-1-11.5l.652.591a7.473 7.473 0 1 0 2.554-2.124.5.5 0 0 0-.22.672c.077.154.667.694 1.098.918.257.134.408.047.5 0 .455-.23.983-.5 2-.5 3.056-.032 4.784 1.74 5.5 4 .748 2.5-.477 5.48-2.5 6.5-1.786.9-5.21 1.148-7-1-1.957-2.347-1.89-5.086-.4-7.1l.734.633A4.414 4.414 0 0 0 9 12.5a4.603 4.603 0 1 0 3.808-4.432.5.5 0 0 0-.417.571c.042.273 1.087 1.337 1.36 1.294.273-.042 1.353.207 1.775.967.48.864.746 2.128-.207 3.237-.43.5-1.464 1.146-2.357.83-1.107-.392-1.816-1.297-1.816-2.467 0-.48.115-.928.354-1.1l.61.541a1.529 1.529 0 1 0 .666-.747L5.358 4.651l.299-1.822L2.828 0l-.707 2.121L0 2.828l2.828 2.829 1.833-.3a.47.47 0 0 0 .031.041l.625.551c-3.6 4.463-2.933 10.993 1.493 14.637L4.1 24.2a.5.5 0 1 0 .8.6l2.71-3.613a10.473 10.473 0 0 0 11.78 0L22.1 24.8a.5.5 0 0 0 .8-.6l-2.71-3.614Z" /></svg>
  );
}
