// @flow

import * as React from 'react';

import {
  Avatar,
  FilledInput,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';
import Downshift from 'downshift';
import { graphql } from 'react-relay';

import { InputPopup } from '../controls/popup';
import { useLocale } from '../hooks/locale';
import { useInputQuery } from '../hooks/relay';
import { Cancel } from '../icons/cancel';
import { Home } from '../icons/home';

import type {
  BuyerLeadsFilters,
  buyerLeadInputQuery,
} from './__generated__/buyerLeadInputQuery.graphql';

export type BuyerLead = $ReadOnly<{
  id: string,
  user: $ReadOnly<{
    firstName: ?string,
    lastName: ?string,
    ...
  }>,
  lot: $ReadOnly<{
    title: ?string,
    lotReference: ?string,
    ...
  }>,
  ...
}>;

type Props = {|
  Input?: React.AbstractComponent<any>,
  filters?: BuyerLeadsFilters,
  value: ?BuyerLead,
  onChange: (?BuyerLead) => void,
  onBlur?: () => void,
|};

graphql`
  fragment buyerLeadInput_buyerLead on BuyerLead {
    id
    user {
      firstName
      lastName
    }
    lot {
      lotReference
      title
    }
  }
`;

const getBuyerLeadContactName = buyerLead => {
  const firstName = buyerLead?.user?.firstName;
  const lastName = buyerLead?.user?.lastName;
  if (firstName != null && lastName != null) {
    return `${firstName} ${lastName}`;
  }
  if (firstName != null) {
    return firstName;
  }
  if (lastName != null) {
    return lastName;
  }
  return '';
};

const getItems = data =>
  (data?.buyerLeads?.edges || []).map(edge => edge?.node).filter(Boolean);

const BuyerLeadMenu = ({ targetRef, items, downshift }) => {
  const { t } = useLocale();
  const { isOpen, highlightedIndex, getMenuProps, getItemProps } = downshift;
  return (
    <InputPopup referenceRef={targetRef} open={isOpen}>
      <List {...getMenuProps()}>
        {items.length === 0 && (
          <ListItem disabled={true}>{t('No options')}</ListItem>
        )}
        {isOpen &&
          items.map((item, index) => (
            <ListItem
              {...getItemProps({ item })}
              key={item.id}
              button={true}
              selected={highlightedIndex === index}
            >
              <ListItemAvatar>
                <Avatar>
                  <Home />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={getBuyerLeadContactName(item)}
                secondary={[item.lot?.lotReference, ' ', item.lot?.title]}
              />
            </ListItem>
          ))}
      </List>
    </InputPopup>
  );
};

export const BuyerLeadInput = (props: Props): React.Node => {
  const [data, refetch] = useInputQuery<buyerLeadInputQuery>(
    graphql`
      query buyerLeadInputQuery($filters: BuyerLeadsFilters) {
        buyerLeads(first: 20, filters: $filters) {
          edges {
            node {
              ...buyerLeadInput_buyerLead @relay(mask: false)
            }
          }
        }
      }
    `,
  );

  const { Input = FilledInput, filters } = props;
  const targetRef = React.useRef(null);
  return (
    <Downshift
      // make selectedItem always controlled
      selectedItem={props.value ?? null}
      itemToString={getBuyerLeadContactName}
      onInputValueChange={inputValue =>
        refetch({ filters: { ...filters, search: inputValue } })
      }
      onChange={selectedItem => props.onChange(selectedItem)}
    >
      {downshift => {
        const { getInputProps, inputValue, selectedItem } = downshift;
        const { openMenu } = downshift;
        return (
          <div>
            <Input
              {...getInputProps({
                ref: targetRef,
                onFocus: () => {
                  refetch({ filters: { ...filters, search: inputValue } });
                  openMenu();
                },
                onBlur: props.onBlur,
                startAdornment: selectedItem != null && (
                  <InputAdornment position="start">
                    <Avatar style={{ width: 24, height: 24 }}>
                      <Home />
                    </Avatar>
                  </InputAdornment>
                ),
                endAdornment: selectedItem && (
                  <InputAdornment position="end">
                    <IconButton onClick={() => props.onChange(null)}>
                      <Cancel />
                    </IconButton>
                  </InputAdornment>
                ),
              })}
            />
            <BuyerLeadMenu
              targetRef={targetRef}
              items={getItems(data)}
              downshift={downshift}
            />
          </div>
        );
      }}
    </Downshift>
  );
};
