import { useConstant } from '@realadvisor/hooks';

import { Filter } from '../controls/Filters';
import { useLocale } from '../hooks/locale';
import {
  type Field,
  makeField,
  makeUrlSearchParamsHook,
} from '../hooks/url-search-params';
import { BaseUserFilter } from '../shared/base-user-filter';

export type BrokerParams = {
  brokerId_in: Field<Array<string | null> | null>;
};

export const brokerParams: BrokerParams = {
  brokerId_in: makeField({
    get: params => {
      const list = params.getAllStrings('brokerId_in');
      return list.length === 0
        ? null
        : list.map(id => {
            if (id === 'null') {
              return null;
            }
            return id;
          });
    },
    set: (params, value) => {
      const valueWithoutNull = (value ?? []).map(v => {
        if (v == null) {
          return 'null';
        }
        return v;
      });
      return params.setAllStrings('brokerId_in', valueWithoutNull);
    },
  }),
};

const useBrokerParams = makeUrlSearchParamsHook<BrokerParams>(brokerParams);

type BrokerFilterProps = {
  showNoAgentFilter?: boolean;
  onlyInCurrentTeams?: boolean;
  title?: string;
};

export const BrokerFilter = (props: BrokerFilterProps) => {
  const { t } = useLocale();
  const { showNoAgentFilter = false, title = t('brokers') } = props;
  const [params, setParams] = useBrokerParams();
  const namesCache = useConstant(() => new Map<null | string, string>());
  const selectedIds = params.brokerId_in ?? [];
  const setValue = (node: { id: null | string; name: string | null }) => {
    const newSelectedIds = new Set(selectedIds);
    if (newSelectedIds.has(node.id)) {
      newSelectedIds.delete(node.id);
    } else {
      newSelectedIds.add(node.id);
    }
    if (node.name != null) {
      namesCache.set(node.id, node.name);
    }
    setParams({ brokerId_in: Array.from(newSelectedIds) });
  };
  let label;
  const count = selectedIds.length;
  const first = selectedIds
    .map(id => namesCache.get(id))
    .find(name => name != null);
  if (count === 0) {
    label = title;
  } else if (first != null) {
    label = count > 1 ? `${first} +${count - 1}` : first;
  } else {
    label = t('brokersSelected', { count });
  }
  return (
    <Filter
      label={label}
      dialogTitle={title}
      empty={count === 0}
      onReset={() => setParams({ brokerId_in: [] })}
    >
      <BaseUserFilter
        filters={{
          isBroker: true,
          only_in_current_teams: props.onlyInCurrentTeams,
        }}
        selectedIds={selectedIds}
        onChange={setValue}
        showNoAssignedOption={showNoAgentFilter}
      />
    </Filter>
  );
};
