/**
 * @generated SignedSource<<1996341227a61a1e597d3dfdf3fc93de>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserName_user$data = {
  readonly emails: ReadonlyArray<{
    readonly email: string;
  }>;
  readonly firstName: string | null;
  readonly id: string;
  readonly lastName: string | null;
  readonly " $fragmentType": "UserName_user";
};
export type UserName_user$key = {
  readonly " $data"?: UserName_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserName_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserName_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Email",
      "kind": "LinkedField",
      "name": "emails",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "2d1f0f27a7fcd0d717cab436f4fe1ee3";

export default node;
