/**
 * @generated SignedSource<<e91ccecff31eb4845db7ea0ceff25e7b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type parentLink_enquiry$data = {
  readonly id: string;
  readonly " $fragmentType": "parentLink_enquiry";
};
export type parentLink_enquiry$key = {
  readonly " $data"?: parentLink_enquiry$data;
  readonly " $fragmentSpreads": FragmentRefs<"parentLink_enquiry">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "parentLink_enquiry",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Enquiry",
  "abstractKey": null
};

(node as any).hash = "fa8a9d08434e3ee721144854efc0e4e3";

export default node;
