import { Box } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import { ListingPropertyImages } from '../../pages/listings/ListingPropertyImages';

import { type ListingCreationStepComponent } from './CreateListingStepper';

export type ListingImagesStepForm = {
  step: 'photos';
};

export const ListingImagesStep: ListingCreationStepComponent = ({
  footerActionsComponent,
  listing,
  onFormSubmitted,
}) => {
  const formMethods = useForm();

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <ListingPropertyImages
        propertyId={listing?.property.id}
        canEditImagesMeta={false}
      />
      <FormProvider {...formMethods}>
        {/* Empty form to match the footer behavior */}
        <form
          onSubmit={() => onFormSubmitted({ action: 'update' }, false, true)}
        >
          {footerActionsComponent}
        </form>
      </FormProvider>
    </Box>
  );
};
