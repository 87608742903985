import { useQuery } from '@apollo/client';
import { Box, CircularProgress } from '@mui/material';

import { useLocale } from '../../../../src/hooks/locale';
import type { SetHook } from '../../../../src/hooks/set';
import type {
  StepListingsCompareAlgoQuery,
  StepListingsCompareFragment,
} from '../../../__generated__/graphql';
import { STEP_LISTINGS_COMPARE_ALGO } from '../cmaReportsQueries';
import { getAutoAlgoParams } from '../shared';

import ListingGrid from './ListingGrid';

interface ListingAutomaticProps {
  data: StepListingsCompareFragment;
  onSelect: (id: number) => void;
  selection: SetHook<number>;
}

export type ListingAutomaticType = Omit<
  NonNullable<
    | StepListingsCompareAlgoQuery['ch_get_similar_listings']
    | StepListingsCompareAlgoQuery['fr_get_similar_listings']
    | StepListingsCompareAlgoQuery['es_get_similar_listings']
    | StepListingsCompareAlgoQuery['it_get_similar_listings']
  >[number],
  'property_type'
> & {
  property_type: null | string;
};

const ListingAutomatic = ({
  selection,
  onSelect,
  data: fragmentData,
}: ListingAutomaticProps) => {
  const { countryCode } = useLocale();

  const autoAlgoParams = getAutoAlgoParams(fragmentData?.lead, countryCode);
  const { data, loading } = useQuery(STEP_LISTINGS_COMPARE_ALGO, {
    ...autoAlgoParams,
  });

  const listings: ListingAutomaticType[] =
    data?.ch_get_similar_listings ??
    data?.es_get_similar_listings ??
    data?.fr_get_similar_listings ??
    data?.it_get_similar_listings ??
    [];

  if (loading) {
    return (
      <Box
        flexGrow={1}
        justifyContent="center"
        display="flex"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ListingGrid
      listings={listings}
      selection={selection}
      onSelect={onSelect}
    />
  );
};

export default ListingAutomatic;
