/**
 * @generated SignedSource<<07d1ea80a3b1af65b30b37f934a628ce>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteLeadsInput = {|
  clientMutationId?: ?string,
  where?: ?DeleteLeadsWhere,
|};
export type DeleteLeadsWhere = {|
  id_in?: ?$ReadOnlyArray<string>,
|};
export type leadDeleteDialogMutation$variables = {|
  input: DeleteLeadsInput,
|};
export type leadDeleteDialogMutation$data = {|
  +deleteLeads: ?{|
    +affectedIds: $ReadOnlyArray<string>,
  |},
|};
export type leadDeleteDialogMutation = {|
  response: leadDeleteDialogMutation$data,
  variables: leadDeleteDialogMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteLeadsPayload",
    "kind": "LinkedField",
    "name": "deleteLeads",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "affectedIds",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "leadDeleteDialogMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "leadDeleteDialogMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a711f7f91192d105f8352a405e22748e",
    "id": null,
    "metadata": {},
    "name": "leadDeleteDialogMutation",
    "operationKind": "mutation",
    "text": "mutation leadDeleteDialogMutation(\n  $input: DeleteLeadsInput!\n) {\n  deleteLeads(input: $input) {\n    affectedIds\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "431f4b87637b3fda5beaffd3d0e8b31b";

export default node;
