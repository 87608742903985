/**
 * @generated SignedSource<<70dd9a28ca56bed65b4cf762dd887b5b>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
type propertyCardPlot_location$fragmentType = any;
export type PoiMapDrawerPlaceQuery$variables = {|
  lat: number,
  lng: number,
|};
export type PoiMapDrawerPlaceQuery$data = {|
  +googlePlace: ?{|
    +address: ?{|
      +countryCode: ?string,
      +googlePlaceId: ?string,
      +locality: ?string,
      +postcode: ?string,
      +route: ?string,
      +state: ?string,
      +streetNumber: ?string,
    |},
    +location: ?{|
      +plot: ?{|
        +owners: ?{|
          +names: ?$ReadOnlyArray<string>,
        |},
      |},
      +$fragmentSpreads: propertyCardPlot_location$fragmentType,
    |},
  |},
  +tenantSettings: ?{|
    +activateLandRegistryOwners: boolean,
  |},
|};
export type PoiMapDrawerPlaceQuery = {|
  response: PoiMapDrawerPlaceQuery$data,
  variables: PoiMapDrawerPlaceQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "lat"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "lng"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "activateLandRegistryOwners",
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "lat",
    "variableName": "lat"
  },
  {
    "kind": "Variable",
    "name": "lng",
    "variableName": "lng"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Address",
  "kind": "LinkedField",
  "name": "address",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "route",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "streetNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postcode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "locality",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "countryCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "googlePlaceId",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Owners",
  "kind": "LinkedField",
  "name": "owners",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "names",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PoiMapDrawerPlaceQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantSettings",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "GooglePlace",
        "kind": "LinkedField",
        "name": "googlePlace",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Location",
            "kind": "LinkedField",
            "name": "location",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "propertyCardPlot_location"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Plot",
                "kind": "LinkedField",
                "name": "plot",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PoiMapDrawerPlaceQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantSettings",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "GooglePlace",
        "kind": "LinkedField",
        "name": "googlePlace",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Location",
            "kind": "LinkedField",
            "name": "location",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Plot",
                "kind": "LinkedField",
                "name": "plot",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "localityId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "plotId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "surface",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "landRegistryURL",
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Zone",
                "kind": "LinkedField",
                "name": "zone",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ZoneDefinition",
                    "kind": "LinkedField",
                    "name": "definition",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cantonZoneName",
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7b3144742872e892c780947fb9764586",
    "id": null,
    "metadata": {},
    "name": "PoiMapDrawerPlaceQuery",
    "operationKind": "query",
    "text": "query PoiMapDrawerPlaceQuery(\n  $lat: Float!\n  $lng: Float!\n) {\n  tenantSettings {\n    activateLandRegistryOwners\n    id\n  }\n  googlePlace(lat: $lat, lng: $lng) {\n    address {\n      route\n      streetNumber\n      postcode\n      state\n      locality\n      countryCode\n      googlePlaceId\n    }\n    location {\n      ...propertyCardPlot_location\n      plot {\n        owners {\n          names\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment propertyCardPlot_location on Location {\n  plot {\n    localityId\n    plotId\n    surface\n    landRegistryURL\n    id\n  }\n  zone {\n    definition {\n      cantonZoneName\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "91643933071287db260ac6d592098889";

export default node;
