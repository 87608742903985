// @flow

// original file realadvisor.api/src/schema/CompositePropertyTypeType.js

export type CompositePropertyType =
  | 'HOUSE_APPT'
  | 'HOUSE'
  | 'APPT'
  | 'ROOM'
  | 'PARK'
  | 'PROP'
  | 'BUILDING'
  | 'COMMERCIAL'
  | 'GASTRO'
  | 'OTHER';

export type Composition = {|
  propertyMainType_in?: $ReadOnlyArray<string>,
  propertyMainType_nin?: $ReadOnlyArray<string>,
  propertyType_in?: $ReadOnlyArray<string>,
  propertyType_nin?: $ReadOnlyArray<string>,
|};

const substitutions: Array<[CompositePropertyType, Composition]> = [
  [
    'HOUSE',
    {
      propertyMainType_in: ['HOUSE'],
      propertyType_nin: ['house_multiple_dwelling'],
    },
  ],

  [
    'APPT',
    {
      propertyMainType_in: ['APPT'],
      propertyType_nin: ['apartment_single_room'],
    },
  ],

  [
    'HOUSE_APPT',
    {
      propertyMainType_in: ['HOUSE', 'APPT'],
      propertyType_nin: ['house_multiple_dwelling', 'apartment_single_room'],
    },
  ],

  ['ROOM', { propertyType_in: ['apartment_single_room'] }],

  ['PARK', { propertyMainType_in: ['PARK'] }],

  ['PROP', { propertyMainType_in: ['PROP'] }],

  [
    'BUILDING',
    {
      propertyType_in: [
        'house_multiple_dwelling',
        'indus_commercial_and_residential',
      ],
    },
  ],

  [
    'COMMERCIAL',
    {
      propertyMainType_in: ['INDUS', 'AGRI'],
      propertyType_nin: ['indus_commercial_and_residential'],
    },
  ],

  ['GASTRO', { propertyMainType_in: ['GASTRO'] }],
];

const coveredMainTypes = substitutions
  .map(([, { propertyMainType_in }]) => propertyMainType_in)
  .filter(Boolean)
  .reduce((acc, cur) => [...acc, ...cur], []);

const coveredTypes = substitutions
  .map(([, { propertyType_in }]) => propertyType_in)
  .filter(Boolean)
  .reduce((acc, cur) => [...acc, ...cur], []);

substitutions.push([
  'OTHER',
  { propertyMainType_nin: coveredMainTypes, propertyType_nin: coveredTypes },
]);

export const decomposePropertyType = (
  composite: CompositePropertyType,
): Composition => {
  const substitution = substitutions.find(([x]) => x === composite);

  if (substitution != null) {
    return substitution[1];
  }

  throw new Error(`Unsupported compositePropertyType: ${composite}`);
};

export const composePropertyType = (
  property_main_type_in: ?(string[]),
  property_type_in: ?(string[]),
  property_type_nin: ?(string[]),
): CompositePropertyType => {
  if (
    property_main_type_in == null &&
    property_type_in == null &&
    property_type_nin == null
  ) {
    return 'OTHER';
  }

  const substitution = substitutions.find(
    ([, { propertyMainType_in, propertyType_in, propertyType_nin }]) => {
      const propertyTypeValue = (() => {
        if (property_type_in != null && propertyType_in != null) {
          return property_type_in.every(x => propertyType_in.includes(x));
        } else if (property_type_nin != null && propertyType_nin != null) {
          return property_type_nin.every(x => propertyType_nin.includes(x));
        }
      })();

      if (property_main_type_in == null && propertyMainType_in != null) {
        return false;
      }

      if (property_main_type_in != null && propertyMainType_in != null) {
        const mainCheck = property_main_type_in.every(x =>
          propertyMainType_in.includes(x),
        );

        return mainCheck && propertyTypeValue;
      } else {
        return propertyTypeValue;
      }
    },
  );

  if (substitution == null) {
    return 'OTHER';
  }

  return substitution[0];
};

export const getFiltersFromComposedPropertyType = (
  compositePropertyType: CompositePropertyType,
): any => {
  const composed = decomposePropertyType(compositePropertyType);
  const filter: any = {
    property_main_type_in: null,
    property_type_in: null,
    property_type_nin: null,
  };

  if (composed.propertyMainType_in != null) {
    filter.property_main_type_in = composed.propertyMainType_in.join(',');
  }
  if (composed.propertyMainType_nin != null) {
    filter.property_main_type_nin = composed.propertyMainType_nin.join(',');
  }

  if (composed.propertyType_in != null) {
    filter.property_type_in = composed.propertyType_in.join(',');
  }
  if (composed.propertyType_nin != null) {
    filter.property_type_nin = composed.propertyType_nin.join(',');
  }

  return filter;
};
