import { getDeploymentByOrigin } from '@realadvisor/host-resolver';
import { toImgproxyPath } from './imgproxy';
import type { ImgProxyOptions } from './imgproxy';

export type BucketImage = {
  bucketName: string;
  fileName: string;
};

export type SourceImage = BucketImage | string;

export type ImgproxyImageOptions = {
  // width
  w?: number;
  // height
  h?: number;
  // crop
  c?:
    | 'fit'
    | 'fill'
    // same as fit but dont expand image
    | 'limit'
    | 'scale';
  // allow only widely supported formats for Images, can be optional
  f?: 'png' | 'jpg' | 'gif';
  b?: string;
  e?: 'trim';

  // Properties we can add support on img proxy but now don't used so
  // and no support is done on cloudflare proxy side.
  // Uncommenting will not work, it just fyi
  // Quality
  q?: number; // 0 - 100,
  // Background color to fill transparent
  // b?: string,
  // Gaussian blur
  e_blur?: number;
  // Overlays are supported but only fixed for now
  overlay?: string;
};

// We must enforce f parameter usage for raw cloudinaryUrl usage
export type Options = Omit<ImgproxyImageOptions, 'f'> & {
  f: 'webp' | 'png' | 'jpg' | 'gif';
};

const normalizeSource = (source: SourceImage): string => {
  if (typeof source === 'string') {
    return source;
  }

  return `https://storage.googleapis.com/${source.bucketName}/${source.fileName}`;
};

const getFileUrl = (url: string) => {
  // Same as passed in config IMGPROXY_ALLOWED_SOURCES see here https://docs.imgproxy.net/#/configuration
  const allowedSources = [
    'https://storage.googleapis.com/img.realadvisor.ch/',
    'https://storage.googleapis.com/img-dev.realadvisor.ch/',
    'https://storage.googleapis.com/aggregator-images/',
    'https://storage.googleapis.com/aggregator-images-test/',
    // some static images. used in realadvisor.aggregator
    'https://storage.googleapis.com/aggregator-images2/',
    // files from Prismic
    'https://images.prismic.io/realadvisor/',
  ];

  const isAllowedToTransform = allowedSources.some(source =>
    url.startsWith(source),
  );

  if (!isAllowedToTransform) {
    return null;
  }

  return url;
};

// Historically we used cloudinary for image conversions, so all frontend/backend code
// uses cloudinary options format.
// To not rewrite all the code we convert cloudinary options => imgproxy
const optionsToImgProxyOptions = (options: Options): ImgProxyOptions => {
  const imgProxyOptions: ImgProxyOptions = {
    width: null,
    height: null,
    // We use jpeg as default format
    format: 'jpg',
    resizingType: 'fill',
    expand: 1,
    bg: null,
    trim: null,
    blur: null,
    quality: null,
  };

  if (options.w != null) {
    imgProxyOptions.width = Math.round(options.w);
  }

  if (options.h != null) {
    imgProxyOptions.height = Math.round(options.h);
  }

  if (options.c === 'fit' || options.c === 'fill') {
    imgProxyOptions.resizingType = options.c;
    imgProxyOptions.expand = 1;
  }

  if (options.c === 'limit') {
    imgProxyOptions.resizingType = 'fit';
    imgProxyOptions.expand = 0;
  }

  if (options.f != null) {
    imgProxyOptions.format = options.f;
  }

  if (options.b != null) {
    const colorName = options.b;
    const colors: Record<string, string | null> = {
      black: '000000',
      white: 'ffffff',
      red: 'ff0000',
    };
    const fallbackColor = colorName.startsWith('#')
      ? colorName.slice(1)
      : colorName;
    imgProxyOptions.bg = colors[colorName] ?? fallbackColor;
  }

  if (options.e === 'trim') {
    const TRIM_TOLERANCE = 0.02;
    imgProxyOptions.trim = TRIM_TOLERANCE;
  }

  if (options.e_blur != null) {
    imgProxyOptions.blur = options.e_blur / 100;
  }

  if (options.q != null) {
    imgProxyOptions.quality = options.q;
  }

  if (options.overlay != null) {
    imgProxyOptions.watermark = {
      opacity: 0.6,
      position: 'soea',
      xOffset: 40,
      yOffset: 40,
      scale: 0.2, // imgProxyOptions.width != null ? 300 / imgProxyOptions.width : 0,
      // base64Url supported only in PRO version
      base64Url: '',
    };
  }

  return imgProxyOptions;
};

let warnOnce_ = false;
let imagesDomain_: null | string = null;

const getImagesDomain = () => {
  const production = 'https://img.realadvisor.ch';
  const test = 'https://img-test.realadvisor.ch';
  if (imagesDomain_ != null) {
    return imagesDomain_;
  }
  imagesDomain_ = production;
  if (typeof window !== 'undefined') {
    try {
      if (getDeploymentByOrigin(window.location.origin) !== 'production') {
        imagesDomain_ = test;
      }
      // eslint-disable-next-line no-empty
    } catch {}
  } else {
    if (process.env.DEPLOYMENT !== 'production') {
      imagesDomain_ = test;
    }
  }
  return imagesDomain_;
};

export const cloudinaryUrl = (
  options: Options,
  rawSource: SourceImage,
): string => {
  // This is not possible from types point of view,
  // but still may happen because some old implementations supported null as input
  if (rawSource == null || rawSource === '') {
    return '';
  }

  const source = normalizeSource(rawSource);
  const fileUrl = getFileUrl(source);

  if (fileUrl == null) {
    if (process.env.NODE_ENV !== 'production') {
      if (!warnOnce_) {
        console.info(
          `Cannot apply cloudinaryUrl. Unsupported source URL: ${source}`,
        );
        warnOnce_ = true;
      }
    }

    return source;
  }

  const imagesDomain = getImagesDomain();

  return `${imagesDomain}/${toImgproxyPath(
    fileUrl,
    optionsToImgProxyOptions(options),
  )}`;
};
