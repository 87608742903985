/**
 * @generated SignedSource<<eeb19c5664c9823cb30d7d97bf1b62ec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeedVisit_root$data = {
  readonly " $fragmentSpreads": FragmentRefs<"activityWorkflowDialog_root">;
  readonly " $fragmentType": "FeedVisit_root";
};
export type FeedVisit_root$key = {
  readonly " $data"?: FeedVisit_root$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedVisit_root">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedVisit_root",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "activityWorkflowDialog_root"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "a1bf3d2bc251bff02c506f2a57cec719";

export default node;
