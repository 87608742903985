import * as React from 'react';

import { ButtonBase } from '@material-ui/core';
import scrollbarSize from 'dom-helpers/scrollbarSize';
import { Box, Flex } from 'react-system';

import { useTheme } from '../../hooks/theme';

import { Provider } from './context';

type Props = {
  actions?: React.ReactNode;
  children?: React.ReactNode;
};

const MENU_HEIGHT = 48;
// To hide even Mac or Mobile scrollbar if scrollbarSize() is 0
const MAX_SCROLL_BAR_SIZE = 32;
const FILTER_SCROLL_DISTANCE = 150;

const baseArrowButtonStyles = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  top: 0,
  bottom: 0,
  width: 32,
} as const;

React.forwardRef;

export const FiltersMenu = ({ actions, children }: Props) => {
  const { colors } = useTheme();
  const dialogBg = colors.primaryMain;
  const menuBg = colors.subMenu;
  const [showScrollLeft, setShowScrollLeft] = React.useState(false);
  const [showScrollRight, setShowScrollRight] = React.useState(false);
  const filtersMenuContainerRef = React.useRef<null | HTMLDivElement>(null);
  const filtersMenuRef = React.useRef<null | HTMLDivElement>(null);

  React.useEffect(() => {
    const firstChildObserver = new IntersectionObserver(([entry]) => {
      setShowScrollLeft(!entry.isIntersecting);
    });

    const lastChildObserver = new IntersectionObserver(([entry]) => {
      setShowScrollRight(!entry.isIntersecting);
    });

    const firstChildElement = filtersMenuRef.current?.firstElementChild;
    const lastChildElement = filtersMenuRef.current?.lastElementChild;
    if (firstChildElement != null && lastChildElement != null) {
      firstChildObserver.observe(firstChildElement);
      lastChildObserver.observe(lastChildElement);
      return () => {
        firstChildObserver.unobserve(firstChildElement);
        lastChildObserver.unobserve(lastChildElement);
      };
    }
  }, [filtersMenuRef]);

  return (
    <Flex
      alignItems="center"
      css={{
        backgroundColor: menuBg,
        borderBottom: `1px solid ${colors.borderMain}`,
        color: colors.mediumText,
      }}
    >
      <Flex
        flexGrow={1}
        alignItems="flex-start"
        height={MENU_HEIGHT + 'px'}
        css={{
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <Flex
          ref={filtersMenuContainerRef}
          alignItems="flex-start"
          height={MENU_HEIGHT + (scrollbarSize() || MAX_SCROLL_BAR_SIZE) + 'px'}
          css={{
            overflowX: 'auto',
            overflowY: 'hidden',
            WebkitOverflowScrolling: 'touch',
            scrollBehavior: 'smooth',
          }}
        >
          <Box flexShrink={0}>
            <Flex
              ref={filtersMenuRef}
              px={3}
              alignItems="center"
              height={MENU_HEIGHT + 'px'}
            >
              <Provider value={{ menuBg, dialogBg }}>{children}</Provider>
            </Flex>
          </Box>
        </Flex>
        {showScrollLeft && (
          <ButtonBase
            onClick={() => {
              if (filtersMenuContainerRef.current) {
                filtersMenuContainerRef.current.scrollBy(
                  -FILTER_SCROLL_DISTANCE,
                  0,
                );
              }
            }}
            css={{
              ...baseArrowButtonStyles,
              background:
                'linear-gradient(90deg, rgba(246,247,249,1) 70%, rgba(246,247,249,0) 100%)',
              left: 0,
            }}
          >
            <Box
              css={{
                width: 0,
                height: 0,
                borderTop: '6px solid transparent',
                borderBottom: '6px solid transparent',
                borderRight: `6px solid ${colors.mediumText}`,
              }}
            />
          </ButtonBase>
        )}
        {showScrollRight && (
          <ButtonBase
            onClick={() => {
              if (filtersMenuContainerRef.current) {
                filtersMenuContainerRef.current.scrollBy(
                  FILTER_SCROLL_DISTANCE,
                  0,
                );
              }
            }}
            css={{
              ...baseArrowButtonStyles,
              background:
                'linear-gradient(270deg, rgba(246,247,249,1) 70%, rgba(246,247,249,0) 100%)',
              borderRight: `1px solid ${colors.borderMain}`,
              right: 0,
            }}
          >
            <div
              css={{
                width: 0,
                height: 0,
                borderTop: '6px solid transparent',
                borderBottom: '6px solid transparent',
                borderLeft: `6px solid ${colors.mediumText}`,
              }}
            />
          </ButtonBase>
        )}
      </Flex>

      {actions != null && (
        // relative hoists actions under filters
        <Flex
          flexShrink={0}
          px={2}
          css={{
            backgroundColor: menuBg,
            color: colors.mediumText,
            position: 'relative',
          }}
        >
          {actions}
        </Flex>
      )}
    </Flex>
  );
};
