// Generated from src/svgs/realadvisor-logo-no-text.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const RealadvisorLogoNoText = ({ size, fill, className }: Props) => {
  return (
    <svg viewBox="0 0 140 140" xmlSpace="preserve" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><linearGradient id="realadvisor-logo-no-text_svg__a" gradientUnits="userSpaceOnUse" x1="20.503" y1="119.498" x2="119.497" y2="20.503"><stop offset="0" style={{"stopColor":"#54beda"}} /><stop offset="1" style={{"stopColor":"#0a39b7"}} /></linearGradient><path style={{"fill":"url(#realadvisor-logo-no-text_svg__a)"}} d="M50.09 56.82a9.903 9.903 0 0 1 6.72-6.72l46.88-13.79L89.9 83.18a9.903 9.903 0 0 1-6.72 6.72l-46.87 13.79 13.78-46.87zM0 70c0 38.66 31.34 70 70 70s70-31.34 70-70c0-18.56-7.38-36.37-20.5-49.5A70.048 70.048 0 0 0 70 0C31.34 0 0 31.34 0 70" /></svg>
  );
}
