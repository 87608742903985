/**
 * @generated SignedSource<<800bd544a407f8d23ed813d72b33bf90>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type leadOtherLeadsCardQuery$variables = {
  leadId: string;
};
export type leadOtherLeadsCardQuery$data = {
  readonly leadsTheSameAddress: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"leadOtherLeadsCard_lead">;
  } | null> | null;
};
export type leadOtherLeadsCardQuery = {
  response: leadOtherLeadsCardQuery$data;
  variables: leadOtherLeadsCardQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "leadId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "leadId",
    "variableName": "leadId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "leadOtherLeadsCardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Lead",
        "kind": "LinkedField",
        "name": "leadsTheSameAddress",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "leadOtherLeadsCard_lead"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "leadOtherLeadsCardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Lead",
        "kind": "LinkedField",
        "name": "leadsTheSameAddress",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "contact",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "relationship",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "appraisalReason",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "saleHorizon",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "buyHorizon",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "LeadStage",
            "kind": "LinkedField",
            "name": "stage",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Pipeline",
                "kind": "LinkedField",
                "name": "pipeline",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "leadType",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c19c86b6bb7b6412d7e23d9b820de2ee",
    "id": null,
    "metadata": {},
    "name": "leadOtherLeadsCardQuery",
    "operationKind": "query",
    "text": "query leadOtherLeadsCardQuery(\n  $leadId: ID!\n) {\n  leadsTheSameAddress(leadId: $leadId) {\n    id\n    ...leadOtherLeadsCard_lead\n  }\n}\n\nfragment leadOtherLeadsCard_lead on Lead {\n  id\n  contact {\n    id\n    firstName\n    lastName\n  }\n  relationship\n  appraisalReason\n  saleHorizon\n  buyHorizon\n  stage {\n    pipeline {\n      leadType\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "7421b74a109ced9f4f843791167a6b63";

export default node;
