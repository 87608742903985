import Delete from '@mui/icons-material/Delete';
import Visibility from '@mui/icons-material/Visibility';
import { Stack, Typography } from '@mui/material';
import {
  GridActionsCellItem,
  type GridActionsColDef,
  type GridColDef,
} from '@mui/x-data-grid-pro';

import { FileIcon } from '../../../src/controls/file-icon';
import { useLocale } from '../../../src/hooks/locale';
import { type FileFragment } from '../../__generated__/graphql';

export const useFileDef = <T extends { file: FileFragment }>() => {
  const { t } = useLocale();
  const fileDef: GridColDef<T> = {
    field: 'file',
    headerName: t('File'),
    display: 'flex',
    flex: 2,
    cellClassName: 'file-cell',
    minWidth: 150,
    renderCell: ({ row: { file } }) => {
      const hasViewer = file.mimetype === 'application/pdf';

      return (
        <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
          <FileIcon mimetype={file.mimetype ?? ''} />
          <Typography noWrap>
            {hasViewer ? (
              file.name
            ) : (
              <a href={file.url} target="_blank" rel="noopener noreferrer">
                {file.name}
              </a>
            )}
          </Typography>
        </Stack>
      );
    },
    sortable: false,
  };
  return fileDef;
};

export const useFileActionsDef = <T extends { file: FileFragment }>({
  setViewerFile,
  deleteFile,
  setDeleteFile,
  props,
  showInMenu = false,
}: {
  setViewerFile: (file: FileFragment) => void;
  deleteFile: T | null;
  setDeleteFile: (file: T) => void;
  props?: Partial<GridActionsColDef<T>>;
  showInMenu?: boolean;
}) => {
  const { t } = useLocale();
  const fileActionsDef: GridActionsColDef<T> = {
    field: 'actions',
    type: 'actions',
    align: 'right',
    getActions: ({ row }) => {
      const hasViewer = row.file.mimetype === 'application/pdf';
      const actionsMap = [
        {
          key: 'preview',
          icon: <Visibility />,
          label: t('Preview'),
          onClick: () => setViewerFile(row.file),
        },
        {
          key: 'delete',
          icon: <Delete />,
          label: t('Delete'),
          disabled: deleteFile != null,
          onClick: () => setDeleteFile(row),
        },
      ].filter(action => hasViewer || action.key !== 'preview');

      return actionsMap.map(action => (
        <GridActionsCellItem
          key={action.key}
          icon={action.icon}
          label={action.label}
          onClick={action.onClick}
          color="inherit"
          showInMenu={showInMenu}
        />
      ));
    },
    ...props,
  };
  return fileActionsDef;
};
