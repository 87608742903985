/**
 * @generated SignedSource<<4455e5f6a03c0182c3c1d385807fdc9e>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type PipelineLeadType = "buyer" | "financing" | "sales";
export type leadCreateDrawerQuery$variables = {||};
export type leadCreateDrawerQuery$data = {|
  +leadSourceTypes: $ReadOnlyArray<{|
    +label: string,
    +name: string,
  |}>,
  +me: ?{|
    +id: string,
  |},
  +pipelines: $ReadOnlyArray<{|
    +id: string,
    +label: ?string,
    +leadType: PipelineLeadType,
    +name: ?string,
    +stages: $ReadOnlyArray<{|
      +__typename: "LeadStage",
      +id: string,
      +label: string,
    |}>,
  |}>,
  +propertyTypes: ?$ReadOnlyArray<{|
    +id: string,
    +label: ?string,
    +mainType: ?string,
    +name: ?string,
  |}>,
  +tenantSettings: ?{|
    +countryCode: string,
    +defaultPipeline: ?{|
      +id: string,
    |},
  |},
|};
export type leadCreateDrawerQuery = {|
  response: leadCreateDrawerQuery$data,
  variables: leadCreateDrawerQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "me",
  "plural": false,
  "selections": (v1/*: any*/),
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Pipeline",
  "kind": "LinkedField",
  "name": "defaultPipeline",
  "plural": false,
  "selections": (v1/*: any*/),
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "countryCode",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Pipeline",
  "kind": "LinkedField",
  "name": "pipelines",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "leadType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LeadStage",
      "kind": "LinkedField",
      "name": "stages",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        (v0/*: any*/),
        (v5/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = [
  {
    "kind": "Literal",
    "name": "type",
    "value": "lead_source_types"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "PropertyType",
  "kind": "LinkedField",
  "name": "propertyTypes",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    (v6/*: any*/),
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mainType",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "leadCreateDrawerQuery",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantSettings",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      (v7/*: any*/),
      {
        "alias": "leadSourceTypes",
        "args": (v8/*: any*/),
        "concreteType": "Dictionary",
        "kind": "LinkedField",
        "name": "dictionaries",
        "plural": true,
        "selections": [
          (v6/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": "dictionaries(type:\"lead_source_types\")"
      },
      (v9/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "leadCreateDrawerQuery",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantSettings",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      (v7/*: any*/),
      {
        "alias": "leadSourceTypes",
        "args": (v8/*: any*/),
        "concreteType": "Dictionary",
        "kind": "LinkedField",
        "name": "dictionaries",
        "plural": true,
        "selections": [
          (v6/*: any*/),
          (v5/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": "dictionaries(type:\"lead_source_types\")"
      },
      (v9/*: any*/)
    ]
  },
  "params": {
    "cacheID": "99aabc6f7a562e8fc0bc38aa3a1ca634",
    "id": null,
    "metadata": {},
    "name": "leadCreateDrawerQuery",
    "operationKind": "query",
    "text": "query leadCreateDrawerQuery {\n  me {\n    id\n  }\n  tenantSettings {\n    defaultPipeline {\n      id\n    }\n    countryCode\n    id\n  }\n  pipelines {\n    id\n    label\n    name\n    leadType\n    stages {\n      __typename\n      id\n      label\n    }\n  }\n  leadSourceTypes: dictionaries(type: \"lead_source_types\") {\n    name\n    label\n    id\n  }\n  propertyTypes {\n    id\n    name\n    label\n    mainType\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "c30e79e440455e92cb375e367e695ee2";

export default node;
