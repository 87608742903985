import * as React from 'react';

import { IconButton, Paper } from '@material-ui/core';
import { Title } from 'react-head';
import { Box, Flex, useSystem } from 'react-system';

import { LanguageMenu } from '../../../apollo/components/LanguageMenu';
import { useAppData } from '../../../apollo/providers/AppDataProvider';
import { useLocale } from '../../hooks/locale';
import { useTheme } from '../../hooks/theme';
import { Menu } from '../../icons/menu';
import { Search } from '../../icons/search';
import { useTopbar } from '../../shared/topbar';

import { useNavigation } from './context';
import { GlobalSearch } from './GlobalSearch';

type Props = {
  open: boolean;
  onOpenMenu: () => void;
};

const TopPanel = ({
  position,
  children,
}: {
  position?: string[];
  children: React.ReactNode;
}) => {
  const { media } = useSystem();
  const { depth } = useTheme();
  return (
    <Box
      flexShrink={0}
      css={media({
        zIndex: depth.appBar,
        top: 0,
        position: position || ['static', 'sticky'],
      })}
    >
      {children}
    </Box>
  );
};

const LeftAction = ({ onOpenMenu }: { onOpenMenu: () => void }) => {
  const { t } = useLocale();
  const { media } = useSystem();
  return (
    <Box ml={2} css={media({ display: ['block', 'block', 'none'] })}>
      <IconButton
        color="inherit"
        title={t('openSideMenu')}
        onClick={onOpenMenu}
      >
        <Menu />
      </IconButton>
    </Box>
  );
};

export const Navigation = (props: Props) => {
  const { text, colors } = useTheme();
  const state = useNavigation();
  const [search, setSearch] = React.useState(false);
  const { titleRef } = useTopbar();

  React.useEffect(() => {
    const focusSearch = (event: KeyboardEvent) => {
      if (props.open === false) {
        if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
          event.preventDefault();
          setSearch(true);
        }
        if (event.key === 'Escape') {
          event.preventDefault();
          setSearch(false);
        }
      }
    };
    document.addEventListener('keydown', focusSearch);
    return () => document.removeEventListener('keydown', focusSearch);
  }, [props.open]);

  const { me } = useAppData();

  return (
    <TopPanel position={state.position}>
      <Paper
        square={true}
        css={{
          position: 'relative',
          zIndex: 2,
          background: colors.white,
        }}
      >
        <Flex alignItems="center" css={{ minHeight: 64, zIndex: 8 }}>
          <div css={{ flexShrink: 0 }}>
            {search === false && props.open === false && (
              <LeftAction onOpenMenu={props.onOpenMenu} />
            )}
          </div>

          {state.title != null ? (
            <Title>{state.title} | RealAdvisor CRM</Title>
          ) : (
            <Title>RealAdvisor CRM</Title>
          )}

          {!search && state.level0 != null && state.level0}

          {!search && state.title != null && (
            <Box
              ml={3}
              flexGrow={1}
              css={[text.h6, text.truncate(1), { fontWeight: text.font.bold }]}
            >
              {state.title ?? null}
            </Box>
          )}

          {state.title == null && (
            <Box
              ref={titleRef}
              ml={3}
              flexGrow={1}
              css={[
                { display: search === false ? 'block' : 'none' },
                text.h6,
                { fontWeight: text.font.bold },
                text.truncate(1),
              ]}
            />
          )}

          {search && (
            <GlobalSearch
              onClose={() => setSearch(false)}
              menuOpening={props.open}
            />
          )}

          <Flex
            flexShrink={0}
            hidden={search}
            alignItems="center"
            justifyContent="flex-end"
          >
            <IconButton
              color="inherit"
              title="search"
              onClick={() => {
                setSearch(true);
              }}
            >
              <Search />
            </IconButton>
            <Flex alignItems="center" pr={2}>
              {me && <LanguageMenu />}
            </Flex>
          </Flex>
        </Flex>
        {state.level1}
      </Paper>
    </TopPanel>
  );
};
