/**
 * @generated SignedSource<<03d6fe3080b3472e64146961e5c43da6>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type DuplicateLotOption = "documents" | "lot" | "photos";
export type DuplicateLotInput = {|
  clientMutationId?: ?string,
  id: string,
  options?: ?$ReadOnlyArray<?DuplicateLotOption>,
|};
export type lotDuplicationDialogMutation$variables = {|
  input: DuplicateLotInput,
|};
export type lotDuplicationDialogMutation$data = {|
  +duplicateLot: ?{|
    +lot: ?{|
      +id: string,
    |},
  |},
|};
export type lotDuplicationDialogMutation = {|
  response: lotDuplicationDialogMutation$data,
  variables: lotDuplicationDialogMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DuplicateLotPayload",
    "kind": "LinkedField",
    "name": "duplicateLot",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lot",
        "kind": "LinkedField",
        "name": "lot",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "lotDuplicationDialogMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "lotDuplicationDialogMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "bb98648d4bcd04d75fef6bfd7a54071c",
    "id": null,
    "metadata": {},
    "name": "lotDuplicationDialogMutation",
    "operationKind": "mutation",
    "text": "mutation lotDuplicationDialogMutation(\n  $input: DuplicateLotInput!\n) {\n  duplicateLot(input: $input) {\n    lot {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "ddde095ee68efcee9c9928faf718ff0f";

export default node;
