import { FormControl, FormHelperText, FormLabel } from '@mui/material';
import type { UseFormGetValues, UseFormSetValue } from 'react-hook-form';

import { OrganisationSelect } from '../organisation-select/OrganisationSelect';

export type RaOrganisationProps = {
  errors: any;
  getValues: UseFormGetValues<any>;
  setValue: UseFormSetValue<any>;
  name: string;
  label: string;
};

export const RaOrganisation = ({
  getValues,
  setValue,
  errors,
  name,
  label,
}: RaOrganisationProps) => {
  const organisationId = getValues(name);
  return (
    <FormControl fullWidth error={!!errors[name]}>
      <FormLabel style={{ marginBottom: '0.5rem', fontWeight: 500 }}>
        {label}
      </FormLabel>
      <OrganisationSelect
        organisationId={organisationId}
        onChange={organisationId =>
          setValue(name, organisationId, { shouldDirty: true })
        }
      />
      <FormHelperText>{errors[name]?.message}</FormHelperText>
    </FormControl>
  );
};
