import * as config from '../config';

const re = /([^:])\/{2,}/gi;

const FR_TENANT_ID =
  'VGVuYW50OjRiNmM5Y2E4LTVhMjYtMTFlYS05Y2U3LWI3YWE4MjViZmQxNw==';
const ES_TENANT_ID =
  'VGVuYW50OjRiNmM5ZWM0LTVhMjYtMTFlYS05Y2U3LThiYTZmMTZkYTY1Zg==';

export const joinURL = (...args: Array<string>): string =>
  args
    .join('/')
    .replace(re, '$1/')
    .replace(/^\/{2,}/gi, '/');

export const webURL = (page: string, query: Record<string, string>): string => {
  const querystring = new URLSearchParams(query).toString();
  // if we pass tenantId param and it's RealAdvisor French tenant id, then use French origins from config
  const origin =
    query.tenantId === FR_TENANT_ID
      ? config.webOriginFr
      : query.tenantId === ES_TENANT_ID
      ? config.webOriginEs
      : config.webOrigin;

  return joinURL(
    origin,
    'redirect',
    `?page=${page}${querystring !== '' ? '&' + querystring : ''}`,
  );
};
