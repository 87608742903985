/**
 * @generated SignedSource<<be67d3fe09098148c65f035572ed9dff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type FileBucket = "files" | "images";
export type GenerateSignedUrlInput = {
  bucket: FileBucket;
  clientMutationId?: string | null;
  name: string;
  type?: string | null;
};
export type fileUploadGenerateSignedUrlMutation$variables = {
  input: GenerateSignedUrlInput;
};
export type fileUploadGenerateSignedUrlMutation$data = {
  readonly generateSignedUrl: {
    readonly url: string;
  } | null;
};
export type fileUploadGenerateSignedUrlMutation = {
  response: fileUploadGenerateSignedUrlMutation$data;
  variables: fileUploadGenerateSignedUrlMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "GenerateSignedUrlPayload",
    "kind": "LinkedField",
    "name": "generateSignedUrl",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "fileUploadGenerateSignedUrlMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "fileUploadGenerateSignedUrlMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "27887df1482d3f45b03dbf0e9e7e24a8",
    "id": null,
    "metadata": {},
    "name": "fileUploadGenerateSignedUrlMutation",
    "operationKind": "mutation",
    "text": "mutation fileUploadGenerateSignedUrlMutation(\n  $input: GenerateSignedUrlInput!\n) {\n  generateSignedUrl(input: $input) {\n    url\n  }\n}\n"
  }
};
})();

(node as any).hash = "191cd16b6ec57d2097018923d5be1936";

export default node;
