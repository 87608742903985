// @flow

import * as React from 'react';

import { Avatar, ListItem } from '@material-ui/core';
import { formatDistance, parseISO } from 'date-fns';
import { graphql, useFragment } from 'react-relay';
import { Box } from 'react-system';

import { useLocale } from '../../hooks/locale';
import { useTheme } from '../../hooks/theme';
import { Search } from '../../icons/search';
import { getCurrencyByCountryCode } from '../../locale';
import { AppraisalDrawer } from '../../shared/appraisal-drawer';

import type { AppraisalListItem_appraisal$key } from './__generated__/AppraisalListItem_appraisal.graphql';
import type { AppraisalListItem_root$key } from './__generated__/AppraisalListItem_root.graphql';

type Props = {|
  root: AppraisalListItem_root$key,
  appraisal: AppraisalListItem_appraisal$key,
  onDelete: () => void,
|};

function secondaryText(t, locale, appraisal) {
  const surfaceLiving = (
    appraisal.property?.livingSurface ??
    appraisal.property?.builtSurface ??
    0
  ).toLocaleString(locale);
  const roomNb = appraisal.property?.numberOfRooms ?? 0;
  const bedroomNb = appraisal.property?.numberOfBedrooms ?? 0;
  const bathroomNb = appraisal.property?.numberOfBathrooms ?? 0;
  return [
    `${surfaceLiving} m²`,
    roomNb !== 0
      ? t('numberOfRoomsWithCount', { count: roomNb })
      : t('numberOfBedroomsWithCount', { count: bedroomNb }),
    t('numberOfBathroomsWithCount', { count: bathroomNb }),
  ].join(' | ');
}

export const AppraisalListItem = (props: Props): React.Node => {
  const [drawer, setDrawer] = React.useState(false);
  const { t, dateLocale, locale } = useLocale();
  const { text, textColor } = useTheme();
  const root = useFragment(
    graphql`
      fragment AppraisalListItem_root on Query {
        ...appraisalDrawer_root
      }
    `,
    props.root,
  );
  const appraisal = useFragment(
    graphql`
      fragment AppraisalListItem_appraisal on Appraisal {
        ...appraisalDrawer_appraisal
        createdAt
        property {
          livingSurface
          builtSurface
          numberOfRooms
          numberOfBedrooms
          numberOfBathrooms
        }
        realadvisor {
          min
          max
        }
        property {
          countryCode
        }
      }
    `,
    props.appraisal,
  );

  const createdAt = appraisal.createdAt;
  const currency = getCurrencyByCountryCode(
    appraisal.property?.countryCode ?? 'CH',
  );
  const min = (appraisal.realadvisor?.min ?? 0).toLocaleString(locale);
  const max = (appraisal.realadvisor?.max ?? 0).toLocaleString(locale);

  return (
    <>
      <ListItem button={true} onClick={() => setDrawer(true)}>
        <Avatar>{<Search />}</Avatar>
        <Box pl={3}>
          <Box
            css={[text.body2, text.ellipsis]}
          >{`${currency} ${min} - ${max}`}</Box>
          <Box css={[text.body2, textColor('mediumText')]}>
            {secondaryText(t, locale, appraisal)}
          </Box>
        </Box>
        {createdAt != null && (
          <Box
            css={[
              text.caption,
              textColor('errorText'),
              {
                position: 'absolute',
                right: 16,
                top: 8,
              },
            ]}
          >
            {formatDistance(parseISO(createdAt), Date.now(), {
              locale: dateLocale,
            })}
          </Box>
        )}
      </ListItem>
      <AppraisalDrawer
        open={drawer}
        root={root}
        appraisal={appraisal}
        onDelete={props.onDelete}
        onClose={() => setDrawer(false)}
      />
    </>
  );
};
