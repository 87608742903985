/**
 * @generated SignedSource<<7cc863f357b2c12c743078bc9b6f44c3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PropertyTypeFilterQuery$variables = {};
export type PropertyTypeFilterQuery$data = {
  readonly propertyTypes: ReadonlyArray<{
    readonly label: string | null;
    readonly name: string | null;
  }> | null;
};
export type PropertyTypeFilterQuery = {
  response: PropertyTypeFilterQuery$data;
  variables: PropertyTypeFilterQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PropertyTypeFilterQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PropertyType",
        "kind": "LinkedField",
        "name": "propertyTypes",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PropertyTypeFilterQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PropertyType",
        "kind": "LinkedField",
        "name": "propertyTypes",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bf54104972576b5ef8323c694b5a50fc",
    "id": null,
    "metadata": {},
    "name": "PropertyTypeFilterQuery",
    "operationKind": "query",
    "text": "query PropertyTypeFilterQuery {\n  propertyTypes {\n    name\n    label\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "5f5a785695ec2d415cdd599b05ede2eb";

export default node;
