import { Box, Card, CardActionArea, Checkbox } from '@mui/material';

export const SelectableCard = ({
  selected,
  onChange,
  children,
}: {
  selected: boolean;
  onChange: () => void;
  children: React.ReactNode;
}) => (
  <Card
    sx={{
      position: 'relative',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <Box
      sx={{
        position: 'absolute',
        bottom: '8px',
        right: '8px',
        zIndex: 1,
      }}
    >
      <Box
        onClick={e => {
          e.stopPropagation();
          onChange();
        }}
      >
        <Checkbox
          checked={selected}
          onChange={onChange}
          color="primary"
          size="small"
          sx={{
            '& .MuiSvgIcon-root': {
              fontSize: 24,
            },
          }}
        />
      </Box>
    </Box>
    <CardActionArea
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      {children}
    </CardActionArea>
  </Card>
);
