import { gql } from '../../__generated__';

export const GET_TENANTS = gql(`
  query GetTenants {
    tenants(order_by: { name: asc }) {
      id
      name
      slug
    }
  }
`);
