import { FormControl, FormHelperText, FormLabel } from '@mui/material';
import type { UseFormGetValues, UseFormSetValue } from 'react-hook-form';

import type {
  Leads_Bool_Exp,
  Leads_Order_By,
} from '../../__generated__/graphql';
import { LeadSelect } from '../LeadsSelect';

export type RaLeadProps = {
  errors: any;
  getValues: UseFormGetValues<any>;
  setValue: UseFormSetValue<any>;
  name: string;
  label: string;
  placeholder?: string;
  autoFocus?: boolean;
  customWhereClause?: (searchValue?: string) => Leads_Bool_Exp;
  orderBy?: Leads_Order_By;
  instantQuery?: boolean;
};

export function RaLead({
  getValues,
  setValue,
  errors,
  name,
  label,
  placeholder,
  autoFocus,
  customWhereClause,
  orderBy,
  instantQuery,
}: RaLeadProps) {
  const lead = getValues(name);

  return (
    <FormControl fullWidth error={!!errors[name]}>
      <FormLabel style={{ marginBottom: '0.5rem', fontWeight: 500 }}>
        {label}
      </FormLabel>
      <LeadSelect
        leadId={lead?.id}
        placeholder={placeholder}
        autoFocus={autoFocus}
        onChange={lead => setValue(name, lead, { shouldDirty: true })}
        customWhereClause={customWhereClause}
        orderBy={orderBy}
        instantQuery={instantQuery}
      />
      <FormHelperText>{errors[name]?.message}</FormHelperText>
    </FormControl>
  );
}
