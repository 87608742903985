// @flow

import type { GoogleMapsApi } from 'rgm';
import { useScript } from '@realadvisor/hooks';
import { useGoogleKey } from './google-key';

export const useGoogleApi = (loadScript?: boolean): ?GoogleMapsApi => {
  const key = useGoogleKey();
  const url = `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places`;
  const state = useScript(url, loadScript);
  if (state === 'done') {
    return typeof window === 'object' && window.google
      ? window.google.maps
      : null;
  } else {
    return null;
  }
};
