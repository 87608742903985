import { gql } from '@apollo/client';

import { USER_INFO_USER_FRAGMENT } from '../../components/data-grid/UserInfo';

export const INVOICE_FRAGMENT = gql`
  fragment InvoiceFragment on invoices {
    id
    stripe_id
    created
    status
    period_start
    period_end
    amount_due
    amount_paid
    lines
  }
`;

export const GET_SUBSCRIPTIONS_LIST = gql`
  ${USER_INFO_USER_FRAGMENT}
  query getSubscriptionsList(
    $where: subscriptions_bool_exp!
    $order_by: [subscriptions_order_by!]
    $limit: Int!
    $offset: Int!
  ) {
    subscriptions(
      where: $where
      order_by: $order_by
      limit: $limit
      offset: $offset
    ) {
      id
      stripe_id
      created_at
      updated_at
      start_date
      current_period_start
      current_period_end
      customer_email
      customer
      status
      total_amount
      user {
        id
        ...UserInfo_user
        broker_leads_aggregate {
          aggregate {
            count
          }
        }
      }
      invoices_aggregate {
        aggregate {
          sum {
            amount_paid
          }
        }
      }
    }
  }
`;

export const GET_SUBSCRIPTIONS_LIST_COUNT = gql`
  query getSubscriptionsListCount($where: subscriptions_bool_exp!) {
    subscriptions_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_SUBSCRIPTION = gql`
  ${INVOICE_FRAGMENT}
  query getSubscription($id: uuid!) {
    subscriptions_by_pk(id: $id) {
      id
      stripe_id
      created_at
      updated_at
      start_date
      current_period_start
      current_period_end
      customer_email
      customer
      status
      total_amount
      user_id
      user {
        id
        full_name
        broker_leads_aggregate {
          aggregate {
            count
          }
        }
      }
      invoices(order_by: { created: desc }) {
        id
        ...InvoiceFragment
      }
      invoices_aggregate {
        aggregate {
          sum {
            amount_paid
          }
        }
      }
    }
  }
`;

export const UPDATE_SUBSCRIPTION = gql`
  ${USER_INFO_USER_FRAGMENT}
  mutation updateSubscription($id: uuid!, $user_id: uuid!) {
    update_subscriptions_by_pk(
      pk_columns: { id: $id }
      _set: { user_id: $user_id }
    ) {
      id
      user_id
      # return user info to update cache and re-render list
      user {
        id
        ...UserInfo_user
        broker_leads_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
`;
