// @flow

import type { Translate } from '@realadvisor/translator/engine';

export const translatePropertyOccupied = (
  t: Translate,
  value: string,
): string => {
  switch (value) {
    case 'owner_occupied':
      return t('ownerOccupied');
    case 'tenant_occupied':
      return t('tenantOccupied');
    case 'vacant':
      return t('vacant');
    case 'i_dont_know':
      return t('iDontKnow');
    default:
      return value;
  }
};

export const translatePropertyUsage = (t: Translate, value: string): string => {
  switch (value) {
    case 'primary_residence':
      return t('primaryResidence');
    case 'secondary_home':
      return t('secondaryHome');
    case 'not_set':
      return t('notSet');
    default:
      return value;
  }
};

export const translateRelationship = (t: Translate, value: string): string => {
  switch (value) {
    case 'owner':
      return t('owner');
    case 'tenant':
      return t('tenant');
    case 'agent':
      return t('agent');
    case 'buyer':
      return t('buyer');
    case 'other':
      return t('other');
    case 'not_set':
      return t('notSet');
    default:
      return value;
  }
};

export const translateOpenToOffer = (t: Translate, value: string): string => {
  if (value === 'depends_on_price') {
    return t('maybeOpenToOffer');
  } else if (value === 'no') {
    return t('notOpenToOffers');
  } else if (value === 'not_set') {
    return t('notSet');
  } else {
    return value;
  }
};

export const translateSellingWith = (t: Translate, value: string): string => {
  switch (value) {
    case 'by_myself': {
      return t('byMyself');
    }
    case 'agent_exclusive': {
      return t('agentExclusive');
    }
    case 'agent_nonexclusive': {
      return t('agent');
    }
    default: {
      return value;
    }
  }
};

export const translateAppraisalPerception = (
  t: Translate,
  value: string,
): string => {
  return (
    {
      too_low: t('tooLow'),
      too_high: t('tooHigh'),
      just_right: t('justRight'),
      undefined: t('undefined'),
    }[value] ?? value
  );
};

export const translateAppraisalReason = (
  t: Translate,
  value: string,
): string => {
  return (
    {
      buy_soon: t('buySoon'),
      inheritance: t('inheritance'),
      not_selling: t('notSelling'),
      buy_this: t('buyThis'),
      buy: t('buy'),
      already_for_sale: t('alreadyForSale'),
      separation: t('separation'),
      move_rental: t('moveRental'),
      sell: t('sell'),
      refinance: t('refinance'),
      other_renting: t('otherRenting'),
      other: t('other'),
      other_not_owner: t('otherNotOwner'),
      other_owner: t('otherOwner'),
      not_set: t('notSet'),
    }[value] ?? value
  );
};

export const translateBuyHorizon = (t: Translate, value: string): string => {
  return (
    {
      under_3_months: t('under3Months'),
      under_12_months: t('under12Months'),
      over_12_months: t('over12Months'),
      not_set: t('notSet'),
    }[value] ?? value
  );
};

export const translateSaleHorizon = (t: Translate, value: string): string => {
  return (
    {
      under_6_months: t('under6Months'),
      from_6_to_12_months: t('from6To12Months'),
      from_1_to_2_years: t('from1To2Years'),
      from_2_years: t('from2YearsOrMore'),
      already_for_sale: t('alreadyForSale'),
      not_selling: t('notSelling'),
      not_set: t('notSet'),
    }[value] ?? value
  );
};

export const translateStatus = (t: Translate, value: string): string => {
  return (
    {
      active: t('Active'),
      lost: t('lost'),
      won: t('won'),
    }[value] ?? value
  );
};

export const translateMortgageBuyingStatus = (
  t: Translate,
  value: ?string,
): string | null => {
  if (value != null) {
    return {
      searching: t('searching'),
      visited: t('visited'),
      offer: t('madeOffer'),
    }[value];
  }
  return null;
};

export const translateMortgageStatus = (
  t: Translate,
  value: ?string,
): string | null => {
  if (value != null) {
    return {
      not_started: t('noApplication'),
      working_with_bank: t('workingWithBank'),
      working_with_independent: t('workingWithBroker'),
    }[value];
  }
  return null;
};

export const translateReceivedMortgageOffer = (
  t: Translate,
  value: ?boolean,
): string | null => {
  if (value != null) {
    return value ? t('hasFinancingOffer') : t('noFinancingOffer');
  }
  return null;
};

export const getHorizonLabel = (
  t: Translate,
  lead: {|
    appraisalReason: string,
    saleHorizon: string,
    buyHorizon: string,
  |},
): ?string => {
  const { appraisalReason, saleHorizon, buyHorizon } = lead;
  const horizon = saleHorizon === 'not_set' ? buyHorizon : saleHorizon;

  if (
    ['not_selling', 'already_for_sale'].includes(appraisalReason) ||
    horizon === 'not_set'
  ) {
    return null;
  }

  const horizonIsTime = ![
    'not_set',
    'not_selling',
    'already_for_sale',
  ].includes(horizon);

  const horizonTypeIsAmbiguous = !['buy_soon', 'buy_this', 'sell'].includes(
    appraisalReason,
  );

  return horizonIsTime
    ? horizonTypeIsAmbiguous
      ? saleHorizon !== 'not_set'
        ? t('sellInTime', { time: translateSaleHorizon(t, horizon) })
        : t('buyInTime', { time: translateBuyHorizon(t, horizon) })
      : t('inTime', { time: translateSaleHorizon(t, horizon) })
    : translateSaleHorizon(t, horizon);
};
