import * as React from 'react';

type State = 'loading' | 'done' | 'failed';

const cachedStates = new Map<string, State>();
const cachedElements = new Map<string, HTMLScriptElement>();

export const useScript = (src: string, loadScript: boolean = true): State => {
  const [state, setState] = React.useState(() => {
    const cached = cachedStates.get(src);
    if (cached != null) {
      return cached;
    }

    return 'loading';
  });

  React.useEffect(() => {
    if (state !== 'loading') {
      return;
    }

    const handleLoad = () => {
      cachedStates.set(src, 'done');
      setState('done');
    };

    const handleError = () => {
      cachedStates.set(src, 'failed');
      setState('failed');
    };

    const cachedState: State | void = cachedStates.get(src);

    if (cachedState != null && cachedState !== state) {
      setState(cachedState);
      return;
    }

    let scriptElement = cachedElements.get(src);

    if (scriptElement == null) {
      scriptElement = document.createElement('script');
      scriptElement.async = true;
      scriptElement.defer = true;
      scriptElement.setAttribute('importance', 'low');
      cachedStates.set(src, 'loading');
      cachedElements.set(src, scriptElement);
    }

    if (loadScript && !scriptElement.hasAttribute('src')) {
      scriptElement.src = src;
      // inject to start loading
      if (document.body) {
        document.body.appendChild(scriptElement);
      }
    }

    scriptElement.addEventListener('load', handleLoad);
    scriptElement.addEventListener('error', handleError);
    return () => {
      if (scriptElement == null) {
        return;
      }
      scriptElement.removeEventListener('load', handleLoad);
      scriptElement.removeEventListener('error', handleError);
    };
  }, [src, loadScript, state]);

  return state;
};
