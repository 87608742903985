import * as React from 'react';

import {
  Accordion,
  CircularProgress,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  type PopoverOrigin,
  type PopoverProps,
} from '@mui/material';
import { graphql, useFragment, useMutation } from 'react-relay';
import { Flex } from 'react-system';

import { AccordionCardSummary } from '../../apollo/components/AccordionCardSummary';
import { useAppData } from '../../apollo/providers/AppDataProvider';
import { fromGlobalId } from '../../shared/global-id';
import { useLocale } from '../hooks/locale';
import { AccountBox } from '../icons/account-box';
import { ArrowDropDown } from '../icons/arrow-drop-down';

import type { leadContactCard_lead$key } from './__generated__/leadContactCard_lead.graphql';
import type { leadContactCard_RelationshipItem$key } from './__generated__/leadContactCard_RelationshipItem.graphql';
import type { leadContactCardRelationshipMutation } from './__generated__/leadContactCardRelationshipMutation.graphql';
import type {
  LeadRelationship,
  leadContactCardUpsertMutation,
} from './__generated__/leadContactCardUpsertMutation.graphql';
import { UserEditableCard } from './user-editable-card';

const useFullWidthCenteredMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<
    PopoverProps['anchorEl'] | null
  >(null);
  const [anchorWidth, setAnchorWidth] = React.useState<number | null>(null);

  const handleClick = (event: React.SyntheticEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget as PopoverProps['anchorEl']);
    setAnchorWidth(event.currentTarget.clientWidth);
  };
  const close = () => setAnchorEl(null);
  const opened = anchorEl ? true : false;

  const bindMenu = {
    anchorEl,
    PaperProps: {
      style: {
        width: `${anchorWidth ? `${anchorWidth}px` : 'auto'}`,
      },
    },
    open: opened,
    onClose: close,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    } as PopoverOrigin,
  };
  const bindTrigger = {
    onClick: handleClick,
  };

  return { bindMenu, bindTrigger, close, opened };
};

type RelationshipItemProps = {
  disabled?: boolean;
  lead: leadContactCard_RelationshipItem$key;
};

const RelationshipItem = (props: RelationshipItemProps) => {
  const { bindMenu, bindTrigger, close } = useFullWidthCenteredMenu();
  const { t } = useLocale();
  const isEditable = props.disabled !== true;
  const lead = useFragment(
    graphql`
      fragment leadContactCard_RelationshipItem on Lead {
        id
        relationship
      }
    `,
    props.lead,
  );

  const [updateLeadRelationship] =
    useMutation<leadContactCardRelationshipMutation>(
      graphql`
        mutation leadContactCardRelationshipMutation($input: UpsertLeadInput!) {
          upsertLead(input: $input) {
            lead {
              id
              relationship
              property {
                # update property owners state when relationship is changed
                owners {
                  ...userEditableCard_user
                }
              }
            }
          }
        }
      `,
    );

  const handleSelectRelationship = (name: LeadRelationship) => () => {
    if (name !== lead.relationship) {
      updateLeadRelationship({
        variables: {
          input: {
            lead: {
              id: lead.id,
              relationship: name,
            },
          },
        },
      });
    }
    close();
  };

  const getRelationshipLabel = (value: string) => {
    switch (value) {
      case 'owner':
        return t('owner');
      case 'tenant':
        return t('tenant');
      case 'buyer':
        return t('buyer');
      case 'heir':
        return t('heir');
      case 'agent':
        return t('agent');
      case 'other':
        return t('other');
      default:
        return value;
    }
  };

  const relationshipTypes = [
    'owner',
    'tenant',
    'buyer',
    'heir',
    'agent',
    'other',
  ] as const;

  return (
    <ListItem
      component="a"
      // @ts-ignore
      button={isEditable}
      {...bindTrigger}
    >
      <ListItemIcon>
        <AccountBox />
      </ListItemIcon>

      <ListItemText primary={getRelationshipLabel(lead.relationship)} />

      {isEditable && (
        <ListItemSecondaryAction style={{ pointerEvents: 'none' }}>
          <ArrowDropDown />
          <Menu {...bindMenu} variant="menu">
            {relationshipTypes.map(relationship => (
              <MenuItem
                key={relationship}
                selected={lead.relationship === relationship}
                onClick={handleSelectRelationship(relationship)}
              >
                {getRelationshipLabel(relationship)}
              </MenuItem>
            ))}
          </Menu>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

type Props = {
  lead: leadContactCard_lead$key;
  defaultExpanded: boolean;
};

export const LeadContactCard = (props: Props) => {
  const lead = useFragment(
    graphql`
      fragment leadContactCard_lead on Lead {
        ...leadContactCard_RelationshipItem
        id
        contact {
          ...userEditableCard_user
          displayName
        }
        createdBy {
          id
        }
      }
    `,
    props.lead,
  );
  const [updateLead, updating] = useMutation<leadContactCardUpsertMutation>(
    graphql`
      mutation leadContactCardUpsertMutation($input: UpsertLeadInput!) {
        upsertLead(input: $input) {
          lead {
            ...leadContactCard_lead
            property {
              # update property owners state when contact is changed
              owners {
                ...userEditableCard_user
              }
            }
          }
        }
      }
    `,
  );
  const { t } = useLocale();
  const defaultExpanded = React.useRef(props.defaultExpanded);
  const [expanded, setExpanded] = React.useState(props.defaultExpanded);
  const [editing, setEditing] = React.useState(false);
  const { me } = useAppData();

  const isEditable =
    me?.is_admin ||
    // createBy may not be visible
    (lead.createdBy?.id != null && fromGlobalId(lead.createdBy?.id) === me?.id);

  const toggleCardAccordion = (
    _event: React.ChangeEvent<{}>,
    expanded: boolean,
  ) => {
    setExpanded(expanded);
  };

  React.useEffect(() => {
    if (defaultExpanded.current !== props.defaultExpanded) {
      defaultExpanded.current = props.defaultExpanded;
      setExpanded(props.defaultExpanded);
    }
  }, [props.defaultExpanded]);

  return (
    <Accordion expanded={expanded} onChange={toggleCardAccordion}>
      <AccordionCardSummary
        expanded={expanded}
        title={t('contact')}
        subTitle={lead.contact?.displayName}
      />
      {updating ? (
        <Flex flexGrow={1} justifyContent="center" alignItems="center" p={3}>
          <CircularProgress disableShrink />
        </Flex>
      ) : (
        <UserEditableCard
          isCreatable={true}
          label={t('selectContact')}
          user={lead.contact}
          prefill={lead.contact}
          autoFocus={editing}
          disabled={!isEditable}
          menuButtonTitle={t('clickToOpenContactMenu')}
          summaryChildren={
            <RelationshipItem lead={lead} disabled={!isEditable} />
          }
          onChange={(user, isEditingUser) => {
            updateLead({
              variables: {
                input: {
                  lead: {
                    id: lead.id,
                    contactId: user ? user.id : null,
                  },
                },
              },
            });
            setEditing(isEditingUser === true);
          }}
        />
      )}
    </Accordion>
  );
};
