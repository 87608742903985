// @flow

import {
  type GeocoderResult,
  parseGeocoderComponents,
} from '@realadvisor/hooks';

export type ParsedGeocoderResult = {|
  lat: number,
  lng: number,
  route: ?string,
  streetNumber: ?string,
  postcode: ?string,
  countryCode: ?string,
  state: ?string,
  locality: ?string,
|};

export const parseGeocoderResult = ({
  address_components,
  geometry,
}: GeocoderResult): ParsedGeocoderResult => {
  const components = parseGeocoderComponents(address_components);
  const { location } = geometry;

  return {
    lat: location.lat(),
    lng: location.lng(),
    route: components.route,
    streetNumber: components.streetNumber,
    postcode: components.postcode,
    countryCode: components.countryCode,
    state: components.state,
    locality: components.locality,
  };
};
