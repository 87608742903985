/**
 * @generated SignedSource<<9df6e9e48ee83850ee19e48c670a9394>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type leadLostDialogQuery$variables = {||};
export type leadLostDialogQuery$data = {|
  +leadLostTypes: $ReadOnlyArray<{|
    +id: string,
    +label: string,
  |}>,
|};
export type leadLostDialogQuery = {|
  response: leadLostDialogQuery$data,
  variables: leadLostDialogQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": "leadLostTypes",
    "args": [
      {
        "kind": "Literal",
        "name": "type",
        "value": "lead_lost_types"
      }
    ],
    "concreteType": "Dictionary",
    "kind": "LinkedField",
    "name": "dictionaries",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "label",
        "storageKey": null
      }
    ],
    "storageKey": "dictionaries(type:\"lead_lost_types\")"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "leadLostDialogQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "leadLostDialogQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "b3e6342801fa33ad7fdbee2115c488df",
    "id": null,
    "metadata": {},
    "name": "leadLostDialogQuery",
    "operationKind": "query",
    "text": "query leadLostDialogQuery {\n  leadLostTypes: dictionaries(type: \"lead_lost_types\") {\n    id\n    label\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "c88b8b411a07ef13f7944ab522e7b138";

export default node;
