import { Box } from '@mui/material';

import { useLocale } from '../../../src/hooks/locale';
import type { Currency } from '../../../src/locale';
import {
  translatePropertyOccupied,
  translatePropertyUsage,
} from '../../../src/utils/lead-labels';
import { gql } from '../../__generated__';
import type { LeadPropertyDetailFragment } from '../../__generated__/graphql';
import { formatIncome } from '../../utils/formatting';
import {
  isLandTypeProperty,
  isMultiFamilyOrCommercialProperty,
} from '../../utils/propertyTypes';

export const LEAD_PROPERTY_DETAIL_FRAGMENT = gql(`
  fragment LeadPropertyDetail on properties {
    id
    living_surface
    built_surface
    land_surface
    number_of_rooms
    number_of_bedrooms
    number_of_residential_units
    residential_surface
    residential_yearly_rental_income
    parking_yearly_rental_income
    number_of_commercial_units
    commercial_surface
    commercial_yearly_rental_income
    income_includes_parking
    plot_number
    property_type {
      id
      name
      label
      main_type
    }
  }
`);

type LeadPropertyDetailProps = {
  property: LeadPropertyDetailFragment | null | undefined;
  currency: Currency;
  appraisalPropertyOccupied?: string | null;
  appraisalPropertyUsage?: string | null;
};

const calculatetotalRentalIncome = (
  property: LeadPropertyDetailFragment | null | undefined,
) => {
  if (!property) {
    return 0;
  }

  let totalRentalIncome = 0;

  switch (property.property_type?.name) {
    case 'house_multiple_dwelling':
      totalRentalIncome += property.residential_yearly_rental_income ?? 0;
      break;
    case 'indus_commercial':
      totalRentalIncome += property.commercial_yearly_rental_income ?? 0;
      break;
    case 'indus_commercial_and_residential':
      totalRentalIncome +=
        (property.commercial_yearly_rental_income ?? 0) +
        (property.residential_yearly_rental_income ?? 0);
      break;
  }

  if (property.income_includes_parking === false) {
    totalRentalIncome += property.parking_yearly_rental_income ?? 0;
  }

  return totalRentalIncome;
};

export const LeadPropertyDetail: React.FC<LeadPropertyDetailProps> = ({
  property,
  currency,
  appraisalPropertyOccupied: appraisal_property_occupied,
  appraisalPropertyUsage: appraisal_property_usage,
}) => {
  const { t, locale } = useLocale();
  const totalRentalIncome = calculatetotalRentalIncome(property);
  const properties: (string | null | undefined)[] = [];
  // Property type
  properties.push(property?.property_type?.label);

  if (
    property?.property_type?.name === 'house_multiple_dwelling' ||
    property?.property_type?.name === 'indus_commercial_and_residential'
  ) {
    // Number of residential units
    properties.push(
      t('numberOfResidentialUnitsCount', {
        count: property.number_of_residential_units ?? 0,
      }),
    );
    // Residential surface
    properties.push(
      t('surfaceOf', {
        surface: property.residential_surface ?? 0,
      }),
    );
  }

  if (
    property?.property_type?.name === 'indus_commercial' ||
    property?.property_type?.name === 'indus_commercial_and_residential'
  ) {
    // Number of commercial tenants
    properties.push(
      t('numberOfCommercialTenantsCount', {
        count: property.number_of_commercial_units ?? 0,
      }),
    );
    // Commercial surface
    properties.push(
      t('surfaceOf', {
        surface: property.commercial_surface ?? 0,
      }),
    );
  }

  if (
    !isMultiFamilyOrCommercialProperty(property) &&
    !isLandTypeProperty(property?.property_type)
  ) {
    // numberOfRooms
    if (property?.number_of_rooms != null) {
      properties.push(t('roomsShort', { count: property.number_of_rooms }));
    } else {
      properties.push(
        t('numberOfBedroomsWithCount', {
          count: property?.number_of_bedrooms ?? 0,
        }),
      );
    }
  }

  if (isMultiFamilyOrCommercialProperty(property)) {
    // Total rental income (realised)
    properties.push(
      t('totalRentalIncome', {
        income: formatIncome(totalRentalIncome, currency, locale),
      }),
    );
  } else if (!isLandTypeProperty(property?.property_type)) {
    properties.push(
      property?.living_surface != null
        ? `${property.living_surface.toLocaleString(locale, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          })} m²`
        : property?.built_surface != null
        ? `${property.built_surface.toLocaleString(locale, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          })} m²`
        : null,
    );
  }

  // Plot size
  if (isLandTypeProperty(property?.property_type)) {
    properties.push(
      property?.land_surface != null
        ? t('surfaceOf', {
            surface: property.land_surface.toLocaleString(locale, {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            }),
          })
        : null,
    );

    properties.push(
      property?.plot_number != null
        ? `${t('plot')} ${property?.plot_number ?? ''}`
        : null,
    );
  } else {
    properties.push(
      property?.land_surface != null
        ? t('plotSurfaceOf', {
            plotSurface: property.land_surface.toLocaleString(locale, {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            }),
          })
        : null,
    );
  }

  if (!isMultiFamilyOrCommercialProperty(property)) {
    if (appraisal_property_occupied) {
      properties.push(
        translatePropertyOccupied(t, appraisal_property_occupied),
      );
    }

    if (appraisal_property_usage) {
      properties.push(translatePropertyUsage(t, appraisal_property_usage));
    }
  }

  return (
    <Box sx={{ typography: 'caption', color: 'text.secondary' }} pb={1}>
      {properties.filter(Boolean).join(' · ')}
    </Box>
  );
};
