// Generated from src/svgs/navigation-outline.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const NavigationOutline = ({ size, fill, className }: Props) => {
  return (
    <svg viewBox="0 0 25 25" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><path fillRule="evenodd" clipRule="evenodd" d="M20.899 5.001H22.5a1.5 1.5 0 0 1 1.5 1.5v16a1.5 1.5 0 0 1-1.5 1.5h-20a1.5 1.5 0 0 1-1.5-1.5v-16a1.5 1.5 0 0 1 1.5-1.5h8.6a5 5 0 0 1 9.799 0Zm-18.399 18H7v-5H2v4.5a.5.5 0 0 0 .5.5Zm4.5-6H2v-10.5a.5.5 0 0 1 .5-.5H7v11Zm13-11a4.005 4.005 0 0 0-4-4 4.005 4.005 0 0 0-4 4c0 1.989 2.796 6.32 4 7.709 1.204-1.39 4-5.72 4-7.709Zm3 16.5a.5.5 0 0 1-.5.5H8v-5h15v4.5Zm-15-5.5h15v-10.5a.5.5 0 0 0-.5-.5H21c0 2.762-4.333 9-5 9-.667 0-5-6.238-5-9H8v11Zm10-11a2 2 0 1 0-4 0 2 2 0 0 0 4 0Zm-2-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z" /></svg>
  );
}
