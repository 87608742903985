import * as React from 'react';
import {
  type ReactNode,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

type CMATemplateViewerDialog = {
  setTemplateOpen: (value: boolean | ((value: boolean) => boolean)) => void;
  templateOpen: boolean;
};

export const CMATemplateViewerContext = createContext<CMATemplateViewerDialog>({
  templateOpen: false,
  setTemplateOpen: () => {},
});

export const CMATemplateViewerProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [templateOpen, setTemplateOpen] = useState<boolean>(false);
  const value = useMemo(
    () => ({
      templateOpen,
      setTemplateOpen,
    }),
    [templateOpen],
  );

  return (
    <CMATemplateViewerContext.Provider value={value}>
      {children}
    </CMATemplateViewerContext.Provider>
  );
};

export const useCMATemplateViewerDialog = () => {
  const context = useContext(CMATemplateViewerContext);
  if (context === undefined) {
    throw new Error(
      'useCMATemplateViewerDialog must be used within a CMATemplateViewerProvider',
    );
  }
  return context;
};
