import type { PropertyPoiFragment } from '../../../__generated__/graphql';
import type { FormDefinitionType } from '../../form/RaForm';

import { createDistanceFieldElement } from './utils';

export const poiFormDefinition: FormDefinitionType<PropertyPoiFragment> = ({
  t,
}) => [
  createDistanceFieldElement<PropertyPoiFragment>(
    'distance_to_airport',
    t('Distance to airport'),
  ),
  createDistanceFieldElement<PropertyPoiFragment>(
    'distance_to_hospital',
    t('Distance to hospital'),
  ),
  createDistanceFieldElement<PropertyPoiFragment>(
    'distance_to_motorway',
    t('Distance to motorway'),
  ),
  createDistanceFieldElement<PropertyPoiFragment>(
    'distance_to_city_center',
    t('Distance to city center'),
  ),
  createDistanceFieldElement<PropertyPoiFragment>(
    'distance_to_high_school',
    t('Distance to high school'),
  ),
  createDistanceFieldElement<PropertyPoiFragment>(
    'distance_to_grocery_shop',
    t('Distance to grocery shop'),
  ),
  createDistanceFieldElement<PropertyPoiFragment>(
    'distance_to_kindergarten',
    t('Distance to kindergarten'),
  ),
  createDistanceFieldElement<PropertyPoiFragment>(
    'distance_to_sports_center',
    t('Distance to sports center'),
  ),
  createDistanceFieldElement<PropertyPoiFragment>(
    'distance_to_train_station',
    t('Distance to train station'),
  ),
  createDistanceFieldElement<PropertyPoiFragment>(
    'distance_to_primary_school',
    t('Distance to primary school'),
  ),
  createDistanceFieldElement<PropertyPoiFragment>(
    'distance_to_public_transport',
    t('Distance to public transport'),
  ),
];

export const getDefaultPropertyPoiFormValues = (
  data: PropertyPoiFragment,
): PropertyPoiFragment => ({
  distance_to_airport: data.distance_to_airport,
  distance_to_hospital: data.distance_to_hospital,
  distance_to_motorway: data.distance_to_motorway,
  distance_to_city_center: data.distance_to_city_center,
  distance_to_high_school: data.distance_to_high_school,
  distance_to_grocery_shop: data.distance_to_grocery_shop,
  distance_to_kindergarten: data.distance_to_kindergarten,
  distance_to_sports_center: data.distance_to_sports_center,
  distance_to_train_station: data.distance_to_train_station,
  distance_to_primary_school: data.distance_to_primary_school,
  distance_to_public_transport: data.distance_to_public_transport,
});
