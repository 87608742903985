// @flow

import * as React from 'react';

import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Box } from 'react-system';

import {
  DateRangeFilter,
  type DateRangeParams,
  dateRangeParams,
} from '../../components/DateRangeFilter';
import { Checkbox as MyCheckbox } from '../../controls/checkbox';
import { Filter, FiltersMenu } from '../../controls/Filters';
import { Radio } from '../../controls/radio';
import { useLocale } from '../../hooks/locale';
import { useTheme } from '../../hooks/theme';
import {
  type Field,
  type UrlSearchParamsHook,
  makeField,
  makeUrlSearchParamsHook,
} from '../../hooks/url-search-params';
import { translateSaleHorizon } from '../../utils/lead-labels';
import { type SortingParams, sortingParams } from '../../utils/sorting';

type LeadSaleHorizon =
  | 'already_for_sale'
  | 'from_1_to_2_years'
  | 'from_2_years'
  | 'from_6_to_12_months'
  | 'not_selling'
  | 'not_set'
  | 'under_6_months';

const sale_horizon_of_string = (string): LeadSaleHorizon => {
  switch (string) {
    case 'under_6_months':
    case 'from_6_to_12_months':
    case 'from_1_to_2_years':
    case 'from_2_years':
    case 'already_for_sale':
    case 'not_selling':
      return string;
    case 'not_set':
    default:
      return 'not_set';
  }
};

const makeNullableListField = name => {
  return makeField<$ReadOnlyArray<string> | null>({
    get: params => {
      const list = params.getAllStrings(name);
      return list.length === 0 ? null : list;
    },
    set: (params, value) => params.setAllStrings(name, value ?? []),
  });
};

export type RequalificationParams = {|
  useRequalifiedIfAvailable: Field<boolean | null>,
  mostRecent: Field<boolean | null>,
|};

export const requalificationParams: RequalificationParams = {
  useRequalifiedIfAvailable: makeField({
    get: params => {
      return params.getBoolean('useRequalifiedIfAvailable');
    },
    set: (params, value) =>
      params.setBoolean('useRequalifiedIfAvailable', value),
  }),

  mostRecent: makeField({
    get: params => {
      return params.getBoolean('mostRecent');
    },
    set: (params, value) => params.setBoolean('mostRecent', value),
  }),
};

export const useLeadsParams: UrlSearchParamsHook<{|
  ...SortingParams,
  ...DateRangeParams,
  ...RequalificationParams,
  sourceId_in: Field<$ReadOnlyArray<string> | null>,
  leadLostId_in: Field<$ReadOnlyArray<string> | null>,
  pipelineId_eq: Field<string | null>,
  completed_in: Field<$ReadOnlyArray<boolean> | null>,
  status_in: Field<$ReadOnlyArray<string> | null>,
  saleHorizon_in: Field<$ReadOnlyArray<LeadSaleHorizon> | null>,
  appraisalPropertyUsage_in: Field<$ReadOnlyArray<string> | null>,
  appraisalPropertyOccupied_in: Field<$ReadOnlyArray<string> | null>,
  nextActivity_overdue: Field<boolean | null>,
  zoom: Field<number | null>,
|}> = makeUrlSearchParamsHook({
  ...requalificationParams,
  ...sortingParams,
  ...dateRangeParams,
  sourceId_in: makeField({
    get: params => {
      const list = params.getAllStrings('sourceId_in');
      return list.length === 0 ? null : list;
    },
    set: (params, value) => params.setAllStrings('sourceId_in', value || []),
  }),
  leadLostId_in: makeField({
    get: params => {
      const list = params.getAllStrings('leadLostId_in');
      return list.length === 0 ? null : list;
    },
    set: (params, value) => params.setAllStrings('leadLostId_in', value || []),
  }),
  pipelineId_eq: makeField({
    get: params => params.getString('pipelineId_eq'),
    set: (params, value) => params.setString('pipelineId_eq', value),
  }),
  completed_in: makeField({
    get: params => {
      const list = params.getAllBooleans('completed_in');
      return list.length === 0 ? null : list;
    },
    set: (params, value) => params.setAllBooleans('completed_in', value ?? []),
  }),
  status_in: makeNullableListField('status_in'),
  saleHorizon_in: makeField({
    get: params => {
      const list = params
        .getAllStrings('saleHorizon_in')
        .map(sale_horizon_of_string);
      return list.length === 0 ? null : list;
    },
    set: (params, value) => params.setAllStrings('saleHorizon_in', value ?? []),
  }),
  appraisalPropertyUsage_in: makeNullableListField('appraisalPropertyUsage_in'),
  appraisalPropertyOccupied_in: makeNullableListField(
    'appraisalPropertyOccupied_in',
  ),
  nextActivity_overdue: makeField({
    get: params => params.getBoolean('nextActivity_overdue'),
    set: (params, value) => params.setBoolean('nextActivity_overdue', value),
  }),
  zoom: makeField({
    get: params => params.getNumber('z'),
    set: (params, value) => params.setNumber('z', value),
  }),
});

const NormalFilterContent = <T: string>({
  nodes,
  selectedIds,
  onClick,
}: {|
  nodes: $ReadOnlyArray<{| +id: T, +label: ?string |}>,
  selectedIds: Set<T>,
  onClick: T => void,
|}) => (
  <Box
    width="300px"
    py={2}
    css={{ maxHeight: 'calc(100vh - 300px)', overflow: 'auto' }}
  >
    {nodes.map(item => (
      <Box key={item.id} px={3}>
        <FormControlLabel
          label={item.label}
          control={
            <Checkbox
              color="primary"
              checked={selectedIds.has(item.id)}
              onChange={() => onClick(item.id)}
            />
          }
        />
      </Box>
    ))}
  </Box>
);

const getLabel = (nodes, ids, idleLabel) => {
  if (ids.size === 0) {
    return idleLabel;
  }
  const firstId = Array.from(ids)[0];
  const first = nodes.find(d => d.id === firstId);
  if (first && first.label != null) {
    return ids.size > 1 ? `${first.label} +${ids.size - 1}` : first.label;
  }
  return '';
};

const toggleFilterItem = (set, item) => {
  const newSet = new Set(set);
  if (newSet.has(item)) {
    newSet.delete(item);
  } else {
    newSet.add(item);
  }
  const list = Array.from(newSet);
  return list.length === 0 ? null : list;
};

const SaleHorizonFilter = () => {
  const { t } = useLocale();
  const [params, setParams] = useLeadsParams();
  const saleHorizon_in = params.saleHorizon_in ?? [];
  const labels = saleHorizon_in.map(value => translateSaleHorizon(t, value));
  const toggle = (value, checked) => {
    const newSet = new Set(saleHorizon_in);
    if (checked) {
      newSet.add(value);
    } else {
      newSet.delete(value);
    }
    setParams({ saleHorizon_in: Array.from(newSet) });
  };
  return (
    <Filter
      label={
        saleHorizon_in.length === 0 ? t('saleHorizons') : labels.join(', ')
      }
      dialogTitle={t('saleHorizons')}
      empty={saleHorizon_in.length === 0}
      onReset={() => setParams({ saleHorizon_in: null })}
    >
      <Box
        width="300px"
        py={2}
        px={3}
        css={{ maxHeight: 'calc(100vh - 300px)', overflow: 'auto' }}
      >
        {[
          'under_6_months',
          'from_6_to_12_months',
          'from_1_to_2_years',
          'from_2_years',
          'already_for_sale',
          'not_selling',
        ].map(value => (
          <MyCheckbox
            key={value}
            color="primary"
            checked={saleHorizon_in.includes(value)}
            onChange={checked => toggle(value, checked)}
          >
            {translateSaleHorizon(t, value)}
          </MyCheckbox>
        ))}
      </Box>
    </Filter>
  );
};

const StatusFilter = () => {
  const { t } = useLocale();
  const [params, setParams] = useLeadsParams();
  const selectedIds = new Set(params.status_in);
  const nodes = [
    { id: 'active', label: t('Active') },
    { id: 'won', label: t('won') },
    { id: 'lost', label: t('lost') },
  ];
  const toggle = (id: string) =>
    setParams({ status_in: toggleFilterItem(selectedIds, id) });

  return (
    <Filter
      label={getLabel(nodes, selectedIds, t('statuses'))}
      dialogTitle={t('statuses')}
      empty={selectedIds.size === 0}
      onReset={() => setParams({ status_in: [] })}
    >
      <NormalFilterContent
        nodes={nodes}
        selectedIds={selectedIds}
        onClick={toggle}
      />
    </Filter>
  );
};

const useRequalificationParams = makeUrlSearchParamsHook(requalificationParams);

const RequalificationFilters = ({ isAdmin }) => {
  const [params, setParams] = useRequalificationParams();
  const { t } = useLocale();
  const { useRequalifiedIfAvailable, mostRecent } = params;
  const createdAt = useRequalifiedIfAvailable == null && mostRecent == null;
  const requalifiedAt = mostRecent == null && useRequalifiedIfAvailable != null;
  const { text } = useTheme();

  React.useEffect(() => {
    if (!isAdmin) {
      // if not admin force date filter to mostRecent
      setParams({ mostRecent: true, useRequalifiedIfAvailable: null });
    }
  }, [isAdmin, setParams]);

  if (!isAdmin) {
    return null;
  }

  return (
    <>
      <Radio
        color="primary"
        checked={useRequalifiedIfAvailable == null && mostRecent != null}
        onChange={() => {
          setParams({
            mostRecent: true,
            useRequalifiedIfAvailable: null,
          });
        }}
      >
        <div css={text.body2}>{t('mostRecentDate')}</div>
      </Radio>
      <Radio
        color="primary"
        checked={createdAt}
        onChange={() => {
          setParams({
            mostRecent: null,
            useRequalifiedIfAvailable: null,
          });
        }}
      >
        <div css={text.body2}>{t('createdDate')}</div>
      </Radio>
      <Radio
        color="primary"
        checked={requalifiedAt}
        onChange={() => {
          setParams({
            useRequalifiedIfAvailable: true,
            mostRecent: null,
          });
        }}
      >
        <div css={text.body2}>{t('requalificationDate')}</div>
      </Radio>
    </>
  );
};

const CompletedFilter = () => {
  const { t } = useLocale();
  const [params, setParams] = useLeadsParams();
  const completed_in = params.completed_in ?? [];
  let label = t('completion');
  if (completed_in.length === 1) {
    if (completed_in.includes(true)) {
      label = t('completed');
    }
    if (completed_in.includes(false)) {
      label = t('notCompleted');
    }
  }
  const makeToggle = value => checked => {
    setParams({
      completed_in: checked
        ? [...completed_in, value]
        : completed_in.filter(d => d !== value),
    });
  };
  return (
    <Filter
      label={label}
      dialogTitle={t('completed')}
      empty={completed_in.length === 0}
      onReset={() => setParams({ completed_in: null })}
    >
      <Box px={3} py={2}>
        <MyCheckbox
          color="primary"
          checked={completed_in.includes(true)}
          onChange={makeToggle(true)}
        >
          {t('completed')}
        </MyCheckbox>
        <MyCheckbox
          color="primary"
          checked={completed_in.includes(false)}
          onChange={makeToggle(false)}
        >
          {t('notCompleted')}
        </MyCheckbox>
      </Box>
    </Filter>
  );
};

export const MapLeadsFilters = ({
  isAdmin,
}: {|
  isAdmin: boolean,
|}): React.Node => {
  return (
    <FiltersMenu>
      <Box px={1}>
        <DateRangeFilter top={<RequalificationFilters isAdmin={isAdmin} />} />
      </Box>
      <Box px={1}>
        <SaleHorizonFilter />
      </Box>
      <Box px={1}>
        <CompletedFilter />
      </Box>
      <Box px={1}>
        <StatusFilter />
      </Box>
    </FiltersMenu>
  );
};
