import * as React from 'react';

import type { Interpolation } from '@emotion/react';
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  LinearProgress,
} from '@material-ui/core';

import { Delete } from '../../icons/delete';

type Props = {
  image: File;
  error: string;
};

const RATIO_IMG = 0.67;

const aspectRatio = (): Interpolation<unknown> => ({
  position: 'relative',
  '&:before': {
    display: 'block',
    width: '100%',
    paddingTop: `${100 * RATIO_IMG}%`,
    content: "''",
  },
});

export const LoadingImageCard = ({ error, image }: Props) => {
  const [preview, setPreview] = React.useState<null | string>(null);
  React.useEffect(() => {
    const url = URL.createObjectURL(image);
    setPreview(url);
    return () => {
      URL.revokeObjectURL(url);
    };
  }, [image]);

  return (
    <Card
      css={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        minHeight: '180px',
      }}
    >
      {preview != null && (
        <CardMedia
          css={[
            {
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexShrink: 0,
              cursor: 'pointer',
            },
            aspectRatio(),
            error != null && {
              ':before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: 'red',
                opacity: 0.3,
                transition: 'background 0.3s linear',
              },
              svg: {
                color: 'white',
              },
            },
          ]}
          image={preview}
        >
          {error == null && (
            <LinearProgress
              css={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                height: '2px',
              }}
            />
          )}
        </CardMedia>
      )}
      <CardContent
        css={{
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'column',
          'p, h2, span': {
            width: '100%',
            overflow: 'hidden',
          },
        }}
      >
        <div
          css={[
            {
              overflow: 'hidden',
              overflowWrap: 'break-word',
              wordWrap: 'break-word',
              hyphens: 'auto',
              marginTop: '2px',
            },
            error != null && { color: 'red', paddingRight: '24px' },
          ]}
        >
          {error != null ? error : null}
        </div>
      </CardContent>
      {error != null && (
        <CardActions css={{ flexDirection: 'row-reverse', flexShrink: 0 }}>
          <IconButton size="small" color="primary">
            <Delete />
          </IconButton>
        </CardActions>
      )}
    </Card>
  );
};
