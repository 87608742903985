// Generated from src/svgs/list-outline.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const ListOutline = ({ size, fill, className }: Props) => {
  return (
    <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><path style={{"fill":"none"}} d="M0 .003h24v24H0z" /><path d="M20 8h-9c-1.104 0-2-.897-2-2V4c0-1.103.896-2 2-2h9c1.104 0 2 .897 2 2v2c0 1.103-.896 2-2 2zm-9-4v2h8.997L20 4h-9zm9 11h-9c-1.104 0-2-.897-2-2v-2c0-1.103.896-2 2-2h9c1.104 0 2 .897 2 2v2c0 1.103-.896 2-2 2zm-9-4v2h8.997L20 11h-9zm9 11h-9c-1.104 0-2-.897-2-2v-2c0-1.103.896-2 2-2h9c1.104 0 2 .897 2 2v2c0 1.103-.896 2-2 2zm-9-4v2h8.997L20 18h-9zM6 8H4c-1.104 0-2-.897-2-2V4c0-1.103.896-2 2-2h2c1.104 0 2 .897 2 2v2c0 1.103-.896 2-2 2zM4 4v2h1.997L6 4H4zm2 11H4c-1.104 0-2-.897-2-2v-2c0-1.103.896-2 2-2h2c1.104 0 2 .897 2 2v2c0 1.103-.896 2-2 2zm-2-4v2h1.997L6 11H4zm2 11H4c-1.104 0-2-.897-2-2v-2c0-1.103.896-2 2-2h2c1.104 0 2 .897 2 2v2c0 1.103-.896 2-2 2zm-2-4v2h1.997L6 18H4z" /></svg>
  );
}
