/**
 * @generated SignedSource<<002babd2b40ecc793e287ca0c05ba260>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RecaptchaVersion = "v2" | "v3";
export type VerificationSender = "realadvisor";
export type PhoneSignInRequestInput = {
  clientMutationId?: string | null;
  number?: string | null;
  recaptchaToken: string;
  recaptchaVersion?: RecaptchaVersion | null;
  sender?: VerificationSender | null;
};
export type LoginPhoneFormPinCodeRequestMutation$variables = {
  input: PhoneSignInRequestInput;
};
export type LoginPhoneFormPinCodeRequestMutation$data = {
  readonly phoneSignInRequest: {
    readonly errors: {
      readonly captcha: string | null;
      readonly number: string | null;
    } | null;
    readonly hint: string | null;
  } | null;
};
export type LoginPhoneFormPinCodeRequestMutation = {
  response: LoginPhoneFormPinCodeRequestMutation$data;
  variables: LoginPhoneFormPinCodeRequestMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "PhoneSignInRequestPayload",
    "kind": "LinkedField",
    "name": "phoneSignInRequest",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hint",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PhoneSignInRequestErrors",
        "kind": "LinkedField",
        "name": "errors",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "number",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "captcha",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LoginPhoneFormPinCodeRequestMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LoginPhoneFormPinCodeRequestMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f6f3cbb6493ebea013b0d2641c9c863a",
    "id": null,
    "metadata": {},
    "name": "LoginPhoneFormPinCodeRequestMutation",
    "operationKind": "mutation",
    "text": "mutation LoginPhoneFormPinCodeRequestMutation(\n  $input: PhoneSignInRequestInput!\n) {\n  phoneSignInRequest(input: $input) {\n    hint\n    errors {\n      number\n      captcha\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "48f3586ddffc943999ac612f5a579249";

export default node;
