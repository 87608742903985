import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';

import {
  type InternalRefetchQueriesInclude,
  useMutation,
  useQuery,
} from '@apollo/client';
import { Alert, Snackbar } from '@mui/material';
import {
  type DeepPartial,
  type ErrorOption,
  type FieldPath,
  useWatch,
} from 'react-hook-form';
import { type To } from 'react-router-dom';

import { useLocale } from '../../../src/hooks/locale';
import { type Currency, getCurrencyByCountryCode } from '../../../src/locale';
import { formatPrice } from '../../../src/utils/format-price';
import { gql } from '../../__generated__';
import {
  type InsertOfferMutation,
  type OfferEditFormFragment,
  Offers_Status_Enum,
  type UpdateOfferMutation,
} from '../../__generated__/graphql';
import {
  GET_OFFER_REFUSED_TYPES_QUERY,
  INSERT_OFFER_MUTATION,
  UPDATE_OFFER_MUTATION,
} from '../../pages/offers/offersQueries';
import { getCurrencySymbol } from '../../utils/formatting';
import { AddUserModal } from '../AddUserModal';
import { type FormDefinitionType, RaForm } from '../form/RaForm';
import {
  ListingCardSnippet,
  ListingCardSnippetSkeletton,
} from '../ListingCardSnippet';

export const OFFER_EDIT_FORM_FRAGMENT = gql(`
  fragment OfferEditForm on offers {
    id
    amount
    valid_until
    lot {
      id
      ...ListingCardSnippet
    }
    status
    offer_refused_type {
      id
      label
    }
    made_on
    comments
    buyer {
      ...UserCard_user
    }
  }
`);

type OfferEditFormProps = {
  listingLink?: (listingId: string) => To;
  readonly?: boolean;
  onCancel?: (isFormDirty: boolean) => void;
  onSubmit?: (
    data:
      | UpdateOfferMutation['update_offers_by_pk']
      | InsertOfferMutation['insert_offers_one'],
  ) => void;
  refetchQueries?: InternalRefetchQueriesInclude;
  preventListingChange?: boolean;
} & (
  | { offer: OfferEditFormFragment; prefillListing?: never }
  | { offer: null; prefillListing?: string }
);

type OfferEditFormData = {
  listing: string;
  amount: number;
  valid_until: string | null;
  status: Offers_Status_Enum;
  offer_refused_type: string | null;
  made_on: string;
  comments: string | null;
  buyer: string;
};

const GET_LISTING_PREVIEW_QUERY = gql(`
  query GetListingPreview($listingId: uuid!) {
    lots_by_pk(id: $listingId) {
      id
      ...ListingCardSnippet
    }
  }
`);

const ListingPreview: React.FC<{
  listingLink?: (listingId: string) => To;
  onListingChange: (listing: OfferEditFormFragment['lot'] | null) => void;
}> = ({ listingLink, onListingChange }) => {
  const listingId = useWatch({ name: 'listing' });
  const listingRef = useRef<OfferEditFormFragment['lot'] | null>(null);
  const { data, loading } = useQuery(GET_LISTING_PREVIEW_QUERY, {
    variables: {
      listingId,
    },
    skip: listingId == null,
  });

  useEffect(() => {
    if (data?.lots_by_pk?.id !== listingRef.current?.id) {
      onListingChange(data?.lots_by_pk ?? null);
      listingRef.current = data?.lots_by_pk ?? null;
    }
  }, [data?.lots_by_pk, onListingChange]);

  return (
    <>
      {data?.lots_by_pk != null && (
        <ListingCardSnippet
          listing={data.lots_by_pk}
          link={listingLink?.(data.lots_by_pk.id)}
          orientation="horizontal"
        />
      )}
      {loading && <ListingCardSnippetSkeletton orientation="horizontal" />}
    </>
  );
};

export const OfferForm: React.FC<OfferEditFormProps> = ({
  offer,
  listingLink,
  readonly = false,
  onCancel,
  onSubmit,
  refetchQueries,
  prefillListing,
  preventListingChange = false,
}) => {
  const { t, locale, countryCode } = useLocale();
  const { data: refusedTypesData } = useQuery(GET_OFFER_REFUSED_TYPES_QUERY);
  const [updateOffer] = useMutation(UPDATE_OFFER_MUTATION);
  const [createOffer] = useMutation(INSERT_OFFER_MUTATION, {
    refetchQueries,
  });
  const [snackBarDisplayed, setSnackBarDisplayed] = useState(false);
  const [userCreationData, setUserCreationData] = useState<{
    firstName?: string;
    lastName?: string;
    email?: string;
  } | null>(null);
  const [resetKey, resetFormKey] = useReducer(d => d + 1, 0);
  const [currentListing, setCurrentListing] = useState<
    OfferEditFormFragment['lot'] | null
  >(offer?.lot || null);
  const [valuesOverride, setValuesOverride] = useState<
    DeepPartial<OfferEditFormData>
  >({});

  const currency = useMemo(
    () =>
      (currentListing?.currency ??
        getCurrencyByCountryCode(
          currentListing?.property.country_code ?? countryCode,
        )) as Currency,
    [countryCode, currentListing],
  );

  const formDefinition = useCallback<FormDefinitionType<OfferEditFormData>>(
    ({ t }) => [
      {
        type: 'lot',
        required: true,
        name: 'listing',
        label: t('Listing'),
        gridProps: { md: 12 },
        disabled: () => readonly || preventListingChange,
      },
      {
        type: 'custom',
        name: 'listingPreview',
        element: (
          <ListingPreview
            listingLink={listingLink}
            onListingChange={setCurrentListing}
          />
        ),
        gridProps: { md: 12 },
      },
      {
        type: 'number',
        required: true,
        name: 'amount',
        label: t('Amount'),
        gridProps: { md: 6 },
        disabled: () => readonly,
        prefix: getCurrencySymbol(currency, locale),
      },
      {
        type: 'date',
        name: 'valid_until',
        label: t('Valid until'),
        gridProps: { md: 6 },
        disabled: () => readonly,
      },
      {
        type: 'select',
        required: true,
        name: 'status',
        label: t('Status'),
        options: () => [
          { value: Offers_Status_Enum.Pending, label: t('Pending') },
          { value: Offers_Status_Enum.Accepted, label: t('Accepted') },
          { value: Offers_Status_Enum.Refused, label: t('Refused') },
        ],
        gridProps: { md: 6 },
        disabled: () => readonly,
      },
      {
        type: 'date',
        required: true,
        name: 'made_on',
        label: t('Made on'),
        gridProps: { md: 6 },
        disabled: () => readonly,
      },
      {
        type: 'select',
        name: 'offer_refused_type',
        label: t('Refused reason'),
        options: () =>
          refusedTypesData?.dictionaries.map(({ id, label }) => ({
            value: id,
            label: label ?? '',
          })) ?? [],
        gridProps: { md: 6 },
        render: ({ status }: OfferEditFormData) =>
          status === Offers_Status_Enum.Refused,
        disabled: () => readonly,
      },
      {
        type: 'user',
        required: true,
        name: 'buyer',
        label: t('Buyer'),
        gridProps: { md: 12 },
        disabled: () => readonly,
        createUserSelected: userData => {
          const newUser: {
            firstName?: string;
            lastName?: string;
            email?: string;
          } = {};

          if (userData.first_name != null) {
            newUser.firstName = userData.first_name;
          }

          if (userData.last_name != null) {
            newUser.lastName = userData.last_name;
          }

          if (userData.email != null) {
            newUser.email = userData.email;
          }

          setUserCreationData(newUser);
        },
      },
      {
        type: 'rich-text',
        name: 'comments',
        label: t('Comments'),
        gridProps: { md: 12 },
        disabled: () => readonly,
        resetKey,
      },
    ],
    [
      refusedTypesData?.dictionaries,
      listingLink,
      readonly,
      locale,
      currency,
      preventListingChange,
      resetKey,
    ],
  );

  const defaultValues = useMemo<DeepPartial<OfferEditFormData> | undefined>(
    () =>
      offer == null
        ? {
            listing: prefillListing,
            made_on: new Date().toISOString(),
            status: Offers_Status_Enum.Pending,
          }
        : {
            listing: offer.lot.id,
            amount: offer.amount,
            valid_until: offer.valid_until ?? null,
            status: offer.status ?? Offers_Status_Enum.Pending,
            offer_refused_type: offer.offer_refused_type?.id ?? null,
            made_on: offer.made_on,
            comments: offer.comments,
            buyer: offer.buyer.id,
          },
    [offer, prefillListing],
  );

  return (
    <>
      <RaForm
        formDefinition={formDefinition}
        onSubmit={async data => {
          if (offer == null) {
            await createOffer({
              variables: {
                offer: {
                  amount: data.amount,
                  valid_until: data.valid_until,
                  status: data.status,
                  offer_refused_id: data.offer_refused_type,
                  made_on: data.made_on,
                  comments: data.comments,
                  buyer_id: data.buyer,
                  lot_id: data.listing,
                },
              },
              onCompleted: data => {
                if (data?.insert_offers_one != null) {
                  onSubmit?.(data.insert_offers_one);
                }
              },
            });
          } else {
            await updateOffer({
              variables: {
                id: offer.id,
                updates: {
                  amount: data.amount,
                  valid_until: data.valid_until,
                  status: data.status,
                  offer_refused_id: data.offer_refused_type,
                  made_on: data.made_on,
                  comments: data.comments,
                  buyer_id: data.buyer,
                  lot_id: data.listing,
                },
              },
              onCompleted: data => {
                if (data?.update_offers_by_pk != null) {
                  onSubmit?.(data.update_offers_by_pk);
                }
              },
            });
          }

          setSnackBarDisplayed(true);
        }}
        validate={values => {
          const errors: [FieldPath<OfferEditFormData>, ErrorOption][] = [];

          if (values.amount > 1_000_000_000) {
            errors.push([
              'amount',
              {
                type: 'max',
                message: t('The maximum value is {{value}}', {
                  value: formatPrice(1_000_000_000, locale, currency),
                }),
              },
            ]);
          }

          if (
            values.status === Offers_Status_Enum.Refused &&
            values.offer_refused_type == null
          ) {
            errors.push([
              'offer_refused_type',
              {
                type: 'required',
                message: t(
                  'This field is required if the status is set to "Refused"',
                ),
              },
            ]);
          }

          if (
            values.valid_until != null &&
            new Date(values.valid_until) < new Date(values.made_on)
          ) {
            errors.push([
              'valid_until',
              {
                type: 'min',
                message: t('The date must be greater than the "Made on" date'),
              },
            ]);
          }

          return errors;
        }}
        defaultValues={defaultValues}
        onCancel={isDirty => {
          resetFormKey();
          onCancel?.(isDirty);
        }}
        valuesOverride={valuesOverride}
      />
      <AddUserModal
        opened={userCreationData != null}
        user={userCreationData ?? undefined}
        onClose={userId => {
          setUserCreationData(null);
          if (userId != null) {
            setValuesOverride({ buyer: userId });
          }
        }}
      />
      <Snackbar
        open={snackBarDisplayed}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={5000}
        onClose={() => setSnackBarDisplayed(false)}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: '100%', alignItems: 'center' }}
        >
          {offer == null
            ? t('Offer created successfully!')
            : t('Offer updated successfully!')}
        </Alert>
      </Snackbar>
    </>
  );
};
