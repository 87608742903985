import * as React from 'react';

export const useWindowInnerSize = (): [number, number] => {
  const [innerSize, setInnerSize] = React.useState<[number, number]>([
    window.innerWidth,
    window.innerHeight,
  ]);
  React.useEffect(() => {
    const handleResize = () => {
      const innerWidth = window.innerWidth;
      const innerHeight = window.innerHeight;
      setInnerSize(prev => {
        const [prevInnerWidth, prevInnerHeight] = prev;
        if (prevInnerWidth !== innerWidth || prevInnerHeight !== innerHeight) {
          return [innerWidth, innerHeight];
        } else {
          return prev;
        }
      });
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return innerSize;
};
