/**
 * @generated SignedSource<<599d4b113c0d9dab3d47a1da75c54707>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type leadBrokerCard_lead$data = {
  readonly broker: {
    readonly displayName: string;
    readonly primaryImage: {
      readonly url: string;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"userEditableCard_user">;
  } | null;
  readonly contact: {
    readonly broker: {
      readonly " $fragmentSpreads": FragmentRefs<"userEditableCard_user">;
    } | null;
  } | null;
  readonly id: string;
  readonly property: {
    readonly brokersInCatchmentArea: ReadonlyArray<{
      readonly id: string;
    }> | null;
    readonly id: string;
  } | null;
  readonly " $fragmentType": "leadBrokerCard_lead";
};
export type leadBrokerCard_lead$key = {
  readonly " $data"?: leadBrokerCard_lead$data;
  readonly " $fragmentSpreads": FragmentRefs<"leadBrokerCard_lead">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "userEditableCard_user"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "leadBrokerCard_lead",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "broker",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Image",
          "kind": "LinkedField",
          "name": "primaryImage",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "contact",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "broker",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Property",
      "kind": "LinkedField",
      "name": "property",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "brokersInCatchmentArea",
          "plural": true,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Lead",
  "abstractKey": null
};
})();

(node as any).hash = "95d40513625e99da0164e044c434c346";

export default node;
