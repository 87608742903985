/**
 * @generated SignedSource<<8262da66b678140ee1592a7340dcbc6b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type leadContactCard_lead$data = {
  readonly contact: {
    readonly displayName: string;
    readonly " $fragmentSpreads": FragmentRefs<"userEditableCard_user">;
  } | null;
  readonly createdBy: {
    readonly id: string;
  } | null;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"leadContactCard_RelationshipItem">;
  readonly " $fragmentType": "leadContactCard_lead";
};
export type leadContactCard_lead$key = {
  readonly " $data"?: leadContactCard_lead$data;
  readonly " $fragmentSpreads": FragmentRefs<"leadContactCard_lead">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "leadContactCard_lead",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "leadContactCard_RelationshipItem"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "contact",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "userEditableCard_user"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Lead",
  "abstractKey": null
};
})();

(node as any).hash = "ba9d0c97aeed3f1ce5cf9c48b3a14a4c";

export default node;
