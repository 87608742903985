/**
 * @generated SignedSource<<5e32a124ceddacb53b0a9e86b0480ee6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ActivityForm_lot$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ActivityEventForm_lot" | "ActivityNoteForm_lot">;
  readonly " $fragmentType": "ActivityForm_lot";
};
export type ActivityForm_lot$key = {
  readonly " $data"?: ActivityForm_lot$data;
  readonly " $fragmentSpreads": FragmentRefs<"ActivityForm_lot">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActivityForm_lot",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ActivityNoteForm_lot"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ActivityEventForm_lot"
    }
  ],
  "type": "Lot",
  "abstractKey": null
};

(node as any).hash = "4e26a3d861c2e2e6f91f064b8ef4316d";

export default node;
