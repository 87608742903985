import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number;
  fill?: string;
  className?: string;
  css?: Interpolation<unknown>;
};

const style: Interpolation<unknown> = {
  display: 'block',
  flex: '0 0 auto',
};

export const KeyboardArrowLeft = ({
  size,
  fill,
  className,
  css: cssProp,
}: Props) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      preserveAspectRatio="xMidYMid meet"
      fontSize={size}
      fill={fill ?? 'currentColor'}
      css={[style, cssProp]}
      className={className}
    >
      <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z" />
      <path fill="none" d="M0 0h24v24H0V0z" />
    </svg>
  );
};
