import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { useTheme } from '@mui/material';
import {
  Navigate,
  Route,
  Routes,
  useMatch,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { useLocale } from '../../../src/hooks/locale';
import { CreateListingStepper } from '../../components/create-listing/CreateListingStepper';
import { Drawer } from '../../components/drawer/Drawer';
import {
  type DrawerTab,
  generateTab,
} from '../../components/drawer/DrawerTopBar';
import { RaForm } from '../../components/form/RaForm';

export const CreateListingDrawer = () => {
  const { t } = useLocale();
  const { breakpoints } = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const match = useMatch('/v2/listings/new/:tabName/*');

  const tabsDef: [string, string, React.ReactElement?][] = [
    [
      t('From scratch'),
      'scratch',
      <AddLocationAltOutlinedIcon key="AddLocationAltOutlinedIcon" />,
    ],
    [
      t('Duplicate'),
      'listing',
      <ContentCopyOutlinedIcon key="ContentCopyOutlinedIcon" />,
    ],
  ];

  const tabs: DrawerTab[] = tabsDef.map(([label, value, icon]) =>
    generateTab([searchParams.toString(), label, value, icon]),
  );

  const handleClose = () =>
    navigate({
      pathname: '../',
      search: searchParams.toString(),
    });

  return (
    <Drawer
      onClose={handleClose}
      title={t('Create listing')}
      currentTab={match?.params.tabName ?? ''}
      tabs={tabs}
      maxWidth={breakpoints.values.md}
    >
      <Routes>
        <Route index element={<Navigate to="scratch/" />} />
        <Route
          path="scratch/:listingId?/:createStep?"
          element={<CreateListingStepper />}
        />
        <Route
          path="listing"
          element={
            <RaForm
              formDefinition={() => [
                {
                  label: t('Listing'),
                  type: 'lot',
                  name: 'lot_id',
                },
              ]}
              onSubmit={() => Promise.resolve()}
            />
          }
        />
      </Routes>
    </Drawer>
  );
};
