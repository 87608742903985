// Generated from src/svgs/link_off.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const LinkOff = ({ size, fill, className }: Props) => {
  return (
    <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><path fill="none" d="M0 0h24v24H0V0z" /><path d="M17 7h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1 0 1.43-.98 2.63-2.31 2.98l1.46 1.46C20.88 15.61 22 13.95 22 12c0-2.76-2.24-5-5-5zm-1 4h-2.19l2 2H16zM2 4.27l3.11 3.11A4.991 4.991 0 0 0 2 12c0 2.76 2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1 0-1.59 1.21-2.9 2.76-3.07L8.73 11H8v2h2.73L13 15.27V17h1.73l4.01 4L20 19.74 3.27 3 2 4.27z" /><path fill="none" d="M0 24V0" /></svg>
  );
}
