import { useLazyQuery, useMutation } from '@apollo/client';

import { gql } from '../__generated__';
import type {
  Dictionaries_Types_Enum_Enum,
  InsertLeadMutation,
} from '../__generated__/graphql';
import { useAppData } from '../providers/AppDataProvider';

type InsertedLead = NonNullable<InsertLeadMutation['insert_leads_one']>;

const INSERT_LEAD = gql(`
  mutation InsertLead($input: leads_insert_input!) {
    insert_leads_one(object: $input) {
      id
    }
  }
`);

const GET_PIPELINE_BY_NAME = gql(`
  query GetPipelineByName($name: String!) {
    pipelines(where: { name: { _eq: $name } }) {
      id
    }
  }
`);

const GET_LEAD_STAGE_BY_PIPELINE_ID = gql(`
  query GetLeadStageByPipelineId($pipeline_id: uuid!, $name: String!) {
    lead_stages(where: { pipeline_id: { _eq: $pipeline_id }, name: { _eq: $name } }) {
      id
    }
  }
`);

const GET_SOURCE_BY_NAME = gql(`
  query GetSourceByName($name: String!) {
    dictionaries(where: { name: { _eq: $name } }) {
      id
    }
  }
`);

const INSERT_SOURCE = gql(`
  mutation InsertSource($input: dictionaries_insert_input!) {
    insert_dictionaries_one(object: $input) {
      id
    }
  }
`);

export const useCreateLead = () => {
  const { me } = useAppData();
  const [insertLead] = useMutation(INSERT_LEAD);
  const [getSourceByName] = useLazyQuery(GET_SOURCE_BY_NAME);
  const [getPipelineByName] = useLazyQuery(GET_PIPELINE_BY_NAME);
  const [getLeadStageByPipelineId] = useLazyQuery(
    GET_LEAD_STAGE_BY_PIPELINE_ID,
  );
  const [insertSource] = useMutation(INSERT_SOURCE);

  const getSourceId = async (
    source: string,
    agentId: string | null,
    utmMedium: string | null,
  ) => {
    if (agentId != null) {
      const composedSource = ['appraisal', 'agent', utmMedium ?? ''].join(
        ' - ',
      );
      const { data: sourceData } = await getSourceByName({
        variables: { name: composedSource },
      });

      if (sourceData?.dictionaries[0]?.id) {
        return sourceData.dictionaries[0].id;
      } else {
        const { data: insertedSource } = await insertSource({
          variables: {
            input: {
              type: 'lead_source_types' as Dictionaries_Types_Enum_Enum,
              name: composedSource,
              label_en: composedSource,
            },
          },
        });
        return insertedSource?.insert_dictionaries_one?.id;
      }
    } else {
      const { data: sourceData } = await getSourceByName({
        variables: { name: source },
      });
      if (sourceData?.dictionaries[0]?.id) {
        return sourceData.dictionaries[0].id;
      } else {
        throw new Error(`No match found for lead source name ${source}`);
      }
    }
  };

  const getStageId = async (source: string) => {
    let pipelineName = 'brokerage';
    let stageName = 'assign';

    if (source === 'mortgage') {
      pipelineName = 'financing';
      stageName = 'new_lead';
    }

    const { data: pipelineData } = await getPipelineByName({
      variables: { name: pipelineName },
    });
    if (!pipelineData?.pipelines[0]?.id) {
      throw new Error(`Tenant is missing ${pipelineName} pipeline`);
    }

    const { data: stageData } = await getLeadStageByPipelineId({
      variables: { pipeline_id: pipelineData.pipelines[0].id, name: stageName },
    });
    if (stageData?.lead_stages[0]?.id == null) {
      throw new Error(`Could not find lead stage for the created lead`);
    }

    return stageData.lead_stages[0].id;
  };

  const createNewLead = async (input: any): Promise<InsertedLead> => {
    const { source, ...lead } = input;

    // Handle URL parsing for agentId and utmMedium
    let agentId = null;
    let utmMedium = null;
    if (input.landingPageUrl != null) {
      const url = new URL(input.landingPageUrl);
      agentId = url.searchParams.get('agent_id');
      utmMedium = url.searchParams.get('utm_medium');
    }

    // Set source_id
    lead.source_id = await getSourceId(source, agentId, utmMedium);

    // Set stage_id based on the source
    if (lead.stage_id == null) {
      lead.stage_id = await getStageId(source);
    }

    // Handle other fields
    if (source === 'crm') {
      lead.completed = true;
      if (input.brokerId == null && me?.id) {
        lead.broker_id = me.id;
        lead.claimed_by = me.id;
      }
    }

    if (['appraisal', 'lead-fisher'].includes(source)) {
      lead.verified = source === 'lead-fisher';
    }

    // Set created_by and contact_id
    if (me?.id != null) {
      lead.created_by = me.id;
      if (lead.contact_id == null) {
        lead.contact_id = me.id;
      }
    } else if (lead.contact_id) {
      lead.created_by = lead.contact_id;
    }

    // Insert the lead
    const { data } = await insertLead({
      variables: { input: { ...lead, tenant_id: me?.tenant_id } },
    });

    if (data?.insert_leads_one == null) {
      throw new Error('Failed to create lead');
    }

    return data.insert_leads_one;
  };

  return createNewLead;
};
