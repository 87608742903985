import { gql, useQuery } from '@apollo/client';
import {
  Alert,
  Chip,
  Link,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';

import type { AutoAssignRankingQuery } from '../__generated__/graphql';

const AUTO_ASSIGN_RANKING_QUERY = gql`
  query AutoAssignRanking($lead_id: uuid!) {
    auto_assign_ranking(lead_id: $lead_id) {
      message
      should_auto_assign
      assign_to {
        id
        full_name
        crm_link
      }
      agents {
        id
        full_name
        crm_link
        auto_assign_leads
        cost_per_lead
        amount_spent
        assigned_leads
        already_assigned
        subscriptions {
          id
          current_period_start
          current_period_end
          current_period_pct
          incurred_cost
          latest_invoice
          start_date
          status
          total_amount
          customer_email
        }
      }
    }
  }
`;

interface Props {
  leadId: string;
}

const RenderStatus = ({ status }: { status: string | null | undefined }) => {
  switch (status) {
    case 'active':
      return <Chip color="success" label={status} />;
    case 'past_due':
      return <Chip color="warning" label={status} variant="outlined" />;
    case 'trialing':
    case 'incomplete_expired':
    case 'canceled':
    default:
      return <Chip color="primary" label={status} variant="outlined" />;
  }
};

const RenderAutoAssign = ({
  auto_assign_leads,
}: {
  auto_assign_leads: Boolean | null | undefined;
}) => {
  if (auto_assign_leads === true) {
    return <Chip color="success" label="Yes" />;
  } else if (auto_assign_leads === false) {
    return <Chip color="error" label="No" variant="outlined" />;
  } else {
    return <Chip color="primary" label="Default" variant="outlined" />;
  }
};

export const AutoAssignAssistantEU = ({ leadId: lead_id }: Props) => {
  const { data, loading, error } = useQuery<AutoAssignRankingQuery>(
    AUTO_ASSIGN_RANKING_QUERY,
    {
      variables: { lead_id },
    },
  );

  // Display 5 skeletons while loading results
  if (loading) {
    return (
      <>
        {[...Array(5).keys()].map(i => (
          <Skeleton key={i} variant="rounded" height={40} sx={{ mb: 2 }} />
        ))}
      </>
    );
  }

  if (error) {
    return (
      <Alert severity="error">
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </Alert>
    );
  }

  const ranking = data?.auto_assign_ranking;
  return (
    <>
      {ranking?.message && (
        <Alert
          severity={!ranking?.should_auto_assign ? 'warning' : 'info'}
          sx={{ m: 1 }}
        >
          {ranking?.message}
        </Alert>
      )}
      {ranking?.assign_to && (
        <Alert severity="success" sx={{ m: 1 }}>
          <strong>Assign to:</strong>{' '}
          <Link href={`${ranking?.assign_to?.crm_link}`}>
            {ranking?.assign_to?.full_name}
          </Link>
        </Alert>
      )}
      {(ranking?.agents?.length ?? 0) > 0 && (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Agent</TableCell>
              <TableCell>
                <Tooltip title="Subscription Status">
                  <strong>Subscriptions</strong>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip title="Auto assign leads?">
                  <strong>Assign</strong>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip title="Already received this lead">
                  <strong>Received</strong>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip title="Incurred cost (6m)">
                  <strong>Spend</strong>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip title="Leads assigned (6m)">
                  <strong>Leads</strong>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip title="Cost per lead (6m)">
                  <strong>CPL</strong>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(ranking?.agents ?? []).map(agent => (
              <TableRow key={agent?.id}>
                <TableCell>
                  <Link href={`${agent?.crm_link}`}>{agent?.full_name}</Link>
                </TableCell>
                <TableCell>
                  {agent?.subscriptions?.map(sub => (
                    <RenderStatus key={sub?.id} status={sub?.status} />
                  ))}
                </TableCell>
                <TableCell>
                  <RenderAutoAssign
                    auto_assign_leads={agent?.auto_assign_leads}
                  />
                </TableCell>
                <TableCell>
                  {agent?.already_assigned ? (
                    <Chip color="error" label="Yes" />
                  ) : (
                    <Chip color="primary" label="No" variant="outlined" />
                  )}
                </TableCell>
                <TableCell style={{ textAlign: 'right' }}>
                  {agent?.amount_spent?.toLocaleString(undefined, {
                    maximumFractionDigits: 0,
                  })}
                  €
                </TableCell>
                <TableCell style={{ textAlign: 'right' }}>
                  {Math.round(agent?.assigned_leads ?? 0)}
                </TableCell>
                <TableCell style={{ textAlign: 'right' }}>
                  {(agent?.cost_per_lead ?? 0).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  €
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
};
