import {
  type Dispatch,
  type FC,
  type MutableRefObject,
  type ReactElement,
  type ReactNode,
  type Ref,
  type SetStateAction,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { useMutation } from '@apollo/client';
import type { DeepPartial } from '@apollo/client/utilities';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { LoadingButton } from '@mui/lab';
import { Box, Button, ButtonBase, useTheme } from '@mui/material';
import scrollbarSize from 'dom-helpers/scrollbarSize';
import {
  type FieldValues,
  type UseFormSetValue,
  useFormContext,
} from 'react-hook-form';

import { FilePdf } from '../../../icons/file-pdf';
import { useLocale } from '../../../src/hooks/locale';
import { useCMATemplateViewerDialog } from '../../../src/routes/Documents/cma-template-viewer-context';
import type { StepListingsCompareFragment } from '../../__generated__/graphql';
import { composePropertyType } from '../../utils/propertyTypes';

import type { CMAPropertyTypes } from './CMAReportEditor/CMAReportEditorDrawer';
import {
  UPDATE_CMA_COVER,
  UPDATE_CMA_INTRODUCTION,
  UPDATE_COMPARABLES_LIST,
  UPDATE_DESCRIPTION,
  UPDATE_FINAL,
  UPDATE_HEDONISTIC_VALUATION,
  UPDATE_OTHER_VALUATIONS,
  UPDATE_POTENTIAL_BUYERS,
  UPDATE_PROPERTY_DETAILS,
} from './cmaReportsQueries';

export type PageId =
  | 'page-cover'
  | 'page-introduction'
  | 'page-property'
  | 'page-description'
  | 'page-hedonistic-valuation'
  | 'page-comparables-list'
  | 'page-other-valuations'
  | 'page-final'
  | 'page-potential-buyers'
  | 'page-append-files';

export const useUpdateCmaReport = (
  cmaReportId: string,
  page: Exclude<PageId, 'page-append-files'>,
) => {
  const cmaMutations = {
    'page-cover': useMutation(UPDATE_CMA_COVER),
    'page-introduction': useMutation(UPDATE_CMA_INTRODUCTION),
    'page-property': useMutation(UPDATE_PROPERTY_DETAILS),
    'page-description': useMutation(UPDATE_DESCRIPTION),
    'page-hedonistic-valuation': useMutation(UPDATE_HEDONISTIC_VALUATION),
    'page-other-valuations': useMutation(UPDATE_OTHER_VALUATIONS),
    'page-potential-buyers': useMutation(UPDATE_POTENTIAL_BUYERS),
    'page-comparables-list': useMutation(UPDATE_COMPARABLES_LIST),
    'page-final': useMutation(UPDATE_FINAL),
  } as const;
  const [mutation, { loading: updating }] = cmaMutations[page];

  const updateCmaReport = useCallback(
    async (input: Record<string, any>) =>
      await mutation({
        variables: {
          id: cmaReportId,
          input,
        },
      }),
    [cmaReportId, mutation],
  );

  return [updateCmaReport, updating] as const;
};

const MAX_SCROLL_BAR_SIZE = 32;
const FILTER_SCROLL_DISTANCE = 150;

export const getAutoAlgoParams = (
  lead: DeepPartial<StepListingsCompareFragment['lead']>,
  countryCode: string,
) => {
  return {
    variables: {
      lat: lead?.property?.lat ?? 0,
      lng: lead?.property?.lng ?? 0,
      living_surface: lead?.property?.living_surface ?? null,
      built_surface: lead?.property?.built_surface ?? null,
      usable_surface: lead?.property?.usable_surface ?? null,
      number_of_rooms: Math.round(
        lead?.property?.number_of_rooms ??
          lead?.property?.number_of_bedrooms ??
          0,
      ),
      property_main_type: composePropertyType(
        lead?.property?.property_type?.main_type ?? null,
        lead?.property?.property_type?.name ?? null,
      ),
      sale_price: lead?.property?.latest_appraisal?.value,
      ch: countryCode === 'CH',
      es: countryCode === 'ES',
      fr: countryCode === 'FR',
      it: countryCode === 'IT',
    },
    context: {
      clientName: 'scrapers',
    },
  };
};

export const HorizontalScrollContainer = ({
  height,
  children,
  nodeIndex,
}: {
  nodeIndex: number;
  height: number;
  children: ReactNode;
}) => {
  const { palette } = useTheme();
  const [showScrollLeft, setShowScrollLeft] = useState(false);
  const [showScrollRight, setShowScrollRight] = useState(false);
  const menuContainerRef = useRef<HTMLElement | null>(null);
  const menuRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const firstChildObserver = new IntersectionObserver(
      ([entry]) => {
        setShowScrollLeft(!entry.isIntersecting);
      },
      { threshold: 0.7 },
    );

    const lastChildObserver = new IntersectionObserver(
      ([entry]) => {
        setShowScrollRight(!entry.isIntersecting);
      },
      { threshold: 0.7 },
    );

    const firstChildElement = menuRef.current?.firstElementChild;
    const lastChildElement = menuRef.current?.lastElementChild;

    if (firstChildElement != null && lastChildElement != null) {
      firstChildObserver.observe(firstChildElement);
      lastChildObserver.observe(lastChildElement);

      return () => {
        firstChildObserver.unobserve(firstChildElement);
        lastChildObserver.unobserve(lastChildElement);
      };
    }
  }, [menuRef]);

  useEffect(() => {
    const node = menuRef.current?.children[nodeIndex];
    node?.scrollIntoView({ block: 'end' });
  }, [menuRef, nodeIndex]);

  const baseArrowButtonStyles = {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    bottom: 0,
    width: 36,
  };

  return (
    <div
      css={{ overflow: 'hidden', position: 'relative', height: `${height}px` }}
    >
      <Box
        display="flex"
        ref={menuContainerRef}
        alignItems="center"
        sx={{
          overflowX: 'auto',
          overflowY: 'hidden',
          WebkitOverflowScrolling: 'touch',
          scrollBehavior: 'smooth',
          height: '100%',
          boxSizing: 'content-box',
          paddingBottom: `${scrollbarSize() || MAX_SCROLL_BAR_SIZE}px`,
        }}
      >
        <Box display="flex" ref={menuRef} pr={3} sx={{ minWidth: '100%' }}>
          {children}
        </Box>
      </Box>
      {showScrollLeft && (
        <ButtonBase
          onClick={() => {
            if (menuContainerRef.current) {
              menuContainerRef.current.scrollBy(-FILTER_SCROLL_DISTANCE, 0);
            }
          }}
          sx={{
            ...baseArrowButtonStyles,
            background:
              'linear-gradient(90deg, rgba(246,247,249,1) 70%, rgba(246,247,249,0) 100%)',
            left: 0,
          }}
        >
          <Box
            css={{
              width: 0,
              height: 0,
              borderTop: '6px solid transparent',
              borderBottom: '6px solid transparent',
              borderRight: `6px solid ${palette.text.secondary}`,
            }}
          />
        </ButtonBase>
      )}
      {showScrollRight && (
        <ButtonBase
          onClick={() => {
            if (menuContainerRef.current) {
              menuContainerRef.current.scrollBy(FILTER_SCROLL_DISTANCE, 0);
            }
          }}
          sx={{
            ...baseArrowButtonStyles,
            background:
              'linear-gradient(270deg, rgba(246,247,249,1) 70%, rgba(246,247,249,0) 100%)',
            borderRight: `1px solid ${palette.grey[300]}`,
            right: 0,
          }}
        >
          <div
            css={{
              width: 0,
              height: 0,
              borderTop: '6px solid transparent',
              borderBottom: '6px solid transparent',
              borderLeft: `6px solid ${palette.text.secondary}`,
            }}
          />
        </ButtonBase>
      )}
    </div>
  );
};

export type CMAReportComponentProps = {
  step: number;
  setStep: (step: number) => void;
  cmaReportId: string;
  propertyTypes: CMAPropertyTypes;
  setViewerPageId: Dispatch<SetStateAction<PageId>>;
  ResponsiveActionsLayout: FC<{ children: ReactNode }>;
  iFrameRef: MutableRefObject<HTMLIFrameElement>;
};

type BaseFooterProps = {
  step: number;
  setStep: (step: number) => void;
  updating: boolean;
  hideBackButton?: boolean;
  ResponsiveActionsLayout: FC<{ children: ReactNode }>;
};

type DownloadButtonProps = BaseFooterProps & {
  downloadButton: true;
};

type SubmitButtonProps<T extends FieldValues> = BaseFooterProps & {
  downloadButton?: false;
  onSubmit: (formData: T) => Promise<void>;
};

type FooterProps<T extends FieldValues> =
  | DownloadButtonProps
  | SubmitButtonProps<T>;

export type FooterActionsRef<T extends FieldValues> = {
  setValue: UseFormSetValue<T>;
};

const FooterActionsComponentWithForm = <T extends FieldValues>(
  props: FooterProps<T>,
  ref: Ref<FooterActionsRef<T>>,
) => {
  const {
    step,
    setStep,
    updating,
    downloadButton,
    hideBackButton,
    ResponsiveActionsLayout,
  } = props;
  const { t } = useLocale();
  const { setTemplateOpen } = useCMATemplateViewerDialog();
  const { handleSubmit, setValue } = useFormContext<T>();

  useImperativeHandle(ref, () => ({ setValue }));

  const onSubmitHandler = async (formData: T) => {
    if ('onSubmit' in props && props.onSubmit) {
      await props.onSubmit(formData);
      setStep(step + 1);
    }
  };

  return (
    <ResponsiveActionsLayout>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 1,
        }}
      >
        {hideBackButton !== true ? (
          <Button startIcon={<ChevronLeft />} onClick={() => setStep(step - 1)}>
            {t('back')}
          </Button>
        ) : (
          <Box />
        )}
        <Box sx={{ display: 'flex', gap: 1 }}>
          {downloadButton ? (
            <Button
              startIcon={<FilePdf />}
              variant="contained"
              onClick={() => setTemplateOpen(true)}
              size="large"
            >
              {t('viewAndDownload')}
            </Button>
          ) : (
            <LoadingButton
              loading={updating}
              endIcon={<ChevronRight />}
              loadingPosition="end"
              variant="contained"
              onClick={handleSubmit(onSubmitHandler)}
            >
              {t('next')}
            </LoadingButton>
          )}
        </Box>
      </Box>
    </ResponsiveActionsLayout>
  );
};

export const FooterActionsComponentWithoutForm = (
  props: Omit<FooterProps<any>, 'onSubmit'>,
) => {
  const { step, setStep, ResponsiveActionsLayout } = props;
  const { t } = useLocale();

  const onClickHandler = async () => {
    setStep(step + 1);
  };

  return (
    <div>
      <ResponsiveActionsLayout>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 1,
          }}
        >
          <Button startIcon={<ChevronLeft />} onClick={() => setStep(step - 1)}>
            {t('back')}
          </Button>
          <Button
            endIcon={<ChevronRight />}
            variant="contained"
            onClick={onClickHandler}
          >
            {t('next')}
          </Button>
        </Box>
      </ResponsiveActionsLayout>
    </div>
  );
};

export const FooterActions = forwardRef(FooterActionsComponentWithForm) as <
  T extends FieldValues,
>(
  props: FooterProps<T> & { ref?: Ref<FooterActionsRef<T>> },
) => ReactElement;
