// @flow

import type { LatLngLiteral } from './google-types.js';

const log2 = Math.log2;

const GOOGLE_TILE_SIZE = 256;

type Point = {| +x: number, +y: number |};

export type Bounds = {| +sw: LatLngLiteral, +ne: LatLngLiteral |};
export type Padding = number | {| l: number, r: number, t: number, b: number |};

function latLng2World({ lat, lng }: LatLngLiteral): Point {
  const sin = Math.sin((lat * Math.PI) / 180);
  const x = lng / 360 + 0.5;
  let y = 0.5 - (0.25 * Math.log((1 + sin) / (1 - sin))) / Math.PI;

  y = y < 0 ? 0 : y > 1 ? 1 : y;
  return { x, y };
}

const mod = (a, m) => ((a % m) + m) % m;

function world2LatLng({ x, y }: Point): LatLngLiteral {
  const n = Math.PI - 2 * Math.PI * y;

  // TODO test that this is faster
  // 360 * Math.atan(Math.exp((180 - y * 360) * Math.PI / 180)) / Math.PI - 90;

  return {
    lat: (180 / Math.PI) * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n))),
    lng: mod(x * 360, 360) - 180,
  };
}

export function getScreenOffset(
  a: LatLngLiteral,
  b: LatLngLiteral,
  zoom: number,
): Point {
  const wA = latLng2World(a);
  const wB = latLng2World(b);
  const scale = Math.pow(2, zoom);
  return {
    x: (wB.x - wA.x) * scale * GOOGLE_TILE_SIZE,
    y: (wB.y - wA.y) * scale * GOOGLE_TILE_SIZE,
  };
}

export function getPanByCoordinates(
  center: LatLngLiteral,
  offset: Point,
  zoom: number,
): LatLngLiteral {
  const wCenter = latLng2World(center);
  const scale = Math.pow(2, zoom);
  const wOffset = {
    x: offset.x / scale / GOOGLE_TILE_SIZE,
    y: offset.y / scale / GOOGLE_TILE_SIZE,
  };

  return world2LatLng({
    x: wCenter.x + wOffset.x,
    y: wCenter.y + wOffset.y,
  });
}

/*
Usage example

```
  fitBounds(
    {
      ne: { lat: 46.6783007682986, lng: 6.78468658331542 },
      sw: { lat: 46.38779532285434, lng: 6.464484471117544 },
    },
    {
      width: 466,
      height: 615,
    },
  ),
);
```
*/
const marginFromNumber = num => ({ l: num, r: num, t: num, b: num });

export function paddingBounds(
  bounds: Bounds,
  zoom: number,
  size: {| width: number, height: number |},
  padding?: Padding,
): Bounds {
  const p =
    padding == null
      ? marginFromNumber(0)
      : typeof padding === 'number'
      ? marginFromNumber(padding)
      : padding;

  const swXY = latLng2World(bounds.sw);
  const neXY = latLng2World(bounds.ne);

  const scale = Math.pow(2, zoom);
  const k = scale * GOOGLE_TILE_SIZE;

  const padSW = world2LatLng({
    x: swXY.x + p.l / k,
    y: swXY.y - p.b / k,
  });

  const padNE = world2LatLng({
    x: neXY.x - p.r / k,
    y: neXY.y + p.t / k,
  });

  return {
    ne: padNE,
    sw: padSW,
  };
}

export function fitBounds(
  bounds: Bounds,
  size: {| width: number, height: number |},
  // marging in screen pixels
  padding?: Padding,
): null | {|
  center: LatLngLiteral,
  zoom: number,
  bounds: Bounds,
|} {
  const EPS = 0.000000001;
  const p =
    padding == null
      ? marginFromNumber(0)
      : typeof padding === 'number'
      ? marginFromNumber(padding)
      : padding;

  const swXY = latLng2World(bounds.sw);
  const neXY = latLng2World(bounds.ne);

  const dx = swXY.x <= neXY.x ? neXY.x - swXY.x : 1 - swXY.x + neXY.x;

  const dy = swXY.y - neXY.y;

  if (dx <= 0 && dy <= 0) {
    return null;
  }

  const zoomX = log2(
    Math.max(1, size.width - p.l - p.r) / GOOGLE_TILE_SIZE / Math.abs(dx),
  );
  const zoomY = log2(
    Math.max(1, size.height - p.t - p.b) / GOOGLE_TILE_SIZE / Math.abs(dy),
  );
  const zoom = Math.floor(EPS + Math.min(zoomX, zoomY));
  const scale = Math.pow(2, zoom);
  const k = scale * GOOGLE_TILE_SIZE;

  const middle = {
    x:
      swXY.x < neXY.x
        ? 0.5 * (swXY.x + neXY.x)
        : swXY.x + neXY.x - 1 > 0
        ? 0.5 * (swXY.x + neXY.x - 1)
        : 0.5 * (1 + swXY.x + neXY.x),
    y: 0.5 * (neXY.y + swXY.y),
  };

  middle.x += (p.r - p.l) / 2 / k;
  middle.y += (p.b - p.t) / 2 / k;

  const halfW = size.width / 2 / k;
  const halfH = size.height / 2 / k;

  const newNE = world2LatLng({
    x: middle.x + halfW,
    y: middle.y - halfH,
  });

  const newSW = world2LatLng({
    x: middle.x - halfW,
    y: middle.y + halfH,
  });

  const center = world2LatLng(middle);

  return {
    center: {
      lng: center.lng,
      // yep google uses not physical screen center but bounds center
      lat: 0.5 * (newNE.lat + newSW.lat),
    },
    zoom,
    bounds: {
      sw: newSW,
      ne: newNE,
    },
  };
}

// lng coordinate is [-180, 180] so you have 2 variants what arc is the shortest
export function getBoundingBox(
  points: $ReadOnlyArray<{ +lat: number, +lng: number, ... }>,
): null | Bounds {
  if (points.length < 1) {
    return null;
  }

  let minLat = Number.POSITIVE_INFINITY;
  let maxLat = Number.NEGATIVE_INFINITY;

  let minLng = Number.POSITIVE_INFINITY;
  let maxLng = Number.NEGATIVE_INFINITY;

  points.forEach(pt => {
    minLat = Math.min(minLat, pt.lat);
    maxLat = Math.max(maxLat, pt.lat);

    const lng = pt.lng < 0 ? 360 + pt.lng : pt.lng;

    minLng = Math.min(minLng, lng);
    maxLng = Math.max(maxLng, lng);
  });

  if (minLat === maxLat || maxLng === minLng) {
    // box must have a size
    return null;
  }

  const d1 = maxLng - minLng;
  const d2 = minLng - maxLng + 360;
  if (d1 > d2) {
    // swap min max lng
    const tmp = minLng;
    minLng = maxLng;
    maxLng = tmp;
  }

  return {
    sw: { lat: minLat, lng: minLng > 180 ? minLng - 360 : minLng },
    ne: { lat: maxLat, lng: maxLng > 180 ? maxLng - 360 : maxLng },
  };
}
