export type Interval = {
  years?: number | null;
  months?: number | null;
  weeks?: number | null;
  days?: number | null;
  hours?: number | null;
  minutes?: number | null;
  type: 'past' | 'future';
};

export const isSQLInterval = (interval: any): boolean => {
  return (
    (interval?.years != null ||
      interval?.months != null ||
      interval?.weeks != null ||
      interval?.days != null ||
      interval?.hours != null ||
      interval?.minutes != null) &&
    interval?.type != null
  );
};

export const isSQLIntervalString = (interval: any): boolean => {
  return (
    typeof interval === 'string' &&
    (interval.startsWith('now() -') || interval.startsWith('now() +'))
  );
};

export const parseSQLInterval = (interval: string): Interval => {
  let type;

  // Check if the interval is future
  if (interval.startsWith('now() +')) {
    type = 'future';
  } else if (interval.startsWith('now() -')) {
    type = 'past';
  } else {
    throw new Error('Invalid Postgres interval string');
  }

  interval = interval.replace('now() - ', '').replace('now() + ', '');

  const regex = /(\d{1,4})\s*(year|month|week|day|hour|min)/g;
  let match: RegExpExecArray | null;

  const result: Interval = {
    type: type as 'past' | 'future',
  };

  while ((match = regex.exec(interval)) != null) {
    const value = parseInt(match[1]);
    const unit = match[2];

    switch (unit) {
      case 'year':
        result.years = (result.years ?? 0) + value;
        break;
      case 'month':
        result.months = (result.months ?? 0) + value;
        break;
      case 'week':
        result.weeks = (result.weeks ?? 0) + value;
        break;
      case 'day':
        result.days = (result.days ?? 0) + value;
        break;
      case 'hour':
        result.hours = (result.hours ?? 0) + value;
        break;
      case 'min':
        result.minutes = (result.minutes ?? 0) + value;
        break;
      default:
        throw new Error('Invalid Postgres interval string');
    }
  }

  return result;
};

// conver to SQL interval string
export const toSQLInterval = (interval: Interval): string => {
  let sqlInterval = 'now() ';

  if (interval.type === 'past') {
    sqlInterval += '- ';
  } else if (interval.type === 'future') {
    sqlInterval += '+ ';
  } else {
    throw new Error(
      'Invalid interval type. Should be either "past" or "future"',
    );
  }

  if (interval.years != null && interval.years !== 0) {
    sqlInterval += `${interval.years} years `;
  }

  if (interval.months != null && interval.months !== 0) {
    sqlInterval += `${interval.months} months `;
  }

  if (interval.weeks != null && interval.weeks !== 0) {
    sqlInterval += `${interval.weeks} weeks `;
  }

  if (interval.days != null && interval.days !== 0) {
    sqlInterval += `${interval.days} days `;
  }

  if (interval.hours != null && interval.hours !== 0) {
    sqlInterval += `${interval.hours} hours `;
  }

  if (interval.minutes != null && interval.minutes !== 0) {
    sqlInterval += `${interval.minutes} mins `;
  }

  // Remove trailing space and return
  return sqlInterval.trim();
};

export const SQLIntervalToISODate = (interval: string): string => {
  const parsedInterval = parseSQLInterval(interval);
  const operator = parsedInterval.type === 'past' ? -1 : 1;

  const date = new Date();

  if (parsedInterval.years != null) {
    date.setFullYear(date.getFullYear() + parsedInterval.years * operator);
  }

  if (parsedInterval.months != null) {
    date.setMonth(date.getMonth() + parsedInterval.months * operator);
  }

  if (parsedInterval.weeks != null) {
    date.setDate(date.getDate() + parsedInterval.weeks * 7 * operator);
  }

  if (parsedInterval.days != null) {
    date.setDate(date.getDate() + parsedInterval.days * operator);
  }

  if (parsedInterval.hours != null) {
    date.setHours(date.getHours() + parsedInterval.hours * operator);
  }

  if (parsedInterval.minutes != null) {
    date.setMinutes(date.getMinutes() + parsedInterval.minutes * operator);
  }

  return date.toISOString();
};
