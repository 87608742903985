// Generated from src/svgs/nav-email-active.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const NavEmailActive = ({ size, fill, className }: Props) => {
  return (
    <svg viewBox="0 0 25 25" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><path d="M24 5.38v1.229l-10.797 6.398a1.38 1.38 0 0 1-1.406 0L1 6.609V5.38A1.38 1.38 0 0 1 2.38 4h20.24A1.38 1.38 0 0 1 24 5.38Zm-11.5 8.74c-.413 0-.818-.111-1.172-.323L1 7.677V20.1a1.38 1.38 0 0 0 1.38 1.38h20.24A1.38 1.38 0 0 0 24 20.1V7.678l-10.328 6.12c-.354.21-.76.322-1.172.322Z" fill="#fff" /></svg>
  );
}
