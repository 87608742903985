import { useQuery } from '@apollo/client';
import { Box, Stack } from '@mui/material';

import { useLocale } from '../../../src/hooks/locale';
import type { Currency } from '../../../src/locale';
import { translateMortgageType } from '../../../src/routes/FinancingRequests/translations';
import { fontWeight } from '../../../src/styles';
import { getCountryName } from '../../../src/utils/countries';
import { formatPrice } from '../../../src/utils/format-price';
import { translatePropertyUsage } from '../../../src/utils/lead-labels';
import { gql } from '../../__generated__';
import type { FiancingLeadDetailFragment } from '../../__generated__/graphql';
import { formatAddress } from '../../utils/formatting';

export const FINANCING_LEAD_DETAIL_FRAGMENT = gql(`
  fragment FiancingLeadDetail on leads {
    id
    completed
    mortgage_property_value
    mortgage_property_usage
    mortgage_is_construction
    mortgage_mortgage_type
    mortgage_report_type
    mortgage_google_address {
      formatted_address
    }
    property {
      street_number
      route
      postcode
      locality
      state
      country_code
      latest_appraisal {
        id
        max
        min
      }
    }
  }
`);

const MORTGAGE_QUERY = gql(`
  query LeadCard_mortgage($leadId: uuid!) {
    calculate_mortgage_for_lead(lead_id: $leadId) {
      mortgage_analysis {
        max_property_value
      }
    }
  }
`);

type FinancingLeadDetailProps = {
  isCompact: boolean;
  lead: FiancingLeadDetailFragment;
  currency: Currency;
  showAddress?: boolean;
  bottomButton?: React.ReactNode;
};

const getLeadMortgageAddress = (lead: FiancingLeadDetailFragment) => {
  const propertyFormattedAddress = formatAddress(
    {
      ...lead.property,
      country: getCountryName(lead.property?.country_code ?? null),
    },
    ', ',
    false,
  );

  const formattedAddress =
    propertyFormattedAddress.length > 0
      ? propertyFormattedAddress
      : lead.mortgage_google_address?.formatted_address;

  return formattedAddress ?? '-';
};

export const FinancingLeadDetail: React.FC<FinancingLeadDetailProps> = ({
  isCompact,
  lead,
  showAddress = false,
  bottomButton,
  currency,
}) => {
  const { t, locale } = useLocale();
  const { data } = useQuery(MORTGAGE_QUERY, {
    variables: { leadId: lead.id },
  });

  const mortgageAnalysis = data?.calculate_mortgage_for_lead?.mortgage_analysis;

  return (
    <Stack flexGrow={1} flexBasis={0} p={isCompact ? 1 : 3} spacing={0.5}>
      <Box>
        <Box sx={{ typography: 'subtitle2' }}>
          {lead.mortgage_is_construction === true
            ? t('newDevelopment')
            : t('existingProperty')}
        </Box>
        {showAddress && (
          <Box sx={{ typography: 'body2' }}>{getLeadMortgageAddress(lead)}</Box>
        )}
        <Box sx={{ typography: 'body2' }}>
          {translatePropertyUsage(t, lead.mortgage_property_usage ?? '')}
        </Box>
        <Box
          sx={{
            typography: 'body1',
            color: 'white.contrastText',
            fontWeight: fontWeight.medium,
            marginTop: 8,
          }}
        >
          {formatPrice(lead.mortgage_property_value, locale, currency)}
        </Box>
      </Box>
      {lead.completed &&
        lead.mortgage_mortgage_type != null &&
        mortgageAnalysis && (
          <Box sx={{ typography: 'body2', color: 'text.secondary' }}>
            {[
              lead.mortgage_report_type === 'financing_passport'
                ? t('financingPassport')
                : translateMortgageType(t, lead.mortgage_mortgage_type ?? ''),
              ', ',
              currency,
              ' ',
              mortgageAnalysis.max_property_value?.toLocaleString(locale) ?? '',
            ]}
          </Box>
        )}
      {bottomButton != null && <Box mt={2}>{bottomButton}</Box>}
    </Stack>
  );
};
