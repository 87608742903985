import {
  type Dispatch,
  type SetStateAction,
  forwardRef,
  useMemo,
  useState,
} from 'react';

import { useMutation, useSuspenseQuery } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Dialog,
  DialogTitle,
  IconButton,
  Paper,
  Slide,
  Snackbar,
  Stack,
} from '@mui/material';
import { type TransitionProps } from '@mui/material/transitions';

import { useLocale } from '../../../src/hooks/locale';
import { customPalette } from '../../../src/styles';
import type {
  GetListingEditContextQuery,
  UpdateListingWithPropertyMutation,
  UpdateListingWithPropertyMutationVariables,
} from '../../__generated__/graphql';
import {
  GET_LISTING_EDIT_CONTEXT,
  type GetListingDetailsData,
  UPDATE_LISTING_WITH_PROPERTY,
} from '../../pages/listings/lotsQueries';
import { useAppData } from '../../providers/AppDataProvider';
import { RaForm } from '../form/RaForm';
import type { PropertyFormContext } from '../property-form/forms-definitions/propertyDetailsFormDefinition';
import { PropertyTypeProvider } from '../property-form/PropertyTypeProvider';

import { getListingOverviewEditFormDefinition } from './listingOverviewFormDefinition';
import { type FormDefinition } from './models';

interface IProps {
  listing: GetListingDetailsData;
  opened: boolean;
  editForm: EEditListingForms | null;
  onClose: () => void;
}

export enum EEditListingForms {
  PHOTOS = 'photos',
  VR_VIDEOS = 'vr-videos',
  TITLE_DESCRIPTION = 'title-description',
  ADDRESS = 'address',
  AVAILABILITY = 'availability',
  PRICING = 'pricing',
  PROPERTY = 'property',
  EQUIPMENT = 'equipment',
  POI = 'poi',
  LISTED_BY = 'listed-by',
  MANDATE = 'mandate',
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type RaFormWrapperProps<TFormData extends Record<string, any>> = {
  globalDefinition:
    | FormDefinition<TFormData, PropertyFormContext>
    | FormDefinition<TFormData>;
  formContext: PropertyFormContext;
  handleModalClose: (checkFormDirty?: boolean) => void;
  setIsFormDirty: Dispatch<SetStateAction<boolean>>;
  setSnackBarDisplayed: Dispatch<SetStateAction<boolean>>;
};

const RaFormWrapper = <TFormData extends Record<string, any>>({
  globalDefinition,
  formContext,
  handleModalClose,
  setIsFormDirty,
  setSnackBarDisplayed,
}: RaFormWrapperProps<TFormData>) => {
  return globalDefinition.hasContext ? (
    <RaForm
      formDefinition={globalDefinition.formDefinition}
      onSubmit={async (_, data) => {
        const result = await globalDefinition.onSubmit(data);
        handleModalClose(false);
        setSnackBarDisplayed(true);

        return result;
      }}
      defaultValues={globalDefinition.defaultValues}
      validate={globalDefinition.validate}
      context={formContext}
      onCancel={() => handleModalClose(false)}
      onFormDirtyChange={setIsFormDirty}
      gridProps={{
        alignItems: 'end',
      }}
    >
      <PropertyTypeProvider propertyTypes={formContext.propertyTypes} />
    </RaForm>
  ) : (
    <RaForm
      formDefinition={globalDefinition.formDefinition}
      onSubmit={async (_, data) => {
        const result = await globalDefinition.onSubmit(data);
        handleModalClose(false);
        setSnackBarDisplayed(true);

        return result;
      }}
      defaultValues={globalDefinition.defaultValues}
      validate={globalDefinition.validate}
      onCancel={() => handleModalClose(false)}
      onFormDirtyChange={setIsFormDirty}
      gridProps={{
        alignItems: 'end',
      }}
    >
      <PropertyTypeProvider propertyTypes={formContext.propertyTypes} />
    </RaForm>
  );
};

export const ListingOverviewEditModal: React.FC<IProps> = ({
  listing,
  opened,
  editForm,
  onClose,
}) => {
  const { t, locale, countryCode } = useLocale();
  const { me } = useAppData();
  const [snackBarDisplayed, setSnackBarDisplayed] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const { data: context } = useSuspenseQuery<GetListingEditContextQuery>(
    GET_LISTING_EDIT_CONTEXT,
  );
  const [update, { loading }] = useMutation<
    UpdateListingWithPropertyMutation,
    UpdateListingWithPropertyMutationVariables
  >(UPDATE_LISTING_WITH_PROPERTY);

  const isCHTenant = me?.tenant.country_code === 'CH';

  const listingFormDefinition = useMemo(
    () =>
      getListingOverviewEditFormDefinition(
        t,
        listing,
        update,
        isCHTenant,
        locale,
        countryCode,
      ),
    [t, listing, update, isCHTenant, locale, countryCode],
  );

  const formDefinition =
    editForm == null ? null : listingFormDefinition.get(editForm);

  if (formDefinition == null) {
    return null;
  }

  const formContext: PropertyFormContext = {
    propertyTypes: context.property_types,
    heatingTypes: context.heating_enum,
    heatingDistribution: context.heating_distribution_enum,
    coolingTypes: context.cooling_enum,
  };

  const handleModalClose = (checkFormDirty = true) => {
    if (loading) {
      // Prevent closing Dialog if saving.
      return;
    }

    if (checkFormDirty && isFormDirty) {
      if (
        window.confirm(
          t('You have unsaved changes. Are you sure you want to close?'),
        ) === false
      ) {
        return;
      }
    }

    onClose();
  };

  return (
    <>
      <Dialog
        open={opened}
        fullWidth
        maxWidth={formDefinition.modalMaxWidth ?? 'md'}
        onClose={() => handleModalClose(true)}
        TransitionComponent={Transition}
        sx={theme => ({
          '& .MuiBackdrop-root': { backgroundColor: 'rgba(0, 0, 0, 0.3)' },
          '& .MuiDialog-paper': {
            backgroundColor: customPalette.superLightBlue,
            [theme.breakpoints.down('sm')]: {
              margin: 0,
              width: '100%',
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            },
          },
          '& .MuiDialog-container': {
            [theme.breakpoints.down('sm')]: {
              alignItems: 'end',
            },
          },
        })}
      >
        <Stack
          direction="row"
          component={Paper}
          elevation={3}
          borderRadius={0}
          justifyContent="space-between"
          alignItems="center"
          sx={{
            position: 'sticky',
            top: 0,
            paddingX: 2,
            paddingY: 1,
            zIndex: 2,
          }}
        >
          <DialogTitle sx={{ p: 0 }}>{formDefinition.title}</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => handleModalClose()}
            sx={{
              color: theme => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <RaFormWrapper
          // TODO: Fix the typing error but already spent to much time on it.
          globalDefinition={formDefinition as any}
          setSnackBarDisplayed={setSnackBarDisplayed}
          formContext={formContext}
          handleModalClose={handleModalClose}
          setIsFormDirty={setIsFormDirty}
        />
      </Dialog>
      <Snackbar
        open={snackBarDisplayed}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={5000}
        onClose={() => setSnackBarDisplayed(false)}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: '100%', alignItems: 'center' }}
        >
          {t('Your changes were saved successfully!')}
        </Alert>
      </Snackbar>
    </>
  );
};
