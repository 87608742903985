import type { Field } from '../hooks/url-search-params';
import { makeField } from '../hooks/url-search-params';

type SortBy = string | null;
export type SortDirection = 'asc' | 'desc' | null;

export type Sorting = {
  sortBy: SortBy;
  sortDirection: SortDirection;
  nullsLast?: boolean;
};

export type SortingParams = {
  sortBy: Field<SortBy>;
  sortDirection: Field<SortDirection>;
  nullsLast?: Field<boolean>;
};

export const sortingParams: SortingParams = {
  sortBy: makeField({
    get: params => params.getString('sortBy'),
    set: (params, value) => params.setString('sortBy', value),
  }),
  sortDirection: makeField({
    get: params => {
      const sortDirection = params.getString('sortDirection');
      if (sortDirection === 'asc' || sortDirection === 'desc') {
        return sortDirection;
      } else {
        return null;
      }
    },
    set: (params, value) => params.setString('sortDirection', value),
  }),
  nullsLast: makeField({
    get: params => params.getBoolean('nullsLast') ?? false,
    set: (params, value) => params.setBoolean('nullsLast', value),
  }),
};
