import * as React from 'react';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useResponsive } from 'react-system';

// This is Drag And Drop functionality of Kanban
// No UI/markup or data processing here

export type DragEndArgs = {
  itemId: string;
  srcColumnId: string;
  destColumnId: string;
  srcIndex: number;
  destIndex: number;
};

type OnDragEnd = (args: DragEndArgs) => void;

export const KanbanDND = (props: {
  onDragEnd: OnDragEnd;
  children: React.ReactNode;
}) => (
  <DragDropContext
    onDragEnd={({ source, destination, draggableId }) => {
      // dropped nowhere
      if (!destination) {
        return;
      }

      // haven't moved
      if (
        source.droppableId === destination.droppableId &&
        source.index === destination.index
      ) {
        return;
      }

      props.onDragEnd({
        itemId: draggableId,
        srcColumnId: source.droppableId,
        destColumnId: destination.droppableId,
        srcIndex: source.index,
        destIndex: destination.index,
      });
    }}
  >
    {props.children}
  </DragDropContext>
);

export type RenderItem<Item> = (props: {
  index: number;
  item: Item;
  props: {};
  ref: (element: null | HTMLElement) => void;
  isDragging: boolean;
}) => JSX.Element;

type ColumnProps<Item> = {
  id: string;
  items: Item[];
  getItemId: (item: Item) => string;
  renderItem: RenderItem<Item>;
  renderContainer: (props: {
    children: React.ReactNode;
    ref: (element: null | HTMLElement) => void;
    isDraggingOver: boolean;
  }) => JSX.Element;
};

export const KanbanDNDColumn = <Item extends unknown>(
  props: ColumnProps<Item>,
) => {
  const { items, getItemId, renderItem } = props;

  // Would be better to disable touch sensor instead
  // https://github.com/atlassian/react-beautiful-dnd/issues/1924
  const responsive = useResponsive();
  const isMobile = responsive([true, false]);

  // https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/api/droppable.md#recommended-droppable--performance-optimisation
  const renderedItems = React.useMemo(
    () =>
      items.map((item, index) => {
        const id = getItemId(item);
        return (
          <Draggable
            key={id}
            draggableId={id}
            index={index}
            isDragDisabled={isMobile}
          >
            {(provided, snapshot) =>
              renderItem({
                index,
                item,
                props: {
                  ...provided.draggableProps,
                  ...provided.dragHandleProps,
                },
                ref: provided.innerRef,
                isDragging: snapshot.isDragging,
              })
            }
          </Draggable>
        );
      }),
    [items, getItemId, renderItem, isMobile],
  );

  return (
    <Droppable
      droppableId={props.id}
      renderClone={(provided, snapshot, rubric) =>
        renderItem({
          index: rubric.source.index,
          item: items[rubric.source.index],
          props: { ...provided.draggableProps, ...provided.dragHandleProps },
          ref: provided.innerRef,
          isDragging: snapshot.isDragging,
        })
      }
    >
      {(provided, snapshot) =>
        props.renderContainer({
          children: (
            <>
              {renderedItems}
              {provided.placeholder}
            </>
          ),
          isDraggingOver: snapshot.isDraggingOver,
          ref: provided.innerRef,
        })
      }
    </Droppable>
  );
};
