import { useCallback } from 'react';

import { Box, List, ListItem, Skeleton, Typography } from '@mui/material';

import { useLocale } from '../../src/hooks/locale';
import type { GetListingDetailsData } from '../pages/listings/lotsQueries';

type TProperty = NonNullable<GetListingDetailsData['property']>;

export type TFieldsDefinition<TKey extends keyof TProperty> = {
  [K in TKey]: {
    name: K;
    label: string;
    shouldRender?: (property: TProperty) => boolean;
    renderFn?: (value: TProperty[K]) => React.ReactNode;
  };
};

interface PropertyDataListProps<TKey extends keyof TProperty> {
  property?: TProperty | null;
  fieldDefinition: TFieldsDefinition<TKey>;
  title: string;
}

export const PropertyDataListSkeleton: React.FC<{ title?: string | null }> = ({
  title,
}) => {
  return (
    <Box flex={1}>
      <Typography variant="h6" sx={{ fontWeight: 700 }}>
        {title || <Skeleton width={150} />}
      </Typography>
      <List sx={{ columnCount: { xs: 1, md: 2 }, columnGap: 5, py: 0.5 }}>
        {Array.from({ length: 7 }).map((_, index) => (
          <ListItem
            divider
            sx={{ justifyContent: 'space-between', width: 'auto' }}
            disableGutters
            key={index}
          >
            <Typography variant="body2">
              <Skeleton width={150} />
            </Typography>
            <Typography variant="body2">
              <Skeleton width={70} />
            </Typography>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export const PropertyDataList = <TKey extends keyof TProperty>({
  property,
  fieldDefinition,
  title,
}: PropertyDataListProps<TKey>) => {
  const renderField = useCallback(
    (fieldDef: TFieldsDefinition<TKey>[TKey]) => {
      const value = property?.[fieldDef.name];

      if (fieldDef.renderFn) {
        return fieldDef.renderFn(value);
      }

      return value == null ? '' : value.toString();
    },
    [property],
  );

  const { t } = useLocale();
  const areAllFieldsEmpty = Object.values<TFieldsDefinition<TKey>[TKey]>(
    fieldDefinition,
  ).every(field => property?.[field.name] == null);

  return (
    <Box flex={1}>
      <Typography variant="h6" sx={{ fontWeight: 700, mb: 0.5, mt: 1 }}>
        {title}
      </Typography>
      {property == null || areAllFieldsEmpty ? (
        <Typography variant="body2" sx={{ mt: 1 }}>
          {t('none')}
        </Typography>
      ) : (
        <List
          sx={{ columnCount: { xs: 1, md: 2 }, columnGap: 5, pt: 0, pb: 0.5 }}
        >
          {Object.values<TFieldsDefinition<TKey>[TKey]>(fieldDefinition).map(
            field =>
              (!field.shouldRender || field.shouldRender(property)) &&
              property[field.name] != null && (
                <ListItem
                  divider
                  sx={{ justifyContent: 'space-between', width: 'auto' }}
                  disableGutters
                  key={field.name}
                >
                  <Typography variant="body2" sx={{ fontWeight: 500 }}>
                    {field.label}
                  </Typography>
                  <Typography variant="body2" sx={{ textAlign: 'right' }}>
                    {renderField(field)}
                  </Typography>
                </ListItem>
              ),
          )}
        </List>
      )}
    </Box>
  );
};
