/**
 * @generated SignedSource<<925c3b0299ade0e85d7a7174344da258>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
export type TypeOfPlace = "agglomeration" | "country" | "district" | "locality" | "municipality" | "neighbourhood" | "postcode" | "region" | "state";
import type { FragmentType } from "relay-runtime";
declare export opaque type placeListItem_place$fragmentType: FragmentType;
export type placeListItem_place$data = {|
  +country: ?{|
    +label: string,
  |},
  +label: string,
  +municipality: ?{|
    +label: string,
  |},
  +state: ?{|
    +label: string,
  |},
  +type: TypeOfPlace,
  +$fragmentType: placeListItem_place$fragmentType,
|};
export type placeListItem_place$key = {
  +$data?: placeListItem_place$data,
  +$fragmentSpreads: placeListItem_place$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "placeListItem_place",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": "country",
      "args": [
        {
          "kind": "Literal",
          "name": "type",
          "value": "country"
        }
      ],
      "concreteType": "Place",
      "kind": "LinkedField",
      "name": "parentPlace",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": "parentPlace(type:\"country\")"
    },
    {
      "alias": "state",
      "args": [
        {
          "kind": "Literal",
          "name": "type",
          "value": "state"
        }
      ],
      "concreteType": "Place",
      "kind": "LinkedField",
      "name": "parentPlace",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": "parentPlace(type:\"state\")"
    },
    {
      "alias": "municipality",
      "args": [
        {
          "kind": "Literal",
          "name": "type",
          "value": "municipality"
        }
      ],
      "concreteType": "Place",
      "kind": "LinkedField",
      "name": "parentPlace",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": "parentPlace(type:\"municipality\")"
    }
  ],
  "type": "Place",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "d5f54a8b535acb43c7678d13fe340454";

export default node;
