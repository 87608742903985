/**
 * @generated SignedSource<<fd90907eab8f095f5276a6f8b7c558ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LeadAppraisalReason = "already_for_sale" | "buy_soon" | "buy_this" | "inheritance" | "move_rental" | "not_selling" | "not_set" | "other" | "other_not_owner" | "other_owner" | "other_renting" | "refinance" | "sell" | "separation";
export type LeadBuyHorizon = "not_set" | "over_12_months" | "under_12_months" | "under_3_months";
export type LeadRelationship = "agent" | "buyer" | "heir" | "not_set" | "other" | "owner" | "tenant";
export type LeadSaleHorizon = "already_for_sale" | "from_1_to_2_years" | "from_2_years" | "from_6_to_12_months" | "not_selling" | "not_set" | "under_6_months";
export type SortDirection = "asc" | "desc";
export type LeadsFilters = {
  appraisalNextStep_in?: ReadonlyArray<string | null> | null;
  appraisalOpenToOffer_in?: ReadonlyArray<string | null> | null;
  appraisalPerception_in?: ReadonlyArray<string | null> | null;
  appraisalPropertyOccupied_in?: ReadonlyArray<string | null> | null;
  appraisalPropertyUsage_in?: ReadonlyArray<string | null> | null;
  appraisalReason_in?: ReadonlyArray<LeadAppraisalReason> | null;
  appraisalValue_gte?: number | null;
  appraisalValue_lte?: number | null;
  brokerId_in?: ReadonlyArray<string | null> | null;
  buyHorizon_in?: ReadonlyArray<LeadBuyHorizon> | null;
  claimedBy_eq?: string | null;
  completed_in?: ReadonlyArray<boolean> | null;
  contactId_in?: ReadonlyArray<string | null> | null;
  contacts_assets_gte?: number | null;
  contacts_assets_lte?: number | null;
  contacts_income_gte?: number | null;
  contacts_income_lte?: number | null;
  createdAt_inRange?: DateRange | null;
  createdBy_in?: ReadonlyArray<string | null> | null;
  id_in?: ReadonlyArray<string | null> | null;
  leadLostId_in?: ReadonlyArray<string | null> | null;
  mandateProbability_in?: ReadonlyArray<number | null> | null;
  mortgageBuyingStatus_in?: ReadonlyArray<string | null> | null;
  mortgagePropertyValue_gte?: number | null;
  mortgagePropertyValue_lte?: number | null;
  mortgageStatus_in?: ReadonlyArray<string | null> | null;
  mortgage_type_in?: ReadonlyArray<string | null> | null;
  nextActivity_exists?: boolean | null;
  nextActivity_overdue?: boolean | null;
  nextActivity_type_in?: ReadonlyArray<string> | null;
  organisationId_in?: ReadonlyArray<string | null> | null;
  pipelineId_eq?: string | null;
  potentialDevelopment_eq?: boolean | null;
  predictedListingDate_inRange?: DateRange | null;
  property_commercialSurface_gte?: number | null;
  property_commercialSurface_lte?: number | null;
  property_googleAddress_lat_gte?: number | null;
  property_googleAddress_lat_lte?: number | null;
  property_googleAddress_lng_gte?: number | null;
  property_googleAddress_lng_lte?: number | null;
  property_landSurface_gte?: number | null;
  property_landSurface_lte?: number | null;
  property_livingSurface_gte?: number | null;
  property_livingSurface_lte?: number | null;
  property_placeId_in?: ReadonlyArray<string> | null;
  property_propertyType_name_in?: ReadonlyArray<string | null> | null;
  property_residentialSurface_gte?: number | null;
  property_residentialSurface_lte?: number | null;
  receivedMortgageOffer_in?: ReadonlyArray<boolean | null> | null;
  relationship_in?: ReadonlyArray<LeadRelationship> | null;
  saleHorizon_in?: ReadonlyArray<LeadSaleHorizon> | null;
  sourceId_in?: ReadonlyArray<string | null> | null;
  stageId_in?: ReadonlyArray<string | null> | null;
  stageName_in?: ReadonlyArray<string | null> | null;
  status_in?: ReadonlyArray<string | null> | null;
  tags_in?: ReadonlyArray<string | null> | null;
};
export type DateRange = {
  endDate?: string | null;
  mostRecent?: boolean | null;
  startDate?: string | null;
  useRequalifiedIfAvailable?: boolean | null;
};
export type LeadsAllQuery$variables = {
  count: number;
  filters?: LeadsFilters | null;
  sortBy?: string | null;
  sortDirection?: SortDirection | null;
};
export type LeadsAllQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"LeadsAll_root">;
};
export type LeadsAllQuery = {
  response: LeadsAllQuery$data;
  variables: LeadsAllQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortBy"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortDirection"
  }
],
v1 = {
  "kind": "Variable",
  "name": "filters",
  "variableName": "filters"
},
v2 = {
  "kind": "Variable",
  "name": "sortBy",
  "variableName": "sortBy"
},
v3 = {
  "kind": "Variable",
  "name": "sortDirection",
  "variableName": "sortDirection"
},
v4 = [
  (v1/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v2/*: any*/),
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LeadsAllQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "LeadsAll_root"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LeadsAllQuery",
    "selections": [
      {
        "alias": "leadIds",
        "args": (v4/*: any*/),
        "concreteType": "LeadConnectionConnection",
        "kind": "LinkedField",
        "name": "leads",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LeadConnectionEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Lead",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "leadIds",
        "args": (v4/*: any*/),
        "filters": [],
        "handle": "connection",
        "key": "Connection_leadIds",
        "kind": "LinkedHandle",
        "name": "leads"
      }
    ]
  },
  "params": {
    "cacheID": "db987ce98d87badb67f1c70e9229c39b",
    "id": null,
    "metadata": {},
    "name": "LeadsAllQuery",
    "operationKind": "query",
    "text": "query LeadsAllQuery(\n  $count: Int!\n  $filters: LeadsFilters\n  $sortBy: String\n  $sortDirection: SortDirection\n) {\n  ...LeadsAll_root_n2fSB\n}\n\nfragment LeadsAll_root_n2fSB on Query {\n  leadIds: leads(first: $count, sortBy: $sortBy, sortDirection: $sortDirection, filters: $filters) {\n    edges {\n      node {\n        id\n        __typename\n      }\n      cursor\n    }\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a3cb593d09922232175164fc5ab0c470";

export default node;
