// Generated from src/svgs/password-appear-outline.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const PasswordAppearOutline = ({ size, fill, className }: Props) => {
  return (
    <svg viewBox="0 0 25 25" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><path fillRule="evenodd" clipRule="evenodd" d="M12 2.5v3a.5.5 0 0 0 1 0v-3a.5.5 0 0 0-1 0ZM6.25 5.067a.5.5 0 0 0-.183.683l1 1.731a.5.5 0 0 0 .866-.5l-1-1.731a.5.5 0 0 0-.683-.183ZM12.5 8C5.596 8 0 14.621 0 15.5c0 .879 5.596 7.5 12.5 7.5S25 16.437 25 15.5c0-.938-5.596-7.5-12.5-7.5Zm0 14c-5.914 0-10.654-5.224-11.429-6.5C1.846 14.224 6.586 9 12.5 9c5.72 0 10.683 5.101 11.444 6.5C23.183 16.9 18.22 22 12.5 22ZM9 15.5a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0Zm3.5 2.5a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5Zm4.567-11.019 1-1.731a.5.5 0 0 1 .866.5l-1 1.731a.5.5 0 0 1-.866-.5Z" /></svg>
  );
}
