export const RealAdvisorLogo = ({ style }: { style?: React.CSSProperties }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    style={style}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 828 140"
  >
    <path
      style={{ fill: '#FFFFFF' }}
      d="M226.76,64.06h-22v-23.6h22c8.59,0,12.71,4.81,12.71,11.8c0,6.99-4.12,11.79-12.71,11.79V64.06z
	 M253.45,52.26c0-14.2-8.82-24.63-26.8-24.63h-35.74V109h13.86V76.88h18.67L239.93,109h16.27l-18.67-33.83
	C248.3,71.73,253.45,63,253.45,52.26"
    />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M276.36,73.13c1.37-8.13,7.1-13.86,15.92-13.86c8.82,0,14.55,5.59,15.72,13.83L276.36,73.13z
	 M292.28,48.13c-17.64,0-29.55,13.75-29.55,31c0,17.75,11.68,31.16,29.67,31.16c13.86,0,24.4-8.36,27.95-20.16H306.6
	c-2.18,5.27-7.56,8.48-13.86,8.48c-9.51,0-15.58-6.07-16.61-15.46h45.24c0,0,0.11-2.06,0.11-4.24
	C321.49,61.53,309.92,48.13,292.28,48.13"
    />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M370.63,84.78c0,8-5.38,14.89-16.38,14.89c-6.53,0-10.54-2.18-10.54-7.79
	c0-4.12,2.75-7.22,11.11-8.48l15.81-2.4V84.78z M358.26,48.13c-18,0-25.08,9.62-26.23,20h13.29c0.92-5.5,5.15-8.82,12.6-8.82
	c8.13,0,12.71,3.66,12.71,10.88v0.92l-17.18,2.41c-15.69,2.18-22.79,8.71-22.79,18.78c0,10.07,6.07,18,20.85,18
	c9.51,0,16.38-3.09,20.39-9.16V109h12V70.35C383.92,56.72,375.67,48.13,358.26,48.13"
    />
    <rect
      x="395.07"
      y="23.05"
      style={{ fill: '#FFFFFF' }}
      width="13.63"
      height="85.91"
    />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M442.03,80.63l13.52-35.85h0.23l13.52,35.85H442.03z M448.44,27.63L416.95,109h14.66l5.84-15.69
	h36.42l5.84,15.69h15.12l-31.61-81.37H448.44z"
    />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M528.4,98.3c-10.31,0-16.72-8.36-16.72-19c0-10.88,6.41-19.13,16.72-19.13
	c10.31,0,16.72,8.25,16.72,19.13C545.12,89.94,538.82,98.3,528.4,98.3 M544.55,57.3c-3.32-5-9.74-9.16-19-9.16
	C508,48.13,498,62.22,498,79.29c0,17.07,10,31,27.49,31c9.28,0,16.27-3.67,20-9.85V109h12.6V23h-13.54V57.3z"
    />
    <polygon
      style={{ fill: '#FFFFFF' }}
      points="597.81,92.8 581.54,49.51 567.11,49.51 591.28,108.95 604.11,108.95 628.39,49.51
	614.3,49.51 "
    />
    <rect
      x="637.21"
      y="49.51"
      style={{ fill: '#FFFFFF' }}
      width="13.63"
      height="59.45"
    />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M637.91,25c-1.63,1.63-2.51,3.88-2.41,6.18c0,4.92,3.55,8.36,8.59,8.36
	c2.26,0.11,4.46-0.73,6.08-2.32c1.61-1.59,2.48-3.78,2.41-6.04c0.11-2.29-0.75-4.52-2.36-6.15c-1.61-1.63-3.83-2.52-6.12-2.44
	c-0.12-0.01-0.24-0.01-0.36-0.01C641.55,22.58,639.46,23.45,637.91,25"
    />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M678.45,66.35c0-4.35,3.44-7.1,10.19-7.1c7,0,10.19,4.35,10.65,8.59h13.06
	c-0.57-8-6.3-19.7-23.37-19.7c-14.66,0-23.6,8.71-23.6,18.67c0,24.17,34.13,12.94,34.13,25.54c0,4.12-3.89,6.76-11,6.76
	c-8.36,0-12.48-4-12.83-9.16h-13.15c0.69,10.88,7.79,20.39,25.2,20.39c16.61,0,24.86-8.48,24.86-18.56
	C712.58,67.72,678.45,77.91,678.45,66.35"
    />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M751.76,98.3c-9.85,0-17.18-7.56-17.18-19s7.33-19.13,17.18-19.13c9.85,0,17.3,7.56,17.3,19.13
	C769.06,90.87,761.72,98.3,751.76,98.3 M751.76,48.13c-18.9,0-30.81,14.43-30.81,31.16s11.91,31,30.81,31
	c18.9,0,30.93-14.32,30.93-31S770.77,48.13,751.76,48.13"
    />
    <polygon
      style={{ fill: '#FFFFFF' }}
      points="795.28,49.51 795.28,108.95 808.91,108.95 808.91,62.34 827.59,62.34 827.59,49.51 "
    />
    <path
      style={{ fill: 'none' }}
      d="M50.09,56.82c0.95-3.24,3.48-5.77,6.72-6.72l46.88-13.79L89.9,83.18c-0.95,3.24-3.48,5.77-6.72,6.72
	l-46.87,13.79L50.09,56.82z M70,0C31.34,0,0,31.34,0,70s31.34,70,70,70s70-31.34,70-70c0-18.56-7.38-36.37-20.5-49.5
	C106.37,7.38,88.56,0,70,0z"
    />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M50.09,56.82c0.95-3.24,3.48-5.77,6.72-6.72l46.88-13.79L89.9,83.18c-0.95,3.24-3.48,5.77-6.72,6.72
	l-46.87,13.79L50.09,56.82z M0,70c0,38.66,31.34,70,70,70s70-31.34,70-70c0-18.56-7.38-36.37-20.5-49.5C106.37,7.38,88.56,0,70,0
	C31.34,0,0,31.34,0,70"
    />
  </svg>
);
