import { FormControl, FormHelperText, FormLabel } from '@mui/material';
import { DateField, type DateFieldProps } from '@mui/x-date-pickers-pro';
import {
  type Control,
  Controller,
  type UseFormGetValues,
  type UseFormSetValue,
} from 'react-hook-form';

import { useLocale } from '../../../src/hooks/locale';

export type RaDateProps = Omit<
  DateFieldProps<Date | null>,
  'value' | 'onChange' | 'variant' | 'size' | 'InputProps' | 'name'
> & {
  control: Control<any>;
  getValues: UseFormGetValues<any>;
  setValue: UseFormSetValue<any>;
  name: string;
  label: string;
};

export const RaDate: React.FC<RaDateProps> = ({
  getValues,
  setValue,
  control,
  name,
  label,
  required = false,
  ...dateFieldProps
}) => {
  const date = getValues(name) ? new Date(getValues(name)) : null;

  const handleChange = (date: Date | null) => {
    try {
      setValue(name, date?.toISOString(), { shouldDirty: true });
    } catch (error) {
      console.error('error', error);
    }
  };

  const { t } = useLocale();
  const rules = {
    required: required ? t('This field is required') : undefined,
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ fieldState: { error } }) => (
        <FormControl fullWidth error={!!error}>
          <FormLabel style={{ marginBottom: '0.5rem', fontWeight: 500 }}>
            {label}
          </FormLabel>
          <DateField
            value={date}
            onChange={handleChange}
            variant="outlined"
            size="small"
            InputProps={{
              sx: { background: 'white' },
            }}
            {...dateFieldProps}
          />
          <FormHelperText>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};
