/**
 * @generated SignedSource<<a802155b9b48a9c49aa3d4b03036de70>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type LeadDetailsMap_property$fragmentType: FragmentType;
export type LeadDetailsMap_property$data = {|
  +googleAddress: ?{|
    +lat: ?number,
    +lng: ?number,
  |},
  +id: string,
  +lat: ?number,
  +lng: ?number,
  +$fragmentType: LeadDetailsMap_property$fragmentType,
|};
export type LeadDetailsMap_property$key = {
  +$data?: LeadDetailsMap_property$data,
  +$fragmentSpreads: LeadDetailsMap_property$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lat",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lng",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LeadDetailsMap_property",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "GoogleAddress",
      "kind": "LinkedField",
      "name": "googleAddress",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Property",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "60d031245d0fb543b9a013683558c7f5";

export default node;
