/**
 * @generated SignedSource<<0eb5111b95cb624369f7e7fbc4f15652>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
export type LeadAppraisalInheritanceNextStep = "buy" | "sell" | "share";
export type LeadAppraisalReason = "already_for_sale" | "buy_soon" | "buy_this" | "inheritance" | "move_rental" | "not_selling" | "not_set" | "other" | "other_not_owner" | "other_owner" | "other_renting" | "refinance" | "sell" | "separation";
export type LeadAppraisalSeparationNextStep = "buy" | "sell" | "share";
export type LeadBuyHorizon = "not_set" | "over_12_months" | "under_12_months" | "under_3_months";
export type LeadMortgageBuyingStatus = "identified" | "offer" | "searching" | "visited";
export type LeadMortgageStatus = "not_started" | "working_with_bank" | "working_with_independent";
export type LeadOwnership = "co_ownership" | "corporate_ownership" | "sole_ownership";
export type LeadRelationship = "agent" | "buyer" | "heir" | "not_set" | "other" | "owner" | "tenant";
export type LeadSaleHorizon = "already_for_sale" | "from_1_to_2_years" | "from_2_years" | "from_6_to_12_months" | "not_selling" | "not_set" | "under_6_months";
import type { FragmentType } from "relay-runtime";
declare export opaque type leadQualificationFunnel_lead$fragmentType: FragmentType;
export type leadQualificationFunnel_lead$data = {|
  +appraisalInheritanceNextStep: ?LeadAppraisalInheritanceNextStep,
  +appraisalMarketTime: ?number,
  +appraisalMonthlyGrossRent: ?number,
  +appraisalNextStep: ?string,
  +appraisalOpenToOffer: ?string,
  +appraisalPerceivedValue: ?number,
  +appraisalPerception: ?string,
  +appraisalReason: LeadAppraisalReason,
  +appraisalSellingWith: ?string,
  +appraisalSeparationNextStep: ?LeadAppraisalSeparationNextStep,
  +buyHorizon: LeadBuyHorizon,
  +mortgageBuyingStatus: ?LeadMortgageBuyingStatus,
  +mortgageStatus: ?LeadMortgageStatus,
  +mortgageTerm: ?string,
  +ownership: ?LeadOwnership,
  +property: ?{|
    +countryCode: ?string,
    +propertyType: ?{|
      +name: ?string,
    |},
  |},
  +receivedMortgageOffer: ?boolean,
  +relationship: LeadRelationship,
  +saleHorizon: LeadSaleHorizon,
  +$fragmentType: leadQualificationFunnel_lead$fragmentType,
|};
export type leadQualificationFunnel_lead$key = {
  +$data?: leadQualificationFunnel_lead$data,
  +$fragmentSpreads: leadQualificationFunnel_lead$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "leadQualificationFunnel_lead",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalReason",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalOpenToOffer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalSellingWith",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalMarketTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalNextStep",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalMonthlyGrossRent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalPerceivedValue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalSeparationNextStep",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalInheritanceNextStep",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "saleHorizon",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalPerception",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buyHorizon",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ownership",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "relationship",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mortgageTerm",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mortgageBuyingStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mortgageStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "receivedMortgageOffer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Property",
      "kind": "LinkedField",
      "name": "property",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "countryCode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PropertyType",
          "kind": "LinkedField",
          "name": "propertyType",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Lead",
  "abstractKey": null
};

(node/*: any*/).hash = "01229159957ebea13aa1907edece6da6";

export default node;
