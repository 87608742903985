/**
 * @generated SignedSource<<e70060d0c1d3e24e0765716afb830fc6>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
type propertyCardPlot_location$fragmentType = any;
export type propertyCardPlotQuery$variables = {|
  id: string,
|};
export type propertyCardPlotQuery$data = {|
  +property: ?{|
    +location?: ?{|
      +$fragmentSpreads: propertyCardPlot_location$fragmentType,
    |},
  |},
|};
export type propertyCardPlotQuery = {|
  response: propertyCardPlotQuery$data,
  variables: propertyCardPlotQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "propertyCardPlotQuery",
    "selections": [
      {
        "alias": "property",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Location",
                "kind": "LinkedField",
                "name": "location",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "propertyCardPlot_location"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Property",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "propertyCardPlotQuery",
    "selections": [
      {
        "alias": "property",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Location",
                "kind": "LinkedField",
                "name": "location",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Plot",
                    "kind": "LinkedField",
                    "name": "plot",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "localityId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "plotId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "surface",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "landRegistryURL",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Zone",
                    "kind": "LinkedField",
                    "name": "zone",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ZoneDefinition",
                        "kind": "LinkedField",
                        "name": "definition",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cantonZoneName",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Property",
            "abstractKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1b95a97ba64fa44331e1028632755db5",
    "id": null,
    "metadata": {},
    "name": "propertyCardPlotQuery",
    "operationKind": "query",
    "text": "query propertyCardPlotQuery(\n  $id: ID!\n) {\n  property: node(id: $id) {\n    __typename\n    ... on Property {\n      location {\n        ...propertyCardPlot_location\n      }\n    }\n    id\n  }\n}\n\nfragment propertyCardPlot_location on Location {\n  plot {\n    localityId\n    plotId\n    surface\n    landRegistryURL\n    id\n  }\n  zone {\n    definition {\n      cantonZoneName\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "288a506e0ea3633b02a68c3ead7a6247";

export default node;
