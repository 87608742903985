import * as React from 'react';

import type { CSSInterpolation } from '@emotion/serialize';
import { Box, Flex } from 'react-system';

import { useTheme } from '../../hooks/theme';

// This is a UI/markup for Kanban
// No DnD logic or data management here

const TITLE_HEIGHT = 48;
const ARR_HEIGHT = TITLE_HEIGHT / Math.sqrt(2);
const PADDING = 6;

export const KanbanLayout = (props: { children: React.ReactNode }) => {
  const { colors } = useTheme();
  return (
    <Box flexGrow={1} css={{ position: 'relative' }}>
      <Flex
        css={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          maxWidth: '100%',
          overflowY: 'hidden',
          overflowX: 'auto',
          borderRight: `1px solid ${colors.grey300}`,
        }}
      >
        {props.children}
      </Flex>
    </Box>
  );
};

const Title = ({
  children,
  isFirstColumn,
}: {
  children: React.ReactNode;
  isFirstColumn: boolean;
}) => {
  const { colors, text } = useTheme();

  const border = `1px solid ${colors.grey300}`;

  const arrow = {
    content: '""',
    position: 'absolute',
    borderTop: border,
    borderRight: border,
    left: -ARR_HEIGHT / 1.8 + 2,
    top: (TITLE_HEIGHT - ARR_HEIGHT) / 2 - 1,
    transformOrigin: `${ARR_HEIGHT / 2}px ${ARR_HEIGHT / 2}px`,
    width: ARR_HEIGHT,
    height: ARR_HEIGHT,
    transform: 'scale(0.55, 1) rotateZ(45deg)',
  } as CSSInterpolation;

  return (
    <Flex
      alignItems="center"
      pl={4}
      pr={2}
      css={[
        {
          height: TITLE_HEIGHT,
          minHeight: TITLE_HEIGHT,
          position: 'relative',
          overflow: 'hidden',
          borderTop: border,
          borderBottom: border,
          background: colors.white,
          '&:before': isFirstColumn ? null : arrow,
        },
        text.subtitle2,
      ]}
    >
      {children}
    </Flex>
  );
};

export const KanbanColumn = React.forwardRef<
  HTMLElement | null,
  {
    width?: number;
    label: React.ReactNode;
    topControls?: React.ReactNode;
    index: number;
    children: React.ReactNode;
  }
>(({ children, label, width = 336, index, topControls }, ref) => {
  const { colors } = useTheme();
  const isFirstColumn = index === 0;
  const borderLeft = isFirstColumn ? undefined : `1px solid ${colors.grey300}`;
  const widthWithBorder = borderLeft == null ? width : width + 1;
  const background = colors.grey50;

  return (
    <Flex
      flexDirection="column"
      flexGrow={1}
      css={{ maxWidth: widthWithBorder, minWidth: widthWithBorder }}
    >
      <Title isFirstColumn={isFirstColumn}>{label}</Title>
      <Flex
        flexGrow={1}
        flexDirection="column"
        css={{ background, borderLeft }}
      >
        {topControls != null && (
          <Box
            flexShrink={0}
            css={{ borderBottom: `1px solid ${colors.grey300}` }}
          >
            {topControls}
          </Box>
        )}
        <Box
          flexGrow={1}
          ref={ref}
          css={{
            overflowX: 'hidden',
            overflowY: 'auto',
            paddingTop: PADDING,
            paddingLeft: PADDING,

            // In WebKit we customize scrollbar,
            // in other engines just hide it
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': { width: PADDING, background },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: 3,
              background: colors.grey300,
              border: `1px solid ${background}`,
            },
            '&::-webkit-scrollbar-track': { background },
          }}
        >
          <div css={{ width: width - PADDING * 2 }}>{children}</div>
        </Box>
      </Flex>
    </Flex>
  );
});

export const KanbanItem = React.forwardRef<
  HTMLElement | null,
  { isDragging: boolean; children: React.ReactNode }
>(({ isDragging, children, ...props }, ref) => {
  const { colors, borderRadius } = useTheme();
  return (
    <Box
      {...props}
      ref={ref}
      css={{
        border: `1px solid ${isDragging ? colors.blue300 : colors.grey300}`,
        overflow: 'hidden',
        background: colors.white,
        borderRadius: borderRadius.medium,
        marginBottom: PADDING,
        boxShadow: isDragging ? `0 0 0 1px ${colors.blue300}` : 'none',

        // we don't support moving cards with keyboard well currently
        // (react-beautiful-dnd does support it to some extent)
        '&:focus': { outline: 'none' },
      }}
    >
      {children}
    </Box>
  );
});
