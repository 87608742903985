/**
 * @generated SignedSource<<5afbc2cfac548842f50f886dd6a8e376>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Feed_lead$data = {
  readonly __typename: "Lead";
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"FeedEmailForm_lead" | "FeedNewForm_lead">;
  readonly " $fragmentType": "Feed_lead";
};
export type Feed_lead$key = {
  readonly " $data"?: Feed_lead$data;
  readonly " $fragmentSpreads": FragmentRefs<"Feed_lead">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Feed_lead",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeedNewForm_lead"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeedEmailForm_lead"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Lead",
  "abstractKey": null
};

(node as any).hash = "35c55acd6a47e0529997806833f8c1c0";

export default node;
