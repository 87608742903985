import * as React from 'react';

const useLayoutEffect =
  typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect;

type PanParams = {
  onStart: (value: number) => void;
  onMove: (value: number) => void;
  onEnd: () => void;
  ref: { current: null | HTMLDivElement };
};

type TapParams = {
  onTap: (value: number) => void;
};

type PanHandlers = {
  onMouseDown: (event: React.MouseEvent<HTMLElement>) => void;
};

export const usePan = (params: PanParams): PanHandlers => {
  const [started, setStarted] = React.useState(false);

  const onStartRef = React.useRef(params.onStart);
  const onMoveRef = React.useRef(params.onMove);
  const onEndRef = React.useRef(params.onEnd);

  useLayoutEffect(() => {
    onStartRef.current = params.onStart;
    onMoveRef.current = params.onMove;
    onEndRef.current = params.onEnd;
  });

  const divElt = params.ref.current;

  React.useEffect(() => {
    let started = false;

    if (divElt != null) {
      const moveTouch = (event: TouchEvent) => {
        if (!started) {
          onStartRef.current(event.touches[0].clientX);
          started = true;
        }

        // Break scroll in browser
        if (event.cancelable === true) {
          event.preventDefault();
        }

        onMoveRef.current(event.touches[0].clientX);
      };

      const end = () => {
        started = false;
        onEndRef.current();
      };

      divElt.addEventListener('touchmove', moveTouch, {
        // we use non passive event here as we need to be able to break scroll on safari
        passive: false,
      });
      divElt.addEventListener('touchend', end);
      divElt.addEventListener('touchcancel', end);

      return () => {
        divElt.removeEventListener('touchmove', moveTouch);
        divElt.removeEventListener('touchend', end);
        divElt.removeEventListener('touchcancel', end);
      };
    }
  }, [divElt]);

  React.useEffect(() => {
    // add style once if many slider instances are used
    if (document.querySelector('style[data-realadvisor-gestures]') == null) {
      const disableSelectStyle = `
      .realadvisor-slider-disable-select {
        user-select: none;
        user-drag: none;
      }
    `;
      const styleElement = document.createElement('style');
      styleElement.setAttribute('data-realadvisor-gestures', 'true');
      styleElement.appendChild(document.createTextNode(disableSelectStyle));
      document.head?.appendChild(styleElement);
    }
  }, []);

  React.useEffect(() => {
    if (started) {
      const moveMouse = (event: MouseEvent) => {
        onMoveRef.current(event.clientX);
      };
      const end = () => {
        onEndRef.current();
        setStarted(false);
      };
      const disableSelectStyle = 'realadvisor-slider-disable-select';
      window.document.body.classList.add(disableSelectStyle);
      window.addEventListener('mousemove', moveMouse);
      window.addEventListener('mouseup', end);
      return () => {
        window.document.body.classList.remove(disableSelectStyle);
        window.removeEventListener('mousemove', moveMouse);
        window.removeEventListener('mouseup', end);
      };
    }
  }, [started]);

  const onMouseDown = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      onStartRef.current(event.clientX);
      setStarted(true);
    },
    [],
  );

  return {
    onMouseDown,
  };
};

type ClickHandlers = {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
};

export const useClick = ({ onTap }: TapParams): ClickHandlers => {
  const onClick = React.useCallback(
    ({ clientX }: React.MouseEvent<HTMLElement>) => onTap(clientX),
    [onTap],
  );

  return {
    onClick,
  };
};
