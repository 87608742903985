import * as React from 'react';

import {
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { Button } from '@mui/material';
import { endOfDay, formatDistance, isBefore } from 'date-fns';
import { graphql, useFragment, useMutation } from 'react-relay';
import type { Disposable } from 'relay-runtime';

import { RelayHasuraWrapper } from '../../../networking';
import { LeadRefusedReasons } from '../../../shared/lead-refused-reasons';
import { Markdown } from '../../controls/markdown';
import { useLocale } from '../../hooks/locale';
import { useTheme } from '../../hooks/theme';
import { NoteOutline } from '../../icons/note-outline';
import { LeadClaimDialog } from '../../shared/lead-claim-dialog';

import type { FeedAssignment_activity$key } from './__generated__/FeedAssignment_activity.graphql';
import type { FeedAssignmentClaimLead$key } from './__generated__/FeedAssignmentClaimLead.graphql';
import type { FeedAssignmentClaimLeadMutation } from './__generated__/FeedAssignmentClaimLeadMutation.graphql';
import type { FeedAssignmentRefuseLeadMutation } from './__generated__/FeedAssignmentRefuseLeadMutation.graphql';
import { FeedOverdue } from './FeedOverdue';
import { UserName } from './UserName';

type Props = {
  activity: FeedAssignment_activity$key;
  indirect: boolean;
  onCreate: (evtArgs?: { trigger: string }) => void;
};

type PassDialogProps = {
  leadId: string;
  open: boolean;
  onClose: () => void;
  onPass?: () => void;
};

export const PassDialog = ({
  leadId,
  open,
  onClose,
  onPass,
}: PassDialogProps) => {
  const { t } = useLocale();
  const [refuseLead] = useMutation<FeedAssignmentRefuseLeadMutation>(
    graphql`
      mutation FeedAssignmentRefuseLeadMutation($input: RefuseLeadInput!) {
        refuseLead(input: $input) {
          lead {
            id
            broker {
              id
            }
            userCanViewLeadDetails
            hasOpenActivityForMe
            stage {
              id
              orderNumber
              pipeline {
                id
                name
                stages {
                  orderNumber
                  id
                  name
                  label
                }
              }
            }
          }
        }
      }
    `,
  );

  const onSelect = (note: string) => {
    onClose();
    refuseLead({
      variables: {
        input: {
          id: leadId,
          note,
        },
      },
      onCompleted: onPass,
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('passLeadTitle')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('passLeadMessage')}</DialogContentText>
      </DialogContent>
      <RelayHasuraWrapper>
        <LeadRefusedReasons onSelect={onSelect} />
      </RelayHasuraWrapper>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {t('cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const useClaimLead = (
  rootActivity: FeedAssignmentClaimLead$key,
  onClaimed: () => void,
): (() => Disposable | null) => {
  const activity = useFragment(
    graphql`
      fragment FeedAssignmentClaimLead on Activity {
        id
        assignedTo {
          id
        }
        ... on ActivityAssignment {
          lead {
            id
            hasOpenActivityForMe
          }
        }
      }
    `,
    rootActivity,
  );

  const [claimLeadMutation] =
    useMutation<FeedAssignmentClaimLeadMutation>(graphql`
      mutation FeedAssignmentClaimLeadMutation($input: ClaimLeadInput!) {
        claimLead(input: $input) {
          lead {
            id
            userCanViewLeadDetails
            hasOpenActivityForMe
          }
        }
      }
    `);

  const { lead } = activity;

  return () =>
    lead != null
      ? claimLeadMutation({
          variables: { input: { id: lead.id } },
          onCompleted: () => {
            onClaimed();
          },
        })
      : null;
};

export const FeedAssignment = (props: Props) => {
  const { t, dateLocale } = useLocale();
  const { text } = useTheme();
  const [pass, setPass] = React.useState(false);
  const [claim, setClaim] = React.useState(false);

  const activity = useFragment(
    graphql`
      fragment FeedAssignment_activity on Activity {
        id
        ... on ActivityAssignment {
          ...FeedAssignmentClaimLead
          canEdit
          success
          assignedTo {
            ...UserName_user
            id
          }
          note
          done
          doneAt
          dueAt
          startDate
          lead {
            id
            hasOpenActivityForMe
            completed
          }
        }
      }
    `,
    props.activity,
  );

  const claimLead = useClaimLead(activity, props.onCreate);

  const startDate =
    activity.startDate != null ? new Date(activity.startDate) : null;
  const dueAt =
    activity.dueAt != null
      ? new Date(activity.dueAt)
      : startDate != null
      ? endOfDay(startDate)
      : null;
  const isOverdue =
    activity.done === true
      ? false
      : startDate != null
      ? isBefore(endOfDay(startDate), new Date())
      : dueAt != null
      ? isBefore(dueAt, new Date())
      : null;

  const onClaim = () => {
    setClaim(false);
    claimLead();
  };

  return (
    <>
      <CardContent
        css={{
          paddingTop: 0,
          display: 'grid',
          gridGap: 12,
          gridTemplateColumns: '24px 1fr',
        }}
      >
        {dueAt != null && (
          <FeedOverdue
            startDate={startDate}
            isOverdue={isOverdue}
            dueAt={dueAt}
          />
        )}
        {activity.done === true && (
          <>
            <NoteOutline />
            <div css={text.body2}>
              {activity.assignedTo && <UserName user={activity.assignedTo} />}
              {` ${
                activity.success === true ? t('hasAccepted') : t('hasDeclined')
              } ${
                activity.doneAt != null
                  ? formatDistance(new Date(activity.doneAt), Date.now(), {
                      locale: dateLocale,
                      addSuffix: true,
                    })
                  : ''
              }`}
            </div>
          </>
        )}
        {activity.note != null && activity.note.length > 0 && (
          <>
            <NoteOutline />
            <div css={text.body2}>
              <Markdown>{activity.note}</Markdown>
            </div>
          </>
        )}
      </CardContent>
      {activity.canEdit === true &&
        (activity.done === false || activity.done == null) &&
        !props.indirect && (
          <CardActions css={{ justifyContent: 'flex-end', padding: 16 }}>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => setPass(true)}
            >
              {t('passLead')}
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                if (
                  activity.lead?.hasOpenActivityForMe === false &&
                  activity.lead.completed === true
                ) {
                  setClaim(true);
                } else {
                  onClaim();
                }
              }}
            >
              {t('claim')}
            </Button>
          </CardActions>
        )}

      {activity.lead && (
        <PassDialog
          leadId={activity.lead.id}
          open={pass}
          onClose={() => setPass(false)}
          onPass={() => props.onCreate({ trigger: 'passLead' })}
        />
      )}

      <LeadClaimDialog
        open={claim}
        onClaim={onClaim}
        onClose={() => setClaim(false)}
      />
    </>
  );
};
