import { Box } from 'react-system';

import { Checkbox } from '../controls/checkbox';
import { Filter } from '../controls/Filters/Filter';
import { useLocale } from '../hooks/locale';

import type { LeadParamsType } from './lead-types';

type Props = {
  params: LeadParamsType[0];
  setParams: LeadParamsType[1];
};

export const NextActivityOverdueFilter = (props: Props) => {
  const { t } = useLocale();
  const { params, setParams } = props;
  const nextActivity_overdue = params.nextActivity_overdue;

  let label: string;
  if (nextActivity_overdue == null || nextActivity_overdue === true) {
    label = t('overdue');
  } else {
    label = t('notOverdue');
  }

  return (
    <Filter
      label={label}
      dialogTitle={t('nextActivityOverdue')}
      empty={nextActivity_overdue == null}
      onReset={() => setParams({ nextActivity_overdue: null })}
    >
      <Box px={3} py={2}>
        <Checkbox
          color="primary"
          checked={nextActivity_overdue === true}
          onChange={() =>
            setParams({
              nextActivity_overdue: nextActivity_overdue === true ? null : true,
            })
          }
        >
          {t('overdue')}
        </Checkbox>
        <Checkbox
          color="primary"
          checked={nextActivity_overdue === false}
          onChange={() =>
            setParams({
              nextActivity_overdue:
                nextActivity_overdue === false ? null : false,
            })
          }
        >
          {t('notOverdue')}
        </Checkbox>
      </Box>
    </Filter>
  );
};
