// @flow

import * as React from 'react';

import { Tooltip } from '@material-ui/core';
import { graphql, useFragment } from 'react-relay';

import { TimeAgo } from '../components/TimeAgo';
import { useLocale } from '../hooks/locale';
import { useTheme } from '../hooks/theme';
import { abbrDateDifference } from '../utils/abbrDateDifference';

import type { leadUpdatedTimeAgo_lead$key } from './__generated__/leadUpdatedTimeAgo_lead.graphql';

export const LeadUpdatedTimeAgo = ({
  shortVersion = false,
  showBothDate,
  customCreationDate,
  suffixLabel,
  ...props
}: {|
  shortVersion?: ?boolean,
  showBothDate: boolean,
  customCreationDate?: ?string,
  lead: leadUpdatedTimeAgo_lead$key,
  suffixLabel?: ?string,
|}): React.Node => {
  const { text, colors } = useTheme();
  const { t, intlLocale } = useLocale();

  const lead = useFragment(
    graphql`
      fragment leadUpdatedTimeAgo_lead on Lead {
        createdAt
        requalifiedAt
      }
    `,
    props.lead,
  );
  const now = new Date();

  const createdAt = new Date(lead.createdAt);
  const requalifiedAt =
    lead.requalifiedAt != null ? new Date(lead.requalifiedAt) : null;
  const parsedCreatedAt = abbrDateDifference(t, new Date(), createdAt).text;
  const parsedRequalifiedAt =
    requalifiedAt && abbrDateDifference(t, new Date(), requalifiedAt).text;

  const tooltipTitle = (
    <>
      <div>
        {`${t('created')}: ${new Intl.DateTimeFormat(intlLocale, {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        }).format(createdAt)}`}
      </div>
      {requalifiedAt && (
        <div>
          {t('requalifiedAt')}:{' '}
          {new Intl.DateTimeFormat(intlLocale, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          }).format(requalifiedAt)}
        </div>
      )}
    </>
  );

  let dateDisplay = null;

  if (customCreationDate != null) {
    dateDisplay = <TimeAgo addSuffix={true}>{customCreationDate}</TimeAgo>;
  } else if (showBothDate === true && lead.requalifiedAt != null) {
    dateDisplay = (
      <Tooltip title={tooltipTitle} placement="top">
        <div>
          {parsedCreatedAt}
          {` .
              ${
                abbrDateDifference(t, now, new Date(lead.requalifiedAt)).text
              } ♺`}
        </div>
      </Tooltip>
    );
  } else {
    if (shortVersion === true) {
      dateDisplay = (
        <Tooltip title={tooltipTitle} placement="top">
          <div>{parsedRequalifiedAt ?? parsedCreatedAt}</div>
        </Tooltip>
      );
    } else {
      dateDisplay = (
        <TimeAgo addSuffix={true} tooltipTitle={tooltipTitle}>
          {lead.requalifiedAt ?? lead.createdAt}
        </TimeAgo>
      );
    }
  }

  return (
    <div css={text.truncate(1)}>
      {dateDisplay}
      {suffixLabel != null && (
        <div css={[text.truncate(1), { color: colors.grey700 }]}>
          {suffixLabel}
        </div>
      )}
    </div>
  );
};
