/**
 * @generated SignedSource<<e347e6106e87ac11ac35bcd9499a3b4b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeedNewForm_buyerLead$data = {
  readonly __typename: "BuyerLead";
  readonly user: {
    readonly phoneNumbers: ReadonlyArray<{
      readonly createdAt: string;
      readonly number: string;
      readonly primary: boolean | null;
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ActivityForm_buyerLead">;
  readonly " $fragmentType": "FeedNewForm_buyerLead";
};
export type FeedNewForm_buyerLead$key = {
  readonly " $data"?: FeedNewForm_buyerLead$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedNewForm_buyerLead">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedNewForm_buyerLead",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ActivityForm_buyerLead"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PhoneNumber",
          "kind": "LinkedField",
          "name": "phoneNumbers",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "number",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "primary",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "createdAt",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    }
  ],
  "type": "BuyerLead",
  "abstractKey": null
};

(node as any).hash = "d1d3d0be8e2a83b2797d9ea88538888f";

export default node;
