import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { ButtonBase, Typography } from '@mui/material';
import { useFileDialog } from '@realadvisor/hooks';

import { FullScreenDropZone } from '../../shared/dropzone';
import { useLocale } from '../../src/hooks/locale';

export const UploadImageCard: React.FC<{
  onClick: () => void;
  noMinHeight?: boolean;
}> = ({ onClick, noMinHeight = false }) => {
  const { t } = useLocale();
  return (
    <ButtonBase
      onClick={onClick}
      focusRipple={true}
      sx={theme => ({
        border: `2px dashed ${theme.palette.divider}`,
        borderRadius: 1,
        minHeight: noMinHeight ? 0 : '180px',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.05)',
        },
      })}
    >
      <CloudUploadOutlinedIcon sx={{ fontSize: 48, color: 'text.disabled' }} />
      <Typography variant="h6" color="text.disabled">
        {t('Drag or click to upload')}
      </Typography>
    </ButtonBase>
  );
};

type UploadFileCardProps = {
  multiple?: boolean;
  onSelect: (files: File[]) => void;
  noMinHeight?: boolean;
} & (
  | {
      accept?: never;
      acceptLabel?: never;
    }
  | {
      accept: string;
      acceptLabel?: string;
    }
) &
  (
    | {
        allowDrop: false;
        disableDrop?: never;
      }
    | {
        allowDrop?: true;
        disableDrop?: boolean;
      }
  );

export const UploadFileCard: React.FC<UploadFileCardProps> = ({
  onSelect,
  accept,
  multiple = true,
  acceptLabel,
  noMinHeight = false,
  allowDrop = true,
  disableDrop = false,
}) => {
  const { t } = useLocale();
  const openFileDialog = useFileDialog({
    accept,
    onChange: onSelect,
    multiple,
  });

  return (
    <>
      {allowDrop && (
        <FullScreenDropZone
          onDrop={onSelect}
          accept={accept}
          acceptLabel={acceptLabel}
          disabled={disableDrop}
        />
      )}

      <ButtonBase
        onClick={openFileDialog}
        focusRipple={true}
        sx={theme => ({
          border: `2px dashed ${theme.palette.divider}`,
          borderRadius: 1,
          minHeight: noMinHeight ? 0 : '180px',
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
          },
        })}
      >
        <CloudUploadOutlinedIcon
          sx={{ fontSize: 48, color: 'text.disabled' }}
        />
        <Typography variant="h6" color="text.disabled">
          {allowDrop ? t('Drag or click to upload') : t('Click to upload')}
        </Typography>
      </ButtonBase>
    </>
  );
};
