import { Button, CardContent } from '@material-ui/core';
import { graphql, useFragment } from 'react-relay';
// $FlowFixMe[untyped-import]
import { Link } from 'react-router-dom';
import { Box, Flex } from 'react-system';

import { useLocale } from '../../hooks/locale';
import { useTheme } from '../../hooks/theme';
import { AccessTime } from '../../icons/access-time';
import { GroupAdd } from '../../icons/group-add';
import { TimeAgo } from '../TimeAgo';

import type { NewMatch_activity$key } from './__generated__/NewMatch_activity.graphql';

type Props = {
  activity: NewMatch_activity$key;
};

export const NewMatch = (props: Props) => {
  const { text, textColor } = useTheme();
  const { t, locale } = useLocale();
  const { lot, targetUsers, createdAt } = useFragment(
    graphql`
      fragment NewMatch_activity on Activity {
        createdAt
        targetUsers {
          id
        }
        lot {
          id
        }
      }
    `,
    props.activity,
  );

  // d MMMM yyyy HH:mm
  const dateTimeFormat = Intl.DateTimeFormat(locale, {
    dateStyle: 'short',
    timeStyle: 'short',
  });

  return (
    <CardContent css={{ paddingTop: 0 }}>
      <Flex justifyContent="center" alignItems="center">
        <Box mr={2}>
          <GroupAdd />
        </Box>
        <Box css={text.h6}>
          {t('newMatchesCount', { count: targetUsers?.length ?? null })}
        </Box>
      </Flex>
      <Flex justifyContent="center" alignItems="center">
        <Box mr={1}>
          <AccessTime />
        </Box>
        <Box>{dateTimeFormat.format(new Date(createdAt))}</Box>
      </Flex>
      <Flex justifyContent="center" mb={3}>
        <Box css={textColor('mediumText')}>
          (<TimeAgo addSuffix={true}>{createdAt}</TimeAgo>)
        </Box>
      </Flex>
      <Flex justifyContent="center" mb={4}>
        <Box>
          {/* $FlowFixMe[prop-missing] */}
          <Button
            color="primary"
            variant="outlined"
            component={Link}
            to={`/lots/${lot?.id ?? ''}/buyers`}
          >
            {t('viewBuyers')}
          </Button>
        </Box>
      </Flex>
    </CardContent>
  );
};
