import * as React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@material-ui/core';
import { Form, useFormik } from '@realadvisor/form';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { useResponsive } from 'react-system';

import { RelayHasuraWrapper } from '../networking';
import { fromHasuraGlobalId, toGlobalId } from '../shared/global-id';
import { useLocale } from '../src/hooks/locale';

import type { financingLeadLostDialogQuery } from './__generated__/financingLeadLostDialogQuery.graphql';

type FinancingLeadLostDialogProps = {
  open: boolean;
  onClose: () => void;
  onChange?:
    | ((note: string, notifyParticipants: boolean, lostTypeId: string) => void)
    | null;
  onConfirm: (
    note: string,
    notifyParticipants: boolean,
    lostTypeId: string,
  ) => void;
  allowNotifyParticipants?: boolean;
};

type IdLabel = {
  id: string;
  name: string;
  label: string;
};

type FinancingLeadLostTypeSelectProps = {
  value: IdLabel | null;
  error: boolean;
  onChange: (value: IdLabel) => void;
};

type State = {
  note: string;
  notifyParticipants: boolean;
  reason: IdLabel | null;
};

const to_reason_id = (idLabel: State['reason']): string => {
  return toGlobalId('Dictionary', fromHasuraGlobalId(idLabel?.id ?? null));
};

const FinancingLeadLostTypeSelect = ({
  value,
  error,
  onChange,
}: FinancingLeadLostTypeSelectProps) => {
  const { dictionaries_connection } =
    useLazyLoadQuery<financingLeadLostDialogQuery>(
      graphql`
        query financingLeadLostDialogQuery {
          dictionaries_connection(
            where: { type: { _eq: financing_lead_lost_types } }
          ) {
            edges {
              node {
                id
                name
                label
              }
            }
          }
        }
      `,
      {},
    );

  const financingLeadLostTypes: IdLabel[] = (
    dictionaries_connection.edges ?? []
  ).map(item => ({
    id: item.node.id,
    name: item.node.name,
    label: item.node.label ?? '',
  }));

  return (
    <Select
      value={value?.id ?? ''}
      error={error}
      onChange={event => {
        const id = event.target.value;
        const financingLeadLostType = financingLeadLostTypes.find(
          item => item.id === id,
        );
        if (financingLeadLostType) {
          onChange(financingLeadLostType);
        }
      }}
    >
      {financingLeadLostTypes.map((item, index) => (
        <MenuItem value={item.id} key={index}>
          {item.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export const FinancingLeadLostDialog = (
  props: FinancingLeadLostDialogProps,
) => {
  const responsive = useResponsive();
  const { t } = useLocale();
  const { values, setValues, errors, valid, setTouched, submitForm } =
    useFormik<State>({
      initialValues: {
        note: '',
        notifyParticipants: true,
        reason: null,
      },
      validate: values => {
        const errors: {
          reason?: string | null;
          note?: string | null;
        } = {};

        if (values.reason == null) {
          errors.reason = t('reasonRequired');
        }

        if (values.reason?.name === 'other' && values.note.trim() === '') {
          errors.note = t('commentRequired');
        }

        return errors;
      },
      onSubmit: values => {
        const reason = values.reason;

        if (reason) {
          const note = reason.name === 'other' ? values.note : reason.label;
          const reasonId = to_reason_id(reason);

          props.onConfirm(note, values.notifyParticipants, reasonId);
        }
      },
    });

  return (
    <Dialog
      maxWidth="xs"
      fullScreen={responsive([true, false])}
      open={props.open}
      onClose={props.onClose}
    >
      <DialogTitle>{t('whyIsThisLeadDead')}</DialogTitle>
      <DialogContent>
        <Form onSubmit={submitForm}>
          <div css={{ display: 'grid', gap: 16 }}>
            <FormControl variant="filled">
              <InputLabel>{t('financingLeadLostReasons')}</InputLabel>
              <RelayHasuraWrapper>
                <React.Suspense fallback={null}>
                  <FinancingLeadLostTypeSelect
                    value={values.reason}
                    error={errors.reason != null}
                    onChange={event => {
                      setValues({
                        reason: event,
                      });

                      props.onChange?.(
                        event.name === 'other' ? values.note : event.label,
                        values.notifyParticipants,
                        to_reason_id(event),
                      );
                    }}
                  />
                </React.Suspense>
              </RelayHasuraWrapper>

              <FormHelperText error={errors.reason != null}>
                {errors.reason ?? ''}
              </FormHelperText>
            </FormControl>
            {values.reason?.name === 'other' && (
              <FormControl required={true}>
                <TextField
                  variant="filled"
                  required={true}
                  error={errors.note != null}
                  helperText={errors.note}
                  multiline={true}
                  label={t('note')}
                  autoFocus={true}
                  value={values.note}
                  onBlur={() => setTouched({ note: true })}
                  onChange={event => {
                    setValues({
                      note: event.target.value,
                    });
                    props.onChange?.(
                      event.target.value,
                      values.notifyParticipants,
                      to_reason_id(values.reason),
                    );
                  }}
                />
              </FormControl>
            )}
          </div>
        </Form>
      </DialogContent>
      <DialogActions>
        {props.allowNotifyParticipants === true && (
          <FormControlLabel
            label={t('notifyParticipants')}
            control={
              <Switch
                checked={values.notifyParticipants}
                onChange={e => {
                  setValues({
                    notifyParticipants: e.target.checked,
                  });
                }}
              />
            }
          />
        )}
        <Button onClick={props.onClose}>{t('back')}</Button>
        <Button color="primary" disabled={!valid} onClick={submitForm}>
          {t('ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
