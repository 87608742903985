import Timeline from '@mui/lab/Timeline';
import { timelineItemClasses } from '@mui/lab/TimelineItem';

import { ActivitySkeleton } from './ActivitySkeleton';

export const FeedSkeleton = () => {
  return (
    <Timeline
      sx={{
        p: 0,
        maxWidth: 750,
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      {[0, 1, 2, 3, 4, 5, 6].map(i => (
        <ActivitySkeleton key={i} />
      ))}
    </Timeline>
  );
};
