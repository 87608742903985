import { Box } from 'react-system';

import { useLocale } from '../../hooks/locale';
import { useTheme } from '../../hooks/theme';
import { RoundWarning } from '../../icons/round-warning';

export const DialogWarning = () => {
  const { colors, borderRadius, text } = useTheme();
  const { t } = useLocale();

  return (
    <>
      <RoundWarning fill={colors.warning} />
      <Box
        py={2}
        px={3}
        css={{
          fontWeight: text.font.medium,
          backgroundColor: colors.warning,
          borderRadius: borderRadius.medium,
        }}
      >
        {t('completeActivityWarning')}
      </Box>
    </>
  );
};
