import * as React from 'react';

import type { UniqueIdentifier } from '@dnd-kit/core';
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { Grid } from '@material-ui/core';
import { useFileDialog } from '@realadvisor/hooks';
import * as ReactDOM from 'react-dom';

import { FullScreenDropZone } from '../dropzone';

import { EditImageCard } from './edit-image-card';
import { FullScreenImage } from './fullscreen-image';
import { LoadingImageCard } from './loading-image-card';
import { type CardImage, SelectImageCard } from './select-image-card';
import { UploadImageCard } from './upload-image-card';

type Props = {
  images: CardImage[];
  onImageUpload: (
    image: { id: number; file: File },
    callback: () => void,
    errorCallback: (error: string) => void,
  ) => void;
  onImageDelete: (
    image: CardImage,
    callback: () => void,
    errorCallback: (error: string) => void,
  ) => void;
  onImageReorder: (sourceIndex: number, destinationIndex: number) => void;
  quickEditContent?: (image: CardImage) => JSX.Element;
  editContent: (image: CardImage, showGrid: () => void) => JSX.Element;
};

const Wrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      css={{
        flex: 1,
        height: '100%',
        padding: '8px',
        minWidth: 0,
      }}
    >
      {children}
    </div>
  );
};

// TODO fix progress bar

export const ImageManager = ({
  quickEditContent,
  editContent,
  images,
  onImageUpload,
  onImageReorder,
  onImageDelete,
}: Props) => {
  const [state, setState] = React.useReducer<React.Reducer<any, any>>(
    (prev, updater) => ({
      ...prev,
      ...(typeof updater === 'function' ? updater(prev) : updater),
    }),
    {
      viewStyle: 'grid',
      openFullScreen: false,
      viewIndex: 0,
    },
  );

  const handleResetViewIndex = () => setState({ viewIndex: 0 });
  const handleViewIndexChange = (viewIndex: number) => setState({ viewIndex });

  const handleShowFullScreen = (viewIndex: number) =>
    setState({
      openFullScreen: true,
      viewIndex,
    });
  const handleCloseFullScreen = () =>
    setState({
      openFullScreen: false,
    });
  const handleShowDetail = (viewIndex: number) =>
    setState({
      viewStyle: 'detail',
      viewIndex,
    });
  const handleShowGrid = () =>
    setState({
      viewStyle: 'grid',
      viewIndex: undefined,
    });

  const [errors, setErrors] = React.useReducer<
    React.Reducer<Record<string, string>, Record<string, string>>
  >((prev, next) => ({ ...prev, ...next }), {});

  const [progressImages, setProgressImages] = React.useState<
    { id: number; file: File }[]
  >([]);

  const lastId = React.useRef(0);
  const uploadFiles = (files: ReadonlyArray<File>) => {
    const dropFiles = files.map(file => {
      lastId.current += 1;
      return { id: lastId.current, file };
    });
    setProgressImages(prevFiles => [...prevFiles, ...dropFiles]);
    dropFiles.forEach(dropFile => {
      onImageUpload(
        dropFile,
        () => {
          setProgressImages(prevFiles =>
            prevFiles.filter(f => f.id !== dropFile.id),
          );
        },
        error => setErrors({ [dropFile.id]: error }),
      );
    });
  };

  const openFileDialog = useFileDialog({
    accept: 'image/*',
    onChange: uploadFiles,
  });

  const items = images.map(item => item.id);
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  const [activeId, setActiveId] = React.useState<null | UniqueIdentifier>(null);
  const activeImage = images.find(image => image.id === activeId);

  return (
    <DndContext
      sensors={sensors}
      onDragStart={({ active }) => {
        if (document.body?.style) {
          document.body.style.cursor = 'grabbing';
        }
        setActiveId(active.id);
      }}
      onDragEnd={({ active, over }) => {
        if (over && active.id !== over.id) {
          const activeIndex = items.indexOf(active.id);
          const overIndex = items.indexOf(over.id);
          onImageReorder(activeIndex, overIndex);
        }
        if (document.body?.style) {
          document.body.style.cursor = '';
        }
        setActiveId(null);
      }}
    >
      <SortableContext items={items}>
        <FullScreenDropZone onDrop={uploadFiles} />
        <Wrapper>
          {state.viewStyle === 'grid' && (
            <div
              css={{
                display: 'grid',
                padding: 8,
                gap: 16,
                gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
              }}
            >
              {images.map((image, i) => (
                <SelectImageCard
                  key={image.id}
                  index={i}
                  image={image}
                  draggable={true}
                  total={images.length}
                  onImageDelete={(image: any) => {
                    onImageDelete(
                      image,
                      () => {},
                      error => setErrors({ [image.id]: error }),
                    );

                    handleResetViewIndex();
                  }}
                  error={errors[image.id]}
                  onShowFullScreen={handleShowFullScreen}
                  onShowDetail={handleShowDetail}
                  quickEditContent={quickEditContent}
                />
              ))}
              {document.body != null &&
                ReactDOM.createPortal(
                  <DragOverlay
                    adjustScale={true}
                    // put dragging element above modal
                    zIndex={2000}
                    style={{
                      // to make cursor style on body work
                      pointerEvents: 'none',
                    }}
                  >
                    {activeImage != null && (
                      <SelectImageCard
                        index={items.indexOf(activeImage.id)}
                        image={activeImage}
                        draggable={false}
                        total={images.length}
                        onImageDelete={() => {}}
                        error={errors[activeImage.id]}
                        onShowFullScreen={() => {}}
                        onShowDetail={() => {}}
                        quickEditContent={quickEditContent}
                      />
                    )}
                  </DragOverlay>,
                  document.body,
                )}

              {progressImages.map((image, i) => (
                <LoadingImageCard
                  key={i}
                  image={image.file}
                  error={errors[image.id]}
                />
              ))}

              <UploadImageCard onClick={openFileDialog} />
            </div>
          )}

          {images.length > 0 && state.viewStyle === 'detail' && (
            <Grid
              css={{ alignContent: 'flex-start' }}
              container={true}
              spacing={0}
            >
              <Grid item={true} xs={12}>
                <EditImageCard
                  images={images}
                  content={editContent}
                  viewIndex={state.viewIndex ?? 0}
                  onViewIndexChange={handleViewIndexChange}
                  onShowFullScreen={handleShowFullScreen}
                  onShowGrid={handleShowGrid}
                />
              </Grid>
            </Grid>
          )}
          {images.length > 0 && (
            <FullScreenImage
              open={state.openFullScreen}
              viewIndex={state.viewIndex ?? 0}
              images={images}
              onViewIndexChange={handleViewIndexChange}
              onCloseFullScreen={handleCloseFullScreen}
            />
          )}
        </Wrapper>
      </SortableContext>
    </DndContext>
  );
};
