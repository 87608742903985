import { useState } from 'react';

import { Box } from 'react-system';

import { FiltersMenu } from '../../../../../src/controls/Filters';
import { useLocale } from '../../../../../src/hooks/locale';
import { composePropertyType } from '../../../../../src/routes/Documents/compose-property-type';
import type { Merged_Listings_Compare_Args } from '../../../../__generated__/graphql';

import CompositePropertyTypeFilter from './CompositePropertyTypeFilter';
import OnlineFilter from './OnlineFilter';
import PlaceFilter, { type MapViewport } from './PlaceFilter';
import RangeFilter from './RangeFilter';
import RoomsFilter from './RoomsFilter';
import SortFilter from './SortFilter';

type Props = {
  filters: Partial<Merged_Listings_Compare_Args>;
  setFilters: (filters: Partial<Merged_Listings_Compare_Args>) => void;
  defaultMapViewport: MapViewport | null;
};

const AggregatesListingsListFilters = ({
  filters,
  setFilters,
  defaultMapViewport,
}: Props) => {
  const { countryCode } = useLocale();
  const [composeValue, setComposeValue] = useState(
    composePropertyType(
      filters.property_main_type_in?.split(',') ?? null,
      filters.property_type_in?.split(',') ?? null,
      filters.property_type_nin?.split(',') ?? null,
    ),
  );

  return (
    <FiltersMenu
      actions={
        countryCode === 'CH' && (
          <SortFilter
            composeValue={composeValue}
            filters={filters}
            setFilters={setFilters}
          />
        )
      }
    >
      <Box px={1}>
        <CompositePropertyTypeFilter
          setFilters={setFilters}
          composeValue={composeValue}
          setComposeValue={setComposeValue}
        />
      </Box>

      <Box px={1}>
        <PlaceFilter
          filters={filters}
          setFilters={setFilters}
          defaultMapViewport={defaultMapViewport}
        />
      </Box>

      {['APPT', 'HOUSE', 'HOUSE_APPT'].includes(composeValue) && (
        <>
          <Box px={1}>
            <RoomsFilter filters={filters} setFilters={setFilters} />
          </Box>
          <Box px={1}>
            <RangeFilter
              filters={filters}
              setFilters={setFilters}
              type="surface"
            />
          </Box>
        </>
      )}

      <Box px={1}>
        <RangeFilter
          filters={filters}
          setFilters={setFilters}
          type="sale_price"
        />
      </Box>

      <Box px={1}>
        <RangeFilter
          filters={filters}
          setFilters={setFilters}
          type="price_per_sqm"
        />
      </Box>

      {['COMMERCIAL', 'HOUSE', 'HOUSE_APPT'].includes(composeValue) && (
        <Box px={1}>
          <RangeFilter
            filters={filters}
            setFilters={setFilters}
            type="land_surface"
          />
        </Box>
      )}

      <Box px={1}>
        <OnlineFilter filters={filters} setFilters={setFilters} />
      </Box>
    </FiltersMenu>
  );
};

export default AggregatesListingsListFilters;
