/**
 * @generated SignedSource<<e9b46998af94e84a12b90717e1ba58e9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type parentLink_lot$data = {
  readonly id: string;
  readonly " $fragmentType": "parentLink_lot";
};
export type parentLink_lot$key = {
  readonly " $data"?: parentLink_lot$data;
  readonly " $fragmentSpreads": FragmentRefs<"parentLink_lot">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "parentLink_lot",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Lot",
  "abstractKey": null
};

(node as any).hash = "a70139ecf498e9c2d9fd7522ff734932";

export default node;
