import { gql } from '../../__generated__';

export const GET_CMA_REPORTS_COUNT = gql(`
  query GetCmaReportsCount($where: cma_reports_bool_exp) {
    cma_reports_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`);

export const STEP_COVER_PHOTO_FRAGMENT = gql(`
  fragment StepCoverPhoto on cma_reports {
    id
    updated_at
    language
    contact {
      ...UserInfo_user
    }
    broker {
      ...UserInfo_user
    }
    cover_image {
      id
      url
    }
    lead {
      id
      property {
        id
        updated_at
        lat
        lng
        property_images(
          where: { display_on_brochure: { _eq: true } }
          order_by: { order_nr: desc }
        ) {
          id
          image {
            id
            url
          }
        }
      }
    }
    doc_template {
      id
    }
  }
`);

export const STEP_HEDONISTIC_VALUATION_FRAGMENT = gql(`
  fragment StepHedonisticValuation on cma_reports {
    id
    updated_at
    include_hedonistic_valuation
    lead {
      id
      property {
        id
        updated_at
        latest_appraisal {
          id
          updated_at
        }
        appraisals(order_by: { created_at: desc_nulls_last }) {
          id
        }
      }
    }
  }
`);

export const CMA_REPORT_FILE_FRAGMENT = gql(`
  fragment CmaReportFile on cma_report_files {
    cma_report_id
    file_id
    is_visible
    order_nr
    file {
      id
      name
      url
      mimetype
      created_at
    }
  }
`);

export const CMA_PROPERTY_FILE_FRAGMENT = gql(`
  fragment CmaPropertyFile on property_files {
    property_id
    file_id
    order_nr
    file {
      id
      name
      url
      mimetype
      created_at
    }
  }
`);

export const STEP_DESCRIPTION_FRAGMENT = gql(`
  fragment StepDescription on cma_reports {
    id
    updated_at
    include_description
    description
  }
`);

export const UPDATE_DESCRIPTION = gql(`
  mutation UpdateDescription($id: uuid!, $input: cma_reports_set_input!) {
    update_cma_reports_by_pk(pk_columns: { id: $id }, _set: $input) {
      ...StepDescription
    }
  }
`);

export const STEP_APPEND_FILES_FRAGMENT = gql(`
  fragment StepAppendFiles on cma_reports {
    id
    cma_report_files(order_by: { order_nr: desc }) {
      cma_report_id
      file_id
      ...CmaReportFile
    }
    lead {
      id
      property {
        id
        property_files(order_by: { order_nr: desc }) {
          property_id
          file_id
          ...CmaPropertyFile
        }
      }
    }
  }
`);

export const CMA_REPORT_EDITOR_FRAGMENT = gql(`
  fragment CMAReportEditor on cma_reports {
    id
    updated_at
    lead {
      id
      property {
        id
        updated_at
        street_number
        route
        postcode
        locality
        updated_at
      }
    }

    doc_template {
      id
      content
      language
      filename_template
    }
  }
`);

export const STEP_INTRODUCTION_FRAGMENT = gql(`
  fragment StepIntroduction on cma_reports {
    id
    updated_at
    introduction
  }
`);

export const STEP_PROPERTY_DETAILS_FRAGMENT = gql(`
  fragment StepPropertyDetails on cma_reports {
    id
    updated_at
    lead {
      id
      property {
        id
        updated_at
      }
    }
  }
`);

export const STEP_OTHER_VALUATIONS_FRAGMENT = gql(`
  fragment StepOtherValuations on cma_reports {
    id
    updated_at
    include_cuprate_valuation
    caprate_estimated_monthly_rent
    caprate_minimum_required_yield
    include_intrinsic_valuation
    construction_cost_base
    intrinsic_building_volume
    intrinsic_building_surface
    intrinsic_construction_cost
    intrinsic_construction_year
    intrinsic_renovation_year
    intrinsic_annual_deprecation_rate
    intrinsic_land_surface
    intrinsic_land_value
    lead {
      id
      property {
        id
        updated_at
        latest_appraisal {
          id
          value
          rent_value
        }
        building_volume
        construction_year
        renovation_year
        land_surface
        living_surface
        usable_surface
        basement_surface
        commercial_surface
        balcony_surface
        garden_surface
        gross_floor_surface
        residential_surface
        terrace_surface
        weighted_floor_surface
      }
    }
  }
`);

export const STEP_FINAL_FRAGMENT = gql(`
  fragment StepFinal on cma_reports {
    id
    updated_at
    positive_comments
    negative_comments
    suggested_market_value
  }
`);

export const STEP_POTENTIAL_BUYERS_FRAGMENT = gql(`
  fragment StepPotentialBuyers on cma_reports {
    id
    updated_at
    max_budget
    min_budget
    include_potential_buyers
    suggested_market_value
    lead {
      id
      property {
        id
        latest_appraisal {
          id
          min
          max
        }
      }
    }
  }
`);

export const DELETE_CMA_REPORT = gql(`
  mutation DeleteCmaReport($id: uuid!) {
    delete_cma_reports_by_pk(id: $id) {
      id
    }
  }
`);

export const DOC_TEMPLATES_CMA_REPORT = gql(`
  query DocTemplatesCmaReport {
    doc_templates(
      where: { type: { _eq: cma_report }, active: { _eq: true } }
    ) {
      id
      name
      language
      active
      doc_templates_tenants(order_by: { is_default: desc_nulls_last}) {
        tenant_id
        is_default
      }
    }
  }
`);

export const GET_CMA_REPORT = gql(`
  query GetCmaReport($id: uuid!) {
    cma_reports_by_pk(id: $id) {
      id
      lead {
        id
        property {
          id
          street_number
          route
          postcode
          locality
        }
      }
      ...CMAReportEditor
      ...StepIntroduction
      ...StepCoverPhoto
      ...StepPropertyDetails
      ...StepDescription
      ...StepHedonisticValuation
      ...StepListingsCompare
      ...StepOtherValuations
      ...StepFinal
      ...StepPotentialBuyers
      ...StepAppendFiles
    }
    property_types {
      id
      label
      name
    }
  }
`);

export const GET_PROPERTY_APPRAISABLE = gql(`
  query GetPropertyAppraisable($propertyId: uuid!) {
    property_appraisable(property_id: $propertyId) {
      appraisable {
        result
        errors {
          path
        }
      }
    }
  }
`);

// Mutations
export const UPDATE_CMA_COVER = gql(`
  mutation UpdateCmaStepCover($id: uuid!, $input: cma_reports_set_input!) {
    update_cma_reports_by_pk(pk_columns: { id: $id }, _set: $input) {
      ...StepCoverPhoto
    }
  }
`);

export const STEP_COVER_INSERT_PROPERTY_IMAGE = gql(`
  mutation StepCoverInsertPropertyImage(
    $object: property_images_insert_input!
  ) {
    insert_property_images_one(object: $object) {
      id
      image {
        id
        url
      }
    }
  }
`);

export const UPDATE_CMA_INTRODUCTION = gql(`
  mutation UpdateCmaIntroduction($id: uuid!, $input: cma_reports_set_input!) {
    update_cma_reports_by_pk(pk_columns: { id: $id }, _set: $input) {
      ...StepIntroduction
    }
  }
`);

export const UPDATE_PROPERTY_DETAILS = gql(`
  mutation UpdatePropertyDetails($id: uuid!, $input: cma_reports_set_input!) {
    update_cma_reports_by_pk(pk_columns: { id: $id }, _set: $input) {
      ...StepPropertyDetails
    }
  }
`);

export const UPDATE_HEDONISTIC_VALUATION = gql(`
  mutation UpdateHedonisticValuation(
    $id: uuid!
    $input: cma_reports_set_input!
  ) {
    update_cma_reports_by_pk(pk_columns: { id: $id }, _set: $input) {
      ...StepHedonisticValuation
    }
  }
`);

export const UPDATE_OTHER_VALUATIONS = gql(`
  mutation UpdateOtherValuations($id: uuid!, $input: cma_reports_set_input!) {
    update_cma_reports_by_pk(pk_columns: { id: $id }, _set: $input) {
      ...StepOtherValuations
    }
  }
`);

export const UPDATE_FINAL = gql(`
  mutation UpdateFinal($id: uuid!, $input: cma_reports_set_input!) {
    update_cma_reports_by_pk(pk_columns: { id: $id }, _set: $input) {
      ...StepFinal
    }
  }
`);

export const UPDATE_POTENTIAL_BUYERS = gql(`
  mutation UpdatePotentialBuyers($id: uuid!, $input: cma_reports_set_input!) {
    update_cma_reports_by_pk(pk_columns: { id: $id }, _set: $input) {
      ...StepPotentialBuyers
    }
  }
`);

export const STEP_ADD_CMA_REPORT_FILE = gql(`
  mutation StepAddCmaReportFile($object: cma_report_files_insert_input!, $cma_report_id: uuid!) {
    insert_cma_report_files_one(object: $object) {
      cma_report_id
      file_id
      cma_report {
        id
        cma_report_files(order_by: { order_nr: desc }) {
          cma_report_id
          file_id
          ...CmaReportFile
        }
      }
    }

    update_cma_reports_by_pk(pk_columns: { id: $cma_report_id }) {
      id
      updated_at
    }
  }
`);

export const STEP_DELETE_CMA_REPORT_FILE = gql(`
  mutation StepDeleteCmaReportFile($file_id: uuid!, $cma_report_id: uuid!) {
    delete_cma_report_files(where: { file_id: { _eq: $file_id }, cma_report_id: { _eq: $cma_report_id } }) {
      returning {
        cma_report_id
        file_id
      }
    }

    update_cma_reports_by_pk(pk_columns: { id: $cma_report_id }) {
      id
      updated_at
    }
  }
`);

export const STEP_UPDATE_CMA_REPORT_FILE = gql(`
  mutation StepUpdateCmaReportFile(
    $input: cma_report_files_set_input!
    $file_id: uuid!
    $cma_report_id: uuid!
  ) {
    update_cma_report_files(
      where: {
        file_id: { _eq: $file_id }
        cma_report_id: { _eq: $cma_report_id }
      }
      _set: $input
    ) {
      returning {
        cma_report_id
        file_id
        cma_report {
          id
          cma_report_files(order_by: { order_nr: desc }) {
            cma_report_id
            file_id
            ...CmaReportFile
          }
        }
      }
    }

    update_cma_reports_by_pk(pk_columns: { id: $cma_report_id }, _set: {}) {
      id
      updated_at
    }
  }
`);

export const STEP_UPDATE_CMA_REPORT_FILE_VISIBILITY = gql(`
 mutation StepUpdateCmaReportFileVisibility(
    $file_id: uuid!
    $cma_report_id: uuid!
    $is_visible: Boolean!
  ) {
    update_cma_report_files(
      where: {
        file_id: { _eq: $file_id }
        cma_report_id: { _eq: $cma_report_id }
      }
      _set: { is_visible: $is_visible }
    ) {
      returning {
        cma_report_id
        file_id
        is_visible
      }
    }

    update_cma_reports_by_pk(pk_columns: { id: $cma_report_id }, _set: {}) {
      id
      updated_at
    }
	}`);

export const STEP_APPEND_ADD_PROPERTY_FILE = gql(`
  mutation StepAppendAddPropertyFile($object: property_files_insert_input!) {
    insert_property_files_one(object: $object) {
      property_id
      file_id
      property {
        id
        property_files(order_by: { order_nr: desc }) {
          property_id
          file_id
          ...CmaPropertyFile
        }
      }
    }
  }
`);

export const STEP_LISTINGS_COMPARE_FRAGMENT = gql(`
  fragment StepListingsCompare on cma_reports {
    id
    include_comparables_valuation
    comparable_listings(order_by: { order_nr: asc }) {
      cma_report_id
      order_nr
      aggregates_db__merged_listings_clusters_id
    }
    lead {
      id
      property {
        id
        number_of_rooms
        number_of_bedrooms
        living_surface
        built_surface
        usable_surface
        land_surface
        locality_place {
          id
          label
        }
        state
        lat
        lng
        latest_appraisal {
          value
        }
        property_type {
          main_type
          name
        }
      }
    }
    updated_at
  }
`);

export const STEP_LISTINGS_COMPARE_LISTINGS = gql(`
  query StepListingsCompareListings(
    $ids: [Int!]!
    $ch: Boolean!
    $es: Boolean!
    $fr: Boolean!
    $it: Boolean!
  ) {
    ch_post_merged_listings(where: { id: { _in: $ids } }) @include(if: $ch) {
      id
      number_of_rooms
      computed_surface
      land_surface
      sale_price
      computed_price_per_sqm
      offline_since
      created_at
      country_code
      route
      street_number
      postcode
      locality
      locality_object {
        id
        label
      }
      property_type
      listing_images(limit: 3) {
        id
        file_name
        bucket_name
      }
      created_at_consolidated
      valid_from
    }

    fr_post_merged_listings(where: { id: { _in: $ids } }) @include(if: $fr) {
      id
      number_of_rooms
      computed_surface
      land_surface
      sale_price
      computed_price_per_sqm
      offline_since
      created_at
      country_code
      route
      street_number
      postcode
      locality
      locality_object {
        id
        label
      }
      property_type
      listing_images(limit: 3) {
        id
        file_name
        bucket_name
      }
      created_at_consolidated
      valid_from
      
    }
    es_post_merged_listings(where: { id: { _in: $ids } }) @include(if: $es) {
      id
      number_of_rooms
      computed_surface
      land_surface
      sale_price
      computed_price_per_sqm
      offline_since
      created_at
      country_code
      route
      street_number
      postcode
      locality
      locality_object {
        id
        label
      }
      property_type
      listing_images(limit: 3) {
        id
        file_name
        bucket_name
      }
      created_at_consolidated
      valid_from
      
    }
    it_post_merged_listings(where: { id: { _in: $ids } }) @include(if: $it) {
      id
      number_of_rooms
      computed_surface
      land_surface
      sale_price
      computed_price_per_sqm
      offline_since
      created_at
      country_code
      route
      street_number
      postcode
      locality
      locality_object {
        id
        label
      }
      property_type
      listing_images(limit: 3) {
        id
        file_name
        bucket_name
      }
      created_at_consolidated
      valid_from
      
    }
  }
`);

export const STEP_LISTINGS_COMPARE_ALGO = gql(`
  query StepListingsCompareAlgo(
    $built_surface: Float
    $lat: Float
    $living_surface: Float
    $lng: Float
    $number_of_rooms: Int
    $property_main_type: String
    $sale_price: Float
    $usable_surface: Float
    $ch: Boolean!
    $es: Boolean!
    $fr: Boolean!
    $it: Boolean!
  ) {
    ch_get_similar_listings(
      args: {
        _built_surface: $built_surface
        _lat: $lat
        _living_surface: $living_surface
        _lng: $lng
        _number_of_rooms: $number_of_rooms
        _property_main_type: $property_main_type
        _sale_price: $sale_price
        _usable_surface: $usable_surface
      }
      limit: 10
    ) @include(if: $ch) {
      id
      number_of_rooms
      computed_surface
      land_surface
      sale_price
      computed_price_per_sqm
      offline_since
      created_at
      country_code
      route
      street_number
      postcode
      locality
      locality_object {
        id
        label
      }
      property_type
      listing_images(limit: 3) {
        id
        file_name
        bucket_name
      }
      created_at_consolidated
      valid_from
    }

    es_get_similar_listings(
      args: {
        _built_surface: $built_surface
        _lat: $lat
        _living_surface: $living_surface
        _lng: $lng
        _number_of_rooms: $number_of_rooms
        _property_main_type: $property_main_type
        _sale_price: $sale_price
        _usable_surface: $usable_surface
      }
      limit: 10
    ) @include(if: $es) {
      id
      number_of_rooms
      computed_surface
      land_surface
      sale_price
      computed_price_per_sqm
      offline_since
      created_at
      country_code
      route
      street_number
      postcode
      locality
      locality_object {
        id
        label
      }
      property_type
      listing_images(limit: 3) {
        id
        file_name
        bucket_name
      }
      created_at_consolidated
      valid_from
    }

    fr_get_similar_listings(
      args: {
        _built_surface: $built_surface
        _lat: $lat
        _living_surface: $living_surface
        _lng: $lng
        _number_of_rooms: $number_of_rooms
        _property_main_type: $property_main_type
        _sale_price: $sale_price
        _usable_surface: $usable_surface
      }
      limit: 10
    ) @include(if: $fr) {
      id
      number_of_rooms
      computed_surface
      land_surface
      sale_price
      computed_price_per_sqm
      offline_since
      created_at
      country_code
      route
      street_number
      postcode
      locality
      locality_object {
        id
        label
      }
      property_type
      listing_images(limit: 3) {
        id
        file_name
        bucket_name
      }
      created_at_consolidated
      valid_from
    }

    it_get_similar_listings(
      args: {
        _built_surface: $built_surface
        _lat: $lat
        _living_surface: $living_surface
        _lng: $lng
        _number_of_rooms: $number_of_rooms
        _property_main_type: $property_main_type
        _sale_price: $sale_price
        _usable_surface: $usable_surface
      }
      limit: 10
    ) @include(if: $it) {
      id
      number_of_rooms
      computed_surface
      land_surface
      sale_price
      computed_price_per_sqm
      offline_since
      created_at
      country_code
      route
      street_number
      postcode
      locality
      locality_object {
        id
        label
      }
      property_type
      listing_images(limit: 3) {
        id
        file_name
        bucket_name
      }
      created_at_consolidated
      valid_from
    }
  }
`);

export const UPDATE_COMPARABLES_LIST = gql(`
  mutation UpdateComparablesList($id: uuid!, $input: cma_reports_set_input!) {
    update_cma_reports_by_pk(pk_columns: { id: $id }, _set: $input) {
      id
      include_comparables_valuation
      updated_at
    }
  }
`);

export const INSERT_CMA_REPORTS_COMPARABLES = gql(`
  mutation InsertCmaReportsComparables(
    $id: uuid!
    $objects: [cma_reports_comparables_insert_input!]!
  ) {
    delete_cma_reports_comparables(where: { cma_report_id: { _eq: $id } }) {
      __typename
    }

    insert_cma_reports_comparables(objects: $objects) {
      returning {
        aggregates_db__merged_listings_clusters_id
        cma_report_id
        order_nr
      }
    }
    update_cma_reports_by_pk(pk_columns: { id: $id }, _set: {}) {
      id
      updated_at
    }
  }
`);

export const UPDATE_CMA_REPORTS_COMPARABLES = gql(`
  mutation UpdateCmaReportsComparables(
    $cma_report_id: uuid!
    $listing_id: bigint!
    $order_nr: Float!
  ) {
    update_cma_reports_comparables(
      where: {
        cma_report_id: { _eq: $cma_report_id }
        aggregates_db__merged_listings_clusters_id: { _eq: $listing_id }
      }
      _set: { order_nr: $order_nr }
    ) {
      returning {
        cma_report {
          comparable_listings(order_by: { order_nr: asc }) {
            order_nr
            cma_report_id
            aggregates_db__merged_listings_clusters_id
          }
        }
      }
    }

    update_cma_reports_by_pk(pk_columns: { id: $cma_report_id }, _set: {}) {
      id
      updated_at
    }
  }
`);

export const DELETE_CMA_REPORTS_COMPARABLES = gql(`
  mutation DeleteCmaReportsComparables(
    $cma_report_id: uuid!
    $listing_id: bigint!
  ) {
    delete_cma_reports_comparables(
      where: {
        cma_report_id: { _eq: $cma_report_id }
        aggregates_db__merged_listings_clusters_id: { _eq: $listing_id }
      }
    ) {
      returning {
        cma_report {
          comparable_listings(order_by: { order_nr: asc }) {
            order_nr
            cma_report_id
            aggregates_db__merged_listings_clusters_id
          }
        }
      }
    }

    update_cma_reports_by_pk(pk_columns: { id: $cma_report_id }, _set: {}) {
      id
      updated_at
    }
  }
`);

export const AGGREGATES_LISTINGS_LIST_QUERY = gql(`
  query AggregatesListingsList(
    $page: Int
    $pageSize: Int
    $ch: Boolean!
    $fr: Boolean!
    $es: Boolean!
    $it: Boolean!
    $filters: merged_listings_compare_args!
  ) {
    ch_merged_listings_compare(limit: $pageSize, offset: $page, args: $filters)
      @include(if: $ch) {
      id
      number_of_rooms
      computed_price_per_sqm
      computed_surface
      land_surface
      sale_price
      offline_since
      created_at
      route
      street_number
      postcode
      locality
      images {
        bucket_name
        file_name
      }
      property_type
      valid_from

      # for place filter
      # eslint-disable-next-line relay/unused-fields
      locality_id
      # eslint-disable-next-line relay/unused-fields
      municipality_id
      # eslint-disable-next-line relay/unused-fields
      neighbourhood_id
      # eslint-disable-next-line relay/unused-fields
      state_id
      # eslint-disable-next-line relay/unused-fields
      district_id
    }

    fr_merged_listings_compare(limit: $pageSize, offset: $page, args: $filters)
      @include(if: $fr) {
      id
      number_of_rooms
      computed_price_per_sqm
      computed_surface
      land_surface
      sale_price
      offline_since
      created_at
      route
      street_number
      postcode
      locality
      images {
        bucket_name
        file_name
      }
      property_type
      valid_from

      # for place filter
      # eslint-disable-next-line relay/unused-fields
      locality_id
      # eslint-disable-next-line relay/unused-fields
      municipality_id
      # eslint-disable-next-line relay/unused-fields
      neighbourhood_id
      # eslint-disable-next-line relay/unused-fields
      state_id
      # eslint-disable-next-line relay/unused-fields
      district_id
    }

    es_merged_listings_compare(limit: $pageSize, offset: $page, args: $filters)
      @include(if: $es) {
      id
      number_of_rooms
      computed_price_per_sqm
      computed_surface
      land_surface
      sale_price
      offline_since
      created_at
      route
      street_number
      postcode
      locality
      images {
        bucket_name
        file_name
      }
      property_type
      valid_from

      # for place filter
      # eslint-disable-next-line relay/unused-fields
      locality_id
      # eslint-disable-next-line relay/unused-fields
      municipality_id
      # eslint-disable-next-line relay/unused-fields
      neighbourhood_id
      # eslint-disable-next-line relay/unused-fields
      state_id
      # eslint-disable-next-line relay/unused-fields
      district_id
    }

    it_merged_listings_compare(limit: $pageSize, offset: $page, args: $filters)
      @include(if: $it) {
      id
      number_of_rooms
      computed_price_per_sqm
      computed_surface
      land_surface
      sale_price
      offline_since
      created_at
      route
      street_number
      postcode
      locality
      images {
        bucket_name
        file_name
      }
      property_type
      valid_from

      # for place filter
      # eslint-disable-next-line relay/unused-fields
      locality_id
      # eslint-disable-next-line relay/unused-fields
      municipality_id
      # eslint-disable-next-line relay/unused-fields
      neighbourhood_id
      # eslint-disable-next-line relay/unused-fields
      state_id
      # eslint-disable-next-line relay/unused-fields
      district_id
    }

    property_types {
      id
      label
      name
    }
  }
`);

export const AGGREGATES_LISTINGS_LIST_FILTERS_QUERY = gql(`
  query AggregatesListingsListFiltersPlaces(
    $placesIds: [uuid!]
    $ch: Boolean!
    $fr: Boolean!
    $es: Boolean!
    $it: Boolean!
  ) {
    ch_places(where: { id: { _in: $placesIds } }, limit: 100)
      @include(if: $ch) {
      _id: id
      type
      lat
      lng
      label
      population
      breadcrumbs {
        _id: breadcrumb_place_id
      }
    }

    fr_places(where: { id: { _in: $placesIds } }, limit: 100)
      @include(if: $fr) {
      _id: id
      type
      lat
      lng
      label
      population
      breadcrumbs {
        _id: breadcrumb_place_id
      }
    }

    es_places(where: { id: { _in: $placesIds } }, limit: 100)
      @include(if: $es) {
      _id: id
      type
      lat
      lng
      label
      population
      breadcrumbs {
        _id: breadcrumb_place_id
      }
    }

    it_places(where: { id: { _in: $placesIds } }, limit: 100)
      @include(if: $it) {
      _id: id
      type
      lat
      lng
      label
      population
      breadcrumbs {
        _id: breadcrumb_place_id
      }
    }
  }
`);

export const CREATE_CMA_REPORT = gql(`
  mutation CreateCmaReport($input: cma_reports_insert_input!) {
    insert_cma_reports_one(
      object: $input
      on_conflict: { constraint: cma_reports_pkey, update_columns: [] }
    ) {
      id
    }
  }
`);

export const GET_SCRAPER_LISTING_DETAILS = gql(`
  query GetScraperListingDetails(
    $id: Int!
    $ch: Boolean!
    $fr: Boolean!
    $es: Boolean!
    $it: Boolean!
  ) {
    ch_post_merged_listings(where: { id: { _eq: $id } }) @include(if: $ch) {
      id
      title
      address
      offline_since
      valid_from
      description
      offline_since
      sale_price
      number_of_rooms
      computed_surface
      land_surface
      route
      street_number
      postcode
      locality
      offer_type
      sale_price
      price_unit
      rent_net
      rent_extra
      currency
      country_code
      living_surface
      built_surface
      computed_price_per_sqm
      property_type
      floor_of_flat
      number_of_rooms
      number_of_bedrooms
      number_of_bathrooms
      number_of_toilets
      number_of_indoor_parkings
      number_of_outdoor_parkings
      construction_year
      property_main_type
      description
      offline_since
      agency_name
      agency_contact_address
      agency_contact_person
      url
      has_view
      has_cellar
      has_elevator
      has_fireplace
      has_storage_room
      has_swimming_pool
      is_child_friendly
      is_wheelchair_accessible
      listing_images(limit: 10) {
        id
        file_name
        bucket_name
      }
      created_at
    }
    fr_post_merged_listings(where: { id: { _eq: $id } }) @include(if: $fr) {
      id
      title
      address
      offline_since
      valid_from
      description
      offline_since
      sale_price
      number_of_rooms
      computed_surface
      land_surface
      route
      street_number
      postcode
      locality
      offer_type
      sale_price
      price_unit
      rent_net
      rent_extra
      currency
      country_code
      living_surface
      built_surface
      computed_price_per_sqm
      property_type
      floor_of_flat
      number_of_rooms
      number_of_bedrooms
      number_of_bathrooms
      number_of_toilets
      number_of_indoor_parkings
      number_of_outdoor_parkings
      construction_year
      property_main_type
      description
      offline_since
      agency_name
      agency_contact_address
      agency_contact_person
      url
      has_view
      has_cellar
      has_elevator
      has_fireplace
      has_storage_room
      has_swimming_pool
      is_child_friendly
      is_wheelchair_accessible
      listing_images(limit: 10) {
        id
        file_name
        bucket_name
      }
      created_at
    }
    es_post_merged_listings(where: { id: { _eq: $id } }) @include(if: $es) {
      id
      title
      address
      offline_since
      valid_from
      description
      sale_price
      number_of_rooms
      computed_surface
      land_surface
      route
      street_number
      postcode
      locality
      offer_type
      sale_price
      price_unit
      rent_net
      rent_extra
      currency
      country_code
      living_surface
      built_surface
      computed_price_per_sqm
      property_type
      floor_of_flat
      number_of_rooms
      number_of_bedrooms
      number_of_bathrooms
      number_of_toilets
      number_of_indoor_parkings
      number_of_outdoor_parkings
      construction_year
      property_main_type
      description
      agency_name
      agency_contact_address
      agency_contact_person
      url
      has_view
      has_cellar
      has_elevator
      has_fireplace
      has_storage_room
      has_swimming_pool
      is_child_friendly
      is_wheelchair_accessible
      listing_images(limit: 10) {
        id
        file_name
        bucket_name
      }
      created_at
    }
    it_post_merged_listings(where: { id: { _eq: $id } }) @include(if: $it) {
      id
      title
      address
      offline_since
      valid_from
      description
      sale_price
      number_of_rooms
      computed_surface
      land_surface
      route
      street_number
      postcode
      locality
      offer_type
      sale_price
      price_unit
      rent_net
      rent_extra
      currency
      country_code
      living_surface
      built_surface
      computed_price_per_sqm
      property_type
      floor_of_flat
      number_of_rooms
      number_of_bedrooms
      number_of_bathrooms
      number_of_toilets
      number_of_indoor_parkings
      number_of_outdoor_parkings
      construction_year
      property_main_type
      description
      agency_name
      agency_contact_address
      agency_contact_person
      url
      has_view
      has_cellar
      has_elevator
      has_fireplace
      has_storage_room
      has_swimming_pool
      is_child_friendly
      is_wheelchair_accessible
      listing_images(limit: 10) {
        id
        file_name
        bucket_name
      }
      created_at
    }
  }
`);
