import { FilledInput } from '@material-ui/core';
import { useRifm } from 'rifm';

import { useLocale } from '../hooks/locale';
import { makeNumberFormat } from '../utils/number-format';

type Props = {
  decimalScale?: number;
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
};

export const NumberInput = (props: Props) => {
  const { locale } = useLocale();
  const { accept, format, delocalizeNumber, localizeNumber } = makeNumberFormat(
    {
      scale: props.decimalScale,
      locale,
    },
  );
  const rifm = useRifm({
    accept,
    format,
    value: localizeNumber(props.value),
    onChange: formatted => props.onChange(delocalizeNumber(formatted)),
  });

  return (
    <FilledInput
      value={rifm.value}
      onChange={rifm.onChange}
      onBlur={props.onBlur}
    />
  );
};
