/**
 * @generated SignedSource<<63c453d045e171b6d0be56baffded7d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateFileInput = {
  clientMutationId?: string | null;
  documentTypeId?: string | null;
  documentTypeName?: string | null;
  file: FileInput;
  parentId?: string | null;
};
export type FileInput = {
  mimetype: string;
  name: string;
  size: number;
  url: string;
};
export type filesListCreateMutation$variables = {
  input: CreateFileInput;
};
export type filesListCreateMutation$data = {
  readonly createFile: {
    readonly development: {
      readonly files: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"filesList_files">;
      }>;
      readonly id: string;
    } | null;
    readonly emailTemplate: {
      readonly files: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"filesList_files">;
      } | null>;
      readonly id: string;
    } | null;
    readonly property: {
      readonly files: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"filesList_files">;
      }>;
      readonly id: string;
    } | null;
    readonly user: {
      readonly files: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"filesList_files">;
      }>;
      readonly id: string;
    } | null;
  } | null;
};
export type filesListCreateMutation = {
  response: filesListCreateMutation$data;
  variables: filesListCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "File",
    "kind": "LinkedField",
    "name": "files",
    "plural": true,
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "filesList_files"
      }
    ],
    "storageKey": null
  }
],
v4 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "File",
    "kind": "LinkedField",
    "name": "files",
    "plural": true,
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mimetype",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "uploader",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "filesListCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateFilePayload",
        "kind": "LinkedField",
        "name": "createFile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Property",
            "kind": "LinkedField",
            "name": "property",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Development",
            "kind": "LinkedField",
            "name": "development",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EmailTemplate",
            "kind": "LinkedField",
            "name": "emailTemplate",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "filesListCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateFilePayload",
        "kind": "LinkedField",
        "name": "createFile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Property",
            "kind": "LinkedField",
            "name": "property",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Development",
            "kind": "LinkedField",
            "name": "development",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EmailTemplate",
            "kind": "LinkedField",
            "name": "emailTemplate",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "260a2cbfe32489ff2a5fe31fd945205d",
    "id": null,
    "metadata": {},
    "name": "filesListCreateMutation",
    "operationKind": "mutation",
    "text": "mutation filesListCreateMutation(\n  $input: CreateFileInput!\n) {\n  createFile(input: $input) {\n    property {\n      id\n      files {\n        ...filesList_files\n        id\n      }\n    }\n    development {\n      id\n      files {\n        ...filesList_files\n        id\n      }\n    }\n    user {\n      id\n      files {\n        ...filesList_files\n        id\n      }\n    }\n    emailTemplate {\n      id\n      files {\n        ...filesList_files\n        id\n      }\n    }\n  }\n}\n\nfragment filesList_files on File {\n  id\n  name\n  url\n  mimetype\n  createdAt\n  uploader {\n    firstName\n    lastName\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "bcd55cd902654d80787ffd940eda307d";

export default node;
