import { type Translate, useLocale } from '../../src/hooks/locale';
import { gql } from '../__generated__';
import { type PropertyDetailsFragment } from '../__generated__/graphql';
import type { GetListingDetailsData } from '../pages/listings/lotsQueries';
import { getRatingLabel } from '../utils/formatting';
import {
  isHouseOrAppt,
  isHouseOrApptOrAgriOrGastroOrIndus,
} from '../utils/propertyDataChecks';
import {
  getPropertyCoolingLabel,
  getPropertyHeatingDistributionLabel,
  getPropertyHeatingLabel,
} from '../utils/propertyTemplateFunctions';

import {
  PropertyDataList,
  PropertyDataListSkeleton,
  type TFieldsDefinition,
} from './PropertyDataList';

type TProperty = NonNullable<GetListingDetailsData['property']>;

export const PROPERTY_DETAILS_FRAGMENT = gql(`
  fragment PropertyDetails on properties {
    living_surface
    land_surface
    commercial_surface
    number_of_floors
    floor_of_flat
    construction_year
    heating
    heating_distribution
    cooling
    micro_location
    macro_location
    number_of_rooms
    number_of_bedrooms
    number_of_bathrooms
    number_of_toilets
    number_of_indoor_parkings
    number_of_outdoor_parkings
    __property_type: property_type {
      id
      name
      label
      main_type
    }
  }
`);

interface PropertyDetailsProps {
  property?: TProperty | null;
  isLoading?: boolean;
}

export const getPropertyDetailsFieldsDefinition = (
  t: Translate,
): TFieldsDefinition<keyof PropertyDetailsFragment> => {
  const sqrMetersSuffix = (value: string | number | null | undefined) =>
    value != null ? `${value} m²` : null;

  return {
    __property_type: {
      label: t('propertyType'),
      name: '__property_type',
      renderFn: value => value?.label,
    },
    living_surface: {
      label: t('livingSurface'),
      name: 'living_surface',
      renderFn: sqrMetersSuffix,
      shouldRender: ({ __property_type }) => isHouseOrAppt(__property_type),
    },
    land_surface: {
      label: t('landSurface'),
      name: 'land_surface',
      renderFn: sqrMetersSuffix,
      shouldRender: ({ __property_type }) =>
        !!__property_type?.main_type &&
        ['HOUSE', 'PROP', 'AGRI', 'GASTRO', 'INDUS', 'PARK', 'GARDEN'].includes(
          __property_type.main_type,
        ),
    },
    commercial_surface: {
      label: t('Commercial surface'),
      name: 'commercial_surface',
      renderFn: sqrMetersSuffix,
      shouldRender: ({ __property_type }) =>
        __property_type?.main_type != null &&
        ['GASTRO', 'INDUS'].includes(__property_type.main_type),
    },
    number_of_floors: {
      label: t('numberOfFloors'),
      name: 'number_of_floors',
      shouldRender: ({ __property_type }) =>
        __property_type?.main_type != null &&
        __property_type?.main_type !== 'PROP',
    },
    floor_of_flat: {
      label: t('Floor of flat'),
      name: 'floor_of_flat',
      shouldRender: ({ __property_type }) =>
        __property_type?.main_type === 'APPT',
    },
    construction_year: {
      label: t('constructionYear'),
      name: 'construction_year',
      shouldRender: ({ __property_type }) => isHouseOrAppt(__property_type),
    },
    heating: {
      label: t('heating'),
      name: 'heating',
      renderFn: value => getPropertyHeatingLabel(value, t),
      shouldRender: ({ __property_type }) =>
        isHouseOrApptOrAgriOrGastroOrIndus(__property_type),
    },
    heating_distribution: {
      name: 'heating_distribution',
      label: t('heatingDistribution'),
      renderFn: value => getPropertyHeatingDistributionLabel(value, t),
      shouldRender: ({ __property_type }) =>
        isHouseOrApptOrAgriOrGastroOrIndus(__property_type),
    },
    cooling: {
      name: 'cooling',
      label: t('cooling'),
      renderFn: value => getPropertyCoolingLabel(value, t),
      shouldRender: ({ __property_type }) =>
        isHouseOrApptOrAgriOrGastroOrIndus(__property_type),
    },
    micro_location: {
      label: t('microLocation'),
      name: 'micro_location',
      renderFn: value => getRatingLabel(value, t),
    },
    macro_location: {
      label: t('macroLocation'),
      name: 'macro_location',
      renderFn: value => getRatingLabel(value, t),
    },
    number_of_rooms: {
      label: t('rooms'),
      name: 'number_of_rooms',
      shouldRender: ({ __property_type }) => isHouseOrAppt(__property_type),
    },
    number_of_bedrooms: {
      label: t('bedrooms'),
      name: 'number_of_bedrooms',
      shouldRender: ({ __property_type }) => isHouseOrAppt(__property_type),
    },
    number_of_bathrooms: {
      label: t('bathrooms'),
      name: 'number_of_bathrooms',
      shouldRender: ({ __property_type }) => isHouseOrAppt(__property_type),
    },
    number_of_toilets: {
      label: t('separatedToilets'),
      name: 'number_of_toilets',
      shouldRender: ({ __property_type }) => isHouseOrAppt(__property_type),
    },
    number_of_indoor_parkings: {
      label: t('indoorParking'),
      name: 'number_of_indoor_parkings',
    },
    number_of_outdoor_parkings: {
      label: t('outdoorParking'),
      name: 'number_of_outdoor_parkings',
    },
  };
};

export const PropertyDetails: React.FC<PropertyDetailsProps> = ({
  property,
  isLoading = false,
}) => {
  const { t } = useLocale();

  if (isLoading) {
    return <PropertyDataListSkeleton title={t('Property details')} />;
  }

  const fields = getPropertyDetailsFieldsDefinition(t);

  return (
    <PropertyDataList
      fieldDefinition={fields}
      property={property}
      title={t('Property details')}
    />
  );
};
