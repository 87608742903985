import * as React from 'react';
import type { GraphQLTaggedNode, Variables } from 'react-relay';
import { useMutation, fetchQuery, useRelayEnvironment } from 'react-relay';

type Query = {
  variables: Variables;
  response: Readonly<unknown>;
};

export type MutateOptions<M extends Query, Q extends Query> = {
  variables: M['variables'];
  onCompleted?: (
    mutationResponse: M['response'],
    queryResponse: Q['response'],
  ) => void;
};

type Options<M extends Query, Q extends Query> = {
  // Transform mutation response to polling query variables
  // If null is returned, we won't do the polling
  mutationResponseToVariables: (
    mutationResponse: M['response'],
  ) => Q['variables'] | null;
  // Given polling query response, should we stop the polling?
  isDone: (queryResponse: Q['response']) => boolean;
  // The time we should wait before making the first poll (after receiving the mutation response)
  initialDelay: number;
  // Wait period between polling requests
  basePeriod: number;
  // Rate of period increase: 1 = no increase, 2 = each period is twice as long as the previous one, etc.
  periodIncreaseRate: number;
  // Maximum time during which we do polling
  maxPollingTime: number;
};

const usePoller = <M extends Query, Q extends Query>(
  pollingQuery: GraphQLTaggedNode,
  options: Options<M, Q>,
) => {
  const environment = useRelayEnvironment();
  const isMounted = React.useRef(false);
  const latestUnsubscribe = React.useRef<null | (() => void)>(null);
  const latestDelayId = React.useRef<null | number>(null);

  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
      // clear on unmount
      latestUnsubscribe.current?.();
      if (latestDelayId.current != null) {
        clearTimeout(latestDelayId.current);
      }
    };
  }, []);

  const startPolling = (
    variables: Q['variables'],
    onCompleted: (response: null | Q['response']) => void,
  ) => {
    // reset when new mutation is started
    latestUnsubscribe.current?.();
    latestUnsubscribe.current = null;
    if (latestDelayId.current != null) {
      clearTimeout(latestDelayId.current);
    }
    latestDelayId.current = null;
    const fetchData = (startTime: number, period: number) => {
      if (isMounted.current === false) {
        return;
      }
      const subscription = fetchQuery(
        environment,
        pollingQuery,
        variables,
      ).subscribe({
        next: data => {
          // avoid unsubscribe from fullfilled query
          latestUnsubscribe.current = null;
          if (isMounted.current === false) {
            return;
          }
          if (options.isDone(data)) {
            onCompleted(data);
            return;
          }
          if (Date.now() - startTime > options.maxPollingTime) {
            console.error('Polling timeout');
            onCompleted(null);
            return;
          }
          latestDelayId.current = window.setTimeout(() => {
            // slow down next iteration a bit to not bloat network
            fetchData(startTime, period * options.periodIncreaseRate);
          }, period);
        },
      });
      latestUnsubscribe.current = subscription.unsubscribe;
    };
    // delay first query
    latestDelayId.current = window.setTimeout(() => {
      fetchData(Date.now(), options.basePeriod);
    }, options.initialDelay);
  };

  return startPolling;
};

export const useMutationWithPolling = <M extends Query, Q extends Query>(
  mutation: GraphQLTaggedNode,
  pollingQuery: GraphQLTaggedNode,
  options: Options<M, Q>,
): [mutate: (options: MutateOptions<M, Q>) => void, mutating: boolean] => {
  const [mutate, isMutating] = useMutation<M>(mutation);
  const [isPolling, setIsPolling] = React.useState(false);
  const startPolling = usePoller<M, Q>(pollingQuery, options);
  const enhancedMutate = ({ variables, onCompleted }: MutateOptions<M, Q>) => {
    mutate({
      variables,
      onCompleted: mutationResponse => {
        const variables = options.mutationResponseToVariables(mutationResponse);
        if (variables == null) {
          console.error('Bad mutation payload');
        }
        setIsPolling(variables != null);
        if (variables != null) {
          startPolling(variables, queryResponse => {
            setIsPolling(false);
            if (queryResponse != null) {
              onCompleted?.(mutationResponse, queryResponse);
            }
          });
        }
      },
    });
  };
  return [enhancedMutate, isMutating || isPolling];
};
