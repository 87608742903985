/**
 * @generated SignedSource<<6e8799b20c59be7ca1cfbbd154bfc4ed>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
type appraisalDrawer_appraisal$fragmentType = any;
import type { FragmentType } from "relay-runtime";
declare export opaque type AppraisalListItem_appraisal$fragmentType: FragmentType;
export type AppraisalListItem_appraisal$data = {|
  +createdAt: ?string,
  +property: ?{|
    +builtSurface: ?number,
    +countryCode: ?string,
    +livingSurface: ?number,
    +numberOfBathrooms: ?number,
    +numberOfBedrooms: ?number,
    +numberOfRooms: ?number,
  |},
  +realadvisor: ?{|
    +max: ?number,
    +min: ?number,
  |},
  +$fragmentSpreads: appraisalDrawer_appraisal$fragmentType,
  +$fragmentType: AppraisalListItem_appraisal$fragmentType,
|};
export type AppraisalListItem_appraisal$key = {
  +$data?: AppraisalListItem_appraisal$data,
  +$fragmentSpreads: AppraisalListItem_appraisal$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppraisalListItem_appraisal",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "appraisalDrawer_appraisal"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Property",
      "kind": "LinkedField",
      "name": "property",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "livingSurface",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "builtSurface",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numberOfRooms",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numberOfBedrooms",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numberOfBathrooms",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "countryCode",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RealadvisorAppraisal",
      "kind": "LinkedField",
      "name": "realadvisor",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "min",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "max",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Appraisal",
  "abstractKey": null
};

(node/*: any*/).hash = "0ac0b71e685087784e6409fdec900cdb";

export default node;
