// @flow

const tilesets = [
  {
    type: 'states',
    // z6 ~ z12
    borders: {
      id: 'spingwun.5wyk00dz',
      name: 'crm-state-poly-50szva',
    },
    centroids: {
      id: 'spingwun.8ppqlow0',
      name: 'crm-state-cent-apo7do',
    },
  },

  {
    type: 'districts',
    // z6 ~ z12
    borders: {
      id: 'spingwun.4w4r6qfz',
      name: 'crm-district-poly-04qfwh',
    },
    centroids: {
      id: 'spingwun.9ak8gtpl',
      name: 'crm-district-cent-7g7h6i',
    },
  },

  {
    type: 'municipalities',
    // z6 ~ z12
    borders: {
      id: 'spingwun.4ye0exut',
      name: 'crm-municipality-poly-45hk41',
    },
    centroids: {
      id: 'spingwun.7uzxn7n8',
      name: 'crm-municipality-cent-b7760h',
    },
  },

  {
    type: 'localities',
    // z6 ~ z12
    borders: {
      id: 'spingwun.amdtvcir',
      name: 'crm-locality-poly-0j1cq1',
    },
    centroids: {
      id: 'spingwun.cjfivoa5',
      name: 'crm-locality-cent-cmilwm',
    },
  },

  {
    type: 'neighbourhoods',
    // z7 ~ z12
    borders: {
      id: 'spingwun.ai67qy6j',
      name: 'fr-ch-neighbourhood-poly-2ismfw',
    },
    centroids: {
      id: 'spingwun.3egsbvpn',
      name: 'fr-ch-neighbourhood-cent-dz5bry',
    },
  },

  {
    type: 'postcodes',
    // z6 ~ z11
    borders: {
      id: 'spingwun.bp8iajwi',
      name: 'crm-postcode-poly-dul4xr',
    },
    centroids: {
      id: 'spingwun.c60heq5m',
      name: 'crm-postcode-cent-172adh',
    },
  },
];

export const MAP_STYLE: any = {
  version: 8,
  name: 'basic',
  metadata: {
    'mapbox:autocomposite': true,
  },
  center: [6.21251895173657, 46.216876686757985],
  zoom: 11.792148885505657,
  bearing: 0,
  pitch: 0,
  sources: {
    composite: {
      url: `mapbox://mapbox.mapbox-streets-v7,${tilesets
        .flatMap(({ centroids, borders }) => [centroids.id, borders.id])
        .join(',')}`,
      type: 'vector',
    },
  },
  sprite: 'mapbox://sprites/spingwun/cje5v0zg1fsmq2st9gft17851',
  glyphs: 'mapbox://fonts/spingwun/{fontstack}/{range}.pbf',
  created: '2018-02-27T16:21:55.456Z',
  id: 'cje5v0zg1fsmq2st9gft17851',
  modified: '2018-02-27T17:41:21.004Z',
  owner: 'spingwun',
  visibility: 'private',
  draft: false,

  layers: [
    {
      id: 'background',
      type: 'background',
      paint: {
        'background-color': '#dedede',
      },
    },
    {
      id: 'landuse_overlay_national_park',
      type: 'fill',
      source: 'composite',
      'source-layer': 'landuse_overlay',
      filter: ['==', 'class', 'national_park'],
      paint: {
        'fill-color': '#d2edae',
        'fill-opacity': 0.75,
      },
    },
    {
      id: 'landuse_park',
      type: 'fill',
      source: 'composite',
      'source-layer': 'landuse',
      filter: ['==', 'class', 'park'],
      paint: {
        'fill-color': '#d2edae',
      },
    },
    {
      id: 'waterway',
      type: 'line',
      source: 'composite',
      'source-layer': 'waterway',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['in', 'class', 'canal', 'river'],
      ],
      paint: {
        'line-color': '#a0cfdf',
        'line-width': {
          base: 1.4,
          stops: [
            [8, 0.5],
            [20, 15],
          ],
        },
      },
    },
    {
      id: 'water',
      type: 'fill',
      source: 'composite',
      'source-layer': 'water',
      paint: {
        'fill-color': '#a0cfdf',
      },
    },
    {
      id: 'building',
      type: 'fill',
      source: 'composite',
      'source-layer': 'building',
      paint: {
        'fill-color': '#d6d6d6',
      },
    },
    {
      id: 'tunnel_minor',
      type: 'line',
      source: 'composite',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        [
          'all',
          ['==', 'structure', 'tunnel'],
          [
            'in',
            'class',
            'link',
            'motorway_link',
            'path',
            'pedestrian',
            'service',
            'street',
            'street_limited',
            'track',
          ],
        ],
      ],
      layout: {
        'line-cap': 'butt',
        'line-join': 'miter',
      },
      paint: {
        'line-color': '#efefef',
        'line-width': {
          base: 1.55,
          stops: [
            [4, 0.25],
            [20, 30],
          ],
        },
        'line-dasharray': [0.36, 0.18],
      },
    },

    {
      id: 'tunnel_major',
      type: 'line',
      source: 'composite',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        [
          'all',
          ['==', 'structure', 'tunnel'],
          [
            'in',
            'class',
            'motorway',
            'primary',
            'secondary',
            'tertiary',
            'trunk',
          ],
        ],
      ],
      layout: {
        'line-cap': 'butt',
        'line-join': 'miter',
      },
      paint: {
        'line-color': '#fff',
        'line-width': {
          base: 1.4,
          stops: [
            [6, 0.5],
            [20, 30],
          ],
        },
        'line-dasharray': [0.28, 0.14],
      },
    },

    {
      id: 'road_minor',
      type: 'line',
      source: 'composite',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        [
          'all',
          [
            'in',
            'class',
            'link',
            'motorway_link',
            'path',
            'pedestrian',
            'service',
            'street',
            'street_limited',
            'track',
          ],
          ['in', 'structure', 'ford', 'none'],
        ],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': '#efefef',
        'line-width': {
          base: 1.55,
          stops: [
            [4, 0.25],
            [20, 30],
          ],
        },
      },
    },

    {
      id: 'road_major',
      type: 'line',
      source: 'composite',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        [
          'all',
          [
            'in',
            'class',
            'motorway',
            'primary',
            'secondary',
            'tertiary',
            'trunk',
          ],
          ['in', 'structure', 'ford', 'none'],
        ],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': '#fff',
        'line-width': {
          base: 1.4,
          stops: [
            [6, 0.5],
            [20, 30],
          ],
        },
      },
    },

    {
      id: 'bridge_minor case',
      type: 'line',
      source: 'composite',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        [
          'all',
          ['==', 'structure', 'bridge'],
          [
            'in',
            'class',
            'link',
            'motorway_link',
            'path',
            'pedestrian',
            'service',
            'street',
            'street_limited',
            'track',
          ],
        ],
      ],
      layout: {
        'line-cap': 'butt',
        'line-join': 'miter',
      },
      paint: {
        'line-color': '#dedede',
        'line-width': {
          base: 1.6,
          stops: [
            [12, 0.5],
            [20, 10],
          ],
        },
        'line-gap-width': {
          base: 1.55,
          stops: [
            [4, 0.25],
            [20, 30],
          ],
        },
      },
    },

    {
      id: 'bridge_major case',
      type: 'line',
      source: 'composite',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        [
          'all',
          ['==', 'structure', 'bridge'],
          [
            'in',
            'class',
            'motorway',
            'primary',
            'secondary',
            'tertiary',
            'trunk',
          ],
        ],
      ],
      layout: {
        'line-cap': 'butt',
        'line-join': 'miter',
      },
      paint: {
        'line-color': '#dedede',
        'line-width': {
          base: 1.6,
          stops: [
            [12, 0.5],
            [20, 10],
          ],
        },
        'line-gap-width': {
          base: 1.55,
          stops: [
            [4, 0.25],
            [20, 30],
          ],
        },
      },
    },

    {
      id: 'bridge_minor',
      type: 'line',
      source: 'composite',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        [
          'all',
          ['==', 'structure', 'bridge'],
          [
            'in',
            'class',
            'link',
            'motorway_link',
            'path',
            'pedestrian',
            'service',
            'street',
            'street_limited',
            'track',
          ],
        ],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': '#efefef',
        'line-width': {
          base: 1.55,
          stops: [
            [4, 0.25],
            [20, 30],
          ],
        },
      },
    },

    {
      id: 'bridge_major',
      type: 'line',
      source: 'composite',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        [
          'all',
          ['==', 'structure', 'bridge'],
          [
            'in',
            'class',
            'motorway',
            'primary',
            'secondary',
            'tertiary',
            'trunk',
          ],
        ],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': '#fff',
        'line-width': {
          base: 1.4,
          stops: [
            [6, 0.5],
            [20, 30],
          ],
        },
      },
    },

    {
      id: 'admin_country',
      type: 'line',
      source: 'composite',
      'source-layer': 'admin',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['<=', 'admin_level', 2], ['==', 'maritime', 0]],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': '#8b8a8a',
        'line-width': {
          base: 1.3,
          stops: [
            [3, 0.5],
            [22, 15],
          ],
        },
      },
    },

    ...tilesets.flatMap(x => [
      // Selected
      {
        id: `${x.type}-selected`,
        type: 'fill',
        source: 'composite',
        'source-layer': x.borders.name,
        paint: { 'fill-color': 'hsla(212, 89%, 37%, 0.6)' },
        filter: ['none'],
      },

      // Outline border
      {
        id: `${x.type}-boundaries`,
        type: 'line',
        metadata: {},
        source: 'composite',
        'source-layer': x.borders.name,
        filter: ['==', '$type', 'Polygon'],
        paint: {
          'line-color': 'hsla(204, 100%, 33%, 0.64)',
          'line-width': 2,
          'line-opacity': 0.5,
        },
      },

      // Labels
      {
        id: `${x.type}-labels`,
        type: 'symbol',
        source: 'composite',
        'source-layer': x.centroids.name,
        filter: ['==', '$type', 'Point'],
        layout: { 'text-field': '{label_en}', 'text-size': 14 },
        paint: {
          'text-color': 'hsla(0, 0%, 0%, 0.8)',
          'text-halo-color': 'hsl(0, 0%, 100%)',
          'text-halo-width': 1.5,
        },
      },

      // Transparent polygons for click events
      {
        id: x.type,
        type: 'fill',
        source: 'composite',
        'source-layer': x.borders.name,
        paint: { 'fill-color': 'rgba(0, 0, 0, 0)' },
      },
    ]),
  ],
};
