/**
 * @generated SignedSource<<ecf6dccd3acc914e7825078e9b8bb2ae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EmailForm_initialTemplate$data = {
  readonly addSignature: boolean | null;
  readonly bcc: any | null;
  readonly cc: any | null;
  readonly files: ReadonlyArray<{
    readonly id: string;
    readonly mimetype: string | null;
    readonly name: string;
    readonly size: number | null;
    readonly url: string;
  } | null>;
  readonly id: string;
  readonly name: string | null;
  readonly subject: string | null;
  readonly text: string | null;
  readonly " $fragmentType": "EmailForm_initialTemplate";
};
export type EmailForm_initialTemplate$key = {
  readonly " $data"?: EmailForm_initialTemplate$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmailForm_initialTemplate">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmailForm_initialTemplate",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cc",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bcc",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subject",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "addSignature",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "files",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "size",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mimetype",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EmailTemplate",
  "abstractKey": null
};
})();

(node as any).hash = "e56142a0094faa11a340d163b1878c56";

export default node;
