export type Handle = {
  id: null | number;
};

export function cancelAnimationTimeout(frame: null | Handle) {
  if (frame?.id != null) {
    cancelAnimationFrame(frame.id);
  }
}

/**
 * Recursively calls requestAnimationFrame until a specified delay has been met
 * or exceeded. When the delay time has been reached the function you're timing
 * out will be called.
 *
 * Credit: Joe Lambert (https://gist.github.com/joelambert/1002116#file-requesttimeout-js)
 */
export function requestAnimationTimeout(
  delay: number,
  func: () => void,
): Handle {
  const start = Date.now();
  const frame: Handle = { id: null };

  function timeout() {
    if (Date.now() - start >= delay) {
      func();
    } else {
      frame.id = requestAnimationFrame(timeout);
    }
  }

  frame.id = requestAnimationFrame(timeout);
  return frame;
}
