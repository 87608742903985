/**
 * @generated SignedSource<<c4ef1beea564753d77b8d3b118882e6d>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type propertyCard_root$fragmentType: FragmentType;
export type propertyCard_root$data = {|
  +tenantSettings: ?{|
    +id: string,
  |},
  +$fragmentType: propertyCard_root$fragmentType,
|};
export type propertyCard_root$key = {
  +$data?: propertyCard_root$data,
  +$fragmentSpreads: propertyCard_root$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "propertyCard_root",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Tenant",
      "kind": "LinkedField",
      "name": "tenantSettings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node/*: any*/).hash = "cac665053f868f86a4df267e0f468581";

export default node;
