/**
 * @generated SignedSource<<9f6b5520fbf2ee0da05637508c265ab5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type emailSequenceDialogUserLoginsQuery$variables = {
  id?: string | null;
};
export type emailSequenceDialogUserLoginsQuery$data = {
  readonly users_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly logins: ReadonlyArray<{
          readonly __typename: "logins";
        }>;
        readonly nylas_account_config: ReadonlyArray<{
          readonly sync_state: string | null;
        }>;
      };
    }>;
  };
};
export type emailSequenceDialogUserLoginsQuery = {
  response: emailSequenceDialogUserLoginsQuery$data;
  variables: emailSequenceDialogUserLoginsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "where",
      "value": {
        "provider": {
          "_eq": "nylas"
        }
      }
    }
  ],
  "concreteType": "logins",
  "kind": "LinkedField",
  "name": "logins",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    }
  ],
  "storageKey": "logins(where:{\"provider\":{\"_eq\":\"nylas\"}})"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sync_state",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "emailSequenceDialogUserLoginsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "usersConnection",
        "kind": "LinkedField",
        "name": "users_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "usersEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "users",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "nylas_account_configs",
                    "kind": "LinkedField",
                    "name": "nylas_account_config",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "emailSequenceDialogUserLoginsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "usersConnection",
        "kind": "LinkedField",
        "name": "users_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "usersEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "users",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "nylas_account_configs",
                    "kind": "LinkedField",
                    "name": "nylas_account_config",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1797fc8af982aed09be773a8ffdb5474",
    "id": null,
    "metadata": {},
    "name": "emailSequenceDialogUserLoginsQuery",
    "operationKind": "query",
    "text": "query emailSequenceDialogUserLoginsQuery(\n  $id: uuid\n) {\n  users_connection(where: {id: {_eq: $id}}) {\n    edges {\n      node {\n        logins(where: {provider: {_eq: \"nylas\"}}) {\n          __typename\n        }\n        nylas_account_config {\n          sync_state\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7120620bc5a2944dec6a82290dc1348c";

export default node;
