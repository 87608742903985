import type * as React from 'react';

import { ArrowDropDown } from '../../icons/arrow-drop-down';
import { Cancel } from '../../icons/cancel';

type Props = {
  empty: boolean;
  timeout?: number;
  onClick?: (e: React.MouseEvent) => void;
};

const IconHolder = ({
  visible,
  timeout,
  children,
}: {
  visible: boolean;
  timeout: number;
  children: React.ReactNode;
}) => {
  return (
    <div
      css={{
        lineHeight: 0,
        position: 'absolute',
        left: 0,
        top: 0,
        opacity: visible ? 1 : 0,
        transition: `opacity ${timeout}ms ease`,
      }}
    >
      {children}
    </div>
  );
};

export const AnimChipIcons = ({ empty, timeout = 250, onClick }: Props) => (
  <div
    css={{
      lineHeight: 0,
      position: 'relative',
      width: 24,
      height: 24,
      marginRight: 3,
    }}
    onClick={onClick}
  >
    <IconHolder visible={empty === true} timeout={timeout}>
      <ArrowDropDown />
    </IconHolder>
    <IconHolder visible={empty === false} timeout={timeout}>
      <Cancel />
    </IconHolder>
  </div>
);
