/**
 * @generated SignedSource<<5033218fee65831346a2560f4e71b37c>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
type placeListItem_place$fragmentType = any;
export type placePickerListQuery$variables = {|
  ids?: ?$ReadOnlyArray<string>,
|};
export type placePickerListQuery$data = {|
  +places: $ReadOnlyArray<{|
    +objectId: string,
    +$fragmentSpreads: placeListItem_place$fragmentType,
  |}>,
|};
export type placePickerListQuery = {|
  response: placePickerListQuery$data,
  variables: placePickerListQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "all",
    "value": true
  },
  {
    "kind": "Literal",
    "name": "limit",
    "value": 100
  },
  {
    "kind": "Variable",
    "name": "objectId_in",
    "variableName": "ids"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  (v3/*: any*/),
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "placePickerListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Place",
        "kind": "LinkedField",
        "name": "places",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "placeListItem_place"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "placePickerListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Place",
        "kind": "LinkedField",
        "name": "places",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": "country",
            "args": [
              {
                "kind": "Literal",
                "name": "type",
                "value": "country"
              }
            ],
            "concreteType": "Place",
            "kind": "LinkedField",
            "name": "parentPlace",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": "parentPlace(type:\"country\")"
          },
          {
            "alias": "state",
            "args": [
              {
                "kind": "Literal",
                "name": "type",
                "value": "state"
              }
            ],
            "concreteType": "Place",
            "kind": "LinkedField",
            "name": "parentPlace",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": "parentPlace(type:\"state\")"
          },
          {
            "alias": "municipality",
            "args": [
              {
                "kind": "Literal",
                "name": "type",
                "value": "municipality"
              }
            ],
            "concreteType": "Place",
            "kind": "LinkedField",
            "name": "parentPlace",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": "parentPlace(type:\"municipality\")"
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "261af20cf73d410b42b74f44de058c49",
    "id": null,
    "metadata": {},
    "name": "placePickerListQuery",
    "operationKind": "query",
    "text": "query placePickerListQuery(\n  $ids: [String!]\n) {\n  places(objectId_in: $ids, limit: 100, all: true) {\n    objectId\n    ...placeListItem_place\n    id\n  }\n}\n\nfragment placeListItem_place on Place {\n  label\n  type\n  country: parentPlace(type: country) {\n    label\n    id\n  }\n  state: parentPlace(type: state) {\n    label\n    id\n  }\n  municipality: parentPlace(type: municipality) {\n    label\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "e0761eaee4505b1a6830c4c67616005a";

export default node;
