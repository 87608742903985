import { type Translate, useLocale } from '../../src/hooks/locale';
import { gql } from '../__generated__';
import type { PropertyPoiFragment } from '../__generated__/graphql';
import type { GetListingDetailsData } from '../pages/listings/lotsQueries';

import { PropertyDataList, type TFieldsDefinition } from './PropertyDataList';

type TProperty = NonNullable<GetListingDetailsData['property']>;

export const PROPERTY_POI_FRAGMENT = gql(`
  fragment PropertyPoi on properties {
    distance_to_airport
    distance_to_hospital
    distance_to_motorway
    distance_to_city_center
    distance_to_high_school
    distance_to_grocery_shop
    distance_to_kindergarten
    distance_to_sports_center
    distance_to_train_station
    distance_to_primary_school
    distance_to_public_transport
  }
`);

interface PointsOfInterestProps {
  property: TProperty;
}

export const getPropertyDetailsFieldsDefinition = (
  t: Translate,
): TFieldsDefinition<keyof PropertyPoiFragment> => {
  const metersSuffix = (value: number | null | undefined) =>
    value != null ? `${value} m` : null;

  return {
    distance_to_airport: {
      label: t('Airport'),
      name: 'distance_to_airport',
      renderFn: metersSuffix,
    },
    distance_to_hospital: {
      label: t('Hospital'),
      name: 'distance_to_hospital',
      renderFn: metersSuffix,
    },
    distance_to_motorway: {
      label: t('Motorway'),
      name: 'distance_to_motorway',
      renderFn: metersSuffix,
    },
    distance_to_city_center: {
      label: t('City center'),
      name: 'distance_to_city_center',
      renderFn: metersSuffix,
    },
    distance_to_high_school: {
      label: t('High school'),
      name: 'distance_to_high_school',
      renderFn: metersSuffix,
    },
    distance_to_grocery_shop: {
      label: t('Supermarket'),
      name: 'distance_to_grocery_shop',
      renderFn: metersSuffix,
    },
    distance_to_kindergarten: {
      label: t('Kindergarden'),
      name: 'distance_to_kindergarten',
      renderFn: metersSuffix,
    },
    distance_to_sports_center: {
      label: t('Sports center'),
      name: 'distance_to_sports_center',
      renderFn: metersSuffix,
    },
    distance_to_train_station: {
      label: t('Train station'),
      name: 'distance_to_train_station',
      renderFn: metersSuffix,
    },
    distance_to_primary_school: {
      label: t('Primary school'),
      name: 'distance_to_primary_school',
      renderFn: metersSuffix,
    },
    distance_to_public_transport: {
      label: t('Public transport'),
      name: 'distance_to_public_transport',
      renderFn: metersSuffix,
    },
  };
};

export const PointsOfInterest: React.FC<PointsOfInterestProps> = ({
  property,
}) => {
  const { t } = useLocale();
  const fields = getPropertyDetailsFieldsDefinition(t);

  return (
    <PropertyDataList
      fieldDefinition={fields}
      property={property}
      title={t('Points of interest nearby')}
    />
  );
};
