// Generated from src/svgs/cma-report.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const CmaReport = ({ size, fill, className }: Props) => {
  return (
    <svg viewBox="0 0 25 25" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><path fillRule="evenodd" clipRule="evenodd" d="M3.5 22h16a1.5 1.5 0 0 0 1.5-1.5v-19A1.5 1.5 0 0 0 19.5 0h-16A1.5 1.5 0 0 0 2 1.5v19A1.5 1.5 0 0 0 3.5 22Zm14-9h-12c-.25 0-.5-.25-.5-.5s.25-.5.5-.5H6V4.5a.5.5 0 0 1 1 0V12h2V7.5a.5.5 0 0 1 1 0V12h2V9.5a.5.5 0 0 1 1 0V12h2V2.5a.5.5 0 0 1 1 0V12h1.5a.5.5 0 0 1 0 1ZM24 4.5v19a1.5 1.5 0 0 1-1.5 1.5h-16A1.5 1.5 0 0 1 5 23.5V23h15a2 2 0 0 0 2-2V3h.5A1.5 1.5 0 0 1 24 4.5Zm-6 14a.5.5 0 0 0-.5-.5h-12a.5.5 0 0 0 0 1h12a.5.5 0 0 0 .5-.5Zm-.5-3.5a.5.5 0 0 1 0 1h-12a.5.5 0 0 1 0-1h12Z" /></svg>
  );
}
