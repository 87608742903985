import { useMemo } from 'react';

import { sanitizeHtml } from '@realadvisor/html-sanitizer';
import { marked } from 'marked';

const sanitizeBacktick = (text: string) => text.replace(/`/g, '&#x60;');

const MarkdownParser: React.FC<{
  markdown: string;
  disableLinks?: boolean;
}> = ({ markdown, disableLinks = false }) => {
  const html = useMemo(() => {
    const renderer = new marked.Renderer();

    if (disableLinks) {
      renderer.link = (_, __, text) => text;
    }

    // "as string" is needed because of the wrong type definition of marked.parse.
    return sanitizeHtml(
      marked.parse(sanitizeBacktick(markdown), {
        renderer,
        breaks: true,
      }) as string,
    );
  }, [markdown, disableLinks]);

  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

export default MarkdownParser;
