/**
 * @generated SignedSource<<dc4902dbaff545fb23797ef7d514c329>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
type placeListItem_place$fragmentType = any;
export type TypeOfPlace = "agglomeration" | "country" | "district" | "locality" | "municipality" | "neighbourhood" | "postcode" | "region" | "state";
export type placePickerInputQuery$variables = {|
  allowedPlaceTypes?: ?$ReadOnlyArray<?TypeOfPlace>,
  search?: ?string,
|};
export type placePickerInputQuery$data = {|
  +places: $ReadOnlyArray<{|
    +breadcrumbs: $ReadOnlyArray<{|
      +id: string,
    |}>,
    +id: string,
    +label: string,
    +lat: number,
    +lng: number,
    +objectId: string,
    +population: ?number,
    +type: TypeOfPlace,
    +$fragmentSpreads: placeListItem_place$fragmentType,
  |}>,
|};
export type placePickerInputQuery = {|
  response: placePickerInputQuery$data,
  variables: placePickerInputQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "allowedPlaceTypes"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v2 = [
  {
    "kind": "Literal",
    "name": "limit",
    "value": 20
  },
  {
    "kind": "Variable",
    "name": "placeType_in",
    "variableName": "allowedPlaceTypes"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lat",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lng",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "population",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "incudeNonIndexed",
      "value": true
    }
  ],
  "concreteType": "Place",
  "kind": "LinkedField",
  "name": "breadcrumbs",
  "plural": true,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": "breadcrumbs(incudeNonIndexed:true)"
},
v11 = [
  (v8/*: any*/),
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "placePickerInputQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Place",
        "kind": "LinkedField",
        "name": "places",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "placeListItem_place"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "placePickerInputQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Place",
        "kind": "LinkedField",
        "name": "places",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "alias": "country",
            "args": [
              {
                "kind": "Literal",
                "name": "type",
                "value": "country"
              }
            ],
            "concreteType": "Place",
            "kind": "LinkedField",
            "name": "parentPlace",
            "plural": false,
            "selections": (v11/*: any*/),
            "storageKey": "parentPlace(type:\"country\")"
          },
          {
            "alias": "state",
            "args": [
              {
                "kind": "Literal",
                "name": "type",
                "value": "state"
              }
            ],
            "concreteType": "Place",
            "kind": "LinkedField",
            "name": "parentPlace",
            "plural": false,
            "selections": (v11/*: any*/),
            "storageKey": "parentPlace(type:\"state\")"
          },
          {
            "alias": "municipality",
            "args": [
              {
                "kind": "Literal",
                "name": "type",
                "value": "municipality"
              }
            ],
            "concreteType": "Place",
            "kind": "LinkedField",
            "name": "parentPlace",
            "plural": false,
            "selections": (v11/*: any*/),
            "storageKey": "parentPlace(type:\"municipality\")"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a58bda0f42e3194bf8218872be41ef87",
    "id": null,
    "metadata": {},
    "name": "placePickerInputQuery",
    "operationKind": "query",
    "text": "query placePickerInputQuery(\n  $search: String\n  $allowedPlaceTypes: [TypeOfPlace]\n) {\n  places(search: $search, limit: 20, placeType_in: $allowedPlaceTypes) {\n    id\n    type\n    objectId\n    lat\n    lng\n    label\n    population\n    breadcrumbs(incudeNonIndexed: true) {\n      id\n    }\n    ...placeListItem_place\n  }\n}\n\nfragment placeListItem_place on Place {\n  label\n  type\n  country: parentPlace(type: country) {\n    label\n    id\n  }\n  state: parentPlace(type: state) {\n    label\n    id\n  }\n  municipality: parentPlace(type: municipality) {\n    label\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "0eb16ead0cfb86890e6cf9e6f297c7c5";

export default node;
