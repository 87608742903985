import * as React from 'react';

import { Accordion, CircularProgress, Collapse } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { graphql, useFragment, useMutation } from 'react-relay';
import { Flex } from 'react-system';

import { useLocale } from '../hooks/locale';
import { EmailCheck } from '../icons/email-check';

import type { leadBrokerCard_lead$key } from './__generated__/leadBrokerCard_lead.graphql';
import type { leadBrokerCard_root$key } from './__generated__/leadBrokerCard_root.graphql';
import type { leadBrokerCardAssignMutation } from './__generated__/leadBrokerCardAssignMutation.graphql';
import { AccordionCardSummary } from './accordion-card-summary';
import { UserEditableCard } from './user-editable-card';

type Props = {
  root: leadBrokerCard_root$key;
  lead: leadBrokerCard_lead$key;
  onChange: () => void;
  defaultExpanded: boolean;
};

export const LeadBrokerCard = (props: Props) => {
  const { t } = useLocale();
  const { me } = useFragment(
    graphql`
      fragment leadBrokerCard_root on Query {
        me {
          isAdmin
        }
      }
    `,
    props.root,
  );
  const lead = useFragment(
    graphql`
      fragment leadBrokerCard_lead on Lead {
        id
        broker {
          ...userEditableCard_user
          displayName
          primaryImage {
            url
          }
        }

        contact {
          broker {
            ...userEditableCard_user
          }
        }
        property {
          id
          brokersInCatchmentArea {
            id
          }
        }
      }
    `,
    props.lead,
  );
  const [assignLeads, updating] = useMutation<leadBrokerCardAssignMutation>(
    graphql`
      mutation leadBrokerCardAssignMutation($input: AssignLeadsInput!) {
        assignLeads(input: $input) {
          leads {
            ...leadBrokerCard_lead
            stage {
              label
              pipeline {
                label
              }
            }
          }
        }
      }
    `,
  );

  const brokersInAreaIds =
    lead.property?.brokersInCatchmentArea?.map(user => user.id) ?? [];

  const [expanded, setExpanded] = React.useState(props.defaultExpanded);

  const [brokerNotification, setBrokerNotification] = React.useState(false);

  React.useEffect(() => {
    if (brokerNotification) {
      const timeout = setTimeout(() => setBrokerNotification(false), 5000);
      return () => clearTimeout(timeout);
    }
  }, [brokerNotification]);

  const statusOrder = [
    'active',
    'trialing',
    'past_due',
    'unpaid',
    'incomplete',
    'incomplete_expired',
    'canceled',
    'no_status',
  ];

  const imageUrl = lead.broker?.primaryImage?.url ?? null;

  return (
    <Accordion
      expanded={expanded}
      onChange={(_event, expanded) => {
        setExpanded(expanded);
      }}
    >
      <AccordionCardSummary
        expanded={expanded}
        title={t('broker')}
        subTitle={lead.broker?.displayName}
        avatarUrl={imageUrl}
      />
      <Collapse in={brokerNotification}>
        <Alert severity="success" icon={<EmailCheck />}>
          {t('brokerHasBeenNotified')}
        </Alert>
      </Collapse>
      {updating ? (
        <Flex flexGrow={1} justifyContent="center" alignItems="center" p={3}>
          <CircularProgress disableShrink />
        </Flex>
      ) : (
        <UserEditableCard
          user={lead.broker}
          label={t('selectBroker')}
          menuButtonTitle="Edit agent"
          prefill={
            lead.broker ? null : lead.contact ? lead.contact.broker : null
          }
          showDelete={me?.isAdmin === true}
          showSubscriptionStatus={me?.isAdmin === true}
          filters={{
            isBroker: true,
            hasLeadsModule: true,
            only_in_current_teams: me?.isAdmin !== true,
            id_sort_first_in: brokersInAreaIds,
          }}
          onChange={user => {
            assignLeads({
              variables: {
                input: {
                  id: [lead.id],
                  brokerId: user?.id ?? null,
                },
              },
              onCompleted: () => {
                if (user?.id != null) {
                  setBrokerNotification(true);
                }
                props.onChange();
              },
            });
          }}
          sections={[
            {
              title: t('brokersInCatchmentArea'),
              filter: node => brokersInAreaIds.includes(node.id),
              sort: (a, b) => {
                return (
                  statusOrder.indexOf(a.subscription?.status ?? 'no_status') -
                  statusOrder.indexOf(b.subscription?.status ?? 'no_status')
                );
              },
              notFoundMessage: t('noAgentsFound'),
            },
            {
              title: t('brokersOutsideOfCatchmentArea'),
              filter: node => !brokersInAreaIds.includes(node.id),
              notFoundMessage: t('noAgentsFound'),
            },
          ]}
        />
      )}
    </Accordion>
  );
};
