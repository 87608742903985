import { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Alert, Grid, LinearProgress } from '@mui/material';
import { useFileDialog } from '@realadvisor/hooks';
import { v4 } from 'uuid';

import { FullScreenDropZone } from '../../../shared/dropzone';
import { UploadImageCard } from '../../../shared/image-manager/upload-image-card';
import type {
  GetOrganisationImagesQuery,
  GetTeamImagesQuery,
  GetUserImagesQuery,
  PrepareUploadMutation,
  PrepareUploadMutationVariables,
} from '../../__generated__/graphql';
import { PREPARE_UPLOAD } from '../../utils/files/fileQueries';
import { RaPre } from '../RaPre';

import { ImageCard } from './ImageCard';
import {
  GET_ORGANISATION_IMAGES,
  GET_TEAM_IMAGES,
  GET_USER_IMAGES,
  INSERT_IMAGE_MUTATIONS,
} from './imageGalleryQueries';

type CrossTableRecord = {
  id: string;
  order_nr?: number;
  isLoading?: boolean;
  image?: {
    id?: string;
    url?: string;
    signedUrl?: string; // For new uploads
  };
  file?: File; // For new uploads
  error?: Error;
};

interface State {
  images: CrossTableRecord[];
  imagesToUpload: CrossTableRecord[];
  imagesUploading: CrossTableRecord[];
}

type Action =
  | {
      type: 'SET_INITIAL_IMAGES';
      payload: CrossTableRecord[];
    }
  | {
      type: 'ADD_IMAGE_FILE';
      payload: CrossTableRecord;
    }
  | {
      type: 'SET_UPLOAD_START';
      payload: {
        id: string;
      };
    }
  | {
      type: 'SET_UPLOAD_SUCCESS';
      payload: {
        id: string;
      };
    }
  | {
      type: 'SET_UPLOAD_ERROR';
      payload: {
        id: string;
        error: Error;
      };
    };

const initialState: State = {
  images: [],
  imagesToUpload: [],
  imagesUploading: [],
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_INITIAL_IMAGES':
      return {
        ...state,
        images: action.payload.sort(
          (a, b) => (a.order_nr ?? 0) - (b.order_nr ?? 0),
        ),
      };
    case 'ADD_IMAGE_FILE': {
      // get max order_nr
      const maxOrderNr = state.images.reduce(
        (acc, image) => Math.max(acc, image.order_nr ?? 0),
        0,
      );
      const newImage = {
        ...action.payload,
        order_nr: maxOrderNr + 1,
      };
      return {
        ...state,
        images: [...state.images, newImage].sort(
          (a, b) => (a.order_nr ?? 0) - (b.order_nr ?? 0),
        ),
        imagesToUpload: [...state.imagesToUpload, newImage],
      };
    }
    case 'SET_UPLOAD_START': {
      const record = state.images.find(image => image.id === action.payload.id);
      if (!record) {
        return state;
      }
      return {
        ...state,
        imagesToUpload: state.imagesToUpload.filter(
          image => image.id !== action.payload.id,
        ),
        imagesUploading: [...state.imagesUploading, record],
      };
    }
    case 'SET_UPLOAD_SUCCESS':
      return {
        ...state,
        images: state.images.map(file =>
          file.id === action.payload.id ? { ...file, isLoading: false } : file,
        ),
        imagesUploading: state.imagesUploading.filter(
          image => image.id !== action.payload.id,
        ),
      };
    case 'SET_UPLOAD_ERROR':
      return {
        ...state,
        images: state.images.map(record =>
          record.id === action.payload.id
            ? { ...record, isLoading: false, error: action.payload.error }
            : record,
        ),
        imagesUploading: state.imagesUploading.filter(
          image => image.id !== action.payload.id,
        ),
      };
    default:
      return state;
  }
};

interface ImageGalleryProps {
  parentId: string;
  parentType: 'user' | 'team' | 'organisation';
  cardActions?: React.FunctionComponent<{
    id: string;
    imageId?: string;
  }>;
}

export const ImageGallery = ({
  parentId,
  parentType,
  cardActions,
}: ImageGalleryProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const previousUploadingImagesCount = useRef(0);
  const [insertImage] = INSERT_IMAGE_MUTATIONS[parentType].useMutation(
    INSERT_IMAGE_MUTATIONS[parentType].mutation,
  );

  const openFileDialog = useFileDialog({
    accept: 'image/*',
    onChange: files => files.forEach(handleFileDrop),
  });

  const [prepareUpload] = useMutation<PrepareUploadMutation>(PREPARE_UPLOAD, {
    onError: (error, context) => {
      const {
        variables: { id },
      } = context as { variables: PrepareUploadMutationVariables };
      dispatch({
        type: 'SET_UPLOAD_ERROR',
        payload: {
          id,
          error,
        },
      });
    },
  });

  // Define all the queries
  const queries = {
    team: useQuery<GetTeamImagesQuery>(GET_TEAM_IMAGES, {
      variables: { team_id: parentId },
      skip: parentType !== 'team',
    }),
    user: useQuery<GetUserImagesQuery>(GET_USER_IMAGES, {
      variables: { user_id: parentId },
      skip: parentType !== 'user',
    }),
    organisation: useQuery<GetOrganisationImagesQuery>(
      GET_ORGANISATION_IMAGES,
      {
        variables: { organisation_id: parentId },
        skip: parentType !== 'organisation',
      },
    ),
  };

  // Extract data, loading, and error based on parentType
  const { data, loading, error, refetch } = queries[parentType];

  type AllQueriesData = GetTeamImagesQuery &
    GetUserImagesQuery &
    GetOrganisationImagesQuery;

  const images = (data as AllQueriesData)?.[`${parentType}_images`];

  useMemo(() => {
    if (!images) {
      return;
    }
    dispatch({
      type: 'SET_INITIAL_IMAGES',
      payload: images.map(image => ({
        id: image.id,
        order_nr: image.order_nr ?? undefined,
        isLoading: false,
        image: {
          id: image.image?.id ?? undefined,
          url: image.image?.url ?? undefined,
        },
      })),
    });
  }, [images]);

  const handleFileDrop = useCallback((file: File) => {
    dispatch({
      type: 'ADD_IMAGE_FILE',
      payload: {
        id: v4(),
        file,
        isLoading: true,
        image: {
          id: v4(),
          url: URL.createObjectURL(file),
        },
      },
    });
    previousUploadingImagesCount.current += 1;
  }, []);

  useEffect(() => {
    const upload = async (crossTableRecord: CrossTableRecord) => {
      const { file, id, order_nr, image } = crossTableRecord;

      if (!file) {
        return;
      }
      dispatch({
        type: 'SET_UPLOAD_START',
        payload: {
          id,
        },
      });

      const { data: preparedUploadData } = await prepareUpload({
        variables: {
          id,
          file_type: 'image',
          file_name: file.name,
          mime_type: file.type,
        },
      });

      if (!preparedUploadData?.prepare_upload?.signed_url) {
        console.error('Failed to get signed url');
        return;
      }

      const uploadResponse = await fetch(
        preparedUploadData.prepare_upload.signed_url,
        {
          method: 'PUT',
          headers: {
            'Content-Type': file.type,
          },
          body: file,
        },
      );

      if (!uploadResponse.ok) {
        console.error('Failed to upload file:', uploadResponse.statusText);
        dispatch({
          type: 'SET_UPLOAD_ERROR',
          payload: {
            id,
            error: new Error(uploadResponse.statusText),
          },
        });
        return;
      }

      // set image in the DB using hasura and apollo
      await insertImage({
        variables: {
          object: {
            id,
            [`${parentType}_id`]: parentId,
            order_nr,
            image: {
              data: {
                id: image?.id,
                url: preparedUploadData.prepare_upload.url,
              },
            },
          },
        },
      });
      dispatch({
        type: 'SET_UPLOAD_SUCCESS',
        payload: {
          id,
        },
      });
    };

    state.imagesToUpload.forEach(record => {
      if (state.imagesUploading.some(uploading => record.id === uploading.id)) {
        return;
      }
      upload(record);
    });

    if (
      previousUploadingImagesCount.current > 0 &&
      state.imagesUploading.length === 0 &&
      state.imagesToUpload.length === 0
    ) {
      // All images are uploaded, refresh cache.
      refetch();
      previousUploadingImagesCount.current = 0;
    }
  }, [
    refetch,
    insertImage,
    parentId,
    parentType,
    prepareUpload,
    state.imagesToUpload,
    state.imagesUploading,
  ]);

  if (error) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </Alert>
    );
  }

  return (
    <>
      <FullScreenDropZone onDrop={files => files.forEach(handleFileDrop)} />
      {loading && (
        <LinearProgress
          sx={{
            position: 'absolute',
            top: 0,
            width: '100%',
            zIndex: 2000,
          }}
        />
      )}
      <Grid container padding={2} spacing={2}>
        {state.images.map(record => (
          <Grid item xs={12} md={6} lg={3} key={record.id}>
            <ImageCard
              id={record.id}
              imageUrl={record.image?.url}
              isLoading={record.isLoading}
              error={record.error}
            >
              {cardActions?.({
                id: record.id,
                imageId: record.image?.id,
              })}
            </ImageCard>
          </Grid>
        ))}
        <Grid item xs={12}>
          <UploadImageCard onClick={openFileDialog} />
        </Grid>
      </Grid>
      <RaPre json={state.images} />
    </>
  );
};
