/**
 * @generated SignedSource<<cd6aafebe2e66c4497b551fda90c2ccf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeedItem_root$data = {
  readonly me: {
    readonly isAdmin: boolean | null;
    readonly nylasAccount: {
      readonly calendarSyncEnabled: boolean;
      readonly email: string | null;
    } | null;
    readonly userIdsInLedTeams: ReadonlyArray<string>;
    readonly " $fragmentSpreads": FragmentRefs<"FeedEmail_me">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"ActivityForm_root" | "FeedCall_root" | "FeedTask_root" | "FeedVisit_root">;
  readonly " $fragmentType": "FeedItem_root";
};
export type FeedItem_root$key = {
  readonly " $data"?: FeedItem_root$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedItem_root">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedItem_root",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "me",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FeedEmail_me"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "NylasAccount",
          "kind": "LinkedField",
          "name": "nylasAccount",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "calendarSyncEnabled",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isAdmin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userIdsInLedTeams",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeedVisit_root"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeedCall_root"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeedTask_root"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ActivityForm_root"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "6d2f6f26256a8b7b98d4b274430d51ef";

export default node;
