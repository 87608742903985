import type { MutableRefObject, ReactNode } from 'react';

import { Box, Divider, Paper } from '@mui/material';

import type { PageId } from '../shared';

import type { CMAReportType } from './CMAReportEditorDrawer';
import DesktopPreview from './DesktopPreview';

type DesktopLayoutProps = {
  stepper: ReactNode;
  editor: ReactNode;
  cmaReport: CMAReportType | null;
  viewerPageId: PageId;
  iFrameRef: MutableRefObject<HTMLIFrameElement>;
  scrollToPageRef: MutableRefObject<(pageId: PageId) => void>;
};

const DesktopLayout = ({
  cmaReport,
  stepper,
  editor,
  viewerPageId,
  scrollToPageRef,
  iFrameRef,
}: DesktopLayoutProps) => (
  <Box
    sx={{
      display: 'grid',
      gridTemplateColumns: 'max(450px, 30vw) 1fr',
      overflow: 'hidden',
      height: '100%',
    }}
  >
    <Paper
      square
      elevation={4}
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          height: 0,
        }}
      >
        <Box sx={{ width: '100%' }}>{stepper}</Box>
        <Divider />
        {editor}
      </Box>
    </Paper>
    {cmaReport && (
      <DesktopPreview
        cmaReport={cmaReport}
        iFrameRef={iFrameRef}
        viewerPageId={viewerPageId}
        scrollToPageRef={scrollToPageRef}
      />
    )}
  </Box>
);

export default DesktopLayout;
