import { gql, useMutation, useQuery } from '@apollo/client';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
} from '@mui/material';

import { fromGlobalId } from '../../shared/global-id';
import { useLocale } from '../../src/hooks/locale';
// Get types from graphql
import type {
  GetLotsMlsQuery,
  UpdateLotMlsMutation,
} from '../__generated__/graphql';

interface Props {
  lotId: string;
  defaultExpanded?: boolean;
}

// Query to get lot shared mls
const GET_LOT_MLS = gql`
  query GetLotsMls($lot_id: uuid!) {
    lots_by_pk(id: $lot_id) {
      id
      shared_on_mls
    }
  }
`;

// Mutation to update lot shared mls
export const UPDATE_LOT_MLS = gql`
  mutation UpdateLotMls($lot_id: uuid!, $shared_on_mls: Boolean!) {
    update_lots_by_pk(
      pk_columns: { id: $lot_id }
      _set: { shared_on_mls: $shared_on_mls }
    ) {
      id
      shared_on_mls
    }
  }
`;

export const MlsCard = ({ lotId, defaultExpanded }: Props) => {
  const { t } = useLocale();
  const lot_id = fromGlobalId(lotId);

  // Query to get lot shared mls
  const { data } = useQuery<GetLotsMlsQuery>(GET_LOT_MLS, {
    variables: {
      lot_id,
    },
  });

  // Mutation to update lot shared mls
  const [updateLotMls] = useMutation<UpdateLotMlsMutation>(UPDATE_LOT_MLS);

  return (
    <Accordion defaultExpanded={defaultExpanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {t('MLS')}
      </AccordionSummary>
      <AccordionDetails>
        {/* ADD check box to toggle shared mls */}
        <FormControlLabel
          control={
            <Checkbox
              checked={data?.lots_by_pk?.shared_on_mls ?? false}
              onChange={e => {
                updateLotMls({
                  variables: {
                    lot_id,
                    shared_on_mls: e.target.checked,
                  },
                });
              }}
            />
          }
          label={t('Shared on MLS')}
        />
      </AccordionDetails>
    </Accordion>
  );
};
