import { useEffect, useState } from 'react';

interface HookProps {
  target: HTMLElement | null;
}

export const useIsDragging = ({ target }: HookProps): boolean => {
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    if (target) {
      const handleEvent = (event: DragEvent) => {
        switch (event.type) {
          case 'dragstart': {
            setIsDragging(true);
            break;
          }
          case 'dragend': {
            setIsDragging(false);
            break;
          }
        }
      };

      target.addEventListener('dragstart', handleEvent);
      target.addEventListener('dragend', handleEvent);

      return () => {
        target.removeEventListener('dragstart', handleEvent);
        target.removeEventListener('dragend', handleEvent);
      };
    }
  }, [target]);

  return isDragging;
};
