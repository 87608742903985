/**
 * @generated SignedSource<<9fe0f3372196f70ef3b1bebaa40a1c93>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserSearchFilters = {
  hasLeadsModule?: boolean | null;
  id_in?: ReadonlyArray<string | null> | null;
  id_nin?: ReadonlyArray<string | null> | null;
  id_sort_first_in?: ReadonlyArray<string | null> | null;
  isAdmin?: boolean | null;
  isBroker?: boolean | null;
  isFinancing?: boolean | null;
  only_in_current_teams?: boolean | null;
};
export type baseUserFilterPaginationQuery$variables = {
  count: number;
  cursor?: string | null;
  filters?: UserSearchFilters | null;
  search?: string | null;
};
export type baseUserFilterPaginationQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"baseUserFilter_root">;
};
export type baseUserFilterPaginationQuery = {
  response: baseUserFilterPaginationQuery$data;
  variables: baseUserFilterPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = {
  "kind": "Variable",
  "name": "filters",
  "variableName": "filters"
},
v2 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v3 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v1/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "baseUserFilterPaginationQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          {
            "kind": "Variable",
            "name": "cursor",
            "variableName": "cursor"
          },
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "baseUserFilter_root"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "baseUserFilterPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "UserSearchConnection",
        "kind": "LinkedField",
        "name": "usersSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserSearchEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Email",
                    "kind": "LinkedField",
                    "name": "primaryEmail",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Image",
                    "kind": "LinkedField",
                    "name": "primaryImage",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "filters": [
          "search",
          "filters"
        ],
        "handle": "connection",
        "key": "baseUserFilterConnection_usersSearch",
        "kind": "LinkedHandle",
        "name": "usersSearch"
      }
    ]
  },
  "params": {
    "cacheID": "73322823747a60ad77748736d9178588",
    "id": null,
    "metadata": {},
    "name": "baseUserFilterPaginationQuery",
    "operationKind": "query",
    "text": "query baseUserFilterPaginationQuery(\n  $count: Int!\n  $cursor: String = null\n  $filters: UserSearchFilters = null\n  $search: String = null\n) {\n  ...baseUserFilter_root_1URasg\n}\n\nfragment baseUserFilter_root_1URasg on Query {\n  usersSearch(first: $count, after: $cursor, search: $search, filters: $filters) {\n    edges {\n      node {\n        id\n        firstName\n        lastName\n        primaryEmail {\n          email\n          id\n        }\n        primaryImage {\n          url\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6db2498ea9e5f524ff7bc3656aee429d";

export default node;
