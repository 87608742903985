// Generated from src/svgs/unfold.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const Unfold = ({ size, fill, className }: Props) => {
  return (
    <svg viewBox="0 0 7 14" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><path fillRule="evenodd" clipRule="evenodd" d="m3.407 2.074 2.37 2.37 1.036-1.037L3.407 0 0 3.407l1.037 1.036 2.37-2.37Zm0 9.183-2.37-2.37L0 9.924l3.407 3.406 3.406-3.406-1.037-1.037-2.37 2.37Z" fill="#000" /></svg>
  );
}
