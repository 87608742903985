/**
 * @generated SignedSource<<83bf77a34ab7ae586bea2916297e51f7>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
type propertyImageForm_image$fragmentType = any;
type propertyImageQuickEdit_image$fragmentType = any;
import type { FragmentType } from "relay-runtime";
declare export opaque type propertyPhotos_property$fragmentType: FragmentType;
export type propertyPhotos_property$data = {|
  +id: string,
  +propertyImages: ?$ReadOnlyArray<{|
    +description: ?string,
    +id: string,
    +image: {|
      +id: string,
      +url: string,
    |},
    +orderNr: number,
    +title: ?string,
    +$fragmentSpreads: propertyImageForm_image$fragmentType & propertyImageQuickEdit_image$fragmentType,
  |}>,
  +$fragmentType: propertyPhotos_property$fragmentType,
|};
export type propertyPhotos_property$key = {
  +$data?: propertyPhotos_property$data,
  +$fragmentSpreads: propertyPhotos_property$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "propertyPhotos_property",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "PropertyImage",
      "kind": "LinkedField",
      "name": "propertyImages",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "propertyImageQuickEdit_image"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "propertyImageForm_image"
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "orderNr",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Image",
          "kind": "LinkedField",
          "name": "image",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Property",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "c2336c107d95215c9b6308b1fe3dfe97";

export default node;
