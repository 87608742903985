// Generated from src/svgs/email-sequences-outline.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const EmailSequencesOutline = ({ size, fill, className }: Props) => {
  return (
    <svg viewBox="0 0 20 20" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><path d="m11.667 13.333.001 5H1.667v-5.88l4.516 3.225a.829.829 0 0 0 .968 0L10 13.642v-1.975h-.1l-3.233 2.309-3.233-2.31h3.233V10h-5C.747 10 0 10.748 0 11.667v6.666C0 19.253.747 20 1.667 20h10c.92 0 1.666-.747 1.666-1.667v-5h-1.666ZM14.167 11.667a5.84 5.84 0 0 1-5.834-5.834A5.84 5.84 0 0 1 14.167 0 5.84 5.84 0 0 1 20 5.833a5.84 5.84 0 0 1-5.833 5.834Zm0-10A4.171 4.171 0 0 0 10 5.833 4.17 4.17 0 0 0 14.167 10a4.17 4.17 0 0 0 4.166-4.167 4.171 4.171 0 0 0-4.166-4.166Z" /><path d="M16.667 6.667h-3.334V2.5H15V5h1.667v1.667Z" /></svg>
  );
}
