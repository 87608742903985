import * as React from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

type UrlSearchParamsGetters = {
  getString(name: string): string | null;
  getAllStrings(name: string): string[];
  getNumber(name: string): number | null;
  getAllNumbers(name: string): number[];
  getBoolean(name: string): boolean | null;
  getAllBooleans(name: string): boolean[];
};

type UrlSearchParamsSetters = {
  toString(): string;
  setString(name: string, value: string | null): void;
  setAllStrings(name: string, value: string[]): void;
  setNumber(name: string, value: number | null): void;
  setAllNumbers(name: string, value: number[]): void;
  setBoolean(name: string, value: boolean | null): void;
  setAllBooleans(name: string, value: boolean[]): void;
};

const getNonNull = <T>(item: T | null): T[] => (item == null ? [] : [item]);

const string_of_number = (number: number): string => String(number);

const number_of_string = (string: string): number | null => {
  const parsed = Number.parseFloat(string);
  if (Number.isNaN(parsed)) {
    return null;
  }
  return parsed;
};

const boolean_of_string = (string: string): boolean | null => {
  if (string === 'true') {
    return true;
  }
  if (string === 'false') {
    return false;
  }
  return null;
};

const string_of_boolean = (boolean: boolean) => (boolean ? 'true' : 'false');

const makeUrlSearchParamsGetters = (search: string): UrlSearchParamsGetters => {
  const params = new URLSearchParams(search);
  const get = (name: string): string | null => {
    const value = params.get(name);
    if (value == null) {
      return null;
    }
    return decodeURIComponent(value);
  };
  const getAll = (name: string): string[] => {
    return params.getAll(name).map(decodeURIComponent);
  };
  return {
    getString: get,
    getAllStrings: getAll,
    getNumber: name => {
      const value = get(name);
      if (value == null) {
        return null;
      }
      return number_of_string(value);
    },
    getAllNumbers: name => {
      return getAll(name).map(number_of_string).flatMap(getNonNull);
    },
    getBoolean: name => {
      const value = get(name);
      if (value == null) {
        return null;
      }
      return boolean_of_string(value);
    },
    getAllBooleans: name => {
      return getAll(name).map(boolean_of_string).flatMap(getNonNull);
    },
  };
};

const makeUrlSearchParamsSetters = (search: string): UrlSearchParamsSetters => {
  const params = new URLSearchParams(search);
  const set = (name: string, string: string | null) => {
    if (string == null) {
      params.delete(name);
    } else {
      params.set(name, encodeURIComponent(string));
    }
  };
  const setAll = (name: string, strings: string[]) => {
    params.delete(name);
    strings.forEach(string => {
      params.append(name, encodeURIComponent(string));
    });
  };
  return {
    toString: () => params.toString(),
    setString: set,
    setAllStrings: setAll,
    setNumber: (name, number) => {
      set(name, number == null ? null : string_of_number(number));
    },
    setAllNumbers: (name, numbers) => {
      setAll(name, numbers.map(string_of_number));
    },
    setBoolean: (name, boolean) => {
      set(name, boolean == null ? null : string_of_boolean(boolean));
    },
    setAllBooleans: (name, booleans) => {
      setAll(name, booleans.map(string_of_boolean));
    },
  };
};

export type Field<T> = {
  get(params: UrlSearchParamsGetters): T;
  set(params: UrlSearchParamsSetters, value: T): void;
};

export type UrlSearchParamsHook<T extends Record<string, unknown>> = () => [
  T,
  (values: Partial<T>) => void,
];

type ExtractFieldType<T> = T extends Field<infer U> ? U : never;

export type ExtractFieldMap<T> = {
  [P in keyof T]: ExtractFieldType<T[P]>;
};

export const makeField = <T>(definition: Field<T>): Field<T> => definition;

export const makeUrlSearchParamsHook = <
  T extends Record<string, Field<unknown>>,
>(
  definition: Record<string, T[string]>,
  push = false,
): UrlSearchParamsHook<ExtractFieldMap<T>> => {
  type Value = ExtractFieldMap<T>;

  const parse = (search: string) => {
    const params = makeUrlSearchParamsGetters(search);
    const result: Value = {} as Value;
    Object.keys(definition).forEach(key => {
      result[key as keyof Value] = definition[key].get(params) as Value[string];
    });
    return result;
  };

  const serialize = (search: string, values: Partial<Value>) => {
    const params = makeUrlSearchParamsSetters(search);
    // iterate value to prevent not specified values overriding
    Object.keys(values).forEach(key => {
      definition[key].set(params, values[key] as T[string]);
    });
    return `?${params.toString()}`;
  };

  const Hook: UrlSearchParamsHook<Value> = () => {
    const { search, pathname } = useLocation();

    const navigate = useNavigate();

    const value: Value = React.useMemo(() => parse(search), [search]);
    const setValue = (values: Partial<Value>) => {
      if (push) {
        navigate({
          pathname,
          search: serialize(search, values),
        });
        return;
      } else {
        navigate(
          {
            // take the latest location from history
            // to prevent overriding immediate replaces
            pathname,
            search: serialize(search, values),
          },
          {
            replace: true,
          },
        );
      }
    };
    return [value, setValue];
  };

  return Hook;
};
