/**
 * @generated SignedSource<<0921422e373e75f18b6bdedbf195e2cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SubscriptionStatus = "active" | "canceled" | "incomplete" | "incomplete_expired" | "past_due" | "trialing" | "unpaid";
import { FragmentRefs } from "relay-runtime";
export type ActivityEventForm_root$data = {
  readonly me: {
    readonly firstName: string | null;
    readonly id: string;
    readonly isAdmin: boolean | null;
    readonly lastName: string | null;
    readonly nylasAccount: {
      readonly calendarSyncEnabled: boolean;
      readonly email: string | null;
      readonly name: string | null;
      readonly user: {
        readonly firstName: string | null;
        readonly id: string;
        readonly lastName: string | null;
        readonly organisation: {
          readonly formattedAddress: string | null;
          readonly name: string | null;
        } | null;
        readonly primaryEmail: {
          readonly email: string;
        } | null;
        readonly primaryImage: {
          readonly url: string;
        } | null;
        readonly subscription: {
          readonly status: SubscriptionStatus;
        } | null;
      } | null;
    } | null;
    readonly organisation: {
      readonly formattedAddress: string | null;
      readonly name: string | null;
    } | null;
    readonly primaryEmail: {
      readonly email: string;
    } | null;
    readonly primaryImage: {
      readonly url: string;
    } | null;
    readonly subscription: {
      readonly status: SubscriptionStatus;
    } | null;
  } | null;
  readonly " $fragmentType": "ActivityEventForm_root";
};
export type ActivityEventForm_root$key = {
  readonly " $data"?: ActivityEventForm_root$data;
  readonly " $fragmentSpreads": FragmentRefs<"ActivityEventForm_root">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Email",
  "kind": "LinkedField",
  "name": "primaryEmail",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Image",
  "kind": "LinkedField",
  "name": "primaryImage",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Organisation",
  "kind": "LinkedField",
  "name": "organisation",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "formattedAddress",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Subscription",
  "kind": "LinkedField",
  "name": "subscription",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActivityEventForm_root",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "me",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isAdmin",
          "storageKey": null
        },
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        (v7/*: any*/),
        (v8/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "NylasAccount",
          "kind": "LinkedField",
          "name": "nylasAccount",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "calendarSyncEnabled",
              "storageKey": null
            },
            (v3/*: any*/),
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/),
                (v7/*: any*/),
                (v8/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "d3584f8bc5aa612e9c4e59584e211eec";

export default node;
