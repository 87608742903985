import { useCallback, useRef } from 'react';

import { useMutation } from '@apollo/client';
import DeleteForever from '@mui/icons-material/DeleteForever';
import { Box, FormLabel, Grid, IconButton, useTheme } from '@mui/material';
import { useFileDialog } from '@realadvisor/hooks';
import { Image } from '@realadvisor/image';

import { FullScreenDropZone } from '../../../../shared/dropzone';
import { useLocale } from '../../../../src/hooks/locale';
import type { MapHook } from '../../../../src/hooks/map';
import type {
  DeletePropertyImageMutationVariables,
  StepCoverPhotoFragment,
} from '../../../__generated__/graphql';
import { UploadImageCard } from '../../../components/UploadCard';
import { useFileUpload } from '../../../utils/files/file-upload';
import {
  DELETE_PROPERTY_IMAGE,
  INSERT_PROPERTY_IMAGE,
} from '../../listings/ListingPropertyImages';
import { STEP_COVER_PHOTO_FRAGMENT } from '../cmaReportsQueries';

import LoadingCard from './LoadingCard';
import SelectableCard from './SelectableCard';

type CoverPhotoSelectorProps = {
  satelliteImageUrl: string;
  data: StepCoverPhotoFragment;
  updateCmaReport: (data: any) => void;
  localImages: MapHook<string>;
};

const CoverPhotoSelector = ({
  satelliteImageUrl,
  data,
  updateCmaReport,
  localImages,
}: CoverPhotoSelectorProps) => {
  const { t } = useLocale();
  const theme = useTheme();
  const lastId = useRef(0);
  const [uploadImage] = useFileUpload('image');

  const [insertPropertyImage] = useMutation(INSERT_PROPERTY_IMAGE, {
    update: (cache, { data: newData }) => {
      if (newData?.insert_property_images_one) {
        const id = cache.identify({
          __typename: 'cma_reports',
          id: data?.id,
        });
        const existingData = cache.readFragment({
          id,
          fragment: STEP_COVER_PHOTO_FRAGMENT,
          fragmentName: 'StepCoverPhoto',
        });

        cache.writeFragment({
          fragment: STEP_COVER_PHOTO_FRAGMENT,
          fragmentName: 'StepCoverPhoto',
          id,
          data: {
            ...existingData,
            cover_image: {
              id: newData.insert_property_images_one?.image?.id ?? '',
              url: newData.insert_property_images_one?.image?.url ?? '',
            },
            lead: {
              ...existingData?.lead,
              property: {
                ...existingData?.lead?.property,
                property_images: [
                  newData.insert_property_images_one,
                  ...(existingData?.lead?.property?.property_images ?? []),
                ],
              },
            },
          } as StepCoverPhotoFragment,
        });

        updateCmaReport({
          cover_image_id: newData.insert_property_images_one?.image?.id ?? null,
        });
      }
    },
  });

  const handleDrop = useCallback(
    (files: File[]) => {
      files.forEach(image => {
        const index = lastId.current;
        lastId.current += 1;
        const reader = new FileReader();
        reader.onload = evt => {
          localImages.set(`${index}`, evt.target?.result as string);
        };
        uploadImage(image, ({ data: imageData, error }) => {
          if (error) {
            console.error(error);
            return;
          }
          insertPropertyImage({
            variables: {
              object: {
                property_id: data?.lead?.property?.id,
                image: {
                  data: {
                    url: imageData?.url,
                  },
                },
                display_on_brochure: true,
                display_on_listing: false,
              },
            },
            onCompleted: () => {
              localImages.delete(`${index}`);
            },
          });
        });
        reader.readAsDataURL(image);
      });
    },
    [data?.lead?.property?.id, insertPropertyImage, localImages, uploadImage],
  );

  const openFileDialog = useFileDialog({
    accept: 'image/*',
    onChange: handleDrop,
  });

  const [deletePropertyImage] = useMutation(DELETE_PROPERTY_IMAGE, {
    update: (cache, _res, { variables }) => {
      const { id } = variables as DeletePropertyImageMutationVariables;
      cache.evict({ id: `property_images:${id}` });
    },
  });

  return (
    <>
      <FormLabel
        sx={{
          fontWeight: 500,
          mb: 1,
        }}
        component={'p'}
      >
        {t('coverPhoto')}
      </FormLabel>
      <FullScreenDropZone onDrop={handleDrop} />
      <Box p={2} mb={1} mx={-2}>
        <UploadImageCard noMinHeight onClick={openFileDialog} />
      </Box>
      <Grid container gap={2} paddingBottom={1} direction={'column'}>
        <SelectableCard
          selected={data?.cover_image?.id == null}
          onChange={selected => {
            if (selected) {
              updateCmaReport({
                cover_image_id: null,
              });
            }
          }}
        >
          <Image
            src={satelliteImageUrl}
            objectFit="cover"
            options={{ w: 450, h: 200, c: 'scale' }}
          />
          <div
            style={{
              ...theme.typography.caption,
              position: 'absolute',
              left: 10,
              bottom: 10,
              display: 'inline-flex',
              borderRadius: 99,
              color: '#fff',
              backgroundColor: theme.palette.primary.main,
              padding: '0 8px',
              margin: 2,
            }}
          >
            {t('satellite')}
          </div>
        </SelectableCard>
        {Array.from(localImages.entries()).map(([index, image]) => (
          <LoadingCard loading key={`cover-photo-${index}`}>
            <img
              src={image}
              css={{ objectFit: 'cover', width: '100%', height: 200 }}
              alt={`cover-photo-${index}`}
            />
          </LoadingCard>
        ))}
        {data?.lead?.property?.property_images?.map(image => (
          <div key={image?.id} style={{ position: 'relative' }}>
            <SelectableCard
              selected={image?.image?.id === data?.cover_image?.id}
              onChange={selected => {
                updateCmaReport({
                  cover_image_id: selected ? image?.image?.id : null,
                });
              }}
            >
              <Image
                src={image?.image?.url ?? ''}
                objectFit="cover"
                options={{ w: 450, h: 200, c: 'scale' }}
              />
            </SelectableCard>
            <IconButton
              size="small"
              css={{
                position: 'absolute',
                top: 6,
                right: 6,
                color: theme.palette.primary.main,
                backgroundColor: '#fff',
                '&:hover': {
                  backgroundColor: '#fff',
                },
              }}
              onMouseDown={evt => {
                evt.stopPropagation();
              }}
              onClick={evt => {
                evt.stopPropagation();

                if (image?.id == null) {
                  return;
                }

                deletePropertyImage({
                  variables: {
                    id: image.id,
                  },
                  onCompleted: () => {
                    const newPropertyImages =
                      data.lead?.property?.property_images.filter(
                        img => img?.id !== image.id,
                      );

                    const isDeletedImageCoverImage =
                      image?.image?.id === data.cover_image?.id;
                    const hasRemainingImages =
                      newPropertyImages && newPropertyImages.length > 0;

                    if (isDeletedImageCoverImage) {
                      updateCmaReport({
                        cover_image_id: hasRemainingImages
                          ? newPropertyImages[0]?.image.id
                          : null,
                      });
                    }
                  },
                });
              }}
            >
              <DeleteForever />
            </IconButton>
          </div>
        ))}
      </Grid>
    </>
  );
};

export default CoverPhotoSelector;
