// Generated from src/svgs/nature-leaf.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const NatureLeaf = ({ size, fill, className }: Props) => {
  return (
    <svg viewBox="0 0 20 20" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><g clipPath="url(#nature-leaf_svg__a)"><path d="M5.678 7.485a5.8 5.8 0 0 0-1.359 4.258 5.79 5.79 0 0 0 1.482 3.414l-2.434 2.819 1.262 1.089 2.456-2.845a5.828 5.828 0 0 0 3.05.874 5.826 5.826 0 0 0 4.463-2.072C16.724 12.509 17.308 5.789 17.5 2.5c-2.696.73-9.973 2.797-11.822 4.985Zm7.647 6.462a4.168 4.168 0 0 1-3.19 1.48 4.16 4.16 0 0 1-1.94-.492l4.803-5.563-1.262-1.089-4.835 5.6a4.146 4.146 0 0 1-.921-2.279 4.145 4.145 0 0 1 .972-3.043c1.045-1.238 5.506-2.87 8.726-3.824-.256 2.751-.965 7.569-2.353 9.21Z" fill="#000" /></g><defs><clipPath id="nature-leaf_svg__a"><path fill="#fff" d="M0 0h20v20H0z" /></clipPath></defs></svg>
  );
}
