// @flow

import * as React from 'react';

import { Button, ListItem, Tooltip } from '@material-ui/core';
import { graphql, useFragment } from 'react-relay';
import { Box, Flex } from 'react-system';

import { useLocale } from '../hooks/locale';
import { useTheme } from '../hooks/theme';
import { PropertySearchDrawer } from '../shared/property-search-drawer';
import { abbrDateDifference } from '../utils/abbrDateDifference';

import type { SearchesPanel_item$key } from './__generated__/SearchesPanel_item.graphql';
import type { SearchesPanel_predefinedUser$key } from './__generated__/SearchesPanel_predefinedUser.graphql';

type Props = {|
  predefinedUser: ?SearchesPanel_predefinedUser$key,
  searches: $ReadOnlyArray<SearchesPanel_item$key>,
  onCreate: () => void,
  onDelete: () => void,
  buttonRenderer?: ({| openDrawer: () => void |}) => React.Node,
|};

const formatCurrency = (number, locale) => {
  return number.toLocaleString(locale, {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    // currency: 'CHF',
    // style: 'currency',
  });
};

const SearchesItem = props => {
  const item = useFragment(
    graphql`
      fragment SearchesPanel_item on PropertySearch {
        id
        offerType
        numberOfRoomsGte
        salePriceLte
        monthlyRentLte
        updatedAt
        createdAt
      }
    `,
    props.item,
  );

  const { text, textColor } = useTheme();
  const { t, locale, intlLocale } = useLocale();

  const t_of_offerType = item => {
    switch (item) {
      case 'buy':
        return t('buy');
      case 'rent':
        return t('rent');
      default:
        return item;
    }
  };

  const { numberOfRoomsGte, salePriceLte, monthlyRentLte } = item;

  const valueStyle = [
    text.caption,
    textColor('mediumText'),
    { display: 'inline-block' },
  ];
  const date = new Date(item.updatedAt ?? item.createdAt ?? '');
  const parsedDate = abbrDateDifference(t, new Date(), date).text;

  return (
    <ListItem
      button={true}
      onClick={() => props.onClick(item.id)}
      divider={true}
    >
      <Box>{t_of_offerType(item.offerType)}</Box>
      <Box ml={3} flexGrow={1}>
        {numberOfRoomsGte != null && (
          <Box mr={3} css={valueStyle}>
            {`${t('minRooms')}: ${numberOfRoomsGte}`}{' '}
          </Box>
        )}
        {salePriceLte != null && (
          <Box mr={3} css={valueStyle}>
            {`${t('salePriceLte')}: ${formatCurrency(salePriceLte, locale)}`}
          </Box>
        )}
        {monthlyRentLte != null && (
          <Box mr={3} css={valueStyle}>
            {`${t('monthlyRentLte')}: ${formatCurrency(
              monthlyRentLte,
              locale,
            )}`}
          </Box>
        )}
      </Box>
      <Tooltip
        title={new Intl.DateTimeFormat(intlLocale, {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        }).format(date)}
        placement="top"
      >
        <div css={[{ textAlign: 'right' }, text.caption]}>{parsedDate}</div>
      </Tooltip>
    </ListItem>
  );
};

export const SearchesPanel = (props: Props): React.Node => {
  const { t } = useLocale();
  const [selectedItemId, setSelectedItemId] = React.useState(null);
  const [propertySearchDrawer, setPropertySearchDrawer] = React.useState(false);

  const predefinedUser = useFragment(
    graphql`
      fragment SearchesPanel_predefinedUser on User {
        id
        ...userInput_user @relay(mask: false)
      }
    `,
    props.predefinedUser,
  );

  return (
    <>
      {props.searches.map((item, index) => (
        <SearchesItem
          key={index}
          item={item}
          onClick={id => setSelectedItemId(id)}
        />
      ))}

      <PropertySearchDrawer
        propertySearchId={selectedItemId}
        predefinedUser={predefinedUser}
        open={selectedItemId != null}
        onClose={() => setSelectedItemId(null)}
        onDelete={() => {
          setSelectedItemId(null);
          props.onDelete();
        }}
      />

      <PropertySearchDrawer
        propertySearchId={null}
        predefinedUser={predefinedUser}
        open={propertySearchDrawer}
        onClose={() => setPropertySearchDrawer(false)}
        onCreate={() => {
          setPropertySearchDrawer(false);
          props.onCreate();
        }}
      />

      {props.buttonRenderer != null ? (
        props.buttonRenderer({
          openDrawer: () => setPropertySearchDrawer(true),
        })
      ) : (
        <Flex alignItems="center" justifyContent="center" width={1} py={2}>
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setPropertySearchDrawer(true)}
            >
              {t('addSearch')}
            </Button>
          </Box>
        </Flex>
      )}
    </>
  );
};
