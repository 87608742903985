import type { Translate } from '@realadvisor/translator/engine';

export const translatePropertyUsage = (t: Translate, value: string): string => {
  return (
    {
      primary: t('primary'),
      secondary: t('secondary'),
      investment: t('investment'),
    }[value] ?? value
  );
};

export const translateMortgageType = (t: Translate, value: string): string => {
  return (
    {
      new_mortgage: t('newMortgage'),
      re_mortgage: t('remortgage'),
    }[value] ?? value
  );
};

export const translateMortgageStep = (t: Translate, value: string): string => {
  return (
    {
      type: t('mortgageType'),
      situation: t('yourSituation'),
      financials: t('yourFinancials'),
      verification: t('verification'),
      result: t('result'),
    }[value] ?? value
  );
};

export const translateLanguage = (t: Translate, value: string): string => {
  switch (value) {
    case 'de':
      return t('german');
    case 'en':
      return t('english');
    case 'es':
      return t('spanish');
    case 'fr':
      return t('french');
    case 'it':
      return t('italian');
    default:
      return value;
  }
};

export const translateProfessionalSituation = (
  t: Translate,
  value: string,
): string => {
  return (
    {
      business_owner: t('businessOwner'),
      employed: t('employed'),
      retired: t('retired'),
      self_employed: t('selfEmployed'),
      unemployed: t('unemployed'),
    }[value] ?? value
  );
};

export const translateGender = (t: Translate, value: string): string => {
  return (
    {
      male: t('male'),
      female: t('female'),
    }[value] ?? value
  );
};

export const translateMaritalStatus = (t: Translate, value: string): string => {
  return (
    {
      cohabitation: t('cohabitation'),
      separated: t('separated'),
      single: t('single'),
      married: t('married'),
      divorced: t('divorced'),
      widowed: t('widowed'),
    }[value] ?? value
  );
};

export const translateFinancingLeadSource = (
  t: Translate,
  value: string,
): string => {
  return (
    {
      mortgage: t('form'),
      crm: t('crm'),
      investment: t('investment'),
    }[value] ?? value
  );
};

export const translateBuyingStatus = (t: Translate, value: string): string => {
  return (
    {
      identified: t('identified'),
      visited: t('visited'),
      offer: t('offer'),
      searching: t('searching'),
    }[value] ?? value
  );
};
