/**
 * @generated SignedSource<<53968d46d4a3c0e4edbb6b50112173a0>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
type propertyImageForm_image$fragmentType = any;
export type PropertyImageBackground = "black" | "white";
export type PropertyImageCrop = "fill" | "fit";
export type PropertyImageSize = "full_page" | "half_page" | "quarter_page";
export type UpdatePropertyImageInput = {|
  backgroundColor?: ?PropertyImageBackground,
  clientMutationId?: ?string,
  crop?: ?PropertyImageCrop,
  description?: ?string,
  destinationOrderNr?: ?number,
  displayOnBrochure?: ?boolean,
  displayOnListing?: ?boolean,
  id: string,
  isFloorPlan?: ?boolean,
  landscapeBackgroundColor?: ?PropertyImageBackground,
  landscapeCrop?: ?PropertyImageCrop,
  landscapeSize?: ?PropertyImageSize,
  propertyId?: ?string,
  size?: ?PropertyImageSize,
  title?: ?string,
  url?: ?string,
|};
export type propertyImageFormUpdateMutation$variables = {|
  input: UpdatePropertyImageInput,
|};
export type propertyImageFormUpdateMutation$data = {|
  +updatePropertyImage: ?{|
    +propertyImage: ?{|
      +$fragmentSpreads: propertyImageForm_image$fragmentType,
    |},
  |},
|};
export type propertyImageFormUpdateMutation = {|
  response: propertyImageFormUpdateMutation$data,
  variables: propertyImageFormUpdateMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "propertyImageFormUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePropertyImagePayload",
        "kind": "LinkedField",
        "name": "updatePropertyImage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PropertyImage",
            "kind": "LinkedField",
            "name": "propertyImage",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "propertyImageForm_image"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "propertyImageFormUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePropertyImagePayload",
        "kind": "LinkedField",
        "name": "updatePropertyImage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PropertyImage",
            "kind": "LinkedField",
            "name": "propertyImage",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "size",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "crop",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "backgroundColor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayOnListing",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayOnBrochure",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isFloorPlan",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "landscapeSize",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "landscapeCrop",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "landscapeBackgroundColor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Image",
                "kind": "LinkedField",
                "name": "image",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fd01ff005be0d19e1f260df64fa0d158",
    "id": null,
    "metadata": {},
    "name": "propertyImageFormUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation propertyImageFormUpdateMutation(\n  $input: UpdatePropertyImageInput!\n) {\n  updatePropertyImage(input: $input) {\n    propertyImage {\n      ...propertyImageForm_image\n      id\n    }\n  }\n}\n\nfragment previewImage_image on Image {\n  url\n}\n\nfragment propertyImageForm_image on PropertyImage {\n  id\n  title\n  description\n  size\n  crop\n  backgroundColor\n  displayOnListing\n  displayOnBrochure\n  isFloorPlan\n  landscapeSize\n  landscapeCrop\n  landscapeBackgroundColor\n  image {\n    id\n    ...previewImage_image\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "aaf1969b96f8bf961460aa7a186dbdf1";

export default node;
