/**
 * @generated SignedSource<<ec7619e29ceba4bd043c55bd56de5a39>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type leadUpdatedTimeAgo_lead$fragmentType: FragmentType;
export type leadUpdatedTimeAgo_lead$data = {|
  +createdAt: string,
  +requalifiedAt: ?string,
  +$fragmentType: leadUpdatedTimeAgo_lead$fragmentType,
|};
export type leadUpdatedTimeAgo_lead$key = {
  +$data?: leadUpdatedTimeAgo_lead$data,
  +$fragmentSpreads: leadUpdatedTimeAgo_lead$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "leadUpdatedTimeAgo_lead",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requalifiedAt",
      "storageKey": null
    }
  ],
  "type": "Lead",
  "abstractKey": null
};

(node/*: any*/).hash = "8ae9cc07ceaa60d0d7135d7c039279d8";

export default node;
