import { useState } from 'react';

import { AutoFixHighOutlined, Close } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';

import { REALADVISOR_TENANT_ID, isRATenantId } from '../../src/utils/tenant';

import { AutoAssignAssistantCH } from './AutoAssignAssistantCH';
import { AutoAssignAssistantEU } from './AutoAssignAssistantEU';

interface Props {
  leadId?: string;
  tenantId?: string;
}

export const AutoAssignAssistant = ({ leadId, tenantId }: Props) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        variant="contained"
        fullWidth
        sx={{ mt: 2 }}
        color="primary"
        disabled={!leadId || !tenantId}
      >
        <AutoFixHighOutlined sx={{ mr: 2 }} />
        Assign Assistant
      </Button>
      <Dialog
        open={open}
        maxWidth="lg"
        fullWidth
        onClose={() => setOpen(false)}
      >
        <DialogTitle
          style={{ display: 'flex', alignItems: 'center', gap: '7px' }}
        >
          <AutoFixHighOutlined />
          Assign Assistant
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {
            // All RA tenants but CH
            tenantId &&
              leadId &&
              isRATenantId(tenantId) &&
              tenantId !== REALADVISOR_TENANT_ID && (
                <AutoAssignAssistantEU leadId={leadId} />
              )
          }
          {
            // Only for RA CH tenant
            leadId && tenantId === REALADVISOR_TENANT_ID && (
              <AutoAssignAssistantCH leadId={leadId} />
            )
          }
        </DialogContent>
      </Dialog>
    </>
  );
};
