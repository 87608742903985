/**
 * @generated SignedSource<<b53fc9dc01791acb84ae58b972cafb7b>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
type poiMapbox_root$fragmentType = any;
export type PoisFilters = {|
  bbox?: ?$ReadOnlyArray<?number>,
  completed_in?: ?$ReadOnlyArray<?boolean>,
  endDate?: ?string,
  id_eq?: ?string,
  mostRecent?: ?boolean,
  nextActivity?: ?$ReadOnlyArray<?string>,
  placeId?: ?string,
  saleHorizon_in?: ?$ReadOnlyArray<?string>,
  startDate?: ?string,
  status_in?: ?$ReadOnlyArray<?string>,
  useRequalifiedIfAvailable?: ?boolean,
|};
export type poiMapboxQuery$variables = {|
  count: number,
  filters?: ?PoisFilters,
|};
export type poiMapboxQuery$data = {|
  +$fragmentSpreads: poiMapbox_root$fragmentType,
|};
export type poiMapboxQuery = {|
  response: poiMapboxQuery$data,
  variables: poiMapboxQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  }
],
v1 = {
  "kind": "Variable",
  "name": "filters",
  "variableName": "filters"
},
v2 = [
  (v1/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "poiMapboxQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          (v1/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "poiMapbox_root"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "poiMapboxQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "PoiConnection",
        "kind": "LinkedField",
        "name": "pois",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PoiEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Poi",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lat",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lng",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasDevLeads",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasCompletedLeads",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "filters": [],
        "handle": "connection",
        "key": "Connection_pois",
        "kind": "LinkedHandle",
        "name": "pois"
      }
    ]
  },
  "params": {
    "cacheID": "1cfa7b0fc2e8e787cf418320b1a8ffb4",
    "id": null,
    "metadata": {},
    "name": "poiMapboxQuery",
    "operationKind": "query",
    "text": "query poiMapboxQuery(\n  $count: Int!\n  $filters: PoisFilters\n) {\n  ...poiMapbox_root_2IZfLc\n}\n\nfragment poiMapbox_root_2IZfLc on Query {\n  pois(first: $count, filters: $filters) {\n    edges {\n      node {\n        id\n        lat\n        lng\n        hasDevLeads\n        hasCompletedLeads\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "d9cc0723c4e0f7a29ad9633030481847";

export default node;
