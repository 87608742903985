import type * as React from 'react';

import { graphql, useFragment } from 'react-relay';
import { Link, type LinkProps } from 'react-router-dom';

import { useLocale } from '../../hooks/locale';
import { useTheme } from '../../hooks/theme';

import type { parentLink_buyerLead$key } from './__generated__/parentLink_buyerLead.graphql';
import type { parentLink_enquiry$key } from './__generated__/parentLink_enquiry.graphql';
import type { parentLink_lead$key } from './__generated__/parentLink_lead.graphql';
import type { parentLink_lot$key } from './__generated__/parentLink_lot.graphql';
import type { parentLink_user$key } from './__generated__/parentLink_user.graphql';
import { UserName } from './UserName';

type Props = {
  enquiry: null | parentLink_enquiry$key;
  lot: null | parentLink_lot$key;
  lead: null | parentLink_lead$key;
  user: null | parentLink_user$key;
  buyerLead: null | parentLink_buyerLead$key;
  children?: React.ReactNode;
};

const BaseLink = ({
  to,
  children,
}: {
  to: LinkProps['to'];
  children: React.ReactNode;
}) => {
  const { text } = useTheme();
  return (
    <Link css={{ fontWeight: text.font.medium }} to={to}>
      {children}
    </Link>
  );
};

export const ParentLink = (props: Props) => {
  const { t } = useLocale();

  const enquiry = useFragment(
    graphql`
      fragment parentLink_enquiry on Enquiry {
        id
      }
    `,
    props.enquiry,
  );
  const lot = useFragment(
    graphql`
      fragment parentLink_lot on Lot {
        id
      }
    `,
    props.lot,
  );
  const lead = useFragment(
    graphql`
      fragment parentLink_lead on Lead {
        id
      }
    `,
    props.lead,
  );
  const user = useFragment(
    graphql`
      fragment parentLink_user on User {
        ...UserName_user
        id
      }
    `,
    props.user,
  );
  const buyerLead = useFragment(
    graphql`
      fragment parentLink_buyerLead on BuyerLead {
        id
        lot {
          id
        }
      }
    `,
    props.buyerLead,
  );

  if (enquiry) {
    return (
      <BaseLink to={`/enquiries/${enquiry.id}`}>
        {props.children ?? t('enquiry')}
      </BaseLink>
    );
  }
  if (lot) {
    return (
      <BaseLink to={`/lots/${lot.id}`}>
        {props.children ?? t('listing')}
      </BaseLink>
    );
  }
  if (lead) {
    return (
      <BaseLink to={`/leads/${lead.id}`}>
        {props.children ?? t('lead')}
      </BaseLink>
    );
  }
  if (user) {
    return <UserName user={user} />;
  }
  if (buyerLead && buyerLead.lot) {
    const { lot } = buyerLead;
    return (
      <BaseLink to={`/lots/${lot.id}/buyers/view/${buyerLead.id}`}>
        {props.children ?? t('buyerLead')}
      </BaseLink>
    );
  }
  return null;
};
