import { type ReactElement, type Ref, forwardRef } from 'react';

import {
  Autocomplete,
  type AutocompleteProps,
  CircularProgress,
  type InputProps,
  TextField,
} from '@mui/material';
import type { Cancelable } from '@mui/utils/debounce';

import { useLocale } from '../../../src/hooks/locale';

export type RaAutoCompleteCreatableItem<T> = {
  inputValue: string;
  new: true;
} & (T extends Record<any, any>
  ? T extends Array<any>
    ? {}
    : Partial<T>
  : {});

export const isCreatableItem = <T,>(
  item: T | RaAutoCompleteCreatableItem<T>,
): item is RaAutoCompleteCreatableItem<T> => (item as any).new;

type RaAutocompleteProps<T> = {
  debouncedSearch: ((value: any) => void) & Cancelable;
  error?: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setOptions: React.Dispatch<React.SetStateAction<T[]>>;
  getOptionLabel: (option: T) => string;
  InputProps?: InputProps;
  allowEmptySearch?: boolean;
} & (
  | (Omit<
      AutocompleteProps<
        T | RaAutoCompleteCreatableItem<T>,
        false,
        false,
        false
      >,
      'renderInput' | 'ref' | 'getOptionLabel' | 'renderOption'
    > & {
      renderOption: (
        props: React.HTMLAttributes<HTMLLIElement>,
        option: T | RaAutoCompleteCreatableItem<T>,
      ) => React.ReactNode;
      creatable:
        | {
            createItemFn?: (
              inputValue: string,
            ) => RaAutoCompleteCreatableItem<T>;
            elementPosition: 'first' | 'last';
          }
        | true;
    })
  | (Omit<
      AutocompleteProps<T, false, false, false>,
      'renderInput' | 'ref' | 'getOptionLabel' | 'renderOption'
    > & {
      renderOption: (
        props: React.HTMLAttributes<HTMLLIElement>,
        option: T,
      ) => React.ReactNode;
      creatable?: never;
    })
);

const RaAutoCompleteWithRef = <T,>(
  {
    sx,
    InputProps,
    open,
    setOpen,
    value,
    options,
    setOptions,
    loading,
    error,
    onChange,
    debouncedSearch,
    renderOption,
    getOptionLabel,
    creatable,
    allowEmptySearch = false,
    ...autoCompleteProps
  }: RaAutocompleteProps<T>,
  ref: Ref<HTMLDivElement>,
) => {
  const { t } = useLocale();
  const createOptions =
    creatable == null
      ? null
      : {
          createItemFn:
            typeof creatable !== 'boolean' && creatable.createItemFn != null
              ? creatable.createItemFn
              : null,
          elementPosition:
            typeof creatable !== 'boolean' ? creatable.elementPosition : 'last',
        };
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value || allowEmptySearch) {
      debouncedSearch(event.target.value);
    } else {
      setOpen(false);
      setOptions([]);
    }
  };

  return (
    <Autocomplete<T>
      sx={sx}
      autoComplete={false}
      autoCorrect="off"
      open={open}
      clearOnBlur
      onClose={() => {
        setOpen(false);
        setOptions([]);
      }}
      value={value}
      getOptionLabel={getOptionLabel}
      options={options}
      loading={loading}
      loadingText={t('Loading...')}
      noOptionsText={error ? t('Error') : t('No results')}
      onChange={onChange}
      filterOptions={(filteredResults, state) => {
        if (createOptions == null) {
          return filteredResults;
        }

        const createItem =
          createOptions.createItemFn?.(state.inputValue) ??
          ({
            inputValue: state.inputValue,
            new: true,
          } as RaAutoCompleteCreatableItem<T>);

        return createOptions.elementPosition === 'first'
          ? [createItem, ...filteredResults]
          : [...filteredResults, createItem];
      }}
      renderOption={renderOption}
      componentsProps={{
        paper: {
          elevation: 3,
          sx: {
            borderRadius: '4px',
          },
        },
      }}
      renderInput={params => (
        <TextField
          {...params}
          autoFocus={autoCompleteProps?.autoFocus}
          onChange={handleSearch}
          variant="outlined"
          sx={{ background: 'white' }}
          size="small"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'one-time-code', // disable autocomplete and autofill
            type: 'new-password',
            name: 'new-password',
            autoCorrect: 'off',
            spellCheck: 'false',
          }}
          InputProps={{
            ...params.InputProps,
            ...InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} disableShrink />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      ref={ref}
      {...(autoCompleteProps as any)}
    />
  );
};

export const RaAutoComplete = forwardRef(RaAutoCompleteWithRef) as <T>(
  props: RaAutocompleteProps<T> & { ref?: Ref<HTMLDivElement> },
) => ReactElement;
