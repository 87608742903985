import { Button, CardActions, CardContent } from '@material-ui/core';
import { format, formatDistance, parseISO } from 'date-fns';
import { graphql, useFragment } from 'react-relay';
// $FlowFixMe[untyped-import]
import { Link } from 'react-router-dom';

import { Markdown } from '../../controls/markdown';
import { useLocale } from '../../hooks/locale';
import { useTheme } from '../../hooks/theme';
import { AccessTime } from '../../icons/access-time';
import { EmailEnvelopeOutline } from '../../icons/email-envelope-outline';

import type {
  feedEnquiry_parent$data,
  feedEnquiry_parent$key,
} from './__generated__/feedEnquiry_parent.graphql';

type Props = {
  parent: null | feedEnquiry_parent$key;
};

const EnquiryLink = ({ enquiry }: { enquiry: feedEnquiry_parent$data }) => {
  const { t } = useLocale();

  return (
    <CardActions css={{ justifyContent: 'flex-end', padding: 16 }}>
      {/* $FlowFixMe[prop-missing] */}
      <Button
        component={Link}
        to={`/enquiries/${enquiry.id}`}
        variant="outlined"
      >
        {t('processEnquiry')}
      </Button>
    </CardActions>
  );
};

export const FeedEnquiry = (props: Props) => {
  const { dateLocale } = useLocale();
  const { text, textColor } = useTheme();
  const parent = useFragment(
    graphql`
      fragment feedEnquiry_parent on Enquiry {
        id
        message
        createdAt
        buyerLead {
          __typename
        }
      }
    `,
    props.parent,
  );

  return (
    <>
      <CardContent
        css={{
          paddingTop: 0,
          display: 'grid',
          gridGap: 12,
          gridTemplateColumns: '24px 1fr',
        }}
      >
        <AccessTime />
        <div css={[text.body2]}>
          {parent && parent.createdAt != null && (
            <>
              {format(parseISO(parent.createdAt), 'd MMMM yyyy HH:mm', {
                locale: dateLocale,
              })}
              <div css={[text.caption, textColor('mediumText')]}>
                (
                {formatDistance(parseISO(parent.createdAt), Date.now(), {
                  locale: dateLocale,
                  addSuffix: true,
                })}
                )
              </div>
            </>
          )}
        </div>
        {parent?.message != null && (
          <>
            <EmailEnvelopeOutline />
            <div css={text.body2}>
              <Markdown>{parent.message}</Markdown>
            </div>
          </>
        )}
      </CardContent>
      {parent && parent.id && parent.buyerLead == null && (
        <EnquiryLink enquiry={parent} />
      )}
    </>
  );
};
