import { Suspense, useState } from 'react';

import { gql, useSuspenseQuery } from '@apollo/client';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from '@mui/material';

import { ProgressButton } from '../../src/controls/progress-button';
import { useLocale } from '../../src/hooks/locale';
import { translateStatus } from '../../src/utils/lead-labels';
import type {
  LeadStatusQuery,
  Leads_Status_Enum,
} from '../__generated__/graphql';

const LEAD_STATUS_QUERY = gql`
  query LeadStatus {
    leads_status {
      value
    }
  }
`;

export const UPDATE_LEADS_STATUS_MUTATION = gql`
  mutation UpdateLeadsStatus($ids: [uuid!]!, $status: leads_status_enum!) {
    update_leads_many(
      updates: { where: { id: { _in: $ids } }, _set: { status: $status } }
    ) {
      affected_rows
    }
  }
`;

const LeadStatusDialogContent = ({
  loading,
  onClose,
  onConfirm,
}: Omit<Props, 'open'>) => {
  const { t } = useLocale();
  const [status, setStatus] = useState<Leads_Status_Enum | undefined>();
  const { data } = useSuspenseQuery<LeadStatusQuery>(LEAD_STATUS_QUERY);
  const statuses = data.leads_status.map(status => status.value);
  return (
    <>
      <DialogContent>
        <TextField
          fullWidth
          select={true}
          label={t('status')}
          value={status}
          variant="filled"
          margin="normal"
          onChange={evt => {
            setStatus(evt.target.value as Leads_Status_Enum);
          }}
        >
          {statuses.map(status => (
            <MenuItem key={status} value={status}>
              {translateStatus(t, status)}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button sx={{ mr: 1 }} color="inherit" onClick={onClose}>
          {t('cancel')}
        </Button>
        <ProgressButton
          loading={loading === true}
          disabled={status == null || loading}
          color="primary"
          variant="contained"
          onClick={() => status && onConfirm(status)}
        >
          {t('confirm')}
        </ProgressButton>
      </DialogActions>
    </>
  );
};

interface Props {
  open: boolean;
  loading?: boolean;
  onClose: () => void;
  onConfirm: (status: Leads_Status_Enum) => void;
}
export const LeadStatusDialog = (props: Props) => {
  const { t } = useLocale();
  const { open, ...rest } = props;
  return (
    <Dialog open={open} onClose={props.onClose} fullWidth>
      <DialogTitle>{t('Change status')}</DialogTitle>
      <Suspense fallback={null}>
        <LeadStatusDialogContent {...rest} />
      </Suspense>
    </Dialog>
  );
};
