import * as React from 'react';

export type SetHook<T> = {
  size: number;
  has: (value: T) => boolean;
  values: () => T[];

  add: (value: T) => void;
  union: (values: T[]) => void;
  delete: (value: T) => void;
  difference: (values: T[]) => void;
  clear: () => void;
};

export const useSet = <T>(initial: T[]): SetHook<T> => {
  const [inst, setInst] = React.useState(() => new Set(initial));

  return {
    size: inst.size,
    has: value => inst.has(value),
    values: () => Array.from<T>(inst),

    add: value => {
      setInst(prev => {
        const next = new Set(prev);
        next.add(value);
        return next;
      });
    },

    union: array => {
      setInst(prev => {
        const next = new Set(prev);
        array.forEach(value => {
          next.add(value);
        });
        return next;
      });
    },

    delete: value => {
      setInst(prev => {
        const next = new Set(prev);
        next.delete(value);
        return next;
      });
    },

    difference: array => {
      setInst(prev => {
        const next = new Set(prev);
        array.forEach(value => {
          next.delete(value);
        });
        return next;
      });
    },

    clear: () => {
      setInst(new Set());
    },
  };
};
