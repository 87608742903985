import * as React from 'react';

import * as ReactDOM from 'react-dom';
import { Title } from 'react-head';

import { useAppData } from '../../apollo/providers/AppDataProvider';

type TopbarState = {
  titleRef: { current: HTMLElement | null };
};

const TopbarContext = React.createContext<TopbarState | null>(null);

export const TopbarProvider = ({ children }: { children: React.ReactNode }) => {
  const titleRef = React.useRef(null);

  const value = React.useMemo(
    () => ({
      titleRef,
    }),
    [],
  );

  return (
    <TopbarContext.Provider value={value}>{children}</TopbarContext.Provider>
  );
};

export const useTopbar = (): TopbarState => {
  const context = React.useContext(TopbarContext);
  if (context == null) {
    throw Error('<TopbarProvider> should be in the tree');
  }
  return context;
};

export const TopbarTitle = ({
  children,
  titleOverride,
}: {
  children: React.ReactNode;
  titleOverride?: null | string;
}) => {
  const { me } = useAppData();
  const tenantName = me?.tenant.name;
  const { titleRef } = useTopbar();
  let textForTitleElement = children;

  if (titleOverride != null) {
    textForTitleElement = titleOverride;
  }

  return (
    <>
      <Title>
        {textForTitleElement} | {tenantName + ' CRM'}
      </Title>
      {titleRef.current != null &&
        ReactDOM.createPortal(children, titleRef.current)}
    </>
  );
};
