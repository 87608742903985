// @flow

import * as React from 'react';

import { FilledInput, IconButton, InputAdornment } from '@material-ui/core';
import { useRifm } from 'rifm';

import { useLocale } from '../hooks/locale';
import { AddCircle } from '../icons/add-circle';
import { RemoveCircle } from '../icons/remove-circle';
import { makeNumberFormat } from '../utils/number-format';

type Props = {|
  disabled?: boolean,
  min?: number,
  max?: number,
  step?: number,
  value: string,
  onChange: string => void,
  onBlur?: () => void,
|};

// Note: requires InputLabel to have shrink={true}

const getFractionSize = value => {
  const [, right] = String(value).split('.');
  return right == null ? 0 : right.length;
};

const round = ({ value, step, min, max }) => {
  const roundingFactor = 1 / step;
  const roundedValue = Math.round(value * roundingFactor) / roundingFactor;
  const newValue = Number.parseFloat(
    roundedValue.toFixed(getFractionSize(step)),
  );
  return Math.max(min, Math.min(newValue, max));
};

export const CounterInput = (props: Props): React.Node => {
  const { locale } = useLocale();

  const { min = 0, max = 1e99, step = 1 } = props;

  const { accept, format, delocalizeNumber, localizeNumber } = makeNumberFormat(
    {
      scale: getFractionSize(step),
      locale,
    },
  );
  const rifm = useRifm({
    accept,
    format,
    value: localizeNumber(props.value),
    onChange: formatted => props.onChange(delocalizeNumber(formatted)),
  });

  const incBy = diff => {
    const value =
      props.value.length === 0 ? min : Number.parseFloat(props.value) + diff;
    return round({ value, step, max, min }).toString();
  };

  const inc = () => props.onChange(incBy(step));
  const dec = () => props.onChange(incBy(-step));

  return (
    <FilledInput
      css={{
        '.MuiInputBase-input': {
          textAlign: 'center',
        },
      }}
      startAdornment={
        <InputAdornment
          position="start"
          css={{
            marginBottom: '2px',
            height: '32px',
            display: 'flex',
            maxHeight: '2em',
            alignSelf: 'flex-end',
          }}
        >
          <IconButton
            css={{ padding: 4, color: 'rgba(0, 0, 0, 0.3)' }}
            onClick={dec}
            tabIndex={-1}
          >
            <RemoveCircle />
          </IconButton>
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment
          position="end"
          css={{
            marginBottom: '2px',
            height: '32px',
            display: 'flex',
            maxHeight: '2em',
            alignSelf: 'flex-end',
          }}
        >
          <IconButton
            css={{ padding: 4, color: 'rgba(0, 0, 0, 0.3)' }}
            onClick={inc}
            tabIndex={-1}
          >
            <AddCircle />
          </IconButton>
        </InputAdornment>
      }
      value={rifm.value}
      onChange={rifm.onChange}
      disabled={props.disabled}
      onBlur={props.onBlur}
      onKeyDown={evt => {
        if (evt.key === 'ArrowUp') {
          inc();
          evt.preventDefault();
        }
        if (evt.key === 'ArrowDown') {
          dec();
          evt.preventDefault();
        }
      }}
    />
  );
};
