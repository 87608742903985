/**
 * @generated SignedSource<<1e8ca375fc9e7437696008a924e8a53a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpsertCheckoutPageInput = {
  checkoutPage?: CheckoutPageInput | null;
  clientMutationId?: string | null;
};
export type CheckoutPageInput = {
  cardPayment?: boolean | null;
  currency?: string | null;
  discountCode?: boolean | null;
  id?: string | null;
  paymentInterval: number;
  quantity: number;
  sepaDebitPayment?: boolean | null;
  stripeCustomerId?: string | null;
  stripePriceId?: string | null;
  stripeProductId?: string | null;
  unitAmount?: number | null;
};
export type plansUpsertCheckoutPageMutation$variables = {
  input: UpsertCheckoutPageInput;
};
export type plansUpsertCheckoutPageMutation$data = {
  readonly upsertCheckoutPage: {
    readonly checkoutPage: {
      readonly cardPayment: boolean;
      readonly discountCode: boolean;
      readonly id: string;
      readonly product: {
        readonly description: string | null;
        readonly name: string | null;
      } | null;
      readonly quantity: number;
      readonly sepaDebitPayment: boolean;
      readonly slug: string | null;
      readonly stripeCustomerId: string | null;
      readonly stripeProductId: string | null;
      readonly unitAmount: number | null;
    } | null;
  } | null;
};
export type plansUpsertCheckoutPageMutation = {
  response: plansUpsertCheckoutPageMutation$data;
  variables: plansUpsertCheckoutPageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stripeProductId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unitAmount",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stripeCustomerId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cardPayment",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sepaDebitPayment",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discountCode",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "plansUpsertCheckoutPageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertCheckoutPagePayload",
        "kind": "LinkedField",
        "name": "upsertCheckoutPage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CheckoutPage",
            "kind": "LinkedField",
            "name": "checkoutPage",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "StripeProduct",
                "kind": "LinkedField",
                "name": "product",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "plansUpsertCheckoutPageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertCheckoutPagePayload",
        "kind": "LinkedField",
        "name": "upsertCheckoutPage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CheckoutPage",
            "kind": "LinkedField",
            "name": "checkoutPage",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "StripeProduct",
                "kind": "LinkedField",
                "name": "product",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "19badeb28e2d7c96ee768480e21c0e6b",
    "id": null,
    "metadata": {},
    "name": "plansUpsertCheckoutPageMutation",
    "operationKind": "mutation",
    "text": "mutation plansUpsertCheckoutPageMutation(\n  $input: UpsertCheckoutPageInput!\n) {\n  upsertCheckoutPage(input: $input) {\n    checkoutPage {\n      id\n      slug\n      quantity\n      stripeProductId\n      unitAmount\n      stripeCustomerId\n      cardPayment\n      sepaDebitPayment\n      discountCode\n      product {\n        name\n        description\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "16962cd93a09c0f8285fcf3a63024d90";

export default node;
