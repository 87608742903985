import type { Translate } from '@realadvisor/translator/engine';
import { differenceInWeeks, formatDistanceStrict } from 'date-fns';

import { number_of_string } from './number-format';

export const abbrDateDifference = (
  t: Translate,
  dateA: Date,
  dateB: Date,
  abs?: boolean,
): { delta: number | null; measurement: string; text: string } => {
  const measurements: [
    string,
    'year' | 'month' | 'week' | 'day' | 'hour' | 'minute' | undefined,
  ][] = [
    ['y', 'year'],
    ['mo', 'month'],
    ['w', 'week'],
    ['d', 'day'],
    ['h', 'hour'],
    ['m', 'minute'],
    ['now', undefined],
  ];

  for (let i = 0; i !== measurements.length; i += 1) {
    const [measurement, unit] = measurements[i];
    let measurementText;

    switch (measurement) {
      case 'y':
        measurementText = t('yearAbbr');
        break;
      case 'mo':
        measurementText = t('monthAbbr');
        break;
      case 'w':
        measurementText = t('weekAbbr');
        break;
      case 'd':
        measurementText = t('dayAbbr');
        break;
      case 'h':
        measurementText = t('hourAbbr');
        break;
      case 'm':
        measurementText = t('minuteAbbr');
        break;
      case 'now':
        measurementText = t('nowAbbr');
    }

    const delta =
      unit == null
        ? null
        : unit === 'week'
        ? // formatDistanceStrict doesn't have weeks unit
          differenceInWeeks(dateA, dateB, { roundingMethod: 'round' })
        : // https://date-fns.org/v2.29.3/docs/formatDistanceStrict
          // returns diff b/w dates in provided unit without any helpers
          // eg: '10 months', we match starting number part 10
          // 0 in case diff is < unit, i.e 0 months
          number_of_string(
            formatDistanceStrict(dateA, dateB, { unit }).match(/^\d+/)?.[0] ??
              '0',
          );
    if (delta !== 0 && !Number.isNaN(delta)) {
      return {
        delta,
        measurement,
        text: [
          delta == null ? null : abs === true ? Math.abs(delta) : delta,
          measurementText,
        ]
          .filter(Boolean)
          .join(''),
      };
    }
  }

  return {
    delta: 0,
    measurement: 'unknown',
    text: '',
  };
};
