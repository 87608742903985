import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';
import { graphql, useLazyLoadQuery } from 'react-relay';

import { ExitToApp } from '../icons/exit-to-app';
import type { leadRefusedReasonsQuery } from '../shared/__generated__/leadRefusedReasonsQuery.graphql';
import { useTheme } from '../src/hooks/theme';

type Props = {
  onSelect: (note: string) => void;
};

export const LeadRefusedReasons = ({ onSelect }: Props) => {
  const { colors } = useTheme();
  const { dictionaries_connection } = useLazyLoadQuery<leadRefusedReasonsQuery>(
    graphql`
      query leadRefusedReasonsQuery {
        dictionaries_connection(where: { type: { _eq: lead_refused_types } }) {
          edges {
            node {
              id
              label
            }
          }
        }
      }
    `,
    {},
  );

  const leadRefusedReasons = [];
  for (const edge of dictionaries_connection.edges ?? []) {
    if (edge.node != null) {
      leadRefusedReasons.push({ ...edge.node, label: edge.node.label ?? '' });
    }
  }

  return (
    <List>
      {leadRefusedReasons.map(({ id, label }) => (
        <ListItem button={true} key={id} onClick={() => onSelect(label)}>
          <ListItemAvatar>
            <Avatar style={{ background: colors.error }}>
              <ExitToApp />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={label} />
        </ListItem>
      ))}
    </List>
  );
};
