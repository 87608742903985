/**
 * @generated SignedSource<<7367d8622db1bc2efd3ab4e28b61d64b>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type LeadAppraisalInheritanceNextStep = "buy" | "sell" | "share";
export type LeadAppraisalReason = "already_for_sale" | "buy_soon" | "buy_this" | "inheritance" | "move_rental" | "not_selling" | "not_set" | "other" | "other_not_owner" | "other_owner" | "other_renting" | "refinance" | "sell" | "separation";
export type LeadAppraisalSeparationNextStep = "buy" | "sell" | "share";
export type LeadAppraisalStep = "address" | "asset_details" | "asset_type" | "result" | "user_details" | "verification";
export type LeadBuyHorizon = "not_set" | "over_12_months" | "under_12_months" | "under_3_months";
export type LeadGender = "female" | "male";
export type LeadMortgageBuyingStatus = "identified" | "offer" | "searching" | "visited";
export type LeadMortgageInterestRateType = "fixed" | "variable";
export type LeadMortgageLenderType = "bank" | "insurance" | "pension_fund";
export type LeadMortgageStatus = "not_started" | "working_with_bank" | "working_with_independent";
export type LeadMortgageStep = "financials" | "result" | "situation" | "type" | "verification";
export type LeadOwnership = "co_ownership" | "corporate_ownership" | "sole_ownership";
export type LeadProfessionalSituation = "business_owner" | "employed" | "retired" | "self_employed" | "unemployed";
export type LeadPropertyUsage = "not_set" | "other" | "primary_residence" | "rental_property" | "secondary_residence";
export type LeadRelationship = "agent" | "buyer" | "heir" | "not_set" | "other" | "owner" | "tenant";
export type LeadSaleHorizon = "already_for_sale" | "from_1_to_2_years" | "from_2_years" | "from_6_to_12_months" | "not_selling" | "not_set" | "under_6_months";
export type LeadStatus = "active" | "lost" | "won";
export type LotBrochurePdfCompressionQuality = "high" | "low" | "off";
export type LotCurrency = "CHF" | "EUR" | "GBP";
export type LotDummyLivingSurface = "built_surface" | "gross_surface" | "living_surface" | "unpublished" | "usable_surface" | "weighted_surface";
export type LotDummyUsableSurface = "built_surface" | "gross_surface" | "living_surface" | "unpublished" | "usable_surface" | "weighted_surface";
export type LotExpectedTimeToSell = "not_set" | "showcase_listing" | "under_12_month" | "under_3_month" | "under_6_month";
export type LotMandateType = "commission_based" | "fixed_fee";
export type LotPriceUnit = "daily" | "m2yearly" | "monthly" | "sell" | "sellm2" | "weekly" | "yearly";
export type LotPublishStep = "contact" | "details" | "listing" | "order" | "photos" | "price" | "property";
export type LotSuccessFeeType = "custom" | "percentage_of_proceeds_above_sale_price";
export type MortgageTrancheType = "fixed" | "variable";
export type PropertyCooling = "air_conditioning" | "ceiling_fans" | "none";
export type PropertyHeatingDistribution = "floor" | "forced_air" | "other" | "radiators" | "stove";
export type PropertyOrientation = "E" | "N" | "NE" | "NW" | "S" | "SE" | "SW" | "W";
export type PropertySearchNotification = "daily" | "never" | "weekly";
export type UserMaritalStatus = "cohabitation" | "divorced" | "married" | "separated" | "single" | "widowed";
export type UpsertLotInput = {|
  clientMutationId?: ?string,
  lot?: ?LotInput,
|};
export type LotInput = {|
  availableFrom?: ?string,
  brochureDescription?: ?string,
  brochurePdfCompressionQuality?: ?LotBrochurePdfCompressionQuality,
  brochureTitle?: ?string,
  brokerId?: ?string,
  buyersIds?: ?$ReadOnlyArray<?string>,
  collaboratorsIds?: ?$ReadOnlyArray<?string>,
  commissionRate?: ?number,
  currency?: ?LotCurrency,
  description?: ?string,
  developmentId?: ?string,
  dummyBrokerName?: ?string,
  dummyBrokerPhone?: ?string,
  dummyCountryCode?: ?string,
  dummyLat?: ?number,
  dummyLivingSurface?: ?LotDummyLivingSurface,
  dummyLng?: ?number,
  dummyLocality?: ?string,
  dummyOrganisationCountryCode?: ?string,
  dummyOrganisationLat?: ?number,
  dummyOrganisationLng?: ?number,
  dummyOrganisationLocality?: ?string,
  dummyOrganisationName?: ?string,
  dummyOrganisationPhone?: ?string,
  dummyOrganisationPostcode?: ?string,
  dummyOrganisationRoute?: ?string,
  dummyOrganisationState?: ?string,
  dummyOrganisationStreetNumber?: ?string,
  dummyPostcode?: ?string,
  dummyRoute?: ?string,
  dummyState?: ?string,
  dummyStreetNumber?: ?string,
  dummyUsableSurface?: ?LotDummyUsableSurface,
  durationInMonths?: ?number,
  enableAutoMatching?: ?boolean,
  enquiryResponseEnabled?: ?boolean,
  enquiryResponseTemplate?: ?EmailTemplateInput,
  expectedTimeToSell?: ?LotExpectedTimeToSell,
  fixedFee?: ?number,
  hasSuccessFee?: ?boolean,
  hidePriceOnBrochure?: ?boolean,
  hidePriceOnPortals?: ?boolean,
  hideRoute?: ?boolean,
  hideRouteOnBrochure?: ?boolean,
  id?: ?string,
  isExclusive?: ?boolean,
  isPaid?: ?boolean,
  isSalesTaxInclusive?: ?boolean,
  lead?: ?LeadInput,
  leadId?: ?string,
  listingUrl?: ?string,
  lotReference?: ?string,
  mandateSalePrice?: ?number,
  mandateType?: ?LotMandateType,
  minimumNetSellerPrice?: ?number,
  mortgageBrokerId?: ?string,
  offerType?: ?string,
  originId?: ?string,
  priceUnit?: ?LotPriceUnit,
  property?: ?PropertyInput,
  propertyId?: ?string,
  publishStep?: ?LotPublishStep,
  rentExtra?: ?number,
  rentNet?: ?number,
  responseSenderId?: ?string,
  salePrice?: ?number,
  sellersIds?: ?$ReadOnlyArray<?string>,
  sendEnquiriesToSellers?: ?boolean,
  signedAt?: ?string,
  status?: ?string,
  successFeeCustom?: ?string,
  successFeePercentage?: ?number,
  successFeeType?: ?LotSuccessFeeType,
  title?: ?string,
  useCustomBrochureDescription?: ?boolean,
  useCustomBrochureTitle?: ?boolean,
  useDummyAddress?: ?boolean,
  useDummyContact?: ?boolean,
  videoUrl?: ?string,
  virtualVisitUrl?: ?string,
|};
export type PropertyInput = {|
  balconySurface?: ?number,
  basementSurface?: ?number,
  borrowerLeadId?: ?string,
  buildingCondition?: ?number,
  buildingStandard?: ?number,
  buildingVolume?: ?number,
  buildingVolumeNorm?: ?string,
  builtSurface?: ?number,
  ceilingHeight?: ?number,
  charges?: ?number,
  commercialPotentialRentalIncome?: ?number,
  commercialSurface?: ?number,
  commercialYearlyRentalIncome?: ?number,
  constructionYear?: ?number,
  consumption?: ?number,
  cooling?: ?PropertyCooling,
  country?: ?string,
  countryCode?: ?string,
  createdBy?: ?string,
  ctn?: ?string,
  deletedByUser?: ?boolean,
  distanceToAirport?: ?number,
  distanceToCityCenter?: ?number,
  distanceToGroceryShop?: ?number,
  distanceToHighSchool?: ?number,
  distanceToHospital?: ?number,
  distanceToKindergarten?: ?number,
  distanceToMotorway?: ?number,
  distanceToPrimarySchool?: ?number,
  distanceToPublicTransport?: ?number,
  distanceToSportsCenter?: ?number,
  distanceToTrainStation?: ?number,
  emissions?: ?number,
  energyCertificateVersion?: ?string,
  energyConsumptionClass?: ?string,
  energyInspectionDate?: ?string,
  energyReferenceYear?: ?number,
  estimatedHigherEnergyCost?: ?number,
  estimatedLowerEnergyCost?: ?number,
  financingInstitutionId?: ?string,
  financingLeadId?: ?string,
  flatCondition?: ?number,
  floorOfFlat?: ?number,
  gardenSurface?: ?number,
  gasEmissionsClass?: ?string,
  googleAddressId?: ?string,
  grossFloorSurface?: ?number,
  hasAttic?: ?boolean,
  hasCellar?: ?boolean,
  hasElevator?: ?boolean,
  hasFireplace?: ?boolean,
  hasGym?: ?boolean,
  hasPhotovoltaicPanels?: ?boolean,
  hasSauna?: ?boolean,
  hasStorageRoom?: ?boolean,
  hasSwimmingPool?: ?boolean,
  hasView?: ?boolean,
  hasWellnessArea?: ?boolean,
  heating?: ?string,
  heatingDistribution?: ?PropertyHeatingDistribution,
  id?: ?string,
  incomeIncludesParking?: ?boolean,
  isChildFriendly?: ?boolean,
  isCommercialFullyRented?: ?boolean,
  isLuxury?: ?boolean,
  isMaisonette?: ?boolean,
  isParkingFullyRented?: ?boolean,
  isRental?: ?boolean,
  isResidentialFullyRented?: ?boolean,
  isSecondaryResidence?: ?boolean,
  isWheelchairAccessible?: ?boolean,
  landSurface?: ?number,
  lat?: ?number,
  livingSurface?: ?number,
  lng?: ?number,
  locality?: ?string,
  localityId?: ?string,
  macroLocation?: ?number,
  microLocation?: ?number,
  minergieCode?: ?number,
  monthlyGrossRent?: ?number,
  mortgage?: ?MortgageInput,
  numberOfBathrooms?: ?number,
  numberOfBedrooms?: ?number,
  numberOfCommercialUnits?: ?number,
  numberOfFlats?: ?number,
  numberOfFloors?: ?number,
  numberOfIndoorParkings?: ?number,
  numberOfLifts?: ?number,
  numberOfOutdoorParkings?: ?number,
  numberOfResidentialUnits?: ?number,
  numberOfRooms?: ?number,
  numberOfToilets?: ?number,
  orientation?: ?PropertyOrientation,
  ownersIds?: ?$ReadOnlyArray<?string>,
  parkingPotentialRentalIncome?: ?number,
  parkingYearlyRentalIncome?: ?number,
  perceivedValue?: ?number,
  plotHasConstructibleReserve?: ?boolean,
  plotIsConstructible?: ?boolean,
  plotNumber?: ?string,
  postcode?: ?string,
  propertyTypeId?: ?string,
  propertyTypeName?: ?string,
  relevantServitudes?: ?boolean,
  renovationFund?: ?number,
  renovationYear?: ?number,
  residentialPotentialRentalIncome?: ?number,
  residentialSurface?: ?number,
  residentialYearlyRentalIncome?: ?number,
  route?: ?string,
  shareOfOwnership?: ?number,
  side?: ?string,
  state?: ?string,
  streetNumber?: ?string,
  subLocality?: ?string,
  terraceSurface?: ?number,
  usableSurface?: ?number,
  weightedFloorSurface?: ?number,
|};
export type MortgageInput = {|
  amortisation?: ?number,
  annualRentalIncome?: ?number,
  bankerId?: ?string,
  borrowerPropertyLocation?: ?string,
  expiryDate?: ?string,
  id?: ?string,
  interestRate?: ?number,
  lender?: ?string,
  lenderId?: ?string,
  loanAmount?: ?number,
  mortgageBrokerId?: ?string,
  property?: ?PropertyInput,
  propertyId?: ?string,
  signedAt?: ?string,
  startDate?: ?string,
  tranches?: ?$ReadOnlyArray<?MortgageTrancheInput>,
  tranchesExist?: ?boolean,
  type?: ?MortgageTrancheType,
  value?: ?number,
|};
export type MortgageTrancheInput = {|
  amortisation?: ?number,
  expiryDate?: ?string,
  fixedFor?: ?number,
  interestRate?: ?number,
  loanAmount?: ?number,
  startDate?: ?string,
  type?: ?MortgageTrancheType,
|};
export type LeadInput = {|
  accessComment?: ?string,
  accessStatus?: ?boolean,
  annualPremium?: ?number,
  appraisalInheritanceNextStep?: ?LeadAppraisalInheritanceNextStep,
  appraisalMarketTime?: ?string,
  appraisalMonthlyGrossRent?: ?number,
  appraisalNextStep?: ?string,
  appraisalOpenToOffer?: ?string,
  appraisalPerceivedValue?: ?number,
  appraisalPerception?: ?string,
  appraisalPropertyBalconySurface?: ?number,
  appraisalPropertyBuildingCondition?: ?number,
  appraisalPropertyBuiltSurface?: ?number,
  appraisalPropertyCommercialPotentialRentalIncome?: ?number,
  appraisalPropertyCommercialSurface?: ?number,
  appraisalPropertyCommercialYearlyRentalIncome?: ?number,
  appraisalPropertyConstructionYear?: ?number,
  appraisalPropertyCountry?: ?string,
  appraisalPropertyCountryCode?: ?string,
  appraisalPropertyCtn?: ?string,
  appraisalPropertyFloorOfFlat?: ?number,
  appraisalPropertyGardenSurface?: ?number,
  appraisalPropertyGoogleAddress?: ?GoogleAddressInput,
  appraisalPropertyGoogleAddressId?: ?string,
  appraisalPropertyHasElevator?: ?boolean,
  appraisalPropertyHasGarden?: ?boolean,
  appraisalPropertyHasStorageRoom?: ?boolean,
  appraisalPropertyHasSwimmingPool?: ?boolean,
  appraisalPropertyHasView?: ?boolean,
  appraisalPropertyIncomeIncludesParking?: ?boolean,
  appraisalPropertyIsCommercialFullyRented?: ?boolean,
  appraisalPropertyIsParkingFullyRented?: ?boolean,
  appraisalPropertyIsRenovated?: ?boolean,
  appraisalPropertyIsResidentialFullyRented?: ?boolean,
  appraisalPropertyKnowPlotNumber?: ?boolean,
  appraisalPropertyLandSurface?: ?number,
  appraisalPropertyLat?: ?number,
  appraisalPropertyLivingSurface?: ?number,
  appraisalPropertyLng?: ?number,
  appraisalPropertyLocality?: ?string,
  appraisalPropertyNumberOfBathrooms?: ?number,
  appraisalPropertyNumberOfBedrooms?: ?number,
  appraisalPropertyNumberOfCommercialUnits?: ?number,
  appraisalPropertyNumberOfFloors?: ?number,
  appraisalPropertyNumberOfIndoorParkings?: ?number,
  appraisalPropertyNumberOfOutdoorParkings?: ?number,
  appraisalPropertyNumberOfResidentialUnits?: ?number,
  appraisalPropertyNumberOfRooms?: ?number,
  appraisalPropertyOccupied?: ?string,
  appraisalPropertyParkingPotentialRentalIncome?: ?number,
  appraisalPropertyParkingYearlyRentalIncome?: ?number,
  appraisalPropertyPlotNumber?: ?string,
  appraisalPropertyPostcode?: ?string,
  appraisalPropertyPropertyTypeId?: ?string,
  appraisalPropertyRenovationYear?: ?number,
  appraisalPropertyResidentialPotentialRentalIncome?: ?number,
  appraisalPropertyResidentialSurface?: ?number,
  appraisalPropertyResidentialYearlyRentalIncome?: ?number,
  appraisalPropertyRoute?: ?string,
  appraisalPropertySide?: ?string,
  appraisalPropertyState?: ?string,
  appraisalPropertyStreetNumber?: ?string,
  appraisalPropertyUsage?: ?string,
  appraisalReason?: ?LeadAppraisalReason,
  appraisalSellingWith?: ?string,
  appraisalSeparationNextStep?: ?LeadAppraisalSeparationNextStep,
  appraisalStep?: ?LeadAppraisalStep,
  askingPrice?: ?number,
  assetsSecondPillar?: ?number,
  assetsThirdPillarAAnnualPremiumOrganization?: ?string,
  assetsThirdPillarAOrganisation?: ?string,
  brokerId?: ?string,
  buyHorizon?: ?LeadBuyHorizon,
  callTimes?: ?$ReadOnlyArray<?string>,
  cantonalPlanComment?: ?string,
  cantonalPlanStatus?: ?boolean,
  comment?: ?string,
  completed?: ?boolean,
  constructionPermitComment?: ?string,
  constructionPermitStatus?: ?boolean,
  contactId?: ?string,
  dateOfBirth?: ?string,
  deadline?: ?string,
  debt?: ?number,
  debts?: ?$ReadOnlyArray<?LeadDebtInput>,
  donation?: ?number,
  emailAddress?: ?string,
  financingLeadLostId?: ?string,
  financingSecured?: ?boolean,
  firstName?: ?string,
  gender?: ?LeadGender,
  groundComment?: ?string,
  groundStatus?: ?boolean,
  hasAcceptedTerms?: ?boolean,
  hasDependantChildren?: ?boolean,
  hasNoStreetNumber?: ?boolean,
  hasOtherCredits?: ?boolean,
  id?: ?string,
  inventoryProtectionComment?: ?string,
  inventoryProtectionStatus?: ?boolean,
  landingPageUrl?: ?string,
  lastBonus?: ?number,
  lastName?: ?string,
  leadLostId?: ?string,
  localityPlanComment?: ?string,
  localityPlanStatus?: ?boolean,
  mandateProbability?: ?number,
  maritalStatus?: ?string,
  maxPropertyValue?: ?number,
  mortgageAutoMortgageType?: ?boolean,
  mortgageBuyingStatus?: ?LeadMortgageBuyingStatus,
  mortgageComments?: ?string,
  mortgageFinancialInstitution?: ?string,
  mortgageGoogleAddress?: ?GoogleAddressInput,
  mortgageGoogleAddressId?: ?string,
  mortgageHasMortgage?: ?boolean,
  mortgageInsuranceProviderId?: ?string,
  mortgageInsuranceRevenue?: ?number,
  mortgageInterestRate?: ?number,
  mortgageInterestRateType?: ?LeadMortgageInterestRateType,
  mortgageIsConstruction?: ?boolean,
  mortgageIsPropertyIdentified?: ?boolean,
  mortgageLenderId?: ?string,
  mortgageLenderName?: ?string,
  mortgageLenderType?: ?LeadMortgageLenderType,
  mortgageMortgageType?: ?string,
  mortgageNotaryId?: ?string,
  mortgageProductMortgage?: ?boolean,
  mortgageProductThirdPillar?: ?boolean,
  mortgagePropertyUsage?: ?string,
  mortgagePropertyValue?: ?number,
  mortgagePurchaseDate?: ?string,
  mortgagePurchasePrice?: ?number,
  mortgageRealEstateBrokerId?: ?string,
  mortgageReportType?: ?string,
  mortgageRevenue?: ?number,
  mortgageSell?: ?boolean,
  mortgageStartDate?: ?string,
  mortgageStatus?: ?LeadMortgageStatus,
  mortgageStep?: ?LeadMortgageStep,
  mortgageTerm?: ?string,
  nationalityId?: ?string,
  neighbourhoodPlanComment?: ?string,
  neighbourhoodPlanStatus?: ?boolean,
  neighbourhoodRelations?: ?string,
  noiseComment?: ?string,
  noiseStatus?: ?boolean,
  numberOfBuyers?: ?number,
  numberOfDependantChildren?: ?number,
  occupationId?: ?string,
  occupationText?: ?string,
  otherCredits?: ?number,
  ownership?: ?LeadOwnership,
  parentLeadId?: ?string,
  partnerAnnualPremium?: ?number,
  partnerDateOfBirth?: ?string,
  partnerDonation?: ?number,
  partnerFirstName?: ?string,
  partnerGender?: ?LeadGender,
  partnerHasOtherCredits?: ?boolean,
  partnerHasThirdPillar?: ?boolean,
  partnerLastBonus?: ?number,
  partnerLastName?: ?string,
  partnerMaritalStatus?: ?UserMaritalStatus,
  partnerNationalityId?: ?string,
  partnerOccupationText?: ?string,
  partnerOtherCredits?: ?number,
  partnerProfessionalSituation?: ?LeadProfessionalSituation,
  partnerResidenceCountryCode?: ?string,
  partnerResidenceStateId?: ?string,
  partnerSavings?: ?number,
  partnerSecondPillar?: ?number,
  partnerThirdPillar?: ?number,
  partnerThirdPillarAOrganisation?: ?string,
  partnerUseBankThirdPillar?: ?boolean,
  partnerUseInsuranceThirdPillar?: ?boolean,
  partnerWorkPermitId?: ?string,
  partnerYearlyRevenues?: ?number,
  partnerYearlyRevenuesAtRetirement?: ?number,
  perceivedValueAgent?: ?number,
  phoneNumber?: ?string,
  pollutionComment?: ?string,
  pollutionStatus?: ?boolean,
  potentialDevelopment?: ?boolean,
  predictedListingDate?: ?string,
  professionalSituation?: ?LeadProfessionalSituation,
  property?: ?PropertyInput,
  propertySearchNotification?: ?PropertySearchNotification,
  propertyUsage?: ?LeadPropertyUsage,
  receivedMortgageOffer?: ?boolean,
  referrer?: ?string,
  relationship?: ?LeadRelationship,
  residenceCountryCode?: ?string,
  residenceStateId?: ?string,
  saleComments?: ?string,
  saleHasFound?: ?boolean,
  saleHorizon?: ?LeadSaleHorizon,
  saleIsSeller?: ?boolean,
  salePrice?: ?number,
  saleStarted?: ?boolean,
  savings?: ?number,
  secondContactId?: ?string,
  secondPillar?: ?number,
  servitudesComment?: ?string,
  servitudesStatus?: ?boolean,
  source?: ?string,
  sourceId?: ?string,
  sourceOrganisationId?: ?string,
  stageId?: ?string,
  status?: ?LeadStatus,
  stepsTaken?: ?string,
  tags?: ?$ReadOnlyArray<?string>,
  thirdPillar?: ?number,
  treesComment?: ?string,
  treesStatus?: ?boolean,
  useBankThirdPillar?: ?boolean,
  useInsuranceThirdPillar?: ?boolean,
  useSecondPillar?: ?boolean,
  useThirdPillar?: ?boolean,
  userInfoIp?: ?string,
  userInfoUserAgent?: ?string,
  workPermitId?: ?string,
  yearlyRevenues?: ?number,
  yearlyRevenuesAtRetirement?: ?number,
|};
export type GoogleAddressInput = {|
  country?: ?string,
  countryCode?: ?string,
  ctn?: ?string,
  formattedAddress?: ?string,
  googlePlace?: ?GooglePlaceInput,
  googlePlaceId?: ?string,
  id?: ?string,
  lat?: ?number,
  lng?: ?number,
  locality?: ?string,
  postcode?: ?string,
  route?: ?string,
  state?: ?string,
  streetNumber?: ?string,
|};
export type GooglePlaceInput = {|
  address_components: $ReadOnlyArray<?GooglePlaceAddressComponents>,
  formatted_address?: ?string,
  geometry?: ?GooglePlaceGeometry,
  partial_match?: ?boolean,
  place_id?: ?string,
  plus_code?: ?PlusCode,
  types?: ?$ReadOnlyArray<?string>,
|};
export type GooglePlaceAddressComponents = {|
  long_name: string,
  short_name: string,
  types: $ReadOnlyArray<string>,
|};
export type GooglePlaceGeometry = {|
  bounds?: ?GooglePlaceGeometryGeometryBounds,
  location?: ?GooglePlaceGeometryLocation,
  location_type?: ?string,
  viewport?: ?GooglePlaceGeometryGeometryViewport,
|};
export type GooglePlaceGeometryGeometryBounds = {|
  east?: ?number,
  north?: ?number,
  northeast?: ?GooglePlaceGeometryBoundsNortheast,
  south?: ?number,
  southwest?: ?GooglePlaceGeometryBoundsSouthwest,
  west?: ?number,
|};
export type GooglePlaceGeometryBoundsNortheast = {|
  lat?: ?number,
  lng?: ?number,
|};
export type GooglePlaceGeometryBoundsSouthwest = {|
  lat?: ?number,
  lng?: ?number,
|};
export type GooglePlaceGeometryLocation = {|
  lat?: ?number,
  lng?: ?number,
|};
export type GooglePlaceGeometryGeometryViewport = {|
  east?: ?number,
  north?: ?number,
  northeast?: ?GooglePlaceGeometryViewportNortheast,
  south?: ?number,
  southwest?: ?GooglePlaceGeometryViewportSouthwest,
  west?: ?number,
|};
export type GooglePlaceGeometryViewportNortheast = {|
  lat?: ?number,
  lng?: ?number,
|};
export type GooglePlaceGeometryViewportSouthwest = {|
  lat?: ?number,
  lng?: ?number,
|};
export type PlusCode = {|
  compound_code?: ?string,
  global_code?: ?string,
|};
export type LeadDebtInput = {|
  debt?: ?number,
  expiration?: ?string,
  id?: ?string,
  leadId?: ?string,
  rate?: ?number,
|};
export type EmailTemplateInput = {|
  addSignature?: ?boolean,
  bcc?: ?$ReadOnlyArray<?EmailMessageRecipientInput>,
  cc?: ?$ReadOnlyArray<?EmailMessageRecipientInput>,
  files?: ?$ReadOnlyArray<?EmailTemplateFilesInput>,
  id?: ?string,
  isSharedWithEveryone?: ?boolean,
  isSharedWithOrganisation?: ?boolean,
  name?: ?string,
  subject?: ?string,
  subjectPlaintext?: ?string,
  text?: ?string,
  textHTML?: ?string,
  textPlaintext?: ?string,
  type?: ?string,
|};
export type EmailMessageRecipientInput = {|
  email: string,
  name?: ?string,
|};
export type EmailTemplateFilesInput = {|
  id?: ?string,
  mimetype?: ?string,
  name?: ?string,
  size?: ?number,
  url?: ?string,
|};
export type lotMandateCreateDialogMutation$variables = {|
  input: UpsertLotInput,
|};
export type lotMandateCreateDialogMutation$data = {|
  +upsertLot: ?{|
    +lot: ?{|
      +id: string,
    |},
  |},
|};
export type lotMandateCreateDialogMutation = {|
  response: lotMandateCreateDialogMutation$data,
  variables: lotMandateCreateDialogMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpsertLotPayload",
    "kind": "LinkedField",
    "name": "upsertLot",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lot",
        "kind": "LinkedField",
        "name": "lot",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "lotMandateCreateDialogMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "lotMandateCreateDialogMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "41f98de2fb9664907467dbfc3753e81a",
    "id": null,
    "metadata": {},
    "name": "lotMandateCreateDialogMutation",
    "operationKind": "mutation",
    "text": "mutation lotMandateCreateDialogMutation(\n  $input: UpsertLotInput!\n) {\n  upsertLot(input: $input) {\n    lot {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "60b4466226e149eb38f296fd17a6be74";

export default node;
