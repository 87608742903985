import * as React from 'react';
import type { Emitter } from 'nanoevents';

export type ErrorItem = Readonly<{
  operationName?: string;
  code?: null | number;
  message?: null | string;
  path?: null | ReadonlyArray<null | string>;
  prettyMessage?: null | string;
  trace?: null | ReadonlyArray<null | Readonly<{
    addr?: null | string;
    file?: null | string;
    line?: null | number;
    what?: null | string;
  }>>;
}>;

export type ErrorsEmitter = Emitter<{
  add: (operationName: string, errors: Array<null | ErrorItem>) => void;
}>;

const Close = () => {
  return (
    <svg
      width="24"
      height="24"
      preserveAspectRatio="xMidYMid meet"
      fill="currentColor"
      css={{ display: 'block' }}
    >
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
    </svg>
  );
};

const ExpandMore = ({ open }: { open: boolean }) => {
  return (
    <svg
      width="24"
      height="24"
      preserveAspectRatio="xMidYMid meet"
      fill="#aaa"
      css={{
        position: 'absolute',
        top: 'calc(50% - 12px)',
        right: 16,
        transform: open ? 'rotate(180deg)' : 'none',
      }}
    >
      <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  );
};

const softText = {
  wordWrap: 'break-word',
  overflowWrap: 'break-word',
  hyphens: 'auto',
} as const;

const nameText = {
  fontSize: '0.75em',
  color: '#666',
};

const ErrorHeading = ({
  open,
  deletable,
  onToggle,
  onDelete,
  children,
}: {
  open: boolean;
  deletable: boolean;
  onToggle: () => void;
  onDelete: () => void;
  children: React.ReactNode;
}) => {
  return (
    <div css={{ position: 'relative' }}>
      {deletable && (
        <div
          css={{
            position: 'absolute',
            top: 'calc(50% - 12px)',
            left: 16,
            cursor: 'pointer',
            color: '#ff5722',
            ':hover': {
              color: '#b71c1c',
            },
          }}
          onClick={onDelete}
        >
          <Close />
        </div>
      )}
      <div css={{ padding: '16px 56px', cursor: 'pointer' }} onClick={onToggle}>
        <div css={[softText, { color: 'red' }]}>{children}</div>
        <ExpandMore open={open} />
      </div>
    </div>
  );
};

const ErrorCard = ({
  error,
  deletable,
  onDelete,
}: {
  error: ErrorItem;
  deletable: boolean;
  onDelete: (error: ErrorItem) => void;
}) => {
  const [open, setOpen] = React.useState(false);
  return (
    <div
      css={{
        backgroundColor: '#fff',
        border: '1px solid #eee',
        borderRadius: 4,
      }}
    >
      <ErrorHeading
        open={open}
        deletable={deletable}
        onToggle={() => setOpen(v => !v)}
        onDelete={() => onDelete(error)}
      >
        {error.operationName != null && (
          <>
            <strong>{error.operationName}</strong>:{' '}
          </>
        )}
        {(error.prettyMessage ?? error.message ?? 'no message').slice(0, 150)}
      </ErrorHeading>

      {open && (
        <div
          css={[softText, { display: 'grid', padding: '0 16px 16px', gap: 8 }]}
        >
          {error.operationName != null && (
            <div>
              <div css={nameText}>Operation:</div>
              <div>{error.operationName}</div>
            </div>
          )}
          <div>
            <div css={nameText}>Message:</div>
            <div>{error.message}</div>
          </div>
          <div>
            <div css={nameText}>Path:</div>
            <div>{(error.path ?? ['-']).join('.')}</div>
          </div>
          <div>
            <div css={nameText}>Stack trace:</div>
            <div
              css={{
                display: 'grid',
                gap: 8,
                padding: 8,
                backgroundColor: '#333',
              }}
            >
              {(error.trace ?? []).map(
                (item, index) =>
                  item != null && (
                    <div key={index} css={{ fontSize: '0.75em' }}>
                      <div css={{ alignItems: 'center', flexWrap: 'wrap' }}>
                        <span css={{ color: '#FF6F00' }}>{item.file}</span>
                        <span css={{ color: '#666' }}>:</span>
                        <span css={{ color: '#FF6F00' }}>{item.line}</span>{' '}
                        <span css={{ color: '#fff' }}>{item.what}</span>
                      </div>
                      <div css={{ color: '#999' }}>{item.addr}</div>
                    </div>
                  ),
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

type ErrorReportingProps = {
  errors: ReadonlyArray<null | ErrorItem>;
  emitter?: ErrorsEmitter;
};

export const ErrorReporting = (props: ErrorReportingProps) => {
  const [errors, setErrors] = React.useState<ReadonlyArray<null | ErrorItem>>(
    props.errors,
  );
  React.useEffect(() => {
    if (props.emitter) {
      // subscribe to new errors
      return props.emitter.on('add', (operationName, newErrors) => {
        const newErrorsWithOperationName = newErrors.map(item =>
          item == null ? null : { ...item, operationName },
        );
        setErrors(prev => [...newErrorsWithOperationName, ...prev]);
      });
    }
  }, [props.emitter]);

  if (errors.length === 0) {
    return null;
  }
  return (
    <div
      css={{
        position: 'absolute',
        width: 420,
        maxWidth: '100vw',
        right: 0,
        top: 100,
        zIndex: 10000,
        display: 'grid',
        padding: 8,
        gap: 8,
      }}
    >
      {errors.map((error, index) => {
        if (error == null) {
          return null;
        }
        return (
          <ErrorCard
            key={index}
            error={error}
            deletable={props.emitter != null}
            onDelete={error => {
              setErrors(prev => {
                return prev.filter(item => item !== error);
              });
            }}
          />
        );
      })}
    </div>
  );
};
