import type { ReactNode } from 'react';

import { Box, CircularProgress, keyframes } from '@mui/material';

type LoadingCardProps = {
  loading: boolean;
  children: ReactNode;
};

const LoadingCard = ({ loading, children }: LoadingCardProps) => {
  const fadeIn = keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  `;
  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        animation: `.8s ease-out 0s 1 ${fadeIn};`,
        borderRadius: '10px',
      }}
    >
      {loading && (
        <Box sx={{ position: 'absolute', top: 12, right: 12, zIndex: 1 }}>
          <CircularProgress variant="indeterminate" size={24} />
        </Box>
      )}
      <Box sx={{ opacity: loading ? 0.6 : 'initial', display: 'flex' }}>
        {children}
      </Box>
    </Box>
  );
};

export default LoadingCard;
