/**
 * @generated SignedSource<<14788571b042d4e88dee666a2ec0e82f>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
export type OfferType = "buy" | "rent";
import type { FragmentType } from "relay-runtime";
declare export opaque type SearchesPanel_item$fragmentType: FragmentType;
export type SearchesPanel_item$data = {|
  +createdAt: ?string,
  +id: string,
  +monthlyRentLte: ?number,
  +numberOfRoomsGte: ?number,
  +offerType: OfferType,
  +salePriceLte: ?number,
  +updatedAt: ?string,
  +$fragmentType: SearchesPanel_item$fragmentType,
|};
export type SearchesPanel_item$key = {
  +$data?: SearchesPanel_item$data,
  +$fragmentSpreads: SearchesPanel_item$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SearchesPanel_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "offerType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numberOfRoomsGte",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "salePriceLte",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "monthlyRentLte",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    }
  ],
  "type": "PropertySearch",
  "abstractKey": null
};

(node/*: any*/).hash = "9512c89093166be1e8c7ccebea485094";

export default node;
