/**
 * @generated SignedSource<<b4ce5c35f6b0c8193c43bf3d7ba7c79f>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type PipelineLeadType = "buyer" | "financing" | "sales";
export type PipelineDialogQuery$variables = {|
  leadType?: ?PipelineLeadType,
|};
export type PipelineDialogQuery$data = {|
  +pipelines: $ReadOnlyArray<{|
    +id: string,
    +label: ?string,
    +stages: $ReadOnlyArray<{|
      +id: string,
      +label: string,
    |}>,
  |}>,
|};
export type PipelineDialogQuery = {|
  response: PipelineDialogQuery$data,
  variables: PipelineDialogQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "leadType"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "leadType",
        "variableName": "leadType"
      }
    ],
    "concreteType": "Pipeline",
    "kind": "LinkedField",
    "name": "pipelines",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "LeadStage",
        "kind": "LinkedField",
        "name": "stages",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PipelineDialogQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PipelineDialogQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "50989e67c0ce4220f8a3d5717818d059",
    "id": null,
    "metadata": {},
    "name": "PipelineDialogQuery",
    "operationKind": "query",
    "text": "query PipelineDialogQuery(\n  $leadType: PipelineLeadType\n) {\n  pipelines(leadType: $leadType) {\n    id\n    label\n    stages {\n      id\n      label\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "3e157b5d6ae131444f28e167d4c41691";

export default node;
