import ImageIcon from '@mui/icons-material/Image';
import { Box, type SxProps, Typography } from '@mui/material';
import { type To } from 'react-router-dom';

import { useLocale } from '../../src/hooks/locale';

import { ConditionalLinkWrapper } from './ConditionalLinkWrapper';

type NoPhotosPlaceholderProps =
  | {
      to?: To;
      sx?: SxProps;
      height: number;
      ratio?: never;
    }
  | {
      to?: To;
      sx?: SxProps;
      height?: never;
      ratio: number;
    };

export const NoPhotosPlaceholder: React.FC<
  NoPhotosPlaceholderProps
> = props => {
  const { t } = useLocale();

  return (
    <ConditionalLinkWrapper
      link={props.to != null ? { to: props.to } : undefined}
    >
      <Box
        sx={{
          backgroundColor: 'grey.200',
          color: 'grey.400',
          position: 'relative',
          ...(props.sx ?? {}),
          ...(props.height != null
            ? { height: props.height }
            : { paddingTop: props.ratio * 100 + '%' }),
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            zIndex: 1,
            inset: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ImageIcon sx={{ width: 64, height: 64 }} />
          <Typography variant="body1">{t('No photo')}</Typography>
        </Box>
      </Box>
    </ConditionalLinkWrapper>
  );
};
