import { useCallback } from 'react';

import {
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { type Control, Controller } from 'react-hook-form';

import { useLocale } from '../../../src/hooks/locale';

export const RaSelect = ({
  name,
  label,
  control,
  options,
  multiple = false,
  required = false,
  disabled = false,
  renderValue,
}: {
  name: string;
  label: string;
  control: Control<any>;
  options: { value: string; label: string; disabled?: boolean }[];
  required?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  renderValue?: (value: any) => React.ReactNode;
}) => {
  const { t } = useLocale();
  const rules = {
    required: required ? t('This field is required') : undefined,
  };

  const computeValue = useCallback(
    (value: any) => {
      if (multiple) {
        if (value == null) {
          return [];
        }
        if (!Array.isArray(value)) {
          return [value];
        }
      }
      return value;
    },
    [multiple],
  );

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <FormControl fullWidth error={!!error}>
          <FormLabel style={{ marginBottom: '0.5rem', fontWeight: 500 }}>
            {label}
          </FormLabel>
          <Select
            {...field}
            value={computeValue(field.value) ?? ''}
            fullWidth
            variant="outlined"
            size="small"
            sx={{ background: 'white' }}
            disabled={disabled}
            multiple={multiple}
            renderValue={renderValue}
          >
            {options.map(option => (
              <MenuItem
                key={option.value}
                value={option.value}
                disabled={option.disabled ?? false}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};
