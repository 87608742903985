/**
 * @generated SignedSource<<d6d0c14a626c071004a0357acc6f7e82>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateMultipleLeadsStageInput = {
  clientMutationId?: string | null;
  ids: ReadonlyArray<string | null>;
  stageId: string;
};
export type leadsActionsMenuUpdateLeadsStageMutation$variables = {
  input: UpdateMultipleLeadsStageInput;
};
export type leadsActionsMenuUpdateLeadsStageMutation$data = {
  readonly updateMultipleLeadsStage: {
    readonly leads: ReadonlyArray<{
      readonly id: string;
      readonly stage: {
        readonly label: string;
      } | null;
    }> | null;
  } | null;
};
export type leadsActionsMenuUpdateLeadsStageMutation = {
  response: leadsActionsMenuUpdateLeadsStageMutation$data;
  variables: leadsActionsMenuUpdateLeadsStageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "leadsActionsMenuUpdateLeadsStageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateMultipleLeadsStagePayload",
        "kind": "LinkedField",
        "name": "updateMultipleLeadsStage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Lead",
            "kind": "LinkedField",
            "name": "leads",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "LeadStage",
                "kind": "LinkedField",
                "name": "stage",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "leadsActionsMenuUpdateLeadsStageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateMultipleLeadsStagePayload",
        "kind": "LinkedField",
        "name": "updateMultipleLeadsStage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Lead",
            "kind": "LinkedField",
            "name": "leads",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "LeadStage",
                "kind": "LinkedField",
                "name": "stage",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9a3f041857b5993f614e742b6d6447ec",
    "id": null,
    "metadata": {},
    "name": "leadsActionsMenuUpdateLeadsStageMutation",
    "operationKind": "mutation",
    "text": "mutation leadsActionsMenuUpdateLeadsStageMutation(\n  $input: UpdateMultipleLeadsStageInput!\n) {\n  updateMultipleLeadsStage(input: $input) {\n    leads {\n      id\n      stage {\n        label\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3f4fb1cde4c57a42a684ef82198716a8";

export default node;
