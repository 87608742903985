// Generated from src/svgs/compass.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const Compass = ({ size, fill, className }: Props) => {
  return (
    <svg preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><g clipPath="url(#compass_svg__a)"><path d="M9 0C4.027 0 0 4.026 0 9c0 4.973 4.025 9 9 9 4.973 0 9-4.025 9-9 0-4.973-4.025-9-9-9zM1.8 9c0-1.923.749-3.731 2.109-5.091A7.153 7.153 0 0 1 9 1.8c1.887 0 3.66.725 5.01 2.036L6.38 6.379l-2.544 7.632A7.15 7.15 0 0 1 1.8 9zm6.002-1.198 2.395 2.397-3.593 1.198 1.198-3.595zM9 16.2a7.148 7.148 0 0 1-5.01-2.035l7.63-2.544 2.544-7.632A7.15 7.15 0 0 1 16.2 9c0 3.98-3.22 7.2-7.2 7.2z" fill="#2A2A2A" /></g><defs><clipPath id="compass_svg__a"><path fill="#fff" d="M0 0h18v18H0z" /></clipPath></defs></svg>
  );
}
