import { useMemo } from 'react';

import Alert from '@material-ui/lab/Alert/Alert';
import { Box, List, Typography } from '@mui/material';

import { Markdown } from '../../src/controls/markdown';
import { useLocale } from '../../src/hooks/locale';

type Error = {
  path: string[];
};

type AlertPropertyAppraisableProps = {
  errors: Error[];
};

const AlertPropertyAppraisable = ({
  errors,
}: AlertPropertyAppraisableProps) => {
  const { t } = useLocale();

  const errorMappings = useMemo(
    () => ({
      construction_year: t('buildYear'),
      land_surface: t('landSurface'),
      built_surface: t('Built surface'),
      living_surface: t('Living surface'),
      number_of_rooms: t('Number of rooms'),
      number_of_bedrooms: t('Number of bedrooms'),
      residential_surface: t('Residential surface'),
      residential_yearly_rental_income: t('actualRentalIncomeResidential'),
      number_of_indoor_parkings: t('indoorParking'),
      number_of_outdoor_parkings: t('outdoorParking'),
      number_of_residential_units: t('numberOfResidentialUnits'),
      number_of_commercial_units: t('numberOfCommercialUnits'),
      commercial_surface: t('commercialSurface'),
      indoor_parking_surface: t('indoorParking'),
      outdoor_parking_surface: t('outdoorParking'),
      commercial_yearly_rental_income: t('actualRentalIncomeCommercial'),
      parking_yearly_rental_income: t('actualRentalIncomeParking'),
      parking_potential_rental_income: t('potentialRentalIncomeParking'),
      commercial_potential_rental_income: t('potentialRentalIncomeCommercial'),
      residential_potential_rental_income: t(
        'potentialRentalIncomeResidential',
      ),
    }),
    [t],
  );

  const isTypeError = errors.some(error =>
    error.path.some(pathPart => pathPart === 'type'),
  );

  const mappedErrorPaths = errors.flatMap(e =>
    e.path.map(p => errorMappings[p as keyof typeof errorMappings]),
  );

  return (
    <Alert severity="error">
      <Typography variant="body2" fontWeight={600}>
        {isTypeError ? (
          t('propertyTypeNotAppraisable')
        ) : (
          <Markdown>{t('thisPropertyIsNotAvailableForAppraising')}</Markdown>
        )}
      </Typography>
      {!isTypeError && (
        <List>
          {mappedErrorPaths.map((pathPart, index) => (
            <Box sx={{ marginBottom: 1 }} key={`${pathPart}-${index}`}>
              • {pathPart}
            </Box>
          ))}
        </List>
      )}
    </Alert>
  );
};

export default AlertPropertyAppraisable;
