/**
 * @generated SignedSource<<a6b6e64ef795b207fcbc43daa56535d4>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
type propertyCard_property$fragmentType = any;
export type EditPropertyOwnersInput = {|
  clientMutationId?: ?string,
  ownersIds: $ReadOnlyArray<string>,
  propertyId: string,
|};
export type propertyCardOwnersMutation$variables = {|
  input: EditPropertyOwnersInput,
|};
export type propertyCardOwnersMutation$data = {|
  +editPropertyOwners: ?{|
    +property: ?{|
      +id: string,
      +$fragmentSpreads: propertyCard_property$fragmentType,
    |},
  |},
|};
export type propertyCardOwnersMutation = {|
  response: propertyCardOwnersMutation$data,
  variables: propertyCardOwnersMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "formattedAddress",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  },
  (v2/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Image",
  "kind": "LinkedField",
  "name": "primaryImage",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Email",
  "kind": "LinkedField",
  "name": "primaryEmail",
  "plural": false,
  "selections": [
    (v8/*: any*/),
    (v2/*: any*/)
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "referrer",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isBroker",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isAdmin",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "primary",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "propertyCardOwnersMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EditPropertyOwnersPayload",
        "kind": "LinkedField",
        "name": "editPropertyOwners",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Property",
            "kind": "LinkedField",
            "name": "property",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "propertyCard_property"
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "propertyCardOwnersMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EditPropertyOwnersPayload",
        "kind": "LinkedField",
        "name": "editPropertyOwners",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Property",
            "kind": "LinkedField",
            "name": "property",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isRental",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "livingSurface",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "builtSurface",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numberOfRooms",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numberOfBedrooms",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "compositePropertyType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PropertyType",
                "kind": "LinkedField",
                "name": "propertyType",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "label",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PropertyImage",
                "kind": "LinkedField",
                "name": "propertyImages",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Image",
                    "kind": "LinkedField",
                    "name": "image",
                    "plural": false,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Appraisal",
                "kind": "LinkedField",
                "name": "latestAppraisal",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RealadvisorAppraisal",
                    "kind": "LinkedField",
                    "name": "realadvisor",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "min",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "max",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Place",
                "kind": "LinkedField",
                "name": "municipalityObject",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slug",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "owners",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organisation",
                    "kind": "LinkedField",
                    "name": "organisation",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PhoneNumber",
                    "kind": "LinkedField",
                    "name": "phoneNumbers",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "formattedNumber",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "number",
                        "storageKey": null
                      },
                      (v14/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Email",
                    "kind": "LinkedField",
                    "name": "emails",
                    "plural": true,
                    "selections": [
                      (v8/*: any*/),
                      (v14/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "broker",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Subscription",
                    "kind": "LinkedField",
                    "name": "subscription",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "318db05cc69acdc8f563eea97fd1237d",
    "id": null,
    "metadata": {},
    "name": "propertyCardOwnersMutation",
    "operationKind": "mutation",
    "text": "mutation propertyCardOwnersMutation(\n  $input: EditPropertyOwnersInput!\n) {\n  editPropertyOwners(input: $input) {\n    property {\n      ...propertyCard_property\n      id\n    }\n  }\n}\n\nfragment propertyCard_property on Property {\n  id\n  formattedAddress\n  isRental\n  livingSurface\n  builtSurface\n  numberOfRooms\n  numberOfBedrooms\n  compositePropertyType\n  propertyType {\n    label\n    id\n  }\n  propertyImages {\n    title\n    image {\n      url\n      id\n    }\n    id\n  }\n  latestAppraisal {\n    realadvisor {\n      min\n      max\n    }\n    id\n  }\n  municipalityObject {\n    slug\n    id\n  }\n  owners {\n    id\n    ...userEditableCard_user\n  }\n}\n\nfragment userAvatar_user on User {\n  id\n  firstName\n  lastName\n  primaryImage {\n    url\n    id\n  }\n  primaryEmail {\n    email\n    id\n  }\n  referrer\n  isBroker\n  isAdmin\n}\n\nfragment userEditableCard_user on User {\n  ...userSummary_user\n  id\n  firstName\n  lastName\n  primaryEmail {\n    email\n    id\n  }\n  primaryImage {\n    url\n    id\n  }\n  organisation {\n    name\n    formattedAddress\n    id\n  }\n  subscription {\n    status\n    id\n  }\n}\n\nfragment userSummary_user on User {\n  ...userAvatar_user\n  id\n  displayName\n  organisation {\n    id\n    name\n  }\n  phoneNumbers {\n    formattedNumber\n    number\n    primary\n    id\n  }\n  emails {\n    email\n    primary\n    id\n  }\n  broker {\n    ...userAvatar_user\n    id\n    displayName\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "122e81f6f161106955b244f58c55a2af";

export default node;
