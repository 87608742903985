import { Chip, useTheme } from '@mui/material';

import { useLocale } from '../../../../src/hooks/locale';
import { formatTimeAgo } from '../../../utils/formatting';

import type { Listing } from './ListingCardInfo';
import type { ScrapperListing } from './ListingComparableDetails';

type ListingStatusChipProps = {
  listing: Listing | ScrapperListing;
  variant?: 'status' | 'duration';
};

const useFormatTimeAgoWithTooltip = () => {
  const { t, dateLocale } = useLocale();

  return (date: string) => {
    const { timeAgo } = formatTimeAgo(date, false, false, t, dateLocale, true);
    return { timeAgo };
  };
};

const ListingStatusChip = ({
  listing,
  variant = 'status',
}: ListingStatusChipProps) => {
  const { t } = useLocale();
  const theme = useTheme();
  const formatTimeAgoWithTooltip = useFormatTimeAgoWithTooltip();
  const isOnline = listing.offline_since == null;
  const isStatusVariant = variant === 'status';

  const getStatusInfo = () => {
    const { valid_from, offline_since } = listing;

    if (isOnline) {
      if (isStatusVariant) {
        return { text: t('Online'), color: theme.palette.success.main };
      }
      const { timeAgo } = formatTimeAgoWithTooltip(valid_from);

      return {
        text: t('{{date}} on the market', { date: timeAgo }),
        color: theme.palette.grey[500],
      };
    }

    if (isStatusVariant) {
      const { timeAgo } = formatTimeAgoWithTooltip(offline_since);

      return {
        text: t('offlineSince {{date}}', { date: timeAgo }),
        color: theme.palette.error.main,
      };
    }

    const validFromDate = new Date(valid_from);
    const offlineDate = new Date(offline_since);
    const durationMs = offlineDate.getTime() - validFromDate.getTime();
    const durationHours = Math.floor(durationMs / (1000 * 60 * 60));

    const adjustedDate = new Date();
    adjustedDate.setHours(adjustedDate.getHours() - durationHours);

    const { timeAgo } = formatTimeAgoWithTooltip(adjustedDate.toISOString());

    return {
      text: t('{{date}} on the market', { date: timeAgo }),
      color: theme.palette.grey[500],
    };
  };

  const { text, color } = getStatusInfo();

  return (
    <Chip
      size="small"
      label={text}
      sx={{
        backgroundColor: color,
        color: '#fff',
      }}
    />
  );
};

export default ListingStatusChip;
