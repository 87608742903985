import { Clear } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import { deleteWhereClause } from '../Filters';
import { useSchema } from '../useSchema';

import { Enum } from './Enum';
import { InputObject } from './InputObject';
import { Scalar } from './Scalar';

interface NodeProps {
  name: string;
  node: any;
  path: string[];
  where: any;
  setWhere: (where: any) => void;
}

export const Node = ({ name, node, path, where, setWhere }: NodeProps) => {
  const { getTypeFromPath } = useSchema();
  const type = getTypeFromPath(path);

  if (!type) {
    console.info('No type found for', path);
    return <div>No type found for {path.join('.')}</div>;
  }

  if (type?.kind === 'INPUT_OBJECT') {
    return (
      <InputObject
        name={name}
        node={node}
        path={path}
        where={where}
        setWhere={setWhere}
      />
    );
  }

  if (type?.kind === 'ENUM') {
    return (
      <Enum
        name={name}
        node={node}
        path={path}
        where={where}
        setWhere={setWhere}
      />
    );
  }

  if (type?.kind === 'SCALAR') {
    return (
      <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
        <label>
          <i>{name}</i>
        </label>
        <Scalar
          name={name}
          node={node}
          path={path}
          where={where}
          setWhere={setWhere}
        />
        <IconButton
          sx={{
            padding: '3px',
          }}
          size="small"
          color="error"
          onClick={() => {
            const newWhere = deleteWhereClause(where, path);
            setWhere(newWhere);
          }}
        >
          <Clear sx={{ fontSize: 20 }} />
        </IconButton>
      </div>
    );
  }

  throw new Error('Unknown type kind: ' + type.kind);
};
