import { useCallback, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import OpenInNew from '@mui/icons-material/OpenInNew';
import PlaceOutlined from '@mui/icons-material/PlaceOutlined';
import {
  Alert,
  Box,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import MarkdownParser from '../../../../shared/markdown-parser';
import { useLocale } from '../../../../src/hooks/locale';
import { getCurrencyByCountryCode } from '../../../../src/locale';
import { formatPrice } from '../../../../src/utils/format-price';
import type { GetScraperListingDetailsQuery } from '../../../__generated__/graphql';
import { CollapsibleContent } from '../../../components/CollapsibleContent';
import { Drawer } from '../../../components/drawer/Drawer';
import { PropertyDetails } from '../../../components/PropertyDetails';
import { PropertyFeatures } from '../../../components/PropertyFeatures';
import { ImagesCarousel } from '../../listings/ListingOverview';
import { GET_SCRAPER_LISTING_DETAILS } from '../cmaReportsQueries';

import ListingStatusChip from './ListingStatusChip';
import { usePropertyLabel } from './StepListingsCompare';

type ScrapperListingArray =
  | GetScraperListingDetailsQuery['ch_post_merged_listings']
  | GetScraperListingDetailsQuery['fr_post_merged_listings']
  | GetScraperListingDetailsQuery['es_post_merged_listings']
  | GetScraperListingDetailsQuery['it_post_merged_listings'];

export type ScrapperListing = NonNullable<ScrapperListingArray>[number];

const ListingComparableDetails = () => {
  const { t, countryCode, locale } = useLocale();
  const theme = useTheme();
  const getPropertyLabel = usePropertyLabel;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const currencyToFormat = getCurrencyByCountryCode(countryCode);
  const { scrapperListingId } = useParams<{ scrapperListingId: string }>();

  const { data, error, loading } = useQuery(GET_SCRAPER_LISTING_DETAILS, {
    variables: {
      id: parseInt(scrapperListingId ?? '', 10),
      ch: countryCode === 'CH',
      fr: countryCode === 'FR',
      es: countryCode === 'ES',
      it: countryCode === 'IT',
    },
    skip: !scrapperListingId,
    context: { clientName: 'scrapers' },
  });

  const handleClose = useCallback(
    () =>
      navigate({
        pathname: '..',
        search: searchParams.toString(),
      }),
    [navigate, searchParams],
  );

  const key =
    `${countryCode.toLowerCase()}_post_merged_listings` as keyof GetScraperListingDetailsQuery;

  const scrappedListing = data?.[key]?.[0] as ScrapperListing | undefined;

  const truncateUrl = (url: string) => {
    const parsedUrl = new URL(url);
    return `${parsedUrl.protocol}//${parsedUrl.hostname}`;
  };

  const scrappedProperty: any = useMemo(() => {
    if (!scrappedListing) {
      return null;
    }

    return {
      __property_type: {
        label: getPropertyLabel(scrappedListing.property_type),
        main_type: scrappedListing.property_main_type,
      },
      living_surface: scrappedListing.living_surface,
      land_surface: scrappedListing.land_surface,
      floor_of_flat: scrappedListing.floor_of_flat,
      construction_year: scrappedListing.construction_year,
      number_of_rooms: scrappedListing.number_of_rooms,
      number_of_bedrooms: scrappedListing.number_of_bedrooms,
      number_of_bathrooms: scrappedListing.number_of_bathrooms,
      number_of_toilets: scrappedListing.number_of_toilets,
      number_of_indoor_parkings: scrappedListing.number_of_indoor_parkings,
      number_of_outdoor_parkings: scrappedListing.number_of_outdoor_parkings,
      has_view: scrappedListing.has_view,
      has_cellar: scrappedListing.has_cellar,
      has_elevator: scrappedListing.has_elevator,
      has_fireplace: scrappedListing.has_fireplace,
      has_storage_room: scrappedListing.has_storage_room,
      has_swimming_pool: scrappedListing.has_swimming_pool,
      is_child_friendly: scrappedListing.is_child_friendly,
      is_wheelchair_accessible: scrappedListing.is_wheelchair_accessible,
    };
  }, [getPropertyLabel, scrappedListing]);

  if (scrapperListingId == null) {
    handleClose();
  }

  return (
    <Drawer
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: ['100vw', '100vw', '100vw', `calc(100vw - 550px)`, '50vw'],
        },
      }}
      sx={{
        zIndex: 2000,
      }}
      title={
        <Box>
          <Typography variant="h6">{t('listingDetails')}</Typography>
        </Box>
      }
    >
      {error != null ? (
        <Alert severity="error" sx={{ m: 2 }}>
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </Alert>
      ) : loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <CircularProgress />
        </Box>
      ) : scrappedListing == null ? (
        <Alert severity="warning" sx={{ m: 2 }}>
          {t('Listing not found')}
        </Alert>
      ) : (
        scrappedListing && (
          <Paper elevation={0}>
            <ImagesCarousel listing={scrappedListing} />
            <Box p={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h5" fontWeight="bold" gutterBottom>
                    {scrappedListing.title ||
                      getPropertyLabel(scrappedListing.property_type)}
                  </Typography>

                  <Stack mb={1} direction="row" spacing={1} alignItems="center">
                    <PlaceOutlined />
                    <Typography variant="body1">
                      {scrappedListing.address}
                    </Typography>
                  </Stack>

                  <Box>
                    <Typography variant="h6" fontWeight="bold">
                      {formatPrice(
                        scrappedListing.sale_price ?? 0,
                        locale,
                        currencyToFormat,
                      )}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {formatPrice(
                        scrappedListing.computed_price_per_sqm ?? 0,
                        locale,
                        currencyToFormat,
                      )}{' '}
                      / m²
                    </Typography>
                    <Box
                      display="flex"
                      gap={1}
                      mt={1}
                      flexDirection="column"
                      alignItems="flex-start"
                    >
                      <ListingStatusChip
                        listing={scrappedListing}
                        variant="status"
                      />
                      <ListingStatusChip
                        listing={scrappedListing}
                        variant="duration"
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ fontWeight: 700, mb: 1.5 }}>
                    {t('description')}
                  </Typography>
                  {scrappedListing.description &&
                  scrappedListing.description.length > 0 ? (
                    <CollapsibleContent nbLinesToShow={6}>
                      <Box
                        sx={{
                          typography: 'body2',
                          '& p, ol, ul': {
                            marginTop: 0,
                            marginBottom: '1em',
                          },
                        }}
                      >
                        <MarkdownParser
                          markdown={scrappedListing.description
                            .replace(/(<br>\s*)*$/g, '')
                            .trim()}
                        />
                      </Box>
                    </CollapsibleContent>
                  ) : (
                    t('noDescription')
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Stack flexDirection="column" spacing={2}>
                    <PropertyDetails property={scrappedProperty} />
                    <PropertyFeatures property={scrappedProperty} />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    {(!!scrappedListing.agency_name ||
                      !!scrappedListing.agency_contact_address ||
                      !!scrappedListing.agency_contact_person) && (
                      <Grid item xs={6} md={5}>
                        <Stack gap={2}>
                          {(!!scrappedListing.agency_name ||
                            !!scrappedListing.agency_contact_address) && (
                            <Box>
                              <Typography
                                variant="h6"
                                fontWeight="bold"
                                gutterBottom
                              >
                                {t('Agency')}
                              </Typography>
                              {scrappedListing.agency_name != null && (
                                <Typography variant="body1">
                                  {scrappedListing.agency_name}
                                </Typography>
                              )}
                              {scrappedListing.agency_contact_address !=
                                null && (
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  {scrappedListing.agency_contact_address}
                                </Typography>
                              )}
                            </Box>
                          )}
                          {scrappedListing.agency_contact_person != null && (
                            <Box>
                              <Typography
                                variant="h6"
                                fontWeight="bold"
                                gutterBottom
                              >
                                {t('Agency contact')}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                                mt={1}
                              >
                                {scrappedListing.agency_contact_person}
                              </Typography>
                            </Box>
                          )}
                        </Stack>
                      </Grid>
                    )}

                    <Grid item xs={6} md={4}>
                      <Typography variant="h6" fontWeight="bold" gutterBottom>
                        {t('Found on')}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        mt={1}
                        fontWeight="medium"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          '&:hover': {
                            textDecoration:
                              scrappedListing.offline_since == null
                                ? 'underline'
                                : 'none',
                          },
                        }}
                      >
                        {scrappedListing.offline_since == null ? (
                          <Link
                            to={scrappedListing.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: theme.palette.primary.main,
                              display: 'flex',
                              alignItems: 'center',
                              textDecoration: 'none',
                            }}
                          >
                            {truncateUrl(scrappedListing.url)}
                            <OpenInNew
                              sx={{
                                fontSize: 'inherit',
                                marginLeft: 0.5,
                              }}
                            />
                          </Link>
                        ) : (
                          <Box
                            display="flex"
                            alignItems="center"
                            sx={{ color: theme.palette.text.disabled }}
                          >
                            {truncateUrl(scrappedListing.url)}
                            <OpenInNew
                              sx={{
                                fontSize: 'inherit',
                                marginLeft: 0.5,
                                color: 'inherit',
                              }}
                            />
                          </Box>
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        )
      )}
    </Drawer>
  );
};

export default ListingComparableDetails;
