/**
 * @generated SignedSource<<85f966edafb85ab08c0a3a7448f61adf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ActivityTypeEnum = "assignment" | "call" | "change" | "create_user" | "email" | "match" | "note" | "offer" | "origination" | "requalification" | "task" | "visit";
export type EmailVisibilityTypeEnum = "organisation" | "private" | "shared";
export type OfferStatus = "accepted" | "pending" | "refused";
export type ParticipantStatus = "maybe" | "no" | "noreply" | "yes";
export type UpsertActivityInput = {
  activity: ActivityInput;
  clientMutationId?: string | null;
  notifyParticipants?: boolean | null;
};
export type ActivityInput = {
  activityType?: ActivityTypeEnum | null;
  assignedTo?: string | null;
  createdBy?: string | null;
  done?: boolean | null;
  doneAt?: string | null;
  dueAt?: string | null;
  endAt?: string | null;
  endDate?: string | null;
  id?: string | null;
  location?: string | null;
  note?: string | null;
  offerStatus?: OfferStatus | null;
  parentId?: string | null;
  participants?: ReadonlyArray<ActivityParticipantInput | null> | null;
  pinned?: boolean | null;
  startDate?: string | null;
  subject?: string | null;
  success?: boolean | null;
  visibility?: EmailVisibilityTypeEnum | null;
};
export type ActivityParticipantInput = {
  email?: string | null;
  id?: string | null;
  name?: string | null;
  status?: ParticipantStatus | null;
};
export type leadControlsCreateActivityMutation$variables = {
  input: UpsertActivityInput;
};
export type leadControlsCreateActivityMutation$data = {
  readonly upsertActivity: {
    readonly activity: {
      readonly id: string;
    } | null;
  } | null;
};
export type leadControlsCreateActivityMutation = {
  response: leadControlsCreateActivityMutation$data;
  variables: leadControlsCreateActivityMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "leadControlsCreateActivityMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertActivityPayload",
        "kind": "LinkedField",
        "name": "upsertActivity",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "activity",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "leadControlsCreateActivityMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertActivityPayload",
        "kind": "LinkedField",
        "name": "upsertActivity",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "activity",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8931e59d03829c066f22f2a05c454a33",
    "id": null,
    "metadata": {},
    "name": "leadControlsCreateActivityMutation",
    "operationKind": "mutation",
    "text": "mutation leadControlsCreateActivityMutation(\n  $input: UpsertActivityInput!\n) {\n  upsertActivity(input: $input) {\n    activity {\n      __typename\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "75861f8c846198a1b19b7cc55c4bf5db";

export default node;
