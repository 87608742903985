import { useCallback, useState } from 'react';

import { useFragment as useApolloFragment, useQuery } from '@apollo/client';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';

import { useDebouncedHandler } from '../../../../src/hooks/debounce';
import { useLocale } from '../../../../src/hooks/locale';
import type { GetPropertyAppraisableQuery } from '../../../__generated__/graphql';
import AlertPropertyAppraisable from '../../../components/AlertPropertyAppraisable';
import { Drawer } from '../../../components/drawer/Drawer';
import {
  type FormDefinitionType,
  RaForm,
  type RaFormOnChange,
} from '../../../components/form/RaForm';
import { PropertyForm } from '../../../components/property-form/PropertyForm';
import {
  GET_PROPERTY_APPRAISABLE,
  STEP_HEDONISTIC_VALUATION_FRAGMENT,
} from '../cmaReportsQueries';
import {
  type CMAReportComponentProps,
  FooterActions,
  useUpdateCmaReport,
} from '../shared';

import AppraisalSection from './AppraisalSection';

export type AppraisalResult = NonNullable<
  GetPropertyAppraisableQuery['property_appraisable']
>['appraisable'];

type StepHedonisticValuationForm = {
  include_hedonistic_valuation: boolean;
};

const StepHedonisticValuation = (props: CMAReportComponentProps) => {
  const { t } = useLocale();
  const [open, setOpen] = useState(false);
  const { cmaReportId } = props;

  const { data, complete } = useApolloFragment({
    fragment: STEP_HEDONISTIC_VALUATION_FRAGMENT,
    fragmentName: 'StepHedonisticValuation',
    from: {
      __typename: 'cma_reports',
      id: cmaReportId,
    },
  });

  const latestAppraisal = data?.lead?.property?.latest_appraisal;

  const { data: propertyAppraisable, loading: appraisalLoading } = useQuery(
    GET_PROPERTY_APPRAISABLE,
    {
      variables: {
        propertyId: data?.lead?.property?.id ?? '',
      },
      skip: data?.lead?.property?.id == null,
    },
  );
  const appraisable = propertyAppraisable?.property_appraisable?.appraisable;

  const [updateCmaReport, updating] = useUpdateCmaReport(
    cmaReportId,
    'page-hedonistic-valuation',
  );

  const update = useCallback(
    async (formData?: Partial<StepHedonisticValuationForm>) => {
      await updateCmaReport({
        include_hedonistic_valuation:
          formData?.include_hedonistic_valuation ?? false,
      });
    },
    [updateCmaReport],
  );

  const debouncedUpdate = useDebouncedHandler(300, update);

  const onChangeHandler: RaFormOnChange<
    StepHedonisticValuationForm
  > = formData => {
    debouncedUpdate(formData);
  };

  const onSubmit = useCallback(
    (formData: StepHedonisticValuationForm) => update(formData),
    [update],
  );

  const handleAppraiseProperty = useCallback(async () => {
    updateCmaReport({});
  }, [updateCmaReport]);

  const cmaStepHedonisticValuationFormDefinition: FormDefinitionType<StepHedonisticValuationForm> =
    useCallback(
      ({ t }) => [
        {
          name: 'include_hedonistic_valuation',
          type: 'checkbox',
          label: t('Include in report'),
          style: 'switch',
          gridProps: { md: 12 },
        },
        {
          name: 'property-details',
          type: 'custom',
          gridProps: { md: 12 },
          element: (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
                gap: 1,
              }}
            >
              <Typography variant="subtitle2" fontWeight={700}>
                {t('property')}
              </Typography>
              <Button
                endIcon={<ChevronRight />}
                size="small"
                onClick={() => setOpen(true)}
                css={{ display: 'inline-flex' }}
              >
                {t('editPropertyDetails')}
              </Button>
            </Box>
          ),
        },
        {
          name: 'latest-appraisal',
          type: 'custom',
          gridProps: { md: 12 },
          element: (
            <AppraisalSection
              propertyId={data?.lead?.property?.id}
              latestAppraisalId={latestAppraisal?.id}
              appraisalsLength={data?.lead?.property?.appraisals?.length ?? 0}
              appraisalLoading={appraisalLoading}
              appraisable={appraisable}
              onAppraise={handleAppraiseProperty}
            />
          ),
        },
        {
          name: 'appraisal-errors',
          type: 'custom',
          gridProps: { md: 12 },
          element: (
            <>
              {appraisable?.result === false && (
                <AlertPropertyAppraisable errors={appraisable.errors} />
              )}
            </>
          ),
        },
      ],
      [
        data?.lead?.property?.id,
        data?.lead?.property?.appraisals?.length,
        latestAppraisal?.id,
        appraisalLoading,
        handleAppraiseProperty,
        appraisable,
      ],
    );

  return (
    <>
      <Drawer
        title={t('editProperty')}
        open={open}
        PaperProps={{ sx: { maxWidth: 1200 } }}
        onClose={() => setOpen(false)}
      >
        <PropertyForm
          propertyId={data?.lead?.property?.id}
          onCancel={() => setOpen(false)}
          onSave={() => {
            setOpen(false);
            updateCmaReport({});
          }}
        />
      </Drawer>
      <Box
        sx={{
          flexGrow: 1,
          position: 'relative',
          overflowY: 'auto',
          p: !complete ? 2 : undefined,
        }}
      >
        {!complete ? (
          <Stack gap={3} p={2}>
            <Stack direction={'row'}>
              <Skeleton
                variant="circular"
                width={24}
                height={24}
                sx={{ mr: 2 }}
              />
              <Skeleton variant="rounded" width={'40%'} height={24} />
            </Stack>
            <Stack gap={1}>
              <Skeleton variant="rectangular" height={30} width={'40%'} />
              <Skeleton variant="rectangular" height={40} width={'60%'} />
            </Stack>
            <Stack gap={1}>
              <Stack>
                <Skeleton variant="rounded" height={30} width={'40%'} />
                <Skeleton variant="text" width={'30%'} />
              </Stack>
              <Skeleton sx={{ pl: 2 }} variant="rounded" height={60} />
            </Stack>
          </Stack>
        ) : (
          <RaForm
            freezeInitialDefaultValues={true}
            formDefinition={cmaStepHedonisticValuationFormDefinition}
            defaultValues={{
              include_hedonistic_valuation:
                data?.include_hedonistic_valuation ?? false,
            }}
            actionButtonsComponent={
              <FooterActions<StepHedonisticValuationForm>
                {...props}
                updating={updating}
                onSubmit={onSubmit}
              />
            }
            contentScrollable
            onChange={onChangeHandler}
            onSubmit={() => Promise.resolve()}
          />
        )}
      </Box>
    </>
  );
};

export default StepHedonisticValuation;
