import * as React from 'react';

import {
  ButtonBase,
  Card,
  CardHeader,
  Divider,
  IconButton,
  ListItemIcon,
  MenuItem,
  Popover,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { format, isValid } from 'date-fns';
import { graphql, useFragment, useMutation } from 'react-relay';
import { Link } from 'react-router-dom';
import { Box, Flex, useResponsive, useSystem } from 'react-system';

import { fromGlobalId } from '../../../shared/global-id';
import {
  EmailVisibilityButton,
  VisibilityButton,
} from '../../components/VisibilityButton';
import { Menu } from '../../controls/popup';
import { type Translate, useLocale } from '../../hooks/locale';
import { type Theme, useTheme } from '../../hooks/theme';
import { AddCircleOutline } from '../../icons/add-circle-outline';
import { Delete } from '../../icons/delete';
import { DoorOutline } from '../../icons/door-outline';
import { Edit } from '../../icons/edit';
import { EmailEnvelopeOutline } from '../../icons/email-envelope-outline';
import { GiftOutline } from '../../icons/gift-outline';
import { MoreHoriz } from '../../icons/more-horiz';
import { NoteOutline } from '../../icons/note-outline';
import { PhoneCheckOutline } from '../../icons/phone-check-outline';
import { PhoneMissCallOutline } from '../../icons/phone-miss-call-outline';
import { PhonePendingOutline } from '../../icons/phone-pending-outline';
import { PinOutline } from '../../icons/pin-outline';
import { TagOutline } from '../../icons/tag-outline';
import { TasksOutline } from '../../icons/tasks-outline';
import { LeadSnippet } from '../../shared/lead-snippet';
import { LotSnippet } from '../../shared/lot-snippet';
import { TimeAgo } from '../TimeAgo';

import type {
  ActivityTypeEnum,
  FeedItem_activity$data,
  FeedItem_activity$key,
} from './__generated__/FeedItem_activity.graphql';
import type {
  FeedItem_root$data,
  FeedItem_root$key,
} from './__generated__/FeedItem_root.graphql';
import type { FeedItemUpsertActivityMutation } from './__generated__/FeedItemUpsertActivityMutation.graphql';
import type { FeedItemVisibilityMutation } from './__generated__/FeedItemVisibilityMutation.graphql';
import { ActivityForm } from './ActivityForm';
import type { ActiveDialogType } from './Feed';
import { FeedEnquiry } from './feed-enquiry';
import { FeedOffer } from './feed-offer';
import { FeedAssignment } from './FeedAssignment';
import { FeedCall } from './FeedCall';
import { FeedDeleteDialog } from './FeedDeleteDialog';
import { FeedEmail } from './FeedEmail';
import { FeedNote } from './FeedNote';
import { FeedRequalification } from './FeedRequalification';
import { FeedTask } from './FeedTask';
import { FeedVisit } from './FeedVisit';
import { NewMatch } from './NewMatch';
import { ParentLink } from './parent-link';
import { UserName } from './UserName';

const EditableTypes: Array<ActivityTypeEnum> = [
  'call',
  'visit',
  'task',
  'note',
];

type Props = {
  root: FeedItem_root$key;
  activity: FeedItem_activity$key;
  indirect: boolean;
  hideOnDeleting?: boolean;
  showIcon?: boolean;
  showPinned?: boolean;
  inDrawer?: boolean;
  onCreate: (evtArgs?: { trigger: string }) => void;
  onDelete: () => void;
  onEdit?: () => void;
  openDialog: boolean;
  setOpenDialog: (value: boolean) => void;
  disableDialogCollapse?: boolean;
  activeDialogType: ActiveDialogType;
  setActiveDialogType: (value: ActiveDialogType) => void;
  showUnfinishedWarning: boolean;
  setShowUnfinishedWarning: (value: boolean) => void;
};

const getCreatedBy = (
  enquiry: NonNullable<FeedItem_activity$data['enquiry']>,
) => {
  if (enquiry.user) {
    return <UserName user={enquiry.user} />;
  }
  return [enquiry.firstName, ' ', enquiry.lastName];
};

const LotLink = ({
  lot,
}: {
  lot: NonNullable<NonNullable<FeedItem_activity$data['enquiry']>['lot']>;
}) => {
  const property = lot.property;
  const lotId = lot.id;
  const lotTitle = lot.title;

  return (
    <Link to={`/lots/${lotId}`}>
      {lotTitle != null && lotTitle.trim() !== ''
        ? lotTitle
        : [
            property.route,
            ' ',
            property.streetNumber,
            ' ',
            property.postcode,
            ' ',
            property.locality,
            ', ',
            property.state,
            ' ',
            property.countryCode,
          ]}
    </Link>
  );
};

const separateArrayItemsWith = <T extends React.ReactNode>(
  array: T[],
  separator: string,
): T[] => {
  const result = [];
  for (let i = 0; i < array.length; i += 1) {
    const item = array[i];
    if (i === array.length - 1) {
      result.push(item);
    } else {
      result.push(item, separator as React.ReactNode);
    }
  }
  return result as T[];
};

type EmailMessageType = NonNullable<FeedItem_activity$data['emailMessage']>;

const RenderEmailTitle = ({
  node,
  createdBy,
}: {
  node: EmailMessageType;
  createdBy: React.ReactNode;
}) => {
  const { t } = useLocale();
  const { text } = useTheme();
  const [showPopover, setShowPopover] = React.useState(false);
  const targetRef = React.useRef<HTMLButtonElement | null>(null);

  const from = node.from;
  const fromUser = node.from[0]?.user;
  const fromName = fromUser ? (
    <UserName user={fromUser} />
  ) : (
    <span>{from[0]?.name || from[0]?.email}</span>
  );

  const getNames = (
    users:
      | EmailMessageType['to']
      | EmailMessageType['cc']
      | EmailMessageType['bcc']
      | EmailMessageType['replyTo'],
  ) =>
    users
      .filter(Boolean)
      .sort((a, b) => {
        if (a?.user != null && b?.user != null) {
          return -1;
        } else if (a?.user == null && b?.user != null) {
          return 1;
        } else {
          return 0;
        }
      })
      .map(r =>
        r?.user != null ? (
          <UserName key={r?.user.id} user={r?.user} />
        ) : (
          <span key={`${r?.name ?? ''}}-${r?.email}`}>
            {r?.name} ({r?.email})
          </span>
        ),
      );

  const recipients = [...getNames(node['to']), ...getNames(node['cc'])];

  const renderContacts = (
    label: string,
    values:
      | EmailMessageType['to']
      | EmailMessageType['cc']
      | EmailMessageType['bcc']
      | EmailMessageType['replyTo'],
  ) => {
    if (values == null || values.length === 0) {
      return null;
    }
    const names = getNames(values).map((name, index) => (
      <Box key={index}>{name}</Box>
    ));
    return (
      <Flex>
        <Box
          pr={2}
          width={'50px'}
          flexShrink={0}
          css={{
            textAlign: 'right',
            textTransform: 'capitalize',
          }}
        >
          {label}:
        </Box>
        <Box flexGrow={1}>{names}</Box>
      </Flex>
    );
  };

  return (
    <>
      <Box>
        {from.length !== 0 ? fromName : createdBy} {t('sentAnEmailTo')}{' '}
        {separateArrayItemsWith(recipients.slice(0, 2), ', ')}
        {recipients.length > 2 && (
          <ButtonBase
            onClick={() => setShowPopover(true)}
            ref={targetRef}
            component={'span'}
          >
            <Box css={text.caption} ml={1}>
              +{t('othersCount', { count: recipients.length - 2 })}
            </Box>
          </ButtonBase>
        )}
      </Box>
      <Popover
        anchorEl={() => targetRef.current as HTMLButtonElement}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transitionDuration={250}
        open={showPopover}
        onClose={() => setShowPopover(false)}
      >
        <Box m={3} width={['300px', '400px']}>
          <Flex>
            <Box
              pr={2}
              width={'50px'}
              flexShrink={0}
              css={{ textAlign: 'right' }}
            >
              {t('date')}:
            </Box>
            <Box>
              {isValid(new Date(node.date))
                ? format(new Date(node.date), 'dd LLLL yyyy HH:mm')
                : ''}
            </Box>
          </Flex>
          {renderContacts(t('to'), node.to)}
          {renderContacts(t('From'), node.from)}
          {renderContacts(t('Cc'), node.cc)}
          {renderContacts(t('Bcc'), node.bcc)}
          {renderContacts(t('replyTo'), node.replyTo)}
        </Box>
      </Popover>
    </>
  );
};

const renderCreatedBy = (activity: FeedItem_activity$data) => {
  // First check if custom owner specified
  if (activity.owner?.user != null) {
    return <UserName user={activity.owner.user} />;
  } else if (activity.createdBy) {
    return <UserName user={activity.createdBy} />;
  } else if (activity.owner != null) {
    return (
      <Tooltip title={activity.owner.email ?? ''} placement="top">
        <span>
          {activity.owner.name !== '' && activity.owner.name != null
            ? activity.owner.name
            : activity.owner.email}
        </span>
      </Tooltip>
    );
  } else {
    return null;
  }
};

const renderTargetUsers = (activity: FeedItem_activity$data) => {
  return separateArrayItemsWith(
    (activity.displayParticipants ?? []).filter(Boolean).map(participant => {
      if (participant.user != null) {
        return <UserName key={participant.user.id} user={participant.user} />;
      }

      const name = participant.name ?? participant.email ?? '';

      return (
        <Tooltip key={name} title={participant.email ?? ''} placement="top">
          <span>{name}</span>
        </Tooltip>
      );
    }) as React.ReactNode[],
    ', ',
  );
};

type IconProps = {
  children: JSX.Element;
  color: string;
};

const IconCircle = (props: IconProps) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      css={{
        position: 'relative',
        backgroundColor: props.color,
        width: 40,
        height: 40,
        borderRadius: '100%',
      }}
    >
      {props.children}
    </Flex>
  );
};

const renderIcon = ({
  activity,
  colors,
}: {
  activity: FeedItem_activity$data;
  colors: Theme['colors'];
}) => {
  switch (activity.__typename) {
    case 'ActivityAssignment':
      return (
        <>
          {activity.done === true && activity.success === true && (
            <IconCircle color={colors.success}>
              <GiftOutline fill={colors.white} />
            </IconCircle>
          )}
          {activity.done === true && activity.success === false && (
            <IconCircle color={colors.error}>
              <GiftOutline fill={colors.white} />
            </IconCircle>
          )}
          {activity.done === false && (
            <IconCircle color={colors.warning}>
              <GiftOutline fill={colors.white} />
            </IconCircle>
          )}
        </>
      );

    case 'ActivityTask':
      return (
        <>
          {activity.done === true && activity.success === true && (
            <IconCircle color={colors.success}>
              <TasksOutline fill={colors.white} />
            </IconCircle>
          )}
          {activity.done === true && activity.success === false && (
            <IconCircle color={colors.error}>
              <TasksOutline fill={colors.white} />
            </IconCircle>
          )}
          {activity.done === false && (
            <IconCircle color={colors.primaryMain}>
              <TasksOutline fill={colors.white} />
            </IconCircle>
          )}
        </>
      );

    case 'ActivityCall':
      return (
        <>
          {activity.done === true && activity.success === true && (
            <IconCircle color={colors.success}>
              <PhoneCheckOutline fill={colors.white} />
            </IconCircle>
          )}
          {activity.done === true && activity.success === false && (
            <IconCircle color={colors.error}>
              <PhoneMissCallOutline fill={colors.white} />
            </IconCircle>
          )}
          {activity.done === false && (
            <IconCircle color={colors.primaryMain}>
              <PhonePendingOutline fill={colors.white} />
            </IconCircle>
          )}
        </>
      );

    case 'ActivityNote':
      return (
        <IconCircle color={colors.success}>
          <NoteOutline fill={colors.white} />
        </IconCircle>
      );

    case 'ActivityOrigination':
      return (
        <IconCircle color={colors.warning}>
          <AddCircleOutline fill={colors.white} />
        </IconCircle>
      );

    case 'ActivityRequalification':
      return (
        <IconCircle color={colors.warning}>
          <Typography css={{ color: colors.white }} variant="h5">
            ♺
          </Typography>
        </IconCircle>
      );

    case 'ActivityVisit':
      return (
        <>
          {activity.done === true && activity.success === true && (
            <IconCircle color={colors.success}>
              <DoorOutline fill={colors.white} />
            </IconCircle>
          )}
          {activity.done === true && activity.success === false && (
            <IconCircle color={colors.error}>
              <DoorOutline fill={colors.white} />
            </IconCircle>
          )}
          {activity.done === false && (
            <IconCircle color={colors.primaryMain}>
              <DoorOutline fill={colors.white} />
            </IconCircle>
          )}
        </>
      );

    case 'ActivityEmail':
      return (
        <IconCircle color={colors.success}>
          <EmailEnvelopeOutline fill={colors.white} />
        </IconCircle>
      );

    case 'ActivityCreateUser':
      return (
        <IconCircle color={colors.warning}>
          <AddCircleOutline fill={colors.white} />
        </IconCircle>
      );

    case 'ActivityMatch':
      return (
        <IconCircle color={colors.success}>
          <AddCircleOutline fill={colors.white} />
        </IconCircle>
      );

    case 'ActivityOffer':
      return (
        <>
          {activity.offerStatus === 'accepted' && (
            <IconCircle color={colors.success}>
              <TagOutline fill={colors.white} />
            </IconCircle>
          )}
          {activity.offerStatus === 'refused' && (
            <IconCircle color={colors.error}>
              <TagOutline fill={colors.white} />
            </IconCircle>
          )}
          {activity.offerStatus === 'pending' && (
            <IconCircle color={colors.primaryMain}>
              <TagOutline fill={colors.white} />
            </IconCircle>
          )}
        </>
      );

    default:
      return null;
  }
};

const renderTitle = ({
  t,
  activity,
  text,
}: {
  t: Translate;
  activity: FeedItem_activity$data;
  text: Theme['text'];
}) => {
  const createdBy = renderCreatedBy(activity);
  const assignedTo = activity.assignedTo && (
    <UserName user={activity.assignedTo} />
  );
  const targetUsers = renderTargetUsers(activity);

  const isOrganizerIsANotCreater =
    activity.assignedTo?.id &&
    activity.createdBy?.id !== activity.assignedTo.id;
  switch (activity.__typename) {
    case 'ActivityAssignment':
      return (
        <>
          {createdBy} {t('assignedLeadTo')} {assignedTo}
        </>
      );

    case 'ActivityTask':
      return (
        <>
          {activity.done === true && assignedTo ? assignedTo : createdBy}
          {activity.done === true &&
            activity.success === true &&
            ` ${t('completedATask')}`}
          {activity.done === true &&
            activity.success === false &&
            ` ${t('canceledATask')}`}
          {activity.done === true &&
            isOrganizerIsANotCreater === true &&
            ` ${t('assignedTaskBy')} `}
          {activity.done === false &&
            isOrganizerIsANotCreater === false &&
            ` ${t('createdATask')}`}
          {activity.done === false &&
            isOrganizerIsANotCreater === true &&
            ` ${t('assignedTaskTo')} `}
          {isOrganizerIsANotCreater === true &&
            (activity.done === false ? assignedTo : createdBy)}
        </>
      );

    case 'ActivityCall':
      return (
        <>
          {activity.done === true ? assignedTo : createdBy}
          {activity.done === true &&
            activity.success === true &&
            ` ${t('madeACall')} `}
          {activity.done === true &&
            activity.success === false &&
            ` ${t('triedToCall')} `}
          {activity.done === false
            ? isOrganizerIsANotCreater === true
              ? ` ${t('setUpACallFor')} `
              : ` ${t('setUpACallWith')} `
            : ''}
          {activity.done === false &&
            isOrganizerIsANotCreater === true &&
            assignedTo}
          {activity.done === false &&
            isOrganizerIsANotCreater === true &&
            ` ${t('with')} `}
          {targetUsers}
        </>
      );

    case 'ActivityNote':
      return (
        <>
          {createdBy}
          {` ${targetUsers.length ? t('wroteANoteOn') : t('wroteANote')} `}
          {targetUsers}
        </>
      );

    case 'ActivityOrigination': {
      let createdANew = t('createdANew');
      let portalName = '';
      if (activity.enquiry) {
        const enquiry = activity.enquiry;
        portalName = enquiry.portal?.name ?? '';
        createdANew = t('createdANew_enquiry');
        const lot = enquiry.lot;
        return (
          <>
            {getCreatedBy(enquiry)} {createdANew}{' '}
            <ParentLink
              enquiry={enquiry}
              lot={null}
              lead={null}
              user={null}
              buyerLead={null}
            />{' '}
            {t('From')} {portalName} {t('for')} {lot && <LotLink lot={lot} />}
          </>
        );
      }
      if (activity.lot) {
        createdANew = t('createdANew_lot');
      }
      if (activity.lead) {
        // Source organisation means it comes from their broker site
        const sourceOrganisation = activity.lead.sourceOrganisation;
        createdANew = t('createdANew_lead');
        return (
          <>
            {createdBy} {createdANew}{' '}
            <ParentLink
              enquiry={null}
              lot={null}
              lead={activity.lead}
              user={null}
              buyerLead={null}
            />
            {sourceOrganisation?.name != null && (
              <> {t('fromBrokerSite', { name: sourceOrganisation.name })}</>
            )}
          </>
        );
      }
      if (activity.lead) {
        createdANew = t('createdANew_lead');
      }
      if (activity.buyerLead) {
        createdANew = t('createdANew_buyerlead');
      }
      if (activity.user) {
        createdANew = t('createdANew_user');
      }
      return (
        <>
          {createdBy} {createdANew}{' '}
          <ParentLink
            enquiry={activity.enquiry}
            lot={activity.lot}
            lead={activity.lead}
            user={activity.user}
            buyerLead={activity.buyerLead}
          />
        </>
      );
    }

    case 'ActivityRequalification': {
      return (
        <>
          {createdBy} {t('requalifiedThe')}{' '}
          <ParentLink
            enquiry={activity.enquiry}
            lot={activity.lot}
            lead={activity.lead}
            user={activity.user}
            buyerLead={activity.buyerLead}
          />
        </>
      );
    }

    case 'ActivityVisit':
      return (
        <>
          {activity.done === true ? assignedTo : createdBy}
          {activity.done === true &&
            activity.success === true &&
            ` ${
              targetUsers.length > 0
                ? t('completedAVisitWith')
                : t('completedAVisit')
            } `}
          {activity.done === true &&
            activity.success === false &&
            ` ${
              targetUsers.length > 0
                ? t('cancelledAVisitWith')
                : t('cancelledAVisit')
            } `}
          {activity.done === false
            ? isOrganizerIsANotCreater === true
              ? ` ${t('organisedAVisitFor')} `
              : ` ${
                  targetUsers.length > 0
                    ? t('organisedAVisitWith')
                    : t('organisedAVisit')
                } `
            : ''}
          {activity.done === false &&
            isOrganizerIsANotCreater === true &&
            assignedTo}
          {activity.done === false &&
            isOrganizerIsANotCreater === true &&
            targetUsers.length > 0 &&
            ` ${t('with')} `}
          {targetUsers}
          {activity.done === true &&
            isOrganizerIsANotCreater === true &&
            createdBy != null && (
              <>
                {` ${t('organisedBy')} `}
                {createdBy}
              </>
            )}
        </>
      );

    case 'ActivityEmail': {
      if (activity.emailMessage == null) {
        return null;
      }

      return (
        <RenderEmailTitle node={activity.emailMessage} createdBy={createdBy} />
      );
    }

    case 'ActivityCreateUser':
      return (
        <>
          {createdBy}
          {` ${t('createdANew_user')} `}
          <ParentLink
            enquiry={activity.enquiry}
            lot={activity.lot}
            lead={activity.lead}
            user={activity.user}
            buyerLead={activity.buyerLead}
          />
        </>
      );

    case 'ActivityMatch': {
      const users = (activity.displayParticipants ?? []).filter(Boolean);

      return (
        <>
          <ParentLink
            enquiry={activity.enquiry}
            lot={activity.lot}
            lead={activity.lead}
            user={activity.user}
            buyerLead={activity.buyerLead}
          >
            {activity.lot?.title}
          </ParentLink>{' '}
          {t('hasBeenMatchedWith')}{' '}
          {users.length === 1 && <UserName user={users[0].user} />}
          {users.length === 2 && (
            <>
              <UserName user={users[0].user} /> {t('and')}{' '}
              <UserName user={users[1].user} />
            </>
          )}
          {users.length > 2 && (
            <>
              <UserName user={users[0].user} />,{' '}
              <UserName user={users[1].user} /> {t('and')}{' '}
              <span css={{ fontWeight: text.font.medium }}>
                {t('othersCount', { count: users.length - 2 })}
              </span>
            </>
          )}
        </>
      );
    }

    case 'ActivityOffer': {
      return (
        <>
          {createdBy}
          {activity.offerStatus !== 'pending'
            ? ` ${t('markedOfferFrom')} `
            : ` ${t('addedOfferFrom')} `}
          {activity.offer?.buyer != null ? (
            <UserName
              key={activity.offer.buyer.id}
              user={activity.offer.buyer}
            />
          ) : null}
          {activity.offerStatus === 'accepted'
            ? ` ${t('asAccepted')}`
            : activity.offerStatus === 'refused'
            ? ` ${t('asRefused')}${
                activity.offerRefused != null
                  ? ` - ${activity.offerRefused.label}`
                  : ''
              }`
            : null}
        </>
      );
    }

    default:
      return null;
  }
};

const renderContent = ({
  root,
  activity,
  indirect,
  inDrawer,
  onCreate,
  openDialog,
  setOpenDialog,
  activeDialogType,
  setActiveDialogType,
  showUnfinishedWarning,
  setShowUnfinishedWarning,
}: {
  root: FeedItem_root$data;
  activity: FeedItem_activity$data;
  indirect: boolean;
  inDrawer: null | boolean;
  onCreate: Props['onCreate'];
  openDialog: boolean;
  setOpenDialog: (value: boolean) => void;
  activeDialogType: ActiveDialogType;
  setActiveDialogType: (value: ActiveDialogType) => void;
  showUnfinishedWarning: boolean;
  setShowUnfinishedWarning: (value: boolean) => void;
}) => {
  switch (activity.__typename) {
    case 'ActivityNote':
      return <FeedNote activity={activity} />;
    case 'ActivityTask':
      return (
        <FeedTask
          root={root}
          activity={activity}
          indirect={indirect}
          onCreate={onCreate}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          activeDialogType={activeDialogType}
          setActiveDialogType={setActiveDialogType}
          showUnfinishedWarning={showUnfinishedWarning}
          setShowUnfinishedWarning={setShowUnfinishedWarning}
        />
      );
    case 'ActivityAssignment':
      if (activity.lead) {
        return (
          <FeedAssignment
            activity={activity}
            indirect={indirect}
            onCreate={onCreate}
          />
        );
      }
      return null;
    case 'ActivityEmail':
      return <FeedEmail activity={activity} onCreate={onCreate} me={root.me} />;
    case 'ActivityCall':
      return (
        <FeedCall
          root={root}
          activity={activity}
          indirect={indirect}
          onCreate={onCreate}
          openDialog={openDialog}
          inDrawer={inDrawer ?? false}
          setOpenDialog={setOpenDialog}
          activeDialogType={activeDialogType}
          setActiveDialogType={setActiveDialogType}
          showUnfinishedWarning={showUnfinishedWarning}
          setShowUnfinishedWarning={setShowUnfinishedWarning}
        />
      );
    case 'ActivityVisit':
      return (
        <FeedVisit
          activity={activity}
          indirect={indirect}
          onCreate={onCreate}
          root={root}
          calendarSyncEnabled={
            root.me?.nylasAccount?.calendarSyncEnabled === true
          }
          nylasEmail={root.me?.nylasAccount?.email ?? null}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          activeDialogType={activeDialogType}
          setActiveDialogType={setActiveDialogType}
          showUnfinishedWarning={showUnfinishedWarning}
          setShowUnfinishedWarning={setShowUnfinishedWarning}
        />
      );
    case 'ActivityOrigination': {
      if (activity.enquiry) {
        return <FeedEnquiry parent={activity.enquiry} />;
      }
      return null;
    }
    case 'ActivityRequalification': {
      return <FeedRequalification activity={activity} />;
    }
    case 'ActivityMatch':
      return <NewMatch activity={activity} />;
    case 'ActivityOffer':
      if (activity.offer) {
        return <FeedOffer activity={activity} />;
      }
      return null;
    default:
      return null;
  }
};

export const FeedItem = (props: Props) => {
  const { t } = useLocale();
  const { colors, text, textColor } = useTheme();
  const { media } = useSystem();
  const responsive = useResponsive();
  const [editing, setEditing] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const menuRef = React.useRef<HTMLButtonElement | null>(null);
  const [menu, setMenu] = React.useState(false);

  const root = useFragment(
    graphql`
      fragment FeedItem_root on Query {
        me {
          ...FeedEmail_me
          nylasAccount {
            calendarSyncEnabled
            email
          }
          isAdmin
          userIdsInLedTeams
        }
        ...FeedVisit_root
        ...FeedCall_root
        ...FeedTask_root
        ...ActivityForm_root
      }
    `,
    props.root,
  );

  const activity = useFragment(
    graphql`
      fragment FeedItem_activity on Activity {
        ...FeedDeleteDialog_activity
        ...ActivityForm_activity
        ...NewMatch_activity
        __typename
        id
        createdAt
        activityType
        canEdit
        canEditVisibility
        isOwner
        visibility
        pinned
        createdBy {
          id
          ...UserName_user
        }
        owner {
          name
          email
          user {
            id
            ...UserName_user
          }
        }
        displayParticipants {
          name
          email
          user {
            id
            ...UserName_user
          }
        }
        assignedTo {
          id
          ...UserName_user
        }

        enquiry {
          ...ActivityForm_enquiry
          ...parentLink_enquiry
          ...feedEnquiry_parent
          id
          portal {
            id
            name
          }
          user {
            ...UserName_user
          }
          lot {
            ...lotSnippet_lot
            id
            title
            property {
              route
              streetNumber
              postcode
              locality
              state
              countryCode
            }
          }
          firstName
          lastName
        }

        lot {
          ...ActivityForm_lot
          ...parentLink_lot
          ...lotSnippet_lot
          id
          title
        }

        lead {
          ...ActivityForm_lead
          ...parentLink_lead
          ...leadSnippet_lead
          sourceOrganisation {
            name
          }
          property {
            route
            streetNumber
            postcode
            locality
          }
          createdBy {
            firstName
            lastName
          }
        }

        user {
          ...ActivityForm_user
          ...parentLink_user
          id
          firstName
          lastName
        }

        buyerLead {
          ...ActivityForm_buyerLead
          ...parentLink_buyerLead
          id
          lot {
            ...lotSnippet_lot
            id
          }
          user {
            firstName
            lastName
          }
        }

        offer {
          buyer {
            id
            ...UserName_user
          }
        }

        ... on ActivityRequalification {
          ...FeedRequalification_activity
        }
        ... on ActivityCall {
          ...FeedCall_activity
          done
          success
        }
        ... on ActivityVisit {
          ...FeedVisit_activity
          done
          success
        }
        ... on ActivityNote {
          done
          ...FeedNote_activity
        }
        ... on ActivityTask {
          done
          success
          ...FeedTask_activity
        }
        ... on ActivityCreateUser {
          __typename
        }
        ... on ActivityAssignment {
          done
          success
          ...FeedAssignment_activity
        }
        ... on ActivityEmail {
          done
          emailMessage {
            threadId
            date
            to {
              name
              email
              user {
                id
                ...UserName_user
              }
            }
            from {
              name
              email
              user {
                id
                ...UserName_user
              }
            }
            replyTo {
              name
              email
              user {
                id
                ...UserName_user
              }
            }
            cc {
              name
              email
              user {
                id
                ...UserName_user
              }
            }
            bcc {
              name
              email
              user {
                id
                ...UserName_user
              }
            }
          }
          ...FeedEmail_activity
        }
        ... on ActivityOffer {
          offerStatus
          offerRefused {
            id
            label
          }
          ...feedOffer_activity
        }
      }
    `,
    props.activity,
  );

  const [updateVisibility] = useMutation<FeedItemVisibilityMutation>(
    graphql`
      mutation FeedItemVisibilityMutation(
        $input: UpdateEmailThreadVisibilityInput!
      ) {
        updateEmailThreadVisibility(input: $input) {
          clientMutationId
        }
      }
    `,
  );

  const [upsertActivity] = useMutation<FeedItemUpsertActivityMutation>(
    graphql`
      mutation FeedItemUpsertActivityMutation($input: UpsertActivityInput!) {
        upsertActivity(input: $input) {
          activity {
            ...FeedItem_activity
          }
        }
      }
    `,
  );

  const { indirect } = props;

  if (!activity) {
    return null;
  }

  const isAdmin = root.me?.isAdmin ?? false;
  // ids of user i am leader of (team leader)
  const userIdsInLedTeams = root.me?.userIdsInLedTeams ?? [];

  const canDelete =
    isAdmin ||
    activity.isOwner ||
    (activity.assignedTo?.id != null &&
      userIdsInLedTeams.includes(fromGlobalId(activity.assignedTo.id)) &&
      activity.createdBy?.id != null &&
      userIdsInLedTeams.includes(fromGlobalId(activity.createdBy.id ?? null)));

  const title = renderTitle({ t, activity, text });

  const content = renderContent({
    root,
    activity,
    indirect,
    inDrawer: props.inDrawer ?? null,
    onCreate: props.onCreate,
    openDialog: props.openDialog,
    setOpenDialog: props.setOpenDialog,
    activeDialogType: props.activeDialogType,
    setActiveDialogType: props.setActiveDialogType,
    showUnfinishedWarning: props.showUnfinishedWarning,
    setShowUnfinishedWarning: props.setShowUnfinishedWarning,
  });

  const icon = renderIcon({ activity, colors });

  const action = (
    <>
      {props.showPinned === true && (
        <IconButton
          size="small"
          css={{
            ':disabled': {
              background: colors.grey700,
              color: colors.white,
            },
            marginRight: '5px',
            background: activity.pinned ? colors.blue400 : 'transparent',
            color: activity.pinned ? colors.white : colors.grey700,
            '&:hover': {
              background: activity.pinned ? colors.blue400 : colors.grey200,
            },
            padding: '7px',
          }}
          onClick={() => {
            upsertActivity({
              variables: {
                input: {
                  activity: {
                    id: activity.id,
                    pinned: !activity.pinned,
                  },
                },
              },
              onCompleted: () => {
                props.onEdit?.();
              },
            });
          }}
          title={activity.pinned ? t('pinToTop') : t('unpinActivity')}
        >
          <PinOutline size={22} />
        </IconButton>
      )}

      {activity.__typename === 'ActivityEmail' &&
        activity.emailMessage?.threadId != null && (
          <EmailVisibilityButton
            disabled={!activity.isOwner}
            visibility={activity.visibility}
            variant={'icon'}
            onChange={visibility => {
              updateVisibility({
                variables: {
                  input: {
                    id: activity.emailMessage?.threadId ?? '',
                    visibility,
                  },
                },
                onCompleted: () => {
                  props.onEdit?.();
                },
              });
            }}
          />
        )}

      {activity.canEditVisibility && (
        <VisibilityButton
          title={t('openVisibilityMenu')}
          visibility={activity.visibility}
          onChange={visibility => {
            upsertActivity({
              variables: {
                input: {
                  activity: {
                    id: activity.id,
                    visibility,
                  },
                },
              },
              onCompleted: () => {
                props.onEdit?.();
              },
            });
          }}
        />
      )}
      {(EditableTypes.includes(activity.activityType) || canDelete) && (
        <>
          <IconButton
            ref={menuRef}
            css={{ marginLeft: '5px' }}
            onClick={() => setMenu(v => !v)}
          >
            <MoreHoriz />
          </IconButton>

          <Menu
            referenceRef={menuRef}
            open={menu}
            onClose={() => setMenu(false)}
          >
            {EditableTypes.includes(activity.activityType) && (
              <MenuItem
                onClick={() => {
                  props.setOpenDialog(true);
                  setEditing(true);
                }}
              >
                <ListItemIcon>
                  <Edit />
                </ListItemIcon>
                {t('edit')}
              </MenuItem>
            )}
            {canDelete && (
              <MenuItem onClick={() => setDeleting(true)}>
                <ListItemIcon>
                  <Delete />
                </ListItemIcon>
                {t('delete')}
              </MenuItem>
            )}
          </Menu>
        </>
      )}
    </>
  );

  return (
    <>
      {!(deleting && props.hideOnDeleting) && (
        <div
          css={
            props.showIcon === true
              ? media({
                  display: 'grid',
                  gridGap: [8, 16],
                  gridTemplateColumns: ['1fr', 'auto 1fr'],
                })
              : null
          }
        >
          {props.showIcon === true && responsive([null, icon])}
          <Card css={{ position: 'relative' }}>
            <CardHeader
              css={{
                '.MuiCardHeader-avatar': {
                  alignSelf: 'flex-start',
                  marginRight: 12,
                  marginTop: -2,
                },
                '.MuiCardHeader-action': {
                  marginLeft: 8,
                },
              }}
              disableTypography={true}
              avatar={props.showIcon === true ? responsive([icon, null]) : null}
              title={title}
              subheader={
                <Box css={[text.caption, textColor('mediumText')]}>
                  <TimeAgo addSuffix={true}>{activity.createdAt}</TimeAgo>
                </Box>
              }
              action={activity.canEdit && !editing && action}
            />

            {editing && (
              <>
                <Divider />
                <ActivityForm
                  enquiry={activity.enquiry}
                  lot={activity.lot}
                  lead={activity.lead}
                  user={activity.user}
                  buyerLead={activity.buyerLead}
                  root={root}
                  activity={activity}
                  onSubmit={() => {
                    if (props.onEdit != null) {
                      props.onEdit();
                    }
                    if (props.setOpenDialog != null) {
                      props.setOpenDialog(false);
                    }
                    setEditing(false);
                  }}
                  onCancel={() => {
                    setEditing(false);
                    if (props.setOpenDialog != null) {
                      props.setOpenDialog(false);
                    }
                  }}
                  openDialog={props.openDialog}
                  setOpenDialog={props.setOpenDialog}
                  showUnfinishedWarning={props.showUnfinishedWarning}
                  disableDialogCollapse={props.disableDialogCollapse}
                />
              </>
            )}

            {!editing && content}

            {!editing && indirect && (
              <>
                {activity.lead ? (
                  <>
                    <Divider />
                    <LeadSnippet lead={activity.lead} openOnNewTab={true} />
                  </>
                ) : activity.buyerLead ? (
                  <>
                    <Divider />
                    <LotSnippet
                      lot={activity.buyerLead.lot}
                      href={`/lots/${activity.buyerLead.lot.id}/buyers/view/${activity.buyerLead.id}`}
                    />
                  </>
                ) : activity.enquiry && activity.enquiry.lot ? (
                  <>
                    <Divider />
                    <LotSnippet
                      lot={activity.enquiry.lot}
                      href={`/enquiries/${activity.enquiry.id}`}
                    />
                  </>
                ) : activity.lot ? (
                  <>
                    <Divider />
                    <LotSnippet lot={activity.lot} />
                  </>
                ) : null}
              </>
            )}
          </Card>
        </div>
      )}
      <FeedDeleteDialog
        allowNotifyParticipants={
          activity.activityType === 'visit' &&
          root.me?.nylasAccount?.calendarSyncEnabled === true
        }
        open={deleting}
        onClose={() => setDeleting(false)}
        onDelete={() => {
          setDeleting(false);
          props.onDelete();
        }}
        title={title}
        createdAt={activity.createdAt}
        activity={activity}
        invisible={props.hideOnDeleting ?? null}
      />
    </>
  );
};
