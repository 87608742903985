import { makeField } from '../hooks/url-search-params';

export const makeNullableListField = (name: string) => {
  return makeField<string[] | null>({
    get: params => {
      const list = params.getAllStrings(name);
      return list.length === 0 ? null : list;
    },
    set: (params, value) => params.setAllStrings(name, value ?? []),
  });
};

export const makeNullableNumberField = (name: string) => {
  return makeField<number | null>({
    get: params => params.getNumber(name),
    set: (params, value) => params.setNumber(name, value),
  });
};

export const toggleFilterItem = (set: Set<string>, item: string) => {
  const newSet = new Set(set);
  if (newSet.has(item)) {
    newSet.delete(item);
  } else {
    newSet.add(item);
  }
  const list = Array.from(newSet);
  return list.length === 0 ? null : list;
};
