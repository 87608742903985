// Generated from src/svgs/fold.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const Fold = ({ size, fill, className }: Props) => {
  return (
    <svg viewBox="0 0 7 14" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><path fillRule="evenodd" clipRule="evenodd" d="M3.407 2.37 1.037 0 0 1.037l3.407 3.406 3.406-3.406L5.776 0l-2.37 2.37Zm0 8.59 2.37 2.37 1.036-1.037-3.406-3.406L0 12.293l1.037 1.037 2.37-2.37Z" fill="#000" /></svg>
  );
}
