import { useMemo } from 'react';

import { Card, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { graphql, useFragment, useRefetchableFragment } from 'react-relay';
import { Box, Flex, useResponsive } from 'react-system';

import type { useGetDefaultRelayTemplate$data } from '../../hooks/__generated__/useGetDefaultRelayTemplate.graphql';
import { useLocale } from '../../hooks/locale';
import {
  templateFragment,
  useGetDefaultRelayTemplate,
} from '../../hooks/use-get-default-relay-template';
import { CardHeader } from '../../shared/card-header';
import { ConnectEmailButton } from '../ConnectEmailButton';
import { EmailForm } from '../EmailForm/EmailForm';

import type {
  FeedEmailForm_buyerLead$data,
  FeedEmailForm_buyerLead$key,
} from './__generated__/FeedEmailForm_buyerLead.graphql';
import type {
  FeedEmailForm_enquiry$data,
  FeedEmailForm_enquiry$key,
} from './__generated__/FeedEmailForm_enquiry.graphql';
import type {
  FeedEmailForm_lead$data,
  FeedEmailForm_lead$key,
} from './__generated__/FeedEmailForm_lead.graphql';
import type {
  FeedEmailForm_lot$data,
  FeedEmailForm_lot$key,
} from './__generated__/FeedEmailForm_lot.graphql';
import type { FeedEmailForm_root$key } from './__generated__/FeedEmailForm_root.graphql';
import type {
  FeedEmailForm_user$data,
  FeedEmailForm_user$key,
} from './__generated__/FeedEmailForm_user.graphql';
import type { FeedEmailFormQuery } from './__generated__/FeedEmailFormQuery.graphql';

type Props = {
  root: FeedEmailForm_root$key;
  enquiry?: null | FeedEmailForm_enquiry$key;
  lot?: null | FeedEmailForm_lot$key;
  lead?: null | FeedEmailForm_lead$key;
  user?: null | FeedEmailForm_user$key;
  buyerLead?: null | FeedEmailForm_buyerLead$key;
  onEmailSent: () => void;
  onCancel: () => void;
  showUnfinishedWarning: boolean;
  setShowUnfinishedWarning: (value: boolean) => void;
  setContactsCount: (value: number) => void;
  setAlertOpen: (value: boolean) => void;
};

graphql`
  fragment FeedEmailForm_property on Property {
    route
    postcode
    streetNumber
    locality
    state
    propertyType {
      label
    }
    municipalityObject {
      label
    }
    latestAppraisal {
      realadvisor {
        min
        max
        value
      }
    }
    numberOfRooms
    livingSurface
    landSurface
  }
`;

type ParentNode =
  | FeedEmailForm_enquiry$data
  | FeedEmailForm_lot$data
  | FeedEmailForm_lead$data
  | FeedEmailForm_user$data
  | FeedEmailForm_buyerLead$data
  | null;

export const FeedEmailForm = (props: Props) => {
  const { t } = useLocale();
  const responsive = useResponsive();
  const { getDefaultTemplate } = useGetDefaultRelayTemplate();

  const [root, refetch] = useRefetchableFragment<
    FeedEmailFormQuery,
    FeedEmailForm_root$key
  >(
    graphql`
      fragment FeedEmailForm_root on Query
      @refetchable(queryName: "FeedEmailFormQuery") {
        me {
          ...EmailForm_me
          id
          nylasAccount {
            id
          }
        }
        docTemplates(first: 100) {
          ...useGetDefaultRelayTemplate
        }
      }
    `,
    props.root,
  );

  const docTemplates = useFragment(templateFragment, root.docTemplates);

  const enquiry = useFragment(
    graphql`
      fragment FeedEmailForm_enquiry on Enquiry {
        __typename
        id
        user {
          id
        }
        responseActivity {
          __typename
        }
        lot {
          lead {
            id
            cmaReports {
              id
              updatedAt
              docTemplate {
                id
              }
            }
          }
          id
          title
          rentNet
          rentExtra
          salePrice
          broker {
            ...EmailForm_contact @relay(mask: false)
          }
          property {
            ...FeedEmailForm_property @relay(mask: false)
          }
          enquiryResponseTemplate {
            ...EmailForm_initialTemplate
          }
        }
      }
    `,
    props.enquiry ?? null,
  );
  const lot = useFragment(
    graphql`
      fragment FeedEmailForm_lot on Lot {
        __typename
        id
        title
        rentNet
        rentExtra
        salePrice
        broker {
          ...EmailForm_contact @relay(mask: false)
        }
        lead {
          id
          cmaReports {
            id
            updatedAt
            docTemplate {
              id
            }
          }
        }
        property {
          ...FeedEmailForm_property @relay(mask: false)
        }
      }
    `,
    props.lot ?? null,
  );
  const lead = useFragment(
    graphql`
      fragment FeedEmailForm_lead on Lead {
        __typename
        id
        broker {
          ...EmailForm_contact @relay(mask: false)
        }
        contact {
          id
        }
        lot {
          id
          title
          rentNet
          rentExtra
          salePrice
        }
        cmaReports {
          id
          updatedAt
          docTemplate {
            id
          }
        }
        property {
          ...FeedEmailForm_property @relay(mask: false)
        }
      }
    `,
    props.lead ?? null,
  );
  const user = useFragment(
    graphql`
      fragment FeedEmailForm_user on User {
        __typename
        id
        broker {
          ...EmailForm_contact @relay(mask: false)
        }
      }
    `,
    props.user ?? null,
  );
  const buyerLead = useFragment(
    graphql`
      fragment FeedEmailForm_buyerLead on BuyerLead {
        __typename
        id
        user {
          id
        }
        lot {
          broker {
            ...EmailForm_contact @relay(mask: false)
          }
          id
          title
          rentNet
          rentExtra
          salePrice
          lead {
            cmaReports {
              id
              updatedAt
              docTemplate {
                id
              }
            }
          }
          property {
            ...FeedEmailForm_property @relay(mask: false)
          }
        }
        enquiry {
          id
          responseActivity {
            __typename
          }
          lot {
            id
            enquiryResponseTemplate {
              ...EmailForm_initialTemplate
            }
          }
        }
      }
    `,
    props.buyerLead ?? null,
  );
  const parent = enquiry ?? lot ?? lead ?? user ?? buyerLead;

  let initialUserIds = null;
  let initialTemplate = null;

  const reportsData = {
    leadId: '',
    lotId: '',
    cmaId: '',
    leadTemplateId: '',
    lotTemplateId: '',
    cmaTemplateId: '',
  };

  type CmaReport = NonNullable<FeedEmailForm_lead$data['cmaReports']>[number];

  const getLatestCma = (cmaReportsArr: CmaReport[]) => {
    const sortedCmaReports = cmaReportsArr.sort(
      (firstCma, secondCma) =>
        new Date(secondCma.updatedAt).getTime() -
        new Date(firstCma.updatedAt).getTime(),
    );
    return sortedCmaReports[0];
  };

  if (parent && parent.__typename === 'Lead') {
    const initialUserId = parent.contact?.id;
    initialUserIds = [initialUserId];
    reportsData.leadId = parent.id;
    reportsData.lotId = parent.lot?.id ?? '';
    if (parent.cmaReports && parent.cmaReports.length !== 0) {
      const latestCma = getLatestCma([...parent.cmaReports]);
      reportsData.cmaId = latestCma.id ?? '';
      reportsData.cmaTemplateId = latestCma.docTemplate?.id ?? '';
    }
  }

  if (parent && parent.__typename === 'Lot') {
    reportsData.lotId = parent.id;
    reportsData.leadId = parent.lead?.id ?? '';
    if (parent.lead?.cmaReports && parent.lead.cmaReports.length !== 0) {
      const latestCma = getLatestCma([...parent.lead.cmaReports]);
      reportsData.cmaId = latestCma.id ?? '';
      reportsData.cmaTemplateId = latestCma.docTemplate?.id ?? '';
    }
  }

  if (parent && parent.__typename === 'User') {
    const initialUserId = parent.id;
    initialUserIds = [initialUserId];
  }

  if (parent && parent.__typename === 'BuyerLead') {
    const initialUserId = parent.user.id;
    initialUserIds = [initialUserId];

    reportsData.leadId = parent.id;
    reportsData.lotId = parent.enquiry?.lot?.id ?? '';

    if (
      parent.lot.lead?.cmaReports &&
      parent.lot.lead.cmaReports.length !== 0
    ) {
      const latestCma = getLatestCma([...parent.lot.lead.cmaReports]);
      reportsData.cmaId = latestCma.id ?? '';
      reportsData.cmaTemplateId = latestCma.docTemplate?.id ?? '';
    }

    if (parent.enquiry != null && parent.enquiry.responseActivity == null) {
      initialTemplate = parent.enquiry.lot?.enquiryResponseTemplate;
    }
  }

  if (parent && parent.__typename === 'Enquiry') {
    const initialUserId = parent.user?.id;
    initialUserIds = [initialUserId];

    reportsData.leadId = parent.lot?.lead?.id ?? '';
    reportsData.lotId = parent.lot?.id ?? '';

    if (
      parent.lot?.lead?.cmaReports &&
      parent.lot.lead.cmaReports.length !== 0
    ) {
      const latestCma = getLatestCma([...parent.lot.lead.cmaReports]);
      reportsData.cmaId = latestCma.id ?? '';
      reportsData.cmaTemplateId = latestCma.docTemplate?.id ?? '';
    }

    if (parent.responseActivity == null) {
      initialTemplate = parent.lot?.enquiryResponseTemplate;
    }
  }

  const appraisalReportTemplateId = useMemo(
    () =>
      getDefaultTemplate(
        docTemplates as useGetDefaultRelayTemplate$data,
        'appraisal_report',
      ) ?? null,
    [docTemplates, getDefaultTemplate],
  );

  const lotBrochureTemplateId = useMemo(
    () =>
      getDefaultTemplate(
        docTemplates as useGetDefaultRelayTemplate$data,
        'lot_brochure',
      ) ?? null,
    [docTemplates, getDefaultTemplate],
  );

  reportsData.leadTemplateId = appraisalReportTemplateId ?? '';
  reportsData.lotTemplateId = lotBrochureTemplateId ?? '';

  const getPropertyByParent = (parentNode: ParentNode) => {
    if (
      parentNode &&
      (parentNode.__typename === 'Lead' || parentNode.__typename === 'Lot')
    ) {
      return parentNode.property;
    }
    if (parentNode && parentNode.__typename === 'BuyerLead') {
      return parentNode.lot.property;
    }
    if (parentNode && parentNode.__typename === 'Enquiry') {
      return parentNode.lot?.property;
    }
  };

  const getAgentByParent = (parentNode: ParentNode) => {
    if (
      parentNode &&
      (parentNode.__typename === 'Lead' ||
        parentNode.__typename === 'Lot' ||
        parentNode.__typename === 'User')
    ) {
      return parentNode.broker;
    }
    if (parentNode && parentNode.__typename === 'BuyerLead') {
      return parentNode.lot.broker;
    }
    if (parentNode && parentNode.__typename === 'Enquiry') {
      return parentNode.lot?.broker;
    }
  };

  const getLotByParent = (parentNode: ParentNode) => {
    if (parentNode && parentNode.__typename === 'Lot') {
      return parentNode;
    }
    if (
      parentNode &&
      (parentNode.__typename === 'BuyerLead' ||
        parentNode.__typename === 'Lead' ||
        parentNode.__typename === 'Enquiry')
    ) {
      return parentNode.lot;
    }
  };

  const propertyByParent = getPropertyByParent(parent);
  const agentByParent = getAgentByParent(parent);
  const lotByParent = getLotByParent(parent);

  const agentKeys = {
    firstName: agentByParent?.firstName ?? null,
    lastName: agentByParent?.lastName ?? null,
    gender: agentByParent?.gender ?? null,
    primaryEmail: agentByParent?.primaryEmail?.email ?? null,
    primaryPhoneNumber:
      agentByParent?.primaryPhoneNumber?.formattedNumber ?? null,
    organisationName: agentByParent?.organisation?.name ?? null,
    organisationAddress: agentByParent?.organisation?.formattedAddress ?? null,
    title: agentByParent?.title ?? null,
  };

  const propertyKeys = {
    route: propertyByParent?.route ?? null,
    postcode: propertyByParent?.postcode ?? null,
    state: propertyByParent?.state ?? null,
    streetNumber: propertyByParent?.streetNumber ?? null,
    type: propertyByParent?.propertyType?.label ?? null,
    numberOfRooms: propertyByParent?.numberOfRooms ?? null,
    livingSurface: propertyByParent?.livingSurface ?? null,
    ...reportsData,
    landSurface: propertyByParent?.landSurface ?? null,
    municipality:
      propertyByParent?.municipalityObject?.label ??
      propertyByParent?.locality ??
      null,
    latestAppraisal: {
      realadvisor: {
        min: propertyByParent?.latestAppraisal?.realadvisor?.min ?? null,
        max: propertyByParent?.latestAppraisal?.realadvisor?.max ?? null,
        value: propertyByParent?.latestAppraisal?.realadvisor?.value ?? null,
      },
    },
  };

  const lotKeys = {
    title: lotByParent?.title ?? null,
    rentNet: lotByParent?.rentNet ?? null,
    rentExtra: lotByParent?.rentExtra ?? null,
    salePrice: lotByParent?.salePrice ?? null,
  };

  const parentId = parent == null ? null : parent.id;
  if (root.me?.nylasAccount != null) {
    return (
      <Card css={{ marginBottom: 16 }}>
        <CardHeader>
          <Box css={{ fontSize: '1rem' }}>{t('sendAnEmail')}</Box>
        </CardHeader>
        <Box p={3}>
          {root.me && (
            <EmailForm
              isDialog={false}
              parentName={parent?.__typename}
              parentId={parentId}
              initialRecipients={
                initialUserIds
                  ? (initialUserIds
                      .filter(Boolean)
                      .map(id => ({ userId: id })) as { userId: string }[])
                  : null
              }
              initialTemplate={initialTemplate}
              property={propertyKeys}
              agent={agentKeys}
              lot={lotKeys}
              me={root.me}
              refetch={() => {
                refetch({}, { fetchPolicy: 'store-and-network' });
              }}
              onEmailSent={props.onEmailSent}
              onCancel={props.onCancel}
              setContactsCount={props.setContactsCount}
              setAlertOpen={props.setAlertOpen}
              showUnfinishedWarning={props.showUnfinishedWarning}
              setShowUnfinishedWarning={props.setShowUnfinishedWarning}
            />
          )}
        </Box>
      </Card>
    );
  } else {
    return (
      <Dialog
        open={true}
        onClose={props.onCancel}
        fullScreen={responsive([true, false])}
      >
        <DialogTitle>{t('sendAnEmail')}</DialogTitle>
        <DialogContent>
          <Flex p={3}>
            <Box
              css={{
                margin: 'auto',
              }}
            >
              <ConnectEmailButton
                onSuccess={() => {
                  refetch({}, { fetchPolicy: 'store-and-network' });
                }}
                onError={() => {
                  refetch({}, { fetchPolicy: 'store-and-network' });
                }}
              />
            </Box>
          </Flex>
        </DialogContent>
      </Dialog>
    );
  }
};
