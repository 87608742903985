// Generated from src/svgs/nav-dashboard.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const NavDashboard = ({ size, fill, className }: Props) => {
  return (
    <svg viewBox="0 0 25 25" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><path d="M9.218 10.327a1.632 1.632 0 0 1 1.108-1.107l7.727-2.273-2.273 7.725a1.632 1.632 0 0 1-1.108 1.108l-7.725 2.273 2.271-7.726ZM.961 12.5c0 6.372 5.166 11.538 11.539 11.538 6.372 0 11.538-5.166 11.538-11.538 0-3.06-1.216-5.995-3.379-8.16A11.546 11.546 0 0 0 12.5.962C6.128.961.962 6.127.962 12.5Z" stroke="#fff" strokeWidth="1.25" /></svg>
  );
}
