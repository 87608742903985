/**
 * @generated SignedSource<<556da7a6501406b4dca64109406fdbca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UserGender = "female" | "male";
export type UserTitle = "doctor" | "madam" | "professor" | "sir";
import { FragmentRefs } from "relay-runtime";
export type FeedEmailForm_lead$data = {
  readonly __typename: "Lead";
  readonly broker: {
    readonly firstName: string | null;
    readonly gender: UserGender | null;
    readonly id: string;
    readonly lastName: string | null;
    readonly organisation: {
      readonly formattedAddress: string | null;
      readonly id: string;
      readonly name: string | null;
    } | null;
    readonly primaryEmail: {
      readonly email: string;
      readonly id: string;
    } | null;
    readonly primaryPhoneNumber: {
      readonly formattedNumber: string;
      readonly id: string;
    } | null;
    readonly title: UserTitle | null;
  } | null;
  readonly cmaReports: ReadonlyArray<{
    readonly docTemplate: {
      readonly id: string;
    } | null;
    readonly id: string;
    readonly updatedAt: string;
  }> | null;
  readonly contact: {
    readonly id: string;
  } | null;
  readonly id: string;
  readonly lot: {
    readonly id: string;
    readonly rentExtra: number | null;
    readonly rentNet: number | null;
    readonly salePrice: number | null;
    readonly title: string | null;
  } | null;
  readonly property: {
    readonly landSurface: number | null;
    readonly latestAppraisal: {
      readonly realadvisor: {
        readonly max: number | null;
        readonly min: number | null;
        readonly value: number | null;
      } | null;
    } | null;
    readonly livingSurface: number | null;
    readonly locality: string | null;
    readonly municipalityObject: {
      readonly label: string;
    } | null;
    readonly numberOfRooms: number | null;
    readonly postcode: string | null;
    readonly propertyType: {
      readonly label: string | null;
    } | null;
    readonly route: string | null;
    readonly state: string | null;
    readonly streetNumber: string | null;
  } | null;
  readonly " $fragmentType": "FeedEmailForm_lead";
};
export type FeedEmailForm_lead$key = {
  readonly " $data"?: FeedEmailForm_lead$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedEmailForm_lead">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = [
  (v0/*: any*/)
],
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "label",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedEmailForm_lead",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "broker",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gender",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Email",
          "kind": "LinkedField",
          "name": "primaryEmail",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PhoneNumber",
          "kind": "LinkedField",
          "name": "primaryPhoneNumber",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "formattedNumber",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Organisation",
          "kind": "LinkedField",
          "name": "organisation",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "formattedAddress",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "contact",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Lot",
      "kind": "LinkedField",
      "name": "lot",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rentNet",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rentExtra",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "salePrice",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CmaReport",
      "kind": "LinkedField",
      "name": "cmaReports",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "updatedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DocTemplate",
          "kind": "LinkedField",
          "name": "docTemplate",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Property",
      "kind": "LinkedField",
      "name": "property",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "route",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "postcode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "streetNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "locality",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "state",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PropertyType",
          "kind": "LinkedField",
          "name": "propertyType",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Place",
          "kind": "LinkedField",
          "name": "municipalityObject",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Appraisal",
          "kind": "LinkedField",
          "name": "latestAppraisal",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RealadvisorAppraisal",
              "kind": "LinkedField",
              "name": "realadvisor",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "min",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "max",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "value",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numberOfRooms",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "livingSurface",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "landSurface",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Lead",
  "abstractKey": null
};
})();

(node as any).hash = "1b8ea3c53f083dd1ae6ae3b5a2266fec";

export default node;
