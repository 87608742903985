/**
 * @generated SignedSource<<038a5877d24161119ebdb61bb11928cc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type LeadAppraisalInheritanceNextStep = "buy" | "sell" | "share";
export type LeadAppraisalReason = "already_for_sale" | "buy_soon" | "buy_this" | "inheritance" | "move_rental" | "not_selling" | "not_set" | "other" | "other_not_owner" | "other_owner" | "other_renting" | "refinance" | "sell" | "separation";
export type LeadAppraisalSeparationNextStep = "buy" | "sell" | "share";
export type LeadBuyHorizon = "not_set" | "over_12_months" | "under_12_months" | "under_3_months";
export type LeadMortgageBuyingStatus = "identified" | "offer" | "searching" | "visited";
export type LeadMortgageStatus = "not_started" | "working_with_bank" | "working_with_independent";
export type LeadMortgageStep = "financials" | "result" | "situation" | "type" | "verification";
export type LeadRelationship = "agent" | "buyer" | "heir" | "not_set" | "other" | "owner" | "tenant";
export type LeadSaleHorizon = "already_for_sale" | "from_1_to_2_years" | "from_2_years" | "from_6_to_12_months" | "not_selling" | "not_set" | "under_6_months";
import { FragmentRefs } from "relay-runtime";
export type LeadDetailsAnswersCard_lead$data = {
  readonly appraisalInheritanceNextStep: LeadAppraisalInheritanceNextStep | null;
  readonly appraisalMarketTime: number | null;
  readonly appraisalMonthlyGrossRent: number | null;
  readonly appraisalNextStep: string | null;
  readonly appraisalOpenToOffer: string | null;
  readonly appraisalPerceivedValue: number | null;
  readonly appraisalPerception: string | null;
  readonly appraisalPropertyBalconySurface: number | null;
  readonly appraisalPropertyConstructionYear: number | null;
  readonly appraisalPropertyFloorOfFlat: number | null;
  readonly appraisalPropertyHasView: boolean | null;
  readonly appraisalPropertyLandSurface: number | null;
  readonly appraisalPropertyLivingSurface: number | null;
  readonly appraisalPropertyLocality: string | null;
  readonly appraisalPropertyNumberOfBathrooms: number | null;
  readonly appraisalPropertyNumberOfBedrooms: number | null;
  readonly appraisalPropertyNumberOfFloors: number | null;
  readonly appraisalPropertyNumberOfIndoorParkings: number | null;
  readonly appraisalPropertyNumberOfOutdoorParkings: number | null;
  readonly appraisalPropertyNumberOfRooms: number | null;
  readonly appraisalPropertyOccupied: string | null;
  readonly appraisalPropertyPlotNumber: string | null;
  readonly appraisalPropertyPostcode: string | null;
  readonly appraisalPropertyPropertyType: {
    readonly label: string | null;
    readonly mainType: string | null;
  } | null;
  readonly appraisalPropertyRenovationYear: number | null;
  readonly appraisalPropertyRoute: string | null;
  readonly appraisalPropertySide: string | null;
  readonly appraisalPropertyStreetNumber: string | null;
  readonly appraisalPropertyUsage: string | null;
  readonly appraisalReason: LeadAppraisalReason;
  readonly appraisalSeparationNextStep: LeadAppraisalSeparationNextStep | null;
  readonly buyHorizon: LeadBuyHorizon;
  readonly callTimes: ReadonlyArray<string> | null;
  readonly dateOfBirth: string | null;
  readonly debt: number | null;
  readonly emailAddress: string | null;
  readonly firstName: string | null;
  readonly hasDependantChildren: boolean | null;
  readonly landingPageUrl: string | null;
  readonly lastName: string | null;
  readonly mortgageAnalysis: {
    readonly maxPropertyValue: number | null;
  } | null;
  readonly mortgageBuyingStatus: LeadMortgageBuyingStatus | null;
  readonly mortgageGoogleAddress: {
    readonly formattedAddress: string | null;
  } | null;
  readonly mortgageIsConstruction: boolean | null;
  readonly mortgageIsPropertyIdentified: boolean | null;
  readonly mortgageMortgageType: string | null;
  readonly mortgagePropertyUsage: string | null;
  readonly mortgagePropertyValue: number | null;
  readonly mortgageStatus: LeadMortgageStatus | null;
  readonly mortgageStep: LeadMortgageStep | null;
  readonly mortgageTerm: string | null;
  readonly nationality: {
    readonly label: string;
  } | null;
  readonly numberOfBuyers: number | null;
  readonly numberOfDependantChildren: number | null;
  readonly occupation: {
    readonly label: string;
  } | null;
  readonly otherCredits: number | null;
  readonly phoneNumber: string | null;
  readonly property: {
    readonly balconySurface: number | null;
    readonly constructionYear: number | null;
    readonly firstAppraisal: {
      readonly realadvisor: {
        readonly value: number | null;
      } | null;
    } | null;
    readonly landSurface: number | null;
    readonly livingSurface: number | null;
    readonly numberOfBathrooms: number | null;
    readonly numberOfBedrooms: number | null;
    readonly numberOfIndoorParkings: number | null;
    readonly numberOfOutdoorParkings: number | null;
    readonly numberOfRooms: number | null;
    readonly renovationYear: number | null;
  } | null;
  readonly receivedMortgageOffer: boolean | null;
  readonly referrer: string | null;
  readonly referringDomain: string | null;
  readonly relationship: LeadRelationship;
  readonly saleHorizon: LeadSaleHorizon;
  readonly savings: number | null;
  readonly secondPillar: number | null;
  readonly sourceOrganisation: {
    readonly name: string | null;
  } | null;
  readonly thirdPillar: number | null;
  readonly yearlyRevenues: number | null;
  readonly " $fragmentType": "LeadDetailsAnswersCard_lead";
};
export type LeadDetailsAnswersCard_lead$key = {
  readonly " $data"?: LeadDetailsAnswersCard_lead$data;
  readonly " $fragmentSpreads": FragmentRefs<"LeadDetailsAnswersCard_lead">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LeadDetailsAnswersCard_lead",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalPropertyRoute",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalPropertyStreetNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalPropertyPostcode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalPropertyLocality",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PropertyType",
      "kind": "LinkedField",
      "name": "appraisalPropertyPropertyType",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mainType",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalPropertyHasView",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalPropertyOccupied",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalPropertyUsage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalOpenToOffer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalMarketTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "relationship",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalPropertyLivingSurface",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalPropertyLandSurface",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalPropertyBalconySurface",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalPropertyConstructionYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalPropertyRenovationYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalPropertyNumberOfFloors",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalPropertyFloorOfFlat",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalPropertySide",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalPropertyNumberOfRooms",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalPropertyNumberOfBedrooms",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalPropertyNumberOfBathrooms",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalPropertyNumberOfIndoorParkings",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalPropertyNumberOfOutdoorParkings",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalPropertyPlotNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalReason",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "saleHorizon",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buyHorizon",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mortgageTerm",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalInheritanceNextStep",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalSeparationNextStep",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalPerceivedValue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalMonthlyGrossRent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalPerception",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalNextStep",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Property",
      "kind": "LinkedField",
      "name": "property",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numberOfRooms",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numberOfBedrooms",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numberOfBathrooms",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numberOfIndoorParkings",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numberOfOutdoorParkings",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "constructionYear",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "renovationYear",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "livingSurface",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "landSurface",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "balconySurface",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Appraisal",
          "kind": "LinkedField",
          "name": "firstAppraisal",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RealadvisorAppraisal",
              "kind": "LinkedField",
              "name": "realadvisor",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "value",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mortgageStep",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MortgageAnalysis",
      "kind": "LinkedField",
      "name": "mortgageAnalysis",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maxPropertyValue",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "yearlyRevenues",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "savings",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "debt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mortgagePropertyValue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mortgageMortgageType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mortgageIsConstruction",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GoogleAddress",
      "kind": "LinkedField",
      "name": "mortgageGoogleAddress",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "formattedAddress",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mortgageIsPropertyIdentified",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mortgageBuyingStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mortgagePropertyUsage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mortgageStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "receivedMortgageOffer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numberOfBuyers",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasDependantChildren",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numberOfDependantChildren",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Dictionary",
      "kind": "LinkedField",
      "name": "occupation",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Dictionary",
      "kind": "LinkedField",
      "name": "nationality",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dateOfBirth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "secondPillar",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thirdPillar",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "otherCredits",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emailAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phoneNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "referrer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "referringDomain",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "landingPageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "callTimes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organisation",
      "kind": "LinkedField",
      "name": "sourceOrganisation",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Lead",
  "abstractKey": null
};
})();

(node as any).hash = "f8cba92aeec47c5f4c41ac5b52b2cf05";

export default node;
