import { gql, useQuery } from '@apollo/client';
import { CheckCircle, Error, Star, Visibility } from '@mui/icons-material';
import {
  Alert,
  Box,
  Chip,
  Link,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material';
import { DataGridPro, type GridColDef } from '@mui/x-data-grid-pro';

import { formatPrice } from '../../src/utils/format-price';
import type { AutoAssignRankingChQuery } from '../__generated__/graphql';

const AUTO_ASSIGN_RANKING_QUERY = gql`
  query AutoAssignRankingCh($lead_id: uuid!) {
    auto_assign_ranking_ch(lead_id: $lead_id) {
      message
      agents {
        id
        membership_type
        modules
        full_name
        crm_link
        total_weighted_leads_assigned
        total_leads_assigned_30_days
        total_unique_lead_visits
        total_lead_mandates
        total_own_mandates
        total_transactions
        total_commissions
        total_agent_share
        lead_to_visit_conversion
        visit_to_mandate_conversion
        mandate_to_transaction_conversion
        lead_to_transaction_conversion
        lead_to_visit_score
        visit_to_mandate_score
        mandate_to_transaction_score
        lead_to_transaction_score
        overall_score
        revenues_per_lead
        gross_margin_per_lead
        number_of_overdue_leads
        number_of_overdue_days
        number_of_leads_without_activity
        number_of_listings_available
      }
    }
    leads_by_pk(id: $lead_id) {
      property {
        agent_ranking(limit: 20) {
          agent: user {
            id
            full_name
          }
          transactions_in_location
          transactions_in_municipality
          transactions_in_district
          transactions_in_state
          total_transactions
          distance
        }
      }
    }
  }
`;

interface Props {
  leadId: string;
}

// Shortened custom sort comparator
const customSortComparatorNullsLast = (v1: any, v2: any): number => {
  if (v1 == null) {
    return 1;
  }
  if (v2 == null) {
    return -1;
  }
  return v1 > v2 ? 1 : v1 < v2 ? -1 : 0;
};

export const AutoAssignAssistantCH = ({ leadId: lead_id }: Props) => {
  const { data, loading, error } = useQuery<AutoAssignRankingChQuery>(
    AUTO_ASSIGN_RANKING_QUERY,
    {
      variables: { lead_id },
      fetchPolicy: 'cache-and-network',
    },
  );

  // Display 5 skeletons while loading results
  if (loading) {
    return (
      <>
        {[...Array(5).keys()].map(i => (
          <Skeleton key={i} variant="rounded" height={40} sx={{ mb: 2 }} />
        ))}
      </>
    );
  }

  if (error) {
    return (
      <Alert severity="error">
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </Alert>
    );
  }

  const ranking = data?.auto_assign_ranking_ch;
  const visibility_ranking = data?.leads_by_pk?.property?.agent_ranking?.map(
    (agent, index) => ({
      rank: index + 1,
      ...agent,
    }),
  );

  const merged_ranking = ranking?.agents?.map(agent => {
    const visibility_agent = visibility_ranking?.find(
      a => a.agent?.id === agent?.id,
    );
    return {
      ...agent,
      ...visibility_agent,
    };
  });

  const columns: GridColDef[] = [
    {
      field: 'full_name',
      headerName: 'Name',
      minWidth: 160,
      flex: 2,
      renderCell: ({ value, row }) => (
        <Link href={`${row.crm_link}`} target="_blank">
          {value}
        </Link>
      ),
    },
    {
      field: 'membership_type',
      headerName: 'Type',
      flex: 2,
      minWidth: 140,
      renderCell: ({ value }) =>
        value && <Chip label={value} size="small" color="primary" />,
    },
    {
      field: 'rank',
      headerName: 'Visibility Rank',
      flex: 1,
      minWidth: 60,
      align: 'right',
      headerAlign: 'right',
      sortComparator: customSortComparatorNullsLast,
      renderCell: ({ value, row }) => (
        <Tooltip
          title={
            <>
              <Typography variant="body2">
                <strong>{row.transactions_in_location ?? '-'}</strong>{' '}
                Transaction in Location
              </Typography>
              <Typography variant="body2">
                <strong>{row.transactions_in_municipality ?? '-'}</strong>{' '}
                Transaction in Municipality
              </Typography>
              <Typography variant="body2">
                <strong>{row.transactions_in_district ?? '-'}</strong>{' '}
                Transaction in District
              </Typography>
              <Typography variant="body2">
                <strong>{row.transactions_in_state ?? '-'}</strong> Transaction
                in Canton
              </Typography>
            </>
          }
          placement="top-end"
        >
          {value && (
            <Chip
              label={value}
              size="small"
              icon={<Visibility />}
              color="warning"
            />
          )}
        </Tooltip>
      ),
    },

    {
      field: 'distance',
      headerName: 'Distance (km)',
      flex: 1,
      minWidth: 60,
      align: 'right',
      headerAlign: 'right',
      renderCell: ({ value }) => (value ? value.toFixed(1) : null),
      sortComparator: customSortComparatorNullsLast,
    },
    {
      field: 'total_leads_assigned_30_days',
      headerName: 'Leads (30D)',
      valueGetter: (_, row) => row.total_leads_assigned_30_days ?? 0,
      valueFormatter: (value: number) => (value ?? 0).toFixed(0),
      minWidth: 60,
      flex: 1,
      align: 'right',
      headerAlign: 'right',
    },
    {
      field: 'visit_to_mandate_conversion',
      headerName: 'Visit to Mandate',
      flex: 1,
      minWidth: 80,
      renderCell: ({ value, row }) => (
        <Tooltip
          title={
            <Typography variant="body2">
              {row.total_lead_mandates} mandates
            </Typography>
          }
          placement="top-end"
        >
          <div>{value ? (value * 100).toFixed(0) + '%' : '-'}</div>
        </Tooltip>
      ),
      align: 'right',
      headerAlign: 'right',
    },
    {
      field: 'mandate_to_transaction_conversion',
      headerName: 'Mandate to Deal',
      flex: 1,
      minWidth: 80,
      renderCell: ({ value, row }) => (
        <Tooltip
          title={
            <>
              <Typography variant="body2">
                {row.total_transactions} deals
              </Typography>
              <Typography variant="caption">
                (including own mandates)
              </Typography>
            </>
          }
          placement="top-end"
        >
          <div>{value ? (value * 100).toFixed(0) + '%' : '-'}</div>
        </Tooltip>
      ),
      align: 'right',
      headerAlign: 'right',
    },
    {
      field: 'number_of_listings_available',
      headerName: 'Listings Available',
      flex: 1,
      minWidth: 60,
      align: 'right',
      headerAlign: 'right',
    },
    {
      field: 'gross_margin_per_lead',
      headerName: 'Margin / Lead',
      flex: 1,
      minWidth: 80,
      renderCell: ({ value }) => formatPrice(value, 'de-CH', 'CHF'),
      align: 'right',
      headerAlign: 'right',
    },
    {
      field: 'overall_score',
      headerName: 'Overall Score',
      flex: 1,
      minWidth: 60,
      renderCell: ({ value, row }) => {
        if (value) {
          let color: 'default' | 'success' | 'error' = 'default';
          if (value >= 4) {
            color = 'success';
          } else if (value < 3) {
            color = 'error';
          }
          return (
            <Tooltip
              title={
                <>
                  <Typography variant="body2">
                    <strong>
                      {(row.lead_to_visit_score &&
                        row.lead_to_visit_score.toFixed(1)) ||
                        '-'}
                    </strong>{' '}
                    Leads to Visit
                  </Typography>
                  <Typography variant="body2">
                    <strong>
                      {(row.visit_to_mandate_score &&
                        row.visit_to_mandate_score.toFixed(1)) ||
                        '-'}
                    </strong>{' '}
                    Visit to Mandate
                  </Typography>
                  <Typography variant="body2">
                    <strong>
                      {(row.mandate_to_transaction_score &&
                        row.mandate_to_transaction_score.toFixed(1)) ||
                        '-'}
                    </strong>{' '}
                    Mandate to Transaction
                  </Typography>
                  <Typography variant="body2">
                    <strong>{row.overall_score}</strong> Overall Score
                  </Typography>
                </>
              }
              placement="top-end"
            >
              <Chip label={value} size="small" icon={<Star />} color={color} />
            </Tooltip>
          );
        }
        return '-';
      },
      align: 'right',
      headerAlign: 'right',
    },
    {
      field: 'number_of_overdue_leads',
      headerName: 'Late & Inactive Leads',
      flex: 1,
      minWidth: 80,
      valueGetter: (_, row) =>
        (row.number_of_overdue_leads ?? 0) +
        (row.number_of_leads_without_activity ?? 0),
      valueFormatter: (value?: number) => `${(value ?? 0).toFixed(0)}`,
      align: 'right',
      headerAlign: 'right',
      renderCell: ({ value, row }) => {
        let chip = <Chip label={value} size="small" icon={<CheckCircle />} />;
        if (value > 5) {
          chip = (
            <Chip label={value} size="small" color="error" icon={<Error />} />
          );
        }
        return (
          <Tooltip
            placement="top-end"
            title={
              <Box textAlign="right">
                <Typography variant="body2">
                  {row.number_of_leads_without_activity} inactive leads
                </Typography>
                <Typography variant="body2">
                  {row.number_of_overdue_leads} overdue leads
                </Typography>
                <Typography variant="body2">
                  {row.number_of_overdue_days} overdue days
                </Typography>
              </Box>
            }
          >
            {chip}
          </Tooltip>
        );
      },
    },
  ];

  return (
    <Box sx={{ width: '100%' }}>
      <DataGridPro
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            whiteSpace: 'normal',
            lineHeight: '0.85rem',
            fontSize: '0.75rem',
            textAlign: 'right',
          },
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'overall_score', sort: 'desc' }],
          },
        }}
        rows={merged_ranking ?? []}
        columns={columns}
        rowHeight={38}
        hideFooter
        hideFooterPagination
        disableColumnMenu
        autoHeight
      />
    </Box>
  );
};
