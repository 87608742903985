import * as React from 'react';
import type { Interpolation } from '@emotion/react';
import { css } from '@emotion/react';
import { useResizeRect } from '@realadvisor/observe';
import { usePan, useClick } from './gestures';
import { getFromTo, getFromToPos, INDEX_LEFT, INDEX_RIGHT } from './math';
import type { Value, ValueConstraints } from './math';

export type { Value, ValueConstraints };

// Range details:
// [  Track ][Thumb][CentralTrack][Thumb][  Track ]

export type Props = {
  thumbSize?: number;
  thumbTouchSize?: number;
  value: Value;
  valueConstraints: ValueConstraints;
  onChange: (value: Value) => void;
  onChanged?: (value: Value) => void;
  children?: any;
  bg?: string;
  thumbBg?: string;
  ThumbIcon?: (props: {
    size?: string | number;
    fill?: string;
    className?: string;
    css?: Interpolation<unknown>;
  }) => JSX.Element;
  bgLineHeight?: number;
  bgLineAlign?: 'start-end' | 'half-thumb';
};

const containerStyle = css`
  display: flex;
  width: 100%;
  flex: 1;
  position: relative;
`;

const trackStyle = css`
  position: absolute;
  top: 0;
  bottom: 0;
  user-select: none;
  user-drag: none;
  pointer-events: all;
`;

const mainStyle = css`
  top: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  user-select: none;
  user-drag: none;
  * {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    user-select: none;
    user-drag: none;
  }
`;

const thumbStyle = (withScale: boolean) => css`
  position: absolute;
  top: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  user-select: none;
  user-drag: none;
  cursor: pointer;
  pointer-events: all;
  border-radius: 100%;
  transform-origin: 'center center';

  color: rgba(0, 0, 0, 0.5);

  transition: transform 0.15s linear, box-shadow 0.25s ease-out,
    color 0.15s ease-out;

  box-shadow: inset 10000px 0 0 rgba(255, 255, 255, 0),
    0px 0px 3px rgba(0, 0, 0, 0.2);

  :hover {
    box-shadow: inset 10000px 0 0 rgba(255, 255, 255, 0.2),
      0px 0px 5px rgba(0, 0, 0, 0.2);
    z-index: 1;
    color: rgba(0, 0, 0, 0.6);
  }

  :active {
    z-index: 1;
    ${withScale ? 'transform: scale(1.1);' : ''}
    box-shadow: inset 10000px 0 0 rgba(255, 255, 255, 0.2),
      0px 0px 7px rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 1);
  }
`;

const centralTrackStyle = css`
  position: absolute;
  top: 0;
  bottom: 0;

  user-select: none;
  user-drag: none;
  pointer-events: all;
  display: flex;
`;

const DEFAULT_THUM_TOUCH_SIZE = 32;
export const Slider = ({
  valueConstraints,
  value,
  thumbSize = 18,
  thumbTouchSize: thumbTouchSizeProp,
  bg = '#0BA197',
  thumbBg = bg,
  ThumbIcon,
  bgLineHeight = 4,
  // to drag left right both (invisible)
  bgLineAlign = 'half-thumb',
  onChange,
  onChanged,
}: Props) => {
  const mainRef = React.useRef<null | HTMLDivElement>(null);

  const mainRect = useResizeRect(mainRef);

  const [panState, setPanState] = React.useState<null | {
    x0: number;
    fromPos0: number;
    toPos0: number;
  }>(null);
  const params = {
    thumbSize,
    width: mainRect ? mainRect.width : 0,
    valueConstraints,
  };

  const clampValue = (nextValue: Value) => {
    const { fixed } = valueConstraints;
    if (fixed === 'to') {
      const to = value.to;
      const from = Math.min(nextValue.from, to);
      return { from, to };
    }
    if (fixed === 'from') {
      const from = value.from;
      const to = Math.max(from, nextValue.to);
      return { to, from };
    }
    return nextValue;
  };

  const onStart = (x: number) => {
    const { fromPos, toPos } = getFromToPos(params, value);
    setPanState({
      x0: x,
      fromPos0: fromPos,
      toPos0: toPos,
    });
  };

  const onEnd = () => {
    if (panState) {
      setPanState(null);
      if (onChanged) {
        onChanged(value);
      }
    }
  };

  const letftThumbRef = React.useRef(null);
  const bindLeftThumb = usePan({
    ref: letftThumbRef,
    onStart,
    onMove: x => {
      if (panState) {
        const { x0, fromPos0 } = panState;
        const { toPos } = getFromToPos(params, value);
        const dx = x - x0;
        const { from, to } = getFromTo(
          params,
          { fromPos: fromPos0 + dx, toPos },
          INDEX_LEFT,
        );
        onChange(clampValue({ from, to }));
      }
    },
    onEnd,
  });

  const rightThumbRef = React.useRef(null);
  const bindRightThumb = usePan({
    ref: rightThumbRef,
    onStart,
    onMove: x => {
      if (panState) {
        const { x0, toPos0 } = panState;
        const dx = x - x0;
        const { from, to } = getFromTo(
          params,
          { fromPos, toPos: toPos0 + dx },
          INDEX_RIGHT,
        );
        onChange(clampValue({ from, to }));
      }
    },
    onEnd,
  });

  const bindLeftTrack = useClick({
    onTap: x => {
      if (mainRef.current != null) {
        const rect = mainRef.current.getBoundingClientRect();
        const left = rect.left;
        const fromPos = x - left - (INDEX_LEFT * thumbSize) / 2;
        const { toPos } = getFromToPos(params, value);
        const { from, to } = getFromTo(params, { fromPos, toPos }, INDEX_LEFT);
        onChange(clampValue({ from, to }));
        if (onChanged) {
          onChanged(clampValue({ from, to }));
        }
      }
    },
  });

  const bindRightTrack = useClick({
    onTap: x => {
      if (mainRef.current != null) {
        const rect = mainRef.current.getBoundingClientRect();
        const left = rect.left;
        const toPos = x - left - (INDEX_RIGHT * thumbSize) / 2;
        const { fromPos } = getFromToPos(params, value);
        const { from, to } = getFromTo(params, { fromPos, toPos }, INDEX_RIGHT);
        onChange(clampValue({ from, to }));
        if (onChanged) {
          onChanged(clampValue({ from, to }));
        }
      }
    },
  });

  const bindCentralTrack = useClick({
    onTap: x => {
      if (mainRef.current != null) {
        const { fixed } = valueConstraints;
        const rect = mainRef.current.getBoundingClientRect();
        const left = rect.left;
        const targetLeftPos = x - left - (INDEX_LEFT * thumbSize) / 2;
        const targetRightPos = x - left - (INDEX_RIGHT * thumbSize) / 2;
        const { fromPos, toPos } = getFromToPos(params, value);

        if (fixed === 'from') {
          const { from, to } = getFromTo(
            params,
            { fromPos, toPos: targetRightPos },
            INDEX_RIGHT,
          );
          onChange({ from, to });
          if (onChanged) {
            onChanged({ from, to });
          }
        }

        if (fixed === 'to') {
          const { from, to } = getFromTo(
            params,
            { fromPos: targetLeftPos, toPos },
            INDEX_LEFT,
          );
          onChange({ from, to });
          if (onChanged) {
            onChanged({ from, to });
          }
        }

        if (fixed === 'none' || fixed == null) {
          const { from, to } =
            targetLeftPos - fromPos <= toPos - targetRightPos
              ? getFromTo(params, { fromPos: targetLeftPos, toPos }, INDEX_LEFT)
              : getFromTo(
                  params,
                  { fromPos, toPos: targetRightPos },
                  INDEX_RIGHT,
                );
          onChange({ from, to });
          if (onChanged) {
            onChanged({ from, to });
          }
        }
      }
    },
  });

  const usePercent = mainRect == null;

  const { fromPos, toPos } = getFromToPos(
    {
      thumbSize,
      width: mainRect == null ? 10000 : mainRect.width,
      valueConstraints,
    },
    value,
  );

  const fixedTo = valueConstraints.fixed === 'to';
  const fixedFrom = valueConstraints.fixed === 'from';

  const leftThumbSize = fixedFrom ? 0 : thumbSize;
  const rightThumbSize = fixedTo ? 0 : thumbSize;

  // seems like no visual effect

  const fromRounded = usePercent
    ? (fromPos * 100) / 10000
    : Math.round(fromPos);
  const toRounded = usePercent ? (toPos * 100) / 10000 : Math.round(toPos);

  const leftTrackWidth = usePercent ? `${fromRounded}%` : fromRounded;
  const leftThumbPos = usePercent ? `${fromRounded}%` : fromRounded;

  const centralTrackPos = usePercent
    ? `calc(${leftThumbPos} ${fixedFrom ? '+' : '-'} ${
        fixedFrom && bgLineAlign === 'start-end' ? 0 : thumbSize / 2
      }px )`
    : fromRounded +
      (fixedFrom
        ? bgLineAlign === 'start-end'
          ? 0
          : thumbSize / 2
        : -thumbSize / 2);

  const centralTrackWidth = usePercent
    ? `calc(${toRounded}% - ${fromRounded}% + ${
        fixedTo ? 0 : leftThumbSize
      }px + ${fixedFrom && bgLineAlign === 'start-end' ? thumbSize / 2 : 0}px)`
    : Math.max(
        toRounded -
          fromRounded +
          (fixedTo
            ? bgLineAlign === 'start-end'
              ? thumbSize / 2
              : 0
            : leftThumbSize) +
          (fixedFrom && bgLineAlign === 'start-end' ? thumbSize / 2 : 0),
        0,
      );

  const rightThumbPos = usePercent ? `${toRounded}%` : toRounded;
  const rightTrackLeftPos = usePercent
    ? `calc(${rightThumbPos} + ${thumbSize}px)`
    : // hack for not well structured code
      (rightThumbPos as number) + thumbSize;

  const toValue = (value: string | number) =>
    typeof value === 'number' ? value + 'px' : value;

  // to not cause scrollbars we set initially thumb pos with same size as thumbSize
  const thumbTouchSize =
    typeof leftThumbPos === 'number'
      ? thumbTouchSizeProp != null
        ? thumbTouchSizeProp
        : DEFAULT_THUM_TOUCH_SIZE
      : thumbSize;

  let leftTouchablePos = (thumbSize - thumbTouchSize) / 2;
  let rightTouchablePos = (thumbSize - thumbTouchSize) / 2;

  if (
    typeof leftThumbPos === 'number' &&
    typeof rightThumbPos === 'number' &&
    mainRect != null
  ) {
    const intersection = Math.max(
      0,
      thumbTouchSize - thumbSize - (rightThumbPos - leftThumbPos),
    );
    // add constraints on thumb touch position
    // they must not intersect so every thumb will be touchable
    leftTouchablePos -= intersection / 2;
    leftTouchablePos += Math.max(0, -leftThumbPos - leftTouchablePos);

    rightTouchablePos += intersection / 2;

    rightTouchablePos -= Math.max(
      0,
      rightThumbPos +
        rightTouchablePos +
        thumbTouchSize -
        mainRect.width -
        thumbSize,
    );
  }

  return (
    <div css={containerStyle} style={{ height: thumbSize }}>
      {/* track line */}
      <div
        css={{
          flex: 1,
          height: bgLineHeight,
          marginTop: 'auto',
          marginBottom: 'auto',
          marginLeft: bgLineAlign === 'half-thumb' ? thumbSize / 2 : 0,
          marginRight: bgLineAlign === 'half-thumb' ? thumbSize / 2 : 0,
          borderRadius: 100,
          background: 'rgba(0,0,0,0.1)',
        }}
      />

      <div
        ref={mainRef}
        css={mainStyle}
        style={{ left: leftThumbSize, right: rightThumbSize }}
      >
        {/* left track */}
        <div
          {...bindLeftTrack}
          css={trackStyle}
          style={{
            left: -leftThumbSize,
            width: leftTrackWidth,
          }}
        />

        {/* right track */}
        <div
          {...bindRightTrack}
          css={trackStyle}
          style={{
            right: -rightThumbSize,
            left: rightTrackLeftPos,
          }}
        />

        {/* central track */}
        <div
          {...bindCentralTrack}
          css={centralTrackStyle}
          style={{
            left: centralTrackPos,
            width: centralTrackWidth,
          }}
        >
          {/* thumb line */}
          <div
            css={{
              flex: 1,
              height: bgLineHeight,
              margin: 'auto 0',
              borderRadius: 100,
              background: bg,
            }}
          />
        </div>

        {/* left thumb */}
        <div
          ref={letftThumbRef}
          {...bindLeftThumb}
          style={{
            left: `calc(${toValue(leftThumbPos)} - ${thumbSize}px)`,
          }}
          css={[
            {
              width: thumbSize,
              display: fixedFrom ? 'none' : undefined,
              background: thumbBg,
            },
            thumbStyle(ThumbIcon == null),
          ]}
        >
          <div
            style={{
              left: leftTouchablePos,
            }}
            css={{
              position: 'absolute',
              top: `${(thumbSize - thumbTouchSize) / 2}px`,
              width: `${thumbTouchSize}px`,
              height: `${thumbTouchSize}px`,
              backgroundColor: 'transparent',
            }}
          />
          {ThumbIcon != null && <ThumbIcon size={12} />}
        </div>

        {/* right thumb */}
        <div
          ref={rightThumbRef}
          {...bindRightThumb}
          css={[
            {
              width: thumbSize,
              display: fixedTo ? 'none' : undefined,
              background: thumbBg,
            },
            thumbStyle(ThumbIcon == null),
          ]}
          style={{ left: rightThumbPos }}
        >
          <div
            style={{
              left: rightTouchablePos,
            }}
            css={{
              position: 'absolute',
              top: `${(thumbSize - thumbTouchSize) / 2}px`,
              width: `${thumbTouchSize}px`,
              height: `${thumbTouchSize}px`,
              backgroundColor: 'transparent',
            }}
          />
          {ThumbIcon != null && <ThumbIcon size={12} />}
        </div>
      </div>
    </div>
  );
};
