// @flow

import * as React from 'react';

import { cloudinaryUrl } from '@realadvisor/cloudinary';

import { theme } from '../../hooks/theme';

// This snippet is intended to use in emails, so using tables etc. here
// It is also used in RichEditorValue when converting from draft to HTML which require a simple function that return JSX
// Note when editing HTML and styles - there is a sanitizer on the backend side, make sure you edited his rules

// TODO: fix flow types

const buttonColor = '#1DA197';

export type PropertySnippet = {|
  t: any,
  language: string,
  title: string,
  postcode: string,
  locality: string,
  listingUrl: string,
  thumbnailUrl: string,
  propertyTypeLabel: string,
  numberOfRooms: any,
  livingSurface: any,
  id: string,
  // render for actual email letter
  emailMode?: boolean,
|};

export const mediaCss = {
  '.propertySnippetV1_columnImageMobile': {
    display: 'none !important',
  },

  '@media only screen and (max-width: 575px)': {
    '.propertySnippetV1': {
      width: '100% !important',
    },

    '.propertySnippetV1_templateColumnContainer': {
      display: 'block !important',
      width: '100% !important',
    },

    '.propertySnippetV1_columnImage': {
      height: 'auto !important',
      display: 'none !important',
      width: '100% !important',
    },

    '.propertySnippetV1_columnImageMobile': {
      display: 'block !important',
      height: 'auto !important',
      maxWidth: '575px !important',
      width: '100% !important',
    },
  },
};

export const renderPropertySnippet = (props: PropertySnippet): React.Node => {
  const {
    title,
    postcode,
    locality,
    listingUrl,
    thumbnailUrl,
    propertyTypeLabel,
    numberOfRooms,
    livingSurface,
    t,
    //emailMode
  } = props;

  const roomsLine = Array.isArray(numberOfRooms)
    ? `${numberOfRooms.join(' - ')} ${t('rooms')}`
    : t('roomsShort', {
        count: Array.isArray(numberOfRooms) ? 0 : numberOfRooms || 0,
      });

  const livingSurfaceLine = Array.isArray(livingSurface)
    ? livingSurface.join(' - ')
    : livingSurface || 0;

  const maxWidth = '700';

  return (
    <table
      border={0}
      cellPadding={0}
      cellSpacing={0}
      style={{
        border: '1px solid #DDDDDD',
        borderRadius: 3,
        overflow: 'hidden',
        maxWidth: `${maxWidth}px`,
      }}
      className="propertySnippetV1"
    >
      <tbody>
        <tr>
          <td
            align="center"
            valign="top"
            width="35%"
            className="propertySnippetV1_templateColumnContainer"
          >
            <table border={0} cellPadding={0} cellSpacing={0} width="100%">
              <tbody>
                <tr>
                  <td className="propertySnippetV1_leftColumnContent">
                    {/* can't use fragments here as it preventing conversion to HTML */}
                    {thumbnailUrl != null &&
                      thumbnailUrl !== '#snippet.thumbnailUrl' && (
                        <img
                          src={cloudinaryUrl(
                            { w: 490, h: 390, c: 'fill', f: 'jpg' },
                            thumbnailUrl,
                          )}
                          alt={title}
                          width={245}
                          style={{ maxWidth: 245, display: 'block' }}
                          className="propertySnippetV1_columnImage"
                        />
                      )}
                    {thumbnailUrl != null &&
                      thumbnailUrl !== '#snippet.thumbnailUrl' && (
                        <img
                          src={cloudinaryUrl(
                            { w: 728, h: 388, c: 'fill', f: 'jpg' },
                            thumbnailUrl,
                          )}
                          width={728}
                          style={{ maxWidth: 728, display: 'none' }}
                          className="propertySnippetV1_columnImageMobile"
                        />
                      )}
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <td
            align="center"
            valign="top"
            width="65%"
            className="propertySnippetV1_templateColumnContainer"
          >
            <table border={0} cellPadding={10} cellSpacing={0} width="100%">
              <tbody>
                <tr>
                  <td
                    valign="top"
                    className="propertySnippetV1_rightColumnContent"
                  >
                    <div
                      style={{
                        fontSize: 12,
                        fontWeight: theme.text.font.bold,
                        color: '#555555',
                      }}
                    >
                      {postcode} {locality}
                    </div>
                    <div
                      style={{
                        fontSize: 16,
                        fontWeight: theme.text.font.bold,
                        marginTop: 12,
                        color: '#000000',
                      }}
                    >
                      {title}
                    </div>
                    <table
                      border={0}
                      cellPadding={0}
                      cellSpacing={0}
                      style={{ marginTop: 15 }}
                      width="100%"
                    >
                      <tbody>
                        <tr>
                          <td
                            align="center"
                            valign="top"
                            width="50%"
                            className="propertySnippetV1_propertyDataContainer"
                          >
                            <table
                              border={0}
                              cellPadding={0}
                              cellSpacing={0}
                              width="100%"
                              style={{ color: '#555555' }}
                            >
                              <tbody>
                                <tr>
                                  <td
                                    style={{ fontSize: 12, lineHeight: '180%' }}
                                  >
                                    {propertyTypeLabel || '-'}
                                    <br />
                                    {roomsLine}
                                    <br />
                                    {livingSurfaceLine} m<sup>2</sup>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td
                            align="center"
                            valign="bottom"
                            width="50%"
                            className="propertySnippetV1_actionButtonContainer"
                          >
                            <table
                              border={0}
                              cellPadding={0}
                              cellSpacing={0}
                              width="100%"
                            >
                              <tbody>
                                <tr>
                                  <td valign="bottom">
                                    <div
                                      style={{
                                        fontSize: 17,
                                        fontWeight: theme.text.font.bold,
                                        marginTop: 12,
                                        textAlign: 'right',
                                      }}
                                    >
                                      <div style={{ marginTop: 12 }}>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ marginLeft: 'auto' }}
                                        >
                                          <tbody>
                                            <tr>
                                              <td align="center">
                                                <table
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          fontFamily:
                                                            'Helvetica, sans-serif',
                                                          fontSize: 14,
                                                          verticalAlign:
                                                            'bottom',
                                                          borderRadius: 4,
                                                          textAlign: 'center',
                                                          backgroundColor:
                                                            buttonColor,
                                                        }}
                                                      >
                                                        <a
                                                          href={listingUrl}
                                                          target="_blank"
                                                          rel="noopener noreferrer"
                                                          style={{
                                                            border:
                                                              'solid 2px ' +
                                                              buttonColor,
                                                            borderRadius: 4,
                                                            boxSizing:
                                                              'border-box',
                                                            cursor: 'pointer',
                                                            display:
                                                              'inline-block',
                                                            fontSize: 14,
                                                            fontWeight:
                                                              theme.text.font
                                                                .bold,
                                                            margin: 0,
                                                            padding: '8px 16px',
                                                            textDecoration:
                                                              'none',
                                                            backgroundColor:
                                                              buttonColor,
                                                            borderColor:
                                                              buttonColor,
                                                            color: '#ffffff',
                                                          }}
                                                        >
                                                          {t('seeAnnouncement')}
                                                        </a>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
