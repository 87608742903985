export type Value = {
  from: number;
  to: number;
};

export type ValueConstraints = {
  min: number;
  max: number;
  minWidth?: number;
  maxWidth?: number;
  fixed?: 'to' | 'from' | 'none';
  step?: number;
};

export const INDEX_LEFT = -1;
export const INDEX_RIGHT = 1;

type Constraints = {
  thumbSize: number;
  width: number;
  valueConstraints: ValueConstraints;
};

type Pos = { fromPos: number; toPos: number };

export const getFromToPos = (
  {
    width,
    valueConstraints: {
      min,
      max,
      minWidth = 0,
      maxWidth = Number.POSITIVE_INFINITY,
    },
  }: Constraints,
  { from, to }: Value,
): Pos => {
  if (process.env.NODE_ENV !== 'production') {
    const eps = maxWidth / 1000000;

    if (to - from > maxWidth + eps) {
      console.warn(
        `
maxWidth constraint does not match,
maxWidth = ${maxWidth}; (to - from) = ${to - from}
      `,
      );
    }

    if (to - from < minWidth - eps) {
      console.warn(
        `
minWidth constraint does not match,
minWidth = ${minWidth}; (to - from) = ${to - from}
      `,
      );
    }
  }

  const sW = 0;
  const fromPos = ((width - 2 * sW) * (from - min)) / (max - min);
  const toPos = ((width - 2 * sW) * (to - min)) / (max - min) + sW;

  return {
    fromPos: Number.isNaN(fromPos) ? 0 : fromPos,
    toPos: Number.isNaN(toPos) ? 0 : Math.min(toPos, width),
  };
};

export const getFromTo = (
  {
    width,
    valueConstraints: {
      min,
      max,
      minWidth = 0,
      maxWidth = Number.POSITIVE_INFINITY,
      step,
    },
  }: Constraints,
  { fromPos, toPos }: Pos,
  index: number,
): Value => {
  const sW = 0;
  const minDeltaScreen = ((width - 2 * sW) * minWidth) / (max - min);
  const maxDeltaScreen = ((width - 2 * sW) * maxWidth) / (max - min);
  // constraints
  let fromPosConstr = Math.max(
    0,
    Math.min(fromPos, width - 2 * sW - minDeltaScreen),
  );
  let toPosConstr = Math.max(sW + minDeltaScreen, Math.min(toPos, width - sW));

  if (index === INDEX_LEFT) {
    toPosConstr = Math.min(
      Math.max(toPosConstr, fromPosConstr + sW + minDeltaScreen),
      fromPosConstr + sW + maxDeltaScreen,
    );
  } else if (index === INDEX_RIGHT) {
    fromPosConstr = Math.max(
      Math.min(fromPosConstr, toPosConstr - sW - minDeltaScreen),
      toPosConstr - sW - maxDeltaScreen,
    );
  }

  let from = ((max - min) * fromPosConstr) / (width - 2 * sW) + min;
  let to = ((max - min) * (toPosConstr - sW)) / (width - 2 * sW) + min;
  // align to step
  if (step != null) {
    from = Math.round(from / step) * step;
    to = Math.round(to / step) * step;
  }

  return { from, to };
};
