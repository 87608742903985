/**
 * @generated SignedSource<<17e77c95952f7b667b74c3690147b328>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
type PropertyForm_root$fragmentType = any;
export type lotCreateDrawerPropertyFormQuery$variables = {||};
export type lotCreateDrawerPropertyFormQuery$data = {|
  +me: ?{|
    +id: string,
  |},
  +origins: $ReadOnlyArray<{|
    +id: string,
    +name: string,
  |}>,
  +tenantSettings: ?{|
    +countryCode: string,
    +id: string,
  |},
  +$fragmentSpreads: PropertyForm_root$fragmentType,
|};
export type lotCreateDrawerPropertyFormQuery = {|
  response: lotCreateDrawerPropertyFormQuery$data,
  variables: lotCreateDrawerPropertyFormQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "me",
  "plural": false,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Tenant",
  "kind": "LinkedField",
  "name": "tenantSettings",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "countryCode",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": "origins",
  "args": [
    {
      "kind": "Literal",
      "name": "type",
      "value": "lot_origin_types"
    }
  ],
  "concreteType": "Dictionary",
  "kind": "LinkedField",
  "name": "dictionaries",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": "dictionaries(type:\"lot_origin_types\")"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "lotCreateDrawerPropertyFormQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "PropertyForm_root"
      },
      (v1/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "lotCreateDrawerPropertyFormQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PropertyType",
        "kind": "LinkedField",
        "name": "propertyTypes",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "label",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mainType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v2/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/)
    ]
  },
  "params": {
    "cacheID": "ec65793d7c9adce0569b4d5164cda92b",
    "id": null,
    "metadata": {},
    "name": "lotCreateDrawerPropertyFormQuery",
    "operationKind": "query",
    "text": "query lotCreateDrawerPropertyFormQuery {\n  ...PropertyForm_root\n  me {\n    id\n  }\n  tenantSettings {\n    id\n    countryCode\n  }\n  origins: dictionaries(type: \"lot_origin_types\") {\n    id\n    name\n  }\n}\n\nfragment PropertyForm_root on Query {\n  propertyTypes {\n    id\n    name\n    label\n    mainType\n    type\n  }\n  tenantSettings {\n    id\n    countryCode\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "fc821c3d85634ba4fe36ec88aeb9b3b3";

export default node;
