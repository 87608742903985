// @flow

import * as React from 'react';

import { Box } from 'react-system';

import { useTheme } from '../hooks/theme';

type Props = {|
  children: React.Node,
|};

export const CardHeader = (props: Props): React.Node => {
  const { text } = useTheme();
  return (
    <Box p={3} flexShrink={0} css={text.subtitle2}>
      {props.children}
    </Box>
  );
};
