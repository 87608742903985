import { useState } from 'react';

import { Button, Grid, Stack } from '@mui/material';

import { useLocale } from '../../../../src/hooks/locale';
import { EditSmartListDialog } from '../../SmartListTitle';
import { useFiltersSearchParams } from '../useFiltersSearchParams';

import { Node } from './Node';

interface AdvancedFiltersProps {
  where: any;
  tableName: string;
  setWhere: (where: any) => void;
  queryParamsScope?: string;
}

export const AdvancedFilters = ({
  tableName,
  where,
  setWhere,
  queryParamsScope,
}: AdvancedFiltersProps) => {
  const [showSaveDialog, setShowSaveDialog] = useState(false);
  const [, setFiltersSearch] = useFiltersSearchParams(queryParamsScope);
  const { t } = useLocale();

  return (
    <Grid container spacing={1} padding={1}>
      <Grid item xs={12} md={6}>
        <Node
          name="where"
          node={where}
          path={[`${tableName}_bool_exp`]}
          setWhere={setWhere}
          where={where}
        />
        <Stack direction="row" spacing={1} sx={{ marginTop: 1 }}>
          <Button
            variant="contained"
            onClick={() => setFiltersSearch(where)}
            size="small"
          >
            {t('Apply Filters')}
          </Button>
          <Button
            color="neutral"
            onClick={() => setShowSaveDialog(true)}
            size="small"
          >
            {t('Save Smart List')}
          </Button>
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <pre style={{ backgroundColor: '#f5f5f5', margin: 0 }}>
          <code>{JSON.stringify(where, null, 2)}</code>
        </pre>
      </Grid>
      <EditSmartListDialog
        open={showSaveDialog}
        smartList={{
          where,
          crm_entity: tableName,
        }}
        onClose={() => setShowSaveDialog(false)}
        onUpdate={() => {
          // Optionally, you can add logic here to update the list of smart lists
        }}
      />
    </Grid>
  );
};
