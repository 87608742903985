import { gql } from '@apollo/client';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import type { MarketingCardLeadFragmentFragment } from '../../__generated__/graphql';

export const MARKETING_CARD_LEAD_FRAGMENT = gql`
  fragment MarketingCardLeadFragment on leads {
    referrer
    referring_domain
    landing_page_url
  }
`;

export const MarketingCard = ({
  lead,
}: {
  lead?: MarketingCardLeadFragmentFragment | null;
}) => {
  return (
    <TableContainer component={Paper} elevation={0} variant="outlined">
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>Marketing</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries({
            Referrer: lead?.referrer,
            'Referring domain': lead?.referring_domain,
            'Landing page URL': lead?.landing_page_url,
          }).map(([key, value]) => (
            <TableRow key={key}>
              <TableCell
                component="th"
                scope="row"
                sx={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  maxWidth: '145px',
                }}
              >
                {key}
              </TableCell>
              <TableCell
                sx={{
                  overflow: 'scroll',
                  whiteSpace: 'nowrap',
                  maxWidth: '200px  ',
                }}
              >
                {value}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
