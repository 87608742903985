// @flow

import { Link } from '@material-ui/core';
import { EditorState, Modifier, RichUtils } from 'draft-js';
// https://github.com/facebook/draft-js/blob/8e9cabfd4193cea46008bd5ac96e6a6fb60af9cf/src/model/modifier/getRangesForDraftEntity.js
import getEntityKeyForSelection from 'draft-js/lib/getEntityKeyForSelection';
import getRangesForDraftEntity from 'draft-js/lib/getRangesForDraftEntity';
// https://github.com/facebook/draft-js/blob/8e9cabfd4193cea46008bd5ac96e6a6fb60af9cf/src/model/entity/getEntityKeyForSelection.js
import normalizeUrl from 'normalize-url';

const getEntitySelectionInCurrentLine = (editorState, entityKey) => {
  const contentState = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  const contentBlock = contentState.getBlockForKey(selection.getStartKey());
  const [{ start, end }] = getRangesForDraftEntity(contentBlock, entityKey);
  return selection.merge({ anchorOffset: start, focusOffset: end });
};

const getSelectedEntityKey = editorState => {
  const contentState = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  return getEntityKeyForSelection(contentState, selection);
};

const isLinkEntity = (contentState, entityKey) =>
  contentState.getEntity(entityKey).getType() === 'LINK';

const shouldNormalizeUrl = url =>
  url.startsWith('mailto:') === false && url.startsWith('tel:') === false;

export const getCurrentLinkUrl = (editorState: EditorState): string => {
  const contentState = editorState.getCurrentContent();
  const selectedEntityKey = getSelectedEntityKey(editorState);
  if (selectedEntityKey != null) {
    if (isLinkEntity(contentState, selectedEntityKey) === false) {
      return '';
    } else {
      return contentState.getEntity(selectedEntityKey).getData().url ?? '';
    }
  }
  return '';
};

export const createLink = (
  editorState: EditorState,
  url: string,
  postprocessLink?: void | (string => string),
  title?: string,
): EditorState => {
  const contentState = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  const normalized = shouldNormalizeUrl(url) ? normalizeUrl(url) : url.trim();
  const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', {
    url: postprocessLink == null ? normalized : postprocessLink(normalized),
  });
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const selectedEntityKey = getSelectedEntityKey(editorState);
  if (selection.isCollapsed()) {
    if (
      selectedEntityKey != null &&
      isLinkEntity(contentState, selectedEntityKey)
    ) {
      // modify whole link under collapsed cursor
      const entitySelection = getEntitySelectionInCurrentLine(
        editorState,
        selectedEntityKey,
      );
      return RichUtils.toggleLink(editorState, entitySelection, entityKey);
    } else {
      // insert link at collapsed cursor if no existing link
      const replaceTextInLink = title !== '' ? title : url;
      const contentState = Modifier.replaceText(
        editorState.getCurrentContent(),
        selection,
        replaceTextInLink ?? '',
        editorState.getCurrentInlineStyle(),
        entityKey,
      );
      return EditorState.push(editorState, contentState, 'insert-characters');
    }
  } else {
    // wrap selection with link
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    return RichUtils.toggleLink(
      newEditorState,
      newEditorState.getSelection(),
      entityKey,
    );
  }
};

export const removeLink = (editorState: EditorState): EditorState => {
  const selection = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const selectedEntityKey = getSelectedEntityKey(editorState);
  if (selection.isCollapsed()) {
    if (
      selectedEntityKey != null &&
      isLinkEntity(contentState, selectedEntityKey)
    ) {
      // remove whole link under collapsed cursor
      const entitySelection = getEntitySelectionInCurrentLine(
        editorState,
        selectedEntityKey,
      );
      return RichUtils.toggleLink(editorState, entitySelection, null);
    } else {
      return editorState;
    }
  } else {
    // remove link only under selection
    return RichUtils.toggleLink(editorState, selection, null);
  }
};

const linkStrategy = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return entityKey != null && isLinkEntity(contentState, entityKey);
  }, callback);
};

const WrappedLink = ({ contentState, entityKey, children }) => {
  const { url } = contentState.getEntity(entityKey).getData();
  return (
    <Link underline="always" color="primary" href={url}>
      {children}
    </Link>
  );
};

export const createLinkPlugin = (): any => {
  return {
    decorators: [
      {
        strategy: linkStrategy,
        component: WrappedLink,
      },
    ],
  };
};
