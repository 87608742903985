import { isRATenantId } from '../../src/utils/tenant';
import type { LeadCardFragment, MeQuery } from '../__generated__/graphql';

type TUser = Pick<
  NonNullable<MeQuery['me']>,
  'id' | 'is_admin' | 'tenant_id' | 'user_ids_in_led_teams' | 'is_broker'
>;

/**
 * Checks whether the current user can see the seller dashboard for the provided listing
 */
export const canSeeSellerDashboard = (
  user: TUser | null | undefined,
  listing: {
    sellers: {
      user_id: any;
    }[];
    broker_id?: any;
    created_by_user?:
      | {
          id: any;
        }
      | null
      | undefined;
  },
) => {
  if (user == null) {
    throw new Error('User must be logged in');
  }

  if (isRATenantId(user.tenant_id) === false) {
    return false;
  }

  const sellersIds = listing.sellers.map(seller => seller.user_id);
  const userIdsInLedTeams = user.user_ids_in_led_teams.flatMap(item =>
    item.team.teams_users.map(user => user.user_id),
  );

  return (
    user.is_admin ||
    userIdsInLedTeams.includes(listing.broker_id) ||
    sellersIds.includes(user.id) ||
    listing.created_by_user?.id === user.id ||
    listing.broker_id === user.id
  );
};

export const userCanViewLeadDetails = (
  user: TUser | null | undefined,
  lead: LeadCardFragment,
) => {
  return (
    user?.is_admin ||
    (user?.is_broker && lead.claimed_by != null) ||
    user?.id === lead.created_by || // show LeadDetails to agent who created lead https://github.com/realadvisor/realadvisor/issues/16500
    lead.completed === false // show incomplete to agent : https://github.com/realadvisor/realadvisor/issues/16929
  );
};
