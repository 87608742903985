import ImageIcon from '@mui/icons-material/Image';
import { Box, Paper } from '@mui/material';
import { Image } from '@realadvisor/image';

export type PropertyImageCrop = 'fit' | 'fill';
export type PropertyImageSize = 'quarter_page' | 'half_page' | 'full_page';
export type PropertyImageBackgroundColor = 'white' | 'black';

interface BrochureImagePreviewProps {
  size?: PropertyImageSize | null;
  crop?: PropertyImageCrop | null;
  bg?: PropertyImageBackgroundColor | null;
  orientation: 'portrait' | 'landscape';
  imageUrl: string;
}

const getGridTemplateColumns = (
  size: PropertyImageSize | null | undefined,
  orientation: 'portrait' | 'landscape',
) => {
  if (orientation === 'portrait') {
    if (size === 'quarter_page') {
      return '1fr 1fr';
    } else {
      return '1fr';
    }
  }

  if (size === 'full_page') {
    return '1fr';
  } else {
    return '1fr 1fr';
  }
};

const PREVIEW_WIDTH = 120;
const PREVIEW_HEIGHT = 170;

const sizeToPlaceholdersNbMap: Record<PropertyImageSize, number> = {
  quarter_page: 3,
  half_page: 1,
  full_page: 0,
};

export const BrochureImagePreview: React.FC<BrochureImagePreviewProps> = ({
  size,
  crop,
  bg,
  orientation,
  imageUrl,
}) => {
  const sizeImgOption =
    orientation === 'portrait'
      ? { h: PREVIEW_HEIGHT, w: PREVIEW_WIDTH }
      : { w: PREVIEW_HEIGHT, h: PREVIEW_WIDTH };
  const placeholders = Array.from({
    length: size != null ? sizeToPlaceholdersNbMap[size] : 1,
  });

  return (
    <Paper
      sx={{
        width: `${sizeImgOption.w}px`,
        height: `${sizeImgOption.h}px`,
        display: 'grid',
        gap: '10px',
        gridTemplateColumns: getGridTemplateColumns(size, orientation),
        gridTemplateRows: getGridTemplateColumns(
          size,
          orientation === 'portrait' ? 'landscape' : 'portrait',
        ),
        p: 1,
        borderRadius: '2px',
      }}
      variant="outlined"
    >
      <Image
        src={imageUrl}
        options={{ ...sizeImgOption, c: crop ?? 'fill', f: 'jpg' }}
        css={{
          minHeight: 0,
          minWidth: 0,
          objectFit: crop === 'fit' ? 'contain' : 'cover',
          backgroundColor: bg === 'black' ? 'black' : 'white',
          borderRadius: '2px',
        }}
      />
      {placeholders.map((_, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'divider',
            borderRadius: '2px',
            overflow: 'hidden',
          }}
        >
          <ImageIcon sx={{ width: 20, height: 20, color: 'grey.500' }} />
        </Box>
      ))}
    </Paper>
  );
};
