import {
  type MutableRefObject,
  type ReactNode,
  useEffect,
  useRef,
} from 'react';

import { Box, Paper } from '@mui/material';

import { type PageId } from '../shared';

import { type CMAReportType } from './CMAReportEditorDrawer';
import { useCmaMobileTab } from './CMAReportEditorWorkflow';
import MobilePreview from './MobilePreview';

const BOTTOM_HEIGHT = 102;

type MobileLayoutProps = {
  cmaReport: CMAReportType | null;
  editor: ReactNode;
  stepper: ReactNode;
  viewerPageId: PageId;
  iFrameRef: MutableRefObject<HTMLIFrameElement>;
};

const MobileLayout = ({
  cmaReport,
  editor,
  stepper,
  viewerPageId,
  iFrameRef,
}: MobileLayoutProps) => {
  const editorEl = useRef<HTMLDivElement>(null);
  const viewerEl = useRef<HTMLDivElement>(null);
  const { mobileTab } = useCmaMobileTab();

  useEffect(() => {
    if (mobileTab === 'editor') {
      editorEl.current?.scrollIntoView({ behavior: 'smooth' });
    }
    if (mobileTab === 'viewer') {
      viewerEl.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [mobileTab]);

  return (
    <>
      <Paper
        square
        elevation={4}
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          zIndex: 1,
        }}
      >
        <Box sx={{ width: '100%' }}>{stepper}</Box>
      </Paper>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          paddingBottom: `${BOTTOM_HEIGHT}px`,
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            scrollSnapType: 'x mandatory',
            display: 'flex',
            overflow: 'hidden',
            flex: 1,
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          <Box
            ref={editorEl}
            css={{
              position: 'relative',
              overflowY: 'auto',
              minWidth: '100vw',
              scrollSnapAlign: 'center',
            }}
          >
            {editor}
          </Box>
          <div
            ref={viewerEl}
            css={{
              minWidth: '100vw',
              position: 'relative',
              overflow: 'hidden',
              scrollSnapAlign: 'center',
            }}
          >
            {cmaReport && (
              <MobilePreview
                cmaReport={cmaReport}
                iFrameRef={iFrameRef}
                viewerPageId={viewerPageId}
              />
            )}
          </div>
        </Box>
      </Box>
    </>
  );
};

export default MobileLayout;
