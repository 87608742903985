/**
 * @generated SignedSource<<b16ac545f92dc7599227fa41a4a58c16>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeedRequalification_activity$data = {
  readonly extraData?: any | null;
  readonly " $fragmentType": "FeedRequalification_activity";
};
export type FeedRequalification_activity$key = {
  readonly " $data"?: FeedRequalification_activity$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedRequalification_activity">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedRequalification_activity",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "extraData",
          "storageKey": null
        }
      ],
      "type": "ActivityRequalification",
      "abstractKey": null
    }
  ],
  "type": "Activity",
  "abstractKey": "__isActivity"
};

(node as any).hash = "b325c685f701cdfbfaaf05e27b37bbd8";

export default node;
