// @flow

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { shallowEqualObjects } from 'shallow-equal';
import type { Property$AlignItems, Property$JustifyContent } from 'csstype';
import type { GoogleMap as Map, GoogleMapOptions } from 'rgm';
import type { LatLngLiteral, MapTypeId, StyleOptions } from './google-types';
import { useGoogleApi } from './google-api';
import {
  fitBounds,
  paddingBounds,
  getScreenOffset,
  getPanByCoordinates,
} from './geo-utils';
import type { Bounds, Padding } from './geo-utils';
import type { GeoJSON } from './geojson-types';

type EventSource = 'user' | 'auto';

export type GoogleMapChildren = React.ChildrenArray<
  | React.Element<typeof GeoJson>
  | React.Element<typeof GoogleMarker>
  | React.Element<typeof Overlay>
  | null,
>;

type MapProps = {|
  defaultZoom?: number,
  center?: LatLngLiteral,
  // offset is usefull when we need to move map on some pixels
  // so mostly its declarative panBy(x,y)
  offset?: {| +x: number, +y: number |},
  defaultBbox?: Bounds,
  defaultPadding?: Padding,
  tilt?: number,
  mapTypeId?: MapTypeId,
  children?: GoogleMapChildren,
  options?: {| ...GoogleMapOptions, center?: LatLngLiteral, zoom?: number |},
  onBoundsChanged?: ({|
    bounds: Bounds,
    center: LatLngLiteral,
    zoom: number,
    source: EventSource,
  |}) => void,
  onIdle?: () => void,
  onClick?: (latLng: LatLngLiteral) => void,
|};

const useFirst = <T>(value: T): T => {
  const ref = React.useRef(value);
  return ref.current;
};

const toLatLngLiteral = latLng => ({ lat: latLng.lat(), lng: latLng.lng() });

const MapContext = React.createContext<Map | null>(null);

type MapHandle = {|
  zoomAt: (pt: LatLngLiteral, zoom: number, source?: EventSource) => void,
  panTo: (center: LatLngLiteral, source?: EventSource) => void,
  panBy: (offset: {| x: number, y: number |}, source?: EventSource) => void,
  panToBounds: (
    bounds: Bounds,
    padding?: Padding,
    source?: EventSource,
  ) => void,
  getPaddingBounds: (bounds: Bounds, padding?: Padding) => null | Bounds,
  fitBounds: (
    bounds: Bounds,
    padding?: Padding,
  ) => {|
    center: LatLngLiteral,
    zoom: number,
    bounds: Bounds,
  |} | null,
|};

const getSize = elt => {
  const rect = elt.getBoundingClientRect();
  return {
    width: rect.width,
    height: rect.height,
  };
};

export type GoogleMapRef = React.ElementRef<
  React.AbstractComponent<MapProps, MapHandle>,
>;

export const GoogleMap: React$AbstractComponent<MapProps, MapHandle> =
  React.forwardRef((props, ref) => {
    const api = useGoogleApi();
    const element = React.useRef(null);
    const onBoundsChangedRef = React.useRef(props.onBoundsChanged);
    const onIdleRef = React.useRef(props.onIdle);
    const onClickRef = React.useRef(props.onClick);
    const eventSourceRef = React.useRef('auto');
    const [map, setMap] = React.useState<Map | null>(null);

    const { center, tilt, mapTypeId, options } = props;
    const defaultCenter = useFirst(center);
    const defaultZoom = useFirst(props.defaultZoom);
    const defaultOptions = useFirst(options);
    const defaultBbox = useFirst(props.defaultBbox);
    const defaultPadding = useFirst(props.defaultPadding);
    const initialOffset = useFirst(props.offset);
    const centerLat = center?.lat;
    const centerLng = center?.lng;
    const { offset } = props;

    React.useImperativeHandle(
      ref,
      () => ({
        zoomAt: (pt: LatLngLiteral, zoom: number, source?: EventSource) => {
          if (map) {
            const center = map.getCenter();
            const centerLatLng = { lat: center.lat(), lng: center.lng() };
            const offsetA = getScreenOffset(pt, centerLatLng, map.getZoom());
            const offsetB = getScreenOffset(pt, centerLatLng, zoom);
            const x = offsetA.x - offsetB.x;
            const y = offsetA.y - offsetB.y;
            eventSourceRef.current = source != null ? source : 'auto';
            map.setZoom(zoom);
            map.panBy(x, y);
          }
        },
        getPaddingBounds: (bounds: Bounds, padding?: Padding) => {
          if (element.current && map) {
            const size = getSize(element.current);
            const zc = paddingBounds(bounds, map.getZoom(), size, padding);
            return zc;
          }
          return null;
        },
        // original map.panToBounds works highly different and unpredictable in some cases
        // as it uses minimal move, so place bounds near sides
        // map.fitToBounds doesnt animate
        fitBounds: (bounds: Bounds, padding?: Padding) => {
          if (element.current) {
            const size = getSize(element.current);
            const zc = fitBounds(bounds, size, padding);
            return zc;
          }
          return null;
        },
        panTo: (center: LatLngLiteral, source?: EventSource) => {
          if (api && map && element.current) {
            eventSourceRef.current = source != null ? source : 'auto';
            map.panTo(center);
          }
        },
        panBy: (offset: {| x: number, y: number |}, source?: EventSource) => {
          if (api && map && element.current) {
            eventSourceRef.current = source != null ? source : 'auto';
            map.panBy(offset.x, offset.y);
          }
        },

        panToBounds: (
          bounds: Bounds,
          padding?: Padding,
          source?: EventSource,
        ) => {
          if (api && map && element.current) {
            const size = getSize(element.current);
            const zc = fitBounds(bounds, size, padding);

            if (zc) {
              const center =
                offset == null
                  ? zc.center
                  : getPanByCoordinates(zc.center, offset, zc.zoom);
              eventSourceRef.current = source != null ? source : 'auto';
              map.setZoom(zc.zoom);
              map.panTo(center);
            }
          }
        },
      }),
      [api, map, offset],
    );

    React.useEffect(() => {
      onBoundsChangedRef.current = props.onBoundsChanged;
      onIdleRef.current = props.onIdle;
      onClickRef.current = props.onClick;
    }, [props]);

    const isInDragRef = React.useRef(false);

    React.useEffect(() => {
      // Don't init non displayed map,
      // offsetParent == null for all elements under display: none element
      const displayed =
        element.current != null && element.current.offsetParent != null;

      if (element.current && api && displayed) {
        let zoom = defaultZoom;
        let center = defaultCenter;

        if (defaultBbox != null) {
          const size = getSize(element.current);
          // we use our fitBounds as it's better suited instead of panToBounds
          const zc = fitBounds(defaultBbox, size, defaultPadding);

          if (zc != null) {
            zoom = zc.zoom;
            center = zc.center;
          }
        }

        if (initialOffset != null && center != null && zoom != null) {
          center = getPanByCoordinates(center, initialOffset, zoom);
        }

        const lmap = new api.Map(element.current, {
          mapTypeControl: true,
          rotateControlOptions: {
            position: api.ControlPosition.LEFT_TOP,
          },
          streetViewControl: true,
          ...defaultOptions,
          // assume either bounding box or center+zoom is set
          // $FlowFixMe[incompatible-call]
          center,
          // $FlowFixMe[incompatible-call]
          zoom,
        });

        let animFrameHandleMain = null;
        let animFrameHandleSecondary = null;

        const boundsChangedListener = lmap.addListener('bounds_changed', () => {
          const onBoundsChanged = onBoundsChangedRef.current;

          window.cancelAnimationFrame(animFrameHandleMain);
          window.cancelAnimationFrame(animFrameHandleSecondary);

          if (onBoundsChanged != null) {
            const bounds = lmap.getBounds();
            const ne = toLatLngLiteral(bounds.getNorthEast());
            const sw = toLatLngLiteral(bounds.getSouthWest());
            const center = toLatLngLiteral(bounds.getCenter());
            const zoom = lmap.getZoom();
            onBoundsChanged({
              bounds: { sw, ne },
              center,
              zoom,
              source: eventSourceRef.current,
            });
          }
        });

        const idleListener = lmap.addListener('idle', () => {
          // For some gmap realisation reasons, on a slow machines it calls idle before last drawings occured
          // but for same unknown reason double raf solves the issue
          // same trick was used by me few years ago in google-map-react - nothing changed since that time
          animFrameHandleMain = window.requestAnimationFrame(() => {
            animFrameHandleSecondary = window.requestAnimationFrame(() => {
              if (onIdleRef.current != null) {
                onIdleRef.current();
                eventSourceRef.current = 'user';
              }
            });
          });
        });

        const onClickListener = lmap.addListener('click', (event: any) => {
          if (event != null && event.latLng != null) {
            const latLng = toLatLngLiteral(event.latLng);
            if (onClickRef.current != null) {
              onClickRef.current(latLng);
            }
          }
        });

        const dragStartListener = lmap.addListener('dragstart', () => {
          isInDragRef.current = true;
        });

        const dragEndListener = lmap.addListener('dragend', () => {
          isInDragRef.current = false;
        });

        setMap(lmap);
        return () => {
          boundsChangedListener.remove();
          idleListener.remove();
          onClickListener.remove();
          dragStartListener.remove();
          dragEndListener.remove();

          window.cancelAnimationFrame(animFrameHandleMain);
          window.cancelAnimationFrame(animFrameHandleSecondary);
        };
      }
    }, [
      api,
      defaultCenter,
      defaultZoom,
      defaultOptions,
      defaultBbox,
      defaultPadding,
      initialOffset,
    ]);

    React.useEffect(() => {
      if (api && map) {
        map.setOptions({
          tilt,
          mapTypeId,
        });
      }
    }, [api, map, tilt, mapTypeId]);

    React.useEffect(() => {
      if (map && centerLat != null && centerLng != null) {
        eventSourceRef.current = 'auto';
        map.panTo({ lat: centerLat, lng: centerLng });
      }
    }, [map, centerLat, centerLng]);

    const prevOffsetRef = React.useRef(offset);

    React.useEffect(() => {
      if (api && map && !shallowEqualObjects(prevOffsetRef.current, offset)) {
        const toOffset = { x: offset?.x ?? 0, y: offset?.y ?? 0 };
        const prevOffset = {
          x: prevOffsetRef.current?.x ?? 0,
          y: prevOffsetRef.current?.y ?? 0,
        };

        if (!isInDragRef.current) {
          eventSourceRef.current = 'auto';
          map.panBy(toOffset.x - prevOffset.x, toOffset.y - prevOffset.y);
        }

        prevOffsetRef.current = offset;
      }
    }, [api, map, offset]);

    React.useEffect(() => {
      if (api && map && options != null) {
        map.setOptions(options);
      }
    }, [api, map, options]);

    return (
      <MapContext.Provider value={map}>
        <div style={{ width: '100%', height: '100%' }} ref={element} />
        {map && props.children}
      </MapContext.Provider>
    );
  });

type MarkerProps = {|
  lat: number,
  lng: number,
|};

export const GoogleMarker = ({ lat, lng }: MarkerProps): React.Node => {
  const api = useGoogleApi();
  const map = React.useContext(MapContext);

  React.useEffect(() => {
    if (api) {
      const marker = new api.Marker({
        map,
        position: {
          lat,
          lng,
        },
      });

      return () => {
        marker.setMap(null);
      };
    }
  }, [api, map, lat, lng]);

  return null;
};

type ReactMarkerProps = {|
  lat: number,
  lng: number,
  children: React.Node,
  // We need this properties because justify-self is not working for now instead of align-self
  // so we can't align marker itself
  alignItems?: Property$AlignItems,
  justifyContent?: Property$JustifyContent,
|};

export const ReactMarker = (props: ReactMarkerProps): React.Node => {
  return props.children;
};

type OverlayProps = {|
  children?: React.ChildrenArray<React.Element<typeof ReactMarker>>,
|};

export const Overlay = ({ children }: OverlayProps): React.Node => {
  const api = useGoogleApi();
  const map = React.useContext(MapContext);
  const [overlay, setOverlay] = React.useState(null);

  const childrenLatLngRefs = React.useRef([]);

  const childrenDivRefs = React.useRef(
    Array.from(Array(React.Children.count(children)), () => ({
      current: null,
    })),
  );

  // this doesn't change existing data in array, but just extends array with new cells
  // we can't use useEffect for this as references defined before
  // BTW this doesnt affect execution flow, just somekind of cache
  if (childrenDivRefs.current.length < React.Children.count(children)) {
    childrenDivRefs.current.push(
      ...Array.from(
        Array(React.Children.count(children) - childrenDivRefs.current.length),
        () => ({ current: null }),
      ),
    );
  }

  // We can't use useEffect here because it causes glitches
  // when in draw we update commited markers with previous markers coordinates
  // it can be visible if make a lot of zoomin zoomout
  React.useLayoutEffect(() => {
    childrenLatLngRefs.current = React.Children.map(
      children != null ? children : [],
      (ch: React.Element<typeof ReactMarker>) => ({
        lat: ch.props.lat,
        lng: ch.props.lng,
      }),
    );
  });

  React.useEffect(() => {
    if (api) {
      const overlayView = new api.OverlayView();
      let elt = null;

      overlayView.onAdd = () => {
        elt = document.createElement('div');
        elt.style.position = 'absolute';
        elt.style.width = '0';
        elt.style.height = '0';
        elt.style.left = '0';
        elt.style.top = '0';

        var panes = overlayView.getPanes();
        // on all other panes there is issues with events like hover etc
        panes.floatPane.appendChild(elt);

        setOverlay({
          element: elt,
          view: overlayView,
        });
      };

      overlayView.onRemove = () => {
        if (elt != null) {
          const { parentNode } = elt;
          if (parentNode != null) {
            // same as panes.overlayMouseTarget.removeChild
            parentNode.removeChild(elt);
          }
          setOverlay(null);
        }
      };

      overlayView.draw = () => {
        var projection = overlayView.getProjection();
        const latLngs = childrenLatLngRefs.current;
        if (latLngs != null) {
          latLngs.forEach(({ lat, lng }, index) => {
            const { current: childElt } = childrenDivRefs.current[index];
            if (childElt != null) {
              const pos = projection.fromLatLngToDivPixel(
                new api.LatLng(lat, lng),
              );
              childElt.style.left = pos.x + 'px';
              childElt.style.top = pos.y + 'px';
            }
          });
        }
      };

      overlayView.setMap(map);

      // Can be highly optimized in case of lat lng changed frequently,
      // no need otherwise
      return () => {
        overlayView.setMap(null);
      };
    }
  }, [api, map]);

  if (overlay != null && children != null && api != null) {
    const projection = overlay.view.getProjection();

    return ReactDOM.createPortal(
      React.Children.map(
        children,
        (ch: React.Element<typeof ReactMarker>, index) => {
          const pos = projection.fromLatLngToDivPixel(
            new api.LatLng(ch.props.lat, ch.props.lng),
          );
          return (
            <div
              ref={childrenDivRefs.current[index]}
              style={{
                position: 'absolute',
                left: pos.x,
                top: pos.y,
                width: 0,
                height: 0,
                display: 'flex',
                justifyContent:
                  ch.props.justifyContent != null
                    ? ch.props.justifyContent
                    : 'center',
                alignItems:
                  ch.props.alignItems != null ? ch.props.alignItems : 'center',
              }}
            >
              {ch}
            </div>
          );
        },
      ),
      overlay.element,
    );
  }
  return null;
};

type GeoJsonProps = {|
  data: GeoJSON,
  style?: {|
    ...StyleOptions,
    hover?: StyleOptions,
    active?: StyleOptions,
  |},
  activeId?: null | string,
|};

export const GeoJson = ({
  data,
  style,
  activeId,
}: GeoJsonProps): React.Node => {
  const map = React.useContext(MapContext);
  const featuresRef = React.useRef(null);

  React.useEffect(() => {
    if (map) {
      // Here is the gmap hack, to prevent style blink we can override style inside
      // addfeature listener, otherwise it will not work
      const addFeatureListener = map.data.addListener('addfeature', event => {
        if (style != null) {
          const { hover, active, ...baseStyle } = style;
          map.data.overrideStyle(event.feature, baseStyle);
        }
      });

      const features = map.data.addGeoJson(data);

      addFeatureListener.remove();

      featuresRef.current = features;

      const cleanFeatures = () => {
        features.forEach(feature => map.data.remove(feature));
        featuresRef.current = null;
      };

      if (style?.hover == null) {
        return cleanFeatures;
      }

      const handleMouseOver = event => {
        if (!features.includes(event.feature)) {
          return;
        }

        if (style != null && style.hover != null) {
          const { hover, active, ...baseStyle } = style;

          map.data.revertStyle(event.feature);
          map.data.overrideStyle(event.feature, baseStyle);
          map.data.overrideStyle(event.feature, hover);
        }
      };

      const handleMouseOut = event => {
        if (!features.includes(event.feature)) {
          return;
        }

        if (style != null && style.hover != null) {
          const { hover, active, ...baseStyle } = style;

          map.data.revertStyle(event.feature);
          map.data.overrideStyle(event.feature, baseStyle);
        }
      };

      const mouseOverListener = map.data.addListener(
        'mouseover',
        handleMouseOver,
      );
      const mouseOutListener = map.data.addListener('mouseout', handleMouseOut);

      return () => {
        cleanFeatures();
        mouseOverListener.remove();
        mouseOutListener.remove();
      };
    }
  }, [map, data, style]);

  React.useEffect(() => {
    const { current: features } = featuresRef;

    if (activeId != null && features != null) {
      const feature = features.find(f => f.getId() === activeId);

      if (feature != null) {
        if (map != null && style != null && style.active != null) {
          const { hover, active, ...baseStyle } = style;

          map.data.revertStyle(feature);
          map.data.overrideStyle(feature, baseStyle);
          map.data.overrideStyle(feature, active);

          return () => {
            map.data.revertStyle(feature);
            map.data.overrideStyle(feature, baseStyle);
          };
        }
      }
    }
  }, [activeId, featuresRef, map, style]);

  return null;
};
