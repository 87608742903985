/**
 * @generated SignedSource<<38aa9f2b5b8d4b44bc1381a997cc5e4c>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type PipelineLeadType = "buyer" | "financing" | "sales";
export type PropertyMainType = "AGRI" | "APPT" | "BUILDING" | "COMMERCIAL" | "GARDEN" | "GASTRO" | "HOUSE" | "HOUSE_APPT" | "INDUS" | "OTHER" | "PARK" | "PROP" | "ROOM" | "SECONDARY";
export type SubscriptionStatus = "active" | "canceled" | "incomplete" | "incomplete_expired" | "past_due" | "trialing" | "unpaid";
export type LeadsSearchFilters = {|
  brokerId_in?: ?$ReadOnlyArray<string>,
  completed_in?: ?$ReadOnlyArray<boolean>,
  contactId_in?: ?$ReadOnlyArray<string>,
  hasProperty?: ?boolean,
  property_propertyType_mainType_in?: ?$ReadOnlyArray<PropertyMainType>,
  search?: ?string,
  stage_pipeline_leadType_in?: ?$ReadOnlyArray<PipelineLeadType>,
  unassignedLeads?: ?boolean,
|};
export type leadInputQuery$variables = {|
  filters?: ?LeadsSearchFilters,
|};
export type leadInputQuery$data = {|
  +leadsSearch: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +broker: ?{|
          +firstName: ?string,
          +id: string,
          +lastName: ?string,
          +organisation: ?{|
            +formattedAddress: ?string,
            +name: ?string,
          |},
          +primaryEmail: ?{|
            +email: string,
          |},
          +primaryImage: ?{|
            +url: string,
          |},
          +subscription: ?{|
            +status: SubscriptionStatus,
          |},
        |},
        +contact: ?{|
          +firstName: ?string,
          +id: string,
          +lastName: ?string,
          +organisation: ?{|
            +formattedAddress: ?string,
            +name: ?string,
          |},
          +primaryEmail: ?{|
            +email: string,
          |},
          +primaryImage: ?{|
            +url: string,
          |},
          +subscription: ?{|
            +status: SubscriptionStatus,
          |},
        |},
        +id: string,
        +property: ?{|
          +formattedAddress: ?string,
        |},
      |},
    |}>,
  |},
|};
export type leadInputQuery = {|
  response: leadInputQuery$data,
  variables: leadInputQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "formattedAddress",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v10 = [
  (v2/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Email",
    "kind": "LinkedField",
    "name": "primaryEmail",
    "plural": false,
    "selections": [
      (v6/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Image",
    "kind": "LinkedField",
    "name": "primaryImage",
    "plural": false,
    "selections": [
      (v7/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Organisation",
    "kind": "LinkedField",
    "name": "organisation",
    "plural": false,
    "selections": [
      (v8/*: any*/),
      (v3/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Subscription",
    "kind": "LinkedField",
    "name": "subscription",
    "plural": false,
    "selections": [
      (v9/*: any*/)
    ],
    "storageKey": null
  }
],
v11 = [
  (v2/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Email",
    "kind": "LinkedField",
    "name": "primaryEmail",
    "plural": false,
    "selections": [
      (v6/*: any*/),
      (v2/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Image",
    "kind": "LinkedField",
    "name": "primaryImage",
    "plural": false,
    "selections": [
      (v7/*: any*/),
      (v2/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Organisation",
    "kind": "LinkedField",
    "name": "organisation",
    "plural": false,
    "selections": [
      (v8/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Subscription",
    "kind": "LinkedField",
    "name": "subscription",
    "plural": false,
    "selections": [
      (v9/*: any*/),
      (v2/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "leadInputQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LeadConnectionConnection",
        "kind": "LinkedField",
        "name": "leadsSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LeadConnectionEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Lead",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Property",
                    "kind": "LinkedField",
                    "name": "property",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "contact",
                    "plural": false,
                    "selections": (v10/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "broker",
                    "plural": false,
                    "selections": (v10/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "leadInputQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LeadConnectionConnection",
        "kind": "LinkedField",
        "name": "leadsSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LeadConnectionEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Lead",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Property",
                    "kind": "LinkedField",
                    "name": "property",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "contact",
                    "plural": false,
                    "selections": (v11/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "broker",
                    "plural": false,
                    "selections": (v11/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "18890a4179e4ea134c0d5df30644598f",
    "id": null,
    "metadata": {},
    "name": "leadInputQuery",
    "operationKind": "query",
    "text": "query leadInputQuery(\n  $filters: LeadsSearchFilters\n) {\n  leadsSearch(first: 20, filters: $filters) {\n    edges {\n      node {\n        id\n        property {\n          formattedAddress\n          id\n        }\n        contact {\n          id\n          firstName\n          lastName\n          primaryEmail {\n            email\n            id\n          }\n          primaryImage {\n            url\n            id\n          }\n          organisation {\n            name\n            formattedAddress\n            id\n          }\n          subscription {\n            status\n            id\n          }\n        }\n        broker {\n          id\n          firstName\n          lastName\n          primaryEmail {\n            email\n            id\n          }\n          primaryImage {\n            url\n            id\n          }\n          organisation {\n            name\n            formattedAddress\n            id\n          }\n          subscription {\n            status\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "14ee235b0d41d8373c18cee828f1a95b";

export default node;
