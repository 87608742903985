/**
 * @generated SignedSource<<7312f33c016f0b5085e17d98ac3701b0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeedCall_root$data = {
  readonly " $fragmentSpreads": FragmentRefs<"activityWorkflowDialog_root">;
  readonly " $fragmentType": "FeedCall_root";
};
export type FeedCall_root$key = {
  readonly " $data"?: FeedCall_root$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedCall_root">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedCall_root",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "activityWorkflowDialog_root"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "fbd705a7dc80aab80d59cf954e615365";

export default node;
