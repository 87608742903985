import * as React from 'react';

import { Checkbox, FormControlLabel } from '@material-ui/core';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { Box, Flex } from 'react-system';

import { Filter } from '../controls/Filters';
import { type Translate, useLocale } from '../hooks/locale';
import { type MapHook, useMap } from '../hooks/map';
import {
  type Field,
  makeField,
  makeUrlSearchParamsHook,
} from '../hooks/url-search-params';

import type {
  PropertyTypeFilterQuery,
  PropertyTypeFilterQuery$data,
} from './__generated__/PropertyTypeFilterQuery.graphql';

export type PropertyTypeParams = {
  propertyType_name_in: Field<string[] | null>;
};

type PropertyType = NonNullable<
  PropertyTypeFilterQuery$data['propertyTypes']
>[number];

export const propertyTypeParams: PropertyTypeParams = {
  propertyType_name_in: makeField({
    get: params => {
      const list = params.getAllStrings('propertyType_name_in');
      return list.length === 0 ? null : list;
    },
    set: (params, value) =>
      params.setAllStrings('propertyType_name_in', value ?? []),
  }),
};

const usePropertyTypeParams =
  makeUrlSearchParamsHook<PropertyTypeParams>(propertyTypeParams);

const Heading = ({ children }: { children: React.ReactNode }) => (
  <div
    css={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '1rem', cursor: 'default' }}
  >
    {children}
  </div>
);

const getLabel = (
  t: Translate,
  names: Set<string>,
  labels: MapHook<string>,
) => {
  if (names.size === 0) {
    return t('propertyTypes');
  }
  const first = Array.from(names)
    .map(name => labels.get(name))
    .find(name => name != null);
  if (first != null) {
    return names.size > 1 ? `${first} +${names.size - 1}` : first;
  }
  return t('propertyTypesSelected', { count: names.size });
};

const PropertyTypeContent = ({
  selected,
  cachedLabel,
  parking,
}: {
  selected: Set<string>;
  cachedLabel: MapHook<string>;
  parking: boolean;
}) => {
  const data = useLazyLoadQuery<PropertyTypeFilterQuery>(
    graphql`
      query PropertyTypeFilterQuery {
        propertyTypes {
          name
          label
        }
      }
    `,
    {},
  );
  const propertyTypes: readonly PropertyType[] = data.propertyTypes ?? [];

  const { t } = useLocale();
  const [, setParams] = usePropertyTypeParams();

  const HOUSE_NAMES = new Set([
    'house_detached',
    'house_semi_detached',
    'house_attached_end',
    'house_attached_middle',
  ]);
  const HOUSE_OPTIONS = propertyTypes.filter(
    type => type.name && HOUSE_NAMES.has(type.name),
  );

  const APARTMENT_NAMES = new Set([
    'apartment',
    'apartment_penthouse',
    'apartment_duplex',
    'apartment_penthouse_duplex',
  ]);
  const APARTMENT_OPTIONS = propertyTypes.filter(
    type => type.name && APARTMENT_NAMES.has(type.name),
  );

  const PARKING_NAMES = new Set([
    'park_outdoor',
    'park_covered',
    'park_single_garage',
  ]);
  const PARKING_OPTIONS = propertyTypes.filter(
    type => type.name && PARKING_NAMES.has(type.name),
  );

  const LAND_NAMES = new Set([
    'prop_building_land',
    'prop_agricultural_land',
    'prop_commercial_land',
    'prop_industrial_land',
  ]);
  const LAND_OPTIONS = propertyTypes.filter(
    type => type.name && LAND_NAMES.has(type.name),
  );

  const BUILDING_NAMES = new Set([
    'house_multiple_dwelling',
    'indus_commercial_and_residential',
    'indus_commercial',
  ]);
  const BUILDING_OPTIONS = propertyTypes.filter(
    type => type.name && BUILDING_NAMES.has(type.name),
  );

  const toggle = ({ name, label }: PropertyType) => {
    const newSelected = new Set(selected);
    if (name == null) {
      return;
    }
    if (newSelected.has(name)) {
      newSelected.delete(name);
    } else {
      newSelected.add(name);
    }
    if (label != null) {
      cachedLabel.set(name, label);
    }
    setParams({ propertyType_name_in: Array.from(newSelected) as string[] });
  };

  return (
    <Flex
      width={['280px', '400px']}
      p={3}
      flexWrap="wrap"
      css={{ maxHeight: 'calc(100vh - 240px)', overflow: 'auto' }}
    >
      <Box width={[1, 1 / 2]} mb={2}>
        <Heading>{t('houseType')}</Heading>

        {HOUSE_OPTIONS.map(type => (
          <FormControlLabel
            key={type.name}
            label={type.label}
            control={
              <Checkbox
                color="primary"
                checked={type.name != null ? selected.has(type.name) : false}
                onChange={() => toggle(type)}
              />
            }
          />
        ))}
      </Box>

      <Box width={[1, 1 / 2]} mb={2}>
        <Heading>{t('apartmentType')}</Heading>

        {APARTMENT_OPTIONS.map(type => (
          <FormControlLabel
            key={type.name}
            label={type.label}
            control={
              <Checkbox
                color="primary"
                checked={type.name != null ? selected.has(type.name) : false}
                onChange={() => toggle(type)}
              />
            }
          />
        ))}
      </Box>

      {parking && (
        <Box width={[1, 1 / 2]} mb={2}>
          <Heading>{t('parkingType')}</Heading>

          {PARKING_OPTIONS.map(type => (
            <FormControlLabel
              key={type.name}
              label={type.label}
              control={
                <Checkbox
                  color="primary"
                  checked={type.name != null ? selected.has(type.name) : false}
                  onChange={() => toggle(type)}
                />
              }
            />
          ))}
        </Box>
      )}

      <Box width={[1, 1 / 2]} mb={2}>
        <Heading>{t('landType')}</Heading>

        {LAND_OPTIONS.map(type => (
          <FormControlLabel
            key={type.name}
            label={type.label}
            control={
              <Checkbox
                color="primary"
                checked={type.name != null ? selected.has(type.name) : false}
                onChange={() => toggle(type)}
              />
            }
          />
        ))}
      </Box>

      <Box width={[1, 1 / 2]} mb={2}>
        <Heading>{t('buildingType')}</Heading>

        {BUILDING_OPTIONS.map(type => (
          <FormControlLabel
            key={type.name}
            label={type.label}
            control={
              <Checkbox
                color="primary"
                checked={type.name != null ? selected.has(type.name) : false}
                onChange={() => toggle(type)}
              />
            }
          />
        ))}
      </Box>
    </Flex>
  );
};

export const PropertyTypeFilter = ({
  parking = false,
}: {
  parking?: boolean;
}) => {
  const { t } = useLocale();
  const [params, setParams] = usePropertyTypeParams();
  const cachedLabel = useMap<string>();

  const selected = new Set<string>(params.propertyType_name_in);

  return (
    <Filter
      label={getLabel(t, selected, cachedLabel)}
      dialogTitle={t('propertyTypes')}
      empty={params.propertyType_name_in == null}
      onReset={() => setParams({ propertyType_name_in: null })}
    >
      <React.Suspense
        fallback={<Box width={['280px', '400px']} height="100px" />}
      >
        <PropertyTypeContent
          selected={selected}
          cachedLabel={cachedLabel}
          parking={parking}
        />
      </React.Suspense>
    </Filter>
  );
};
