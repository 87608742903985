import { gql, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Alert, AlertTitle } from '@mui/material';
import { useParams } from 'react-router-dom';

import { useLocale } from '../../../src/hooks/locale';
import type { RestoreUserMutation } from '../../__generated__/graphql';

const RESTORE_USER = gql`
  mutation RestoreUser($id: uuid!) {
    update_users_by_pk(pk_columns: { id: $id }, _set: { is_deleted: false }) {
      id
      is_deleted
    }
  }
`;

export const DeletedUser = () => {
  const { t } = useLocale();
  const { userId } = useParams<{ userId: string }>();
  const [restoreUser, { loading }] =
    useMutation<RestoreUserMutation>(RESTORE_USER);

  return (
    <Alert severity="error" sx={{ m: 2, mb: 0 }}>
      <AlertTitle>{t('This user has been deleted')}</AlertTitle>
      <LoadingButton
        color="info"
        onClick={() => restoreUser({ variables: { id: userId } })}
        loading={loading}
      >
        {t('Restore user')}
      </LoadingButton>
    </Alert>
  );
};
