import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number;
  fill?: string;
  className?: string;
  css?: Interpolation<unknown>;
};

const style: Interpolation<unknown> = {
  display: 'block',
  flex: '0 0 auto',
};

export const KeyboardArrowRight = ({
  size,
  fill,
  className,
  css: cssProp,
}: Props) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      preserveAspectRatio="xMidYMid meet"
      fontSize={size}
      fill={fill ?? 'currentColor'}
      css={[style, cssProp]}
      className={className}
    >
      <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
      <path fill="none" d="M0 0h24v24H0V0z" />
    </svg>
  );
};
