// Generated from src/svgs/location-pin-outline.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const LocationPinOutline = ({ size, fill, className }: Props) => {
  return (
    <svg preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><path d="M.001.001h24v24.001h-24z" fill="none" /><circle cx="12" cy="9" r="2" /><path d="M12 23c-4.816 0-10-1.251-10-4 0-.954.648-2.284 3.734-3.201l.569 1.917C4.4 18.282 4 18.902 4 19c0 .515 2.75 2 8 2s8-1.485 8-2c0-.098-.398-.716-2.289-1.279l.57-1.917C21.355 16.719 22 18.047 22 19c0 2.749-5.184 4-10 4z" /><path d="m12 19.415-.707-.708C11.077 18.491 6 13.365 6 9c0-3.308 2.691-6 6-6s6 2.692 6 6c0 4.365-5.077 9.491-5.293 9.707zM12 5C9.794 5 8 6.794 8 9c0 2.498 2.459 5.798 4 7.536 1.541-1.738 4-5.039 4-7.536 0-2.206-1.794-4-4-4z" /></svg>
  );
}
