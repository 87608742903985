import * as React from 'react';

import type { DragEndArgs } from './kanban-dnd';

// This is common utilities for data management for a Kanban
// No UI/Markup or DnD functionality here

type PageMeta = { pageId: string; itemIds: string[] };

export type Column<Item, ColumnData> = {
  columnId: string;
  readyItems: Item[];
  loadingPage: null | PageMeta;
  idlePages: PageMeta[];
  data: ColumnData;
};

type PaginatedState<Item, ColumnData> = Column<Item, ColumnData>[];

const updateColumn = <Item, ColumnData>(
  columnId: string,
  updater: (column: Column<Item, ColumnData>) => Column<Item, ColumnData>,
) => {
  return (
    state: PaginatedState<Item, ColumnData>,
  ): PaginatedState<Item, ColumnData> =>
    state.map(column =>
      columnId === column.columnId ? updater(column) : column,
    );
};

const updateOrRemoveItem = <Item, ColumnData>(
  id: string,
  updatedItem: Item | null,
  getItemId: (item: Item) => string,
) => {
  return (
    state: PaginatedState<Item, ColumnData>,
  ): PaginatedState<Item, ColumnData> => {
    return state.map(column => {
      const index = column.readyItems.findIndex(item => getItemId(item) === id);
      return index === -1
        ? column
        : {
            ...column,
            readyItems:
              updatedItem == null
                ? [
                    ...column.readyItems.slice(0, index),
                    ...column.readyItems.slice(index + 1),
                  ]
                : [
                    ...column.readyItems.slice(0, index),
                    updatedItem,
                    ...column.readyItems.slice(index + 1),
                  ],
          };
    });
  };
};

const moveItem = <Item, ColumnData>(
  args: {
    itemId: string;
    srcColumnId: string;
    destColumnId: string;
    destIndex: number;
  },
  getItemId: (item: Item) => string,
  updateItemAfterMove: null | ((item: Item, columnId: string) => Item),
) => {
  return (
    state: PaginatedState<Item, ColumnData>,
  ): PaginatedState<Item, ColumnData> => {
    const { itemId, srcColumnId, destColumnId, destIndex } = args;
    const srcColumn = state.find(column => column.columnId === srcColumnId);

    // for flow
    if (srcColumn == null) {
      return state;
    }

    const targetItemIndex = srcColumn.readyItems.findIndex(
      item => getItemId(item) === itemId,
    );

    const targetItem = srcColumn.readyItems[targetItemIndex];

    const state2 = updateColumn<Item, ColumnData>(
      srcColumn.columnId,
      column => ({
        ...column,
        readyItems: [
          ...column.readyItems.slice(0, targetItemIndex),
          ...column.readyItems.slice(targetItemIndex + 1),
        ],
      }),
    )(state);

    const destColumn = state2.find(column => column.columnId === destColumnId);

    // for flow
    if (destColumn == null) {
      return state;
    }

    const finalItem =
      updateItemAfterMove == null
        ? targetItem
        : updateItemAfterMove(targetItem, destColumn.columnId);

    return updateColumn<Item, ColumnData>(destColumn.columnId, column => ({
      ...column,
      readyItems: [
        ...column.readyItems.slice(0, destIndex),
        finalItem,
        ...column.readyItems.slice(destIndex),
      ],
    }))(state2);
  };
};

export type PaginatedStateHook<Item, ColumnData> = {
  state: PaginatedState<Item, ColumnData>;
  loadPage: (targetPage: PageMeta) => void;
  onDragEnd: (args: DragEndArgs) => void;
  removeItem: (itemId: string) => void;
  updateItem: (updatedItem: Item) => void;
  moveItem: (itemId: string, destColumnId: string, destIndex?: number) => void;
};

// Responsibilities of this hook:
//   - store items data split into columns and pages within columns
//   - load pages by request
//   - move items between pages locally after drag and drop
export const useKanbanPaginatedState = <Item, ColumnData>({
  getInitialState,
  getItemId,
  updateItemAfterMove,
  loadItems,
  afterDragEnd,
}: {
  getInitialState: () => PaginatedState<Item, ColumnData>;
  loadItems: (itemsId: string[]) => Promise<Item[]>;
  getItemId: (item: Item) => string;
  updateItemAfterMove: null | ((item: Item, columnId: string) => Item);
  afterDragEnd: (
    args: DragEndArgs,
    updatedState: PaginatedState<Item, ColumnData>,
  ) => void;
}): PaginatedStateHook<Item, ColumnData> => {
  const [state, setState] =
    React.useState<PaginatedState<Item, ColumnData>>(getInitialState);

  const isMounted = React.useRef(false);
  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const loadPage = (targetPage: PageMeta) => {
    const targetColumn = state.find(column =>
      column.idlePages.some(page => page.pageId === targetPage.pageId),
    );

    if (targetColumn == null || targetColumn.loadingPage != null) {
      return;
    }

    setState(
      updateColumn(targetColumn.columnId, column => ({
        ...column,
        loadingPage: targetPage,
        idlePages: column.idlePages.filter(
          page => page.pageId !== targetPage.pageId,
        ),
      })),
    );

    loadItems(targetPage.itemIds).then(
      items => {
        if (!isMounted.current) {
          return;
        }

        const sortedItems = targetPage.itemIds.flatMap(id => {
          const item = items.find(item => getItemId(item) === id);
          return item ? [item] : [];
        });
        setState(
          updateColumn<Item, ColumnData>(targetColumn.columnId, column => ({
            ...column,
            readyItems: [...column.readyItems, ...sortedItems],
            loadingPage: null,
          })),
        );
      },
      err => {
        if (!isMounted.current) {
          return;
        }

        setState(
          updateColumn<Item, ColumnData>(targetColumn.columnId, column => ({
            ...column,
            loadingPage: null,
            idlePages: [targetPage, ...column.idlePages],
          })),
        );
        console.error(err);
      },
    );
  };

  const memoized = React.useMemo(
    () => ({
      removeItem: (itemId: string) => {
        setState(updateOrRemoveItem(itemId, null, getItemId));
      },
      updateItem: (updatedItem: Item) => {
        setState(
          updateOrRemoveItem(getItemId(updatedItem), updatedItem, getItemId),
        );
      },
      moveItem: (itemId: string, destColumnId: string, destIndex = 0) => {
        setState(state => {
          const srcColumn = state.find(column =>
            column.readyItems.some(item => getItemId(item) === itemId),
          );
          return srcColumn == null
            ? state
            : moveItem<Item, ColumnData>(
                {
                  itemId,
                  destColumnId,
                  destIndex,
                  srcColumnId: srcColumn.columnId,
                },
                getItemId,
                updateItemAfterMove,
              )(state);
        });
      },
    }),
    [getItemId, setState, updateItemAfterMove],
  );

  return {
    state,
    loadPage,
    onDragEnd: (args: DragEndArgs) => {
      const { itemId, srcColumnId, destColumnId, destIndex } = args;
      const newState = moveItem<Item, ColumnData>(
        { itemId, srcColumnId, destColumnId, destIndex },
        getItemId,
        updateItemAfterMove,
      )(state);
      setState(newState);
      afterDragEnd(args, newState);
    },
    ...memoized,
  };
};
