import { type Duration, intervalToDuration, parseISO } from 'date-fns';

import { type Translate } from '../../src/hooks/locale';

const getFirstChar = (str: null | string) => str?.trim()[0] ?? '';

export const getAbbr = (firstName: null | string, lastName: null | string) => {
  if (firstName == null && lastName == null) {
    return null;
  }
  return (getFirstChar(firstName) + getFirstChar(lastName)).toUpperCase();
};

export const getAbbrTimeAgo = (date: string, t: Translate): string => {
  const duration = intervalToDuration({
    start: new Date(),
    end: parseISO(date),
  });

  const unitLabelMap: Record<keyof Duration, string> = {
    years: t('yr'),
    months: t('mo'),
    weeks: t('wk'),
    days: t('d'),
    hours: t('h'),
    minutes: t('m'),
    seconds: t('now'),
  };

  const units = Object.keys(unitLabelMap) as (keyof Duration)[];

  for (const unit of units) {
    const unitValue = duration[unit];

    if (unitValue != null && unitValue > 0) {
      return unit === 'seconds'
        ? unitLabelMap[unit]
        : `${duration[unit]} ${unitLabelMap[unit]}`;
    }
  }

  return '';
};
