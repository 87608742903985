/**
 * @generated SignedSource<<481e9f1b13aec70c5215718625989f52>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EmailVisibilityTypeEnum = "organisation" | "private" | "shared";
export type SubscriptionStatus = "active" | "canceled" | "incomplete" | "incomplete_expired" | "past_due" | "trialing" | "unpaid";
import { FragmentRefs } from "relay-runtime";
export type ActivityEventForm_activity$data = {
  readonly assignedTo?: {
    readonly firstName: string | null;
    readonly id: string;
    readonly lastName: string | null;
    readonly organisation: {
      readonly formattedAddress: string | null;
      readonly name: string | null;
    } | null;
    readonly primaryEmail: {
      readonly email: string;
    } | null;
    readonly primaryImage: {
      readonly url: string;
    } | null;
    readonly subscription: {
      readonly status: SubscriptionStatus;
    } | null;
  } | null;
  readonly canEditVisibility: boolean;
  readonly createdBy?: {
    readonly firstName: string | null;
    readonly id: string;
    readonly lastName: string | null;
    readonly organisation: {
      readonly formattedAddress: string | null;
      readonly name: string | null;
    } | null;
    readonly primaryEmail: {
      readonly email: string;
    } | null;
    readonly primaryImage: {
      readonly url: string;
    } | null;
    readonly subscription: {
      readonly status: SubscriptionStatus;
    } | null;
  } | null;
  readonly displayParticipants?: ReadonlyArray<{
    readonly email: string | null;
    readonly name: string | null;
    readonly user: {
      readonly firstName: string | null;
      readonly id: string;
      readonly lastName: string | null;
      readonly organisation: {
        readonly formattedAddress: string | null;
        readonly name: string | null;
      } | null;
      readonly primaryEmail: {
        readonly email: string;
      } | null;
      readonly primaryImage: {
        readonly url: string;
      } | null;
      readonly subscription: {
        readonly status: SubscriptionStatus;
      } | null;
    } | null;
  }> | null;
  readonly done?: boolean;
  readonly dueAt?: string | null;
  readonly endAt?: string | null;
  readonly endDate?: string | null;
  readonly id: string;
  readonly location?: string | null;
  readonly note?: string | null;
  readonly owner?: {
    readonly email: string | null;
  } | null;
  readonly startDate?: string | null;
  readonly subject?: string | null;
  readonly success?: boolean;
  readonly visibility: EmailVisibilityTypeEnum | null;
  readonly " $fragmentType": "ActivityEventForm_activity";
};
export type ActivityEventForm_activity$key = {
  readonly " $data"?: ActivityEventForm_activity$data;
  readonly " $fragmentSpreads": FragmentRefs<"ActivityEventForm_activity">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "note",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subject",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "location",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dueAt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v7 = [
  (v6/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "firstName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lastName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Email",
    "kind": "LinkedField",
    "name": "primaryEmail",
    "plural": false,
    "selections": (v7/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Image",
    "kind": "LinkedField",
    "name": "primaryImage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Organisation",
    "kind": "LinkedField",
    "name": "organisation",
    "plural": false,
    "selections": [
      (v8/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "formattedAddress",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Subscription",
    "kind": "LinkedField",
    "name": "subscription",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "createdBy",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "assignedTo",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "ActivityParticipant",
  "kind": "LinkedField",
  "name": "displayParticipants",
  "plural": true,
  "selections": [
    (v8/*: any*/),
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": (v9/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "ActivityParticipant",
  "kind": "LinkedField",
  "name": "owner",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "done",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v16 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  (v10/*: any*/),
  (v11/*: any*/),
  (v12/*: any*/),
  (v13/*: any*/),
  (v14/*: any*/),
  (v15/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActivityEventForm_activity",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "visibility",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canEditVisibility",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v16/*: any*/),
      "type": "ActivityTask",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v16/*: any*/),
      "type": "ActivityCall",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endAt",
          "storageKey": null
        },
        (v10/*: any*/),
        (v11/*: any*/),
        (v12/*: any*/),
        (v13/*: any*/),
        (v14/*: any*/),
        (v15/*: any*/)
      ],
      "type": "ActivityVisit",
      "abstractKey": null
    }
  ],
  "type": "Activity",
  "abstractKey": "__isActivity"
};
})();

(node as any).hash = "f59b60e99f2db386ebaf7218844b2114";

export default node;
