/**
 * @generated SignedSource<<49d135926dbf4c6dad537e05083ccc9b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UserGender = "female" | "male";
export type UserTitle = "doctor" | "madam" | "professor" | "sir";
import { FragmentRefs } from "relay-runtime";
export type FeedEmailForm_enquiry$data = {
  readonly __typename: "Enquiry";
  readonly id: string;
  readonly lot: {
    readonly broker: {
      readonly firstName: string | null;
      readonly gender: UserGender | null;
      readonly id: string;
      readonly lastName: string | null;
      readonly organisation: {
        readonly formattedAddress: string | null;
        readonly id: string;
        readonly name: string | null;
      } | null;
      readonly primaryEmail: {
        readonly email: string;
        readonly id: string;
      } | null;
      readonly primaryPhoneNumber: {
        readonly formattedNumber: string;
        readonly id: string;
      } | null;
      readonly title: UserTitle | null;
    } | null;
    readonly enquiryResponseTemplate: {
      readonly " $fragmentSpreads": FragmentRefs<"EmailForm_initialTemplate">;
    } | null;
    readonly id: string;
    readonly lead: {
      readonly cmaReports: ReadonlyArray<{
        readonly docTemplate: {
          readonly id: string;
        } | null;
        readonly id: string;
        readonly updatedAt: string;
      }> | null;
      readonly id: string;
    } | null;
    readonly property: {
      readonly landSurface: number | null;
      readonly latestAppraisal: {
        readonly realadvisor: {
          readonly max: number | null;
          readonly min: number | null;
          readonly value: number | null;
        } | null;
      } | null;
      readonly livingSurface: number | null;
      readonly locality: string | null;
      readonly municipalityObject: {
        readonly label: string;
      } | null;
      readonly numberOfRooms: number | null;
      readonly postcode: string | null;
      readonly propertyType: {
        readonly label: string | null;
      } | null;
      readonly route: string | null;
      readonly state: string | null;
      readonly streetNumber: string | null;
    };
    readonly rentExtra: number | null;
    readonly rentNet: number | null;
    readonly salePrice: number | null;
    readonly title: string | null;
  } | null;
  readonly responseActivity: {
    readonly __typename: "ActivityEmail";
  } | null;
  readonly user: {
    readonly id: string;
  } | null;
  readonly " $fragmentType": "FeedEmailForm_enquiry";
};
export type FeedEmailForm_enquiry$key = {
  readonly " $data"?: FeedEmailForm_enquiry$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedEmailForm_enquiry">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "label",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedEmailForm_enquiry",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ActivityEmail",
      "kind": "LinkedField",
      "name": "responseActivity",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Lot",
      "kind": "LinkedField",
      "name": "lot",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Lead",
          "kind": "LinkedField",
          "name": "lead",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "CmaReport",
              "kind": "LinkedField",
              "name": "cmaReports",
              "plural": true,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "updatedAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "DocTemplate",
                  "kind": "LinkedField",
                  "name": "docTemplate",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v1/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rentNet",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rentExtra",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "salePrice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "broker",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "gender",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Email",
              "kind": "LinkedField",
              "name": "primaryEmail",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "email",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PhoneNumber",
              "kind": "LinkedField",
              "name": "primaryPhoneNumber",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "formattedNumber",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Organisation",
              "kind": "LinkedField",
              "name": "organisation",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "formattedAddress",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v3/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Property",
          "kind": "LinkedField",
          "name": "property",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "route",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "postcode",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "streetNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "locality",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "state",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PropertyType",
              "kind": "LinkedField",
              "name": "propertyType",
              "plural": false,
              "selections": (v4/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Place",
              "kind": "LinkedField",
              "name": "municipalityObject",
              "plural": false,
              "selections": (v4/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Appraisal",
              "kind": "LinkedField",
              "name": "latestAppraisal",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "RealadvisorAppraisal",
                  "kind": "LinkedField",
                  "name": "realadvisor",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "min",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "max",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "value",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "numberOfRooms",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "livingSurface",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "landSurface",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EmailTemplate",
          "kind": "LinkedField",
          "name": "enquiryResponseTemplate",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "EmailForm_initialTemplate"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Enquiry",
  "abstractKey": null
};
})();

(node as any).hash = "4c6e5dda23007b42779773c02517baba";

export default node;
