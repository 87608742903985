/**
 * @generated SignedSource<<0a6cb6e0e64c7710cdc1922f860ab7aa>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
type propertyPhotos_property$fragmentType = any;
export type CreatePropertyImageInput = {|
  clientMutationId?: ?string,
  description?: ?string,
  displayOnBrochure?: ?boolean,
  displayOnListing?: ?boolean,
  isFloorPlan?: ?boolean,
  propertyId?: ?string,
  title?: ?string,
  url?: ?string,
|};
export type propertyPhotosCreateMutation$variables = {|
  input: CreatePropertyImageInput,
|};
export type propertyPhotosCreateMutation$data = {|
  +createPropertyImage: ?{|
    +property: ?{|
      +propertyImages: ?$ReadOnlyArray<{|
        +image: {|
          +url: string,
        |},
      |}>,
      +$fragmentSpreads: propertyPhotos_property$fragmentType,
    |},
  |},
|};
export type propertyPhotosCreateMutation = {|
  response: propertyPhotosCreateMutation$data,
  variables: propertyPhotosCreateMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "propertyPhotosCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreatePropertyImagePayload",
        "kind": "LinkedField",
        "name": "createPropertyImage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Property",
            "kind": "LinkedField",
            "name": "property",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "propertyPhotos_property"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PropertyImage",
                "kind": "LinkedField",
                "name": "propertyImages",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Image",
                    "kind": "LinkedField",
                    "name": "image",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "propertyPhotosCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreatePropertyImagePayload",
        "kind": "LinkedField",
        "name": "createPropertyImage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Property",
            "kind": "LinkedField",
            "name": "property",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PropertyImage",
                "kind": "LinkedField",
                "name": "propertyImages",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayOnListing",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayOnBrochure",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "size",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "crop",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "backgroundColor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isFloorPlan",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "landscapeSize",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "landscapeCrop",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "landscapeBackgroundColor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Image",
                    "kind": "LinkedField",
                    "name": "image",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "orderNr",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "573fcdd58c1c018d36757a037395576a",
    "id": null,
    "metadata": {},
    "name": "propertyPhotosCreateMutation",
    "operationKind": "mutation",
    "text": "mutation propertyPhotosCreateMutation(\n  $input: CreatePropertyImageInput!\n) {\n  createPropertyImage(input: $input) {\n    property {\n      ...propertyPhotos_property\n      propertyImages {\n        image {\n          url\n          id\n        }\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment previewImage_image on Image {\n  url\n}\n\nfragment propertyImageForm_image on PropertyImage {\n  id\n  title\n  description\n  size\n  crop\n  backgroundColor\n  displayOnListing\n  displayOnBrochure\n  isFloorPlan\n  landscapeSize\n  landscapeCrop\n  landscapeBackgroundColor\n  image {\n    id\n    ...previewImage_image\n  }\n}\n\nfragment propertyImageQuickEdit_image on PropertyImage {\n  id\n  displayOnListing\n  displayOnBrochure\n}\n\nfragment propertyPhotos_property on Property {\n  id\n  propertyImages {\n    ...propertyImageQuickEdit_image\n    ...propertyImageForm_image\n    id\n    orderNr\n    title\n    description\n    image {\n      id\n      url\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "429c84d27a2895bd9d8d9aa20d7df52c";

export default node;
