import { graphql, useLazyLoadQuery } from 'react-relay';
import { Box } from 'react-system';

import { useAppData } from '../../apollo/providers/AppDataProvider';
import { Checkbox } from '../controls/checkbox';
import { Filter } from '../controls/Filters';
import { useLocale } from '../hooks/locale';
import {
  type Field,
  makeField,
  makeUrlSearchParamsHook,
} from '../hooks/url-search-params';

import type { leadSourceFilterQuery } from './__generated__/leadSourceFilterQuery.graphql';

export type LeadSourceParams = {
  sourceId_in: Field<string[] | null>;
};

export const leadSourceParams: LeadSourceParams = {
  sourceId_in: makeField({
    get: params => {
      const list = params.getAllStrings('sourceId_in');
      return list.length === 0 ? null : list;
    },
    set: (params, value) => params.setAllStrings('sourceId_in', value ?? []),
  }),
};

const useLeadSourceParams =
  makeUrlSearchParamsHook<LeadSourceParams>(leadSourceParams);

export const LeadSourceFilter = () => {
  const { me } = useAppData();

  const { leadSourceTypes } = useLazyLoadQuery<leadSourceFilterQuery>(
    graphql`
      query leadSourceFilterQuery {
        leadSourceTypes: dictionaries(type: "lead_source_types") {
          id
          name
          label
        }
      }
    `,
    {},
  );
  const types = leadSourceTypes ?? [];
  const { t } = useLocale();
  const [params, setParams] = useLeadSourceParams();
  const sourceId_in = params.sourceId_in ?? [];
  const makeToggle = (id: string | null) => (value: boolean) => {
    setParams({
      sourceId_in:
        value && id != null
          ? [...sourceId_in, id]
          : sourceId_in.filter(d => d !== id),
    });
  };

  return (
    <Filter
      dialogTitle={t('leadOrigin')}
      label={
        sourceId_in.length === 0
          ? t('leadOrigin')
          : types
              .flatMap(s =>
                s != null && sourceId_in.includes(s.id) ? [s.label] : [],
              )
              .join(', ')
      }
      empty={sourceId_in.length === 0}
      onReset={() => setParams({ sourceId_in: null })}
    >
      {types
        .filter(type => me?.is_admin || type.name !== 'lead-fisher')
        .map(
          source =>
            source && (
              <Box px={3} py={2} key={source.id}>
                <Checkbox
                  color="primary"
                  checked={sourceId_in.includes(source.id)}
                  onChange={makeToggle(source.id)}
                >
                  {source.label}
                </Checkbox>
              </Box>
            ),
        )}
    </Filter>
  );
};
