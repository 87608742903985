import { Avatar, Box, Stack, Tooltip } from '@mui/material';

import { useLocale } from '../../../src/hooks/locale';
import type { Currency } from '../../../src/locale';
import { getCountryName } from '../../../src/utils/countries';
import { getHostName } from '../../../src/utils/getHostName';
import { gql } from '../../__generated__';
import type { SalesLeadDetailFragment } from '../../__generated__/graphql';
import { useAppData } from '../../providers/AppDataProvider';
import { getAppraisalPerception } from '../../utils/appraisal';
import { formatAddress, formatCurrencyRange } from '../../utils/formatting';

import { LeadPropertyDetail } from './LeadPropertyDetail';
import { LeadQualificationFunnel } from './LeadQualificationFunnel';
import { LeadUpdatedTimeAgo } from './LeadUpdatedTimeAgo';

export const SALES_LEAD_DETAIL_FRAGMENT = gql(`
  fragment SalesLeadDetail on leads {
    id
    ...LeadQualificationFunnel
    ...LeadUpdatedTimeAgo
    referrer
    referring_domain
    landing_page_url
    appraisal_perceived_value
    appraisal_perception
    appraisal_property_usage
    relationship
    appraisal_property_occupied
    appraisal_property_route
    appraisal_property_street_number
    appraisal_property_postcode
    appraisal_property_locality
    appraisal_property_state
    appraisal_property_country_code
    property {
      ...LeadPropertyDetail
      street_number
      route
      postcode
      locality
      state
      country_code
      latest_appraisal {
        id
        max
        min
      }
    }
    contact {
      id
      first_name
      last_name
      referrer
    }
  }
`);

type SalesLeadDetailProps = {
  isCompact: boolean;
  lead: SalesLeadDetailFragment;
  canViewLeadDetails: boolean;
  customCreationDate: string | null;
  currency: Currency;
  showAddress?: boolean;
  bottomButton?: React.ReactNode;
  controls?: React.ReactNode;
};

const getLeadAddress = (
  lead: SalesLeadDetailFragment,
  canViewLeadDetails: boolean,
) => {
  const propertyFormattedAddress = formatAddress(
    {
      ...lead.property,
      country: getCountryName(lead.property?.country_code ?? null),
    },
    ', ',
    false,
  );
  const appraisalPropertyFormattedAddress = formatAddress(
    {
      route: lead.appraisal_property_route,
      street_number: lead.appraisal_property_street_number,
      postcode: lead.appraisal_property_postcode,
      locality: lead.appraisal_property_locality,
      state: lead.appraisal_property_state,
      country: getCountryName(lead.appraisal_property_country_code ?? null),
    },
    ', ',
    false,
  );

  return canViewLeadDetails
    ? propertyFormattedAddress.length > 0
      ? propertyFormattedAddress
      : appraisalPropertyFormattedAddress
    : formatAddress(
        {
          postcode: lead.property?.postcode,
          locality: lead.property?.locality,
          state: lead.property?.state,
          country: getCountryName(lead.property?.country_code ?? null),
        },
        ', ',
        false,
      );
};

const LeadRefererAvatar: React.FC<{ lead: SalesLeadDetailFragment }> = ({
  lead,
}) => (
  <Tooltip
    title={
      <>
        <div>User: {lead.contact?.referrer}</div>
        <div>Lead: {lead.referrer}</div>
        <div>Landing page: {lead.landing_page_url}</div>
      </>
    }
  >
    <Avatar
      sx={{ height: 18, width: 18, marginLeft: 8 }}
      src={`https://www.google.com/s2/favicons?domain=${
        lead.contact?.referrer != null
          ? getHostName(lead.contact.referrer)
          : lead.referring_domain || ''
      }`}
    />
  </Tooltip>
);

export const SalesLeadDetail: React.FC<SalesLeadDetailProps> = ({
  isCompact,
  lead,
  canViewLeadDetails,
  customCreationDate,
  currency,
  showAddress = false,
  bottomButton,
  controls = null,
}) => {
  const { t, locale } = useLocale();
  const { me } = useAppData();
  const isAdmin = me?.is_admin || false;

  return (
    <Stack
      flexGrow={1}
      flexBasis={0}
      p={isCompact ? 1 : 3}
      sx={{ minWidth: 0 }}
      spacing={0.5}
    >
      <Stack alignItems="center" direction="row" flexWrap="nowrap">
        <Stack direction="row" alignItems="center" flexGrow={1}>
          <Box sx={{ typography: 'subtitle2' }} pb={isCompact ? 0 : 1}>
            {lead.relationship === 'owner' ? '✅' : '✖️'}{' '}
            {lead.contact?.first_name != null || lead.contact?.last_name
              ? [
                  lead.contact?.first_name,
                  ' ',
                  canViewLeadDetails
                    ? lead.contact?.last_name
                    : `${
                        lead.contact?.last_name != null
                          ? lead.contact.last_name.slice(0, 1) + '.'
                          : ''
                      }`,
                ]
              : t('unknown')}
          </Box>
          {!isCompact && (lead.referrer != null || lead.contact?.referrer) && (
            <LeadRefererAvatar lead={lead} />
          )}
        </Stack>
        {!isCompact && (
          <>
            <Stack
              direction="row"
              alignItems="center"
              sx={{ typography: 'caption', color: 'text.secondary' }}
            >
              <LeadUpdatedTimeAgo
                lead={lead}
                customCreationDate={customCreationDate}
                showBothDate={isAdmin ?? false}
              />
            </Stack>
            {controls != null && <Box pl={2}>{controls}</Box>}
          </>
        )}
      </Stack>
      <Box
        sx={theme => ({
          ...theme.mixins.truncate(),
          typography: 'caption',
          color: 'text.secondary',
        })}
        pb={isCompact ? 0 : 1}
      >
        <LeadQualificationFunnel lead={lead} />
      </Box>
      <Stack
        direction="row"
        flexWrap="wrap"
        alignItems="center"
        sx={{ typography: 'subtitle2' }}
        pb={1}
      >
        {getAppraisalPerception(
          lead.appraisal_perception,
          lead.appraisal_perceived_value,
          lead.property?.latest_appraisal?.max,
          lead.property?.latest_appraisal?.min,
          true,
        )}{' '}
        {lead.property?.latest_appraisal != null &&
          formatCurrencyRange(
            lead.property?.latest_appraisal,
            currency,
            locale,
          )}
      </Stack>
      {!isCompact && (
        <LeadPropertyDetail
          property={lead.property}
          currency={currency}
          appraisalPropertyOccupied={lead.appraisal_property_occupied}
          appraisalPropertyUsage={lead.appraisal_property_usage}
        />
      )}
      {!isCompact && showAddress && (
        <Box sx={{ typography: 'body2' }}>
          {getLeadAddress(lead, canViewLeadDetails)}
        </Box>
      )}
      {bottomButton != null && <Box mt={2}>{bottomButton}</Box>}
    </Stack>
  );
};
