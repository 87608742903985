/**
 * @generated SignedSource<<13bee03631d733edf0548adb9b090a55>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type propertyCardPlot_location$fragmentType: FragmentType;
export type propertyCardPlot_location$data = {|
  +plot: ?{|
    +landRegistryURL: ?string,
    +localityId: ?number,
    +plotId: ?string,
    +surface: ?number,
  |},
  +zone: ?{|
    +definition: ?{|
      +cantonZoneName: ?string,
    |},
  |},
  +$fragmentType: propertyCardPlot_location$fragmentType,
|};
export type propertyCardPlot_location$key = {
  +$data?: propertyCardPlot_location$data,
  +$fragmentSpreads: propertyCardPlot_location$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "propertyCardPlot_location",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Plot",
      "kind": "LinkedField",
      "name": "plot",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "localityId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "plotId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "surface",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "landRegistryURL",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Zone",
      "kind": "LinkedField",
      "name": "zone",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ZoneDefinition",
          "kind": "LinkedField",
          "name": "definition",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cantonZoneName",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Location",
  "abstractKey": null
};

(node/*: any*/).hash = "f75f4d56368dbcaf0b1d20f12fefcf81";

export default node;
