/**
 * @generated SignedSource<<dad7efe0d1ef188133c1e7d46887f347>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type lotMandateCreateDialog_root$fragmentType: FragmentType;
export type lotMandateCreateDialog_root$data = {|
  +originTypes: $ReadOnlyArray<{|
    +id: string,
    +label: string,
    +name: string,
  |}>,
  +$fragmentType: lotMandateCreateDialog_root$fragmentType,
|};
export type lotMandateCreateDialog_root$key = {
  +$data?: lotMandateCreateDialog_root$data,
  +$fragmentSpreads: lotMandateCreateDialog_root$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "lotMandateCreateDialog_root",
  "selections": [
    {
      "alias": "originTypes",
      "args": [
        {
          "kind": "Literal",
          "name": "type",
          "value": "lot_origin_types"
        }
      ],
      "concreteType": "Dictionary",
      "kind": "LinkedField",
      "name": "dictionaries",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": "dictionaries(type:\"lot_origin_types\")"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node/*: any*/).hash = "f2be6cef762f3ee3372bb27b0b865aa0";

export default node;
