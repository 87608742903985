import { useRef, useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, ButtonGroup, Menu, MenuItem } from '@mui/material';

import { TemplateViewerDialog } from '../../src/controls/template-viewer-dialog';
import { useLocale } from '../../src/hooks/locale';
import {
  type ApolloTemplate,
  useGetDefaultApolloTemplate,
} from '../utils/template';

interface TemplatesListButtonGroupProps {
  includeAsButton?: boolean;
  startIcon?: React.ReactNode;
  docTemplates: ApolloTemplate[];
  documentId: string;
  disabled: boolean;
  fullWidth?: boolean;
  label: React.ReactNode;
  size?: 'small' | 'medium' | 'large';
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | 'neutral';
}

export const TemplatesListButtonGroup: React.FC<
  TemplatesListButtonGroupProps
> = ({
  docTemplates,
  documentId,
  disabled,
  fullWidth,
  label,
  size,
  color = 'primary',
  includeAsButton = false,
  startIcon,
}) => {
  const { t } = useLocale();
  const [templateId, setTemplateId] = useState<string | null>(null);
  const anchorRef = useRef(null);
  const [anchor, setAnchor] = useState<null | HTMLButtonElement>(null);
  const { getDefaultTemplate } = useGetDefaultApolloTemplate();

  const open = Boolean(anchor);
  const defaultTemplate = getDefaultTemplate(docTemplates);

  const renderButtons = () => [
    <Button
      key="openTemplateBtn"
      variant="outlined"
      size={size}
      title={defaultTemplate?.name ?? t('No templates')}
      onClick={() => {
        if (defaultTemplate != null) {
          setTemplateId(defaultTemplate.id);
        }
      }}
      startIcon={startIcon}
      ref={anchorRef}
      sx={theme => ({
        ...(fullWidth === true ? { flexGrow: 1 } : {}),
        ...(includeAsButton === true
          ? {
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              flexDirection: { xs: 'column', md: 'row' },
              '& .MuiButton-startIcon': {
                [theme.breakpoints.down('md')]: {
                  mr: 0,
                },
              },
            }
          : {}),
      })}
    >
      {label}
    </Button>,
    <Button
      key="dropdownBtn"
      size={size}
      sx={theme => ({
        paddingX: 0,
        [theme.breakpoints.down('md')]: { minWidth: '25px !important' },
        ...(includeAsButton === true ? { width: 'auto' } : {}),
      })}
      onClick={() => setAnchor(anchorRef.current)}
      id="templates-list-button"
      aria-controls={open ? 'templates-list-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
    >
      <ArrowDropDownIcon />
    </Button>,
  ];

  return (
    <>
      {includeAsButton ? (
        renderButtons()
      ) : (
        <ButtonGroup
          sx={fullWidth === true ? { display: 'flex' } : {}}
          // HACK: Because somehow MUI typing on ButtonGroup does not match the values accepted for the color prop
          color={color as any}
          variant="outlined"
          disabled={disabled}
        >
          {renderButtons()}
        </ButtonGroup>
      )}
      <Menu
        anchorEl={anchor}
        open={open}
        onClick={() => setAnchor(null)}
        onClose={() => setAnchor(null)}
        id="templates-list-menu"
        MenuListProps={{
          'aria-labelledby': 'templates-list-button',
        }}
      >
        {docTemplates.map(item => (
          <MenuItem key={item.id} onClick={() => setTemplateId(item.id)}>
            {item.name}
          </MenuItem>
        ))}
      </Menu>
      <TemplateViewerDialog
        templateId={templateId ?? ''}
        documentId={documentId}
        open={templateId != null}
        onClose={() => setTemplateId(null)}
      />
    </>
  );
};
