// @flow

import type { Translate } from '@realadvisor/translator/engine';

type User = {
  +name: ?string,
  ...
};

type AssignedTo = {|
  firstName: ?string,
  lastName: ?string,
  organisationName: ?string,
|};

type Props = {|
  t: Translate,
  assignedTo: AssignedTo,
  participants: ?$ReadOnlyArray<User>,
  activityType: string,
|};

const activityTypeToTitle = (activityType, t) => {
  if (activityType === 'visit') {
    return t('visit');
  } else if (activityType === 'call') {
    return t('call');
  } else if (activityType === 'task') {
    return t('task');
  }

  return '';
};

export const getDefaultSubject = ({
  t,
  assignedTo,
  participants,
  activityType,
}: Props): string => {
  const organisationName =
    assignedTo.organisationName != null
      ? `(${assignedTo.organisationName})`
      : '';

  const names = participants?.map(u => u.name ?? '') ?? [];

  return `${activityTypeToTitle(activityType, t)} - ${names.join(', ')} <> ${
    assignedTo.firstName ?? ''
  } ${assignedTo.lastName ?? ''} ${organisationName}`;
};
