import { CardContent } from '@material-ui/core';
import { graphql, useFragment } from 'react-relay';

import { Markdown } from '../../controls/markdown';
import { useTheme } from '../../hooks/theme';
import { NoteOutline } from '../../icons/note-outline';

import type { FeedNote_activity$key } from './__generated__/FeedNote_activity.graphql';

type Props = {
  activity: FeedNote_activity$key;
};

export const FeedNote = (props: Props) => {
  const { text } = useTheme();

  const activity = useFragment(
    graphql`
      fragment FeedNote_activity on Activity {
        ... on ActivityNote {
          note
        }
      }
    `,
    props.activity,
  );
  return activity.note != null ? (
    // TODO move CardContent to FeedItem
    <CardContent
      css={{
        paddingTop: 0,
        display: 'grid',
        gridGap: 12,
        gridTemplateColumns: '24px 1fr',
      }}
    >
      <NoteOutline />
      <div css={text.body2}>
        <Markdown>{activity.note}</Markdown>
      </div>
    </CardContent>
  ) : null;
};
