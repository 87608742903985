import * as React from 'react';

import { Button, Chip } from '@material-ui/core';
import { graphql, useFragment } from 'react-relay';
import { Box, Flex } from 'react-system';

import { getRatingLabel } from '../../../apollo/utils/formatting';
import { type Translate, useLocale } from '../../hooks/locale';
import { ExpandLess } from '../../icons/expand-less';
import { ExpandMore } from '../../icons/expand-more';
import {
  translateAppraisalReason,
  translateBuyHorizon,
  translateMortgageBuyingStatus,
  translateMortgageStatus,
  translateOpenToOffer,
  translatePropertyOccupied,
  translatePropertyUsage,
  translateReceivedMortgageOffer,
  translateRelationship,
  translateSaleHorizon,
  translateSellingWith,
} from '../../utils/lead-labels';

import type { FeedRequalification_activity$key } from './__generated__/FeedRequalification_activity.graphql';

type Props = {
  activity: FeedRequalification_activity$key;
};

const translateLabel = (t: Translate, label: string) => {
  switch (label) {
    case 'relationship':
      return t('relationship');
    case 'appraisal_reason':
      return t('appraisalReason');
    case 'buy_horizon':
      return t('buyHorizon');
    case 'sale_horizon':
      return t('saleHorizon');
    case 'mortgage_buying_status':
      return t('buyingStage');
    case 'mortgage_status':
      return t('mortgageApplication');
    case 'received_mortgage_offer':
      return t('mortgageOffer');
    case 'appraisal_open_to_offer':
      return t('appraisalOpenToOffer');
    case 'appraisal_property_occupied':
      return t('appraisalPropertyOccupied');
    case 'appraisal_property_usage':
      return t('appraisalPropertyUsage');
    case 'perceived_value':
      return t('perceivedValue');
    case 'requalified_at':
      return t('requalifiedAt');
    case 'updated_at':
      return t('Updated at');
    case 'created_at':
      return t('createdAt');
    case 'appraisal_market_time':
      return t('monthsOnMarket');
    case 'appraisal_selling_with':
      return t('sell');
    case 'appraisal_property_building_condition':
      return t('buildingCondition');
    default:
      return label;
  }
};

const renderRow = (
  key: string,
  old: string,
  newValue: string,
  t: Translate,
) => {
  return (
    <Box key={key} p={1}>
      {translateLabel(t, key)} <Chip size="small" label={old} /> &rarr;{' '}
      <Chip size="small" label={newValue} />
    </Box>
  );
};

export const FeedRequalification = (props: Props) => {
  const [expanded, setExpanded] = React.useState(false);
  const { t } = useLocale();
  const activity = useFragment(
    graphql`
      fragment FeedRequalification_activity on Activity {
        ... on ActivityRequalification {
          extraData
        }
      }
    `,
    props.activity,
  );

  if (activity.extraData == null) {
    return null;
  }

  const updatedValues = activity.extraData.updatedValues ?? {};

  const keysOrder = [
    'relationship',
    'appraisal_reason',
    'sale_horizon',
    'buy_horizon',
    'mortgage_buying_status',
    'mortgage_status',
    'received_mortgage_offer',
    'perceived_value',
    'appraisal_open_to_offer',
    'appraisal_property_occupied',
    'appraisal_property_usage',
    'appraisal_property_building_condition',
  ];

  const pickedValuesSorted: { key: string; old: string; newValue: string }[] =
    [];

  for (const key of keysOrder) {
    if (updatedValues[key] != null) {
      let old = updatedValues[key].old;
      let newValue = updatedValues[key].new;
      switch (key) {
        case 'relationship':
          old = translateRelationship(t, old);
          newValue = translateRelationship(t, newValue);
          break;
        case 'appraisal_reason':
          old = translateAppraisalReason(t, old);
          newValue = translateAppraisalReason(t, newValue);
          break;
        case 'sale_horizon':
          old = translateSaleHorizon(t, old);
          newValue = translateSaleHorizon(t, newValue);
          break;
        case 'buy_horizon':
          old = translateBuyHorizon(t, old);
          newValue = translateBuyHorizon(t, newValue);
          break;
        case 'mortgage_buying_status':
          old = translateMortgageBuyingStatus(t, old);
          newValue = translateMortgageBuyingStatus(t, newValue);
          break;
        case 'mortgage_status':
          old = translateMortgageStatus(t, old);
          newValue = translateMortgageStatus(t, newValue);
          break;
        case 'received_mortgage_offer':
          old = translateReceivedMortgageOffer(t, old);
          newValue = translateReceivedMortgageOffer(t, newValue);
          break;
        case 'appraisal_open_to_offer':
          old = translateOpenToOffer(t, old);
          newValue = translateOpenToOffer(t, newValue);
          break;
        case 'appraisal_property_occupied':
          old = translatePropertyOccupied(t, old);
          newValue = translatePropertyOccupied(t, newValue);
          break;
        case 'appraisal_property_usage':
          old = translatePropertyUsage(t, old);
          newValue = translatePropertyUsage(t, newValue);
          break;
        case 'appraisal_selling_with':
          old = translateSellingWith(t, old);
          newValue = translateSellingWith(t, newValue);
          break;
        case 'appraisal_property_building_condition':
          old = getRatingLabel(old, t);
          newValue = getRatingLabel(newValue, t);
          break;
      }

      old = old ?? t('notSet');
      newValue = newValue ?? t('notSet');

      if (old !== newValue) {
        pickedValuesSorted.push({ key, old, newValue });
      }
    }
  }

  return (
    <Box px={3} pb={2}>
      {pickedValuesSorted
        .slice(0, expanded ? pickedValuesSorted.length : 4)
        .map(({ key, old, newValue }) => renderRow(key, old, newValue, t))}

      {pickedValuesSorted.length > 4 && (
        <Flex>
          <Button
            size="small"
            onClick={() => setExpanded(!expanded)}
            startIcon={expanded ? <ExpandLess /> : <ExpandMore />}
          >
            {expanded ? t('showLess') : t('showMore')}
          </Button>
        </Flex>
      )}
    </Box>
  );
};
