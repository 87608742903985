/**
 * @generated SignedSource<<7a1ba4138873f73298a5ffabab90a00e>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
type previewImage_image$fragmentType = any;
export type PropertyImageBackground = "black" | "white";
export type PropertyImageCrop = "fill" | "fit";
export type PropertyImageSize = "full_page" | "half_page" | "quarter_page";
import type { FragmentType } from "relay-runtime";
declare export opaque type propertyImageForm_image$fragmentType: FragmentType;
export type propertyImageForm_image$data = {|
  +backgroundColor: PropertyImageBackground,
  +crop: PropertyImageCrop,
  +description: ?string,
  +displayOnBrochure: boolean,
  +displayOnListing: boolean,
  +id: string,
  +image: {|
    +id: string,
    +$fragmentSpreads: previewImage_image$fragmentType,
  |},
  +isFloorPlan: boolean,
  +landscapeBackgroundColor: PropertyImageBackground,
  +landscapeCrop: PropertyImageCrop,
  +landscapeSize: PropertyImageSize,
  +size: PropertyImageSize,
  +title: ?string,
  +$fragmentType: propertyImageForm_image$fragmentType,
|};
export type propertyImageForm_image$key = {
  +$data?: propertyImageForm_image$data,
  +$fragmentSpreads: propertyImageForm_image$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "propertyImageForm_image",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "size",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "crop",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "backgroundColor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayOnListing",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayOnBrochure",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isFloorPlan",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "landscapeSize",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "landscapeCrop",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "landscapeBackgroundColor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "previewImage_image"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PropertyImage",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "6b9eeb1d20a19a9b793d756aa6283968";

export default node;
