import { encode } from './codec';

export type TranslatorResource = {
  language: string;
  data: Record<string, string>;
};

// disallow direct index access
type IndexItem = {
  language: string;
  key: string;
  value: string;
};
export type TranslatorIndex = Map<string, IndexItem>;

export type TranslatorStore = {
  readonly index: TranslatorIndex;
  readonly draft: TranslatorIndex;
  readonly devtool: boolean;
  currentLanguage: string;
  fallbackLanguage: string;
  locale: string;
};

// private api
export const _buildIndexKey = (language: string, key: string): string => {
  return `${language}:${key}`;
};

export const makeTranslatorStore = ({
  currentLanguage,
  fallbackLanguage,
  devtool,
  locale,
  resources,
}: {
  currentLanguage: string;
  fallbackLanguage: string;
  devtool: boolean;
  resources: ReadonlyArray<TranslatorResource>;
  locale: string;
}): TranslatorStore => {
  const index = new Map();
  const draft = new Map();
  for (const { language, data } of resources) {
    for (const key of Object.keys(data)) {
      index.set(_buildIndexKey(language, key), {
        language,
        key,
        value: data[key],
      });
    }
  }
  return {
    index,
    draft,
    currentLanguage,
    fallbackLanguage,
    devtool,
    locale,
  };
};

export const addTranslatorResources = (
  index: TranslatorIndex,
  resources: ReadonlyArray<TranslatorResource>,
) => {
  for (const { language, data } of resources) {
    for (const key of Object.keys(data)) {
      index.set(_buildIndexKey(language, key), {
        language,
        key,
        value: data[key],
      });
    }
  }
};

export const getTranslatorResources = (
  index: TranslatorIndex,
): ReadonlyArray<TranslatorResource> => {
  const resourcesMap = new Map();
  for (const [, { language, key, value }] of index) {
    let resourceData = resourcesMap.get(language);
    if (resourceData == null) {
      const newData = {};
      resourcesMap.set(language, newData);
      resourceData = newData;
    }
    resourceData[key] = value;
  }
  const resources = [];
  for (const [language, data] of resourcesMap) {
    resources.push({ language, data });
  }
  return resources;
};

export const clearTranslatorResources = (index: TranslatorIndex) => {
  index.clear();
};

// read only fixes the problem with interpolate options incompatibility
type TranslateOptions = { count?: null | number } & Record<
  string,
  null | string | number
>;

export type Translate = (text: string, options?: TranslateOptions) => string;

export type Interpolate = (
  template: string,
  options?: Record<string, string | number | null>,
  locale?: string,
) => string;

const resolveKeyQuantity = (
  language: string,
  key: string,
  count: null | number,
) => {
  if (count == null) {
    return key;
  }
  if (
    language === 'de' ||
    language === 'en' ||
    language === 'it' ||
    language === 'es'
  ) {
    if (count === 1) {
      return key;
    } else {
      return `${key}_plural`;
    }
  }
  if (language === 'fr') {
    if (count === 0 || count === 1) {
      return key;
    } else {
      return `${key}_plural`;
    }
  }
  console.error(`Cannot found plural forms for ${language} language`);
  return key;
};

export const interpolate: Interpolate = (template, options, locale) => {
  const braceRegex = /{{(.*?)}}/g;
  return template.replace(braceRegex, (_, property) => {
    const value = options?.[property];
    if (value == null) {
      return '';
    }

    if (typeof value !== 'string' && locale != null) {
      return value.toLocaleString(locale, {
        minimumFractionDigits: 0,
      });
    }

    return value.toString();
  });
};

export const translate = (
  store: TranslatorStore,
  key: string,
  options?: TranslateOptions,
): string => {
  const count = options?.count ?? null;
  const { currentLanguage, fallbackLanguage, index } = store;
  const currentKey = resolveKeyQuantity(currentLanguage, key, count);
  const currentIndexKey = _buildIndexKey(currentLanguage, currentKey);
  const fallbackKey = resolveKeyQuantity(fallbackLanguage, key, count);
  const fallbackIndexKey = _buildIndexKey(fallbackLanguage, fallbackKey);
  if (store.devtool) {
    // prefer draft when in devtool mode and encode key into translation
    const devtoolTemplate =
      store.draft.get(currentIndexKey)?.value ??
      index.get(currentIndexKey)?.value ??
      key;
    return encode(
      currentKey,
      interpolate(devtoolTemplate, options, store.locale),
    );
  }
  const template =
    index.get(currentIndexKey)?.value ??
    index.get(fallbackIndexKey)?.value ??
    key;
  return interpolate(template, options, store.locale);
};
