import type { FieldPath } from 'react-hook-form';

import {
  type GetPortalQuery,
  Portals_Types_Enum_Enum,
} from '../../__generated__/graphql';
import type {
  BaseFieldDefinition,
  FormDefinitionType,
} from '../../components/form/RaForm';

type PortalSettingsContext = {
  shared_portals: GetPortalQuery['shared_portals'];
  portals_types_enum: GetPortalQuery['portals_types_enum'];
  disableTeamId: boolean;
  isCreation: boolean;
};

type PortalSettingsFormData = Exclude<
  NonNullable<GetPortalQuery['portals_by_pk']>,
  '__typename' | 'id' | 'updated_at' | 'portal_listings_aggregate'
>;

const defaultFormElement: Pick<
  BaseFieldDefinition<PortalSettingsFormData>,
  'render' | 'required' | 'gridProps'
> = {
  render: ({ type }) => !!type && type !== Portals_Types_Enum_Enum.Shared,
  required: true,
  gridProps: {
    xs: 12,
    md: 6,
  },
};

export const formDefinition: FormDefinitionType<
  PortalSettingsFormData,
  PortalSettingsContext
> = ({ t, context }) => [
  {
    ...defaultFormElement,
    type: 'select',
    name: 'type',
    label: t('Portal Type'),
    render: () => true,
    options: () =>
      context.portals_types_enum.map(({ value }) => ({
        value,
        label: value,
      })),
  },
  {
    ...defaultFormElement,
    type: 'text',
    name: 'name',
    label: t('Name'),
    render: () => true,
  },
  {
    ...defaultFormElement,
    type: 'select',
    name: 'master_portal_id',
    label: t('Shared Portal'),
    options: () =>
      context.shared_portals
        .filter(
          (item): item is { id: string; name: string } =>
            item.id != null && item.name != null,
        )
        .map(({ id, name }) => ({
          value: id,
          label: name,
        })),
    render: ({ type }) => type === Portals_Types_Enum_Enum.Shared,
  },
  {
    ...defaultFormElement,
    type: 'text',
    name: 'contract_id',
    label: t('Contract ID'),
  },
  {
    ...defaultFormElement,
    type: 'text',
    name: 'quota',
    label: t('Quota'),
    required: false,
  },
  {
    ...defaultFormElement,
    type: 'text',
    name: 'username',
    label: t('Username'),
  },
  {
    ...defaultFormElement,
    type: 'text',
    name: 'password',
    label: t('Password'),
  },
  {
    ...defaultFormElement,
    type: 'text',
    name: 'email',
    label: t('Email'),
  },
  {
    ...defaultFormElement,
    type: 'text',
    name: 'website_url',
    label: t('Website URL'),
    required: false,
  },
  {
    type: 'select',
    name: 'idx_protocol',
    label: t('Protocol'),
    render: ({ type }) => type === Portals_Types_Enum_Enum.Idx,
    options: () => [
      { value: 'ftp', label: 'FTP' },
      { value: 'sftp', label: 'SFTP' },
    ],
    gridProps: {
      xs: 12,
      md: 3,
    },
  },
  {
    type: 'text',
    name: 'url',
    label: t('URL'),
    render: ({ type }) => type === Portals_Types_Enum_Enum.Idx,
    gridProps: {
      xs: 12,
      md: 7,
    },
  },
  {
    type: 'text',
    name: 'port',
    label: t('Port'),
    render: ({ type }) => type === Portals_Types_Enum_Enum.Idx,
    gridProps: {
      xs: 12,
      md: 2,
    },
  },
  {
    type: 'team',
    name: 'team_id',
    label: t('Team'),
    disabled: () => context.disableTeamId,
  },
  {
    name: 'options',
    label: t('Options'),
    type: 'checkbox-group',
    render: ({ type }) => !!type && type !== Portals_Types_Enum_Enum.Shared,
    checkboxes: (
      [
        {
          name: 'active',
          label: t('Active'),
        },
        {
          name: 'has_top_premium_listing',
          label: t('Has Top Premium listings'),
        },
        {
          name: 'exclude_from_published_stats',
          label: t('Exclude from published stats'),
        },
        {
          name: 'exact_address_required',
          label: t('Exact address required'),
        },
      ] as {
        name: FieldPath<PortalSettingsFormData>;
        label: string;
      }[]
    ).map(checkbox => ({
      ...checkbox,
      type: 'checkbox',
    })),
    gridProps: {
      xs: 12,
    },
  },
  {
    name: 'meta',
    label: t('Metadata'),
    type: 'category-title',
    render: () => !context.isCreation,
  },
  {
    name: 'created_at',
    label: t('Created at'),
    type: 'date',
    render: () => !context.isCreation,
    disabled: () => true,
  },
  {
    name: 'created_by',
    label: t('Created by'),
    type: 'user',
    render: () => !context.isCreation,
    disabled: () => true,
  },
];
