import * as React from 'react';

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';

import type { ConditionOperator } from '../utils/parseWhereClause';

type BooleanOperator = Extract<ConditionOperator, '_eq'>;

type FilterBooleanFormProps = {
  value: boolean | null;
  operator: BooleanOperator;
  onChange: (params: { value: boolean; operator: BooleanOperator }) => void;
};

export const FilterBooleanForm: React.FC<FilterBooleanFormProps> = ({
  value: initialValue,
  onChange,
}) => {
  const [value, setValue] = React.useState(initialValue);

  return (
    <Stack direction="column" alignItems="left" spacing={1} paddingY={2}>
      <FormControl>
        <RadioGroup
          value={value}
          onChange={event => {
            if (event.target.value === 'true') {
              setValue(true);
              onChange({ value: true, operator: '_eq' });
            } else {
              setValue(false);
              onChange({ value: false, operator: '_eq' });
            }
          }}
        >
          <FormControlLabel
            value="true"
            label="True"
            control={<Radio size="small" />}
          />
          <FormControlLabel
            value="false"
            label="False"
            control={<Radio size="small" />}
          />
        </RadioGroup>
      </FormControl>
    </Stack>
  );
};
