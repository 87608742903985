import type { IntlLocale } from '../locale';

export const string_of_number = (number: null | undefined | number): string =>
  number != null ? String(number) : '';

export const number_of_string = (string: string): null | number => {
  const parsed = Number.parseFloat(string);
  if (Number.isNaN(parsed)) {
    return null;
  }
  return parsed;
};

type Params = {
  scale?: number;
  locale: IntlLocale;
};

const LOCALES_WITH_COMMA = ['de-DE', 'it-IT', 'fr-FR', 'fr-CH', 'es-ES'];

type decimalSymbol = '.' | ',';

export const getDecimalSymbol = (
  locale: IntlLocale,
): { accept: RegExp; decimalSymbol: decimalSymbol } => {
  return LOCALES_WITH_COMMA.includes(locale)
    ? {
        accept: /[\d,]+/g,
        decimalSymbol: ',',
      }
    : {
        accept: /[\d.]+/g,
        decimalSymbol: '.',
      };
};

export const makeNumberFormat = ({
  locale,
  scale = 0,
}: Params): {
  accept: RegExp;
  format: (string: string) => string;
  delocalizeNumber: (string: string) => string;
  localizeNumber: (string: string) => string;
} => {
  const { accept, decimalSymbol } = getDecimalSymbol(locale);

  const format = (string: string) => {
    // use Intl.NumberFormat.prototype.formatToParts when Safari below 13 are dropped
    const parsed = (string.match(accept) ?? []).join('');
    const [head, tail] = parsed.split(decimalSymbol);
    // Avoid rounding errors at toLocaleString as when user enters 1.239 and maxDigits=2 we
    // must not to convert it to 1.24, it must stay 1.23
    const scaledTail = tail != null ? tail.slice(0, scale) : '';

    const number = Number.parseFloat(`${head}.${scaledTail}`);

    if (Number.isNaN(number)) {
      return '';
    }

    const formatter = new Intl.NumberFormat(locale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: scale,
    });
    const formatted = formatter.format(number);

    if (scale !== 0 && parsed.includes(decimalSymbol)) {
      const [formattedHead] = formatted.split(decimalSymbol);

      // skip zero at digits position for non fixed floats
      // as at digits 2 for non fixed floats numbers like 1.50 has no sense, just 1.5 allowed
      // but 1.0 has sense as otherwise you will not be able to enter 1.05 for example
      const formattedTail =
        scaledTail !== '' && scaledTail[scale - 1] === '0'
          ? scaledTail.slice(0, -1)
          : scaledTail;

      return `${formattedHead}${decimalSymbol}${formattedTail}`;
    }
    return formatted;
  };

  // number input state should be parsable with parseFloat
  // so we convert js number to localized format and back
  // usually not necessary except locales with decimal comma

  const delocalizeNumber = (string: string) => {
    return (string.match(accept) ?? []).join('').replace(decimalSymbol, '.');
  };

  const localizeNumber = (string: string) => {
    return (string.match(/[\d.]+/g) ?? []).join('').replace('.', decimalSymbol);
  };

  return {
    accept,
    format,
    delocalizeNumber,
    localizeNumber,
  };
};

export const numberToLocaleString = (
  value: null | number,
  locale: IntlLocale,
): null | string => (value != null ? value.toLocaleString(locale) : null);

export const formatNumberByPrecision = (
  value: null | number,
  locale: IntlLocale,
): string => {
  if (typeof value !== 'number') {
    return '-';
  } else {
    let val = value / 1000;
    let valUnit = 'k';
    let str = '';

    if (val >= 1000) {
      val = val / 1000;
      str = val.toLocaleString(locale, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
      valUnit = 'm';
    } else {
      str = val.toLocaleString(locale, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      });
    }

    return `${str}${valUnit}`;
  }
};
