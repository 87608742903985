/**
 * @generated SignedSource<<cc48f124b95496cf0e7d59f2db059d50>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserAssetsThirdPillarAOrganisationType = "bank" | "insurance_company";
export type UserAssetsThirdPillarBOrganisationType = "bank" | "insurance_company";
export type UserGender = "female" | "male";
export type UserLanguage = "de" | "en" | "es" | "fr" | "it";
export type UserMaritalStatus = "cohabitation" | "divorced" | "married" | "separated" | "single" | "widowed";
export type UserMatrimonialRegime = "contribution_to_jointly_acquired_property" | "joint_estates" | "separate_estates";
export type UserProfessionalSituation = "business_owner" | "employed" | "retired" | "self_employed" | "unemployed";
export type UserTitle = "doctor" | "madam" | "professor" | "sir";
export type UpdateUserInput = {
  accountManagerId?: string | null;
  annualCostsChildSupport?: number | null;
  annualCostsLeasing?: number | null;
  annualCostsOtherCredits?: number | null;
  annualCredits?: ReadonlyArray<UserAnnualCreditInput | null> | null;
  annualCreditsExist?: boolean | null;
  annualIncomeAdditionalExist?: boolean | null;
  annualIncomeBonus?: number | null;
  annualIncomeChildSupport?: number | null;
  annualIncomeFamilyAllowances?: number | null;
  annualIncomeOther?: number | null;
  annualIncomeRent?: number | null;
  annualIncomeSalary?: number | null;
  annualIncomeSalary13?: boolean | null;
  annualIncomeSalary14?: boolean | null;
  annualLeasings?: ReadonlyArray<UserAnnualLeasingInput | null> | null;
  annualLeasingsExist?: boolean | null;
  annualOtherExist?: boolean | null;
  annualOtherPayments?: ReadonlyArray<number | null> | null;
  assetsSavings?: number | null;
  assetsSavingsDonation?: number | null;
  assetsSecondPillar?: number | null;
  assetsSecondPillarExist?: boolean | null;
  assetsSecondPillarOrganisation?: string | null;
  assetsSecurities?: number | null;
  assetsThirdPillarA?: number | null;
  assetsThirdPillarAAnnualPremium?: number | null;
  assetsThirdPillarAAnnualPremiumOrganization?: string | null;
  assetsThirdPillarACash?: number | null;
  assetsThirdPillarAExist?: boolean | null;
  assetsThirdPillarALifeInsurance?: boolean | null;
  assetsThirdPillarAOrganisation?: string | null;
  assetsThirdPillarAOrganisationType?: UserAssetsThirdPillarAOrganisationType | null;
  assetsThirdPillarB?: number | null;
  assetsThirdPillarBAnnualPremium?: number | null;
  assetsThirdPillarBExist?: boolean | null;
  assetsThirdPillarBLifeInsurance?: boolean | null;
  assetsThirdPillarBOrganisation?: string | null;
  assetsThirdPillarBOrganisationType?: UserAssetsThirdPillarBOrganisationType | null;
  assetsThirdPillarExist?: boolean | null;
  autoAssignLeads?: boolean | null;
  brandingColor?: string | null;
  brandingLogo?: string | null;
  brandingName?: string | null;
  brandingWebsite?: string | null;
  brokerCatchmentAreas?: ReadonlyArray<string> | null;
  brokerId?: string | null;
  canEditSettings?: boolean | null;
  canUserLoginAs?: boolean | null;
  clientMutationId?: string | null;
  dateOfBirth?: string | null;
  debtLeasingBalance?: number | null;
  debtMortgageBalance?: number | null;
  debtOtherCreditsBalance?: number | null;
  divorceDate?: string | null;
  donation?: number | null;
  emailSignature?: string | null;
  emailSignatureHTML?: string | null;
  employer?: string | null;
  employerId?: string | null;
  employmentCommencementDate?: string | null;
  employmentRate?: number | null;
  firstName?: string | null;
  gender?: UserGender | null;
  hasAcceptedTerms?: boolean | null;
  hasChildren?: boolean | null;
  hasFreeCrmAccess?: boolean | null;
  hasUsedTrialButton?: boolean | null;
  homeAddress?: AddressInput | null;
  id: string;
  identificationDocumentExpiryDate?: string | null;
  identificationDocumentIssueDate?: string | null;
  identificationDocumentNumber?: string | null;
  identificationDocumentTypeId?: string | null;
  isAdmin?: boolean | null;
  isBroker?: boolean | null;
  jobTitle?: string | null;
  language?: UserLanguage | null;
  lastBonus?: number | null;
  lastName?: string | null;
  maritalPartnerId?: string | null;
  maritalStatus?: UserMaritalStatus | null;
  matrimonialRegime?: UserMatrimonialRegime | null;
  membershipType?: string | null;
  modules?: ReadonlyArray<string> | null;
  mortgageBrokerId?: string | null;
  nationalityId?: string | null;
  numberOfBuyers?: number | null;
  numberOfDependantChildren?: number | null;
  occupationId?: string | null;
  occupationText?: string | null;
  placeOfOrigin?: string | null;
  primaryEmail?: string | null;
  primaryImageId?: string | null;
  primaryPhoneNumber?: string | null;
  professionalAddress?: AddressInput | null;
  professionalSituation?: UserProfessionalSituation | null;
  propertiesExist?: boolean | null;
  referrer?: string | null;
  residenceCountryCode?: string | null;
  residenceStateId?: string | null;
  secondPillarOrganisationId?: string | null;
  thirdPillarAAnnualPremiumOrganizationId?: string | null;
  thirdPillarAOrganisationId?: string | null;
  thirdPillarBOrganisationId?: string | null;
  title?: UserTitle | null;
  updatesDailyTasks?: boolean | null;
  updatesIntroductionToLeadContacts?: boolean | null;
  updatesNewLeadAssignedSms?: boolean | null;
  updatesNewsletter?: boolean | null;
  updatesPropertyRevaluation?: boolean | null;
  weddingDate?: string | null;
  workPermitId?: string | null;
  yearlyRevenuesAtRetirement?: number | null;
};
export type AddressInput = {
  countryCode?: string | null;
  lat?: number | null;
  lng?: number | null;
  locality?: string | null;
  postcode?: string | null;
  route?: string | null;
  state?: string | null;
  streetNumber?: string | null;
};
export type UserAnnualLeasingInput = {
  annualPayment?: number | null;
  endDate?: string | null;
  remainingBalance?: number | null;
};
export type UserAnnualCreditInput = {
  annualPayment?: number | null;
  endDate?: string | null;
  purpose?: string | null;
  remainingBalance?: number | null;
};
export type plansUserSettingsMutation$variables = {
  input: UpdateUserInput;
};
export type plansUserSettingsMutation$data = {
  readonly updateUser: {
    readonly user: {
      readonly hasFreeCrmAccess: boolean | null;
      readonly hasUsedTrialButton: boolean | null;
    } | null;
  } | null;
};
export type plansUserSettingsMutation = {
  response: plansUserSettingsMutation$data;
  variables: plansUserSettingsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasFreeCrmAccess",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasUsedTrialButton",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "plansUserSettingsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateUserPayload",
        "kind": "LinkedField",
        "name": "updateUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "plansUserSettingsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateUserPayload",
        "kind": "LinkedField",
        "name": "updateUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "48475497da3f4f5b41ea943f70419b6c",
    "id": null,
    "metadata": {},
    "name": "plansUserSettingsMutation",
    "operationKind": "mutation",
    "text": "mutation plansUserSettingsMutation(\n  $input: UpdateUserInput!\n) {\n  updateUser(input: $input) {\n    user {\n      hasFreeCrmAccess\n      hasUsedTrialButton\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4dd955c95765981c28b6e3f5895d1e41";

export default node;
