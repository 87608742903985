export function flatten(data: any): { [key: string]: any } {
  const result: { [key: string]: any } = {};

  function recurse(cur: any, prop: string): void {
    if (Object(cur) !== cur) {
      result[prop] = cur;
    } else if (Array.isArray(cur)) {
      const l = cur.length; // Correctly define l as the length of the array
      for (let i = 0; i < l; i++) {
        // Use l here for the loop condition
        recurse(cur[i], prop ? `${prop}[${i}]` : String(i));
      }
      if (l === 0) {
        result[prop] = [];
      }
    } else {
      let isEmpty = true;
      for (const p in cur) {
        isEmpty = false;
        recurse(cur[p], prop ? `${prop}.${p}` : p);
      }
      if (isEmpty && prop) {
        result[prop] = {};
      }
    }
  }

  recurse(data, '');
  return result;
}
