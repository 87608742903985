import { Fragment, useState } from 'react';

import { type ApolloError } from '@apollo/client';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { Button, Divider } from '@mui/material';

import { useLocale } from '../../src/hooks/locale';
import type { UserCard_UserFragment } from '../__generated__/graphql';
import { useAppData } from '../providers/AppDataProvider';

import { ListingAccordion } from './ListingAccordion';
import { EditableUserCard, UserActionCard } from './UserActionCard';

interface SellersActionCardProps {
  isLoading?: boolean;
  sellers: UserCard_UserFragment[];
  openedDefault?: boolean;
  onSellersChanged: (
    sellerIds: string[],
    onError: (err: ApolloError) => void,
  ) => Promise<any>;
}

export const SellersActionCardSkeleton: React.FC<{
  openedDefault?: boolean;
}> = ({ openedDefault = false }) => {
  const { t } = useLocale();

  return (
    <ListingAccordion title={t('Sellers')} defaultExpanded={openedDefault}>
      <UserActionCard
        isLoading
        user={null}
        onUserChanged={() => Promise.resolve()}
      />
      <Divider sx={{ my: 2 }} />
      <Button startIcon={<AddCircleOutlineOutlinedIcon />} size="small">
        {t('Add seller')}
      </Button>
    </ListingAccordion>
  );
};

export const SellersActionCard: React.FC<SellersActionCardProps> = ({
  sellers,
  onSellersChanged,
  isLoading = false,
  openedDefault = false,
}) => {
  const { t } = useLocale();
  const { me } = useAppData();
  const [displayAddSeller, setDisplayAddSeller] = useState(false);

  if (isLoading) {
    return <SellersActionCardSkeleton openedDefault={openedDefault} />;
  }

  const sellerIds = sellers.map(s => s.id);

  return (
    <ListingAccordion title={t('Sellers')} defaultExpanded={openedDefault}>
      {sellers.map(seller => (
        <Fragment key={seller.id}>
          <UserActionCard
            user={seller}
            canDelete={me?.is_admin === true}
            onUserChanged={(userId, onError) => {
              const newIds = sellerIds.filter(id => id !== seller.id);

              if (userId) {
                newIds.push(userId);
              }

              return onSellersChanged(newIds, onError);
            }}
          />
          <Divider sx={{ my: 2 }} />
        </Fragment>
      ))}

      <EditableUserCard
        isEditing={displayAddSeller}
        onChange={async userId => {
          const newIds = [...sellerIds, userId];

          await onSellersChanged(newIds, err => console.error(err));
          setDisplayAddSeller(false);
        }}
        onCancel={() => setDisplayAddSeller(false)}
      >
        <Button
          startIcon={<AddCircleOutlineOutlinedIcon />}
          size="small"
          onClick={() => setDisplayAddSeller(true)}
        >
          {t('Add seller')}
        </Button>
      </EditableUserCard>
    </ListingAccordion>
  );
};
