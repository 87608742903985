/**
 * @generated SignedSource<<8ba5cf3fccdea7d7570dbdd5a4f86e6a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LeadDetailsLatestAppraisalCardQuery$variables = {
  appraisalId: string;
};
export type LeadDetailsLatestAppraisalCardQuery$data = {
  readonly appraisalStatus: {
    readonly isPending: boolean;
    readonly updatedProperty: {
      readonly id: string;
      readonly latestAppraisal: {
        readonly realadvisor: {
          readonly max: number | null;
          readonly min: number | null;
          readonly pricePerSquareMeterBuiltSurface: number | null;
          readonly pricePerSquareMeterLivingSurface: number | null;
          readonly rentalValue: number | null;
          readonly value: number | null;
        } | null;
        readonly updatedAt: string | null;
        readonly " $fragmentSpreads": FragmentRefs<"appraisalGraph_appraisal">;
      } | null;
      readonly propertyType: {
        readonly mainType: string | null;
      } | null;
    } | null;
  } | null;
};
export type LeadDetailsLatestAppraisalCardQuery = {
  response: LeadDetailsLatestAppraisalCardQuery$data;
  variables: LeadDetailsLatestAppraisalCardQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "appraisalId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "appraisalId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isPending",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mainType",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "min",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "max",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "RealadvisorAppraisal",
  "kind": "LinkedField",
  "name": "realadvisor",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v6/*: any*/),
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pricePerSquareMeterLivingSurface",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pricePerSquareMeterBuiltSurface",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rentalValue",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v10 = [
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/),
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LeadDetailsLatestAppraisalCardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AppraisalStatus",
        "kind": "LinkedField",
        "name": "appraisalStatus",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Property",
            "kind": "LinkedField",
            "name": "updatedProperty",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PropertyType",
                "kind": "LinkedField",
                "name": "propertyType",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Appraisal",
                "kind": "LinkedField",
                "name": "latestAppraisal",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "appraisalGraph_appraisal"
                  },
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LeadDetailsLatestAppraisalCardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AppraisalStatus",
        "kind": "LinkedField",
        "name": "appraisalStatus",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Property",
            "kind": "LinkedField",
            "name": "updatedProperty",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PropertyType",
                "kind": "LinkedField",
                "name": "propertyType",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Appraisal",
                "kind": "LinkedField",
                "name": "latestAppraisal",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "IaziAppraisal",
                    "kind": "LinkedField",
                    "name": "iazi",
                    "plural": false,
                    "selections": (v10/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "IaziAppraisal",
                    "kind": "LinkedField",
                    "name": "iazi_cv",
                    "plural": false,
                    "selections": (v10/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Pricehubble",
                    "kind": "LinkedField",
                    "name": "pricehubble",
                    "plural": false,
                    "selections": (v10/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WupAppraisal",
                    "kind": "LinkedField",
                    "name": "wup",
                    "plural": false,
                    "selections": (v10/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PropertydataAppraisal",
                    "kind": "LinkedField",
                    "name": "propertydata",
                    "plural": false,
                    "selections": (v10/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RealadvisorListingAppraisal",
                    "kind": "LinkedField",
                    "name": "realadvisor_listing",
                    "plural": false,
                    "selections": (v10/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RealadvisorNaiveListingsAppraisal",
                    "kind": "LinkedField",
                    "name": "realadvisor_naive_listings",
                    "plural": false,
                    "selections": (v10/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RealadvisorOmniMetaAppraisal",
                    "kind": "LinkedField",
                    "name": "realadvisor_omni_meta",
                    "plural": false,
                    "selections": (v10/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RealadvisorTransactionAppraisal",
                    "kind": "LinkedField",
                    "name": "realadvisor_transaction",
                    "plural": false,
                    "selections": (v10/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RealadvisorOpenDataTransactionAppraisal",
                    "kind": "LinkedField",
                    "name": "realadvisor_open_data_transaction",
                    "plural": false,
                    "selections": (v10/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RealadvisorPerceivedAppraisal",
                    "kind": "LinkedField",
                    "name": "realadvisor_perceived",
                    "plural": false,
                    "selections": (v10/*: any*/),
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1960d0069cdf5e08197229a97a782cf8",
    "id": null,
    "metadata": {},
    "name": "LeadDetailsLatestAppraisalCardQuery",
    "operationKind": "query",
    "text": "query LeadDetailsLatestAppraisalCardQuery(\n  $appraisalId: ID!\n) {\n  appraisalStatus(id: $appraisalId) {\n    isPending\n    updatedProperty {\n      id\n      propertyType {\n        mainType\n        id\n      }\n      latestAppraisal {\n        ...appraisalGraph_appraisal\n        realadvisor {\n          min\n          max\n          value\n          pricePerSquareMeterLivingSurface\n          pricePerSquareMeterBuiltSurface\n          rentalValue\n        }\n        updatedAt\n        id\n      }\n    }\n  }\n}\n\nfragment appraisalGraph_appraisal on Appraisal {\n  iazi {\n    min\n    max\n    value\n    id\n  }\n  iazi_cv {\n    min\n    max\n    value\n    id\n  }\n  pricehubble {\n    min\n    max\n    value\n    id\n  }\n  wup {\n    min\n    max\n    value\n    id\n  }\n  propertydata {\n    min\n    max\n    value\n    id\n  }\n  realadvisor_listing {\n    min\n    max\n    value\n    id\n  }\n  realadvisor_naive_listings {\n    min\n    max\n    value\n    id\n  }\n  realadvisor_omni_meta {\n    min\n    max\n    value\n    id\n  }\n  realadvisor_transaction {\n    min\n    max\n    value\n    id\n  }\n  realadvisor_open_data_transaction {\n    min\n    max\n    value\n    id\n  }\n  realadvisor_perceived {\n    min\n    max\n    value\n    id\n  }\n  realadvisor {\n    min\n    max\n    value\n  }\n}\n"
  }
};
})();

(node as any).hash = "e99bd80ecf6e8ea6e80d45513d396bd5";

export default node;
