/// <reference types="googlemaps" />

import * as React from 'react';
import { useScript } from './script';

const useGoogleApi = (key: string) => {
  // load api lazily on demand
  // which is first usage of autocomplete or geocoder
  const [shouldLoadApi, loadApi] = React.useReducer(() => true, false);
  const url = `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places`;
  const state = useScript(url, shouldLoadApi);
  if (state === 'done' && typeof window === 'object' && window.google) {
    const maps = window.google.maps;
    return [loadApi, maps] as const;
  } else {
    return [loadApi, null] as const;
  }
};

type Callback<Result> = (result: Result) => void;
type RequestFn<Request, Result> = (
  request: Request,
  callback: Callback<Result>,
) => void;
type InvokeRequest<Request, Result> = (
  api: typeof google.maps,
  request: Request,
  callback: Callback<Result>,
) => void;

const useService = <Request, Result>(
  googleKey: string,
  invokeRequest: InvokeRequest<Request, Result>,
): [createRequest: RequestFn<Request, Result>, loading: boolean] => {
  const [loading, setLoading] = React.useState(false);

  // track mounting for safe state changes
  const mounted = React.useRef(false);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  // load google api
  const [loadApi, api] = useGoogleApi(googleKey);

  const hasPendingRequest = React.useRef<boolean>(false);
  const scheduledRequestPair = React.useRef<[Request, Callback<Result>] | null>(
    null,
  );

  const executeRequest: InvokeRequest<Request, Result> = React.useCallback(
    (api, request, callback) => {
      // request predictions
      hasPendingRequest.current = true;
      invokeRequest(api, request, result => {
        // prevent state change after unmount
        if (mounted.current === false) {
          return;
        }
        hasPendingRequest.current = false;
        callback(result);
        if (scheduledRequestPair.current == null) {
          // stop loading only when all requests are finished
          // to prevent jumpy animation
          setLoading(false);
        } else {
          // execute scheduled request
          const [scheduledRequest, scheduledCallback] =
            scheduledRequestPair.current;
          scheduledRequestPair.current = null;
          executeRequest(api, scheduledRequest, scheduledCallback);
        }
      });
    },
    [],
  );

  React.useEffect(() => {
    if (
      api != null &&
      hasPendingRequest.current === false &&
      scheduledRequestPair.current != null
    ) {
      const [scheduledRequest, scheduledCallback] =
        scheduledRequestPair.current;
      scheduledRequestPair.current = null;
      executeRequest(api, scheduledRequest, scheduledCallback);
    }
  }, [api, executeRequest]);

  const createRequest: RequestFn<Request, Result> = (request, callback) => {
    // prevent state change after unmount
    if (mounted.current === false) {
      return;
    }
    // schedule request if api is not loaded
    if (api == null) {
      scheduledRequestPair.current = [request, callback];
      loadApi();
      return;
    }
    // schedule request if there is pending one
    if (hasPendingRequest.current === true) {
      scheduledRequestPair.current = [request, callback];
      return;
    }
    executeRequest(api, request, callback);
  };

  return [createRequest, loading];
};

export type AutocompletionRequest = google.maps.places.AutocompletionRequest;
type AutocompletionRequestInput = Omit<AutocompletionRequest, 'location'> & {
  location?: { lat: number; lng: number };
};
export type AutocompletePrediction = google.maps.places.AutocompletePrediction;

export const useGoogleAutocomplete = (googleKey: string) => {
  const autocompleteServiceCache =
    React.useRef<google.maps.places.AutocompleteService | null>(null);
  const hook = useService<AutocompletionRequestInput, AutocompletePrediction[]>(
    googleKey,
    (api, { location, ...request }, callback) => {
      // empty input doesn't require scheduling
      if (request.input.length === 0) {
        callback([]);
        return;
      }
      // lazily initialize autocomplete service
      let autocompleteService = autocompleteServiceCache.current;
      if (autocompleteService == null) {
        autocompleteService = new api.places.AutocompleteService();
        autocompleteServiceCache.current = autocompleteService;
      }
      // convert location from LatLngLiteral to LatLng
      const normalizedRequest: AutocompletionRequest = request;
      if (location != null) {
        normalizedRequest.location = new api.LatLng(location.lat, location.lng);
      }
      // load predictions
      autocompleteService.getPlacePredictions(
        normalizedRequest,
        (predictions, status) => {
          if (status === 'OK') {
            callback(predictions);
          } else if (status === 'ZERO_RESULTS') {
            callback([]);
          } else {
            console.error(status, predictions, normalizedRequest);
            callback([]);
          }
        },
      );
    },
  );
  return hook;
};

type GeocoderRequest = google.maps.GeocoderRequest;
export type GeocoderResult = google.maps.GeocoderResult;

export const useGoogleGeocoder = (googleKey: string) => {
  const geocoderCache = React.useRef<google.maps.Geocoder | null>(null);
  const hook = useService<GeocoderRequest, GeocoderResult[]>(
    googleKey,
    (api, request, callback) => {
      // lazily initialize geocoder
      let geocoder = geocoderCache.current;
      if (geocoder == null) {
        geocoder = new api.Geocoder();
        geocoderCache.current = geocoder;
      }
      // load predictions
      geocoder.geocode(request, (results, status) => {
        if (status === 'OK') {
          callback(results);
        } else if (status === 'ZERO_RESULTS') {
          callback([]);
        } else {
          console.error(status, results, request);
          callback([]);
        }
      });
    },
  );
  return hook;
};

type GeocoderAddressComponents = google.maps.GeocoderAddressComponent[];

type ParsedComponents = {
  route: null | string;
  streetNumber: null | string;
  postcode: null | string;
  country: null | string;
  countryCode: null | string;
  state: null | string;
  locality: null | string;
  ctn: null | string;
};

const componentByType = (type: string, components: GeocoderAddressComponents) =>
  components.find(component => component.types.includes(type));

// https://en.wikipedia.org/wiki/List_of_administrative_divisions_by_country
const stateComponent = (components: GeocoderAddressComponents) => {
  const country = componentByType('country', components);
  const level1 = componentByType('administrative_area_level_1', components);
  const level2 = componentByType('administrative_area_level_2', components);

  const coutryCode = country?.short_name;

  // For continental France:
  // level 1 = region
  // level 2 = department
  if (coutryCode === 'FR') {
    // If only level 1 is present, it's probably an overseas region
    return level2 != null ? level2 : level1;
  }

  // TODO: United Kingdom support
  // It looks very complex
  // Probably we'll need to sometimes use level 1 and sometimes level 2

  // Switzerland: level 1 = canton
  // Germany: level 1 = state or city (Berlin, Hamburg, Bremen)
  // Spain: level 1 = autonomous community or "plazas de soberanía"
  return level1;
};

export const parseGeocoderComponents = (
  components: GeocoderAddressComponents,
): ParsedComponents => {
  const country = componentByType('country', components);
  const state = stateComponent(components);

  return {
    route: componentByType('route', components)?.long_name ?? null,
    streetNumber:
      componentByType('street_number', components)?.long_name ?? null,
    postcode: componentByType('postal_code', components)?.short_name ?? null,
    countryCode: country?.short_name ?? null,
    country: country?.long_name ?? null,
    state: state?.long_name ?? null,
    locality:
      componentByType('locality', components)?.long_name ??
      componentByType('postal_town', components)?.long_name ??
      null,
    ctn:
      country?.short_name === 'CH'
        ? state?.short_name.slice(0, 2) ?? null
        : null,
  };
};
