// @flow

import { nanoid } from 'nanoid';
import { getAnotherAppOrigin } from '@realadvisor/host-resolver';

const getRefreshToken = () => {
  const refresh_token = localStorage.getItem('refresh_token');
  return refresh_token;
};

type State = {
  runningLoginRequest: null | Promise<Response>;
  runningRefreshRequest: null | Promise<Response | null>;
};

const state: State = {
  runningLoginRequest: null,
  runningRefreshRequest: null,
};

export const loginAnonymously = async () => {
  if (state.runningLoginRequest != null) {
    return await state.runningLoginRequest;
  }

  // skip if token already present
  const old_refresh_token = getRefreshToken();
  if (old_refresh_token != null) {
    return null;
  }

  state.runningLoginRequest = new Promise<Response>((res, rej) => {
    const url = new URL(
      '/anonymous',
      getAnotherAppOrigin(location.origin, 'auth'),
    );

    return fetch(url.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    })
      .then(async response => {
        const new_refresh_token = response.headers.get('x-refresh-token');
        if (new_refresh_token != null) {
          localStorage.setItem('refresh_token', new_refresh_token);
        }
        const responseClone = response.clone();
        const { access_token } = await responseClone.json();
        if (access_token != null) {
          localStorage.setItem('access_token', access_token);
        }
        return res(response);
      })
      .catch(err => rej(err));
  });

  const response = await state.runningLoginRequest;
  state.runningLoginRequest = null;
  return response;
};

const gennerateCodeVerifier = () => {
  return nanoid(128);
};

const arrayBufferToBase64 = (arrayBuffer: ArrayBuffer) => {
  const list = Array.from(new Uint8Array(arrayBuffer));
  const string = list.map(b => String.fromCharCode(b)).join('');
  return window.btoa(string);
};

const generateCodeChallenge = async (code_verifier: string) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(code_verifier);
  const digest = await window.crypto.subtle.digest('SHA-256', data);
  const base64 = arrayBufferToBase64(digest);
  return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
};

type RequestGoogleCodeOptions = {
  client_id: string;
  redirect_back_uri: string;
  target?: '_self' | '_blank';
};

// login by google

export const requestGoogleCode = async (options: RequestGoogleCodeOptions) => {
  const authOrigin = getAnotherAppOrigin(location.origin, 'auth');
  const redirect_uri = `${authOrigin}/forward-code`;
  const code_verifier = gennerateCodeVerifier();
  const code_challenge = await generateCodeChallenge(code_verifier);
  const google_url = new URL('https://accounts.google.com/o/oauth2/v2/auth');
  google_url.searchParams.set('client_id', options.client_id);
  google_url.searchParams.set('redirect_uri', redirect_uri);
  google_url.searchParams.set('response_type', 'code');
  google_url.searchParams.set('scope', 'profile email');
  google_url.searchParams.set('code_challenge', code_challenge);
  google_url.searchParams.set('code_challenge_method', 'S256');
  google_url.searchParams.set(
    'state',
    JSON.stringify({ redirect_uri: options.redirect_back_uri }),
  );
  // store code verifier to use when google redirect back to app
  localStorage.setItem('code_verifier', code_verifier);
  const target: '_self' | '_blank' = options.target ?? '_self';
  if (target === '_self') {
    location.replace(google_url.toString());
  } else if (target === '_blank') {
    open(google_url.toString());
  }
};

export const loginByGoogle = async () => {
  if (state.runningLoginRequest != null) {
    await state.runningLoginRequest;
  }
  const { searchParams } = new URL(window.location.href);
  const code = searchParams.get('code');
  const code_verifier = localStorage.getItem('code_verifier');
  const tenant_id = searchParams.get('tenant_id');
  if (code == null || code_verifier == null) {
    throw Error('Invalid code');
  }
  // fetch token
  const google_code_url = new URL(
    '/google',
    getAnotherAppOrigin(location.origin, 'auth'),
  );
  google_code_url.searchParams.set('code', code);
  google_code_url.searchParams.set('code_verifier', code_verifier);
  if (tenant_id != null) {
    google_code_url.searchParams.set('tenant_id', tenant_id);
  }
  const refresh_token = localStorage.getItem('refresh_token');
  state.runningLoginRequest = fetch(
    google_code_url.toString(),
    bodyFactory(refresh_token),
  );
  const response = await state.runningLoginRequest;
  state.runningLoginRequest = null;

  return await handleLoginResponse(response);
};

// login by facebook

type RequestFacebookCodeOptions = {
  client_id: string;
  redirect_back_uri: string;
  target?: '_self' | '_blank';
};

export const requestFacebookCode = async (
  options: RequestFacebookCodeOptions,
) => {
  const authOrigin = getAnotherAppOrigin(location.origin, 'auth');
  const redirect_uri = `${authOrigin}/forward-code`;
  const code_verifier = gennerateCodeVerifier();
  const code_challenge = await generateCodeChallenge(code_verifier);
  const facebook_url = new URL('https://www.facebook.com/v3.2/dialog/oauth');
  facebook_url.searchParams.set('client_id', options.client_id);
  facebook_url.searchParams.set('redirect_uri', redirect_uri);
  facebook_url.searchParams.set(
    'state',
    JSON.stringify({ redirect_uri: options.redirect_back_uri, code_challenge }),
  );
  facebook_url.searchParams.set('response_type', 'code');
  facebook_url.searchParams.set('scope', 'email,public_profile');
  // store code verifier to use when facebook redirect back to app
  localStorage.setItem('code_verifier', code_verifier);
  const target: '_self' | '_blank' = options.target ?? '_self';
  if (target === '_self') {
    location.replace(facebook_url.toString());
  } else if (target === '_blank') {
    open(facebook_url.toString());
  }
};

export const loginByFacebook = async () => {
  if (state.runningLoginRequest != null) {
    await state.runningLoginRequest;
  }
  const { searchParams } = new URL(window.location.href);
  const code = searchParams.get('code');
  const code_challenge = searchParams.get('code_challenge');
  const code_verifier = localStorage.getItem('code_verifier');
  const tenant_id = searchParams.get('tenant_id');
  if (code == null || code_verifier == null) {
    throw Error('Invalid code');
  }
  // facebook does not support pkce so we check it ourselves
  const expected_code_challenge = await generateCodeChallenge(code_verifier);
  if (code_challenge !== expected_code_challenge) {
    throw Error('Invalid code');
  }
  // fetch token
  const facebook_code_url = new URL(
    '/facebook',
    getAnotherAppOrigin(location.origin, 'auth'),
  );
  facebook_code_url.searchParams.set('code', code);
  if (tenant_id != null) {
    facebook_code_url.searchParams.set('tenant_id', tenant_id);
  }
  const refresh_token = localStorage.getItem('refresh_token');
  state.runningLoginRequest = fetch(
    facebook_code_url.toString(),
    bodyFactory(refresh_token),
  );
  const response = await state.runningLoginRequest;
  state.runningLoginRequest = null;

  return await handleLoginResponse(response);
};

// login by email

export const prepareCodeChallenge = async (): Promise<string> => {
  const code_verifier = gennerateCodeVerifier();
  const code_challenge = await generateCodeChallenge(code_verifier);
  // store code verifier to use when user open email link and go back to app
  localStorage.setItem('code_verifier', code_verifier);
  return code_challenge;
};

export const loginByEmail = async () => {
  if (state.runningLoginRequest != null) {
    await state.runningLoginRequest;
  }
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get('code');
  const tenant_id = searchParams.get('tenant_id');
  if (code == null) {
    throw Error('Invalid code');
  }
  const email_url = new URL(
    '/email',
    getAnotherAppOrigin(location.origin, 'auth'),
  );
  email_url.searchParams.set('code', code);
  if (tenant_id != null) {
    email_url.searchParams.set('tenant_id', tenant_id);
  }
  const refresh_token = localStorage.getItem('refresh_token');
  state.runningLoginRequest = fetch(
    email_url.toString(),
    bodyFactory(refresh_token),
  );
  const response = await state.runningLoginRequest;
  state.runningLoginRequest = null;

  return await handleLoginResponse(response);
};

export const loginByMagicToken = async () => {
  if (state.runningLoginRequest != null) {
    return await state.runningLoginRequest;
  }

  state.runningLoginRequest = new Promise<Response>((res, rej) => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    if (token == null) {
      throw Error('Invalid token');
    }
    const magic_token_url = new URL(
      '/magic-token',
      getAnotherAppOrigin(location.origin, 'auth'),
    );
    magic_token_url.searchParams.set('token', token);
    const refresh_token = localStorage.getItem('refresh_token');
    return fetch(magic_token_url.toString(), bodyFactory(refresh_token))
      .then(async response => {
        const ret = await handleLoginResponse(response);
        return res(ret);
      })
      .catch(err => rej(err));
  });

  const response = await state.runningLoginRequest;
  state.runningLoginRequest = null;

  return response;
};

type LoginByPhoneOptions = {
  number: string;
  code: string;
  tenantId?: string | null;
};

export const loginByPhone = async (options: LoginByPhoneOptions) => {
  if (state.runningLoginRequest != null) {
    await state.runningLoginRequest;
  }
  const phone_url = new URL(
    '/phone',
    getAnotherAppOrigin(location.origin, 'auth'),
  );
  phone_url.searchParams.set('number', options.number);
  phone_url.searchParams.set('code', options.code);
  if (options.tenantId != null) {
    phone_url.searchParams.set('tenant_id', options.tenantId);
  }

  const refresh_token = localStorage.getItem('refresh_token');
  state.runningLoginRequest = fetch(
    phone_url.toString(),
    bodyFactory(refresh_token),
  );
  const response = await state.runningLoginRequest;
  state.runningLoginRequest = null;

  return await handleLoginResponse(response);
};

// switch tenant
export const switchTenant = async () => {
  if (state.runningLoginRequest != null) {
    await state.runningLoginRequest;
  }
  const searchParams = new URLSearchParams(location.search);
  const tenant_id = searchParams.get('tenant_id');
  if (tenant_id == null) {
    throw Error('Tenant id is not specified');
  }
  const switch_tenant_url = new URL(
    '/multitenant',
    getAnotherAppOrigin(location.origin, 'auth'),
  );
  switch_tenant_url.searchParams.set('tenant_id', tenant_id);
  const refresh_token = localStorage.getItem('refresh_token');
  state.runningLoginRequest = fetch(
    switch_tenant_url.toString(),
    bodyFactory(refresh_token),
  );
  const response = await state.runningLoginRequest;
  state.runningLoginRequest = null;

  return await handleLoginResponse(response);
};

type RegisterUserOptions = {
  email: string;
};

export const registerUser = async (options: RegisterUserOptions) => {
  if (state.runningLoginRequest != null) {
    await state.runningLoginRequest;
  }
  const register_url = new URL(
    '/register',
    getAnotherAppOrigin(location.origin, 'auth'),
  );
  register_url.searchParams.set('email', options.email);
  const refresh_token = localStorage.getItem('refresh_token');
  state.runningLoginRequest = fetch(
    register_url.toString(),
    bodyFactory(refresh_token),
  );
  const response = await state.runningLoginRequest;
  state.runningLoginRequest = null;

  return await handleLoginResponse(response);
};

type LoginAsUserOptions = {
  user_id: null | string;
};

export const loginAsUser = async (options: LoginAsUserOptions) => {
  if (state.runningLoginRequest != null) {
    await state.runningLoginRequest;
  }
  const login_as_user_url = new URL(
    '/login-as-user',
    getAnotherAppOrigin(location.origin, 'auth'),
  );
  if (options.user_id != null) {
    login_as_user_url.searchParams.set('user_id', options.user_id);
  }
  const refresh_token = localStorage.getItem('refresh_token');
  state.runningLoginRequest = fetch(
    login_as_user_url.toString(),
    bodyFactory(refresh_token),
  );
  await state.runningLoginRequest;
  state.runningLoginRequest = null;
  // force renew token to get one with the right user_id
  await refreshToken();
  location.reload();
};

export const logoutUser = async () => {
  const logout_url = new URL(
    '/logout',
    getAnotherAppOrigin(location.origin, 'auth'),
  );
  const refresh_token = localStorage.getItem('refresh_token');
  if (refresh_token != null) {
    await fetch(logout_url.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'x-refresh-token': refresh_token,
      },
    });
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('access_token');

    // to force clean all server data etc
    location.reload();
  }
};

export const refreshToken = async () => {
  if (state.runningRefreshRequest != null) {
    await state.runningRefreshRequest;
    return null;
  }
  state.runningRefreshRequest = new Promise<Response | null>((res, rej) => {
    const existing = getRefreshToken();
    const headers = new Headers();
    if (existing != null) {
      headers.set('x-refresh-token', existing);
    } else {
      // clear access token when refresh token is not present
      localStorage.removeItem('access_token');
      return res(null);
    }
    const endpoint = `${getAnotherAppOrigin(
      location.origin,
      'auth',
    )}/refresh-token`;
    return fetch(endpoint, {
      method: 'POST',
      credentials: 'include',
      headers,
    })
      .then(async response => {
        if (response.ok) {
          const responseClone = response.clone();
          const { access_token } = await responseClone.json();
          if (access_token != null) {
            localStorage.setItem('access_token', access_token);
          }
          state.runningRefreshRequest = null;
          return res(response);
        }
        // clear saved refresh token when status is 401
        // which means refresh token is expired
        if (response.status === 401) {
          localStorage.removeItem('refresh_token');
          localStorage.removeItem('access_token');
        }

        return res(null);
      })
      .catch(err => rej(err));
  });

  const response = await state.runningRefreshRequest;
  state.runningRefreshRequest = null;

  return response;
};

export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

const handleLoginResponse = async (response: Response) => {
  const new_refresh_token = response.headers.get('x-refresh-token');
  if (new_refresh_token != null) {
    localStorage.setItem('refresh_token', new_refresh_token);
  }
  const responseClone = response.clone();
  const { access_token } = await responseClone.json();
  if (access_token != null) {
    localStorage.setItem('access_token', access_token);
  }

  return response;
};

const bodyFactory = (refresh_token: string | null): RequestInit | undefined => {
  return {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      ...(refresh_token && {
        'x-refresh-token': refresh_token,
      }),
    },
  };
};
