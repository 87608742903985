import * as React from 'react';

import type { Interpolation } from '@emotion/react';
import { TableSortLabel } from '@material-ui/core';
import { Link, type To } from 'react-router-dom';

import { useLocale } from '../../hooks/locale';
import { useTheme } from '../../hooks/theme';
import type { SortDirection, Sorting } from '../../utils/sorting';

type Alignment = 'left' | 'center' | 'right';
type ColumnTypes = 'image';

export type ColumnConfig<T> = {
  width: number;
  label?: React.ReactNode;
  grow?: number;
  type?: ColumnTypes;
  alignment?: Alignment;
  clickable?: boolean;
  sortBy?: string;
  nullsLast?: boolean;
  cellRenderer: (props: { node: T; rowIndex: number }) => React.ReactNode;
};

export type HeadColumn = {
  width: number;
  label: React.ReactNode;
  grow: number;
  alignment: Alignment;
  sortBy: string | null;
  nullsLast?: boolean;
};

export type RowColumn<T> = {
  width: number;
  grow: number;
  alignment: Alignment;
  type: ColumnTypes | null;
  clickable: boolean;
  cellRenderer: (props: { node: T; rowIndex: number }) => React.ReactNode;
};

type HeadProps = {
  width: number;
  height: number;
  sorting: Sorting | null;
  columns: HeadColumn[];
  onSort: (sorting: Sorting | null) => void;
  initialSortDirection?: SortDirection;
};

type FooterProps = {
  //width: number;
  height: number;
  displayedCount?: number | null;
  totalCount?: number | null;
  onShowTotalCount?: () => void | null;
};

type RowProps<T> = {
  width: number;
  height: number;
  columns: RowColumn<T>[];
  rowIndex: number;
  node: T;
  hoveredRow: number;
  onRowHover: null | ((props: { rowIndex: number }) => void);
  onRowClick: null | ((props: { node: T }) => void);
  getRowLink: null | ((props: { node: T; rowIndex: number }) => To);
  getRowStyle: null | ((props: { node: T }) => null | Interpolation<unknown>);
  rowTarget: null | '_self' | '_blank';
  children:
    | null
    | ((props: {
        node: T;
        rowIndex: number;
        link: null | To;
        hovered: boolean;
        onMouseOver: null | (() => void);
        width: number;
        height: number;
        children: React.ReactNode;
      }) => React.ReactNode);
};

export const getHeadColumn = <T extends unknown>(
  config: ColumnConfig<T>,
): HeadColumn => ({
  label: config.label != null ? config.label : null,
  width: config.width,
  grow: config.grow != null ? config.grow : 0,
  alignment: config.alignment != null ? config.alignment : 'left',
  sortBy: config.sortBy != null ? config.sortBy : null,
  nullsLast: config.nullsLast != null ? config.nullsLast : false,
});

export const getRowColumn = <T extends unknown>(
  config: ColumnConfig<T>,
): RowColumn<T> => ({
  width: config.width,
  grow: config.grow != null ? config.grow : 0,
  alignment: config.alignment != null ? config.alignment : 'left',
  type: config.type || null,
  cellRenderer: config.cellRenderer,
  clickable: config.clickable != null ? config.clickable : false,
});

const alignmentToJustifyCell = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
} as const;

const alignmentToJustifyHead = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-start',
};

const alignementToFlexDirection = {
  left: 'row',
  center: 'row',
  right: 'row-reverse',
} as const;

const toggleSort = (
  sortBy: null | string,
  value: null | Sorting,
  initialSortDirection: SortDirection = 'asc',
  nullsLast = false,
): Sorting | null => {
  if (!value || value.sortBy !== sortBy) {
    return {
      sortBy,
      sortDirection: initialSortDirection,
      nullsLast,
    };
  }

  if (value.sortDirection === initialSortDirection) {
    return {
      sortBy,
      sortDirection: initialSortDirection === 'asc' ? 'desc' : 'asc',
      nullsLast,
    };
  }
  return null;
};

const HeadCell = ({
  column,
  sorting,
  onSort,
  initialSortDirection,
}: {
  column: HeadColumn;
  sorting: null | Sorting;
  onSort: (sorting: null | Sorting) => void;
  initialSortDirection?: SortDirection;
}) => {
  const { text, colors } = useTheme();

  const active = sorting?.sortBy != null && sorting.sortBy === column.sortBy;

  return (
    <div
      css={{
        flex: `${column.grow} 0 ${column.width * 8}px`,
        display: 'flex',
        alignItems: 'center',
        flexDirection: alignementToFlexDirection[column.alignment],
        justifyContent: alignmentToJustifyHead[column.alignment],
        boxSizing: 'border-box',
        padding: '0 8px',
        cursor: 'default',
        color: colors.highText,
        fontWeight: text.font.bold,
        fontSize: 12,
        overflow: 'hidden',
        lineHeight: '14px',
      }}
    >
      {column.sortBy != null ? (
        <TableSortLabel
          active={active}
          direction={
            active &&
            sorting?.sortDirection != null &&
            ['asc', 'asc_nulls_last'].includes(sorting.sortDirection)
              ? 'asc'
              : 'desc'
          }
          onClick={() =>
            onSort(
              toggleSort(
                column.sortBy,
                sorting,
                initialSortDirection,
                column.nullsLast === true ? true : false,
              ),
            )
          }
        >
          {column.label}
        </TableSortLabel>
      ) : (
        <span>{column.label}</span>
      )}
    </div>
  );
};

export const Head = (props: HeadProps) => {
  const { colors } = useTheme();
  return (
    <div
      css={{
        flex: '0 0 auto',
        display: 'flex',
        height: props.height,
        paddingTop: 8,
        paddingBottom: 8,
        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.2)',
        background: colors.white,
        position: 'relative',
        zIndex: 1,
        minWidth: props.width,
      }}
    >
      {props.columns.map((column, columnIndex) => (
        <HeadCell
          key={columnIndex}
          column={column}
          sorting={props.sorting}
          onSort={props.onSort}
          initialSortDirection={props.initialSortDirection}
        />
      ))}
    </div>
  );
};

export const Footer = (props: FooterProps) => {
  const { colors } = useTheme();
  const { locale } = useLocale();
  const { displayedCount, totalCount } = props;
  const formattedTotalCount = totalCount
    ? totalCount.toLocaleString(locale, {
        minimumFractionDigits: 0,
      })
    : 0;
  const formattedDisplayedCount = displayedCount
    ? displayedCount.toLocaleString(locale, {
        minimumFractionDigits: 0,
      })
    : '...';
  return (
    <div
      onClick={props.onShowTotalCount}
      css={{
        flex: '0 0 auto',
        display: 'flex',
        height: props.height,
        paddingTop: 8,
        paddingBottom: 8,
        paddingRight: 8,
        paddingLeft: 8,
        boxShadow: '0 -1px 2px rgba(0, 0, 0, 0.2)',
        background: colors.white,
        position: 'relative',
        zIndex: 1,
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <a
        onClick={props.onShowTotalCount}
        css={{
          cursor: totalCount !== 500 ? 'default' : 'pointer',
          padding: 8,
        }}
      >
        Showing {formattedDisplayedCount} of {totalCount === 500 && '>'}
        {formattedTotalCount}
      </a>
    </div>
  );
};

const RowBox = ({
  style,
  width,
  height,
  link,
  hovered,
  onMouseOver,
  children,
}: {
  style: null | Interpolation<unknown>;
  width: number;
  height: number;
  link: To | null;
  hovered: boolean;
  onMouseOver: null | (() => void);
  children: React.ReactNode;
}) => {
  const { colors } = useTheme();
  return (
    <div
      css={[
        {
          display: 'flex',
          background: hovered ? colors.grey200 : colors.white,
          borderBottom: `1px solid ${colors.grey300}`,
          minWidth: width,
          height,
        },
        link != null && {
          cursor: 'pointer',
          ':hover': {
            background: colors.grey200,
          },
        },
        style,
      ]}
      onMouseOver={onMouseOver ?? undefined}
    >
      {children}
    </div>
  );
};

const RowLink = ({
  style,
  to,
  target,
  onClick,
  children,
}: {
  style: null | Interpolation<unknown>;
  to: To;
  target: string;
  onClick: null | ((event: React.MouseEvent) => void);
  children: React.ReactNode;
}) => {
  return (
    <Link
      css={[
        style,
        {
          color: 'inherit',
          textDecoration: 'none',
          cursor: 'pointer',
          outline: 'none',
        },
      ]}
      to={to}
      target={target}
      onClick={onClick ?? undefined}
    >
      {children}
    </Link>
  );
};

const RowCell = <T extends unknown>({
  column,
  to,
  target,
  children,
  onClick,
}: {
  column: RowColumn<T>;
  to: null | To;
  target: null | '_self' | '_blank';
  children: React.ReactNode;
  onClick: null | ((event: React.MouseEvent) => void);
}) => {
  const style = {
    flex: `${column.grow} 0 ${column.width * 8}px`,
    display: 'flex',
    justifyContent: alignmentToJustifyCell[column.alignment],
    alignItems: column.type === 'image' ? 'stretch' : 'center',
    boxSizing: 'border-box',
    padding: column.type === 'image' ? 0 : '0 8px',
    cursor: 'default',
    fontSize: 14,
    overflow: 'hidden',
    pointerEvents: 'auto',
  } as const;
  if (to == null) {
    return (
      <div css={style} onClick={onClick ?? undefined}>
        {children}
      </div>
    );
  } else {
    return (
      <RowLink
        style={style}
        to={to}
        target={target ?? '_self'}
        onClick={onClick}
      >
        {children}
      </RowLink>
    );
  }
};

export class Row<T> extends React.PureComponent<RowProps<T>> {
  render() {
    const {
      columns,
      rowIndex,
      node,
      width,
      height,
      hoveredRow,
      onRowHover,
      onRowClick,
      getRowLink,
      getRowStyle,
      rowTarget,
      children,
    } = this.props;

    const link = getRowLink != null ? getRowLink({ node, rowIndex }) : null;
    const style = getRowStyle != null ? getRowStyle({ node }) : null;

    const rowRender =
      children ||
      (props => (
        <RowBox
          style={style}
          width={props.width}
          height={props.height}
          link={props.link}
          hovered={props.hovered}
          onMouseOver={props.onMouseOver}
        >
          {props.children}
        </RowBox>
      ));

    return rowRender({
      hovered: hoveredRow === rowIndex,
      onMouseOver: onRowHover ? () => onRowHover({ rowIndex }) : null,
      link,
      width,
      height,
      rowIndex,
      node,
      children: columns.map((column: RowColumn<any>, columnIndex) => (
        <RowCell
          key={columnIndex}
          column={column}
          to={link != null && !column.clickable ? link : null}
          onClick={
            onRowClick
              ? e => {
                  if (!e.ctrlKey && !e.metaKey) {
                    e.preventDefault();
                    onRowClick({ node });
                  }
                }
              : null
          }
          target={rowTarget}
        >
          {column.cellRenderer({ node, rowIndex })}
        </RowCell>
      )),
    });
  }
}
