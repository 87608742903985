// @flow

import * as React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { graphql, useLazyLoadQuery } from 'react-relay';

import { ProgressButton } from '../controls/progress-button';
import { useLocale } from '../hooks/locale';

import type {
  PipelineDialogQuery,
  PipelineLeadType,
} from './__generated__/PipelineDialogQuery.graphql';
type Props = {|
  pipelines: PipelineLeadType,
  open: boolean,
  initialStageId?: ?string,
  pipelineId?: string,
  onChange: ({| stageId: string, pipelineId: string |}) => void,
  loading?: boolean,
  onClose: () => void,
|};

const PipelineDialogContent = props => {
  const { t } = useLocale();
  const [form, setForm] = React.useState({
    pipelineId: props.pipelineId,
    stageId: props.initialStageId,
  });

  const { pipelines } = useLazyLoadQuery<PipelineDialogQuery>(
    graphql`
      query PipelineDialogQuery($leadType: PipelineLeadType) {
        pipelines(leadType: $leadType) {
          id
          label
          stages {
            id
            label
          }
        }
      }
    `,
    { leadType: props.pipelines },
  );

  const selectedPipeline =
    pipelines.length === 1
      ? pipelines[0]
      : form.pipelineId != null
      ? pipelines.find(pipeline => pipeline.id === form.pipelineId)
      : form.stageId != null
      ? pipelines.find(pipeline =>
          pipeline.stages.some(stage => stage.id === form.stageId),
        )
      : null;
  const stages = selectedPipeline?.stages ?? [];

  return (
    <>
      <DialogTitle>
        {pipelines.length > 1
          ? t('Change pipeline and stage')
          : t('Change stage')}
      </DialogTitle>
      <DialogContent>
        {pipelines.length > 1 && (
          <TextField
            select={true}
            label={t('pipeline')}
            value={selectedPipeline?.id ?? ''}
            variant="filled"
            margin="normal"
            onChange={event =>
              setForm({ pipelineId: event.target.value, stageId: null })
            }
          >
            {pipelines.map(pipeline => (
              <MenuItem key={pipeline.id} value={pipeline.id}>
                {pipeline.label ?? null}
              </MenuItem>
            ))}
          </TextField>
        )}

        <TextField
          select={true}
          label={t('stage')}
          value={form.stageId ?? ''}
          variant="filled"
          margin="normal"
          disabled={stages.length === 0}
          onChange={event => {
            setForm({
              pipelineId: form.pipelineId,
              stageId: event.target.value,
            });
          }}
        >
          {stages.map(stage => (
            <MenuItem key={stage.id} value={stage.id}>
              {stage.label ?? null}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>

      <DialogActions>
        <Button color="inherit" onClick={props.onClose}>
          {t('cancel')}
        </Button>
        <ProgressButton
          loading={props?.loading ?? false}
          disabled={form.stageId == null}
          color="primary"
          variant="contained"
          onClick={() => {
            const { stageId } = form;
            const pipelineId = selectedPipeline?.id;
            if (stageId != null && pipelineId != null) {
              props.onChange({ stageId, pipelineId });
            }
          }}
        >
          {t('confirm')}
        </ProgressButton>
      </DialogActions>
    </>
  );
};

export const PipelineDialog = (props: Props): React.Node => {
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <React.Suspense fallback={null}>
        <PipelineDialogContent
          initialStageId={props.initialStageId}
          pipelineId={props.pipelineId}
          pipelines={props.pipelines}
          onClose={props.onClose}
          onChange={props.onChange}
          loading={props.loading}
        />
      </React.Suspense>
    </Dialog>
  );
};
