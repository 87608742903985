import * as React from 'react';
import { dequal } from 'dequal';
import type { Interpolation } from '@emotion/react';

type FormProps = {
  css?: Interpolation<unknown>;
  className?: string;
  onSubmit: () => void;
  children: React.ReactNode;
};

export const Form = ({
  css: cssProp,
  className,
  onSubmit,
  children,
}: FormProps) => {
  return (
    <form
      noValidate={true}
      css={cssProp}
      className={className}
      onSubmit={event => {
        event.preventDefault();
        event.stopPropagation();
        onSubmit();
      }}
    >
      {/* hack to allow to trigger submit from inputs */}
      <button hidden={true} />
      {children}
    </form>
  );
};

export type FormikErrors<Values> = Partial<{
  [K in keyof Values]?: null | string;
}>;

export type FormikTouched<Values> = Partial<{
  [K in keyof Values]?: null | boolean;
}>;

type State<Values> = {
  values: Values;
  errors: FormikErrors<Values>;
  responseErrors: FormikErrors<Values>;
  touched: FormikTouched<Values>;
};

type Action<Values> =
  | {
      type: 'setValues';
      values: Partial<Values> | ((values: Values) => Partial<Values>);
    }
  | { type: 'setTouched'; touched: FormikTouched<Values> }
  | { type: 'setResponseErrors'; responseErrors: FormikErrors<Values> }
  | { type: 'submit' }
  | { type: 'reset' };

export type FormikParams<Values> = {
  initialValues: Values;
  validate: (values: Values) => FormikErrors<Values>; // TODO | Promise<FormikErrors<Values>>,
  areValuesEqual?: (a: Values, b: Values) => boolean;
  onSubmit: (values: Values) => void | Promise<void>;
  onInvalidSubmit?: (values: Values, errors: Array<string>) => void;
};

export type FormikHook<Values> = {
  values: Values;
  errors: FormikErrors<Values>;
  changed: boolean;
  ready: boolean;
  valid: boolean;
  setValues: (
    setter: Partial<Values> | ((values: Values) => Partial<Values>),
  ) => void;
  setTouched: (touched: FormikTouched<Values>) => void;
  setResponseErrors: (errors: FormikErrors<Values>) => void;
  submitForm: () => void;
  resetForm: () => void;
};

type ValuesObject = Record<string | number, unknown>;

const fillObject = (values: ValuesObject, fillingValue: boolean | null) => {
  const result: ValuesObject = {};
  for (const key of Object.keys(values)) {
    result[key] = fillingValue;
  }
  return result;
};

const areAllObjectValuesNullable = (values: ValuesObject) =>
  !Object.values(values).some(value => value != null);

const getNonNullableValues = (values: ValuesObject) =>
  Object.values(values)
    .filter(value => value != null)
    .map(String);

export const useFormik = <T extends Record<string | number, unknown>>({
  initialValues,
  validate,
  areValuesEqual = dequal,
  onSubmit,
  onInvalidSubmit,
}: FormikParams<T>): FormikHook<T> => {
  const reducer = (state: State<T>, action: Action<T>) => {
    if (action.type === 'setValues') {
      const values =
        typeof action.values === 'function'
          ? action.values(state.values)
          : action.values;
      return {
        values: {
          ...state.values,
          ...values,
        },
        touched: { ...state.touched, ...fillObject(values, false) },
        errors: state.errors,
        responseErrors: {
          ...state.responseErrors,
          ...fillObject(values, null),
        },
      };
    }
    if (action.type === 'setTouched') {
      return {
        values: state.values,
        touched: { ...state.touched, ...action.touched },
        errors: validate(state.values),
        responseErrors: state.responseErrors,
      };
    }
    if (action.type === 'submit') {
      const errors = validate(state.values);
      return {
        values: state.values,
        touched: { ...state.touched, ...fillObject(errors, true) },
        errors,
        responseErrors: state.responseErrors,
      };
    }
    if (action.type === 'reset') {
      return {
        values: initialValues,
        touched: {},
        errors: {},
        responseErrors: {},
      };
    }
    if (action.type === 'setResponseErrors') {
      return {
        values: state.values,
        touched: state.touched,
        errors: state.errors,
        responseErrors: action.responseErrors,
      };
    }
    throw Error('Invalid action');
  };

  const [state, dispatch] = React.useReducer<
    React.Reducer<State<T>, Action<T>>
  >(reducer, {
    values: initialValues,
    errors: {},
    responseErrors: {},
    touched: {},
  });

  const changed = areValuesEqual(initialValues, state.values) === false;

  const touchedErrors = React.useMemo(() => {
    const result: FormikErrors<T> = {};
    for (const key in state.errors) {
      if (state.errors[key] != null && state.touched[key] === true) {
        result[key] = state.errors[key];
      }
    }
    return result;
  }, [state.errors, state.touched]);

  const responseErrors = state.responseErrors;

  const mergedErrors = React.useMemo(() => {
    const result: FormikErrors<T> = {};
    for (const key in touchedErrors) {
      const value = touchedErrors[key];
      if (typeof value === 'string') {
        result[key] = value;
      }
    }
    for (const key in responseErrors) {
      const value = responseErrors[key];
      if (value != null) {
        result[key] = value;
      }
    }
    return result;
  }, [touchedErrors, responseErrors]);

  const valid = React.useMemo(
    () =>
      areAllObjectValuesNullable(touchedErrors) &&
      areAllObjectValuesNullable(responseErrors),
    [touchedErrors, responseErrors],
  );

  const ready = valid && areAllObjectValuesNullable(validate(state.values));

  const setValuesWithTouchedReset = (
    newValues: Partial<T> | ((values: T) => Partial<T>),
  ) => dispatch({ type: 'setValues', values: newValues });

  const setTouchedWithValidation = (newTouched: FormikTouched<T>) =>
    dispatch({ type: 'setTouched', touched: newTouched });

  const setResponseErrors = (newResponseErrors: FormikErrors<T>) =>
    dispatch({ type: 'setResponseErrors', responseErrors: newResponseErrors });

  const submitForm = () => {
    dispatch({ type: 'submit' });
    const newErrors = validate(state.values);
    if (areAllObjectValuesNullable(newErrors)) {
      onSubmit(state.values);
    } else if (onInvalidSubmit) {
      onInvalidSubmit(state.values, getNonNullableValues(newErrors));
    }
  };

  const resetForm = () => {
    dispatch({ type: 'reset' });
  };

  return {
    values: state.values,
    errors: mergedErrors,
    changed,
    ready,
    valid,
    setValues: setValuesWithTouchedReset,
    setTouched: setTouchedWithValidation,
    setResponseErrors,
    submitForm,
    resetForm,
  };
};
