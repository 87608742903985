import { format, formatDistance, isToday } from 'date-fns';
import { Box, Flex } from 'react-system';

import { useLocale } from '../../hooks/locale';
import { useTheme } from '../../hooks/theme';
import { AccessTime } from '../../icons/access-time';
import { RoundWarning } from '../../icons/round-warning';

export const FeedOverdue = ({
  startDate,
  isOverdue,
  dueAt,
  showDate = true,
}: {
  startDate: Date | null;
  isOverdue: boolean | null;
  dueAt: Date;
  showDate?: boolean;
}) => {
  const { text, colors, textColor } = useTheme();
  const { t, dateLocale } = useLocale();

  return (
    <>
      <AccessTime
        fill={isOverdue === true ? colors.errorText : 'currentColor'}
      />
      <div
        css={[
          text.body2,
          {
            color: isOverdue === true ? colors.errorText : undefined,
          },
        ]}
      >
        <Flex alignItems="center">
          {startDate == null
            ? format(dueAt, 'd MMMM yyyy HH:mm', {
                locale: dateLocale,
              })
            : format(startDate, 'd MMMM yyyy', {
                locale: dateLocale,
              })}
          {isOverdue === true && (
            <Flex ml={2} alignItems="center">
              <Box mr={1}>
                <RoundWarning size={20} fill={colors.error} />
              </Box>
              <Box css={[text.caption, { fontWeight: text.font.medium }]}>
                {t('overdue')}
              </Box>
            </Flex>
          )}
        </Flex>
        {showDate && (
          <div css={[text.caption, textColor('mediumText')]}>
            (
            {startDate != null && isToday(startDate)
              ? t('Today')
              : formatDistance(dueAt, Date.now(), {
                  locale: dateLocale,
                  addSuffix: true,
                })}
            )
          </div>
        )}
      </div>
    </>
  );
};
