// Generated from src/svgs/expand_less.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const ExpandLess = ({ size, fill, className }: Props) => {
  return (
    <svg viewBox="0 0 18 18" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><path d="m9 6-4.5 4.5 1.06 1.06L9 8.12l3.44 3.44 1.06-1.06z" /></svg>
  );
}
