// @flow

import * as React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { Form, useFormik } from '@realadvisor/form';
import { graphql, useFragment, useMutation } from 'react-relay';
import { Box, useResponsive, useSystem } from 'react-system';

import { DateInput, parseDate } from '../controls/date-input';
import { NumberInput } from '../controls/number-input';
import { PercentInput } from '../controls/percent-input';
import { PriceInput } from '../controls/price-input';
import { ProgressButton } from '../controls/progress-button';
import { useLocale } from '../hooks/locale';
import { useTheme } from '../hooks/theme';
import { toISODateString } from '../utils/date-utils';

import type { lotMandateCreateDialog_lead$key } from './__generated__/lotMandateCreateDialog_lead.graphql';
import type { lotMandateCreateDialog_root$key } from './__generated__/lotMandateCreateDialog_root.graphql';
import type { lotMandateCreateDialogMutation } from './__generated__/lotMandateCreateDialogMutation.graphql';
import { type User, UserInput } from './user-input';
import { UserMultiInput, getMultiUserShrink } from './user-input';

type Props = {|
  lead: lotMandateCreateDialog_lead$key,
  root: lotMandateCreateDialog_root$key,
  open: boolean,
  onCreate: (lotId: string) => void,
  onClose: () => void,
|};

const string_of_number = (number: ?number) =>
  number != null ? String(number) : '';

const number_of_string = (string: string) => {
  const parsed = Number.parseFloat(string);
  if (Number.isNaN(parsed)) {
    return null;
  }
  return parsed;
};

const isPriceWithinLimit = (price: string) => {
  const _price = number_of_string(price);
  const MAX_MANDATE_SALE_PRICE_LIMIT = 1000000000;

  if (_price != null && MAX_MANDATE_SALE_PRICE_LIMIT > _price) {
    return true;
  }
  return false;
};

const mandateType_of_string = string => {
  switch (string) {
    case 'commission_based':
      return string;
    case 'fixed_fee':
      return string;
    default:
      null;
  }
};

const expectedTimeToSell_of_string = string => {
  switch (string) {
    case 'under_3_month':
      return string;
    case 'under_6_month':
      return string;
    case 'under_12_month':
      return string;
    case 'showcase_listing':
      return string;
    case 'not_set':
      return string;
    default:
      null;
  }
};

const LeadLotForm = props => {
  const lead = useFragment(
    graphql`
      fragment lotMandateCreateDialog_lead on Lead {
        id
        contact {
          ...userInput_user @relay(mask: false)
        }
        broker {
          ...userInput_user @relay(mask: false)
        }
        property {
          id
        }
      }
    `,
    props.lead,
  );

  const mandateOrigins = useFragment(
    graphql`
      fragment lotMandateCreateDialog_root on Query {
        originTypes: dictionaries(type: "lot_origin_types") {
          id
          label
          name
        }
      }
    `,
    props.root,
  );

  const { media } = useSystem();
  const { t } = useLocale();
  const { text } = useTheme();
  const [createLot] = useMutation<lotMandateCreateDialogMutation>(
    graphql`
      mutation lotMandateCreateDialogMutation($input: UpsertLotInput!) {
        upsertLot(input: $input) {
          lot {
            id
          }
        }
      }
    `,
  );

  const defaultOrigin = mandateOrigins.originTypes.find(
    origin => origin.name === 'realadvisor_appraisal',
  );

  const [loading, setLoading] = React.useState(false);
  const form = useFormik({
    initialValues: {
      title: '',
      broker: lead.broker,
      isExclusive: false,
      commissionRate: string_of_number(0),
      signedAt: '',
      origin: defaultOrigin?.id ?? '',
      collaborators: ([]: $ReadOnlyArray<User>),
      sellers: lead.contact ? [lead.contact] : [],
      isSalesTaxInclusive: false,
      mandateSalePrice: '',
      mandateType: '',
      expectedTimeToSell: 'not_set',
      durationInMonths: '',
      fixedFee: '',
    },

    validate: values => {
      const errors = {};
      if (values.mandateSalePrice === '') {
        errors.mandateSalePrice = t('salePriceRequired');
      } else if (!isPriceWithinLimit(values.mandateSalePrice)) {
        errors.mandateSalePrice = t('enterPriceLowerThanPriceLimit');
      }

      if (values.mandateType === '') {
        errors.mandateType = t('mandateTypeRequired');
      }
      if (values.mandateType === 'fixed_fee') {
        if (values.fixedFee === '') {
          errors.fixedFee = t('fixedFeeRequired');
        } else if (!isPriceWithinLimit(values.fixedFee)) {
          errors.fixedFee = t('enterPriceLowerThanPriceLimit');
        }
      }
      if (values.expectedTimeToSell === 'not_set') {
        errors.expectedTimeToSell = t('expectedTimeToSellRequired');
      }
      if (values.signedAt === '') {
        errors.signedAt = t('signedAtRequired');
      } else if (parseDate(values.signedAt) == null) {
        errors.signedAt = t('invalidDate');
      }
      if (values.durationInMonths === '') {
        errors.durationInMonths = t('durationInMonthsRequired');
      }
      return errors;
    },

    onSubmit: values => {
      setLoading(true);
      const commissionRate = number_of_string(values.commissionRate);
      const input = {
        title: values.title,
        signedAt: toISODateString(parseDate(values.signedAt)),
        originId: values.origin,
        mandateSalePrice: number_of_string(values.mandateSalePrice),
        mandateType: mandateType_of_string(values.mandateType),
        expectedTimeToSell: expectedTimeToSell_of_string(
          values.expectedTimeToSell,
        ),
        durationInMonths: number_of_string(values.durationInMonths),
        fixedFee: number_of_string(values.fixedFee),
        leadId: lead.id,
        propertyId: lead.property?.id,
        isExclusive: values.isExclusive,
        isSalesTaxInclusive: values.isSalesTaxInclusive,
        brokerId: values.broker?.id ?? null,
        commissionRate: commissionRate != null ? commissionRate / 100 : null,
        collaboratorsIds: values.collaborators.map(b => b.id),
        sellersIds: values.sellers.map(b => b.id),
      };
      createLot({
        variables: { input: { lot: input } },
        onCompleted: data => {
          if (data.upsertLot?.lot) {
            props.onCreate(data.upsertLot.lot.id);
          }
        },
      });
    },
  });

  const EXPECTED_TIME_TO_SELL_OPTIONS: {| id: string, label: string |}[] = [
    { id: 'under_3_month', label: t('expectedTimeToSell0to3') },
    { id: 'under_6_month', label: t('expectedTimeToSell3to6') },
    { id: 'under_12_month', label: t('expectedTimeToSell6to12') },
    { id: 'showcase_listing', label: t('expectedTimeToSellShowcaseListing') },
  ];

  return (
    <>
      <DialogContent>
        <Form css={{ display: 'grid', gap: 16 }} onSubmit={form.submitForm}>
          <Box css={text.subtitle2}>{t('stakeholders')}</Box>

          <FormControl>
            <InputLabel shrink={getMultiUserShrink(form.values.sellers)}>
              {t('sellers')}
            </InputLabel>
            <UserMultiInput
              value={form.values.sellers}
              onChange={users => form.setValues({ sellers: users })}
            />
          </FormControl>

          <FormControl>
            <InputLabel>{t('broker')}</InputLabel>
            <UserInput
              creatable={false}
              filters={{ isBroker: true }}
              value={form.values.broker}
              onChange={user => form.setValues({ broker: user })}
            />
          </FormControl>

          <FormControl>
            <InputLabel shrink={getMultiUserShrink(form.values.collaborators)}>
              {t('collaborators')}
            </InputLabel>
            <UserMultiInput
              filters={{ isBroker: true }}
              value={form.values.collaborators}
              onChange={users => form.setValues({ collaborators: users })}
            />
          </FormControl>

          <Box css={text.subtitle2}>{t('terms')}</Box>

          <FormControl
            required={true}
            error={form.errors.mandateSalePrice != null}
          >
            <InputLabel shrink={true}>{t('salePrice')}</InputLabel>
            <PriceInput
              value={form.values.mandateSalePrice}
              onChange={mandateSalePrice =>
                form.setValues({ mandateSalePrice })
              }
              onBlur={() => form.setTouched({ mandateSalePrice: true })}
            />
            {form.errors.mandateSalePrice != null && (
              <FormHelperText>{form.errors.mandateSalePrice}</FormHelperText>
            )}
          </FormControl>

          <Box
            css={media({
              display: 'grid',
              gap: 16,
              gridTemplateColumns: ['1fr', '1fr 1fr'],
            })}
          >
            <TextField
              variant="filled"
              select={true}
              required={true}
              error={form.errors.mandateType != null}
              helperText={form.errors.mandateType}
              label={t('mandateType')}
              value={form.values.mandateType}
              onChange={event =>
                form.setValues({
                  mandateType: event.target.value,
                  // reset validation
                  fixedFee: form.values.fixedFee,
                })
              }
              onBlur={() => form.setTouched({ mandateType: true })}
            >
              <MenuItem value="commission_based">
                {t('commissionBased')}
              </MenuItem>
              <MenuItem value="fixed_fee">{t('fixedFee')}</MenuItem>
            </TextField>

            {form.values.mandateType && (
              <TextField
                variant="filled"
                select={true}
                label={t('Exclusive listing')}
                value={form.values.isExclusive ? 'true' : 'false'}
                onChange={event =>
                  form.setValues({
                    isExclusive: event.target.value === 'true' ? true : false,
                  })
                }
              >
                <MenuItem value="false">{t('no')}</MenuItem>
                <MenuItem value="true">{t('yes')}</MenuItem>
              </TextField>
            )}

            {form.values.mandateType === 'commission_based' && (
              <FormControl>
                <InputLabel shrink={true}>{t('rate')}</InputLabel>
                <PercentInput
                  value={form.values.commissionRate}
                  onChange={commissionRate =>
                    form.setValues({ commissionRate })
                  }
                />
              </FormControl>
            )}

            {form.values.mandateType === 'fixed_fee' && (
              <FormControl required={true} error={form.errors.fixedFee != null}>
                <InputLabel shrink={true}>{t('fixedFee')}</InputLabel>
                <PriceInput
                  value={form.values.fixedFee}
                  onChange={fixedFee => form.setValues({ fixedFee })}
                  onBlur={() => form.setTouched({ fixedFee: true })}
                />
                {form.errors.fixedFee != null && (
                  <FormHelperText>{form.errors.fixedFee}</FormHelperText>
                )}
              </FormControl>
            )}

            {form.values.mandateType && (
              <TextField
                variant="filled"
                select={true}
                label={t('feeInclusiveOfTVA')}
                value={form.values.isSalesTaxInclusive ? 'true' : 'false'}
                onChange={event =>
                  form.setValues({
                    isSalesTaxInclusive:
                      event.target.value === 'true' ? true : false,
                  })
                }
              >
                <MenuItem value="false">{t('noNotIncluded')}</MenuItem>
                <MenuItem value="true">{t('yesIncluded')}</MenuItem>
              </TextField>
            )}
          </Box>

          <Box
            css={media({
              display: 'grid',
              gap: 16,
              gridTemplateColumns: ['1fr', '1fr 1fr'],
            })}
          >
            <FormControl required={true} error={form.errors.signedAt != null}>
              <InputLabel>{t('signature')}</InputLabel>
              <DateInput
                value={form.values.signedAt}
                onChange={signedAt => form.setValues({ signedAt })}
              />
              {form.errors.signedAt != null && (
                <FormHelperText>{form.errors.signedAt}</FormHelperText>
              )}
            </FormControl>

            <FormControl
              required={true}
              error={form.errors.durationInMonths != null}
            >
              <InputLabel>{t('durationInMonths')}</InputLabel>
              <NumberInput
                value={form.values.durationInMonths}
                onChange={v => form.setValues({ durationInMonths: v })}
                onBlur={() => form.setTouched({ durationInMonths: true })}
              />
              {form.errors.durationInMonths != null && (
                <FormHelperText>{form.errors.durationInMonths}</FormHelperText>
              )}
            </FormControl>
          </Box>

          <Box
            css={media({
              display: 'grid',
              gap: 16,
              gridTemplateColumns: ['1fr', '1fr 1fr'],
            })}
          >
            <TextField
              variant="filled"
              select={true}
              required={true}
              error={form.errors.expectedTimeToSell != null}
              helperText={form.errors.expectedTimeToSell}
              label={t('expectedTimeToSell')}
              // mui select accepts only empty string as empty value
              value={
                form.values.expectedTimeToSell === 'not_set'
                  ? ''
                  : form.values.expectedTimeToSell
              }
              onChange={event =>
                form.setValues({
                  expectedTimeToSell: event.target.value,
                })
              }
              onBlur={() => form.setTouched({ expectedTimeToSell: true })}
            >
              {EXPECTED_TIME_TO_SELL_OPTIONS.map(option => (
                <MenuItem key={option.id} value={option.id}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>

          <Box css={text.subtitle2}>{t('statusAndReference')}</Box>

          <TextField
            variant="filled"
            label={t('reference')}
            value={form.values.title}
            onChange={event => form.setValues({ title: event.target.value })}
          />

          <TextField
            variant="filled"
            select={true}
            label={t('mandateOrigin')}
            value={form.values.origin}
            onChange={event => form.setValues({ origin: event.target.value })}
          >
            {mandateOrigins.originTypes.map(origin => (
              <MenuItem key={origin.id} value={origin.id}>
                {origin.label}
              </MenuItem>
            ))}
          </TextField>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={props.onClose}>
          {t('cancel')}
        </Button>
        <ProgressButton
          color="primary"
          disabled={form.valid === false}
          loading={loading}
          onClick={form.submitForm}
        >
          {t('save')}
        </ProgressButton>
      </DialogActions>
    </>
  );
};

export const LotMandateCreateDialog = (props: Props): React.Node => {
  const { t } = useLocale();
  const responsive = useResponsive();
  const fullScreen = responsive([true, false]);
  return (
    <Dialog fullScreen={fullScreen} open={props.open} onClose={props.onClose}>
      <DialogTitle>{t('createLot')}</DialogTitle>
      <LeadLotForm
        lead={props.lead}
        root={props.root}
        onCreate={props.onCreate}
        onClose={props.onClose}
      />
    </Dialog>
  );
};
