import * as React from 'react';

import { Accordion } from '@material-ui/core';
import { graphql, useFragment } from 'react-relay';

import { useLocale } from '../../hooks/locale';
import { AccordionCardSummary } from '../../shared/accordion-card-summary';
import { FilesList } from '../../shared/files-list';

import type { leadFilesCard_lead$key } from './__generated__/leadFilesCard_lead.graphql';

type Props = {
  lead: leadFilesCard_lead$key;
  defaultExpanded: boolean;
};

export const LeadFilesCard = (props: Props) => {
  const lead = useFragment(
    graphql`
      fragment leadFilesCard_lead on Lead {
        id
        property {
          id
          files {
            ...filesList_files
          }
        }
      }
    `,
    props.lead,
  );

  const { t } = useLocale();
  const [expanded, setExpanded] = React.useState(false);

  const property = lead.property;
  if (property == null) {
    return null;
  }
  return (
    <Accordion
      defaultExpanded={props.defaultExpanded}
      onChange={(_event, expanded) => {
        setExpanded(expanded);
      }}
    >
      <AccordionCardSummary
        expanded={expanded}
        title={`${t('files')} (${property.files.length})`}
        subTitle={property.files.length > 0 ? t('viewFiles') : t('addFile')}
      />
      <FilesList parentId={property.id} files={property.files} />
    </Accordion>
  );
};
