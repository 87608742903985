/**
 * @generated SignedSource<<9dc8afb07e332847ae2a2e4d2ebcd395>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type propertyImageQuickEdit_image$fragmentType: FragmentType;
export type propertyImageQuickEdit_image$data = {|
  +displayOnBrochure: boolean,
  +displayOnListing: boolean,
  +id: string,
  +$fragmentType: propertyImageQuickEdit_image$fragmentType,
|};
export type propertyImageQuickEdit_image$key = {
  +$data?: propertyImageQuickEdit_image$data,
  +$fragmentSpreads: propertyImageQuickEdit_image$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "propertyImageQuickEdit_image",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayOnListing",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayOnBrochure",
      "storageKey": null
    }
  ],
  "type": "PropertyImage",
  "abstractKey": null
};

(node/*: any*/).hash = "91fee852029d98f5e10fe748f4f158aa";

export default node;
