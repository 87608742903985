/**
 * @generated SignedSource<<1797b244a292444a181006cf84b7b84e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type LeadsPipelinesQuery$variables = {};
export type LeadsPipelinesQuery$data = {
  readonly pipelines: ReadonlyArray<{
    readonly id: string;
    readonly label: string | null;
  }>;
  readonly tenantSettings: {
    readonly defaultPipeline: {
      readonly id: string;
    } | null;
  } | null;
};
export type LeadsPipelinesQuery = {
  response: LeadsPipelinesQuery$data;
  variables: LeadsPipelinesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Pipeline",
  "kind": "LinkedField",
  "name": "defaultPipeline",
  "plural": false,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "leadType",
      "value": "sales"
    }
  ],
  "concreteType": "Pipeline",
  "kind": "LinkedField",
  "name": "pipelines",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    }
  ],
  "storageKey": "pipelines(leadType:\"sales\")"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LeadsPipelinesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantSettings",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      (v2/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LeadsPipelinesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantSettings",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      (v2/*: any*/)
    ]
  },
  "params": {
    "cacheID": "a5209ab9b843c79f3e70fd99c394b76e",
    "id": null,
    "metadata": {},
    "name": "LeadsPipelinesQuery",
    "operationKind": "query",
    "text": "query LeadsPipelinesQuery {\n  tenantSettings {\n    defaultPipeline {\n      id\n    }\n    id\n  }\n  pipelines(leadType: sales) {\n    id\n    label\n  }\n}\n"
  }
};
})();

(node as any).hash = "0484199fc381814d20da56b958433be2";

export default node;
