// Generated from src/svgs/nav-calendar-active.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const NavCalendarActive = ({ size, fill, className }: Props) => {
  return (
    <svg viewBox="0 0 25 25" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><path d="M25 3v4H0V3a1 1 0 0 1 1-1h5V.5a.5.5 0 0 1 1 0V2h11V.5a.5.5 0 0 1 1 0V2h5a1 1 0 0 1 1 1zM0 8h25v15a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1zm18 5h2v-2h-2zm-5 0h2v-2h-2zm-3 5h2v-2h-2zm-5 0h2v-2H5z" /></svg>
  );
}
