// Generated from src/svgs/nav-email.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const NavEmail = ({ size, fill, className }: Props) => {
  return (
    <svg viewBox="0 0 25 25" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><path d="M22.62 4H2.38A1.38 1.38 0 0 0 1 5.38V20.1a1.38 1.38 0 0 0 1.38 1.38h20.24A1.38 1.38 0 0 0 24 20.1V5.38A1.38 1.38 0 0 0 22.62 4Zm.46 16.1a.46.46 0 0 1-.46.46H2.38a.46.46 0 0 1-.46-.46V8.225l9.642 5.714a1.84 1.84 0 0 0 1.876 0l9.642-5.714V20.1Zm0-12.944-10.11 5.992a.92.92 0 0 1-.939 0L1.92 7.156V5.38a.46.46 0 0 1 .46-.46h20.24a.46.46 0 0 1 .46.46v1.776Z" fill="#fff" /></svg>
  );
}
