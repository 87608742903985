import { forwardRef } from 'react';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  Box,
  Card,
  CardMedia,
  LinearProgress,
  Typography,
} from '@mui/material';

import { useLocale } from '../../../src/hooks/locale';

interface ImageUploadingCardProps {
  isLoading?: boolean; // Flag to indicate if the image has been uploaded
  imageUrl?: string; // The actual image URL (for existing DB images)
  error?: Error;
  height?: number;
}

export const ImageUploadingCard = forwardRef<
  HTMLDivElement,
  ImageUploadingCardProps & React.PropsWithChildren
>(({ isLoading = false, imageUrl, error, height = 100, children }, ref) => {
  const { t } = useLocale();

  return (
    <Card
      variant="outlined"
      elevation={0}
      sx={{ position: 'relative' }}
      ref={ref}
    >
      {error && (
        <Box
          sx={{
            position: 'absolute',
            inset: 0,
            padding: 1,
            zIndex: 1,
            background: 'rgba(255, 255, 255, 0.6)',
            backdropFilter: 'blur(10px)',
            borderRadius: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ErrorOutlineIcon
            sx={{
              color: 'error.main',
              position: 'absolute',
              zIndex: 2,
              top: 8,
              right: 8,
            }}
          />
          <Typography
            variant="body2"
            color="error"
            sx={{ textAlign: 'center' }}
          >
            {t('Upload error')}
          </Typography>
        </Box>
      )}
      <CardMedia sx={{ height }} image={imageUrl}></CardMedia>
      {isLoading && <LinearProgress />}
      {children}
    </Card>
  );
});
