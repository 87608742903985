/**
 * @generated SignedSource<<539adc445a4990262d0c19f7d951b19a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeedNewForm_user$data = {
  readonly __typename: "User";
  readonly phoneNumbers: ReadonlyArray<{
    readonly createdAt: string;
    readonly number: string;
    readonly primary: boolean | null;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"ActivityForm_user">;
  readonly " $fragmentType": "FeedNewForm_user";
};
export type FeedNewForm_user$key = {
  readonly " $data"?: FeedNewForm_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedNewForm_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedNewForm_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ActivityForm_user"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PhoneNumber",
      "kind": "LinkedField",
      "name": "phoneNumbers",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "number",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "primary",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "3a374394e3e03a4578cdba5927c7e48c";

export default node;
