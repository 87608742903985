// @flow

import * as React from 'react';

import 'mapbox-gl/dist/mapbox-gl.css';
import { Input } from '@material-ui/core';
import { useConstant } from '@realadvisor/hooks';
import mapbox from 'mapbox-gl';
import type { GeoJSONSource } from 'mapbox-gl';
import { graphql, useLazyLoadQuery, usePaginationFragment } from 'react-relay';
// $FlowFixMe[untyped-import]
import { useNavigate } from 'react-router-dom';
import { Box, Flex, useResponsive, useSystem } from 'react-system';

import { MAPBOX_TOKEN } from '../../config';
import {
  type ColumnConfig,
  InfiniteTable,
} from '../../controls/infinite-table';
import { Subscription } from '../../controls/subscription';
import { type IntlLocale, type Translate, useLocale } from '../../hooks/locale';
import { type Theme, useTheme } from '../../hooks/theme';
import { AddressInput } from '../../shared/address-input';
import { LeadUpdatedTimeAgo } from '../../shared/lead-updated-time-ago';
import { UserInfo } from '../../shared/user-info';
import { formatNumberByPrecision } from '../../utils/number-format';
import { type SortDirection } from '../../utils/sorting';

import type {
  leadsMap_root$data,
  leadsMap_root$key,
} from './__generated__/leadsMap_root.graphql';
import type { leadsMapPaginationQuery } from './__generated__/leadsMapPaginationQuery.graphql';
import type { leadsMapQuery } from './__generated__/leadsMapQuery.graphql';
import { getAppraisalPerception } from './Leads';
import {
  type LeadsParams,
  paramsToFilters,
  useLeadsParams,
} from './LeadsFilters';

type LeadsMapNode = NonNullable<
  NonNullable<
    NonNullable<NonNullable<leadsMap_root$data['leads']>['edges']>[number]
  >['node']
>;

const getSorting = (params: LeadsParams) => {
  if (params.sortBy != null) {
    return { sortBy: params.sortBy, sortDirection: params.sortDirection };
  }
  const sortDirection: SortDirection = 'desc';
  return { sortBy: 'createdAt', sortDirection };
};

const getQueryVariables = (
  params: LeadsParams,
  bounds: mapbox.LngLatBounds | null,
  pipelineId_eq: string | null,
) => {
  return {
    ...getSorting(params),
    count: bounds ? 1000 : 40,
    // decreased count to 1000 instead of 1 000 000 to fix timeout, plus it makes no sense to load 1M lead on the map. Ref https://github.com/realadvisor/realadvisor/issues/16271
    filters: {
      ...paramsToFilters(params),
      pipelineId_eq,
      property_googleAddress_lat_gte: bounds?.getSouth(),
      property_googleAddress_lat_lte: bounds?.getNorth(),
      property_googleAddress_lng_gte: bounds?.getWest(),
      property_googleAddress_lng_lte: bounds?.getEast(),
    },
  };
};

const getColumns = ({
  t,
  locale,
  colors,
  totalCount,
  isAdmin,
}: {
  colors: Theme['colors'];
  t: Translate;
  locale: IntlLocale;
  totalCount: number;
  isAdmin: boolean;
}) => {
  const formatValues = (
    realadvisor: {
      readonly max: number | null;
      readonly min: number | null;
    } | null,
  ) => {
    if (realadvisor?.min == null || realadvisor.max == null) {
      return '-';
    }

    const min = realadvisor.min / 1000000;
    const max = realadvisor.max / 1000000;

    return `${min.toLocaleString(locale, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })}m - ${max.toLocaleString(locale, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })}m`;
  };

  const columns: ColumnConfig<LeadsMapNode>[] = [
    {
      label: totalCount,
      width: 12,
      sortBy: 'createdAt',
      cellRenderer: ({ node }) => (
        <LeadUpdatedTimeAgo
          lead={node}
          shortVersion={true}
          showBothDate={isAdmin}
        />
      ),
    },
    {
      label: t('contact'),
      width: 20,
      grow: 2,
      sortBy: 'contact_firstName|contact_lastName',
      cellRenderer: ({ node }) =>
        node.contact != null && (
          <UserInfo
            user={node.contact}
            showSensitiveData={node.userCanViewLeadDetails}
          />
        ),
    },

    {
      label: t('address'),
      width: 18,
      grow: 2,
      cellRenderer: ({ node }) => {
        const state = node.property?.state;
        const postcode = node.property?.postcode;
        const locality = node.property?.locality;
        const route = node.property?.route;
        const streetNumber = node.property?.streetNumber;
        return (
          <div css={{ alignSelf: 'center', whiteSpace: 'nowrap' }}>
            {node.userCanViewLeadDetails === true && (
              <div>{[route, ' ', streetNumber]}</div>
            )}
            <div css={{ color: colors.grey700 }}>
              {[postcode, ' ', locality, ' ', state]}
            </div>
          </div>
        );
      },
    },

    {
      width: 6,
      alignment: 'right',
      cellRenderer: ({ node }) => (
        <div css={{ fontSize: 20 }}>
          {getAppraisalPerception(
            colors,
            node.appraisalPerception,
            node.appraisalPerceivedValue,
            node.property?.latestAppraisal?.realadvisor?.max ?? null,
            node.property?.latestAppraisal?.realadvisor?.min ?? null,
          )}
        </div>
      ),
    },

    {
      label: t('value'),
      width: 20,
      sortBy: 'value',
      cellRenderer: ({ node }) => (
        <div>
          <div>
            {formatValues(node.property?.latestAppraisal?.realadvisor ?? null)}
          </div>
          <div css={{ color: colors.grey700 }}>
            {node.appraisalPerception != null
              ? node.appraisalPerception
              : formatNumberByPrecision(node.appraisalPerceivedValue, locale)}
          </div>
        </div>
      ),
    },
  ];

  return columns;
};

const MapContainer = ({
  mapRef,
  setBounds,
  lat,
  lng,
  zoom,
}: {
  mapRef: React.MutableRefObject<mapbox.Map | null>;
  setBounds: React.Dispatch<React.SetStateAction<mapbox.LngLatBounds | null>>;
  lat: number;
  lng: number;
  zoom: number;
}) => {
  const containerRef = React.useRef(null);
  React.useEffect(() => {
    if (containerRef.current != null) {
      const map = new mapbox.Map({
        container: containerRef.current,
        accessToken: MAPBOX_TOKEN,
        style: 'mapbox://styles/spingwun/cjoeeyssz16n82rlmpsofotvw',
        attributionControl: false,
        center: {
          lat: lat ?? 46.219925,
          lng: lng ?? 6.113784,
        },
        zoom: zoom ?? 10,
      });
      mapRef.current = map;
      return () => {
        map.remove();
        mapRef.current = null;
      };
    }
  }, [mapRef, lat, lng, zoom]);

  // track bounds changes
  React.useEffect(() => {
    if (mapRef.current != null) {
      const map = mapRef.current;
      setBounds(map.getBounds());
      const handleMoveend = () => {
        setBounds(map.getBounds());
      };
      map.on('moveend', handleMoveend);
      return () => {
        map.off('moveend', handleMoveend);
      };
    }
  }, [mapRef, setBounds]);
  return (
    <>
      {/* emotion lost marker styles */}
      <div ref={containerRef} style={{ width: '100%', height: '100%' }}></div>
    </>
  );
};

const renderIncompleteIcon = (scale: number, color: string) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (ctx == null) {
    throw new Error('Canvas not supported');
  }
  const x = scale * 8;
  const y = scale * 8;
  const radius = scale * 4;
  canvas.width = x * 2;
  canvas.height = y * 2;
  ctx.fillStyle = color;
  ctx.arc(x, y, radius + scale * 3, 0, Math.PI * 2, false);
  ctx.fill();
  return { canvas, imageData: ctx.getImageData(0, 0, x * 2, y * 2) };
};

const renderCompleteIcon = (
  scale: number,
  outlineColor: string,
  fillColor: string,
) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (ctx == null) {
    throw new Error('Canvas not supported');
  }
  const x = scale * 12;
  const y = scale * 12;
  const radius = scale * 8;
  canvas.width = x * 2;
  canvas.height = y * 2;
  ctx.fillStyle = outlineColor;
  ctx.beginPath();
  ctx.arc(x, y, radius + scale * 3, 0, Math.PI * 2, true);
  ctx.closePath();
  ctx.fill();
  ctx.fillStyle = '#ffffff';
  ctx.beginPath();
  ctx.arc(x, y, radius + scale * 2, 0, Math.PI * 2, true);
  ctx.closePath();
  ctx.fill();
  ctx.fillStyle = fillColor;
  ctx.beginPath();
  ctx.arc(x, y, radius, 0, Math.PI * 2, true);
  ctx.closePath();
  ctx.fill();
  return { canvas, imageData: ctx.getImageData(0, 0, x * 2, y * 2) };
};

const BLUE_COLOR = '#2196F3';
const LIGHT_BLUE_COLOR = '#b2dbfb'; //  lighten 0.3
const RED_COLOR = '#FF5722';
const LIGHT_RED_COLOR = '#ffcbbb'; // lighten 0.3

const renderIncompleteApartmentIcon = (scale: number) => {
  return renderIncompleteIcon(scale, LIGHT_BLUE_COLOR);
};

const renderIncompleteHouseIcon = (scale: number) => {
  return renderIncompleteIcon(scale, LIGHT_RED_COLOR);
};

const renderCompleteApartmentIcon = (scale: number) => {
  return renderCompleteIcon(scale, LIGHT_BLUE_COLOR, BLUE_COLOR);
};

const renderCompleteHouseIcon = (scale: number) => {
  return renderCompleteIcon(scale, LIGHT_RED_COLOR, RED_COLOR);
};

const getMarkerType = (lead: LeadsMapNode) => {
  let markerType = null;
  const propertyMainType = lead.property?.propertyType?.mainType;
  const complete = lead.source.name === 'appraisal' && lead.completed;
  if (propertyMainType === 'HOUSE') {
    if (complete) {
      markerType = 'complete-house-icon';
    } else {
      markerType = 'incomplete-house-icon';
    }
  }
  if (propertyMainType === 'APPT') {
    if (complete) {
      markerType = 'complete-apartment-icon';
    } else {
      markerType = 'incomplete-apartment-icon';
    }
  }
  return markerType;
};

const getLeadFeature = (
  lead: LeadsMapNode,
  index: number,
): GeoJSON.Feature<GeoJSON.Geometry | null> => {
  const { property, userCanViewLeadDetails } = lead || {};
  return {
    type: 'Feature',
    properties: {
      id: lead?.id,
      index,
      iconImage: getMarkerType(lead),
    },
    geometry:
      property?.lng == null || property?.lat == null || !userCanViewLeadDetails
        ? null
        : {
            type: 'Point',
            coordinates: [property.lng, property.lat],
          },
  };
};

const Markers = ({
  mapRef,
  leads,
}: {
  mapRef: React.MutableRefObject<mapbox.Map | null>;
  leads: ReadonlyArray<LeadsMapNode>;
}) => {
  // prerender markers icons
  React.useEffect(() => {
    if (mapRef.current != null) {
      const map = mapRef.current;
      const handleLoad = () => {
        map.addImage(
          'incomplete-house-icon',
          renderIncompleteHouseIcon(2).imageData,
          { pixelRatio: 2 },
        );
        map.addImage(
          'incomplete-apartment-icon',
          renderIncompleteApartmentIcon(2).imageData,
          { pixelRatio: 2 },
        );
        map.addImage(
          'complete-house-icon',
          renderCompleteHouseIcon(2).imageData,
          { pixelRatio: 2 },
        );
        map.addImage(
          'complete-apartment-icon',
          renderCompleteApartmentIcon(2).imageData,
          { pixelRatio: 2 },
        );
        map.addSource('markers', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [],
          },
        });
        map.addLayer({
          id: 'markers',
          type: 'symbol',
          source: 'markers',
          layout: {
            'icon-image': ['get', 'iconImage'],
            'icon-allow-overlap': true,
            'symbol-z-order': 'source',
          },
        });
      };
      if (map.loaded()) {
        handleLoad();
      } else {
        map.on('load', handleLoad);
        return () => {
          map.off('load', handleLoad);
        };
      }
    }
  }, [mapRef]);

  // apply data and bind icons
  React.useEffect(() => {
    if (mapRef.current != null) {
      const map = mapRef.current;
      const handleLoad = () => {
        (map.getSource('markers') as unknown as GeoJSONSource)?.setData({
          type: 'FeatureCollection',
          features: leads
            .map(getLeadFeature)
            .filter(
              f => f.geometry != null,
            ) as GeoJSON.Feature<GeoJSON.Geometry>[],
        });
      };
      if (map.loaded()) {
        handleLoad();
      } else {
        map.on('load', handleLoad);
        return () => {
          map.off('load', handleLoad);
        };
      }
    }
  }, [mapRef, leads]);

  return null;
};

const findMinBy = <T,>(
  array: ReadonlyArray<T>,
  by: (item: T) => number,
): T | null => {
  let min = Number.POSITIVE_INFINITY;
  let minItem: T | null = null;
  for (const item of array) {
    const value = by(item);
    if (value < min) {
      min = value;
      minItem = item;
    }
  }
  return minItem;
};

const getPopupStyles = (colors: Theme['colors']): any => ({
  '.mapboxgl-popup': {
    pointerEvents: 'none',
  },
  '.mapboxgl-popup-tip': {
    display: 'none',
  },
  '.mapboxgl-popup-content': {
    pointerEvents: 'none',
    fontSize: 12,
    color: colors.white,
    backgroundColor: colors.grey700,
    opacity: 0.9,
    padding: '4px 8px',
  },
  '.mapboxgl-popup-content-name': {
    fontSize: 16,
  },
  '.mapboxgl-popup-content-email': {
    color: colors.grey400,
    marginBottom: 8,
  },
  '.mapboxgl-popup-content-address-head': {
    fontSize: 14,
  },
  '.mapboxgl-popup-content-address-tail': {
    color: colors.grey400,
  },
});

const HoveredMarker = ({
  mapRef,
  leads,
  hoveredMarker,
  setHoveredMarker,
}: {
  mapRef: React.MutableRefObject<mapbox.Map | null>;
  leads: ReadonlyArray<LeadsMapNode>;
  hoveredMarker: number;
  setHoveredMarker: (index: number) => void;
}) => {
  const navigate = useNavigate();
  const { language } = useLocale();

  // show bigger marker and popup on hover
  React.useEffect(() => {
    const map = mapRef.current;
    const lead = leads[hoveredMarker];
    if (map && lead) {
      const markerType = getMarkerType(lead);
      let canvas;
      if (markerType === 'incomplete-apartment-icon') {
        canvas = renderIncompleteApartmentIcon(2.5).canvas;
      }
      if (markerType === 'incomplete-house-icon') {
        canvas = renderIncompleteHouseIcon(2.5).canvas;
      }
      if (markerType === 'complete-apartment-icon') {
        canvas = renderCompleteApartmentIcon(2.5).canvas;
      }
      if (markerType === 'complete-house-icon') {
        canvas = renderCompleteHouseIcon(2.5).canvas;
      }
      if (canvas != null) {
        const matchedCanvas = canvas;
        const pixelRatio = 2;
        const image = new Image();
        image.style.pointerEvents = 'none';
        image.src = matchedCanvas.toDataURL();
        image.width = matchedCanvas.width / pixelRatio;
        image.height = matchedCanvas.height / pixelRatio;
        const popup = new mapbox.Popup({
          maxWidth: '200px',
          closeButton: false,
          closeOnClick: false,
        });
        const { contact, property, userCanViewLeadDetails } = lead;
        const html = `
        <div class='mapboxgl-popup-content-name'>
          ${[contact?.firstName, contact?.lastName].filter(Boolean).join(' ')}
        </div>
        <div class='mapboxgl-popup-content-email'>
          ${contact?.primaryEmail?.email ?? ''}
        </div>
        <div class='mapboxgl-popup-content-address-head'>
          ${[property?.route, property?.streetNumber].filter(Boolean).join(' ')}
        </div>
        <div class='mapboxgl-popup-content-address-tail'>
          ${[property?.locality, property?.postcode].filter(Boolean).join(' ')}
        </div>
      `;
        let marker: mapbox.Marker | null = null;
        if (userCanViewLeadDetails) {
          marker = new mapbox.Marker({ element: image })
            .setLngLat([property?.lng ?? 0, property?.lat ?? 0])
            .setPopup(popup.setHTML(html))
            .addTo(map)
            .togglePopup();
        }
        return () => {
          marker?.remove();
        };
      }
    }
  }, [mapRef, leads, hoveredMarker]);

  // track markers hover and click
  React.useEffect(() => {
    if (mapRef.current != null) {
      const map = mapRef.current;
      // mousemove is used to allow switching to overlapped markers
      // where mouseenter is not fired because layer already entered
      let lastFeature: any = null;
      const handleMousemove = (event: any) => {
        const closestFeature = findMinBy(event.features, (feature: any) => {
          const [lng, lat] = feature.geometry.coordinates;
          return event.lngLat.distanceTo(new mapbox.LngLat(lng, lat));
        });
        if (
          closestFeature &&
          closestFeature.properties.id !== lastFeature?.properties.id
        ) {
          lastFeature = closestFeature;
          // Change the cursor style as a UI indicator.
          map.getCanvas().style.cursor = 'pointer';
          setHoveredMarker(closestFeature.properties.index);
        }
      };
      const handleMouseleave = () => {
        lastFeature = null;
        map.getCanvas().style.cursor = '';
        setHoveredMarker(-1);
      };
      // open hovered lead on click
      const handleClick = () => {
        if (lastFeature) {
          navigate(`/leads/${lastFeature.properties.id}`);
        }
      };
      map.on('mousemove', 'markers', handleMousemove);
      map.on('mouseleave', 'markers', handleMouseleave);
      map.on('click', 'markers', handleClick);
      return () => {
        map.off('mousemove', 'markers', handleMousemove);
        map.off('mouseleave', 'markers', handleMouseleave);
        map.off('click', 'markers', handleClick);
      };
    }
  }, [mapRef, setHoveredMarker, language, navigate]);
  return null;
};

const LegendIcon = ({
  scale,
  renderIcon,
}: {
  scale: number;
  renderIcon: (scale: number) => {
    canvas: HTMLCanvasElement;
    imageData: ImageData;
  };
}) => {
  const { canvas } = React.useMemo(() => {
    return renderIcon(scale);
  }, [renderIcon, scale]);
  const pixelRatio = 2;
  return (
    <img
      src={canvas.toDataURL()}
      width={canvas.width / pixelRatio}
      height={canvas.height / pixelRatio}
    />
  );
};

const Legend = () => {
  const { t } = useLocale();
  const { colors } = useTheme();
  return (
    <div
      css={{
        padding: 16,
        display: 'grid',
        rowGap: 8,
        columnGap: 16,
        gridTemplateColumns: '20px 1fr',
        background: 'rgba(0, 0, 0, 0.4)',
        fontSize: 14,
        color: colors.white,
      }}
    >
      <LegendIcon scale={2.5} renderIcon={renderIncompleteApartmentIcon} />
      <div>{t('appartmentAppraisalNotCompleted')}</div>
      <LegendIcon scale={2.5} renderIcon={renderIncompleteHouseIcon} />
      <div>{t('houseAppraisalNotCompleted')}</div>
      <LegendIcon scale={2} renderIcon={renderCompleteApartmentIcon} />
      <div>{t('appartmentAppraisalCompleted')}</div>
      <LegendIcon scale={2} renderIcon={renderCompleteHouseIcon} />
      <div>{t('houseAppraisalCompleted')}</div>
    </div>
  );
};

const SearchInput = React.forwardRef((props, ref) => {
  const { t } = useLocale();

  return (
    <Input
      {...props}
      ref={ref}
      placeholder={t('enterAddress')}
      fullWidth={true}
      disableUnderline={true}
      css={{ paddingLeft: 8, paddingRight: 8 }}
    />
  );
});

const SearchField = ({
  mapRef,
  bounds,
}: {
  mapRef: React.MutableRefObject<mapbox.Map | null>;
  bounds: mapbox.LngLatBounds;
}) => {
  const { countryCode } = useLocale();
  const { colors } = useTheme();
  const [address, setAddress] = React.useState('');
  if (bounds == null) {
    return null;
  }
  return (
    <Box css={{ backgroundColor: colors.white }}>
      <AddressInput
        Input={SearchInput}
        clearable={true}
        country={countryCode}
        bounds={{
          west: bounds.getWest(),
          south: bounds.getSouth(),
          east: bounds.getEast(),
          north: bounds.getNorth(),
        }}
        value={address}
        onChange={setAddress}
        onSelect={(parsedResult, geometry, description) => {
          setAddress(description ?? '');
          if (geometry) {
            const { location, bounds } = geometry;
            if (bounds) {
              const { west, south, east, north } = bounds.toJSON();
              mapRef.current?.fitBounds([west, south, east, north]);
            } else {
              mapRef.current?.jumpTo({
                center: [location.lng(), location.lat()],
                zoom: 16,
              });
            }
          }
        }}
      />
    </Box>
  );
};

export const LeadsMap = (props: { pipelineId: string | null }) => {
  const { media } = useSystem();
  const responsive = useResponsive();
  const { t, locale } = useLocale();
  const { colors } = useTheme();
  const [params, setParams] = useLeadsParams();
  const [hovered, setHovered] = React.useState(-1);
  const mapRef = React.useRef<null | mapbox.Map>(null);
  const pipelineId_eq = params.pipelineId_eq ?? props.pipelineId;

  const [bounds, setBounds] = React.useState<mapbox.LngLatBounds | null>(null);
  const initialVariables = useConstant(() =>
    getQueryVariables(params, bounds, pipelineId_eq),
  );

  const queryData = useLazyLoadQuery<leadsMapQuery>(
    graphql`
      query leadsMapQuery(
        $count: Int!
        $filters: LeadsFilters
        $sortBy: String
        $sortDirection: SortDirection
      ) {
        ...leadsMap_root
          @arguments(
            count: $count
            filters: $filters
            sortBy: $sortBy
            sortDirection: $sortDirection
          )
      }
    `,
    initialVariables,
  );

  const { data, hasNext, isLoadingNext, loadNext, refetch } =
    usePaginationFragment<leadsMapPaginationQuery, leadsMap_root$key>(
      graphql`
        fragment leadsMap_root on Query
        @refetchable(queryName: "leadsMapPaginationQuery")
        @argumentDefinitions(
          count: { type: "Int!" }
          cursor: { type: "String", defaultValue: null }
          filters: { type: "LeadsFilters" }
          sortBy: { type: "String" }
          sortDirection: { type: "SortDirection" }
        ) {
          leads(
            first: $count
            after: $cursor
            sortBy: $sortBy
            sortDirection: $sortDirection
            filters: $filters
          ) @connection(key: "Connection_leads", filters: []) {
            edges {
              node {
                id
                ...leadUpdatedTimeAgo_lead
                appraisalPerception
                appraisalPerceivedValue
                userCanViewLeadDetails
                completed
                source {
                  name
                }
                contact {
                  ...userInfo_user
                  firstName
                  lastName
                  primaryEmail {
                    email
                  }
                }
                property {
                  lat
                  lng
                  route
                  streetNumber
                  postcode
                  locality
                  state
                  propertyType {
                    mainType
                  }
                  latestAppraisal {
                    realadvisor {
                      max
                      min
                    }
                  }
                }
              }
            }
            totalCount
          }
          tenantSettings {
            defaultMapCoordinates {
              lng
              lat
              z
            }
          }
          me {
            isAdmin
          }
        }
      `,
      queryData,
    );

  const nodes = [];
  for (const edge of data.leads?.edges ?? []) {
    if (edge?.node != null) {
      nodes.push(edge.node);
    }
  }

  const loadUntil = (stopIndex: number) => {
    const count = nodes.length;
    const LOAD_ITEMS_MORE = 30;
    if (count <= stopIndex + 1 && hasNext === true && isLoadingNext === false) {
      loadNext(LOAD_ITEMS_MORE);
    }
  };

  const columns = getColumns({
    t,
    locale,
    colors,
    totalCount: data.leads?.totalCount ?? 0,
    isAdmin: data.me?.isAdmin ?? false,
  });

  return (
    <Flex flexGrow={1} width={1} css={{ overflow: 'hidden' }}>
      <Subscription
        value={params}
        onChange={() => {
          refetch(getQueryVariables(params, bounds, pipelineId_eq), {
            fetchPolicy: 'store-and-network',
          });
        }}
      />
      <Subscription
        value={bounds}
        onChange={() => {
          refetch(getQueryVariables(params, bounds, pipelineId_eq), {
            fetchPolicy: 'store-and-network',
          });
        }}
      />

      {responsive([false, true]) && (
        <Flex
          width={[1, 4 / 10]}
          css={{ borderRight: `1px solid ${colors.divider}` }}
        >
          <InfiniteTable
            columns={columns}
            data={nodes}
            sort={params}
            onSort={setParams}
            rowSize={64}
            hoveredRow={hovered}
            onRowHover={({ rowIndex }) => setHovered(rowIndex)}
            getRowLink={({ node }) => `/leads/${node.id}`}
            rowTarget="_blank"
            loadUntil={loadUntil}
            hasMore={() => hasNext}
          />
        </Flex>
      )}

      <Box
        width={[1, 6 / 10]}
        css={[{ position: 'relative' }, getPopupStyles(colors)]}
      >
        <MapContainer
          mapRef={mapRef}
          setBounds={setBounds}
          lat={data.tenantSettings?.defaultMapCoordinates.lat ?? 0}
          lng={data.tenantSettings?.defaultMapCoordinates.lng ?? 0}
          zoom={data.tenantSettings?.defaultMapCoordinates.z ?? 0}
        />
        <Markers mapRef={mapRef} leads={nodes} />
        <HoveredMarker
          mapRef={mapRef}
          leads={nodes}
          hoveredMarker={hovered}
          setHoveredMarker={setHovered}
        />
        <Flex
          p={1}
          alignItems="flex-start"
          css={media({
            position: 'absolute',
            top: 8,
            left: 8,
            right: 8,
            display: 'grid',
            gridTemplateColumns: ['1fr', '1fr max-content'],
            gap: 8,
            pointerEvents: 'none',
          })}
        >
          <Box p={1} flexGrow={1} css={{ pointerEvents: 'auto' }}>
            {bounds && <SearchField mapRef={mapRef} bounds={bounds} />}
          </Box>
          <Box css={media({ display: ['none', 'block'] })}>
            <Legend />
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};
