import type { ActivityWorkflowParticipantData } from './activity-workflow-dialog';
import type { ActivityEventParticipantData } from './ActivityEventForm';
import type { ActiveDialogType } from './Feed';
import type { FeedCallProps } from './FeedCall';
import type { FeedTaskProps } from './FeedTask';
import type { FeedVisitProps } from './FeedVisit';

export const getParticipantData = (
  datum: ActivityEventParticipantData | ActivityWorkflowParticipantData,
) => {
  return {
    id: datum.user?.id ?? datum.email ?? '',
    firstName: datum.user?.firstName ?? datum.name ?? datum.email,
    lastName: datum.user?.lastName ?? null,
    primaryImage: datum.user != null ? datum.user.primaryImage : null,
    primaryEmail: datum.email != null ? { email: datum.email } : null,
  };
};

export const workflowButtonClickWrapper =
  (
    props: FeedVisitProps | FeedTaskProps | FeedCallProps,
    setDialog: (
      value: React.SetStateAction<'cancelled' | 'completed' | 'none'>,
    ) => void,
  ) =>
  (type: 'cancelled' | 'completed') => {
    if (props.activeDialogType != null) {
      props.setShowUnfinishedWarning(true);
    } else {
      props.setActiveDialogType('workflow');
      setDialog(type);
    }
    props.setOpenDialog(true);
  };

export interface FeedFormProps<R, A> {
  root: R;
  activity: A;
  indirect: boolean;
  onCreate: () => void;
  openDialog: boolean;
  setOpenDialog: (value: boolean) => void;
  activeDialogType: ActiveDialogType;
  setActiveDialogType: (value: ActiveDialogType) => void;
  showUnfinishedWarning: boolean;
  setShowUnfinishedWarning: (value: boolean) => void;
}
