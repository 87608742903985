// @flow

import * as React from 'react';

import { useLocale } from '../hooks/locale';
import { useTheme } from '../hooks/theme';

export type Stats = {|
  +percentile10: ?number,
  +percentile90: ?number,
  +median: ?number,
  currency: string,
|};

export const BellCurve = (props: Stats): React.Node => {
  const { colors, text, textColor } = useTheme();
  const { t, locale } = useLocale();
  const { percentile10, median, percentile90, currency } = props;

  const formatNumber = (n, fraction = 0) =>
    n.toLocaleString(locale, {
      minimumFractionDigits: fraction,
      maximumFractionDigits: fraction,
    });
  const useMillions = median != null && median > 150000;

  const pos = coord => ({ position: 'absolute', ...coord });

  const font = (size, fontWeight) => ({
    fontWeight: text.font[fontWeight],
    fontSize: text.size(size),
    lineHeight: 1,
    textAlign: 'center',
  });

  return (
    <div
      css={{ position: 'relative', width: 200, height: 124, margin: 'auto' }}
    >
      <svg
        width="199"
        height="59"
        viewBox="0 0 199 59"
        xmlns="http://www.w3.org/2000/svg"
        css={pos({ top: 32, left: 0 })}
      >
        <path
          d="M101.5 0.00732422C62.5747 0.00740598 68.2286 58.0073 0 58.0073H199C136.02 58.0073 140.425 0.00724246 101.5 0.00732422Z"
          fill="#3454D1"
          fillOpacity="0.24"
        />
      </svg>
      {percentile10 != null && median != null && percentile90 != null ? (
        <>
          <div
            css={[
              textColor('white'),
              font(10, 'bold'),
              pos({ top: 64, left: '32%', right: '50%' }),
            ]}
          ></div>
          <div
            css={[
              textColor('white'),
              font(10, 'bold'),
              pos({ top: 64, left: '50%', right: '32%' }),
            ]}
          ></div>
          <div
            css={[
              font(12, 'bold'),
              pos({ top: 10, left: 0, right: 0 }),
              { whiteSpace: 'nowrap' },
            ]}
          >
            {currency} {formatNumber(median)}
          </div>
          <div
            css={[
              font(11, 'medium'),
              pos({ bottom: 10, left: 20, right: '50%' }),
            ]}
          >
            {currency}{' '}
            {useMillions
              ? `${formatNumber(percentile10 / 1000000, 2)}m`
              : formatNumber(percentile10)}
          </div>
          <div
            css={[
              font(11, 'medium'),
              pos({ bottom: 10, left: '50%', right: 20 }),
            ]}
          >
            {currency}{' '}
            {useMillions
              ? `${formatNumber(percentile90 / 1000000, 2)}m`
              : formatNumber(percentile90)}
          </div>
          <div
            css={[
              pos({ left: '50%', bottom: 30 }),
              {
                marginLeft: -1,
                height: 67,
                width: 0,
                border: `solid 1px ${colors.primaryMain}`,
              },
            ]}
          />
          <div
            css={[
              pos({ left: '32%', bottom: 30 }),
              {
                height: 50,
                width: 0,
                borderLeft: `solid 1px ${colors.primaryMain}`,
              },
            ]}
          />
          <div
            css={[
              pos({ right: '32%', bottom: 30 }),
              {
                height: 50,
                width: 0,
                borderRight: `solid 1px ${colors.primaryMain}`,
              },
            ]}
          />
        </>
      ) : (
        <div
          css={[
            textColor('white'),
            font(11, 'bold'),
            pos({ top: 60, left: 0, right: 0 }),
          ]}
        >
          {t('notAvailable')}
        </div>
      )}
    </div>
  );
};
