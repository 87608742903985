// @flow

export const findQuotes = (
  body: ?string,
): {| message: ?string, quotes: null | string |} => {
  let message = body;
  let quotes = null;

  try {
    const dom = document.createElement('div');
    // $FlowFixMe[prop-missing]
    const isQuotes = n => n.tagName === 'BLOCKQUOTE';
    // $FlowFixMe[prop-missing]
    const isEmptyTag = n => n.tagName === 'BR' || n.tagType !== 1;
    let quoteFound = null;
    let quoteFoundIndex = null;

    dom.innerHTML = body ?? '';

    dom.childNodes.forEach((node, index) => {
      if (isQuotes(node)) {
        quoteFound = node;
        quoteFoundIndex = index;
      } else {
        node.childNodes.forEach(child => {
          if (isQuotes(child)) {
            quoteFound = child.parentNode;
            quoteFoundIndex = index;
          }
        });
      }
    });

    // ensure the type is number
    quoteFoundIndex = quoteFoundIndex ?? 0;

    if (quoteFound != null && quoteFound instanceof Element) {
      const quoteNode = quoteFound;
      let isLastNotEmpty = true;
      for (let i = quoteFoundIndex + 1; i < dom.childNodes.length; i += 1) {
        if (!isEmptyTag(dom.childNodes[i])) {
          isLastNotEmpty = false;
        }
      }
      if (isLastNotEmpty) {
        quotes = quoteNode.outerHTML;
        for (let i = quoteFoundIndex + 1; i < dom.childNodes.length; i += 1) {
          dom.removeChild(dom.childNodes[i]);
        }
        quoteNode.parentNode?.removeChild(quoteNode);
        message = dom.innerHTML;
      }
    }
  } catch (e) {
    console.error('Email parse error', e);
  }

  return { message, quotes };
};
