/**
 * @generated SignedSource<<811bbc363523c3ac0dc3caf5e359e6ff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeedNewForm_lot$data = {
  readonly __typename: "Lot";
  readonly sellers: ReadonlyArray<{
    readonly phoneNumbers: ReadonlyArray<{
      readonly createdAt: string;
      readonly number: string;
      readonly primary: boolean | null;
    }>;
  } | null>;
  readonly " $fragmentSpreads": FragmentRefs<"ActivityForm_lot">;
  readonly " $fragmentType": "FeedNewForm_lot";
};
export type FeedNewForm_lot$key = {
  readonly " $data"?: FeedNewForm_lot$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedNewForm_lot">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedNewForm_lot",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ActivityForm_lot"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "sellers",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PhoneNumber",
          "kind": "LinkedField",
          "name": "phoneNumbers",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "number",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "primary",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "createdAt",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    }
  ],
  "type": "Lot",
  "abstractKey": null
};

(node as any).hash = "470472ce63edb7d3901e59659f85792a";

export default node;
