import { gql, useMutation, useQuery } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box } from '@mui/material';
import {
  DataGridPro,
  GridActionsCellItem,
  type GridColDef,
  type GridRowsProp,
} from '@mui/x-data-grid-pro';
import { useParams } from 'react-router-dom';

import { useLocale } from '../../../src/hooks/locale';
import type { PortalTeamsQuery } from '../../__generated__/graphql';
import { TeamsSelect } from '../../components/teams-select/TeamsSelect';
import { DELETE_TEAM_PORTAL } from '../teams/teamQueries';

type TeamPortal = NonNullable<
  PortalTeamsQuery['portals_by_pk']
>['teams_portals'][number];

const PORTAL_TEAMS = gql`
  query PortalTeams($portalId: uuid!) {
    portals_by_pk(id: $portalId) {
      id
      name
      teams_portals(order_by: { team: { name: asc } }) {
        id
        contract_id
        team {
          id
          name
        }
      }
    }
    portal_listings(
      where: {
        _and: [{ live: { _eq: true } }, { portal_id: { _eq: $portalId } }]
      }
    ) {
      lot {
        broker {
          teams_users {
            team_id
          }
        }
      }
    }
  }
`;

const ADD_PORTAL_TEAM = gql`
  mutation AddPortalTeam($portalId: uuid!, $teamId: uuid!) {
    insert_teams_portals_one(
      object: { portal_id: $portalId, team_id: $teamId }
    ) {
      id
      portal_id
      team_id
    }
  }
`;

const UPDATE_PORTAL_TEAM = gql`
  mutation UpdatePortalTeam($id: uuid!, $contract_id: String) {
    update_teams_portals_by_pk(
      pk_columns: { id: $id }
      _set: { contract_id: $contract_id }
    ) {
      id
      contract_id
    }
  }
`;

export const PortalTeams = () => {
  const { t } = useLocale();
  const { portalId } = useParams();
  const { data, loading } = useQuery<PortalTeamsQuery>(PORTAL_TEAMS, {
    variables: { portalId },
  });

  const refetchQueries = [
    {
      query: PORTAL_TEAMS,
      variables: { portalId },
    },
  ];

  const [addPortalTeam] = useMutation(ADD_PORTAL_TEAM, { refetchQueries });
  const [deleteTeamPortal] = useMutation(DELETE_TEAM_PORTAL, {
    refetchQueries,
  });
  const [updatePortalTeam] = useMutation(UPDATE_PORTAL_TEAM, {
    refetchQueries,
  });

  const rows: GridRowsProp<TeamPortal> =
    data?.portals_by_pk?.teams_portals ?? [];

  const columns: GridColDef<TeamPortal>[] = [
    {
      field: 'team.name',
      headerName: t('Team'),
      width: 200,
      valueGetter: (_, row) => row.team.name,
    },
    {
      field: 'contract_id',
      headerName: t('Contract ID'),
      width: 150,
      editable: true,
    },
    {
      field: 'listings',
      headerName: t('Listings'),
      width: 120,
      type: 'number',
      valueGetter: (_, row) => {
        return (
          data?.portal_listings.filter(
            pl =>
              pl.lot.broker?.teams_users.find(
                tu => tu.team_id === row.team.id,
              ) !== undefined,
          ).length ?? 0
        );
      },
    },
    {
      field: 'actions',
      headerName: t('Actions'),
      type: 'actions',
      width: 80,
      getActions: params => [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label={t('Remove')}
          onClick={() => deleteTeamPortal({ variables: { id: params.row.id } })}
          disabled={
            (data?.portal_listings.filter(
              pl =>
                pl.lot.broker?.teams_users.find(
                  tu => tu.team_id === params.row.team.id,
                ) !== undefined,
            ).length ?? 0) > 0
          }
        />,
      ],
    },
  ];

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ mb: 2, maxWidth: 300 }}>
        <TeamsSelect
          size="small"
          onChange={teamId => {
            if (teamId) {
              addPortalTeam({
                variables: { portalId, teamId },
              });
            }
          }}
          blurOnSelect
          clearOnBlur
          InputProps={{
            placeholder: t('Add team'),
          }}
        />
      </Box>
      <DataGridPro
        autoHeight
        rows={rows}
        columns={columns}
        loading={loading}
        getRowId={row => row.id}
        sx={{
          backgroundColor: '#fff',
          borderRadius: 4,
          '& .MuiDataGrid-main': {
            borderRadius: 4,
          },
        }}
        processRowUpdate={(newRow, oldRow) => {
          if (newRow.contract_id !== oldRow.contract_id) {
            updatePortalTeam({
              variables: {
                id: newRow.id,
                contract_id: newRow.contract_id?.trim() || null,
              },
            });
          }
          return newRow;
        }}
      />
    </Box>
  );
};
