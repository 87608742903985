import { useEffect, useMemo, useState } from 'react';

import { type ApolloError, useMutation } from '@apollo/client';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckIcon from '@mui/icons-material/Check';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  CircularProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
  Snackbar,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { toGlobalId } from '../../../shared/global-id';
import { type Translate, useLocale } from '../../../src/hooks/locale';
import { gql } from '../../__generated__';
import type {
  GetListingQuickActionsDataQuery,
  ListingStatusSelectFragment,
  UpdateListingMutation,
} from '../../__generated__/graphql';
import { UPDATE_LISTING } from '../../pages/listings/lotsQueries';
import { getLotStatusTagVariant } from '../LotCardInfo';

export const LISTING_STATUS_SELECT_FRAGMENT = gql(`
  fragment ListingStatusSelect on lots {
    id
    status
  }
`);

export const translateListingStatus = (status: string, t: Translate) => {
  switch (status) {
    case 'draft':
      return t('draft');
    case 'available_coming_soon':
      return t('availableComingSoon');
    case 'available_off_market':
      return t('availableOffMarket');
    case 'available':
      return t('available');
    case 'paused':
      return t('paused');
    case 'reserved':
      return t('reserved');
    case 'won':
      return t('won');
    case 'lost_sold_someone':
      return t('lostSoldBySomeone');
    case 'lost_sold_owner':
      return t('lostSoldByOwner');
    case 'abandoned':
      return t('lostAbandoned');
  }

  return null;
};

const translateListingStatuses = (
  statuses: GetListingQuickActionsDataQuery['lots_status_enum'],
  t: Translate,
) =>
  statuses.map(status => ({
    id: status.value,
    label: translateListingStatus(status.value, t),
  }));

interface ListingStatusSelectProps {
  useLegacyLinks?: boolean;
  listing?: ListingStatusSelectFragment | null;
  allStatuses?: GetListingQuickActionsDataQuery['lots_status_enum'];
  onListingStatusUpdated?: (status: string) => void;
  isLoading?: boolean;
  size?: 'small' | 'medium' | 'large';
}

export const ListingStatusSelect: React.FC<ListingStatusSelectProps> = ({
  useLegacyLinks,
  listing,
  onListingStatusUpdated,
  allStatuses,
  isLoading = false,
  size,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [updateStatusError, setUpdateStatusError] =
    useState<ApolloError | null>(null);
  const [updateLot, { loading, error }] =
    useMutation<UpdateListingMutation>(UPDATE_LISTING);
  const { t } = useLocale();
  const navigate = useNavigate();

  useEffect(() => {
    if (error != null) {
      setUpdateStatusError(error);
    }
  }, [error]);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (loading) {
      return;
    }

    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const translatedStatuses = useMemo(
    () => translateListingStatuses(allStatuses ?? [], t),
    [allStatuses, t],
  );

  const onListingStatusChange = (status: string) => {
    if (listing == null) {
      return;
    }

    if (status === 'won') {
      navigate({
        pathname: `/transactions/new/`,
        search: `?lotId=${
          useLegacyLinks ? toGlobalId('Lot', listing.id) : listing.id
        }`,
      });
    }

    updateLot({
      variables: {
        id: listing.id,
        lot: { status },
      },
    });
    handleCloseMenu();
    onListingStatusUpdated?.(status);
  };

  const btnColor = useMemo(
    () => getLotStatusTagVariant(listing?.status ?? ''),
    [listing?.status],
  );

  return isLoading ? (
    <Skeleton width={100} variant="rounded">
      <LoadingButton size={size} endIcon={<ArrowDropDownIcon />}>
        placeholder
      </LoadingButton>
    </Skeleton>
  ) : (
    <>
      <LoadingButton
        size={size}
        variant="contained"
        disableElevation
        color={btnColor}
        onClick={handleOpenMenu}
        id="listing-statuses-button"
        aria-controls={open ? 'listing-statuses-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        endIcon={<ArrowDropDownIcon />}
        loading={loading}
        loadingIndicator={<CircularProgress disableShrink size={16} />}
      >
        {listing != null ? translateListingStatus(listing.status, t) : ''}
      </LoadingButton>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        id="listing-statuses-menu"
        MenuListProps={{
          'aria-labelledby': 'listing-statuses-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        sx={{
          '& .MuiPaper-root': {
            mt: 1,
          },
        }}
      >
        {translatedStatuses.map(({ id, label }) => {
          const isSelected = id === listing?.status;
          return (
            <MenuItem
              key={id}
              onClick={() => onListingStatusChange(id)}
              selected={isSelected}
              disabled={isSelected}
              disableRipple
            >
              {isSelected && (
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
              )}
              <ListItemText inset={!isSelected}>{label}</ListItemText>
            </MenuItem>
          );
        })}
      </Menu>
      <Snackbar
        open={updateStatusError != null}
        autoHideDuration={6000}
        onClose={() => setUpdateStatusError(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setUpdateStatusError(null)}
          severity="error"
          sx={{ width: '100%' }}
        >
          {t('An error occured while updating the listing status.')}
        </Alert>
      </Snackbar>
    </>
  );
};
