/**
 * @generated SignedSource<<b1d01fa4f1c59d1784cc532b4a516d7c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ActivityTypeEnum = "assignment" | "call" | "change" | "create_user" | "email" | "match" | "note" | "offer" | "origination" | "requalification" | "task" | "visit";
import { FragmentRefs } from "relay-runtime";
export type Feed_activities$data = ReadonlyArray<{
  readonly activityType: ActivityTypeEnum;
  readonly assignedTo: {
    readonly __typename: "User";
  } | null;
  readonly buyerLead: {
    readonly id: string;
    readonly lot: {
      readonly id: string;
    };
  } | null;
  readonly createdBy: {
    readonly __typename: "User";
  } | null;
  readonly enquiry: {
    readonly id: string;
    readonly lot: {
      readonly id: string;
    } | null;
  } | null;
  readonly id: string;
  readonly lead: {
    readonly id: string;
    readonly lot: {
      readonly id: string;
    } | null;
  } | null;
  readonly lot: {
    readonly id: string;
  } | null;
  readonly offer: {
    readonly id: string;
  } | null;
  readonly user: {
    readonly id: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"FeedItem_activity">;
  readonly " $fragmentType": "Feed_activities";
}>;
export type Feed_activities$key = ReadonlyArray<{
  readonly " $data"?: Feed_activities$data;
  readonly " $fragmentSpreads": FragmentRefs<"Feed_activities">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  }
],
v2 = [
  (v0/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Lot",
  "kind": "LinkedField",
  "name": "lot",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": null
},
v4 = [
  (v0/*: any*/),
  (v3/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "Feed_activities",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeedItem_activity"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "activityType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "assignedTo",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Enquiry",
      "kind": "LinkedField",
      "name": "enquiry",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Lead",
      "kind": "LinkedField",
      "name": "lead",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BuyerLead",
      "kind": "LinkedField",
      "name": "buyerLead",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Offer",
      "kind": "LinkedField",
      "name": "offer",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Activity",
  "abstractKey": "__isActivity"
};
})();

(node as any).hash = "132e195e02bb6ac411d23a9f87c7756e";

export default node;
