import CheckIcon from '@mui/icons-material/Check';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';

import { type Translate, useLocale } from '../../src/hooks/locale';
import { gql } from '../__generated__';
import { type PropertyFeaturesFragment } from '../__generated__/graphql';
import type { GetListingDetailsData } from '../pages/listings/lotsQueries';

import { equipmentConditionalFields } from './property-form/forms-definitions/propertyFeaturesDefinition';

type TProperty = NonNullable<GetListingDetailsData['property']>;

export const PROPERTY_FEATURES_FRAGMENT = gql(`
  fragment PropertyFeatures on properties {
    is_luxury
    is_child_friendly
    is_wheelchair_accessible
    has_gym
    has_view
    has_attic
    has_sauna
    has_cellar
    has_elevator
    has_fireplace
    has_storage_room
    has_swimming_pool
    has_wellness_area
    has_photovoltaic_panels
  }
`);

interface PropertyFeaturesProps {
  property: TProperty;
}

type PropertyFeaturesFields = {
  name: keyof PropertyFeaturesFragment;
  label: string;
  shouldRender?: (property: TProperty) => boolean;
};

const getPropertyFeaturesFieldsDefinition = (t: Translate) => {
  const fields: PropertyFeaturesFields[] = [
    {
      name: 'has_gym',
      label: t('Has gym'),
    },
    {
      name: 'has_view',
      label: t('Has view'),
    },
    {
      name: 'has_attic',
      label: t('Has attic'),
    },
    {
      name: 'has_sauna',
      label: t('Has sauna'),
    },
    {
      name: 'has_cellar',
      label: t('Has cellar'),
    },
    {
      name: 'has_elevator',
      label: t('Has elevator'),
    },
    {
      name: 'has_fireplace',
      label: t('Has fireplace'),
    },
    {
      name: 'has_storage_room',
      label: t('Has storage room'),
    },
    {
      name: 'has_swimming_pool',
      label: t('Has swimming pool'),
    },
    {
      name: 'has_wellness_area',
      label: t('Has wellness area'),
    },
    {
      name: 'has_photovoltaic_panels',
      label: t('Has photovoltaic panels'),
    },
    {
      name: 'is_luxury',
      label: t('Is luxury'),
    },
    {
      name: 'is_child_friendly',
      label: t('Is child friendly'),
    },
    {
      name: 'is_wheelchair_accessible',
      label: t('Is wheelchair accessible'),
    },
  ];

  return fields.map(field => {
    const shouldRender = equipmentConditionalFields.get(field.name as any);

    return { ...field, shouldRender } as PropertyFeaturesFields;
  });
};

export const PropertyFeatures: React.FC<PropertyFeaturesProps> = ({
  property,
}) => {
  const { t } = useLocale();

  const features = getPropertyFeaturesFieldsDefinition(t);
  const nbDisplayedFields = features.reduce((acc, field) => {
    if (
      (field.shouldRender && !field.shouldRender(property)) ||
      !property[field.name]
    ) {
      return acc;
    }

    return acc + 1;
  }, 0);

  return (
    <Box flex={1}>
      <Typography variant="h6" sx={{ fontWeight: 700, mb: 0.5, mt: 1 }}>
        {t('equipmentAndFeatures')}
      </Typography>
      {nbDisplayedFields === 0 ? (
        <Typography variant="body2" sx={{ my: 0.5 }}>
          {t('none')}
        </Typography>
      ) : (
        <List
          dense
          sx={{
            columnCount: { xs: 1, md: nbDisplayedFields > 1 ? 2 : 1 },
            columnGap: 5,
            pt: 0,
            pb: 0.5,
          }}
        >
          {features.map(
            field =>
              (!field.shouldRender || field.shouldRender(property)) &&
              !!property[field.name] && (
                <ListItem
                  sx={{ width: 'auto' }}
                  disableGutters
                  key={field.name}
                >
                  <ListItemIcon sx={{ minWidth: 0, mr: 1 }}>
                    <CheckIcon />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="body2">{field.label}</Typography>
                  </ListItemText>
                </ListItem>
              ),
          )}
        </List>
      )}
    </Box>
  );
};
