import * as React from 'react';

import {
  FilledInput,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  useFormControl,
} from '@material-ui/core';
import { Image } from '@realadvisor/image';
import { useCombobox } from 'downshift';
import { graphql } from 'react-relay';
import { Flex } from 'react-system';

import { Cancel } from '../icons/cancel';
import { Image as ImageIcon } from '../icons/image';
import { RelayHasuraWrapper } from '../networking';
import { InputPopup } from '../src/controls/popup';
import { useLocale } from '../src/hooks/locale';
import { useInputQuery } from '../src/hooks/relay';
import { useTheme } from '../src/hooks/theme';

import type { organisationInputQuery } from './__generated__/organisationInputQuery.graphql';
import {
  fromGlobalId,
  fromHasuraGlobalId,
  toGlobalId,
  toHasuraGlobalId,
} from './global-id';

graphql`
  fragment organisationInput_organisation on organisations {
    id
    name
    organisation_images(order_by: { is_primary: desc }) {
      image {
        url
      }
    }
  }
`;

export type OrganisationValue =
  organisationInputQuery['response']['organisations_search_connection']['edges'][number]['node'];

const OrganisationAvatar = ({
  organisation,
  size,
}: {
  organisation: OrganisationValue;
  size: number;
}) => {
  const { colors } = useTheme();
  const imageUrl = organisation.organisation_images[0]?.image.url;
  return (
    <Flex flexShrink={0} css={{ width: size, height: size }} mr={2}>
      {imageUrl == null ? (
        <ImageIcon size={size} fill={colors.grey400} />
      ) : (
        <Image
          objectFit="contain"
          options={{ w: 40, h: 40, c: 'fit' }}
          src={imageUrl}
        />
      )}
    </Flex>
  );
};

type Props = {
  value: null | OrganisationValue;
  onChange: (value: null | OrganisationValue) => void;
  onBlur?: () => void;
};

export const OrganisationInput = (props: Props) => {
  const formControl = useFormControl();
  const disabled = formControl?.disabled ?? false;
  const { t } = useLocale();

  const [data, refetch] = useInputQuery<organisationInputQuery>(
    graphql`
      query organisationInputQuery($search: String!) {
        organisations_search_connection(first: 20, args: { search: $search }) {
          edges {
            node {
              ...organisationInput_organisation @relay(mask: false)
            }
          }
        }
      }
    `,
  );

  const items =
    data?.organisations_search_connection.edges.map(edge => edge.node) ?? [];

  const targetRef = React.useRef(null);

  const combobox = useCombobox({
    items,
    // make selectedItem always controlled
    selectedItem: props.value ?? null,
    itemToString: item => item?.name ?? '',
    onInputValueChange: ({ isOpen, inputValue }) => {
      if (isOpen) {
        refetch({ search: inputValue ?? '' });
      }
    },
    onSelectedItemChange: ({ selectedItem }) =>
      props.onChange(selectedItem ?? null),
  });

  const { isOpen, highlightedIndex, inputValue, selectedItem } = combobox;
  const { getComboboxProps, openMenu } = combobox;
  const { getInputProps, getMenuProps, getItemProps } = combobox;

  return (
    <div {...getComboboxProps()}>
      <FilledInput
        {...getInputProps({
          ref: targetRef,
          onFocus: () => {
            refetch({ search: inputValue ?? '' });
            openMenu();
          },
          onBlur: props.onBlur,
        })}
        startAdornment={
          selectedItem && (
            <InputAdornment position="start">
              <OrganisationAvatar organisation={selectedItem} size={24} />
            </InputAdornment>
          )
        }
        endAdornment={
          selectedItem &&
          disabled === false && (
            <InputAdornment position="end">
              <IconButton onClick={() => props.onChange(null)}>
                <Cancel />
              </IconButton>
            </InputAdornment>
          )
        }
      />

      <InputPopup referenceRef={targetRef} open={isOpen}>
        <List {...getMenuProps()}>
          {items.length === 0 && (
            <ListItem disabled={true}>{t('No options')}</ListItem>
          )}
          {isOpen &&
            items.map((item, index) => (
              <ListItem
                {...getItemProps({ index, item })}
                key={item.id}
                button={true}
                selected={highlightedIndex === index}
              >
                <OrganisationAvatar organisation={item} size={40} />
                <ListItemText>{item.name}</ListItemText>
              </ListItem>
            ))}
        </List>
      </InputPopup>
    </div>
  );
};

export type OrganisationLegacyValue = {
  readonly id: string;
  readonly name: string | null;
  readonly primaryImage: {
    readonly url: string;
  } | null;
};

type LegacyProps = {
  value: null | OrganisationLegacyValue;
  onChange: (value: null | OrganisationLegacyValue) => void;
  onBlur?: () => void;
};

export const OrganisationLegacyInput = (props: LegacyProps) => {
  const { value, onChange } = props;
  let modernValue: null | OrganisationValue = null;
  if (value != null) {
    modernValue = {
      id: toHasuraGlobalId('organisations', fromGlobalId(value.id)),
      name: value.name,
      organisation_images: [
        {
          image: {
            url: value.primaryImage?.url ?? null,
          },
        },
      ],
    };
  }

  return (
    <RelayHasuraWrapper>
      <OrganisationInput
        value={modernValue}
        onChange={newValue => {
          let legacyValue: null | OrganisationLegacyValue = null;
          if (newValue != null) {
            const imageUrl = newValue.organisation_images[0]?.image.url;
            legacyValue = {
              id: toGlobalId('Organisation', fromHasuraGlobalId(newValue.id)),
              name: newValue.name,
              primaryImage: imageUrl == null ? null : { url: imageUrl },
            };
          }
          onChange(legacyValue);
        }}
        onBlur={props.onBlur}
      />
    </RelayHasuraWrapper>
  );
};
