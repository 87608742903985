/**
 * @generated SignedSource<<6d103233757a1e6d1e92cc6b05f9891d>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
type leadSnippet_lead$fragmentType = any;
import type { FragmentType } from "relay-runtime";
declare export opaque type leadDeleteDialog_lead$fragmentType: FragmentType;
export type leadDeleteDialog_lead$data = {|
  +id: string,
  +$fragmentSpreads: leadSnippet_lead$fragmentType,
  +$fragmentType: leadDeleteDialog_lead$fragmentType,
|};
export type leadDeleteDialog_lead$key = {
  +$data?: leadDeleteDialog_lead$data,
  +$fragmentSpreads: leadDeleteDialog_lead$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "leadDeleteDialog_lead",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "leadSnippet_lead"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Lead",
  "abstractKey": null
};

(node/*: any*/).hash = "c55acacccb964d18555701c8c80d0fbf";

export default node;
