/**
 * @generated SignedSource<<54f6156dbe7a96efd7f4ae30a1be8dbd>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type userInfo_enquiry$fragmentType: FragmentType;
export type userInfo_enquiry$data = {|
  +emailAddress: ?string,
  +firstName: ?string,
  +lastName: ?string,
  +phoneNumber: ?string,
  +$fragmentType: userInfo_enquiry$fragmentType,
|};
export type userInfo_enquiry$key = {
  +$data?: userInfo_enquiry$data,
  +$fragmentSpreads: userInfo_enquiry$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "userInfo_enquiry",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emailAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phoneNumber",
      "storageKey": null
    }
  ],
  "type": "Enquiry",
  "abstractKey": null
};

(node/*: any*/).hash = "be8268e58d0e43cb85b3fa1416b0543f";

export default node;
