/**
 * @generated SignedSource<<a270825f027192b882aea0ba8dae882c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ActivityTypeEnum = "assignment" | "call" | "change" | "create_user" | "email" | "match" | "note" | "offer" | "origination" | "requalification" | "task" | "visit";
import { FragmentRefs } from "relay-runtime";
export type ActivityForm_activity$data = {
  readonly activityType: ActivityTypeEnum;
  readonly " $fragmentSpreads": FragmentRefs<"ActivityEventForm_activity" | "ActivityNoteForm_activity">;
  readonly " $fragmentType": "ActivityForm_activity";
};
export type ActivityForm_activity$key = {
  readonly " $data"?: ActivityForm_activity$data;
  readonly " $fragmentSpreads": FragmentRefs<"ActivityForm_activity">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActivityForm_activity",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "activityType",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ActivityNoteForm_activity"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ActivityEventForm_activity"
    }
  ],
  "type": "Activity",
  "abstractKey": "__isActivity"
};

(node as any).hash = "d9d92451703e70b0a3033dddb4d64b27";

export default node;
