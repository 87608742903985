import { createContext, useContext, useEffect, useRef, useState } from 'react';

import { LocationOn } from '@mui/icons-material';
import mapbox from 'mapbox-gl';
import type { Map, Marker } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { createPortal } from 'react-dom';

import { MAPBOX_TOKEN } from '../../src/config';
import { useAppData } from '../providers/AppDataProvider';

// Create a context that will hold the map reference.
const MapContext = createContext<React.MutableRefObject<Map | null> | null>(
  null,
);

// Custom hook to use the context
const useMap = () => {
  const context = useContext(MapContext);
  if (!context) {
    throw new Error('useMap must be used within a MapProvider');
  }
  return context;
};

interface MapboxProps {
  mapRef?: React.MutableRefObject<Map | null>;
  lat?: number;
  lng?: number;
  zoom?: number;
  defaultLat?: number;
  defaultLng?: number;
  defaultZoom?: number;
  style?: React.CSSProperties;
  mapStyle?: string | mapbox.Style;
  children?: React.ReactNode;
}

export const Mapbox = ({
  mapRef: propsMapRef,
  lat,
  lng,
  zoom,
  defaultLat = 40.7,
  defaultLng = -74,
  defaultZoom = 12,
  style,
  mapStyle = 'mapbox://styles/mapbox/streets-v12',
  children,
}: MapboxProps) => {
  const internalMapRef = useRef<mapbox.Map | null>(null);
  const mapRef = propsMapRef || internalMapRef;
  const containerRef = useRef(null);

  const { me } = useAppData();
  const myTeam = me?.default_team;
  const tenant = me?.tenant;

  // Initialize map when component mounts
  useEffect(() => {
    if (!containerRef.current) {
      return;
    } // no map container
    if (mapRef.current) {
      return;
    } // initialize map only once

    mapRef.current = new mapbox.Map({
      container: containerRef.current,
      style: mapStyle || 'mapbox://styles/mapbox/streets-v12',
      center: [
        lng || myTeam?.lng || tenant?.lng || defaultLng,
        lat || myTeam?.lat || tenant?.lat || defaultLat,
      ],
      zoom: zoom || defaultZoom,
      accessToken: MAPBOX_TOKEN,
      projection: {
        name: 'globe',
      },
    });
  }, [
    mapRef,
    lat,
    lng,
    zoom,
    defaultLat,
    defaultLng,
    defaultZoom,
    myTeam,
    tenant,
    mapStyle,
  ]);

  // Refresh map whenever center or zoom changes
  useEffect(() => {
    if (!mapRef.current) {
      return;
    } // wait for map to initialize
    if (lat === undefined || lng === undefined) {
      return;
    } // no center provided
    mapRef.current.flyTo({ center: [lng, lat], zoom, speed: 5 });
  }, [mapRef, lat, lng, zoom]);

  return (
    <>
      <MapContext.Provider value={mapRef}>
        <div
          ref={containerRef}
          style={{
            ...style,
            width: '100%',
            height: '100%',
            position: 'relative',
          }}
          className="map-container"
        />
        {children}
      </MapContext.Provider>
    </>
  );
};

type Anchor =
  | 'center'
  | 'top'
  | 'bottom'
  | 'left'
  | 'right'
  | 'top-left'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-right';

const useMarker = (
  markerRef: React.MutableRefObject<mapbox.Marker | null>,
  lat: number,
  lng: number,
  anchor: Anchor = 'center',
) => {
  const mapRef = useMap();
  const map = mapRef.current;
  const [container, setContainer] = useState<HTMLElement | null>(null);

  // initialize marker
  useEffect(() => {
    if (!map) {
      return;
    } // Do nothing if map is not initialized

    const element = document.createElement('div');
    const marker = new mapbox.Marker({ element, anchor })
      .setLngLat([lng, lat])
      .addTo(map);

    setContainer(element);
    markerRef.current = marker;

    // Cleanup function to remove marker from map on unmount
    return () => {
      marker.remove();
    };
  }, [map, markerRef, lat, lng, anchor]);

  return container;
};

interface StaticMarkerProps {
  lat: number;
  lng: number;
  anchor?: Anchor;
  children?: React.ReactNode;
}

export const StaticMarker = ({
  lat,
  lng,
  anchor,
  children,
}: StaticMarkerProps) => {
  const markerRef = useRef<Marker | null>(null);
  const container = useMarker(markerRef, lat, lng, anchor);
  useEffect(() => {
    if (container) {
      container.style.cursor = 'arrow';
    }
  }, [container]);
  return container && createPortal(children, container);
};

type LngLatLike = {
  lat: number;
  lng: number;
};

interface DraggableMarkerProps {
  lat: number;
  lng: number;
  anchor?: Anchor;
  onDragend: (latLng: LngLatLike) => void;
  children: ({ dragging }: { dragging: boolean }) => React.ReactNode;
}

export const DraggableMarker = ({
  lat,
  lng,
  anchor,
  onDragend,
  children,
}: DraggableMarkerProps) => {
  const markerRef = useRef<Marker | null>(null);
  const [dragging, setDragging] = useState(false);
  const container = useMarker(markerRef, lat, lng, anchor); // Adjusted to useMarker with the correct params

  useEffect(() => {
    if (container && markerRef.current) {
      container.style.cursor = 'move';
      markerRef.current.setDraggable(true);
    }
  }, [container]);

  useEffect(() => {
    const marker = markerRef.current;
    if (marker) {
      const handleDragstart = () => setDragging(true);
      const handleDragend = (event: any) => {
        // Type for mapbox dragend event
        const lngLat = event.target.getLngLat();
        onDragend({ lat: lngLat.lat, lng: lngLat.lng });
        setDragging(false);
      };
      marker.on('dragstart', handleDragstart);
      marker.on('dragend', handleDragend);
      return () => {
        marker.off('dragstart', handleDragstart);
        marker.off('dragend', handleDragend);
      };
    }
  }, [onDragend]);

  return container ? createPortal(children({ dragging }), container) : null;
};

// LocationMarkerProps type definition
interface LocationMarkerProps {
  lat: number;
  lng: number;
  onDragend?: (latLng: LngLatLike) => void;
}

export const LocationMarker = ({
  lat,
  lng,
  onDragend,
}: LocationMarkerProps) => {
  // Define your style object here
  const style = {
    filter: [
      'drop-shadow(1px 1px 1px rgba(255, 255, 255, 1))',
      'drop-shadow(1px -1px 1px rgba(255, 255, 255, 1))',
      'drop-shadow(-1px 1px 1px rgba(255, 255, 255, 1))',
      'drop-shadow(-1px -1px 1px rgba(255, 255, 255, 1))',
    ].join(' '),
    width: 40,
    height: 40,
    color: '#2196f3',
  };

  if (onDragend) {
    return (
      // Your JSX for draggable marker, replace DraggableMarker with your actual component
      <DraggableMarker
        lat={lat}
        lng={lng}
        anchor="bottom"
        onDragend={onDragend}
      >
        {({ dragging }) => (
          <LocationOn
            css={[
              style,
              {
                transition: 'all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
                willChange: 'width, height',
                ':hover': { color: '#64b5f6', width: 44, height: 44 },
                '&, &:hover': dragging
                  ? { color: '#64b5f6', width: 52, height: 52 }
                  : {},
              },
            ]}
          />
        )}
      </DraggableMarker>
    );
  } else {
    return (
      // Your JSX for static marker, replace LocationOn with your actual component
      <StaticMarker lat={lat} lng={lng} anchor="bottom">
        <LocationOn style={style} />
      </StaticMarker>
    );
  }
};
