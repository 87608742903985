export const chunkEvery = <T>(
  array: ReadonlyArray<T>,
  chunkSize: number,
): ReadonlyArray<ReadonlyArray<T>> => {
  if (chunkSize <= 0) {
    throw Error('Chunk size should be 1 or greater');
  }
  // take upper bound to include tail
  const chunksCount = Math.ceil(array.length / chunkSize);
  const result = [];
  for (let i = 0; i < chunksCount; i += 1) {
    const offset = i * chunkSize;
    result.push(array.slice(offset, offset + chunkSize));
  }
  return result;
};

export const uniqueBy = <T>(
  array: ReadonlyArray<T>,
  by: (item: T) => unknown,
): ReadonlyArray<T> => {
  const cache = new Set();
  const result = [];
  for (const item of array) {
    const byValue = by(item);
    if (cache.has(byValue) === false) {
      cache.add(byValue);
      result.push(item);
    }
  }
  return result;
};

type Primitive = number | string | boolean | null | void;

const getPrivimiveTypeOrder = (value: Primitive) => {
  if (typeof value === 'boolean') {
    return 1;
  }
  if (typeof value === 'number') {
    return 2;
  }
  if (typeof value === 'string') {
    return 3;
  }
  // undefined is placed to the end by array.sort and not passed to this function
  if (value == null) {
    return 4;
  }
  return 5;
};

const compareAsc = <T extends Primitive>(a: T, b: T): number => {
  const typeA = getPrivimiveTypeOrder(a);
  const typeB = getPrivimiveTypeOrder(b);
  if (typeA !== typeB) {
    return typeA - typeB;
  }
  if (typeof a === 'boolean' && typeof b === 'boolean') {
    // eslint-disable-next-line no-restricted-syntax
    return Number(a) - Number(b);
  }
  if (typeof a === 'number' && typeof b === 'number') {
    return a - b;
  }
  if (typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b);
  }
  return 0;
};

const compareDesc = <T extends Primitive>(a: T, b: T): number => {
  return -1 * compareAsc(a, b);
};

export const orderBy = <Outer, Inner extends Primitive>(
  array: Outer[],
  by: (item: Outer) => Inner,
  direction: 'desc' | 'asc' = 'asc',
): Outer[] => {
  const compare = direction === 'desc' ? compareDesc : compareAsc;
  return array.slice().sort((a, b) => compare(by(a), by(b)));
};
