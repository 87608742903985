/**
 * @generated SignedSource<<2fa10c0e928d6f37687f8ac0699c9440>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeedDeleteDialog_activity$data = {
  readonly createdBy: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"userAvatar_user">;
  } | null;
  readonly id: string;
  readonly " $fragmentType": "FeedDeleteDialog_activity";
};
export type FeedDeleteDialog_activity$key = {
  readonly " $data"?: FeedDeleteDialog_activity$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedDeleteDialog_activity">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedDeleteDialog_activity",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "userAvatar_user"
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Activity",
  "abstractKey": "__isActivity"
};
})();

(node as any).hash = "ffc68cfb1b3dd12a1b9fe5f590280117";

export default node;
