import * as React from 'react';

export const useSelectionBound = (
  inputRef: { current: null | HTMLInputElement | HTMLTextAreaElement },
  start: number,
  end: number,
) => {
  React.useEffect(() => {
    const element = inputRef.current;

    if (element) {
      const handleSelectionChange = () => {
        // handle selection change only when input is active
        if (document.activeElement === element) {
          const { selectionStart, selectionEnd } = element;
          if (selectionStart != null && selectionEnd != null) {
            // handle negative end similar to array slice
            const realEnd = end < 0 ? element.value.length + end + 1 : end;
            if (selectionStart < start || realEnd < selectionEnd) {
              // clamp selection with provided values
              element.setSelectionRange(
                Math.max(start, Math.min(selectionStart, realEnd)),
                Math.max(start, Math.min(selectionEnd, realEnd)),
              );
            }
          }
        }
      };

      document.addEventListener('selectionchange', handleSelectionChange);

      return () => {
        document.removeEventListener('selectionchange', handleSelectionChange);
      };
    }
  }, [inputRef, start, end]);
};
