/**
 * @generated SignedSource<<c2ef935837b0a02c871536fe1b3cf86d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ActivityEventForm_enquiry$data = {
  readonly __typename: "Enquiry";
  readonly id: string;
  readonly " $fragmentType": "ActivityEventForm_enquiry";
};
export type ActivityEventForm_enquiry$key = {
  readonly " $data"?: ActivityEventForm_enquiry$data;
  readonly " $fragmentSpreads": FragmentRefs<"ActivityEventForm_enquiry">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActivityEventForm_enquiry",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Enquiry",
  "abstractKey": null
};

(node as any).hash = "ab88c0ab1cd889e6c7b54eaf12845957";

export default node;
