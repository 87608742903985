import { useState } from 'react';

import { useMutation } from '@apollo/client';
import { DeleteOutline, PersonAdd } from '@mui/icons-material';
import {
  Alert,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { useLocale } from '../../../src/hooks/locale';
import { gql } from '../../__generated__';
import type { LeadAgents_LeadFragment } from '../../__generated__/graphql';
import { TimeAgo } from '../../components/TimeAgo';
import { UserSelect } from '../../components/user-select/UserSelect';

import { ADD_LEAD_AGENT, DELETE_LEAD_AGENT } from './leadsQueries';

export const LEAD_AGENTS_FRAGMENT = gql(`
  fragment LeadAgents_lead on leads {
    id
    lead_agents {
      id
      created_at
      source
      user {
        id
        first_name
        last_name
        default_team {
          name
        }
      }
    }
  }
`);

interface LeadAgentsProps {
  lead_id: string;
  lead_agents?: LeadAgents_LeadFragment['lead_agents'];
}

export const LeadAgents = ({ lead_id, lead_agents }: LeadAgentsProps) => {
  const { t } = useLocale();
  const [mutationError, setMutationError] = useState<string | null>(null);
  const [showUserSelect, setShowUserSelect] = useState<boolean>(false);

  const [addLeadAgent] = useMutation(ADD_LEAD_AGENT, {
    refetchQueries: ['GetLead', 'LeadFeed'],
  });
  const [DeleteLeadAgent] = useMutation(DELETE_LEAD_AGENT, {
    refetchQueries: ['GetLead', 'LeadFeed'],
  });

  return (
    <>
      <TableContainer component={Paper} elevation={0} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={3}>{t('Lead Agents')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lead_agents?.map(lead_agent => (
              <TableRow key={lead_agent.id}>
                <TableCell>
                  <Typography variant="body1">
                    {lead_agent.user.first_name} {lead_agent.user.last_name}
                  </Typography>
                  <Typography variant="body2">
                    {lead_agent.user.default_team?.name}
                  </Typography>
                </TableCell>
                <TableCell>
                  {lead_agent.source}
                  <br />
                  <TimeAgo dateString={lead_agent.created_at} />
                </TableCell>
                <TableCell width="1%">
                  <IconButton
                    className="delete-button"
                    onClick={() =>
                      DeleteLeadAgent({
                        variables: { id: lead_agent.id },
                      }).catch(error => {
                        setMutationError(error.message);
                      })
                    }
                  >
                    <DeleteOutline />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {!lead_agents?.length && (
              <TableRow>
                <TableCell colSpan={2}>{t('Empty')}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {mutationError && <Alert severity="error">{mutationError}</Alert>}
      {showUserSelect && (
        <UserSelect
          autoFocus
          InputProps={{
            onBlur: () => setShowUserSelect(false),
          }}
          onBlur={() => setShowUserSelect(false)}
          onChange={user_id => {
            if (!user_id) {
              return;
            }
            addLeadAgent({
              variables: {
                lead_id,
                user_id,
              },
              onError: error => {
                setMutationError(error.message);
              },
            }).then(() => setShowUserSelect(false));
          }}
          filters={{
            is_broker: { _eq: true },
            id: { _nin: lead_agents?.map(agent => agent.user.id) },
          }}
        />
      )}
      {!showUserSelect && (
        <Button onClick={() => setShowUserSelect(true)}>
          <PersonAdd sx={{ mr: 1 }} />
          {t('Add agent')}
        </Button>
      )}
    </>
  );
};
