type WindowOptions = {
  width?: number;
  height?: number;
};

type Options = WindowOptions & {
  onPostMessage: (event: MessageEvent) => any;
};

function getWindowFeataures(options?: WindowOptions) {
  const width = options?.width != null ? options.width : 600;
  const height = options?.height != null ? options.height : 600;
  const { screenLeft, screenTop, innerWidth, innerHeight, screen } = window;
  const html = window.document.documentElement;
  const dualScreenLeft: number =
    // @ts-ignore left does not exist on screen
    screenLeft !== undefined ? screenLeft : screen.left;
  const dualScreenTop: number =
    // @ts-ignore top does not exist on screen
    screenTop !== undefined ? screenTop : screen.top;
  const w: number = innerWidth || html.clientWidth || screen.width;
  const h: number = innerHeight || html.clientHeight || screen.height;

  const left = w / 2 - width / 2 + dualScreenLeft;
  const top = h / 2 - height / 2 + dualScreenTop;
  return `width=${width},height=${height},left=${left},top=${top}`;
}

export function openWindow(
  uri: string,
  { onPostMessage, ...options }: Options,
): Promise<void> {
  const win = window.open(uri, undefined, getWindowFeataures(options));

  let executor: null | { resolve: (data: any) => void } = null;

  const onResolve = (data: any) => {
    window.removeEventListener('message', onPostMessageWrapper);

    if (executor) {
      const currentExecutor = executor;
      win?.close();
      currentExecutor.resolve(data);
      executor = null;
    }
  };

  const onPostMessageWrapper = (event: MessageEvent) => {
    if (onPostMessage) {
      const result = onPostMessage(event);
      if (result) {
        onResolve(result);
      }
    }
  };

  window.addEventListener('message', onPostMessageWrapper, true);

  return new Promise<void>(resolve => {
    executor = { resolve };
  });
}
