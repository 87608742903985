// Generated from src/svgs/nav-calendar.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const NavCalendar = ({ size, fill, className }: Props) => {
  return (
    <svg viewBox="0 0 25 25" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><path d="M24 2h-5V.5a.5.5 0 0 0-1 0V2H7V.5a.5.5 0 0 0-1 0V2H1a1 1 0 0 0-1 1v20a1 1 0 0 0 1 1h23a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1zm0 21H1V8h23zm0-16H1V3h23zm-9 6h-2v-2h2zm5 0h-2v-2h2zM7 18H5v-2h2zm5 0h-2v-2h2z" /></svg>
  );
}
