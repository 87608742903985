/**
 * @generated SignedSource<<f4a4cd26a6e13fd9ca87ebb5b574b85f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type LeadStatus = "active" | "lost" | "won";
import { FragmentRefs } from "relay-runtime";
export type leadQualificationCard_lead$data = {
  readonly id: string;
  readonly leadLost: {
    readonly id: string;
    readonly label: string;
  } | null;
  readonly mandateProbability: number | null;
  readonly predictedListingDate: string | null;
  readonly stage: {
    readonly id: string;
    readonly label: string;
    readonly pipeline: {
      readonly id: string;
      readonly label: string | null;
    } | null;
  } | null;
  readonly status: LeadStatus;
  readonly tags: ReadonlyArray<string> | null;
  readonly " $fragmentType": "leadQualificationCard_lead";
};
export type leadQualificationCard_lead$key = {
  readonly " $data"?: leadQualificationCard_lead$data;
  readonly " $fragmentSpreads": FragmentRefs<"leadQualificationCard_lead">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "leadQualificationCard_lead",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mandateProbability",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "predictedListingDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tags",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LeadStage",
      "kind": "LinkedField",
      "name": "stage",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Pipeline",
          "kind": "LinkedField",
          "name": "pipeline",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Dictionary",
      "kind": "LinkedField",
      "name": "leadLost",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Lead",
  "abstractKey": null
};
})();

(node as any).hash = "3e952839d89ebfff6514eee1bd4f7738";

export default node;
