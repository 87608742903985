import * as React from 'react';

import { FilterDialog } from '../SelectPopover/FilterDialog';
import { SelectPopover } from '../SelectPopover/SelectPopover';

import { useFilter } from './context';

type Props = {
  empty?: boolean;
  label: React.ReactNode;
  dialogTitle: React.ReactNode;
  onReset?: () => void;
  children: React.ReactNode;
};

export const Filter = (props: Props) => {
  const { dialogBg } = useFilter();
  const [open, setOpen] = React.useState(false);

  return (
    <SelectPopover
      label={props.label}
      empty={props.empty}
      open={open}
      onOpenChange={setOpen}
      onClear={props.onReset}
    >
      <FilterDialog
        title={props.dialogTitle}
        bg={dialogBg}
        onClose={() => setOpen(false)}
      >
        {props.children}
      </FilterDialog>
    </SelectPopover>
  );
};
