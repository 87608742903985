// Generated from src/svgs/map-outline.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const MapOutline = ({ size, fill, className }: Props) => {
  return (
    <svg preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><path d="M0 0h24v24.001H0z" fill="none" /><path d="M20.555 17.832A1 1 0 0 0 21 17V3a.998.998 0 0 0-1.554-.832l-5.392 3.595-4.429-3.544a1.073 1.073 0 0 0-.59-.211 1.064 1.064 0 0 0-.59.16l-6 4A1.003 1.003 0 0 0 2 7v14a1 1 0 0 0 1.555.832l5.392-3.594 4.429 3.543c.18.145.401.219.624.219a.855.855 0 0 0 .549-.176zM4 7.535l4-2.666v11.596l-4 2.667zm9 11.385-3-2.4V5.082l3 2.399zm6-2.455-4 2.667V7.535l4-2.666z" /></svg>
  );
}
