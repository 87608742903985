import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  type RadioProps,
  type SxProps,
} from '@mui/material';
import {
  type Control,
  Controller,
  type FieldPath,
  type FieldPathValue,
} from 'react-hook-form';

interface RaRadioGroupProps<
  TFieldValues extends Record<string, any>,
  K extends FieldPath<TFieldValues>,
> {
  control: Control<TFieldValues>;
  label: string;
  name: K;
  radios: { label: string; value: FieldPathValue<TFieldValues, K> }[];
  radioProps?: Omit<
    RadioProps,
    'name' | 'onChange' | 'onBlur' | 'checked' | 'ref' | 'value'
  >;
  sx?: SxProps;
}

export const RaRadioGroup = <
  TFieldValues extends Record<string, any>,
  K extends FieldPath<TFieldValues>,
>({
  control,
  label,
  name,
  radios,
  radioProps = {},
  sx,
}: RaRadioGroupProps<TFieldValues, K>) => {
  return (
    <FormControl fullWidth sx={sx}>
      <FormLabel component="legend" style={{ fontWeight: 500 }}>
        {label}
      </FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <RadioGroup
            row
            name={field.name}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            ref={field.ref}
          >
            {radios.map(radio => (
              <FormControlLabel
                key={radio.value.toString()}
                value={radio.value}
                control={<Radio {...radioProps} />}
                label={radio.label}
              />
            ))}
          </RadioGroup>
        )}
      />
    </FormControl>
  );
};
