import type { ReactNode } from 'react';

import MuiCheckbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

type Props = {
  color?: 'primary' | 'secondary' | 'default';
  size?: 'medium' | 'small';
  indeterminate?: boolean;
  disabled?: boolean;
  checked: boolean;
  onChange: (value: boolean) => void;
  children?: ReactNode;
};

export const Checkbox = (props: Props) => {
  return (
    <FormControlLabel
      css={{
        // delegate layout to grid
        display: 'flex',
        marginRight: 0,
        '.MuiFormControlLabel-label': {
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
        },
      }}
      label={props.children}
      control={
        <MuiCheckbox
          color={props.color}
          size={props.size}
          indeterminate={props.indeterminate}
          disabled={props.disabled}
          checked={props.checked}
          onChange={(_event, checked) => props.onChange(checked)}
        />
      }
    />
  );
};
