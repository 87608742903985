// Generated from src/svgs/archery-outline.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const ArcheryOutline = ({ size, fill, className }: Props) => {
  return (
    <svg viewBox="0 0 25 25" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><path fillRule="evenodd" clipRule="evenodd" d="M21.952 6.263a10.495 10.495 0 0 1-1.762 14.323L22.9 24.2a.5.5 0 0 1-.8.6l-2.71-3.613a10.473 10.473 0 0 1-11.78 0L4.9 24.8a.5.5 0 0 1-.8-.6l2.71-3.614C2.384 16.942 1.718 10.412 5.317 5.95l-.625-.55a.47.47 0 0 1-.03-.042l-1.834.3L0 2.828l2.121-.707L2.828 0l2.829 2.829-.299 1.822 7.418 6.543a1.529 1.529 0 1 1-.666.747l-1.522-1.343A3.418 3.418 0 0 0 10 12.5a3.492 3.492 0 1 0 2.962-3.444.5.5 0 0 1-.154-.988A4.603 4.603 0 1 1 9 12.5c.003-.922.295-1.82.834-2.567L8.33 8.606A6.57 6.57 0 1 0 13.5 6a6.43 6.43 0 0 0-2.927.703.5.5 0 1 1-.45-.893 7.473 7.473 0 1 1-2.555 2.125l-1.5-1.323a9.632 9.632 0 1 0 2.227-2.054.5.5 0 1 1-.549-.836 10.495 10.495 0 0 1 14.206 2.541ZM3.172 4.587l1.216-.199.2-1.215L3.264 1.85l-.195.587-.158.474-.474.158-.587.195 1.322 1.322Z" /></svg>
  );
}
