/**
 * @generated SignedSource<<a3fd12ca4adcbc267554adfd7a8e2949>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type filesList_files$data = ReadonlyArray<{
  readonly createdAt: string | null;
  readonly id: string;
  readonly mimetype: string | null;
  readonly name: string;
  readonly uploader: {
    readonly firstName: string | null;
    readonly lastName: string | null;
  } | null;
  readonly url: string;
  readonly " $fragmentType": "filesList_files";
}>;
export type filesList_files$key = ReadonlyArray<{
  readonly " $data"?: filesList_files$data;
  readonly " $fragmentSpreads": FragmentRefs<"filesList_files">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "filesList_files",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mimetype",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "uploader",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "File",
  "abstractKey": null
};

(node as any).hash = "5db080be97c77bfc583223127803a690";

export default node;
