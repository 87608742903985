import * as React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Flex } from 'react-system';

type Props = {
  loadMore: () => void;
};

const useIntersectionObserver = (
  targetRef: { current: HTMLElement | null },
  handle: (entry: IntersectionObserverEntry) => void,
) => {
  const handleRef = React.useRef(handle);
  handleRef.current = handle;

  const instanceRef = React.useRef<null | IntersectionObserver>(null);
  if (instanceRef.current == null) {
    instanceRef.current = new IntersectionObserver(([entry]) => {
      handleRef.current(entry);
    });
  }

  const instance = instanceRef.current;

  React.useEffect(() => {
    if (targetRef.current) {
      const element = targetRef.current;
      instance.observe(element);
      return () => {
        instance.unobserve(element);
      };
    }
  }, [instance, targetRef]);
};

export const LoadMoreIndicator = ({ loadMore }: Props) => {
  return (
    <Flex
      justifyContent="center"
      css={{ gridColumn: '-1 / 1', position: 'relative' }}
    >
      <InfiniteLoader offset={-1000} onLoadMore={loadMore} />
      <CircularProgress disableShrink />
    </Flex>
  );
};

export const InfiniteLoader = ({
  offset,
  onLoadMore,
}: {
  offset: number;
  onLoadMore: () => void;
}) => {
  const ref = React.useRef(null);
  const isVisible = React.useRef(false);

  useIntersectionObserver(ref, entry => {
    if (!isVisible.current && entry.isIntersecting) {
      isVisible.current = true;
      onLoadMore();
    }
    if (isVisible.current && !entry.isIntersecting) {
      isVisible.current = false;
    }
  });

  return (
    <div css={{ position: 'relative' }}>
      <div
        ref={ref}
        css={{
          top: offset,
          bottom: 0,
          pointerEvent: 'none',
          position: 'absolute',
        }}
      />
    </div>
  );
};
