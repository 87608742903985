// @flow

import * as React from 'react';

import { List, ListItem, ListItemText, Paper } from '@material-ui/core';
import {
  GoogleMap,
  GoogleMarker,
  Overlay,
  ReactMarker,
} from '@realadvisor/google-maps';
import type { LatLngLiteral } from '@realadvisor/google-maps/google-types';
import { graphql, useLazyLoadQuery, usePaginationFragment } from 'react-relay';
import { Box, useGlobalResponsive } from 'react-system';

import { useTheme } from '../../hooks/theme';
import { MapMarkerSelected } from '../../icons/map-marker-selected';

import type { PoiMap_root$key } from './__generated__/PoiMap_root.graphql';
import type { PoiMapPaginationQuery } from './__generated__/PoiMapPaginationQuery.graphql';
import type { PoiMapQuery } from './__generated__/PoiMapQuery.graphql';
import type { PoiMapWithPropsQuery } from './__generated__/PoiMapWithPropsQuery.graphql';
import { PoiMapDrawer } from './PoiMapDrawer';

export type Viewport = {|
  latitude: number,
  longitude: number,
  zoom?: number,
  altitude?: number,
  bearing?: number,
  maxPitch?: number,
  maxZoom?: number,
  minPitch?: number,
  minZoom?: number,
  pitch?: number,
  transitionDuration?: number,
|};

type GoogleProps = {|
  ...Viewport,
  mapType: 'satellite' | 'roadmap',
  previewMode?: boolean,
  children: React.ChildrenArray<
    React.Element<typeof GoogleMarker> | React.Element<typeof Overlay>,
  > | null,
|};

const GENEVA_LATLNG = {
  lat: 46.2044,
  lng: 6.1432,
};
const MAX_POSSIBLE_TILT = 45;
const DEFAULT_ZOOM = 14;

const MAP_OPTIONS = {
  mapTypeControl: false,
  clickableIcons: false,
};
const MAP_OPTIONS_PREVIEW_MODE = {
  ...MAP_OPTIONS,
  streetViewControl: false,
  fullscreenControl: false,
  zoomControl: false,
};

const MarkerPopup = ({ marker, settings }) => {
  const responsive = useGlobalResponsive();
  const data = useLazyLoadQuery<PoiMapWithPropsQuery>(
    graphql`
      query PoiMapWithPropsQuery($count: Int!, $filters: PoisFilters) {
        pois(first: $count, filters: $filters) {
          edges {
            node {
              properties {
                route
                streetNumber
                users {
                  firstName
                  lastName
                }
              }
            }
          }
        }
      }
    `,
    {
      count: 100,
      filters: {
        id_eq: marker.id,
      },
    },
  );
  const poi = data.pois?.edges?.[0]?.node;
  if (poi == null) {
    return null;
  }

  return responsive([
    null,
    <Paper
      key={'desktop'}
      elevation={6}
      css={{
        position: 'absolute',
        overflow: 'hidden',
        left: settings.hoveredRadius - settings.paperWidth / 2,
        bottom: settings.hoveredRadius,
        width: settings.paperWidth,
        pointerEvents: 'none',
      }}
    >
      <List>
        {(poi.properties ?? []).map((p, i) => (
          <ListItem key={i}>
            <ListItemText
              primary={(p.users ?? [])
                .map(p =>
                  [(p.lastName ?? '').toUpperCase(), p.firstName].join(' '),
                )
                .join(', ')}
              secondary={[p.route, p.streetNumber].join(' ')}
            />
          </ListItem>
        ))}
      </List>
    </Paper>,
  ]);
};

const HoveredMarkerPopup = ({ settings, marker }) => {
  const [showToolTip, setShowToolTip] = React.useState(false);
  React.useEffect(() => {
    const RENDER_DELAY = 300;
    const handle = setTimeout(() => {
      setShowToolTip(true);
    }, RENDER_DELAY);
    return () => {
      clearTimeout(handle);
    };
  }, []);
  return (
    showToolTip && (
      <React.Suspense fallback={null}>
        <MarkerPopup marker={marker} settings={settings} />
      </React.Suspense>
    )
  );
};

export const GooglePoiMap = ({
  previewMode = false,
  ...props
}: GoogleProps): React.Node => {
  const [, startTransition] = React.useTransition();
  const queryData = useLazyLoadQuery<PoiMapQuery>(
    graphql`
      query PoiMapQuery($count: Int!, $filters: PoisFilters) {
        ...PoiMap_root @arguments(count: $count, filters: $filters)
      }
    `,
    {
      count: 0,
      filters: {
        bbox: [0, 0, 0, 0],
      },
    },
    { fetchPolicy: 'store-only' }, // we don't want to fetch data from the server at this point
  );

  const { data, refetch } = usePaginationFragment<
    PoiMapPaginationQuery,
    PoiMap_root$key,
  >(
    graphql`
      fragment PoiMap_root on Query
      @refetchable(queryName: "PoiMapPaginationQuery")
      @argumentDefinitions(
        count: { type: "Int!" }
        cursor: { type: "String", defaultValue: null }
        filters: { type: "PoisFilters" }
      ) {
        pois(first: $count, after: $cursor, filters: $filters)
          @connection(key: "Connection_pois", filters: []) {
          edges {
            node {
              id
              lat
              lng
              hasDevLeads
              hasCompletedLeads
            }
          }
        }
      }
    `,
    queryData,
  );

  const markers = [];
  for (const edge of data.pois?.edges ?? []) {
    if (edge?.node != null) {
      markers.push(edge.node);
    }
  }

  const { children, mapType } = props;

  const { colors } = useTheme();
  const responsive = useGlobalResponsive();
  const isDesktopView = responsive([false, true, true]);
  const [point, setPoint] = React.useState(null);
  const mapCenter =
    props.latitude != null && props.longitude != null
      ? { lat: props.latitude, lng: props.longitude }
      : GENEVA_LATLNG;
  const [mapBbox, setMapBbox] = React.useState(null);
  const [hoveredMarker, setHoveredMarker] = React.useState(null);
  const markerSettings = {
    devLeadColor: colors.orange500,
    leadColor: colors.blue500,
    size: 14,
    hoveredSize: 20,
    hoveredRadius: 6,
    markerBorder: 2,
    paperWidth: 400,
  };

  const handleViewPortChange = (newBbox = null) => {
    const bbox = newBbox != null ? newBbox : mapBbox;
    bbox != null &&
      refetch({
        count: 20,
        filters: {
          bbox: [bbox.ne.lng, bbox.ne.lat, bbox.sw.lng, bbox.sw.lat],
        },
      });
  };

  const handleMapClick = (latLng: LatLngLiteral) => {
    if (isDesktopView) {
      setPoint(latLng);
    }
  };

  return (
    <Box flexGrow={1} css={{ position: 'relative' }}>
      <Box
        css={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          overflow: 'hidden',
        }}
      >
        <GoogleMap
          center={mapCenter}
          defaultZoom={previewMode ? DEFAULT_ZOOM : props.zoom ?? DEFAULT_ZOOM}
          tilt={MAX_POSSIBLE_TILT}
          options={previewMode ? MAP_OPTIONS_PREVIEW_MODE : MAP_OPTIONS}
          // options changes working because of our map implementation, will not work directly with rgm lib
          mapTypeId={mapType}
          onBoundsChanged={({ bounds }) => {
            if (mapBbox == null) {
              // $FlowFixMe[not-a-function] - flow doesn't understand startTransition
              startTransition(() => {
                setMapBbox(bounds);
                handleViewPortChange(bounds);
              });
            } else {
              setMapBbox(bounds);
            }
          }}
          onClick={handleMapClick}
          onIdle={() => handleViewPortChange()}
        >
          <Overlay>
            {markers.map(marker => {
              const { lat, lng, hasCompletedLeads, hasDevLeads } = marker;
              const scale = markerSettings.hoveredSize / markerSettings.size;
              const color =
                hasDevLeads === true
                  ? markerSettings.devLeadColor
                  : markerSettings.leadColor;
              const borderColor = hasCompletedLeads === true ? '#fff' : color;

              return (
                <ReactMarker
                  key={marker.id}
                  lat={lat ?? 0}
                  lng={lng ?? 0}
                  justifyContent="center"
                >
                  <div
                    css={[
                      {
                        position: 'absolute',
                        width: markerSettings.size,
                        height: markerSettings.size,
                        border: `${markerSettings.markerBorder}px solid ${borderColor}`,
                        borderRadius: '100%',
                        cursor: 'pointer',
                        background: color,
                        transition:
                          ' box-shadow 0.15s cubic-bezier(0.4, 0, 0.2, 1), transform 0.15s cubic-bezier(0.4, 0, 0.2, 1), z-index 0.0s 0.1s',
                      },
                      `&:hover {
                        z-index: 10;
                        transform: scale(${scale});
                        box-shadow: inset 10000px 0 0 rgba(255, 255, 255, 0.1);
                        transition: box-shadow 0.15s cubic-bezier(0.4, 0, 0.2, 1), transform 0.15s cubic-bezier(0.4, 0, 0.2, 1), z-index 0.0s 0.0s;
                      }`,
                    ]}
                    onClick={() =>
                      handleMapClick({ lat: lat ?? 0, lng: lng ?? 0 })
                    }
                    onMouseEnter={() => setHoveredMarker(marker)}
                    onMouseLeave={() => setHoveredMarker(null)}
                  />
                </ReactMarker>
              );
            })}

            <ReactMarker
              lat={hoveredMarker?.lat ?? 0}
              lng={hoveredMarker?.lng ?? 0}
              justifyContent="center"
            >
              {hoveredMarker != null && (
                <HoveredMarkerPopup
                  key={hoveredMarker.id}
                  marker={hoveredMarker}
                  settings={markerSettings}
                />
              )}
            </ReactMarker>
          </Overlay>

          <Overlay>
            <ReactMarker
              lat={point?.lat ?? 0}
              lng={point?.lng ?? 0}
              alignItems="flex-end"
              justifyContent="center"
            >
              {point != null && (
                <MapMarkerSelected
                  css={{
                    color: colors.blue500,
                    filter: 'drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.6))',
                    width: 50,
                    height: 50,
                    transition:
                      'all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
                  }}
                />
              )}
            </ReactMarker>
          </Overlay>

          {children}
        </GoogleMap>

        <PoiMapDrawer
          lat={point?.lat}
          lng={point?.lng}
          withSearch={false}
          onClose={() => {
            setPoint(null);
          }}
        />
      </Box>
    </Box>
  );
};
