/**
 * @generated SignedSource<<57240f9b0c4e15c6b9bb399d8eb6deba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type leadFilesCard_lead$data = {
  readonly id: string;
  readonly property: {
    readonly files: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"filesList_files">;
    }>;
    readonly id: string;
  } | null;
  readonly " $fragmentType": "leadFilesCard_lead";
};
export type leadFilesCard_lead$key = {
  readonly " $data"?: leadFilesCard_lead$data;
  readonly " $fragmentSpreads": FragmentRefs<"leadFilesCard_lead">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "leadFilesCard_lead",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Property",
      "kind": "LinkedField",
      "name": "property",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "File",
          "kind": "LinkedField",
          "name": "files",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "filesList_files"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Lead",
  "abstractKey": null
};
})();

(node as any).hash = "33f4db380b2c5fdcc64817668559aced";

export default node;
