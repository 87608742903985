import * as React from 'react';

export type MapHook<V> = {
  size: number;
  get: (key: string) => V | void;
  has: (key: string) => boolean;
  entries: () => Array<[string, V]>;
  keys: () => string[];
  values: () => V[];

  delete: (key: string) => void;
  set: (key: string, value: V) => void;
  clear: () => void;
  merge: (map: Record<string, V>) => void;
};

export const useMap = <V>(initialValue?: Record<string, V>): MapHook<V> => {
  const [inst, setInst] = React.useState<Map<string, V>>(() => {
    return new Map(initialValue == null ? [] : Object.entries(initialValue));
  });

  return {
    size: inst.size,
    get: key => inst.get(key),
    has: key => inst.has(key),
    entries: () => Array.from<[string, V]>(inst.entries()),
    keys: () => Array.from<string>(inst.keys()),
    values: () => Array.from<V>(inst.values()),

    delete: key => {
      setInst(prev => {
        const next = new Map(prev);
        next.delete(key);
        return next;
      });
    },
    set: (key, value) => {
      setInst(prev => {
        const next = new Map(prev);
        next.set(key, value);
        return next;
      });
    },
    clear: () => {
      setInst(new Map());
    },
    merge: obj => {
      setInst(prev => {
        const next = new Map(prev);
        Object.keys(obj).forEach(key => {
          next.set(key, obj[key]);
        });
        return next;
      });
    },
  };
};
