import { Link } from 'react-router-dom';

import { useLocale } from '../../../src/hooks/locale';
import type { ActivityFieldsFragment } from '../../__generated__/graphql';

const UserLink = ({ user }: { user: ActivityFieldsFragment['creator'] }) => {
  const { t } = useLocale();
  if (!user) {
    return <>{t('Someone')}</>;
  }
  return (
    <Link to={`/v2/users/${user?.id}`} style={{ fontWeight: 'bold' }}>
      {user?.full_name || t('Someone')}
    </Link>
  );
};

export const ActivityTitle = ({
  activity,
}: {
  activity: ActivityFieldsFragment;
}) => {
  const { t } = useLocale();

  if (activity.activity_type === 'requalification') {
    return (
      <>
        <UserLink user={activity.creator} />
        &nbsp;
        {t('requalified this lead')}
      </>
    );
  }
  if (activity.activity_type === 'note') {
    return (
      <>
        <UserLink user={activity.creator} />
        &nbsp;
        {t('added a note')}
      </>
    );
  }
  if (activity.activity_type === 'call' && !activity.success) {
    return (
      <>
        <UserLink user={activity.assignee} />
        &nbsp;
        {t('tried to call')}
      </>
    );
  }

  if (activity.activity_type === 'call' && activity.success) {
    return (
      <>
        <UserLink user={activity.assignee} />
        &nbsp;
        {t('called')}
      </>
    );
  }

  if (activity.activity_type === 'email') {
    return (
      <>
        {activity.email_message?.from[0]?.name || t('Someone')}&nbsp;
        {t('sent an email')}
      </>
    );
  }

  if (activity.activity_type === 'assignment') {
    return (
      <>
        <UserLink user={activity.creator} />
        &nbsp;
        {t('assigned this lead to')}
        &nbsp;
        <UserLink user={activity.assignee} />
      </>
    );
  }

  if (activity.activity_type === 'task') {
    return (
      <>
        <UserLink user={activity.creator} />
        &nbsp;
        {t('created a task')}
      </>
    );
  }

  if (activity.activity_type === 'origination') {
    return (
      <>
        <UserLink user={activity.creator} />
        &nbsp;
        {t('created this lead')}
      </>
    );
  }

  if (activity.activity_type === 'create_user') {
    return (
      <>
        <UserLink user={activity.creator} />
        &nbsp;
        {t('created a new user')}
      </>
    );
  }

  if (activity.activity_type === 'visit' && !activity.success) {
    return (
      <>
        <UserLink user={activity.creator} />
        &nbsp;
        {t('cancelled a visit')}
      </>
    );
  }

  if (activity.activity_type === 'visit' && activity.success) {
    return (
      <>
        <UserLink user={activity.creator} />
        &nbsp;
        {t('visited this lead')}
      </>
    );
  }

  return null;
};
