/**
 * @generated SignedSource<<68a58116c9fcaeac993cfad6f7b54127>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type appraisalGraph_tenantSettings$fragmentType: FragmentType;
export type appraisalGraph_tenantSettings$data = {|
  +activateModelIazi: boolean,
  +activateModelPricehubble: boolean,
  +activateModelPropertydata: boolean,
  +activateModelRealadvisorListings: boolean,
  +activateModelRealadvisorPerceived: boolean,
  +activateModelRealadvisorTransactions: boolean,
  +activateModelWup: boolean,
  +$fragmentType: appraisalGraph_tenantSettings$fragmentType,
|};
export type appraisalGraph_tenantSettings$key = {
  +$data?: appraisalGraph_tenantSettings$data,
  +$fragmentSpreads: appraisalGraph_tenantSettings$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "appraisalGraph_tenantSettings",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "activateModelIazi",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "activateModelWup",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "activateModelPropertydata",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "activateModelPricehubble",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "activateModelRealadvisorListings",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "activateModelRealadvisorTransactions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "activateModelRealadvisorPerceived",
      "storageKey": null
    }
  ],
  "type": "Tenant",
  "abstractKey": null
};

(node/*: any*/).hash = "f81bbfb33ae0079dcd7e58cfa154a2cf";

export default node;
