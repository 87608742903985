import { useCallback } from 'react';

import { graphql } from 'react-relay';

import { useAppData } from '../../apollo/providers/AppDataProvider';

import type { useGetDefaultRelayTemplate$data } from './__generated__/useGetDefaultRelayTemplate.graphql';
import { useLocale } from './locale';

export const templateFragment = graphql`
  fragment useGetDefaultRelayTemplate on DocTemplateConnection {
    edges {
      node {
        id
        language
        type
        active
        docTemplatesTenants {
          tenantId
          isDefault
        }
      }
    }
  }
`;

type Type =
  | 'appraisal_report'
  | 'lot_brochure'
  | 'cma_report'
  | 'financing_passport';

export const useGetDefaultRelayTemplate = () => {
  const { me } = useAppData();
  const { language } = useLocale();
  const tenantId = me?.tenant_id;

  const getDefaultTemplate = useCallback(
    (
      templates: useGetDefaultRelayTemplate$data | null | undefined,
      type: Type,
    ) => {
      if (!templates?.edges) {
        return null;
      }

      const templatesList = templates.edges
        .filter(Boolean)
        .map(edge => edge?.node)
        .filter(Boolean);

      if (!templatesList.length) {
        return null;
      }

      const isDefaultTemplate = (
        template: NonNullable<(typeof templatesList)[number]>,
      ) => {
        return template.docTemplatesTenants?.some(
          tenant => tenant?.tenantId === tenantId && tenant?.isDefault,
        );
      };

      const matchesType = (
        template: NonNullable<(typeof templatesList)[number]>,
      ) => {
        return template?.type === type;
      };

      const isActive = (
        template: NonNullable<(typeof templatesList)[number]>,
      ) => {
        return template?.active === true;
      };

      // Find template matching all criteria
      const defaultTemplate = templatesList.find(
        template =>
          template &&
          isDefaultTemplate(template) &&
          template?.language === language &&
          matchesType(template) &&
          isActive(template),
      );

      // Fallback to English if no template in specified language
      const defaultEnglishTemplate = templatesList.find(
        template =>
          template &&
          isDefaultTemplate(template) &&
          template?.language === 'en' &&
          matchesType(template) &&
          isActive(template),
      );

      return defaultTemplate?.id ?? defaultEnglishTemplate?.id ?? null;
    },
    [language, tenantId],
  );

  return { getDefaultTemplate };
};
