import { useMemo } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Alert, CircularProgress, Grid } from '@mui/material';

import { useLocale } from '../../../src/hooks/locale';
import { GET_PROPERTY_APPRAISABLE } from '../../pages/cma-reports/cmaReportsQueries';
import { useAppData } from '../../providers/AppDataProvider';
import { RaForm } from '../form/RaForm';

import {
  getDefaultPropertyDetailsFormValues,
  preparePropertyDetailsFormData,
} from './forms-definitions/propertyDetailsFormDefinition';
import { getValidatePropertyDetailsFormData } from './forms-definitions/propertyDetailsFormValidation';
import {
  getDefaultPropertyFeaturesEquipmentsFormValues,
  preparePropertyFeaturesFormData,
} from './forms-definitions/propertyFeaturesDefinition';
import { getDefaultPropertyPoiFormValues } from './forms-definitions/propertyPoiDefinition';
import {
  getDefaultPropertyPricingFormValues,
  getValidatePricingFormData,
  preparePricingFormData,
} from './forms-definitions/propertyPricingDefinition';
import {
  type PropertyFormData,
  getPropertyFormDefinition,
} from './propertyFormDefinition';
import { GET_PROPERTY, UPDATE_PROPERTY } from './propertyFormQueries';
import { PropertyTypeProvider } from './PropertyTypeProvider';

export const PropertyForm = ({
  propertyId,
  onCancel,
  onSave,
}: {
  propertyId?: string;
  onSave?: () => void;
  onCancel?: () => void;
}) => {
  const { t, locale, countryCode } = useLocale();
  const { data, loading, error } = useQuery(GET_PROPERTY, {
    variables: { id: propertyId ?? '' },
    skip: propertyId == null,
  });
  const { me } = useAppData();
  const isCHTenant = me?.tenant.country_code === 'CH';

  const property = data?.properties_by_pk;
  const property_types = data?.property_types ?? [];
  const heating_types = data?.heating_enum ?? [];
  const heating_distribution = data?.heating_distribution_enum ?? [];
  const cooling_types = data?.cooling_enum ?? [];

  const [updateProperty] = useMutation(UPDATE_PROPERTY, {
    refetchQueries: [
      {
        query: GET_PROPERTY_APPRAISABLE,
        variables: { propertyId },
      },
    ],
  });

  const defaultValues = useMemo<PropertyFormData | {}>(() => {
    if (property != null) {
      const {
        street_number,
        route,
        locality,
        state,
        country,
        country_code,
        postcode,
        lat,
        lng,
        created_at,
        created_by,
        ...propertyData
      } = property;

      return {
        street_number,
        route,
        locality,
        state,
        country,
        country_code,
        postcode,
        lat,
        lng,
        created_at,
        created_by,
        ...getDefaultPropertyPricingFormValues(propertyData),
        ...getDefaultPropertyPoiFormValues(propertyData),
        ...getDefaultPropertyFeaturesEquipmentsFormValues(propertyData),
        ...getDefaultPropertyDetailsFormValues(propertyData),
      };
    }

    return {};
  }, [property]);

  const propertyFormDefinition = useMemo(
    () =>
      getPropertyFormDefinition(
        isCHTenant,
        null,
        property?.country_code ?? countryCode,
        locale,
      ),
    [isCHTenant, property, locale, countryCode],
  );

  if (loading) {
    return (
      <Grid container justifyContent="center" alignItems="center" height="100%">
        <CircularProgress disableShrink />
      </Grid>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </Alert>
    );
  }

  if (!property) {
    return <Alert severity="error">{t('Property not found')}</Alert>;
  }

  return (
    data && (
      <RaForm
        formDefinition={propertyFormDefinition}
        context={{
          propertyTypes: property_types,
          heatingTypes: heating_types,
          heatingDistribution: heating_distribution,
          coolingTypes: cooling_types,
        }}
        onCancel={onCancel}
        defaultValues={defaultValues}
        onSubmit={(formData, unstrippedFormData) => {
          if (propertyId == null) {
            return Promise.resolve();
          }

          const {
            distance_to_airport,
            distance_to_hospital,
            distance_to_motorway,
            distance_to_city_center,
            distance_to_high_school,
            distance_to_grocery_shop,
            distance_to_kindergarten,
            distance_to_sports_center,
            distance_to_train_station,
            distance_to_primary_school,
            distance_to_public_transport,
            street_number,
            route,
            locality,
            state,
            country,
            country_code,
            postcode,
            lat,
            lng,
            ...restData
          } = formData;

          const preparedDetailsData = preparePropertyDetailsFormData(
            unstrippedFormData,
            isCHTenant,
          );

          const preparedPricingData = preparePricingFormData(
            restData,
            unstrippedFormData.__property_type,
          );

          const preparedFeaturesData = preparePropertyFeaturesFormData(
            restData,
            unstrippedFormData.__property_type,
          );

          return updateProperty({
            variables: {
              id: propertyId,
              property: {
                distance_to_airport,
                distance_to_hospital,
                distance_to_motorway,
                distance_to_city_center,
                distance_to_high_school,
                distance_to_grocery_shop,
                distance_to_kindergarten,
                distance_to_sports_center,
                distance_to_train_station,
                distance_to_primary_school,
                distance_to_public_transport,
                street_number,
                route,
                locality,
                state,
                country,
                country_code,
                postcode,
                lat,
                lng,
                ...preparedFeaturesData,
                ...preparedPricingData,
                ...preparedDetailsData,
              },
            },
            onCompleted: () => {
              onSave?.();
            },
          });
        }}
        validate={data => {
          const pricingErrors = getValidatePricingFormData(
            t,
            data.__property_type,
          )(data);
          const detailsErrors = getValidatePropertyDetailsFormData(t)(data);

          return [...pricingErrors, ...detailsErrors];
        }}
      >
        <PropertyTypeProvider propertyTypes={property_types} />
      </RaForm>
    )
  );
};
