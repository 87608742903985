// @flow

import * as React from 'react';

import {
  Avatar,
  FilledInput,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import Downshift from 'downshift';
import { graphql, useMutation } from 'react-relay';

import { PropertyDrawer } from '../components/BasicListItem';
import { InputPopup } from '../controls/popup';
import { useLocale } from '../hooks/locale';
import { useInputQuery } from '../hooks/relay';
import { AddCircleOutline } from '../icons/add-circle-outline';
import { Cancel } from '../icons/cancel';
import { GpsFixed } from '../icons/gps-fixed';

import type { leadInput_lead$data } from './__generated__/leadInput_lead.graphql';
import type {
  LeadsSearchFilters,
  leadInputQuery,
} from './__generated__/leadInputQuery.graphql';
import type { leadInputUpsertLeadMutation } from './__generated__/leadInputUpsertLeadMutation.graphql';

export type Lead = $ReadOnly<
  $Diff<leadInput_lead$data, {| $fragmentType: any |}>,
>;

type Props = {|
  Input?: React.AbstractComponent<any>,
  filters?: LeadsSearchFilters,
  value: ?Lead,
  onChange: (?Lead) => void,
  onBlur?: () => void,
  // creates a property with lead
  propertyCreatable?: boolean,
|};

graphql`
  fragment leadInput_lead on Lead {
    id
    property {
      formattedAddress
    }
    contact {
      id
      firstName
      lastName
      ...userInput_user @relay(mask: false)
    }
    # eslint-disable-next-line relay/unused-fields
    broker {
      ...userInput_user @relay(mask: false)
    }
  }
`;

const getLeadContactName = lead => {
  const firstName = lead?.contact?.firstName;
  const lastName = lead?.contact?.lastName;
  if (firstName != null && lastName != null) {
    return `${firstName} ${lastName}`;
  }
  if (firstName != null) {
    return firstName;
  }
  if (lastName != null) {
    return lastName;
  }
  return null;
};

const getItems = data =>
  (data?.leadsSearch?.edges || []).map(edge => edge?.node).filter(Boolean);

const LeadMenu = ({
  targetRef,
  items,
  downshift,
  onChange,
  propertyCreatable,
}) => {
  const { t } = useLocale();
  const { isOpen, highlightedIndex, getMenuProps, getItemProps } = downshift;
  const [propertyOpen, setPropertyOpen] = React.useState(false);
  const [upsertLead] = useMutation<leadInputUpsertLeadMutation>(
    graphql`
      mutation leadInputUpsertLeadMutation($input: UpsertLeadInput!) {
        upsertLead(input: $input) {
          lead {
            ...leadInput_lead @relay(mask: false)
          }
        }
      }
    `,
  );

  return (
    <>
      <PropertyDrawer
        propertyId={null}
        open={propertyOpen}
        onClose={() => setPropertyOpen(false)}
        handleSubmit={property => {
          upsertLead({
            variables: {
              input: { lead: { property } },
            },
            onCompleted: data => {
              if (data.upsertLead != null) {
                onChange(data.upsertLead.lead);
              }
              setPropertyOpen(false);
            },
          });
        }}
      />
      <InputPopup referenceRef={targetRef} open={isOpen}>
        <List {...getMenuProps()}>
          {items.length === 0 && (
            <ListItem disabled={true}>{t('No options')}</ListItem>
          )}
          {propertyCreatable === true && (
            <ListItem
              button={true}
              onClick={() => {
                downshift.reset();
                setPropertyOpen(true);
              }}
            >
              <ListItemIcon>
                <AddCircleOutline />
              </ListItemIcon>
              <ListItemText primary={t('createProperty')} />
            </ListItem>
          )}
          {isOpen &&
            items.map((item, index) => (
              <ListItem
                {...getItemProps({ item })}
                key={item.id}
                button={true}
                selected={highlightedIndex === index}
              >
                <ListItemAvatar>
                  <Avatar>
                    <GpsFixed />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={getLeadContactName(item)}
                  secondary={item.property?.formattedAddress}
                />
              </ListItem>
            ))}
        </List>
      </InputPopup>
    </>
  );
};

export const LeadInput = (props: Props): React.Node => {
  const [data, refetch] = useInputQuery<leadInputQuery>(
    graphql`
      query leadInputQuery($filters: LeadsSearchFilters) {
        leadsSearch(first: 20, filters: $filters) {
          edges {
            node {
              ...leadInput_lead @relay(mask: false)
            }
          }
        }
      }
    `,
  );

  const { Input = FilledInput, filters } = props;
  const targetRef = React.useRef(null);
  return (
    <Downshift
      // make selectedItem always controlled
      selectedItem={props.value ?? null}
      itemToString={lead =>
        [getLeadContactName(lead), lead?.property?.formattedAddress]
          .filter(Boolean)
          .join(' — ')
      }
      onInputValueChange={inputValue =>
        refetch({ filters: { ...filters, search: inputValue } })
      }
      onChange={selectedItem => props.onChange(selectedItem)}
    >
      {downshift => {
        const { getInputProps, inputValue, selectedItem } = downshift;
        const { openMenu } = downshift;
        return (
          <div>
            <Input
              {...getInputProps({
                ref: targetRef,
                onFocus: () => {
                  refetch({ filters: { ...filters, search: inputValue } });
                  openMenu();
                },
                onBlur: props.onBlur,
                startAdornment: selectedItem != null && (
                  <InputAdornment position="start">
                    <Avatar style={{ width: 24, height: 24 }}>
                      <GpsFixed />
                    </Avatar>
                  </InputAdornment>
                ),
                endAdornment: selectedItem && (
                  <InputAdornment position="end">
                    <IconButton onClick={() => props.onChange(null)}>
                      <Cancel />
                    </IconButton>
                  </InputAdornment>
                ),
              })}
            />
            <LeadMenu
              targetRef={targetRef}
              items={getItems(data)}
              downshift={downshift}
              propertyCreatable={props.propertyCreatable}
              onChange={props.onChange}
            />
          </div>
        );
      }}
    </Downshift>
  );
};
