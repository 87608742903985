// Generated from src/svgs/nav-users-active.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const NavUsersActive = ({ size, fill, className }: Props) => {
  return (
    <svg viewBox="0 0 25 25" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><path d="M7.053 7.053C7.053 3.206 9.038 1 12.5 1c3.462 0 5.447 2.206 5.447 6.053 0 4.435-2.596 8.473-5.447 8.473s-5.447-4.038-5.447-8.473Zm16.072 11.573c-1.551-.936-4.802-2.864-6.762-3.79a5.327 5.327 0 0 1-3.863 1.9 5.331 5.331 0 0 1-3.872-1.91c-1.97.922-5.214 2.86-6.759 3.799A1.812 1.812 0 0 0 1 20.175v1.083a1.814 1.814 0 0 0 1.523 1.791c3.298.558 6.633.875 9.977.951a69.275 69.275 0 0 0 9.977-.95A1.814 1.814 0 0 0 24 21.257v-1.079a1.812 1.812 0 0 0-.875-1.553Z" fill="#fff" /></svg>
  );
}
