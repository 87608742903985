/**
 * @generated SignedSource<<08a6b6834584d49104800e4c4a055cea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type BuyerLeadStatus = "active" | "dead" | "won";
export type activityWorkflowDialogLeadBuyerLeadUpdateMutation$variables = {
  id: string;
  status?: BuyerLeadStatus | null;
};
export type activityWorkflowDialogLeadBuyerLeadUpdateMutation$data = {
  readonly updateBuyerLead: {
    readonly buyerLead: {
      readonly id: string;
      readonly status: BuyerLeadStatus | null;
    } | null;
  } | null;
};
export type activityWorkflowDialogLeadBuyerLeadUpdateMutation = {
  response: activityWorkflowDialogLeadBuyerLeadUpdateMutation$data;
  variables: activityWorkflowDialogLeadBuyerLeadUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "status"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          },
          {
            "kind": "Variable",
            "name": "status",
            "variableName": "status"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "UpdateBuyerLeadPayload",
    "kind": "LinkedField",
    "name": "updateBuyerLead",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BuyerLead",
        "kind": "LinkedField",
        "name": "buyerLead",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "activityWorkflowDialogLeadBuyerLeadUpdateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "activityWorkflowDialogLeadBuyerLeadUpdateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a913beb008cfd4ed0916e4318977126e",
    "id": null,
    "metadata": {},
    "name": "activityWorkflowDialogLeadBuyerLeadUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation activityWorkflowDialogLeadBuyerLeadUpdateMutation(\n  $id: ID!\n  $status: BuyerLeadStatus\n) {\n  updateBuyerLead(input: {id: $id, status: $status}) {\n    buyerLead {\n      id\n      status\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b5cb42cbd356afee286a3ee85e20de9d";

export default node;
