import { useEffect, useMemo } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';

import { useLocale } from '../../../src/hooks/locale';
import { RaSelect } from '../form/RaSelect';

import { type TAvailabilityFormData } from './listingOverviewFormDefinition';

export const ListingOverviewAvailabilitySelect: React.FC = () => {
  const { t } = useLocale();
  const { control, setValue } = useFormContext<TAvailabilityFormData>();
  const availableImmediately = useWatch<TAvailabilityFormData>({
    control,
    name: 'available_immediately',
  });

  useEffect(() => {
    if (availableImmediately === 'yes') {
      setValue('available_from', null);
    }
  }, [availableImmediately, setValue]);

  return useMemo(
    () => (
      <RaSelect
        name="available_immediately"
        label={t('Available immediately?')}
        control={control}
        options={[
          { value: 'yes', label: t('Yes') },
          { value: 'no', label: t('No, enter a date') },
        ]}
      />
    ),
    [control, t],
  );
};
