import { useEffect, useRef, useState } from 'react';

import { Box, Collapse, Link } from '@mui/material';

import { useLocale } from '../../src/hooks/locale';

type Props = {
  expandedByDefault?: boolean;
  nbLinesToShow?: number;
} & React.PropsWithChildren;

export const CollapsibleContent: React.FC<Props> = ({
  children,
  nbLinesToShow,
  expandedByDefault = false,
}) => {
  const { t } = useLocale();
  const [expanded, setExpanded] = useState(expandedByDefault);
  const [collapsedHeight, setCollapsedHeight] = useState<number>(0);
  const rootRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (rootRef.current && nbLinesToShow != null) {
      const clone = rootRef.current.cloneNode(true) as HTMLDivElement;
      clone.style.overflow = 'hidden';
      clone.style.textOverflow = 'ellipsis';
      clone.style.display = '-webkit-box';
      clone.style.webkitLineClamp = nbLinesToShow.toString();
      clone.style.webkitBoxOrient = 'vertical';

      document.body.appendChild(clone);
      setCollapsedHeight(clone.clientHeight);
      document.body.removeChild(clone);
    }
  }, [rootRef, nbLinesToShow]);

  return (
    <Box>
      <Collapse
        in={expanded}
        collapsedSize={collapsedHeight}
        sx={{
          position: 'relative',
          ':after': {
            opacity: expanded ? 0 : 1,
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: 30,
            background:
              'linear-gradient(0deg, rgba(255,255,255,1) 15%, rgba(255,255,255,0.5) 60%, rgba(255,255,255,0) 100%)',
            zIndex: 1,
            transition: 'opacity 0.3s',
          },
        }}
      >
        <Box ref={rootRef}>{children}</Box>
      </Collapse>
      <Link
        sx={{ textTransform: 'none', mt: 0.5 }}
        component="button"
        color="primary"
        variant="button"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          setExpanded(!expanded);
        }}
      >
        {expanded ? t('Show less') : t('Show more')}
      </Link>
    </Box>
  );
};
