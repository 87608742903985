/**
 * @generated SignedSource<<b9b33d0eae2cd639b4be576c71d5523f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Boolean_comparison_exp = {
  _eq?: boolean | null;
  _gt?: boolean | null;
  _gte?: boolean | null;
  _in?: ReadonlyArray<boolean> | null;
  _is_null?: boolean | null;
  _lt?: boolean | null;
  _lte?: boolean | null;
  _neq?: boolean | null;
  _nin?: ReadonlyArray<boolean> | null;
};
export type organisationFilterQuery$variables = {
  count: number;
  is_lender: Boolean_comparison_exp;
  order_by_first_ids: string;
};
export type organisationFilterQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"organisationFilter_root">;
};
export type organisationFilterQuery = {
  response: organisationFilterQuery$data;
  variables: organisationFilterQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "is_lender"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order_by_first_ids"
},
v3 = {
  "kind": "Variable",
  "name": "is_lender",
  "variableName": "is_lender"
},
v4 = {
  "kind": "Variable",
  "name": "order_by_first_ids",
  "variableName": "order_by_first_ids"
},
v5 = {
  "kind": "Literal",
  "name": "search",
  "value": ""
},
v6 = [
  {
    "fields": [
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "ObjectValue",
    "name": "args"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "fields": [
      (v3/*: any*/)
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "organisationFilterQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "organisationFilter_root"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "organisationFilterQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "organisationsConnection",
        "kind": "LinkedField",
        "name": "organisations_search_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "organisationsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "organisations",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "order_by",
                        "value": {
                          "is_primary": "desc"
                        }
                      }
                    ],
                    "concreteType": "organisation_images",
                    "kind": "LinkedField",
                    "name": "organisation_images",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "images",
                        "kind": "LinkedField",
                        "name": "image",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                          },
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "organisation_images(order_by:{\"is_primary\":\"desc\"})"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v6/*: any*/),
        "filters": [
          "args",
          "where"
        ],
        "handle": "connection",
        "key": "organisationFilter__organisations_search_connection",
        "kind": "LinkedHandle",
        "name": "organisations_search_connection"
      }
    ]
  },
  "params": {
    "cacheID": "26d1f899b904c86ad97c36c7757008ff",
    "id": null,
    "metadata": {},
    "name": "organisationFilterQuery",
    "operationKind": "query",
    "text": "query organisationFilterQuery(\n  $count: Int!\n  $order_by_first_ids: String!\n  $is_lender: Boolean_comparison_exp!\n) {\n  ...organisationFilter_root_2xzhkS\n}\n\nfragment organisationFilter_root_2xzhkS on query_root {\n  organisations_search_connection(first: $count, args: {order_by_first_ids: $order_by_first_ids, search: \"\"}, where: {is_lender: $is_lender}) {\n    edges {\n      node {\n        id\n        name\n        organisation_images(order_by: {is_primary: desc}) {\n          image {\n            url\n            id\n          }\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "631bdd23061e748e20bb35b92042a47d";

export default node;
