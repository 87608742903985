import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';

import { gql } from '../__generated__';
import type { AgentCard_UserFragment } from '../__generated__/graphql';
import { formatAddress } from '../utils/formatting';

import { TeamAvatar } from './TeamAvatar';
import { UserCard } from './UserCard';

export const AGENT_CARD_FRAGMENT = gql(`
  fragment AgentCard_user on users {
    ...UserCard_user
    default_team {
      id
      name
      street_number
      route
      locality
      postcode
      state
      country
      logo {
        id
        url
      }
    }
    organisation {
      id
      organisation_images {
        id
        is_primary
        image {
          id
          url
        }
      }
    }
  }
`);

interface IProps {
  agent: AgentCard_UserFragment;
  condensed?: boolean;
  userActions?: React.ReactNode;
}

export const AgentCard: React.FC<IProps> = ({
  agent,
  condensed,
  userActions,
}) => {
  const teamUrl =
    agent.default_team?.logo?.url ??
    agent.organisation?.organisation_images?.find(img => img.is_primary)?.image
      ?.url ??
    agent.organisation?.organisation_images?.[0]?.image?.url;

  return condensed ? (
    <UserCard
      user={agent}
      userActions={userActions}
      condensed={true}
      secondaryText={agent.default_team?.name}
    />
  ) : (
    <UserCard
      user={agent}
      userActions={userActions}
      extraItems={
        agent.default_team && (
          <ListItem disableGutters>
            <ListItemAvatar sx={{ mr: 1.5 }}>
              <TeamAvatar logoUrl={teamUrl} size={70} />
            </ListItemAvatar>
            <ListItemText
              sx={theme => ({
                '& .MuiListItemText-secondary': theme.mixins.truncate(),
                '& .MuiListItemText-primary': theme.mixins.truncate(),
              })}
              primary={
                <Typography variant="body1" sx={{ fontWeight: 700 }}>
                  {agent.default_team.name}
                </Typography>
              }
              secondary={formatAddress(agent.default_team, ', ')}
            />
          </ListItem>
        )
      }
    />
  );
};
