// Generated from src/svgs/password-appear.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const PasswordAppear = ({ size, fill, className }: Props) => {
  return (
    <svg viewBox="0 0 25 25" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><path fillRule="evenodd" clipRule="evenodd" d="M12 2.5v3a.5.5 0 0 0 1 0v-3a.5.5 0 0 0-1 0ZM6.25 5.067a.5.5 0 0 0-.183.683l1 1.731a.5.5 0 0 0 .866-.5l-1-1.731a.5.5 0 0 0-.683-.183ZM12.5 8C5.596 8 0 14.621 0 15.5c0 .879 5.596 7.5 12.5 7.5S25 16.437 25 15.5c0-.938-5.596-7.5-12.5-7.5Zm0 4a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7Zm4.567-5.019 1-1.731a.5.5 0 0 1 .866.5l-1 1.731a.5.5 0 0 1-.866-.5Z" /></svg>
  );
}
