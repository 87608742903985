/**
 * @generated SignedSource<<6d9fc6b252285fb3b447d0d85afa073a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type LeadAppraisalReason = "already_for_sale" | "buy_soon" | "buy_this" | "inheritance" | "move_rental" | "not_selling" | "not_set" | "other" | "other_not_owner" | "other_owner" | "other_renting" | "refinance" | "sell" | "separation";
export type LeadBuyHorizon = "not_set" | "over_12_months" | "under_12_months" | "under_3_months";
export type LeadRelationship = "agent" | "buyer" | "heir" | "not_set" | "other" | "owner" | "tenant";
export type LeadSaleHorizon = "already_for_sale" | "from_1_to_2_years" | "from_2_years" | "from_6_to_12_months" | "not_selling" | "not_set" | "under_6_months";
export type LeadsFilters = {
  appraisalNextStep_in?: ReadonlyArray<string | null> | null;
  appraisalOpenToOffer_in?: ReadonlyArray<string | null> | null;
  appraisalPerception_in?: ReadonlyArray<string | null> | null;
  appraisalPropertyOccupied_in?: ReadonlyArray<string | null> | null;
  appraisalPropertyUsage_in?: ReadonlyArray<string | null> | null;
  appraisalReason_in?: ReadonlyArray<LeadAppraisalReason> | null;
  appraisalValue_gte?: number | null;
  appraisalValue_lte?: number | null;
  brokerId_in?: ReadonlyArray<string | null> | null;
  buyHorizon_in?: ReadonlyArray<LeadBuyHorizon> | null;
  claimedBy_eq?: string | null;
  completed_in?: ReadonlyArray<boolean> | null;
  contactId_in?: ReadonlyArray<string | null> | null;
  contacts_assets_gte?: number | null;
  contacts_assets_lte?: number | null;
  contacts_income_gte?: number | null;
  contacts_income_lte?: number | null;
  createdAt_inRange?: DateRange | null;
  createdBy_in?: ReadonlyArray<string | null> | null;
  id_in?: ReadonlyArray<string | null> | null;
  leadLostId_in?: ReadonlyArray<string | null> | null;
  mandateProbability_in?: ReadonlyArray<number | null> | null;
  mortgageBuyingStatus_in?: ReadonlyArray<string | null> | null;
  mortgagePropertyValue_gte?: number | null;
  mortgagePropertyValue_lte?: number | null;
  mortgageStatus_in?: ReadonlyArray<string | null> | null;
  mortgage_type_in?: ReadonlyArray<string | null> | null;
  nextActivity_exists?: boolean | null;
  nextActivity_overdue?: boolean | null;
  nextActivity_type_in?: ReadonlyArray<string> | null;
  organisationId_in?: ReadonlyArray<string | null> | null;
  pipelineId_eq?: string | null;
  potentialDevelopment_eq?: boolean | null;
  predictedListingDate_inRange?: DateRange | null;
  property_commercialSurface_gte?: number | null;
  property_commercialSurface_lte?: number | null;
  property_googleAddress_lat_gte?: number | null;
  property_googleAddress_lat_lte?: number | null;
  property_googleAddress_lng_gte?: number | null;
  property_googleAddress_lng_lte?: number | null;
  property_landSurface_gte?: number | null;
  property_landSurface_lte?: number | null;
  property_livingSurface_gte?: number | null;
  property_livingSurface_lte?: number | null;
  property_placeId_in?: ReadonlyArray<string> | null;
  property_propertyType_name_in?: ReadonlyArray<string | null> | null;
  property_residentialSurface_gte?: number | null;
  property_residentialSurface_lte?: number | null;
  receivedMortgageOffer_in?: ReadonlyArray<boolean | null> | null;
  relationship_in?: ReadonlyArray<LeadRelationship> | null;
  saleHorizon_in?: ReadonlyArray<LeadSaleHorizon> | null;
  sourceId_in?: ReadonlyArray<string | null> | null;
  stageId_in?: ReadonlyArray<string | null> | null;
  stageName_in?: ReadonlyArray<string | null> | null;
  status_in?: ReadonlyArray<string | null> | null;
  tags_in?: ReadonlyArray<string | null> | null;
};
export type DateRange = {
  endDate?: string | null;
  mostRecent?: boolean | null;
  startDate?: string | null;
  useRequalifiedIfAvailable?: boolean | null;
};
export type leadsKanbanIdsQuery$variables = {
  filters: LeadsFilters;
  first: number;
};
export type leadsKanbanIdsQuery$data = {
  readonly leads: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly stageId: string | null;
      } | null;
    } | null> | null;
  } | null;
};
export type leadsKanbanIdsQuery = {
  response: leadsKanbanIdsQuery$data;
  variables: leadsKanbanIdsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filters",
        "variableName": "filters"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Literal",
        "name": "sortBy",
        "value": "posWithinStage"
      }
    ],
    "concreteType": "LeadConnectionConnection",
    "kind": "LinkedField",
    "name": "leads",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LeadConnectionEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Lead",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "stageId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "leadsKanbanIdsQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "leadsKanbanIdsQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "b81fcc0342bdf9a19a6ddf534abf8df4",
    "id": null,
    "metadata": {},
    "name": "leadsKanbanIdsQuery",
    "operationKind": "query",
    "text": "query leadsKanbanIdsQuery(\n  $first: Int!\n  $filters: LeadsFilters!\n) {\n  leads(first: $first, sortBy: \"posWithinStage\", filters: $filters) {\n    edges {\n      node {\n        id\n        stageId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ac68e87cd4284c429cbb43c8b5b63a22";

export default node;
