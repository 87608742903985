/**
 * @generated SignedSource<<8d4590d4ce22a83e856cb99b60c2854b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type LeadAppraisalReason = "already_for_sale" | "buy_soon" | "buy_this" | "inheritance" | "move_rental" | "not_selling" | "not_set" | "other" | "other_not_owner" | "other_owner" | "other_renting" | "refinance" | "sell" | "separation";
export type LeadBuyHorizon = "not_set" | "over_12_months" | "under_12_months" | "under_3_months";
export type LeadMortgageBuyingStatus = "identified" | "offer" | "searching" | "visited";
export type LeadMortgageStatus = "not_started" | "working_with_bank" | "working_with_independent";
export type LeadSaleHorizon = "already_for_sale" | "from_1_to_2_years" | "from_2_years" | "from_6_to_12_months" | "not_selling" | "not_set" | "under_6_months";
export type LeadStatus = "active" | "lost" | "won";
import { FragmentRefs } from "relay-runtime";
export type Leads_root$data = {
  readonly leads: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly appraisalPerceivedValue: number | null;
        readonly appraisalPerception: string | null;
        readonly appraisalReason: LeadAppraisalReason;
        readonly broker: {
          readonly " $fragmentSpreads": FragmentRefs<"userInfo_user">;
        } | null;
        readonly buyHorizon: LeadBuyHorizon;
        readonly contact: {
          readonly id: string;
          readonly primaryEmail: {
            readonly email: string;
          } | null;
          readonly referrer: string | null;
          readonly " $fragmentSpreads": FragmentRefs<"userInfo_user">;
        } | null;
        readonly id: string;
        readonly latestActivity: {
          readonly __typename: string;
          readonly assignedTo: {
            readonly id: string;
          } | null;
          readonly createdAt?: string;
          readonly createdBy: {
            readonly id: string;
          } | null;
          readonly done?: boolean;
          readonly doneAt?: string | null;
          readonly dueAt?: string | null;
          readonly note?: string | null;
          readonly overdue?: boolean;
          readonly startDate?: string | null;
          readonly subject?: string | null;
          readonly success?: boolean;
        } | null;
        readonly mortgageBuyingStatus: LeadMortgageBuyingStatus | null;
        readonly mortgageStatus: LeadMortgageStatus | null;
        readonly nextActivity: {
          readonly __typename: string;
          readonly assignedTo: {
            readonly id: string;
          } | null;
          readonly createdAt?: string;
          readonly createdBy: {
            readonly id: string;
          } | null;
          readonly done?: boolean;
          readonly doneAt?: string | null;
          readonly dueAt?: string | null;
          readonly note?: string | null;
          readonly overdue?: boolean;
          readonly startDate?: string | null;
          readonly subject?: string | null;
          readonly success?: boolean;
        } | null;
        readonly property: {
          readonly builtSurface: number | null;
          readonly googleAddress: {
            readonly locality: string | null;
            readonly postcode: string | null;
            readonly route: string | null;
            readonly state: string | null;
            readonly streetNumber: string | null;
          } | null;
          readonly id: string;
          readonly landSurface: number | null;
          readonly latestAppraisal: {
            readonly realadvisor: {
              readonly max: number | null;
              readonly min: number | null;
            } | null;
          } | null;
          readonly livingSurface: number | null;
          readonly locality: string | null;
          readonly postcode: string | null;
          readonly propertyType: {
            readonly label: string | null;
            readonly mainType: string | null;
          } | null;
          readonly route: string | null;
          readonly state: string | null;
          readonly streetNumber: string | null;
        } | null;
        readonly receivedMortgageOffer: boolean | null;
        readonly saleHorizon: LeadSaleHorizon;
        readonly source: {
          readonly label: string;
        };
        readonly stage: {
          readonly label: string;
        } | null;
        readonly status: LeadStatus;
        readonly userCanViewLeadDetails: boolean;
        readonly " $fragmentSpreads": FragmentRefs<"leadUpdatedTimeAgo_lead">;
      } | null;
    } | null> | null;
    readonly totalCount: number;
  } | null;
  readonly " $fragmentType": "Leads_root";
};
export type Leads_root$key = {
  readonly " $data"?: Leads_root$data;
  readonly " $fragmentSpreads": FragmentRefs<"Leads_root">;
};

import LeadsPaginationQuery_graphql from './LeadsPaginationQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  "leads"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "userInfo_user"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "route",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "streetNumber",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postcode",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locality",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v11 = [
  (v1/*: any*/)
],
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "createdBy",
  "plural": false,
  "selections": (v11/*: any*/),
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "assignedTo",
  "plural": false,
  "selections": (v11/*: any*/),
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dueAt",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "doneAt",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "overdue",
  "storageKey": null
},
v19 = {
  "kind": "InlineFragment",
  "selections": [
    (v14/*: any*/),
    (v15/*: any*/),
    (v16/*: any*/),
    (v17/*: any*/),
    (v18/*: any*/)
  ],
  "type": "ActivityAssignment",
  "abstractKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "note",
  "storageKey": null
},
v21 = [
  (v14/*: any*/),
  (v15/*: any*/),
  (v16/*: any*/),
  (v17/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "done",
    "storageKey": null
  },
  (v18/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "success",
    "storageKey": null
  },
  (v20/*: any*/)
],
v22 = {
  "kind": "InlineFragment",
  "selections": (v21/*: any*/),
  "type": "ActivityCall",
  "abstractKey": null
},
v23 = {
  "kind": "InlineFragment",
  "selections": (v21/*: any*/),
  "type": "ActivityVisit",
  "abstractKey": null
},
v24 = {
  "kind": "InlineFragment",
  "selections": [
    (v14/*: any*/),
    (v15/*: any*/),
    (v16/*: any*/),
    (v17/*: any*/),
    (v18/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subject",
      "storageKey": null
    }
  ],
  "type": "ActivityTask",
  "abstractKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filters"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sortBy"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sortDirection"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": LeadsPaginationQuery_graphql
    }
  },
  "name": "Leads_root",
  "selections": [
    {
      "alias": "leads",
      "args": null,
      "concreteType": "LeadConnectionConnection",
      "kind": "LinkedField",
      "name": "__Connection_leads_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LeadConnectionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Lead",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "leadUpdatedTimeAgo_lead"
                },
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "appraisalReason",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "appraisalPerception",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "appraisalPerceivedValue",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "mortgageBuyingStatus",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "mortgageStatus",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "receivedMortgageOffer",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "saleHorizon",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "buyHorizon",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "userCanViewLeadDetails",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "contact",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Email",
                      "kind": "LinkedField",
                      "name": "primaryEmail",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "email",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "referrer",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "broker",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "LeadStage",
                  "kind": "LinkedField",
                  "name": "stage",
                  "plural": false,
                  "selections": (v4/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Property",
                  "kind": "LinkedField",
                  "name": "property",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PropertyType",
                      "kind": "LinkedField",
                      "name": "propertyType",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "mainType",
                          "storageKey": null
                        },
                        (v3/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v5/*: any*/),
                    (v6/*: any*/),
                    (v7/*: any*/),
                    (v8/*: any*/),
                    (v9/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "GoogleAddress",
                      "kind": "LinkedField",
                      "name": "googleAddress",
                      "plural": false,
                      "selections": [
                        (v5/*: any*/),
                        (v6/*: any*/),
                        (v7/*: any*/),
                        (v8/*: any*/),
                        (v9/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "livingSurface",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "builtSurface",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "landSurface",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Appraisal",
                      "kind": "LinkedField",
                      "name": "latestAppraisal",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "RealadvisorAppraisal",
                          "kind": "LinkedField",
                          "name": "realadvisor",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "max",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "min",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "nextActivity",
                  "plural": false,
                  "selections": [
                    (v10/*: any*/),
                    (v12/*: any*/),
                    (v13/*: any*/),
                    (v19/*: any*/),
                    (v22/*: any*/),
                    (v23/*: any*/),
                    (v24/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "latestActivity",
                  "plural": false,
                  "selections": [
                    (v10/*: any*/),
                    (v12/*: any*/),
                    (v13/*: any*/),
                    (v19/*: any*/),
                    (v22/*: any*/),
                    (v23/*: any*/),
                    (v24/*: any*/),
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v15/*: any*/),
                        (v17/*: any*/),
                        (v20/*: any*/)
                      ],
                      "type": "ActivityNote",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Dictionary",
                  "kind": "LinkedField",
                  "name": "source",
                  "plural": false,
                  "selections": (v4/*: any*/),
                  "storageKey": null
                },
                (v10/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "48f7fba3302a6dcbbb8337abecc48617";

export default node;
