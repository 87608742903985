import type {
  Control,
  DeepPartialSkipArrayKey,
  FieldPath,
  FieldPathValue,
} from 'react-hook-form';
import { useWatch } from 'react-hook-form';

type ItemOf<T> = T extends (infer U)[] ? U : never;
type FieldValues = Record<string, any>;
type FieldPathValues<
  TFieldValues extends FieldValues,
  TPath extends FieldPath<TFieldValues>[] | readonly FieldPath<TFieldValues>[],
> = {
  [K in ItemOf<TPath>]: FieldPathValue<
    TFieldValues,
    K & FieldPath<TFieldValues>
  >;
};

export const useWatchMultiple = <
  TFieldValues extends FieldValues = FieldValues,
  TFieldNames extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: {
  name: readonly TFieldNames[];
  defaultValue?: DeepPartialSkipArrayKey<TFieldValues>;
  control?: Control<TFieldValues>;
  disabled?: boolean;
  exact?: boolean;
}): FieldPathValues<TFieldValues, TFieldNames[]> => {
  const watchedValues = useWatch<TFieldValues>(props);

  return props.name.reduce((acc, name, index) => {
    acc[name] = watchedValues[index];

    return acc;
  }, {} as FieldPathValues<TFieldValues, TFieldNames[]>);
};
