/**
 * @generated SignedSource<<236c7135daf80de7225fd6fe0c602674>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type AppraisePropertyInput = {|
  clientMutationId?: ?string,
  id: string,
  shouldCompleteLead?: ?boolean,
|};
export type PropertyDrawerAppraiseMutation$variables = {|
  input: AppraisePropertyInput,
|};
export type PropertyDrawerAppraiseMutation$data = {|
  +appraiseProperty: ?{|
    +pendingAppraisalId: ?string,
  |},
|};
export type PropertyDrawerAppraiseMutation = {|
  response: PropertyDrawerAppraiseMutation$data,
  variables: PropertyDrawerAppraiseMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AppraisePropertyPayload",
    "kind": "LinkedField",
    "name": "appraiseProperty",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pendingAppraisalId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PropertyDrawerAppraiseMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PropertyDrawerAppraiseMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "10d60dfc10f055c08c87acb26abc5165",
    "id": null,
    "metadata": {},
    "name": "PropertyDrawerAppraiseMutation",
    "operationKind": "mutation",
    "text": "mutation PropertyDrawerAppraiseMutation(\n  $input: AppraisePropertyInput!\n) {\n  appraiseProperty(input: $input) {\n    pendingAppraisalId\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "ecde9072d259fa83386395e9ab08b5f6";

export default node;
