import { Star } from '@mui/icons-material';
import { Card, Grid, Skeleton, Typography } from '@mui/material';
import { Link, type To } from 'react-router-dom';

import { useLocale } from '../../../src/hooks/locale';
import type { OverviewGetTeamQuery } from '../../__generated__/graphql';
import { roundRating } from '../../utils/formatting';

import { getRatingNumberAndAverage } from './Teams';

interface ScoreCardProps {
  title: string;
  value?: string | number | React.ReactNode;
  to?: To;
}

const ScoreCard = ({ title, value, to }: ScoreCardProps) => {
  const ScoreCard = (
    <Card sx={{ p: 2, mb: 2 }} variant="outlined" elevation={0}>
      <Typography variant="h6">{title}</Typography>
      <Typography variant="h4">{value}</Typography>
    </Card>
  );
  if (to) {
    return <Link to={to}>{ScoreCard}</Link>;
  }
  return ScoreCard;
};

const ScoreCardSkeleton = () => (
  <Card sx={{ p: 2, mb: 2 }} variant="outlined" elevation={0}>
    <Typography variant="h6">
      <Skeleton />
    </Typography>
    <Typography variant="h4">
      <Skeleton />
    </Typography>
  </Card>
);

interface TeamScoreCardsProps {
  team: OverviewGetTeamQuery['teams_by_pk'];
  loading?: boolean;
}

export const TeamScoreCards = ({ team, loading }: TeamScoreCardsProps) => {
  const { t, locale } = useLocale();

  if (loading) {
    return (
      <Grid container spacing={2}>
        {Array.from({ length: 5 }).map((_, i) => (
          <Grid item xs={6} sm={4} lg={2.4} key={i}>
            <ScoreCardSkeleton />
          </Grid>
        ))}
      </Grid>
    );
  }

  if (!team) {
    return null;
  }

  const totalLeads = team.teams_users.reduce(
    (acc, tu) => acc + (tu.user?.broker_leads_aggregate.aggregate?.count || 0),
    0,
  );

  const totalLots = team.teams_users.reduce(
    (acc, tu) => acc + (tu.user?.lots_aggregate.aggregate?.count || 0),
    0,
  );

  const totalSales = team.teams_users.reduce(
    (acc, tu) =>
      acc +
      (tu.user?.property_transactions_aggregate.aggregate?.sum
        ?.purchase_price || 0),
    0,
  );

  const subscriptions = [];
  for (const team_user of team.teams_users || []) {
    for (const sub of team_user.user?.subscriptions || []) {
      subscriptions.push(sub);
    }
  }

  const totalAmountPaid = subscriptions.reduce(
    (acc, sub) =>
      acc + (sub.invoices_aggregate.aggregate?.sum?.amount_paid ?? 0),
    0,
  );

  const { averageRating, totalReviews } = getRatingNumberAndAverage(
    team.combined_reviews_aggregate,
    team.teams_users.map(tu => tu.user?.combined_reviews_aggregate),
  );

  const cards = [
    {
      title: t('Members'),
      value: team.teams_users.length,
    },
    {
      title: t('Amount paid'),
      value: (totalAmountPaid / 100).toLocaleString(locale),
    },
    {
      title: t('Leads'),
      value: totalLeads,
      to: {
        pathname: `/v2/leads`,
        search: new URLSearchParams({
          order_by: JSON.stringify([
            {
              requalified_or_created_at: 'desc',
            },
          ]),
          where: JSON.stringify({
            broker: {
              teams_users: {
                team_id: {
                  _eq: team.id,
                },
              },
            },
          }),
        }).toString(),
      },
    },
    {
      title: t('Listings'),
      value: totalLots,
      to: {
        pathname: `/v2/listings`,
        search: new URLSearchParams({
          order_by: JSON.stringify([
            {
              created_at: 'desc',
            },
          ]),
          where: JSON.stringify({
            broker: {
              teams_users: {
                team_id: {
                  _eq: team.id,
                },
              },
            },
          }),
        }).toString(),
      },
    },
    {
      title: t('Sales'),
      value: Intl.NumberFormat().format(totalSales || 0),
    },
    {
      title: `${t('Rating')} (${totalReviews} ${t('reviews')})`,
      value: (
        <div css={{ display: 'inline-flex', alignItems: 'center' }}>
          <Star
            fontSize="inherit"
            sx={{
              color: 'orange',
              marginRight: 1,
            }}
          />
          {roundRating(averageRating).toFixed(1)}
        </div>
      ),
    },
  ];

  return (
    <Grid container spacing={2} rowSpacing={0.5}>
      {cards.map(({ title, value, to }) => (
        <Grid item xs={6} sm={4} lg={2.4} key={title}>
          <ScoreCard title={title} value={value} to={to} />
        </Grid>
      ))}
    </Grid>
  );
};
