import * as React from 'react';
import { ResizeObserver } from '@juggle/resize-observer';

type TargetRef = { current: HTMLElement | null };

type ObservedRectSize = {
  height: number;
  width: number;
};

export type ObservedRect = {
  bottom: number;
  height: number;
  left: number;
  right: number;
  top: number;
  width: number;
  x: number;
  y: number;
};

const mapSize = (rect: DOMRectReadOnly) => ({
  height: rect.height,
  width: rect.width,
});

const useLayoutEffect =
  typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect;

export const useResizeObserver = (
  targetRef: TargetRef,
  listener: (rectSize: ObservedRectSize) => void,
) => {
  // use the latest listener in observer
  const listenerRef = React.useRef(listener);

  useLayoutEffect(() => {
    listenerRef.current = listener;
  });

  const [observer] = React.useState(
    () =>
      new ResizeObserver(entries => {
        if (entries.length !== 0) {
          const { contentRect } = entries[0];
          listenerRef.current(mapSize(contentRect));
        }
      }),
  );

  // observe an element
  React.useEffect(() => {
    const element = targetRef.current;
    if (element == null) {
      throw Error('Observed ref.current should be not be nullable');
    }

    listenerRef.current(mapSize(element.getBoundingClientRect()));

    observer.observe(element);
    return () => {
      observer.unobserve(element);
    };
  }, [targetRef, observer]);
};

export const useResizeRect = (
  targetRef: TargetRef,
): null | ObservedRectSize => {
  const [rect, setRect] = React.useState<null | ObservedRectSize>(null);
  useResizeObserver(targetRef, setRect);
  return rect;
};

const getRect = (element: HTMLElement) => {
  const rect = element.getBoundingClientRect();
  return {
    bottom: rect.bottom,
    height: rect.height,
    left: rect.left,
    right: rect.right,
    top: rect.top,
    width: rect.width,
    x: rect.left,
    y: rect.top,
  };
};

export const useClientPositionObserver = (
  targetRef: TargetRef,
  listener: (rect: ObservedRect) => void,
) => {
  // use the latest listener in observer
  const listenerRef = React.useRef(listener);
  // TODO temporary fix of late listener before scheduler.next is ready
  // TODO remove before enabling concurrent mode
  listenerRef.current = listener;
  /*
  React.useEffect(() => {
    listenerRef.current = listener;
  });
  */

  // observe an element
  React.useEffect(() => {
    const element = targetRef.current;
    if (element == null) {
      throw Error('Observed ref.current should be not nullable');
    }

    // enable capture to listen scroll events of all elements
    const scrollOptions = true;

    const handleScroll = (event: Event) => {
      // ignore scroll events from siblings
      if (
        event.target instanceof HTMLElement &&
        event.target.contains(element)
      ) {
        listenerRef.current(getRect(element));
      }
    };

    const handleResize = () => {
      listenerRef.current(getRect(element));
    };

    // compute rect after mount
    handleResize();

    window.addEventListener('scroll', handleScroll, scrollOptions);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('scroll', handleScroll, scrollOptions);
      window.removeEventListener('resize', handleResize);
    };
  }, [targetRef]);
};

export const useClientPositionRect = (
  targetRef: TargetRef,
): null | ObservedRect => {
  const [rect, setRect] = React.useState<null | ObservedRect>(null);
  useClientPositionObserver(targetRef, setRect);
  return rect;
};

export const useClickOutsideObserver = (
  targets: ReadonlyArray<TargetRef>,
  listener: (event: MouseEvent | TouchEvent) => void,
) => {
  // use the latest listener in observer
  const listenerRef = React.useRef(listener);
  // TODO temporary fix of late listener before scheduler.next is ready
  // TODO remove before enabling concurrent mode
  listenerRef.current = listener;
  /*
  React.useEffect(() => {
    listenerRef.current = listener;
  });
  */

  React.useEffect(() => {
    const handleStart = (event: MouseEvent | TouchEvent) => {
      const target = event.target;
      if (target instanceof Element) {
        const clickedOutside = targets.every(
          // check null to skip not mounted refs
          ref => ref.current == null || !ref.current.contains(target),
        );
        const visible =
          target.closest('[data-invisible-for-click-outside]') == null;
        if (clickedOutside && visible) {
          listenerRef.current(event);
        }
      }
    };

    document.addEventListener('touchstart', handleStart);
    document.addEventListener('mousedown', handleStart);
    return () => {
      document.removeEventListener('touchstart', handleStart);
      document.removeEventListener('mousedown', handleStart);
    };
  }, [targets]);
};
