/**
 * @generated SignedSource<<f9dbe9dc00521a5d63949a4302446b1c>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type TypeOfPlace = "agglomeration" | "country" | "district" | "locality" | "municipality" | "neighbourhood" | "postcode" | "region" | "state";
export type placeFilterQuery$variables = {|
  placeId_in?: ?$ReadOnlyArray<string>,
|};
export type placeFilterQuery$data = {|
  +places: $ReadOnlyArray<{|
    +breadcrumbs: $ReadOnlyArray<{|
      +id: string,
    |}>,
    +id: string,
    +label: string,
    +lat: number,
    +lng: number,
    +objectId: string,
    +population: ?number,
    +type: TypeOfPlace,
  |}>,
  +tenantSettings: ?{|
    +defaultMapCoordinates: {|
      +lat: number,
      +lng: number,
      +z: number,
    |},
  |},
|};
export type placeFilterQuery = {|
  response: placeFilterQuery$data,
  variables: placeFilterQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "placeId_in"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lng",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lat",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "MapPositionWithZoom",
  "kind": "LinkedField",
  "name": "defaultMapCoordinates",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "z",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "all",
      "value": true
    },
    {
      "kind": "Variable",
      "name": "id_in",
      "variableName": "placeId_in"
    },
    {
      "kind": "Literal",
      "name": "limit",
      "value": 100
    }
  ],
  "concreteType": "Place",
  "kind": "LinkedField",
  "name": "places",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "objectId",
      "storageKey": null
    },
    (v2/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "population",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "incudeNonIndexed",
          "value": true
        }
      ],
      "concreteType": "Place",
      "kind": "LinkedField",
      "name": "breadcrumbs",
      "plural": true,
      "selections": [
        (v4/*: any*/)
      ],
      "storageKey": "breadcrumbs(incudeNonIndexed:true)"
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "placeFilterQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantSettings",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      (v5/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "placeFilterQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantSettings",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      (v5/*: any*/)
    ]
  },
  "params": {
    "cacheID": "03175bf60cb9622a64ded795617ffb9c",
    "id": null,
    "metadata": {},
    "name": "placeFilterQuery",
    "operationKind": "query",
    "text": "query placeFilterQuery(\n  $placeId_in: [ID!]\n) {\n  tenantSettings {\n    defaultMapCoordinates {\n      lng\n      lat\n      z\n    }\n    id\n  }\n  places(id_in: $placeId_in, limit: 100, all: true) {\n    id\n    type\n    objectId\n    lat\n    lng\n    label\n    population\n    breadcrumbs(incudeNonIndexed: true) {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "8664284762fcbdbf087ebe9b0ee3a436";

export default node;
