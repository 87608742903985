import type * as React from 'react';

import { Box, Flex } from 'react-system';

import { useTheme } from '../../hooks/theme';
import { Close } from '../../icons/close';

type Props = {
  bg?: string;
  title: React.ReactNode;
  onClose: () => void;
  children: React.ReactNode;
};

export const FilterDialog = (props: Props) => {
  const { text } = useTheme();

  return (
    <>
      <Flex alignItems="center" pt={3} justifyContent="space-between">
        <Box px={3} css={text.subtitle1}>
          {props.title}
        </Box>
        <Box
          p={1}
          mr={2}
          css={{ lineHeight: 0, cursor: 'pointer' }}
          onClick={props.onClose}
        >
          <Close />
        </Box>
      </Flex>
      {props.children}
    </>
  );
};
