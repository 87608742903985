// This component is used to edit and create portals.
// Still to do:
// - Handle network errors (unauthorized, etc.)
import { useMutation, useQuery } from '@apollo/client';
import { DeleteForever, HolidayVillageOutlined } from '@mui/icons-material';
import {
  Navigate,
  Route,
  Routes,
  matchPath,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { useLocale } from '../../../src/hooks/locale';
import type { GetPortalQuery } from '../../__generated__/graphql';
import { Drawer } from '../../components/drawer/Drawer';

import { PortalSettings } from './PortalSettings';
import { DELETE_PORTAL, GET_PORTAL } from './portalsQueries';
import { PortalTeams } from './PortalTeams';

export const PortalDrawer = () => {
  const { t } = useLocale();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { portalId } = useParams();
  const { pathname } = useLocation();

  const currentTab =
    matchPath('/v2/portals/:portalId/:tab', pathname)?.params.tab ??
    matchPath('/teams/:teamId/portals/:portalId/:tab', pathname)?.params.tab ??
    'overview';

  const { data } = useQuery<GetPortalQuery>(GET_PORTAL, {
    variables: { id: portalId },
  });

  const isEditing = !!data?.portals_by_pk?.id;

  const [deletePortal] = useMutation(DELETE_PORTAL);

  const handleClose = () =>
    navigate({
      pathname: '../',
      search: searchParams.toString(),
    });

  const tabs = [
    {
      label: t('Settings'),
      value: 'settings',
      to: {
        pathname: 'settings',
        search: searchParams.toString(),
      },
    },
    {
      label: t('Sharing'),
      value: 'sharing',
      to: {
        pathname: 'sharing',
        search: searchParams.toString(),
      },
    },
  ];

  return (
    <Drawer
      onClose={handleClose}
      PaperProps={{
        sx: { width: ['100%', '80%'], maxWidth: 700 },
      }}
      title={
        isEditing
          ? [t('Portals'), data.portals_by_pk?.name].join(' / ')
          : t('Create Portal')
      }
      actions={[
        {
          label: t('Delete'),
          icon: <DeleteForever />,
          disabled:
            (data?.portals_by_pk?.portal_listings_aggregate?.aggregate?.count ??
              0) > 0,
          onClick: () => {
            if (
              window.confirm(t('Are you sure you want to delete this portal?'))
            ) {
              // Delete portal and remove from cache
              deletePortal({
                variables: { id: portalId },
                update: cache => {
                  cache.evict({ id: `portals:${portalId}` });
                  cache.gc();
                },
              }).then(() => {
                // Close drawer
                handleClose();
              });
            }
          },
        },
        {
          label: t('View listings'),
          icon: <HolidayVillageOutlined />,
          to: {
            pathname: `/v2/listings`,
            search: new URLSearchParams({
              where: JSON.stringify({
                portal_listings: {
                  portal_id: { _eq: portalId },
                  live: { _eq: true },
                },
              }),
            }).toString(),
          },
        },
      ]}
      currentTab={currentTab}
      tabs={tabs}
    >
      <Routes>
        <Route
          index
          element={
            <Navigate
              replace
              to={{
                pathname: './settings',
                search: searchParams.toString(),
              }}
            />
          }
        />
        <Route path="settings" element={<PortalSettings />} />
        <Route path="sharing" element={<PortalTeams />} />
      </Routes>
    </Drawer>
  );
};
