const parentMap_ = new WeakMap<HTMLElement, HTMLElement | null>();

const regex = /(auto|scroll)/;

const style = (node: HTMLElement, prop: string) =>
  getComputedStyle(node).getPropertyValue(prop);

const hasScroll = (node: HTMLElement) =>
  regex.test(
    style(node, 'overflow') +
      style(node, 'overflow-y') +
      style(node, 'overflow-x'),
  );

const getScrollableParent = (node: HTMLElement): HTMLElement | null => {
  const weakParent = parentMap_.get(node);

  if (weakParent !== undefined) {
    return weakParent;
  }

  if (node == null || node === document.body) {
    return null;
  }

  if (style(node, 'position') === 'fixed') {
    parentMap_.set(node, null);
    return null;
  }

  const { parentNode } = node;

  if (parentNode instanceof HTMLElement) {
    if (hasScroll(parentNode)) {
      parentMap_.set(node, parentNode);
      return parentNode;
    }

    const res = getScrollableParent(parentNode);
    parentMap_.set(node, res);
    return res;
  }

  parentMap_.set(node, null);
  return null;
};

export const getScrollableParents = (
  node: HTMLElement,
): ReadonlyArray<null | HTMLElement> => {
  const res = [];
  let curr: null | HTMLElement = node;
  do {
    curr = getScrollableParent(curr);
    res.push(curr);
  } while (curr != null);
  return res;
};
