import * as React from 'react';

// Collect current portals props in queue and merge them in consumer.
// Unmounted portals removes their props from queue.
// This logic solves "the latest is winner" problem.

export type NavigationState = {
  position?: Array<'static' | 'sticky'>;
  title?: React.ReactNode;
  level0?: React.ReactNode;
  level1?: React.ReactNode;
};

type NavigationProviderProps = {
  children: React.ReactNode;
};

type NavigationQueue = [number, Partial<NavigationState>][];

type NavigationContextType = {
  queue: NavigationQueue;
  addState: (state: Partial<NavigationState>) => number;
  updateState: (key: number, state: Partial<NavigationState>) => void;
  removeState: (key: number) => void;
};

const initialState = {
  title: null,
  level0: null,
  level1: null,
};

const NavigationContext = React.createContext<NavigationContextType>({
  queue: [],
  addState: () => -1,
  updateState: () => {},
  removeState: () => {},
});

const omitVoidValues = <T extends any>(obj: T): Partial<T> =>
  Object.keys(obj as any).reduce((acc, key) => {
    // @ts-ignore
    if (obj[key] !== undefined) {
      // @ts-ignore
      return { ...acc, [key]: obj[key] };
    }
    return acc;
  }, {});

export const useNavigation = (): NavigationState => {
  const { queue } = React.useContext(NavigationContext);

  return queue.reduce<NavigationState>(
    (acc, [, state]) => ({
      ...acc,
      ...omitVoidValues(state),
      title:
        acc.title == null ? (
          state.title
        ) : state.title == null ? (
          acc.title
        ) : (
          <>
            {acc.title} / {state.title}
          </>
        ),
    }),
    initialState,
  );
};

export const NavigationPortal = React.memo<NavigationState>(props => {
  const context = React.useContext(NavigationContext);
  const key = React.useRef(-1);

  React.useEffect(() => {
    key.current = context.addState(props);

    return () => {
      if (key.current != null) {
        context.removeState(key.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (key.current != null && key.current !== -1) {
      context.updateState(key.current, props);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return null;
});

export const NavigationProvider = (props: NavigationProviderProps) => {
  const lastKey = React.useRef(-1);
  const getNextKey = () => {
    const key = lastKey.current + 1;
    lastKey.current = key;
    return key;
  };

  const [state, setState] = React.useState<NavigationContextType>({
    queue: [],
    addState: (updater: Partial<NavigationState>) => {
      const key = getNextKey();
      setState(prev => ({
        ...prev,
        queue: [...prev.queue, [key, updater]],
      }));
      return key;
    },
    updateState: (key: number, updater: Partial<NavigationState>) => {
      setState(prev => ({
        ...prev,
        queue: prev.queue.map(([k, state]) =>
          k === key ? [key, updater] : [k, state],
        ),
      }));
    },
    removeState: (key: number) => {
      setState(prev => ({
        ...prev,
        queue: prev.queue.filter(([k]) => k !== key),
      }));
    },
  });

  return (
    <NavigationContext.Provider value={state}>
      {props.children}
    </NavigationContext.Provider>
  );
};
