// Generated from src/svgs/navigation.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const Navigation = ({ size, fill, className }: Props) => {
  return (
    <svg viewBox="0 0 25 25" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><path fillRule="evenodd" clipRule="evenodd" d="M21 6c0 2.761-4.333 9-5 9-.667 0-5-6.239-5-9a5 5 0 0 1 10 0Zm-5-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4ZM1.5 18H7v6H2.5A1.5 1.5 0 0 1 1 22.5V18h.5ZM7 5H2.5A1.5 1.5 0 0 0 1 6.5V17h6V5Zm17 1.5V17H8V5h2.09c-.058.33-.088.665-.09 1 0 2.71 4.374 10 6 10 1.626 0 6-7.29 6-10a5.998 5.998 0 0 0-.09-1h.59A1.5 1.5 0 0 1 24 6.5ZM24 18H8v6h14.5a1.5 1.5 0 0 0 1.5-1.5V18Z" /></svg>
  );
}
