/**
 * @generated SignedSource<<a20b40af58de2f4c9d55626a62365cd9>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
type propertyPhotos_property$fragmentType = any;
export type DeletePropertyImageInput = {|
  clientMutationId?: ?string,
  id?: ?string,
|};
export type propertyPhotosDeleteMutation$variables = {|
  input: DeletePropertyImageInput,
|};
export type propertyPhotosDeleteMutation$data = {|
  +deletePropertyImage: ?{|
    +property: ?{|
      +$fragmentSpreads: propertyPhotos_property$fragmentType,
    |},
  |},
|};
export type propertyPhotosDeleteMutation = {|
  response: propertyPhotosDeleteMutation$data,
  variables: propertyPhotosDeleteMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "propertyPhotosDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeletePropertyImagePayload",
        "kind": "LinkedField",
        "name": "deletePropertyImage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Property",
            "kind": "LinkedField",
            "name": "property",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "propertyPhotos_property"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "propertyPhotosDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeletePropertyImagePayload",
        "kind": "LinkedField",
        "name": "deletePropertyImage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Property",
            "kind": "LinkedField",
            "name": "property",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PropertyImage",
                "kind": "LinkedField",
                "name": "propertyImages",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayOnListing",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayOnBrochure",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "size",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "crop",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "backgroundColor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isFloorPlan",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "landscapeSize",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "landscapeCrop",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "landscapeBackgroundColor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Image",
                    "kind": "LinkedField",
                    "name": "image",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "orderNr",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "81d5c4565dc6489f9bb87c029c90e7e1",
    "id": null,
    "metadata": {},
    "name": "propertyPhotosDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation propertyPhotosDeleteMutation(\n  $input: DeletePropertyImageInput!\n) {\n  deletePropertyImage(input: $input) {\n    property {\n      ...propertyPhotos_property\n      id\n    }\n  }\n}\n\nfragment previewImage_image on Image {\n  url\n}\n\nfragment propertyImageForm_image on PropertyImage {\n  id\n  title\n  description\n  size\n  crop\n  backgroundColor\n  displayOnListing\n  displayOnBrochure\n  isFloorPlan\n  landscapeSize\n  landscapeCrop\n  landscapeBackgroundColor\n  image {\n    id\n    ...previewImage_image\n  }\n}\n\nfragment propertyImageQuickEdit_image on PropertyImage {\n  id\n  displayOnListing\n  displayOnBrochure\n}\n\nfragment propertyPhotos_property on Property {\n  id\n  propertyImages {\n    ...propertyImageQuickEdit_image\n    ...propertyImageForm_image\n    id\n    orderNr\n    title\n    description\n    image {\n      id\n      url\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "99082ee330149e5abb1cbf4a12c83400";

export default node;
