// @flow

import * as React from 'react';

type Props = {|
  googleKey: string,
  children: React.Node,
|};

const GoogleKeyContext = React.createContext<?string>(null);

export const GoogleKeyProvider = ({
  googleKey,
  children,
}: Props): React.Node => {
  return (
    <GoogleKeyContext.Provider value={googleKey}>
      {children}
    </GoogleKeyContext.Provider>
  );
};

export const useGoogleKey = (): string => {
  const googleKey = React.useContext(GoogleKeyContext);
  if (googleKey == null) {
    throw Error('Missing Google API key');
  }
  return googleKey;
};
