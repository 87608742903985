// @ts-ignore no types available
import { t2z, z2t, zeroWidthDict } from 'zero-width-lib';

const separator = t2z('__codec_separator__');
const dict = Object.values(zeroWidthDict) as ReadonlyArray<string>;

export const encode = (value: string, string: string): string => {
  // put in the beginning to not break search on page
  return separator + t2z(value) + separator + string;
};

export const decode = (string: string): ReadonlyArray<string> => {
  const chunks = string.split(separator);
  const hid = [];
  for (const chunk of chunks) {
    if (dict.some(ch => chunk.includes(ch))) {
      hid.push(z2t(chunk));
    }
  }
  return hid;
};
