import * as React from 'react';

import { gql, useMutation as useApolloMutation } from '@apollo/client';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { graphql, useLazyLoadQuery, useMutation } from 'react-relay';
import { Box, Flex, useSystem } from 'react-system';

import * as config from '../config';
import { isProduction } from '../config';
import { Markdown } from '../controls/markdown';
import { useLocale } from '../hooks/locale';
import { useTheme } from '../hooks/theme';
import { Check } from '../icons/check';
import { CheckCircle } from '../icons/check-circle';
import { Close } from '../icons/close';
import { NatureLeaf } from '../icons/nature-leaf';
import { RealadvisorLogoNoText } from '../icons/realadvisor-logo-no-text';
import { RoundWarning } from '../icons/round-warning';
import { SpaceRocket } from '../icons/space-rocket';
import { TopbarTitle } from '../shared/topbar';

import type { plansCreateShortLinkMutation } from './__generated__/plansCreateShortLinkMutation.graphql';
import type { plansStripeProductsQuery } from './__generated__/plansStripeProductsQuery.graphql';
import type { plansUpsertCheckoutPageMutation } from './__generated__/plansUpsertCheckoutPageMutation.graphql';
import type { plansUserSettingsMutation } from './__generated__/plansUserSettingsMutation.graphql';

interface Props {
  stripeCustomer?: string;
  tenantCountryCode: string;
  showPlansType: string;
}

export const Plans = ({ stripeCustomer, showPlansType }: Props) => {
  const { t, language } = useLocale();
  const { colors } = useTheme();
  const { media } = useSystem();
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [createShortLink] = useMutation<plansCreateShortLinkMutation>(
    graphql`
      mutation plansCreateShortLinkMutation($input: CreateShortLinkInput!) {
        createShortLink(input: $input) {
          shortUrl
        }
      }
    `,
  );

  const productsData = useLazyLoadQuery<plansStripeProductsQuery>(
    graphql`
      query plansStripeProductsQuery($filters: StripeProductFilters) {
        stripeProducts(filters: $filters) {
          edges {
            node {
              stripeId
              name
              stripePriceId
            }
          }
        }
        me {
          id
          firstName
          lastName
          phoneNumbers {
            number
            primary
          }
        }
        tenantSettings {
          name
        }
      }
    `,
    { filters: { name_in: ['Light Plan', 'Pro Plan'] } },
  );

  const products = productsData.stripeProducts?.edges?.map(edge => edge?.node);

  const [upsertCheckoutPage] = useMutation<plansUpsertCheckoutPageMutation>(
    graphql`
      mutation plansUpsertCheckoutPageMutation(
        $input: UpsertCheckoutPageInput!
      ) {
        upsertCheckoutPage(input: $input) {
          checkoutPage {
            id
            slug
            quantity
            stripeProductId
            unitAmount
            stripeCustomerId
            cardPayment
            sepaDebitPayment
            discountCode
            product {
              name
              description
            }
          }
        }
      }
    `,
  );

  const [upsertUser] = useMutation<plansUserSettingsMutation>(
    graphql`
      mutation plansUserSettingsMutation($input: UpdateUserInput!) {
        updateUser(input: $input) {
          user {
            hasFreeCrmAccess
            hasUsedTrialButton
          }
        }
      }
    `,
  );

  const fakeTrial = () => {
    if (productsData.me?.id) {
      setLoading(true);
      upsertUser({
        variables: {
          input: {
            id: productsData.me.id,
            hasFreeCrmAccess: true,
            hasUsedTrialButton: true,
          },
        },
        onCompleted: () => {
          setLoading(false);
          window.location.reload();
        },
      });
    }
  };

  const SEND_SLACK_NOTIFICATION = gql`
    mutation sendSlackNotification($channel: String!, $text: String!) {
      send_slack_notification(channel: $channel, text: $text) {
        success
        error
      }
    }
  `;
  const [sendSlackNotification] = useApolloMutation(SEND_SLACK_NOTIFICATION);

  const chooseProPlan = () => {
    setShowConfirm(true);
    const { firstName, lastName, id, phoneNumbers } = productsData?.me ?? {};
    const tenantName = productsData.tenantSettings?.name;
    const sortedPhoneNumbers = Array.from(phoneNumbers ?? []).sort(data =>
      data.primary === true ? -1 : 1,
    );
    const phoneNumber = sortedPhoneNumbers?.[0]?.number;

    sendSlackNotification({
      variables: {
        channel: isProduction
          ? '#paywall-usage-notifications'
          : '#test-n8n-notification',
        text: `Potential re-subscription in *${tenantName} : *\n<${
          location.origin
        }/users/${id}|*${firstName} ${lastName}*> selected the *Pro Plan* from the CRM Paywall.\nContact them and help them re-subscribe: ${
          phoneNumber ? `<tel://${phoneNumber}|${phoneNumber}>` : ''
        }`,
      },
    });
    // trigger slack notification // generic hasura action ?
  };

  const chooseLightPlan = () => {
    setLoading(true);
    const product = products?.find(p => p?.name === `Light Plan`);

    upsertCheckoutPage({
      variables: {
        input: {
          checkoutPage: {
            stripeProductId: product?.stripeId,
            quantity: 1,
            paymentInterval: 1,
            stripeCustomerId: stripeCustomer ?? null,
            cardPayment: true,
            sepaDebitPayment: false,
            discountCode: false,
            stripePriceId: product?.stripePriceId,
          },
        },
      },
      onCompleted: ({ upsertCheckoutPage: data }) => {
        const checkoutPageUrl = new URL(
          `/api/checkout/${data?.checkoutPage?.slug ?? ''}/${
            language ?? ''
          }?userId=${productsData.me?.id}`,
          config.api_origin,
        );
        createShortLink({
          variables: {
            input: { url: checkoutPageUrl.toString() },
          },
          onCompleted: ({ createShortLink }) => {
            //open in another tab
            setLoading(false);
            window.open(createShortLink?.shortUrl, '_blank');
          },
        });
      },
    });
  };

  if (showPlansType !== 'showPlans') {
    return (
      <>
        <TopbarTitle>{t('No access')}</TopbarTitle>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          flexGrow={1}
          p={2}
          css={{ maxWidth: 1280, margin: '0 auto' }}
        >
          <Box>
            {showPlansType === 'showTrialing' ? (
              <RealadvisorLogoNoText size={64} />
            ) : (
              <RoundWarning fill={colors.red500} size={64} />
            )}
          </Box>
          <Box mt={3}>
            <Typography variant="h4" align="center">
              <strong>
                {showPlansType === 'showTrialing'
                  ? t('Welcome to RealAdvisor CRM')
                  : t('You subscription managed by {{manager}} has expired', {
                      manager: showPlansType.split(':')?.[1],
                    })}
              </strong>
            </Typography>
          </Box>
          <Box mt={2}>
            {showPlansType === 'showTrialing' ? (
              <Button
                size="large"
                variant="contained"
                onClick={fakeTrial}
                disabled={loading}
              >
                {loading && <CircularProgress size={24} disableShrink />}
                {t('Start trial')}
              </Button>
            ) : (
              <Typography variant="h6" align="center">
                {t(
                  'Please contact them to renew your access to RealAdvisor CRM',
                )}
              </Typography>
            )}
          </Box>
          <Box mt={3}>
            <Typography variant="body2" align="center">
              {t('For additional information, contact')}{' '}
              <a href="mailto:support@realadvisor.com">
                support@realadvisor.com
              </a>
            </Typography>
          </Box>
        </Flex>
      </>
    );
  }

  return (
    <>
      <TopbarTitle>{t('No access')}</TopbarTitle>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        p={2}
        flexGrow={1}
        css={{ maxWidth: 1280, margin: '0 auto' }}
      >
        {showConfirm ? (
          <>
            <Box>
              <CheckCircle fill={colors.blue500} size={64} />
            </Box>
            <Box mt={3}>
              <Typography variant="h4" align="center" gutterBottom>
                <strong>
                  {t('Thank you for your interest in RealAdvisor Pro')}
                </strong>
              </Typography>
              <Typography variant="h6" align="center">
                {t(
                  "To help choose the plan that's right for you, a member of our team will be in touch shortly",
                )}
              </Typography>
            </Box>
          </>
        ) : (
          <>
            <Box mt={3}>
              <Typography variant="h4" align="center">
                <strong>{t('You subscription has expired')}</strong>
              </Typography>
            </Box>
            <Typography variant="subtitle1" align="center">
              {t('Choose a plan to access RealAdvisor CRM')}
            </Typography>
            <Box my={2} p={2}>
              <Flex
                justifyContent="space-evenly"
                css={media({
                  flexDirection: ['column', 'row'],
                  alignItems: ['center', 'stretch'],
                })}
              >
                <Card
                  css={media({
                    width: ['90%', '33%'],
                    marginBottom: [32, 0],
                    display: 'flex',
                    flexDirection: 'column',
                  })}
                >
                  <CardHeader
                    title={
                      <Typography component="div" variant="h5">
                        <NatureLeaf css={{ display: 'inline' }} />{' '}
                        <strong>Light</strong>
                      </Typography>
                    }
                    css={{ paddingBottom: 0 }}
                  />
                  <CardContent>
                    <Box css={{ height: 55 }}>
                      <Typography variant="h5">
                        <span css={{ fontSize: 40 }}>
                          <strong>99 €</strong>
                        </span>{' '}
                        / {t('month')}
                      </Typography>
                    </Box>
                    <Typography variant="subtitle1">
                      <Markdown>
                        {t(
                          'Keep your **existing leads** and access to all features',
                        )}
                      </Markdown>
                    </Typography>
                    <Box mt={2}>
                      <Button
                        fullWidth={true}
                        variant="contained"
                        color="success"
                        onClick={() => chooseLightPlan()}
                        disabled={loading}
                      >
                        {loading && (
                          <CircularProgress size={24} disableShrink />
                        )}
                        {t('Choose plan')}
                      </Button>
                    </Box>
                    <Divider css={{ margin: '24px 0px 12px 0px' }} />
                    <Typography variant="h6">
                      <strong>{t('Features')}</strong>
                    </Typography>
                    <List dense={true}>
                      {[
                        t('Historic leads access'),
                        t('All CRM features'),
                        t('Unlimited CMA reports'),
                        t('Agent website'),
                        t('Lead fisher'),
                        t('Training program'),
                      ].map(feature => (
                        <ListItem disableGutters key={feature}>
                          <Check fill={colors.green500} />
                          <Box ml={1}>{feature}</Box>
                        </ListItem>
                      ))}
                      <ListItem disableGutters={true}>
                        <Close fill={colors.red500} />
                        <Box ml={1}>{t('New leads every month')}</Box>
                      </ListItem>
                    </List>
                  </CardContent>
                </Card>
                <Card
                  css={media({
                    width: ['90%', '33%'],
                    marginBottom: [32, 0],
                    display: 'flex',
                    flexDirection: 'column',
                  })}
                >
                  <CardHeader
                    title={
                      <Typography component="div" variant="h5">
                        <SpaceRocket css={{ display: 'inline' }} />{' '}
                        <strong>Pro</strong>
                        <Chip
                          css={{ float: 'right' }}
                          color="primary"
                          label={t('Most popular')}
                        />
                      </Typography>
                    }
                    css={{ paddingBottom: 0 }}
                  />
                  <CardContent css={{ padding: 0 }}>
                    <Box p={3} pb={0}>
                      <Box
                        css={{
                          height: 55,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Typography variant="h5">
                          <strong>{t('Price on request')}</strong>
                        </Typography>
                      </Box>
                      <Typography variant="subtitle1">
                        <Markdown>
                          {t(
                            'Get **new seller leads** for you and your team every month',
                          )}
                        </Markdown>
                      </Typography>
                      <Box mt={2}>
                        <Button
                          fullWidth={true}
                          variant="contained"
                          color="success"
                          onClick={() => chooseProPlan()}
                          disabled={loading}
                        >
                          {loading && (
                            <CircularProgress size={24} disableShrink />
                          )}
                          {t('Choose plan')}
                        </Button>
                      </Box>
                      <Divider css={{ margin: '24px 0px 12px 0px' }} />
                      <Typography variant="h6">
                        <strong>{t('Features')}</strong>
                      </Typography>
                      <List
                        dense={true}
                        subheader={
                          <Typography>
                            {t('All included in Light, plus...')}
                          </Typography>
                        }
                      >
                        {[
                          t('New seller leads every month'),
                          t('prospectionMap'),
                          t('Multiple teams'),
                        ].map(feature => (
                          <ListItem disableGutters key={feature}>
                            <Check fill={colors.green500} />
                            <Box ml={1}>{feature}</Box>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  </CardContent>
                </Card>
              </Flex>
            </Box>
          </>
        )}
        <Box mt={3}>
          <Typography variant="body2" align="center">
            {t('For additional information, contact')}{' '}
            <a href="mailto:support@realadvisor.com">support@realadvisor.com</a>
          </Typography>
        </Box>
      </Flex>
    </>
  );
};
