import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton, Skeleton, Stack, Typography } from '@mui/material';

import {
  type IntlLocale,
  type Translate,
  useLocale,
} from '../../../src/hooks/locale';
import { type Currency } from '../../../src/locale';
import { formatPrice } from '../../../src/utils/format-price';
import { gql } from '../../__generated__';
import type { ListingMandateCardFragment } from '../../__generated__/graphql';
import { toHumanReadableDate } from '../../utils/formatting';
import { getExpectedTimeToSellLabel } from '../../utils/general-labels';
import { ListingAccordion } from '../ListingAccordion';

export const LISTING_MANDATE_CARD_FRAGMENT = gql(`
  fragment ListingMandateCard on lots {
    origin {
      id
      label
      name
    }
    currency
    commission_rate
    fixed_fee
    is_exclusive
    mandate_type
    expected_time_to_sell
    signed_at
    property {
      country_code
    }
  }
`);

interface ListingMandateCardProps {
  listing?: ListingMandateCardFragment | null;
  isLoading?: boolean;
  openedDefault?: boolean;
  onEdit?: () => void;
}

const getMandateAmountOrPercentage = (
  mandate: ListingMandateCardFragment,
  t: Translate,
  locale: IntlLocale,
  currency: Currency,
) => {
  if (mandate.mandate_type == null) {
    return <i>{t('Set a mandate type')}</i>;
  }

  if (mandate.mandate_type === 'commission_based') {
    return mandate.commission_rate == null ? (
      <i>{t('Set a commission rate')}</i>
    ) : (
      `${(mandate.commission_rate * 100).toLocaleString(locale, {
        maximumFractionDigits: 1,
      })}%`
    );
  }

  return mandate.fixed_fee == null ? (
    <i>{t('Set a fixed fee')}</i>
  ) : (
    formatPrice(mandate.fixed_fee, locale, currency)
  );
};

export const ListingMandateCardSkeleton: React.FC<{
  openedDefault?: boolean;
}> = ({ openedDefault }) => {
  const { t } = useLocale();
  return (
    <ListingAccordion title={t('Mandate')} defaultExpanded={openedDefault}>
      <Box sx={{ position: 'relative', pr: 4 }}>
        <Stack>
          <Typography>
            <Skeleton width={150} />
          </Typography>
          <Typography variant="body2">
            <Skeleton width={250} />
          </Typography>
        </Stack>
        <IconButton
          sx={{
            position: 'absolute',
            // To account for the button padding so it seems like it's on the edge of the parent
            top: -8,
            right: -8,
          }}
        >
          <EditIcon />
        </IconButton>
      </Box>
    </ListingAccordion>
  );
};

export const ListingMandateCard: React.FC<ListingMandateCardProps> = ({
  listing,
  openedDefault,
  onEdit,
  isLoading = false,
}) => {
  const { t, locale } = useLocale();

  if (isLoading) {
    return <ListingMandateCardSkeleton openedDefault={openedDefault} />;
  }

  const currencyToFormat = (listing?.currency ??
    (listing?.property.country_code === 'CH' ? 'CHF' : 'EUR')) as Currency;

  return (
    <ListingAccordion title={t('Mandate')} defaultExpanded={openedDefault}>
      <Box
        sx={{
          position: 'relative',
          pr: 4,
        }}
      >
        {listing != null && (
          <Stack
            sx={theme => ({
              '& .MuiTypography-root': theme.mixins.truncate(),
            })}
          >
            <Typography>
              {getMandateAmountOrPercentage(
                listing,
                t,
                locale,
                currencyToFormat,
              )}
              {' • '}
              {listing.is_exclusive == null ? (
                <i>{t('Set exclusivity')}</i>
              ) : listing.is_exclusive ? (
                t('Exclusive')
              ) : (
                t('Non-exclusive')
              )}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: 'neutral.main',
              }}
            >
              {listing.signed_at != null
                ? t('Signed {{date}}', {
                    date: toHumanReadableDate(locale, listing.signed_at, {
                      month: 'numeric',
                    }),
                  })
                : t('Not signed yet')}
              {' • '}
              {getExpectedTimeToSellLabel(listing.expected_time_to_sell, t) ??
                t('Set expected time to sell')}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: 'neutral.main',
              }}
            >
              {t('Mandate origin: {{origin}}', {
                origin:
                  listing.origin?.label ?? listing.origin?.name ?? t('Unknown'),
              })}
            </Typography>
          </Stack>
        )}
        <IconButton
          sx={{
            position: 'absolute',
            // To account for the button padding so it seems like it's on the edge of the parent
            top: -8,
            right: -8,
          }}
          onClick={onEdit}
        >
          <EditIcon />
        </IconButton>
      </Box>
    </ListingAccordion>
  );
};
