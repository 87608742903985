// Generated from src/svgs/globe-outline.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const GlobeOutline = ({ size, fill, className }: Props) => {
  return (
    <svg preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><path d="M.001.001h24v24.001h-24z" fill="none" /><path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zM8 5.083a7.933 7.933 0 0 1 3-1.013V10h3v3.5l-2.49 1.867L9.721 10H8zM12 20c-4.411 0-8-3.589-8-8 0-2.021.759-3.864 2-5.274V12h2.279l2.211 6.633L16 14.5V8h-3V4.069c3.94.495 7 3.859 7 7.931 0 4.411-3.589 8-8 8z" /></svg>
  );
}
