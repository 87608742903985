import { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import {
  LocationOffOutlined,
  LocationOnOutlined,
  Search,
} from '@mui/icons-material';
import { Alert, Box } from '@mui/material';
import { green, grey, red } from '@mui/material/colors';
import type { GridColDef, GridRowsProp } from '@mui/x-data-grid-premium';
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { ListToolbar } from '../../../list-toolbar/ListToolbar';
import { useLocale } from '../../../src/hooks/locale';
import {
  type GetTeamsListQuery,
  type TeamCombinedReviewsAggFragment,
  type Teams_Bool_Exp,
} from '../../__generated__/graphql';
import { AddressCell } from '../../components/data-grid/AddressCell';
import { OrganisationCell } from '../../components/data-grid/OrganisationCell';
import { RaDataGrid } from '../../components/data-grid/RaDataGrid';
import RatingDisplay from '../../components/RatingDisplay';
import { SmartListTitle } from '../../components/SmartListTitle';
import { type FiltersTableDef } from '../../components/table/TableFilters';
import { Tag } from '../../components/Tag';
import { TeamAvatar } from '../../components/TeamAvatar';
import { TeamsSelect } from '../../components/teams-select/TeamsSelect';
import { TimeAgo } from '../../components/TimeAgo';
import { useAppData } from '../../providers/AppDataProvider';
import { prepareWhereClauseQuery } from '../../utils/parseWhereClause';

import { TeamCreateDrawer } from './TeamCreateDrawer';
import { TeamDrawer } from './TeamDrawer';
import { GET_TEAMS_LIST, GET_TEAMS_LIST_COUNT } from './teamQueries';

type Team = GetTeamsListQuery['teams'][number];
type CombinedReviewsAggregate =
  TeamCombinedReviewsAggFragment['combined_reviews_aggregate'];

export function getRatingNumberAndAverage(
  teamCombinedReviewsAgg: CombinedReviewsAggregate,
  usersCombinedReviewsAgg: CombinedReviewsAggregate[],
) {
  let totalReviews = teamCombinedReviewsAgg.aggregate?.count ?? 0;
  let averageRating =
    (teamCombinedReviewsAgg.aggregate?.avg?.rating ?? 0) *
    (totalReviews > 0 ? totalReviews : 1);

  if (usersCombinedReviewsAgg.length > 0) {
    totalReviews =
      totalReviews +
      usersCombinedReviewsAgg.reduce((acc, cr) => {
        return acc + (cr?.aggregate?.count ?? 0);
      }, 0);

    averageRating =
      averageRating +
      usersCombinedReviewsAgg.reduce((acc, cr) => {
        return (
          acc + (cr?.aggregate?.avg?.rating ?? 0) * (cr?.aggregate?.count ?? 0)
        );
      }, 0);
  }

  if (averageRating > 0) {
    averageRating = averageRating / totalReviews;
  }

  return { totalReviews, averageRating };
}

export const Teams = () => {
  const { t, locale } = useLocale();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { me } = useAppData();

  const where: Teams_Bool_Exp = useMemo(
    () =>
      prepareWhereClauseQuery(JSON.parse(searchParams.get('where') || '{}')),
    [searchParams],
  );
  const order_by = JSON.parse(
    searchParams.get('order_by') || '[{ "created_at": "desc" }]',
  );
  const limit = parseInt(searchParams.get('limit') ?? '100');
  const offset = parseInt(searchParams.get('offset') ?? '0');

  const { data, loading, error } = useQuery(GET_TEAMS_LIST, {
    variables: {
      where,
      order_by,
      limit,
      offset,
    },
  });
  const teams: GridRowsProp<Team> = data?.teams ?? [];

  const { data: countData } = useQuery(GET_TEAMS_LIST_COUNT, {
    variables: {
      where,
    },
  });

  const filtersTables: FiltersTableDef[] = [
    {
      name: 'teams',
      label: 'Teams',
      relationshipPath: null,
      fields: {
        table: 'teams',
      },
    },
    {
      name: 'teams_users',
      label: 'Teams Users',
      relationshipPath: 'teams_users',
      fields: {
        table: 'teams_users',
      },
    },
    {
      name: 'users',
      label: 'Users',
      relationshipPath: 'teams_users.user',
      fields: {
        table: 'users',
      },
    },
    {
      name: 'emails',
      label: 'Emails',
      relationshipPath: 'teams_users.user.emails',
      fields: {
        table: 'emails',
      },
    },
    {
      name: 'subscriptions',
      label: 'Subscriptions',
      relationshipPath: 'teams_users.user.subscriptions',
      fields: {
        table: 'subscriptions',
      },
    },
  ];

  const columns: GridColDef<Team>[] = [
    {
      field: 'created_at',
      headerName: t('Created'),
      width: 75,
      renderCell: ({ value }) => <TimeAgo dateString={value} />,
    },
    {
      field: 'name',
      headerName: t('Name'),
      minWidth: 250,
      maxWidth: 500,
      flex: 1,
      renderCell: ({ row }) => (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'max-content 1fr',
            alignItems: 'center',
            gap: 8,
          }}
        >
          <div style={{ position: 'relative' }}>
            <TeamAvatar logoUrl={row?.logo?.url} />
          </div>
          <div style={{ minWidth: 0 }}>
            <div>{row?.name}</div>
          </div>
        </div>
      ),
    },
    {
      field: 'teams_users.user.subscriptions.status',
      headerName: t('Subscription'),
      width: 100,
      sortable: false,
      renderCell: ({ row }) => (
        <>
          <div
            style={{
              height: 10,
              width: 10,
              borderRadius: '50%',
              display: 'inline-block',
              flexShrink: 0,
              marginRight: 5,
              backgroundColor:
                row.teams_users.filter(
                  tu =>
                    tu.user?.subscriptions.filter(s => s.status === 'active')
                      .length > 0,
                ).length > 0
                  ? green[500]
                  : grey[300],
            }}
          />
          <div
            style={{
              flexGrow: 1,
              textAlign: 'right',
            }}
          >
            {(
              row.teams_users.reduce((acc, tu) => {
                return (
                  acc +
                  tu.user?.subscriptions
                    .filter(s => s.status === 'active')
                    .reduce((acc, s) => acc + s.total_amount, 0)
                );
              }, 0) / 100
            ).toLocaleString(locale)}
          </div>
        </>
      ),
    },
    {
      field: 'show_in_agency_pages',
      headerName: t('Show in agency pages'),
      width: 350,
      display: 'flex',

      renderCell: ({ row }) => (
        <Tag status={row.show_in_agency_pages ? 'success' : 'neutral'}>
          /{row.agency_slug}
        </Tag>
      ),
    },
    {
      field: 'postcode,locality,route,street_number',
      headerName: t('Address'),
      width: 200,
      display: 'flex',

      renderCell: ({ row }) => (
        <>
          {row.lat && row.lng ? (
            <LocationOnOutlined sx={{ color: red[700], mr: 1 }} />
          ) : (
            <LocationOffOutlined sx={{ color: grey[300], mr: 1 }} />
          )}
          <AddressCell
            address={{
              street_number: row.street_number ?? '',
              route: row.route ?? '',
              postcode: row.postcode ?? '',
              locality: row.locality ?? '',
            }}
          />
        </>
      ),
    },
    {
      field: 'property_transactions_aggregate.count',
      headerName: t('Transactions'),
      width: 120,
      align: 'right',
      renderCell: ({ row }) =>
        row.property_transactions_aggregate?.aggregate?.count,
    },
    {
      field: 'property_transactions_aggregate.max.updated_at',
      headerName: t('Last transaction'),
      width: 120,
      renderCell: ({ row }) => (
        <TimeAgo
          dateString={
            row.property_transactions_aggregate?.aggregate?.max?.updated_at
          }
        />
      ),
    },
    {
      field: 'teams_users.user.lead_agents_aggregate.aggregate.count',
      sortable: false,
      headerName: t('Seller leads'),
      width: 120,
      align: 'right',
      renderCell: ({ row }) =>
        row.teams_users.reduce((acc, tu) => {
          return acc + (tu.user?.lead_agents_aggregate?.aggregate?.count ?? 0);
        }, 0),
    },
    {
      field: 'combined_rating',
      headerName: t('Combined Reviews'),
      width: 200,
      display: 'flex',
      sortable: false,
      renderCell: ({ row }) => {
        const { totalReviews, averageRating } = getRatingNumberAndAverage(
          row.combined_reviews_aggregate,
          row.teams_users.map(tu => tu.user?.combined_reviews_aggregate),
        );

        return (
          <div>
            {totalReviews > 0 && (
              <Box sx={{ gap: 1, alignItems: 'center', display: 'flex' }}>
                <RatingDisplay
                  readOnly
                  count={totalReviews}
                  rating={averageRating}
                  size="small"
                />
              </Box>
            )}
          </div>
        );
      },
    },
    {
      field: 'google_reviews_aggregate.avg.rating',
      headerName: t('Google Reviews'),
      width: 200,
      display: 'flex',
      renderCell: ({ row }) => (
        <div>
          <div>{row.google_place_description ?? ''}</div>
          {row.google_reviews_aggregate?.aggregate?.avg?.rating && (
            <Box sx={{ gap: 1, alignItems: 'center', display: 'flex' }}>
              <RatingDisplay
                count={row.google_reviews_aggregate?.aggregate?.count}
                readOnly
                rating={row.google_reviews_aggregate?.aggregate?.avg?.rating}
                size="small"
              />
            </Box>
          )}
        </div>
      ),
    },
    {
      field: 'teams_users_aggregate.count',
      headerName: t('Users'),
      width: 200,
      renderCell: ({ row }) => {
        return row?.teams_users?.map(tu => tu.user?.full_name).join(', ');
      },
    },
    {
      field: 'organisation.name',
      headerName: 'Organisation',
      width: 250,
      renderCell: ({ row }) =>
        row.organisation ? (
          <OrganisationCell organisation={row.organisation} />
        ) : null,
    },
  ];

  return (
    <>
      <SmartListTitle tableName="teams" defaultTitle={t('Agencies / Teams')} />

      <ListToolbar
        tableName="teams"
        tableFiltersTables={filtersTables}
        quickFilters={[
          {
            label: 'Subscription status',
            path: [
              'teams_bool_exp',
              'teams_users',
              'user',
              'subscriptions',
              'status',
              '_in',
            ],
          },
          {
            label: 'Subscription amount',
            path: [
              'teams_bool_exp',
              'teams_users',
              'user',
              'subscriptions',
              'total_amount',
            ],
          },
          {
            label: 'Show in agency pages',
            path: ['teams_bool_exp', 'show_in_agency_pages'],
          },
          {
            label: 'Agents',
            path: ['teams_bool_exp', 'teams_users', 'user'],
          },
        ]}
        searchComponent={
          <TeamsSelect
            autoFocus
            sx={{
              maxWidth: 350,
              flexGrow: 1,
              ml: 1,
              background: 'white',
            }}
            InputProps={{
              startAdornment: <Search />,
            }}
            onChange={teamId => {
              if (!teamId) {
                return;
              }
              navigate({
                pathname: `/teams/${teamId}`,
                search: searchParams.toString(),
              });
            }}
            size="small"
          />
        }
        newLink="new"
      />
      {error ? (
        <Alert severity="error" sx={{ m: 2 }}>
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </Alert>
      ) : (
        <RaDataGrid
          loading={loading}
          rows={teams}
          columns={columns}
          rowCount={countData?.teams_aggregate.aggregate?.count ?? 0}
        />
      )}
      <Routes>
        <Route
          path="new"
          element={
            me?.is_admin ? (
              <TeamCreateDrawer />
            ) : (
              <Navigate to="../" replace={true} />
            )
          }
        />
        <Route path=":teamId/*" element={<TeamDrawer />} />
      </Routes>
    </>
  );
};

export default Teams;
