import { useConstant } from '@realadvisor/hooks';

import { Filter } from '../controls/Filters';
import { useLocale } from '../hooks/locale';
import {
  type Field,
  makeField,
  makeUrlSearchParamsHook,
} from '../hooks/url-search-params';
import { BaseUserFilter } from '../shared/base-user-filter';

export type ContactParams = {
  contactId_in: Field<string[] | null>;
};

export const contactParams: ContactParams = {
  contactId_in: makeField({
    get: params => {
      const list = params.getAllStrings('contactId_in');
      return list.length === 0 ? null : list;
    },
    set: (params, value) => params.setAllStrings('contactId_in', value || []),
  }),
};

const useContactParams = makeUrlSearchParamsHook<ContactParams>(contactParams);

export const ContactFilter = () => {
  const { t } = useLocale();
  const [params, setParams] = useContactParams();
  const namesCache = useConstant(() => new Map<string, string>());
  // filter out null value
  const selectedIds = (params.contactId_in ?? []).filter(Boolean);
  const setValue = (node: { id: null | string; name: string | null }) => {
    const id = node.id;
    if (id != null) {
      const newSelectedIds = new Set(selectedIds);
      if (newSelectedIds.has(id)) {
        newSelectedIds.delete(id);
      } else {
        newSelectedIds.add(id);
      }
      if (node.name != null) {
        namesCache.set(id, node.name);
      }
      setParams({ contactId_in: Array.from(newSelectedIds) });
    }
  };
  let label;
  const count = selectedIds.length;
  const first = selectedIds
    .map(id => namesCache.get(id))
    .find(name => name != null);
  if (count === 0) {
    label = t('contacts');
  } else if (first != null) {
    label = count > 1 ? `${first} +${count - 1}` : first;
  } else {
    label = t('contactsSelectedCount', { count });
  }
  return (
    <Filter
      label={label}
      dialogTitle={t('contacts')}
      empty={count === 0}
      onReset={() => setParams({ contactId_in: [] })}
    >
      <BaseUserFilter selectedIds={selectedIds} onChange={setValue} />
    </Filter>
  );
};
