// @flow

import * as React from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  List,
  ListItem,
} from '@material-ui/core';
import { graphql, useFragment } from 'react-relay';
// $FlowFixMe[untyped-import]
import { Link } from 'react-router-dom';
import { Box } from 'react-system';

import { useLocale } from '../hooks/locale';
import { useTheme } from '../hooks/theme';
import { ExpandMore } from '../icons/expand-more';
import { GpsFixed } from '../icons/gps-fixed';
import { FinancingLeadCreationDrawer } from '../shared/financing-lead-creation-drawer';

import type { financingLeadsCard_user$key } from './__generated__/financingLeadsCard_user.graphql';

type Props = {|
  user: ?financingLeadsCard_user$key,
  onCreate: (leadId?: string) => void,
  onCancel?: () => void,
|};

const FinancingListItemLink = ({ lead }) => {
  const { t } = useLocale();
  const { text, textColor } = useTheme();

  const getMortgageType = value => {
    switch (value) {
      case 'new_mortgage':
        return t('newMortgage');
      case 're_mortgage':
        return t('remortgage');
      default:
        return value ?? '-';
    }
  };
  const getPropertyType = isConstruction => {
    if (isConstruction == null) {
      return '-';
    }
    return isConstruction === true
      ? t('newDevelopment')
      : t('existingProperty');
  };
  const getUsage = value => {
    switch (value) {
      case 'investment':
        return t('investment');
      case 'primary':
        return t('primary');
      case 'secondary':
        return t('secondary');
      default:
        return value ?? '-';
    }
  };

  return (
    // $FlowFixMe[prop-missing]
    <ListItem
      button={true}
      component={Link}
      to={`/financing/${lead.id}`}
      target="_blank"
    >
      <Avatar>{<GpsFixed />}</Avatar>
      <Box pl={3}>
        <Box css={text.body2}>{`${getMortgageType(
          lead.mortgageMortgageType,
        )} • ${getPropertyType(lead.mortgageIsConstruction)} • ${getUsage(
          lead.mortgagePropertyUsage,
        )}`}</Box>
        <Box css={[text.body2, textColor('mediumText')]}>
          {lead.property?.formattedAddress != null
            ? lead.property.formattedAddress
            : lead.mortgageGoogleAddress?.formattedAddress}
        </Box>
      </Box>
    </ListItem>
  );
};

export const FinancingLeadsCard = (props: Props): React.Node => {
  const { t } = useLocale();
  const [propertySearchDrawer, setPropertySearchDrawer] = React.useState(false);

  const user = useFragment(
    graphql`
      fragment financingLeadsCard_user on User {
        ...financingLeadCreationDrawer_user
        id
        leads {
          id
          mortgageIsConstruction
          mortgageMortgageType
          mortgagePropertyUsage
          stage {
            pipeline {
              leadType
            }
          }
          property {
            formattedAddress
          }
          mortgageGoogleAddress {
            formattedAddress
          }
        }
      }
    `,
    props.user,
  );

  const financingLeads = user?.leads?.filter(
    l => l?.stage?.pipeline?.leadType === 'financing',
  );

  return (
    <Accordion defaultExpanded={false}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        {t('financingLeadsCount', {
          count: financingLeads?.length ?? 0,
        })}
      </AccordionSummary>
      <FinancingLeadCreationDrawer
        open={propertySearchDrawer}
        onClose={() => setPropertySearchDrawer(false)}
        onCreate={leadId => {
          setPropertySearchDrawer(false);
          props.onCreate(leadId);
        }}
        initialValues={{ contact: user }}
      />

      <List>
        {financingLeads?.map(
          lead =>
            lead != null && <FinancingListItemLink lead={lead} key={lead.id} />,
        )}
      </List>
      <AccordionDetails css={{ flexDirection: 'column' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setPropertySearchDrawer(true)}
        >
          {t('addFinancingLead')}
        </Button>
      </AccordionDetails>
    </Accordion>
  );
};
