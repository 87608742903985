// Generated from src/svgs/nav-saas-active.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const NavSaasActive = ({ size, fill, className }: Props) => {
  return (
    <svg viewBox="0 0 25 25" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><path d="M16.185 4.58A5.041 5.041 0 0 1 17.465 7h-3.758l1.147-1.147a.5.5 0 1 0-.707-.706L12.293 7H7.535a5.082 5.082 0 0 1 8.65-2.42zM12.5 13a1.5 1.5 0 1 0 1.5 1.5 1.502 1.502 0 0 0-1.5-1.5zm8.075-4.51-.504 10.085A1.5 1.5 0 0 1 18.573 20H14v5h-3v-5H6.427a1.5 1.5 0 0 1-1.498-1.425L4.425 8.489a8.085 8.085 0 1 1 16.15 0zM15 14.5a2.5 2.5 0 1 0-2.5 2.5 2.5 2.5 0 0 0 2.5-2.5zm0-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 0 0 1h4a.5.5 0 0 0 .5-.5zM18.578 8A6.079 6.079 0 0 0 6.422 8z" /></svg>
  );
}
