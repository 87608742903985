import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { useLocale } from '../../../src/hooks/locale';
import type { InvoiceFragmentFragment } from '../../__generated__/graphql';

interface InvoicesTableProps {
  invoices: InvoiceFragmentFragment[];
}

export const InvoicesTable = ({ invoices }: InvoicesTableProps) => {
  const { t } = useLocale();

  return (
    <TableContainer component={Paper} variant="outlined">
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>{t('Created')}</TableCell>
            <TableCell>{t('Period start')}</TableCell>
            <TableCell>{t('Period end')}</TableCell>
            <TableCell>{t('Status')}</TableCell>
            <TableCell>{t('Due')}</TableCell>
            <TableCell>{t('Paid')}</TableCell>
            <TableCell>Stripe</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoices.map(inv => (
            <TableRow key={inv.id}>
              <TableCell>{inv.created.substring(0, 10)}</TableCell>
              <TableCell>
                {new Date((inv.lines?.data?.[0]?.period?.start || 0) * 1000)
                  .toISOString()
                  .substring(0, 10)}
              </TableCell>
              <TableCell>
                {new Date((inv.lines?.data?.[0]?.period?.end || 0) * 1000)
                  .toISOString()
                  .substring(0, 10)}
              </TableCell>
              <TableCell>{inv.status}</TableCell>
              <TableCell>
                {Intl.NumberFormat().format((inv.amount_due ?? 0) / 100)}
              </TableCell>
              <TableCell>
                {Intl.NumberFormat().format((inv.amount_paid ?? 0) / 100)}
              </TableCell>
              <TableCell>
                <a
                  href={`https://dashboard.stripe.com/invoices/${inv.stripe_id}`}
                  target="_blank"
                >
                  {inv.stripe_id}
                </a>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
