import { FormControl, FormHelperText, FormLabel } from '@mui/material';
import type { Control } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import { useLocale } from '../../../src/hooks/locale';
import { type Dictionaries_Types_Enum_Enum } from '../../__generated__/graphql';
import { DictionarySelect } from '../dictionary-select/DictionarySelect';

export type RaDictionaryProps = {
  control: Control<any>;
  name: string;
  label: string;
  dictionaryType: Dictionaries_Types_Enum_Enum;
  disabled?: boolean;
  required?: boolean;
  multiple?: boolean;
  valueField?: 'id' | 'name';
};

export const RaDictionary = ({
  name,
  label,
  control,
  dictionaryType,
  disabled = false,
  multiple = false,
  required,
  valueField = 'name',
}: RaDictionaryProps) => {
  const { t } = useLocale();
  const rules = {
    required: required ? t('This field is required') : undefined,
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl fullWidth error={!!error}>
          <FormLabel style={{ marginBottom: '0.5rem', fontWeight: 500 }}>
            {label}
          </FormLabel>
          <DictionarySelect
            disabled={disabled}
            defaultValue={value}
            dictionaryType={dictionaryType}
            onChange={onChange}
            size="small"
            multiple={multiple}
            clearOnBlur={value == null}
            valueField={valueField}
          />
          <FormHelperText>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};
