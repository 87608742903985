import type { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import type { Interpolation } from '@emotion/react';
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  IconButton,
} from '@material-ui/core';
import { Image } from '@realadvisor/image';
import { Box } from 'react-system';

import { Delete } from '../../icons/delete';
import { Edit } from '../../icons/edit';
import { Fullscreen } from '../../icons/fullscreen';
import { Reorder } from '../../icons/reorder';
import { useLocale } from '../../src/hooks/locale';
import { useTheme } from '../../src/hooks/theme';

type Attributes = ReturnType<typeof useSortable>['attributes'];
type Listeners = ReturnType<typeof useSortable>['listeners'];

const RATIO_IMG = 0.67;

const aspectRatio = (): Interpolation<unknown> => ({
  position: 'relative',
  '&:before': {
    display: 'block',
    width: '100%',
    paddingTop: `${100 * RATIO_IMG}%`,
    content: "''",
  },
});

const UploadedImage = ({
  image,
  error,
  index,
  total,
  onImageDelete,
  onShowFullScreen,
  onShowDetail,
  quickEditContent,
  attributes,
  listeners,
}: {
  image: CardImage;
  error: null | string;
  index: number;
  total: number;
  onImageDelete: (image: CardImage) => void;
  onShowFullScreen: (imageIndex: number) => void;
  onShowDetail: (imageIndex: number) => void;
  quickEditContent?: (image: CardImage) => JSX.Element;
  attributes: Attributes | {};
  listeners: Listeners;
}) => {
  const { t } = useLocale();
  const { text, colors } = useTheme();

  return (
    <Card
      css={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <CardActions
        css={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1,
          color: colors.white,
          justifyContent: 'space-between',
        }}
      >
        <IconButton
          size="small"
          color="inherit"
          css={{ cursor: 'grab' }}
          {...attributes}
          {...listeners}
        >
          <Reorder />
        </IconButton>
        <IconButton
          size="small"
          color="inherit"
          onClick={() => onShowFullScreen(index)}
        >
          <Fullscreen />
        </IconButton>
      </CardActions>

      <CardActionArea
        css={[
          aspectRatio(),
          error != null && {
            ':before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: 'red',
              opacity: 0.3,
              transition: 'background 0.3s linear',
            },
          },
        ]}
        onClick={() => onShowDetail(index)}
      >
        <Image
          objectFit="cover"
          options={{ w: 300, h: 300, c: 'fill' }}
          src={image.image.url}
          css={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          }}
        />
        <div
          css={[
            text.subtitle2,
            {
              position: 'absolute',
              bottom: '0px',
              right: '0px',
              padding: '4px 8px',
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
              color: colors.white,
            },
          ]}
        >
          {index + 1}/{total}
        </div>
      </CardActionArea>

      <CardContent
        css={{
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'column',
          'p, h2': {
            width: '100%',
            overflow: 'hidden',
          },
        }}
      >
        <Box mb={2} css={[text.body1, text.truncate(1)]}>
          {image.title != null ? image.title : t('noTitle')}
        </Box>
        <Box css={[text.caption, { color: colors.mediumText }]}>
          {image.description != null ? image.description : t('noDescription')}
        </Box>
        <div
          css={[
            {
              overflow: 'hidden',
              overflowWrap: 'break-word',
              wordWrap: 'break-word',
              hyphens: 'auto',
              marginTop: '2px',
            },
            error != null && { color: 'red', paddingRight: '24px' },
          ]}
        >
          {error != null ? error : null}
        </div>
        {quickEditContent != null && quickEditContent(image)}
      </CardContent>

      <CardActions css={{ flexShrink: 0, justifyContent: 'space-between' }}>
        <IconButton
          size="small"
          color="primary"
          onClick={() => onShowDetail(index)}
        >
          <Edit />
        </IconButton>
        <IconButton
          size="small"
          color="primary"
          onClick={() => onImageDelete(image)}
        >
          <Delete />
        </IconButton>
      </CardActions>
    </Card>
  );
};

export type CardImage = {
  id: UniqueIdentifier;
  title: null | string;
  description: null | string;
  image: {
    id: string;
    url: string;
  };
};

const SortableUploadedImage = (props: Props) => {
  const {
    isDragging,
    setNodeRef,
    transform,
    transition,
    attributes,
    listeners,
  } = useSortable({
    id: props.image.id,
  });
  return (
    <div
      ref={setNodeRef}
      style={{
        transition,
        transform: CSS.Transform.toString(transform),
        visibility: isDragging ? 'hidden' : undefined,
      }}
    >
      <UploadedImage {...props} attributes={attributes} listeners={listeners} />
    </div>
  );
};

type Props = {
  image: CardImage;
  draggable: boolean;
  error: null | string;
  index: number;
  total: number;
  onImageDelete: (image: CardImage) => void;
  onShowFullScreen: (imageIndex: number) => void;
  onShowDetail: (imageIndex: number) => void;
  quickEditContent?: (image: CardImage) => JSX.Element;
};

export const SelectImageCard = (props: Props) => {
  return props.draggable === true ? (
    <SortableUploadedImage {...props} />
  ) : (
    <UploadedImage {...props} attributes={{}} listeners={{}} />
  );
};
