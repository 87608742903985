/**
 * @generated SignedSource<<74d35c0b5245c16099b6c4b6cb52fdd8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ActivityForm_buyerLead$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ActivityEventForm_buyerLead" | "ActivityNoteForm_buyerLead">;
  readonly " $fragmentType": "ActivityForm_buyerLead";
};
export type ActivityForm_buyerLead$key = {
  readonly " $data"?: ActivityForm_buyerLead$data;
  readonly " $fragmentSpreads": FragmentRefs<"ActivityForm_buyerLead">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActivityForm_buyerLead",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ActivityNoteForm_buyerLead"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ActivityEventForm_buyerLead"
    }
  ],
  "type": "BuyerLead",
  "abstractKey": null
};

(node as any).hash = "c313fab9ae82795af360eab21ad18745";

export default node;
