/**
 * @generated SignedSource<<aef18ac0cfc6180495aa61900c4f572b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type LeadAppraisalReason = "already_for_sale" | "buy_soon" | "buy_this" | "inheritance" | "move_rental" | "not_selling" | "not_set" | "other" | "other_not_owner" | "other_owner" | "other_renting" | "refinance" | "sell" | "separation";
export type LeadBuyHorizon = "not_set" | "over_12_months" | "under_12_months" | "under_3_months";
export type LeadRelationship = "agent" | "buyer" | "heir" | "not_set" | "other" | "owner" | "tenant";
export type LeadSaleHorizon = "already_for_sale" | "from_1_to_2_years" | "from_2_years" | "from_6_to_12_months" | "not_selling" | "not_set" | "under_6_months";
export type PipelineLeadType = "buyer" | "financing" | "sales";
import { FragmentRefs } from "relay-runtime";
export type leadOtherLeadsCard_lead$data = {
  readonly appraisalReason: LeadAppraisalReason;
  readonly buyHorizon: LeadBuyHorizon;
  readonly contact: {
    readonly firstName: string | null;
    readonly id: string;
    readonly lastName: string | null;
  } | null;
  readonly id: string;
  readonly relationship: LeadRelationship;
  readonly saleHorizon: LeadSaleHorizon;
  readonly stage: {
    readonly pipeline: {
      readonly leadType: PipelineLeadType;
    } | null;
  } | null;
  readonly " $fragmentType": "leadOtherLeadsCard_lead";
};
export type leadOtherLeadsCard_lead$key = {
  readonly " $data"?: leadOtherLeadsCard_lead$data;
  readonly " $fragmentSpreads": FragmentRefs<"leadOtherLeadsCard_lead">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "leadOtherLeadsCard_lead",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "contact",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "relationship",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalReason",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "saleHorizon",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buyHorizon",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LeadStage",
      "kind": "LinkedField",
      "name": "stage",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Pipeline",
          "kind": "LinkedField",
          "name": "pipeline",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "leadType",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Lead",
  "abstractKey": null
};
})();

(node as any).hash = "2ce1bd1b922b2699a66ed44037d6fb85";

export default node;
