import { gql } from '../../__generated__';

export const ME = gql(`
  query GetLeadsMe {
    me {
      id
      is_admin
    }
  }
`);

export const ActivityCellFragment = gql(`
  fragment ActivityCell on activities {
    id
    created_at
    due_at
    done_at
    start_date
    end_date
    done
    success
    subject
    activity_type
    note
  }
`);

export const GET_LEADS = gql(`
  query GetLeads(
    $limit: Int
    $offset: Int
    $where: leads_bool_exp
    $order_by: [leads_order_by!] = [{ requalified_or_created_at: asc }]
  ) {
    leads(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
      id
      created_at
      created_by
      requalified_at
      requalified_or_created_at
      appraisal_reason
      sale_horizon
      buy_horizon
      appraisal_perceived_value
      appraisal_perception
      status
      source {
        label
      }
      leads_status {
        label
      }
      landing_page_url
      contact {
        id
        first_name
        last_name
        ...UserInfo_user
      }
      broker {
        id
        first_name
        last_name
        ...UserInfo_user
      }
      claimed_by
      next_activity: activities(
        limit: 1
        where: {
          done: { _eq: false }
          activity_type: { _in: [call, visit, task, assignment] }
        }
        order_by: { due_at: asc }
      ) {
        id
        ...ActivityCell
      }
      last_activity: activities(
        limit: 1
        where: {
          done: { _eq: true }
          activity_type: { _in: [note, call, visit, assignment, task] }
        }
        order_by: { consolidated_done_at: desc }
      ) {
        id
        ...ActivityCell
      }
      property {
        id
        route
        street_number
        locality
        postcode
        land_surface
        living_surface
        built_surface
        property_type {
          id
          label
          main_type
        }
        latest_appraisal {
          id
          min
          value
          max
        }
      }
      stage {
        id
        label
      }
    }
  }
`);

export const GET_LEADS_COUNT = gql(`
  query GetLeadsCount($where: leads_bool_exp, $limit: Int) {
    leads_aggregate(where: $where, limit: $limit) {
      aggregate {
        count
      }
    }
  }
`);

export const GET_LEAD = gql(`
  query GetLead($lead_id: uuid!) {
    leads_by_pk(id: $lead_id) {
      id
      tenant_id
      ...MarketingCardLeadFragment
      appraisal_reason
      appraisal_perception
      appraisal_perceived_value
      property {
        id
        latest_appraisal {
          id
          value
        }
      }
      broker {
        id
        ...UserCard_user
        default_team {
          id
          name
        }
      }
      contact {
        id
        ...ContactCardUserFragment
      }
      ...LeadAgents_lead
    }
  }
`);

export const LEAD_FEED = gql(`
  query LeadFeed(
    $user_id: uuid!
    $email_addresses: [String!]
    $skip_email_activities: Boolean!
  ) {
    user_activities: activities(
      where: {
        # Skip activities that are already included in lead_activites
        lead_id: { _is_null: true }
        user_id: { _eq: $user_id }
        # Skip activities that are already included in email_activities
        activity_type: { _neq: email }
      }
      order_by: { created_at: desc }
      limit: 250
    ) {
      ...activityFields
    }
    lead_activites: activities(
      where: {
        lead: { contact_id: { _eq: $user_id } }
        # Skip activities that are already included in email_activities
        activity_type: { _neq: email }
      }
      order_by: { created_at: desc }
      limit: 250
    ) {
      ...activityFields
    }
    email_activities: activities(
      where: {
        activities_target_users: { email: { _in: $email_addresses } }
        # Only query email_activities
        activity_type: { _eq: email }
      }
      order_by: { created_at: desc }
      limit: 250
    ) @skip(if: $skip_email_activities) {
      ...activityFields
    }
  }
`);

export const ASSIGN_LEAD = gql(`
  mutation AssignLead($lead_id: uuid!, $broker_id: uuid!) {
    update_leads_by_pk(
      pk_columns: { id: $lead_id }
      _set: { broker_id: $broker_id }
    ) {
      id
    }
    insert_activities_one(
      object: {
        lead_id: $lead_id
        assigned_to: $broker_id
        activity_type: assignment
      }
    ) {
      id
    }
  }
`);

export const ADD_LEAD_AGENT = gql(`
  mutation AddLeadAgent($lead_id: uuid!, $user_id: uuid!) {
    insert_lead_agents_one(object: { lead_id: $lead_id, user_id: $user_id }) {
      id
    }
    insert_activities_one(
      object: {
        lead_id: $lead_id
        assigned_to: $user_id
        activity_type: assignment
      }
    ) {
      id
    }
  }
`);

export const DELETE_LEAD_AGENT = gql(`
  mutation DeleteLeadAgent($id: uuid!) {
    delete_lead_agents_by_pk(id: $id) {
      id
    }
  }
`);
