import { CardContent } from '@material-ui/core';
import { formatDistance } from 'date-fns';
import { graphql, useFragment } from 'react-relay';
import { Link } from 'react-router-dom';
import { Box, Flex } from 'react-system';
// $FlowFixMe[untyped-import]

import { useLocale } from '../../hooks/locale';
import { useTheme } from '../../hooks/theme';
import { AccessTime } from '../../icons/access-time';
import { RoundWarning } from '../../icons/round-warning';
import { TagOutline } from '../../icons/tag-outline';
import { getCurrencyByCountryCode } from '../../locale';
import { formatPrice } from '../../utils/format-price';

import type { feedOffer_activity$key } from './__generated__/feedOffer_activity.graphql';

type Props = {
  activity: feedOffer_activity$key;
};

export const FeedOffer = (props: Props) => {
  const { text, colors, textColor } = useTheme();
  const { locale, countryCode, dateLocale, t, intlLocale } = useLocale();
  const activity = useFragment(
    graphql`
      fragment feedOffer_activity on Activity {
        id
        ... on ActivityOffer {
          offer {
            id
            lot {
              id
            }
            amount
            validUntil
            status
          }
        }
      }
    `,
    props.activity,
  );

  const { offer } = activity;

  if (offer == null) {
    return null;
  }

  const dueAt = offer.validUntil != null ? new Date(offer.validUntil) : null;
  const isOverdue =
    offer.status !== 'pending'
      ? false
      : dueAt != null
      ? dueAt < new Date()
      : null;

  return (
    <CardContent
      css={{
        paddingTop: 0,
        display: 'grid',
        gridGap: 12,
        gridTemplateColumns: '24px 1fr',
      }}
    >
      <TagOutline />
      <div css={text.body2}>
        {formatPrice(
          offer.amount,
          locale,
          getCurrencyByCountryCode(countryCode),
          0,
          '-',
        )}{' '}
        -{' '}
        <Link
          css={{
            fontWeight: text.font.bold,
          }}
          to={`/lots/${offer.lot?.id ?? ''}/offers/${offer.id}`}
        >
          {t('viewOffer')}
        </Link>
      </div>
      {dueAt != null && (
        <>
          <AccessTime
            fill={isOverdue === true ? colors.errorText : 'currentColor'}
          />
          <div
            css={[
              text.body2,
              {
                color: isOverdue === true ? colors.errorText : undefined,
              },
            ]}
          >
            <Flex alignItems="center">
              {new Intl.DateTimeFormat(intlLocale, {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              }).format(dueAt)}
              {isOverdue === true && (
                <Flex ml={2} alignItems="center">
                  <Box mr={1}>
                    <RoundWarning size={20} fill={colors.error} />
                  </Box>
                  <Box css={[text.caption, { fontWeight: text.font.medium }]}>
                    {t('overdue')}
                  </Box>
                </Flex>
              )}
            </Flex>
            <div css={[text.caption, textColor('mediumText')]}>
              (
              {formatDistance(dueAt, Date.now(), {
                locale: dateLocale,
                addSuffix: true,
              })}
              )
            </div>
          </div>
        </>
      )}
    </CardContent>
  );
};
