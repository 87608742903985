import { useEffect } from 'react';

import { Box, Paper } from '@mui/material';
import { useGoogleApi } from '@realadvisor/google-maps';
import { Map, useMap } from 'rgm';

import { gql } from '../../__generated__';
import { type ListingMapFragment } from '../../__generated__/graphql';

export const LISTING_MAP_FRAGMENT = gql(`
  fragment ListingMap on properties {
    id
    lat
    lng
    google_address {
      lat
      lng
    }
  }
`);

const GMarker: React.FC<{
  lat: number;
  lng: number;
}> = ({ lat, lng }) => {
  const { api, map } = useMap();

  useEffect(() => {
    if (api) {
      const marker = new api.Marker({
        map,
        position: {
          lat,
          lng,
        },
      });

      return () => {
        marker.setMap(null);
      };
    }
  }, [api, map, lat, lng]);

  return null;
};

interface IProps {
  property: ListingMapFragment;
}

export const ListingMap: React.FC<IProps> = ({ property }) => {
  const id = property.id;

  const lat = property.lat ?? property.google_address?.lat;
  const lng = property.lng ?? property.google_address?.lng;

  const marker =
    lat != null && lng != null ? { id, lat, lng } : { id, lat: 0, lng: 0 };

  const mapApi = useGoogleApi(true);
  const mapOptions: google.maps.MapOptions = {
    zoom: 10,
    center: {
      lat: marker.lat,
      lng: marker.lng,
    },
    gestureHandling: 'greedy',
    clickableIcons: false,
  };

  return (
    mapApi && (
      <Paper
        sx={{
          position: 'relative',
          borderRadius: 2,
          overflow: 'hidden',
          pt: '60%',
        }}
        elevation={0}
        component="div"
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            zIndex: 1,
          }}
        >
          <Map api={mapApi} options={mapOptions}>
            <GMarker lat={marker.lat} lng={marker.lng} />
          </Map>
        </Box>
      </Paper>
    )
  );
};
