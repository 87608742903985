// @flow

import * as React from 'react';

import {
  AppBar,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Tooltip,
} from '@material-ui/core';

import { useLocale } from '../hooks/locale';
import { useTheme } from '../hooks/theme';
import { Close } from '../icons/close';
import { CloudDownload } from '../icons/cloud-download';

const SlideUp = React.forwardRef((props, ref) => (
  <Slide ref={ref} direction="up" {...props} />
));

type Props = {|
  url: ?string,
  name: string,
  open: boolean,
  onClose: () => void,
|};

const supportObject = !/iPad|iPhone|iPod/.test(navigator.userAgent); // do not show embed viewer on iOS

export const FileViewerDialog = ({
  url,
  name,
  open,
  onClose,
}: Props): React.Node => {
  const { t } = useLocale();
  const { text } = useTheme();
  const encodedUri = url == null ? null : encodeURIComponent(url);
  const iframe =
    encodedUri == null ? null : (
      <iframe
        css={{ flexGrow: 1 }}
        src={`https://docs.google.com/viewer?url=${encodedUri}&embedded=true`}
      />
    );
  return (
    <Dialog
      fullScreen={true}
      open={open}
      onClose={onClose}
      TransitionComponent={SlideUp}
    >
      <AppBar position="static">
        <Toolbar>
          <IconButton color="inherit" onClick={onClose}>
            <Close />
          </IconButton>
          <div css={[text.h6, text.truncate(1), { flexGrow: 1 }]}>{name}</div>
          <Tooltip
            title={
              <>
                {t('downloadAs')} {name}
              </>
            }
          >
            <IconButton
              color="inherit"
              component="a"
              href={url ?? ''}
              disabled={url == null}
              download={true}
            >
              <CloudDownload />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      {supportObject ? (
        <object
          data={url}
          type="application/pdf"
          css={{ flex: 1, display: 'flex' }}
        >
          {iframe}
        </object>
      ) : (
        iframe
      )}
    </Dialog>
  );
};
