export const isEmpty = (value: string | number | null | undefined) =>
  value == null || (typeof value === 'string' && value.length === 0);

export const outOfRange = (
  value: number | string | null | undefined,
  min: number,
  max: number,
  allowEmpty = false,
) => {
  if (value == null) {
    return !allowEmpty;
  }

  const parsed = typeof value === 'string' ? Number.parseFloat(value) : value;

  if (Number.isNaN(parsed)) {
    return true;
  }

  return parsed < min || parsed > max;
};

export const numberContainsDecimals = (value: number, decimals: number[]) => {
  const decimal = parseInt((value % 1).toFixed(1).substring(2), 10);

  return decimals.includes(decimal);
};
