import { gql } from '../../__generated__';

export const GET_TEAM_VISIBILITY_STATS_GRAPH = gql(`
  query GetTeamVisibilityStatsGraph(
    $teamId: uuid
    $startDate: String
    $endDate: String
    $groupBy: ImpressionsGroupingColumn
    $orderBy: String
    $orderDirection: String
    $hostname: String
  ) {
    impressions_graph_data: impressions_grouped_by_column(
      agency_uuid: $teamId
      date_range_start: $startDate
      date_range_end: $endDate
      grouping_column: date
      order_by_column: "name"
      order_by_direction: "asc"
      hostname: $hostname
    ) {
      data {
        name
        count
      }
    }
    views_graph_data: impressions_grouped_by_column(
      agency_uuid: $teamId
      date_range_start: $startDate
      date_range_end: $endDate
      grouping_column: date
      page_types: ["agency-detail", "agent-detail"]
      order_by_column: "name"
      order_by_direction: "asc"
      hostname: $hostname
    ) {
      data {
        name
        count
        user_count
      }
    }
    average_position: impressions_grouped_by_column(
      agency_uuid: $teamId
      date_range_start: $startDate
      date_range_end: $endDate
      grouping_column: date
      order_by_column: "name"
      order_by_direction: "asc"
      hostname: $hostname
    ) {
      data {
        name
        average_position
        count
      }
    }
    show_phone_number_data: impressions_grouped_by_column(
      agency_uuid: $teamId
      date_range_start: $startDate
      date_range_end: $endDate
      grouping_column: date
      hostname: $hostname
      order_by_column: "name"
      order_by_direction: "asc"
      event_names: ["show_phone_number"]
    ) {
      data {
        name
        count
        user_count
      }
    } 
    me {
      is_admin
    }
  }
`);

export const GET_TEAM_VISIBILITY_STATS_LIST = gql(`
  query GetTeamVisibilityStatsList(
    $teamId: uuid
    $startDate: String
    $endDate: String
    $groupBy: ImpressionsGroupingColumn
    $orderBy: String
    $orderDirection: String
    $hostname: String
  ) {
    list_data: impressions_grouped_by_column(
      agency_uuid: $teamId
      date_range_start: $startDate
      date_range_end: $endDate
      grouping_column: $groupBy
      order_by_column: $orderBy
      order_by_direction: $orderDirection
      hostname: $hostname
    ) {
      data {
        name
        count
        user_count
        average_position
      }
    }
    me {
      is_admin
    }
  }
`);
