/**
 * @generated SignedSource<<53f0651e39a5212068368dd88774f6d8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type leadDetails_lead$data = {
  readonly appraisalPropertyFormattedAddress: string | null;
  readonly contact: {
    readonly firstName: string | null;
    readonly id: string;
    readonly lastName: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"financingLeadsCard_user">;
  } | null;
  readonly id: string;
  readonly potentialDevelopment: boolean;
  readonly property: {
    readonly formattedAddress: string | null;
    readonly formattedLocality: string | null;
    readonly id: string;
    readonly owners: ReadonlyArray<{
      readonly id: string;
    }> | null;
    readonly " $fragmentSpreads": FragmentRefs<"LeadDetailsMap_property" | "propertyCard_property">;
  } | null;
  readonly userCanViewLeadDetails: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"Feed_lead" | "LeadDetailsAnswersCard_lead" | "LeadDetailsLatestAppraisalCard_lead" | "leadBrokerCard_lead" | "leadCmaCard_lead" | "leadContactCard_lead" | "leadControls_lead" | "leadFilesCard_lead" | "leadLotCard_lead" | "leadQualificationCard_lead" | "leadSnippet_lead">;
  readonly " $fragmentType": "leadDetails_lead";
};
export type leadDetails_lead$key = {
  readonly " $data"?: leadDetails_lead$data;
  readonly " $fragmentSpreads": FragmentRefs<"leadDetails_lead">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "leadDetails_lead",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Feed_lead"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "leadFilesCard_lead"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LeadDetailsAnswersCard_lead"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "leadBrokerCard_lead"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "leadContactCard_lead"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "leadSnippet_lead"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "leadControls_lead"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "leadQualificationCard_lead"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LeadDetailsLatestAppraisalCard_lead"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "leadLotCard_lead"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "leadCmaCard_lead"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalPropertyFormattedAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "potentialDevelopment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userCanViewLeadDetails",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "contact",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "financingLeadsCard_user"
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Property",
      "kind": "LinkedField",
      "name": "property",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "LeadDetailsMap_property"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "propertyCard_property"
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "formattedAddress",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "formattedLocality",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "owners",
          "plural": true,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Lead",
  "abstractKey": null
};
})();

(node as any).hash = "45780306f9282b4b3207f1397b7af388";

export default node;
