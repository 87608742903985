/**
 * @generated SignedSource<<6e1cede1f5c05e4ddb33892434b443d5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssignLeadsInput = {
  brokerId?: string | null;
  clientMutationId?: string | null;
  id?: ReadonlyArray<string | null> | null;
};
export type leadBrokerCardAssignMutation$variables = {
  input: AssignLeadsInput;
};
export type leadBrokerCardAssignMutation$data = {
  readonly assignLeads: {
    readonly leads: ReadonlyArray<{
      readonly stage: {
        readonly label: string;
        readonly pipeline: {
          readonly label: string | null;
        } | null;
      } | null;
      readonly " $fragmentSpreads": FragmentRefs<"leadBrokerCard_lead">;
    } | null> | null;
  } | null;
};
export type leadBrokerCardAssignMutation = {
  response: leadBrokerCardAssignMutation$data;
  variables: leadBrokerCardAssignMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Image",
  "kind": "LinkedField",
  "name": "primaryImage",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    (v3/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Email",
  "kind": "LinkedField",
  "name": "primaryEmail",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "referrer",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isBroker",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isAdmin",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "primary",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "broker",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/),
    (v8/*: any*/),
    (v9/*: any*/),
    (v10/*: any*/),
    (v11/*: any*/),
    (v12/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Organisation",
      "kind": "LinkedField",
      "name": "organisation",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "formattedAddress",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PhoneNumber",
      "kind": "LinkedField",
      "name": "phoneNumbers",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "formattedNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "number",
          "storageKey": null
        },
        (v13/*: any*/),
        (v3/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Email",
      "kind": "LinkedField",
      "name": "emails",
      "plural": true,
      "selections": [
        (v7/*: any*/),
        (v13/*: any*/),
        (v3/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "broker",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        (v8/*: any*/),
        (v9/*: any*/),
        (v10/*: any*/),
        (v11/*: any*/),
        (v12/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Subscription",
      "kind": "LinkedField",
      "name": "subscription",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        (v3/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "leadBrokerCardAssignMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AssignLeadsPayload",
        "kind": "LinkedField",
        "name": "assignLeads",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Lead",
            "kind": "LinkedField",
            "name": "leads",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "leadBrokerCard_lead"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "LeadStage",
                "kind": "LinkedField",
                "name": "stage",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Pipeline",
                    "kind": "LinkedField",
                    "name": "pipeline",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "leadBrokerCardAssignMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AssignLeadsPayload",
        "kind": "LinkedField",
        "name": "assignLeads",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Lead",
            "kind": "LinkedField",
            "name": "leads",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v14/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "contact",
                "plural": false,
                "selections": [
                  (v14/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Property",
                "kind": "LinkedField",
                "name": "property",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "brokersInCatchmentArea",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "LeadStage",
                "kind": "LinkedField",
                "name": "stage",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Pipeline",
                    "kind": "LinkedField",
                    "name": "pipeline",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "56aa3e043f074411042070a7e6b8d94d",
    "id": null,
    "metadata": {},
    "name": "leadBrokerCardAssignMutation",
    "operationKind": "mutation",
    "text": "mutation leadBrokerCardAssignMutation(\n  $input: AssignLeadsInput!\n) {\n  assignLeads(input: $input) {\n    leads {\n      ...leadBrokerCard_lead\n      stage {\n        label\n        pipeline {\n          label\n          id\n        }\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment leadBrokerCard_lead on Lead {\n  id\n  broker {\n    ...userEditableCard_user\n    displayName\n    primaryImage {\n      url\n      id\n    }\n    id\n  }\n  contact {\n    broker {\n      ...userEditableCard_user\n      id\n    }\n    id\n  }\n  property {\n    id\n    brokersInCatchmentArea {\n      id\n    }\n  }\n}\n\nfragment userAvatar_user on User {\n  id\n  firstName\n  lastName\n  primaryImage {\n    url\n    id\n  }\n  primaryEmail {\n    email\n    id\n  }\n  referrer\n  isBroker\n  isAdmin\n}\n\nfragment userEditableCard_user on User {\n  ...userSummary_user\n  id\n  firstName\n  lastName\n  primaryEmail {\n    email\n    id\n  }\n  primaryImage {\n    url\n    id\n  }\n  organisation {\n    name\n    formattedAddress\n    id\n  }\n  subscription {\n    status\n    id\n  }\n}\n\nfragment userSummary_user on User {\n  ...userAvatar_user\n  id\n  displayName\n  organisation {\n    id\n    name\n  }\n  phoneNumbers {\n    formattedNumber\n    number\n    primary\n    id\n  }\n  emails {\n    email\n    primary\n    id\n  }\n  broker {\n    ...userAvatar_user\n    id\n    displayName\n  }\n}\n"
  }
};
})();

(node as any).hash = "07d8729f2f76099dddd804b650daea13";

export default node;
