// Generated from src/svgs/user-chat.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const UserChat = ({ size, fill, className }: Props) => {
  return (
    <svg preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><g clipPath="url(#user-chat_svg__a)" fill="#2A2A2A"><path d="M11.25 12.75a.75.75 0 0 1-.75-.75v-1.5h-.75V9h1.5a.75.75 0 0 1 .75.75v.75l1.8-1.35a.75.75 0 0 1 .45-.15c1.241 0 2.25-1.01 2.25-2.25v-1.5C16.5 4.01 15.492 3 14.25 3h-4.5a2.257 2.257 0 0 0-2.165 1.638l-1.443-.407A3.762 3.762 0 0 1 9.75 1.5h4.5A3.754 3.754 0 0 1 18 5.25v1.5a3.754 3.754 0 0 1-3.488 3.741L11.701 12.6a.753.753 0 0 1-.45.15z" /><path d="M6 12c-1.654 0-3-1.345-3-3 0-1.654 1.346-3 3-3 1.655 0 3 1.346 3 3 0 1.655-1.345 3-3 3zm0-4.5a1.501 1.501 0 0 0 0 3 1.501 1.501 0 0 0 0-3zM12 18H0v-.75c0-2.65 2.468-4.5 6-4.5 3.533 0 6 1.85 6 4.5V18zM1.62 16.5h8.762c-.448-1.356-2.125-2.25-4.38-2.25-2.257 0-3.934.894-4.382 2.25z" /></g><defs><clipPath id="user-chat_svg__a"><path fill="#fff" d="M0 0h18v18H0z" /></clipPath></defs></svg>
  );
}
