import type { FieldPath } from 'react-hook-form';

import type { Translate } from '../../../../src/hooks/locale';
import type {
  Properties_Set_Input,
  PropertyFeaturesFragment,
} from '../../../__generated__/graphql';
import { getKeys } from '../../../utils/objects';
import {
  isCommercialBuilding,
  isHouseOrAppt,
  isMixedUseBuilding,
} from '../../../utils/propertyDataChecks';
import {
  type CheckboxFieldDefinition,
  type FormDefinitionType,
  createCheckboxElement,
  createCheckboxGroupElement,
} from '../../form/RaForm';
import type { PropertyFormData } from '../propertyFormDefinition';
import type { PropertyTypeLight as PropertyType } from '../propertyFormQueries';

type PropertyFeaturesType = Pick<
  PropertyFeaturesFragment,
  'is_luxury' | 'is_child_friendly' | 'is_wheelchair_accessible'
>;

type PropertyEquipmentType = Pick<
  PropertyFeaturesFragment,
  | 'has_gym'
  | 'has_view'
  | 'has_attic'
  | 'has_sauna'
  | 'has_cellar'
  | 'has_elevator'
  | 'has_fireplace'
  | 'has_storage_room'
  | 'has_swimming_pool'
  | 'has_wellness_area'
  | 'has_photovoltaic_panels'
>;

const getFeaturesFields = (
  t: Translate,
): CheckboxFieldDefinition<PropertyFeaturesType>[] => [
  createCheckboxElement('is_luxury', t('Is luxury')),
  createCheckboxElement('is_child_friendly', t('Is child friendly')),
  createCheckboxElement(
    'is_wheelchair_accessible',
    t('Is wheelchair accessible'),
  ),
];

const getEquipmentFields = (
  t: Translate,
): CheckboxFieldDefinition<PropertyEquipmentType>[] => [
  createCheckboxElement('has_gym', t('Has gym')),
  createCheckboxElement('has_view', t('Has view')),
  createCheckboxElement('has_sauna', t('Has sauna')),
  createCheckboxElement('has_cellar', t('Has cellar')),
  createCheckboxElement('has_fireplace', t('Has fireplace')),
  createCheckboxElement('has_storage_room', t('Has storage room')),
  createCheckboxElement('has_swimming_pool', t('Has swimming pool')),
  createCheckboxElement('has_wellness_area', t('Has wellness area')),
  createCheckboxElement('has_elevator', t('Has elevator')),
  createCheckboxElement(
    'has_photovoltaic_panels',
    t('Has photovoltaic panels'),
  ),
  createCheckboxElement('has_attic', t('Has attic')),
];

const isHouseOrApptOrMixedUse = (property_type: PropertyType) =>
  isHouseOrAppt(property_type) || isMixedUseBuilding(property_type);
const isBuilding = (property_type: PropertyType) =>
  isHouseOrApptOrMixedUse(property_type) || isCommercialBuilding(property_type);

export const equipmentConditionalFields = new Map<
  FieldPath<PropertyEquipmentType>,
  (data: { __property_type?: PropertyType }) => boolean
>([
  [
    'has_sauna',
    ({ __property_type }) => isHouseOrApptOrMixedUse(__property_type),
  ],
  ['has_cellar', ({ __property_type }) => isBuilding(__property_type)],
  ['has_fireplace', ({ __property_type }) => isBuilding(__property_type)],
  ['has_storage_room', ({ __property_type }) => isBuilding(__property_type)],
  [
    'has_swimming_pool',
    ({ __property_type }) => isHouseOrApptOrMixedUse(__property_type),
  ],
  [
    'has_wellness_area',
    ({ __property_type }) => isHouseOrApptOrMixedUse(__property_type),
  ],
  ['has_elevator', ({ __property_type }) => isBuilding(__property_type)],
  [
    'has_photovoltaic_panels',
    ({ __property_type }) => isHouseOrApptOrMixedUse(__property_type),
  ],
  [
    'has_attic',
    ({ __property_type }) => isHouseOrApptOrMixedUse(__property_type),
  ],
]);

export const standaloneFeaturesFormDefinition: FormDefinitionType<
  PropertyFeaturesType
> = ({ t }) => {
  const fields = getFeaturesFields(t);

  return [
    createCheckboxGroupElement('features', t('Features'), fields, {
      gridProps: { alignSelf: 'start', md: 6 },
    }),
  ];
};

export const getFeaturesFormDefinition =
  <FormData extends PropertyFormData>(): FormDefinitionType<FormData> =>
  ({ t }) => {
    const fields = getFeaturesFields(t) as CheckboxFieldDefinition<FormData>[];

    return [
      createCheckboxGroupElement('features', t('Features'), fields, {
        gridProps: { alignSelf: 'start', md: 6 },
      }),
    ];
  };

export const standaloneEquipmentFormDefinition: FormDefinitionType<
  PropertyEquipmentType,
  { propertyType: PropertyType }
> = ({ t, context }) => {
  const fields = getEquipmentFields(t);

  const displayedFields = fields.filter(field => {
    const renderFn = equipmentConditionalFields.get(field.name);

    return (
      renderFn == null || renderFn({ __property_type: context.propertyType })
    );
  });

  return displayedFields.length === 0
    ? []
    : [
        createCheckboxGroupElement(
          'equipment',
          t('Equipment'),
          displayedFields,
          {
            gridProps: { alignSelf: 'start', md: 6 },
          },
        ),
      ];
};

export const getEquipmentFormDefinition =
  <FormData extends PropertyFormData>(): FormDefinitionType<FormData> =>
  ({ t }) => {
    const fields = getEquipmentFields(t) as CheckboxFieldDefinition<FormData>[];

    fields.forEach(field => {
      const renderFn = equipmentConditionalFields.get(field.name as any);

      if (renderFn != null) {
        field.render = renderFn;
      }
    });

    return [
      createCheckboxGroupElement('equipment', t('Equipment'), fields, {
        gridProps: { alignSelf: 'start', md: 6 },
      }),
    ];
  };

export const preparePropertyFeaturesFormData = (
  data: PropertyFeaturesFragment,
  propertyType: PropertyType,
): Properties_Set_Input =>
  getKeys(data).reduce(
    (acc: Properties_Set_Input, key): Properties_Set_Input => {
      const value = data[key];
      acc[key] = value;

      const renderFn = equipmentConditionalFields.get(key as any);
      if (renderFn && !renderFn({ __property_type: propertyType })) {
        // We unset the value if the field is not rendered.
        acc[key] = null;
      }

      return acc;
    },
    {},
  );

export const getDefaultPropertyFeaturesEquipmentsFormValues = (
  property: PropertyFeaturesFragment,
): PropertyFeaturesFragment => ({
  has_attic: property.has_attic === true,
  has_cellar: property.has_cellar === true,
  has_elevator: property.has_elevator === true,
  has_fireplace: property.has_fireplace === true,
  has_gym: property.has_gym === true,
  has_photovoltaic_panels: property.has_photovoltaic_panels === true,
  has_sauna: property.has_sauna === true,
  has_storage_room: property.has_storage_room === true,
  has_swimming_pool: property.has_swimming_pool === true,
  has_view: property.has_view === true,
  has_wellness_area: property.has_wellness_area === true,
  is_luxury: property.is_luxury === true,
  is_child_friendly: property.is_child_friendly === true,
  is_wheelchair_accessible: property.is_wheelchair_accessible === true,
});
