/**
 * @generated SignedSource<<38312aba9505bc8626958991799c2a9e>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type ActivityTypeEnum = "assignment" | "call" | "change" | "create_user" | "email" | "match" | "note" | "offer" | "origination" | "requalification" | "task" | "visit";
export type EmailVisibilityTypeEnum = "organisation" | "private" | "shared";
export type OfferStatus = "accepted" | "pending" | "refused";
export type ParticipantStatus = "maybe" | "no" | "noreply" | "yes";
export type UpsertActivityInput = {|
  activity: ActivityInput,
  clientMutationId?: ?string,
  notifyParticipants?: ?boolean,
|};
export type ActivityInput = {|
  activityType?: ?ActivityTypeEnum,
  assignedTo?: ?string,
  createdBy?: ?string,
  done?: ?boolean,
  doneAt?: ?string,
  dueAt?: ?string,
  endAt?: ?string,
  endDate?: ?string,
  id?: ?string,
  location?: ?string,
  note?: ?string,
  offerStatus?: ?OfferStatus,
  parentId?: ?string,
  participants?: ?$ReadOnlyArray<?ActivityParticipantInput>,
  pinned?: ?boolean,
  startDate?: ?string,
  subject?: ?string,
  success?: ?boolean,
  visibility?: ?EmailVisibilityTypeEnum,
|};
export type ActivityParticipantInput = {|
  email?: ?string,
  id?: ?string,
  name?: ?string,
  status?: ?ParticipantStatus,
|};
export type leadCreateDrawerAssignmentMutation$variables = {|
  input: UpsertActivityInput,
|};
export type leadCreateDrawerAssignmentMutation$data = {|
  +upsertActivity: ?{|
    +activity: ?{|
      +id: string,
    |},
  |},
|};
export type leadCreateDrawerAssignmentMutation = {|
  response: leadCreateDrawerAssignmentMutation$data,
  variables: leadCreateDrawerAssignmentMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "leadCreateDrawerAssignmentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertActivityPayload",
        "kind": "LinkedField",
        "name": "upsertActivity",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "activity",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "leadCreateDrawerAssignmentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertActivityPayload",
        "kind": "LinkedField",
        "name": "upsertActivity",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "activity",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "df9a98eb605a02d387b85a0931e7dbb3",
    "id": null,
    "metadata": {},
    "name": "leadCreateDrawerAssignmentMutation",
    "operationKind": "mutation",
    "text": "mutation leadCreateDrawerAssignmentMutation(\n  $input: UpsertActivityInput!\n) {\n  upsertActivity(input: $input) {\n    activity {\n      __typename\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "c93ef37bc81f2ff974acc4be602ce036";

export default node;
