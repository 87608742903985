import { useCallback } from 'react';

import { useQuery } from '@apollo/client';
import {
  Alert,
  Box,
  CircularProgress,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';

import { useLocale } from '../../../../src/hooks/locale';
import { CMATemplateViewerProvider } from '../../../../src/routes/Documents/cma-template-viewer-context';
import type { GetCmaReportQuery } from '../../../__generated__/graphql';
import { Drawer } from '../../../components/drawer/Drawer';
import { formatAddress } from '../../../utils/formatting';
import { GET_CMA_REPORT } from '../cmaReportsQueries';

import CMAReportEditorWorkflow from './CMAReportEditorWorkflow';

export type CMAReportType = NonNullable<GetCmaReportQuery['cma_reports_by_pk']>;
export type CMAPropertyTypes = NonNullable<GetCmaReportQuery['property_types']>;

type CMAReportEditorDrawerType = {
  onClose: () => void;
};

const CMAReportEditorDrawer = ({ onClose }: CMAReportEditorDrawerType) => {
  const { t } = useLocale();
  const { cmaReportId } = useParams<{ cmaReportId: string }>();

  const { data, loading } = useQuery(GET_CMA_REPORT, {
    variables: { id: cmaReportId ?? '' },
    skip: cmaReportId == null,
  });

  const property = data?.cma_reports_by_pk?.lead?.property;
  const formatted_address = formatAddress(property ?? {}, ' ');

  const renderContent = useCallback(() => {
    if (loading) {
      return (
        <Stack flexGrow={1} justifyContent="center" alignItems="center" p={3}>
          <CircularProgress disableShrink />
        </Stack>
      );
    }
    if (data?.cma_reports_by_pk != null) {
      return <CMAReportEditorWorkflow data={data} />;
    }

    return <Alert severity="warning">{t('cmaReportNotFound')}</Alert>;
  }, [data, loading, t]);

  return (
    <Drawer
      onClose={onClose}
      PaperProps={{
        sx: {
          width: ['100vw', '100vw', '100vw', `calc(100vw - 100px)`, '80vw'],
        },
      }}
      title={
        <Box>
          <Typography variant="h6">{`${t(
            'valuationAnalysisEditor',
          )}`}</Typography>
          {loading ? (
            <Skeleton width={200} />
          ) : (
            <Typography variant="subtitle1">{formatted_address}</Typography>
          )}
        </Box>
      }
    >
      <CMATemplateViewerProvider>{renderContent()}</CMATemplateViewerProvider>
    </Drawer>
  );
};

export default CMAReportEditorDrawer;
