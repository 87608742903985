import * as React from 'react';

import { Accordion, Avatar, List, ListItem } from '@material-ui/core';
import { graphql, useFragment, useLazyLoadQuery } from 'react-relay';
import { Link } from 'react-router-dom';
import { Box } from 'react-system';

import { useLocale } from '../../hooks/locale';
import { useTheme } from '../../hooks/theme';
import { GpsFixed } from '../../icons/gps-fixed';
import { AccordionCardSummary } from '../../shared/accordion-card-summary';
import {
  translateAppraisalReason,
  translateBuyHorizon,
  translateSaleHorizon,
} from '../../utils/lead-labels';

import type {
  LeadRelationship,
  leadOtherLeadsCard_lead$key,
} from './__generated__/leadOtherLeadsCard_lead.graphql';
import type { leadOtherLeadsCardQuery } from './__generated__/leadOtherLeadsCardQuery.graphql';

type Props = {
  leadId: string;
  defaultExpanded: boolean;
};

type OtherLeadsListItemProps = {
  lead: leadOtherLeadsCard_lead$key;
};

const OtherLeadsListItemLink = (props: OtherLeadsListItemProps) => {
  const { text, textColor } = useTheme();
  const { t } = useLocale();

  const data = useFragment(
    graphql`
      fragment leadOtherLeadsCard_lead on Lead {
        id
        contact {
          id
          firstName
          lastName
        }
        relationship
        appraisalReason
        saleHorizon
        buyHorizon
        stage {
          pipeline {
            leadType
          }
        }
      }
    `,
    props.lead,
  );

  const getRelationshipLabel = (value: LeadRelationship) => {
    switch (value) {
      case 'owner':
        return t('owner');
      case 'tenant':
        return t('tenant');
      case 'buyer':
        return t('buyer');
      case 'heir':
        return t('heir');
      case 'agent':
        return t('agent');
      case 'other':
        return t('other');
      case 'not_set':
        return t('notSet');
      default:
        return value;
    }
  };
  const firstName = data.contact?.firstName ?? null;
  const lastName = data.contact?.lastName ?? null;
  const fullName =
    firstName != null && lastName != null
      ? `${firstName} ${lastName}`
      : t('noName');

  const linkBase =
    data.stage?.pipeline?.leadType === 'financing' ? 'financing' : 'leads';
  const link = `/${linkBase}/${data.id}`;

  const getHorizon = () => {
    if (data.appraisalReason === 'sell' && data.relationship === 'owner') {
      return ` • ${translateSaleHorizon(t, data.saleHorizon)}`;
    }
    if (data.appraisalReason === 'buy_this' && data.relationship === 'owner') {
      return ` • ${translateBuyHorizon(t, data.buyHorizon)}`;
    }
    return '';
  };

  return (
    <ListItem button={true} component={Link} target="_blank" to={link}>
      <Avatar>{<GpsFixed />}</Avatar>
      <Box pl={3}>
        <Box css={[text.body2, text.ellipsis]}>{fullName}</Box>
        <Box css={[text.body2, textColor('mediumText')]}>
          {`
            ${getRelationshipLabel(data.relationship)} •
            ${translateAppraisalReason(t, data.appraisalReason)}
            ${getHorizon()}
          `}
        </Box>
      </Box>
    </ListItem>
  );
};

export const LeadOtherLeadsCard = (props: Props) => {
  const { t } = useLocale();

  const data = useLazyLoadQuery<leadOtherLeadsCardQuery>(
    graphql`
      query leadOtherLeadsCardQuery($leadId: ID!) {
        leadsTheSameAddress(leadId: $leadId) {
          id
          ...leadOtherLeadsCard_lead
        }
      }
    `,
    { leadId: props.leadId },
  );

  const [expanded, setExpanded] = React.useState(props.defaultExpanded);

  const leads = [];
  for (const lead of data.leadsTheSameAddress ?? []) {
    if (lead != null) {
      leads.push(lead);
    }
  }

  return (
    <Accordion
      expanded={expanded}
      onChange={(_event, expanded) => setExpanded(expanded)}
    >
      <AccordionCardSummary
        expanded={expanded}
        title={t('otherLeadsAtThisAddressCount', {
          count: leads.length,
        })}
      />
      <List>
        {leads.map(lead => (
          <OtherLeadsListItemLink lead={lead} key={lead.id} />
        ))}
      </List>
    </Accordion>
  );
};
