import * as React from 'react';

import { graphql, useMutation } from 'react-relay';

import type {
  FileBucket,
  fileUploadGenerateSignedUrlMutation,
} from './__generated__/fileUploadGenerateSignedUrlMutation.graphql';

const useMountedRef = () => {
  const ref = React.useRef(false);
  React.useEffect(() => {
    ref.current = true;
    return () => {
      ref.current = false;
    };
  }, []);
  return ref;
};

const inc = (v: number) => v + 1;
const dec = (v: number) => v - 1;

type UploadSignedFile = (
  file: File,
  signedUrl: string,
  callback: (url: null | string, error: null | Error) => void,
) => void;

export const useSignedFileUpload = (): [UploadSignedFile, boolean] => {
  const [mutating, setMutating] = React.useState(0);
  const mountedRef = useMountedRef();
  const mutate: UploadSignedFile = (file, signedUrl, callback) => {
    setMutating(inc);
    fetch(signedUrl, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type,
      },
    })
      .then(({ status, url }) => {
        if (status !== 200) {
          throw new Error('Upload file error');
        }
        if (mountedRef.current) {
          setMutating(dec);
          callback(url, null);
        }
      })
      .catch(error => {
        if (mountedRef.current) {
          setMutating(dec);
          callback(null, error);
        }
      });
  };
  return [mutate, mutating !== 0];
};

export type UploadFile = (
  file: File,
  bucket: FileBucket,
  callback?: (fileUrl: null | string, error: null | any) => void,
) => void;

const sanitizeFileNameForUpload = (fileName: string) => {
  const notAllowedCharsRE = /[^a-z0-9-_]+/gi;
  return fileName
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .replace(notAllowedCharsRE, '_');
};

export const useFileUpload = (): [UploadFile, boolean] => {
  const [generateSignedUrl, generating] =
    useMutation<fileUploadGenerateSignedUrlMutation>(
      graphql`
        mutation fileUploadGenerateSignedUrlMutation(
          $input: GenerateSignedUrlInput!
        ) {
          generateSignedUrl(input: $input) {
            url
          }
        }
      `,
    );

  const [uploadSignedFile, uploading] = useSignedFileUpload();
  const mutating = generating || uploading;
  const mutate: UploadFile = (file, bucket, callback) => {
    const match = file.name.match(/^(.+)\.([^.]{2,})$/);

    if (match == null) {
      callback?.(null, new Error('invalid_file_name_format'));
      return;
    }

    const [, baseName, fileExtension] = match;
    const fileName = `${sanitizeFileNameForUpload(
      baseName ?? '',
    )}.${fileExtension}`;

    const renamedFile = new File([file], fileName, {
      type: file.type,
      lastModified: file.lastModified,
    });

    generateSignedUrl({
      variables: {
        input: {
          bucket,
          name: renamedFile.name,
          type: renamedFile.type,
        },
      },
      onCompleted: (signedUrlPayload, error) => {
        if (signedUrlPayload.generateSignedUrl != null) {
          uploadSignedFile(
            renamedFile,
            signedUrlPayload.generateSignedUrl.url,
            (fileUrl, error) => {
              if (callback) {
                callback(fileUrl, error);
              }
            },
          );
        } else if (callback) {
          callback(null, error);
        }
      },
    });
  };
  return [mutate, mutating];
};
