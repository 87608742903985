/**
 * @generated SignedSource<<80a561551deefe4fad601aad5dbbf6a7>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
type appraisalGraph_tenantSettings$fragmentType = any;
import type { FragmentType } from "relay-runtime";
declare export opaque type appraisalDrawer_root$fragmentType: FragmentType;
export type appraisalDrawer_root$data = {|
  +tenantSettings: ?{|
    +$fragmentSpreads: appraisalGraph_tenantSettings$fragmentType,
  |},
  +$fragmentType: appraisalDrawer_root$fragmentType,
|};
export type appraisalDrawer_root$key = {
  +$data?: appraisalDrawer_root$data,
  +$fragmentSpreads: appraisalDrawer_root$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "appraisalDrawer_root",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Tenant",
      "kind": "LinkedField",
      "name": "tenantSettings",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "appraisalGraph_tenantSettings"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node/*: any*/).hash = "00574b0fe381d6396d2d81d18082693b";

export default node;
