/**
 * @generated SignedSource<<b0445682ac81473abee03cce6886ecdc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type LeadRelationship = "agent" | "buyer" | "heir" | "not_set" | "other" | "owner" | "tenant";
export type UserGender = "female" | "male";
export type UserTitle = "doctor" | "madam" | "professor" | "sir";
export type UserFilters = {
  account_managerId_in?: ReadonlyArray<string | null> | null;
  buyerLeadsCount_gte?: number | null;
  buyerLeadsCount_lte?: number | null;
  catchmentAreaPlace?: string | null;
  email?: string | null;
  emailOrPhoneNumber?: EmailOrPhoneNumber | null;
  id_in?: ReadonlyArray<string | null> | null;
  id_nin?: ReadonlyArray<string | null> | null;
  isAdmin?: boolean | null;
  isAdminOrBroker?: boolean | null;
  isBroker?: boolean | null;
  membershipType_in?: ReadonlyArray<string | null> | null;
  organisations_id_in?: ReadonlyArray<string | null> | null;
  phoneNumber?: string | null;
  propertySearchesCount_gte?: number | null;
  propertySearchesCount_lte?: number | null;
  relationship_in?: ReadonlyArray<LeadRelationship> | null;
  updatesDailyTasks?: boolean | null;
};
export type EmailOrPhoneNumber = {
  email?: string | null;
  phoneNumber?: string | null;
};
export type EmailFormRecipientQuery$variables = {
  filters?: UserFilters | null;
};
export type EmailFormRecipientQuery$data = {
  readonly users: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly firstName: string | null;
        readonly gender: UserGender | null;
        readonly id: string;
        readonly lastName: string | null;
        readonly organisation: {
          readonly formattedAddress: string | null;
          readonly id: string;
          readonly name: string | null;
        } | null;
        readonly primaryEmail: {
          readonly email: string;
          readonly id: string;
        } | null;
        readonly primaryPhoneNumber: {
          readonly formattedNumber: string;
          readonly id: string;
        } | null;
        readonly title: UserTitle | null;
      } | null;
    } | null> | null;
  } | null;
};
export type EmailFormRecipientQuery = {
  response: EmailFormRecipientQuery$data;
  variables: EmailFormRecipientQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "UserEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "gender",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Email",
            "kind": "LinkedField",
            "name": "primaryEmail",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PhoneNumber",
            "kind": "LinkedField",
            "name": "primaryPhoneNumber",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "formattedNumber",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Organisation",
            "kind": "LinkedField",
            "name": "organisation",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "formattedAddress",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 99999
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmailFormRecipientQuery",
    "selections": [
      {
        "alias": "users",
        "args": null,
        "concreteType": "UserConnection",
        "kind": "LinkedField",
        "name": "__ConnectionPrefilledRecipientData_users_connection",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmailFormRecipientQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "UserConnection",
        "kind": "LinkedField",
        "name": "users",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "filters": [],
        "handle": "connection",
        "key": "ConnectionPrefilledRecipientData_users",
        "kind": "LinkedHandle",
        "name": "users"
      }
    ]
  },
  "params": {
    "cacheID": "e2ff20d3462ee630c754a5fc98c2033f",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "users"
          ]
        }
      ]
    },
    "name": "EmailFormRecipientQuery",
    "operationKind": "query",
    "text": "query EmailFormRecipientQuery(\n  $filters: UserFilters\n) {\n  users(first: 99999, filters: $filters) {\n    edges {\n      node {\n        id\n        firstName\n        lastName\n        gender\n        primaryEmail {\n          id\n          email\n        }\n        primaryPhoneNumber {\n          id\n          formattedNumber\n        }\n        organisation {\n          id\n          name\n          formattedAddress\n        }\n        title\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "97ebcbf71ccc69ddc59542fb00887233";

export default node;
