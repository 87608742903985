/**
 * @generated SignedSource<<307df5a36a7580bdac15f23bdcf910ae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SubscriptionStatus = "active" | "canceled" | "incomplete" | "incomplete_expired" | "past_due" | "trialing" | "unpaid";
import { FragmentRefs } from "relay-runtime";
export type ActivityEventForm_lead$data = {
  readonly __typename: "Lead";
  readonly broker: {
    readonly firstName: string | null;
    readonly id: string;
    readonly lastName: string | null;
    readonly organisation: {
      readonly formattedAddress: string | null;
      readonly name: string | null;
    } | null;
    readonly primaryEmail: {
      readonly email: string;
    } | null;
    readonly primaryImage: {
      readonly url: string;
    } | null;
    readonly subscription: {
      readonly status: SubscriptionStatus;
    } | null;
  } | null;
  readonly contact: {
    readonly firstName: string | null;
    readonly id: string;
    readonly lastName: string | null;
    readonly organisation: {
      readonly formattedAddress: string | null;
      readonly name: string | null;
    } | null;
    readonly primaryEmail: {
      readonly email: string;
    } | null;
    readonly primaryImage: {
      readonly url: string;
    } | null;
    readonly subscription: {
      readonly status: SubscriptionStatus;
    } | null;
  } | null;
  readonly id: string;
  readonly property: {
    readonly formattedAddress: string | null;
  } | null;
  readonly " $fragmentType": "ActivityEventForm_lead";
};
export type ActivityEventForm_lead$key = {
  readonly " $data"?: ActivityEventForm_lead$data;
  readonly " $fragmentSpreads": FragmentRefs<"ActivityEventForm_lead">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "formattedAddress",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "firstName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lastName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Email",
    "kind": "LinkedField",
    "name": "primaryEmail",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Image",
    "kind": "LinkedField",
    "name": "primaryImage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Organisation",
    "kind": "LinkedField",
    "name": "organisation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      (v1/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Subscription",
    "kind": "LinkedField",
    "name": "subscription",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActivityEventForm_lead",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Property",
      "kind": "LinkedField",
      "name": "property",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "contact",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "broker",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    }
  ],
  "type": "Lead",
  "abstractKey": null
};
})();

(node as any).hash = "96cf006c54e0f6d26badd92391ffe0ef";

export default node;
