import { Box } from 'react-system';

import { Checkbox } from '../controls/checkbox';
import { Filter } from '../controls/Filters/Filter';
import { useLocale } from '../hooks/locale';

import type { LeadParamsType } from './lead-types';

type Props = {
  params: LeadParamsType[0];
  setParams: LeadParamsType[1];
};

export const CompletedFilter = (props: Props) => {
  const { t } = useLocale();
  const { params, setParams } = props;
  const completed_in = params.completed_in ?? [];
  let label = t('completion');
  if (completed_in.length === 1) {
    if (completed_in.includes(true)) {
      label = t('completed');
    }
    if (completed_in.includes(false)) {
      label = t('notCompleted');
    }
  }
  const makeToggle = (value: boolean) => (checked: boolean) => {
    setParams({
      completed_in: checked
        ? [...completed_in, value]
        : completed_in.filter(d => d !== value),
    });
  };
  return (
    <Filter
      label={label}
      dialogTitle={t('completed')}
      empty={completed_in.length === 0}
      onReset={() => setParams({ completed_in: null })}
    >
      <Box px={3} py={2}>
        <Checkbox
          color="primary"
          checked={completed_in.includes(true)}
          onChange={makeToggle(true)}
        >
          {t('completed')}
        </Checkbox>
        <Checkbox
          color="primary"
          checked={completed_in.includes(false)}
          onChange={makeToggle(false)}
        >
          {t('notCompleted')}
        </Checkbox>
      </Box>
    </Filter>
  );
};
