/**
 * @generated SignedSource<<1fc46ce5f37f4fa7fffa91a43c8c2ce7>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type previewImage_image$fragmentType: FragmentType;
export type previewImage_image$data = {|
  +url: string,
  +$fragmentType: previewImage_image$fragmentType,
|};
export type previewImage_image$key = {
  +$data?: previewImage_image$data,
  +$fragmentSpreads: previewImage_image$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "previewImage_image",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "type": "Image",
  "abstractKey": null
};

(node/*: any*/).hash = "cc4f2d0db5d4c18dafb555e12061f9c7";

export default node;
