// Generated from src/svgs/nav-brokerage-active.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const NavBrokerageActive = ({ size, fill, className }: Props) => {
  return (
    <svg viewBox="0 0 25 25" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><path d="M20.716 12.324a4.16 4.16 0 0 1-2.77-1.066 4.06 4.06 0 0 1-2.755 1.066 4.116 4.116 0 0 1-2.745-1.065 4.064 4.064 0 0 1-2.755 1.065 4.115 4.115 0 0 1-2.75-1.07 4.161 4.161 0 0 1-2.775 1.07c-.462 0-1.334-.083-1.334-.226v9.527A1.375 1.375 0 0 0 4.207 23h1.375v-7.333a.917.917 0 0 1 .917-.917h4.583a.917.917 0 0 1 .917.917V23h8.708a1.375 1.375 0 0 0 1.375-1.375v-9.53c0 .148-.889.229-1.366.229Zm-2.759 8.843h-2.75a.458.458 0 1 1 0-.917h2.75a.459.459 0 0 1 0 .917Zm.459-3.667a.917.917 0 0 1-.917.917h-1.833a.917.917 0 0 1-.917-.917v-1.833a.917.917 0 0 1 .916-.917H17.5a.917.917 0 0 1 .917.917V17.5Zm2.416-14.667H4.942l.403-1.206A.917.917 0 0 1 6.215 1H19.56a.917.917 0 0 1 .87.627l.402 1.206Zm3.028 6.118a3.234 3.234 0 0 1-5.919.874 3.181 3.181 0 0 1-5.5 0 3.181 3.181 0 0 1-5.5 0 3.235 3.235 0 0 1-5.807-.507 2.243 2.243 0 0 1 .177-1.901L4.148 3.75h17.367l2.206 3.822c.195.432.244.916.14 1.379Z" fill="#fff" /></svg>
  );
}
