import * as React from 'react';

import { Checkbox } from '@material-ui/core';
import { useConstant } from '@realadvisor/hooks';
import { Image } from '@realadvisor/image';
import { useResizeRect } from '@realadvisor/observe';
import { graphql, useLazyLoadQuery, usePaginationFragment } from 'react-relay';
import { Box, Flex } from 'react-system';
import { FixedSizeList, type ListChildComponentProps } from 'react-window';

import { Image as ImageIcon } from '../icons/image';
import { RelayHasuraWrapper } from '../networking';
import { Filter, SearchField } from '../src/controls/Filters';
import { useLocale } from '../src/hooks/locale';
import { useWindowPagination } from '../src/hooks/relay';
import { useTheme } from '../src/hooks/theme';
import {
  type Field,
  makeField,
  makeUrlSearchParamsHook,
} from '../src/hooks/url-search-params';

import type {
  organisationFilter_root$data,
  organisationFilter_root$key,
} from './__generated__/organisationFilter_root.graphql';
import type { organisationFilterPaginationQuery } from './__generated__/organisationFilterPaginationQuery.graphql';
import organisationFilterPaginationQueryNode from './__generated__/organisationFilterPaginationQuery.graphql';
import type { organisationFilterQuery } from './__generated__/organisationFilterQuery.graphql';
import {
  fromArrayToStringHasura,
  fromGlobalId,
  fromHasuraGlobalId,
  toGlobalId,
  toHasuraGlobalId,
} from './global-id';

export type OrganisationParams = {
  organisationId_in: Field<string[] | null>;
};

export const organisationParams: OrganisationParams = {
  organisationId_in: makeField({
    get: params => {
      const list = params.getAllStrings('organisationId_in');
      return list.length === 0 ? null : list;
    },
    set: (params, value) =>
      params.setAllStrings('organisationId_in', value || []),
  }),
};

type OrganisationsNode =
  organisationFilter_root$data['organisations_search_connection']['edges'][number]['node'];

type WindowNode = {
  selectedIds: Array<null | string>;
  nodes: OrganisationsNode[];
  onRowClick: (node: OrganisationsNode) => void;
};

const OrganisationItem = ({
  style,
  index,
  data,
}: ListChildComponentProps<WindowNode>) => {
  const { colors } = useTheme();
  const node = data.nodes[index];
  const imageUrl = node.organisation_images[0]?.image.url;

  return (
    <Flex
      style={style}
      alignItems="center"
      px={1}
      css={{ cursor: 'pointer' }}
      onClick={() => data.onRowClick(node)}
    >
      <Checkbox color="primary" checked={data.selectedIds.includes(node.id)} />
      <Flex width="40px" height="40px" flexShrink={0} mr={2}>
        {imageUrl == null ? (
          <ImageIcon size={40} fill={colors.grey400} />
        ) : (
          <Image objectFit="contain" src={imageUrl} />
        )}
      </Flex>
      <Box>{node.name}</Box>
    </Flex>
  );
};

const useOrganisationParams =
  makeUrlSearchParamsHook<OrganisationParams>(organisationParams);

const OrganisationFilterList = ({
  nodes,
  selectedIds,
  setValue,
  loadUntil,
}: {
  nodes: OrganisationsNode[];
  selectedIds: string[];
  setValue: (node: OrganisationsNode) => void;
  loadUntil: (index: number) => void;
}) => {
  const containerRef = React.useRef(null);
  const containerRect = useResizeRect(containerRef);

  return (
    <Box flexGrow={1} ref={containerRef}>
      {containerRect && (
        <FixedSizeList
          width={containerRect.width}
          height={containerRect.height}
          itemSize={64}
          itemCount={nodes.length}
          itemData={{ selectedIds, nodes, onRowClick: setValue }}
          overscanCount={20}
          onItemsRendered={({ overscanStopIndex }) =>
            loadUntil(overscanStopIndex)
          }
        >
          {OrganisationItem}
        </FixedSizeList>
      )}
    </Box>
  );
};

const OrganisationFilterContent = ({
  selectedIds,
  setValue,
  isLender,
}: {
  selectedIds: string[];
  setValue: (node: OrganisationsNode) => void;
  isLender?: boolean;
}) => {
  const order_by_first_ids = fromArrayToStringHasura(
    selectedIds.map(id => fromHasuraGlobalId(id)),
  );
  const initialVariables = useConstant(() => ({
    count: 40,
    order_by_first_ids,
    is_lender: isLender == null ? {} : { _eq: isLender },
  }));
  const queryData = useLazyLoadQuery<organisationFilterQuery>(
    graphql`
      query organisationFilterQuery(
        $count: Int!
        $order_by_first_ids: String!
        $is_lender: Boolean_comparison_exp!
      ) {
        ...organisationFilter_root
          @arguments(
            count: $count
            order_by_first_ids: $order_by_first_ids
            search: ""
            is_lender: $is_lender
          )
      }
    `,
    initialVariables,
  );

  const { data, refetch, loadUntil } = useWindowPagination(
    usePaginationFragment<
      organisationFilterPaginationQuery,
      organisationFilter_root$key
    >(
      graphql`
        fragment organisationFilter_root on query_root
        @refetchable(queryName: "organisationFilterPaginationQuery")
        @argumentDefinitions(
          count: { type: "Int!" }
          cursor: { type: "String", defaultValue: null }
          order_by_first_ids: { type: "String", defaultValue: "{}" }
          search: { type: "String!" }
          is_lender: { type: "Boolean_comparison_exp!" }
        ) {
          organisations_search_connection(
            first: $count
            after: $cursor
            args: { order_by_first_ids: $order_by_first_ids, search: $search }
            where: { is_lender: $is_lender }
          )
            @connection(
              key: "organisationFilter__organisations_search_connection"
            ) {
            edges {
              node {
                id
                name
                organisation_images(order_by: { is_primary: desc }) {
                  image {
                    url
                  }
                }
              }
            }
          }
        }
      `,
      queryData,
    ),
    data => data.organisations_search_connection.edges.length,
    organisationFilterPaginationQueryNode,
  );

  const [input, setInput] = React.useState('');

  return (
    <>
      <SearchField
        value={input}
        onChange={value => {
          setInput(value);
          refetch({
            count: 40,
            search: value,
            is_lender: isLender == null ? {} : { _eq: isLender },
          });
        }}
      />
      <OrganisationFilterList
        nodes={data.organisations_search_connection.edges.map(
          edge => edge.node,
        )}
        selectedIds={selectedIds}
        setValue={setValue}
        loadUntil={loadUntil}
      />
    </>
  );
};

export const OrganisationFilter = (props: { isLender?: boolean }) => {
  const { t } = useLocale();
  const [params, setParams] = useOrganisationParams();
  const namesCache = useConstant(() => new Map<string, string>());
  const selectedIds =
    params.organisationId_in?.map(id =>
      toHasuraGlobalId('organisations', fromGlobalId(id)),
    ) ?? [];
  const setValue = (node: OrganisationsNode) => {
    const newSelectedIds = new Set(selectedIds);
    if (newSelectedIds.has(node.id)) {
      newSelectedIds.delete(node.id);
    } else {
      newSelectedIds.add(node.id);
    }
    if (node.name != null) {
      namesCache.set(node.id, node.name);
    }
    setParams({
      organisationId_in: Array.from(newSelectedIds).map(id =>
        toGlobalId('Organisation', fromHasuraGlobalId(id)),
      ),
    });
  };
  let label;
  const count = selectedIds.length;
  const first = selectedIds
    .map(id => namesCache.get(id))
    .find(name => name != null);
  if (count === 0) {
    label = t('organisations');
  } else if (first != null) {
    label = count > 1 ? `${first} +${count - 1}` : first;
  } else {
    label = t('organisationsSelected', { count });
  }
  return (
    <Filter
      label={label}
      dialogTitle={t('organisations')}
      empty={count === 0}
      onReset={() => setParams({ organisationId_in: [] })}
    >
      <Flex
        flexDirection="column"
        width="300px"
        height="calc(100vh - 300px)"
        css={{ minHeight: 300 }}
      >
        <React.Suspense fallback={<SearchField value="" onChange={() => {}} />}>
          <RelayHasuraWrapper>
            <OrganisationFilterContent
              selectedIds={selectedIds}
              setValue={setValue}
              isLender={props.isLender}
            />
          </RelayHasuraWrapper>
        </React.Suspense>
      </Flex>
    </Filter>
  );
};
