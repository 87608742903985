import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

import { Filter } from '../../../../../src/controls/Filters/Filter';
import { useLocale } from '../../../../../src/hooks/locale';
import type { Merged_Listings_Compare_Args } from '../../../../__generated__/graphql';
import {
  type CompositePropertyType,
  getFiltersFromComposedPropertyType,
} from '../../../../utils/propertyTypes';

const CompositePropertyTypeFilter = ({
  setFilters,
  composeValue,
  setComposeValue,
}: {
  setFilters: (filters: Partial<Merged_Listings_Compare_Args>) => void;
  composeValue: CompositePropertyType;
  setComposeValue: (value: CompositePropertyType) => void;
}) => {
  const { t, countryCode } = useLocale();

  // @ts-ignore requires all cases to be specified
  const labels: Record<CompositePropertyType, string> = {
    HOUSE_APPT: t('houseOrApartmentCapital'),
    HOUSE: t('house'),
    APPT: t('apartment'),
  };

  if (countryCode === 'CH') {
    labels.COMMERCIAL = t('commercial');
    labels.BUILDING = t('building');
  }

  return (
    <Filter
      label={composeValue != null ? labels[composeValue] : t('anyType')}
      dialogTitle={t('propertyType')}
    >
      <Box width="280px" p={2}>
        <FormControl>
          <RadioGroup
            value={composeValue}
            onChange={evt => {
              const newComposeValue = evt.target.value as CompositePropertyType;
              setComposeValue(newComposeValue);
              const filter =
                getFiltersFromComposedPropertyType(newComposeValue);
              setFilters(filter);
            }}
          >
            {Object.entries(labels).map(([type, label]) => (
              <FormControlLabel
                key={type}
                value={type}
                label={typeof label === 'string' ? label : ''}
                control={<Radio color="primary" />}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
    </Filter>
  );
};

export default CompositePropertyTypeFilter;
