/**
 * @generated SignedSource<<caac0aadf25dde5e7f5b39f8ce8bc0ad>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
export type LotCurrency = "CHF" | "EUR" | "GBP";
import type { FragmentType } from "relay-runtime";
declare export opaque type lotSnippet_lot$fragmentType: FragmentType;
export type lotSnippet_lot$data = {|
  +broker: ?{|
    +firstName: ?string,
    +lastName: ?string,
    +primaryImage: ?{|
      +url: string,
    |},
  |},
  +currency: ?LotCurrency,
  +enquiries: ?$ReadOnlyArray<{|
    +id: string,
  |}>,
  +id: string,
  +matchingBuyerLeads: $ReadOnlyArray<{|
    +id: string,
  |}>,
  +offerType: ?string,
  +primaryPropertyImage: ?{|
    +image: {|
      +url: string,
    |},
  |},
  +property: {|
    +locality: ?string,
    +postcode: ?string,
    +route: ?string,
    +streetNumber: ?string,
  |},
  +rentExtra: ?number,
  +rentNet: ?number,
  +salePrice: ?number,
  +seller: ?{|
    +firstName: ?string,
    +lastName: ?string,
    +primaryImage: ?{|
      +url: string,
    |},
  |},
  +$fragmentType: lotSnippet_lot$fragmentType,
|};
export type lotSnippet_lot$key = {
  +$data?: lotSnippet_lot$data,
  +$fragmentSpreads: lotSnippet_lot$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "firstName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lastName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Image",
    "kind": "LinkedField",
    "name": "primaryImage",
    "plural": false,
    "selections": (v1/*: any*/),
    "storageKey": null
  }
],
v3 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "lotSnippet_lot",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "PropertyImage",
      "kind": "LinkedField",
      "name": "primaryPropertyImage",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Image",
          "kind": "LinkedField",
          "name": "image",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Property",
      "kind": "LinkedField",
      "name": "property",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "route",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "streetNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "postcode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "locality",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "salePrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rentNet",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rentExtra",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "offerType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "seller",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "broker",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Enquiry",
      "kind": "LinkedField",
      "name": "enquiries",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BuyerLead",
      "kind": "LinkedField",
      "name": "matchingBuyerLeads",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Lot",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "34f477cef968d6afc2593024671ca496";

export default node;
