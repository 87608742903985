import { useCallback } from 'react';

import { useFragment as useApolloFragment } from '@apollo/client';
import { Box, Skeleton, Stack } from '@mui/material';

import { useDebouncedHandler } from '../../../src/hooks/debounce';
import { useLocale } from '../../../src/hooks/locale';
import { getCurrencyByCountryCode } from '../../../src/locale';
import {
  type FormDefinitionType,
  RaForm,
  type RaFormOnChange,
} from '../../components/form/RaForm';

import { STEP_FINAL_FRAGMENT } from './cmaReportsQueries';
import {
  type CMAReportComponentProps,
  FooterActions,
  useUpdateCmaReport,
} from './shared';

type StepFinalForm = {
  positive_comments: string;
  negative_comments: string;
  suggested_market_value?: number | null;
};

const StepFinal = (props: CMAReportComponentProps) => {
  const { cmaReportId } = props;

  const { data, complete } = useApolloFragment({
    fragment: STEP_FINAL_FRAGMENT,
    fragmentName: 'StepFinal',
    from: {
      __typename: 'cma_reports',
      id: cmaReportId,
    },
  });
  const [updateCmaReport, updating] = useUpdateCmaReport(
    cmaReportId,
    'page-final',
  );
  const { countryCode } = useLocale();
  const stepFinalFormDefinition: FormDefinitionType<StepFinalForm> =
    useCallback(
      ({ t }) => [
        {
          type: 'category-title',
          name: 'positives',
          label: t('positives'),
          gridProps: { md: 12 },
        },
        {
          name: 'positive_comments',
          label: t('positives'),
          type: 'rich-text',
          gridProps: { md: 12 },
        },
        {
          type: 'category-title',
          name: 'negatives',
          label: t('negatives'),
          gridProps: { md: 12 },
        },
        {
          name: 'negative_comments',
          label: t('negatives'),
          type: 'rich-text',
          gridProps: { md: 12 },
        },
        {
          name: 'suggested_market_value',
          prefix: getCurrencyByCountryCode(countryCode),
          label: t('suggestedMarketValue'),
          type: 'number',

          gridProps: { md: 12 },
        },
      ],
      [countryCode],
    );

  const update = useCallback(
    async (formData?: Partial<StepFinalForm>) => {
      await updateCmaReport({
        positive_comments: formData?.positive_comments,
        negative_comments: formData?.negative_comments,
        suggested_market_value: formData?.suggested_market_value,
      });
    },
    [updateCmaReport],
  );
  const debouncedUpdate = useDebouncedHandler(300, update);

  const onChangeHandler: RaFormOnChange<StepFinalForm> = formData => {
    debouncedUpdate(formData);
  };

  const onSubmit = async (formData: StepFinalForm) => update(formData);

  return (
    <Box
      flexGrow={1}
      position={'relative'}
      sx={{ overflowY: 'auto', p: !complete ? 2 : undefined }}
    >
      {!complete ? (
        <Stack gap={2}>
          <Stack gap={2}>
            {Array.from({ length: 2 }).map((_, index) => (
              <Skeleton
                variant="rounded"
                key={`feedback-${index}`}
                height={150}
              />
            ))}
          </Stack>
          <Stack gap={1}>
            <Skeleton variant="rounded" height={40} width={'40%'} />
            <Skeleton variant="rounded" height={40} />
          </Stack>
        </Stack>
      ) : (
        <RaForm
          freezeInitialDefaultValues={true}
          formDefinition={stepFinalFormDefinition}
          onSubmit={() => Promise.resolve()}
          contentScrollable
          defaultValues={{
            positive_comments: data?.positive_comments ?? '',
            negative_comments: data?.negative_comments ?? '',
            suggested_market_value: data?.suggested_market_value,
          }}
          onChange={onChangeHandler}
          actionButtonsComponent={
            <FooterActions<StepFinalForm>
              {...props}
              updating={updating}
              onSubmit={onSubmit}
            />
          }
        />
      )}
    </Box>
  );
};

export default StepFinal;
