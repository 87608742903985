import * as React from 'react';

export const Subscription = ({
  value,
  onChange,
  callOnInitial = false,
}: {
  value: unknown;
  onChange: () => void;
  callOnInitial?: boolean;
}) => {
  const first = React.useRef(!callOnInitial);
  const serialized = JSON.stringify(value);

  React.useEffect(() => {
    // skip initial value
    if (first.current === false) {
      onChange();
    }
    first.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serialized]);

  return null;
};
