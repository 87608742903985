export type AddressCellProps = {
  address: {
    street_number: string;
    route: string;
    postcode: string;
    locality: string;
  };
};

export const AddressCell = ({
  address: { street_number, route, postcode, locality },
}: AddressCellProps) => (
  <div>
    <div>
      {route} {street_number}
    </div>
    <div>
      {postcode} {locality}
    </div>
  </div>
);
