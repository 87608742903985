import * as React from 'react';

import { CardActions, CardContent } from '@material-ui/core';
import { Button } from '@mui/material';
import { endOfDay, isBefore, parseISO } from 'date-fns';
import { graphql, useFragment } from 'react-relay';

import { Markdown } from '../../controls/markdown';
import { useLocale } from '../../hooks/locale';
import { useTheme } from '../../hooks/theme';
import { NoteOutline } from '../../icons/note-outline';
import { Notes } from '../../icons/notes';

import type { FeedTask_activity$key } from './__generated__/FeedTask_activity.graphql';
import type { FeedTask_root$key } from './__generated__/FeedTask_root.graphql';
import { ActivityWorkflowDialog } from './activity-workflow-dialog';
import {
  type FeedFormProps,
  workflowButtonClickWrapper,
} from './feed-form-utils';
import { FeedOverdue } from './FeedOverdue';

export type FeedTaskProps = FeedFormProps<
  FeedTask_root$key,
  FeedTask_activity$key
>;

export const FeedTask = (props: FeedTaskProps) => {
  const root = useFragment(
    graphql`
      fragment FeedTask_root on Query {
        ...activityWorkflowDialog_root
      }
    `,
    props.root,
  );
  const activity = useFragment(
    graphql`
      fragment FeedTask_activity on Activity {
        ...activityWorkflowDialog_activity
        id
        ... on ActivityTask {
          canEdit
          note
          subject
          dueAt
          startDate
          done
          enquiry {
            __typename
            id
          }
          lot {
            __typename
            id
          }
          lead {
            __typename
            id
            stage {
              pipeline {
                leadType
              }
            }
          }
          user {
            __typename
            id
          }
          buyerLead {
            __typename
            id
          }
        }
      }
    `,
    props.activity,
  );
  const { t } = useLocale();
  const { text } = useTheme();
  const [dialog, setDialog] = React.useState<
    'cancelled' | 'completed' | 'none'
  >('none');

  const parent =
    activity.enquiry ??
    activity.lot ??
    activity.lead ??
    activity.user ??
    activity.buyerLead;
  const parentType = parent?.__typename;
  const parentId = parent?.id;
  const startDate =
    activity.startDate != null ? parseISO(activity.startDate) : null;
  const dueAt =
    activity.dueAt != null
      ? parseISO(activity.dueAt)
      : startDate != null
      ? endOfDay(startDate)
      : null;
  const isOverdue =
    activity.done === true
      ? false
      : startDate != null
      ? isBefore(endOfDay(startDate), new Date())
      : dueAt != null
      ? isBefore(dueAt, new Date())
      : null;

  const workflowButtonClick = workflowButtonClickWrapper(props, setDialog);

  const DIALOG_CONFIG = {
    completed: 'completed_task',
    cancelled: 'cancelled_task',
  } as const;

  const shouldDisplayDialog = dialog !== 'none' && dialog in DIALOG_CONFIG;

  return (
    <>
      <CardContent
        css={{
          paddingTop: 0,
          display: 'grid',
          gridGap: 12,
          gridTemplateColumns: '24px 1fr',
          wordBreak: 'break-word',
        }}
      >
        {activity.subject != null && (
          <>
            <Notes />
            <div css={text.body2}>{activity.subject}</div>
          </>
        )}
        {dueAt != null && (
          <FeedOverdue
            startDate={startDate}
            isOverdue={isOverdue}
            dueAt={dueAt}
          />
        )}
        {activity.note != null && activity.note.length > 0 && (
          <>
            <NoteOutline />
            <div css={text.body2}>
              <Markdown>{activity.note}</Markdown>
            </div>
          </>
        )}
      </CardContent>
      {activity.canEdit === true &&
        activity.done !== true &&
        props.indirect === false && (
          <CardActions css={{ justifyContent: 'flex-end', padding: 16 }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => workflowButtonClick('cancelled')}
            >
              {t('cancel')}
            </Button>

            <Button
              variant="contained"
              color="success"
              onClick={() => workflowButtonClick('completed')}
            >
              {t('completed')}
            </Button>
          </CardActions>
        )}

      {shouldDisplayDialog && (
        <ActivityWorkflowDialog
          root={root}
          activity={activity}
          initialStep={DIALOG_CONFIG[dialog]}
          parentId={parentId ?? null}
          parentType={parentType ?? null}
          leadType={activity.lead?.stage?.pipeline?.leadType}
          onClose={() => {
            props.setOpenDialog(false);
            props.setActiveDialogType(null);
            setDialog('none');
          }}
          onNewActivity={() => {
            props.setOpenDialog(false);
            props.setActiveDialogType(null);
            setDialog('none');
            props.onCreate();
          }}
          open={true}
          openDialog={props.openDialog}
          setOpenDialog={props.setOpenDialog}
          showUnfinishedWarning={props.showUnfinishedWarning}
          setShowUnfinishedWarning={props.setShowUnfinishedWarning}
        />
      )}
    </>
  );
};
