// @flow

import * as React from 'react';

import { useConstant } from '@realadvisor/hooks';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { Flex, useSystem } from 'react-system';

import { Filter } from '../controls/Filters';
import { useLocale } from '../hooks/locale';
import {
  type Field,
  makeField,
  makeUrlSearchParamsHook,
} from '../hooks/url-search-params';

import type { placeFilterQuery } from './__generated__/placeFilterQuery.graphql';
import { PlacePicker } from './place-picker';

export type PlaceParams = {|
  placeId_in: Field<$ReadOnlyArray<string> | null>,
|};

export const placeParams: PlaceParams = {
  placeId_in: makeField({
    get: params => {
      // extract place id from legacy search params
      // TODO It's november 2020 now. Remove maybe in the middle of 2021.
      const legacyList = params
        .getAllStrings('place_in')
        .map(x => {
          try {
            const { objectId } = JSON.parse(x);
            return btoa(`Place:${objectId}`);
          } catch (e) {
            return null;
          }
        })
        .filter(Boolean);
      const list = params.getAllStrings('placeId_in');
      if (legacyList.length !== 0) {
        return legacyList;
      }
      return list.length === 0 ? null : list;
    },
    set: (params, value) => {
      params.setAllStrings('place_in', []);
      params.setAllStrings('placeId_in', value ?? []);
    },
  }),
};

const usePlaceParams = makeUrlSearchParamsHook(placeParams);

const PlaceFilterContent = ({ placeId_in, onChange }) => {
  const initialPlaceIds = useConstant(() => placeId_in);
  const [places, setPlaces] = React.useState(null);
  const data = useLazyLoadQuery<placeFilterQuery>(
    graphql`
      query placeFilterQuery($placeId_in: [ID!]) {
        tenantSettings {
          defaultMapCoordinates {
            lng
            lat
            z
          }
        }
        places(id_in: $placeId_in, limit: 100, all: true) {
          ...placePicker_place @relay(mask: false)
        }
      }
    `,
    { placeId_in: initialPlaceIds },
  );

  // for flow
  if (data.tenantSettings == null) {
    return null;
  }

  const { z, lat, lng } = data.tenantSettings.defaultMapCoordinates;

  return (
    <PlacePicker
      value={places ?? data.places}
      onChange={newPlaces => {
        setPlaces(newPlaces);
        onChange(newPlaces);
      }}
      initialCenter={{ lat, lng }}
      initialZoom={z}
    />
  );
};

export const PlaceFilter = (): React.Node => {
  const { t } = useLocale();
  const { media } = useSystem();
  const [params, setParams] = usePlaceParams();
  const placeId_in = params.placeId_in ?? [];
  const labelsCache = useConstant(() => new Map());
  const getLabel = items => {
    if (items.length === 0) {
      return t('places');
    }
    const labels = items.flatMap(id => {
      const label = labelsCache.get(id);
      return label == null ? [] : [label];
    });
    if (labels.length === 0) {
      return t('placesCount', { count: items.length });
    }
    if (items.length === 1) {
      return labels[0];
    }
    return `${labels[0]} +${items.length - 1}`;
  };
  return (
    <Filter
      label={getLabel(placeId_in)}
      dialogTitle={t('places')}
      empty={placeId_in.length === 0}
      onReset={() => setParams({ placeId_in: [] })}
    >
      <Flex
        flexDirection="column"
        pt={2}
        css={media({
          width: ['100vw', 'calc(100vw - 64px)'],
          maxWidth: '100%',
          height: 'calc(100vh - 128px)',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
        })}
      >
        <React.Suspense fallback={null}>
          <PlaceFilterContent
            placeId_in={placeId_in}
            onChange={newPlaces => {
              const newIds = [];
              for (const place of newPlaces) {
                newIds.push(place.id);
                labelsCache.set(place.id, place.label);
              }
              setParams({ placeId_in: newIds });
            }}
          />
        </React.Suspense>
      </Flex>
    </Filter>
  );
};
