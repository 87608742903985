/**
 * @generated SignedSource<<30e1da7b9fad5986b0426439cecfb908>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RefuseLeadInput = {
  clientMutationId?: string | null;
  id?: string | null;
  note?: string | null;
};
export type FeedAssignmentRefuseLeadMutation$variables = {
  input: RefuseLeadInput;
};
export type FeedAssignmentRefuseLeadMutation$data = {
  readonly refuseLead: {
    readonly lead: {
      readonly broker: {
        readonly id: string;
      } | null;
      readonly hasOpenActivityForMe: boolean;
      readonly id: string;
      readonly stage: {
        readonly id: string;
        readonly orderNumber: number;
        readonly pipeline: {
          readonly id: string;
          readonly name: string | null;
          readonly stages: ReadonlyArray<{
            readonly id: string;
            readonly label: string;
            readonly name: string | null;
            readonly orderNumber: number;
          }>;
        } | null;
      } | null;
      readonly userCanViewLeadDetails: boolean;
    } | null;
  } | null;
};
export type FeedAssignmentRefuseLeadMutation = {
  response: FeedAssignmentRefuseLeadMutation$data;
  variables: FeedAssignmentRefuseLeadMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "orderNumber",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RefuseLeadPayload",
    "kind": "LinkedField",
    "name": "refuseLead",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lead",
        "kind": "LinkedField",
        "name": "lead",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "broker",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "userCanViewLeadDetails",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasOpenActivityForMe",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "LeadStage",
            "kind": "LinkedField",
            "name": "stage",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Pipeline",
                "kind": "LinkedField",
                "name": "pipeline",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LeadStage",
                    "kind": "LinkedField",
                    "name": "stages",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v1/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "label",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FeedAssignmentRefuseLeadMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FeedAssignmentRefuseLeadMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "526d1d51bde2dd942255df659a9acaec",
    "id": null,
    "metadata": {},
    "name": "FeedAssignmentRefuseLeadMutation",
    "operationKind": "mutation",
    "text": "mutation FeedAssignmentRefuseLeadMutation(\n  $input: RefuseLeadInput!\n) {\n  refuseLead(input: $input) {\n    lead {\n      id\n      broker {\n        id\n      }\n      userCanViewLeadDetails\n      hasOpenActivityForMe\n      stage {\n        id\n        orderNumber\n        pipeline {\n          id\n          name\n          stages {\n            orderNumber\n            id\n            name\n            label\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e0326827f05b1bc1a816de2d66b2efc2";

export default node;
