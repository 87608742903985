// Generated from src/svgs/nav-users.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const NavUsers = ({ size, fill, className }: Props) => {
  return (
    <svg viewBox="0 0 25 25" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><path d="M23.125 19.02c-1.63-.854-5.123-2.651-7.1-3.473a10.58 10.58 0 0 0 2.499-6.992C18.524 4.03 15.827 2 12.5 2S6.476 4.03 6.476 8.555A10.583 10.583 0 0 0 8.97 15.54c-1.987.817-5.473 2.621-7.098 3.478A1.633 1.633 0 0 0 1 20.465v.782a1.739 1.739 0 0 0 1.503 1.73c3.312.491 6.65.783 9.997.873a83.423 83.423 0 0 0 9.997-.873A1.747 1.747 0 0 0 24 21.247v-.78a1.628 1.628 0 0 0-.875-1.448ZM7.571 8.554c0-3.471 1.797-5.463 4.929-5.463 3.132 0 4.929 1.992 4.929 5.463 0 4.002-2.35 7.648-4.929 7.648-2.58 0-4.929-3.646-4.929-7.648Zm15.334 12.692a.645.645 0 0 1-.559.648c-3.262.485-6.55.773-9.846.863a82.135 82.135 0 0 1-9.846-.864.648.648 0 0 1-.559-.647v-.782a.539.539 0 0 1 .287-.481c3.96-2.088 6.46-3.257 7.476-3.637a4.41 4.41 0 0 0 2.642.948 4.403 4.403 0 0 0 2.626-.936c1.061.399 3.552 1.567 7.49 3.627a.541.541 0 0 1 .289.481v.78Z" fill="#fff" /></svg>
  );
}
