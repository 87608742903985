import type React from 'react';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { AppBar, Box, Tab, Tabs, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Link, type To } from 'react-router-dom';

import { useLocale } from '../../../src/hooks/locale';
import { ActionsMenu } from '../actions-menu/ActionsMenu';
import type { Actions } from '../actions-menu/ActionsMenu';

interface DrawerTopBarProps {
  title?: React.ReactNode;
  actions?: Actions[];
  onClose?: () => void;
  tabs?: DrawerTab[];
  currentTab?: string;
}

export type DrawerTab = {
  label: string;
  icon?: React.ReactElement;
  value: string;
  disabled?: boolean;
  onClick?: () => void;
  to: To;
};

export const generateTab = ([search, label, value, icon, disabled]: [
  search: string,
  label: string,
  value: string,
  icon?: React.ReactElement,
  disabled?: boolean,
]): DrawerTab => ({
  label,
  value,
  icon,
  to: {
    pathname: value,
    search,
  },
  disabled,
});

export const DrawerTopBar = ({
  title,
  actions,
  onClose,
  tabs,
  currentTab,
}: DrawerTopBarProps) => {
  const { t } = useLocale();
  return (
    <AppBar
      position="sticky"
      sx={theme => ({
        top: 0,
        bottom: 'auto',
        zIndex: theme.zIndex.drawer + 1,
      })}
      elevation={1}
      color="white"
    >
      <Grid container>
        <Grid item xs={8} sx={{ display: 'flex', alignItems: 'center', pl: 2 }}>
          <ArrowBackIosIcon sx={{ cursor: 'pointer' }} onClick={onClose} />
          <Typography variant="h6" sx={{ flexGrow: 1, px: 1, py: 2 }} noWrap>
            {title}
          </Typography>
        </Grid>
        {actions && (
          <Grid item xs={4}>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              sx={{ height: '100%', p: 1, pr: 2 }}
            >
              <ActionsMenu
                label={t('Actions')}
                actions={actions}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>
      {tabs && (
        <Tabs
          value={currentTab ?? false}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            pl: 2,
            '.MuiTabs-scrollButtons.Mui-disabled': {
              opacity: 0.3,
            },
          }}
        >
          {tabs.map(tab => (
            <Tab
              key={tab.value}
              value={tab.value}
              label={tab.label}
              icon={tab.icon}
              iconPosition="start"
              disabled={tab.disabled}
              onClick={tab.onClick}
              component={Link}
              to={tab.to}
              sx={{
                minHeight: '48px',
                /* SVG icon styles */
                '.MuiSvgIcon-root': {
                  fontSize: 18,
                },
                /* Ripple styles */
                '.MuiTouchRipple-root': {
                  borderRadius: '7px',
                  mt: '6px',
                  mb: '7px',
                  mx: '1px',
                },
                /* Hover styles */
                '&:hover .MuiTouchRipple-root': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            />
          ))}
        </Tabs>
      )}
    </AppBar>
  );
};
