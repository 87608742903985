// Generated from src/svgs/gps_fixed.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const GpsFixed = ({ size, fill, className }: Props) => {
  return (
    <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><path d="M0 0h24v24H0z" fill="none" /><path d="M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3A8.994 8.994 0 0 0 13 3.06V1h-2v2.06A8.994 8.994 0 0 0 3.06 11H1v2h2.06A8.994 8.994 0 0 0 11 20.94V23h2v-2.06A8.994 8.994 0 0 0 20.94 13H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z" /></svg>
  );
}
