import PersonIcon from '@mui/icons-material/Person';
import { Avatar, Chip, useTheme } from '@mui/material';

import { gql } from '../__generated__';
import { type UserChipFragment } from '../__generated__/graphql';

export const USER_CHIP_FRAGMENT = gql(`
  fragment UserChip on users {
    first_name
    last_name
    user_images(order_by: { is_primary: desc, order_nr: asc }) {
      image {
        url
      }
    }
  }
`);

export const UserChip: React.FC<{ user: UserChipFragment }> = ({ user }) => {
  const { text } = useTheme();

  const firstName =
    user?.first_name == null
      ? null
      : `${user.first_name.trim().charAt(0).toUpperCase()}.`;

  const imageUrl = user?.user_images?.[0]?.image?.url;

  return (
    <Chip
      avatar={
        imageUrl == null ? (
          <Avatar>
            <PersonIcon />
          </Avatar>
        ) : (
          <Avatar src={imageUrl} />
        )
      }
      label={[firstName, user?.last_name].join(' ')}
      sx={{
        maxWidth: '100%',
        '& .MuiChip-label': {
          ...text.ellipsis,
          maxWidth: '100%',
          display: 'block',
        },
      }}
    />
  );
};
