// Generated from src/svgs/nav-settings-active.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const NavSettingsActive = ({ size, fill, className }: Props) => {
  return (
    <svg viewBox="0 0 25 25" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><path fillRule="evenodd" clipRule="evenodd" d="m24.276 10.552-2.056-.589a.489.489 0 0 1-.332-.331 9.777 9.777 0 0 0-.72-1.746.487.487 0 0 1 .002-.467l1.032-1.859a1 1 0 0 0-.168-1.192l-1.401-1.403a1 1 0 0 0-1.193-.167L17.58 3.83a.486.486 0 0 1-.467 0 9.758 9.758 0 0 0-1.745-.72.49.49 0 0 1-.331-.33l-.59-2.055a1 1 0 0 0-.96-.725h-1.973a1 1 0 0 0-.962.725l-.589 2.054a.49.49 0 0 1-.331.332 9.745 9.745 0 0 0-1.745.72.487.487 0 0 1-.468 0L5.56 2.797a1 1 0 0 0-1.192.168L2.966 4.367a1 1 0 0 0-.168 1.193L3.83 7.42a.486.486 0 0 1 0 .467 9.73 9.73 0 0 0-.72 1.745.49.49 0 0 1-.331.332l-2.055.588a1 1 0 0 0-.724.962v1.972a1 1 0 0 0 .725.961l2.055.59a.488.488 0 0 1 .331.331c.183.604.425 1.189.72 1.746a.487.487 0 0 1 0 .467L2.797 19.44a1 1 0 0 0 .168 1.192l1.401 1.402a1 1 0 0 0 1.193.168l1.86-1.032a.486.486 0 0 1 .467 0 9.748 9.748 0 0 0 1.745.72.49.49 0 0 1 .331.33l.59 2.056a1 1 0 0 0 .96.724h1.973a1 1 0 0 0 .962-.724l.588-2.055a.488.488 0 0 1 .332-.332 9.756 9.756 0 0 0 1.745-.72.487.487 0 0 1 .468.001l1.859 1.032a1 1 0 0 0 1.192-.168l1.402-1.401a1 1 0 0 0 .168-1.193l-1.032-1.86a.486.486 0 0 1 0-.467 9.754 9.754 0 0 0 .72-1.745.489.489 0 0 1 .33-.332l2.056-.588a1 1 0 0 0 .724-.962v-1.972a1 1 0 0 0-.724-.962ZM12.5 17a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9Z" fill="#fff" /></svg>
  );
}
