/**
 * @generated SignedSource<<a148a01f688fa7a6df26aace57e23255>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ActivityForm_root$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ActivityEventForm_root" | "ActivityNoteForm_root">;
  readonly " $fragmentType": "ActivityForm_root";
};
export type ActivityForm_root$key = {
  readonly " $data"?: ActivityForm_root$data;
  readonly " $fragmentSpreads": FragmentRefs<"ActivityForm_root">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActivityForm_root",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ActivityEventForm_root"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ActivityNoteForm_root"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "e1ec5c092c8fd58d339cad38a706f0ca";

export default node;
