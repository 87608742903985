import * as React from 'react';

import {
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
} from '@material-ui/core';
import { graphql, useFragment } from 'react-relay';

import { useLocale } from '../../hooks/locale';
import { useTheme } from '../../hooks/theme';
import {
  translateMortgageBuyingStatus,
  translateMortgageStatus,
  translateReceivedMortgageOffer,
} from '../../utils/lead-labels';
import { numberToLocaleString } from '../../utils/number-format';

import type {
  LeadDetailsAnswersCard_lead$data,
  LeadDetailsAnswersCard_lead$key,
  LeadSaleHorizon,
} from './__generated__/LeadDetailsAnswersCard_lead.graphql';

type Props = {
  lead: LeadDetailsAnswersCard_lead$key;
};

const AppraisalTable = ({
  lead,
}: {
  lead: LeadDetailsAnswersCard_lead$data;
}) => {
  const { t, locale } = useLocale();

  const getSaleHorizonLabel = (value: LeadSaleHorizon) => {
    switch (value) {
      case 'under_6_months':
        return t('under6Months');
      case 'from_6_to_12_months':
        return t('from6To12Months');
      case 'from_1_to_2_years':
        return t('from1To2Years');
      case 'from_2_years':
        return t('from2YearsOrMore');
      case 'already_for_sale':
        return t('alreadyForSale');
      case 'not_selling':
        return t('notSelling');
      default:
        return '-';
    }
  };

  const getPropertyOccupied = () => {
    switch (lead.appraisalPropertyOccupied) {
      case 'owner_occupied':
        return t('ownerOccupied');
      case 'tenant_occupied':
        return t('tenantOccupied');
      case 'vacant':
        return t('vacant');
      case 'i_dont_know':
        return t('iDontKnow');
      default:
        return null;
    }
  };

  const getPropertyUsage = () => {
    switch (lead.appraisalPropertyUsage) {
      case 'primary_residence':
        return t('primaryResidence');
      case 'secondary_home':
        return t('secondaryHome');
      default:
        return null;
    }
  };

  const getOpenToOffer = () => {
    if (lead.appraisalOpenToOffer === 'depends_on_price') {
      return t('maybeOpenToOffer');
    } else if (lead.appraisalOpenToOffer === 'no') {
      return t('noFinancingOffer');
    }
  };

  const getRelationship = () => {
    switch (lead.relationship) {
      case 'owner':
        return t('owner');
      case 'tenant':
        return t('tenant');
      case 'agent':
        return t('agent');
      case 'buyer':
        return t('buyer');
      case 'other':
        return t('other');
      default:
        return null;
    }
  };

  const renderTimeOnTheMarket = () => {
    if (lead.appraisalMarketTime == null) {
      return null;
    }

    return (
      <TableRow>
        <TableCell>{t('monthsOnMarket')}</TableCell>
        <TableCell>{lead.appraisalMarketTime}</TableCell>
      </TableRow>
    );
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell css={{ minWidth: 200 }}>{t('question')}</TableCell>
          <TableCell>{t('answer')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {lead.sourceOrganisation != null && (
          <TableRow>
            <TableCell>{t('source')}</TableCell>
            <TableCell>
              {t('brokerSite')}: {lead.sourceOrganisation?.name}
            </TableCell>
          </TableRow>
        )}
        <TableRow>
          <TableCell>{t('route')}</TableCell>
          <TableCell>
            {[
              lead.appraisalPropertyRoute,
              ' ',
              lead.appraisalPropertyStreetNumber,
            ]}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('locality')}</TableCell>
          <TableCell>
            {[
              lead.appraisalPropertyPostcode,
              ' ',
              lead.appraisalPropertyLocality,
            ]}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('propertyType')}</TableCell>
          <TableCell>
            {[
              lead.appraisalPropertyPropertyType &&
                lead.appraisalPropertyPropertyType.mainType != null &&
                `(${lead.appraisalPropertyPropertyType.mainType})`,
              ' ',
              lead.appraisalPropertyPropertyType?.label ?? '-',
            ]}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('plotNumberLabel')}</TableCell>
          <TableCell>{lead.appraisalPropertyPlotNumber}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('owner')}</TableCell>
          <TableCell>
            {lead.relationship === 'owner' ? t('yes') : t('no')}
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell>{t('relationship')}</TableCell>
          <TableCell>{getRelationship()}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('tenant')}</TableCell>
          <TableCell>
            {lead.relationship === 'tenant' ? t('yes') : t('no')}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('propertyOccupancy')}</TableCell>
          <TableCell>{getPropertyOccupied()}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('propertyUsage')}</TableCell>
          <TableCell>{getPropertyUsage()}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('features')}</TableCell>
          <TableCell>
            {lead.appraisalPropertyHasView === true ? t('yes') : t('no')}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('livingSurface')}</TableCell>
          <TableCell>
            {numberToLocaleString(
              lead.property?.livingSurface ??
                lead.appraisalPropertyLivingSurface,
              locale,
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('landSurface')}</TableCell>
          <TableCell>
            {numberToLocaleString(
              lead.property?.landSurface ?? lead.appraisalPropertyLandSurface,
              locale,
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('balconySurface')}</TableCell>
          <TableCell>
            {numberToLocaleString(
              lead.property?.balconySurface ??
                lead.appraisalPropertyBalconySurface,
              locale,
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('constructionYear')}</TableCell>
          <TableCell>
            {lead.property?.constructionYear ??
              lead.appraisalPropertyConstructionYear ??
              null}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('renovationYear')}</TableCell>
          <TableCell>
            {lead.property?.renovationYear ??
              lead.appraisalPropertyRenovationYear ??
              null}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('numberOfFloors')}</TableCell>
          <TableCell>{lead.appraisalPropertyNumberOfFloors ?? null}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('floorOfFlat')}</TableCell>
          <TableCell>{lead.appraisalPropertyFloorOfFlat ?? null}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('side')}</TableCell>
          <TableCell>{lead.appraisalPropertySide ?? null}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('numberOfRooms')}</TableCell>
          <TableCell>
            {numberToLocaleString(
              lead.property?.numberOfRooms ??
                lead.appraisalPropertyNumberOfRooms,
              locale,
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('numberOfBedrooms')}</TableCell>
          <TableCell>
            {numberToLocaleString(
              lead.property?.numberOfBedrooms ??
                lead.appraisalPropertyNumberOfBedrooms,
              locale,
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('numberOfBathrooms')}</TableCell>
          <TableCell>
            {numberToLocaleString(
              lead.property?.numberOfBathrooms ??
                lead.appraisalPropertyNumberOfBathrooms,
              locale,
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('numberOfIndoorParkings')}</TableCell>
          <TableCell>
            {numberToLocaleString(
              lead.property?.numberOfIndoorParkings ??
                lead.appraisalPropertyNumberOfIndoorParkings,
              locale,
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('numberOfOutdoorParkings')}</TableCell>
          <TableCell>
            {numberToLocaleString(
              lead.property?.numberOfOutdoorParkings ??
                lead.appraisalPropertyNumberOfOutdoorParkings,
              locale,
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('appraisalReason')}</TableCell>
          <TableCell>{lead.appraisalReason}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('buyingStage')}</TableCell>
          <TableCell>
            {translateMortgageBuyingStatus(t, lead.mortgageBuyingStatus)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('mortgageApplication')}</TableCell>
          <TableCell>
            {translateMortgageStatus(t, lead.mortgageStatus)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('mortgageOffer')}</TableCell>
          <TableCell>
            {translateReceivedMortgageOffer(t, lead.receivedMortgageOffer) ??
              '-'}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('saleHorizon')}</TableCell>
          <TableCell>{getSaleHorizonLabel(lead.saleHorizon) ?? null}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('buyHorizon')}</TableCell>
          <TableCell>{lead.buyHorizon}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('openToOffer')}</TableCell>
          <TableCell>{getOpenToOffer()}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('alreadyForSale')}</TableCell>
          <TableCell>
            {lead.saleHorizon === 'already_for_sale' ? t('yes') : t('no')}
          </TableCell>
        </TableRow>
        {renderTimeOnTheMarket()}
        <TableRow>
          <TableCell>{t('mortgageTerm')}</TableCell>
          <TableCell>{lead.mortgageTerm ?? null}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('appraisalInheritanceNextStep')}</TableCell>
          <TableCell>{lead.appraisalInheritanceNextStep ?? null}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('appraisalSeparationNextStep')}</TableCell>
          <TableCell>{lead.appraisalSeparationNextStep ?? null}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('perceivedValue')}</TableCell>
          <TableCell>
            {numberToLocaleString(lead.appraisalPerceivedValue, locale)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('appraisalValue')}</TableCell>
          <TableCell>
            {numberToLocaleString(
              lead.property?.firstAppraisal?.realadvisor?.value ?? null,
              locale,
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('monthlyGrossRent')}</TableCell>
          <TableCell>
            {numberToLocaleString(lead.appraisalMonthlyGrossRent, locale)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('appraisalPerception')}</TableCell>
          <TableCell>{lead.appraisalPerception ?? null}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('appraisalNextStep')}</TableCell>
          <TableCell>{lead.appraisalNextStep ?? null}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const MortgageTable = ({
  lead,
}: {
  lead: LeadDetailsAnswersCard_lead$data;
}) => {
  const { t, locale } = useLocale();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t('question')}</TableCell>
          <TableCell>{t('answer')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>{t('mortgageStep')}</TableCell>
          <TableCell>{lead.mortgageStep}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('maxPropertyValue')}</TableCell>
          <TableCell>
            {numberToLocaleString(
              lead.mortgageAnalysis?.maxPropertyValue ?? null,
              locale,
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('leadType')}</TableCell>
          <TableCell>{lead.mortgageMortgageType ?? null}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('isPropertyIdentified')}</TableCell>
          <TableCell>
            {lead.mortgageIsPropertyIdentified === true ? t('yes') : t('no')}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('buyingStatus')}</TableCell>
          <TableCell>{lead.mortgageBuyingStatus ?? null}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('propertyType')}</TableCell>
          <TableCell>
            {lead.mortgageIsConstruction === true
              ? t('newDevelopment')
              : t('existingProperty')}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('googleAddress')}</TableCell>
          <TableCell>
            {lead.mortgageGoogleAddress?.formattedAddress ?? null}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('propertyValue')}</TableCell>
          <TableCell>
            {numberToLocaleString(lead.mortgagePropertyValue, locale)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('propertyUsage')}</TableCell>
          <TableCell>{lead.mortgagePropertyUsage ?? null}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('numberOfBuyers')}</TableCell>
          <TableCell>{lead.numberOfBuyers ?? null}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('hasDependantChildren')}</TableCell>
          <TableCell>
            {lead.hasDependantChildren === true ? t('yes') : t('no')}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('numberOfDependantChildren')}</TableCell>
          <TableCell>
            {numberToLocaleString(lead.numberOfDependantChildren, locale)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('occupation')}</TableCell>
          <TableCell>{lead.occupation?.label ?? null}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('nationality')}</TableCell>
          <TableCell>{lead.nationality?.label ?? null}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('dateOfBirth')}</TableCell>
          <TableCell>{lead.dateOfBirth ?? null}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('yearlyRevenues')}</TableCell>
          <TableCell>
            {numberToLocaleString(lead.yearlyRevenues, locale)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('savings')}</TableCell>
          <TableCell>{numberToLocaleString(lead.savings, locale)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('secondPillar')}</TableCell>
          <TableCell>
            {numberToLocaleString(lead.secondPillar, locale)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('thirdPillar')}</TableCell>
          <TableCell>
            {numberToLocaleString(lead.thirdPillar, locale)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('debt')}</TableCell>
          <TableCell>{numberToLocaleString(lead.debt, locale)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('otherCredits')}</TableCell>
          <TableCell>
            {numberToLocaleString(lead.otherCredits, locale)}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const ContactTable = ({ lead }: { lead: LeadDetailsAnswersCard_lead$data }) => {
  const { t } = useLocale();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell css={{ minWidth: 200 }}>{t('question')}</TableCell>
          <TableCell>{t('answer')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>{t('firstName')}</TableCell>
          <TableCell>{lead.firstName ?? null}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('lastName')}</TableCell>
          <TableCell>{lead.lastName ?? null}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('emailAddress')}</TableCell>
          <TableCell>{lead.emailAddress ?? null}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('phoneNumber')}</TableCell>
          <TableCell>{lead.phoneNumber ?? null}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('callTimes')}</TableCell>
          <TableCell>{(lead.callTimes || []).join(', ')}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('referrer')}</TableCell>
          <TableCell>{lead.referrer ?? null}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('referringDomain')}</TableCell>
          <TableCell>{lead.referringDomain ?? null}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('landingPageUrl')}</TableCell>
          <TableCell>{lead.landingPageUrl ?? null}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export const LeadDetailsAnswersCard = (props: Props) => {
  const lead = useFragment(
    graphql`
      fragment LeadDetailsAnswersCard_lead on Lead {
        appraisalPropertyRoute
        appraisalPropertyStreetNumber
        appraisalPropertyPostcode
        appraisalPropertyLocality
        appraisalPropertyPropertyType {
          mainType
          label
        }
        appraisalPropertyHasView
        appraisalPropertyOccupied
        appraisalPropertyUsage
        appraisalOpenToOffer
        appraisalMarketTime
        relationship
        appraisalPropertyLivingSurface
        appraisalPropertyLandSurface
        appraisalPropertyBalconySurface
        appraisalPropertyConstructionYear
        appraisalPropertyRenovationYear
        appraisalPropertyNumberOfFloors
        appraisalPropertyFloorOfFlat
        appraisalPropertySide
        appraisalPropertyNumberOfRooms
        appraisalPropertyNumberOfBedrooms
        appraisalPropertyNumberOfBathrooms
        appraisalPropertyNumberOfIndoorParkings
        appraisalPropertyNumberOfOutdoorParkings
        appraisalPropertyPlotNumber
        appraisalReason
        saleHorizon
        buyHorizon
        mortgageTerm
        appraisalInheritanceNextStep
        appraisalSeparationNextStep
        appraisalPerceivedValue
        appraisalMonthlyGrossRent
        appraisalPerception
        appraisalNextStep
        property {
          numberOfRooms
          numberOfBedrooms
          numberOfBathrooms
          numberOfIndoorParkings
          numberOfOutdoorParkings
          constructionYear
          renovationYear
          livingSurface
          landSurface
          balconySurface
          firstAppraisal {
            realadvisor {
              value
            }
          }
        }

        mortgageStep
        mortgageAnalysis {
          maxPropertyValue
        }
        yearlyRevenues
        savings
        debt
        mortgagePropertyValue
        mortgageMortgageType
        mortgageIsConstruction
        mortgageGoogleAddress {
          formattedAddress
        }
        mortgageIsPropertyIdentified
        mortgageBuyingStatus
        mortgagePropertyUsage
        mortgageStatus
        receivedMortgageOffer
        numberOfBuyers
        hasDependantChildren
        numberOfDependantChildren
        occupation {
          label
        }
        nationality {
          label
        }
        dateOfBirth
        secondPillar
        thirdPillar
        otherCredits
        firstName
        lastName
        emailAddress
        phoneNumber
        referrer
        referringDomain
        landingPageUrl
        callTimes
        sourceOrganisation {
          name
        }
      }
    `,
    props.lead,
  );

  const { t } = useLocale();
  const { boxShadow, colors } = useTheme();
  const [tab, setTab] = React.useState('appraisal');

  return (
    <>
      <Tabs
        css={{
          boxShadow: boxShadow.elevation2,
          '.MuiTabs-indicator': { backgroundColor: colors.primaryMain },
          '.Mui-selected': { color: colors.black },
        }}
        value={tab}
        onChange={(_event, value) => setTab(value)}
      >
        <Tab label={t('appraisal')} value="appraisal" />
        {lead.mortgageStep != null && (
          <Tab label={t('mortgage')} value="mortgage" />
        )}
        <Tab label={t('contact')} value="contact" />
      </Tabs>

      {tab === 'appraisal' && <AppraisalTable lead={lead} />}

      {tab === 'mortgage' && <MortgageTable lead={lead} />}

      {tab === 'contact' && <ContactTable lead={lead} />}
    </>
  );
};
