/**
 * @generated SignedSource<<5af4defc3fd4743b6c3186dd5a1f9808>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
type appraisalGraph_appraisal$fragmentType = any;
import type { FragmentType } from "relay-runtime";
declare export opaque type appraisalDrawer_appraisal$fragmentType: FragmentType;
export type appraisalDrawer_appraisal$data = {|
  +iazi: ?{|
    +input: ?{|
      +bathroomNb: ?number,
      +buildCondition: ?string,
      +buildQuality: ?string,
      +buildYear: ?number,
      +flatCondition: ?string,
      +flatType: ?string,
      +floor: ?number,
      +floorNb: ?number,
      +houseType: ?string,
      +minergieCode: ?string,
      +modelDate: ?number,
      +modelType: ?string,
      +ortId: ?number,
      +parkExternalNb: ?number,
      +parkOpenNb: ?number,
      +qualityMicro: ?string,
      +roomNb: ?number,
      +sepWcNb: ?number,
      +servituteFlag: ?number,
      +street: ?string,
      +surfaceBalcony: ?number,
      +surfaceGround: ?number,
      +surfaceLiving: ?number,
      +town: ?string,
      +volume: ?number,
      +volumeCode: ?number,
      +zip: ?number,
    |},
    +output: ?{|
      +hiConfInt50: ?number,
      +hiConfInt95: ?number,
      +lowConfInt50: ?number,
      +lowConfInt95: ?number,
      +modelDate: ?number,
      +qualityCode: ?number,
      +qualityText: ?string,
      +value: ?number,
    |},
  |},
  +iazi_cv: ?{|
    +input: ?{|
      +bathroomNb: ?number,
      +buildCondition: ?string,
      +buildQuality: ?string,
      +buildYear: ?number,
      +flatCondition: ?string,
      +flatType: ?string,
      +floor: ?number,
      +floorNb: ?number,
      +houseType: ?string,
      +minergieCode: ?string,
      +modelDate: ?number,
      +modelType: ?string,
      +ortId: ?number,
      +parkExternalNb: ?number,
      +parkOpenNb: ?number,
      +qualityMicro: ?string,
      +roomNb: ?number,
      +sepWcNb: ?number,
      +servituteFlag: ?number,
      +street: ?string,
      +surfaceBalcony: ?number,
      +surfaceGround: ?number,
      +surfaceLiving: ?number,
      +town: ?string,
      +volume: ?number,
      +volumeCode: ?number,
      +zip: ?number,
    |},
    +output: ?{|
      +hiConfInt50: ?number,
      +hiConfInt95: ?number,
      +lowConfInt50: ?number,
      +lowConfInt95: ?number,
      +modelDate: ?number,
      +qualityCode: ?number,
      +qualityText: ?string,
      +value: ?number,
    |},
  |},
  +id: string,
  +pricehubble: ?{|
    +input: ?{|
      +buildingYear: ?number,
      +city: ?string,
      +conditionDelta: ?number,
      +houseNumber: ?string,
      +landArea: ?number,
      +livingArea: ?number,
      +numberOfRooms: ?number,
      +postcode: ?string,
      +propertyTypeCode: ?string,
      +propertyTypeSubcode: ?string,
      +street: ?string,
    |},
    +output: ?{|
      +confidence: ?number,
      +geoQuality: ?string,
      +lowerValueRange: ?number,
      +upperValueRange: ?number,
    |},
  |},
  +wup: ?{|
    +input: ?{|
      +addressLine1: ?string,
      +condition: ?string,
      +constructionYear: ?string,
      +countryIsoCode: ?string,
      +flatType: ?string,
      +hedonicModelValues: ?string,
      +houseType: ?string,
      +landPlotArea: ?string,
      +numberOfGarageParkingSpaces: ?string,
      +numberOfOutdoorParkingSpaces: ?string,
      +numberOfRooms: ?string,
      +place: ?string,
      +residenceType: ?string,
      +standard: ?string,
      +terraceArea: ?string,
      +usableArea: ?string,
      +valuationDate: ?string,
      +zipCode: ?string,
    |},
    +output: ?{|
      +marketValueAfterCorrection: ?number,
      +marketValueBeforeCorrection: ?number,
    |},
  |},
  +$fragmentSpreads: appraisalGraph_appraisal$fragmentType,
  +$fragmentType: appraisalDrawer_appraisal$fragmentType,
|};
export type appraisalDrawer_appraisal$key = {
  +$data?: appraisalDrawer_appraisal$data,
  +$fragmentSpreads: appraisalDrawer_appraisal$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "modelDate",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "houseType",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "flatType",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "street",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "IaziAppraisalOutput",
    "kind": "LinkedField",
    "name": "output",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hiConfInt95",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hiConfInt50",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lowConfInt50",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lowConfInt95",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "qualityCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "qualityText",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "IaziAppraisalInput",
    "kind": "LinkedField",
    "name": "input",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "modelType",
        "storageKey": null
      },
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ortId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "qualityMicro",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "buildYear",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "surfaceLiving",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "surfaceGround",
        "storageKey": null
      },
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "roomNb",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "bathroomNb",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sepWcNb",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "zip",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "town",
        "storageKey": null
      },
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "buildCondition",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "flatCondition",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "buildQuality",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "floorNb",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "floor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "volume",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "volumeCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "minergieCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "parkExternalNb",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "parkOpenNb",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "servituteFlag",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "surfaceBalcony",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numberOfRooms",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "appraisalDrawer_appraisal",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "appraisalGraph_appraisal"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "IaziAppraisal",
      "kind": "LinkedField",
      "name": "iazi",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "IaziAppraisal",
      "kind": "LinkedField",
      "name": "iazi_cv",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Pricehubble",
      "kind": "LinkedField",
      "name": "pricehubble",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PricehubbleInput",
          "kind": "LinkedField",
          "name": "input",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "city",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "postcode",
              "storageKey": null
            },
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "houseNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "buildingYear",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "conditionDelta",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "livingArea",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "landArea",
              "storageKey": null
            },
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "propertyTypeCode",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "propertyTypeSubcode",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PricehubbleOutput",
          "kind": "LinkedField",
          "name": "output",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "upperValueRange",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lowerValueRange",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "confidence",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "geoQuality",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WupAppraisal",
      "kind": "LinkedField",
      "name": "wup",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "WupAppraisalInput",
          "kind": "LinkedField",
          "name": "input",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "valuationDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hedonicModelValues",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "residenceType",
              "storageKey": null
            },
            (v5/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "terraceArea",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "addressLine1",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "zipCode",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "place",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "countryIsoCode",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "standard",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "condition",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "numberOfOutdoorParkingSpaces",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "numberOfGarageParkingSpaces",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "landPlotArea",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "constructionYear",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "usableArea",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "WupAppraisalOutput",
          "kind": "LinkedField",
          "name": "output",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "marketValueBeforeCorrection",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "marketValueAfterCorrection",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Appraisal",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "9212161c6acd7213c70f7a93210f5b89";

export default node;
