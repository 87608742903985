import { useState } from 'react';

import { KeyboardArrowDown } from '@mui/icons-material';
import { Button, MenuItem, type PopoverOrigin } from '@mui/material';
import { Link, type To } from 'react-router-dom';

import { IconsMenu } from './IconsMenu';

export type Actions = {
  label: string;
  disabled?: boolean;
  onClick?: () => void;
  to?: To;
  icon?: React.ReactNode;
};
type ActionsMenuProps = {
  label: string;
  actions: Actions[];
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
};

export const ActionsMenu = ({
  label,
  actions,
  anchorOrigin,
  transformOrigin,
}: ActionsMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isActionsMenuOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleActionsMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="actions-button"
        aria-controls={isActionsMenuOpen ? 'actions-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isActionsMenuOpen ? 'true' : undefined}
        variant="outlined"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDown />}
        color="neutral"
      >
        {label}
      </Button>
      {
        <IconsMenu
          id="actions-menu"
          MenuListProps={{
            'aria-labelledby': 'actions-button',
          }}
          anchorEl={anchorEl}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          open={isActionsMenuOpen}
          onClose={handleActionsMenuClose}
        >
          {actions.map(action => (
            <MenuItem
              key={action.label}
              onClick={() => {
                handleActionsMenuClose();
                action.onClick?.();
              }}
              component={action.to ? Link : MenuItem}
              to={action.to}
              disabled={action.disabled}
            >
              {action.icon}
              {action.label}
            </MenuItem>
          ))}
        </IconsMenu>
      }
    </>
  );
};
