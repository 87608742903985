import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionSummary, Box, IconButton, Stack } from '@mui/material';
import { Image } from '@realadvisor/image';

type Props = {
  expanded: boolean;
  title: string;
  subTitle?: string;
  avatarUrl?: string | null;
};

export const AccordionCardSummary: React.FC<Props> = props => (
  <AccordionSummary
    expandIcon={
      <IconButton sx={{ p: 1.5 }}>
        <ExpandMoreIcon />
      </IconButton>
    }
    sx={{
      '& .MuiAccordionSummary-expandIconWrapper': {
        mr: -1.5,
      },
    }}
  >
    <Stack
      direction="row"
      width={1}
      justifyContent="space-between"
      alignItems="center"
    >
      <Box sx={{ typography: 'subtitle2', fontWeight: 700 }}>{props.title}</Box>
      <div>
        <Stack
          direction="row"
          width={1}
          justifyContent="space-between"
          alignItems="center"
        >
          {!props.expanded && props.avatarUrl != null && (
            <Box mr={2} my={-2}>
              <Image
                src={props.avatarUrl}
                loading="lazy"
                css={{ width: 34, height: 34, borderRadius: '50%' }}
              />
            </Box>
          )}
          {!props.expanded && (
            <Box
              sx={theme => ({
                typography: 'caption',
                ...theme.mixins.truncate(),
                lineHeight: 1,
                color: 'text.secondary',
                borderColor: 'grey.300',
              })}
            >
              {props.subTitle}
            </Box>
          )}
        </Stack>
      </div>
    </Stack>
  </AccordionSummary>
);
