import { Carousel } from '@realadvisor/carousel';
import { Image } from '@realadvisor/image';

import { useLocale } from '../../src/hooks/locale';
import { gql } from '../__generated__';
import type { LotCardCarousel_LotFragment } from '../__generated__/graphql';

import { NoPhotosPlaceholder } from './NoPhotoPlaceholder';
import { Tag } from './Tag';
import { TimeAgo } from './TimeAgo';

type Props = {
  lot: LotCardCarousel_LotFragment;
};

export const LOT_CARD_CAROUSEL_FRAGMENT = gql(`
  fragment LotCardCarousel_lot on lots {
    lot_reference
    created_at
    created_by_user {
      first_name
      last_name
    }
    property {
      property_images(order_by: { order_nr: asc }) {
        title
        image {
          url
        }
      }
    }
    portal_listings {
      live
    }
  }
`);

const LivePortalListingsCount = ({
  portal_listings,
}: {
  portal_listings: LotCardCarousel_LotFragment['portal_listings'];
}) => {
  const { t } = useLocale();

  const livePortalListingsCount =
    portal_listings?.filter(x => x.live).length ?? 0;

  return (
    <Tag
      status={livePortalListingsCount > 0 ? 'dark_success' : 'dark_error'}
      noBorder
    >
      {livePortalListingsCount > 0 ? t('online') : t('offline')}
      {livePortalListingsCount > 0 && (
        <span css={{ marginLeft: 6, opacity: '.8' }}>
          {livePortalListingsCount}
        </span>
      )}
    </Tag>
  );
};

export const LotCardCarousel = (props: Props) => {
  const { lot } = props;

  const images = lot.property?.property_images ?? [];

  const imageRatio = 10 / 16;

  const firstName = lot.created_by_user?.first_name;
  const lastName = lot.created_by_user?.last_name;
  const createdBy =
    firstName != null && lastName != null
      ? `${firstName.charAt(0)}. ${lastName}`
      : null;

  return (
    <div css={{ position: 'relative' }}>
      {images.length === 0 ? (
        <NoPhotosPlaceholder ratio={imageRatio} />
      ) : (
        <Carousel
          media={{
            gap: 0,
            itemsOnScreen: 1,
            aspectRatio: imageRatio,
          }}
        >
          {images.map((item, index) => {
            const options = { w: 700, c: 'fill', f: 'jpg' } as const;
            return (
              <Image
                key={index}
                css={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
                alt={item.title ?? ''}
                loading={'lazy'}
                options={options}
                src={item.image?.url ?? ''}
              />
            );
          })}
        </Carousel>
      )}

      <div css={{ position: 'absolute', bottom: 10, left: 10, zIndex: 1 }}>
        <Tag status="neutral" noBorder>
          {lot.lot_reference}
        </Tag>
      </div>
      {lot.created_at != null && createdBy != null && (
        <div css={{ position: 'absolute', top: 10, left: 10, zIndex: 1 }}>
          <Tag status="dark" noBorder>
            <TimeAgo dateString={lot.created_at} /> - {createdBy}
          </Tag>
        </div>
      )}
      <div css={{ position: 'absolute', top: 10, right: 10, zIndex: 1 }}>
        <LivePortalListingsCount portal_listings={lot.portal_listings} />
      </div>
    </div>
  );
};
