// Generated from src/svgs/door-outline.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const DoorOutline = ({ size, fill, className }: Props) => {
  return (
    <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><path style={{"fill":"none"}} d="M0 0h24v24.001H0z" /><path d="M18 20.999v-17c0-1.102-.897-2-2-2H8c-1.103 0-2 .898-2 2v17H3v2h18v-2h-3zm-10 0v-17h8l.001 17H8z" /><path d="M13 9.999h2v4h-2z" /></svg>
  );
}
