import { useCallback, useEffect, useState } from 'react';

import { type ApolloError, useMutation, useQuery } from '@apollo/client';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

import { useLocale } from '../../../src/hooks/locale';
import { gql } from '../../__generated__';
import {
  type GetSalesPipelineQuery,
  type LeadPipelineFormFragment,
  Leads_Status_Enum,
} from '../../__generated__/graphql';
import { MutationErrorModal } from '../MutationErrorModal';

const GET_SALES_PIPELINE_QUERY = gql(`
  query GetSalesPipeline {
    pipelines(where: {lead_type: {_eq: sales}}) {
      id
      label
      name
      stages {
        id
        label
      }
    }
  }
`);

const UPDATE_LEAD_STAGE = gql(`
  mutation UpdateLeadStage($id: uuid!, $stageId: uuid!) {
    update_leads_by_pk(pk_columns: {id: $id}, _set: {stage_id: $stageId}) {
      ...LeadPipelineForm
    }
  }
`);

export const LEAD_PIPELINE_FORM_FRAGMENT = gql(`
  fragment LeadPipelineForm on leads {
    id
    status
    stage {
      id
      label
      pipeline {
        id
      }
    }
    lead_lost_type {
      id
      label
    }
  }
`);

type Pipeline = GetSalesPipelineQuery['pipelines'][number];

type LeadPipelineFormProps = {
  lead: LeadPipelineFormFragment;
};

export const LeadPipelineForm: React.FC<LeadPipelineFormProps> = ({ lead }) => {
  const { t } = useLocale();
  const { text } = useTheme();
  const { data, loading } = useQuery(GET_SALES_PIPELINE_QUERY);
  const [updateLeadStage] = useMutation(UPDATE_LEAD_STAGE);
  const [updateError, setUpdateError] = useState<null | ApolloError>(null);
  const [stageId, setStageId] = useState(lead.stage?.id ?? null);

  useEffect(() => {
    setStageId(lead.stage?.id ?? null);
  }, [lead.stage?.id]);

  const getSelectedPipeline = useCallback((): Pipeline | null => {
    const pipelines = data?.pipelines ?? [];
    const pipelineId = lead.stage?.pipeline?.id;
    const stageId = lead.stage?.id;

    if (pipelineId != null) {
      return pipelines.find(pipeline => pipeline.id === pipelineId) ?? null;
    }

    if (stageId != null) {
      return (
        pipelines.find(pipeline =>
          pipeline.stages.some(stage => stage.id === lead.stage?.id),
        ) ?? null
      );
    }

    return pipelines.find(p => p.name === 'brokerage') ?? null;
  }, [lead, data]);

  const selectedPipeline = getSelectedPipeline();
  const stages = selectedPipeline ? selectedPipeline.stages : [];

  return (
    <>
      {loading ? (
        <CircularProgress sx={{ alignSelf: 'center' }} disableShrink />
      ) : (
        <Stack spacing={2}>
          <FormControl fullWidth error={selectedPipeline == null}>
            <FormLabel style={{ marginBottom: '0.5rem', fontWeight: 500 }}>
              {t('Lead stage')}
            </FormLabel>
            <Select
              value={stageId}
              variant="outlined"
              placeholder={
                stages.length === 0
                  ? t('No stages for the selected pipeline')
                  : undefined
              }
              size="small"
              disabled={stages.length === 0}
              onChange={event => {
                const selectedStage = stages.find(
                  stg => stg.id === event.target.value,
                );

                if (selectedStage != null && selectedPipeline != null) {
                  updateLeadStage({
                    variables: { id: lead.id, stageId: selectedStage.id },
                    optimisticResponse: {
                      update_leads_by_pk: {
                        ...lead,
                        stage: {
                          ...selectedStage,
                          pipeline: {
                            id: selectedPipeline.id,
                          },
                        },
                      },
                    },
                    onError: error => setUpdateError(error),
                  });
                }
              }}
            >
              {stages.map(stage => (
                <MenuItem key={stage.id} value={stage.id}>
                  {stage.label ?? ''}
                </MenuItem>
              ))}
            </Select>
            {selectedPipeline == null && (
              <FormHelperText>{t('Invalid pipeline selected')}</FormHelperText>
            )}
          </FormControl>

          {lead.status === Leads_Status_Enum.Won && (
            <Stack direction="row" spacing={1} alignItems="center">
              <CheckCircleIcon color="success" fontSize="large" />
              <Typography variant="subtitle1">{t('Mandate won')}</Typography>
            </Stack>
          )}

          {lead.status === Leads_Status_Enum.Lost && (
            <Stack direction="row" spacing={1} alignItems="center">
              <CancelIcon color="error" fontSize="large" />
              <Typography variant="subtitle1" sx={text.ellipsis}>
                {t('Lead lost')}
                {lead.lead_lost_type?.label && (
                  <>
                    : <strong>{lead.lead_lost_type.label}</strong>
                  </>
                )}
              </Typography>
            </Stack>
          )}
        </Stack>
      )}
      <MutationErrorModal
        error={updateError}
        onClose={() => setUpdateError(null)}
      />
    </>
  );
};
