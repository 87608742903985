import * as React from 'react';

import { useMutation as useApolloMutation } from '@apollo/client';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import { graphql, useLazyLoadQuery, useMutation } from 'react-relay';

import type { UpdateLeadsStatusMutation } from '../../apollo/__generated__/graphql';
import {
  LeadStatusDialog,
  UPDATE_LEADS_STATUS_MUTATION,
} from '../../apollo/components/lead-status-dialog';
import { RelayHasuraWrapper } from '../../networking';
import { EmailSequenceDialog } from '../../shared/email-sequence-dialog';
import { fromGlobalId } from '../../shared/global-id';
import { PipelineDialog } from '../components/PipelineDialog';
import { Menu } from '../controls/popup';
import { useLocale } from '../hooks/locale';

import type { leadsActionsMenuAssignBrokerMutation } from './__generated__/leadsActionsMenuAssignBrokerMutation.graphql';
import type { leadsActionsMenuQuery } from './__generated__/leadsActionsMenuQuery.graphql';
import type { leadsActionsMenuUpdateLeadsStageMutation } from './__generated__/leadsActionsMenuUpdateLeadsStageMutation.graphql';
import { type User, UserInput } from './user-input';

type Props = {
  leadsIds: string[];
  pipeline: 'buyer' | 'financing' | 'sales';
  pipelineId?: string;
  retry?: () => void;
  onConfirm: () => void;
  onContactsAdded: (count: number) => void;
  recipients: {
    userId: string;
    email: string | null;
    propertyId?: string;
    leadId?: string;
  }[];
};

const AssignBrokerDialog = ({
  leadsIds,
  open,
  onConfirm,
  onCancel,
}: {
  leadsIds: string[];
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}) => {
  const [assignBroker, assigning] =
    useMutation<leadsActionsMenuAssignBrokerMutation>(
      graphql`
        mutation leadsActionsMenuAssignBrokerMutation(
          $input: AssignLeadsInput!
        ) {
          assignLeads(input: $input) {
            leads {
              id
              broker {
                firstName
                lastName
                emails {
                  email
                }
              }
            }
          }
        }
      `,
    );
  const { t } = useLocale();
  const [user, setUser] = React.useState<User | null>(null);
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{t('assignThislead')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('pleaseSelectAUserToAssignTheseLeadsTo')}
        </DialogContentText>
        <FormControl>
          <InputLabel>{t('broker')}</InputLabel>
          <UserInput
            value={user}
            filters={{ isBroker: true }}
            onChange={newUser => setUser(newUser)}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onCancel}>
          {t('cancel')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={user == null || assigning}
          onClick={() => {
            assignBroker({
              variables: {
                input: {
                  id: leadsIds,
                  brokerId: user ? user.id : null,
                },
              },
              onCompleted: onConfirm,
            });
          }}
        >
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const LeadsActionsMenu = ({
  pipeline,
  pipelineId,
  leadsIds,
  onConfirm,
  recipients,
  onContactsAdded,
  retry,
}: Props) => {
  const { t } = useLocale();
  const [assignBrokerDialog, setAssignBrokerDialog] = React.useState(false);
  const [pipelineDialog, setPipelineDialog] = React.useState(false);
  const [statusDialog, setStatusDialog] = React.useState(false);
  const [emailSequenceDialog, setEmailSequenceDialog] = React.useState(false);
  const targetRef = React.useRef(null);
  const [menu, setMenu] = React.useState(false);
  const [updateMultipleLeadsStage, updatingMultipleLeadsStage] =
    useMutation<leadsActionsMenuUpdateLeadsStageMutation>(graphql`
      mutation leadsActionsMenuUpdateLeadsStageMutation(
        $input: UpdateMultipleLeadsStageInput!
      ) {
        updateMultipleLeadsStage(input: $input) {
          leads {
            id
            stage {
              label
            }
          }
        }
      }
    `);

  const [updateLeadsStatus, { loading: updatingLeadsStatus }] =
    useApolloMutation<UpdateLeadsStatusMutation>(UPDATE_LEADS_STATUS_MUTATION);

  const data = useLazyLoadQuery<leadsActionsMenuQuery>(
    graphql`
      query leadsActionsMenuQuery {
        me {
          isAdmin
          ...userInput_user @relay(mask: false)
        }
      }
    `,
    {},
  );
  const isAdmin = data.me?.isAdmin ?? false;

  return (
    <>
      <AssignBrokerDialog
        leadsIds={leadsIds}
        open={assignBrokerDialog}
        onConfirm={() => {
          setAssignBrokerDialog(false);
          onConfirm();
        }}
        onCancel={() => setAssignBrokerDialog(false)}
      />
      <Button
        ref={targetRef}
        variant="contained"
        color="secondary"
        onClick={() => setMenu(true)}
      >
        {t('actions')}
      </Button>
      <Menu referenceRef={targetRef} open={menu} onClose={() => setMenu(false)}>
        <MenuItem onClick={() => setAssignBrokerDialog(true)}>
          {t('assignToBroker')}
        </MenuItem>
        <MenuItem onClick={() => setStatusDialog(true)}>
          {t('Change status')}
        </MenuItem>
        <MenuItem onClick={() => setPipelineDialog(true)}>
          {t('Change stage')}
        </MenuItem>
        {
          // allow only for admins in initial release
          isAdmin && (
            <MenuItem onClick={() => setEmailSequenceDialog(true)}>
              {t('sendEmailSequence')}
            </MenuItem>
          )
        }
      </Menu>
      <PipelineDialog
        pipelines={pipeline}
        pipelineId={pipelineId}
        open={pipelineDialog}
        onClose={() => setPipelineDialog(false)}
        loading={updatingMultipleLeadsStage}
        onChange={({ stageId, pipelineId: changedPipelineId }) => {
          updateMultipleLeadsStage({
            variables: {
              input: {
                ids: leadsIds,
                stageId,
              },
            },
            onCompleted: () => {
              setPipelineDialog(false);
              onConfirm();
              if (pipelineId !== changedPipelineId) {
                retry?.();
              }
            },
          });
        }}
      />
      <LeadStatusDialog
        open={statusDialog}
        loading={updatingLeadsStatus}
        onClose={() => setStatusDialog(false)}
        onConfirm={status => {
          updateLeadsStatus({
            variables: {
              ids: leadsIds.map(fromGlobalId),
              status,
            },
            onCompleted: () => {
              setStatusDialog(false);
              onConfirm();
              retry?.();
            },
          });
        }}
      />
      <RelayHasuraWrapper>
        <EmailSequenceDialog
          recipients={recipients}
          open={emailSequenceDialog}
          me={data.me}
          onClose={() => setEmailSequenceDialog(false)}
          onSuccess={onContactsAdded}
        />
      </RelayHasuraWrapper>
    </>
  );
};
