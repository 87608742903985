/**
 * @generated SignedSource<<f5723ddd153f25b442a5674eb9a0f9bd>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
type financingLeadCreationDrawer_user$fragmentType = any;
export type PipelineLeadType = "buyer" | "financing" | "sales";
import type { FragmentType } from "relay-runtime";
declare export opaque type financingLeadsCard_user$fragmentType: FragmentType;
export type financingLeadsCard_user$data = {|
  +id: string,
  +leads: ?$ReadOnlyArray<?{|
    +id: string,
    +mortgageGoogleAddress: ?{|
      +formattedAddress: ?string,
    |},
    +mortgageIsConstruction: ?boolean,
    +mortgageMortgageType: ?string,
    +mortgagePropertyUsage: ?string,
    +property: ?{|
      +formattedAddress: ?string,
    |},
    +stage: ?{|
      +pipeline: ?{|
        +leadType: PipelineLeadType,
      |},
    |},
  |}>,
  +$fragmentSpreads: financingLeadCreationDrawer_user$fragmentType,
  +$fragmentType: financingLeadsCard_user$fragmentType,
|};
export type financingLeadsCard_user$key = {
  +$data?: financingLeadsCard_user$data,
  +$fragmentSpreads: financingLeadsCard_user$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "formattedAddress",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "financingLeadsCard_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "financingLeadCreationDrawer_user"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Lead",
      "kind": "LinkedField",
      "name": "leads",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mortgageIsConstruction",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mortgageMortgageType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mortgagePropertyUsage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "LeadStage",
          "kind": "LinkedField",
          "name": "stage",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Pipeline",
              "kind": "LinkedField",
              "name": "pipeline",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "leadType",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Property",
          "kind": "LinkedField",
          "name": "property",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "GoogleAddress",
          "kind": "LinkedField",
          "name": "mortgageGoogleAddress",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "cbca0dc357b91691353214f1b38a0d9e";

export default node;
