import * as React from 'react';

import {
  Accordion,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from '@material-ui/core';
import { Avatar, TextField } from '@mui/material';
import {
  LocalizationProvider,
  MobileDatePicker,
} from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { parseISO } from 'date-fns';
import { de, enGB, es, fr, it } from 'date-fns/esm/locale';
import { graphql, useFragment, useMutation } from 'react-relay';
import { Box, Flex } from 'react-system';

import { AutocompleteMultiple } from '../../controls/autocomplete-multiple';
import { useLocale } from '../../hooks/locale';
import { useTheme } from '../../hooks/theme';
import { Cancel } from '../../icons/cancel';
import { Close } from '../../icons/close';
import { AccordionCardSummary } from '../../shared/accordion-card-summary';
import { number_of_string } from '../../utils/number-format';

import type {
  leadQualificationCard_lead$data,
  leadQualificationCard_lead$key,
} from './__generated__/leadQualificationCard_lead.graphql';
import type {
  leadQualificationCard_root$data,
  leadQualificationCard_root$key,
} from './__generated__/leadQualificationCard_root.graphql';
import type {
  leadQualificationCardMutation,
  leadQualificationCardMutation$variables,
} from './__generated__/leadQualificationCardMutation.graphql';

type Props = {
  lead: leadQualificationCard_lead$key;
  defaultExpanded: boolean;
  root: leadQualificationCard_root$key;
};

const LeadQualificationForm = ({
  lead,
  dictionary,
}: {
  lead: leadQualificationCard_lead$data;
  dictionary: leadQualificationCard_root$data;
}) => {
  const { t, language } = useLocale();
  const datePickerLocales = {
    en: enGB,
    fr,
    de,
    it,
    es,
  };
  const { colors, text } = useTheme();

  const tagsOptions = dictionary.tagsTypes.map(tag => ({
    title: tag.label,
    value: tag.name,
  }));

  const [updateLead] = useMutation<leadQualificationCardMutation>(
    graphql`
      mutation leadQualificationCardMutation($input: UpsertLeadInput!) {
        upsertLead(input: $input) {
          lead {
            ...leadQualificationCard_lead
            ...leadDetails_lead
          }
        }
      }
    `,
  );

  const updateLeadHelper = (
    values: leadQualificationCardMutation$variables['input']['lead'],
  ) => {
    updateLead({
      variables: {
        input: {
          lead: {
            id: lead.id,
            ...values,
          },
        },
      },
    });
  };

  return (
    <>
      <Box width={1} p={2}>
        <FormControl variant="filled">
          <InputLabel>
            {[t('stage'), lead.stage?.pipeline?.label].join(': ')}
          </InputLabel>
          <Select
            variant="filled"
            defaultValue={lead?.stage?.id ?? ''}
            onChange={event =>
              updateLeadHelper({
                stageId: event.target.value as string,
              })
            }
          >
            {dictionary.pipelinesStages
              .map(option => [
                [
                  <ListSubheader key={option.label}>
                    {option.label}
                  </ListSubheader>,
                ],
                [
                  option.stages.map(stage => (
                    <MenuItem value={stage.id ?? ''} key={stage.name}>
                      {stage.label}
                    </MenuItem>
                  )),
                ],
              ])
              .flatMap(o => o)}
          </Select>
        </FormControl>
      </Box>
      <Flex width={1} flexWrap="wrap">
        <Box width={1} p={2}>
          <FormControl variant="filled">
            <InputLabel shrink>{t('mandateProbability')}</InputLabel>
            <Select
              variant="filled"
              defaultValue={lead?.mandateProbability ?? ''}
              displayEmpty
              onChange={event =>
                updateLeadHelper({
                  mandateProbability:
                    event.target.value != null
                      ? number_of_string(event.target.value as string)
                      : null,
                })
              }
            >
              <MenuItem value="" key={'none'}>
                <i>{t('notSet')}</i>
              </MenuItem>
              {dictionary.mandateProbabilityTypes.map(option => (
                <MenuItem value={option.name} key={option.name}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box width={1} p={2}>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={datePickerLocales[language]}
          >
            <FormControl variant="filled">
              <MobileDatePicker
                views={['year', 'month']}
                openTo="year"
                label={t('predictedListingDate')}
                value={
                  lead?.predictedListingDate
                    ? parseISO(lead?.predictedListingDate ?? '')
                    : null
                }
                onMonthChange={(date: Date) => {
                  updateLeadHelper({
                    predictedListingDate:
                      date != null ? new Date(date).toISOString() : null,
                  });
                }}
                onChange={() => {}}
                closeOnSelect={true}
                slotProps={{
                  textField: {
                    InputProps: {
                      endAdornment:
                        lead?.predictedListingDate != null ? (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              color="inherit"
                              onClick={event => {
                                event.stopPropagation();
                                updateLeadHelper({
                                  predictedListingDate: null,
                                });
                              }}
                            >
                              <Cancel />
                            </IconButton>
                          </InputAdornment>
                        ) : null,
                    },
                  },
                }}
              />
            </FormControl>
          </LocalizationProvider>
        </Box>
        <Box width={1} p={2}>
          <FormControl variant="filled">
            <AutocompleteMultiple
              options={tagsOptions}
              value={tagsOptions.filter(tag => lead?.tags?.includes(tag.value))}
              renderInput={params => (
                <TextField
                  {...params}
                  css={
                    (lead?.tags || []).length > 0 && {
                      '.MuiAutocomplete-inputRoot': {
                        paddingTop: '25px',
                        paddingBottom: '8px',
                      },
                      '.MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
                        paddingLeft: '14px',
                      },
                      '.MuiAutocomplete-endAdornment': {
                        top: '14px',
                      },
                    }
                  }
                  variant="filled"
                  label={t('tags')}
                  placeholder={t('addTag')}
                />
              )}
              onChange={(_event, newValue) => {
                updateLeadHelper({
                  tags: newValue?.map(tag => tag.value),
                });
              }}
            />
          </FormControl>
          {lead.status === 'lost' && (
            <Flex alignItems="center" mt={2}>
              <Box mr={2}>
                <Avatar
                  css={{
                    backgroundColor: colors.error,
                    width: 28,
                    height: 28,
                  }}
                >
                  <Close fill={colors.white} />
                </Avatar>
              </Box>
              <Box css={[text.subtitle1, text.ellipsis]}>
                {t('leadLost')}
                {lead.leadLost?.label && (
                  <span>
                    : <strong>{lead.leadLost.label}</strong>
                  </span>
                )}
              </Box>
            </Flex>
          )}
        </Box>
      </Flex>
    </>
  );
};

export const LeadDetailsQualificationCard = (props: Props) => {
  const { t, intlLocale } = useLocale();

  const lead = useFragment(
    graphql`
      fragment leadQualificationCard_lead on Lead {
        id
        mandateProbability
        predictedListingDate
        tags
        stage {
          id
          label
          pipeline {
            id
            label
          }
        }
        status
        leadLost {
          id
          label
        }
      }
    `,
    props.lead,
  );

  const dictionary = useFragment(
    graphql`
      fragment leadQualificationCard_root on Query {
        mandateProbabilityTypes: dictionaries(
          type: "mandate_probability_types"
        ) {
          name
          label
        }
        tagsTypes: dictionaries(type: "tags_types") {
          id
          name
          label
        }
        pipelinesStages: pipelines(leadType: sales) {
          id
          label
          stages {
            id
            label
            name
          }
        }
      }
    `,
    props.root,
  );

  // expand if probability is set
  const hasProbability = lead.mandateProbability != null;
  const [expanded, setExpanded] = React.useState(
    hasProbability || props.defaultExpanded,
  );

  const dateTimeFormat = new Intl.DateTimeFormat(intlLocale, {
    month: 'long',
    year: 'numeric',
  });

  const subTitle = [];
  lead.tags != null && subTitle.push(lead.stage?.label);

  const mandateProbabilityLabel = dictionary.mandateProbabilityTypes.find(
    item => number_of_string(item.name) === lead.mandateProbability,
  )?.label;
  if (mandateProbabilityLabel != null) {
    subTitle.push(mandateProbabilityLabel);
  }
  lead.predictedListingDate != null &&
    subTitle.push(dateTimeFormat.format(new Date(lead.predictedListingDate)));

  return (
    <Accordion
      expanded={expanded}
      onChange={(_event, expanded) => {
        setExpanded(expanded);
      }}
    >
      <AccordionCardSummary
        expanded={expanded}
        title={t('details')}
        subTitle={subTitle.join(', ').substring(0, 26)}
      />
      <Box p={2}>
        <LeadQualificationForm
          lead={lead}
          dictionary={dictionary}
          key={subTitle.join(', ')}
        />
      </Box>
    </Accordion>
  );
};
