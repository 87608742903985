import * as React from 'react';
import { useScript } from './script';

/*

user presses "get verification code"
v3 recaptcha token is requested
token is passed to mutation
captcha error in response
render visible captcha
-> user passes captcha and token is saved to state
|  user presses "get verification code"
|  token from visible captcha is passed to mutation
|  captcha error in response
<- reset visible captcha

Usage example:

const captcha = useCaptcha()
captcha.requestToken((token, version) => {
  signIn({ token, version, phoneNumber }, error => {
    if (error) {
      captcha.verifyUser()
    }
  })
})
return <>{captcha.element}</>

*/

type Params = {
  reCaptchaKeyV3: string;
  reCaptchaKeyV2: string;
};

type TokenCallback = (token: string, version: 'v2' | 'v3') => void;

type CaptchaApi = {
  requestToken: (callback: TokenCallback) => void;
  verifyUser: () => void;
  element: React.ReactNode;
};

type State = { version: 'v2'; token: string } | { version: 'v3' };

const useMounted = () => {
  const mounted = React.useRef(true);
  const isMounted = React.useCallback(() => mounted.current, []);
  React.useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);
  return isMounted;
};

type ReadyCallback = () => void;

const useReady = (reCaptchaKey: string) => {
  const state = useScript(`${googleRecaptchaSrc}?render=${reCaptchaKey}`);
  const readyRef = React.useRef(false);
  const queueRef = React.useRef<Array<ReadyCallback>>([]);
  const whenReady = React.useCallback((callback: ReadyCallback) => {
    if (readyRef.current) {
      callback();
    } else {
      queueRef.current.push(callback);
    }
  }, []);
  // flush queue
  React.useEffect(() => {
    if (state === 'done') {
      window.grecaptcha.ready(() => {
        const queue = queueRef.current;
        queueRef.current = [];
        readyRef.current = true;
        for (const fn of queue) {
          fn();
        }
      });
    }
  }, [state]);
  return whenReady;
};

const googleRecaptchaSrc = 'https://www.google.com/recaptcha/api.js';

const CaptchaContainer = ({
  container,
  reset,
}: {
  container: { current: null | HTMLDivElement };
  reset: () => void;
}) => {
  // reset when captcha container is not rendered
  React.useEffect(() => {
    return () => {
      reset();
    };
  }, []);
  return <div id="" ref={container} className="g-recaptcha" />;
};

export const useCaptcha = (params: Params): CaptchaApi => {
  const { reCaptchaKeyV2, reCaptchaKeyV3 } = params;
  const whenReady = useReady(reCaptchaKeyV3);
  const [state, setState] = React.useState<State>({ version: 'v3' });
  const isMounted = useMounted();

  const requestToken = (callback: TokenCallback) => {
    // return cached token from visible captcha after user verification
    if (state.version === 'v2') {
      if (state.token != null) {
        callback(state.token, 'v2');
      }
    }
    // request token from invisible captcha
    if (state.version === 'v3') {
      whenReady(() => {
        window.grecaptcha
          .execute(reCaptchaKeyV3, { action: 'phone_sign_in' })
          .then((token: string) => {
            if (isMounted()) {
              callback(token, 'v3');
            }
          });
      });
    }
  };

  const container = React.useRef<null | HTMLDivElement>(null);
  const instanceId = React.useRef<null | number>(null);
  const [captchaRendered, setCaptchaRendered] = React.useState(false);
  const verifyUser = () => {
    // render or reset already rendered recaptcha
    if (captchaRendered) {
      whenReady(() => {
        if (instanceId.current != null) {
          window.grecaptcha.reset(instanceId.current);
        }
      });
    } else {
      setCaptchaRendered(true);
    }
  };
  React.useEffect(() => {
    if (captchaRendered) {
      if (container.current == null) {
        throw Error('captcha.element must be rendered during verifyUser call');
      }
      const containerElement = container.current;
      whenReady(() => {
        instanceId.current = window.grecaptcha.render(containerElement, {
          sitekey: reCaptchaKeyV2,
          theme: 'light',
          size: 'normal',
          badge: 'bottomright',
          tabindex: 0,
          // return cached token next time
          callback: token => {
            if (isMounted()) {
              setState({ version: 'v2', token });
            }
          },
        });
      });
      return () => {
        whenReady(() => {
          if (instanceId.current != null) {
            window.grecaptcha.reset(instanceId.current);
          }
        });
      };
    }
  }, [whenReady, isMounted, reCaptchaKeyV2, captchaRendered]);

  const resetCaptcha = React.useCallback(() => {
    if (instanceId.current != null) {
      window.grecaptcha.reset(instanceId.current);
    }
    setState({ version: 'v3' });
    setCaptchaRendered(false);
  }, []);

  const element = captchaRendered && (
    <CaptchaContainer container={container} reset={resetCaptcha} />
  );
  return {
    requestToken,
    verifyUser,
    element,
  };
};
