import * as React from 'react';
import type { Interpolation } from '@emotion/react';

type Props = {
  count: number;
  visibleCount: number;
  // 0..1
  dx: number;
  // css support to reduce amount of divs
  css?: Interpolation<unknown>;
  className?: string;
};

// Piecewise linear function with zero tan on ends.
// Basically same as find linear interval for x, then approximate with linear function on interval
const createLinearK = (pointsArg: ReadonlyArray<[number, number]>) => {
  const points: Array<[number, number]> = [];
  let max = -1;

  for (const pt of pointsArg) {
    if (pt[0] > max) {
      max = pt[0];
      points.push(pt);
    }
  }

  const k = [0];
  for (let i = 1; i < points.length; i += 1) {
    k.push(
      (points[i][1] - points[i - 1][1]) / (points[i][0] - points[i - 1][0]),
    );
  }
  k.push(0);

  const fn = (b: number) => (x: number) => {
    let sum = b;
    for (let i = 0; i < points.length; i += 1) {
      sum += 0.5 * (k[i + 1] - k[i]) * Math.abs(x - points[i][0]);
    }
    return sum;
  };

  const b = points[0][1] - fn(0)(points[0][0]);

  return (x: number) => Math.round(fn(b)(x) * 1000) / 1000;
};

const CIRCLE_MAX_R = 14;
const CIRCLE_MED_R = 10;
const CIRCLE_MIN_R = 6;
const CIRCLE_EDGE_R = 3;

const CIRCLE_GAP = 2;
// To look good on retina we use 50% transform for gradients
// So real px size doesnt need to be x2
const SIZE = CIRCLE_MAX_R + CIRCLE_GAP;

const sizeToOpacity = createLinearK([
  [CIRCLE_EDGE_R, 0],
  [CIRCLE_MIN_R, 0.6],
  [CIRCLE_MED_R, 0.8],
  [CIRCLE_MAX_R, 1],
]);

// To reduce 1 div in carousel we export this method
export const getPaginatorWidth = (props: {
  count: number;
  visibleCount: number;
}): number => {
  const visibleCount = Math.min(props.count, props.visibleCount);
  return visibleCount * SIZE;
};

export const Paginator = (props: Props) => {
  const visibleCount = Math.min(props.count, props.visibleCount);
  const visibleWidth = getPaginatorWidth({
    count: props.count,
    visibleCount: props.visibleCount,
  });

  // Prepare visibleCount radial gradients  background (same as draw visibleCount circles).
  // gradient color and size depends on css variable for each circle
  // Moving of circles we emulate with backgroundPositionX
  const cssStyle = React.useMemo(() => {
    const gradients = Array.from(
      Array(visibleCount),

      (_, index) => `
      radial-gradient(var(--size-${index}) circle at ${
        SIZE / 2 + SIZE * index
      }px ${CIRCLE_MAX_R / 2}px, var(--color-${index}) 46%, transparent 54%)
        `,
    ).join(', ');

    return {
      width: visibleWidth,
      height: CIRCLE_MAX_R,
      background: gradients,
      backgroundPositionX: 'var(--bg-position)',
    };
  }, [visibleCount, visibleWidth]);

  if (props.count <= 1) {
    return null;
  }
  if (process.env.NODE_ENV !== 'production') {
    if (props.visibleCount <= 1) {
      throw new Error(
        `Paginator with visibleCount=${props.visibleCount} is useless`,
      );
    }
  }

  // At every monent we see visibleCount points on screen
  // during Math.floor(visibleCount / 2) steps at begin and end there is no
  // move of view beginning
  const viewStartFn = createLinearK([
    [0, 0],
    [Math.floor(visibleCount / 2), 0],
    [
      props.count - Math.floor(visibleCount / 2) - 1,
      props.count - visibleCount,
    ],
  ]);

  // Center (Big) point is moving at Math.floor(visibleCount / 2) steps at begin and end,
  // but always at center during other moves.
  const viewCenterFn = createLinearK([
    [0, 0],
    [Math.floor(visibleCount / 2), Math.floor(visibleCount / 2)],
    [
      props.count - Math.floor(visibleCount / 2) - 1,
      Math.floor(visibleCount / 2),
    ],
    [props.count - 1, visibleCount - 1],
  ]);

  // Below the math to calculate bg offset and point sizes
  // FOr size its piecewise linear function looking like:
  //           \
  //            ‾‾‾‾\
  //                 \/
  // To calculate size of point based on position from center
  // \/ at the end is the background drawing trick.
  // Points out of left bound moved with backgroundPosition are drawn at the right
  // see background-repeat css property

  const start = viewStartFn(props.dx * (props.count - 1));
  const center = viewCenterFn(props.dx * (props.count - 1));
  const positionOffset = start - Math.floor(start);
  const cx = center + positionOffset;
  const l = center - Math.floor(visibleCount / 2);
  const h = Math.floor(visibleCount / 2);

  const pts: Array<[number, number]> = [
    [0, CIRCLE_MAX_R],
    [1, CIRCLE_MED_R],
  ];
  if (h > 1) {
    pts.push(
      [h + Math.abs(l) - 1, CIRCLE_MED_R],
      [h + Math.abs(l), CIRCLE_MIN_R],
    );
  }
  pts.push(
    [h + Math.abs(l) + 0.48, CIRCLE_EDGE_R],
    [h + Math.abs(l) + 0.52, CIRCLE_EDGE_R],
  );
  if (h > 1) {
    pts.push([h + Math.abs(l) + 1, CIRCLE_MIN_R]);
  } else {
    pts.push([h + Math.abs(l) + 1, CIRCLE_MED_R]);
  }

  const leftFn = createLinearK(pts);

  // Finally get css variable
  const styles: React.CSSProperties = {
    // @ts-ignore custom properties are not defined in React.CSSProperties
    '--bg-position': `${-SIZE * positionOffset}px`,
  };
  Array.from(Array(visibleCount)).forEach((_, index) => {
    const d = Math.abs(cx - index);

    const radius = leftFn(d);
    const opacity = sizeToOpacity(radius);
    const color = `rgba(255, 255, 255, ${opacity})`;

    // @ts-ignore custom properties are not defined in React.CSSProperties
    styles[`--size-${index}`] = `${radius}px`;
    // @ts-ignore custom properties are not defined in React.CSSProperties
    styles[`--color-${index}`] = color;
  });

  return (
    <div
      className={props.className}
      style={styles}
      // css is static during all the execution
      css={cssStyle}
    />
  );
};
