/**
 * @generated SignedSource<<30d64f7200f42f930aa3972a7d6335ef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type propertySearchDrawerTypesQuery$variables = {};
export type propertySearchDrawerTypesQuery$data = {
  readonly propertyTypes: ReadonlyArray<{
    readonly id: string;
    readonly label: string | null;
    readonly mainType: string | null;
  }> | null;
  readonly tenantSettings: {
    readonly defaultMapCoordinates: {
      readonly lat: number;
      readonly lng: number;
      readonly z: number;
    };
  } | null;
};
export type propertySearchDrawerTypesQuery = {
  response: propertySearchDrawerTypesQuery$data;
  variables: propertySearchDrawerTypesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "MapPositionWithZoom",
  "kind": "LinkedField",
  "name": "defaultMapCoordinates",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lng",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lat",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "z",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "PropertyType",
  "kind": "LinkedField",
  "name": "propertyTypes",
  "plural": true,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mainType",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "propertySearchDrawerTypesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantSettings",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      (v2/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "propertySearchDrawerTypesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantSettings",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      (v2/*: any*/)
    ]
  },
  "params": {
    "cacheID": "78ea8dc77c0d60158df1f614f79dd879",
    "id": null,
    "metadata": {},
    "name": "propertySearchDrawerTypesQuery",
    "operationKind": "query",
    "text": "query propertySearchDrawerTypesQuery {\n  tenantSettings {\n    defaultMapCoordinates {\n      lng\n      lat\n      z\n    }\n    id\n  }\n  propertyTypes {\n    id\n    label\n    mainType\n  }\n}\n"
  }
};
})();

(node as any).hash = "346013c220080aa3fb5dcdb23e9dd6de";

export default node;
