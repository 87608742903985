// @flow

import * as React from 'react';

import { graphql, useFragment, useMutation } from 'react-relay';

import { Checkbox } from '../controls/checkbox';
import { useLocale } from '../hooks/locale';

import type { propertyImageQuickEdit_image$key } from './__generated__/propertyImageQuickEdit_image.graphql';
import type { propertyImageQuickEditUpdateMutation } from './__generated__/propertyImageQuickEditUpdateMutation.graphql';

type Props = {|
  image: propertyImageQuickEdit_image$key,
|};

export const PropertyImageQuickEdit = (props: Props): React.Node => {
  const image = useFragment(
    graphql`
      fragment propertyImageQuickEdit_image on PropertyImage {
        id
        displayOnListing
        displayOnBrochure
      }
    `,
    props.image,
  );
  const [updatePropertyImage] =
    useMutation<propertyImageQuickEditUpdateMutation>(
      graphql`
        mutation propertyImageQuickEditUpdateMutation(
          $input: UpdatePropertyImageInput!
        ) {
          updatePropertyImage(input: $input) {
            propertyImage {
              ...propertyImageQuickEdit_image
            }
          }
        }
      `,
    );

  const { t } = useLocale();

  return (
    <div>
      <Checkbox
        color="primary"
        size="small"
        checked={image.displayOnListing}
        onChange={checked => {
          updatePropertyImage({
            variables: {
              input: {
                id: image.id,
                displayOnListing: checked,
              },
            },
          });
        }}
      >
        {t('displayOnListing')}
      </Checkbox>
      <Checkbox
        color="primary"
        size="small"
        checked={image.displayOnBrochure}
        onChange={checked => {
          updatePropertyImage({
            variables: {
              input: {
                id: image.id,
                displayOnBrochure: checked,
              },
            },
          });
        }}
      >
        {t('displayOnBrochure')}
      </Checkbox>
    </div>
  );
};
