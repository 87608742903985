import { useQuery } from '@apollo/client';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useSearchParams } from 'react-router-dom';

import { toGlobalId } from '../../../shared/global-id';
import { useLocale } from '../../../src/hooks/locale';
import { Offers_Status_Enum } from '../../__generated__/graphql';
import { GET_LISTING_OFFERS_QUERY } from '../../pages/listings/ListingOffers';
import { CountActionCard, CountActionCardSkeleton } from '../CountActionCard';
import { ListingAccordion } from '../ListingAccordion';

interface ListingOffersCardProps {
  listingId?: string | null;
  openedDefault?: boolean;
  useLegacyLinks?: boolean;
}

export const ListingOffersCardSkeleton: React.FC<{
  openedDefault?: boolean;
}> = ({ openedDefault }) => {
  const { t } = useLocale();
  return (
    <ListingAccordion title={t('Offers')} defaultExpanded={openedDefault}>
      <CountActionCardSkeleton
        countLabels={[t('Pending'), t('Refused'), t('Accepted')]}
        actions={[
          {
            label: t('Add offer'),
            icon: <AddCircleOutlineOutlinedIcon />,
          },
          {
            label: t('View offers'),
            icon: <VisibilityOutlinedIcon />,
          },
        ]}
      />
    </ListingAccordion>
  );
};

export const ListingOffersCard: React.FC<ListingOffersCardProps> = ({
  listingId,
  openedDefault = false,
  useLegacyLinks = true,
}) => {
  const { t } = useLocale();
  const [searchParams] = useSearchParams();
  const { data, loading } = useQuery(GET_LISTING_OFFERS_QUERY, {
    variables: { listingId: listingId ?? '' },
    skip: listingId == null,
  });

  const acceptedCount =
    data?.offers.filter(({ status }) => status === Offers_Status_Enum.Accepted)
      .length ?? 0;
  const pendingCount =
    data?.offers.filter(({ status }) => status === Offers_Status_Enum.Pending)
      .length ?? 0;
  const refusedCount =
    data?.offers.filter(({ status }) => status === Offers_Status_Enum.Refused)
      .length ?? 0;

  return (
    <ListingAccordion title={t('Offers')} defaultExpanded={openedDefault}>
      <CountActionCard
        isLoading={loading || listingId == null}
        countElements={[
          { label: t('Pending'), value: pendingCount },
          {
            label: t('Refused'),
            value: refusedCount,
          },
          { label: t('Accepted'), value: acceptedCount },
        ]}
        actions={[
          {
            label: t('Add offer'),
            icon: <AddCircleOutlineOutlinedIcon />,
            link:
              listingId != null
                ? {
                    to: useLegacyLinks
                      ? `/listings/${toGlobalId('Lot', listingId)}/offers/add`
                      : `/v2/listings/${listingId}/offers/new`,
                    target: useLegacyLinks ? '_blank' : '_self',
                    search: searchParams.toString(),
                  }
                : undefined,
          },
          {
            label: t('View offers'),
            icon: <VisibilityOutlinedIcon />,
            link:
              listingId != null
                ? {
                    to: useLegacyLinks
                      ? `/listings/${toGlobalId('Lot', listingId)}/offers`
                      : `/v2/listings/${listingId}/offers`,
                    target: useLegacyLinks ? '_blank' : '_self',
                    search: searchParams.toString(),
                  }
                : undefined,
          },
        ]}
      />
    </ListingAccordion>
  );
};
