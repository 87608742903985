import { type ReactNode, useCallback, useEffect, useRef } from 'react';

import { keyframes } from '@mui/material';

import { customPalette } from '../../../../src/styles';

type InteractiveStepContainerProps = {
  children: ReactNode;
  onInteraction: () => void;
};

const InteractiveStepContainer = ({
  children,
  onInteraction,
}: InteractiveStepContainerProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const handleInteraction = useCallback(
    (e: Event) => {
      const target = e.target as HTMLElement;
      const cardElement = target.closest('[data-card-element]');

      if (cardElement) {
        return;
      }

      requestAnimationFrame(onInteraction);
    },
    [onInteraction],
  );

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    // We need to target the form inside the container without the footer buttons
    const editorFormRef = containerRef.current.querySelector('form > div');

    if (editorFormRef) {
      editorFormRef.addEventListener('click', handleInteraction);
      editorFormRef.addEventListener('keydown', handleInteraction);
    }
    return () => {
      if (editorFormRef) {
        editorFormRef.removeEventListener('click', handleInteraction);
        editorFormRef.removeEventListener('keydown', handleInteraction);
      }
    };
  }, [handleInteraction]);

  const fadeIn = keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  `;

  return (
    <div
      ref={containerRef}
      css={[
        {
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          animation: `.5s ease 1 ${fadeIn}`,
          overflowY: 'auto',
          position: 'relative',
          transition: 'background-color 0.5s ease',
          backgroundColor: customPalette.superLightBlue,
        },
      ]}
    >
      {children}
    </div>
  );
};

export default InteractiveStepContainer;
