import React, { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { Box, Divider, Tab, Tabs, Typography } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { format } from 'date-fns';
import { useSearchParams } from 'react-router-dom';

import { useLocale } from '../../../src/hooks/locale';
import type { GetTeamVisibilityStatsListQueryVariables } from '../../__generated__/graphql';

import { GET_TEAM_VISIBILITY_STATS_LIST } from './visibilityStatsQueries';

type Props = {
  teamId: string | undefined;
  isAdmin?: boolean;
  startDate: Date | null;
  endDate: Date | null;
  hostname: string | null;
  availableTabs?: (
    | 'page_type'
    | 'page_location'
    | 'city'
    | 'country'
    | 'device'
  )[];
};

export const VisibilityList = ({
  teamId,
  isAdmin,
  startDate,
  endDate,
  hostname,
  availableTabs = ['page_type', 'page_location', 'city', 'country', 'device'],
}: Props) => {
  const [searchParams, setSearchParms] = useSearchParams();

  const tab = useMemo(
    () => searchParams.get('tab') ?? 'page_type',
    [searchParams],
  );

  const { t } = useLocale();

  const variables: GetTeamVisibilityStatsListQueryVariables = useMemo(
    () => ({
      teamId: teamId ?? null,
      agentId: null,
      startDate: startDate ? format(startDate, 'yyyyMMdd') : null,
      endDate: endDate ? format(endDate, 'yyyyMMdd') : null,
      groupBy: tab as GetTeamVisibilityStatsListQueryVariables['groupBy'],
      orderBy: 'count',
      orderDirection: 'desc',
      hostname,
    }),
    [teamId, tab, startDate, endDate, hostname],
  );

  const { data, loading } = useQuery(GET_TEAM_VISIBILITY_STATS_LIST, {
    variables,
    skip: !teamId && !isAdmin,
  });

  const tabs = [
    {
      label: t('Page Type'),
      value: 'page_type',
    },
    {
      label: t('Page'),
      value: 'page_location',
    },
    {
      label: t('City'),
      value: 'city',
    },
    {
      label: t('Country'),
      value: 'country',
    },
    {
      label: t('Device'),
      value: 'device',
    },
  ].filter(tab => availableTabs.includes(tab.value as any));

  return (
    <div>
      <Tabs
        value={tab}
        onChange={(event: React.SyntheticEvent, tab: string) => {
          event.preventDefault();
          searchParams.set('tab', tab);
          setSearchParms(searchParams);
        }}
        aria-label="basic tabs example"
      >
        {tabs.map(tab => (
          <Tab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </Tabs>
      <Divider />
      {!teamId && !isAdmin && (
        <Box
          justifyContent="center"
          height="300px"
          display="flex"
          alignItems="center"
        >
          <Typography variant="body1">{t('Select a team')}</Typography>
        </Box>
      )}
      <div>
        <DataGridPro
          loading={loading}
          sx={{
            borderColor: 'transparent',
            '& .MuiDataGrid-virtualScroller': {
              height: data?.list_data?.data?.length === 0 ? 300 : 'auto',
            },
          }}
          columns={[
            {
              field: 'name',
              headerName: 'Name',
              flex: 2,
            },
            {
              field: 'count',
              headerName: 'Impressions',
              flex: 1,
            },
            {
              field: 'user_count',
              headerName: 'Unique Users',
              flex: 1,
            },
            {
              field: 'average_position',
              headerName: 'Avg. Position',
              flex: 1,
              renderCell: ({ value }) => (value ? value.toFixed(2) : ''),
            },
          ]}
          rows={
            data?.list_data?.data?.map(x => ({
              id: x.name ?? 'empty',
              name: x.name ?? t('Undefined'),
              count: x.count,
              user_count: x.user_count,
              average_position: x.average_position,
            })) ?? []
          }
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10, page: 0 },
            },
          }}
          pagination
          pageSizeOptions={[10, 25, 50, 100]}
        />
      </div>
    </div>
  );
};
