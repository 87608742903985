/**
 * @generated SignedSource<<ab41cacf2a10e140558d474162d28f28>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RequestAuthLinkInput = {
  clientMutationId?: string | null;
  code_challenge: string;
  email: string;
  redirect_uri: string;
};
export type LoginEmailFormMutation$variables = {
  input: RequestAuthLinkInput;
};
export type LoginEmailFormMutation$data = {
  readonly requestAuthLink: {
    readonly errors: {
      readonly email: string | null;
    } | null;
  } | null;
};
export type LoginEmailFormMutation = {
  response: LoginEmailFormMutation$data;
  variables: LoginEmailFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RequestAuthLinkPayload",
    "kind": "LinkedField",
    "name": "requestAuthLink",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RequestAuthEmailErrors",
        "kind": "LinkedField",
        "name": "errors",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LoginEmailFormMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LoginEmailFormMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1f0d294a143279a17fc9331c79ab3d50",
    "id": null,
    "metadata": {},
    "name": "LoginEmailFormMutation",
    "operationKind": "mutation",
    "text": "mutation LoginEmailFormMutation(\n  $input: RequestAuthLinkInput!\n) {\n  requestAuthLink(input: $input) {\n    errors {\n      email\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "87934e9a57817a3daff97fcf8474b068";

export default node;
