/**
 * @generated SignedSource<<050f719a6f5abcbb4a5957ffbaf10990>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConnectEmailForm_replyToMessage$data = {
  readonly " $fragmentSpreads": FragmentRefs<"EmailForm_replyToMessage">;
  readonly " $fragmentType": "ConnectEmailForm_replyToMessage";
};
export type ConnectEmailForm_replyToMessage$key = {
  readonly " $data"?: ConnectEmailForm_replyToMessage$data;
  readonly " $fragmentSpreads": FragmentRefs<"ConnectEmailForm_replyToMessage">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ConnectEmailForm_replyToMessage",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EmailForm_replyToMessage"
    }
  ],
  "type": "EmailMessage",
  "abstractKey": null
};

(node as any).hash = "3088117cd68da91bd29a3ad28d921b7e";

export default node;
