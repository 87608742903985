// Generated from src/svgs/nav-saas.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const NavSaas = ({ size, fill, className }: Props) => {
  return (
    <svg viewBox="0 0 25 25" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><path d="M12.5 0a8.048 8.048 0 0 0-7.99 8.49l.499 10.085A1.491 1.491 0 0 0 6.491 20H11v5h3v-5h4.509a1.491 1.491 0 0 0 1.482-1.425L20.49 8.49A8.048 8.048 0 0 0 12.5 0zm.5 24h-1v-4h1zm5.992-5.475a.491.491 0 0 1-.483.475H6.49a.49.49 0 0 1-.483-.474l-.5-10.086a7.068 7.068 0 0 1 1.95-5.271 6.941 6.941 0 0 1 10.082 0 7.067 7.067 0 0 1 1.95 5.271zM12.5 2a6.073 6.073 0 0 0-6.078 6h12.156A6.073 6.073 0 0 0 12.5 2zm1.207 5 1.147-1.147a.5.5 0 0 0-.707-.706L12.293 7H7.532a4.973 4.973 0 0 1 1.283-2.42 5.086 5.086 0 0 1 7.37 0A4.973 4.973 0 0 1 17.468 7zM12.5 12a2.5 2.5 0 1 0 2.5 2.5 2.5 2.5 0 0 0-2.5-2.5zm0 4a1.5 1.5 0 1 1 1.5-1.5 1.502 1.502 0 0 1-1.5 1.5zm2.5-5.5a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 1 .5.5z" /></svg>
  );
}
