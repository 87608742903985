import { RaForm } from '../form/RaForm';
import { EEditListingForms } from '../listing-overview-form/ListingOverviewEditModal';
import { useListingOverviewEditFormDefinition } from '../listing-overview-form/listingOverviewFormDefinition';
import { type LightFormDefinition } from '../listing-overview-form/models';

import {
  type ListingCreationStepComponent,
  type ListingCreationStepComponentProps,
} from './CreateListingStepper';

const ListingRaFormStep: React.FC<
  ListingCreationStepComponentProps & {
    type:
      | EEditListingForms.PRICING
      | EEditListingForms.MANDATE
      | EEditListingForms.TITLE_DESCRIPTION;
  }
> = ({ footerActionsComponent, onFormSubmitted, listing, type }) => {
  //  We must cast back to the generic LightFormDefinition type here because otherwise RaForm cannot infer the right type for the different fields.
  const formDefinition = useListingOverviewEditFormDefinition({
    listing,
    formType: type,
  }) as LightFormDefinition;

  if (formDefinition == null) {
    return null;
  }

  return (
    <RaForm
      formDefinition={formDefinition.formDefinition}
      onSubmit={data =>
        onFormSubmitted({
          action: 'update',
          ...formDefinition.transformData(data),
        })
      }
      defaultValues={formDefinition.defaultValues}
      validate={formDefinition.validate}
      actionButtonsComponent={footerActionsComponent}
      gridProps={{
        alignItems: 'end',
      }}
      contentScrollable
    />
  );
};

export const ListingPriceStep: ListingCreationStepComponent = ({
  footerActionsComponent,
  onFormSubmitted,
  listing,
}) => (
  <ListingRaFormStep
    footerActionsComponent={footerActionsComponent}
    onFormSubmitted={onFormSubmitted}
    listing={listing}
    type={EEditListingForms.PRICING}
  />
);

export const ListingMandateStep: ListingCreationStepComponent = ({
  footerActionsComponent,
  onFormSubmitted,
  listing,
}) => (
  <ListingRaFormStep
    footerActionsComponent={footerActionsComponent}
    onFormSubmitted={onFormSubmitted}
    listing={listing}
    type={EEditListingForms.MANDATE}
  />
);

export const ListingDescriptionStep: ListingCreationStepComponent = ({
  footerActionsComponent,
  onFormSubmitted,
  listing,
}) => (
  <ListingRaFormStep
    footerActionsComponent={footerActionsComponent}
    onFormSubmitted={onFormSubmitted}
    listing={listing}
    type={EEditListingForms.TITLE_DESCRIPTION}
  />
);
