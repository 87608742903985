import { File } from '../icons/file';
import { FileExcel } from '../icons/file-excel';
import { FileImage } from '../icons/file-image';
import { FilePdf } from '../icons/file-pdf';
import { FileWord } from '../icons/file-word';

type Props = {
  mimetype: string | null;
  className?: string;
};

export const FileIcon = ({ mimetype, className }: Props) => {
  if (mimetype == null) {
    return <File className={className} />;
  }
  if (mimetype === 'application/pdf') {
    return <FilePdf className={className} />;
  }
  if (mimetype.includes('image')) {
    return <FileImage className={className} />;
  }
  if (mimetype.includes('ms-excel') || mimetype.includes('spreadsheetml')) {
    return <FileExcel className={className} />;
  }
  if (mimetype.includes('msword')) {
    return <FileWord className={className} />;
  }
  return <File className={className} />;
};
