import { useCallback, useState } from 'react';

import { useFragment as useApolloFragment } from '@apollo/client';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import { type UseFormSetValue } from 'react-hook-form';

import { useDebouncedHandler } from '../../../src/hooks/debounce';
import { useLocale } from '../../../src/hooks/locale';
import { getCurrencyByCountryCode } from '../../../src/locale';
import { number_of_string } from '../../../src/utils/number-format';
import { type Cma_Reports_Set_Input } from '../../__generated__/graphql';
import { Drawer } from '../../components/drawer/Drawer';
import {
  type FormDefinitionType,
  RaForm,
  type RaFormOnChange,
} from '../../components/form/RaForm';
import { PropertyForm } from '../../components/property-form/PropertyForm';
import { getCurrencySymbol } from '../../utils/formatting';

import { STEP_OTHER_VALUATIONS_FRAGMENT } from './cmaReportsQueries';
import {
  type CMAReportComponentProps,
  FooterActions,
  useUpdateCmaReport,
} from './shared';

type StepOtherValuationsForm = {
  include_cuprate_valuation: boolean;
  caprate_estimated_monthly_rent: number | null | undefined;
  caprate_minimum_required_yield: number | null | undefined;
  include_intrinsic_valuation: boolean;
  construction_cost_base: Cma_Reports_Set_Input['construction_cost_base'];
  intrinsic_building_volume: number | null | undefined;
  intrinsic_building_surface: number | null | undefined;
  intrinsic_construction_cost: number | null | undefined;
  intrinsic_construction_year: Date | null | undefined;
  intrinsic_renovation_year: Date | null | undefined;
  intrinsic_annual_deprecation_rate: number | null | undefined;
  intrinsic_land_surface: number | null | undefined;
  intrinsic_land_value: number | null | undefined;
};

const StepOtherValuations = (props: CMAReportComponentProps) => {
  const { t, locale, countryCode } = useLocale();
  const { cmaReportId } = props;
  const [open, setOpen] = useState(false);

  const { data, complete } = useApolloFragment({
    fragment: STEP_OTHER_VALUATIONS_FRAGMENT,
    fragmentName: 'StepOtherValuations',
    from: {
      __typename: 'cma_reports',
      id: cmaReportId,
    },
  });

  const [updateCmaReport, updating] = useUpdateCmaReport(
    cmaReportId,
    'page-other-valuations',
  );

  const update = useCallback(
    async (formData?: Partial<StepOtherValuationsForm>) => {
      await updateCmaReport({
        include_cuprate_valuation: formData?.include_cuprate_valuation,
        caprate_estimated_monthly_rent:
          formData?.caprate_estimated_monthly_rent,
        caprate_minimum_required_yield:
          formData?.caprate_minimum_required_yield,
        include_intrinsic_valuation: formData?.include_intrinsic_valuation,
        construction_cost_base: formData?.construction_cost_base,
        intrinsic_building_volume: formData?.intrinsic_building_volume,
        intrinsic_building_surface: formData?.intrinsic_building_surface,
        intrinsic_construction_cost: formData?.intrinsic_construction_cost,
        intrinsic_construction_year:
          formData?.intrinsic_construction_year?.getFullYear(),
        intrinsic_renovation_year:
          formData?.intrinsic_renovation_year?.getFullYear(),
        intrinsic_annual_deprecation_rate:
          formData?.intrinsic_annual_deprecation_rate,
        intrinsic_land_surface: formData?.intrinsic_land_surface,
        intrinsic_land_value: formData?.intrinsic_land_value,
      });
    },
    [updateCmaReport],
  );

  const debouncedUpdate = useDebouncedHandler(300, update);

  const fillCaprateFields = useCallback(
    (
      checked: boolean,
      setValue: UseFormSetValue<StepOtherValuationsForm>,
      formData: StepOtherValuationsForm,
    ) => {
      const latestAppraisal = data?.lead?.property?.latest_appraisal;

      if (!latestAppraisal?.value || checked === false) {
        setValue('include_cuprate_valuation', checked);
        return debouncedUpdate({
          ...formData,
          include_cuprate_valuation: checked,
        });
      }

      const fieldsToUpdate = {
        include_cuprate_valuation: checked,
        caprate_estimated_monthly_rent: latestAppraisal.rent_value,
        caprate_minimum_required_yield: number_of_string(
          (
            ((12 * (latestAppraisal.rent_value ?? 0)) /
              (latestAppraisal.value ?? 0)) *
            100
          ).toFixed(2),
        ),
      };

      Object.entries(fieldsToUpdate).forEach(([key, value]) => {
        setValue(key as keyof typeof fieldsToUpdate, value, {
          shouldValidate: false,
          shouldDirty: true,
          shouldTouch: true,
        });
      });

      debouncedUpdate({ ...formData, ...fieldsToUpdate });
    },
    [data?.lead?.property?.latest_appraisal, debouncedUpdate],
  );

  const fillIntrinsicFields = useCallback(
    (
      checked: boolean,
      setValue: UseFormSetValue<StepOtherValuationsForm>,
      formData: StepOtherValuationsForm,
    ) => {
      const property = data?.lead?.property;
      if (!property || checked === false) {
        setValue('include_intrinsic_valuation', checked);
        return debouncedUpdate({
          ...formData,
          include_intrinsic_valuation: checked,
        });
      }

      const fieldsToUpdate = {
        include_intrinsic_valuation: checked,
        construction_cost_base:
          'volume' as Cma_Reports_Set_Input['construction_cost_base'],
        intrinsic_building_volume: property.building_volume,
        intrinsic_building_surface: Math.max(
          property.living_surface ?? 0,
          property.usable_surface ?? 0,
          property.basement_surface ?? 0,
          property.commercial_surface ?? 0,
          property.balcony_surface ?? 0,
          property.garden_surface ?? 0,
          property.gross_floor_surface ?? 0,
          property.residential_surface ?? 0,
          property.terrace_surface ?? 0,
          property.weighted_floor_surface ?? 0,
        ),
        intrinsic_construction_year: new Date(
          property.construction_year ?? 0,
          0,
          1,
        ),
        intrinsic_renovation_year: new Date(
          property.renovation_year ?? 0,
          0,
          1,
        ),
        intrinsic_land_surface: property.land_surface,
      };

      Object.entries(fieldsToUpdate).forEach(([key, value]) => {
        setValue(key as keyof typeof fieldsToUpdate, value, {
          shouldValidate: false,
          shouldDirty: true,
          shouldTouch: true,
        });
      });

      debouncedUpdate({ ...formData, ...fieldsToUpdate });
    },
    [data?.lead?.property, debouncedUpdate],
  );

  const onChangeHandler: RaFormOnChange<StepOtherValuationsForm> = useCallback(
    (formData, name, setValue) => {
      if (name === 'include_cuprate_valuation') {
        fillCaprateFields(
          formData?.include_cuprate_valuation ?? false,
          setValue,
          formData as StepOtherValuationsForm,
        );
      } else if (name === 'include_intrinsic_valuation') {
        fillIntrinsicFields(
          formData?.include_intrinsic_valuation ?? false,
          setValue,
          formData as StepOtherValuationsForm,
        );
      } else {
        debouncedUpdate(formData);
      }
    },
    [fillCaprateFields, fillIntrinsicFields, debouncedUpdate],
  );

  const stepOtherValuationsFormDefinition = useCallback<
    FormDefinitionType<StepOtherValuationsForm>
  >(
    ({ t }) => [
      {
        type: 'custom',
        name: 'property-details',
        element: (
          <>
            <Typography variant="subtitle2" fontWeight={700}>
              {t('Property')}
            </Typography>
            <Box py={1 / 2}>
              <Button
                endIcon={<ChevronRight />}
                size="small"
                onClick={() => setOpen(true)}
                sx={{ display: 'inline-flex' }}
              >
                {t('editPropertyDetails')}
              </Button>
            </Box>
          </>
        ),
      },
      {
        type: 'category-title',
        label: t('capRateValuation'),
        gridProps: { md: 12 },
        name: 'caprate-valuation',
      },
      {
        name: 'include_cuprate_valuation',
        label: t('includeInReport'),
        type: 'checkbox',
        style: 'switch',
        gridProps: { md: 12 },
      },
      {
        name: 'caprate_estimated_monthly_rent',
        label: t('estimatedMonthlyRent'),
        type: 'number',
        prefix: getCurrencySymbol(
          getCurrencyByCountryCode(countryCode),
          locale,
        ),
        gridProps: { md: 12 },
        min: 0,
        max: 999_999_999,
        disabled: (formData: StepOtherValuationsForm) =>
          !formData.include_cuprate_valuation,
      },
      {
        name: 'caprate_minimum_required_yield',
        label: t('minimumRequiredYield'),
        type: 'number',
        min: 0,
        max: 100,
        suffix: '%',
        decimalNumbers: 2,
        gridProps: { md: 12 },
        disabled: (formData: StepOtherValuationsForm) =>
          !formData.include_cuprate_valuation,
      },
      {
        type: 'category-title',
        label: t('intrinsicValuation'),
        gridProps: { md: 12 },
        name: 'intrinsic-valuation',
      },
      {
        name: 'include_intrinsic_valuation',
        label: t('includeInReport'),
        type: 'checkbox',
        style: 'switch',
        gridProps: { md: 12 },
      },
      {
        name: 'construction_cost_base',
        label: t('constructionCostBasedOn'),
        type: 'select',
        options: () => [
          { value: 'volume', label: t('volume') },
          { value: 'surface', label: t('surface') },
        ],
        gridProps: { md: 12 },
        disabled: (formData: StepOtherValuationsForm) =>
          !formData.include_intrinsic_valuation,
      },
      {
        name: 'intrinsic_building_volume',
        label: t('buildingVolume'),
        type: 'number',
        suffix: 'm³',
        min: 40,
        max: 20_000,
        decimalNumbers: 1,
        gridProps: { md: 12 },
        render: (formData: StepOtherValuationsForm) =>
          formData.construction_cost_base === 'volume',
        disabled: (formData: StepOtherValuationsForm) =>
          !formData.include_intrinsic_valuation,
      },
      {
        name: 'intrinsic_building_surface',
        label: t('surface'),
        type: 'number',
        suffix: 'm²',
        decimalNumbers: 1,
        min: 0,
        max: 50_000,
        gridProps: { md: 12 },
        render: (formData: StepOtherValuationsForm) =>
          formData.construction_cost_base === 'surface',
        disabled: (formData: StepOtherValuationsForm) =>
          !formData.include_intrinsic_valuation,
      },
      {
        name: 'intrinsic_construction_cost',
        label: t('constructionCostPerSquareMeter'),
        type: 'number',
        gridProps: { md: 12 },
        prefix: getCurrencySymbol(
          getCurrencyByCountryCode(countryCode),
          locale,
        ),
        render: (formData: StepOtherValuationsForm) =>
          formData.construction_cost_base === 'surface',
        disabled: (formData: StepOtherValuationsForm) =>
          !formData.include_intrinsic_valuation,
      },
      {
        name: 'intrinsic_construction_cost',
        label: t('constructionCostPerCubicMeter'),
        type: 'number',
        gridProps: { md: 12 },
        prefix: getCurrencySymbol(
          getCurrencyByCountryCode(countryCode),
          locale,
        ),
        render: (formData: StepOtherValuationsForm) =>
          formData.construction_cost_base !== 'surface',
        disabled: (formData: StepOtherValuationsForm) =>
          !formData.include_intrinsic_valuation,
      },
      {
        name: 'intrinsic_construction_year',
        label: t('constructionYear'),
        type: 'date-select',
        gridProps: { md: 12 },
        views: ['year'],
        datePickerProps: {
          disableFuture: true,
        },
        disabled: (formData: StepOtherValuationsForm) =>
          !formData.include_intrinsic_valuation,
      },
      {
        name: 'intrinsic_renovation_year',
        label: t('renovationYear'),
        type: 'date-select',
        gridProps: { md: 12 },
        views: ['year'],
        datePickerProps: {
          disableFuture: true,
        },
        disabled: (formData: StepOtherValuationsForm) =>
          !formData.include_intrinsic_valuation,
      },
      {
        name: 'intrinsic_annual_deprecation_rate',
        label: t('annualDeprecationRate'),
        type: 'number',
        suffix: '%',
        min: 0,
        max: 100,
        decimalNumbers: 2,
        gridProps: { md: 12 },
        disabled: (formData: StepOtherValuationsForm) =>
          !formData.include_intrinsic_valuation,
      },
      {
        name: 'intrinsic_land_surface',
        label: t('landSurface'),
        type: 'number',
        min: 0,
        max: 500_000,
        suffix: 'm²',
        gridProps: { md: 12 },
        disabled: (formData: StepOtherValuationsForm) =>
          !formData.include_intrinsic_valuation,
      },
      {
        name: 'intrinsic_land_value',
        label: t('landValuePerSquareMeter'),
        type: 'number',
        prefix: getCurrencySymbol(
          getCurrencyByCountryCode(countryCode),
          locale,
        ),
        gridProps: { md: 12 },
        disabled: (formData: StepOtherValuationsForm) =>
          !formData.include_intrinsic_valuation,
      },
    ],
    [locale, countryCode],
  );

  const onSubmit = async (formData: StepOtherValuationsForm) =>
    update(formData);

  return (
    <>
      <Drawer open={open} onClose={() => setOpen(false)} title={t('property')}>
        <PropertyForm propertyId={data?.lead?.property?.id} />
      </Drawer>
      <Box
        sx={{
          flexGrow: 1,
          position: 'relative',
          overflowY: 'auto',
          p: !complete ? 2 : undefined,
        }}
      >
        {!complete ? (
          <Stack gap={4}>
            <Stack gap={1}>
              <Skeleton variant="rounded" width={'30%'} height={30} />
              <Skeleton variant="rounded" width={'50%'} height={40} />
            </Stack>
            <Stack gap={2}>
              <Skeleton variant="rounded" height={20} />
              <Stack direction={'row'}>
                <Skeleton
                  variant="circular"
                  width={24}
                  height={24}
                  sx={{ mr: 2 }}
                />
                <Skeleton variant="rounded" width={'30%'} height={24} />
              </Stack>
              <Stack gap={3}>
                {Array.from({ length: 2 }).map((_, index) => (
                  <Skeleton
                    variant="rounded"
                    key={`caprate-${index}`}
                    height={50}
                  />
                ))}
              </Stack>
            </Stack>
            <Stack gap={2}>
              <Skeleton variant="rounded" height={20} />
              <Stack direction={'row'}>
                <Skeleton
                  variant="circular"
                  width={24}
                  height={24}
                  sx={{ mr: 2 }}
                />
                <Skeleton variant="rounded" width={'30%'} height={24} />
              </Stack>
              <Stack gap={3}>
                {Array.from({ length: 2 }).map((_, index) => (
                  <Skeleton
                    variant="rounded"
                    key={`intrinsic-${index}`}
                    height={50}
                  />
                ))}
              </Stack>
            </Stack>
          </Stack>
        ) : (
          <RaForm
            freezeInitialDefaultValues={true}
            formDefinition={stepOtherValuationsFormDefinition}
            defaultValues={{
              include_cuprate_valuation:
                data?.include_cuprate_valuation ?? false,
              caprate_estimated_monthly_rent:
                data?.caprate_estimated_monthly_rent,
              caprate_minimum_required_yield:
                data?.caprate_minimum_required_yield,
              include_intrinsic_valuation:
                data?.include_intrinsic_valuation ?? false,
              construction_cost_base:
                data?.construction_cost_base ??
                ('volume' as Cma_Reports_Set_Input['construction_cost_base']),
              intrinsic_building_volume: data?.intrinsic_building_volume,
              intrinsic_building_surface: data?.intrinsic_building_surface,

              intrinsic_construction_cost: data?.intrinsic_construction_cost,

              intrinsic_construction_year: new Date(
                data?.intrinsic_construction_year ?? 0,
                0,
                1,
              ),

              intrinsic_renovation_year: new Date(
                data?.intrinsic_renovation_year ?? 0,
                0,
                1,
              ),

              intrinsic_annual_deprecation_rate:
                data?.intrinsic_annual_deprecation_rate,

              intrinsic_land_surface: data?.intrinsic_land_surface,
              intrinsic_land_value: data?.intrinsic_land_value,
            }}
            onSubmit={() => Promise.resolve()}
            contentScrollable
            onChange={onChangeHandler}
            actionButtonsComponent={
              <FooterActions<StepOtherValuationsForm>
                {...props}
                updating={updating}
                onSubmit={onSubmit}
              />
            }
          />
        )}
      </Box>
    </>
  );
};

export default StepOtherValuations;
