import { Suspense, useState } from 'react';

import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useLocale } from '../../../src/hooks/locale';
import { LeadWithData } from '../../../src/pages/LeadsRoutes';
import { Drawer } from '../../components/drawer/Drawer';

export const LegacyLeadDrawer = () => {
  const navigate = useNavigate();
  const { t } = useLocale();
  const [title, setTitle] = useState<string>(t('Lead'));

  return (
    <Drawer
      onClose={() =>
        navigate({
          pathname: '..',
          search: location.search,
        })
      }
      title={title}
    >
      <Suspense fallback={<CircularProgress sx={{ margin: 'auto' }} />}>
        <LeadWithData setTitle={setTitle} />
      </Suspense>
    </Drawer>
  );
};

export default LegacyLeadDrawer;
