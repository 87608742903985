// Generated from src/svgs/btn_google_light_normal_ios.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const BtnGoogleLightNormalIos = ({ size, fill, className }: Props) => {
  return (
    <svg viewBox="0 0 34 34" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><rect width="34" height="34" rx="4" fill="#fff" /><path fillRule="evenodd" clipRule="evenodd" d="M25.64 17.204c0-.638-.057-1.251-.164-1.84H17v3.481h4.844a4.14 4.14 0 0 1-1.796 2.716v2.259h2.908c1.702-1.567 2.684-3.875 2.684-6.616Z" fill="#4285F4" /><path fillRule="evenodd" clipRule="evenodd" d="M17 26c2.43 0 4.467-.806 5.956-2.18l-2.908-2.259c-.806.54-1.837.86-3.048.86-2.344 0-4.328-1.584-5.036-3.711H8.957v2.332A8.997 8.997 0 0 0 17 26Z" fill="#34A853" /><path fillRule="evenodd" clipRule="evenodd" d="M11.964 18.71a5.41 5.41 0 0 1-.282-1.71c0-.593.102-1.17.282-1.71v-2.332H8.957A8.996 8.996 0 0 0 8 17c0 1.452.348 2.827.957 4.042l3.007-2.332Z" fill="#FBBC05" /><path fillRule="evenodd" clipRule="evenodd" d="M17 11.58c1.321 0 2.508.454 3.44 1.345l2.582-2.58C21.463 8.891 19.426 8 17 8a8.997 8.997 0 0 0-8.043 4.958l3.007 2.332c.708-2.127 2.692-3.71 5.036-3.71Z" fill="#EA4335" /></svg>
  );
}
