import * as React from 'react';

import { CardActions, CardContent } from '@material-ui/core';
import { Button } from '@mui/material';
import { endOfDay, isBefore, parseISO } from 'date-fns';
import { graphql, useFragment } from 'react-relay';
import { Flex } from 'react-system';

import { Markdown } from '../../controls/markdown';
import { useLocale } from '../../hooks/locale';
import { useTheme } from '../../hooks/theme';
import { NoteOutline } from '../../icons/note-outline';
import { PhoneOutgoingOutline } from '../../icons/phone-outgoing-outline';

import type {
  FeedCall_activity$data,
  FeedCall_activity$key,
} from './__generated__/FeedCall_activity.graphql';
import type { FeedCall_root$key } from './__generated__/FeedCall_root.graphql';
import { ActivityWorkflowDialog } from './activity-workflow-dialog';
import {
  type FeedFormProps,
  workflowButtonClickWrapper,
} from './feed-form-utils';
import { FeedOverdue } from './FeedOverdue';

export interface FeedCallProps
  extends FeedFormProps<FeedCall_root$key, FeedCall_activity$key> {
  inDrawer: boolean;
}

export const FeedCall = (props: FeedCallProps) => {
  const { t } = useLocale();
  const { text } = useTheme();
  const root = useFragment(
    graphql`
      fragment FeedCall_root on Query {
        ...activityWorkflowDialog_root
      }
    `,
    props.root,
  );
  const activity = useFragment(
    graphql`
      fragment FeedCall_activity on Activity {
        ...activityWorkflowDialog_activity
        id
        ... on ActivityCall {
          canEdit
          done
          dueAt
          startDate
          success
          note
          enquiry {
            __typename
            id
            lot {
              lead {
                contact {
                  primaryPhoneNumber {
                    number
                    formattedNumber
                  }
                }
              }
            }
          }
          lot {
            __typename
            id
            lead {
              contact {
                primaryPhoneNumber {
                  number
                  formattedNumber
                }
              }
            }
          }
          lead {
            __typename
            id
            stage {
              pipeline {
                leadType
              }
            }
            contact {
              primaryPhoneNumber {
                number
                formattedNumber
              }
            }
          }
          user {
            __typename
            id
            primaryPhoneNumber {
              number
              formattedNumber
            }
          }
          buyerLead {
            __typename
            id
            lot {
              lead {
                contact {
                  primaryPhoneNumber {
                    number
                    formattedNumber
                  }
                }
              }
            }
          }
          participants {
            user {
              primaryPhoneNumber {
                number
                formattedNumber
              }
            }
          }
        }
      }
    `,
    props.activity,
  );

  const getPhoneNumberByParent = (
    parent:
      | FeedCall_activity$data['enquiry']
      | FeedCall_activity$data['lot']
      | FeedCall_activity$data['lead']
      | FeedCall_activity$data['user']
      | FeedCall_activity$data['buyerLead'],
  ) => {
    switch (parent?.__typename) {
      case 'Enquiry':
        return {
          shown:
            parent.lot?.lead?.contact?.primaryPhoneNumber?.formattedNumber ??
            '',
          number: parent.lot?.lead?.contact?.primaryPhoneNumber?.number ?? '',
        };
      case 'Lot':
        return {
          shown:
            parent.lead?.contact?.primaryPhoneNumber?.formattedNumber ?? '',
          number: parent.lead?.contact?.primaryPhoneNumber?.number ?? '',
        };
      case 'Lead':
        return {
          shown: parent.contact?.primaryPhoneNumber?.formattedNumber ?? '',
          number: parent.contact?.primaryPhoneNumber?.number ?? '',
        };
      case 'User':
        return {
          shown: parent.primaryPhoneNumber?.formattedNumber ?? '',
          number: parent.primaryPhoneNumber?.number ?? '',
        };
      case 'BuyerLead':
        return {
          shown:
            parent.lot.lead?.contact?.primaryPhoneNumber?.formattedNumber ?? '',
          number: parent.lot.lead?.contact?.primaryPhoneNumber?.number ?? '',
        };
      default:
        return {
          shown: '',
          number: '',
        };
    }
  };

  const [dialog, setDialog] = React.useState<
    'cancelled' | 'completed' | 'none'
  >('none');

  const parent =
    activity.enquiry ??
    activity.lot ??
    activity.lead ??
    activity.user ??
    activity.buyerLead;
  const parentType = parent?.__typename;
  const parentId = parent?.id;
  const startDate =
    activity.startDate != null ? parseISO(activity.startDate) : null;
  const dueAt =
    activity.dueAt != null
      ? parseISO(activity.dueAt)
      : startDate != null
      ? endOfDay(startDate)
      : null;
  const isOverdue =
    activity.done === true
      ? false
      : startDate != null
      ? isBefore(endOfDay(startDate), new Date())
      : dueAt != null
      ? isBefore(dueAt, new Date())
      : null;

  const workflowButtonClick = workflowButtonClickWrapper(props, setDialog);

  const participantWithNo = activity.participants?.find(
    participant => participant.user?.primaryPhoneNumber?.number != null,
  );

  const personNumber =
    participantWithNo != null
      ? {
          shown:
            participantWithNo.user?.primaryPhoneNumber?.formattedNumber ?? '',
          number: participantWithNo.user?.primaryPhoneNumber?.number ?? '',
        }
      : getPhoneNumberByParent(parent);

  const DIALOG_CONFIG = {
    completed: 'completed_call',
    cancelled: 'cancelled_call',
  } as const;

  const shouldDisplayDialog = dialog !== 'none' && dialog in DIALOG_CONFIG;

  return (
    <>
      <CardContent
        css={{
          paddingTop: 0,
          display: 'grid',
          gridGap: 12,
          gridTemplateColumns: '24px 1fr',
        }}
      >
        {dueAt != null && (
          <FeedOverdue
            startDate={startDate}
            isOverdue={isOverdue}
            dueAt={dueAt}
            showDate={activity.done !== true && activity.success !== true}
          />
        )}
        {activity.note != null && activity.note.length > 0 && (
          <>
            <NoteOutline />
            <div css={text.body2}>
              <Markdown>{activity.note}</Markdown>
            </div>
          </>
        )}
      </CardContent>
      {activity.canEdit === true &&
        activity.done !== true &&
        props.indirect === false && (
          <CardActions
            css={{
              justifyContent: 'flex-end',
              flexWrap: 'wrap',
              padding: 16,
            }}
          >
            {activity.success !== true &&
              personNumber.number !== '' &&
              personNumber.shown !== '' && (
                <Flex
                  flexGrow={1}
                  mb={[2, props.inDrawer ? 2 : 0]}
                  width={[1, props.inDrawer ? 1 : 'auto']}
                  justifyContent={[
                    'flex-end',
                    props.inDrawer ? 'flex-end' : 'flex-start',
                  ]}
                >
                  <Button
                    href={`tel:${personNumber.number}`}
                    component="a"
                    variant="contained"
                    color="primary"
                    startIcon={<PhoneOutgoingOutline size={16} />}
                  >
                    {personNumber.shown}
                  </Button>
                </Flex>
              )}

            <Button
              variant="contained"
              color="secondary"
              onClick={() => workflowButtonClick('cancelled')}
            >
              {t('notReached')}
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={() => workflowButtonClick('completed')}
            >
              {t('reached')}
            </Button>
          </CardActions>
        )}

      {shouldDisplayDialog && (
        <ActivityWorkflowDialog
          root={root}
          activity={activity}
          initialStep={DIALOG_CONFIG[dialog]}
          parentId={parentId ?? null}
          parentType={parentType ?? null}
          leadType={activity.lead?.stage?.pipeline?.leadType}
          onClose={() => {
            props.setOpenDialog(false);
            props.setActiveDialogType(null);
            setDialog('none');
          }}
          onNewActivity={() => {
            props.setOpenDialog(false);
            props.setActiveDialogType(null);
            setDialog('none');
            props.onCreate();
          }}
          open={true}
          openDialog={props.openDialog}
          setOpenDialog={props.setOpenDialog}
          showUnfinishedWarning={props.showUnfinishedWarning}
          setShowUnfinishedWarning={props.setShowUnfinishedWarning}
        />
      )}
    </>
  );
};
