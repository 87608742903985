import Brightness1Icon from '@mui/icons-material/Brightness1';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

export const getAppraisalPerception = (
  appraisalPerception: null | string | undefined,
  appraisalPerceivedValue: null | number | undefined,
  max: null | number | undefined,
  min: null | number | undefined,
  useSVGIcons = false,
) => {
  if (
    appraisalPerception === 'just_right' ||
    (appraisalPerceivedValue != null &&
      min != null &&
      max != null &&
      min <= appraisalPerceivedValue &&
      max >= appraisalPerceivedValue)
  ) {
    return '👍';
  } else if (
    appraisalPerception === 'too_low' ||
    (appraisalPerceivedValue != null &&
      max != null &&
      max < appraisalPerceivedValue)
  ) {
    return useSVGIcons ? (
      <WarningRoundedIcon sx={{ fontSize: 18 }} color="error" />
    ) : (
      '🔻'
    );
  } else if (
    appraisalPerception === 'too_high' ||
    (appraisalPerceivedValue != null &&
      min != null &&
      min > appraisalPerceivedValue)
  ) {
    return useSVGIcons ? (
      <Brightness1Icon sx={{ fontSize: 15 }} color="success" />
    ) : (
      '🔺'
    );
  } else {
    return '－';
  }
};
