import type * as React from 'react';

import { AppBar, ButtonBase, Card } from '@material-ui/core';
import { graphql, useFragment } from 'react-relay';
import { Box, Flex } from 'react-system';

import { useLocale } from '../../hooks/locale';
import { useTheme } from '../../hooks/theme';
import { Create } from '../../icons/create';
import { Email } from '../../icons/email';
import { Phone } from '../../icons/phone';
import { Sms } from '../../icons/sms';
import { Task } from '../../icons/task';
import { Visibility } from '../../icons/visibility';
import { Whatsapp } from '../../icons/whatsapp';

import type { FeedNewForm_buyerLead$key } from './__generated__/FeedNewForm_buyerLead.graphql';
import type { FeedNewForm_enquiry$key } from './__generated__/FeedNewForm_enquiry.graphql';
import type { FeedNewForm_lead$key } from './__generated__/FeedNewForm_lead.graphql';
import type { FeedNewForm_lot$key } from './__generated__/FeedNewForm_lot.graphql';
import type { FeedNewForm_root$key } from './__generated__/FeedNewForm_root.graphql';
import type { FeedNewForm_user$key } from './__generated__/FeedNewForm_user.graphql';
import { ActivityForm } from './ActivityForm';

type Props = {
  enquiry: null | FeedNewForm_enquiry$key;
  lot: null | FeedNewForm_lot$key;
  lead: null | FeedNewForm_lead$key;
  user: null | FeedNewForm_user$key;
  buyerLead: null | FeedNewForm_buyerLead$key;
  root: null | FeedNewForm_root$key;
  disabled?: boolean;
  showUnfinishedWarning: boolean;
  setShowUnfinishedWarning: (value: boolean) => void;
  setNextActiveDialogType: (
    value: 'note' | 'task' | 'call' | 'visit' | 'email' | 'workflow' | null,
  ) => void;
  openDialog: boolean;
  setOpenDialog: (value: boolean) => void;
  activeDialogType:
    | 'note'
    | 'task'
    | 'call'
    | 'visit'
    | 'email'
    | 'workflow'
    | null;
  setActiveDialogType: (
    value: 'note' | 'task' | 'call' | 'visit' | 'email' | 'workflow' | null,
  ) => void;
  onCreate: () => void;
  onCallScheduled?: () => void;
};

const ActivityButton = ({
  onClick,
  icon,
  label,
  disabled = false,
}: {
  onClick: () => void;
  icon: React.ReactNode;
  label: string;
  disabled?: boolean;
}) => {
  return (
    <ButtonBase
      disabled={disabled}
      css={{
        display: 'flex',
        flexDirection: 'column',
        padding: 12,
        opacity: disabled === true ? 0.5 : 1,
      }}
      focusRipple={true}
      onClick={onClick}
    >
      {icon}
      <Box
        pt={2}
        css={{ fontSize: 9, whiteSpace: 'nowrap', textTransform: 'uppercase' }}
      >
        {label}
      </Box>
    </ButtonBase>
  );
};

export const FeedNewForm = ({ disabled = false, ...props }: Props) => {
  const enquiry = useFragment(
    graphql`
      fragment FeedNewForm_enquiry on Enquiry {
        ...ActivityForm_enquiry
        user {
          phoneNumbers {
            number
            primary
            createdAt
          }
        }
        __typename
      }
    `,
    props.enquiry,
  );
  const lot = useFragment(
    graphql`
      fragment FeedNewForm_lot on Lot {
        ...ActivityForm_lot
        sellers {
          phoneNumbers {
            number
            primary
            createdAt
          }
        }
        __typename
      }
    `,
    props.lot,
  );
  const lead = useFragment(
    graphql`
      fragment FeedNewForm_lead on Lead {
        ...ActivityForm_lead
        __typename
        stage {
          pipeline {
            leadType
          }
        }
        contact {
          phoneNumbers {
            number
            primary
            createdAt
          }
        }
      }
    `,
    props.lead,
  );
  const user = useFragment(
    graphql`
      fragment FeedNewForm_user on User {
        ...ActivityForm_user
        phoneNumbers {
          number
          primary
          createdAt
        }
        __typename
      }
    `,
    props.user,
  );
  const buyerLead = useFragment(
    graphql`
      fragment FeedNewForm_buyerLead on BuyerLead {
        ...ActivityForm_buyerLead
        user {
          phoneNumbers {
            number
            primary
            createdAt
          }
        }
        __typename
      }
    `,
    props.buyerLead,
  );

  const root = useFragment(
    graphql`
      fragment FeedNewForm_root on Query {
        ...ActivityForm_root
      }
    `,
    props.root,
  );
  const { t } = useLocale();
  const { borderRadius } = useTheme();

  let visitLabel = t('visit');
  if (lead?.stage?.pipeline?.leadType === 'financing') {
    visitLabel = t('meeting');
  }

  const activityButtonClick = (
    type: 'note' | 'task' | 'call' | 'visit' | 'email',
  ) => {
    if (props.activeDialogType == null) {
      props.setActiveDialogType(type);
      props.setNextActiveDialogType(null);
    } else if (props.activeDialogType !== type) {
      props.setNextActiveDialogType(type);
      props.setShowUnfinishedWarning(true);
    }
    props.setOpenDialog(true);
  };

  const phoneNumbers = (
    lot?.sellers[0] ??
    lead?.contact ??
    buyerLead?.user ??
    enquiry?.user ??
    user
  )?.phoneNumbers;

  // Getting primary phone number
  // If no primary phone number, getting the latest added phone number
  const phoneNumber =
    phoneNumbers && phoneNumbers.length > 0
      ? [...phoneNumbers].sort((a, b) =>
          a.primary !== b.primary
            ? a.primary === true
              ? -1
              : 1
            : new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        )[0].number
      : null;

  const shouldPhoneButtonBeDisabled = disabled === false && phoneNumber == null;

  const onWhatsAppClick = () => {
    if (phoneNumber != null) {
      window.open(
        'https://wa.me/' + phoneNumber.replace(/[^0-9]/g, ''),
        '_blank',
      );
    }
  };

  const onSMSClick = () => {
    if (phoneNumber != null) {
      window.open('sms:' + phoneNumber, '_blank');
    }
  };

  return (
    <Card
      css={{
        pointerEvents: disabled === true ? 'none' : 'auto',
        borderRadius: borderRadius.medium,
      }}
    >
      <AppBar
        position="static"
        color="primary"
        elevation={0}
        css={{ opacity: disabled === true ? 0.5 : 1 }}
      >
        <Flex css={{ overflow: 'auto' }} pl={2}>
          <ActivityButton
            onClick={() => activityButtonClick('note')}
            icon={<Create />}
            label={t('note')}
          />
          <ActivityButton
            onClick={() => activityButtonClick('task')}
            icon={<Task />}
            label={t('task')}
          />
          <ActivityButton
            onClick={() => activityButtonClick('call')}
            icon={<Phone />}
            label={t('call')}
          />
          <ActivityButton
            onClick={() => activityButtonClick('visit')}
            icon={<Visibility />}
            label={visitLabel}
          />
          <ActivityButton
            onClick={() => activityButtonClick('email')}
            icon={<Email />}
            label={t('email')}
          />
          <ActivityButton
            disabled={shouldPhoneButtonBeDisabled}
            onClick={onWhatsAppClick}
            icon={<Whatsapp />}
            label={t('sendWhatsappMessage')}
          />
          <ActivityButton
            disabled={shouldPhoneButtonBeDisabled}
            onClick={onSMSClick}
            icon={<Sms />}
            label={t('sendSMS')}
          />
        </Flex>
      </AppBar>

      <ActivityForm
        enquiry={enquiry}
        lot={lot}
        lead={lead}
        user={user}
        buyerLead={buyerLead}
        activity={null}
        root={root}
        type={props.activeDialogType}
        onSubmit={() => {
          props.onCreate();
          props.setActiveDialogType(null);
          props.setOpenDialog(false);
        }}
        onCallScheduled={props.onCallScheduled}
        onCancel={() => {
          props.setActiveDialogType(null);
          props.setOpenDialog(false);
        }}
        openDialog={props.openDialog}
        setOpenDialog={props.setOpenDialog}
        showUnfinishedWarning={props.showUnfinishedWarning}
      />
    </Card>
  );
};
