/**
 * @generated SignedSource<<5e25bc5d840e9ec43311cbaa533af656>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
type userAvatar_user$fragmentType = any;
import type { FragmentType } from "relay-runtime";
declare export opaque type userSummary_user$fragmentType: FragmentType;
export type userSummary_user$data = {|
  +broker: ?{|
    +displayName: string,
    +id: string,
    +$fragmentSpreads: userAvatar_user$fragmentType,
  |},
  +displayName: string,
  +emails: $ReadOnlyArray<{|
    +email: string,
    +primary: boolean,
  |}>,
  +id: string,
  +organisation: ?{|
    +id: string,
    +name: ?string,
  |},
  +phoneNumbers: $ReadOnlyArray<{|
    +formattedNumber: string,
    +number: string,
    +primary: ?boolean,
  |}>,
  +$fragmentSpreads: userAvatar_user$fragmentType,
  +$fragmentType: userSummary_user$fragmentType,
|};
export type userSummary_user$key = {
  +$data?: userSummary_user$data,
  +$fragmentSpreads: userSummary_user$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "userAvatar_user"
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "primary",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "userSummary_user",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Organisation",
      "kind": "LinkedField",
      "name": "organisation",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PhoneNumber",
      "kind": "LinkedField",
      "name": "phoneNumbers",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "formattedNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "number",
          "storageKey": null
        },
        (v3/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Email",
      "kind": "LinkedField",
      "name": "emails",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        (v3/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "broker",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "030e149db534cbc9e4178e8bd64f1fb2";

export default node;
