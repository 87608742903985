/**
 * @generated SignedSource<<8efa3647b8237ea7adc3ac7324b0e27d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type LeadsFiltersQuery$variables = {};
export type LeadsFiltersQuery$data = {
  readonly leadLostTypes: ReadonlyArray<{
    readonly id: string;
    readonly label: string;
  }>;
  readonly mandateProbabilityTypes: ReadonlyArray<{
    readonly id: string;
    readonly label: string;
    readonly name: string;
  }>;
  readonly me: {
    readonly isAdmin: boolean | null;
  } | null;
  readonly tagsTypes: ReadonlyArray<{
    readonly id: string;
    readonly label: string;
    readonly name: string;
  }>;
  readonly tenantSettings: {
    readonly defaultPipeline: {
      readonly id: string;
    } | null;
  } | null;
};
export type LeadsFiltersQuery = {
  response: LeadsFiltersQuery$data;
  variables: LeadsFiltersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Pipeline",
  "kind": "LinkedField",
  "name": "defaultPipeline",
  "plural": false,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isAdmin",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v4 = {
  "alias": "leadLostTypes",
  "args": [
    {
      "kind": "Literal",
      "name": "type",
      "value": "lead_lost_types"
    }
  ],
  "concreteType": "Dictionary",
  "kind": "LinkedField",
  "name": "dictionaries",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": "dictionaries(type:\"lead_lost_types\")"
},
v5 = [
  (v0/*: any*/),
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v6 = {
  "alias": "mandateProbabilityTypes",
  "args": [
    {
      "kind": "Literal",
      "name": "type",
      "value": "mandate_probability_types"
    }
  ],
  "concreteType": "Dictionary",
  "kind": "LinkedField",
  "name": "dictionaries",
  "plural": true,
  "selections": (v5/*: any*/),
  "storageKey": "dictionaries(type:\"mandate_probability_types\")"
},
v7 = {
  "alias": "tagsTypes",
  "args": [
    {
      "kind": "Literal",
      "name": "type",
      "value": "tags_types"
    }
  ],
  "concreteType": "Dictionary",
  "kind": "LinkedField",
  "name": "dictionaries",
  "plural": true,
  "selections": (v5/*: any*/),
  "storageKey": "dictionaries(type:\"tags_types\")"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LeadsFiltersQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantSettings",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      (v4/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LeadsFiltersQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantSettings",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      (v4/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ]
  },
  "params": {
    "cacheID": "4a42534dfaa761071bcef04e56233e6f",
    "id": null,
    "metadata": {},
    "name": "LeadsFiltersQuery",
    "operationKind": "query",
    "text": "query LeadsFiltersQuery {\n  tenantSettings {\n    defaultPipeline {\n      id\n    }\n    id\n  }\n  me {\n    isAdmin\n    id\n  }\n  leadLostTypes: dictionaries(type: \"lead_lost_types\") {\n    id\n    label\n  }\n  mandateProbabilityTypes: dictionaries(type: \"mandate_probability_types\") {\n    id\n    label\n    name\n  }\n  tagsTypes: dictionaries(type: \"tags_types\") {\n    id\n    label\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "8fafaa3e72c9b44f041ee4fa051d3b20";

export default node;
