// @flow

import * as React from 'react';

import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { Form, useFormik } from '@realadvisor/form';
import {
  graphql,
  useFragment,
  useLazyLoadQuery,
  useMutation,
} from 'react-relay';
import { Box } from 'react-system';

import {
  DrawerBottomToolbar,
  DrawerContent,
  DrawerHeader,
  DrawerLayer,
} from '../controls/drawer';
import { PriceInput } from '../controls/price-input';
import { ProgressButton } from '../controls/progress-button';
import { ScrollToError } from '../controls/scroll-to-error';
import { useLocale } from '../hooks/locale';

import type { financingLeadCreationDrawer_user$key } from './__generated__/financingLeadCreationDrawer_user.graphql';
import type { financingLeadCreationDrawerMutation } from './__generated__/financingLeadCreationDrawerMutation.graphql';
import type { financingLeadCreationDrawerQuery } from './__generated__/financingLeadCreationDrawerQuery.graphql';
import { type User, UserInput } from './user-input';

const FinancingLeadCreationForm = props => {
  const { t } = useLocale();
  const [createRequest, creating] =
    useMutation<financingLeadCreationDrawerMutation>(
      graphql`
        mutation financingLeadCreationDrawerMutation($input: UpsertLeadInput!) {
          upsertLead(input: $input) {
            lead {
              id
            }
          }
        }
      `,
    );

  const contact = useFragment(
    graphql`
      fragment financingLeadCreationDrawer_user on User {
        ...userInput_user @relay(mask: false)
      }
    `,
    props.initialValues.contact,
  );

  const financingPipelines = useLazyLoadQuery<financingLeadCreationDrawerQuery>(
    graphql`
      query financingLeadCreationDrawerQuery {
        pipelines(name: "financing") {
          stages {
            id
            label
          }
        }
      }
    `,
    {},
  );

  const getMortgageType = value => {
    switch (value) {
      case 'new_mortgage':
        return 'new_mortgage';
      case 're_mortgage':
        return 're_mortgage';
    }
  };
  const getUsage = value => {
    switch (value) {
      case 'investment':
        return 'investment';
      case 'primary':
        return 'primary';
      case 'secondary':
        return 'secondary';
    }
  };
  const getBuyingStatus = value => {
    switch (value) {
      case 'searching':
        return 'searching';
      case 'identified':
        return 'identified';
      case 'offer':
        return 'offer';
      case 'visited':
        return 'visited';
    }
  };

  const number_of_string = (string: string) => {
    const parsed = Number.parseFloat(string);
    if (Number.isNaN(parsed)) {
      return null;
    }
    return parsed;
  };

  const {
    values,
    setValues,
    changed,
    errors,
    submitForm,
    setTouched,
    resetForm,
  } = useFormik({
    initialValues: {
      contact: contact ?? null,
      secondContact: props.initialValues.secondContact ?? null,
      stageId: props.initialValues.stageId ?? null,
      yearlyRevenues: '',
      savings: '',
      mortgageReportType: null || '', // just to tell the compiler that the field can be null
      mortgagePropertyUsage: '',
      mortgageMortgageType: '',
      mortgageBuyingStatus: '',
    },
    validate: values => {
      const errors = {};
      if (values.contact == null) {
        errors.contact = t('pleaseChooseBorrower');
      }
      if (values.stageId == null || values.stageId === '') {
        errors.stageId = t('pleaseChooseLeadStage');
      }
      if (values.mortgagePropertyUsage === '') {
        errors.mortgagePropertyUsage = t('pleasePropertyUsage');
      }
      if (values.mortgageMortgageType === '') {
        errors.mortgageMortgageType = t('pleaseMortgageType');
      }
      return errors;
    },
    onSubmit: values => {
      const input = {
        lead: {
          source: 'crm',
          contactId: values.contact?.id,
          secondContactId: values.secondContact?.id,
          stageId: values.stageId,
          mortgagePropertyUsage: getUsage(values.mortgagePropertyUsage),
          mortgageMortgageType: getMortgageType(values.mortgageMortgageType),
          mortgageReportType: values.mortgageReportType,
          mortgageBuyingStatus: getBuyingStatus(values.mortgageBuyingStatus),
          yearlyRevenues: number_of_string(values.yearlyRevenues),
          savings: number_of_string(values.savings),
        },
      };
      createRequest({
        variables: { input },
        onCompleted: data => {
          if (data.upsertLead?.lead != null) {
            resetForm();
            props.onCreate(data.upsertLead?.lead?.id);
          }
        },
      });
    },
  });

  return (
    <>
      <DrawerContent>
        <Box p={3}>
          <Form css={{ display: 'grid', gap: 16 }} onSubmit={submitForm}>
            <ScrollToError errors={errors} />
            <FormControl required={true} error={errors.contact != null}>
              <InputLabel>{t('borrower1')}</InputLabel>
              <UserInput
                filters={{
                  isBroker: false,
                  id_nin: values.secondContact
                    ? [values.secondContact.id]
                    : null,
                }}
                value={values.contact}
                onChange={user => setValues({ contact: user })}
              />
              {errors.contact != null && (
                <FormHelperText>{errors.contact}</FormHelperText>
              )}
            </FormControl>
            <FormControl>
              <InputLabel>{t('borrower2')}</InputLabel>
              <UserInput
                filters={{
                  isBroker: false,
                  id_nin: values.contact ? [values.contact.id] : null,
                }}
                value={values.secondContact}
                onChange={user => setValues({ secondContact: user })}
              />
            </FormControl>
            <FormControl required={true} error={errors.stageId != null}>
              <InputLabel>{t('stage')}</InputLabel>
              <Select
                value={values.stageId || ''}
                variant="filled"
                margin="dense"
                onChange={event =>
                  setValues({
                    stageId: event.target.value,
                  })
                }
              >
                {financingPipelines.pipelines[0].stages.map(stage => (
                  <MenuItem key={stage.id} value={stage.id}>
                    {stage.label ?? null}
                  </MenuItem>
                ))}
              </Select>
              {errors.stageId != null && (
                <FormHelperText>{errors.stageId}</FormHelperText>
              )}
            </FormControl>
            <TextField
              variant="filled"
              select={true}
              required={true}
              error={errors.mortgagePropertyUsage != null}
              helperText={errors.mortgagePropertyUsage}
              label={t('mortgagesPropertyUsageLabel')}
              value={values.mortgagePropertyUsage}
              onChange={event =>
                setValues({
                  mortgagePropertyUsage: event.target.value,
                })
              }
              onBlur={() => setTouched({ mortgagePropertyUsage: true })}
            >
              <MenuItem value="primary">{t('primary')}</MenuItem>
              <MenuItem value="secondary">{t('secondary')}</MenuItem>
              <MenuItem value="investment">{t('investment')}</MenuItem>
            </TextField>
            <TextField
              variant="filled"
              select={true}
              required={true}
              error={errors.mortgageMortgageType != null}
              helperText={errors.mortgageMortgageType}
              label={t('leadType')}
              value={values.mortgageReportType ?? values.mortgageMortgageType}
              onChange={event => {
                switch (event.target.value) {
                  case 'new_mortgage':
                  case 're_mortgage':
                    setValues({
                      mortgageMortgageType: event.target.value,
                      mortgageReportType: null,
                    });
                    break;
                  case 'financing_passport':
                    setValues({
                      mortgageMortgageType: 'new_mortgage',
                      mortgageReportType: 'financing_passport',
                    });
                    break;
                }
              }}
              onBlur={() => setTouched({ mortgageMortgageType: true })}
            >
              <MenuItem value="new_mortgage">{t('newMortgage')}</MenuItem>
              <MenuItem value="re_mortgage">{t('remortgage')}</MenuItem>
              <MenuItem value="financing_passport">
                {t('financingPassport')}
              </MenuItem>
            </TextField>
            <TextField
              variant="filled"
              select={true}
              required={false}
              error={errors.mortgageBuyingStatus != null}
              helperText={errors.mortgageBuyingStatus}
              label={t('buyingStatus')}
              value={values.mortgageBuyingStatus}
              onChange={event =>
                setValues({
                  mortgageBuyingStatus: event.target.value,
                })
              }
              onBlur={() => setTouched({ mortgageBuyingStatus: true })}
            >
              <MenuItem value="searching">{t('activelySearching')}</MenuItem>
              <MenuItem value="identified">{t('identified')}</MenuItem>
              <MenuItem value="visited">{t('visited')}</MenuItem>
              <MenuItem value="offer">{t('offer')}</MenuItem>
            </TextField>
            <FormControl>
              <InputLabel>{t('income')}</InputLabel>
              <PriceInput
                value={values.yearlyRevenues ?? 0}
                onChange={yearlyRevenues => setValues({ yearlyRevenues })}
                onBlur={() => setTouched({ yearlyRevenues: true })}
              />
              {errors.yearlyRevenues != null && (
                <FormHelperText>{errors.yearlyRevenues}</FormHelperText>
              )}
            </FormControl>
            <FormControl>
              <InputLabel>{t('ownFunds')}</InputLabel>
              <PriceInput
                value={values.savings ?? 0}
                onChange={savings => setValues({ savings })}
                onBlur={() => setTouched({ savings: true })}
              />
              {errors.savings != null && (
                <FormHelperText>{errors.savings}</FormHelperText>
              )}
            </FormControl>
          </Form>
        </Box>
      </DrawerContent>
      <DrawerBottomToolbar>
        <Button onClick={props.onReset}>{t('cancel')}</Button>
        <ProgressButton
          loading={creating}
          disabled={!changed}
          onClick={submitForm}
        >
          {t('create')}
        </ProgressButton>
      </DrawerBottomToolbar>
    </>
  );
};

type Props = {|
  open: boolean,
  onCreate: (leadId?: string) => void,
  onClose: () => void,
  initialValues: {|
    contact?: ?financingLeadCreationDrawer_user$key,
    secondContact?: ?User,
    stageId?: ?string,
  |},
|};

export const FinancingLeadCreationDrawer = (props: Props): React.Node => {
  const { t } = useLocale();

  return (
    <DrawerLayer
      width={['100vw', 'calc(100vw - 100px)', 960]}
      open={props.open}
      onClose={props.onClose}
    >
      <DrawerHeader onClose={props.onClose}>
        {t('createNewFinancingLead')}
      </DrawerHeader>
      <React.Suspense fallback={null}>
        <FinancingLeadCreationForm
          initialValues={props.initialValues}
          onCreate={props.onCreate}
          onReset={props.onClose}
        />
      </React.Suspense>
    </DrawerLayer>
  );
};
