/**
 * @generated SignedSource<<4935e362bbd246c52f637b484b5c635d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeedEmailForm_root$data = {
  readonly docTemplates: {
    readonly " $fragmentSpreads": FragmentRefs<"useGetDefaultRelayTemplate">;
  } | null;
  readonly me: {
    readonly id: string;
    readonly nylasAccount: {
      readonly id: string;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"EmailForm_me">;
  } | null;
  readonly " $fragmentType": "FeedEmailForm_root";
};
export type FeedEmailForm_root$key = {
  readonly " $data"?: FeedEmailForm_root$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedEmailForm_root">;
};

import FeedEmailFormQuery_graphql from './FeedEmailFormQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": FeedEmailFormQuery_graphql
    }
  },
  "name": "FeedEmailForm_root",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "me",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EmailForm_me"
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "NylasAccount",
          "kind": "LinkedField",
          "name": "nylasAccount",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 100
        }
      ],
      "concreteType": "DocTemplateConnection",
      "kind": "LinkedField",
      "name": "docTemplates",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useGetDefaultRelayTemplate"
        }
      ],
      "storageKey": "docTemplates(first:100)"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "b4e7469c4713cf971fff31d8f3b8d02a";

export default node;
