/**
 * @generated SignedSource<<9617a0831173ea9869c86f05964f8709>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type LeadRelationship = "agent" | "buyer" | "heir" | "not_set" | "other" | "owner" | "tenant";
import { FragmentRefs } from "relay-runtime";
export type leadContactCard_RelationshipItem$data = {
  readonly id: string;
  readonly relationship: LeadRelationship;
  readonly " $fragmentType": "leadContactCard_RelationshipItem";
};
export type leadContactCard_RelationshipItem$key = {
  readonly " $data"?: leadContactCard_RelationshipItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"leadContactCard_RelationshipItem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "leadContactCard_RelationshipItem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "relationship",
      "storageKey": null
    }
  ],
  "type": "Lead",
  "abstractKey": null
};

(node as any).hash = "9276b1528e82c36634c41dac9283bd70";

export default node;
