import * as React from 'react';

import {
  CircularProgress,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { graphql, useFragment, useMutation } from 'react-relay';

import { DropZoneButton } from '../../shared/dropzone';
import { TimeAgo } from '../components/TimeAgo';
import { FileIcon } from '../controls/file-icon';
import { FileViewerDialog } from '../controls/file-viewer-dialog';
import { useLocale } from '../hooks/locale';
import { useTheme } from '../hooks/theme';
import { DeleteForever } from '../icons/delete-forever';

import type {
  filesList_files$data,
  filesList_files$key,
} from './__generated__/filesList_files.graphql';
import type { filesListCreateMutation } from './__generated__/filesListCreateMutation.graphql';
import { FileDeleteDialog } from './file-delete-dialog';
import { useFileUpload } from './file-upload';

type SchemaFile = filesList_files$data[number];

type Props = {
  files: filesList_files$key;
  parentId: string;
};

const FilesItem = ({
  parentId,
  file,
}: {
  parentId: string;
  file: SchemaFile;
}) => {
  const { text, colors } = useTheme();
  const hasViewer = file.mimetype === 'application/pdf';
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [viewerDialog, setViewerDialog] = React.useState(false);
  return (
    <>
      <FileDeleteDialog
        parentId={parentId}
        fileId={file.id}
        open={deleteDialog}
        onClose={() => setDeleteDialog(false)}
      />
      <FileViewerDialog
        url={file.url}
        name={file.name}
        open={viewerDialog}
        onClose={() => setViewerDialog(false)}
      />
      <ListItem
        button={hasViewer ? undefined : false}
        onClick={() => {
          if (hasViewer) {
            setViewerDialog(true);
          }
        }}
      >
        <ListItemIcon>
          <FileIcon mimetype={file.mimetype} />
        </ListItemIcon>
        <ListItemText>
          <div css={[text.body1, text.truncate(1)]}>
            {hasViewer ? (
              file.name
            ) : (
              <Link href={file.url} target="_blank" rel="noopener noreferrer">
                {file.name}
              </Link>
            )}
          </div>
          <div
            css={[text.body2, text.truncate(1), { color: colors.mediumText }]}
          >
            <TimeAgo>{file.createdAt}</TimeAgo>
            <span>
              {[' by ', file.uploader?.firstName, ' ', file.uploader?.lastName]}
            </span>
          </div>
        </ListItemText>
        <ListItemSecondaryAction>
          <IconButton onClick={() => setDeleteDialog(true)}>
            <DeleteForever />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );
};

const PendingFilesItem = ({ file }: { file: File }) => {
  const { text } = useTheme();
  return (
    <ListItem>
      <ListItemIcon>
        <FileIcon mimetype={file.type} />
      </ListItemIcon>
      <ListItemText css={text.truncate(1)}>{file.name}</ListItemText>
      <ListItemSecondaryAction>
        <CircularProgress size={24} disableShrink />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export const FilesList = (props: Props) => {
  const files = useFragment(
    graphql`
      fragment filesList_files on File @relay(plural: true) {
        id
        name
        url
        mimetype
        createdAt
        uploader {
          firstName
          lastName
        }
      }
    `,
    props.files,
  );

  const { t } = useLocale();
  const [uploadFile] = useFileUpload();
  const [createFile] = useMutation<filesListCreateMutation>(
    graphql`
      mutation filesListCreateMutation($input: CreateFileInput!) {
        createFile(input: $input) {
          property {
            id
            files {
              ...filesList_files
            }
          }
          development {
            id
            files {
              ...filesList_files
            }
          }
          user {
            id
            files {
              ...filesList_files
            }
          }
          emailTemplate {
            id
            files {
              ...filesList_files
            }
          }
        }
      }
    `,
  );
  const [pendingFiles, setPendingFiles] = React.useState<File[]>([]);
  const addPendingFile = (file: File) =>
    setPendingFiles(prev => [...prev, file]);
  const removePendingFile = (file: File) =>
    setPendingFiles(prev => prev.filter(item => item !== file));
  return (
    <>
      <List>
        {files.length === 0 && (
          <ListItem css={{ justifyContent: 'center' }}>{t('noFiles')}</ListItem>
        )}
        {files.map(file => (
          <FilesItem key={file.id} parentId={props.parentId} file={file} />
        ))}
        {pendingFiles.map((file, index) => (
          <PendingFilesItem key={index} file={file} />
        ))}
      </List>
      <Divider />
      <DropZoneButton
        onDrop={files => {
          files.forEach(file => {
            addPendingFile(file);
            uploadFile(file, 'files', url => {
              if (url != null) {
                createFile({
                  variables: {
                    input: {
                      parentId: props.parentId,
                      file: {
                        url,
                        size: file.size,
                        name: file.name,
                        mimetype: file.type,
                      },
                    },
                  },
                  onCompleted: () => removePendingFile(file),
                });
              } else {
                removePendingFile(file);
              }
            });
          });
        }}
      />
    </>
  );
};
