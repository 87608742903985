import React, { useCallback, useState } from 'react';

import { Box } from '@mui/material';

import { Filter } from '../../../../../src/controls/Filters/Filter';
import { SquareInput } from '../../../../../src/controls/meter-input';
import { PriceInput } from '../../../../../src/controls/price-input';
import { RangeInput } from '../../../../../src/controls/range-input';
import { useDebouncedHandler } from '../../../../../src/hooks/debounce';
import { useLocale } from '../../../../../src/hooks/locale';
import { number_of_string } from '../../../../../src/utils/number-format';
import type { Merged_Listings_Compare_Args } from '../../../../__generated__/graphql';
import { formatNumber } from '../../../../utils/formatting';

type FilterType = 'surface' | 'land_surface' | 'sale_price' | 'price_per_sqm';

interface RangeFilterProps {
  filters: Partial<Merged_Listings_Compare_Args>;
  setFilters: (filters: Partial<Merged_Listings_Compare_Args>) => void;
  type: FilterType;
  min?: number;
  max?: number;
  step?: number;
}

const FILTER_CONFIG: Record<
  FilterType,
  {
    min: number;
    max: number;
    step: number;
    prefix: string;
    inputComponent: typeof SquareInput | typeof PriceInput;
  }
> = {
  surface: {
    min: 0,
    max: 5_000,
    step: 5,
    prefix: 'computed_surface_',
    inputComponent: SquareInput,
  },
  land_surface: {
    min: 0,
    max: 500_000,
    step: 100,
    prefix: 'land_surface_',
    inputComponent: SquareInput,
  },
  sale_price: {
    min: 50_000,
    max: 20_000_000,
    step: 10_000,
    prefix: 'sale_price_',
    inputComponent: PriceInput,
  },
  price_per_sqm: {
    min: 0,
    max: 50_000,
    step: 1_000,
    prefix: 'computed_price_per_sqm_',
    inputComponent: PriceInput,
  },
};

const RangeFilter: React.FC<RangeFilterProps> = ({
  filters,
  setFilters,
  type,
  min,
  max,
  step,
}) => {
  const { t, locale } = useLocale();
  const {
    prefix,
    min: defaultMin,
    max: defaultMax,
    step: defaultStep,
    inputComponent: InputComponent,
  } = FILTER_CONFIG[type];

  const actualMin = min ?? defaultMin;
  const actualMax = max ?? defaultMax;
  const actualStep = step ?? defaultStep;

  const gteKey = `${prefix}gte` as keyof Merged_Listings_Compare_Args;
  const lteKey = `${prefix}lte` as keyof Merged_Listings_Compare_Args;

  const _gte = filters[gteKey] as number | undefined;
  const _lte = filters[lteKey] as number | undefined;

  const [range, setRange] = useState({
    from: Math.max(actualMin, _gte ?? actualMin).toString(),
    to: Math.min(actualMax, _lte ?? actualMax).toString(),
  });

  const getLabel = useCallback(() => {
    const formatValue = (value: number) =>
      type.includes('price')
        ? formatNumber(value, locale)
        : value.toLocaleString(locale);

    if (_gte != null && _lte != null) {
      switch (type) {
        case 'surface':
          return t('surfaceBetween', {
            over: formatValue(_gte),
            under: formatValue(_lte),
          });
        case 'land_surface':
          return t('landSurfaceBetween', {
            over: formatValue(_gte),
            under: formatValue(_lte),
          });
        case 'sale_price':
          return t('salePriceBetweenLabel', {
            over: formatValue(_gte),
            under: formatValue(_lte),
          });
        case 'price_per_sqm':
          return t('salePricePerLivingSurfaceAutoBetween', {
            over: formatValue(_gte),
            under: formatValue(_lte),
          });
      }
    }
    if (_lte != null) {
      switch (type) {
        case 'surface':
          return t('surfaceUnder', { under: formatValue(_lte) });
        case 'land_surface':
          return t('landSurfaceUnder', { under: formatValue(_lte) });
        case 'sale_price':
          return t('salePriceUnderLabel', { under: formatValue(_lte) });
        case 'price_per_sqm':
          return t('salePricePerLivingSurfaceAutoUnder', {
            under: formatValue(_lte),
          });
      }
    }
    if (_gte != null) {
      switch (type) {
        case 'surface':
          return t('surfaceOver', { over: formatValue(_gte) });
        case 'land_surface':
          return t('landSurfaceOver', { over: formatValue(_gte) });
        case 'sale_price':
          return t('salePriceOverLabel', { over: formatValue(_gte) });
        case 'price_per_sqm':
          return t('salePricePerLivingSurfaceOver', {
            over: formatValue(_gte),
          });
      }
    }
    switch (type) {
      case 'surface':
        return t('surface');
      case 'land_surface':
        return t('landSurface');
      case 'sale_price':
        return t('priceFilter');
      case 'price_per_sqm':
        return t('salePricePerLivingSurfaceAuto');
    }
  }, [_gte, _lte, locale, t, type]);

  const onChangeDebounced = useDebouncedHandler(800, setFilters);

  const handleReset = useCallback(() => {
    setRange({ from: actualMin.toString(), to: actualMax.toString() });
    setFilters({ [gteKey]: null, [lteKey]: null });
  }, [setFilters, actualMin, actualMax, gteKey, lteKey]);

  const handleRangeChange = useCallback(
    ({
      valueGte: from,
      valueLte: to,
    }: {
      valueGte: string;
      valueLte: string;
    }) => {
      setRange({ from, to });
      onChangeDebounced({
        [gteKey]:
          number_of_string(from) === actualMin ? null : number_of_string(from),
        [lteKey]:
          number_of_string(to) === actualMax ? null : number_of_string(to),
      });
    },
    [setRange, onChangeDebounced, actualMin, actualMax, gteKey, lteKey],
  );

  return (
    <Filter
      empty={_lte == null && _gte == null}
      onReset={handleReset}
      label={getLabel()}
      dialogTitle={
        type === 'surface'
          ? t('livingSurface')
          : type === 'land_surface'
          ? t('landSurface')
          : type === 'sale_price'
          ? t('priceFilter')
          : t('salePricePerLivingSurfaceAuto')
      }
    >
      <Box px={1} pb={2} sx={{ maxWidth: 380 }}>
        <RangeInput
          min={actualMin}
          max={actualMax}
          step={actualStep}
          valueGte={range.from}
          valueLte={range.to}
          onChange={handleRangeChange}
          renderInput={props => <InputComponent {...props} decimalScale={0} />}
        />
      </Box>
    </Filter>
  );
};

export default RangeFilter;
