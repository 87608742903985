import { FormControl, FormHelperText, FormLabel } from '@mui/material';
import { type Control, Controller } from 'react-hook-form';

import { useLocale } from '../../../src/hooks/locale';
import { LotSelect } from '../LotsSelect';

export type RaLotProps = {
  control: Control<any>;
  name: string;
  label: string;
  disabled?: boolean;
  required?: boolean;
};

export const RaLot = ({
  control,
  name,
  label,
  required,
  disabled = false,
}: RaLotProps) => {
  const { t } = useLocale();
  const rules = {
    required: required ? t('This field is required') : undefined,
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl fullWidth error={!!error}>
          <FormLabel style={{ marginBottom: '0.5rem', fontWeight: 500 }}>
            {label}
          </FormLabel>
          <LotSelect lotId={value} onChange={onChange} disabled={disabled} />
          <FormHelperText>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};
