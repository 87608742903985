/**
 * @generated SignedSource<<62b7298baf0bc3b4fc397b8e005d47a5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Feed_user$data = {
  readonly __typename: "User";
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"FeedEmailForm_user" | "FeedNewForm_user">;
  readonly " $fragmentType": "Feed_user";
};
export type Feed_user$key = {
  readonly " $data"?: Feed_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"Feed_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Feed_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeedNewForm_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeedEmailForm_user"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "e1aa7824a8211ad17d888753cc56fa44";

export default node;
