import { type ReactNode, memo } from 'react';

import { Box, Card, CardActionArea, useTheme } from '@mui/material';

type SelectableCardProps = {
  selected: boolean;
  onChange: (value: boolean) => void;
  children: ReactNode;
};

const SelectableCard = ({
  selected,
  onChange,
  children,
}: SelectableCardProps) => {
  const theme = useTheme();
  const bg = selected ? theme.palette.primary.main : theme.palette.grey[400];

  return (
    <Card
      css={[
        {
          position: 'relative',
          cursor: 'pointer',
          border: `4px transparent solid`,
          margin: -4,
          transition: 'all .15s ease',
          height: 200,
        },
        selected
          ? {
              borderColor: bg,
            }
          : {
              '&:hover': {
                borderColor: bg,
              },
            },
      ]}
      elevation={0}
      onClick={() => onChange(!selected)}
    >
      {selected && (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 1,
            top: 0,
            left: 0,
            height: 60,
            width: 60,
            transform: 'rotate(45deg) translateX(-70%)',
            background: bg,
          }}
        />
      )}
      <CardActionArea sx={{ height: '100%' }}>
        <Box
          sx={{
            position: 'relative',
            overflow: 'hidden',
            height: '100%',
            borderRadius: '4px',
            borderTopLeftRadius: selected ? 0 : '4px',
            backgroundColor: bg,
          }}
        >
          {children}
        </Box>
      </CardActionArea>
    </Card>
  );
};

export default memo(SelectableCard);
