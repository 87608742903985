import * as React from 'react';

const getBaseMimeType = (value: string) => value.replace(/\/.*$/, '');

const isMimeTypeAccepted = (mimeType: string, acceptedType: string) => {
  if (acceptedType === '*') {
    return true;
  }
  // Example; image/*
  if (acceptedType.endsWith('/*')) {
    // This is something like a image/* mime type
    return getBaseMimeType(mimeType) === getBaseMimeType(acceptedType);
  }
  // exact match
  return mimeType === acceptedType;
};

// from https://github.com/okonet/attr-accept
// check if the provided file type should be accepted
// by the input with accept attribute.
const isFileAccepted = (file: File, acceptedFiles: string) => {
  const name = file.name.toLowerCase();
  return acceptedFiles.split(/\s*,\s*/).some(acceptedType => {
    // Example: .pdf
    if (acceptedType.charAt(0) === '.') {
      return name.endsWith(acceptedType.toLowerCase());
    }
    return isMimeTypeAccepted(file.type, acceptedType);
  });
};

const isTransferItemAccepted = (
  item: DataTransferItem,
  acceptedFiles: string,
) => {
  return acceptedFiles
    .split(/\s*,\s*/)
    .some(acceptedType => isMimeTypeAccepted(item.type, acceptedType));
};

type DropZoneProps = {
  targetRef: { current: null | HTMLElement };
  accept?: string;
  onDragEnter?: (
    acceptedItems: DataTransferItem[],
    rejectedItems: DataTransferItem[],
  ) => void;
  onDragLeave?: () => void;
  onDrop: (files: File[]) => void;
};

export const useDropZone = ({
  targetRef,
  accept = '*',
  onDragEnter = () => {},
  onDragLeave = () => {},
  onDrop,
}: DropZoneProps): boolean => {
  const onDragEnterRef = React.useRef(onDragEnter);
  onDragEnterRef.current = onDragEnter;

  const onDragLeaveRef = React.useRef(onDragLeave);
  onDragLeaveRef.current = onDragLeave;

  const onDropRef = React.useRef(onDrop);
  onDropRef.current = onDrop;

  const [dragOver, setDragOver] = React.useState(false);

  React.useEffect(() => {
    const element = targetRef.current;
    if (element) {
      let entered = 0;

      const handleEvent = (event: DragEvent) => {
        if (event.type === 'dragenter') {
          entered += 1;
          if (entered === 1) {
            setDragOver(true);
            if (event.dataTransfer) {
              const items = Array.from(event.dataTransfer.items);
              const acceptedItems = items.filter(
                item => isTransferItemAccepted(item, accept) === true,
              );
              const rejectedItems = items.filter(
                item => isTransferItemAccepted(item, accept) === false,
              );
              onDragEnterRef.current(acceptedItems, rejectedItems);
            }
          }
        }
        if (event.type === 'dragleave') {
          entered -= 1;
          if (entered === 0) {
            setDragOver(false);
            onDragLeaveRef.current();
          }
        }
        if (event.type === 'dragover') {
          // to enable drop event
          event.preventDefault();
          if (event.dataTransfer) {
            event.dataTransfer.dropEffect = 'copy';
          }
        }
        if (event.type === 'drop') {
          // prevent file from being opened
          event.preventDefault();
          entered = 0;
          setDragOver(false);
          if (event.dataTransfer) {
            onDropRef.current(
              Array.from(event.dataTransfer.files).filter(file =>
                isFileAccepted(file, accept),
              ),
            );
          }
        }
      };

      const handleRootEvent = (event: DragEvent) => {
        if (event.type === 'dragover') {
          // allow the entire document to be a drag target
          event.preventDefault();
        }
        if (event.type === 'drop') {
          if (
            targetRef.current &&
            event.target instanceof Node &&
            targetRef.current.contains(event.target)
          ) {
            // if we intercepted an event for our instance,
            // let it propagate down to the instance's onDrop handler
            return;
          }
          event.preventDefault();
        }
      };

      element.addEventListener('dragenter', handleEvent);
      element.addEventListener('dragover', handleEvent);
      element.addEventListener('dragleave', handleEvent);
      element.addEventListener('drop', handleEvent);
      document.addEventListener('dragover', handleRootEvent);
      document.addEventListener('drop', handleRootEvent);

      return () => {
        element.removeEventListener('dragenter', handleEvent);
        element.removeEventListener('dragover', handleEvent);
        element.removeEventListener('dragleave', handleEvent);
        element.removeEventListener('drop', handleEvent);
        document.removeEventListener('dragover', handleRootEvent);
        document.removeEventListener('drop', handleRootEvent);
      };
    }
  }, [targetRef, accept]);

  return dragOver;
};

type FileDialogOptions = {
  accept?: string;
  multiple?: boolean;
  onChange: (files: File[]) => void;
};

export const useFileDialog = ({
  accept = '*',
  multiple = true,
  onChange,
}: FileDialogOptions): (() => void) => {
  const inputRef = React.useRef<null | HTMLInputElement>(null);

  const onChangeRef = React.useRef(onChange);
  // TODO temporary fix of late listener before scheduler.next is ready
  // TODO remove before enabling concurrent mode
  onChangeRef.current = onChange;

  const openFileDialog = () => {
    if (inputRef.current) {
      // reset current state to allow upload the same file
      inputRef.current.value = '';
      // click should be called inside of user click event
      // safari and firefox do not allow "progammic" clicks
      inputRef.current.click();
    }
  };

  React.useEffect(() => {
    const input = document.createElement('input');
    input.type = 'file';
    input.multiple = multiple;
    input.accept = accept;
    inputRef.current = input;

    const handleChange = (event: Event) => {
      if (
        event.target instanceof HTMLInputElement &&
        event.target.files != null
      ) {
        onChangeRef.current(Array.from(event.target.files));
      }
    };

    // input event does not work in safari
    input.addEventListener('change', handleChange);

    return () => {
      input.removeEventListener('change', handleChange);
    };
  }, [accept, multiple]);

  return openFileDialog;
};
