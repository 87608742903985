/**
 * @generated SignedSource<<49145587065671627c03c24875b20a18>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PipelineLeadType = "buyer" | "financing" | "sales";
import { FragmentRefs } from "relay-runtime";
export type FeedCall_activity$data = {
  readonly buyerLead?: {
    readonly __typename: "BuyerLead";
    readonly id: string;
    readonly lot: {
      readonly lead: {
        readonly contact: {
          readonly primaryPhoneNumber: {
            readonly formattedNumber: string;
            readonly number: string;
          } | null;
        } | null;
      } | null;
    };
  } | null;
  readonly canEdit?: boolean;
  readonly done?: boolean;
  readonly dueAt?: string | null;
  readonly enquiry?: {
    readonly __typename: "Enquiry";
    readonly id: string;
    readonly lot: {
      readonly lead: {
        readonly contact: {
          readonly primaryPhoneNumber: {
            readonly formattedNumber: string;
            readonly number: string;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
  readonly id: string;
  readonly lead?: {
    readonly __typename: "Lead";
    readonly contact: {
      readonly primaryPhoneNumber: {
        readonly formattedNumber: string;
        readonly number: string;
      } | null;
    } | null;
    readonly id: string;
    readonly stage: {
      readonly pipeline: {
        readonly leadType: PipelineLeadType;
      } | null;
    } | null;
  } | null;
  readonly lot?: {
    readonly __typename: "Lot";
    readonly id: string;
    readonly lead: {
      readonly contact: {
        readonly primaryPhoneNumber: {
          readonly formattedNumber: string;
          readonly number: string;
        } | null;
      } | null;
    } | null;
  } | null;
  readonly note?: string | null;
  readonly participants?: ReadonlyArray<{
    readonly user: {
      readonly primaryPhoneNumber: {
        readonly formattedNumber: string;
        readonly number: string;
      } | null;
    } | null;
  }> | null;
  readonly startDate?: string | null;
  readonly success?: boolean;
  readonly user?: {
    readonly __typename: "User";
    readonly id: string;
    readonly primaryPhoneNumber: {
      readonly formattedNumber: string;
      readonly number: string;
    } | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"activityWorkflowDialog_activity">;
  readonly " $fragmentType": "FeedCall_activity";
};
export type FeedCall_activity$key = {
  readonly " $data"?: FeedCall_activity$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedCall_activity">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "PhoneNumber",
  "kind": "LinkedField",
  "name": "primaryPhoneNumber",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "formattedNumber",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "contact",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Lead",
  "kind": "LinkedField",
  "name": "lead",
  "plural": false,
  "selections": [
    (v4/*: any*/)
  ],
  "storageKey": null
},
v6 = [
  (v1/*: any*/),
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Lot",
    "kind": "LinkedField",
    "name": "lot",
    "plural": false,
    "selections": [
      (v5/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedCall_activity",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "activityWorkflowDialog_activity"
    },
    (v0/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canEdit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "done",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dueAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "success",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "note",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Enquiry",
          "kind": "LinkedField",
          "name": "enquiry",
          "plural": false,
          "selections": (v6/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Lot",
          "kind": "LinkedField",
          "name": "lot",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v0/*: any*/),
            (v5/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Lead",
          "kind": "LinkedField",
          "name": "lead",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "LeadStage",
              "kind": "LinkedField",
              "name": "stage",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Pipeline",
                  "kind": "LinkedField",
                  "name": "pipeline",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "leadType",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v4/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v0/*: any*/),
            (v2/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BuyerLead",
          "kind": "LinkedField",
          "name": "buyerLead",
          "plural": false,
          "selections": (v6/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ActivityParticipant",
          "kind": "LinkedField",
          "name": "participants",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": (v3/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "ActivityCall",
      "abstractKey": null
    }
  ],
  "type": "Activity",
  "abstractKey": "__isActivity"
};
})();

(node as any).hash = "f980fdb3e7c2037cb5a5c5fa87353614";

export default node;
