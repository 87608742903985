// Needed until smooth scrolling support in Safari

type Ease = (t: number, from: number, to: number) => number;

const easeInQuad: Ease = (t, from, to) => {
  const d = 1;
  const c = to - from;

  t /= d;
  return c * t * t + from;
};

type AnimateParams = {
  duration: number;
  from: number;
  to: number;
  ease: Ease;
  onStep: (x: number, t: number) => void;
  onEnd?: () => void;
};

const animate = ({
  duration,
  from,
  to,
  ease,
  onStep,
}: AnimateParams): (() => void) => {
  const dtStart = new Date().getTime();
  const easingFn = ease;

  let frameId: null | number;

  const runner = () => {
    const t = Math.min((new Date().getTime() - dtStart) / duration, 1);
    const x = easingFn(t, from, to);

    onStep(x, t);

    if (t < 1) {
      frameId = requestAnimationFrame(runner);
    } else {
      frameId = null;
    }
  };

  frameId = requestAnimationFrame(runner);

  return () => {
    if (frameId != null) {
      cancelAnimationFrame(frameId);
    }
  };
};

export const scrollTo = (node: HTMLElement, left: number) => {
  node.scrollLeft = left;
};

export const smoothScrollTo = (node: HTMLElement, left: number) => {
  const isSmoothScrollSupported =
    document.documentElement != null &&
    'scrollBehavior' in document.documentElement.style;

  if (isSmoothScrollSupported && typeof node.scrollTo === 'function') {
    const scrollToOptions: ScrollToOptions = { behavior: 'smooth' };
    if (left != null) {
      scrollToOptions.left = left;
    }
    node.scrollTo(scrollToOptions);
    return;
  }

  animate({
    duration: 300,
    from: node.scrollLeft,
    to: left,
    ease: easeInQuad,
    onStep: x => {
      node.scrollLeft = x;
    },
  });
};
