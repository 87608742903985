/**
 * @generated SignedSource<<142f4e81554f20b9a95ad2eb1fee7ab1>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type financingLeadCreationDrawerQuery$variables = {||};
export type financingLeadCreationDrawerQuery$data = {|
  +pipelines: $ReadOnlyArray<{|
    +stages: $ReadOnlyArray<{|
      +id: string,
      +label: string,
    |}>,
  |}>,
|};
export type financingLeadCreationDrawerQuery = {|
  response: financingLeadCreationDrawerQuery$data,
  variables: financingLeadCreationDrawerQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "name",
    "value": "financing"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "LeadStage",
  "kind": "LinkedField",
  "name": "stages",
  "plural": true,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "financingLeadCreationDrawerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "Pipeline",
        "kind": "LinkedField",
        "name": "pipelines",
        "plural": true,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": "pipelines(name:\"financing\")"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "financingLeadCreationDrawerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "Pipeline",
        "kind": "LinkedField",
        "name": "pipelines",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": "pipelines(name:\"financing\")"
      }
    ]
  },
  "params": {
    "cacheID": "f6f440a891ad4cbb9c79f2f9449d8f3e",
    "id": null,
    "metadata": {},
    "name": "financingLeadCreationDrawerQuery",
    "operationKind": "query",
    "text": "query financingLeadCreationDrawerQuery {\n  pipelines(name: \"financing\") {\n    stages {\n      id\n      label\n    }\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "b9fa113a464263f800ef8dbfdb2119a0";

export default node;
