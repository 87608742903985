import { type FormikErrors, useFormik } from '@realadvisor/form';
import { addHours, format, parseISO, roundToNearestMinutes } from 'date-fns';
import { graphql, useFragment, useMutation } from 'react-relay';

import { formatDate, parseDate } from '../../controls/date-input';
import { formatTime, parseTime } from '../../controls/time-input';
import { type Translate, useLocale } from '../../hooks/locale';

import type {
  ActivityEventForm_activity$data,
  ActivityEventForm_activity$key,
} from './__generated__/ActivityEventForm_activity.graphql';
import type {
  ActivityEventForm_buyerLead$data,
  ActivityEventForm_buyerLead$key,
} from './__generated__/ActivityEventForm_buyerLead.graphql';
import type {
  ActivityEventForm_enquiry$data,
  ActivityEventForm_enquiry$key,
} from './__generated__/ActivityEventForm_enquiry.graphql';
import type {
  ActivityEventForm_lead$data,
  ActivityEventForm_lead$key,
} from './__generated__/ActivityEventForm_lead.graphql';
import type {
  ActivityEventForm_lot$data,
  ActivityEventForm_lot$key,
} from './__generated__/ActivityEventForm_lot.graphql';
import type {
  ActivityEventForm_root$data,
  ActivityEventForm_root$key,
} from './__generated__/ActivityEventForm_root.graphql';
import type {
  ActivityEventForm_user$data,
  ActivityEventForm_user$key,
} from './__generated__/ActivityEventForm_user.graphql';
import type {
  ActivityEventFormUpsertMutation,
  ActivityInput,
} from './__generated__/ActivityEventFormUpsertMutation.graphql';
import { ActivityEventDialog } from './activity-event-dialog';
import { getParticipantData } from './feed-form-utils';
import { activityOfTimeRange, timeRangeOfActivity } from './time-range-picker';
import { getDefaultSubject } from './utils';

type Props = {
  activityType: 'visit' | 'call' | 'task';
  enquiry: null | ActivityEventForm_enquiry$key;
  lot: null | ActivityEventForm_lot$key;
  lead: null | ActivityEventForm_lead$key;
  user: null | ActivityEventForm_user$key;
  buyerLead: null | ActivityEventForm_buyerLead$key;
  root: null | ActivityEventForm_root$key;
  activity: null | ActivityEventForm_activity$key;
  onSubmit: () => void;
  onCancel: () => void;
  openDialog: boolean;
  setOpenDialog: (value: boolean) => void;
  showUnfinishedWarning: boolean;
  disableDialogCollapse?: boolean;
};

export type ActivityEventParticipantData = NonNullable<
  ActivityEventForm_activity$data['displayParticipants']
>[number];

const getInitialValues = ({
  root,
  activity,
  parent,
  t,
  activityType,
}: {
  root: null | ActivityEventForm_root$data;
  activity: null | ActivityEventForm_activity$data;
  parent:
    | null
    | ActivityEventForm_enquiry$data
    | ActivityEventForm_lot$data
    | ActivityEventForm_lead$data
    | ActivityEventForm_user$data
    | ActivityEventForm_buyerLead$data;
  t: Translate;
  activityType: 'visit' | 'call' | 'task';
}) => {
  const assignedToUser = activity ? activity.assignedTo : root?.me;

  const getUserData = (
    datum:
      | NonNullable<ActivityEventForm_activity$data['assignedTo']>
      | NonNullable<ActivityEventForm_root$data['me']>,
  ) => ({
    id: datum.id ?? '',
    firstName: datum.firstName,
    lastName: datum.lastName,
    primaryImage: datum.primaryImage,
    primaryEmail: datum.primaryEmail,
    organisation: datum.organisation,
  });

  const getParticipants = (
    participants: NonNullable<
      ActivityEventForm_activity$data['displayParticipants']
    >,
  ) => {
    if (activity == null) {
      const result = [];

      if (parent != null) {
        if (parent.__typename === 'User') {
          result.push(getUserData(parent));
        }
        if (parent.__typename === 'Lead' && parent.contact != null) {
          result.push(getUserData(parent.contact));
        }
        if (parent.__typename === 'BuyerLead') {
          result.push(getUserData(parent.user));
        }
      }

      return result;
    } else if (participants != null && participants.length > 0) {
      return participants.filter(Boolean).map(getParticipantData);
    }

    return [];
  };

  const now = Date.now();
  const inTwoHours = roundToNearestMinutes(addHours(now, 2), { nearestTo: 15 });
  let dueAt = null;
  let endAt = null;
  let startDate = null;
  let timeRange = null;

  if (activityType === 'visit') {
    dueAt = activity?.dueAt != null ? parseISO(activity.dueAt) : inTwoHours;
    if (!activity) {
      endAt = addHours(inTwoHours, 1);
    } else if (activity.endAt != null) {
      endAt = parseISO(activity.endAt);
    }

    timeRange = timeRangeOfActivity({
      dueAt,
      startDate: activity?.startDate,
      endDate: activity?.endDate,
      endAt,
    });
  } else if (activityType === 'call' || activityType === 'task') {
    // If startDate is not null it means no dueAt time is set
    dueAt =
      activity?.startDate == null
        ? activity?.dueAt != null
          ? formatTime(new Date(activity.dueAt))
          : ''
        : '';
    startDate =
      activity?.startDate != null
        ? formatDate(new Date(activity.startDate))
        : activity?.dueAt != null
        ? formatDate(new Date(activity.dueAt))
        : formatDate(inTwoHours);
  }

  const assignedTo = assignedToUser ? getUserData(assignedToUser) : null;

  const prefilledSubject = getDefaultSubject({
    t,
    participants: getParticipants(activity?.displayParticipants ?? []).map(
      p => ({
        name: [p.firstName, p.lastName].filter(Boolean).join(' '),
      }),
    ),
    assignedTo: {
      firstName: assignedToUser?.firstName ?? null,
      lastName: assignedToUser?.lastName ?? null,
      organisationName: assignedToUser?.organisation?.name ?? null,
    },
    activityType,
  });

  return {
    createdBy: activity?.createdBy ?? root?.me,
    visibility: activity?.visibility ?? 'shared',
    notifyParticipants: true,
    timeRange,
    dueAt,
    startDate,
    editParent: false,
    note: activity?.note ?? '',
    subject: activity
      ? activity.subject
      : activityType !== 'task'
      ? prefilledSubject
      : '',
    targetUsers: getParticipants(activity?.displayParticipants ?? []),
    assignedTo,
    doneSuccess: JSON.stringify({
      done: activity?.done || false,
      success: activity?.success || false,
    }),

    done: activity?.done,

    location:
      activity?.location ?? activityType === 'visit'
        ? activity != null
          ? activity.location
          : parent?.__typename === 'Lead' || parent?.__typename === 'Lot'
          ? parent.property?.formattedAddress
          : null
        : null,
    parentType: parent?.__typename,
    parent,
  };
};

export const ActivityEventForm = (props: Props) => {
  const enquiry = useFragment(
    graphql`
      fragment ActivityEventForm_enquiry on Enquiry {
        __typename
        id
      }
    `,
    props.enquiry,
  );
  const lot = useFragment(
    graphql`
      fragment ActivityEventForm_lot on Lot {
        ...lotInput_lot @relay(mask: false)
        __typename
        id
        property {
          formattedAddress
        }
      }
    `,
    props.lot,
  );
  const lead = useFragment(
    graphql`
      fragment ActivityEventForm_lead on Lead {
        ...leadInput_lead @relay(mask: false)
        __typename
        id
        property {
          formattedAddress
        }
        contact {
          ...userInput_user @relay(mask: false)
        }
      }
    `,
    props.lead,
  );
  const user = useFragment(
    graphql`
      fragment ActivityEventForm_user on User {
        ...userInput_user @relay(mask: false)
        __typename
        id
      }
    `,
    props.user,
  );
  const buyerLead = useFragment(
    graphql`
      fragment ActivityEventForm_buyerLead on BuyerLead {
        ...buyerLeadInput_buyerLead @relay(mask: false)
        __typename
        id
        user {
          ...userInput_user @relay(mask: false)
        }
      }
    `,
    props.buyerLead,
  );
  const parent = enquiry ?? lot ?? lead ?? user ?? buyerLead;

  const root = useFragment(
    graphql`
      fragment ActivityEventForm_root on Query {
        me {
          isAdmin
          ...userInput_user @relay(mask: false)
          organisation {
            name
          }
          nylasAccount {
            calendarSyncEnabled
            email
            name
            user {
              ...userInput_user @relay(mask: false)
            }
          }
        }
      }
    `,
    props.root,
  );

  const activity = useFragment(
    graphql`
      fragment ActivityEventForm_activity on Activity {
        id
        visibility
        canEditVisibility
        ... on ActivityTask {
          note
          subject
          location
          dueAt
          startDate
          createdBy {
            ...userInput_user @relay(mask: false)
          }
          assignedTo {
            ...userInput_user @relay(mask: false)
            organisation {
              name
            }
          }
          displayParticipants {
            name
            email
            user {
              ...userInput_user @relay(mask: false)
            }
          }
          owner {
            email
          }
          done
          success
        }
        ... on ActivityCall {
          note
          subject
          location
          dueAt
          startDate
          createdBy {
            ...userInput_user @relay(mask: false)
          }
          assignedTo {
            ...userInput_user @relay(mask: false)
            organisation {
              name
            }
          }
          displayParticipants {
            name
            email
            user {
              ...userInput_user @relay(mask: false)
            }
          }
          owner {
            email
          }
          done
          success
        }
        ... on ActivityVisit {
          note
          subject
          location
          dueAt
          startDate
          endDate
          endAt
          createdBy {
            ...userInput_user @relay(mask: false)
          }
          assignedTo {
            ...userInput_user @relay(mask: false)
            organisation {
              name
            }
          }
          displayParticipants {
            name
            email
            user {
              ...userInput_user @relay(mask: false)
            }
          }
          owner {
            email
          }
          done
          success
        }
      }
    `,
    props.activity,
  );

  const [upsertActivity, upserting] =
    useMutation<ActivityEventFormUpsertMutation>(
      graphql`
        mutation ActivityEventFormUpsertMutation($input: UpsertActivityInput!) {
          upsertActivity(input: $input) {
            activity {
              id
              ...ActivityEventForm_activity
              enquiry {
                ...ActivityEventForm_enquiry
              }
              lot {
                ...ActivityEventForm_lot
              }
              lead {
                ...ActivityEventForm_lead
              }
              user {
                ...ActivityEventForm_user
              }
              buyerLead {
                ...ActivityEventForm_buyerLead
              }
            }
          }
        }
      `,
    );

  const { t } = useLocale();
  const {
    activityType,
    onCancel,
    setOpenDialog,
    openDialog,
    disableDialogCollapse,
    showUnfinishedWarning,
  } = props;

  const submit = (values: ReturnType<typeof getInitialValues>) => {
    const { notifyParticipants } = values;
    const { done, success } = JSON.parse(values.doneSuccess);
    let dueAt = null;
    let endAt = null;
    let startDate = null;
    let endDate = null;

    if (activityType === 'visit' && values.timeRange != null) {
      const dates = activityOfTimeRange(values.timeRange);
      dueAt = dates.dueAt ? dates.dueAt.toISOString() : null;
      endAt = dates.endAt ? dates.endAt.toISOString() : null;
      startDate = dates.startDate;
      endDate = dates.endDate;
    } else if (activityType === 'call' || activityType === 'task') {
      const parsedDate =
        values.startDate != null ? parseDate(values.startDate) : null;
      let parsedTime = null;
      if (parsedDate) {
        parsedTime =
          values.dueAt != null
            ? parseTime(parsedDate, values.dueAt.toString())
            : null;
        if (parsedTime == null) {
          // set whole day
          startDate = format(parsedDate, 'yyyy-MM-dd');
          // set start of day
          parsedTime = parseTime(parsedDate, '00:00');
        }
      }
      dueAt = parsedTime?.toISOString();
    }

    const participants = values.targetUsers.map(u => {
      const email = u.primaryEmail?.email != null ? u.primaryEmail.email : null;
      const id = u.id != null && u.id !== '' && u.id !== email ? u.id : null;
      return {
        name: [u.firstName, u.lastName].filter(Boolean).join(' '),
        email,
        id,
      };
    });

    const input: ActivityInput = {
      id: activity?.id,
      createdBy: values.createdBy?.id,
      parentId: values.parent?.id ?? null,
      activityType: props.activityType,
      note: values.note,
      subject: values.subject,
      location: values.location,
      assignedTo: values.assignedTo?.id ?? null,
      visibility: values.visibility,
      participants,
      done,
      success,
      dueAt,
      endAt,
      startDate,
      endDate,
    };

    if (done === true && activity?.done !== true) {
      input.doneAt = new Date().toISOString();
    }

    if (done === false && activity?.done === true) {
      input.doneAt = null;
    }

    upsertActivity({
      variables: {
        input: {
          activity: input,
          notifyParticipants: notifyParticipants ?? false,
        },
      },
      onCompleted: props.onSubmit,
    });
  };

  const form = useFormik({
    initialValues: getInitialValues({
      root,
      activity,
      parent,
      t,
      activityType,
    }),
    validate: values => {
      const errors: FormikErrors<ReturnType<typeof getInitialValues>> = {};

      if (activityType === 'task' || activityType === 'call') {
        const parsedDate = parseDate(values.startDate ?? '');
        if (parsedDate == null) {
          errors.startDate = t('invalidDate');
        }
      }

      if (activityType === 'visit' && values.timeRange != null) {
        const dates = activityOfTimeRange(values.timeRange);

        if (dates.startDate == null && dates.dueAt == null) {
          errors.startDate = t('invalidDate');
        }
      }

      if (!values.parent) {
        errors.parent = t('invalidParent');
      }

      if (values.subject?.trim().length === 0) {
        errors.subject = t('subjectRequired');
      }

      if (values.assignedTo == null) {
        errors.assignedTo = t('Assign to broker is required');
      }

      return errors;
    },
    onSubmit: values => {
      submit(values);
    },
  });

  // We can't notify participants if editing canceled activity
  const { done, success } = JSON.parse(form.values.doneSuccess);
  const nylasEmail = root?.me?.nylasAccount?.email;
  const activityStillCanceled =
    activity?.done === true &&
    activity.success === false &&
    done === true &&
    success === false;

  // This was merged before functionality is ready, so hide it from the user for now
  const notifyParticipants = false;
  const allowNotifyParticipants =
    notifyParticipants &&
    root?.me?.nylasAccount?.calendarSyncEnabled === true &&
    activityType === 'visit' &&
    !activityStillCanceled &&
    (activity == null ? true : nylasEmail === activity.owner?.email);

  return (
    <ActivityEventDialog
      openDialog={openDialog}
      disableDialogCollapse={disableDialogCollapse}
      form={form}
      nylasEmail={nylasEmail ?? null}
      isWorkflow={false}
      isCreating={activity != null ? true : false}
      activityType={activityType}
      isUpserting={upserting}
      allowNotifyParticipants={allowNotifyParticipants}
      canEditParent={true}
      onSubmit={form.submitForm}
      onCancel={onCancel}
      isAdmin={root?.me?.isAdmin ?? false}
      canEditVisibility={
        activity?.canEditVisibility ?? root?.me?.isAdmin ?? false
      }
      setOpenDialog={setOpenDialog}
      showUnfinishedWarning={showUnfinishedWarning}
    />
  );
};
