import * as React from 'react';

import { Flex } from 'react-system';

import { useTheme } from '../../hooks/theme';

type Props = {
  length: number;
  onChange: (value: string) => void;
};

type State = {
  values: string[];
};

type ItemProps = {
  autoFocus: boolean;
  value: string;
  onChange: (value: string) => void;
  onBackspace: () => void;
};

const Item = React.forwardRef<HTMLInputElement, ItemProps>((props, ref) => {
  const { borderRadius, text } = useTheme();
  return (
    <input
      ref={ref}
      css={{
        margin: '0 2px',
        textAlign: 'center',
        background: 'transparent',
        color: 'black',
        width: 40,
        height: 40,
        borderRadius: borderRadius.small,
        border: '1px solid #9e9e9e',
        boxSizing: 'border-box',
        fontWeight: text.font.bold,
        fontSize: '1.2em',
        padding: 5,

        ':focus': {
          outline: '1px solid #9e9e9e',
          border: '1px solid #9e9e9e',
        },
      }}
      maxLength={1}
      autoComplete="off"
      type="tel"
      autoFocus={props.autoFocus}
      value={props.value}
      onChange={e => {
        let { value } = e.target;
        const numCode = value.charCodeAt(0);
        const isInteger =
          numCode >= '0'.charCodeAt(0) && numCode <= '9'.charCodeAt(0);
        if (!isInteger) {
          value = '';
        }
        if (props.value === value) {
          return;
        }
        if (value.length < 2) {
          props.onChange(value);
        }
      }}
      onKeyDown={e => {
        if (e.keyCode === 8 && props.value.length === 0) {
          props.onBackspace();
        }
      }}
    />
  );
});

export class PinInput extends React.Component<Props, State> {
  state: State = {
    values: Array.from<void, string>(Array(this.props.length), () => ''),
  };
  inputRefs: Array<any> = this.state.values.map<any>(() => React.createRef());
  render() {
    return (
      <Flex justifyContent="center">
        {this.state.values.map((value, index) => (
          <Item
            key={index}
            ref={this.inputRefs[index]}
            value={value}
            autoFocus={index === 0}
            onChange={v => {
              this.setState(
                prev => ({
                  values: prev.values.map((d, i) => (index === i ? v : d)),
                }),
                () => {
                  // Set focus on next
                  if (v.length === 1 && index < this.props.length - 1) {
                    const { current } = this.inputRefs[index + 1];
                    if (current) {
                      current.focus();
                    }
                  }

                  // Notify the parent
                  const pin = this.state.values.join('');

                  if (pin.length === this.props.length) {
                    this.props.onChange(pin);
                  }
                },
              );
            }}
            onBackspace={() => {
              if (index !== 0) {
                const { current } = this.inputRefs[index - 1];
                if (current) {
                  current.focus();
                }
              }
            }}
          />
        ))}
      </Flex>
    );
  }
}
