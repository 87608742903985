import { useState } from 'react';

import { useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import { useConstant } from '@realadvisor/hooks';

import { fromGlobalId, toGlobalId } from '../../../../../shared/global-id';
import { Filter } from '../../../../../src/controls/Filters';
import { useLocale } from '../../../../../src/hooks/locale';
import {
  PlacePicker,
  type PlacePickerPlace,
} from '../../../../../src/shared/place-picker';
import type {
  AggregatesListingsListFiltersPlacesQuery,
  Merged_Listings_Compare_Args,
} from '../../../../__generated__/graphql';
import { AGGREGATES_LISTINGS_LIST_FILTERS_QUERY } from '../../cmaReportsQueries';

export type MapViewport = {
  lat: number;
  lng: number;
  z: number;
};

const PlaceFilter = ({
  filters,
  setFilters,
  defaultMapViewport,
}: {
  filters: Partial<Merged_Listings_Compare_Args>;
  setFilters: (filters: Partial<Merged_Listings_Compare_Args>) => void;
  defaultMapViewport: null | MapViewport;
}) => {
  const { t, countryCode } = useLocale();
  const ids = [
    ...(filters.locality_id_in ?? '').split(','),
    ...(filters.municipality_id_in ?? '').split(','),
    ...(filters.neighbourhood_id_in ?? '').split(','),
    ...(filters.state_id_in ?? '').split(','),
    ...(filters.district_id_in ?? '').split(','),
  ].filter(Boolean);
  const initialPlaceIds = useConstant(() => ids);
  const [places, setPlaces] = useState<null | PlacePickerPlace[]>(null);
  const labelsCache = useConstant(() => new Map());
  const { data } = useQuery(AGGREGATES_LISTINGS_LIST_FILTERS_QUERY, {
    variables: {
      placesIds: initialPlaceIds,
      ch: countryCode === 'CH',
      fr: countryCode === 'FR',
      es: countryCode === 'ES',
      it: countryCode === 'IT',
    },
  });
  const fetchedPlaces =
    data?.ch_places ?? data?.fr_places ?? data?.es_places ?? [];

  // for flow
  if (defaultMapViewport == null) {
    return null;
  }

  type Place = NonNullable<
    | AggregatesListingsListFiltersPlacesQuery['ch_places']
    | AggregatesListingsListFiltersPlacesQuery['fr_places']
    | AggregatesListingsListFiltersPlacesQuery['es_places']
    | AggregatesListingsListFiltersPlacesQuery['it_places']
  >[number];

  const mapFields = (values: Place[]): PlacePickerPlace[] =>
    values.map(place => ({
      id: toGlobalId('Place', place._id),
      type: place.type as any,
      objectId: place._id,
      lat: place.lat,
      lng: place.lng,
      label: place.label ?? '',
      population: place.population ?? null,
      breadcrumbs:
        place.breadcrumbs.map(bc => ({
          id: toGlobalId('Place', bc._id ?? ''),
        })) ?? [],
    }));

  const getLabel = (items: string[]) => {
    if (items.length === 0) {
      return t('places');
    }
    const labels = items.flatMap(id => {
      const label = labelsCache.get(id);
      return label == null ? [] : [label];
    });
    if (labels.length === 0) {
      return t('placesCount', { count: items.length });
    }
    if (items.length === 1) {
      return labels[0];
    }
    return `${labels[0]} +${items.length - 1}`;
  };

  return (
    <Filter
      label={getLabel(ids)}
      dialogTitle={t('places')}
      empty={ids.length === 0}
      onReset={() => {
        setFilters({
          locality_id_in: null,
          municipality_id_in: null,
          neighbourhood_id_in: null,
          state_id_in: null,
          district_id_in: null,
        });
        setPlaces([]);
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        pt={1}
        sx={{
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          height: 'calc(100vh - 128px)',
          maxWidth: '100%',
          width: { xs: '100vw', sm: 'calc(100vw - 64px)' },
        }}
      >
        <PlacePicker
          value={places ?? mapFields(fetchedPlaces.slice())}
          onChange={newPlaces => {
            setPlaces(newPlaces);

            for (const place of newPlaces) {
              const id = place.id;
              labelsCache.set(id, place.label);
            }

            setFilters({
              locality_id_in: newPlaces
                .filter(place => place.type === 'locality')
                .map(place => fromGlobalId(place.id))
                .join(','),
              municipality_id_in: newPlaces
                .filter(place => place.type === 'municipality')
                .map(place => fromGlobalId(place.id))
                .join(','),
              neighbourhood_id_in: newPlaces
                .filter(place => place.type === 'neighbourhood')
                .map(place => fromGlobalId(place.id))
                .join(','),
              state_id_in: newPlaces
                .filter(place => place.type === 'state')
                .map(place => fromGlobalId(place.id))
                .join(','),
              district_id_in: newPlaces
                .filter(place => place.type === 'district')
                .map(place => fromGlobalId(place.id))
                .join(','),
            });
          }}
          initialCenter={{
            lat: defaultMapViewport.lat,
            lng: defaultMapViewport.lng,
          }}
          initialZoom={defaultMapViewport.z}
        />
      </Box>
    </Filter>
  );
};

export default PlaceFilter;
