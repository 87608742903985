import * as React from 'react';

import { graphql, useFragment } from 'react-relay';
import { Link } from 'react-router-dom';

import { useTheme } from '../../hooks/theme';
import { getUserNameUtil } from '../../shared/user-input';

import type {
  UserName_user$data,
  UserName_user$key,
} from './__generated__/UserName_user.graphql';

type Props = {
  user: UserName_user$key | null;
};

const getUserName = (user: UserName_user$data): string => {
  if (user == null) {
    return '';
  }
  const firstName = user.firstName ?? '';
  const lastName = user.lastName ?? '';
  const email = user.emails?.[0]?.email ?? null;
  return getUserNameUtil({ firstName, lastName, email });
};

const UserLink = ({ user }: { user: UserName_user$data }) => {
  const { text } = useTheme();
  const username = getUserName(user);

  return (
    <Link style={{ fontWeight: text.font.bold }} to={`/v1/users/${user.id}`}>
      {username}
    </Link>
  );
};

export const UserName = (props: Props) => {
  const user = useFragment<UserName_user$key>(
    graphql`
      fragment UserName_user on User {
        id
        firstName
        lastName
        emails {
          email
        }
      }
    `,
    props.user,
  );

  return user ? <UserLink user={user} /> : 'Name is not defined';
};
