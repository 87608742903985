/**
 * @generated SignedSource<<29100f029e0da7944d9615c7662ca832>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type leadQualificationCard_root$data = {
  readonly mandateProbabilityTypes: ReadonlyArray<{
    readonly label: string;
    readonly name: string;
  }>;
  readonly pipelinesStages: ReadonlyArray<{
    readonly id: string;
    readonly label: string | null;
    readonly stages: ReadonlyArray<{
      readonly id: string;
      readonly label: string;
      readonly name: string | null;
    }>;
  }>;
  readonly tagsTypes: ReadonlyArray<{
    readonly id: string;
    readonly label: string;
    readonly name: string;
  }>;
  readonly " $fragmentType": "leadQualificationCard_root";
};
export type leadQualificationCard_root$key = {
  readonly " $data"?: leadQualificationCard_root$data;
  readonly " $fragmentSpreads": FragmentRefs<"leadQualificationCard_root">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "leadQualificationCard_root",
  "selections": [
    {
      "alias": "mandateProbabilityTypes",
      "args": [
        {
          "kind": "Literal",
          "name": "type",
          "value": "mandate_probability_types"
        }
      ],
      "concreteType": "Dictionary",
      "kind": "LinkedField",
      "name": "dictionaries",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": "dictionaries(type:\"mandate_probability_types\")"
    },
    {
      "alias": "tagsTypes",
      "args": [
        {
          "kind": "Literal",
          "name": "type",
          "value": "tags_types"
        }
      ],
      "concreteType": "Dictionary",
      "kind": "LinkedField",
      "name": "dictionaries",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": "dictionaries(type:\"tags_types\")"
    },
    {
      "alias": "pipelinesStages",
      "args": [
        {
          "kind": "Literal",
          "name": "leadType",
          "value": "sales"
        }
      ],
      "concreteType": "Pipeline",
      "kind": "LinkedField",
      "name": "pipelines",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "LeadStage",
          "kind": "LinkedField",
          "name": "stages",
          "plural": true,
          "selections": [
            (v2/*: any*/),
            (v1/*: any*/),
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": "pipelines(leadType:\"sales\")"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "359955b8dd1796fb067eb6c1631c3835";

export default node;
