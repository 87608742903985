// Generated from src/svgs/gift-outline.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const GiftOutline = ({ size, fill, className }: Props) => {
  return (
    <svg xmlSpace="preserve" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><path style={{"fill":"none"}} d="M0 0h24v24H0z" /><path d="M20 8h-3.115a4.96 4.96 0 0 0 1.236-.879 3.002 3.002 0 0 0 0-4.242c-1.134-1.133-3.108-1.133-4.242 0C12.242 4.516 12.02 7.597 12 7.943c-.02-.347-.242-3.428-1.879-5.064-1.134-1.133-3.11-1.132-4.242 0C5.313 3.445 5 4.199 5 5s.313 1.555.879 2.121c.357.358.784.644 1.236.879H4c-1.104 0-2 .897-2 2v3a1 1 0 0 0 1 1v6c0 1.103.896 2 2 2h14c1.104 0 2-.897 2-2v-6a1 1 0 0 0 1-1v-3c0-1.103-.896-2-2-2zM8 20H5v-6h3v6zm0-8H4v-2h4v2zm-.707-6.293C7.104 5.518 7 5.267 7 5s.104-.518.293-.707c.189-.189.439-.292.707-.292s.518.104.707.293c.59.589.934 1.647 1.118 2.53-.88-.185-1.945-.529-2.532-1.117zM14 20h-4v-6h4v6zm0-8h-4v-2h4v2zm1.293-7.706c.189-.189.439-.293.707-.293s.518.104.707.292c.389.391.39 1.024 0 1.414-.591.591-1.65.936-2.533 1.119.184-.883.529-1.942 1.119-2.532zM19 20h-3v-6h3v6zm1-8h-4v-2h4v2z" /></svg>
  );
}
