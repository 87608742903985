import { useCallback } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';

import {
  REALADVISOR_ES_TENANT_ID,
  REALADVISOR_FR_TENANT_ID,
  REALADVISOR_IT_TENANT_ID,
  REALADVISOR_TENANT_ID,
} from '../../../../src/utils/tenant';
import { useAppData } from '../../../providers/AppDataProvider';
import { useGetDefaultApolloTemplate } from '../../../utils/template';
import { GET_CMA_REPORTS_LIST } from '../CMAReports';
import {
  CREATE_CMA_REPORT,
  DOC_TEMPLATES_CMA_REPORT,
  GET_CMA_REPORTS_COUNT,
} from '../cmaReportsQueries';

export const useCreateCMAReport = () => {
  const [createCMAReportMutation] = useMutation(CREATE_CMA_REPORT, {
    refetchQueries: [GET_CMA_REPORTS_LIST, GET_CMA_REPORTS_COUNT],
  });
  const [getDocTemplates] = useLazyQuery(DOC_TEMPLATES_CMA_REPORT, {
    fetchPolicy: 'network-only',
  });
  const { getDefaultTemplate } = useGetDefaultApolloTemplate();
  const { me } = useAppData();

  const createCMAReport = useCallback(
    async (values: { leadId: string; contactId?: string }) => {
      const { data: docTemplatesData } = await getDocTemplates();
      const tenantId = me?.tenant.id;
      const userLanguage = me?.language;
      const language = determineLanguage(tenantId, userLanguage);

      const defaultTemplateId = getDefaultTemplate(
        docTemplatesData?.doc_templates ?? [],
      )?.id;

      const input = {
        lead_id: values.leadId,
        doc_template_id: defaultTemplateId,
        contact_id: values.contactId,
        broker_id: me?.id,
        language,
      };

      const { data } = await createCMAReportMutation({ variables: { input } });
      return data?.insert_cma_reports_one?.id;
    },
    [
      createCMAReportMutation,
      getDefaultTemplate,
      getDocTemplates,
      me?.id,
      me?.language,
      me?.tenant.id,
    ],
  );

  return createCMAReport;
};

export const determineLanguage = (
  tenantId: string | undefined,
  userLanguage: string | undefined,
): string => {
  if (tenantId === REALADVISOR_TENANT_ID && userLanguage === 'es') {
    return 'en';
  } else if (tenantId === REALADVISOR_ES_TENANT_ID && userLanguage !== 'es') {
    return 'en';
  } else if (tenantId === REALADVISOR_FR_TENANT_ID && userLanguage !== 'fr') {
    return 'en';
  } else if (tenantId === REALADVISOR_IT_TENANT_ID && userLanguage !== 'it') {
    return 'en';
  }
  return userLanguage ?? 'en';
};
