/**
 * @generated SignedSource<<9930b27cdf1b81e1c5edb1da72219bc9>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type DevelopmentsFilters = {|
  brokerId_in?: ?$ReadOnlyArray<?string>,
  broker_organisations_id_in?: ?$ReadOnlyArray<?string>,
  isPublished_eq?: ?boolean,
  properties_placeId_in?: ?$ReadOnlyArray<string>,
  search?: ?string,
  status_in?: ?$ReadOnlyArray<string>,
|};
export type developmentInputQuery$variables = {|
  filters?: ?DevelopmentsFilters,
|};
export type developmentInputQuery$data = {|
  +developments: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +deliveryDate: ?string,
        +id: string,
        +primaryDevelopmentImage: ?{|
          +image: {|
            +url: string,
          |},
        |},
        +title: ?string,
      |},
    |}>,
  |},
|};
export type developmentInputQuery = {|
  response: developmentInputQuery$data,
  variables: developmentInputQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deliveryDate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "developmentInputQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DevelopmentConnection",
        "kind": "LinkedField",
        "name": "developments",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DevelopmentEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Development",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DevelopmentImage",
                    "kind": "LinkedField",
                    "name": "primaryDevelopmentImage",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Image",
                        "kind": "LinkedField",
                        "name": "image",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "developmentInputQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DevelopmentConnection",
        "kind": "LinkedField",
        "name": "developments",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DevelopmentEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Development",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DevelopmentImage",
                    "kind": "LinkedField",
                    "name": "primaryDevelopmentImage",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Image",
                        "kind": "LinkedField",
                        "name": "image",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "631dd9ee5d4719455b5a9470e83ec203",
    "id": null,
    "metadata": {},
    "name": "developmentInputQuery",
    "operationKind": "query",
    "text": "query developmentInputQuery(\n  $filters: DevelopmentsFilters\n) {\n  developments(first: 20, filters: $filters) {\n    edges {\n      node {\n        id\n        title\n        deliveryDate\n        primaryDevelopmentImage {\n          image {\n            url\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "f5cdbd23a0a764b05efd8f32a33b1262";

export default node;
