/**
 * @generated SignedSource<<059b1c4aabcd9132f263bd71b77be0fc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type leadLotCard_lead$data = {
  readonly id: string;
  readonly lot: {
    readonly id: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"lotMandateCreateDialog_lead">;
  readonly " $fragmentType": "leadLotCard_lead";
};
export type leadLotCard_lead$key = {
  readonly " $data"?: leadLotCard_lead$data;
  readonly " $fragmentSpreads": FragmentRefs<"leadLotCard_lead">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "leadLotCard_lead",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "lotMandateCreateDialog_lead"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Lot",
      "kind": "LinkedField",
      "name": "lot",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Lead",
  "abstractKey": null
};
})();

(node as any).hash = "1d46df2d5c6f01c18c12ea33109eed6d";

export default node;
