import * as React from 'react';

import { Button, DialogActions, DialogContent } from '@material-ui/core';
import { Form, type FormikErrors, useFormik } from '@realadvisor/form';
import { graphql, useFragment, useMutation } from 'react-relay';
import { Box, useResponsive, useSystem } from 'react-system';

import { MarkdownComposer } from '../../../shared/composer';
import { CollapsableDialog } from '../../controls/collapsable-dialog';
import { ProgressButton } from '../../controls/progress-button';
import { useLocale } from '../../hooks/locale';
import { useTheme } from '../../hooks/theme';
import { Lock } from '../../icons/lock';
import { LockOpen } from '../../icons/lock-open';
import { PeopleOutline } from '../../icons/people-outline';

import type {
  ActivityNoteForm_activity$data,
  ActivityNoteForm_activity$key,
} from './__generated__/ActivityNoteForm_activity.graphql';
import type {
  ActivityNoteForm_buyerLead$data,
  ActivityNoteForm_buyerLead$key,
} from './__generated__/ActivityNoteForm_buyerLead.graphql';
import type {
  ActivityNoteForm_enquiry$data,
  ActivityNoteForm_enquiry$key,
} from './__generated__/ActivityNoteForm_enquiry.graphql';
import type {
  ActivityNoteForm_lead$data,
  ActivityNoteForm_lead$key,
} from './__generated__/ActivityNoteForm_lead.graphql';
import type {
  ActivityNoteForm_lot$data,
  ActivityNoteForm_lot$key,
} from './__generated__/ActivityNoteForm_lot.graphql';
import type {
  ActivityNoteForm_root$data,
  ActivityNoteForm_root$key,
} from './__generated__/ActivityNoteForm_root.graphql';
import type {
  ActivityNoteForm_user$data,
  ActivityNoteForm_user$key,
} from './__generated__/ActivityNoteForm_user.graphql';
import type {
  ActivityNoteFormUpsertMutation,
  ActivityTypeEnum,
} from './__generated__/ActivityNoteFormUpsertMutation.graphql';
import { DialogWarning } from './dialog-warning';
import {
  FieldContacts,
  FieldCreatedBy,
  FieldParent,
  FieldVisibility,
} from './Fields';

type Props = {
  enquiry: null | ActivityNoteForm_enquiry$key;
  lot: null | ActivityNoteForm_lot$key;
  lead: null | ActivityNoteForm_lead$key;
  user: null | ActivityNoteForm_user$key;
  buyerLead: null | ActivityNoteForm_buyerLead$key;
  activity: null | ActivityNoteForm_activity$key;
  root: null | ActivityNoteForm_root$key;
  onSubmit: () => void;
  onCancel: () => void;
  openDialog: boolean;
  setOpenDialog: (value: boolean) => void;
  showUnfinishedWarning: boolean;
};

const Subtitle = ({ children }: { children: React.ReactNode }) => {
  const { text } = useTheme();
  return (
    <Box pb={2} css={[text.subtitle2]}>
      {children}
    </Box>
  );
};

const getInitialValues = ({
  parent,
  activity,
  currentUser,
}: {
  parent:
    | ActivityNoteForm_enquiry$data
    | ActivityNoteForm_lot$data
    | ActivityNoteForm_lead$data
    | ActivityNoteForm_user$data
    | ActivityNoteForm_buyerLead$data
    | null;
  activity: null | ActivityNoteForm_activity$data;
  currentUser: ActivityNoteForm_root$data['me'];
}) => {
  const getUserData = (
    datum:
      | NonNullable<ActivityNoteForm_user$data>
      | NonNullable<ActivityNoteForm_lead$data['contact']>,
  ) => ({
    id: datum.id ?? '',
    firstName: datum.firstName,
    lastName: datum.lastName,
    primaryImage: datum.primaryImage,
    primaryEmail: datum.primaryEmail,
  });

  const getParticipantData = (
    datum: NonNullable<ActivityNoteForm_activity$data['participants']>[number],
  ) => {
    return {
      id: datum.user?.id ?? datum.email ?? '',
      firstName: datum.user?.firstName ?? datum.name ?? datum.email,
      lastName: datum.user?.lastName ?? null,
      primaryImage: datum.user?.primaryImage ?? null,
      primaryEmail:
        datum.email != null
          ? {
              email: datum.email,
            }
          : null,
    };
  };

  const getParticipants = (
    participants: ActivityNoteForm_activity$data['participants'],
  ) => {
    if (activity == null) {
      const result = [];

      if (parent != null) {
        if (parent.__typename === 'User') {
          result.push(getUserData(parent));
        }
        if (parent.__typename === 'Lead' && parent.contact != null) {
          result.push(getUserData(parent.contact));
        }
        if (parent.__typename === 'BuyerLead') {
          result.push(getUserData(parent.user));
        }
      }

      return result;
    } else if (participants != null && participants.length > 0) {
      return participants.filter(Boolean).map(getParticipantData);
    }

    return [];
  };

  return {
    createdBy: activity?.createdBy ?? currentUser,
    visibility: activity?.visibility ?? 'shared',
    note: activity?.note ?? '',
    targetUsers: getParticipants(activity?.participants),
    parentType: parent?.__typename ?? '',
    parent,
  };
};

export const ActivityNoteForm = (props: Props) => {
  const root = useFragment(
    graphql`
      fragment ActivityNoteForm_root on Query {
        me {
          id
          isAdmin
          ...userInput_user @relay(mask: false)
        }
      }
    `,
    props.root,
  );

  const enquiry = useFragment(
    graphql`
      fragment ActivityNoteForm_enquiry on Enquiry {
        __typename
        id
      }
    `,
    props.enquiry,
  );
  const lot = useFragment(
    graphql`
      fragment ActivityNoteForm_lot on Lot {
        ...lotInput_lot @relay(mask: false)
        __typename
        id
      }
    `,
    props.lot,
  );
  const lead = useFragment(
    graphql`
      fragment ActivityNoteForm_lead on Lead {
        ...leadInput_lead @relay(mask: false)
        __typename
        id
        contact {
          ...userInput_user @relay(mask: false)
        }
      }
    `,
    props.lead,
  );
  const user = useFragment(
    graphql`
      fragment ActivityNoteForm_user on User {
        ...userInput_user @relay(mask: false)
        __typename
        id
      }
    `,
    props.user,
  );
  const buyerLead = useFragment(
    graphql`
      fragment ActivityNoteForm_buyerLead on BuyerLead {
        ...buyerLeadInput_buyerLead @relay(mask: false)
        __typename
        id
        user {
          ...userInput_user @relay(mask: false)
        }
      }
    `,
    props.buyerLead,
  );
  const parent = enquiry ?? lot ?? lead ?? user ?? buyerLead;

  const activity = useFragment(
    graphql`
      fragment ActivityNoteForm_activity on Activity {
        id
        ... on ActivityNote {
          createdBy {
            ...userInput_user @relay(mask: false)
          }
          visibility
          note
          participants {
            name
            email
            user {
              ...userInput_user @relay(mask: false)
            }
          }
        }
      }
    `,
    props.activity,
  );

  const [upsertActivity, upserting] =
    useMutation<ActivityNoteFormUpsertMutation>(
      graphql`
        mutation ActivityNoteFormUpsertMutation($input: UpsertActivityInput!) {
          upsertActivity(input: $input) {
            activity {
              id
              ...ActivityNoteForm_activity
              enquiry {
                ...ActivityNoteForm_enquiry
              }
              lot {
                ...ActivityNoteForm_lot
              }
              lead {
                ...ActivityNoteForm_lead
              }
              user {
                ...ActivityNoteForm_user
              }
              buyerLead {
                ...ActivityNoteForm_buyerLead
              }
            }
          }
        }
      `,
    );

  const { t } = useLocale();
  const responsive = useResponsive();
  const { media } = useSystem();
  const initialValues = getInitialValues({
    parent,
    activity,
    currentUser: root?.me ?? null,
  });
  const [expanded, toggleExpand] = React.useReducer(show => !show, false);
  const form = useFormik({
    initialValues,
    validate: values => {
      const errors: FormikErrors<ReturnType<typeof getInitialValues>> = {};

      if (!values.parent) {
        errors.parent = t('invalidParent');
      }

      return errors;
    },
    onSubmit: values => {
      const participants = values.targetUsers.map(u => {
        const email =
          u.primaryEmail?.email != null ? u.primaryEmail.email : null;
        const id = u.id != null && u.id !== '' && u.id !== email ? u.id : null;
        return {
          name: [u.firstName, u.lastName].filter(Boolean).join(' '),
          email,
          id,
        };
      });

      const input = {
        id: activity?.id,
        createdBy: values.createdBy?.id,
        parentId: values.parent?.id ?? null,
        activityType: 'note' as ActivityTypeEnum,
        note: values.note,
        visibility: values.visibility,
        participants,
      };

      upsertActivity({
        variables: {
          input: {
            activity: input,
          },
        },
        onCompleted: props.onSubmit,
      });
    },
  });

  const parentRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (expanded) {
      parentRef.current?.scrollIntoView();
    }
  }, [expanded]);

  const onCancel = () => {
    if (form.changed) {
      if (confirm(t('noteNotSavedMessage'))) {
        props.onCancel();
      }
    } else {
      props.onCancel();
    }
  };

  return (
    <CollapsableDialog
      open={props.openDialog}
      onCollapse={() => props.setOpenDialog(false)}
      onClose={onCancel}
      fullScreen={responsive([true, false])}
      title={t('writeNote')}
    >
      <DialogContent>
        <Form
          onSubmit={form.submitForm}
          css={media({
            display: 'grid',
            gridColumnGap: [8, 12],
            gridRowGap: 12,
            alignItems: 'center',
            gridTemplateColumns: '24px 1fr',
          })}
        >
          {props.showUnfinishedWarning && <DialogWarning />}
          <PeopleOutline />
          <FieldContacts values={form.values} setValues={form.setValues} />
          {root?.me?.isAdmin === true && (
            <>
              {(form.values.visibility === 'shared' ||
                form.values.visibility === 'organisation') && <LockOpen />}
              {form.values.visibility === 'private' && <Lock />}
              <FieldVisibility
                values={form.values}
                setValues={form.setValues}
              />
            </>
          )}
          <Box pt={2} css={{ gridColumn: '1/-1' }}>
            <Subtitle>{t('note')}</Subtitle>
            <MarkdownComposer
              initialValue={form.values.note}
              onChange={value => form.setValues({ note: value })}
            />
            {expanded && (
              <>
                {root?.me?.isAdmin === true && (
                  <Box pt={3} ref={parentRef} css={{ gridColumn: '1/-1' }}>
                    <Subtitle>{t('createdBy')}</Subtitle>
                    <FieldCreatedBy
                      values={form.values}
                      setValues={form.setValues}
                    />
                  </Box>
                )}
                <Box pt={3} ref={parentRef} css={{ gridColumn: '1/-1' }}>
                  <Subtitle>{t('parent')}</Subtitle>
                  <FieldParent
                    error={form.errors.parent != null}
                    helperText={form.errors.parent}
                    values={form.values}
                    setValues={form.setValues}
                  />
                </Box>
              </>
            )}
          </Box>
        </Form>
      </DialogContent>
      <DialogActions>
        <Box mr="auto">
          <Button onClick={toggleExpand}>
            {expanded ? t('showLess') : t('showMore')}
          </Button>
        </Box>
        <Button onClick={onCancel}>{t('cancel')}</Button>
        <ProgressButton
          loading={upserting}
          disabled={!form.valid || !form.changed}
          onClick={form.submitForm}
        >
          {activity ? t('save') : t('create')}
        </ProgressButton>
      </DialogActions>
    </CollapsableDialog>
  );
};
