import { AccordionSummary } from '@material-ui/core';
import { Image } from '@realadvisor/image';
import { Box, Flex } from 'react-system';

import { useTheme } from '../hooks/theme';
import { ExpandMore } from '../icons/expand-more';

type Props = {
  expanded: boolean;
  title: string;
  subTitle?: string;
  avatarUrl?: string | null;
};

export const AccordionCardSummary = (props: Props) => {
  const { colors, text } = useTheme();
  return (
    <AccordionSummary expandIcon={<ExpandMore />}>
      <Flex width={1} justifyContent="space-between" alignItems="center">
        <Box css={text.subtitle2}>{props.title}</Box>
        <div>
          <Flex width={1} justifyContent="space-between" alignItems="center">
            {!props.expanded && props.avatarUrl != null && (
              <Box mr={2} my={-2}>
                <Image
                  src={props.avatarUrl}
                  loading="lazy"
                  css={{ width: 34, height: 34, borderRadius: '50%' }}
                />
              </Box>
            )}
            {!props.expanded && (
              <Box
                css={[
                  text.caption,
                  text.truncate(1),
                  {
                    lineHeight: 1,
                    color: colors.mediumText,
                    borderColor: colors.grey300,
                    //maxWidth: 'calc(10vw - 75px)',
                  },
                ]}
              >
                {props.subTitle}
              </Box>
            )}
          </Flex>
        </div>
      </Flex>
    </AccordionSummary>
  );
};
