import { Box, Typography } from '@mui/material';
import { amber, blue, green, grey, red } from '@mui/material/colors';

interface TagProps {
  status:
    | 'neutral'
    | 'error'
    | 'warning'
    | 'info'
    | 'success'
    | 'dark'
    | 'dark_success'
    | 'dark_error';
  noBorder?: boolean;
  children: React.ReactNode;
  textWrap?: 'noWrap' | 'wrap';
}

export const Tag = ({
  status = 'neutral',
  noBorder = false,
  textWrap = 'noWrap',
  children,
}: TagProps) => {
  const statusColors = {
    error: { bg: red[50], color: red[900], border: red[500] },
    warning: { bg: amber[50], color: amber[900], border: amber[500] },
    info: { bg: blue[50], color: blue[900], border: blue[500] },
    success: { bg: green[50], color: green[900], border: green[500] },
    neutral: { bg: grey[100], color: grey[800], border: grey[400] },
    dark: { bg: grey[800], color: grey[50], border: grey[900] },
    dark_success: { bg: green[800], color: green[50], border: green[900] },
    dark_error: { bg: red[800], color: red[50], border: red[900] },
  };

  const { bg, color, border } = statusColors[status] || {};

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 0.5,
        padding: '2px 8px',
        borderRadius: '4px',
        backgroundColor: bg,
        color,
        border: noBorder ? 'non' : '1px solid',
        borderColor: border,
      }}
    >
      <Typography
        variant="caption"
        sx={{
          lineHeight: '16px',
          textWrap,
        }}
      >
        {children}
      </Typography>
    </Box>
  );
};
