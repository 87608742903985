/**
 * @generated SignedSource<<d00db7fec4dcdf3675aec8d9825d7c2f>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
type leadQualificationFunnel_lead$fragmentType = any;
type leadUpdatedTimeAgo_lead$fragmentType = any;
export type LeadRelationship = "agent" | "buyer" | "heir" | "not_set" | "other" | "owner" | "tenant";
export type PipelineLeadType = "buyer" | "financing" | "sales";
import type { FragmentType } from "relay-runtime";
declare export opaque type leadSnippet_lead$fragmentType: FragmentType;
export type leadSnippet_lead$data = {|
  +appraisalPerceivedValue: ?number,
  +appraisalPerception: ?string,
  +appraisalPropertyFormattedAddress: ?string,
  +appraisalPropertyOccupied: ?string,
  +appraisalPropertyUsage: ?string,
  +completed: boolean,
  +contact: ?{|
    +firstName: ?string,
    +lastName: ?string,
    +referrer: ?string,
  |},
  +id: string,
  +landingPageUrl: ?string,
  +mortgageAnalysis: ?{|
    +maxPropertyValue: ?number,
  |},
  +mortgageGoogleAddress: ?{|
    +formattedAddress: ?string,
  |},
  +mortgageIsConstruction: ?boolean,
  +mortgageMortgageType: ?string,
  +mortgagePropertyUsage: ?string,
  +mortgagePropertyValue: ?number,
  +mortgageReportType: ?string,
  +property: ?{|
    +builtSurface: ?number,
    +commercialSurface: ?number,
    +commercialYearlyRentalIncome: ?number,
    +countryCode: ?string,
    +formattedAddress: ?string,
    +formattedLocality: ?string,
    +incomeIncludesParking: ?boolean,
    +landSurface: ?number,
    +lat: ?number,
    +latestAppraisal: ?{|
      +realadvisor: ?{|
        +max: ?number,
        +min: ?number,
      |},
    |},
    +livingSurface: ?number,
    +lng: ?number,
    +numberOfBedrooms: ?number,
    +numberOfCommercialUnits: ?number,
    +numberOfResidentialUnits: ?number,
    +numberOfRooms: ?number,
    +parkingYearlyRentalIncome: ?number,
    +plotNumber: ?string,
    +propertyType: ?{|
      +label: ?string,
      +name: ?string,
    |},
    +residentialSurface: ?number,
    +residentialYearlyRentalIncome: ?number,
  |},
  +referrer: ?string,
  +referringDomain: ?string,
  +relationship: LeadRelationship,
  +stage: ?{|
    +pipeline: ?{|
      +leadType: PipelineLeadType,
    |},
  |},
  +userCanViewLeadDetails: boolean,
  +$fragmentSpreads: leadQualificationFunnel_lead$fragmentType & leadUpdatedTimeAgo_lead$fragmentType,
  +$fragmentType: leadSnippet_lead$fragmentType,
|};
export type leadSnippet_lead$key = {
  +$data?: leadSnippet_lead$data,
  +$fragmentSpreads: leadSnippet_lead$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "referrer",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "formattedAddress",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "leadSnippet_lead",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "leadQualificationFunnel_lead"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "leadUpdatedTimeAgo_lead"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LeadStage",
      "kind": "LinkedField",
      "name": "stage",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Pipeline",
          "kind": "LinkedField",
          "name": "pipeline",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "leadType",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "referringDomain",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "landingPageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userCanViewLeadDetails",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalPerceivedValue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalPerception",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalPropertyUsage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "relationship",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalPropertyOccupied",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mortgagePropertyValue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mortgagePropertyUsage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mortgageIsConstruction",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mortgageMortgageType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mortgageReportType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MortgageAnalysis",
      "kind": "LinkedField",
      "name": "mortgageAnalysis",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maxPropertyValue",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GoogleAddress",
      "kind": "LinkedField",
      "name": "mortgageGoogleAddress",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appraisalPropertyFormattedAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Property",
      "kind": "LinkedField",
      "name": "property",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "countryCode",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "formattedLocality",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lat",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lng",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "livingSurface",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "builtSurface",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "landSurface",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numberOfRooms",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numberOfBedrooms",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numberOfResidentialUnits",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "residentialSurface",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "residentialYearlyRentalIncome",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "parkingYearlyRentalIncome",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numberOfCommercialUnits",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "commercialSurface",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "commercialYearlyRentalIncome",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "incomeIncludesParking",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "plotNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PropertyType",
          "kind": "LinkedField",
          "name": "propertyType",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "label",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Appraisal",
          "kind": "LinkedField",
          "name": "latestAppraisal",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RealadvisorAppraisal",
              "kind": "LinkedField",
              "name": "realadvisor",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "min",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "max",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "contact",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Lead",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "0684436622a5a1f4bc08ef64fde03f05";

export default node;
