/**
 * @generated SignedSource<<391a9510592274b79943e53ca02a1583>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LeadStatus = "active" | "lost" | "won";
export type activityWorkflowDialogUpdateLeadMutation$variables = {
  financingLeadLostId?: string | null;
  id: string;
  status?: LeadStatus | null;
};
export type activityWorkflowDialogUpdateLeadMutation$data = {
  readonly upsertLead: {
    readonly lead: {
      readonly financingLeadLost: {
        readonly id: string;
        readonly label: string;
      } | null;
      readonly id: string;
      readonly status: LeadStatus;
    } | null;
  } | null;
};
export type activityWorkflowDialogUpdateLeadMutation = {
  response: activityWorkflowDialogUpdateLeadMutation$data;
  variables: activityWorkflowDialogUpdateLeadMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "financingLeadLostId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "financingLeadLostId",
                "variableName": "financingLeadLostId"
              },
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "id"
              },
              {
                "kind": "Variable",
                "name": "status",
                "variableName": "status"
              }
            ],
            "kind": "ObjectValue",
            "name": "lead"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "UpsertLeadPayload",
    "kind": "LinkedField",
    "name": "upsertLead",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lead",
        "kind": "LinkedField",
        "name": "lead",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Dictionary",
            "kind": "LinkedField",
            "name": "financingLeadLost",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "label",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "activityWorkflowDialogUpdateLeadMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "activityWorkflowDialogUpdateLeadMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "d23a2ca1e4de643ce1793051b2ef7a7c",
    "id": null,
    "metadata": {},
    "name": "activityWorkflowDialogUpdateLeadMutation",
    "operationKind": "mutation",
    "text": "mutation activityWorkflowDialogUpdateLeadMutation(\n  $id: ID!\n  $status: LeadStatus\n  $financingLeadLostId: ID\n) {\n  upsertLead(input: {lead: {id: $id, status: $status, financingLeadLostId: $financingLeadLostId}}) {\n    lead {\n      id\n      status\n      financingLeadLost {\n        id\n        label\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2c4ea666e7e6ff7df6ac41a16e67c391";

export default node;
