import { useEffect, useState } from 'react';

import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Image } from '@realadvisor/image';
import { type To } from 'react-router-dom';

import { useLocale } from '../../src/hooks/locale';
import { gql } from '../__generated__';
import { type ListingCardSnippetFragment } from '../__generated__/graphql';

import { ConditionalLinkWrapper } from './ConditionalLinkWrapper';
import { LotCardInfo } from './LotCardInfo';
import { UserChip } from './UserChip';

type ListingCardSnippetProps = {
  listing: ListingCardSnippetFragment;
  link?: To;
  orientation?: 'horizontal' | 'vertical';
};

export const LISTING_CARD_SNIPPET_FRAGMENT = gql(`
  fragment ListingCardSnippet on lots {
    ...LotCardInfo_lot
    property {
      property_images(order_by: { order_nr: asc }, limit: 1) {
        image {
          id
          url
        }
      }
    }
    broker {
      ...UserChip
    }
  }
`);

export const ListingCardSnippetSkeletton: React.FC<{
  orientation?: 'horizontal' | 'vertical';
}> = ({ orientation: initialOrientation = 'horizontal' }) => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const [orientation, setOrientation] = useState<'horizontal' | 'vertical'>(
    initialOrientation,
  );

  useEffect(() => {
    if (isMobile) {
      setOrientation('vertical');
    } else {
      setOrientation('horizontal');
    }
  }, [isMobile]);

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'row',
        minHeight: 140,
        alignItems: 'stretch',
      }}
    >
      <CardMedia>
        <Skeleton variant="rectangular" width={150} height={140} />
      </CardMedia>

      <CardContent
        sx={{
          flexGrow: 1,
          '&.MuiCardContent-root:last-child': { pb: 2 },
          minWidth: 0,
        }}
      >
        <Stack
          height="100%"
          justifyContent="center"
          alignItems={orientation === 'horizontal' ? 'flex-start' : 'stretch'}
          direction={orientation === 'horizontal' ? 'row' : 'column'}
          spacing={orientation === 'horizontal' ? 2 : 0}
        >
          <Box flexGrow={1} sx={{ minWidth: 0 }}>
            <Stack>
              <Typography variant="caption">
                <Skeleton width={270} />
              </Typography>

              <Typography variant="body1">
                <Skeleton width={300} />
              </Typography>
              <Typography variant="h6">
                <Skeleton width={150} />
              </Typography>
              <Typography mb={1} variant="body2">
                <Skeleton width={100} />
              </Typography>
            </Stack>
          </Box>

          <Divider
            sx={{
              opacity: 0.5,
              ...(orientation === 'vertical' ? { mb: 1 } : {}),
            }}
            orientation={
              orientation === 'horizontal' ? 'vertical' : 'horizontal'
            }
          />

          <Typography variant="caption">
            <Skeleton width={150} />
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

export const ListingCardSnippet: React.FC<ListingCardSnippetProps> = ({
  listing,
  link,
  orientation: initialOrientation = 'horizontal',
}) => {
  const { t } = useLocale();
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const [orientation, setOrientation] = useState<'horizontal' | 'vertical'>(
    initialOrientation,
  );

  useEffect(() => {
    if (isMobile) {
      setOrientation('vertical');
    } else {
      setOrientation('horizontal');
    }
  }, [isMobile]);

  return (
    <ConditionalLinkWrapper link={link != null ? { to: link } : undefined}>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'row',
          minHeight: 140,
          alignItems: 'stretch',
        }}
      >
        <CardMedia sx={{ width: 150, minWidth: 120 }}>
          {listing?.property?.property_images &&
          listing?.property?.property_images.length > 0 ? (
            <Image
              src={listing?.property?.property_images[0]?.image.url ?? ''}
              options={{ w: 150, h: 140, f: 'jpg', c: 'fill' }}
            />
          ) : (
            <HomeWorkOutlinedIcon
              sx={theme => ({
                fontSize: 32,
                color: theme.palette.grey[400],
                margin: 8,
              })}
            />
          )}
        </CardMedia>

        <CardContent
          sx={{
            flexGrow: 1,
            '&.MuiCardContent-root:last-child': { pb: 2 },
            minWidth: 0,
          }}
        >
          <Stack
            height="100%"
            justifyContent="center"
            alignItems={orientation === 'horizontal' ? 'flex-start' : 'stretch'}
            direction={orientation === 'horizontal' ? 'row' : 'column'}
            spacing={orientation === 'horizontal' ? 2 : 0}
          >
            <Box flexGrow={1} sx={{ minWidth: 0 }}>
              <LotCardInfo lot={listing} showTitle />
            </Box>

            <Divider
              sx={{
                opacity: 0.5,
                ...(orientation === 'vertical' ? { mb: 1 } : {}),
              }}
              orientation={
                orientation === 'horizontal' ? 'vertical' : 'horizontal'
              }
            />

            {listing.broker != null && (
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="caption">{t('agent')}</Typography>
                <UserChip user={listing.broker} />
              </Stack>
            )}
          </Stack>
        </CardContent>
      </Card>
    </ConditionalLinkWrapper>
  );
};
