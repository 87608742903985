// Generated from src/svgs/nav-dashboard-active.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const NavDashboardActive = ({ size, fill, className }: Props) => {
  return (
    <svg viewBox="0 0 25 25" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><path d="M8.945 10.146c.17-.578.621-1.03 1.2-1.2l8.371-2.462-2.462 8.37a1.769 1.769 0 0 1-1.2 1.2l-8.37 2.462 2.46-8.37ZM0 12.5C0 19.404 5.596 25 12.5 25S25 19.404 25 12.5c0-3.314-1.318-6.495-3.66-8.84A12.509 12.509 0 0 0 12.5 0C5.596 0 0 5.596 0 12.5Z" fill="#fff" /></svg>
  );
}
