import React, { useCallback, useRef } from 'react';

import {
  Alert,
  Box,
  CircularProgress,
  Divider,
  Paper,
  Typography,
} from '@mui/material';
import { useResizeRect } from '@realadvisor/observe';
import {
  type ScaleLinear,
  type ScalePoint,
  scaleLinear,
  scalePoint,
} from 'd3-scale';
import { Flex } from 'react-system';

import { ProgressButton } from '../../../src/controls/progress-button';
import { useLocale } from '../../../src/hooks/locale';
import { useTheme } from '../../../src/hooks/theme';
import type { AppraisalGraphQuery } from '../../__generated__/graphql';
import { usePropertyAppraisal } from '../../hooks/useAppraiseProperty';

type AppraisalData = {
  name: string;
  min?: number | null;
  value?: number | null;
  max?: number | null;
};

type LatestAppraisal = Omit<AppraisalData, 'name'>;

type AppraisalDisplay = {
  x: ScalePoint<string>;
  y: ScaleLinear<number, number>;
  item: AppraisalData;
};

const formatValue = (value: number, locale: string) => {
  if (value < 1_000) {
    return value.toLocaleString(locale, {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    });
  }
  if (value < 1_000_000) {
    return (
      (value / 1_000).toLocaleString(locale, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }) + 'K'
    );
  }
  return (
    (value / 1_000_000).toLocaleString(locale, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }) + 'M'
  );
  // TODO: use code below when target Safari version become 14.1+
  // const formatValue = (value, locale) => {
  //   return new Intl.NumberFormat(locale, { notation: 'compact' }).format(value);
  // };
};

const getModelData = (
  appraisal: NonNullable<AppraisalGraphQuery['appraisals_by_pk']>,
): AppraisalData[] => {
  const sortedValuations = appraisal.valuations.sort((a, b) => {
    const aIsRealadvisor = a.model.toLowerCase().startsWith('realadvisor');
    const bIsRealadvisor = b.model.toLowerCase().startsWith('realadvisor');

    if (aIsRealadvisor && !bIsRealadvisor) {
      return -1;
    }
    return a.model.localeCompare(b.model);
  });

  return sortedValuations.reduce<AppraisalData[]>((acc, valuation) => {
    const { min, value, max, valuation_model } = valuation;

    if (
      (min != null || value != null || max != null) &&
      valuation_model.short_name
    ) {
      acc.push({
        name: valuation_model.short_name,
        min,
        value,
        max,
      });
    }

    return acc;
  }, []);
};

const AppraisalMax = ({ x, y, item }: AppraisalDisplay) => {
  const { colors, text } = useTheme();
  const { locale } = useLocale();

  if (item.max != null) {
    const name = item.name;
    const value = item.max;
    return (
      <>
        <circle
          cx={x(name)}
          cy={y(value)}
          r={3}
          fill="#fff"
          stroke={colors.blue400}
          strokeWidth="2"
        />
        <text
          x={x(name)}
          dx={20}
          y={y(value)}
          style={{ fontSize: 10, fontWeight: text.font.regular }}
          textAnchor="middle"
        >
          {formatValue(value, locale)}
        </text>
      </>
    );
  }

  return null;
};

const AppraisalValue = ({ x, y, item }: AppraisalDisplay) => {
  const { colors, text } = useTheme();
  const { locale } = useLocale();

  if (item.value != null) {
    const name = item.name;
    const value = item.value;
    return (
      <>
        <circle cx={x(name)} cy={y(value)} r={5} fill={colors.blue400} />
        <text
          x={x(name)}
          dx={20}
          y={y(value)}
          style={{ fontSize: 12, fontWeight: text.font.medium }}
          textAnchor="middle"
        >
          {formatValue(value, locale)}
        </text>
      </>
    );
  }

  return null;
};

const AppraisalMin = ({ x, y, item }: AppraisalDisplay) => {
  const { colors, text } = useTheme();
  const { locale } = useLocale();

  if (item.min != null) {
    const name = item.name;
    const value = item.min;
    return (
      <>
        <circle
          cx={x(name)}
          cy={y(value)}
          r={3}
          fill="#fff"
          stroke={colors.blue400}
          strokeWidth="2"
        />
        <text
          x={x(name)}
          dx={20}
          y={y(value)}
          style={{ fontSize: 10, fontWeight: text.font.regular }}
          textAnchor="middle"
        >
          {formatValue(value, locale)}
        </text>
      </>
    );
  }

  return null;
};

const getAppraisalDomain = (
  appraisalItems: AppraisalData[],
): [number, number] => {
  const values = appraisalItems
    .flatMap(item => [item.min, item.value, item.max])
    .filter((value): value is number => value != null);

  if (values.length === 0) {
    return [0, 0]; // Default domain if no valid values
  }

  return [Math.min(...values), Math.max(...values)];
};

const getArrayItem = <T extends any>(
  array: ReadonlyArray<T>,
  index: number,
): T => array[index];

const Chart = ({
  width,
  height,
  data,
  latestAppraisal,
}: {
  width: number;
  height: number;
  data: AppraisalData[];
  latestAppraisal: LatestAppraisal;
}) => {
  const { colors, text } = useTheme();
  const { locale } = useLocale();
  const x = scalePoint()
    .domain(data.map(item => item.name))
    .range([0, width])
    .padding(1);
  const y = scaleLinear()
    .domain(getAppraisalDomain(data))
    .range([height - 30, 20])
    .nice(5);

  const ticks = y.ticks(5);
  const xStart = x(getArrayItem(data, 0)?.name);
  const xEnd = x(getArrayItem(data, data.length - 1)?.name);

  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <g transform={`translate(0, ${height - 30})`}>
        {data.map(item => {
          const pos = x(item.name);
          return (
            <React.Fragment key={item.name}>
              <line x1={pos} x2={pos} y1={0} y2={6} stroke="#666" />
              <text
                x={pos}
                y="16"
                dy="0.71em"
                textAnchor="middle"
                style={{ fontSize: 12, fontWeight: text.font.medium }}
              >
                {item.name}
              </text>
            </React.Fragment>
          );
        })}
      </g>

      <g transform={`translate(34, 0)`}>
        {ticks.map(tick => (
          <React.Fragment key={tick}>
            <line
              x1={0}
              x2={width}
              y1={y(tick)}
              y2={y(tick)}
              stroke="rgba(0, 0, 0, .12)"
              strokeWidth={2}
              strokeDasharray="3 6"
            />
            <text
              x={0}
              y={y(tick)}
              textAnchor="end"
              style={{ fontSize: 10, fontWeight: text.font.medium }}
            >
              {formatValue(tick, locale)}
            </text>
          </React.Fragment>
        ))}
      </g>

      <line
        x1={xStart}
        x2={xEnd}
        y1={y(latestAppraisal.max ?? 0)}
        y2={y(latestAppraisal.max ?? 0)}
        stroke={colors.green600}
        strokeWidth="1"
        strokeDasharray="2 2"
      />

      <line
        x1={xStart}
        x2={xEnd}
        y1={y(latestAppraisal.value ?? 0)}
        y2={y(latestAppraisal.value ?? 0)}
        stroke={colors.green600}
        strokeWidth="2"
        strokeDasharray="2 2"
      />

      <line
        x1={xStart}
        x2={xEnd}
        y1={y(latestAppraisal.min ?? 0)}
        y2={y(latestAppraisal.min ?? 0)}
        stroke={colors.green500}
        strokeWidth="1"
        strokeDasharray="2 2"
      />

      {data.map(item => (
        <AppraisalMax key={item.name} x={x} y={y} item={item} />
      ))}

      {data.map(item => (
        <AppraisalValue key={item.name} x={x} y={y} item={item} />
      ))}

      {data.map(item => (
        <AppraisalMin key={item.name} x={x} y={y} item={item} />
      ))}
    </svg>
  );
};

const ResizableChart: React.FC<{
  chartData: AppraisalData[];
  targetRef: React.RefObject<HTMLDivElement>;
  latestAppraisal: LatestAppraisal;
}> = ({ chartData, targetRef, latestAppraisal }) => {
  const targetRect = useResizeRect(targetRef);

  if (!targetRect) {
    return null;
  }

  return (
    <Chart
      width={targetRect.width}
      height={targetRect.height}
      data={chartData}
      latestAppraisal={latestAppraisal}
    />
  );
};

type AppraisalGraphProps = {
  latestAppraisalId?: string;
  propertyId: string;
};

export const AppraisalGraph: React.FC<AppraisalGraphProps> = ({
  latestAppraisalId,
  propertyId,
}) => {
  const { t } = useLocale();
  const targetRef = useRef<HTMLDivElement>(null);

  const {
    appraiseProperty,
    appraisalData,
    queryLoading,
    mutationLoading,
    queryError,
    mutationError,
  } = usePropertyAppraisal(latestAppraisalId);

  const handleClickAppraise = useCallback(
    async (property_id: string) => {
      try {
        await appraiseProperty(property_id);
      } catch (error) {
        console.error('Error in appraiseProperty:', error);
      }
    },
    [appraiseProperty],
  );

  if (queryError || mutationError) {
    return (
      <div>
        <Alert severity="error" sx={{ m: 2 }}>
          <pre>{JSON.stringify(queryError || mutationError, null, 2)}</pre>
        </Alert>
      </div>
    );
  }

  if (queryLoading) {
    return (
      <Flex flexGrow={1} justifyContent="center" alignItems="center" p={3}>
        <CircularProgress disableShrink />
      </Flex>
    );
  }

  const chartData = appraisalData ? getModelData(appraisalData) : [];
  const latestAppraisal = {
    min: appraisalData?.min,
    value: appraisalData?.value,
    max: appraisalData?.max,
  };

  return (
    <Paper variant="outlined">
      <Typography variant="subtitle2" sx={{ mx: 2, my: 1 }}>
        {t('latestAppraisal')}
      </Typography>

      <Divider sx={{ my: 1 }} />

      {!!chartData.length && (
        <Box sx={{ p: 2 }}>
          <div css={{ height: 280, overflow: 'hidden' }} ref={targetRef}>
            <ResizableChart
              chartData={chartData}
              targetRef={targetRef}
              latestAppraisal={latestAppraisal}
            />
          </div>
        </Box>
      )}
      <Flex justifyContent="center" css={{ margin: '8px 0' }}>
        <ProgressButton
          variant="contained"
          size="small"
          loading={mutationLoading}
          onClick={() => handleClickAppraise(propertyId)}
        >
          {t('appraise')}
        </ProgressButton>
      </Flex>
    </Paper>
  );
};

export default AppraisalGraph;
