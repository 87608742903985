import * as React from 'react';
import type { Interpolation } from '@emotion/react';
import { useSystem } from 'react-system';
import { KeyboardArrowLeft } from './keyboard-arrow-left';
import { KeyboardArrowRight } from './keyboard-arrow-right';
import { hasPassiveEventsSupport } from './features-detector';
import { Paginator, getPaginatorWidth } from './paginator-css';
import { smoothScrollTo, scrollTo } from './scroll';

const useLayoutEffect =
  typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect;

type ArrowButtonProps = {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  position: 'left' | 'right';
};

type PositionProps = {
  value: number;
  total: number;
};

type CarouselMedia = {
  itemsOnScreen: number;
  aspectRatio?: number;
  gap: number;
};

type CarouselProps = {
  media: CarouselMedia | ReadonlyArray<CarouselMedia>;
  // renderLeftArrow?: ArrowProps => React.Node,
  // renderRightArrow?: ArrowProps => React.Node,
  // How many images to render before carousel touched (optimisation)
  renderUntilTouched?: number;
  children: React.ReactNode;
  ArrowButton?: React.FunctionComponent<ArrowButtonProps>;
  // Current position control
  Position?: React.FunctionComponent<PositionProps>;
  // Fill carousel with first child
  fillEmpty?: boolean;
  // Default behaviour is show left right arrows on hover
  buttonsVisibility?: 'hover' | 'always';
  // For gallery to focus on show
  autoFocus?: boolean;
  initialOffset?: number;
};

const ARROW_BUTTON_CLASSNAME = 'carousel_arrow_button';

// We need to not update the whole carousel during paginator changes
// So hide buttons into memo
const ArrowButton = React.memo<ArrowButtonProps>(({ onClick, position }) => {
  return (
    <button
      onClick={onClick}
      css={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: position === 'left' ? 0 : 'unset',
        right: position === 'right' ? 0 : 'unset',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',

        WebkitAppearance: 'none',
        background: `linear-gradient(to ${position}, transparent 0%, rgba(0, 0, 0, 0.25) 100%)`,

        padding: '16px',
        border: 'none',
        outline: 'none',
        cursor: 'pointer',

        // Do not show on touch devices
        '@media (hover: none) and (pointer: coarse)': {
          display: 'none',
        },
      }}
      className={ARROW_BUTTON_CLASSNAME}
    >
      {position === 'left' ? (
        <KeyboardArrowLeft size={32} />
      ) : (
        <KeyboardArrowRight size={32} />
      )}
    </button>
  );
});

// We need to not update the whole carousel during paginator changes
// So hide most heavy part into memo
const CarouselInternal = React.memo(
  React.forwardRef<
    HTMLDivElement | null,
    {
      media: CarouselMedia | ReadonlyArray<CarouselMedia>;
      count: number;
      fillEmpty: boolean;
      children: React.ReactNode;
    }
  >((props, ref) => {
    const { media } = useSystem();

    const carouselMedia: ReadonlyArray<CarouselMedia> = [props.media].flat();

    const gridMedia: {
      gridAutoColumns: string[];
      gridGap: number[];
    } = {
      gridAutoColumns: [],
      gridGap: [],
    };
    for (const v of carouselMedia) {
      const d = ((v.itemsOnScreen - 1) * v.gap) / v.itemsOnScreen;
      const p = 100 / v.itemsOnScreen;
      gridMedia.gridAutoColumns.push(`calc(${p}% - ${d}px)`);
      gridMedia.gridGap.push(v.gap);
    }
    const gridMediaStyles = media(gridMedia);

    const hasRatioMedia = carouselMedia.every(v => v.aspectRatio != null);

    let fillEmptyMediaStyles: Interpolation<unknown>;
    if (props.fillEmpty) {
      const fillEmptyMedia: {
        display: string[];
      } = {
        display: [],
      };
      for (const v of carouselMedia) {
        // fillEmptyMedia.paddingTop.push(`${Math.round(v.aspectRatio * 100)}%`);
        fillEmptyMedia.display.push(
          v.itemsOnScreen > props.count ? 'grid' : 'none',
        );
      }
      fillEmptyMediaStyles = media(fillEmptyMedia);
    }

    return (
      <div
        ref={ref}
        css={[
          gridMediaStyles,
          {
            display: 'grid',
            overflowX: 'scroll',
            overflowY: 'hidden',
            gridTemplateRows: '100%',
            gridAutoFlow: 'column',

            // Hide scrollbar
            scrollbarWidth: 'none',
            scrollbarHeight: 'none',

            '::-webkit-scrollbar': {
              display: 'none',
            },

            // Enable scroll-snapping
            scrollSnapType: 'x mandatory',
            '>*': {
              scrollSnapAlign: 'start',
            },
            // Prev ios versions support
            WebkitOverflowScrolling: 'touch',
          },
          hasRatioMedia
            ? { position: 'absolute', left: 0, right: 0, top: 0, bottom: 0 }
            : null,
          props.fillEmpty
            ? {
                overflowX: 'hidden',
                filter: 'brightness(0.5)',
                position: 'absolute',

                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
              }
            : null,
          fillEmptyMediaStyles,
        ]}
      >
        {props.children}
      </div>
    );
  }),
);

const VISIBLE_PAGINATOR_COUNT = 7;

type CarouselState = { dx: number; touched: boolean };

const carouselReducer = (
  state: CarouselState,
  action: Partial<CarouselState>,
) => ({
  ...state,
  ...action,
});

export const Carousel = (props: CarouselProps) => {
  const { media } = useSystem();
  const { buttonsVisibility = 'hover' } = props;

  const scrollRef = React.useRef<HTMLDivElement | null>(null);
  const mainRef = React.useRef<HTMLDivElement | null>(null);
  const [buttonsVisible, setButtonsVisible] = React.useState(false);

  const count = React.Children.count(props.children);

  const [state, dispatch] = React.useReducer(carouselReducer, {
    dx: (props.initialOffset ?? 0) / (count > 1 ? count - 1 : count),
    touched: false,
  });

  const ArrowButtonComponent = props.ArrowButton ?? ArrowButton;

  if (process.env.NODE_ENV !== 'production') {
    // This is because it breaks initial scroll animation if both are set, (can be fixed but too many code)
    if (props.autoFocus != null && props.renderUntilTouched != null) {
      throw new Error(
        `Carousel-css autoFocus and renderUntilTouched can't be set simulateneously`,
      );
    }

    if (props.initialOffset != null && props.renderUntilTouched != null) {
      throw new Error(
        `Carousel-css initialOffset and renderUntilTouched can't be set simulateneously`,
      );
    }
  }

  React.useEffect(() => {
    if (mainRef.current && props.autoFocus === true) {
      mainRef.current.focus();
    }
  }, [props.autoFocus]);

  const optimizedChildren = React.useMemo(() => {
    const ch = React.Children.toArray(props.children);
    if (props.renderUntilTouched == null || state.touched) {
      return ch;
    }

    return ch.slice(0, props.renderUntilTouched);
  }, [props.children, state.touched, props.renderUntilTouched]);

  const scrollOperationRef = React.useRef({
    destination: -1,
    time: Date.now(),
  });

  const scrollToOffset = React.useCallback(
    (offset: number, immediate: boolean) => {
      const elt = scrollRef.current;
      if (elt != null) {
        const elementsCount =
          props.renderUntilTouched == null || state.touched
            ? count
            : props.renderUntilTouched;

        const gap = Number.parseFloat(
          getComputedStyle(elt).getPropertyValue('column-gap'),
        );
        const width =
          (elt.scrollWidth - gap * (elementsCount - 1)) / elementsCount;
        const current = Math.round(elt.scrollLeft / (width + gap));

        const destination = (current + offset) * (width + gap);

        const currTime = Date.now();

        const SCROLL_TIMEOUT = 300;
        if (
          destination !== scrollOperationRef.current.destination ||
          currTime - scrollOperationRef.current.time > SCROLL_TIMEOUT
        ) {
          if (immediate) {
            scrollTo(elt, destination);
          } else {
            smoothScrollTo(elt, destination);
          }
          scrollOperationRef.current.time = currTime;
          scrollOperationRef.current.destination = destination;
        }
      }
    },
    [count, props.renderUntilTouched, state.touched],
  );

  const initialOffsetExecuted = React.useRef(false);
  useLayoutEffect(() => {
    if (
      initialOffsetExecuted.current === false &&
      props.initialOffset != null &&
      props.initialOffset > 0
    ) {
      scrollToOffset(props.initialOffset, true);
    }
    initialOffsetExecuted.current = true;
  }, [props.initialOffset, scrollToOffset]);

  const handleLeftClick = React.useCallback(
    (e: React.MouseEvent | React.KeyboardEvent) => {
      scrollToOffset(-1, false);
      // Prevent and stop to allow wrap the whole carousel inside A tag
      // This seriously reduces needed tags for images
      e.stopPropagation();
      e.preventDefault();
    },
    [scrollToOffset],
  );

  const handleRightClick = React.useCallback(
    (e: React.MouseEvent | React.KeyboardEvent) => {
      scrollToOffset(1, false);
      // Prevent and stop to allow wrap the whole carousel inside A tag
      // This seriously reduces needed tags for images
      e.stopPropagation();
      e.preventDefault();
    },
    [scrollToOffset],
  );

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent) => {
      switch (event.key) {
        case 'ArrowLeft':
          handleLeftClick(event);
          break;
        case 'ArrowRight':
          handleRightClick(event);
          break;
        default:
      }
    },
    [handleLeftClick, handleRightClick],
  );

  const handleMouseEnter = React.useCallback(() => {
    const elt = scrollRef.current;
    if (elt != null) {
      if (elt.scrollWidth > elt.clientWidth) {
        dispatch({ touched: true });
        setButtonsVisible(true);
      }
    }
  }, []);

  const handleMouseLeave = React.useCallback(() => {
    setButtonsVisible(false);
  }, []);

  React.useEffect(() => {
    const elt = scrollRef.current;
    if (elt != null) {
      const scrollOptions: boolean | AddEventListenerOptions =
        hasPassiveEventsSupport() ? { passive: false } : false;

      const handleScroll = () => {
        const dx =
          elt.scrollWidth > elt.clientWidth
            ? elt.scrollLeft / (elt.scrollWidth - elt.clientWidth)
            : 0;
        dispatch({ dx, touched: true });
      };

      elt.addEventListener('scroll', handleScroll, scrollOptions);
      return () => {
        elt.removeEventListener('scroll', handleScroll, scrollOptions);
      };
    }
  }, []);

  const paginatorDisplayMedia = React.useMemo(() => {
    const carouselMedia: ReadonlyArray<CarouselMedia> = [props.media].flat();
    const displayMedia: { display: string[] } = { display: [] };
    for (const v of carouselMedia) {
      displayMedia.display.push(v.itemsOnScreen === 1 ? 'block' : 'none');
    }
    const displayMediaStyles = media(displayMedia);
    return displayMediaStyles;
  }, [props.media, media]);

  const paginatorWidth = getPaginatorWidth({
    count,
    visibleCount: VISIBLE_PAGINATOR_COUNT,
  });

  const firstChildArray: ReadonlyArray<React.ReactNode> = React.useMemo(() => {
    if (props.fillEmpty !== true) {
      return [];
    }

    const firstChild = React.Children.toArray(props.children)[0];

    const carouselMedia: ReadonlyArray<CarouselMedia> = [props.media].flat();

    const maxChildren = Math.ceil(
      Math.max(...carouselMedia.map(v => v.itemsOnScreen)),
    );

    return Array.from(Array(maxChildren), (_, key) =>
      // @ts-ignore TODO have not idea how to solve
      React.cloneElement(firstChild, { key }),
    );
  }, [props.children, props.media, props.fillEmpty]);

  const ratioMedia = React.useMemo(() => {
    const carouselMedia: ReadonlyArray<CarouselMedia> = [props.media].flat();

    const ratioMedia: {
      width: string[];
      '&:before': {
        paddingTop: string[];
      };
    } = {
      width: [],
      '&:before': {
        paddingTop: [],
      },
    };
    for (const v of carouselMedia) {
      if (v.aspectRatio == null) {
        return null;
      }
      ratioMedia['&:before'].paddingTop.push(
        `${Math.round(v.aspectRatio * 100)}%`,
      );
      const d = ((v.itemsOnScreen - 1) * v.gap) / v.itemsOnScreen;
      const p = 100 / v.itemsOnScreen;
      ratioMedia.width.push(`calc(${p}% - ${d}px)`);
    }

    const ratioMediaStyles = media(ratioMedia);
    return ratioMediaStyles;
  }, [props.media, media]);

  return (
    <div
      ref={mainRef}
      css={{ position: 'relative', outline: 'none' }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      tabIndex={0}
      onKeyDown={handleKeyDown}
    >
      {ratioMedia != null && (
        <div
          css={[
            ratioMedia,
            {
              '&:before': {
                display: 'block',
                content: '""',
              },
            },
          ]}
        />
      )}

      {props.fillEmpty === true && (
        <CarouselInternal
          ref={null}
          media={props.media}
          count={count}
          fillEmpty={true}
        >
          {firstChildArray}
        </CarouselInternal>
      )}

      <CarouselInternal
        ref={scrollRef}
        media={props.media}
        count={count}
        fillEmpty={false}
      >
        {optimizedChildren}
      </CarouselInternal>
      {/*
      we use non css visibility detection to avoid 2 buttons + 2 svg render
      (here not infinite carousel so button visibility depends on dx value)
      */}
      {(buttonsVisible || buttonsVisibility === 'always') &&
        state.dx > 1 / (2 * count) && (
          <ArrowButtonComponent position="left" onClick={handleLeftClick} />
        )}
      {(buttonsVisible || buttonsVisibility === 'always') &&
        state.dx < 1 - 1 / (2 * count) && (
          <ArrowButtonComponent position="right" onClick={handleRightClick} />
        )}
      {props.Position != null && (
        <props.Position
          value={Math.round(1 + state.dx * (optimizedChildren.length - 1))}
          total={count}
        />
      )}
      {/* We use this position method instead of flex as it allows to reduce 1 div */}
      <Paginator
        css={[
          paginatorDisplayMedia,
          {
            position: 'absolute',
            left: `calc(50% - ${paginatorWidth / 2}px)`,
            bottom: 8,
          },
        ]}
        count={count}
        visibleCount={VISIBLE_PAGINATOR_COUNT}
        dx={state.dx}
      />
    </div>
  );
};
