import { type MouseEventHandler } from 'react';

import {
  Box,
  Button,
  Divider,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { Link, type To } from 'react-router-dom';

interface CardAction {
  label: string;
  link?: { to: string; target?: '_blank' | '_self'; search?: string };
  icon?: React.ReactNode;
  onClick?: () => void;
}

interface CountActionCardProps {
  countElements: { value?: number | null; label: string }[];
  actions?: CardAction[];
  isLoading?: boolean;
}

const getActionBtnProps = (
  action: CardAction,
): {
  component?: React.ElementType;
  to?: To;
  target?: string;
  onClick?: MouseEventHandler;
} => {
  if (action.link) {
    return {
      component: Link,
      to:
        action.link.search != null
          ? { pathname: action.link.to, search: action.link.search }
          : action.link.to,
      target: action.link.target ?? '_self',
    };
  }

  return { onClick: action.onClick };
};

export const CountActionCardSkeleton: React.FC<{
  countLabels: string[];
  actions: { label: string; icon?: React.ReactNode }[];
}> = ({ countLabels, actions }) => (
  <>
    <Box
      sx={{
        display: 'grid',
        gridAutoColumns: '1fr',
        gridAutoFlow: 'column',
        columnGap: 4,
      }}
    >
      {countLabels.map((label, index) => (
        <Stack key={index}>
          <Typography variant="h6" sx={{ lineHeight: 1.2 }}>
            <Skeleton width={20} sx={{ py: 1 }} />
          </Typography>
          <Typography variant="body2" sx={{ color: 'neutral.main' }}>
            {label}
          </Typography>
        </Stack>
      ))}
    </Box>
    {actions && (
      <>
        <Divider sx={{ my: 2 }} />
        <Stack direction="row" justifyContent="space-between" flexWrap="wrap">
          {actions.map((action, index) => {
            return (
              <Button
                key={`action-${index}`}
                startIcon={action.icon}
                size="small"
              >
                {action.label}
              </Button>
            );
          })}
        </Stack>
      </>
    )}
  </>
);

export const CountActionCard: React.FC<CountActionCardProps> = ({
  countElements,
  actions,
  isLoading = false,
}) => (
  <>
    <Box
      sx={{
        display: 'grid',
        gridAutoColumns: '1fr',
        gridAutoFlow: 'column',
        columnGap: 4,
      }}
    >
      {countElements.map(({ value, label }, index) => (
        <Stack key={index}>
          <Typography variant="h6" sx={{ lineHeight: 1.2 }}>
            {isLoading ? <Skeleton width={20} sx={{ py: 1 }} /> : value ?? 0}
          </Typography>
          <Typography variant="body2" sx={{ color: 'neutral.main' }}>
            {label}
          </Typography>
        </Stack>
      ))}
    </Box>
    {actions && (
      <>
        <Divider sx={{ my: 2 }} />
        <Stack direction="row" justifyContent="space-between" flexWrap="wrap">
          {actions.map((action, index) => {
            const btnProps = isLoading ? {} : getActionBtnProps(action);
            return (
              <Button
                key={`action-${index}`}
                {...btnProps}
                startIcon={action.icon}
                size="small"
              >
                {action.label}
              </Button>
            );
          })}
        </Stack>
      </>
    )}
  </>
);
