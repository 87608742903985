/**
 * @generated SignedSource<<562759f24e7e8934b6209c164e03139d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserSearchFilters = {
  hasLeadsModule?: boolean | null;
  id_in?: ReadonlyArray<string | null> | null;
  id_nin?: ReadonlyArray<string | null> | null;
  id_sort_first_in?: ReadonlyArray<string | null> | null;
  isAdmin?: boolean | null;
  isBroker?: boolean | null;
  isFinancing?: boolean | null;
  only_in_current_teams?: boolean | null;
};
export type baseUserFilterQuery$variables = {
  count: number;
  filters?: UserSearchFilters | null;
};
export type baseUserFilterQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"baseUserFilter_root">;
};
export type baseUserFilterQuery = {
  response: baseUserFilterQuery$data;
  variables: baseUserFilterQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  }
],
v1 = {
  "kind": "Variable",
  "name": "filters",
  "variableName": "filters"
},
v2 = [
  (v1/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "baseUserFilterQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          (v1/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "baseUserFilter_root"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "baseUserFilterQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "UserSearchConnection",
        "kind": "LinkedField",
        "name": "usersSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserSearchEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Email",
                    "kind": "LinkedField",
                    "name": "primaryEmail",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Image",
                    "kind": "LinkedField",
                    "name": "primaryImage",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "filters": [
          "search",
          "filters"
        ],
        "handle": "connection",
        "key": "baseUserFilterConnection_usersSearch",
        "kind": "LinkedHandle",
        "name": "usersSearch"
      }
    ]
  },
  "params": {
    "cacheID": "20145c3158e2ed5d8dd5bb10cbdda403",
    "id": null,
    "metadata": {},
    "name": "baseUserFilterQuery",
    "operationKind": "query",
    "text": "query baseUserFilterQuery(\n  $count: Int!\n  $filters: UserSearchFilters\n) {\n  ...baseUserFilter_root_2IZfLc\n}\n\nfragment baseUserFilter_root_2IZfLc on Query {\n  usersSearch(first: $count, filters: $filters) {\n    edges {\n      node {\n        id\n        firstName\n        lastName\n        primaryEmail {\n          email\n          id\n        }\n        primaryImage {\n          url\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "80cf9812acf00f4293e7b4f31be2cde1";

export default node;
