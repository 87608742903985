import { useState } from 'react';

import { Box, useTheme } from '@mui/material';
import { Slider } from '@realadvisor/slider';

import { Filter } from '../../../../../src/controls/Filters/Filter';
import { useLocale } from '../../../../../src/hooks/locale';
import type { Merged_Listings_Compare_Args } from '../../../../__generated__/graphql';

const RoomsFilter = ({
  filters,
  setFilters,
}: {
  filters: Partial<Merged_Listings_Compare_Args>;
  setFilters: (filters: Partial<Merged_Listings_Compare_Args>) => void;
}) => {
  const { t, countryCode } = useLocale();
  const { palette, text } = useTheme();
  const step = countryCode === 'CH' ? 0.5 : 1;

  const _gte = filters.number_of_rooms_gte;
  const _lte = filters.number_of_rooms_lte;

  const min = 0;
  const max = 50;
  const [range, setRange] = useState({
    from: _gte ?? min,
    to: _lte ?? max,
  });

  const getRoomsLabel = ({
    _lte,
    _gte,
  }: {
    _lte: number | null | undefined;
    _gte: number | null | undefined;
  }) => {
    if (_lte != null && _gte != null) {
      return t('roomsBetweenLabel', {
        under: _lte,
        over: _gte,
      });
    }

    if (_gte == null && _lte != null) {
      return t('roomsUnderLabel', {
        under: _lte,
      });
    }

    if (_lte == null && _gte != null) {
      return t('roomsOverLabel', {
        over: _gte,
      });
    }

    return t('rooms');
  };

  return (
    <Filter
      dialogTitle={t('rooms')}
      label={getRoomsLabel({ _gte, _lte })}
      empty={_lte == null && _gte == null}
      onReset={() => {
        setRange({
          from: min,
          to: max,
        });
        setFilters({
          number_of_rooms_gte: null,
          number_of_rooms_lte: null,
        });
      }}
    >
      <Box width="280px">
        <Box
          display="flex"
          px={1}
          pt={2}
          justifyContent="space-between"
          sx={{
            color: palette.primary.main,
            fontWeight: text.font.medium,
          }}
        >
          <Box px={1}>{range.from !== 0 ? range.from : t('noMinimum')}</Box>
          <Box px={1}>{range.to !== 100 ? range.to : t('noMaximum')}</Box>
        </Box>

        <Box px={1} pb={2}>
          <Slider
            bg={palette.primary.main}
            valueConstraints={{ min, max, step }}
            value={range}
            onChange={setRange}
            onChanged={({ from, to }) => {
              setFilters({
                number_of_rooms_gte: from === min ? null : from,
                number_of_rooms_lte: to === max ? null : to,
              });
            }}
          />
        </Box>
      </Box>
    </Filter>
  );
};

export default RoomsFilter;
