/**
 * @generated SignedSource<<7d9af54dd1cd5a28a2b5ea912823070e>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type PropertyImageBackground = "black" | "white";
export type PropertyImageCrop = "fill" | "fit";
export type PropertyImageSize = "full_page" | "half_page" | "quarter_page";
export type UpdatePropertyImageInput = {|
  backgroundColor?: ?PropertyImageBackground,
  clientMutationId?: ?string,
  crop?: ?PropertyImageCrop,
  description?: ?string,
  destinationOrderNr?: ?number,
  displayOnBrochure?: ?boolean,
  displayOnListing?: ?boolean,
  id: string,
  isFloorPlan?: ?boolean,
  landscapeBackgroundColor?: ?PropertyImageBackground,
  landscapeCrop?: ?PropertyImageCrop,
  landscapeSize?: ?PropertyImageSize,
  propertyId?: ?string,
  size?: ?PropertyImageSize,
  title?: ?string,
  url?: ?string,
|};
export type propertyPhotosOrderMutation$variables = {|
  input: UpdatePropertyImageInput,
|};
export type propertyPhotosOrderMutation$data = {|
  +updatePropertyImage: ?{|
    +property: ?{|
      +id: string,
      +propertyImages: ?$ReadOnlyArray<{|
        +id: string,
        +orderNr: number,
      |}>,
    |},
  |},
|};
export type propertyPhotosOrderMutation = {|
  response: propertyPhotosOrderMutation$data,
  variables: propertyPhotosOrderMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdatePropertyImagePayload",
    "kind": "LinkedField",
    "name": "updatePropertyImage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Property",
        "kind": "LinkedField",
        "name": "property",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PropertyImage",
            "kind": "LinkedField",
            "name": "propertyImages",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "orderNr",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "propertyPhotosOrderMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "propertyPhotosOrderMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "43f6453bad9456034c2d7c433b191e22",
    "id": null,
    "metadata": {},
    "name": "propertyPhotosOrderMutation",
    "operationKind": "mutation",
    "text": "mutation propertyPhotosOrderMutation(\n  $input: UpdatePropertyImageInput!\n) {\n  updatePropertyImage(input: $input) {\n    property {\n      id\n      propertyImages {\n        id\n        orderNr\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "12bb43f1a21a1cefa0d08af78b383456";

export default node;
