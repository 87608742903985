import { useReducer } from 'react';

import Timeline from '@mui/lab/Timeline';
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import {
  Alert,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';

import { useLocale } from '../../../src/hooks/locale';
import type { ActivityFieldsFragment } from '../../__generated__/graphql';

type FilterState = {
  status: 'all' | 'done' | 'pending';
  type: ActivityFieldsFragment['activity_type'] | 'all';
  success: boolean | 'all';
  parent_id: string | 'all';
};

type FilterAction =
  | { type: 'SET_STATUS_FILTER'; payload: FilterState['status'] }
  | { type: 'SET_TYPE_FILTER'; payload: FilterState['type'] }
  | { type: 'SET_SUCCESS_FILTER'; payload: FilterState['success'] }
  | { type: 'SET_PARENT_FILTER'; payload: FilterState['parent_id'] };

const initialState: FilterState = {
  status: 'all',
  type: 'all',
  success: 'all',
  parent_id: 'all',
};

function filterReducer(state: FilterState, action: FilterAction): FilterState {
  switch (action.type) {
    case 'SET_STATUS_FILTER':
      return { ...state, status: action.payload };
    case 'SET_TYPE_FILTER':
      return { ...state, type: action.payload };
    case 'SET_SUCCESS_FILTER':
      return { ...state, success: action.payload };
    case 'SET_PARENT_FILTER':
      return { ...state, parent_id: action.payload };
    default:
      return state;
  }
}

import { Activity } from './Activity';

interface FeedProps {
  activities?: ActivityFieldsFragment[];
  lead_id?: string;
  user_id?: string;
}

export const Feed = ({ activities, lead_id, user_id }: FeedProps) => {
  const [filterState, dispatch] = useReducer(filterReducer, initialState);
  const { t } = useLocale();

  const filteredActivities = activities?.filter(activity => {
    const statusMatch =
      filterState.status === 'all'
        ? true
        : filterState.status === 'done'
        ? activity.done
        : !activity.done;

    const typeMatch =
      filterState.type === 'all'
        ? true
        : activity.activity_type === filterState.type;

    const successMatch =
      filterState.success === 'all'
        ? true
        : filterState.success === true
        ? activity.success
        : !activity.success;

    const parentMatch =
      filterState.parent_id === 'all' ||
      activity.lead_id === filterState.parent_id ||
      activity.user_id === filterState.parent_id;

    return statusMatch && typeMatch && successMatch && parentMatch;
  });

  if (!activities?.length) {
    return (
      <Alert severity="info">
        <Typography variant="body2">No activities found</Typography>
      </Alert>
    );
  }

  return (
    <>
      <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
        <FormControl size="small" sx={{ minWidth: 120, background: 'white' }}>
          <Select
            value={filterState.status}
            onChange={e =>
              dispatch({
                type: 'SET_STATUS_FILTER',
                payload: e.target.value as FilterState['status'],
              })
            }
            variant="outlined"
            sx={{ backgroundColor: 'white' }}
          >
            <MenuItem value="all">All Statuses</MenuItem>
            <MenuItem value="done">✅ Completed</MenuItem>
            <MenuItem value="pending">⏳ Pending</MenuItem>
          </Select>
        </FormControl>

        <FormControl size="small" sx={{ minWidth: 120, background: 'white' }}>
          <Select
            value={filterState.type}
            onChange={e =>
              dispatch({
                type: 'SET_TYPE_FILTER',
                payload: e.target.value as FilterState['type'],
              })
            }
            variant="outlined"
            sx={{ backgroundColor: 'white' }}
          >
            <MenuItem value="all">All Types</MenuItem>
            <MenuItem value="call">☎️ Calls</MenuItem>
            <MenuItem value="note">📝 Notes</MenuItem>
            <MenuItem value="task">📅 Tasks</MenuItem>
          </Select>
        </FormControl>

        <FormControl size="small" sx={{ minWidth: 120, background: 'white' }}>
          <Select
            value={String(filterState.success)}
            onChange={e =>
              dispatch({
                type: 'SET_SUCCESS_FILTER',
                payload:
                  e.target.value === 'all' ? 'all' : e.target.value === 'true',
              })
            }
            variant="outlined"
            sx={{ backgroundColor: 'white' }}
          >
            <MenuItem value="all">All Outcomes</MenuItem>
            <MenuItem value="true">✅ Success</MenuItem>
            <MenuItem value="false">❌ Failure</MenuItem>
          </Select>
        </FormControl>

        <FormControl size="small" sx={{ minWidth: 120, background: 'white' }}>
          <Select
            value={filterState.parent_id}
            onChange={e =>
              dispatch({
                type: 'SET_PARENT_FILTER',
                payload: e.target.value,
              })
            }
            variant="outlined"
            sx={{ backgroundColor: 'white' }}
          >
            <MenuItem value="all">All Parents</MenuItem>
            {lead_id && (
              <MenuItem value={lead_id}>{`🎯 ${t('This Lead')}`}</MenuItem>
            )}
            {user_id && <MenuItem value={user_id}>{t('This User')}</MenuItem>}
          </Select>
        </FormControl>
      </Stack>

      <Timeline
        sx={{
          p: 0,
          maxWidth: 750,
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {filteredActivities?.map(activity => (
          <Activity key={activity?.id} activity={activity} />
        ))}
      </Timeline>
    </>
  );
};
