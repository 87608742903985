// Generated from src/svgs/file-excel.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const FileExcel = ({ size, fill, className }: Props) => {
  return (
    <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><path d="M6 2h8l6 6v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2m7 1.5V9h5.5L13 3.5m4 7.5h-4v2h1l-2 1.67L10 13h1v-2H7v2h1l3 2.5L8 18H7v2h4v-2h-1l2-1.67L14 18h-1v2h4v-2h-1l-3-2.5 3-2.5h1v-2Z" /></svg>
  );
}
