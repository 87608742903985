// @flow

import * as React from 'react';

import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';

import { useLocale } from '../hooks/locale';
import { useEmailClientConnect } from '../shared/email-client-connect';

type Props = {|
  open: boolean,
  onClose: () => void,
  onSuccess: () => void,
|};

// In some cases we need to trigger reauthenticate window
export const ReconnectEmailDialog = ({
  open,
  onClose,
  onSuccess,
}: Props): React.Node => {
  const { t } = useLocale();
  const connectEmailClient = useEmailClientConnect();
  return (
    <Dialog maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle>{t('youNeedToReconnectYourEmail')}</DialogTitle>
      <DialogActions>
        <Button onClick={onClose}>{t('cancel')}</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            connectEmailClient(error => {
              if (error == null) {
                onSuccess();
              } else {
                onClose();
              }
            });
          }}
        >
          {t('reconnectEmail')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
