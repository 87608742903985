/**
 * @generated SignedSource<<12b12cf065d448698125f2735989c904>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
type propertyImageQuickEdit_image$fragmentType = any;
export type PropertyImageBackground = "black" | "white";
export type PropertyImageCrop = "fill" | "fit";
export type PropertyImageSize = "full_page" | "half_page" | "quarter_page";
export type UpdatePropertyImageInput = {|
  backgroundColor?: ?PropertyImageBackground,
  clientMutationId?: ?string,
  crop?: ?PropertyImageCrop,
  description?: ?string,
  destinationOrderNr?: ?number,
  displayOnBrochure?: ?boolean,
  displayOnListing?: ?boolean,
  id: string,
  isFloorPlan?: ?boolean,
  landscapeBackgroundColor?: ?PropertyImageBackground,
  landscapeCrop?: ?PropertyImageCrop,
  landscapeSize?: ?PropertyImageSize,
  propertyId?: ?string,
  size?: ?PropertyImageSize,
  title?: ?string,
  url?: ?string,
|};
export type propertyImageQuickEditUpdateMutation$variables = {|
  input: UpdatePropertyImageInput,
|};
export type propertyImageQuickEditUpdateMutation$data = {|
  +updatePropertyImage: ?{|
    +propertyImage: ?{|
      +$fragmentSpreads: propertyImageQuickEdit_image$fragmentType,
    |},
  |},
|};
export type propertyImageQuickEditUpdateMutation = {|
  response: propertyImageQuickEditUpdateMutation$data,
  variables: propertyImageQuickEditUpdateMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "propertyImageQuickEditUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePropertyImagePayload",
        "kind": "LinkedField",
        "name": "updatePropertyImage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PropertyImage",
            "kind": "LinkedField",
            "name": "propertyImage",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "propertyImageQuickEdit_image"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "propertyImageQuickEditUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePropertyImagePayload",
        "kind": "LinkedField",
        "name": "updatePropertyImage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PropertyImage",
            "kind": "LinkedField",
            "name": "propertyImage",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayOnListing",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayOnBrochure",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d3f99959ca9563b8c3a67ecfd08b4ec7",
    "id": null,
    "metadata": {},
    "name": "propertyImageQuickEditUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation propertyImageQuickEditUpdateMutation(\n  $input: UpdatePropertyImageInput!\n) {\n  updatePropertyImage(input: $input) {\n    propertyImage {\n      ...propertyImageQuickEdit_image\n      id\n    }\n  }\n}\n\nfragment propertyImageQuickEdit_image on PropertyImage {\n  id\n  displayOnListing\n  displayOnBrochure\n}\n"
  }
};
})();

(node/*: any*/).hash = "9f0f97cfa15ee271874361924c537b02";

export default node;
