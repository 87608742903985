/**
 * @generated SignedSource<<d08dc5de534163a9578742f9aabee3bd>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type TemplatesDialogQuery$variables = {|
  search?: ?string,
|};
export type TemplatesDialogQuery$data = {|
  +emailTemplates: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +addSignature: ?boolean,
        +cc: ?any,
        +files: $ReadOnlyArray<?{|
          +id: string,
          +mimetype: ?string,
          +name: string,
          +size: ?number,
          +url: string,
        |}>,
        +id: string,
        +name: ?string,
        +subject: ?string,
        +subjectPlaintext: ?string,
        +text: ?string,
      |},
    |}>,
    +totalCount: number,
  |},
|};
export type TemplatesDialogQuery = {|
  response: TemplatesDialogQuery$data,
  variables: TemplatesDialogQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Literal",
            "name": "developmentId_null",
            "value": true
          },
          {
            "kind": "Literal",
            "name": "lotId_null",
            "value": true
          },
          {
            "kind": "Variable",
            "name": "search",
            "variableName": "search"
          }
        ],
        "kind": "ObjectValue",
        "name": "filters"
      },
      {
        "kind": "Literal",
        "name": "first",
        "value": 100
      }
    ],
    "concreteType": "EmailTemplateConnectionConnection",
    "kind": "LinkedField",
    "name": "emailTemplates",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "EmailTemplateConnectionEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EmailTemplate",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cc",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "subject",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "subjectPlaintext",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "addSignature",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "files",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "size",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mimetype",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TemplatesDialogQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TemplatesDialogQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "04f1c42c0a8328f2f2b807fdaf38b1ec",
    "id": null,
    "metadata": {},
    "name": "TemplatesDialogQuery",
    "operationKind": "query",
    "text": "query TemplatesDialogQuery(\n  $search: String\n) {\n  emailTemplates(first: 100, filters: {lotId_null: true, developmentId_null: true, search: $search}) {\n    totalCount\n    edges {\n      node {\n        id\n        name\n        cc\n        text\n        subject\n        subjectPlaintext\n        addSignature\n        files {\n          id\n          size\n          name\n          url\n          mimetype\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "38cce7035984b76204675a92d98cdd3a";

export default node;
