/**
 * @generated SignedSource<<b1fede25cc40512ae4f2f88506d45c59>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeedAssignment_activity$data = {
  readonly assignedTo?: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"UserName_user">;
  } | null;
  readonly canEdit?: boolean;
  readonly done?: boolean;
  readonly doneAt?: string | null;
  readonly dueAt?: string | null;
  readonly id: string;
  readonly lead?: {
    readonly completed: boolean;
    readonly hasOpenActivityForMe: boolean;
    readonly id: string;
  } | null;
  readonly note?: string | null;
  readonly startDate?: string | null;
  readonly success?: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"FeedAssignmentClaimLead">;
  readonly " $fragmentType": "FeedAssignment_activity";
};
export type FeedAssignment_activity$key = {
  readonly " $data"?: FeedAssignment_activity$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedAssignment_activity">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedAssignment_activity",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FeedAssignmentClaimLead"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canEdit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "success",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "assignedTo",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "UserName_user"
            },
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "note",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "done",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "doneAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dueAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Lead",
          "kind": "LinkedField",
          "name": "lead",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasOpenActivityForMe",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "completed",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "ActivityAssignment",
      "abstractKey": null
    }
  ],
  "type": "Activity",
  "abstractKey": "__isActivity"
};
})();

(node as any).hash = "25e0a58c960ca7d248958bd7a3b121fd";

export default node;
