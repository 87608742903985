import { Liquid } from 'liquidjs';
import {
  format,
  formatDistanceStrict,
  fromUnixTime,
  getUnixTime,
  isValid,
  parseISO,
} from 'date-fns';
// here we import commonjs for docs
import en from 'date-fns/locale/en-GB/index.js';
import fr from 'date-fns/locale/fr/index.js';
import de from 'date-fns/locale/de/index.js';
import it from 'date-fns/locale/it/index.js';
import es from 'date-fns/locale/es/index.js';

type Language = 'en' | 'de' | 'fr' | 'it' | 'es';

type Renderer = {
  render: (params: {
    content: string;
    data: any;
    meta: {
      title?: string;
      language: Language;
      googleMapsChannel?: string;
      googleMapsKey?: string;
    };
  }) => Promise<string>;
};

const DATEFNS_LOCALE_MAP = {
  en,
  fr,
  de,
  it,
  es,
};

type Options = {
  mainLocale: string;
  dateFnsLocale?: keyof typeof DATEFNS_LOCALE_MAP | null;
  sanitize: (raw: string) => string;
};

type ResultType = {
  name: string;
  min: number;
  value: number;
  max: number;
};

export function setupRenderer(options: Options): Renderer {
  const { mainLocale, dateFnsLocale, sanitize } = options;
  const mainLocaleLanguage = mainLocale.split('-')[0] as Language;
  const engine = new Liquid({
    cache: true,
    outputEscape: 'escape',
    relativeReference: false,
    fs: {
      readFileSync() {
        throw new Error('File read is not allowed in template-renderer');
      },
      async readFile() {
        throw new Error('File read is not allowed in template-renderer');
      },
      existsSync() {
        throw new Error('Work with files is not allowed in template-renderer');
      },
      exists() {
        throw new Error('Work with files is not allowed in template-renderer');
      },
      contains() {
        throw new Error('Work with files is not allowed in template-renderer');
      },
      resolve() {
        throw new Error('Work with files is not allowed in template-renderer');
      },
    },
  });

  // fix for Chrome Skia bug, which cuts French spaces in numbers
  const fixSpaces = (str: string) => str.replace(/\s+/g, ' ');

  const getAppraisalData = (tenantSettings: any, appraisal: any) => {
    const result: ResultType[] = [];
    if (tenantSettings.activateModelPropertydata === true) {
      result.push({
        name: 'Property data',
        min: appraisal.propertydata?.min,
        value: appraisal.propertydata?.value,
        max: appraisal.propertydata?.max,
      });
    }
    if (tenantSettings.activateModelPricehubble === true) {
      result.push({
        name: 'PH',
        min: appraisal.pricehubble?.min,
        value: appraisal.pricehubble?.value,
        max: appraisal.pricehubble?.max,
      });
    }
    if (tenantSettings.activateModelRealadvisorListings === true) {
      result.push({
        name: 'RAL',
        min: appraisal.realadvisor_listing?.min,
        value: appraisal.realadvisor_listing?.value,
        max: appraisal.realadvisor_listing?.max,
      });
      result.push({
        name: 'RAN',
        min: appraisal.realadvisor_naive_listings?.min,
        value: appraisal.realadvisor_naive_listings?.value,
        max: appraisal.realadvisor_naive_listings?.max,
      });
      result.push({
        name: 'RAX',
        min: appraisal.realadvisor_omni_meta?.min,
        value: appraisal.realadvisor_omni_meta?.value,
        max: appraisal.realadvisor_omni_meta?.max,
      });
    }
    if (tenantSettings.activateModelRealadvisorTransactions === true) {
      result.push({
        name: 'RAT',
        min: appraisal.realadvisor_transaction?.min,
        value: appraisal.realadvisor_transaction?.value,
        max: appraisal.realadvisor_transaction?.max,
      });
      result.push({
        name: 'RAO',
        min: appraisal.realadvisor_open_data_transaction?.min,
        value: appraisal.realadvisor_open_data_transaction?.value,
        max: appraisal.realadvisor_open_data_transaction?.max,
      });
    }
    if (tenantSettings.activateModelRealadvisorPerceived === true) {
      result.push({
        name: 'RAP',
        min: appraisal.realadvisor_perceived?.min,
        value: appraisal.realadvisor_perceived?.value,
        max: appraisal.realadvisor_perceived?.max,
      });
    }
    if (tenantSettings.activateModelIazi === true) {
      result.push({
        name: 'IAZI',
        min: appraisal.iazi?.min,
        value: appraisal.iazi?.value,
        max: appraisal.iazi?.max,
      });
      result.push({
        name: 'IAZI CV',
        min: appraisal.iazi_cv?.min,
        value: appraisal.iazi_cv?.value,
        max: appraisal.iazi_cv?.max,
      });
    }
    if (tenantSettings.activateModelWup === true) {
      result.push({
        name: 'Wuest',
        min: appraisal.wup?.min,
        value: appraisal.wup?.value,
        max: appraisal.wup?.max,
      });
    }
    return result;
  };

  engine.registerFilter('number', (number, ...parameterEntries) => {
    const { locale, ...params } = Object.fromEntries(parameterEntries);
    return fixSpaces(
      (number ?? 0).toLocaleString(locale ?? mainLocale, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
        ...params,
      }),
    );
  });

  engine.registerFilter('percent', (number, ...parameterEntries) => {
    const { base, locale, ...params } = Object.fromEntries(parameterEntries);
    return fixSpaces(
      (number / base ?? 0).toLocaleString(locale ?? mainLocale, {
        style: 'percent',
        ...params,
      }),
    );
  });

  engine.registerFilter('normalize_name', title => {
    const formattedName = title.split('_').join(' ');
    return formattedName[0].toUpperCase() + formattedName.slice(1);
  });

  engine.registerFilter('currency', (number, ...parameterEntries) => {
    const {
      locale,
      currency = 'CHF',
      tenantCode,
      ...params
    } = Object.fromEntries(parameterEntries);
    if (tenantCode) {
      const tenantsWithCHF = ['CH'];
      const tenantsWithEUR = ['FR', 'ES', 'DE', 'IT', 'PT', 'NL'];
      const tenantsWithGBP = ['GB'];
      let currencyByLocale = 'CHF';
      if (tenantsWithCHF.includes(tenantCode)) currencyByLocale = 'CHF';
      if (tenantsWithEUR.includes(tenantCode)) currencyByLocale = 'EUR';
      if (tenantsWithGBP.includes(tenantCode)) currencyByLocale = 'GBP';
      return fixSpaces(
        (number ?? 0).toLocaleString(locale ?? mainLocale, {
          style: 'currency',
          currencyDisplay: 'symbol',
          currency: currencyByLocale,
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
          ...params,
        }),
      );
    }
    const checkedCurrency = ['CHF', 'EUR', 'GBP'].includes(currency)
      ? currency
      : 'CHF';
    return fixSpaces(
      (number ?? 0).toLocaleString(locale ?? mainLocale, {
        style: 'currency',
        currencyDisplay: 'symbol',
        currency: checkedCurrency,
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
        ...params,
      }),
    );
  });

  /*
  calculates median values...
  of the array of values:
  {{ data.prices | median }}
  OR array of objects
  {{ data.listings | median: key='salePrice' }}
  zeroes or nulls are excluded from calculating
   */
  engine.registerFilter('median', (arr, ...parameterEntries) => {
    const median = (arr: number[]) => {
      if (arr.length >= 1) {
        arr = arr.sort((a, b) => a - b);

        if (arr.length % 2 === 0) {
          // return avg of 2 middle values if even
          const subCount: number = arr.length / 2 ?? 0;
          if (arr[subCount] && arr[subCount - 1]) {
            return (arr[subCount - 1]! + arr[subCount]!) / 2;
          }
        }

        return arr[(arr.length - 1) / 2];
      }

      return 0;
    };

    const { key } = Object.fromEntries(parameterEntries);

    let values = arr;
    if (key != null) {
      values = arr.map((item: any) => item[key]);
    }
    values = values.filter(Boolean).map((value: unknown) => {
      if (typeof value === 'string') {
        const parsed = parseISO(value);
        if (isValid(parsed)) {
          return getUnixTime(parsed);
        }

        return 0;
      }

      return value;
    });

    return median(values);
  });

  engine.registerFilter('date_fns', (date, formatString) => {
    const locale =
      DATEFNS_LOCALE_MAP[dateFnsLocale ?? mainLocaleLanguage ?? 'en'];
    return date ? format(parseISO(date), formatString, { locale }) : '';
  });

  engine.registerFilter('date_fns_unix', (date, formatString) => {
    const locale =
      DATEFNS_LOCALE_MAP[dateFnsLocale ?? mainLocaleLanguage ?? 'en'];
    return date ? format(fromUnixTime(date), formatString, { locale }) : '';
  });

  engine.registerFilter('format_distance_strict', date => {
    const locale =
      DATEFNS_LOCALE_MAP[dateFnsLocale ?? mainLocaleLanguage ?? 'en'];

    return date
      ? formatDistanceStrict(
          typeof date === 'number' ? fromUnixTime(date) : parseISO(date),
          new Date(),
          {
            locale,
            addSuffix: false,
          },
        )
      : '';
  });

  engine.registerFilter('markdown', async (text, ...params) => {
    if (typeof text !== 'string') {
      return '';
    }

    const { spaces } = Object.fromEntries(params);

    const { Remarkable } = await import('remarkable');

    // add param to enable empty lines in markdown
    if (spaces) {
      text = text.split('\n').join('\n&nbsp;');
    }

    text = new Remarkable({ breaks: true, html: true }).render(text);

    return text;
  });

  // uncomment for easier debug in templates if necessary
  // engine.registerFilter('JSON', data => JSON.stringify(data));

  engine.registerFilter(
    'google_map_coordinates',
    (coordinates, ...parameterEntries) => {
      const style = parameterEntries
        .map(([key, value]) => `${key}:${value}`)
        .join('|');
      return coordinates
        .map((path: any) => {
          const points = path
            .flat()
            .map(([lng, lat]: [number, number]) => `${lat},${lng}`)
            .join('|');
          return `&path=${style}|${points}`;
        })
        .join('');
    },
  );

  engine.registerFilter('google_map_markers', markers => {
    return markers
      .map(({ lat, lng, ...params }: any) => {
        const style = Object.entries(params)
          .map(
            ([key, value]) =>
              `${key}:${typeof value === 'string' ? value : ''}`,
          )
          .join('|');
        return `&markers=${style}|${lat},${lng}`;
      })
      .join('');
  });

  engine.registerFilter(
    'football_chart',
    async (appraisal: any, ...parameterEntries) => {
      const {
        height,
        width,
        comparables,
        livingSurface,
        builtSurface,
        suggestedValue,
        caprateValue,
        intrinsicValue,
        currency,
        includeCuprate,
        includeIntrinsic,
        includeHedonisticValuation,
        includeComparablesValuation,
        locale,
      } = Object.fromEntries(parameterEntries);

      const margins = {
        left: 130,
        top: 20,
        right: 30,
        bottom: 30,
      };

      const { scaleLinear, scalePoint } = await import('d3-scale');
      const { extent, median } = await import('d3-array');

      const filteredComparables = comparables.filter(
        (comparable: any) => comparable.computedPricePerSqm,
      );

      const comparableValues: number[] | undefined =
        filteredComparables.length > 0
          ? filteredComparables.map(
              (comparable: any) =>
                comparable.computedPricePerSqm *
                (builtSurface ?? livingSurface),
            )
          : undefined;

      const comparableLineValues = comparableValues
        ? (extent(comparableValues) as [number, number])
        : undefined;

      const comparableMedianValue = comparableValues
        ? median(comparableValues)
        : undefined;

      const hedonistLineValues: [number, number] = [
        appraisal?.realadvisor.min,
        appraisal?.realadvisor.max,
      ];

      type ValueType = {
        name: string;
        value?: number;
        lineValue?: [number, number];
        hide?: boolean;
      };

      const translations = {
        hedonist: {
          en: 'Hedonist',
          es: 'Estadística',
          it: 'Edonica',
          de: 'Hedonist',
          fr: 'Hédoniste',
        },
        comparables: {
          en: 'Comparables',
          es: 'Testigos',
          it: 'Comparativa',
          de: 'Vergleichbares',
          fr: 'Comparable',
        },
        caprate: {
          en: 'Cap-rate',
          es: 'Tasa de retorno',
          it: 'Cap-rate',
          de: 'Kapitalisierungsrate',
          fr: 'Taux de capitalisation',
        },
        intrinsic: {
          en: 'Intrinsic',
          es: 'Intrínseco',
          it: 'Intrinseca',
          de: 'Intrinsisch',
          fr: 'Intrinsèque',
        },
      };

      const values: ValueType[] = [
        {
          name: translations.hedonist[locale as Language],
          value: median(hedonistLineValues),
          lineValue: hedonistLineValues,
          hide: !includeHedonisticValuation,
        },
        {
          name: translations.comparables[locale as Language],
          value: comparableMedianValue,
          lineValue: comparableLineValues,
          hide: !includeComparablesValuation,
        },
        {
          name: translations.caprate[locale as Language],
          value: caprateValue,
          hide: !includeCuprate,
        },
        {
          name: translations.intrinsic[locale as Language],
          value: intrinsicValue,
          hide: !includeIntrinsic,
        },
      ].filter(e => !e.hide && e.value);

      const pricesBounds = extent(
        [
          ...hedonistLineValues,
          ...(comparableLineValues ?? []),
          caprateValue,
          intrinsicValue,
          suggestedValue,
        ].filter(price => price),
      );

      const additionalMargin = 90;
      const xScale = scaleLinear()
        .domain([pricesBounds[0], pricesBounds[1]])
        //Adding an additional margins in order to let the text fit the chart
        .range([
          margins.left + additionalMargin,
          width - margins.right - additionalMargin,
        ]);

      // Don't trusct xScale.tick(), since in some cases it will give not correct amount of ticks
      // Here we manually create ticks for xAxis
      const tickCount = 5;
      const xAxis = [...Array(tickCount).keys()].map(
        k =>
          ((pricesBounds[1] - pricesBounds[0]) * k) / (tickCount - 1) +
          pricesBounds[0],
      );

      const yScale = scalePoint()
        .domain(values.map(v => v.name))
        .range([margins.top, height - margins.bottom])
        .padding(0.5);

      const XAxisComponent = `<g transform="translate(0,${
        height - margins.bottom
      })">
        <path d="M${margins.left + 0.5},6V0.5H${
        width - margins.right + 0.5
      }V6" stroke="#000" stroke-width="1" fill="none" />
        ${xAxis
          .map(
            (tick: number) =>
              `
              <g opacity="1" transform="translate(${
                xScale(tick) + 0.5
              },0)" text-anchor="middle" font-size="10">
                <line stroke="currentColor" y2="6" />
                <text y="9" dy="0.71em" style="font-weight: 600;">
                  ${tick.toLocaleString(undefined, {
                    style: 'currency',
                    currency,
                    maximumSignificantDigits: 2,
                    notation: 'compact',
                  })}
                </text>
              </g>
            `,
          )
          .join('')}
      </g>`;

      const YAxisComponent = `<g transform="translate(${
        margins.left
      },0)" fill="none" font-size="10" text-anchor="end">
        <path d="M0.5,20.5V350.5" stroke="#000" />
        ${values
          .map(
            ({ name }) => `
          <g opacity="1" transform="translate(0,${yScale(name)! + 0.5})">
            <text fill="currentColor" x="-5" dy="0.32em" style="font-weight: 600;"> ${name} </text>
          </g>
        `,
          )
          .join('')}
      </g>`;

      const SuggestedValueLine = suggestedValue
        ? `<line x1="${xScale(suggestedValue)}" x2="${xScale(
            suggestedValue,
          )}" y1="${margins.top}" y2="${
            height - margins.bottom
          }" stroke-dasharray="3" stroke-width="2" stroke="#49945A" />`
        : '';

      const gradients = values
        .filter(value => value.lineValue)
        .map(
          ({ name, lineValue }) => `
      <linearGradient id="gradient${name}" x1="${xScale(
            lineValue![0],
          )}" y1="${yScale(name)}" x2="${xScale(lineValue![1])} y2="${yScale(
            name,
          )}" gradientUnits="userSpaceOnUse">
          <stop class="start" offset="0%" stop-color="#55BAD9" stop-opacity="1" />
          <stop class="end" offset="100%" stop-color="#203197" stop-opacity="1" />
      </linearGradient>
    `,
        )
        .join('');

      const renderLineAndDots = ({ lineValue, name, value }: ValueType) =>
        lineValue
          ? `
          <line stroke-width="8" stroke="url(#gradient${name})" stroke-linecap='round' x1="${xScale(
              lineValue[0],
            )}" y1="${yScale(name)}" x2="${xScale(lineValue[1])}" y2="${yScale(
              name,
            )}" />

            ${renderDots({ value, name })}


            <rect x="${xScale(lineValue[0]) - 5}" y="${
              (yScale(name) as number) - 8
            }" width="80" height="15" fill="#FFF" transform="translate(-80, 0)"/>
            <text x="${xScale(lineValue[0]) - 5}" y="${
              (yScale(name) as number) + 4
            }" text-anchor="end">
                ${lineValue[0].toLocaleString(undefined, {
                  style: 'currency',
                  currency,
                  maximumFractionDigits: 0,
                })}
            </text>

            <rect x="${xScale(lineValue[1]) + 5}" y="${
              (yScale(name) as number) - 8
            }" width="80" height="15" fill="#FFF"/>

            <text x="${xScale(lineValue[1]) + 5}" y="${
              (yScale(name) as number) + 4
            }" text-anchor="start">
                ${lineValue[1].toLocaleString(undefined, {
                  style: 'currency',
                  currency,
                  maximumFractionDigits: 0,
                })}
              </text>`
          : `${renderDots({ value, name })}`;

      const renderDots = ({ value, name }: ValueType) =>
        value
          ? `
            <circle cx="${xScale(value)}" cy="${yScale(
              name,
            )}" r="5" fill="#FFF" stroke-width="2" stroke="#3b59c3" />

            <rect x="${xScale(value) - 50}" y="${
              (yScale(name) as number) - 22
            }" width="100" height="15" fill="#FFF"/>

            <text x="${xScale(value)}" y="${
              (yScale(name) as number) - 10
            }" style="font-weight: 600;" text-anchor="middle">
            ${value.toLocaleString(undefined, {
              style: 'currency',
              currency,
              maximumFractionDigits: 0,
            })}
            </text>`
          : '';

      const LinesAndDots = values
        .map(value => renderLineAndDots(value))
        .join('');

      return `<svg width="${width}" height="${height}">
        <defs>
          ${gradients}
        </defs>
        ${XAxisComponent}
        ${YAxisComponent}
        ${SuggestedValueLine}
        ${LinesAndDots}
      </svg>`;
    },
  );

  // TODO: not in use now, but could be useful later
  // engine.registerFilter('bbox_to_center', bbox => {
  //   const getBBox2D = bbox => {
  //     if (bbox.length === 6) {
  //       const [swLng, swLat, , neLng, neLat] = bbox;
  //       return [swLng, swLat, neLng, neLat];
  //     }
  //     return bbox;
  //   };
  //
  //   if (Array.isArray(bbox)) {
  //     const [swLng, swLat, neLng, neLat] = getBBox2D(bbox);
  //     return `${swLat + (neLat - swLat) / 2},${swLng + (neLng - swLng) / 2}`;
  //   } else {
  //     // null
  //     return bbox;
  //   }
  // });

  // hey! if you're making changes to this filter, please make sure to check if
  // you need to update realadvisor/realadvisor.crm/src/shared/appraisal-graph.js
  // also! thanks! and have a great day! 🌞

  engine.registerFilter(
    'appraisal_graph',
    async (appraisal: any, ...parameterEntries) => {
      const { scaleLinear, scalePoint } = await import('d3-scale');

      if (appraisal == null) {
        return '';
      }

      const { tenantSettings, height, width, locale } =
        Object.fromEntries(parameterEntries);

      const getAppraisalDomain = (appraisal: any) => {
        const getTuple = (item: any) => [item?.min, item?.value, item?.max];

        const values = [
          ...getTuple(appraisal.iazi),
          ...getTuple(appraisal.iazi_cv),
          ...getTuple(appraisal.wup),
          ...getTuple(appraisal.pricehubble),
          ...getTuple(appraisal.realadvisor_listing),
          ...getTuple(appraisal.realadvisor_naive),
          ...getTuple(appraisal.realadvisor_omni_meta),
          ...getTuple(appraisal.realadvisor_transaction),
          ...getTuple(appraisal.realadvisor_open_data_transaction),
          ...getTuple(appraisal.realadvisor_perceived),
          ...getTuple(appraisal.realadvisor),
        ].filter(d => d != null);

        return [Math.min(...values), Math.max(...values)];
      };

      // also see realadvisor/realadvisor.crm/src/shared/appraisal-graph.js
      const formatValue = (value: number) => {
        // TODO: this is a very hacky solution, we should try to adopt liquidjs render tag maybe
        if (value < 1_000) {
          return value.toLocaleString(locale ?? mainLocale, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          });
        }
        if (value < 1_000_000) {
          return (
            (value / 1_000).toLocaleString(locale ?? mainLocale, {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            }) + 'K'
          );
        }
        return (
          (value / 1_000_000).toLocaleString(locale ?? mainLocale, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + 'M'
        );
        // TODO: use code below when target Safari version become 14.1+
        // const formatValue = value => {
        //   return new Intl.NumberFormat(locale ?? mainLocale, {
        //     notation: 'compact',
        //   }).format(value);
        // };
      };

      // collect points data and remove items without values
      const appraisalData = getAppraisalData(tenantSettings, appraisal).filter(
        item => item.min != null || item.value != null || item.max != null,
      );
      const x = scalePoint()
        .domain(appraisalData.map(item => item.name))
        .range([0, width])
        .padding(1);
      const y = scaleLinear()
        .domain(getAppraisalDomain(appraisal))
        .range([height - 30, 20])
        .nice(5);
      const ticks = y.ticks(5);
      const xStart = x(appraisalData[0]?.name ?? '');
      const xEnd = x(appraisalData[appraisalData.length - 1]?.name ?? '');

      return `
      <svg width="${width}" height="${height}" viewBox="0 0 ${width} ${height}">
        <g transform="translate(0, ${height - 30})">
          ${appraisalData
            .map(item => {
              const pos = x(item.name);
              return `
                <line x1="${pos}" x2="${pos}" y1="0" y2="6" stroke="rgba(102, 102, 102, 1)" />
                <text
                  x="${pos}"
                  y="16"
                  dy="0.71em"
                  text-anchor="middle"
                  font-size="12px"
                  font-weight="bold"
                >
                  ${item.name}
                </text>
              `;
            })
            .join('')}
        </g>

        <g transform="translate(34, 0)">
          ${ticks
            .map(
              tick => `
                <line
                  x1="15"
                  x2="${width}"
                  y1="${y(tick)}"
                  y2="${y(tick)}"
                  fill="none"
                  stroke="rgba(0, 0, 0, .12)"
                  stroke-width="2"
                  stroke-dasharray="3 6"
                />
                <text
                  x="8"
                  y="${y(tick)}"
                  text-anchor="end"
                  font-size="10px"
                  font-weight="bold"
                >
                  ${formatValue(tick)}
                </text>
              `,
            )
            .join('')}
        </g>
        <line
          x1="${xStart}"
          x2="${xEnd}"
          y1="${y(appraisal.realadvisor?.max)}"
          y2="${y(appraisal.realadvisor?.max)}"
          fill="none"
          stroke="rgba(76, 175, 80, 1)"
          stroke-width="1"
          stroke-dasharray="2 2"
        />

        <line
          x1="${xStart}"
          x2="${xEnd}"
          y1="${y(appraisal.realadvisor?.value)}"
          y2="${y(appraisal.realadvisor?.value)}"
          fill="none"
          stroke="rgba(76, 175, 80, 1)"
          stroke-width="2"
          stroke-dasharray="2 2"
        />

        <line
          x1="${xStart}"
          x2="${xEnd}"
          y1="${y(appraisal.realadvisor?.min)}"
          y2="${y(appraisal.realadvisor?.min)}"
          fill="none"
          stroke="rgba(76, 175, 80, 1)"
          stroke-width="1"
          stroke-dasharray="2 2"
        />

        ${appraisalData
          .filter(item => item.max != null)
          .map(
            item => `
              <circle
                cx="${x(item.name)}"
                cy="${y(item.max)}"
                r="3"
                fill="white"
                stroke="rgba(100, 181, 246, 1)"
                stroke-width="2"
              />
              <text
                x="${x(item.name)}"
                dx="20"
                y="${y(item.max)}"
                font-size="10px"
                font-weight="normal"
                text-anchor="middle"
              >
                ${formatValue(item.max)}
              </text>
            `,
          )
          .join('')}

        ${appraisalData
          .filter(item => item.value != null)
          .map(
            item => `
              <circle
               cx="${x(item.name)}"
               cy="${y(item.value)}"
               r="5"
               fill="rgba(100, 181, 246, 1)"
              />
              <text
                x="${x(item.name)}"
                dx="20"
                y="${y(item.value)}"
                font-size="12px"
                font-weight="bold"
                text-anchor="middle"
              >
                ${formatValue(item.value)}
              </text>
            `,
          )
          .join('')}

        ${appraisalData
          .filter(item => item.min != null)
          .map(
            item => `
              <circle
                cx="${x(item.name)}"
                cy="${y(item.min)}"
                r="3"
                fill="white"
                stroke="rgba(100, 181, 246, 1)"
                stroke-width="2"
              />
              <text
                x="${x(item.name)}"
                dx="20"
                y="${y(item.min)}"
                font-size="10px"
                font-weight="normal"
                text-anchor="middle"
              >
                ${formatValue(item.min)}
              </text>
            `,
          )
          .join('')}
      </svg>
    `;
    },
  );

  return {
    render: async ({ content, data, meta }) => {
      const result = await engine.parseAndRender(content, {
        data,
        meta,
      });
      return sanitize(result);
    },
  };
}
