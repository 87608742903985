/**
 * @generated SignedSource<<3b527728bf46faf0e2b64c8e01799079>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OfferStatus = "accepted" | "pending" | "refused";
import { FragmentRefs } from "relay-runtime";
export type feedOffer_activity$data = {
  readonly id: string;
  readonly offer?: {
    readonly amount: number;
    readonly id: string;
    readonly lot: {
      readonly id: string;
    } | null;
    readonly status: OfferStatus;
    readonly validUntil: string | null;
  } | null;
  readonly " $fragmentType": "feedOffer_activity";
};
export type feedOffer_activity$key = {
  readonly " $data"?: feedOffer_activity$data;
  readonly " $fragmentSpreads": FragmentRefs<"feedOffer_activity">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "feedOffer_activity",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Offer",
          "kind": "LinkedField",
          "name": "offer",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Lot",
              "kind": "LinkedField",
              "name": "lot",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "amount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "validUntil",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "ActivityOffer",
      "abstractKey": null
    }
  ],
  "type": "Activity",
  "abstractKey": "__isActivity"
};
})();

(node as any).hash = "1308cf955dff3ecb8c1752e18bd39128";

export default node;
