import { gql } from '@apollo/client';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import { Image } from '@realadvisor/image';

import { useTheme } from '../../../src/hooks/theme';
import type { OrganisationCell_OrganisationFragment } from '../../__generated__/graphql';

export const ORGANISATION_CELL_FRAGMENT = gql`
  fragment OrganisationCell_organisation on organisations {
    id
    name
    street
    street_number
    postcode
    locality
    organisation_images(where: { is_primary: { _eq: true } }, limit: 1) {
      image {
        url
      }
    }
  }
`;

interface OrganisationCellProps {
  organisation?: OrganisationCell_OrganisationFragment;
}

export const OrganisationCell = ({ organisation }: OrganisationCellProps) => {
  const { text, colors } = useTheme();
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'max-content 1fr',
        alignItems: 'center',
        gap: 8,
      }}
    >
      <div style={{ position: 'relative' }}>
        <div style={{ height: 50, width: 75, textAlign: 'center' }}>
          {(organisation?.organisation_images?.length || 0) > 0 ? (
            <Image
              src={organisation?.organisation_images[0]?.image.url ?? ''}
              options={{ w: 256, f: 'jpg' }}
              objectFit="contain"
            />
          ) : (
            <BusinessRoundedIcon
              style={{ fontSize: 32, color: colors.grey400, margin: 8 }}
            />
          )}
        </div>
      </div>
      <div style={{ minWidth: 0 }}>
        <div>{organisation?.name}</div>
        <div
          style={{
            ...text.caption,
            ...text.truncate,
            color: colors.mediumText,
          }}
        >
          {[organisation?.street, organisation?.street_number].join(' ')}
        </div>
        <div
          style={{
            ...text.caption,
            ...text.truncate,
            color: colors.mediumText,
          }}
        >
          {[organisation?.postcode, organisation?.locality].join(' ')}
        </div>
      </div>
    </div>
  );
};
