import type { CountryCode } from '../locale';

export const REALADVISOR_TENANT_ID = '8ce0ed5e-1748-11ea-ab1d-63204f713040';
export const REALADVISOR_DE_TENANT_ID = '4b6ca0fe-5a26-11ea-9ce7-4b0a2b400da4';
export const REALADVISOR_ES_TENANT_ID = '4b6c9ec4-5a26-11ea-9ce7-8ba6f16da65f';
export const REALADVISOR_FR_TENANT_ID = '4b6c9ca8-5a26-11ea-9ce7-b7aa825bfd17';
export const REALADVISOR_GB_TENANT_ID = '2f6910d2-af5a-11ec-a079-9f449d021dc9';
export const REALADVISOR_IT_TENANT_ID = '6b3041d6-b03a-11ec-a491-5348b8d4a351';
export const REALADVISOR_NL_TENANT_ID = '4daf2488-b03a-11ec-a491-7b2833d704f6';
export const REALADVISOR_PT_TENANT_ID = '24773ef0-b500-11ec-bb8b-5f799ccb78b8';

export const isRATenantId = (tenantId: string): boolean => {
  switch (tenantId) {
    case REALADVISOR_TENANT_ID:
    case REALADVISOR_FR_TENANT_ID:
    case REALADVISOR_DE_TENANT_ID:
    case REALADVISOR_ES_TENANT_ID:
    case REALADVISOR_GB_TENANT_ID:
    case REALADVISOR_IT_TENANT_ID:
    case REALADVISOR_NL_TENANT_ID:
    case REALADVISOR_PT_TENANT_ID:
      return true;
    default:
      return false;
  }
};

export const getTenantHostname = (tenantId: string): string | null => {
  switch (tenantId) {
    case REALADVISOR_TENANT_ID:
      return 'realadvisor.ch';
    case REALADVISOR_FR_TENANT_ID:
      return 'realadvisor.fr';
    case REALADVISOR_DE_TENANT_ID:
      return 'realadvisor.de';
    case REALADVISOR_ES_TENANT_ID:
      return 'realadvisor.es';
    case REALADVISOR_GB_TENANT_ID:
      return 'realadvisor.co.uk';
    case REALADVISOR_IT_TENANT_ID:
      return 'realadvisor.it';
    case REALADVISOR_NL_TENANT_ID:
      return 'realadvisor.nl';
    case REALADVISOR_PT_TENANT_ID:
      return 'realadvisor.pt';
    default:
      return null;
  }
};

export const getCountryCodeFromTenantId = (tenantId: string): CountryCode => {
  switch (tenantId) {
    case REALADVISOR_TENANT_ID:
      return 'CH';
    case REALADVISOR_FR_TENANT_ID:
      return 'FR';
    case REALADVISOR_DE_TENANT_ID:
      return 'DE';
    case REALADVISOR_ES_TENANT_ID:
      return 'ES';
    case REALADVISOR_GB_TENANT_ID:
      return 'GB';
    case REALADVISOR_IT_TENANT_ID:
      return 'IT';
    default:
      return 'CH';
  }
};

export const getTenantSlugStageAndSubdomainFromHostname = (
  hostname: string,
) => {
  const match = hostname.match(
    /^(?:(?<stage>[\w-]{1,27})--)?(?<tenantSlug>[\w-]{1,34})\.(?<subdomain>(crm|local-crm|dev-crm))\.realadvisor\.com$/,
  );

  return {
    slug: match?.groups?.tenantSlug ?? null,
    stage: match?.groups?.stage ?? null,
    subdomain: match?.groups?.subdomain ?? null,
  };
};

export const getTenantLanguages = (tenantId: string): string[] => {
  switch (tenantId) {
    case REALADVISOR_TENANT_ID:
      return ['en', 'de', 'fr', 'it'];
    case REALADVISOR_FR_TENANT_ID:
      return ['fr'];
    case REALADVISOR_DE_TENANT_ID:
      return ['de'];
    case REALADVISOR_ES_TENANT_ID:
      return ['es'];
    case REALADVISOR_GB_TENANT_ID:
      return ['en'];
    case REALADVISOR_IT_TENANT_ID:
      return ['it'];
    case REALADVISOR_NL_TENANT_ID:
      return ['nl'];
    case REALADVISOR_PT_TENANT_ID:
      return ['pt'];
    default:
      return [];
  }
};
