import { useState } from 'react';

import { useMutation } from '@apollo/client';
import { v4 } from 'uuid';

import { PREPARE_UPLOAD } from './fileQueries';

export type UploadFileFn = (
  file: File,
  callback?: (params: {
    data: null | {
      url: string;
      id: string;
    };
    error: any;
  }) => void,
) => void;

export const useFileUpload = (
  fileType: 'image' | 'file',
): [UploadFileFn, boolean] => {
  const [generateSignedUrl, { loading: generating }] =
    useMutation(PREPARE_UPLOAD);
  const [isUploading, setIsUploading] = useState(false);

  const mutating = generating || isUploading;

  const mutate: UploadFileFn = (file, callback) => {
    generateSignedUrl({
      variables: {
        id: v4(),
        file_type: fileType,
        file_name: file.name,
        mime_type: file.type,
      },
      onCompleted: data => {
        if (data.prepare_upload != null) {
          setIsUploading(true);
          const prepareUploadData = data.prepare_upload;

          fetch(data.prepare_upload.signed_url, {
            method: 'PUT',
            body: file,
            headers: {
              'Content-Type': file.type,
            },
          })
            .then(({ status }) => {
              if (status !== 200) {
                throw new Error('Upload file error');
              }

              callback?.({
                data: { url: prepareUploadData.url, id: prepareUploadData.id },
                error: null,
              });
            })
            .catch(error => {
              callback?.({ data: null, error });
            })
            .finally(() => setIsUploading(false));
        }
      },
      onError: error => {
        callback?.({ data: null, error });
      },
    });
  };
  return [mutate, mutating];
};
