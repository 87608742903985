// Generated from src/svgs/cma-report-outline.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const CmaReportOutline = ({ size, fill, className }: Props) => {
  return (
    <svg viewBox="0 0 25 25" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><path fillRule="evenodd" clipRule="evenodd" d="M21 3h1.5A1.5 1.5 0 0 1 24 4.5v19a1.5 1.5 0 0 1-1.5 1.5h-16A1.5 1.5 0 0 1 5 23.5V22H3.5A1.5 1.5 0 0 1 2 20.5v-19A1.5 1.5 0 0 1 3.5 0h16A1.5 1.5 0 0 1 21 1.5V3ZM3 20.5a.5.5 0 0 0 .5.5h16a.5.5 0 0 0 .5-.5v-19a.5.5 0 0 0-.5-.5h-16a.5.5 0 0 0-.5.5v19Zm20 3a.5.5 0 0 1-.5.5h-16a.5.5 0 0 1-.5-.5V22h13.5a1.5 1.5 0 0 0 1.5-1.5V4h1.5a.5.5 0 0 1 .5.5v19ZM17.5 16a.5.5 0 0 0 0-1h-12a.5.5 0 0 0 0 1h12Zm.5 2.5a.5.5 0 0 1-.5.5h-12a.5.5 0 0 1 0-1h12a.5.5 0 0 1 .5.5ZM5.5 13h12a.5.5 0 0 0 0-1H16V2.5a.5.5 0 0 0-1 0V12h-2V9.5a.5.5 0 0 0-1 0V12h-2V7.5a.5.5 0 0 0-1 0V12H7V4.5a.5.5 0 0 0-1 0V12h-.5c-.25 0-.5.25-.5.5s.25.5.5.5Z" /></svg>
  );
}
