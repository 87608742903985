/**
 * @generated SignedSource<<63576abe16ee14583c1fac871355c69e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Feed_lot$data = {
  readonly __typename: "Lot";
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"FeedEmailForm_lot" | "FeedNewForm_lot">;
  readonly " $fragmentType": "Feed_lot";
};
export type Feed_lot$key = {
  readonly " $data"?: Feed_lot$data;
  readonly " $fragmentSpreads": FragmentRefs<"Feed_lot">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Feed_lot",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeedNewForm_lot"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeedEmailForm_lot"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Lot",
  "abstractKey": null
};

(node as any).hash = "0abe2be70a307e4d8cdcb0394ce8dec4";

export default node;
