import { Box } from 'react-system';

import { Checkbox } from '../controls/checkbox';
import { Filter } from '../controls/Filters';
import { useLocale } from '../hooks/locale';
import {
  type Field,
  makeField,
  makeUrlSearchParamsHook,
} from '../hooks/url-search-params';

type LeadRelationship =
  | 'owner'
  | 'tenant'
  | 'buyer'
  | 'heir'
  | 'agent'
  | 'other'
  | 'not_set';

const relationship_of_string = (string: string): LeadRelationship => {
  switch (string) {
    case 'owner':
      return string;
    case 'tenant':
      return string;
    case 'buyer':
      return string;
    case 'heir':
      return string;
    case 'agent':
      return string;
    case 'other':
      return string;
    default:
      return 'not_set';
  }
};

export type RelationshipParams = {
  relationship_in: Field<LeadRelationship[] | null>;
};

export const relationshipParams: RelationshipParams = {
  relationship_in: makeField({
    get: params => {
      const list = params
        .getAllStrings('relationship_in')
        .map(relationship_of_string);
      return list.length === 0 ? null : list;
    },
    set: (params, value) =>
      params.setAllStrings('relationship_in', value || []),
  }),
};

const useRelationshipParams =
  makeUrlSearchParamsHook<RelationshipParams>(relationshipParams);

export const RelationshipFilter = () => {
  const { t } = useLocale();
  const [params, setParams] = useRelationshipParams();
  const relationship_in = params.relationship_in || [];
  const labels = relationship_in.map(value => {
    switch (value) {
      case 'owner':
        return t('owner');
      case 'tenant':
        return t('tenant');
      case 'buyer':
        return t('buyer');
      case 'heir':
        return t('heir');
      case 'agent':
        return t('agent');
      case 'other':
        return t('other');
      default:
        return value;
    }
  });
  const toggle = (value: LeadRelationship, checked: boolean) => {
    const newSet = new Set(relationship_in);
    if (checked) {
      newSet.add(value);
    } else {
      newSet.delete(value);
    }
    setParams({ relationship_in: Array.from(newSet) });
  };
  return (
    <Filter
      label={
        relationship_in.length === 0 ? t('relationship') : labels.join(', ')
      }
      dialogTitle={t('relationship')}
      empty={relationship_in.length === 0}
      onReset={() => setParams({ relationship_in: null })}
    >
      <Box
        width="300px"
        py={2}
        px={3}
        css={{ maxHeight: 'calc(100vh - 300px)', overflow: 'auto' }}
      >
        <Checkbox
          color="primary"
          checked={relationship_in.includes('owner')}
          onChange={checked => toggle('owner', checked)}
        >
          {t('owner')}
        </Checkbox>
        <Checkbox
          color="primary"
          checked={relationship_in.includes('tenant')}
          onChange={checked => toggle('tenant', checked)}
        >
          {t('tenant')}
        </Checkbox>
        <Checkbox
          color="primary"
          checked={relationship_in.includes('buyer')}
          onChange={checked => toggle('buyer', checked)}
        >
          {t('buyer')}
        </Checkbox>
        <Checkbox
          color="primary"
          checked={relationship_in.includes('heir')}
          onChange={checked => toggle('heir', checked)}
        >
          {t('heir')}
        </Checkbox>
        <Checkbox
          color="primary"
          checked={relationship_in.includes('agent')}
          onChange={checked => toggle('agent', checked)}
        >
          {t('agent')}
        </Checkbox>
        <Checkbox
          color="primary"
          checked={relationship_in.includes('other')}
          onChange={checked => toggle('other', checked)}
        >
          {t('other')}
        </Checkbox>
      </Box>
    </Filter>
  );
};
