import {
  type Ref,
  type RefAttributes,
  forwardRef,
  useMemo,
  useState,
} from 'react';

import {
  Autocomplete,
  type AutocompleteOwnerState,
  type AutocompleteProps,
  type AutocompleteRenderOptionState,
  CircularProgress,
  type InputProps,
  TextField,
  debounce,
} from '@mui/material';

import { useLocale } from '../../src/hooks/locale';

import type { RaAutoCompleteCreatableItem } from './data-grid/RaAutoComplete';

export type AutocompleteMultiProps<T> = {
  values: T[];
  onFilter: (inputStr: string) => Promise<T[]> | T[];
  loading?: boolean;
  error?: boolean;
  disabled?: boolean;
  InputProps?: InputProps;
  noDebounce?: boolean;
} & Pick<
  AutocompleteProps<T, true, false, false>,
  'getOptionLabel' | 'isOptionEqualToValue' | 'renderTags'
> &
  (
    | ({ onChange: (values: T[]) => void; creatable?: false } & Pick<
        AutocompleteProps<T, true, false, false>,
        'renderOption'
      >)
    | {
        creatable:
          | true
          | {
              createItemFn?: (inputValue: string) => any;
              elementPosition: 'first' | 'last';
            };
        onChange: (values: (T | RaAutoCompleteCreatableItem<T>)[]) => void;
        renderOption?: (
          props: React.HTMLAttributes<HTMLLIElement>,
          option: T | RaAutoCompleteCreatableItem<T>,
          state: AutocompleteRenderOptionState,
          ownerState: AutocompleteOwnerState<T, true, false, false>,
        ) => React.ReactNode;
      }
  );

const AutocompleteMultiWithRef = <T,>(
  props: AutocompleteMultiProps<T>,
  ref: Ref<HTMLDivElement>,
): React.ReactNode => {
  const {
    values,
    onFilter,
    loading,
    error,
    disabled,
    InputProps,
    noDebounce,
    onChange,
    renderOption,
    getOptionLabel,
    isOptionEqualToValue,
    renderTags,
  } = props;

  const { t } = useLocale();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<T[]>([]);

  const createOptions = useMemo(
    () =>
      props.creatable != null && props.creatable !== false
        ? {
            createItemFn:
              typeof props.creatable !== 'boolean' &&
              props.creatable.createItemFn != null
                ? props.creatable.createItemFn
                : null,
            elementPosition:
              typeof props.creatable !== 'boolean'
                ? props.creatable.elementPosition
                : 'last',
          }
        : null,
    [props],
  );

  const maybeDebouncedSearch = useMemo(() => {
    const searchFn = async (value: string) => {
      // Convert result to Promise if the onFilter function is synchronous.
      const result = await Promise.resolve(onFilter(value));

      setOptions(result);
    };

    return noDebounce === true ? searchFn : debounce(searchFn, 275);
  }, [noDebounce, onFilter]);

  const handleSearch = async (inputStr?: string) => {
    if (inputStr != null && inputStr.length > 0) {
      setOpen(true);
      maybeDebouncedSearch(inputStr);
    } else {
      setOpen(false);
      setOptions([]);
    }
  };

  return (
    <Autocomplete<T, true>
      multiple
      ref={ref}
      autoComplete={false}
      autoCorrect="off"
      open={open}
      filterSelectedOptions
      disabled={disabled}
      onClose={() => {
        setOpen(false);
        setOptions([]);
      }}
      value={values}
      getOptionLabel={getOptionLabel}
      options={options}
      loading={loading}
      loadingText={t('Loading...')}
      noOptionsText={t('No results')}
      isOptionEqualToValue={isOptionEqualToValue}
      disableCloseOnSelect
      onInputChange={(_, value) => handleSearch(value)}
      onChange={(_, values) => onChange(values)}
      filterOptions={(filteredResults, state) => {
        if (createOptions == null) {
          return filteredResults;
        }

        const createItem =
          createOptions.createItemFn?.(state.inputValue) ??
          ({
            inputValue: state.inputValue,
            new: true,
          } as RaAutoCompleteCreatableItem<T>);

        return createOptions.elementPosition === 'first'
          ? [createItem, ...filteredResults]
          : [...filteredResults, createItem];
      }}
      renderOption={renderOption}
      renderTags={renderTags}
      componentsProps={{
        paper: {
          elevation: 3,
          sx: {
            borderRadius: '4px',
          },
        },
      }}
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          sx={{ background: 'white' }}
          error={error}
          size="small"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'one-time-code', // disable autocomplete and autofill
            type: 'new-password',
            name: 'new-password',
            autoCorrect: 'off',
            spellCheck: 'false',
          }}
          InputProps={{
            ...InputProps,
            ...params.InputProps,
            startAdornment: (
              <>
                {InputProps?.startAdornment}
                {params.InputProps.startAdornment}
              </>
            ),
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} disableShrink />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export const AutocompleteMulti = forwardRef(AutocompleteMultiWithRef) as <T>(
  props: AutocompleteMultiProps<T> & RefAttributes<HTMLDivElement>,
) => React.ReactNode;
