// @flow

import * as React from 'react';

import 'mapbox-gl/dist/mapbox-gl.css';
import {
  ButtonBase,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { getBoundingBox } from '@realadvisor/google-maps';
import { useResizeRect } from '@realadvisor/observe';
import mapbox from 'mapbox-gl';
import * as ReactDOM from 'react-dom';
import { graphql } from 'react-relay';
import { Box, Flex } from 'react-system';

import { MAPBOX_TOKEN } from '../../config';
import { useLocale } from '../../hooks/locale';
import { useTheme } from '../../hooks/theme';
import { ViewList } from '../../icons/view-list';

import type { TypeOfPlace } from './__generated__/placePicker_place.graphql';
import { MAP_STYLE } from './map-style';
import { PlacePickerInput } from './place-picker-input';
import { PlacePickerList } from './place-picker-list';

/* eslint-disable relay/unused-fields */
graphql`
  fragment placePicker_place on Place {
    id
    type
    objectId
    lat
    lng
    label
    population
    breadcrumbs(incudeNonIndexed: true) {
      id
    }
  }
`;
/* eslint-enable relay/unused-fields */

export type PlacePickerPlace = {|
  +id: string,
  +type: TypeOfPlace,
  +objectId: string,
  +lat: number,
  +lng: number,
  +label: string,
  +population?: number | null,
  +breadcrumbs?: $ReadOnlyArray<{ +id: string, ... }>,
|};

type Props = {|
  mapHeight?: number,
  error?: boolean,
  helperText?: React.Node,
  value: $ReadOnlyArray<PlacePickerPlace>,
  onChange: ($ReadOnlyArray<PlacePickerPlace>) => void,
  initialCenter: {| lat: number, lng: number |},
  initialZoom?: number,
  initialMode?: 'auto' | TypeOfPlace,
  allowedPlaceTypes?: $ReadOnlyArray<TypeOfPlace>,
  canSwitchMode?: boolean,
  inputFooter?: React.Node,
  required?: boolean,
|};

const MapButton = props => {
  const { text } = useTheme();

  return (
    <ButtonBase
      {...props}
      css={{
        fontWeight: text.font.bold,
        fontSize: 14,
        width: 30,
        height: 30,
        borderRadius: 4,
        background: 'white',
        boxShadow: '0 0 0 2px rgba(0, 0, 0, 0.1)',
        ':hover': { background: '#f2f2f2' },
      }}
    />
  );
};

const ModeControl = ({ mapRef, mode, setMode }) => {
  const { text } = useTheme();
  const { t } = useLocale();

  return (
    // $FlowFixMe[prop-missing] data attribute is necessary for click outside
    <Select
      value={mode}
      onChange={event => {
        const newMode: any = event.target.value;
        const newZoom = preferedZoom[newMode];
        if (newZoom != null) {
          mapRef.current?.setZoom(newZoom);
        }
        setMode(newMode);
      }}
      // $FlowFixMe[prop-missing] temporary data prop until useEvent is out
      MenuProps={{
        // fix the problem when menu click is click outside for filter
        // TODO remove when flare InteractOutside is out
        'data-invisible-for-click-outside': true,
      }}
      input={
        <InputBase
          css={{
            fontWeight: text.font.bold,
            fontSize: 14,
            width: '100%',
            borderRadius: 4,
            background: 'white',
            boxShadow: '0 0 0 2px rgba(0, 0, 0, 0.1)',
            ':hover': { background: '#f2f2f2' },
            '.MuiInputBase-input': { paddingLeft: 8 },
          }}
        />
      }
    >
      <MenuItem value="state">{t('states')}</MenuItem>
      <MenuItem value="district">{t('districts')}</MenuItem>
      <MenuItem value="municipality">{t('municipalities')}</MenuItem>
      <MenuItem value="locality">{t('localities')}</MenuItem>
      <MenuItem value="neighbourhood">{t('neighbourhoods')}</MenuItem>
      <MenuItem value="postcode">{t('postcodes')}</MenuItem>
      <MenuItem value="auto">{t('auto')}</MenuItem>
    </Select>
  );
};

const preferedZoom = {
  auto: null,
  country: 6,
  region: 7,
  state: 8,
  district: 9,
  municipality: 11,
  locality: 11,
  postcode: 11,
  agglomeration: 11,
  neighbourhood: 12,
};

const typeByZoom = (zoom): TypeOfPlace | null => {
  if (zoom == null) {
    return null;
  }
  if (zoom >= preferedZoom.locality) {
    return 'locality';
  }
  if (zoom >= preferedZoom.district) {
    return 'district';
  }
  return 'state';
};

const getMapOptions = places => {
  if (places.length === 1) {
    const [place] = places;
    return {
      center: {
        lat: place.lat,
        lng: place.lng,
      },
      zoom: preferedZoom[place.type],
    };
  }
  if (places.length > 1) {
    const boundingBox = getBoundingBox(places);
    if (boundingBox != null) {
      const { ne, sw } = boundingBox;
      return {
        bounds: [
          sw.lng, // west
          sw.lat, // south
          ne.lng, // east
          ne.lat, // north
        ],
        fitBoundsOptions: {
          padding: { top: 100, right: 100, bottom: 100, left: 100 },
        },
      };
    }
  }
  return null;
};

const fitPlaces = (map, places) => {
  if (places.length === 1) {
    const [place] = places;
    map.jumpTo({
      center: {
        lat: place.lat,
        lng: place.lng,
      },
      zoom: preferedZoom[place.type],
    });
  }
  if (places.length > 1) {
    const boundingBox = getBoundingBox(places);
    if (boundingBox != null) {
      const { ne, sw } = boundingBox;
      const bounds = [
        sw.lng, // west
        sw.lat, // south
        ne.lng, // east
        ne.lat, // north
      ];
      map.fitBounds(bounds, {
        padding: { top: 100, right: 100, bottom: 100, left: 100 },
      });
    }
  }
};

const getMapStyle = (places, mode, zoom) => {
  const autoType = typeByZoom(zoom);
  const visibleType = mode === 'auto' ? autoType : mode;
  const shadowStyle = { 'fill-color': 'hsla(212, 30%, 37%, 0.4)' };
  const postcodes = [];
  const neighbourhoods = [];
  const localities = [];
  const municipalities = [];
  const districts = [];
  const states = [];
  for (const place of places) {
    if (place.type === 'postcode') {
      postcodes.push(place.objectId);
    }
    if (place.type === 'neighbourhood') {
      neighbourhoods.push(place.objectId);
    }
    if (place.type === 'locality') {
      localities.push(place.objectId);
    }
    if (place.type === 'municipality') {
      municipalities.push(place.objectId);
    }
    if (place.type === 'district') {
      districts.push(place.objectId);
    }
    if (place.type === 'state') {
      states.push(place.objectId);
    }
  }
  return {
    ...MAP_STYLE,
    layers: MAP_STYLE.layers.map(layer => {
      if (layer.id === 'postcodes-selected') {
        return {
          ...layer,
          paint: visibleType === 'postcode' ? layer.paint : shadowStyle,
          filter: ['in', 'id', ...postcodes],
        };
      }
      if (layer.id === 'neighbourhoods-selected') {
        return {
          ...layer,
          paint: visibleType === 'neighbourhood' ? layer.paint : shadowStyle,
          filter: ['in', 'id', ...neighbourhoods],
        };
      }
      if (layer.id === 'localities-selected') {
        return {
          ...layer,
          paint: visibleType === 'locality' ? layer.paint : shadowStyle,
          filter: ['in', 'id', ...localities],
        };
      }
      if (layer.id === 'municipalities-selected') {
        return {
          ...layer,
          paint: visibleType === 'municipality' ? layer.paint : shadowStyle,
          filter: ['in', 'id', ...municipalities],
        };
      }
      if (layer.id === 'districts-selected') {
        return {
          ...layer,
          paint: visibleType === 'district' ? layer.paint : shadowStyle,
          filter: ['in', 'id', ...districts],
        };
      }
      if (layer.id === 'states-selected') {
        return {
          ...layer,
          paint: visibleType === 'state' ? layer.paint : shadowStyle,
          filter: ['in', 'id', ...states],
        };
      }
      if (
        (layer.id.startsWith('neighbourhoods') &&
          visibleType !== 'neighbourhood') ||
        (layer.id.startsWith('postcodes') && visibleType !== 'postcode') ||
        (layer.id.startsWith('localities') && visibleType !== 'locality') ||
        (layer.id.startsWith('municipalities') &&
          visibleType !== 'municipality') ||
        (layer.id.startsWith('districts') && visibleType !== 'district') ||
        (layer.id.startsWith('states') && visibleType !== 'state')
      ) {
        return { ...layer, layout: { visibility: 'none' } };
      }
      return layer;
    }),
  };
};

const useStableCallback = callback => {
  const ref = React.useRef(callback);
  React.useLayoutEffect(() => {
    ref.current = callback;
  });
  const stableCallback = React.useCallback(
    (...args) => ref.current(...args),
    [],
  );
  return stableCallback;
};

const PlaceMarker = ({ mapRef, place }) => {
  const [container, setContainer] = React.useState(null);
  React.useEffect(() => {
    if (mapRef.current != null) {
      const map = mapRef.current;
      const element = document.createElement('div');
      new mapbox.Marker({ element, anchor: 'bottom' })
        .setLngLat([place.lng, place.lat])
        .addTo(map);
      setContainer(element);
    }
  }, [mapRef, place]);
  return (
    container &&
    ReactDOM.createPortal(<div css={{ fontSize: 30 }}>📍</div>, container)
  );
};

const PlacePickerMap = ({
  mapRef,
  places,
  mode,
  onTap,
  initialCenter,
  initialZoom,
}) => {
  const containerRef = React.useRef(null);
  React.useEffect(() => {
    // initialize once when container element exists
    if (containerRef.current != null && mapRef.current == null) {
      const initialOptions = {
        center: initialCenter,
        zoom: initialZoom ?? preferedZoom[mode] ?? preferedZoom.locality,
      };
      const map = new mapbox.Map({
        testMode: null,
        container: containerRef.current,
        accessToken: MAPBOX_TOKEN,
        attributionControl: false,
        doubleClickZoom: false,
        style: getMapStyle(places, mode, null),
        ...initialOptions,
        ...getMapOptions(places),
      });
      map.addControl(new mapbox.NavigationControl({ showCompass: false }));
      mapRef.current = map;
    }
  });
  // clear resources on unmount
  React.useEffect(() => {
    return () => {
      mapRef.current?.remove();
      mapRef.current = null;
    };
  }, [mapRef]);
  // set style when map is loaded to prevent diffing warning
  React.useEffect(() => {
    if (mapRef.current != null) {
      const map = mapRef.current;
      const handleLoad = () => {
        map.setStyle(getMapStyle(places, mode, map.getZoom()));
      };
      if (map.loaded()) {
        handleLoad();
      } else {
        map.on('load', handleLoad);
        return () => {
          map.off('load', handleLoad);
        };
      }
    }
  }, [mapRef, places, mode]);
  // update style on zoom change
  React.useEffect(() => {
    if (mapRef.current != null) {
      const map = mapRef.current;
      const handleZoomend = () => {
        map.setStyle(getMapStyle(places, mode, map.getZoom()));
      };
      map.on('zoomend', handleZoomend);
      return () => {
        map.off('zoomend', handleZoomend);
      };
    }
  }, [mapRef, places, mode]);
  // subscribe on click
  const stableOnTap = useStableCallback(onTap);
  React.useEffect(() => {
    if (mapRef.current != null) {
      const map = mapRef.current;
      const handleTap = event => {
        const { lat, lng } = event.lngLat;
        stableOnTap({ lat, lng, point: event.point });
      };
      map.on('click', handleTap);
      return () => {
        map.off('click', handleTap);
      };
    }
  }, [mapRef, stableOnTap]);
  return (
    <>
      {/* emotion lost marker styles */}
      <div
        ref={containerRef}
        style={{ width: '100%', height: '100%' }}
        onTouchStart={event => {
          // Allow only two or more fingers
          if (event.touches.length > 1) {
            event.preventDefault();
            event.stopPropagation();
          }
        }}
      ></div>
    </>
  );
};

export const PlacePicker = ({
  mapHeight,
  value,
  error,
  helperText,
  onChange,
  initialCenter,
  initialZoom,
  inputFooter,
  required,
  initialMode = 'auto',
  canSwitchMode = true,
  allowedPlaceTypes = ['state', 'district', 'municipality', 'locality'],
}: Props): React.Node => {
  const { t } = useLocale();
  const [view, setView] = React.useState<'input' | 'map'>('input');
  const [mode, setMode] = React.useState<'auto' | TypeOfPlace>(initialMode);
  const mapRef = React.useRef<mapbox.Map | null>(null);

  const containerRef = React.useRef(null);
  const containerRect = useResizeRect(containerRef);
  const showViewSwitcher = (containerRect?.width ?? 0) < 720;
  const showInput = showViewSwitcher === false || view === 'input';
  const showMap = showViewSwitcher === false || view === 'map';

  const handleMapTap = ({ point, lat, lng }) => {
    const mappedTypes = {
      localities: 'locality',
      municipalities: 'municipality',
      districts: 'district',
      states: 'state',
      neighbourhoods: 'neighbourhood',
      postcodes: 'postcode',
    };
    const features =
      mapRef.current?.queryRenderedFeatures(point, {
        layers: Object.keys(mappedTypes),
      }) ?? [];
    for (const feature of features) {
      const queryResult: any = feature;
      const type = mappedTypes[queryResult.layer.id];
      if (type != null) {
        const { id: objectId, label_en: label } = queryResult.properties;
        const globalId = btoa(`Place:${objectId}`);
        const place = { id: globalId, type, objectId, label, lat, lng };
        if (value.some(x => x.objectId === place.objectId)) {
          onChange(value.filter(x => x.objectId !== place.objectId));
        } else {
          onChange([...value, place]);
        }
      }
    }
  };

  return (
    <div
      ref={containerRef}
      css={{
        height: '100%',
        display: 'grid',
        gridTemplateColumns: showViewSwitcher ? '1fr' : '2fr 3fr',
      }}
    >
      {showInput && (
        <Flex flexDirection="column" justifyContent="space-between">
          <div css={{ padding: '12px 12px 0 12px' }}>
            <FormControl error={error} required={required}>
              <InputLabel>{t('searchPlaces')}</InputLabel>
              <PlacePickerInput
                showSwitcher={showViewSwitcher}
                value={value}
                onSwitchView={() => setView('map')}
                onChange={newPlaces => {
                  if (mapRef.current) {
                    fitPlaces(mapRef.current, newPlaces);
                  }
                  onChange(newPlaces);
                }}
                allowedPlaceTypes={allowedPlaceTypes}
              />
              {helperText != null && (
                <FormHelperText>{helperText}</FormHelperText>
              )}
            </FormControl>
          </div>

          <React.Suspense
            fallback={
              <CircularProgress css={{ margin: 'auto' }} disableShrink={true} />
            }
          >
            <Box css={{ flexGrow: 1, padding: '0 2px', overflowY: 'scroll' }}>
              <PlacePickerList
                items={value}
                onRemove={toRemove => {
                  const newPlaces = value.filter(
                    place => place.objectId !== toRemove,
                  );
                  if (mapRef.current) {
                    fitPlaces(mapRef.current, newPlaces);
                  }
                  onChange(newPlaces);
                }}
              />
            </Box>
          </React.Suspense>

          {inputFooter != null && (
            <Flex
              py={3}
              px={'18px'}
              css={[{ boxShadow: '0px -3px 10px rgba(0, 0, 0, 0.12)' }]}
            >
              {inputFooter}
            </Flex>
          )}
        </Flex>
      )}

      {showMap && (
        <div
          css={{
            height: mapHeight == null ? 'auto' : mapHeight,
            maxHeight: '100%',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <PlacePickerMap
            mapRef={mapRef}
            places={value}
            mode={mode}
            onTap={handleMapTap}
            initialCenter={initialCenter}
            initialZoom={initialZoom}
          />
          {/* markers should be rendered in parallel with map component
              to run markers effects after map one */}
          {value.map(place => (
            <PlaceMarker
              key={place.type + place.objectId}
              mapRef={mapRef}
              place={place}
            />
          ))}
          {showViewSwitcher && (
            <div css={{ position: 'absolute', right: 10, top: 76 }}>
              <MapButton onClick={() => setView('input')}>
                <ViewList />
              </MapButton>
            </div>
          )}
          {canSwitchMode && (
            <div css={{ position: 'absolute', left: 10, top: 10, width: 130 }}>
              <ModeControl mapRef={mapRef} mode={mode} setMode={setMode} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
