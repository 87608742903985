import type * as React from 'react';

import { FormControlLabel, Radio as MuiRadio } from '@material-ui/core';

type Props = {
  color?: 'primary' | 'secondary' | 'default';
  size?: 'small' | 'medium';
  disabled?: boolean;
  checked: boolean;
  onChange: () => void;
  children?: React.ReactNode;
};

export const Radio = (props: Props) => {
  return (
    <FormControlLabel
      css={{
        // delegate layout to grid
        display: 'flex',
        marginRight: 0,
        '.MuiFormControlLabel-label': {
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
        },
      }}
      label={props.children}
      control={
        <MuiRadio
          inputProps={{
            // we use checkbox here to prevent focus trap within radio buttons
            // which restricts navigation between radio buttons only with arrows
            // arrows also selects focused radio so this makes form completely
            // not accessible with keyboard
            type: 'checkbox',
          }}
          color={props.color}
          disabled={props.disabled}
          checked={props.checked}
          onChange={props.onChange}
          size={props.size}
        />
      }
    />
  );
};
