import { createContext, useContext, useMemo } from 'react';

import { gql, useApolloClient, useSuspenseQuery } from '@apollo/client';
import { type IntrospectionQuery, getIntrospectionQuery } from 'graphql';
import combineQuery from 'graphql-combine-query';

import { type MeQuery } from '../__generated__/graphql';

const SCHEMA_QUERY = gql(getIntrospectionQuery());
const ME_QUERY = gql`
  query Me {
    me {
      id
      is_admin
      is_broker
      language
      # GTM UserDetails params (used for GTM intercom setup)
      first_name
      last_name
      full_name
      can_user_login_as
      can_edit_settings
      modules
      show_in_agency_pages
      tenant_id
      default_team {
        id
        name
        lng
        lat
      }
      teams_users {
        team {
          id
          name
        }
      }
      emails {
        email
        primary
        verified
      }
      tenant {
        id
        name
        country_code
        lng
        lat
        help_link_en
        help_link_fr
        help_link_de
        help_link_it
        help_link_es
        image {
          url
        }
      }
      phone_numbers {
        number
        primary
        verified
      }
      user_images(where: { is_primary: { _eq: true } }, limit: 1) {
        image {
          url
        }
      }
      teams: teams_users(where: { deactivated: { _eq: false } }) {
        team_id
        team {
          organisation_id
        }
      }
      user_ids_in_led_teams: teams_users(
        where: { deactivated: { _eq: false }, is_leader: { _eq: true } }
      ) {
        team_id
        team {
          teams_users {
            user_id
          }
        }
      }
      nylas_account: logins(where: { provider: { _eq: "nylas" } }) {
        __typename
      }
    }
  }
`;

const APP_DATA_QUERY = combineQuery('AppDataQuery')
  .add(ME_QUERY)
  .add(SCHEMA_QUERY).document;

export const AppDataContext = createContext<{
  me: MeQuery['me'];
  schema?: IntrospectionQuery;
} | null>(null);

export const AppDataProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { data } = useSuspenseQuery<MeQuery & IntrospectionQuery>(
    APP_DATA_QUERY,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const appData = useMemo(() => {
    return {
      me: data.me,
      schema: { __schema: data.__schema },
    };
  }, [data.me, data.__schema]);

  // HACK: storing schema like this doesn't alter the cache performance
  // giving more room for real data
  const client = useApolloClient();
  const normalizedData = client.cache.extract();
  client.cache.restore({
    ...normalizedData,
    schema: appData.schema,
  });

  return (
    <AppDataContext.Provider value={appData}>
      {children}
    </AppDataContext.Provider>
  );
};

export const useAppData = () => {
  const context = useContext(AppDataContext);

  if (context == null) {
    throw Error('useAppData must be used within an AppDataProvider');
  }
  return context;
};
