/**
 * @generated SignedSource<<b9e2f76d25ca0d2c38982521b201fec9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type emailSequenceSelectQuery$variables = {};
export type emailSequenceSelectQuery$data = {
  readonly email_sequences_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly email_sequences_recipients: ReadonlyArray<{
          readonly buyer_lead_id: string | null;
          readonly lead_id: string | null;
          readonly lot_id: string | null;
          readonly user_id: string;
        }>;
        readonly id: string;
        readonly name: string | null;
      };
    }>;
  };
};
export type emailSequenceSelectQuery = {
  response: emailSequenceSelectQuery$data;
  variables: emailSequenceSelectQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "status": {
        "_eq": "active"
      }
    }
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "user_id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lot_id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "buyer_lead_id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lead_id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "emailSequenceSelectQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "email_sequencesConnection",
        "kind": "LinkedField",
        "name": "email_sequences_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "email_sequencesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "email_sequences",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "email_sequences_recipients",
                    "kind": "LinkedField",
                    "name": "email_sequences_recipients",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "email_sequences_connection(where:{\"status\":{\"_eq\":\"active\"}})"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "emailSequenceSelectQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "email_sequencesConnection",
        "kind": "LinkedField",
        "name": "email_sequences_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "email_sequencesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "email_sequences",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "email_sequences_recipients",
                    "kind": "LinkedField",
                    "name": "email_sequences_recipients",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "email_sequences_connection(where:{\"status\":{\"_eq\":\"active\"}})"
      }
    ]
  },
  "params": {
    "cacheID": "c9c622cbe0e16503b3ff4311b5bcb2a4",
    "id": null,
    "metadata": {},
    "name": "emailSequenceSelectQuery",
    "operationKind": "query",
    "text": "query emailSequenceSelectQuery {\n  email_sequences_connection(where: {status: {_eq: active}}) {\n    edges {\n      node {\n        id\n        name\n        email_sequences_recipients {\n          user_id\n          lot_id\n          buyer_lead_id\n          lead_id\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "53a4c8fc8f658e6198705c946c138a12";

export default node;
