// Generated from src/svgs/keyboard_arrow_left.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const KeyboardArrowLeft = ({ size, fill, className }: Props) => {
  return (
    <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><path d="M15.41 16.59 10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z" /><path fill="none" d="M0 0h24v24H0V0z" /></svg>
  );
}
