// Generated from src/svgs/previous-queue.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const PreviousQueue = ({ size, fill, className }: Props) => {
  return (
    <svg viewBox="0 0 25 25" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><path d="M13 7.5V13h5.5a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5v-6a.5.5 0 0 1 1 0ZM12.5 0A12.412 12.412 0 0 0 1 7.616V1.5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1H1.928a11.489 11.489 0 1 1 1.944 12.104.5.5 0 0 0-.75.662A12.5 12.5 0 1 0 12.5 0Z" /></svg>
  );
}
