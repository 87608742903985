// Generated from src/svgs/map_marker_selected.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const MapMarkerSelected = ({ size, fill, className }: Props) => {
  return (
    <svg viewBox="0 0 45 70" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><path d="M43 22.387c0 3.38-1.28 7.95-3.368 13.07-2.066 5.066-4.835 10.465-7.626 15.433a254.699 254.699 0 0 1-7.673 12.717c-.707 1.1-1.334 2.059-1.855 2.845a230.435 230.435 0 0 1-9.49-15.216C10.2 46.33 7.435 40.966 5.37 35.86 3.288 30.712 2 26.015 2 22.387 2 14.784 4.364 9.79 7.912 6.664 11.505 3.5 16.596 2 22.5 2c5.839 0 10.943 1.702 14.564 4.997C40.654 10.264 43 15.29 43 22.387Z" fill="#2877CC" stroke="#fff" strokeWidth="4" /></svg>
  );
}
