import * as React from 'react';

import { useQuery } from '@apollo/client';
import type { Theme } from '@emotion/react';
import type { Interpolation } from '@emotion/serialize';
import { Button, Paper, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import {
  requestFacebookCode,
  requestGoogleCode,
} from '@realadvisor/auth-client';
import { Box, Flex } from 'react-system';

import { gql } from '../../../apollo/__generated__';
import { BtnFacebookLightNormalIos } from '../../../icons/btn-facebook-light-normal-ios';
import * as config from '../../config';
import { Markdown } from '../../controls/markdown';
import { useLocale } from '../../hooks/locale';
import { useTheme } from '../../hooks/theme';
import { BtnGoogleLightNormalIos } from '../../icons/btn-google-light-normal-ios';
import { RealadvisorLogoNoText } from '../../icons/realadvisor-logo-no-text';
import { getTenantSlugStageAndSubdomainFromHostname } from '../../utils/tenant';

import { LoginEmailForm } from './LoginEmailForm';
import { LoginPhoneForm } from './LoginPhoneForm';
import { LoginVerifyCodeForm } from './LoginVerifyCodeForm';

const Heading = ({ children }: { children: React.ReactNode }) => {
  const { text } = useTheme();
  return (
    <Box pb={3} css={[text.h6, { textAlign: 'center' }]}>
      {children}
    </Box>
  );
};

const generateButtonCss = (background: string): Interpolation<Theme> => ({
  '&&': {
    background,
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'left',
    padding: 0,
  },
  '& .MuiButton-label': {
    justifyContent: 'flex-start',
    padding: 2,
  },
  '& .MuiButton-startIcon': {
    marginRight: 12,
    marginLeft: 0,
  },
  '& .MuiButton-startIcon > *:first-of-type': {
    fontSize: 40,
  },
});

const Divider = ({ children }: { children: React.ReactNode }) => (
  <strong
    css={{
      position: 'relative',
      zIndex: 1,
      display: 'block',
      marginTop: '0.5em',
      width: '100%',
      color: '#757575',
      textAlign: 'center',
      fontSize: '80%',
      '::before': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '50%',
        zIndex: -1,
        marginTop: -5,
        marginLeft: -20,
        width: 40,
        height: 10,
        backgroundColor: '#fff',
      },
      '::after': {
        content: '""',
        position: 'absolute',
        top: '49%',
        zIndex: -2,
        display: 'block',
        width: '100%',
        borderBottom: '1px solid #ddd',
      },
    }}
  >
    {children}
  </strong>
);

const LoginError = () => {
  const { t } = useLocale();
  const { searchParams } = new URL(location.href);
  const error = searchParams.get('error');
  if (error == null) {
    return null;
  }
  let message = t('somethingWentWrong');
  if (error === 'EXPIRED_CODE') {
    message = t('authEmailExpired');
  }
  return (
    <Alert
      severity="error"
      css={{ marginBottom: 8, '.MuiAlert-icon': { display: 'none' } }}
    >
      <Markdown>{message}</Markdown>
    </Alert>
  );
};

const GET_TENANT_BY_SLUG = gql(`
  query GetTenantBySlug($slug: String!) {
    tenants(where: {slug: {_eq: $slug}}) {
        id
    }
  }
  `);

const LoginPage = ({
  onPhoneChange,
  tenantId,
}: {
  onPhoneChange: (state: { number: string; tenantId: string | null }) => void;
  tenantId: string | null;
}) => {
  const { t, language } = useLocale();
  const searchParams = new URLSearchParams(location.search);
  const returnTo = searchParams.get('return') ?? location.origin;

  return (
    <>
      <Heading>{t('signIn')}</Heading>
      <LoginError />

      <Button
        variant="contained"
        startIcon={<BtnGoogleLightNormalIos />}
        css={generateButtonCss('#4285F4')}
        size="large"
        onClick={() => {
          const redirect_back_url = new URL('/login/google', location.origin);
          redirect_back_url.searchParams.set('redirect_uri', returnTo);
          if (tenantId != null) {
            redirect_back_url.searchParams.set('tenant_id', tenantId);
          }
          requestGoogleCode({
            client_id: config.googleClientId,
            redirect_back_uri: redirect_back_url.toString(),
          });
        }}
      >
        {t('Sign in with Google')}
      </Button>
      <Box py={1}>
        <Button
          variant="contained"
          startIcon={<BtnFacebookLightNormalIos />}
          css={generateButtonCss('#4c6ca6')}
          onClick={() => {
            const redirect_back_url = new URL(
              '/login/facebook',
              location.origin,
            );
            redirect_back_url.searchParams.set('redirect_uri', returnTo);
            if (tenantId != null) {
              redirect_back_url.searchParams.set('tenant_id', tenantId);
            }
            requestFacebookCode({
              client_id: config.facebookAppID,
              redirect_back_uri: redirect_back_url.toString(),
            });
          }}
        >
          {t('Sign in with Facebook')}
        </Button>
      </Box>
      <Divider>{t('or')}</Divider>
      <LoginEmailForm tenantId={tenantId} />
      <Divider>{t('or')}</Divider>
      <LoginPhoneForm onChange={onPhoneChange} tenantId={tenantId} />
      <div
        css={{ marginTop: 16, fontSize: 12, textAlign: 'justify' }}
        dangerouslySetInnerHTML={{
          __html: t('loginDisclaimer', {
            privacyHref: `https://realadvisor.${
              language === 'es' ? 'es' : 'ch'
            }/${language}/privacy`,
            termsHref: `https://realadvisor.${
              language === 'es' ? 'es' : 'ch'
            }/${language}/terms`,
          }),
        }}
      />
      <div
        css={{ marginTop: 16, fontSize: 12, textAlign: 'justify' }}
        dangerouslySetInnerHTML={{
          __html: t('loginGoogleDisclaimer', {
            dataPolicyLink: `https://developers.google.com/terms/api-services-user-data-policy`,
          }),
        }}
      />
    </>
  );
};

const VerifyPage = ({
  phone,
  tenantId,
  onBack,
}: {
  phone: string;
  tenantId: string | null;
  onBack: () => void;
}) => {
  const { t } = useLocale();
  return (
    <>
      <Heading>{t('enterSMSCode')}</Heading>
      <LoginVerifyCodeForm phone={phone} tenantId={tenantId} />
      <Divider>{t('or')}</Divider>
      <Box py={1}>
        <Button variant="contained" color="primary" onClick={onBack}>
          {t('back')}
        </Button>
      </Box>
    </>
  );
};

export const Login = () => {
  const { t } = useLocale();
  const [phoneState, setPhoneState] = React.useState<{
    number: string;
    tenantId: string | null;
  } | null>(null);

  let { slug, stage, subdomain } = getTenantSlugStageAndSubdomainFromHostname(
    location.hostname,
  );

  // we are accessing dev-crm without tenantSlug only stage, but regex match only slug
  // or we are accessing local or prod without slug
  if (
    (subdomain === 'dev-crm' && stage == null && slug != null) ||
    slug == null
  ) {
    slug = '';
  }

  const { data, loading } = useQuery(GET_TENANT_BY_SLUG, {
    variables: { slug },
    skip: slug === '',
  });

  const tenantId: string | null = data?.tenants[0]?.id ?? null;

  if (loading) {
    return null;
  }

  return (
    <Flex
      width={1}
      py={3}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      // vh is broken in iOS
      css={{ minHeight: window.innerHeight }}
    >
      <Typography
        variant="h5"
        component="div"
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 16,
        }}
      >
        <RealadvisorLogoNoText size={32} css={{ marginRight: 8 }} />
        RealAdvisor
      </Typography>
      <Typography variant="h4" css={{ marginBottom: 32 }}>
        <strong>RealAdvisor CRM</strong>
      </Typography>
      {slug !== '' && tenantId == null ? (
        <Alert severity="error">
          <Markdown>{t('Tenant not found')}</Markdown>
        </Alert>
      ) : (
        <Paper elevation={4}>
          <Box
            // prevent captcha overflow
            width={['300px', '360px']}
            py={[3, 4]}
            px={[2, 4]}
            css={{ overflow: 'hidden' }}
          >
            {phoneState == null ? (
              <LoginPage onPhoneChange={setPhoneState} tenantId={tenantId} />
            ) : (
              <VerifyPage
                phone={phoneState.number}
                tenantId={phoneState.tenantId}
                onBack={() => setPhoneState(null)}
              />
            )}
          </Box>
        </Paper>
      )}
    </Flex>
  );
};
