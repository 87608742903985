import type { PropertyTypeLight } from '../components/property-form/propertyFormQueries';

/**
 * @param main_type The main_type of the property type.
 * @param propertyTypeName The name of the property type. Only provide it if you want to check if the property type is not a residential building.
 */
function isHouseFn(
  main_type: string,
  propertyTypeName?: string | null,
): boolean;
/**
 * @param type - The property type object.
 * @param excludeResidentialBuildings - If true, the function will return false if the property type is a residential building.
 */
function isHouseFn(
  type: PropertyTypeLight,
  excludeResidentialBuildings?: boolean,
): boolean;
function isHouseFn(
  type: PropertyTypeLight | string,
  propTypeOrExcludeResidential?: string | boolean | null,
): boolean;
function isHouseFn(
  type: PropertyTypeLight | string,
  propTypeOrExcludeResidential?: string | boolean | null,
) {
  if (type == null) {
    return false;
  }

  const main_type = typeof type === 'string' ? type : type.main_type;
  const isMainTypeHouse = main_type === 'HOUSE';

  if (
    propTypeOrExcludeResidential == null ||
    propTypeOrExcludeResidential === false
  ) {
    return isMainTypeHouse;
  }

  const propertyTypeName =
    typeof propTypeOrExcludeResidential === 'string'
      ? propTypeOrExcludeResidential
      : (type as NonNullable<PropertyTypeLight>).name ?? '';

  return isMainTypeHouse && propertyTypeName !== 'house_multiple_dwelling';
}

export const isHouse = isHouseFn;

function isApptFn(main_type: string): boolean;
function isApptFn(type: PropertyTypeLight): boolean;
function isApptFn(type: PropertyTypeLight | string): boolean;
function isApptFn(type: PropertyTypeLight | string) {
  if (type == null) {
    return false;
  }

  const main_type = typeof type === 'string' ? type : type.main_type;

  return main_type === 'APPT';
}

export const isAppt = isApptFn;

/**
 * Check `isHouse` function for doc on parameters.
 */
export const isHouseOrAppt: typeof isHouseFn = (
  type: PropertyTypeLight | string,
  propTypeOrExcludeResidential?: string | boolean | null,
) => isHouseFn(type, propTypeOrExcludeResidential) || isApptFn(type);

function isHouseOrApptOrGastroOrIndusFn(main_type: string): boolean;
function isHouseOrApptOrGastroOrIndusFn(type: PropertyTypeLight): boolean;
function isHouseOrApptOrGastroOrIndusFn(type: PropertyTypeLight | string) {
  if (type == null) {
    return false;
  }

  const main_type = typeof type === 'string' ? type : type.main_type;
  return ['HOUSE', 'APPT', 'GASTRO', 'INDUS'].includes(main_type ?? '');
}

export const isHouseOrApptOrGastroOrIndus = isHouseOrApptOrGastroOrIndusFn;

function isHouseOrApptOrAgriOrGastroOrIndusFn(main_type: string): boolean;
function isHouseOrApptOrAgriOrGastroOrIndusFn(type: PropertyTypeLight): boolean;
function isHouseOrApptOrAgriOrGastroOrIndusFn(
  type: PropertyTypeLight | string,
) {
  if (type == null) {
    return false;
  }

  const main_type = typeof type === 'string' ? type : type.main_type;
  return ['HOUSE', 'AGRI', 'APPT', 'GASTRO', 'INDUS'].includes(main_type ?? '');
}

export const isHouseOrApptOrAgriOrGastroOrIndus =
  isHouseOrApptOrAgriOrGastroOrIndusFn;

const checkPropTypeName = (
  nameToCheck: string,
  type: PropertyTypeLight | string,
) => {
  if (type == null) {
    return false;
  }

  const propertyTypeName = typeof type === 'string' ? type : type.name ?? '';

  return propertyTypeName === nameToCheck;
};

function isCommercialBuildingFn(propertyTypeName: string | null): boolean;
function isCommercialBuildingFn(type: PropertyTypeLight): boolean;
function isCommercialBuildingFn(type: PropertyTypeLight | string) {
  return checkPropTypeName('indus_commercial', type);
}

export const isCommercialBuilding = isCommercialBuildingFn;

function isMixedUseBuildingFn(propertyTypeName: string | null): boolean;
function isMixedUseBuildingFn(type: PropertyTypeLight): boolean;
function isMixedUseBuildingFn(type: PropertyTypeLight | string) {
  return checkPropTypeName('indus_commercial_and_residential', type);
}

export const isMixedUseBuilding = isMixedUseBuildingFn;

function isResidentialBuildingFn(propertyTypeName: string | null): boolean;
function isResidentialBuildingFn(type: PropertyTypeLight): boolean;
function isResidentialBuildingFn(type: PropertyTypeLight | string) {
  return checkPropTypeName('house_multiple_dwelling', type);
}

export const isResidentialBuilding = isResidentialBuildingFn;
