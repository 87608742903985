import { useMutation } from '@apollo/client';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useLocale } from '../../../src/hooks/locale';
import { Drawer } from '../../components/drawer/Drawer';
import { type FormDefinitionType, RaForm } from '../../components/form/RaForm';

import { CREATE_TEAM } from './teamQueries';

const formDefinition: FormDefinitionType = ({ t }) => [
  {
    name: 'name',
    label: t('Name'),
    type: 'text',
    required: true,
    gridProps: { xs: 12, md: 6 },
  },
];

export const TeamCreateDrawer = () => {
  const navigate = useNavigate();
  const { t } = useLocale();
  const [searchParams] = useSearchParams();
  const [createTeam] = useMutation(CREATE_TEAM);

  const handleClose = () =>
    navigate({
      pathname: '../',
      search: searchParams.toString(),
    });

  const handleSubmit = (values: any) =>
    createTeam({
      variables: {
        team: values,
      },
    }).then(res => {
      navigate({
        pathname: `../${res.data?.insert_teams_one?.id}/overview`,
        search: searchParams.toString(),
      });
    });
  return (
    <Drawer
      onClose={handleClose}
      title={t('Create team')}
      PaperProps={{
        sx: {
          width: ['100%', '100%', 800],
        },
      }}
    >
      <RaForm formDefinition={formDefinition} onSubmit={handleSubmit} />
    </Drawer>
  );
};
