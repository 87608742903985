/**
 * @generated SignedSource<<e3f680da261fe8fc3c82b303d461c0f8>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteFileInput = {|
  clientMutationId?: ?string,
  id: string,
  parentId: string,
|};
export type fileDeleteDialogMutation$variables = {|
  input: DeleteFileInput,
|};
export type fileDeleteDialogMutation$data = {|
  +deleteFile: ?{|
    +development: ?{|
      +files: $ReadOnlyArray<{|
        +id: string,
      |}>,
    |},
    +emailTemplate: ?{|
      +files: $ReadOnlyArray<?{|
        +id: string,
      |}>,
    |},
    +property: ?{|
      +files: $ReadOnlyArray<{|
        +id: string,
      |}>,
    |},
    +user: ?{|
      +files: $ReadOnlyArray<{|
        +id: string,
      |}>,
    |},
  |},
|};
export type fileDeleteDialogMutation = {|
  response: fileDeleteDialogMutation$data,
  variables: fileDeleteDialogMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "File",
  "kind": "LinkedField",
  "name": "files",
  "plural": true,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
],
v5 = [
  (v3/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "fileDeleteDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteFilePayload",
        "kind": "LinkedField",
        "name": "deleteFile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Property",
            "kind": "LinkedField",
            "name": "property",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Development",
            "kind": "LinkedField",
            "name": "development",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EmailTemplate",
            "kind": "LinkedField",
            "name": "emailTemplate",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "fileDeleteDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteFilePayload",
        "kind": "LinkedField",
        "name": "deleteFile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Property",
            "kind": "LinkedField",
            "name": "property",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Development",
            "kind": "LinkedField",
            "name": "development",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EmailTemplate",
            "kind": "LinkedField",
            "name": "emailTemplate",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "68c4d8376fb06fe0f0f17f44cf917fa2",
    "id": null,
    "metadata": {},
    "name": "fileDeleteDialogMutation",
    "operationKind": "mutation",
    "text": "mutation fileDeleteDialogMutation(\n  $input: DeleteFileInput!\n) {\n  deleteFile(input: $input) {\n    property {\n      files {\n        id\n      }\n      id\n    }\n    development {\n      files {\n        id\n      }\n      id\n    }\n    user {\n      files {\n        id\n      }\n      id\n    }\n    emailTemplate {\n      files {\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "e78dc2cfcabf061f8427ee6bde19f53b";

export default node;
