// Generated from src/svgs/lab_filled.svg

import type { Interpolation } from '@emotion/react';

type Props = {
  size?: string | number,
  fill?: string,
  className?: string,
  css?: Interpolation<unknown>,
};

const style = {
  display: "block",
  flex: "0 0 auto",
};

export const LabFilled = ({ size, fill, className }: Props) => {
  return (
    <svg viewBox="0 0 600 600" preserveAspectRatio="xMidYMid meet" width="1em" height="1em" fontSize={size ?? 24} fill={fill ?? 'currentColor'} className={className} css={style}><path d="M388.187 119.835h-21.818c-6.442 0-11.666-5.224-11.666-11.667s5.224-11.667 11.666-11.667h21.818c8.052 0 14.584-6.526 14.584-14.584 0-8.052-6.532-14.584-14.584-14.584h-159.48c-8.052 0-14.584 6.532-14.584 14.584 0 8.058 6.531 14.584 14.584 14.584h24.03c6.448 0 11.667 5.224 11.667 11.667s-.956 11.667-7.404 11.667h-28.293a37.916 37.916 0 0 1-32.839-56.876A37.928 37.928 0 0 1 228.707 44h159.48a37.913 37.913 0 0 1 32.833 18.959 37.904 37.904 0 0 1 0 37.917 37.904 37.904 0 0 1-32.833 18.959Z" /><path clipRule="evenodd" d="M472.307 555.475H128.613a46.672 46.672 0 0 1-30.14-11.089 46.652 46.652 0 0 1-9.875-59.494l152.48-255.147V108.177c0-6.443 5.224-11.667 11.667-11.667 6.448 0 11.666 5.224 11.666 11.667v121.568a23.292 23.292 0 0 1-3.265 11.667l-28.84 48.303a453.884 453.884 0 0 1 44.002-7.241 114.093 114.093 0 0 1 42.12 14 125.829 125.829 0 0 0 28.697 10.964 229.458 229.458 0 0 0 41.287-7.548l-31.016-58.479a23.29 23.29 0 0 1-2.682-10.848V108.177c0-6.442 5.224-11.667 11.666-11.667 6.443 0 11.667 5.225 11.667 11.667v122.386c.995 1.182-.465-1.506 0 0 .009.028-.026-.087 0 0L513.5 487.229a46.655 46.655 0 0 1-1.26 45.864 46.66 46.66 0 0 1-39.921 22.62l-.012-.238Zm-98.467-45.733a55.202 55.202 0 0 1-42.432-21.454 55.184 55.184 0 0 1 67.396-83.546 54.722 54.722 0 0 1 27.536 32.082 55.303 55.303 0 0 1-35 70 58.259 58.259 0 0 1-17.5 2.918Zm-133.004-91.839a44.918 44.918 0 0 0 26.364 8.532l.002-.001a44.973 44.973 0 0 0 14-2.213 44.919 44.919 0 0 0 28.698-56.584 44.901 44.901 0 0 0-22.355-26.083 44.914 44.914 0 0 0-34.251-2.661 44.912 44.912 0 0 0-26.109 22.328 44.418 44.418 0 0 0-2.682 34.297 44.89 44.89 0 0 0 16.333 22.385Z" /></svg>
  );
}
