import AddIcCallOutlinedIcon from '@mui/icons-material/AddIcCallOutlined';
import AddLocationOutlinedIcon from '@mui/icons-material/AddLocationOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import MeetingRoomOutlinedIcon from '@mui/icons-material/MeetingRoomOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import PhoneMissedOutlinedIcon from '@mui/icons-material/PhoneMissedOutlined';

import type { ActivityFieldsFragment } from '../../__generated__/graphql';

export const ActivityIcon = ({
  activity,
}: {
  activity: ActivityFieldsFragment;
}) => {
  if (activity.activity_type === 'call' && activity.success) {
    return <PhoneInTalkOutlinedIcon sx={{ color: 'green' }} />;
  }
  if (
    activity.activity_type === 'call' &&
    !activity.success &&
    activity.done_at
  ) {
    return <PhoneMissedOutlinedIcon sx={{ color: 'red' }} />;
  }
  if (
    activity.activity_type === 'call' &&
    !activity.success &&
    !activity.done_at
  ) {
    return <AddIcCallOutlinedIcon sx={{ color: 'blue' }} />;
  }

  if (activity.activity_type === 'note') {
    return <DescriptionOutlinedIcon sx={{ color: 'orange' }} />;
  }

  if (activity.activity_type === 'email') {
    return <EmailOutlinedIcon />;
  }

  if (activity.activity_type === 'assignment') {
    return <AssignmentIndOutlinedIcon sx={{ color: 'blue' }} />;
  }

  if (activity.activity_type === 'requalification') {
    return <CurrencyExchangeOutlinedIcon sx={{ color: 'green' }} />;
  }

  if (activity.activity_type === 'task') {
    return <CheckBoxOutlinedIcon />;
  }

  if (activity.activity_type === 'origination') {
    return <AddLocationOutlinedIcon sx={{ color: 'red' }} />;
  }

  if (activity.activity_type === 'visit') {
    return <MeetingRoomOutlinedIcon />;
  }

  if (activity.activity_type === 'create_user') {
    return <PersonAddAltOutlinedIcon />;
  }

  return <LabelOutlinedIcon />;
};
