/**
 * @generated SignedSource<<40e4fcdb33813595d436993841f3b6fa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeedNote_activity$data = {
  readonly note?: string | null;
  readonly " $fragmentType": "FeedNote_activity";
};
export type FeedNote_activity$key = {
  readonly " $data"?: FeedNote_activity$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedNote_activity">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedNote_activity",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "note",
          "storageKey": null
        }
      ],
      "type": "ActivityNote",
      "abstractKey": null
    }
  ],
  "type": "Activity",
  "abstractKey": "__isActivity"
};

(node as any).hash = "b1c92229df6d1d4174e2d835ae0851be";

export default node;
