import {
  FormControl,
  FormHelperText,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { graphql, useLazyLoadQuery } from 'react-relay';

import { useLocale } from '../src/hooks/locale';

import type {
  emailSequenceSelectQuery,
  emailSequenceSelectQuery$data,
} from './__generated__/emailSequenceSelectQuery.graphql';
import type { ExistingRecipient } from './email-sequence-dialog';

type Props = {
  value: Readonly<EmailSequenceData> | null;
  onChange: (
    sequence: Readonly<EmailSequenceData> | null,
    recipients?: ExistingRecipient[],
  ) => void;
  errorText?: string | null;
};

export type EmailSequenceData =
  emailSequenceSelectQuery$data['email_sequences_connection']['edges'][number]['node'];

export const EmailSequenceSelect = ({ value, onChange, errorText }: Props) => {
  const { t } = useLocale();
  const data = useLazyLoadQuery<emailSequenceSelectQuery>(
    graphql`
      query emailSequenceSelectQuery {
        email_sequences_connection(where: { status: { _eq: active } }) {
          edges {
            node {
              id
              name
              email_sequences_recipients {
                user_id
                lot_id
                buyer_lead_id
                lead_id
              }
            }
          }
        }
      }
    `,
    {},
    { fetchPolicy: 'store-and-network' },
  );

  const sequences = (data?.email_sequences_connection.edges || []).map(
    edge => edge.node,
  );

  const selectSequence = (selectedItemId: string) => {
    const selectedItem =
      sequences.find(item => item.id === selectedItemId) ?? null;
    const existsRecipientIds = selectedItem?.email_sequences_recipients?.reduce<
      ExistingRecipient[]
    >((acc, item) => {
      const recipient: ExistingRecipient = {
        userId: item.user_id,
        ...(item.lot_id && { lotId: item.lot_id }),
        ...(item.lead_id && { leadId: item.lead_id }),
        ...(item.buyer_lead_id && { buyerLeadId: item.buyer_lead_id ?? null }),
      };
      acc.push(recipient);
      return acc;
    }, []);
    onChange(selectedItem, existsRecipientIds);
  };

  return (
    <FormControl error={!!errorText}>
      <TextField
        select={true}
        label={t('sequence')}
        value={value?.id ?? ''}
        variant="filled"
        disabled={!sequences.length}
        required={true}
        onChange={event => selectSequence(event.target.value)}
      >
        {sequences.map(sequence => (
          <MenuItem key={sequence.id} value={sequence.id}>
            {sequence.name ?? null}
          </MenuItem>
        ))}
      </TextField>
      {!!errorText && <FormHelperText>{errorText}</FormHelperText>}
    </FormControl>
  );
};
