import { gql } from '@apollo/client';
import { Person } from '@mui/icons-material';
import { Avatar, Box, Checkbox } from '@mui/material';
import { Image } from '@realadvisor/image';

import type { UserAvatarFragment } from '../__generated__/graphql';
import { getAbbr } from '../utils/abbr';

export const USER_AVATAR_FRAGMENT = gql`
  fragment UserAvatar on users {
    id
    first_name
    last_name
    user_images(where: { is_primary: { _eq: true } }, limit: 1) {
      id
      image {
        url
      }
    }
  }
`;

const getUserInitals = (fullName: string): string | null => {
  const [firstPart, ...otherParts] = fullName.split(' ');
  const secondPart = otherParts.filter(str => str !== '').join(' ');

  return getAbbr(firstPart ?? null, secondPart ?? null);
};

export const UserAvatar = (props: {
  user?:
    | UserAvatarFragment
    | {
        first_name?: string | null;
        last_name?: string | null;
        user_images?: Array<{
          image: { url: string };
        }>;
      }
    | null;
  size?: number;
  onSelect?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selected?: boolean;
  forceCheckbox?: boolean;
  nameFallback?: string;
}) => {
  const { user, size = 40 } = props;
  const firstName = user?.first_name;
  const lastName = user?.last_name;
  const imageUrl = user?.user_images?.[0]?.image?.url;
  const avatarName =
    `${firstName ?? ''} ${lastName ?? ''}`.replace(' ', '') !== ''
      ? `${firstName} ${lastName}`
      : props.nameFallback;

  return (
    <Avatar
      sx={{
        width: size,
        height: size,
        cursor: props.onSelect != null ? 'pointer' : 'inherit',
        ...(props.onSelect != null
          ? {
              ['& .MuiCheckbox-root']: {
                visibility: props.forceCheckbox
                  ? 'visible !important'
                  : 'hidden !important',
                opacity: props.forceCheckbox ? '1 !important' : '0 !important',
              },
              ['& .avatar-image']: {
                visibility: !props.forceCheckbox
                  ? 'visible !important'
                  : 'hidden !important',
                opacity: !props.forceCheckbox ? '1 !important' : '0 !important',
              },
              ['&:hover .MuiCheckbox-root']: {
                visibility: 'visible !important',
                opacity: '1 !important',
              },
              ['&:hover .avatar-image']: {
                visibility: 'hidden !important',
                opacity: '0 !important',
              },
            }
          : {}),
      }}
    >
      <Box className="avatar-image">
        {imageUrl != null ? (
          <Image src={imageUrl} srcSize={[[size, size]]} />
        ) : (
          getUserInitals(avatarName ?? '') ?? <Person />
        )}
      </Box>
      {props.onSelect && user != null && 'id' in user && (
        <Checkbox
          onChange={props.onSelect}
          value={user?.id}
          checked={props.selected}
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            zIndex: 1,
          }}
          onClick={e => {
            e.stopPropagation();
          }}
        />
      )}
    </Avatar>
  );
};
