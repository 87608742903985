import { type Translate, useLocale } from '../../../src/hooks/locale';
import {
  type Currency,
  type IntlLocale,
  getCurrencyByCountryCode,
} from '../../../src/locale';
import { formatPrice } from '../../../src/utils/format-price';
import {
  translateAppraisalPerception,
  translateAppraisalReason,
  translateBuyHorizon,
  translateMortgageBuyingStatus,
  translateMortgageStatus,
  translateOpenToOffer,
  translateReceivedMortgageOffer,
  translateRelationship,
  translateSaleHorizon,
  translateSellingWith,
} from '../../../src/utils/lead-labels';
import { gql } from '../../__generated__';
import { type LeadQualificationFunnelFragment } from '../../__generated__/graphql';
import { isMultiFamilyOrCommercialProperty } from '../../utils/propertyTypes';

export const LEAD_QUALIFICATION_FUNNEL_FRAGMENT = gql(`
  fragment LeadQualificationFunnel on leads {
    appraisal_reason
    appraisal_open_to_offer
    appraisal_selling_with
    appraisal_market_time
    appraisal_next_step
    appraisal_monthly_gross_rent
    appraisal_perceived_value
    appraisal_separation_next_step
    appraisal_inheritance_next_step
    sale_horizon
    appraisal_perception
    buy_horizon
    ownership
    relationship
    mortgage_term
    mortgage_buying_status
    mortgage_status
    received_mortgage_offer
    property {
      country_code
      property_type {
        name
        main_type
      }
    }
  }
`);

interface LeadQualificationFunnelProps {
  lead: LeadQualificationFunnelFragment;
}

const getQualificationFunnel = (
  lead: LeadQualificationFunnelFragment,
  currency: Currency,
  t: Translate,
  locale: IntlLocale,
) => {
  let step1 = null;

  if (isMultiFamilyOrCommercialProperty(lead.property) && lead.ownership) {
    // Add ownership structure under the contact name, before appraisal reason
    switch (lead.ownership) {
      case 'sole_ownership':
        step1 = t('soleOwnership');
        break;
      case 'co_ownership':
        step1 = t('coOwnership');
        break;
      case 'corporate_ownership':
        step1 = t('corporateOwnership');
        break;
    }
  } else if (lead.relationship !== 'buyer' && lead.relationship !== 'not_set') {
    // TODO: Check if we want to keep that behavior.
    step1 = translateRelationship(t, lead.relationship);
  }

  if (step1 == null) {
    return [
      translateAppraisalReason(t, lead.appraisal_reason),
      translateMortgageBuyingStatus(t, lead.mortgage_buying_status),
      translateMortgageStatus(t, lead.mortgage_status),
      translateReceivedMortgageOffer(t, lead.received_mortgage_offer ?? null),
    ].filter(step => step != null);
  }

  let step3 = null;

  switch (lead.appraisal_reason) {
    case 'sell':
      step3 = translateSaleHorizon(t, lead.sale_horizon);
      break;
    case 'refinance':
      step3 = lead.mortgage_term;
      break;
    case 'separation':
      step3 = lead.appraisal_separation_next_step;
      break;
    case 'inheritance':
      step3 = lead.appraisal_inheritance_next_step;
      break;
    case 'buy_soon':
      step3 = translateBuyHorizon(t, lead.buy_horizon);
      break;
  }

  const sellingWith =
    lead.appraisal_selling_with != null
      ? translateSellingWith(t, lead.appraisal_selling_with)
      : null;
  const step4 =
    (lead.appraisal_reason === 'other_not_owner' &&
      lead.relationship === 'tenant') ||
    lead.appraisal_reason === 'move_rental'
      ? lead.appraisal_monthly_gross_rent
      : lead.appraisal_perceived_value != null
      ? formatPrice(lead.appraisal_perceived_value, locale, currency)
      : null;

  const openToOffer =
    lead.appraisal_open_to_offer && lead.appraisal_open_to_offer !== 'not_set'
      ? translateOpenToOffer(t, lead.appraisal_open_to_offer)
      : null;
  const marketTime =
    lead.appraisal_market_time != null
      ? t('sinceMonths', { numberMonths: lead.appraisal_market_time })
      : null;

  return [
    // step 1
    step1,
    // step 2
    lead.appraisal_reason !== 'not_set'
      ? translateAppraisalReason(t, lead.appraisal_reason)
      : null,
    // step 3
    step3,
    sellingWith,
    // step 4
    step4,
    // step 5
    lead.appraisal_perception == null
      ? null
      : translateAppraisalPerception(t, lead.appraisal_perception ?? ''),
    // step 6
    lead.appraisal_next_step,
    openToOffer,
    marketTime,
  ].filter(step => step != null);
};

export const LeadQualificationFunnel: React.FC<
  LeadQualificationFunnelProps
> = ({ lead }) => {
  const { t, locale } = useLocale();

  const currency = getCurrencyByCountryCode(
    lead.property?.country_code ?? 'CH',
  );

  return <>{getQualificationFunnel(lead, currency, t, locale).join(' • ')}</>;
};
