import React, { useMemo, useState } from 'react';

import { gql, useLazyQuery, useQuery } from '@apollo/client';
import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { debounce } from '@mui/material/utils';

import type {
  GetSelectedTeamsQuery,
  GetTeamsQuery,
} from '../__generated__/graphql';
import type { ConditionOperator } from '../utils/parseWhereClause';

export type TeamOperator = Extract<ConditionOperator, '_in' | '_nin'>;

type FilterTeamFormProps = {
  value: string[] | 'true' | 'false';
  operator: TeamOperator | null;
  onChange: (params: {
    value: FilterTeamFormProps['value'];
    operator: TeamOperator;
  }) => void;
};

const GET_SELECTED_TEAMS = gql`
  query GetSelectedTeams($team_ids: [uuid!]) {
    teams(where: { id: { _in: $team_ids } }) {
      id
      name
    }
  }
`;

type DisplaySelectedTeamsProps = {
  teamIds: string[];
};

export const DisplaySelectedTeams: React.FC<DisplaySelectedTeamsProps> = ({
  teamIds,
}) => {
  const [teams, setTeams] = useState<GetSelectedTeamsQuery['teams']>([]);

  useQuery<GetSelectedTeamsQuery>(GET_SELECTED_TEAMS, {
    variables: { team_ids: teamIds },
    onCompleted: data => {
      setTeams(data?.teams ?? []);
    },
  });

  if (teams?.length === 1) {
    return <>{teams[0].name}</>;
  } else if (teams?.length > 1) {
    return (
      <>
        {teams[0].name} + {teams.length - 1}
      </>
    );
  }
  return null;
};

const GET_TEAMS = gql`
  query GetTeams($q: String!) {
    teams(where: { name: { _ilike: $q } }, limit: 10) {
      id
      name
    }
  }
`;

export const FilterTeamForm: React.FC<FilterTeamFormProps> = ({
  value: initialValue,
  operator: initialOperator,
  onChange,
}) => {
  const [value, setValue] = useState<GetTeamsQuery['teams']>([]);
  const [operator, setOperator] = useState(initialOperator ?? '_in');
  const [open, setOpen] = useState(false);

  useQuery<GetSelectedTeamsQuery>(GET_SELECTED_TEAMS, {
    variables: { user_ids: initialValue },
    skip: initialValue.length === 0,
    onCompleted: data => {
      setValue(data?.teams ?? null);
    },
  });

  const [getTeams, { data, loading }] = useLazyQuery<GetTeamsQuery>(GET_TEAMS);

  const debouncedSearch = useMemo(
    () =>
      debounce(value => {
        getTeams({
          variables: {
            q: value,
          },
        });
      }, 500),
    [getTeams],
  );

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      debouncedSearch(event.target.value);
    }
  };

  return (
    <Stack direction="column" alignItems="center" spacing={1} paddingY={2}>
      <Select
        size="small"
        variant="filled"
        fullWidth
        value={operator}
        //@ts-ignore
        hiddenLabel
        onChange={event => {
          const selectedOperator = event.target.value as TeamOperator;
          if (['_in', '_nin'].includes(selectedOperator)) {
            setOperator(selectedOperator);
            onChange({
              value: value?.map(team => team.id) ?? null,
              operator: selectedOperator,
            });
          }
        }}
      >
        {[{ value: '_in', label: 'is any of' }].map(item => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      <Autocomplete
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        value={value ?? []}
        disableCloseOnSelect
        size="small"
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={option => option?.name ?? ''}
        renderOption={(props, option, { selected }) => (
          <ListItem {...props} dense disableGutters disablePadding>
            <ListItemIcon sx={{ minWidth: 0 }}>
              <Checkbox
                size="small"
                disableRipple
                sx={{
                  '&.MuiCheckbox-root': {
                    padding: '4px',
                  },
                }}
                checked={selected}
              />
            </ListItemIcon>
            <ListItemText
              primary={option.name}
              primaryTypographyProps={{
                noWrap: true,
              }}
              secondaryTypographyProps={{
                noWrap: true,
              }}
            />
          </ListItem>
        )}
        options={data?.teams ?? []}
        loading={loading}
        filterOptions={x => x}
        autoComplete
        multiple
        includeInputInList
        fullWidth
        noOptionsText="No teams"
        clearOnBlur
        onChange={(_, newValue) => {
          setValue(newValue);
          onChange({
            value: newValue?.map(user => user.id) ?? null,
            operator,
          });
        }}
        renderInput={params => (
          <TextField
            {...params}
            size="small"
            hiddenLabel
            onChange={handleSearch}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} disableShrink />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </Stack>
  );
};
