/**
 * @generated SignedSource<<e7817ed5b52356fab5ac83a03d2cbe1c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ActivityTypeEnum = "assignment" | "call" | "change" | "create_user" | "email" | "match" | "note" | "offer" | "origination" | "requalification" | "task" | "visit";
export type EmailVisibilityTypeEnum = "organisation" | "private" | "shared";
export type OfferStatus = "accepted" | "pending" | "refused";
import { FragmentRefs } from "relay-runtime";
export type FeedItem_activity$data = {
  readonly __typename: string;
  readonly activityType: ActivityTypeEnum;
  readonly assignedTo: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"UserName_user">;
  } | null;
  readonly buyerLead: {
    readonly id: string;
    readonly lot: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"lotSnippet_lot">;
    };
    readonly user: {
      readonly firstName: string | null;
      readonly lastName: string | null;
    };
    readonly " $fragmentSpreads": FragmentRefs<"ActivityForm_buyerLead" | "parentLink_buyerLead">;
  } | null;
  readonly canEdit: boolean;
  readonly canEditVisibility: boolean;
  readonly createdAt: string;
  readonly createdBy: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"UserName_user">;
  } | null;
  readonly displayParticipants: ReadonlyArray<{
    readonly email: string | null;
    readonly name: string | null;
    readonly user: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"UserName_user">;
    } | null;
  }> | null;
  readonly done?: boolean;
  readonly emailMessage?: {
    readonly bcc: ReadonlyArray<{
      readonly email: string;
      readonly name: string | null;
      readonly user: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"UserName_user">;
      } | null;
    } | null>;
    readonly cc: ReadonlyArray<{
      readonly email: string;
      readonly name: string | null;
      readonly user: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"UserName_user">;
      } | null;
    } | null>;
    readonly date: string;
    readonly from: ReadonlyArray<{
      readonly email: string;
      readonly name: string | null;
      readonly user: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"UserName_user">;
      } | null;
    } | null>;
    readonly replyTo: ReadonlyArray<{
      readonly email: string;
      readonly name: string | null;
      readonly user: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"UserName_user">;
      } | null;
    } | null>;
    readonly threadId: string | null;
    readonly to: ReadonlyArray<{
      readonly email: string;
      readonly name: string | null;
      readonly user: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"UserName_user">;
      } | null;
    } | null>;
  } | null;
  readonly enquiry: {
    readonly firstName: string | null;
    readonly id: string;
    readonly lastName: string | null;
    readonly lot: {
      readonly id: string;
      readonly property: {
        readonly countryCode: string | null;
        readonly locality: string | null;
        readonly postcode: string | null;
        readonly route: string | null;
        readonly state: string | null;
        readonly streetNumber: string | null;
      };
      readonly title: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"lotSnippet_lot">;
    } | null;
    readonly portal: {
      readonly id: string;
      readonly name: string;
    } | null;
    readonly user: {
      readonly " $fragmentSpreads": FragmentRefs<"UserName_user">;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"ActivityForm_enquiry" | "feedEnquiry_parent" | "parentLink_enquiry">;
  } | null;
  readonly id: string;
  readonly isOwner: boolean;
  readonly lead: {
    readonly createdBy: {
      readonly firstName: string | null;
      readonly lastName: string | null;
    } | null;
    readonly property: {
      readonly locality: string | null;
      readonly postcode: string | null;
      readonly route: string | null;
      readonly streetNumber: string | null;
    } | null;
    readonly sourceOrganisation: {
      readonly name: string | null;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"ActivityForm_lead" | "leadSnippet_lead" | "parentLink_lead">;
  } | null;
  readonly lot: {
    readonly id: string;
    readonly title: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"ActivityForm_lot" | "lotSnippet_lot" | "parentLink_lot">;
  } | null;
  readonly offer: {
    readonly buyer: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"UserName_user">;
    } | null;
  } | null;
  readonly offerRefused?: {
    readonly id: string;
    readonly label: string;
  } | null;
  readonly offerStatus?: OfferStatus;
  readonly owner: {
    readonly email: string | null;
    readonly name: string | null;
    readonly user: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"UserName_user">;
    } | null;
  } | null;
  readonly pinned: boolean;
  readonly success?: boolean;
  readonly user: {
    readonly firstName: string | null;
    readonly id: string;
    readonly lastName: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"ActivityForm_user" | "parentLink_user">;
  } | null;
  readonly visibility: EmailVisibilityTypeEnum | null;
  readonly " $fragmentSpreads": FragmentRefs<"ActivityForm_activity" | "FeedAssignment_activity" | "FeedCall_activity" | "FeedDeleteDialog_activity" | "FeedEmail_activity" | "FeedNote_activity" | "FeedRequalification_activity" | "FeedTask_activity" | "FeedVisit_activity" | "NewMatch_activity" | "feedOffer_activity">;
  readonly " $fragmentType": "FeedItem_activity";
};
export type FeedItem_activity$key = {
  readonly " $data"?: FeedItem_activity$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedItem_activity">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "UserName_user"
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "email",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": (v2/*: any*/),
    "storageKey": null
  }
],
v5 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "lotSnippet_lot"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "route",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "streetNumber",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postcode",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locality",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v13 = [
  (v11/*: any*/),
  (v12/*: any*/)
],
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "done",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedItem_activity",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "activityType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canEdit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canEditVisibility",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isOwner",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "visibility",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pinned",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ActivityParticipant",
      "kind": "LinkedField",
      "name": "owner",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ActivityParticipant",
      "kind": "LinkedField",
      "name": "displayParticipants",
      "plural": true,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "assignedTo",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Enquiry",
      "kind": "LinkedField",
      "name": "enquiry",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ActivityForm_enquiry"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "parentLink_enquiry"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "feedEnquiry_parent"
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Portal",
          "kind": "LinkedField",
          "name": "portal",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v3/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Lot",
          "kind": "LinkedField",
          "name": "lot",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            (v0/*: any*/),
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Property",
              "kind": "LinkedField",
              "name": "property",
              "plural": false,
              "selections": [
                (v7/*: any*/),
                (v8/*: any*/),
                (v9/*: any*/),
                (v10/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "state",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "countryCode",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v11/*: any*/),
        (v12/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Lot",
      "kind": "LinkedField",
      "name": "lot",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ActivityForm_lot"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "parentLink_lot"
        },
        (v5/*: any*/),
        (v0/*: any*/),
        (v6/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Lead",
      "kind": "LinkedField",
      "name": "lead",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ActivityForm_lead"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "parentLink_lead"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "leadSnippet_lead"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Organisation",
          "kind": "LinkedField",
          "name": "sourceOrganisation",
          "plural": false,
          "selections": [
            (v3/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Property",
          "kind": "LinkedField",
          "name": "property",
          "plural": false,
          "selections": [
            (v7/*: any*/),
            (v8/*: any*/),
            (v9/*: any*/),
            (v10/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "createdBy",
          "plural": false,
          "selections": (v13/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ActivityForm_user"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "parentLink_user"
        },
        (v0/*: any*/),
        (v11/*: any*/),
        (v12/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BuyerLead",
      "kind": "LinkedField",
      "name": "buyerLead",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ActivityForm_buyerLead"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "parentLink_buyerLead"
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Lot",
          "kind": "LinkedField",
          "name": "lot",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": (v13/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Offer",
      "kind": "LinkedField",
      "name": "offer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "buyer",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeedDeleteDialog_activity"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ActivityForm_activity"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NewMatch_activity"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FeedRequalification_activity"
        }
      ],
      "type": "ActivityRequalification",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FeedCall_activity"
        },
        (v14/*: any*/),
        (v15/*: any*/)
      ],
      "type": "ActivityCall",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FeedVisit_activity"
        },
        (v14/*: any*/),
        (v15/*: any*/)
      ],
      "type": "ActivityVisit",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v14/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FeedNote_activity"
        }
      ],
      "type": "ActivityNote",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v14/*: any*/),
        (v15/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FeedTask_activity"
        }
      ],
      "type": "ActivityTask",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v14/*: any*/),
        (v15/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FeedAssignment_activity"
        }
      ],
      "type": "ActivityAssignment",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v14/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "EmailMessage",
          "kind": "LinkedField",
          "name": "emailMessage",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "threadId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "date",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "EmailRecipient",
              "kind": "LinkedField",
              "name": "to",
              "plural": true,
              "selections": (v4/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "EmailRecipient",
              "kind": "LinkedField",
              "name": "from",
              "plural": true,
              "selections": (v4/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "EmailRecipient",
              "kind": "LinkedField",
              "name": "replyTo",
              "plural": true,
              "selections": (v4/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "EmailRecipient",
              "kind": "LinkedField",
              "name": "cc",
              "plural": true,
              "selections": (v4/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "EmailRecipient",
              "kind": "LinkedField",
              "name": "bcc",
              "plural": true,
              "selections": (v4/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FeedEmail_activity"
        }
      ],
      "type": "ActivityEmail",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "offerStatus",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Dictionary",
          "kind": "LinkedField",
          "name": "offerRefused",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "label",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "feedOffer_activity"
        }
      ],
      "type": "ActivityOffer",
      "abstractKey": null
    }
  ],
  "type": "Activity",
  "abstractKey": "__isActivity"
};
})();

(node as any).hash = "c43968914730725657709acf11bdc9ee";

export default node;
