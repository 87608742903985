let passiveIfSupported_: null | boolean = null;

export const hasPassiveEventsSupport = (): boolean => {
  if (passiveIfSupported_ != null) {
    return passiveIfSupported_;
  }

  passiveIfSupported_ = false;

  try {
    // @ts-ignore untypable hack to test passive events support
    window.addEventListener(
      'test',
      null,
      Object.defineProperty({}, 'passive', {
        get() {
          passiveIfSupported_ = true;
          return null;
        },
      }),
    );
  } catch {
    // do nothing
  }

  return passiveIfSupported_;
};
