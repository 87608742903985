// @flow

import * as React from 'react';

import {
  Avatar,
  Chip,
  FormControl,
  FormHelperText,
  Input,
} from '@material-ui/core';
import { Box, Flex } from 'react-system';

import { useLocale } from '../../hooks/locale';
import { useTheme } from '../../hooks/theme';
import { Person } from '../../icons/person';
import { type User, UserMultiInput } from '../../shared/user-input';

type Props = {|
  value: $ReadOnlyArray<User>,
  readOnly?: boolean,
  maxVisibleItems?: ?number,
  actionComponent?: React.Node,
  onChange: ($ReadOnlyArray<User>) => void,
  error?: ?boolean,
  errorText?: ?string,
  onBlur?: () => void,
|};

export const getName = (user: any): React.Node => {
  if (!user) {
    return '';
  }
  if (user.label) {
    return user.label;
  }
  if (user.name != null && user.name.trim() !== '') {
    return user.name;
  }
  if (user.email != null) {
    return user.email;
  }
  if (
    (user.firstName == null || user.firstName === '') &&
    (user.lastName == null || user.lastName === '')
  ) {
    return user.primaryEmail?.email;
  }
  return [user.firstName, ' ', user.lastName];
};

const UsersList = ({ value }) => {
  const { text } = useTheme();
  return (
    <Flex py={2} flexWrap="wrap">
      {value.map(user => {
        const imageUrl = user?.primaryImage?.url;
        return (
          <Chip
            key={user.id}
            tabIndex={-1}
            avatar={
              user.label != null ? null : imageUrl != null ? (
                <Avatar src={imageUrl} />
              ) : (
                <Avatar>
                  <Person />
                </Avatar>
              )
            }
            label={getName(user)}
            css={{
              margin: '2px',
              backgroundColor: 'rgb(0, 0, 0, 0.1)',
              overflow: 'hidden',
              '.MuiChip-label': [text.truncate(1), { display: 'block' }],
            }}
          />
        );
      })}
    </Flex>
  );
};

const BaseUserInput = React.forwardRef((props, ref) => {
  return (
    <Input
      ref={ref}
      {...props}
      variant="standard"
      fullWidth={true}
      disableUnderline={true}
      css={{ minHeight: 48 }}
    />
  );
});

export const AddressField = ({
  value,
  onChange,
  actionComponent,
  readOnly,
  maxVisibleItems,
  errorText,
}: Props): React.Node => {
  const { t } = useLocale();
  const users =
    maxVisibleItems != null && value.length > maxVisibleItems + 1
      ? [
          ...value.slice(0, maxVisibleItems),
          ({
            label: `+${value.length - maxVisibleItems} ${t('recipients')}`,
          }: any),
        ]
      : value;
  return (
    <FormControl error={errorText != null}>
      <Flex
        width={1}
        justifyContent="space-between"
        alignItems="center"
        css={[
          {
            borderRadius: 4,
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            paddingLeft: 8,
            paddingRight: 8,
          },
          readOnly !== true && {
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.08)',
              '&::after': {
                content: '""',
                position: 'absolute',
                left: 2,
                right: 2,
                bottom: 0,
                height: 1,
                backgroundColor: 'black',
              },
            },
          },
        ]}
      >
        <Box flexGrow={1}>
          {readOnly === true ? (
            <UsersList value={users} />
          ) : (
            <UserMultiInput
              Input={BaseUserInput}
              value={users}
              onChange={onChange}
            />
          )}
        </Box>
        {actionComponent != null && (
          <Box flexShrink={0} css={{ fontSize: 16, color: '#757575' }}>
            {actionComponent}
          </Box>
        )}
      </Flex>
      {errorText != null && <FormHelperText>{errorText}</FormHelperText>}
    </FormControl>
  );
};
