/**
 * @generated SignedSource<<e8f7987be47e5465660e0e49f0798330>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type feedEnquiry_parent$data = {
  readonly buyerLead: {
    readonly __typename: "BuyerLead";
  } | null;
  readonly createdAt: string;
  readonly id: string;
  readonly message: string | null;
  readonly " $fragmentType": "feedEnquiry_parent";
};
export type feedEnquiry_parent$key = {
  readonly " $data"?: feedEnquiry_parent$data;
  readonly " $fragmentSpreads": FragmentRefs<"feedEnquiry_parent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "feedEnquiry_parent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BuyerLead",
      "kind": "LinkedField",
      "name": "buyerLead",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Enquiry",
  "abstractKey": null
};

(node as any).hash = "79d6221a0e05bce9420a82618996993b";

export default node;
